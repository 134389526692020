import { createAction, TActionsUnion } from "@core/redux/utils"
import { TCreateSubAccountScreenName } from "../create-sub-account/create-sub-accounts.router"
import { ScheduleFrequencyEnum } from "@generated/graphql"
import { TSubAccountTransferScreenName } from "../transfer/sub-account-transfer.router"
import { ISubAccount } from "@typedefs/types"

export enum AccountsActionEnum {
  SET_ACTIVE_TRANSACTION_ID = "ACCOUNTS_SET_ACTIVE_TRANSACTION_ID",
  CREATE_SUB_ACCOUNT_SET_ID = "CREATE_SUB_ACCOUNT_SET_ID",
  CREATE_SUB_ACCOUNT_SET_NAME = "CREATE_SUB_ACCOUNT_SET_NAME",
  CREATE_SUB_ACCOUNT_NAVIGATE_NEXT = "CREATE_SUB_ACCOUNT_NAVIGATE_NEXT",
  CREATE_SUB_ACCOUNT_NAVIGATE_BACK = "CREATE_SUB_ACCOUNT_NAVIGATE_BACK",
  CREATE_SUB_ACCOUNT_SET_INITIAL_BALANCE = "CREATE_SUB_ACCOUNT_SET_INITIAL_BALANCE",
  CREATE_SUB_ACCOUNT_SET_SCHEDULED_AMOUNT = "CREATE_SUB_ACCOUNT_SET_SCHEDULED_AMOUNT",
  CREATE_SUB_ACCOUNT_SET_SCHEDULED_FREQUENCY = "CREATE_SUB_ACCOUNT_SET_SCHEDULED_FREQUENCY",
  CREATE_SUB_ACCOUNT_SET_PERCENTAGE = "CREATE_SUB_ACCOUNT_SET_PERCENTAGE",
  CREATE_SUB_ACCOUNT_CLEAR_STATE = "CREATE_SUB_ACCOUNT_CLEAR_STATE",
  SUB_ACCOUNT_TRANSFER_NAVIGATE_NEXT = "SUB_ACCOUNT_TRANSFER_NAVIGATE_NEXT",
  SUB_ACCOUNT_TRANSFER_NAVIGATE_BACK = "SUB_ACCOUNT_TRANSFER_NAVIGATE_BACK",
  SUB_ACCOUNT_TRANSFER_DISCARD = "SUB_ACCOUNT_TRANSFER_DISCARD",
  SUB_ACCOUNT_TRANSFER_SET_SOURCE_ACCOUNT = "SUB_ACCOUNT_TRANSFER_SET_SOURCE_ACCOUNT",
  SUB_ACCOUNT_TRANSFER_SET_DESTINATION_ACCOUNT = "SUB_ACCOUNT_TRANSFER_SET_DESTINATION_ACCOUNT",
  SUB_ACCOUNT_TRANSFER_SET_AMOUNT = "SUB_ACCOUNT_TRANSFER_SET_AMOUNT",
}

export const accountsActions = {
  setActiveTransactionId: (id: string) =>
    createAction(AccountsActionEnum.SET_ACTIVE_TRANSACTION_ID, id),
  setName: (name: string) => createAction(AccountsActionEnum.CREATE_SUB_ACCOUNT_SET_NAME, name),
  setCreateSubAccountId: (id: string) =>
    createAction(AccountsActionEnum.CREATE_SUB_ACCOUNT_SET_ID, id),
  setCreateSubAccountName: (name: string) =>
    createAction(AccountsActionEnum.CREATE_SUB_ACCOUNT_SET_NAME, name),
  setCreateSubAccountInitialBalance: (balance: string) =>
    createAction(AccountsActionEnum.CREATE_SUB_ACCOUNT_SET_INITIAL_BALANCE, balance),
  setCreateSubAccountScheduledAmount: (amount: string) =>
    createAction(AccountsActionEnum.CREATE_SUB_ACCOUNT_SET_SCHEDULED_AMOUNT, amount),
  setCreateSubAccountScheduledFrequency: (frequency: ScheduleFrequencyEnum | null) =>
    createAction(AccountsActionEnum.CREATE_SUB_ACCOUNT_SET_SCHEDULED_FREQUENCY, frequency),
  setCreateSubAccountPercentage: (percentage: string) =>
    createAction(AccountsActionEnum.CREATE_SUB_ACCOUNT_SET_PERCENTAGE, percentage),
  clearCreateSubAccountState: () => createAction(AccountsActionEnum.CREATE_SUB_ACCOUNT_CLEAR_STATE),
  createSubAccountNavigateNext: (nextScreen: TCreateSubAccountScreenName) =>
    createAction(AccountsActionEnum.CREATE_SUB_ACCOUNT_NAVIGATE_NEXT, nextScreen),
  createSubAccountNavigateBack: () =>
    createAction(AccountsActionEnum.CREATE_SUB_ACCOUNT_NAVIGATE_BACK),
  subAccountTransferNavigateNext: (nextScreen: TSubAccountTransferScreenName) =>
    createAction(AccountsActionEnum.SUB_ACCOUNT_TRANSFER_NAVIGATE_NEXT, nextScreen),
  subAccountTransferNavigateBack: () =>
    createAction(AccountsActionEnum.SUB_ACCOUNT_TRANSFER_NAVIGATE_BACK),
  subAccountTransferDiscard: () => createAction(AccountsActionEnum.SUB_ACCOUNT_TRANSFER_DISCARD),
  setSubAccountTransferSourceAccount: (sourceAccount: ISubAccount) =>
    createAction(AccountsActionEnum.SUB_ACCOUNT_TRANSFER_SET_SOURCE_ACCOUNT, sourceAccount),
  setSubAccountTransferDestinationAccount: (destinationAccount: ISubAccount) =>
    createAction(
      AccountsActionEnum.SUB_ACCOUNT_TRANSFER_SET_DESTINATION_ACCOUNT,
      destinationAccount,
    ),
  setSubAccountTransferAmount: (amount: string) =>
    createAction(AccountsActionEnum.SUB_ACCOUNT_TRANSFER_SET_AMOUNT, amount),
}

export type TAccountsActions = TActionsUnion<typeof accountsActions>
