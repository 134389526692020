import React from "react"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import { formatMoney } from "accounting"
import { Analytics } from "@core/analytics/actions"
import { selectMoveMoney } from "@core/redux/app/selectors"
import { useAppSelector } from "@core/redux/utils"
import { RoutesEnum } from "@routers/types"
import { ButtonTypeEnum } from "@components/primitive/button/button"
import { InfoBlock } from "@components/composite/info-block/info-block"
import { moveMoneyActions } from "@features/move-money/redux/actions"
import { getPaymentSuccessSubtitle } from "@features/move-money/utils/helpers"
import {
  SENT_CONTINUE,
  SENT_RETURN,
  SENT_LABEL,
  SENT_TITLE_I18N,
  SENT_PAYMENT_TYPES_I18N,
} from "@features/move-money/utils/i18n-translations"
import { ScheduleFrequencyEnum } from "@generated/graphql"
import { images } from "@assets/images/images"
import { translate } from "@i18n/i18n"

interface IMoveMoneySentCardProps {
  doneEvent: string
  subtitle?: string
}

export const MoveMoneySentCard: React.FC<IMoveMoneySentCardProps> = ({ doneEvent, subtitle }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const moveMoneyState = useAppSelector(selectMoveMoney)
  const { amount, recipient, paymentMethod, subAccount } = moveMoneyState

  const buttons = [
    {
      onClick: () => {
        dispatch(moveMoneyActions.deleteDraft())
        Analytics.track(doneEvent)
      },
      children: SENT_CONTINUE,
    },
    {
      type: ButtonTypeEnum.TERTIARY,
      onClick: () => {
        history.push(RoutesEnum.DASHBOARD)
        dispatch(moveMoneyActions.deleteDraft())
      },
      children: SENT_RETURN,
    },
  ]

  return (
    <InfoBlock
      subTitleHasPartialBolding
      imageSrc={images.illustrations.rocketship}
      imageDimensions={{ height: 180, width: 250 }}
      heading={SENT_LABEL}
      title={translate(SENT_TITLE_I18N, {
        amount: formatMoney(amount),
        recipientName: recipient.name,
        paymentMethod: translate(`${SENT_PAYMENT_TYPES_I18N}.${paymentMethod}`, { count: 1 }),
        account: subAccount.name,
      })}
      subtitle={
        subtitle ||
        getPaymentSuccessSubtitle({
          paymentType: paymentMethod,
          frequency: ScheduleFrequencyEnum.ONCE,
          startDate: new Date(),
          endDate: null,
        })
      }
      buttons={buttons}
    />
  )
}
