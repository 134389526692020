import React from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import i18n from "@i18n/i18n"
import { RoutesEnum } from "@routers/types"
import { Text } from "@components/primitive/text/text"
import { NavigationListItem } from "@components/extended/list-items/navigation/navigation-list-item"
import { BaseLayout } from "@layouts/base-layout"
import { FlexColumnLayout } from "@layouts/flex"

const RECIPIENT_TYPES = [
  {
    name: i18n.t("settings.pages.recipients.ach"),
    route: `${RoutesEnum.SETTINGS_RECIPIENTS}/ach`,
  },
  {
    name: i18n.t("settings.pages.recipients.wire"),
    route: `${RoutesEnum.SETTINGS_RECIPIENTS}/wire`,
  },
]

export const RecipientsLanding: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  return (
    <BaseLayout pageTitle={t("pageTitles.settings.recipients")}>
      <Text tag="h2" sx={{ marginBottom: 36 }}>
        {t("settings.pages.recipients.title")}
      </Text>
      <FlexColumnLayout>
        {RECIPIENT_TYPES.map(({ name, route }, idx) => (
          <NavigationListItem text={name} key={idx} onClick={() => history.push(route)} />
        ))}
      </FlexColumnLayout>
    </BaseLayout>
  )
}
