import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { TransactionPanelItem } from "./base-transaction-panel"
import { SubscriptionUpsellButton } from "@features/subscriptions/shared/components/upsell-button"
import { moveMoneyActions } from "@features/move-money/redux/actions"
import { useTranslation } from "react-i18next"
import { selectPaymentMethod, selectScreenHistory } from "@features/move-money/redux/selectors"
import { SubscriptionUpsellTransactionType, TransactionTypeEnum } from "@generated/graphql"
import { Analytics } from "@core/analytics/actions"
import { events } from "@core/analytics/events"
import { useAppSelector } from "@core/redux/utils"
import { useTransactionSubscriptionUpsell } from "@features/subscriptions/shared/hooks/use-transaction-subscription-upsell"

export const TransactionPanelSubscriptionUpsell = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const screenHistory = useAppSelector(selectScreenHistory)
  const isOnConfirmationScreen = ["ACH_CONFIRM", "DOMESTIC_WIRE_CONFIRM"].includes(
    screenHistory[screenHistory.length - 1],
  )
  const paymentMethod = useSelector(selectPaymentMethod)
  const transactionType = getUpsellTransactionTypeFromTransactionEnum(paymentMethod)

  const { upsellPlan } = useTransactionSubscriptionUpsell({
    transactionType,
    fetchPolicy: "cache-and-network",
  })

  const buttonContent = upsellPlan?.buttonContent

  if (!isOnConfirmationScreen || !buttonContent) return null

  return (
    <TransactionPanelItem label={t("transactions.previewPanel.yourPlan")}>
      <SubscriptionUpsellButton
        text={buttonContent}
        onClick={() => {
          Analytics.track(events.subscriptions.moveMoneyUpsell, { paymentMethod })
          dispatch(moveMoneyActions.navigateNext("SUBSCRIPTION_UPSELL_DETAILS"))
        }}
      />
    </TransactionPanelItem>
  )
}

export const getUpsellTransactionTypeFromTransactionEnum = (
  paymentMethod?: TransactionTypeEnum,
): SubscriptionUpsellTransactionType | undefined => {
  switch (paymentMethod) {
    case TransactionTypeEnum.ACH: {
      return SubscriptionUpsellTransactionType.ACH
    }
    case TransactionTypeEnum.DOMESTIC_WIRE:
      return SubscriptionUpsellTransactionType.OUTGOING_WIRE
    default: {
      return
    }
  }
}
