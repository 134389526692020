import React, { useState, useEffect, useRef } from "react"
import { useRouteMatch, useHistory, useLocation } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useAppSelector } from "@core/redux/utils"
import { RoutesEnum, ILocationState } from "@routers/types"
import { ACHScreensEnum } from "@features/move-money/router/ach"
import {
  PAGE_TITLES_MOVEMONEY_SENDFLOW_I18N,
  PAGE_TITLES_MOVEMONEY_SEND,
} from "@features/move-money/utils/i18n-translations"
import { translate } from "@i18n/i18n"
import { FlowModal } from "@components/extended/overlay/flow-modal"
import { navigateFromModal } from "@utils/navigation"
import { getTransactionTypeDetails } from "@utils/mapping/transactions.mapping"
import { toTitleCase } from "@utils/format"
import { TransactionTypeEnum } from "@generated/graphql"
import { MOVE_MONEY_ROUTES, TMoveMoneyScreenNames } from "./router/move-money"
import {
  moveMoneyDomesticWireProgressBarItems,
  moveMoneyProgressBarItems,
} from "./router/progress-bar-items"
import { MoveMoneyPanel } from "./move-money-panel"
import { moveMoneyActions } from "./redux/actions"
import { selectPaymentMethod, selectScreenHistory } from "./redux/selectors"
import { DiscardPaymentModal } from "./discard-modal/discard-modal"
import { TimeSavedBanner, TimeSavingActionsEnum } from "./time-saved-banner"

export const MoveMoneyModal: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation<ILocationState>()
  const isRouteMatch = useRouteMatch({ path: RoutesEnum.MOVE_MONEY })
  const paymentMethod = useAppSelector(selectPaymentMethod)
  const screenHistory = useAppSelector(selectScreenHistory)
  const scrollContainerRef = useRef<HTMLDivElement>(null)
  const [discardModalOpen, setDiscardModalOpen] = useState(false)

  const currentRoute = screenHistory[screenHistory.length - 1]
  const currentScreen = MOVE_MONEY_ROUTES[currentRoute]

  const dismissibleScreens: TMoveMoneyScreenNames[] = ["PAYMENT_METHOD", ACHScreensEnum.ACH_SENT]
  const isOpen = Boolean(isRouteMatch?.isExact)

  useEffect(() => {
    if (scrollContainerRef && scrollContainerRef.current) {
      scrollContainerRef.current.scrollTo({ top: 0 })
    }
  }, [currentScreen])

  const pageTitle =
    paymentMethod && currentRoute !== "PAYMENT_METHOD"
      ? translate(PAGE_TITLES_MOVEMONEY_SENDFLOW_I18N, {
          paymentMethod: toTitleCase({
            text: getTransactionTypeDetails(paymentMethod).outgoingType,
          }),
        })
      : PAGE_TITLES_MOVEMONEY_SEND

  const progressBarItems =
    paymentMethod && paymentMethod === TransactionTypeEnum.DOMESTIC_WIRE
      ? moveMoneyDomesticWireProgressBarItems
      : moveMoneyProgressBarItems

  const bottomBanners: { [key in TMoveMoneyScreenNames]?: JSX.Element } = {
    DOMESTIC_WIRE_SENT: <TimeSavedBanner timeSavingAction={TimeSavingActionsEnum.DOMESTIC_WIRE} />,
    ACH_SENT: <TimeSavedBanner timeSavingAction={TimeSavingActionsEnum.ACH} />,
  }
  return (
    <>
      <DiscardPaymentModal
        isOpen={discardModalOpen}
        closeModal={() => setDiscardModalOpen(false)}
      />
      <FlowModal
        pageTitle={pageTitle}
        scrollContainerRef={scrollContainerRef}
        layout={currentScreen.layout}
        isOpen={isOpen}
        onOutsideClick={() => {
          if (dismissibleScreens.includes(screenHistory[screenHistory.length - 1])) {
            navigateFromModal({ defaultBackRoute: RoutesEnum.DASHBOARD, history, location })
            history.push(location.state?.background?.pathname || RoutesEnum.DASHBOARD)
            dispatch(moveMoneyActions.deleteDraft())
          } else {
            setDiscardModalOpen(true)
          }
        }}
        progressBar={{
          currentItem: currentScreen.progressItem,
          progressItems: progressBarItems,
        }}
        sidepanel={<MoveMoneyPanel />}
        bottomBanner={bottomBanners[currentRoute]}
      >
        {currentScreen.component}
      </FlowModal>
    </>
  )
}
