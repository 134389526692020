import React from "react"
import { TransactionTypeEnum } from "@generated/graphql"
import { images } from "@assets/images/images"
import { useTranslation } from "react-i18next"
import { TransactionListItem } from "./transaction-list-item"
import { navigateToModal } from "@utils/navigation"
import { ILocationState, RoutesEnum } from "@routers/types"
import { useHistory, useLocation } from "react-router-dom"

interface IUpgradeDepositListItemProps {
  amount: number
  date: string
}
export const BancorpUpgradeDepositTransactionListItem: React.FC<IUpgradeDepositListItemProps> = ({
  amount,
  date,
}) => {
  const history = useHistory()
  const location = useLocation<ILocationState>()
  const { t } = useTranslation()
  return (
    <TransactionListItem
      counterpartyName={t("dashboard.accountUpgradeTransfer.transaction.title")}
      onClick={() =>
        navigateToModal({
          routeName: RoutesEnum.ACCOUNT_UPGRADE_BALANCE_TRANSFER,
          history,
          currentLocation: location,
        })
      }
      amount={amount}
      date={date}
      type={TransactionTypeEnum.ACH}
      counterpartyLogoSrc={images.northoneCounterparty}
      borderRadius={0}
      counterpartyLogoSize={28}
      subtitle={t("dashboard.accountUpgradeTransfer.transaction.subtext")}
    />
  )
}
