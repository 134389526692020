import React from "react"
import { FlexColumnLayout, Text, QueryResults } from "@northone/ui"
import { useCardPinSet } from "./utils"

const PIN_SET_FORM_HEIGHT = 444

export const CardPinSet: React.FC = () => {
  const { error, loading, refetch, setIsIFrameLoading, isIFrameLoading, pinSetFormUrl, t } =
    useCardPinSet()

  return (
    <>
      <Text tag="h3Serif" sx={{ marginBottom: 21 }} paddingX={36}>
        {t("cards.setPIN.title")}
      </Text>
      <FlexColumnLayout
        sx={{ minHeight: PIN_SET_FORM_HEIGHT, justifyContent: "center", alignItems: "center" }}
      >
        <QueryResults
          error={error}
          loading={loading && isIFrameLoading}
          loadingIconDimension={74}
          errorScreenProps={{
            retry: () => refetch && refetch(),
            size: "large",
            buttonText: t("errors.genericScreen.button"),
            title: t("errors.genericScreen.title"),
          }}
        >
          <iframe
            id="pin-set-frame"
            src={pinSetFormUrl}
            onLoad={() => {
              setIsIFrameLoading(false)
            }}
            frameBorder={"0"}
            style={{
              width: "100%",
              minHeight: PIN_SET_FORM_HEIGHT,
            }}
            scrolling={"no"}
          />
        </QueryResults>
      </FlexColumnLayout>
    </>
  )
}
