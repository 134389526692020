import { INavBarLinkProps } from "@components/composite/nav-bar/nav-bar-link"
import { NavSideBarSection } from "@features/sidebar/shared/side-bar-section"
import i18n from "@i18n/i18n"
import { RoutesEnum } from "@routers/types"
import React from "react"
import { useTranslation } from "react-i18next"

export const AccountSection = () => {
  const { t } = useTranslation()

  const accountSettingsLinks: INavBarLinkProps[] = [
    {
      text: i18n.t("settings.pages.accountStatements.title"),
      to: RoutesEnum.SETTINGS_ACCOUNT_STATEMENTS,
    },
    {
      text: i18n.t("settings.pages.autoTopUp.title"),
      to: RoutesEnum.SETTINGS_AUTO_TOP_UP,
    },
  ]

  return (
    <NavSideBarSection
      eventPrefix="Settings"
      sectionLabel={t("settings.sidebarLabels.accountSettings")}
      links={accountSettingsLinks}
    />
  )
}
