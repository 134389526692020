import React from "react"
import { ErrorScreen } from "@components/composite/error-screen/error-screen"
import { Image } from "rebass"
import { images } from "@assets/images/images"
import { ButtonTypeEnum } from "@components/primitive/button/button"
import { useTranslation } from "react-i18next"

export const SubscriptionsLandingError = ({ refetch }: { refetch: () => void }) => {
  const { t } = useTranslation()
  return (
    <ErrorScreen
      titleTag="body-large-bold"
      size={"large"}
      subtitle={t("subscriptions.landing.error.subtitle")}
      sx={{ justifyContent: "flex-start", alignItems: "center" }}
      image={<Image src={images.icons.warningTriangle} />}
      buttonContainerSx={{ justifyContent: "center" }}
      buttons={[
        {
          children: t("subscriptions.landing.error.cta"),
          type: ButtonTypeEnum.TERTIARY,
          onClick: refetch,
        },
      ]}
    />
  )
}
