import React from "react"
import { Avatar } from "@northone/ui"
import { theme } from "@layouts/theme"

interface IBusinessOwnerAvatarProps {
  name: string
  disabled?: boolean | null
}

/**
 * Extends the Avatar primitive
 */
export const BusinessOwnerAvatar = ({ name, disabled }: IBusinessOwnerAvatarProps) => (
  <Avatar
    name={name}
    dimensions={{
      width: 40,
      borderRadius: 20,
    }}
    colorThemes={[
      {
        backgroundColor: theme.colors.blue10,
        textColor: theme.colors.blue100,
      },
    ]}
    opacity={disabled ? 0.5 : 1}
    textTag="body-bold"
    imageBorderWidth={1.5}
  />
)
