import React, { useCallback } from "react"
import { useDispatch } from "react-redux"
import { TFunction, useTranslation } from "react-i18next"

import { ButtonTypeEnum, InfoBlockModal } from "@northone/ui"

import { cardsActions } from "../redux/actions"
import { pollWithMaxRetries } from "@utils/poll-with-max-retries"
import { useAddVirtualCardMutation } from "@generated/graphql"
import { CARDS } from "../operations.gql"

import { images } from "@assets/images/images"
import { useAppSelector } from "@core/redux/utils"
import { selectCardAccountId } from "../redux/selectors"
import { events } from "@core/analytics/events"
import { Analytics } from "@core/analytics/actions"

const useAddVirtualCardBehavior = () => {
  const dispatch = useDispatch()
  const businessId = useAppSelector((state) => state.activeBusiness.businessId)
  const accountId = useAppSelector(selectCardAccountId)
  const isOpen = useAppSelector((state) => state.cards.addVirtualCardModalOpen)
  const closeModal = useCallback(() => dispatch(cardsActions.setAddVirtualCardModalOpen(false)), [])
  const onCancel = () => {
    Analytics.track(events.cards.virtualCards.cancel)
    closeModal()
  }

  const [addVirtualCard, { loading, error }] = useAddVirtualCardMutation({
    variables: {
      businessId,
      accountId,
    },
    onCompleted: () => {
      pollWithMaxRetries([{ query: CARDS, variables: { businessId } }])
      Analytics.track(events.cards.virtualCards.issued)
      closeModal()
    },
  })

  return {
    addVirtualCard,
    loading,
    error,
    isOpen,
    onCancel,
  }
}

/**
 * TODO:
 * this component is like this so that a link w/ interaction callback
 * can be embedded in the InfoBlockModal ErrorMessage prop. This is not
 * a great way to do this, but it works inside the existing UI component.
 *
 * This should absolutely be refactored at the InfoBlockModal (i.e. in @northone/ui) such that
 * the link action can be passed directly into the InfoBlockModal's embedded ErrorBanner.
 * ErrorBanner can already do this in significantly less code,
 *
 * SeeAlso: view-card-numbers-modal line 60 for example of this taking two lines of code.
 * https://bitbucket.org/northone/northone-web-banking/src/9de10a1a50d793d9c38548ca2f398d58ba76e01c/src/features/cards/manage-card/view-card-numbers-modal.tsx#src/features/cards/manage-card/view-card-numbers-modal.tsx-60]
 */
export const VirtualCardModalErrorBones: React.FC<{
  t: TFunction
}> = ({ t }) => (
  <>
    {t("cards.virtualCards.addVirtualCard.modal.error.body")}{" "}
    <a
      href="#"
      onClick={() => {
        window.Intercom("show")
        return false
      }}
    >
      {t("cards.virtualCards.addVirtualCard.modal.error.contactLink")}
    </a>
  </>
)

export const AddVirtualCardModal: React.FC = () => {
  const { t } = useTranslation()
  const { addVirtualCard, loading, error, onCancel, isOpen } = useAddVirtualCardBehavior()
  // Possibly TODO: null render also be replaced with a Skeleton of the resulting modal, but we do not
  // have a "modal skeleton" component. (?)
  if (addVirtualCard === null) {
    return <></>
  }

  return (
    <InfoBlockModal
      // TODO: implement passing the textProps all the way through to show up on the ErrorBanner of the InfoBlockModal
      // so we can set onLinkClick
      errorMessage={error && ((<VirtualCardModalErrorBones t={t} />) as any)}
      isOpen={isOpen}
      closeModal={onCancel}
      imageSrc={images.illustrations.virtualCard}
      title={t("cards.virtualCards.addVirtualCard.modal.title")}
      subtitle={t("cards.virtualCards.addVirtualCard.modal.body")}
      buttons={[
        {
          children: t("cards.virtualCards.addVirtualCard.modal.button"),
          type: ButtonTypeEnum.PRIMARY_BLUE,
          isLoading: loading,
          onClick: addVirtualCard,
        },
        {
          children: t("buttons.cancel"),
          type: ButtonTypeEnum.SECONDARY,
          onClick: onCancel,
        },
      ]}
    />
  )
}
