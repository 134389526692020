import React from "react"

import { LoanProductSectionBanner } from "./loan-product-section-banner"
import { LoanProductSectionValueProps } from "./loan-product-section-value-props"
import { Text } from "@components/primitive/text/text"
import { FlexColumnLayout } from "@layouts/flex"

type LoanProductSectionProps = {
  productSectionTitle: string
  bannerTitle: string
  description: string
  bulletPointsTitle: string
  bulletPoints: string[]
  valueProps?: { title: string; value: string }[]
  ctaButton?: {
    onClick: () => void
    label: string
    subtext: string
  }
}

export const LoanProductSection: React.FC<LoanProductSectionProps> = ({
  productSectionTitle,
  bannerTitle,
  description,
  bulletPointsTitle,
  bulletPoints,
  valueProps,
  ctaButton,
}) => {
  const loanProductSectionTitleComponent = (
    <Text
      tag={"h4"}
      sx={{
        lineHeight: "26px",
      }}
    >
      {productSectionTitle}
    </Text>
  )

  return (
    <FlexColumnLayout
      className="lending-landing-product-section"
      sx={{ rowGap: "inherit", marginRight: "50px" }}
    >
      {loanProductSectionTitleComponent}
      <LoanProductSectionBanner
        title={bannerTitle}
        description={description}
        bulletPointsTitle={bulletPointsTitle}
        bulletPoints={bulletPoints}
        ctaButton={ctaButton}
      />
      {valueProps ? <LoanProductSectionValueProps valueProps={valueProps} /> : undefined}
    </FlexColumnLayout>
  )
}
