import React, { PropsWithChildren } from "react"
import { FlexColumnLayout } from "@layouts/flex"
import { theme } from "@layouts/theme"
import { Text } from "@components/primitive/text/text"
import { useTranslation } from "react-i18next"
import { FlexRowLayout } from "@northone/ui"

const I18N_LABELS = {
  subscriptionHeaderKeyPath: "subscriptions.billingDetails.header",
}

type BillingDetailsItems = { label: string; value: string }

interface BillingDetailsBoxProps {
  items: BillingDetailsItems[]
  pillComponent?: React.ReactElement
}

export const BillingDetailsBox = ({
  items,
  children,
  pillComponent,
}: PropsWithChildren<BillingDetailsBoxProps>) => {
  const { t } = useTranslation()

  return (
    <FlexColumnLayout sx={{ rowGap: "12px" }}>
      <Text tag="body-large" sx={{ color: theme.colors.ui2 }}>
        {t(I18N_LABELS.subscriptionHeaderKeyPath)}
      </Text>
      <FlexColumnLayout sx={billingDetailsStyle}>
        <FlexRowLayout sx={{ justifyContent: "space-between" }}>
          <FlexColumnLayout sx={{ rowGap: "16px" }}>
            {items.map(({ label, value }) => (
              <BillingDetail label={label} value={value} key={value} />
            ))}
          </FlexColumnLayout>
          {pillComponent}
        </FlexRowLayout>
        {children}
      </FlexColumnLayout>
    </FlexColumnLayout>
  )
}

const BillingDetail = ({ label, value }: { label: string; value: string }) => (
  <FlexColumnLayout>
    <Text tag="body" sx={{ fontSize: "14px" }}>
      {label}
    </Text>
    <Text tag="body" sx={{ fontSize: "12px", color: theme.colors.ui2 }}>
      {value}
    </Text>
  </FlexColumnLayout>
)

const billingDetailsStyle = {
  padding: "24px",
  rowGap: "16px",
  justifyContent: "space-between",
  border: `1px solid ${theme.colors.ui5}`,
  borderRadius: "4px",
}
