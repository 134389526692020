import { ButtonContainer } from "@components/primitive/button-container"
import { Button, ButtonTypeEnum } from "@components/primitive/button/button"
import { Modal, Text, theme } from "@northone/ui"
import React from "react"
import { useTranslation } from "react-i18next"

const i18nKeyPaths = {
  title: "autoTopUps.landing.cancelConfirmationModal.title",
  body: "autoTopUps.landing.cancelConfirmationModal.body",
  confirmButton: "autoTopUps.landing.cancelConfirmationModal.buttons.confirm",
  cancelButton: "autoTopUps.landing.cancelConfirmationModal.buttons.cancel",
}

type CancelConfirmationModalProps = {
  isOpen: boolean
  isTopUpCancelling: boolean
  onContinue: () => Promise<void>
  onCancel: () => Promise<void>
}

export function CancelConfirmationModal({
  isOpen,
  onContinue,
  onCancel,
  isTopUpCancelling,
}: CancelConfirmationModalProps) {
  const { t } = useTranslation()

  const titleText = t(i18nKeyPaths.title)
  const bodyText = t(i18nKeyPaths.body)
  const confirmButtonText = t(i18nKeyPaths.confirmButton)
  const cancelButtonText = t(i18nKeyPaths.cancelButton)

  return (
    <Modal isOpen={isOpen} onOutsideClick={onCancel} sx={{ gap: 4, maxWidth: 500 }}>
      <Text tag="h3Serif">{titleText}</Text>
      <Text tag="body" textColor={theme.colors.ui2}>
        {bodyText}
      </Text>
      <ButtonContainer>
        <Button type={ButtonTypeEnum.SECONDARY} onClick={onCancel}>
          {cancelButtonText}
        </Button>
        <Button
          type={ButtonTypeEnum.PRIMARY_BLUE}
          onClick={onContinue}
          isLoading={isTopUpCancelling}
        >
          {confirmButtonText}
        </Button>
      </ButtonContainer>
    </Modal>
  )
}
