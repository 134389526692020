import React, { useEffect } from "react"
import { SxStyleProp } from "rebass"

import { FlexRowLayout } from "@northone/ui"

import { images } from "@assets/images/images"
import { Banner } from "@components/composite/banner/banner"
import { PilotPill } from "@components/extended/pill/pilot-pill"
import { Text } from "@components/primitive/text/text"
import { theme } from "@layouts/theme"

const titleStyles = { fontFamily: theme.fontFamily.plantin, fontWeight: 600, fontSize: "18px" }
const subtitleStyles = { marginTop: "4px", marginBottom: "16px" }
const pilotPillStyles = { marginLeft: "5px" }

export const TeamBankingBanner = ({
  title,
  subtitle,
  buttons,
  isPillShown,
  bannerHeight,
  onMounted,
  containerSx,
}: {
  title: string
  subtitle?: string
  buttons: JSX.Element[]
  isPillShown?: boolean
  bannerHeight: number
  onMounted?: () => void
  containerSx?: SxStyleProp
}) => {
  useEffect(() => {
    if (onMounted) {
      onMounted()
    }
  }, [])

  return (
    <Banner
      sideBackgroundColor={theme.colors.goldTint1}
      buttons={buttons}
      imageSrc={images.teamBankingCards}
      bannerHeight={bannerHeight}
      containerSx={containerSx}
    >
      <FlexRowLayout sx={{ alignItems: "flex-end" }}>
        <Text tag={"body-large"} sx={titleStyles}>
          {title}
        </Text>
        {isPillShown && <PilotPill sx={pilotPillStyles} />}
      </FlexRowLayout>
      <Text tag={"body-small"} sx={subtitleStyles}>
        {subtitle}
      </Text>
    </Banner>
  )
}
