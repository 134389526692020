import React from "react"
import { useTranslation } from "react-i18next"
import { useRutterLink } from "react-rutter-link"

import { FlexColumnLayout, FlexRowLayout } from "@layouts/flex"
import { images } from "@assets/images/images"
import { Text } from "@components/primitive/text/text"
import { theme } from "@layouts/theme"
import { Button, ButtonTypeEnum } from "@components/primitive/button/button"
import { Panel } from "@components/primitive/panel/panel"
import { config } from "@utils/environment"
import {
  RevenueHubConnectionPlatformEnum,
  useSaveRevenueHubConnectionMutation,
} from "@generated/graphql"
import { useAppSelector } from "@core/redux/utils"
import { selectActiveBusinessID } from "@core/active-business/redux/selectors"
import { RevenueHubPlatformInfo } from "./platforms"
import { Analytics } from "@core/analytics/actions"
import { events } from "@core/analytics/events"
import { ImageLink } from "@components/extended/image/image-link"

interface RevenueHubPlatformConnectProps {
  platformInfo: RevenueHubPlatformInfo
  onConnectionSaved: () => void
}

export const RevenueHubPlatformConnect = ({
  platformInfo,
  onConnectionSaved,
}: RevenueHubPlatformConnectProps) => {
  const { t } = useTranslation()
  const businessId = useAppSelector(selectActiveBusinessID)
  const analyticsData = {
    platformName: platformInfo.name,
    businessId,
  }

  const [saveConnection] = useSaveRevenueHubConnectionMutation({
    onCompleted: () => onConnectionSaved(),
  })

  const { open } = useRutterLink({
    publicKey: config.rutterPublicKey,
    onExit: (error: string) => {
      Analytics.track(events.revenueHub.rutterLinkFailed, { ...analyticsData, error })
    },
    onSuccess: async (publicToken: string) => {
      await saveConnection({
        variables: {
          input: {
            businessId,
            platform: platformInfo.type as RevenueHubConnectionPlatformEnum,
            publicToken,
          },
        },
      })
      Analytics.track(events.revenueHub.rutterLinkCompleted, analyticsData)
    },
  })

  const TITLE = t("revenueHub.platformConnect.title", { platform: platformInfo.name })
  const DESCRIPTION = t("revenueHub.platformConnect.description", { platform: platformInfo.name })

  const handleButtonClick = () => {
    open({ platform: platformInfo.type })
    Analytics.track(events.revenueHub.rutterLinkInitiated, analyticsData)
  }

  return (
    <Panel sx={{ padding: 24 }}>
      <FlexColumnLayout sx={{ gap: 3 }}>
        <FlexRowLayout
          alignItems="center"
          sx={{ gap: 3, borderBottom: `1px solid ${theme.colors.ui4}` }}
        >
          <FlexRowLayout alignItems="center" sx={{ gap: 3, paddingBottom: 16 }}>
            <ImageLink
              url={platformInfo.siteUrl}
              imgSx={{ width: 36, height: 36 }}
              imgSrc={platformInfo.imageSrc}
            />
            <Text tag="body-large-bold">{TITLE}</Text>
          </FlexRowLayout>
        </FlexRowLayout>
        <FlexColumnLayout alignItems="start" sx={{ gap: 3 }}>
          <Text tag="body-small">{DESCRIPTION}</Text>
          <Button
            isLoading={false}
            type={ButtonTypeEnum.PRIMARY_BLUE}
            onClick={handleButtonClick}
            sx={{ width: "100%" }}
            iconSrc={images.greyCross}
            textTag="body-small-bold"
          >
            {t("revenueHub.platformConnect.buttonLabel")}
          </Button>
        </FlexColumnLayout>
      </FlexColumnLayout>
    </Panel>
  )
}
