import gql from "graphql-tag"

export const SUB_ACCOUNT_DETAILS = gql`
  query subAccountDetails($businessId: ID!, $subAccountId: ID!) {
    business(businessId: $businessId) {
      id
      bankAccount {
        id
        subAccount(subAccountId: $subAccountId) {
          id
          name
          balance
          isDefault
          percentRule {
            businessId
            percent
            availableUpdateLimit
          }
        }
      }
    }
  }
`

export const SUB_ACCOUNTS_QUERY = gql`
  query subAccounts($businessId: ID!) {
    business(businessId: $businessId) {
      id
      bankAccount {
        id
        availableBalance
        subAccounts {
          id
          name
          balance
          isDefault
          percentRule {
            percent
          }
        }
      }
    }
  }
`
