import { InvoiceAccountPayablePaymentMethod } from "@generated/graphql"
import { TransactionFees } from "@typedefs/types"
import { STANDARD_ACH_FEE } from "@utils/constants"
import { TPaymentMethod } from "./invoice-modal/payment-details/form"

export const getTransactionFeeForPaymentMethod = (
  transactionFeeData: TransactionFees,
  paymentMethod?: InvoiceAccountPayablePaymentMethod | TPaymentMethod,
) => {
  switch (paymentMethod) {
    case "ach_sameday":
      return transactionFeeData.sameDayAch
    case "mailed_check":
      return transactionFeeData.mailedCheck
    case "wire":
      return transactionFeeData.domesticWire
    case "ach":
      return STANDARD_ACH_FEE
    case "bill_pay":
      return transactionFeeData.billPay
    default:
      return null
  }
}
