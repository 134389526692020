import { ApolloError } from "@apollo/client/core"
import { ErrorCodeEnum } from "../generated/graphql"
import { get } from "lodash-es"

export const hasGQLErrorType = (error: ApolloError, type: ErrorCodeEnum) => {
  const errorCodes = error.graphQLErrors.map((graphQLError) =>
    get(graphQLError, "extensions.code"),
  ) as ErrorCodeEnum[]
  return errorCodes.includes(type)
}

export const getGQLErrorMessage = (error: ApolloError) => {
  return error.graphQLErrors[0] ? error.graphQLErrors[0].message : ""
}

/**
 * @description Type guard that returns whether
 * an error is an ApolloError.
 *
 * This type guard is based on the Apollo client
 * documentation on ApolloError
 * @see https://www.apollographql.com/docs/react/data/queries/#error
 */
export const isApolloError = (e: unknown): e is ApolloError => {
  if (!e) {
    return false
  }

  if (!(e instanceof Error)) {
    return false
  }

  if (e.name !== "ApolloError") {
    return false
  }

  if ("graphQLErrors" in e) {
    return true
  }

  if ("networkError" in e) {
    return true
  }

  return false
}
