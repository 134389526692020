import React, { useRef, useEffect } from "react"
import { useRouteMatch, useHistory, useLocation } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useAppSelector } from "@core/redux/utils"
import { RoutesEnum, ILocationState } from "@routers/types"
import { FlowModal } from "@components/extended/overlay/flow-modal"
import { PreviewSubAccountPanel } from "@components/composite/preview-sub-account-panel/preview-sub-account-panel"
import { accountsActions } from "@features/accounts/redux/actions"
import { selectCreateSubAccountState } from "@features/accounts/redux/selectors"
import { navigateFromModal } from "@utils/navigation"
import {
  CREATE_SUB_ACCOUNT_ROUTES,
  createSubAccountProgressItems,
} from "./create-sub-accounts.router"

export const CreateSubAccountModal: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const ref = useRef<HTMLDivElement>(null)
  const location = useLocation<ILocationState>()
  const isRouteMatch = useRouteMatch({ path: RoutesEnum.CREATE_SUB_ACCOUNT })
  const { screenHistory, name, initialBalance, percentage, scheduledAmount, scheduledFrequency } =
    useAppSelector(selectCreateSubAccountState)
  const currentScreen = CREATE_SUB_ACCOUNT_ROUTES[screenHistory[screenHistory.length - 1]]

  useEffect(() => {
    if (ref && ref.current) {
      ref.current.scrollTo({ top: 0 })
    }
  }, [currentScreen])

  return (
    <FlowModal
      scrollContainerRef={ref}
      layout={currentScreen.layout}
      sidepanel={
        <PreviewSubAccountPanel
          {...{ name, initialBalance, percentage, scheduledAmount, scheduledFrequency }}
        />
      }
      isOpen={Boolean(isRouteMatch?.isExact)}
      onOutsideClick={() => {
        dispatch(accountsActions.clearCreateSubAccountState())
        navigateFromModal({ defaultBackRoute: RoutesEnum.ACCOUNTS_OVERVIEW, history, location })
        history.push(RoutesEnum.ACCOUNTS_OVERVIEW)
      }}
      progressBar={{
        currentItem: currentScreen.progressItem,
        progressItems: createSubAccountProgressItems,
      }}
    >
      {currentScreen.component}
    </FlowModal>
  )
}
