import React from "react"
import { Text } from "@northone/ui"
import { Button, ButtonTypeEnum } from "@components/primitive/button/button"
import { FlexColumnLayout } from "@layouts/flex"
import { theme } from "@layouts/theme"
import { Image } from "rebass"
import { images } from "@assets/images/images"
import { useTranslation } from "react-i18next"

type EmptyStateProps = {
  onCreate: () => void
  loading: boolean
}

export function EmptyState({ onCreate, loading }: EmptyStateProps) {
  const { t } = useTranslation()

  function handleAddNewItem() {
    onCreate()
  }
  return (
    <FlexColumnLayout
      sx={{
        alignItems: "center",
        backgroundColor: theme.colors.ui6,
        textAlign: "center",
        height: "250px",
        flexGrow: 1,
        marginTop: 20,
      }}
    >
      <FlexColumnLayout
        sx={{
          alignItems: "center",
          justifyItems: "center",
          margin: "auto",
          maxWidth: "500px",
          gap: "3",
        }}
      >
        <Image src={images.icons.fundThirdParty} height={35} width={35} />
        <Text tag={"body-large-bold"} color={theme.colors.ui2}>
          {t("settings.pages.linkedAccount.empty.title")}
        </Text>
        <Text tag="body" textColor={theme.colors.ui2}>
          {t("settings.pages.linkedAccount.empty.subtitle")}
        </Text>
        <Button
          type={ButtonTypeEnum.PRIMARY_BLUE}
          onClick={handleAddNewItem}
          isLoading={loading}
          disabled={loading}
        >
          {t("settings.pages.linkedAccount.create.button")}
        </Button>
      </FlexColumnLayout>
    </FlexColumnLayout>
  )
}
