import React from "react"
import { useTranslation } from "react-i18next"
import { AddFundsACHWireThirdParty } from "@features/add-funds/shared/ach-wire-third-party/form"
import { useCapabilitiesAndAvailableBalanceQuery } from "@generated/graphql"
import { LoadingPanel } from "@components/extended/panel/loading-panel"
import { ErrorPanel } from "@components/extended/panel/error-panel"
import { useAppSelector } from "@core/redux/utils"
import { selectActiveBusinessID } from "@core/active-business/redux/selectors"
import { getFeeDisplay } from "@features/move-money/utils/fee.utils"

export const AddFundsWireForm: React.FC = () => {
  const { t } = useTranslation()
  const businessId = useAppSelector(selectActiveBusinessID)
  const {
    data: capabilitiesData,
    error: capabilitiesError,
    loading: capabilitiesLoading,
    refetch: capabilitiesRefetch,
  } = useCapabilitiesAndAvailableBalanceQuery({
    variables: { businessId },
    notifyOnNetworkStatusChange: true,
  })
  const wireFee = getFeeDisplay(
    capabilitiesData?.business?.bankAccounts[0].capabilities?.domesticWire.fee,
  )
  if (capabilitiesLoading) {
    return <LoadingPanel iconDimension={40} />
  }
  if (capabilitiesError || !wireFee) {
    return <ErrorPanel retry={capabilitiesRefetch} />
  }
  return (
    <AddFundsACHWireThirdParty
      title={t("topUp.addFunds.wire.title")}
      subtitle={t("topUp.addFunds.wire.subtitle", { wireFee })}
    />
  )
}
