import React from "react"
import { Helmet } from "react-helmet"
import { Box } from "rebass"

import { FadeInWrapper } from "@components/animations/fade-in/fade-in-wrapper"
import { useWindowDimensions } from "@utils/hooks"

import { Logo } from "./logo"
import { theme } from "./theme"

interface BaseLayoutProps {
  children?: React.ReactNode
  rightAside?: React.ReactNode
  childrenGridColumn?: string
  rightAsideGridColumn?: string
  pageTitle?: string
  paddingBottom?: number
  withLogoFooter?: boolean
}

export const RIGHT_ASIDE_CONTAINER_ID = "right-aside-container"

export const BaseLayout = ({
  children,
  rightAside,
  childrenGridColumn,
  rightAsideGridColumn,
  pageTitle,
  paddingBottom,
  withLogoFooter,
}: BaseLayoutProps) => {
  const { height } = useWindowDimensions()

  return (
    <>
      {pageTitle && (
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
      )}
      <Box
        sx={{
          paddingY: "20px",
          paddingBottom: paddingBottom,
          gridColumn: childrenGridColumn ? childrenGridColumn : rightAside ? "4/10" : "5/11",
          gridRow: "1/2",
        }}
      >
        <div style={{ height: "100%" }}>
          <Box
            sx={{
              marginX: -theme.spacing.listItemPadding,
              paddingX: theme.spacing.listItemPadding,
              height: "100%",
            }}
          >
            {children}
            {withLogoFooter && <Logo sx={{ marginTop: 80 }} />}
          </Box>
        </div>
      </Box>

      {rightAside && (
        <Box
          sx={{
            gridColumn: rightAsideGridColumn || "10/13",
            gridRow: "1/2",
          }}
        >
          <Box
            id={RIGHT_ASIDE_CONTAINER_ID}
            sx={{
              position: "sticky",
              top: theme.heights.navBar,
              height: rightAside ? height - theme.heights.navBar : undefined,
              overflow: "auto",
              paddingY: theme.spacing.sidebar,
              zIndex: rightAside ? undefined : -1,
              marginX: -12,
              paddingX: 12,
            }}
          >
            <FadeInWrapper>{rightAside}</FadeInWrapper>
          </Box>
        </Box>
      )}
    </>
  )
}
