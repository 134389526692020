import React from "react"
import { useTranslation } from "react-i18next"
import LoadingComponent from "../shared/loading-spinner"
import { InfoBlock } from "@components/composite/info-block/info-block"

export const PinSetLoading: React.FC = () => {
  const { t } = useTranslation()

  return (
    <InfoBlock
      title={t("cards.pinNotSet.settingPin")}
      subHeading={t("cards.pinNotSet.almostReadyToUse")}
      subtitle={<LoadingComponent marginTop={48} />}
      buttons={[]}
      centered
      containerSx={{ paddingTop: 64, paddingBottom: 64 }}
    />
  )
}
