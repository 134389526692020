import React, { useRef } from "react"
import { useHistory, useLocation, useRouteMatch } from "react-router-dom"
import { ILocationState, RoutesEnum } from "@routers/types"
import { FlowModal } from "@components/extended/overlay/flow-modal"
import { navigateFromModal } from "@utils/navigation"
import { useTranslation } from "react-i18next"
import { ReactivatePlanScreen } from "./reactivate-plan-screen"

export const SubscriptionReactivateModal = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation<ILocationState>()

  const closeModal = () => {
    navigateFromModal({ defaultBackRoute: RoutesEnum.SUBSCRIPTION_REACTIVATE, history, location })
  }

  const isRouteMatch = useRouteMatch({ path: RoutesEnum.SUBSCRIPTION_REACTIVATE })
  const scrollContainerRef = useRef<HTMLDivElement>(null)
  const isOpen = Boolean(isRouteMatch?.isExact)

  return (
    <FlowModal
      pageTitle={t("subscriptions.reactivate.pageTitle")}
      scrollContainerRef={scrollContainerRef}
      containerStyle={{ height: "90vh", paddingY: "50px" }}
      layout={"centered"}
      isOpen={isOpen}
      onExitButtonClick={closeModal}
    >
      <ReactivatePlanScreen {...{ closeModal }} />
    </FlowModal>
  )
}
