import { createAction, TActionsUnion } from "@core/redux/utils"
import { TMoveMoneyScreenNames } from "@features/move-money/router/move-money"
import { IGenericRecipient, IAddress, initialRecipient } from "./state"
import { ISubAccount } from "@typedefs/types"
import { TransactionTypeEnum } from "@generated/graphql"

export enum MoveMoneyActionEnum {
  RESET_SCREEN_HISTORY = "RESET_SCREEN_HISTORY",
  SET_PAYMENT_TYPE = "MOVE_MONEY_SET_PAYMENT_TYPE",
  SET_RECIPIENT = "MOVE_MONEY_SET_RECIPIENT",
  SET_EDITING_RECIPIENT = "MOVE_MONEY_SET_EDITING_RECIPIENT",
  SET_EDITING_RECIPIENT_ADDRESS = "MOVE_MONEY_SET_EDITING_RECIPIENT_ADDRESS",
  SET_AMOUNT = "MOVE_MONEY_SET_AMOUNT",
  SET_SUBACCOUNT = "MOVE_MONEY_SET_SUB_ACCOUNT",
  SET_WIRE_PURPOSE = "MOVE_MONEY_SET_WIRE_PURPOSE",
  SET_NON_ACTIVATED_CARD_IDS = "MOVE_MONEY_SET_NON_ACTIVATED_CARD_IDS",
  DELETE_DRAFT = "MOVE_MONEY_DELETE_DOMESTIC_WIRE_DRAFT",
  NAVIGATE_NEXT = "MOVE_MONEY_NAVIGATE_NEXT",
  NAVIGATE_BACK = "MOVE_MONEY_NAVIGATE_BACK",
  CLEAR_RECIPIENT = "MOVE_MONEY_CLEAR_RECIPIENT",
  CLEAR_EDITING_RECIPIENT = "MOVE_MONEY_CLEAR_EDITING_RECIPIENT",
  SET_WIRE_SEND_COMPLETED = "SET_WIRE_SEND_COMPLETED",
  SET_WIRE_ENABLED = "SET_WIRE_ENABLED",
}

export const moveMoneyActions = {
  resetScreenHistory: () => createAction(MoveMoneyActionEnum.RESET_SCREEN_HISTORY),
  setRecipient: (recipient: IGenericRecipient | null) =>
    createAction(MoveMoneyActionEnum.SET_RECIPIENT, recipient || initialRecipient),
  setEditingRecipient: (recipient: IGenericRecipient | null) =>
    createAction(MoveMoneyActionEnum.SET_EDITING_RECIPIENT, recipient || initialRecipient),
  setEditingRecipientAddress: (address: IAddress | null) =>
    createAction(MoveMoneyActionEnum.SET_EDITING_RECIPIENT_ADDRESS, address),
  setPaymentType: (paymentType: TransactionTypeEnum | undefined) =>
    createAction(MoveMoneyActionEnum.SET_PAYMENT_TYPE, paymentType),
  setAmount: (amount: string) => createAction(MoveMoneyActionEnum.SET_AMOUNT, amount),
  setSubAccount: (subAccount: ISubAccount) =>
    createAction(MoveMoneyActionEnum.SET_SUBACCOUNT, subAccount),
  setWirePurpose: (purpose: string) => createAction(MoveMoneyActionEnum.SET_WIRE_PURPOSE, purpose),
  setNonActivatedCardIds: (nonActivatedCardIds: string[] | null) =>
    createAction(MoveMoneyActionEnum.SET_NON_ACTIVATED_CARD_IDS, nonActivatedCardIds),
  navigateNext: (nextScreen: TMoveMoneyScreenNames) =>
    createAction(MoveMoneyActionEnum.NAVIGATE_NEXT, nextScreen),
  navigateBack: () => createAction(MoveMoneyActionEnum.NAVIGATE_BACK),
  deleteDraft: () => createAction(MoveMoneyActionEnum.DELETE_DRAFT),
  clearRecipient: () => createAction(MoveMoneyActionEnum.CLEAR_RECIPIENT),
  clearEditingRecipient: () => createAction(MoveMoneyActionEnum.CLEAR_EDITING_RECIPIENT),
  setWireSendCompleted: (status: boolean) =>
    createAction(MoveMoneyActionEnum.SET_WIRE_SEND_COMPLETED, status),
  setWireEnabled: (enabled: boolean) => createAction(MoveMoneyActionEnum.SET_WIRE_ENABLED, enabled),
}

export type TMoveMoneyActions = TActionsUnion<typeof moveMoneyActions>
