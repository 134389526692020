import React, { useState, useRef, useEffect } from "react"
import { useRouteMatch, useHistory, useLocation } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useAppSelector } from "@core/redux/utils"
import { RoutesEnum, ILocationState } from "@routers/types"
import { FlowModal } from "@components/extended/overlay/flow-modal"
import { PreviewTransactionPanel } from "@components/composite/transaction-panel/preview-transaction-panel"
import { selectAccountsSubAccountTransfer } from "@features/accounts/redux/selectors"
import { accountsActions } from "@features/accounts/redux/actions"
import { TransactionTypeEnum } from "@generated/graphql"
import { navigateFromModal } from "@utils/navigation"
import {
  subAccountTransferProgressItems,
  SUB_ACCOUNT_TRANSFER_ROUTES,
  TSubAccountTransferScreenName,
} from "./sub-account-transfer.router"
import { TransferDiscardModal } from "./discard-modal/transfer-discard-modal"

export const SubAccountTransferModal: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation<ILocationState>()
  const ref = useRef<HTMLDivElement>(null)
  const [discardModalOpen, setDiscardModalOpen] = useState(false)
  const isRouteMatch = useRouteMatch({ path: RoutesEnum.SUB_ACCOUNT_TRANSFER })
  const { screenHistory, sourceAccount, destinationAccount, amount } = useAppSelector(
    selectAccountsSubAccountTransfer,
  )
  const currentScreen = SUB_ACCOUNT_TRANSFER_ROUTES[screenHistory[screenHistory.length - 1]]

  const dismissibleScreens: TSubAccountTransferScreenName[] = [
    "SUB_ACCOUNT_TRANSFER_SOURCE_ACCOUNT",
    "SUB_ACCOUNT_TRANSFER_SENT",
  ]

  useEffect(() => {
    if (ref && ref.current) {
      ref.current.scrollTo({ top: 0 })
    }
  }, [currentScreen])
  return (
    <React.Fragment>
      <TransferDiscardModal
        isOpen={discardModalOpen}
        closeModal={() => setDiscardModalOpen(false)}
      />
      <FlowModal
        scrollContainerRef={ref}
        layout={currentScreen.layout}
        sidepanel={
          <PreviewTransactionPanel
            amount={amount}
            counterpartyName={destinationAccount.name}
            transactionType={TransactionTypeEnum.SUB_ACCOUNT_TRANSFER}
            subAccountName={sourceAccount.name}
          />
        }
        isOpen={Boolean(isRouteMatch?.isExact)}
        onOutsideClick={() => {
          if (dismissibleScreens.includes(screenHistory[screenHistory.length - 1])) {
            dispatch(accountsActions.subAccountTransferDiscard())
            navigateFromModal({
              defaultBackRoute: RoutesEnum.ACCOUNTS_OVERVIEW,
              history,
              location,
            })
          } else {
            setDiscardModalOpen(true)
          }
        }}
        progressBar={{
          currentItem: currentScreen.progressItem,
          progressItems: subAccountTransferProgressItems,
        }}
      >
        {currentScreen.component}
      </FlowModal>
    </React.Fragment>
  )
}
