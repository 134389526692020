import { useEffect, useState } from "react"
import { ISupportCoverage } from "./types"

import { getSupportSchedule } from "@utils/prismic/shared/support"
import { convertTo12HrFormat, getDateTimeInEasternTimezone, isInWorkingHours } from "@utils/dates"
import { useTranslation } from "react-i18next"

export const LIVE_CHAT_AVAILABLE_I18N = "support.overview.liveChat.available"
export const LIVE_CHAT_AVAILABLE_BTN_TEXT_I18N = "support.overview.talkToUs"
export const LIVE_CHAT_UNAVAILABLE_I18N = "support.overview.liveChat.unavailable"
export const LIVE_CHAT_UNAVAILABLE_BTN_TEXT_I18N = "support.overview.leaveMessage"

const getScheduleForToday = (data: ISupportCoverage[]) => {
  const today = getDateTimeInEasternTimezone()
  return data.find((schedule) => schedule.week_day === today.weekday)
}

export const useGetChatDetails = () => {
  const [chatTitle, setChatTitle] = useState<string | undefined>()
  const [chatButtonText, setChatButtonText] = useState<string | undefined>()
  const [isChatOffline, setIsChatOffline] = useState(false)
  const [isLoadingChatTitle, setIsLoadingChatTitle] = useState(true)

  const { t } = useTranslation()

  const setUnavailableText = () => {
    setChatTitle(t(LIVE_CHAT_UNAVAILABLE_I18N))
    setChatButtonText(t(LIVE_CHAT_UNAVAILABLE_BTN_TEXT_I18N))
    setIsChatOffline(true)
  }

  const setAvailableText = (hours: number, zone: string) => {
    setChatTitle(
      t(LIVE_CHAT_AVAILABLE_I18N, {
        time: convertTo12HrFormat(hours),
        timezone: zone,
      }),
    )
    setChatButtonText(t(LIVE_CHAT_AVAILABLE_BTN_TEXT_I18N))
  }

  useEffect(() => {
    getSupportSchedule()
      .then((schedule) => {
        setIsLoadingChatTitle(false)

        if (!schedule) return

        const hoursForToday = getScheduleForToday(schedule)
        if (!hoursForToday) return

        const now = getDateTimeInEasternTimezone()

        if (!isInWorkingHours(now, hoursForToday)) {
          setUnavailableText()
          return
        }

        setAvailableText(hoursForToday.end_hour, hoursForToday.timezone)
      })
      .catch(() => {
        setIsLoadingChatTitle(false)
      })
  }, [])

  return {
    isLoadingChatTitle,
    isChatOffline,
    chatTitle,
    chatButtonText,
  }
}
