import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router"
import { useTranslation } from "react-i18next"
import { ButtonTypeEnum, IButtonProps } from "@northone/ui"
import i18n from "@i18n/i18n"
import { RoutesEnum } from "@routers/types"
import { events } from "@core/analytics/events"
import { useAppSelector } from "@core/redux/utils"
import { Analytics } from "@core/analytics/actions"
import { selectActiveBusinessID } from "@core/active-business/redux/selectors"
import { appActions } from "@core/redux/app/actions"
import { pollWithMaxRetries } from "@utils/poll-with-max-retries"
import { dashboardActions } from "@features/dashboard/redux/actions"
import { images } from "@assets/images/images"
import {
  TransactionTypeEnum,
  CardPinSetStatusEnum,
  CardProductType,
  useCardUnfreezeMutation,
} from "@generated/graphql"

import { CARD } from "../operations.gql"
import { cardsActions } from "../redux/actions"
import { CardSetupScreensEnum } from "../card-setup-modal/card-setup-router"
import { CardStatusEnum } from "./transformers"

interface ICardSummaryUiElements {
  buttonsProps: IButtonProps[]
}

const PIN_NOT_SET_INTL_PATH = "cards.pinNotSet"
const INACTIVE_CARD_INTL_PATH = "cards.inactiveCard"
const ACTIVATED_CARD_INTL_PATH = "cards.activeCard"
const DISABLED_CARD_INTL_PATH = "cards.disabledCard"

export const useCardSummaryUiElements = ({
  cardId,
  cardStatus,
  productType,
  isFrozen,
  isDisabled,
  last4,
}: {
  cardId: string
  cardStatus: CardStatusEnum
  productType?: CardProductType | null
  isFrozen: boolean
  isDisabled: boolean
  last4: string
}): ICardSummaryUiElements => {
  const history = useHistory()
  const dispatch = useDispatch()
  const businessId = useAppSelector(selectActiveBusinessID)
  const accountId = useAppSelector((state) => state.cards.cardAccountId)
  const { t } = useTranslation()

  const isVirtualCard = productType === CardProductType.VIRTUAL
  const fullyActivated = cardStatus === CardStatusEnum.FULLY_ACTIVATED
  const pendingPinSet = cardStatus === CardStatusEnum.PENDING_PIN_SET

  const [unfreezeCard] = useCardUnfreezeMutation({
    variables: { cardId, accountId, businessId },
    onCompleted: () => {
      dispatch(
        appActions.setNotificationMessage(t("cards.activeCard.unfreezeSuccessToast", { last4 })),
      )
      pollWithMaxRetries([{ query: CARD, variables: { businessId, cardId } }])
      dispatch(cardsActions.setCurrentCardIsLoading(false))
    },

    onError: () => {
      dispatch(
        cardsActions.setCurrentCardErrorMessage(t(`${ACTIVATED_CARD_INTL_PATH}.unfreezeError`)),
      )
    },
  })

  const getCardSummaryButtons = () => {
    // Button handlers
    const activateCardClickHandler = () => {
      Analytics.track(events.cards.notActivated.activate)
      dispatch(cardsActions.setCurrentCardId(cardId))
      dispatch(cardsActions.setCardSetupModalScreen(CardSetupScreensEnum.CARD_SETUP_ACTIVATE))
      dispatch(cardsActions.setCardActivationModalOpen(true))
    }

    const viewTransactionsClickHandler = (event?: string) => {
      Analytics.track(
        isVirtualCard
          ? events.cards.virtualCards.landing.viewTransactions
          : events.cards.landing.viewTransactions,
      )
      dispatch(dashboardActions.setIsFiltering(true))
      dispatch(dashboardActions.setTransactionTypes([TransactionTypeEnum.CARD_PURCHASE]))
      dispatch(dashboardActions.setCardIdFilter([cardId]))
      dispatch(
        dashboardActions.setFilters({
          transactionTypes: [TransactionTypeEnum.CARD_PURCHASE],
          cardIds: [cardId],
        }),
      )

      if (event) {
        Analytics.track(event)
      }
      history.push(RoutesEnum.DASHBOARD)
    }

    const unfreezeCardClickHandler = () => {
      dispatch(cardsActions.setCurrentCardIsLoading(true))
      dispatch(cardsActions.setCurrentCardId(cardId))
      dispatch(cardsActions.setCurrentCardErrorMessage(""))
      unfreezeCard()
      Analytics.track(
        isVirtualCard
          ? events.cards.virtualCards.modal.unfreezeConfirm
          : events.cards.frozen.unfreeze,
      )
    }

    const setPinClickHandler = () => {
      dispatch(cardsActions.setCurrentCardId(cardId))
      dispatch(cardsActions.setCardSetupModalScreen(CardSetupScreensEnum.CARD_SETUP_PIN_SET))
      dispatch(cardsActions.setCardActivationModalOpen(true))
    }

    const primaryButtonAttributes = {
      type: ButtonTypeEnum.PRIMARY_BLUE,
      sx: { height: "26px", fontSize: "10px", paddingX: "8px" },
      textTag: "h5" as const,
    }

    if (isDisabled) {
      return [
        {
          ...primaryButtonAttributes,
          children: i18n.t(`${DISABLED_CARD_INTL_PATH}.button`),
          onClick: () => {
            window.Intercom("show")
            Analytics.track(events.cards.locked)
          },
        },
      ]
    }

    if (isFrozen) {
      return [
        {
          ...primaryButtonAttributes,
          children: i18n.t(`${ACTIVATED_CARD_INTL_PATH}.viewTransactions`),
          onClick: () => viewTransactionsClickHandler(events.cards.frozen.viewTransaction),
        },
        {
          children: i18n.t(`${ACTIVATED_CARD_INTL_PATH}.unfreezeCard`),
          type: ButtonTypeEnum.TERTIARY_SHORT,
          onClick: unfreezeCardClickHandler,
          iconSrc: images.unfreezeIcon,
        },
      ]
    }

    if (fullyActivated) {
      return [
        {
          ...primaryButtonAttributes,
          children: i18n.t(`${ACTIVATED_CARD_INTL_PATH}.viewTransactions`).toUpperCase(),
          onClick: () => viewTransactionsClickHandler(),
        },
      ]
    }

    if (pendingPinSet) {
      return [
        {
          ...primaryButtonAttributes,
          children: i18n.t(`${PIN_NOT_SET_INTL_PATH}.setPin`),
          onClick: setPinClickHandler,
        },
      ]
    }

    return [
      {
        ...primaryButtonAttributes,
        children: i18n.t(`${INACTIVE_CARD_INTL_PATH}.activateCard`),
        onClick: activateCardClickHandler,
      },
    ]
  }

  return {
    buttonsProps: getCardSummaryButtons(),
  }
}

export const useCardPinSetPolling = (
  cardId: string,
  pinSetStatus?: CardPinSetStatusEnum | null,
) => {
  const dispatch = useDispatch()

  const businessId = useAppSelector((state) => state.activeBusiness.businessId)
  const currentCardId = useAppSelector((state) => state.cards.currentCardId)
  const pinSetPending = useAppSelector((state) => state.cards.pinSetPending)

  /**
   * When we set a pin, we send a request to Galileo via their iframe.
   * We only get a response back that indicates the request was received.
   * We need to wait for Galileo to publish events, push them into our store,
   * and request them via the cards service.
   * In pin-set/utils there is a function that sets pinSetPending to true when
   * we receive a success response from the Galileo.
   * If pinSetPending is true we poll the cards service until the pinSetStatus
   * changes coming from Galileo. We keep polling until we get a success (SET)
   * or an ERROR.
   */
  useEffect(() => {
    if (cardId === currentCardId) {
      if (
        pinSetPending &&
        pinSetStatus !== CardPinSetStatusEnum.SET &&
        pinSetStatus !== CardPinSetStatusEnum.ERROR
      ) {
        pollWithMaxRetries([{ query: CARD, variables: { businessId, cardId } }], 120)
      }

      if (
        pinSetStatus === CardPinSetStatusEnum.SET ||
        pinSetStatus === CardPinSetStatusEnum.ERROR
      ) {
        dispatch(cardsActions.setPinSetPending(false))
      }
    }
  }, [pinSetPending, pinSetStatus])
}
