export const cards = {
  virtualCards: {
    addVirtualCard: {
      panel: {
        title: "Add a virtual card",
        subtitle: "See your card numbers in your account",
        button: "Add card",
      },
      modal: {
        title: "Add virtual card?",
        body: "A virtual card allows you to see your card numbers in your account so you can pay without a physical card.",
        button: "Add card",
        error: {
          body: "There was a problem adding your virtual card. Please try again. If that doesn't work,",
          contactLink: "contact Customer Care",
        },
      },
    },
    securityModal: {
      title:
        "Contact our Customer Care team to report a security issue with this virtual debit card",
      subtitle:
        "If there are any unauthorized transactions on this card, the team will help you start a dispute process.",
      contactButton: "Contact Customer Care",
    },
  },
  landing: {
    title: "Cards",
    subtitle: "Manage your NorthOne debit cards.",
    physicalCard: "*Physical debit card* (•• {{last4}})",
    virtualCard: "*Virtual debit card* (•• {{last4}})",
    tabs: {
      myCards: "My cards",
      otherCards: "Other cards",
    },
    loadingError: "We had an issue loading your cards. Reload the page to try again.",
  },
  inactiveCard: {
    activateCard: "Activate card",
    haventReceived: "I haven't received it yet",
    onItsWay: "ON ITS WAY",
    shippedOn: "Shipped on {{shippedDate}}",
  },
  activeCard: {
    viewTransactions: "View transactions",
    unfreezeCard: "Unfreeze card",
    active: "ACTIVE",
    freeze: "FREEZE CARD",
    reportLost: "REPORT LOST OR STOLEN",
    changePin: "CHANGE PIN",
    viewCardNumbers: "VIEW CARD NUMBERS",
    reportSecurityIssue: "REPORT SECURITY ISSUE",
    unfreezeError:
      "There was a technical problem. Try unfreezing the card again, or __contact Customer Care.__",
    frozen: "FROZEN",
    unfreezeSuccessToast: "Card ending in {{last4}} is ready to be used again",
  },
  disabledCard: {
    locked: "LOCKED",
    message:
      "This card has been temporarily locked. Our team will reach out with more information.",
    button: "Contact Customer Care",
  },
  pinNotSet: {
    setPin: "Set PIN",
    settingPin: "Setting Your PIN ...",
    almostReadyToUse: "Your debit card is almost ready to use.",
    contactCS: "Contact Customer Care",
    pinSetError: "There was a problem setting your PIN. Try again.",
  },
  virtualCard: {
    viewCardNumbers: "Virtual card numbers",
    viewCardNumbersError:
      "There was a problem viewing your virtual card numbers. Please try again. If that doesn’t work, __contact Customer Care.__",
  },
  freeze: {
    title: "Freeze card?",
    subtitle:
      "If you freeze this card, no new transactions can be made. Pending and recurring transactions made by this card will still be processed. You can unfreeze this card at any time.",
    button: "Freeze card",
    error:
      "There was a technical problem. Try freezing the card again, or __contact Customer Care.__",
  },
  freezeCS: {
    title: "Contact our Customer Care team to freeze or unfreeze your debit card",
    subtitle:
      "If there are any unauthorized transactions on your card, the team will help you start a dispute process.",
    subtitle_frozen: "Unfreezing a card will allow the cardholder to make transactions again.",
  },
  stolenCard: {
    title: "Contact our Customer Care team to report this debit card lost or stolen",
    subtitle:
      "If there are any unauthorized transactions on this card, the team will help you start a dispute process.",
  },
  listCards: {
    cardFrozen: "CARD FROZEN",
    freezeCard: "Freeze card",
    unfreezeCard: "Unfreeze card",
    reportStolen: "Report stolen",
    unfreeze: "Unfreeze",
    freeze: "Freeze",
    activateCard: "ACTIVATE CARD",
    changePIN: "Change PIN",
  },
  activateCard: {
    title: "Call 1-866-331-3085 to activate your debit card",
    subtitle: "To get started, you'll need:",
    listCards: {
      debitCard: "Your NorthOne debit card",
      ssn: "Your Social Security Number (SSN)",
    },
    cardNotArrived: {
      title:
        "NorthOne debit cards can take up to {{cardShippingTime}} business days to arrive in the mail",
      subtitle:
        "If you haven’t received your card after {{cardShippingTime}} business days, our Customer Care team can help you out.",
      button: {
        primary: "Contact Customer Care",
        secondary: "Back",
      },
    },
  },
  changePIN: {
    title: "Call 1-866-331-3085 to change your PIN",
    subtitle: "You'll need:",
    listCards: {
      debitCard: "Your NorthOne debit card",
      ssn: "Your Social Security Number (SSN)",
      currentPin: "Your current PIN",
    },
    bodyText: "Forgot PIN?",
    bodyTextLink: "Contact Customer Care",
  },
  setPIN: {
    title: "Set your card's PIN",
    success: "You’re all set!",
    subtitle:
      "By activating your card, you unlocked all the tools you need to simplify your business banking.",
    benefit1: "Use your card online or in store",
    benefit2: `Send ACH and domestic wire¹ transfers`,
    benefit3: `Deposit checks² through the NorthOne App`,
    benefit4: "Earn rewards when you make a referral",
    condition1:
      "¹ Domestic wire payments subject to {{wireFee}} fee. See our __Deposit Account Agreement__ for details.",
    condition2: "² See our __Deposit Account Agreement__ for details on mobile check deposits.",
  },
  labels: {
    cardHolder: "CARDHOLDER",
    cardNumber: "CARD NUMBER",
  },
  activate: {
    enterCardDetails: "Enter the details on your debit card ending in **{{last4}}**",
    cardNotReceived: "I don't have my card yet",
    enterValidExpiry: "Enter a valid expiry date",
    label: {
      expirationDate: "Expiration date",
      securityCode: "Security code",
    },
    errors: {
      invalidInput: "The details you entered don’t match the debit card. Please try again.",
      generic:
        "There was a technical problem. Try clicking “Continue” again, or contact Customer Care.",
      expirationDate: "Enter a valid expiry date",
      securityCode: "Enter a 3-digit security code",
    },
  },
}
