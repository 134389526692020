type DebouncedFunction<T extends any[]> = (...args: T) => void

export function debounce<T extends any[]>(
  callback: DebouncedFunction<T>,
  time = 250,
): DebouncedFunction<T> {
  let interval: NodeJS.Timeout

  return (...args: T) => {
    clearTimeout(interval)
    interval = setTimeout(() => {
      callback(...args)
    }, time)
  }
}
