import React, { PropsWithChildren } from "react"
import { FlexColumnLayout, useWindowDimensions } from "@northone/ui"
import { Box } from "rebass"
import { Text } from "@components/primitive/text/text"
import { useTranslation } from "react-i18next"
import { Helmet } from "react-helmet"
import { theme } from "@layouts/theme"

export const SubscriptionsLayout = ({ children }: PropsWithChildren) => {
  return (
    <>
      <ScreenLayout>
        <FlexColumnLayout>
          <SubscriptionsHeading />
          {children}
        </FlexColumnLayout>
      </ScreenLayout>
    </>
  )
}

const SubscriptionsHeading: React.FC = () => {
  const { t } = useTranslation()
  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    marginTop: "70px",
    marginBottom: "88px",
  }
  const titleStyle = { marginBottom: 10, lineHeight: "40px" }
  const subtitleStyle = { color: theme.colors.ui2 }

  return (
    <Box sx={containerStyle}>
      <Text tag={"h1"} sx={titleStyle}>
        {t("subscriptions.landing.title")}
      </Text>
      <Text tag={"body-large"} sx={subtitleStyle}>
        {t("subscriptions.landing.subtitle")}
      </Text>
    </Box>
  )
}

//This layout is required to keep the subscription plan boxes centered on the screen for all screen widths
//Our current grid system doesn't allow for responsive centering - this should be addressed during the Front-end redesign
const ScreenLayout = ({ children }: PropsWithChildren) => {
  const { t } = useTranslation()
  const { width: windowWidth } = useWindowDimensions()
  const width =
    windowWidth && windowWidth > theme.maxWidth
      ? theme.maxWidth
      : `calc(100vw - ${theme.spacing.appHorizontalPadding * 2}px)`

  return (
    <>
      <Helmet>
        <title>{t("pageTitles.subscriptions")}</title>
      </Helmet>
      <Box
        sx={{
          paddingY: "20px",
          gridRow: "1/2",
          width,
        }}
      >
        <div style={{ height: "100%" }}>
          <Box
            sx={{
              marginX: -theme.spacing.listItemPadding,
              paddingX: theme.spacing.listItemPadding,
              height: "100%",
            }}
          >
            {children}
          </Box>
        </div>
      </Box>
    </>
  )
}
