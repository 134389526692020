import React from "react"
import { useTranslation } from "react-i18next"
import { AlignedColumnLayout } from "../aligned-column-layout"
import { theme } from "@layouts/theme"
import { TransactionTypeEnum } from "@generated/graphql"
import { getTransactionIcons } from "@utils/mapping/transactions.mapping"
import { ListItem, Text, FlexRowLayout, FlexColumnLayout, PreloadImage } from "@northone/ui"

export interface IMoveMoneyListItemProps {
  type: TransactionTypeEnum
  subTitle?: string
  isActive?: boolean
  onClick?: () => void
}

/**
 * Extends the ListItem primitive
 */
export const MoveMoneyListItem: React.FC<IMoveMoneyListItemProps> = ({
  type,
  subTitle,
  onClick,
  isActive,
}) => {
  const { t } = useTranslation()

  const labels: { [type in TransactionTypeEnum]?: string } = {
    [TransactionTypeEnum.ACH]: t("moveMoney.paymentMethod.ach"),
    [TransactionTypeEnum.DOMESTIC_WIRE]: t("moveMoney.paymentMethod.wire"),
    [TransactionTypeEnum.SUB_ACCOUNT_TRANSFER]: t("moveMoney.paymentMethod.external"),
  }

  return (
    <ListItem
      sx={{
        paddingX: theme.spacing.listItemPadding,
        justifyContent: "space-between",
      }}
      onClick={onClick}
      isActive={isActive}
    >
      <PreloadImage
        src={getTransactionIcons(type)}
        dimensions={{
          height: 34,
          width: 32,
        }}
        containerStyle={{ marginRight: 22 }}
      />
      <FlexRowLayout sx={{ width: "100%" }}>
        <AlignedColumnLayout alignment={"flex-start"} widthRadio={1} sidePadding={9}>
          <FlexColumnLayout>
            <Text tag={isActive ? "body-small-bold" : "body-small"} sx={{ marginBottom: "2px" }}>
              {labels[type]}
            </Text>
            <Text tag={"body-x-small"} color={theme.colors.ui2}>
              {subTitle}
            </Text>
          </FlexColumnLayout>
        </AlignedColumnLayout>
      </FlexRowLayout>
    </ListItem>
  )
}
