import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useParams, useHistory, Link } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useAppSelector } from "@core/redux/utils"
import { selectActiveBusinessID } from "@core/active-business/redux/selectors"
import { Analytics } from "@core/analytics/actions"
import { events } from "@core/analytics/events"
import { RoutesEnum } from "@routers/types"
import { Text } from "@components/primitive/text/text"
import { Button, ButtonTypeEnum } from "@components/primitive/button/button"
import { TransactionList } from "@components/composite/transaction-list/transaction-list"
import { ErrorScreen } from "@components/composite/error-screen/error-screen"
import { selectAccountsTransactionID } from "@features/accounts/redux/selectors"
import { accountsActions } from "@features/accounts/redux/actions"
import { DEPOSIT_ACCOUNT_AGREEMENT_LINK } from "@utils/constants"
import { BaseLayout } from "@layouts/base-layout"
import { FlexRowLayout } from "@layouts/flex"
import { theme } from "@layouts/theme"
import { images } from "@assets/images/images"
import { useSubAccountDetailsQuery } from "@generated/graphql"
import { SubAccountsTransactionPanel } from "./sub-account-transaction-panel"

export const SubAccountDashboard: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const activeTransactionId = useAppSelector(selectAccountsTransactionID)
  const businessId = useAppSelector(selectActiveBusinessID)
  const { subAccountId } = useParams<{ subAccountId: string }>()

  const { data, loading, error, refetch } = useSubAccountDetailsQuery({
    variables: { businessId, subAccountId },
    notifyOnNetworkStatusChange: true,
  })
  const subAccount = data?.business?.bankAccount?.subAccount
  const subAccountName = subAccount?.name
  const isDefault = subAccount?.isDefault || false

  useEffect(() => {
    dispatch(accountsActions.setActiveTransactionId(""))
  }, [subAccountId, dispatch])

  return (
    <React.Fragment>
      <BaseLayout rightAside={activeTransactionId ? <SubAccountsTransactionPanel /> : null}>
        <FlexRowLayout
          sx={{ marginBottom: 4, alignItems: "center", justifyContent: "space-between" }}
        >
          <Text tag="h5" textColor={theme.colors.ui2}>
            {!isDefault ? t("accounts.subAccounts.envelope") : "\n"}
          </Text>
          {!isDefault && !error && !loading ? (
            <Button
              type={ButtonTypeEnum.TERTIARY_ALT}
              iconSrc={images.icons.edit}
              onClick={() => {
                Analytics.track(events.accounts.edit.start)
                history.push(`${RoutesEnum.ACCOUNTS_OVERVIEW}/${subAccountId}/edit`)
              }}
            >
              {t("buttons.edit")}
            </Button>
          ) : null}
        </FlexRowLayout>
        {error ? (
          <ErrorScreen retry={() => refetch()} size="large" />
        ) : (
          <>
            {loading ? null : (
              <>
                <Text tag="h2" sx={{ opacity: subAccountName ? 1 : 0, marginBottom: 15 }}>
                  {subAccountName}
                </Text>
                <Text tag="body" color={theme.colors.ui2} sx={{ marginBottom: 15 }}>
                  {t("accounts.subAccounts.subHeading")}
                </Text>
              </>
            )}
            <TransactionList
              activeTransactionId={activeTransactionId}
              subAccountId={subAccountId}
              transactionOnClick={(transactionId) =>
                dispatch(accountsActions.setActiveTransactionId(transactionId))
              }
            />
            <Text tag="body-x-small" color={theme.colors.ui2} sx={{ marginTop: 40 }}>
              <>
                {t("accounts.subAccounts.info")}
                <Link style={{ color: theme.colors.ui3 }} to={DEPOSIT_ACCOUNT_AGREEMENT_LINK}>
                  {`${t("accounts.emptyState.dashboard")}.`}
                </Link>
              </>
            </Text>
          </>
        )}
      </BaseLayout>
    </React.Fragment>
  )
}
