import React from "react"
import { Avatar, TTextTag } from "@northone/ui"
import { theme } from "@layouts/theme"

interface ITeamMemberAvatarProps {
  name: string
  width?: number
  textTag?: TTextTag
}

export const TeamMemberAvatar = ({ name, width, textTag }: ITeamMemberAvatarProps) => (
  <Avatar
    name={name}
    dimensions={{
      width: width ?? 36,
      borderRadius: 3,
    }}
    colorThemes={[
      {
        backgroundColor: theme.colors.goldTint2,
        textColor: theme.colors.black1,
      },
    ]}
    textTag={textTag ? textTag : "body-bold"}
    imageBorderWidth={1.5}
  />
)
