import React from "react"
import { Box, SxStyleProp } from "rebass"
import { FlexColumnLayout } from "./flex"
import { theme } from "./theme"

interface WithMaxWidthProps {
  children?: React.ReactNode
  sx?: SxStyleProp
}

/**
 * Defines the top-level layout element containing the grid
 * Required to set a maximum supported screen width
 */
export const WithMaxWidth = ({ sx, children }: WithMaxWidthProps) => (
  <FlexColumnLayout
    sx={{
      alignItems: "center",
      paddingX: theme.spacing.appHorizontalPadding,
      width: "100%",
      height: "100%",
    }}
  >
    <Box sx={{ maxWidth: theme.maxWidth, width: "100%", height: "100%", ...sx }}>{children}</Box>
  </FlexColumnLayout>
)
