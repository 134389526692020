import { Button, ButtonTypeEnum } from "@components/primitive/button/button"
import React from "react"
import { Image, SxStyleProp } from "rebass"

export const ImageButton = ({
  onClick,
  imgSrc,
  imgSx,
  buttonSx,
}: {
  onClick: () => void
  imgSrc: string
  imgSx?: SxStyleProp
  buttonSx?: SxStyleProp
}) => (
  <Button
    type={ButtonTypeEnum.SECONDARY}
    onClick={onClick}
    sx={{
      background: "none",
      margin: 0,
      padding: 0,
      ":hover": { backgroundColor: "none" },
      ":active": { backgroundColor: "none" },
      ...buttonSx,
    }}
  >
    <Image src={imgSrc} sx={imgSx} />
  </Button>
)
