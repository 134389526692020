import React from "react"
import { AnimatePresence, ErrorBanner, FlexRowLayout, Panel, theme } from "@northone/ui"
import { Box, Image } from "rebass"
import { QueryResults } from "@components/composite/query-results/query-results"
import { CardPinSetStatusEnum, useCardQuery } from "@generated/graphql"
import { useAppSelector } from "@core/redux/utils"
import LoadingComponent from "../shared/loading-spinner"
import { images } from "@assets/images/images"
import { useTranslation } from "react-i18next"
import { useCardPinSetPolling } from "./hooks"
import { cardDataTransformer } from "./transformers"
import { CardSummaryContent } from "./card-summary-content"
import { ErrorScreen } from "@components/composite/error-screen/error-screen"

interface ICardSummaryProps {
  cardId: string
}

export const CardSummary: React.FC<ICardSummaryProps> = ({ cardId }) => {
  const { t } = useTranslation()
  const businessId = useAppSelector((state) => state.activeBusiness.businessId)

  const currentCardId = useAppSelector((state) => state.cards.currentCardId)
  const isCurrentCardLoading = useAppSelector((state) => state.cards.currentCardIsLoading)
  const currentCardErrorMessage = useAppSelector((state) => state.cards.currentCardErrorMessage)
  const isReloading = currentCardId === cardId && isCurrentCardLoading

  const {
    data: cardData,
    error: cardError,
    loading: cardLoading,
    refetch: cardRefetch,
  } = useCardQuery({
    variables: { businessId, cardId },
    nextFetchPolicy: "network-only",
  })
  const card = cardDataTransformer(cardData)
  const { isVirtualCard, isFrozen, isDisabled, isFullyActivated, pinSetStatus } = card || {}
  const isLocked = isFrozen || isDisabled

  useCardPinSetPolling(cardId, pinSetStatus)

  const getCardImage = () => {
    if (isVirtualCard) {
      return isLocked ? images.virtualCardFrozen : images.virtualCard
    }
    return isLocked ? images.physicalCardFrozen : images.physicalCard
  }

  const errorMessage =
    pinSetStatus === CardPinSetStatusEnum.ERROR
      ? t("cards.pinNotSet.pinSetError")
      : currentCardErrorMessage

  return (
    <Panel
      sx={{
        paddingX: 32,
        paddingY: 16,
        marginBottom: 16,
        width: "100%",
        ...(!isFullyActivated && { bg: theme.colors.ui6, boxShadow: undefined }),
      }}
    >
      <AnimatePresence animation="fadeAndExpand">
        {errorMessage && (
          <ErrorBanner
            textProps={{ onLinkClick: () => window.Intercom("show") }}
            text={errorMessage}
          />
        )}
      </AnimatePresence>
      <FlexRowLayout sx={{ justifyContent: "flex-start" }}>
        <QueryResults
          loading={cardLoading}
          error={cardError}
          retry={() => cardRefetch()}
          size="medium"
          listShimmerCount={1}
        >
          <Box sx={{ position: "relative", flexShrink: 0, display: "flex" }}>
            {isLocked && (
              <Image
                src={images.lockTag}
                sx={{
                  position: "absolute",
                  top: "-2px",
                  left: "-2px",
                  width: 25,
                  height: 25,
                  zIndex: 1,
                }}
              />
            )}
            <Image
              src={getCardImage()}
              sx={{
                width: 59,
                height: 93,
                boxShadow: "0px 2px 4px 0px #00000040",
                borderRadius: 5,
              }}
            />
          </Box>
          {isReloading ? (
            <Box sx={{ margin: "auto", height: "100%" }}>
              <LoadingComponent />
            </Box>
          ) : card ? (
            <CardSummaryContent card={card} />
          ) : (
            <ErrorScreen size={"medium"} retry={() => cardRefetch()} />
          )}
        </QueryResults>
      </FlexRowLayout>
    </Panel>
  )
}
