import React, { useState } from "react"
import { selectActiveBusinessID } from "@core/active-business/redux/selectors"
import { useAppSelector } from "@core/redux/utils"
import {
  PlaidFundingStatusEnum,
  useGetFundingStatusQuery,
  useTransactionsListQuery,
} from "@generated/graphql"
import { useTranslation } from "react-i18next"
import { PlaidFundingBaseModal } from "./plaid-funding-base-modal"
import { events } from "@core/analytics/events"
import { images } from "@assets/images/images"

const statusesForGenericError = [
  PlaidFundingStatusEnum.UNKNOWN_ERROR,
  PlaidFundingStatusEnum.FUNDING_BLOCKED,
  PlaidFundingStatusEnum.PLAID_ITEM_DEACTIVATED,
  PlaidFundingStatusEnum.TRANSACTION_NON_SUFFICIENT_BALANCE,
  PlaidFundingStatusEnum.PLAID_NAME_MISMATCH,
]

export const usePlaidFundingGate = () => {
  const [shouldDisplayPlaidErrors, setShouldDisplayPlaidErrors] = useState<boolean>()
  const businessId = useAppSelector(selectActiveBusinessID)

  useTransactionsListQuery({
    variables: { businessId },
    fetchPolicy: "network-only",
    onCompleted: (response) => {
      if (
        response.transactionsList?.transactionListItems &&
        response.transactionsList?.transactionListItems.length > 0
      ) {
        setShouldDisplayPlaidErrors(false)
      } else {
        setShouldDisplayPlaidErrors(true)
      }
    },
    onError: () => setShouldDisplayPlaidErrors(false),
  })
  return { shouldDisplayPlaidErrors }
}

export const PlaidFundingModal = () => {
  const { t } = useTranslation()
  const businessId = useAppSelector(selectActiveBusinessID)
  const { shouldDisplayPlaidErrors } = usePlaidFundingGate()
  const { data: fundingStatusResponse } = useGetFundingStatusQuery({
    variables: { businessId },
    skip: !shouldDisplayPlaidErrors,
  })

  const fundingStatus = fundingStatusResponse?.getFundingStatus?.status
  const shouldShowGenericErrorModal =
    fundingStatus && statusesForGenericError.includes(fundingStatus)
  const shouldShowMoneyOnMoveModal =
    fundingStatus === PlaidFundingStatusEnum.FUNDING_ATTEMPT_INITIATED
  const isInitialFundingOnHold =
    fundingStatus == PlaidFundingStatusEnum.TRANSACTION_INITIATED ||
    fundingStatus == PlaidFundingStatusEnum.TRANSACTION_POSTED

  if (
    !businessId ||
    !shouldDisplayPlaidErrors ||
    isInitialFundingOnHold ||
    (!shouldShowGenericErrorModal && !shouldShowMoneyOnMoveModal)
  ) {
    return null
  }

  return (
    <>
      {shouldShowGenericErrorModal && (
        <PlaidFundingBaseModal
          title={t("plaidFunding.fundingFailed.title")}
          subtitle={t("plaidFunding.fundingFailed.subtitle")}
          analyticsEvent={events.plaidFunding.failedConnection}
        />
      )}
      {shouldShowMoneyOnMoveModal && (
        <PlaidFundingBaseModal
          imageSrc={images.illustrations.hourglassWithMoney}
          title={t("plaidFunding.moneyOnMove.title")}
          subtitle={t("plaidFunding.moneyOnMove.subtitle")}
          analyticsEvent={events.plaidFunding.moneyOnMove}
        />
      )}
    </>
  )
}
