import React from "react"
import { FlowModalLayout } from "@components/extended/overlay/flow-modal"
import { ACHRecipientForm } from "@features/move-money/ach/recipient/form"
import { ACHRecipientDetailsForm } from "@features/move-money/ach/recipient-details/form"
import { ACHAmountForm } from "@features/move-money/ach/amount/form"
import { ACHPaymentConfirmCard } from "@features/move-money/ach/confirm/card"
import { ACHSubAccountForm } from "@features/move-money/ach/sub-account/form"
import { ACHSentCard } from "@features/move-money/ach/sent/card"
import { moveMoneyProgressBarItems } from "./progress-bar-items"

export type TACHScreenNames =
  | "ACH_RECIPIENT"
  | "ACH_RECIPIENT_DETAILS"
  | "ACH_AMOUNT"
  | "ACH_SUB_ACCOUNT"
  | "ACH_CONFIRM"
  | "ACH_SENT"

export enum ACHScreensEnum {
  ACH_RECIPIENT = "ACH_RECIPIENT",
  ACH_RECIPIENT_DETAILS = "ACH_RECIPIENT_DETAILS",
  ACH_AMOUNT = "ACH_AMOUNT",
  ACH_SUB_ACCOUNT = "ACH_SUB_ACCOUNT",
  ACH_CONFIRM = "ACH_CONFIRM",
  ACH_SENT = "ACH_SENT",
}

export const ACH_ROUTER: {
  [key in TACHScreenNames]: {
    component: React.ReactElement
    progressItem: string
    layout: FlowModalLayout
  }
} = {
  ACH_RECIPIENT: {
    component: <ACHRecipientForm />,
    progressItem: moveMoneyProgressBarItems[1],
    layout: "form",
  },
  ACH_RECIPIENT_DETAILS: {
    component: <ACHRecipientDetailsForm />,
    progressItem: moveMoneyProgressBarItems[2],
    layout: "form",
  },
  ACH_AMOUNT: {
    component: <ACHAmountForm />,
    progressItem: moveMoneyProgressBarItems[3],
    layout: "form",
  },
  ACH_SUB_ACCOUNT: {
    component: <ACHSubAccountForm />,
    progressItem: moveMoneyProgressBarItems[4],
    layout: "form",
  },

  ACH_CONFIRM: {
    component: <ACHPaymentConfirmCard />,
    progressItem: moveMoneyProgressBarItems[5],
    layout: "centered",
  },
  ACH_SENT: {
    component: <ACHSentCard />,
    progressItem: moveMoneyProgressBarItems[6],
    layout: "centered",
  },
}
