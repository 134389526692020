import React from "react"
import { Box, SxStyleProp, Image } from "rebass"
import { PreloadImage } from "@northone/ui"
import { Link, useHistory, useLocation } from "react-router-dom"

import { Text } from "@components/primitive/text/text"
import { FlexRowLayout } from "@layouts/flex"
import { theme } from "@layouts/theme"
import { images } from "@assets/images/images"
import { ILocationState, RoutesEnum } from "@routers/types"
import { navigateToModal } from "@utils/navigation"

export const SideBarActionItem: React.FC<{
  imageSrc: string
  text: string
  notification?: { count: number; color: string }
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
  sx?: SxStyleProp
  hasImageNotification?: boolean
  route: RoutesEnum
  modal?: boolean
}> = ({ text, imageSrc, notification, onClick, sx, hasImageNotification, route, modal }) => {
  const history = useHistory()
  const location = useLocation<ILocationState>()

  return (
    <FlexRowLayout sx={sx}>
      <Link
        to={route}
        style={{ textDecoration: "none" }}
        onClick={(e) => {
          if (modal) {
            e.preventDefault()
            navigateToModal({ routeName: route, currentLocation: location, history })
          }
          if (onClick) {
            onClick(e)
          }
        }}
      >
        <FlexRowLayout sx={{ alignItems: "center", justifyContent: "space-between" }}>
          <FlexRowLayout sx={{ alignItems: "center" }}>
            <Box sx={{ height: 36, width: 36, display: "flex", justifyContent: "flex-end" }}>
              {hasImageNotification && (
                <Image
                  src={images.icons.redNotification}
                  sx={{
                    width: 7,
                    height: 7,
                    zIndex: 1,
                    position: "absolute",
                    marginRight: "5px",
                    marginTop: "5px",
                  }}
                />
              )}
              <PreloadImage
                src={imageSrc}
                containerStyle={{ position: "relative", zIndex: 0 }}
                dimensions={{
                  height: 36,
                  width: 36,
                }}
              />
            </Box>
            <Text sx={{ marginLeft: "16px" }} tag="body-large">
              {text}
            </Text>
          </FlexRowLayout>
          {notification && (
            <Text
              sx={{
                backgroundColor: notification.color,
                height: "24px",
                width: "24px",
                borderRadius: "12px",
                textAlign: "center",
                lineHeight: "24px",
              }}
              tag="body-small-bold"
              textColor={theme.colors.ui7}
            >
              {notification.count}
            </Text>
          )}
        </FlexRowLayout>
      </Link>
    </FlexRowLayout>
  )
}
