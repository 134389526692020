import gql from "graphql-tag"

export const CAPABILITIES_WITH_LIMIT_AND_FEES_FRAGMENT = gql`
  fragment capabilitiesWithLimitAndFees on BankAccountCapabilities {
    ach {
      enabled
      limit
    }
    sameDayAch {
      enabled
      fee {
        ... on PercentFee {
          maximum
          minimum
          percentage
          type
        }
        ... on FlatFee {
          type
          amount
        }
      }
    }
    mailedCheck {
      limit
      fee {
        ... on FlatFee {
          type
          amount
        }
        ... on PercentFee {
          maximum
          minimum
          percentage
          type
        }
      }
    }
    checkDeposit {
      enabled
      limit
    }
    billPay {
      limit
      fee {
        ... on PercentFee {
          maximum
          minimum
          percentage
          type
        }
        ... on FlatFee {
          type
          amount
        }
      }
    }
    domesticWire {
      enabled
      limit
      fee {
        ... on FlatFee {
          type
          amount
        }
        ... on PercentFee {
          maximum
          minimum
          percentage
          type
        }
      }
    }
  }
`

export const CAPABILITIES_AND_AVAILABLE_BALANCE = gql`
  query capabilitiesAndAvailableBalance($businessId: ID!) {
    business(businessId: $businessId) {
      id
      bankAccount {
        id
        availableBalance
      }
      bankAccounts {
        id
        capabilities {
          ...capabilitiesWithLimitAndFees
        }
      }
    }
  }
  ${CAPABILITIES_WITH_LIMIT_AND_FEES_FRAGMENT}
`

export const PAYMENT_METHOD_FEES = gql`
  query paymentMethodFees($businessId: ID!) {
    business(businessId: $businessId) {
      bankAccounts {
        id
        capabilities {
          ...capabilitiesWithLimitAndFees
        }
      }
    }
  }
  ${CAPABILITIES_WITH_LIMIT_AND_FEES_FRAGMENT}
`
