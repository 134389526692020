import React from "react"
import { Box, Link } from "rebass"
import { Text } from "@northone/ui"
import { theme } from "@layouts/theme"
import { NORTHONE_TERMS_OF_SERVICE_URL } from "@utils/constants"
import { RECIPIENT_TERMS_TEXT, RECIPIENT_TERMS } from "@features/move-money/utils/i18n-translations"

export const TermsDisclaimer = () => {
  return (
    <Box sx={{ marginTop: "8px" }}>
      <Text tag="body" textColor={theme.colors.ui2}>
        {RECIPIENT_TERMS_TEXT}{" "}
        <Link
          href={NORTHONE_TERMS_OF_SERVICE_URL}
          target="_blank"
          sx={{ textDecoration: "underline", cursor: "pointer", color: theme.colors.ui2 }}
        >
          {RECIPIENT_TERMS}
        </Link>
      </Text>
    </Box>
  )
}
