import { LoadingIcon } from "@northone/ui"
import { formatMoney } from "accounting"
import React from "react"
import { useTranslation } from "react-i18next"
import { Image } from "rebass"

import { images } from "@assets/images/images"
import { Text } from "@components/primitive/text/text"
import { useAppSelector } from "@core/redux/utils"
import { FlexColumnLayout, FlexRowLayout } from "@layouts/flex"
import { theme } from "@layouts/theme"
import { SHARE_AND_EARN_HUB_TOP_SPACING } from "./share-and-earn-landing"

interface IShareAndEarnSidebarContentProps {
  totalRedeemedOffers: number | undefined
  disabled: boolean | undefined
}
const ShareAndEarnSidebarContent: React.FC<IShareAndEarnSidebarContentProps> = ({
  totalRedeemedOffers,
  disabled,
}) => {
  const { t } = useTranslation()

  const totalRedeemedAmount = useAppSelector((state) => state.shareAndEarn.totalRedeemedAmount)
  const referralTotalItems = [
    { label: t("EARNED"), total: formatMoney(totalRedeemedAmount ?? 0, { precision: 0 }) },
    { label: t("REFERRED"), total: totalRedeemedOffers ?? 0 },
  ]

  const textColor = disabled ? theme.colors.ui3 : theme.colors.ui1
  const finalIndex = referralTotalItems.length - 1
  return (
    <>
      {referralTotalItems.map((item, idx) => (
        <FlexColumnLayout key={idx}>
          <Text
            tag={"label"}
            key={idx}
            color={textColor}
            sx={{ letterSpacing: 1, marginBottom: 15, fontWeight: 500 }}
          >
            {item.label}
          </Text>
          <Text
            tag={"body-large"}
            key={idx}
            sx={{
              fontSize: 48,
              lineHeight: "42px",
              paddingBottom: 20,
              color: textColor,
              marginRight: idx === finalIndex ? 30 : 50,
            }}
          >
            {item.total}
          </Text>
        </FlexColumnLayout>
      ))}
    </>
  )
}

export const ShareAndEarnSidebar: React.FC = () => {
  const totalRedeemedOffers = useAppSelector((state) => state.shareAndEarn.totalRedeemedOffers)
  const isActiveAdvocate = useAppSelector((state) => state.shareAndEarn.offerUrl)
  const advocateIsEligible = useAppSelector((state) => state.shareAndEarn.isEligible)
  const isShareAndEarnLoading = useAppSelector((state) => state.shareAndEarn.isShareAndEarnLoading)
  const disabled = !isActiveAdvocate || !advocateIsEligible

  const lockImage = disabled ? (
    <Image
      width={16}
      height={17}
      src={images.shareAndEarnLock}
      sx={{ position: "absolute", right: 22 }}
    />
  ) : null
  return (
    <FlexRowLayout
      sx={{
        boxShadow: theme.boxShadow,
        borderRadius: 8,
        padding: 20,
        backgroundColor: theme.colors.ui7,
        marginTop: SHARE_AND_EARN_HUB_TOP_SPACING,
      }}
    >
      {isShareAndEarnLoading ? (
        <LoadingIcon />
      ) : (
        <ShareAndEarnSidebarContent totalRedeemedOffers={totalRedeemedOffers} disabled={disabled} />
      )}
      {lockImage}
    </FlexRowLayout>
  )
}
