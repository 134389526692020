import React from "react"

import { ListItem, ListItemProps } from "@components/primitive/list-item/list-item"
import { FlexRowLayout } from "@layouts/flex"
import { theme } from "@layouts/theme"

/**
 * Extends the ListItem primitive to add highlight/selection overflow
 */
export const OverflowListItem = (props: ListItemProps) => {
  return (
    <ListItem
      {...props}
      sx={{
        marginX: -theme.spacing.listItemPadding,
        paddingX: theme.spacing.listItemPadding,
        borderWidth: 0,
        ...props.sx,
      }}
    >
      <FlexRowLayout
        sx={{ borderBottom: `solid 1px ${theme.colors.ui5}`, height: "100%", width: "100%" }}
      >
        {props.children}
      </FlexRowLayout>
    </ListItem>
  )
}
