import React from "react"
import { useTranslation } from "react-i18next"
import { LoanProductSection } from "../shared/loan-product-section"

type LandingTermLoanSectionProps = {
  ctaButtonOnClick?: () => void
  currentUserIsLoanManager: boolean
  loanManagerName: string
  loanProductIsActive: boolean
}

export const LandingTermLoanSection: React.FC<LandingTermLoanSectionProps> = ({
  ctaButtonOnClick,
  currentUserIsLoanManager,
  loanManagerName,
  loanProductIsActive,
}) => {
  const { t } = useTranslation()
  const title = t("lending.landing.termLoanSection.title")
  const defaultSubtitle = t("lending.landing.termLoanSection.subtitle")
  const activeStateSubtitle = t("lending.landing.termLoanSection.activeStateSubtitle")

  const defaultDescription = currentUserIsLoanManager
    ? t("lending.landing.termLoanSection.description")
    : t("lending.landing.termLoanSection.viewOnly.description", { loanManagerName })

  const activeStateDescription = currentUserIsLoanManager
    ? t("lending.landing.termLoanSection.activeStateDescription")
    : t("lending.landing.termLoanSection.activeStateDescriptionSecondary", { loanManagerName })

  const qualicationsIntro = t("lending.landing.qualificationsIntro")
  const qualications = [
    t("lending.landing.termLoanSection.qualifications.accountAge"),
    t("lending.landing.termLoanSection.qualifications.creditScore"),
    t("lending.landing.termLoanSection.qualifications.accountBalance"),
  ]

  const manageLoanBulletPointsIntro = t(
    "lending.landing.termLoanSection.manageLoanBulletPointsIntro",
  )
  const manageLoanBulletPoints = [
    t("lending.landing.termLoanSection.manageLoanBulletPoints.repayment"),
    t("lending.landing.termLoanSection.manageLoanBulletPoints.viewingLoanInfo"),
    t("lending.landing.termLoanSection.manageLoanBulletPoints.downloadStatements"),
  ]

  const valueProps = loanProductIsActive
    ? undefined
    : [
        {
          title: t("lending.landing.maxAmountValuePropTitle"),
          value: t("lending.landing.termLoanSection.maxAmountValuePropValue"),
        },
        {
          title: t("lending.landing.industryLeadingValuePropTitle"),
          value: t("lending.landing.termLoanSection.industryLeadingValuePropValue"),
        },
        {
          title: t("lending.landing.decisionTimeValuePropTitle"),
          value: t("lending.landing.termLoanSection.decisionTimeValuePropValue"),
        },
      ]

  const subtitle = loanProductIsActive ? activeStateSubtitle : defaultSubtitle
  const description = loanProductIsActive ? activeStateDescription : defaultDescription

  const bulletPointsIntro = loanProductIsActive ? manageLoanBulletPointsIntro : qualicationsIntro
  const bulletPoints = loanProductIsActive ? manageLoanBulletPoints : qualications

  const ctaButton = ctaButtonOnClick
    ? {
        onClick: ctaButtonOnClick,
        label: t("lending.landing.termLoanSection.applyCTAButton"),
        subtext: t("lending.landing.topBanner.applyCTASubtext"),
      }
    : undefined

  return (
    <LoanProductSection
      productSectionTitle={title}
      bannerTitle={subtitle}
      description={description}
      bulletPointsTitle={bulletPointsIntro}
      bulletPoints={bulletPoints}
      valueProps={valueProps}
      ctaButton={ctaButton}
    />
  )
}
