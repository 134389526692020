import React from "react"
import { Modal } from "@components/extended/overlay/modal"
import { theme } from "@layouts/theme"
import { InfoBlock, IInfoBlockProps } from "../info-block/info-block"

interface IInfoBlockModalProps extends IInfoBlockProps {
  isOpen: boolean
  closeModal: () => void
}

export const InfoBlockModal: React.FC<IInfoBlockModalProps> = ({
  isOpen,
  title,
  buttons,
  subtitle,
  subtitleTag,
  subtitleSx,
  bodyText,
  closeModal,
  imageSrc,
  imageDimensions,
  imageSx,
  isImageContain,
  errorMessage,
  subTitleHasPartialBolding,
  listItemData,
  listItemContainerSx,
  titleSx,
  modalSx,
  buttonContainerSx,
  centered,
}) => {
  return (
    <Modal
      sx={{ width: 378, ...modalSx }}
      zIndex={theme.zIndex.flowModal + 1}
      onOutsideClick={closeModal}
      isOpen={isOpen}
    >
      <InfoBlock
        centered={centered}
        errorMessage={errorMessage}
        subTitleHasPartialBolding={subTitleHasPartialBolding}
        bodyText={bodyText}
        title={title}
        subtitle={subtitle}
        imageDimensions={imageDimensions}
        imageSx={imageSx}
        imageSrc={imageSrc}
        isImageContain={isImageContain}
        buttons={buttons}
        listItemData={listItemData}
        listItemContainerSx={listItemContainerSx}
        subtitleTag={subtitleTag}
        subtitleSx={subtitleSx}
        titleSx={titleSx}
        buttonContainerSx={buttonContainerSx}
      />
    </Modal>
  )
}
