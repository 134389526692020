import React, { useEffect } from "react"
import { Text } from "@components/primitive/text/text"
import { useTranslation } from "react-i18next"
import { FlexColumnLayout } from "@northone/ui"
import { theme } from "@layouts/theme"
import { events } from "@core/analytics/events"
import { Analytics } from "@core/analytics/actions"

interface INoSearchResultsProps {
  searchValue?: string
}

export const PerksNoSearchResults = ({ searchValue }: INoSearchResultsProps) => {
  const { t } = useTranslation()
  useEffect(() => {
    if (searchValue) {
      Analytics.track(events.perks.searchNoResult, { searchValue })
    }
  }, [searchValue])
  return (
    <FlexColumnLayout
      sx={{ width: "50%", alignItems: "center", paddingTop: 75, paddingBottom: 250 }}
    >
      <Text sx={{ marginBottom: 20 }} tag={"h3Serif"}>
        {t("perks.landing.search.noResults.title")}
      </Text>
      <Text tag={"body-large"} sx={{ color: theme.colors.ui2 }}>
        {t("perks.landing.search.noResults.body")}
      </Text>
    </FlexColumnLayout>
  )
}
