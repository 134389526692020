import { TActionsUnion, IAction, createAction } from "../utils"
import { ThunkDispatch } from "redux-thunk"
import { IRootState } from "../root-state"

export enum AppActionEnum {
  RESET_STATE = "APP_RESET_STATE",
  SET_NOTIFICATION_MESSAGE = "SET_NOTIFICATION_MESSAGE",
}

export const appActions = {
  resetState: () => (dispatch: ThunkDispatch<IRootState, void, IAction<AppActionEnum>>) => {
    dispatch({ type: AppActionEnum.RESET_STATE })
    return Promise.resolve()
  },
  setNotificationMessage: (message: string) =>
    createAction(AppActionEnum.SET_NOTIFICATION_MESSAGE, message),
}

export type TAppActions = TActionsUnion<typeof appActions>
