import {
  RELATIONSHIP_TYPE_GOVERNMENT,
  RELATIONSHIP_TYPE_OTHER_BANK,
  RELATIONSHIP_TYPE_PAYROLL,
  RELATIONSHIP_TYPE_VENDOR,
  RELATIONSHIP_TYPE_OTHER,
} from "@features/move-money/utils/i18n-translations"

export const WIRE_CUT_OFF_TIME_ET_WITHOUT_OFFSET = "15:30:00" //  3:30 PM

// These values can be found here:
// https://docs.google.com/spreadsheets/d/1GHo4dn66IetaUfdLGax8E4HbWyjgzbLkELWYTYm25zg/edit?usp=sharing
export const wireConstants = {
  RECIPIENT_NAME_MAX_LENGTH: 35,
  RECIPIENT_ADDRESS_LINE_ONE_MAX_LENGTH: 30,
  RECIPIENT_ADDRESS_LINE_TWO_MAX_LENGTH: 10,
  RECIPIENT_CITY_MAX_LENGTH: 24,
  RECIPIENT_POSTAL_CODE_MAX_LENGTH: 9,
  RECIPIENT_POSTAL_CODE_MIN_LENGTH: 5,
  RECIPIENT_BUSINESS_REASON_MAX_LENGTH: 35,
  RECIPIENT_BUSINESS_REASON_MIN_LENGTH: 4,
  RECIPIENT_BANK_NAME_MAX_LENGTH: 35,
  RECIPIENT_ROUTING_NUMBER_LENGTH: 9,
  RECIPIENT_ACCOUNT_NUMBER_MAX_LENGTH: 17,
  RECIPIENT_ACCOUNT_NUMBER_MIN_LENGTH: 5,
  WIRE_PURPOSE_MIN_LENGTH: 15,
  WIRE_PURPOSE_MAX_LENGTH: 60,
}

export const businessRelationshipOptions = {
  government: RELATIONSHIP_TYPE_GOVERNMENT,
  otherBank: RELATIONSHIP_TYPE_OTHER_BANK,
  payroll: RELATIONSHIP_TYPE_PAYROLL,
  vendor: RELATIONSHIP_TYPE_VENDOR,
  other: RELATIONSHIP_TYPE_OTHER,
}
