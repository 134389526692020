import { images } from "@assets/images/images"
import { theme } from "@northone/ui"
import React from "react"
import { Button, Image, SxStyleProp } from "rebass"

interface ICloseButtonProps {
  onCloseClick: () => void
  sx?: SxStyleProp
}

export const CloseButton: React.FC<ICloseButtonProps> = ({ onCloseClick, sx }) => {
  return (
    <Button
      sx={{
        cursor: "pointer",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        border: `1px solid ${theme.colors.ui4}`,
        backgroundColor: theme.colors.ui7,
        borderRadius: "50%",
        width: 40,
        height: 40,
        padding: 0,
        ...sx,
      }}
      onClick={onCloseClick}
    >
      <Image src={images.icons.closeLight} />
    </Button>
  )
}
