import React from "react"
import { useTranslation } from "react-i18next"
import { Link, useHistory, useLocation } from "react-router-dom"
import { useAppSelector } from "@core/redux/utils"
import { selectActiveBusinessID } from "@core/active-business/redux/selectors"
import { events } from "@core/analytics/events"
import { Analytics } from "@core/analytics/actions"
import { RoutesEnum, ILocationState } from "@routers/types"
import { Text } from "@components/primitive/text/text"
import { AddNewEntityListItem } from "@components/extended/list-items/new-entity/add-new-entity-list-item"
import { SubAccountList } from "@components/composite/sub-account-list/sub-account-list"
import { QueryResults } from "@components/composite/query-results/query-results"
import { Button, ButtonTypeEnum } from "@components/primitive/button/button"
import { InfoBlock } from "@components/composite/info-block/info-block"
import { FlexColumnLayout, FlexRowLayout } from "@layouts/flex"
import { BaseLayout } from "@layouts/base-layout"
import { theme } from "@layouts/theme"
import { images } from "@assets/images/images"
import { navigateToModal } from "@utils/navigation"
import { DEPOSIT_ACCOUNT_AGREEMENT_LINK } from "@utils/constants"
import { useSubAccountsQuery } from "@generated/graphql"
import { ISubAccount } from "@typedefs/types"

export const Accounts: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation<ILocationState>()
  const businessId = useAppSelector(selectActiveBusinessID)

  const { data, loading, error, refetch } = useSubAccountsQuery({
    variables: { businessId },
    notifyOnNetworkStatusChange: true,
  })

  const subAccounts: ISubAccount[] | undefined = data?.business?.bankAccount?.subAccounts || []
  const availableBalance = data?.business?.bankAccount?.availableBalance || 0
  const addNewSubAccount = () => {
    navigateToModal({
      routeName: RoutesEnum.CREATE_SUB_ACCOUNT,
      history,
      currentLocation: location,
    })
    Analytics.track(events.accounts.overview.subAccountAdd)
  }

  return (
    <BaseLayout>
      {subAccounts.length > 1 && (
        <FlexColumnLayout sx={{ marginBottom: 15 }}>
          <FlexRowLayout
            sx={{
              marginBottom: 19,
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <>
              <Text tag="h2">{t("accounts.header")}</Text>
              {subAccounts.length > 1 && <CreateTransferButton />}
            </>
          </FlexRowLayout>
          <Text tag="body" color={theme.colors.ui2}>
            {t("accounts.subHeading")}
          </Text>
        </FlexColumnLayout>
      )}

      <QueryResults
        loading={loading}
        error={error}
        retry={() => refetch()}
        size="large"
        listShimmerCount={3}
      >
        {subAccounts.length > 1 ? (
          <>
            <SubAccountList
              subAccounts={subAccounts}
              totalAvailableBalance={availableBalance}
              onSelect={(subAccount) => {
                Analytics.track(
                  subAccount.isDefault
                    ? events.accounts.overview.checking
                    : events.accounts.overview.subAccount,
                  !subAccount.isDefault && { subAccountName: subAccount.name },
                )
                history.push(`${RoutesEnum.ACCOUNTS_OVERVIEW}/${subAccount.id}`)
              }}
              withArrow
            />
            <AddNewEntityListItem onClick={addNewSubAccount} text={t("accounts.newSubAccount")} />
          </>
        ) : (
          <AccountsEmptyState />
        )}
      </QueryResults>
    </BaseLayout>
  )
}

export const AccountsEmptyState: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()

  return (
    <InfoBlock
      title={t("accounts.emptyState.title")}
      subHeading={"Envelopes let you budget for important expenses."}
      subtitle={
        <>
          {`${t("accounts.emptyState.subtitle")}
          ${t("accounts.emptyState.dashboardLink")}`}
          <Link style={{ color: theme.colors.ui3 }} to={DEPOSIT_ACCOUNT_AGREEMENT_LINK}>
            {`${t("accounts.emptyState.dashboard")}.`}
          </Link>
        </>
      }
      subtitleTag={"body-x-small"}
      subtitleSx={{ width: 344, color: theme.colors.ui3 }}
      containerSx={{ maxWidth: undefined, marginTop: 76 }}
      centered
      imageSrc={images.illustrations.lemonadeJug}
      imageDimensions={{ height: 180, width: 175 }}
      buttons={[
        {
          type: ButtonTypeEnum.PRIMARY_BLUE,
          children: t("accounts.emptyState.button"),
          onClick: () =>
            navigateToModal({
              routeName: RoutesEnum.CREATE_SUB_ACCOUNT,
              currentLocation: location,
              history,
            }),
        },
      ]}
    />
  )
}

export const CreateTransferButton: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation<ILocationState>()

  return (
    <Button
      type={ButtonTypeEnum.TERTIARY_ALT}
      iconSrc={images.icons.arrows}
      onClick={() => {
        navigateToModal({
          routeName: RoutesEnum.SUB_ACCOUNT_TRANSFER,
          history,
          currentLocation: location,
        })
        Analytics.track(events.accounts.transfer.start)
      }}
      sx={{
        marginBottom: 10,
      }}
    >
      {t("accounts.transfer.startTransferCta")}
    </Button>
  )
}
