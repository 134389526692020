import * as Sentry from "@sentry/browser"
import { ofType } from "redux-observable"
import { Observable, of, from } from "rxjs"
import { map, catchError, mergeMap } from "rxjs/operators"

import { AddFundsActionEnum, addFundsActions, IActionsForAddFunds } from "./actions"
import { LinkingService } from "@core/linking/linking.service"
import { store } from "../../../"

export const initializeSMSMessageService = (action$: Observable<IActionsForAddFunds<any>>) =>
  action$.pipe(
    //@ts-ignore
    ofType(AddFundsActionEnum.INITIALIZE_SMS_MESSAGE_SERVICE),
    mergeMap(() =>
      from(LinkingService.initialize()).pipe(
        map(() => {
          addFundsActions.setSMSMessageSendCapabilityStatus(true)
          return addFundsActions.initializeSMSMessageServiceSuccess()
        }),
        catchError((error) => {
          Sentry.captureException(error)
          store.dispatch(addFundsActions.setSMSMessageSendCapabilityStatus(false))
          return of(addFundsActions.initializeSMSMessageServiceFailure(error))
        }),
      ),
    ),
  )

export const sendSMSMessageEpic = (action$: Observable<IActionsForAddFunds<any>>) =>
  action$.pipe(
    //@ts-ignore
    ofType(AddFundsActionEnum.SEND_SMS_MESSAGE),
    mergeMap(({ payload }) =>
      from(LinkingService.sendSMS(payload.phone)).pipe(
        map((res) => {
          addFundsActions.setSMSMessageSendStatus(true)
          return addFundsActions.sendSMSMessageSuccess(res)
        }),
        catchError((error) => {
          Sentry.captureException(error)
          store.dispatch(addFundsActions.setSMSMessageSendStatus(false))
          return of(addFundsActions.sendSMSMessageFailure(error))
        }),
      ),
    ),
  )
