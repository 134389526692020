import { EASTERN_TIME_ZONE, isNowBefore } from "@utils/dates"
import { DateTime } from "luxon"
import {
  businessRelationshipOptions,
  wireConstants,
  WIRE_CUT_OFF_TIME_ET_WITHOUT_OFFSET,
} from "./constants"

/**
 * If "other" was selected as relationship, return the business relationship from redux
 */
export const getRelationshipValue = (relationship: string, otherRelationship: string) => {
  return relationship === businessRelationshipOptions.other ? otherRelationship : relationship
}

/**
 * The banking service expects a 9 digit zip to include a dash between characters 5 & 6
 */
export const addDashToPostalCode = (postalCode: string): string => {
  if (postalCode.length === wireConstants.RECIPIENT_POSTAL_CODE_MAX_LENGTH) {
    return postalCode.replace(/(\d{5})/, "$1-")
  }
  return postalCode
}

export const wireCutOffTimeInLocalTime = () => {
  const cutOffTimeInLocalTime = DateTime.fromISO(WIRE_CUT_OFF_TIME_ET_WITHOUT_OFFSET, {
    zone: EASTERN_TIME_ZONE,
  }).toLocal()

  //@ts-ignore
  return `${cutOffTimeInLocalTime.toLocaleString(DateTime.TIME_SIMPLE)} ${
    cutOffTimeInLocalTime.offsetNameShort
  }`
}

export const isBeforeWireCutOffTime = () => {
  const cutOffTimeInLocalTime = DateTime.fromISO(WIRE_CUT_OFF_TIME_ET_WITHOUT_OFFSET, {
    zone: EASTERN_TIME_ZONE,
  })
    .toLocal()
    .toISO()
  return isNowBefore(cutOffTimeInLocalTime)
}
