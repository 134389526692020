import React from "react"

import { TSubscriptionCancelScreenNames } from "../subscriptions-cancel-modal"
import { ChangePlanDetailsLayout } from "@features/subscriptions/shared/components/change-plan-details-layout"
import { PlanSection } from "@features/subscriptions/landing/components/plan-details-box/plan-details-box-layout"
import { Text } from "@components/primitive/text/text"
import { theme } from "@layouts/theme"
import { ButtonTypeEnum, IButtonProps } from "@components/primitive/button/button"
import { useTranslation } from "react-i18next"
import { useSubscriptionsData } from "@features/subscriptions/shared/hooks/use-subscriptions-data"
import { ErrorScreen } from "@components/composite/error-screen/error-screen"
import { Analytics } from "@core/analytics/actions"
import { events } from "@core/analytics/events"
import { getFormattedFullDate } from "@utils/dates"

export const RetentionScreen = ({
  setCurrentModalScreen,
  closeModal,
}: {
  setCurrentModalScreen: (screen: TSubscriptionCancelScreenNames) => void
  closeModal: () => void
}) => {
  const { t } = useTranslation()
  const { subscription, error } = useSubscriptionsData()

  if (!subscription || !subscription.nextBillingDate || error) {
    return <ErrorScreen size="large" />
  }

  const { plan, nextBillingDate } = subscription
  const { title: planTitle, shortTitle: planShortTitle, retentionFeatures } = plan

  const copy = {
    title: t("subscriptions.cancel.retention.title", { planTitle }),
    subtitle: t("subscriptions.cancel.retention.subtitle", {
      planShortTitle,
      nextBillingDate: getFormattedFullDate(nextBillingDate),
    }),
    confirmButtonText: t("subscriptions.cancel.retention.confirmButton", { planShortTitle }),
    goBackButtonText: t("subscriptions.cancel.retention.goBackButton", { planShortTitle }),
    bulletsHeading: t("subscriptions.cancel.retention.bulletsHeading"),
  }

  const navNextScreen = () => setCurrentModalScreen("CONFIRM_CANCELLATION")
  const buttons: IButtonProps[] = [
    {
      onClick: () => {
        Analytics.track(events.subscriptions.retentionContinueWithPlanClick, {
          planName: plan.name,
        })
        closeModal()
      },
      type: ButtonTypeEnum.TERTIARY,
      children: copy.goBackButtonText,
    },
    {
      onClick: () => {
        Analytics.track(events.subscriptions.retentionConfirmCancellationClick, {
          planName: plan.name,
        })
        navNextScreen()
      },
      type: ButtonTypeEnum.PRIMARY_BLACK,
      children: copy.confirmButtonText,
    },
  ]

  return (
    <ChangePlanDetailsLayout heading={copy.title} subtitle={copy.subtitle} buttons={buttons}>
      <PlanSection
        containerSx={{ pt: "24px", borderTop: `1px solid ${theme.colors.ui6}` }}
        heading={
          <Text tag="body" color={theme.colors.ui2}>
            {copy.bulletsHeading}
          </Text>
        }
        bullets={retentionFeatures}
      />
    </ChangePlanDetailsLayout>
  )
}
