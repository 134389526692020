import React from "react"
import { Text } from "@northone/ui"
import { Link } from "rebass"
import { theme } from "@layouts/theme"
import { DEPOSIT_ACCOUNT_AGREEMENT_LINK } from "@utils/constants"
import {
  PAYMENT_METHOD_LIMIT,
  PAYMENT_METHOD_LIMIT_NOTE,
  PAYMENT_METHOD_DEPOSIT_AGREEMENT,
} from "@features/move-money/utils/i18n-translations"

export const TransactionLimitsNote: React.FC = () => {
  return (
    <Text sx={{ color: theme.colors.ui2 }} tag="body-x-small">
      <Link
        target="_blank"
        href={DEPOSIT_ACCOUNT_AGREEMENT_LINK}
        sx={{ color: theme.colors.ui2, cursor: "pointer" }}
      >
        {PAYMENT_METHOD_LIMIT}
      </Link>{" "}
      {PAYMENT_METHOD_LIMIT_NOTE}{" "}
      <Link
        target="_blank"
        href={DEPOSIT_ACCOUNT_AGREEMENT_LINK}
        sx={{ color: theme.colors.ui2, cursor: "pointer" }}
      >
        {PAYMENT_METHOD_DEPOSIT_AGREEMENT}
      </Link>
    </Text>
  )
}
