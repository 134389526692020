import React from "react"
import { TeamOwner } from "@generated/graphql"
import { Box, SxStyleProp } from "rebass"
import { Text } from "@components/primitive/text/text"
import { TeamBankingListItem } from "@components/extended/list-items/team-banking/team-banking-list-item"
import { theme } from "@northone/ui"
import { useDispatch } from "react-redux"
import { teamBankingActions } from "./redux/actions"
import { Analytics } from "@core/analytics/actions"
import { events } from "@core/analytics/events"
type ITeamMemberListProps = {
  teamMembers: TeamOwner[]
  label: string
  hasBeenInvited?: boolean
  containerSx?: SxStyleProp
}
export const TeamMemberList = ({
  teamMembers,
  label,
  hasBeenInvited,
  containerSx,
}: ITeamMemberListProps) => {
  const dispatch = useDispatch()
  const selectTeamMember = (teamMember: TeamOwner) =>
    dispatch(teamBankingActions.setSelectedTeamMember(teamMember))
  if (!teamMembers.length) return null

  return (
    <Box sx={{ ...containerSx }}>
      <Text tag={"h5"} textColor={theme.colors.ui2} sx={{ marginBottom: 20 }}>
        {label}
      </Text>
      {teamMembers.map((teamMember) => {
        const onClick = () => {
          Analytics.track(events.settings.teamBanking.listItemClick)
          selectTeamMember(teamMember)
        }
        return (
          <TeamBankingListItem
            key={teamMember.id}
            teamMember={teamMember}
            hasBeenInvited={hasBeenInvited}
            onClick={onClick}
          />
        )
      })}
    </Box>
  )
}
