import { ApolloError } from "@apollo/client"
import { DateTime } from "luxon"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router"

import { ButtonTypeEnum } from "@components/primitive/button/button"
import { Analytics } from "@core/analytics/actions"
import { events } from "@core/analytics/events"
import { useAppSelector } from "@core/redux/utils"
import { useCardsQuery, useCardActivateMutation, ErrorCodeEnum } from "@generated/graphql"
import { RoutesEnum } from "@routers/types"
import { hasGQLErrorType } from "@utils/errors"
import { pollWithMaxRetries } from "@utils/poll-with-max-retries"

import { CardSetupScreensEnum } from "../card-setup-modal/card-setup-router"
import { CARDS } from "../operations.gql"
import { cardsActions } from "../redux/actions"
import { CARD_SHIPPING_DAYS } from "./constants"

export const useActivateCardDetails = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const closeModal = () => dispatch(cardsActions.setCardActivationModalOpen(false))

  const [expirationDate, setExpirationDate] = useState("")
  const [securityCode, setSecurityCode] = useState("")
  const [errorType, setErrorType] = useState<string | undefined>()
  const [expirationDateError, setExpirationDateError] = useState<string | undefined>()
  const [securityCodeError, setSecurityCodeError] = useState<string | undefined>()

  const cardId = useAppSelector((state) => state.cards.currentCardId)
  const businessId = useAppSelector((state) => state.activeBusiness.businessId)

  const { data } = useCardsQuery({ variables: { businessId } })
  const accountId = data?.business?.bankAccounts[0].id
  const cards = data?.business?.bankAccounts[0].cards
  const last4 = cards?.find((card) => card.id === cardId)?.cardNumber?.last4
  const securityCodeInvalid = trimMaskInput(securityCode).length < 3
  const expirationDateInvalid =
    !validateCardExpiry(expirationDate) || trimMaskInput(expirationDate).length < 4
  const buttonDisabled = securityCodeInvalid || expirationDateInvalid
  const GQLErrorMessage = errorType ? t(`cards.activate.errors.${errorType}`) : undefined

  const securityCodeOnBlur = () =>
    setSecurityCodeError(securityCodeInvalid ? t(`cards.activate.errors.securityCode`) : undefined)

  const securityCodeOnFocus = () => setSecurityCodeError(undefined)

  const expirationDateOnBlur = () =>
    setExpirationDateError(
      expirationDateInvalid ? t(`cards.activate.errors.expirationDate`) : undefined,
    )

  const expirationDateOnFocus = () => setExpirationDateError(undefined)

  const [activateCard, { loading }] = useCardActivateMutation({
    variables: {
      expirationDate: formatGQLExpirationDate(expirationDate),
      accountId: accountId || "",
      cardId: cardId || "",
      businessId,
      securityCode,
    },
    onCompleted: () => {
      pollWithMaxRetries([{ query: CARDS, variables: { businessId } }])
      dispatch(cardsActions.setCardAccountId(accountId || ""))
      dispatch(cardsActions.setCardSetupModalScreen(CardSetupScreensEnum.CARD_SETUP_PIN_SET))
    },
    onError: (err: ApolloError) =>
      setErrorType(
        hasGQLErrorType(err, ErrorCodeEnum.CARD_ACTIVATION_INVALID_INPUT)
          ? "invalidInput"
          : "generic",
      ),
  })
  const formButtons = [
    {
      children: t("buttons.continue"),
      onClick: () => {
        Analytics.track(events.cards.activate.enterDetails)
        activateCard()
      },
      isLoading: loading,
      disabled: buttonDisabled,
    },
    {
      children: t("cards.activate.cardNotReceived"),
      type: ButtonTypeEnum.SECONDARY,
      onClick: () => {
        Analytics.track(events.cards.activate.notArrived)
        closeModal()
        dispatch(cardsActions.setCardNotArrivedModalOpen(true))
      },
    },
  ]

  return {
    expirationDate,
    setExpirationDate,
    securityCode,
    setSecurityCode,
    last4,
    formButtons,
    expirationDateError,
    securityCodeError,
    securityCodeOnBlur,
    expirationDateOnBlur,
    securityCodeOnFocus,
    expirationDateOnFocus,
    GQLErrorMessage,
  }
}

export const useCardNotArrived = () => {
  const INTL_PATH = "cards.activateCard.cardNotArrived"

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const history = useHistory()
  const isOpen = useAppSelector((state) => state.cards.cardNotArrivedModalOpen)
  const closeModal = () => dispatch(cardsActions.setCardNotArrivedModalOpen(false))
  const cardShippingTime = CARD_SHIPPING_DAYS

  const title = t(`${INTL_PATH}.title`, { cardShippingTime })
  const subtitle = t(`${INTL_PATH}.subtitle`, { cardShippingTime })

  const buttons = [
    {
      onClick: () => {
        Analytics.track(events.cards.notArrived)
        closeModal()
        window.Intercom("show")
      },
      children: t(`${INTL_PATH}.button.primary`),
      type: ButtonTypeEnum.PRIMARY_BLUE,
    },
    {
      onClick: () => {
        closeModal()
        history.push(RoutesEnum.CARDS)
      },
      children: t(`${INTL_PATH}.button.secondary`),
      type: ButtonTypeEnum.SECONDARY,
    },
  ]

  return {
    title,
    subtitle,
    buttons,
    isOpen,
    closeModal,
  }
}

export const validateCardExpiry = (expirationDate: string) => {
  const month = expirationDate.slice(0, 2)
  const year = `20${expirationDate.slice(3, 5)}`
  const trimmedExpiry = trimMaskInput(expirationDate)
  if (Number(month) > 12 || (Number(month) < 1 && trimmedExpiry.length === 2)) {
    return false
  }
  if (trimmedExpiry.length === 4) {
    const currentDate = DateTime.local()
    const expiry = DateTime.fromObject({
      month: Number(month),
      year: Number(year),
      day: DateTime.local().day,
    })
    return expiry > currentDate
  }
  return true
}

const formatGQLExpirationDate = (expirationDate: string) =>
  `20${expirationDate.slice(3, 5)}-${expirationDate.slice(0, 2)}`

const trimMaskInput = (input: string) => input.replace("_", "").replace("/", "").trim()
