export const inputs = {
  streetAddressLine1: "Street address",
  streetAddressLine2: "Apartment, suite, etc. (optional)",
  city: "City",
  postalCode: "ZIP code",
  provinceState: "State",
  selectState: "Select state",
  accountNumber: "Account number",
  routingNumber: "Routing number",
  email: "Email",
  recipientName: "Recipient name",
  recipientAddress: "Recipient address",
  bankAddress: "Bank address",
  bankName: "Bank name",
  payeeName: "Payee name",
  payeeDetails: "Payee details",
  phoneNumber: "Phone number",
}
