import React from "react"
import { Text } from "@components/primitive/text/text"
import { theme } from "@layouts/theme"
import { Pill } from "@components/primitive/pill/pill"

interface FeePillProps {
  children?: React.ReactNode
  isFilled?: boolean
  filledBackgroundColor?: string
  filledTextColor?: string
}

export const FeePill = ({
  isFilled,
  children,
  filledBackgroundColor = theme.colors.blue10,
  filledTextColor = theme.colors.blue100,
}: FeePillProps) => {
  return (
    <Pill
      sx={{
        backgroundColor: isFilled ? filledBackgroundColor : "transparent",
        border: isFilled ? `1px solid transparent` : `1px solid ${theme.colors.ui4}`,
        minWidth: isFilled ? 82 : undefined,
      }}
    >
      <Text
        tag={"h5"}
        sx={{ lineHeight: "14px" }}
        textColor={isFilled ? filledTextColor : theme.colors.ui2}
      >
        {children}
      </Text>
    </Pill>
  )
}
