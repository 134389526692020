import React from "react"
import { IErrorScreenProps, ErrorScreen } from "@components/composite/error-screen/error-screen"
import { Panel } from "@components/primitive/panel/panel"
import { theme } from "@layouts/theme"

export const ErrorPanel: React.FC<Omit<IErrorScreenProps, "size">> = (props) => (
  <Panel
    sx={{
      paddingX: theme.spacing.transactionPanel.x,
      paddingY: theme.spacing.transactionPanel.y,
      height: 500,
    }}
  >
    <ErrorScreen {...props} size="small" />
  </Panel>
)
