import React, { ReactChild } from "react"
import { useTranslation } from "react-i18next"
import { Modal } from "@components/extended/overlay/modal"
import { Button, ButtonTypeEnum } from "@components/primitive/button/button"
import { theme } from "@layouts/theme"
import { DownloadBlock } from "./download"

declare global {
  interface Window {
    branch: any
  }
}

interface IDownloadModalProps {
  isOpen: boolean
  closeModal: () => void
  children?: ReactChild
  linkDataFeature: string
  downloadClickEvent: string
}

export const DownloadModal: React.FC<IDownloadModalProps> = ({
  isOpen,
  closeModal,
  children,
  linkDataFeature,
  downloadClickEvent,
}) => {
  const { t } = useTranslation()

  return (
    <Modal zIndex={theme.zIndex.flowModal + 1} isOpen={isOpen} onOutsideClick={closeModal}>
      {children}
      <DownloadBlock linkDataFeature={linkDataFeature} downloadClickEvent={downloadClickEvent} />
      <Button
        onClick={closeModal}
        type={ButtonTypeEnum.SECONDARY}
        sx={{ marginTop: 24, alignSelf: "flex-start" }}
      >
        {t("buttons.dismiss")}
      </Button>
    </Modal>
  )
}
