import React from "react"
import { Box, BoxProps } from "rebass"

interface IFlexLayoutProps extends BoxProps {
  boxRef?: React.RefObject<HTMLDivElement>
  displayNone?: boolean
}

/**
 * Defines a flex container with column as the main-axis
 */
export const FlexColumnLayout: React.FC<IFlexLayoutProps> = (props) => (
  //@ts-ignore
  <Box
    {...props}
    sx={{
      ...props.sx,
      display: "flex",
      flexDirection: "column",
    }}
    ref={props.boxRef}
  >
    {props.children}
  </Box>
)

/**
 * Defines a flex container with row as the main-axis
 */
export const FlexRowLayout: React.FC<IFlexLayoutProps> = (props) => (
  //@ts-ignore
  <Box
    {...props}
    sx={{
      ...props.sx,
      display: props.displayNone ? "none" : "flex",
      flexDirection: "row",
    }}
    ref={props.boxRef}
  >
    {props.children}
  </Box>
)
