import React from "react"
import { Box } from "rebass"
import { Text, TTextTag } from "@components/primitive/text/text"
import { BULLET_SYMBOL } from "@utils/constants"

export interface IUnorderedBulletListProps {
  bulletPoints: string[]
  textTag: TTextTag
  textColor: string
}

export const UnorderedBulletedList: React.FC<IUnorderedBulletListProps> = ({
  bulletPoints,
  textTag,
  textColor,
}) => {
  return (
    <>
      {bulletPoints.map((bullet, idx) => (
        <Box key={idx} style={{ paddingLeft: 10 }}>
          <Text sx={{ display: "flex", flexDirection: "row" }} tag={textTag} textColor={textColor}>
            {BULLET_SYMBOL}
            <Text sx={{ paddingLeft: 10 }} tag={textTag} textColor={textColor}>
              {bullet}
            </Text>
          </Text>
        </Box>
      ))}
    </>
  )
}
