import React from "react"
import { useTranslation } from "react-i18next"
import moment from "moment"

import { Text } from "@northone/ui"
import {
  isCardTransaction,
  isCheckDeposit,
  isMailedCheckRefunded,
  isMailedCheckTransaction,
  isOutgoing,
  isOutgoingWire,
  isTransactionActive,
  isTransactionInitiated,
  isTransactionPosted,
  TTransactionPanelStatus,
  TTransactionPanelType,
} from "./utils"
import { TransactionPanelItem } from "./base-transaction-panel"
import { TransactionTimeline, TTimeline } from "./transaction-timeline"

interface IProps {
  type: TTransactionPanelType
  amount: number
  status: TTransactionPanelStatus
  createdAt: string
  postedAt: string | undefined
}

export const TransactionPanelDate = ({ type, amount, status, createdAt, postedAt }: IProps) => {
  const { t } = useTranslation()

  let timelineType: TTimeline | undefined = undefined

  const shouldHaveMailedCheckTimeline =
    isTransactionActive(status) && !isMailedCheckRefunded(type) && !isTransactionInitiated(status)

  if (isCardTransaction(type) && isOutgoing(amount)) {
    timelineType = "card_auth"
  }
  if (isOutgoingWire(amount, type)) {
    timelineType = "wire"
  }
  if (isCheckDeposit(type) && !isTransactionPosted(status)) {
    timelineType = "check_deposit"
  }
  if (isMailedCheckTransaction(type) && shouldHaveMailedCheckTimeline) {
    timelineType = "mailed_check"
  }

  if (timelineType) {
    return (
      <TransactionPanelItem label={t("dashboard.transactionPanel.date")}>
        <TransactionTimeline
          timelineType={timelineType}
          createdAt={createdAt}
          postedAt={postedAt}
          isTransactionPosted={isTransactionPosted(status)}
        />
      </TransactionPanelItem>
    )
  }

  return (
    <TransactionPanelItem label={t("dashboard.transactionPanel.date")}>
      <Text tag="body">{moment(createdAt).format("MMMM DD, YYYY h:mm a")}</Text>
    </TransactionPanelItem>
  )
}
