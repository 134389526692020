import React from "react"
import { Text } from "rebass"

import { FlexColumnLayout } from "@layouts/flex"
import { theme } from "@layouts/theme"
import { Card } from "@generated/graphql"

import { CardSummary } from "../card-summary/card-summary"

type TCardsList = {
  cards: Card[]
  user?: { id?: string; firstName?: string; lastName?: string }
}[]

interface ICardsListProps {
  cards: Card[]
}
export const CardList: React.FC<ICardsListProps> = ({ cards }) => {
  const groupCardsPerUser = cards.reduce<TCardsList>((groups: TCardsList, card: Card) => {
    const existingGroup = groups.find((g) => g.user?.id === card.user?.id)

    const newGroups = groups.map((group) => {
      if (group === existingGroup) {
        return {
          ...group,
          cards: [...group.cards, card],
        }
      }
      return group
    })

    if (!existingGroup) {
      newGroups.push({
        cards: [card],
        user: {
          id: card.user?.id || undefined,
          firstName: card.user?.firstName || undefined,
          lastName: card.user?.lastName || undefined,
        },
      })
    }

    return newGroups
  }, [])

  return (
    <>
      {groupCardsPerUser.map((group, idx) => (
        <FlexColumnLayout key={idx}>
          <Text sx={{ marginBottom: 20, ...theme.textStyles["body-bold"] }}>
            {group.user?.firstName} {group.user?.lastName}
          </Text>
          {group.cards.map((card) => (
            <CardSummary key={card.id} cardId={card.id} />
          ))}
        </FlexColumnLayout>
      ))}
    </>
  )
}
