/**
 * Clears all segment traits from local storage after Analytics.identify call
 */

export interface ISegmentTraits {
  email?: string | null
  businessId: string
}
export const clearSegmentLocalStorage = (traits: ISegmentTraits) => {
  const ajsUserTraitsString = window.localStorage.getItem("ajs_user_traits")
  if (!ajsUserTraitsString) {
    return
  }
  const ajsUserTraits = JSON.parse(ajsUserTraitsString)
  Object.keys(traits).forEach((trait) => {
    delete ajsUserTraits[trait]
  })
  window.localStorage.setItem("ajs_user_traits", JSON.stringify(ajsUserTraits))
}
