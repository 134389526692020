import React from "react"
import { FlexColumnLayout, theme } from "@northone/ui"
import { Text } from "@components/primitive/text/text"
import { Button, ButtonTypeEnum } from "@components/primitive/button/button"
import { useTranslation } from "react-i18next"

type ITeamBankingErrorProps = {
  retry: () => void
}

export const TeamBankingError = ({ retry }: ITeamBankingErrorProps) => {
  const { t } = useTranslation()
  return (
    <FlexColumnLayout sx={{ width: "100%", alignItems: "center", padding: "40px" }}>
      <Text sx={{ marginBottom: "8px" }} tag={"h3Serif"}>
        {t("errors.genericScreen.title")}
      </Text>
      <Text sx={{ color: theme.colors.ui2, marginBottom: "20px" }} tag={"body-large"}>
        {t("settings.pages.teamBanking.errorSubtitle")}
      </Text>
      <Button type={ButtonTypeEnum.SECONDARY} onClick={retry}>
        {t("errors.genericErrorLoading.button")}
      </Button>
    </FlexColumnLayout>
  )
}
