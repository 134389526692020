import React from "react"
import { UpgradePlanDetails } from "@features/subscriptions/upgrade/upgrade-plan-details"
import { useDispatch } from "react-redux"
import { invoicePaymentsActions } from "@features/invoice-payments/redux/actions"
import { useAppSelector } from "@core/redux/utils"
import { getInvoicePayments } from "@features/invoice-payments/redux/selector"
import { useTransactionSubscriptionUpsell } from "@features/subscriptions/shared/hooks/use-transaction-subscription-upsell"
import { getUpsellTransactionTypeFromInvoicePaymentMethod } from "./upsell-button"
import { ErrorScreen } from "@components/composite/error-screen/error-screen"
import { appActions } from "@core/redux/app/actions"
import { useTranslation } from "react-i18next"
import { formatPlanNameString } from "@features/subscriptions/utils"

export const InvoiceUpsellPlanConfirm = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const paymentMethod = useAppSelector(getInvoicePayments).invoicePaymentDetails?.paymentMethod
  const transactionType = getUpsellTransactionTypeFromInvoicePaymentMethod(paymentMethod)
  const { upsellPlan } = useTransactionSubscriptionUpsell({ transactionType })
  const upsellPlanName = formatPlanNameString(upsellPlan?.planName)

  if (!upsellPlanName) return <ErrorScreen size="large" />

  return (
    <UpgradePlanDetails
      upsellPlanName={upsellPlanName}
      navigateBack={() => dispatch(invoicePaymentsActions.navigate("SUBSCRIPTION_UPSELL_DETAILS"))}
      navigateNext={() => {
        dispatch(invoicePaymentsActions.navigate("PAYMENT_REVIEW"))
        dispatch(appActions.setNotificationMessage(t("subscriptions.planUpdatedToast")))
      }}
    />
  )
}
