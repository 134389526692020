export const accountStatements = {
  navTitle: "Account Statements",
  oneMonth: {
    title: "Exporting monthly statements",
    subTitle:
      "Statements become available within 5 to 7 days of a month ending. You can download them here, in PDF format.",
    export: "Export this statement",
  },
  zeroState: {
    title: "Your monthly statement",
    subTitle:
      /* tslint:disable:max-line-length */
      "Export your monthly NorthOne Account statement. This feature will be unlocked at the end of the month.",
    statementNotReadyTitle: "Your statement isn't ready yet",
    statementNotReadySubtitle:
      "It will be available by the {{dayOfMonth}} of the month after you opened your account.",
  },
  header: "New Statements are available by the {{dayOfMonth}} of the following month.",
  statementItemTitle: "{{month}}",
  statementItemSubtitle: "Closing balance: {{balance}}, In: {{incoming}}, Out: {{outgoing}}",
  historicalData: "See **account statements** from before March 2021",
}
