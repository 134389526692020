/**
 *  Split time in seconds into days, hours and minutes
 *  @example splitTime(100000) => { days: 1, hours: 3, minutes: 56 }
 *  @param {number} timeInSeconds
 *  @returns {{days: number, hours: number, minutes: number}}
 */
export const splitTime = (timeInSeconds: number) => {
  const fullDays = Math.floor(timeInSeconds / (60 * 60 * 24))
  const timeInSecondsWithoutFullDays = timeInSeconds - fullDays * 60 * 60 * 24
  const fullHours = Math.floor(timeInSecondsWithoutFullDays / (60 * 60))
  const timeInSecondWithoutFullHours = timeInSecondsWithoutFullDays - fullHours * 60 * 60
  const ceiledMinutes = Math.ceil(timeInSecondWithoutFullHours / 60)
  return {
    days: fullDays,
    hours: fullHours,
    minutes: ceiledMinutes,
  }
}
