import React from "react"
import { Panel } from "@components/primitive/panel/panel"
import { Text } from "@components/primitive/text/text"
import { useTranslation } from "react-i18next"
import { CounterpartyAvatar } from "@components/extended/avatar/counterparty-avatar"
import { theme } from "@layouts/theme"
import { TransactionPanelItem } from "../transaction-panel/base-transaction-panel"
import { WithHighlight } from "@components/primitive/highlight/highlight"
import { formatMoney } from "accounting"
import { ScheduleFrequencyEnum } from "@generated/graphql"
import { scheduleFrequencyToI18n } from "@utils/mapping/schedules.mapping"

interface IPreviewSubAccountPanelProps {
  name?: string
  initialBalance?: string
  scheduledAmount?: string
  scheduledFrequency?: ScheduleFrequencyEnum | null
  percentage?: string
  withTitle?: boolean
}

export const PreviewSubAccountPanel: React.FC<IPreviewSubAccountPanelProps> = ({
  name,
  initialBalance,
  scheduledAmount,
  scheduledFrequency,
  percentage,
  withTitle,
}) => {
  const { t } = useTranslation()
  const subAccountName = name || t("accounts.createSubAccount.preview.subAccount") || ""

  const initialBalanceAsNumber = Number(initialBalance?.replace(/,/g, ""))

  return (
    <Panel sx={{ padding: "28px 24px" }}>
      {withTitle ? (
        <Text textColor={theme.colors.ui2} sx={{ marginBottom: 16 }} tag="h5">
          {t("accounts.createSubAccount.preview.title")}
        </Text>
      ) : null}
      <CounterpartyAvatar counterpartyName={subAccountName} />
      <Text
        textColor={name ? theme.colors.ui1 : theme.colors.ui2}
        sx={{ marginY: 16 }}
        tag="h3Serif"
      >
        {subAccountName}
      </Text>
      <TransactionPanelItem label={t("accounts.createSubAccount.preview.balance")}>
        <WithHighlight sx={{ marginTop: "9px" }} color={theme.colors.brown10}>
          <Text
            textColor={initialBalanceAsNumber ? theme.colors.brown100 : theme.colors.ui2}
            tag="body-large"
            sx={{ fontSize: "24px" }}
          >
            {formatMoney(Math.abs(initialBalanceAsNumber || 0))}
          </Text>
        </WithHighlight>
      </TransactionPanelItem>
      <TransactionPanelItem label={t("accounts.createSubAccount.preview.rules")}>
        {scheduledAmount && scheduledFrequency && (
          <Text tag="body">
            {t("accounts.createSubAccount.preview.fixedAmount", {
              amount: formatMoney(scheduledAmount),
              frequency: scheduleFrequencyToI18n[scheduledFrequency].toLowerCase(),
            })}
          </Text>
        )}
        {percentage && (
          <Text tag="body">
            {t("accounts.createSubAccount.preview.percentage", {
              percentage,
            })}
          </Text>
        )}
        {!(scheduledAmount && scheduledFrequency) && !percentage && (
          <Text tag="body">{t("accounts.createSubAccount.preview.noRules")}</Text>
        )}
      </TransactionPanelItem>
    </Panel>
  )
}
