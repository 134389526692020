import React, { useRef } from "react"
import { Box } from "rebass"
import { theme } from "@layouts/theme"
import { AnimatePresence } from "@components/animations/animate-presence/animation-presence"
import { MotionStyle } from "framer-motion"

interface WithDropdownMenuProps {
  children?: React.ReactNode
  visible: boolean
  content: React.ReactNode
  customWidth?: number
  alignment?: "left" | "right"
  style?: MotionStyle
}

export const WithDropdownMenu = ({
  children,
  content,
  visible,
  customWidth,
  alignment = "left",
  style,
}: WithDropdownMenuProps) => {
  const ref = useRef<HTMLDivElement>(null)

  const scrollIntoView = () => {
    if (ref && ref.current) {
      const bottom = ref.current.getBoundingClientRect().bottom
      if (bottom > window.innerHeight) {
        ref.current.scrollIntoView({ behavior: "smooth", block: "end" })
      }
    }
  }

  return (
    <Box sx={{ position: "relative", flex: 1 }}>
      <Box sx={{ width: "100%" }}>{children}</Box>
      <Box
        ref={ref}
        sx={{
          position: "absolute",
          width: customWidth,
          left: alignment === "left" || !customWidth ? 0 : undefined,
          right: alignment === "right" || !customWidth ? 0 : undefined,
          backgroundColor: theme.colors.ui7,
          zIndex: theme.zIndex.flowModal + 1,
          scrollMarginBottom: 15,
          marginBottom: 15,
        }}
      >
        <AnimatePresence
          style={{
            width: "100%",
            boxShadow: "0px 4px 16px rgba(110, 110, 110, 0.12)",
            ...style,
          }}
          animation="expand"
          onAnimationComplete={scrollIntoView}
          onLayoutAnimationComplete={() => setTimeout(scrollIntoView, 100)}
        >
          {visible && <Box>{content}</Box>}
        </AnimatePresence>
      </Box>
    </Box>
  )
}
