import { useState } from "react"
import { DateTime } from "luxon"
import { v4 as uuid } from "uuid"

import { selectActiveBusinessID } from "@core/active-business/redux/selectors"
import { useAppSelector } from "@core/redux/utils"
import {
  Maybe,
  ListInvoiceAccountsPayableQuery,
  ListInvoiceAccountsPayableQueryVariables,
  useAchCutoffTimesQuery,
  useCreateInvoiceAccountsPayablePaymentMutation,
  useCreateInvoiceAccountsPayableScheduledPaymentMutation,
  useGetInvoiceAccountInfoQuery,
  useInvoicePaymentsContactQuery,
  useListInvoiceAccountsPayableQuery,
  useListInvoiceAccountsPayableLazyQuery,
  useUpdateInvoiceContactWirePaymentDetailsMutation,
} from "@generated/graphql"
import { getInvoicePayments } from "@features/invoice-payments/redux/selector"
import {
  IInvoiceDetailsState,
  IPaymentDetailsState,
  TInvoiceAccountsPayable,
} from "@features/invoice-payments/redux/reducer"
import { pollWithMaxRetries } from "@utils/poll-with-max-retries"
import { INVOICE_PAYMENT_CONTACT_QUERY, LIST_INVOICES } from "./operations.gql"
import { TWireContactPaymentDetails } from "@features/invoice-payments/invoice-modal/payment-details/payment-method/wire-payment-details"
import { EASTERN_TIME_ZONE, getDateTimeInEasternTimezone } from "@utils/dates"

export const useListInvoiceAccountsPayable = ({
  queryVariables,
}: {
  queryVariables: Omit<ListInvoiceAccountsPayableQueryVariables, "businessId" | "pagination">
}) => {
  const businessId = useAppSelector(selectActiveBusinessID)

  const [invoices, setInvoices] = useState<TInvoiceAccountsPayable[]>([])
  const [cursor, setCursor] = useState<Maybe<string> | undefined>()

  const onFetchInvoicesCompleted = (
    data: ListInvoiceAccountsPayableQuery,
    appendInvoices = false,
  ) => {
    const fetchedInvoices = data?.listInvoiceAccountsPayable?.invoices || []
    const nextCursor = data?.listInvoiceAccountsPayable?.pagination?.cursor

    setInvoices(appendInvoices ? invoices.concat(fetchedInvoices) : fetchedInvoices)
    setCursor(nextCursor)
  }

  const variables: ListInvoiceAccountsPayableQueryVariables = {
    businessId,
    ...queryVariables,
    pagination: {
      cursor: null,
    },
  }

  const {
    loading: invoicesLoading,
    error: invoicesError,
    refetch,
  } = useListInvoiceAccountsPayableQuery({
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables,
    onCompleted: (data) => onFetchInvoicesCompleted(data),
  })

  const [fetchMoreInvoices, { loading: moreInvoicesLoading, error: moreInvoicesError }] =
    useListInvoiceAccountsPayableLazyQuery({
      fetchPolicy: "network-only",
      onCompleted: (data) => onFetchInvoicesCompleted(data, true),
    })

  const poll = async () => {
    await pollWithMaxRetries([
      {
        query: LIST_INVOICES,
        variables,
      },
    ])
  }

  return {
    invoices,
    invoicesLoading,
    invoicesError,
    cursor,
    hasMoreInvoices: Boolean(cursor),
    fetchMoreInvoices,
    moreInvoicesLoading,
    moreInvoicesError,
    poll,
    refetch,
  }
}

export const useInvoicePaymentsContact = () => {
  const businessId = useAppSelector(selectActiveBusinessID)
  const invoice = useAppSelector(getInvoicePayments)

  const contactId = invoice.invoiceDetails?.contactId ?? ""

  const {
    data,
    loading: isContactDataLoading,
    error,
    refetch,
  } = useInvoicePaymentsContactQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      businessId,
      contactId,
    },
  })

  const contact = data?.business?.contact

  return {
    contact,
    isContactDataLoading,
    error,
    refetch,
  }
}

export const useBankAccountInfo = (invoicePaymentSubAccountId?: string) => {
  const businessId = useAppSelector(selectActiveBusinessID)

  const {
    data,
    loading: isLoading,
    error,
    refetch,
  } = useGetInvoiceAccountInfoQuery({
    variables: { businessId },
  })

  const subAccounts = data?.business?.bankAccount?.subAccounts
  const subAccount =
    subAccounts && subAccounts.find((subAccount) => subAccount.id === invoicePaymentSubAccountId)

  return {
    subAccounts,
    subAccount,
    isLoading,
    error,
    refetch,
    accountId: data?.business?.bankAccount?.id,
    accountNumber: data?.business?.bankAccount?.accountNumber,
    limits: data?.business?.bankAccount?.capabilities,
    accountAvailableBalance: data?.business?.bankAccount?.availableBalance,
    isWireEnabled: data?.business?.bankAccount?.capabilities?.domesticWire.enabled,
  }
}

export const useCreateInvoicePayment = () => {
  const [idempotencyKey] = useState(uuid())
  const invoice = useAppSelector(getInvoicePayments)
  const businessId = useAppSelector(selectActiveBusinessID)

  const invoicePaymentDetails = invoice.invoicePaymentDetails as IPaymentDetailsState
  const invoiceDetails = invoice.invoiceDetails as IInvoiceDetailsState

  const [createInvoiceAccountsPayablePayment, { loading: isCreateInvoicePaymentLoading }] =
    useCreateInvoiceAccountsPayablePaymentMutation({})

  const createInvoicePaymentMutation = async () => {
    if (!invoicePaymentDetails.paymentMethodDetails.type || !invoiceDetails.invoiceId) return

    await createInvoiceAccountsPayablePayment({
      variables: {
        businessId,
        invoiceId: invoiceDetails.invoiceId,
        data: {
          amount: Number(invoicePaymentDetails.paymentAmount),
          subAccountId: invoicePaymentDetails.subAccountId,
          paymentMethodDetails: invoicePaymentDetails.paymentMethodDetails,
          idempotencyKey,
        },
      },
    })
  }

  return {
    createInvoicePaymentMutation,
    isCreateInvoicePaymentLoading,
  }
}

export const useCreateInvoiceScheduledPayment = () => {
  const [idempotencyKey] = useState(uuid())
  const invoice = useAppSelector(getInvoicePayments)
  const businessId = useAppSelector(selectActiveBusinessID)
  const { accountId } = useBankAccountInfo()

  const invoicePaymentDetails = invoice.invoicePaymentDetails as IPaymentDetailsState
  const invoiceDetails = invoice.invoiceDetails as IInvoiceDetailsState

  const [
    createInvoiceAccountsPayableScheduledPayment,
    { loading: isCreateInvoiceScheduledPaymentLoading },
  ] = useCreateInvoiceAccountsPayableScheduledPaymentMutation({})

  const createInvoiceScheduledPaymentMutation = async () => {
    if (!accountId || !invoiceDetails.invoiceId) return

    await createInvoiceAccountsPayableScheduledPayment({
      variables: {
        businessId,
        invoiceId: invoiceDetails.invoiceId,
        data: {
          amount: Number(invoicePaymentDetails.paymentAmount),
          subAccountId: invoicePaymentDetails.subAccountId,
          paymentMethodDetails: invoicePaymentDetails.paymentMethodDetails,
          startDate: invoicePaymentDetails.paymentDate,
          accountId: accountId,
          period: "once",
          idempotencyKey,
        },
      },
    })
  }

  return {
    createInvoiceScheduledPaymentMutation,
    isCreateInvoiceScheduledPaymentLoading,
  }
}

export const useUpdateContactWithWireDetails = ({ onError }: { onError?: () => void }) => {
  const businessId = useAppSelector(selectActiveBusinessID)
  const invoice = useAppSelector(getInvoicePayments)

  const contactId = invoice.invoiceDetails?.contactId

  const [contactDomesticWireUpdate, { error: contactDomesticWireUpdateError }] =
    useUpdateInvoiceContactWirePaymentDetailsMutation({
      onCompleted: () => {
        pollWithMaxRetries([
          { query: INVOICE_PAYMENT_CONTACT_QUERY, variables: { businessId, contactId } },
        ])
      },
      onError,
    })

  const updateContactWithWireDetailsMutation = async (
    contactPaymentDetails: TWireContactPaymentDetails | undefined,
  ) => {
    if (!contactId || !contactPaymentDetails) return

    const {
      name,
      address,
      relationship,
      wirePaymentDetails: { bankName, accountNumber, routingNumber },
    } = contactPaymentDetails

    const response = await contactDomesticWireUpdate({
      variables: {
        data: {
          businessId,
          contactId,
          name,
          address,
          relationship,
          wirePaymentDetails: {
            bankName,
            accountNumber,
            routingNumber,
          },
        },
      },
    })
    return response
  }

  return { updateContactWithWireDetailsMutation, contactDomesticWireUpdateError }
}

export const useAchCutoffTimes = ({ onError }: { onError?: () => void }) => {
  const businessId = useAppSelector(selectActiveBusinessID)

  const {
    loading: isAchCutoffTimesLoading,
    data: achCutoffTimesData,
    error: achCutoffTimesError,
  } = useAchCutoffTimesQuery({
    fetchPolicy: "network-only",
    variables: {
      businessId,
    },
    onError,
  })

  const { isSameDayAvailable, nextCutoff, secondsToNextCutoff } =
    achCutoffTimesData?.achCutoffTimes || {}

  const achCutoffTime = nextCutoff
    ? DateTime.fromISO(nextCutoff, { zone: EASTERN_TIME_ZONE })
    : getDateTimeInEasternTimezone().plus({ seconds: secondsToNextCutoff })

  return {
    isAchCutoffTimesLoading,
    achCutoffTimesData,
    achCutoffTimesError,
    isSameDayAvailable,
    achCutoffTime,
  }
}
