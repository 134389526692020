import React from "react"
import i18n from "@i18n/i18n"
import { SxStyleProp } from "rebass"
import { theme } from "@layouts/theme"
import { Pill } from "@components/primitive/pill/pill"
import { Text } from "@components/primitive/text/text"
import { InvoiceStatus, InvoicePaymentStatus } from "@generated/graphql"
import { TInvoiceAccountsPayable } from "@features/invoice-payments/redux/reducer"
import { FlexRowLayout } from "@northone/ui"

interface IInvoiceStatusPillProps {
  text: string
  backgroundColor: string
  border?: string
}

const getInvoiceStatusPillsProps = (
  invoice: TInvoiceAccountsPayable,
  showMinimalView?: boolean,
): IInvoiceStatusPillProps[] => {
  const { status: invoiceStatus, paymentStatus, paidAmount } = invoice

  const invoiceStatusPillPropsArray: IInvoiceStatusPillProps[] = []

  switch (invoiceStatus) {
    case InvoiceStatus.UNPAID: {
      switch (paymentStatus) {
        case InvoicePaymentStatus.PROCESSING:
          invoiceStatusPillPropsArray.push({
            text: i18n.t("invoicePayments.status.processing"),
            backgroundColor: theme.colors.blackTint3,
          })
          break

        case InvoicePaymentStatus.FAILED:
          invoiceStatusPillPropsArray.push({
            text: i18n.t("invoicePayments.status.failed"),
            backgroundColor: theme.colors.errorTint,
            border: `1px solid ${theme.colors.errorPrimary}`,
          })
          break

        case InvoicePaymentStatus.SCHEDULED:
          if (paidAmount && !showMinimalView) {
            invoiceStatusPillPropsArray.push({
              text: i18n.t("invoicePayments.status.partiallyPaid"),
              backgroundColor: theme.colors.goldTint3,
            })
          }

          invoiceStatusPillPropsArray.push({
            text: i18n.t("invoicePayments.status.scheduled"),
            backgroundColor: theme.colors.greenTint3,
          })

          break

        default:
          if (paidAmount) {
            invoiceStatusPillPropsArray.push({
              text: i18n.t("invoicePayments.status.partiallyPaid"),
              backgroundColor: theme.colors.goldTint3,
            })
          } else {
            invoiceStatusPillPropsArray.push({
              text: i18n.t("invoicePayments.status.draft"),
              backgroundColor: theme.colors.blackTint3,
            })
          }
      }
      break
    }

    case InvoiceStatus.PAID:
      invoiceStatusPillPropsArray.push({
        text: i18n.t("invoicePayments.status.paid"),
        backgroundColor: theme.colors.greenTint3,
      })
      break

    case InvoiceStatus.ARCHIVED:
      invoiceStatusPillPropsArray.push({
        text: i18n.t("invoicePayments.status.archived"),
        backgroundColor: theme.colors.blackTint3,
      })
      break

    default:
      invoiceStatusPillPropsArray.push({
        text: invoiceStatus,
        backgroundColor: theme.colors.blackTint3,
      })
  }

  return invoiceStatusPillPropsArray
}

interface IInvoiceStatusPillsProps {
  invoice: TInvoiceAccountsPayable
  showMinimalView?: boolean
  sx?: SxStyleProp
}

export const InvoiceStatusPills = ({ invoice, showMinimalView, sx }: IInvoiceStatusPillsProps) => {
  const invoiceStatusPillPropsArray = getInvoiceStatusPillsProps(invoice, showMinimalView)

  return (
    <FlexRowLayout alignItems="start">
      {invoiceStatusPillPropsArray.map((invoiceStatusPillProps, index) => (
        <Pill
          key={index}
          sx={{
            backgroundColor: invoiceStatusPillProps.backgroundColor,
            border: invoiceStatusPillProps.border,
            margin: "auto 5px",
            minHeight: "25px",
            alignItems: "center",
            width: "auto",
            ...sx,
          }}
        >
          <Text
            tag="body"
            fontSize="8px"
            letterSpacing="1px"
            fontWeight="500"
            lineHeight="1"
            textAlign="center"
          >
            {invoiceStatusPillProps.text.toUpperCase()}
          </Text>
        </Pill>
      ))}
    </FlexRowLayout>
  )
}
