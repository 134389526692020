import { images } from "@assets/images/images"
import { theme } from "@layouts/theme"
import React, { ReactElement } from "react"
import { Box, SxStyleProp } from "rebass"
import { Text } from "../text/text"

export interface IToggleSwitch {
  checked?: boolean
  disabled?: boolean
  containerSx?: SxStyleProp
  label: string | ReactElement
  onClick?: () => void
}

export const ToggleSwitch: React.FC<IToggleSwitch> = ({
  checked = false,
  disabled = false,
  containerSx,
  label,
  onClick,
}) => {
  const labelComponent = typeof label === "string" ? <Text tag="body">{label}</Text> : label

  const toggle = () => {
    if (!onClick || disabled) return
    onClick()
  }

  return (
    <Box as="label" sx={{ display: "flex", maxWidth: "fit-content", ...containerSx }}>
      {labelComponent}
      <Box
        as="button"
        type="button"
        role="switch"
        tx="forms"
        variant="switch"
        aria-checked={checked}
        aria-disabled={disabled}
        onClick={toggle}
        onKeyDown={(e) => {
          // When the user presses the left or right arrow key, select the
          // we want to trigger the same behavior as a click event
          if (e.key === "ArrowLeft" || e.key === "ArrowRight") {
            toggle()
          }
        }}
        sx={{
          appearance: "none",
          m: "0 0 0 0.5rem",
          p: 0,
          width: 40,
          height: 24,
          bg: theme.colors.ui4,
          border: "2px solid",
          borderColor: theme.colors.ui4,
          borderRadius: 9999,
          cursor: "pointer",
          "&[aria-checked=true]": {
            bg: theme.colors.blue100,
            borderColor: theme.colors.blue100,
          },
          "&[aria-disabled=true]": {
            bg: theme.colors.ui4,
            borderColor: theme.colors.ui4,
            cursor: "auto",
          },
          ":focus": {
            outline: "none",
            boxShadow: `0 0 0 2px ${theme.focusOutline}`,
          },
        }}
      >
        <Box
          aria-hidden
          style={{
            transform: checked ? "translateX(16px)" : "translateX(0)",
          }}
          sx={{
            width: 20,
            height: 20,
            borderRadius: 9999,
            backgroundColor: "white",
            backgroundImage: checked ? `url('${images.icons.checkmarkBlue}')` : "",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.15), 0px 3px 1px rgba(0, 0, 0, 0.06)",
            transitionProperty: "transform",
            transitionTimingFunction: "ease-out",
            transitionDuration: "0.1s",
            variant: "forms.switch.thumb",
          }}
        />
      </Box>
    </Box>
  )
}
