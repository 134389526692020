import React from "react"

import { BaseLayout } from "@layouts/base-layout"
import { theme } from "@layouts/theme"
import { FlexColumnLayout, Text } from "@northone/ui"
import { OneOrMoreNullableElements } from "@typedefs/types"
import { useTranslation } from "react-i18next"
import { SxStyleProp } from "rebass"

const pageContainerStyles: SxStyleProp = { gap: 4 }
const subtitleStyles: SxStyleProp = { color: theme.colors.ui2 }
const titleStyles: SxStyleProp = { color: theme.colors.ui1 }
const titleContainerStyles: SxStyleProp = { gap: 3 }

const i18nKeyPaths = {
  pageTitle: "pageTitles.settings.autoTopUp",
  title: "autoTopUps.landing.title",
  subtitle: "autoTopUps.landing.subtitle",
}

export const TopUpSettingsLayout = ({ children }: { children: OneOrMoreNullableElements }) => {
  const { t } = useTranslation()
  return (
    <BaseLayout pageTitle={t(i18nKeyPaths.pageTitle)}>
      <FlexColumnLayout sx={pageContainerStyles}>
        <FlexColumnLayout sx={titleContainerStyles}>
          <Text tag="h2" sx={titleStyles}>
            {t(i18nKeyPaths.title)}
          </Text>
          <Text tag="body-large" sx={subtitleStyles}>
            {t(i18nKeyPaths.subtitle)}
          </Text>
        </FlexColumnLayout>

        {children}
      </FlexColumnLayout>
    </BaseLayout>
  )
}
