import React from "react"

import { Button, ButtonTypeEnum } from "@components/primitive/button/button"

type TBannerButtonProps = {
  label: string
  onClick: () => void
}

export const LaunchWidgetButton: React.FC<TBannerButtonProps> = ({ label, onClick }) => {
  return (
    <Button
      type={ButtonTypeEnum.PRIMARY_BLACK}
      onClick={onClick}
      textSx={{
        fontStyle: "normal",
        paddingY: 10,
      }}
    >
      {label}
    </Button>
  )
}
