import { gql } from '@apollo/client';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  GraphQLDate: { input: any; output: any; }
  GraphQLDateTime: { input: any; output: any; }
  GraphQLTime: { input: any; output: any; }
  JSON: { input: any; output: any; }
  JSONObject: { input: any; output: any; }
  Upload: { input: any; output: any; }
};

export type AcceptedAgreement = {
  __typename?: 'AcceptedAgreement';
  acceptedAt: Scalars['String']['output'];
  agreementType: Scalars['String']['output'];
  documentReference: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type AccountStatement = {
  __typename?: 'AccountStatement';
  month?: Maybe<Scalars['String']['output']>;
  statement?: Maybe<Scalars['String']['output']>;
  year?: Maybe<Scalars['String']['output']>;
};

export type AccountStatementPdf = {
  __typename?: 'AccountStatementPdf';
  month?: Maybe<Scalars['String']['output']>;
  pdf?: Maybe<Scalars['String']['output']>;
  year?: Maybe<Scalars['String']['output']>;
};

export enum AccountTypeEnum {
  SUB_ACCOUNT = 'SUB_ACCOUNT'
}

export type AccountUsageData = {
  averageACHAmount: Scalars['Float']['input'];
  averageCheckDepositAmount: Scalars['Float']['input'];
  averageCheckPaymentAmount: Scalars['Float']['input'];
  averageDepositAmount: Scalars['Float']['input'];
  averageWireAmount: Scalars['Float']['input'];
  businessId: Scalars['ID']['input'];
  debitCardAmount: Scalars['Float']['input'];
  numberOfACHs: Scalars['Int']['input'];
  numberOfCheckDeposits: Scalars['Int']['input'];
  numberOfCheckPayment: Scalars['Int']['input'];
  numberOfWires: Scalars['Int']['input'];
  percentRevenueOutsideUS: Scalars['Int']['input'];
};

export type AccountUsageSubmitResponse = MutationResponse & {
  __typename?: 'AccountUsageSubmitResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type AchCapability = {
  __typename?: 'AchCapability';
  limits: TransferLimits;
};

export type AchCutoffTimesResponse = {
  __typename?: 'AchCutoffTimesResponse';
  cutoffTimes: Array<Scalars['String']['output']>;
  isSameDayAvailable: Scalars['Boolean']['output'];
  nextAvailableTime?: Maybe<Scalars['String']['output']>;
  nextCutoff: Scalars['String']['output'];
  secondsToNextAvailable?: Maybe<Scalars['Float']['output']>;
  secondsToNextCutoff: Scalars['Float']['output'];
  secondsToNextWireCutoff: Scalars['Float']['output'];
  wireCutoffTime: Scalars['String']['output'];
};

export type AchPullCapability = {
  __typename?: 'AchPullCapability';
  limits: TransferLimits;
};

export type AddVirtualCardResponse = MutationResponse & {
  __typename?: 'AddVirtualCardResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type AdditionalOwnerOnboardingStatus = {
  __typename?: 'AdditionalOwnerOnboardingStatus';
  onboardingCompleted: Scalars['Boolean']['output'];
  userId: Scalars['String']['output'];
};

export type Address = {
  __typename?: 'Address';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  line1?: Maybe<Scalars['String']['output']>;
  line2?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type AddressInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  line1?: InputMaybe<Scalars['String']['input']>;
  line2?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type Agreement = {
  __typename?: 'Agreement';
  documentReference: Scalars['String']['output'];
  html: Scalars['String']['output'];
};

export enum AgreementType {
  ACCOUNT_AGREEMENT = 'ACCOUNT_AGREEMENT',
  ESIGN_AGREEMENT = 'ESIGN_AGREEMENT',
  ISSUER_AGREEMENT = 'ISSUER_AGREEMENT',
  MOBILE_PAYMENT_SERVICES_AGREEMENT = 'MOBILE_PAYMENT_SERVICES_AGREEMENT',
  TERMS_OF_SERVICE = 'TERMS_OF_SERVICE',
  WIRE_TRANSFER_AGREEMENT = 'WIRE_TRANSFER_AGREEMENT'
}

export type AgreementsPdf = {
  __typename?: 'AgreementsPDF';
  pdfURL: Scalars['String']['output'];
};

export type Announcement = {
  __typename?: 'Announcement';
  isInteractionComplete?: Maybe<Scalars['Boolean']['output']>;
  requiredAudience?: Maybe<AnnouncementAudienceRequirementEnum>;
  type?: Maybe<AnnouncementTypeEnum>;
};

export enum AnnouncementActionEventEnum {
  CONTINUE = 'CONTINUE',
  DISMISS = 'DISMISS'
}

export enum AnnouncementAudienceRequirementEnum {
  ALL_USERS = 'ALL_USERS',
  BUSINESS = 'BUSINESS'
}

export type AnnouncementInteractResponse = MutationResponse & {
  __typename?: 'AnnouncementInteractResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export enum AnnouncementTypeEnum {
  ACCOUNT_UPGRADE_COMPLETED_CAROUSEL = 'ACCOUNT_UPGRADE_COMPLETED_CAROUSEL',
  ADDITIONAL_OWNER_BANNER = 'ADDITIONAL_OWNER_BANNER',
  ADDITIONAL_OWNER_SETTINGS = 'ADDITIONAL_OWNER_SETTINGS',
  CONTINUOUS_ONBOARDING = 'CONTINUOUS_ONBOARDING',
  HISTORICAL_SUB_ACCOUNTS_MIGRATION_COMPLETE = 'HISTORICAL_SUB_ACCOUNTS_MIGRATION_COMPLETE',
  INVOICE_CREATE_AN_INVOICE = 'INVOICE_CREATE_AN_INVOICE',
  INVOICE_SETTINGS = 'INVOICE_SETTINGS',
  IN_APP_REVIEW_DEPOSIT_THRESHOLD_REACHED = 'IN_APP_REVIEW_DEPOSIT_THRESHOLD_REACHED',
  IN_APP_REVIEW_TENTH_ENVELOPE_CONTRIBUTION = 'IN_APP_REVIEW_TENTH_ENVELOPE_CONTRIBUTION',
  IN_APP_REVIEW_THIRD_ENVELOPE_CREATED = 'IN_APP_REVIEW_THIRD_ENVELOPE_CREATED',
  SHARE_AND_EARN_HUB = 'SHARE_AND_EARN_HUB'
}

export type ApplicantBusiness = {
  __typename?: 'ApplicantBusiness';
  address?: Maybe<Address>;
  annualRevenue?: Maybe<Scalars['Int']['output']>;
  businessType?: Maybe<BusinessType>;
  dba?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  ein?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  legalName?: Maybe<Scalars['String']['output']>;
  naicsCode?: Maybe<Scalars['String']['output']>;
  numberOfEmployees?: Maybe<Scalars['Int']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  typeOfProductsOrServices?: Maybe<Scalars['String']['output']>;
  website?: Maybe<Scalars['String']['output']>;
  yearsAtLocation?: Maybe<Scalars['Int']['output']>;
};

export type ApplicantBusinessInput = {
  address?: InputMaybe<AddressInput>;
  annualRevenue?: InputMaybe<Scalars['Int']['input']>;
  businessType?: InputMaybe<BusinessType>;
  dba?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  ein?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  legalName?: InputMaybe<Scalars['String']['input']>;
  naicsCode?: InputMaybe<Scalars['String']['input']>;
  numberOfEmployees?: InputMaybe<Scalars['Int']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  typeOfProductsOrServices?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
  yearsAtLocation?: InputMaybe<Scalars['Int']['input']>;
};

export type ApplicationData = {
  __typename?: 'ApplicationData';
  business?: Maybe<ApplicantBusiness>;
  id?: Maybe<Scalars['ID']['output']>;
  metadata?: Maybe<Scalars['JSONObject']['output']>;
  persons?: Maybe<Array<Maybe<Person>>>;
  primaryContact?: Maybe<PrimaryContact>;
  submittedAt?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type ApplicationInput = {
  business?: InputMaybe<ApplicantBusinessInput>;
  persons?: InputMaybe<Array<InputMaybe<PersonInput>>>;
  primaryContact?: InputMaybe<PrimaryContactInput>;
};

export type ApplicationResponse = {
  __typename?: 'ApplicationResponse';
  application?: Maybe<ApplicationData>;
  metadata?: Maybe<Scalars['JSONObject']['output']>;
};

export enum AuthTokenTypeEnum {
  ACCESS_TOKEN = 'ACCESS_TOKEN',
  REFRESH_TOKEN = 'REFRESH_TOKEN'
}

export type BankAccount = {
  __typename?: 'BankAccount';
  accountNumber?: Maybe<Scalars['String']['output']>;
  accountStatementPdf?: Maybe<AccountStatementPdf>;
  accountStatements: Array<AccountStatement>;
  accountStatementsByYear: Array<YearlyAccountStatements>;
  availableBalance?: Maybe<Scalars['Float']['output']>;
  bankAddress?: Maybe<Scalars['String']['output']>;
  bankName?: Maybe<Scalars['String']['output']>;
  businessId?: Maybe<Scalars['ID']['output']>;
  capabilities?: Maybe<BankAccountCapabilities>;
  card?: Maybe<Card>;
  cards: Array<Card>;
  id: Scalars['ID']['output'];
  routingNumber?: Maybe<Scalars['String']['output']>;
  subAccount?: Maybe<SubAccount>;
  subAccounts: Array<SubAccount>;
};


export type BankAccountAccountStatementPdfArgs = {
  month: Scalars['String']['input'];
  year: Scalars['String']['input'];
};


export type BankAccountCardArgs = {
  cardId: Scalars['ID']['input'];
};


export type BankAccountSubAccountArgs = {
  subAccountId: Scalars['ID']['input'];
};

export type BankAccountCapabilities = {
  __typename?: 'BankAccountCapabilities';
  ach: BankAccountCapabilitiesAch;
  billPay: BankAccountCapabilitiesBillPay;
  checkDeposit: BankAccountCapabilitiesCheckDeposit;
  debitCardDeposit: BankAccountCapabilitiesDebitCardDeposit;
  domesticWire: BankAccountCapabilitiesDomesticWire;
  mailedCheck: BankAccountCapabilitiesMailedCheck;
  sameDayAch: BankAccountCapabilitiesSameDayAch;
};

export type BankAccountCapabilitiesAch = {
  __typename?: 'BankAccountCapabilitiesACH';
  dailyLimit: Scalars['Float']['output'];
  enabled?: Maybe<Scalars['Boolean']['output']>;
  limit: Scalars['Float']['output'];
  /** @deprecated Naming is inaccurate; use thirtyDayRollingLimit instead */
  monthlyLimit: Scalars['Float']['output'];
  thirtyDayRollingLimit: Scalars['Float']['output'];
};

export type BankAccountCapabilitiesBillPay = {
  __typename?: 'BankAccountCapabilitiesBillPay';
  fee: Fee;
  limit: Scalars['Float']['output'];
};

export type BankAccountCapabilitiesCheckDeposit = {
  __typename?: 'BankAccountCapabilitiesCheckDeposit';
  dailyLimit: Scalars['Float']['output'];
  enabled?: Maybe<Scalars['Boolean']['output']>;
  limit: Scalars['Float']['output'];
  thirtyDayRollingLimit: Scalars['Float']['output'];
};

export type BankAccountCapabilitiesDebitCardDeposit = {
  __typename?: 'BankAccountCapabilitiesDebitCardDeposit';
  enabled?: Maybe<Scalars['Boolean']['output']>;
};

export type BankAccountCapabilitiesDomesticWire = {
  __typename?: 'BankAccountCapabilitiesDomesticWire';
  dailyLimit: Scalars['Float']['output'];
  enabled?: Maybe<Scalars['Boolean']['output']>;
  fee: Fee;
  limit: Scalars['Float']['output'];
  /** @deprecated Naming is inaccurate; use thirtyDayRollingLimit instead */
  monthlyLimit: Scalars['Float']['output'];
  thirtyDayRollingLimit: Scalars['Float']['output'];
};

export type BankAccountCapabilitiesMailedCheck = {
  __typename?: 'BankAccountCapabilitiesMailedCheck';
  fee: Fee;
  limit: Scalars['Float']['output'];
};

export type BankAccountCapabilitiesSameDayAch = {
  __typename?: 'BankAccountCapabilitiesSameDayACH';
  enabled?: Maybe<Scalars['Boolean']['output']>;
  fee: Fee;
};

export type BasicUser = {
  __typename?: 'BasicUser';
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isPrimaryOwner: Scalars['Boolean']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  signUpDate: Scalars['String']['output'];
};

export type BillPayCapability = {
  __typename?: 'BillPayCapability';
  fee: Fee;
  limits: BillPayLimits;
};

export type BillPayLimits = {
  __typename?: 'BillPayLimits';
  MAX_PER_TRANSACTION: Scalars['Float']['output'];
};

export type BillPayPaymentDetailsInput = {
  accountNumber: Scalars['String']['input'];
};

export type Biller = {
  __typename?: 'Biller';
  billerAccountNumberPatterns?: Maybe<Array<Scalars['String']['output']>>;
  billerName: Scalars['String']['output'];
  rppsBillerId: Scalars['String']['output'];
};

export type Billing = {
  __typename?: 'Billing';
  business: Business;
  invoices: Array<BillingInvoice>;
  plan?: Maybe<BillingPlan>;
};

export type BillingInvoice = {
  __typename?: 'BillingInvoice';
  amountDue?: Maybe<Scalars['Float']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
};

export type BillingInvoicePdf = {
  __typename?: 'BillingInvoicePDF';
  invoice: Scalars['String']['output'];
};

export type BillingPlan = {
  __typename?: 'BillingPlan';
  name?: Maybe<Scalars['String']['output']>;
  nextPaymentDate?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
};

export type Business = {
  __typename?: 'Business';
  address?: Maybe<Address>;
  bankAccount?: Maybe<BankAccount>;
  bankAccounts: Array<BankAccount>;
  billing: Billing;
  /**
   * ** deprecated ** use cards within bankAccounts instead
   * @deprecated use cards within bankAccounts instead
   */
  cards: Array<Card>;
  contact?: Maybe<Contact>;
  contacts: Array<Contact>;
  createdAt?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  inAppReview?: Maybe<InAppReview>;
  invoiceForwardingEmail?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  onboarding?: Maybe<BusinessOnboarding>;
  onboardingMailingAddress?: Maybe<Location>;
  ownerCandidates: Array<OwnerCandidate>;
  shareAndEarn?: Maybe<ShareAndEarn>;
  shippingAddress?: Maybe<Address>;
  subscription?: Maybe<Subscription>;
  subscriptionPlans?: Maybe<SubscriptionPlans>;
  type?: Maybe<Scalars['String']['output']>;
  users: Array<BasicUser>;
};


export type BusinessBankAccountArgs = {
  bankAccountId?: InputMaybe<Scalars['ID']['input']>;
};


export type BusinessContactArgs = {
  contactId: Scalars['ID']['input'];
};


export type BusinessContactsArgs = {
  contactPaymentType?: InputMaybe<ContactPaymentType>;
};

export type BusinessAccessTokenMutationResponse = MutationResponse & {
  __typename?: 'BusinessAccessTokenMutationResponse';
  businessAccessToken: Scalars['String']['output'];
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export enum BusinessMemberStatusEnum {
  ACTIVE = 'ACTIVE',
  INVITED = 'INVITED'
}

export type BusinessOnboarding = {
  __typename?: 'BusinessOnboarding';
  accountStatus?: Maybe<OnboardingAccountStatus>;
  application?: Maybe<OnboardingApplication>;
  businessId: Scalars['ID']['output'];
  initialFunding?: Maybe<InitialCardFundingTransactionResponse>;
  /** @deprecated Use `initialFunding`. */
  initialFundingStatus?: Maybe<CardFundingTransactionStatusEnum>;
};

export enum BusinessType {
  CORP = 'CORP',
  FREELANCER = 'FREELANCER',
  LLC = 'LLC',
  PARTNERSHIP = 'PARTNERSHIP',
  SOLEPROP = 'SOLEPROP'
}

export type CancelScheduledPaymentResponse = MutationResponse & {
  __typename?: 'CancelScheduledPaymentResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type CaptureInitialFundingAccountTypeMutationResponse = MutationResponse & {
  __typename?: 'CaptureInitialFundingAccountTypeMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type CaptureInitialFundingAmountMutationResponse = MutationResponse & {
  __typename?: 'CaptureInitialFundingAmountMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type Card = {
  __typename?: 'Card';
  /** ** deprecated ** use activation instead */
  activated?: Maybe<Scalars['Boolean']['output']>;
  activation?: Maybe<CardActivation>;
  businessId: Scalars['ID']['output'];
  canceled?: Maybe<Scalars['Boolean']['output']>;
  cardNumber?: Maybe<CardNumber>;
  disabled?: Maybe<Scalars['Boolean']['output']>;
  embossLine1?: Maybe<Scalars['String']['output']>;
  embossLine2?: Maybe<Scalars['String']['output']>;
  freezeInfo?: Maybe<CardFreezeInfo>;
  id: Scalars['ID']['output'];
  productType?: Maybe<CardProductType>;
  shippedDate?: Maybe<Scalars['String']['output']>;
  user?: Maybe<BasicUser>;
};

export type CardActivate = {
  __typename?: 'CardActivate';
  accountId: Scalars['ID']['output'];
  businessId: Scalars['ID']['output'];
  cardId: Scalars['ID']['output'];
  expirationDate: Scalars['String']['output'];
  securityCode: Scalars['String']['output'];
};

export type CardActivateResponse = MutationResponse & {
  __typename?: 'CardActivateResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type CardActivation = {
  __typename?: 'CardActivation';
  activated?: Maybe<Scalars['Boolean']['output']>;
  cardId: Scalars['ID']['output'];
  pinSet?: Maybe<CardPinSet>;
};

export type CardDataInput = {
  addressOnCard: LocationStrictInput;
  /** Encrypt card data in this format (cardNumber|YYYYMM|CVV) with RSA key */
  encryptedCardData: Scalars['String']['input'];
  encryptionKeyId: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
};

export type CardFreezeInfo = {
  __typename?: 'CardFreezeInfo';
  endDate?: Maybe<Scalars['String']['output']>;
  frozen: Scalars['Boolean']['output'];
};

export type CardFreezeResponse = MutationResponse & {
  __typename?: 'CardFreezeResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export enum CardFundingAddressEnum {
  BUSINESS = 'BUSINESS',
  HOME = 'HOME'
}

export type CardFundingInput = {
  amount: Scalars['Float']['input'];
  assertNoPreviousSuccessfulCharge?: InputMaybe<Scalars['Boolean']['input']>;
  businessId: Scalars['ID']['input'];
  cardData: CardDataInput;
  idempotencyKey: Scalars['String']['input'];
};

export type CardFundingSubmitResponse = MutationResponse & {
  __typename?: 'CardFundingSubmitResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  referenceId: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export enum CardFundingTransactionStatusEnum {
  TRANSACTION_FAILED = 'TRANSACTION_FAILED',
  TRANSACTION_INITIATED = 'TRANSACTION_INITIATED',
  TRANSACTION_SUCCESSFUL = 'TRANSACTION_SUCCESSFUL',
  TRANSACTION_UNRESOLVED = 'TRANSACTION_UNRESOLVED'
}

export type CardNumber = {
  __typename?: 'CardNumber';
  last4?: Maybe<Scalars['String']['output']>;
};

export type CardPinChangeForm = {
  __typename?: 'CardPinChangeForm';
  cardId: Scalars['ID']['output'];
  expiresAt: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type CardPinSet = {
  __typename?: 'CardPinSet';
  cardId: Scalars['ID']['output'];
  status?: Maybe<CardPinSetStatusEnum>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export enum CardPinSetStatusEnum {
  ERROR = 'ERROR',
  PROCESSING = 'PROCESSING',
  READY = 'READY',
  SET = 'SET'
}

export enum CardProductType {
  PHYSICAL = 'PHYSICAL',
  VIRTUAL = 'VIRTUAL'
}

export type CardUnfreezeResponse = MutationResponse & {
  __typename?: 'CardUnfreezeResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type CheckDepositCreateInput = {
  accountId: Scalars['ID']['input'];
  amountInCents: Scalars['Float']['input'];
  frontImage: Scalars['String']['input'];
  idempotencyKey: Scalars['String']['input'];
  rearImage: Scalars['String']['input'];
};

export type CheckDepositCreateMutationResponse = MutationResponse & {
  __typename?: 'CheckDepositCreateMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type ConcreteMutationResponse = {
  __typename?: 'ConcreteMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type ConfirmAdditionalOwnersOnboardingStatusMutationResponse = MutationResponse & {
  __typename?: 'ConfirmAdditionalOwnersOnboardingStatusMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  status?: Maybe<AdditionalOwnerOnboardingStatus>;
  success: Scalars['Boolean']['output'];
  userErrors?: Maybe<Array<UserError>>;
};

export type Contact = {
  __typename?: 'Contact';
  achPaymentDetails?: Maybe<ContactAchPaymentDetails>;
  address?: Maybe<Location>;
  billPayPaymentDetails?: Maybe<ContactBillPayPaymentDetails>;
  businessId: Scalars['ID']['output'];
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  mailedCheckPaymentDetails?: Maybe<ContactMailedCheckPaymentDetails>;
  name: Scalars['String']['output'];
  relationship?: Maybe<Scalars['String']['output']>;
  /**
   * RPPS stands for Mastercard Remote Payment and Presentment Service
   * rppsBillerDetails simply indicate that the Contact exists in the RPPS directory.
   * To make bill payments, Bill Pay payment method details must still exist on the Contact
   */
  rppsBillerDetails?: Maybe<RppsBillerDetails>;
  wirePaymentDetails?: Maybe<ContactWirePaymentDetails>;
};

export type ContactAchPaymentDetails = {
  __typename?: 'ContactAchPaymentDetails';
  accountNumber: Scalars['String']['output'];
  routingNumber: Scalars['String']['output'];
};

export type ContactAchPaymentDetailsCreateInput = {
  accountNumber: Scalars['String']['input'];
  routingNumber: Scalars['String']['input'];
};

export type ContactAchPaymentDetailsUpdateInput = {
  accountNumber?: InputMaybe<Scalars['String']['input']>;
  routingNumber?: InputMaybe<Scalars['String']['input']>;
};

export type ContactBillPayPaymentDetails = {
  __typename?: 'ContactBillPayPaymentDetails';
  accountNumber: Scalars['String']['output'];
};

export type ContactBillPayPaymentDetailsCreateInput = {
  businessId: Scalars['ID']['input'];
  contactId: Scalars['ID']['input'];
  idempotencyKey?: InputMaybe<Scalars['String']['input']>;
  paymentDetails: BillPayPaymentDetailsInput;
};

export type ContactBillPayPaymentDetailsCreateMutationResponse = MutationResponse & {
  __typename?: 'ContactBillPayPaymentDetailsCreateMutationResponse';
  code: Scalars['String']['output'];
  contact: Contact;
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type ContactBillPayPaymentDetailsDeleteInput = {
  businessId: Scalars['ID']['input'];
  contactId: Scalars['ID']['input'];
  idempotencyKey?: InputMaybe<Scalars['String']['input']>;
};

export type ContactBillPayPaymentDetailsDeleteMutationResponse = MutationResponse & {
  __typename?: 'ContactBillPayPaymentDetailsDeleteMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type ContactBillPayPaymentDetailsUpdateInput = {
  businessId: Scalars['ID']['input'];
  contactId: Scalars['ID']['input'];
  idempotencyKey?: InputMaybe<Scalars['String']['input']>;
  paymentDetails: BillPayPaymentDetailsInput;
};

export type ContactBillPayPaymentDetailsUpdateMutationResponse = MutationResponse & {
  __typename?: 'ContactBillPayPaymentDetailsUpdateMutationResponse';
  code: Scalars['String']['output'];
  contact: Contact;
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type ContactCreateDataInput = {
  address?: InputMaybe<LocationInput>;
  businessId: Scalars['ID']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  idempotencyKey?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  relationship?: InputMaybe<Scalars['String']['input']>;
  rppsBillerDetails?: InputMaybe<ContactRppsBillerDetailsInput>;
};

export type ContactCreateMutationResponse = MutationResponse & {
  __typename?: 'ContactCreateMutationResponse';
  code: Scalars['String']['output'];
  contact: Contact;
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type ContactDeleteInput = {
  businessId: Scalars['ID']['input'];
  contactId: Scalars['ID']['input'];
  idempotencyKey?: InputMaybe<Scalars['String']['input']>;
};

export type ContactDeleteMutationResponse = MutationResponse & {
  __typename?: 'ContactDeleteMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type ContactDomesticAchCreateDataInput = {
  achPaymentDetails: ContactAchPaymentDetailsCreateInput;
  businessId: Scalars['ID']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  idempotencyKey?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type ContactDomesticAchUpdateDataInput = {
  achPaymentDetails?: InputMaybe<ContactAchPaymentDetailsUpdateInput>;
  businessId: Scalars['ID']['input'];
  contactId: Scalars['ID']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  idempotencyKey?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type ContactDomesticWireCreateDataInput = {
  address: LocationInput;
  businessId: Scalars['ID']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  idempotencyKey?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  relationship: Scalars['String']['input'];
  wirePaymentDetails: ContactWirePaymentDetailsCreateInput;
};

export type ContactDomesticWireUpdateDataInput = {
  address?: InputMaybe<LocationInput>;
  businessId: Scalars['ID']['input'];
  contactId: Scalars['ID']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  idempotencyKey?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  relationship?: InputMaybe<Scalars['String']['input']>;
  wirePaymentDetails?: InputMaybe<ContactWirePaymentDetailsUpdateInput>;
};

export type ContactMailedCheckCreateDataInput = {
  address: LocationInput;
  businessId: Scalars['String']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  idempotencyKey?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type ContactMailedCheckPaymentDetails = {
  __typename?: 'ContactMailedCheckPaymentDetails';
  enabled: Scalars['Boolean']['output'];
};

export type ContactMailedCheckUpdateDataInput = {
  address: LocationInput;
  businessId: Scalars['ID']['input'];
  contactId: Scalars['ID']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  idempotencyKey?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export enum ContactPaymentType {
  ACH = 'ach',
  BILLPAY = 'billPay',
  MAILEDCHECK = 'mailedCheck',
  WIRE = 'wire'
}

export type ContactRppsBillerDetailsInput = {
  billerName: Scalars['String']['input'];
  rppsBillerId: Scalars['String']['input'];
};

export type ContactUpdateDataInput = {
  address?: InputMaybe<LocationInput>;
  businessId: Scalars['ID']['input'];
  contactId: Scalars['ID']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  idempotencyKey?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  relationship?: InputMaybe<Scalars['String']['input']>;
};

export type ContactUpdateMutationResponse = MutationResponse & {
  __typename?: 'ContactUpdateMutationResponse';
  code: Scalars['String']['output'];
  contact: Contact;
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type ContactWirePaymentDetails = {
  __typename?: 'ContactWirePaymentDetails';
  accountNumber: Scalars['String']['output'];
  bankName: Scalars['String']['output'];
  routingNumber: Scalars['String']['output'];
};

export type ContactWirePaymentDetailsCreateInput = {
  accountNumber: Scalars['String']['input'];
  bankName: Scalars['String']['input'];
  routingNumber: Scalars['String']['input'];
};

export type ContactWirePaymentDetailsUpdateInput = {
  accountNumber?: InputMaybe<Scalars['String']['input']>;
  bankName?: InputMaybe<Scalars['String']['input']>;
  routingNumber?: InputMaybe<Scalars['String']['input']>;
};

export type Coordinates = {
  __typename?: 'Coordinates';
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
};

export enum CorporationType {
  CCORP = 'CCORP',
  LLC = 'LLC',
  PARTNERSHIP = 'PARTNERSHIP',
  SCORP = 'SCORP',
  SOLEPROP = 'SOLEPROP'
}

export type CreateInvoiceAccountsPayablePaymentDataInput = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  idempotencyKey: Scalars['String']['input'];
  paymentMethodDetails: InvoiceAccountPayablePaymentMethodDetails;
  subAccountId: Scalars['ID']['input'];
};

export type CreateInvoiceAccountsPayablePaymentMutationResponse = MutationResponse & {
  __typename?: 'CreateInvoiceAccountsPayablePaymentMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
  transactionId: Scalars['String']['output'];
  userErrors?: Maybe<Array<UserError>>;
};

export type CreateInvoiceAccountsPayableScheduledPaymentDataInput = {
  accountId: Scalars['ID']['input'];
  amount: Scalars['Float']['input'];
  /** Format: ISO date. eg YYYY-MM-DD */
  endDate?: InputMaybe<Scalars['String']['input']>;
  idempotencyKey: Scalars['String']['input'];
  paymentMethodDetails: InvoiceAccountPayablePaymentMethodDetails;
  /** Values could be: 'once' */
  period: Scalars['String']['input'];
  /** Format: ISO date. eg YYYY-MM-DD */
  startDate: Scalars['String']['input'];
  subAccountId: Scalars['ID']['input'];
};

export type CreateInvoiceAccountsPayableScheduledPaymentMutationResponse = MutationResponse & {
  __typename?: 'CreateInvoiceAccountsPayableScheduledPaymentMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  scheduledPaymentId: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
  userErrors?: Maybe<Array<UserError>>;
};

export type CreateInvoiceAttachmentDataInput = {
  contentLength: Scalars['Float']['input'];
  contentType: Scalars['String']['input'];
  extract: Scalars['Boolean']['input'];
};

export type CreateInvoiceAttachmentMutationResponse = MutationResponse & {
  __typename?: 'CreateInvoiceAttachmentMutationResponse';
  attachmentId: Scalars['ID']['output'];
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  status: InvoiceAttachmentStatus;
  success: Scalars['Boolean']['output'];
  upload: InvoiceAttachmentUpload;
};

export type CreateLinkTokenMutationResponse = MutationResponse & {
  __typename?: 'CreateLinkTokenMutationResponse';
  code: Scalars['String']['output'];
  data: LinkTokenData;
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
  userErrors?: Maybe<Array<UserError>>;
};

export enum CsvFormatEnum {
  QBO = 'QBO',
  QBO_DEBIT_CREDIT = 'QBO_DEBIT_CREDIT',
  STANDARD = 'STANDARD'
}

export enum CurrencyCodeEnum {
  CAD = 'CAD',
  CHF = 'CHF',
  EUR = 'EUR',
  GBP = 'GBP',
  USD = 'USD'
}

export type CurrentPlan = {
  __typename?: 'CurrentPlan';
  planName: Scalars['String']['output'];
};

export type DatetimeRangeInput = {
  /** datetime in ISO 8601 format */
  endDatetime?: InputMaybe<Scalars['String']['input']>;
  /** datetime in ISO 8601 format */
  startDatetime?: InputMaybe<Scalars['String']['input']>;
};

export type DebitCardDepositAmountIsValidResponse = {
  __typename?: 'DebitCardDepositAmountIsValidResponse';
  /** This should always be true. An invalid amount would throw a GraphQL error. */
  valid: Scalars['Boolean']['output'];
};

export type DebitCardDepositSubmitInput = {
  amount: Scalars['Float']['input'];
  businessId: Scalars['ID']['input'];
  idempotencyKey: Scalars['String']['input'];
  linkedDebitCardId: Scalars['ID']['input'];
};

export type DeleteLinkedDebitCardInput = {
  businessId: Scalars['ID']['input'];
  linkedDebitCardId: Scalars['ID']['input'];
};

export type DeleteMutationResponse = MutationPayload & {
  __typename?: 'DeleteMutationResponse';
  success: Scalars['Boolean']['output'];
  userErrors: Array<UserError>;
};

export type DeviceInfoInput = {
  os: OperatingSystemEnum;
  /** i.e) for ios 12.1.2 major version would be '12' */
  osMajorVersion: Scalars['String']['input'];
};

export type DigitalCardAssetUrl = {
  __typename?: 'DigitalCardAssetUrl';
  cardId: Scalars['ID']['output'];
  expiresAt: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type DisableTopUpsDataInput = {
  idempotencyKey: Scalars['String']['input'];
};

export type DisableTopUpsResponse = MutationResponse & {
  __typename?: 'DisableTopUpsResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type DiscountFeeDetails = FlatFeeDetails | PercentFeeDetails;

export type DocumentRequestEmailResendData = {
  applicationId: Scalars['ID']['input'];
  businessId: Scalars['ID']['input'];
  idempotencyKey?: InputMaybe<Scalars['String']['input']>;
};

export type DocumentRequestEmailResendResponse = MutationResponse & {
  __typename?: 'DocumentRequestEmailResendResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type DomesticWireCreateInput = {
  amount: Scalars['Float']['input'];
  contactId: Scalars['ID']['input'];
  contactNotificationOptions?: InputMaybe<TransactionContactNotificationOptionsInput>;
  idempotencyKey: Scalars['String']['input'];
  purpose: Scalars['String']['input'];
  subAccountId: Scalars['ID']['input'];
};

export type DomesticWireCreateMutationResponse = MutationResponse & {
  __typename?: 'DomesticWireCreateMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export enum ErrorCodeEnum {
  ACCOUNT_FUNDING_ADDRESS_VALIDATION_FAILED = 'ACCOUNT_FUNDING_ADDRESS_VALIDATION_FAILED',
  /** Account funding validation errors */
  ACCOUNT_FUNDING_PULL_NOT_ENABLED = 'ACCOUNT_FUNDING_PULL_NOT_ENABLED',
  ACCOUNT_FUNDING_UNSUPPORTED_CARD_CREDIT = 'ACCOUNT_FUNDING_UNSUPPORTED_CARD_CREDIT',
  ACCOUNT_FUNDING_UNSUPPORTED_CARD_INTERNATIONAL = 'ACCOUNT_FUNDING_UNSUPPORTED_CARD_INTERNATIONAL',
  ACCOUNT_FUNDING_UNSUPPORTED_CARD_PREPAID = 'ACCOUNT_FUNDING_UNSUPPORTED_CARD_PREPAID',
  /** Not authorized to access resource */
  AUTH_UNAUTHORIZED = 'AUTH_UNAUTHORIZED',
  /** registerUser mutation error */
  AUTH_USER_ALREADY_EXISTS = 'AUTH_USER_ALREADY_EXISTS',
  AVAILABLE_BALANCE_MINUS_PENDING_ERROR = 'AVAILABLE_BALANCE_MINUS_PENDING_ERROR',
  BILL_PAYMENT_RECIPIENT_CREATE_ERROR = 'BILL_PAYMENT_RECIPIENT_CREATE_ERROR',
  /** Card activation errors */
  CARD_ACTIVATION_INVALID_INPUT = 'CARD_ACTIVATION_INVALID_INPUT',
  CHECK_DEPOSIT_AMOUNT_NOT_MATCHING = 'CHECK_DEPOSIT_AMOUNT_NOT_MATCHING',
  CHECK_DEPOSIT_AMOUNT_TOO_HIGH = 'CHECK_DEPOSIT_AMOUNT_TOO_HIGH',
  CHECK_DEPOSIT_AMOUNT_TOO_LOW = 'CHECK_DEPOSIT_AMOUNT_TOO_LOW',
  CHECK_DEPOSIT_BANK_ACCOUNT_NOT_FOUND = 'CHECK_DEPOSIT_BANK_ACCOUNT_NOT_FOUND',
  CHECK_DEPOSIT_CHECK_BLENDED_WITH_BACKGROUND = 'CHECK_DEPOSIT_CHECK_BLENDED_WITH_BACKGROUND',
  CHECK_DEPOSIT_CORNERS_NOT_VISIBLE = 'CHECK_DEPOSIT_CORNERS_NOT_VISIBLE',
  CHECK_DEPOSIT_DAILY_LIMIT_EXCEEDED = 'CHECK_DEPOSIT_DAILY_LIMIT_EXCEEDED',
  CHECK_DEPOSIT_IMAGE_TOO_SMALL = 'CHECK_DEPOSIT_IMAGE_TOO_SMALL',
  CHECK_DEPOSIT_MISSING_BACK_PHOTO = 'CHECK_DEPOSIT_MISSING_BACK_PHOTO',
  CHECK_DEPOSIT_MONTHLY_LIMIT_EXCEEDED = 'CHECK_DEPOSIT_MONTHLY_LIMIT_EXCEEDED',
  CHECK_DEPOSIT_PHOTO_NOT_CLEAR = 'CHECK_DEPOSIT_PHOTO_NOT_CLEAR',
  CHECK_DEPOSIT_SHADOW_IN_PHOTOS = 'CHECK_DEPOSIT_SHADOW_IN_PHOTOS',
  CHECK_DEPOSIT_SIGNATURE_NOT_FOUND = 'CHECK_DEPOSIT_SIGNATURE_NOT_FOUND',
  /** Check deposit errors */
  CHECK_DEPOSIT_SOMETHING_WENT_WRONG = 'CHECK_DEPOSIT_SOMETHING_WENT_WRONG',
  CONTACT_INVALID_BILLER_ACCOUNT_NUMBER = 'CONTACT_INVALID_BILLER_ACCOUNT_NUMBER',
  DEBIT_CARD_DEPOSIT_ADDRESS_VALIDATION_FAILED = 'DEBIT_CARD_DEPOSIT_ADDRESS_VALIDATION_FAILED',
  /** Debit card deposit validation errors */
  DEBIT_CARD_DEPOSIT_DAILY_LIMIT_EXCEEDED = 'DEBIT_CARD_DEPOSIT_DAILY_LIMIT_EXCEEDED',
  DEBIT_CARD_DEPOSIT_MONTHLY_LIMIT_EXCEEDED = 'DEBIT_CARD_DEPOSIT_MONTHLY_LIMIT_EXCEEDED',
  DEBIT_CARD_DEPOSIT_PULL_NOT_ENABLED = 'DEBIT_CARD_DEPOSIT_PULL_NOT_ENABLED',
  DEBIT_CARD_DEPOSIT_UNSUPPORTED_CARD_CREDIT = 'DEBIT_CARD_DEPOSIT_UNSUPPORTED_CARD_CREDIT',
  DEBIT_CARD_DEPOSIT_UNSUPPORTED_CARD_INTERNATIONAL = 'DEBIT_CARD_DEPOSIT_UNSUPPORTED_CARD_INTERNATIONAL',
  DEBIT_CARD_DEPOSIT_UNSUPPORTED_CARD_PREPAID = 'DEBIT_CARD_DEPOSIT_UNSUPPORTED_CARD_PREPAID',
  DOMESTIC_ACH_DAILY_LIMIT_EXCEEDED = 'DOMESTIC_ACH_DAILY_LIMIT_EXCEEDED',
  DOMESTIC_ACH_INSUFFICIENT_BALANCE = 'DOMESTIC_ACH_INSUFFICIENT_BALANCE',
  DOMESTIC_ACH_INVALID_DETAILS = 'DOMESTIC_ACH_INVALID_DETAILS',
  /** Domestic ACH errors */
  DOMESTIC_ACH_SOMETHING_WENT_WRONG = 'DOMESTIC_ACH_SOMETHING_WENT_WRONG',
  /** Domestic Wire errors */
  DOMESTIC_WIRE_CARD_NOT_ACTIVATED = 'DOMESTIC_WIRE_CARD_NOT_ACTIVATED',
  DOMESTIC_WIRE_EXCEEDS_30D_LIMIT = 'DOMESTIC_WIRE_EXCEEDS_30D_LIMIT',
  DOMESTIC_WIRE_EXCEEDS_DAILY_LIMIT = 'DOMESTIC_WIRE_EXCEEDS_DAILY_LIMIT',
  DOMESTIC_WIRE_EXCEEDS_TRANSACTION_LIMIT = 'DOMESTIC_WIRE_EXCEEDS_TRANSACTION_LIMIT',
  DOMESTIC_WIRE_INSUFFICIENT_BALANCE = 'DOMESTIC_WIRE_INSUFFICIENT_BALANCE',
  DOMESTIC_WIRE_SOMETHING_WENT_WRONG = 'DOMESTIC_WIRE_SOMETHING_WENT_WRONG',
  /** Contacts errors */
  DUPLICATE_ACH_CONTACT_NOT_ALLOWED = 'DUPLICATE_ACH_CONTACT_NOT_ALLOWED',
  /** Resource not found */
  GENERAL_NOT_FOUND = 'GENERAL_NOT_FOUND',
  /** Inputs not valid */
  GENERAL_VALIDATION_FAILED = 'GENERAL_VALIDATION_FAILED',
  INCORRECT_PASSWORD = 'INCORRECT_PASSWORD',
  INVALID_CARD = 'INVALID_CARD',
  INVALID_CARD_FUNDING = 'INVALID_CARD_FUNDING',
  INVALID_INVITATION_TOKEN = 'INVALID_INVITATION_TOKEN',
  INVALID_ROUTING_NUMBER = 'INVALID_ROUTING_NUMBER',
  INVOICE_ACCOUNTS_PAYABLE_CARD_NOT_ACTIVATED = 'INVOICE_ACCOUNTS_PAYABLE_CARD_NOT_ACTIVATED',
  INVOICE_ACCOUNTS_PAYABLE_EXCEEDS_LIMIT = 'INVOICE_ACCOUNTS_PAYABLE_EXCEEDS_LIMIT',
  /** Invoice Accounts Payable errors */
  INVOICE_ACCOUNTS_PAYABLE_INSUFFICIENT_BALANCE = 'INVOICE_ACCOUNTS_PAYABLE_INSUFFICIENT_BALANCE',
  INVOICE_ACCOUNTS_PAYABLE_INVALID_ACH_DETAILS = 'INVOICE_ACCOUNTS_PAYABLE_INVALID_ACH_DETAILS',
  INVOICE_ACCOUNTS_PAYABLE_INVALID_SCHEDULE_START_DATE = 'INVOICE_ACCOUNTS_PAYABLE_INVALID_SCHEDULE_START_DATE',
  INVOICE_ACCOUNTS_PAYABLE_INVALID_WIRE_DETAILS = 'INVOICE_ACCOUNTS_PAYABLE_INVALID_WIRE_DETAILS',
  INVOICE_ACCOUNTS_PAYABLE_MISSING_MAILED_CHECK_ADDRESS = 'INVOICE_ACCOUNTS_PAYABLE_MISSING_MAILED_CHECK_ADDRESS',
  INVOICE_ACCOUNTS_PAYABLE_MISSING_MAILED_CHECK_DETAILS = 'INVOICE_ACCOUNTS_PAYABLE_MISSING_MAILED_CHECK_DETAILS',
  INVOICE_ACCOUNTS_PAYABLE_SOMETHING_WENT_WRONG = 'INVOICE_ACCOUNTS_PAYABLE_SOMETHING_WENT_WRONG',
  /** plaid-funding errors */
  PLAID_FUNDING_IDENTITY_MATCH_FAILED = 'PLAID_FUNDING_IDENTITY_MATCH_FAILED',
  RECAPTCHA_ERROR = 'RECAPTCHA_ERROR',
  /** subscriptions errors */
  SUBSCRIPTION_UPGRADE_FAILED_NSF = 'SUBSCRIPTION_UPGRADE_FAILED_NSF',
  SUPPORT_UNAVAILABLE_TIME_SLOT = 'SUPPORT_UNAVAILABLE_TIME_SLOT',
  /** Account does not have sufficient funds for transaction */
  TRANSACTIONS_INSUFFICIENT_FUNDS = 'TRANSACTIONS_INSUFFICIENT_FUNDS',
  /** Transaction schedule not valid */
  TRANSACTIONS_INVALID_SCHEDULE = 'TRANSACTIONS_INVALID_SCHEDULE',
  TRANSACTIONS_LIMIT_EXCEEDED = 'TRANSACTIONS_LIMIT_EXCEEDED',
  /** Mobile app version uses deprecated API */
  UNSUPPORTED_MOBILE_APP_VERSION = 'UNSUPPORTED_MOBILE_APP_VERSION'
}

export type ExchangePublicTokenData = {
  __typename?: 'ExchangePublicTokenData';
  itemId: Scalars['String']['output'];
};

export type ExchangePublicTokenMutationResponse = MutationResponse & {
  __typename?: 'ExchangePublicTokenMutationResponse';
  code: Scalars['String']['output'];
  data: ExchangePublicTokenData;
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
  userErrors?: Maybe<Array<UserError>>;
};

export type Fee = FlatFee | PercentFee;

export enum FeeType {
  FLAT = 'flat',
  PERCENT = 'percent'
}

export enum FileTypeEnum {
  CSV = 'CSV',
  PDF = 'PDF'
}

export type FlatFee = {
  __typename?: 'FlatFee';
  amount: Scalars['Float']['output'];
  /** @deprecated Field is not resolved. Use transactionFees query instead. */
  calculatedFee?: Maybe<Scalars['Float']['output']>;
  type: FeeType;
};

export type FlatFeeDetails = {
  __typename?: 'FlatFeeDetails';
  amount: Scalars['Float']['output'];
  feeType: FeeType;
};

export type FloatRangeInput = {
  maximum?: InputMaybe<Scalars['Float']['input']>;
  minimum?: InputMaybe<Scalars['Float']['input']>;
};

export type GetBalanceQueryResponse = {
  __typename?: 'GetBalanceQueryResponse';
  data: PlaidAccounts;
};

export type GetFundingAmountsQueryResponse = {
  __typename?: 'GetFundingAmountsQueryResponse';
  defaultAmount: Scalars['Int']['output'];
  maximumAmount: Scalars['Int']['output'];
  minimumAmount: Scalars['Int']['output'];
};

export type GetFundingStatusQueryResponse = {
  __typename?: 'GetFundingStatusQueryResponse';
  status: PlaidFundingStatusEnum;
};

export type GetIdentityQueryResponse = {
  __typename?: 'GetIdentityQueryResponse';
  data: PlaidAccounts;
};

export type GetInvoiceAccountsPayableQueryResponse = {
  __typename?: 'GetInvoiceAccountsPayableQueryResponse';
  invoice: InvoiceAccountsPayable;
};

export type GetInvoiceAttachmentResponse = {
  __typename?: 'GetInvoiceAttachmentResponse';
  attachmentData?: Maybe<InvoiceAttachmentData>;
  attachmentId: Scalars['ID']['output'];
  file?: Maybe<InvoiceAttachmentFile>;
  status: InvoiceAttachmentStatus;
};

export type GetTopUpsSettingsQueryResponse = {
  __typename?: 'GetTopUpsSettingsQueryResponse';
  error?: Maybe<TopUpsError>;
  isTopUpsEnabled: Scalars['Boolean']['output'];
  lowBalanceThreshold: Scalars['Float']['output'];
  plaidItem?: Maybe<PlaidItem>;
  topUpAmount: Scalars['Float']['output'];
};

export enum GroupTypeEnum {
  OWNER = 'OWNER'
}

export type GroupedIndustries = {
  __typename?: 'GroupedIndustries';
  industries: Array<KybIndustry>;
  vertical: Scalars['String']['output'];
};

export type HomeAnnouncement = {
  __typename?: 'HomeAnnouncement';
  body: Scalars['String']['output'];
  color: Scalars['String']['output'];
  cta: HomeAnnouncementCta;
  featureFlag?: Maybe<Scalars['String']['output']>;
  iconSrc?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
};

export type HomeAnnouncementAnalyticEventNames = {
  __typename?: 'HomeAnnouncementAnalyticEventNames';
  continue?: Maybe<Scalars['String']['output']>;
  dismiss?: Maybe<Scalars['String']['output']>;
};

export type HomeAnnouncementCta = {
  __typename?: 'HomeAnnouncementCTA';
  analyticEventNames?: Maybe<HomeAnnouncementAnalyticEventNames>;
  text: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type IdentityCheckInputs = {
  businessName: Scalars['String']['input'];
  ownerNames: Array<Scalars['String']['input']>;
};

export type InAppReview = {
  __typename?: 'InAppReview';
  businessId: Scalars['ID']['output'];
  status?: Maybe<InAppReviewStatus>;
};


export type InAppReviewStatusArgs = {
  inAppReviewType: InAppReviewType;
};

export type InAppReviewDismissResponse = MutationResponse & {
  __typename?: 'InAppReviewDismissResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export enum InAppReviewStatus {
  DISMISSED = 'DISMISSED',
  ELIGIBLE = 'ELIGIBLE',
  NOT_ELIGIBLE = 'NOT_ELIGIBLE'
}

export enum InAppReviewType {
  DEPOSIT_THRESHOLD_REACHED = 'DEPOSIT_THRESHOLD_REACHED',
  TENTH_ENVELOPE_CONTRIBUTION = 'TENTH_ENVELOPE_CONTRIBUTION',
  THIRD_ENVELOPE_CREATED = 'THIRD_ENVELOPE_CREATED'
}

export type IngoCustomerResponse = {
  __typename?: 'IngoCustomerResponse';
  ingoCustomerId?: Maybe<Scalars['ID']['output']>;
  ingoSSOToken?: Maybe<Scalars['String']['output']>;
};

export type InitialCardDataInput = {
  addressType: CardFundingAddressEnum;
  /** Encrypt card data in this format (cardNumber|YYYYMM|CVV) with RSA key */
  encryptedCardData: Scalars['String']['input'];
  encryptionKeyId: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
};

export type InitialCardFundingInput = {
  amount: Scalars['Float']['input'];
  assertNoPreviousSuccessfulCharge?: InputMaybe<Scalars['Boolean']['input']>;
  businessId: Scalars['ID']['input'];
  cardData: InitialCardDataInput;
  idempotencyKey: Scalars['String']['input'];
};

export type InitialCardFundingSubmitResponse = MutationResponse & {
  __typename?: 'InitialCardFundingSubmitResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  referenceId: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type InitialCardFundingTransactionResponse = {
  __typename?: 'InitialCardFundingTransactionResponse';
  failureReason?: Maybe<Scalars['String']['output']>;
  status?: Maybe<CardFundingTransactionStatusEnum>;
};

export type InitialFundingAmounts = {
  __typename?: 'InitialFundingAmounts';
  defaultAmount: Scalars['Float']['output'];
  maximumAmount: Scalars['Float']['output'];
  minimumAmount: Scalars['Float']['output'];
};

export type InitialFundingCapability = {
  __typename?: 'InitialFundingCapability';
  amounts: InitialFundingAmounts;
};

export enum InsightTypeEnum {
  BALANCE = 'BALANCE',
  PAYMENTS = 'PAYMENTS',
  REVENUE = 'REVENUE'
}

export enum IntegrationCategoryEnum {
  ACCOUNTING = 'ACCOUNTING',
  EXPENSES = 'EXPENSES',
  E_COMMERCE = 'E_COMMERCE',
  PAYMENT_PROCESSOR = 'PAYMENT_PROCESSOR'
}

export enum IntegrationStatusEnum {
  ACTIVE = 'ACTIVE',
  ERROR = 'ERROR',
  INACTIVE = 'INACTIVE',
  PENDING = 'PENDING'
}

export type InviteAdditionalOwnerMutationResponse = MutationResponse & {
  __typename?: 'InviteAdditionalOwnerMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
  userErrors?: Maybe<Array<UserError>>;
};

export enum InvoiceAccountPayableCreatedVia {
  EMAIL_FORWARDING = 'email_forwarding',
  OTHER = 'other',
  USER = 'user'
}

export enum InvoiceAccountPayablePaymentMethod {
  ACH = 'ach',
  BILL_PAY = 'bill_pay',
  MAILED_CHECK = 'mailed_check',
  WIRE = 'wire'
}

export type InvoiceAccountPayablePaymentMethodDetails = {
  memo?: InputMaybe<Scalars['String']['input']>;
  purpose?: InputMaybe<Scalars['String']['input']>;
  sameDay?: InputMaybe<Scalars['Boolean']['input']>;
  type: InvoiceAccountPayablePaymentMethod;
};

export type InvoiceAccountsPayable = {
  __typename?: 'InvoiceAccountsPayable';
  amount?: Maybe<Scalars['Float']['output']>;
  attachmentId?: Maybe<Scalars['ID']['output']>;
  business: Business;
  /** Instead of this field, prefer to use business.id. Ideally, we can deprecate this field in the future. */
  businessId: Scalars['ID']['output'];
  contactId?: Maybe<Scalars['ID']['output']>;
  createdDate: Scalars['String']['output'];
  createdVia: InvoiceAccountPayableCreatedVia;
  description?: Maybe<Scalars['String']['output']>;
  dueDate?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  invoiceDate?: Maybe<Scalars['String']['output']>;
  invoiceNumber?: Maybe<Scalars['String']['output']>;
  isArchived?: Maybe<Scalars['Boolean']['output']>;
  isMarkedAsPaid?: Maybe<Scalars['Boolean']['output']>;
  markedAsPaidReason?: Maybe<Scalars['String']['output']>;
  paidAmount: Scalars['Float']['output'];
  paidDate?: Maybe<Scalars['String']['output']>;
  paymentStatus?: Maybe<InvoicePaymentStatus>;
  pendingAmount: Scalars['Float']['output'];
  remainingAmount: Scalars['Float']['output'];
  scheduledAmount: Scalars['Float']['output'];
  status: InvoiceStatus;
  transactions?: Maybe<Array<Maybe<InvoiceAccountsPayableTransaction>>>;
  updatedDate?: Maybe<Scalars['String']['output']>;
  vendorName?: Maybe<Scalars['String']['output']>;
};

export type InvoiceAccountsPayableDataInput = {
  attachmentId?: InputMaybe<Scalars['ID']['input']>;
  contactId?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  dueDate?: InputMaybe<Scalars['String']['input']>;
  idempotencyKey: Scalars['String']['input'];
  invoiceDate?: InputMaybe<Scalars['String']['input']>;
  invoiceId?: InputMaybe<Scalars['ID']['input']>;
  invoiceNumber?: InputMaybe<Scalars['String']['input']>;
  totalAmount?: InputMaybe<Scalars['Float']['input']>;
  vendorName?: InputMaybe<Scalars['String']['input']>;
};

export type InvoiceAccountsPayableListFiltersInput = {
  status?: InputMaybe<InvoiceStatus>;
};

export type InvoiceAccountsPayableListPaginationOptionsInput = {
  cursor?: InputMaybe<Scalars['String']['input']>;
};

export type InvoiceAccountsPayableListSortingInput = {
  sortDirection: SortDirection;
  sortKey: SortKey;
};

export type InvoiceAccountsPayablePagination = {
  __typename?: 'InvoiceAccountsPayablePagination';
  cursor?: Maybe<Scalars['String']['output']>;
};

export type InvoiceAccountsPayableTransaction = {
  __typename?: 'InvoiceAccountsPayableTransaction';
  accountId?: Maybe<Scalars['ID']['output']>;
  amount: Scalars['Float']['output'];
  business: Business;
  invoiceId: Scalars['ID']['output'];
  lastUpdated: Scalars['String']['output'];
  paymentMethod?: Maybe<InvoiceAccountPayablePaymentMethod>;
  scheduledDate?: Maybe<Scalars['String']['output']>;
  scheduledPaymentId?: Maybe<Scalars['ID']['output']>;
  status: InvoiceTransactionStatus;
  subAccount: SubAccount;
  transactionId?: Maybe<Scalars['ID']['output']>;
};

export type InvoiceAttachmentData = {
  __typename?: 'InvoiceAttachmentData';
  invoiceDate?: Maybe<Scalars['String']['output']>;
  invoiceDueDate?: Maybe<Scalars['String']['output']>;
  invoiceNumber?: Maybe<Scalars['String']['output']>;
  invoiceTotal?: Maybe<Scalars['String']['output']>;
  paymentDetails?: Maybe<InvoiceAttachmentDataPaymentDetails>;
  vendorName?: Maybe<Scalars['String']['output']>;
};

export type InvoiceAttachmentDataPaymentDetails = {
  __typename?: 'InvoiceAttachmentDataPaymentDetails';
  accountNumber?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  paymentTerms?: Maybe<Scalars['String']['output']>;
  personalOrBusinessName?: Maybe<Scalars['String']['output']>;
  routingNumber?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  streetAddress?: Maybe<Scalars['String']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type InvoiceAttachmentFile = {
  __typename?: 'InvoiceAttachmentFile';
  contentType: Scalars['String']['output'];
  expires: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export enum InvoiceAttachmentStatus {
  COMPLETED = 'COMPLETED',
  CREATED = 'CREATED',
  FAILED = 'FAILED',
  IN_PROGRESS = 'IN_PROGRESS'
}

export type InvoiceAttachmentUpload = {
  __typename?: 'InvoiceAttachmentUpload';
  expires: Scalars['String']['output'];
  fields: Array<InvoiceAttachmentUploadFields>;
  url: Scalars['String']['output'];
};

export type InvoiceAttachmentUploadFields = {
  __typename?: 'InvoiceAttachmentUploadFields';
  headerKey: Scalars['String']['output'];
  headerValue: Scalars['String']['output'];
};

export enum InvoicePaymentStatus {
  FAILED = 'failed',
  PROCESSING = 'processing',
  SCHEDULED = 'scheduled'
}

export enum InvoiceStatus {
  ARCHIVED = 'archived',
  PAID = 'paid',
  UNPAID = 'unpaid'
}

export enum InvoiceTransactionStatus {
  CANCELED = 'canceled',
  FAILED = 'failed',
  INITIATED = 'initiated',
  POSTED = 'posted',
  SCHEDULED = 'scheduled'
}

export type KybIndustry = {
  __typename?: 'KYBIndustry';
  keywords: Scalars['String']['output'];
  naicsCategory: Scalars['String']['output'];
  naicsCode: Scalars['Int']['output'];
  subCategory: Scalars['String']['output'];
  vertical: Scalars['String']['output'];
};

export type KanmonConnectTokenMutationResponse = MutationResponse & {
  __typename?: 'KanmonConnectTokenMutationResponse';
  code: Scalars['String']['output'];
  connectToken: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type KanmonLending = {
  __typename?: 'KanmonLending';
  business: Business;
  products: Array<KanmonLendingProduct>;
};

export type KanmonLendingProduct = {
  __typename?: 'KanmonLendingProduct';
  business: Business;
  createdAt: Scalars['String']['output'];
  id: Scalars['String']['output'];
  loanStatus: KanmonLoanStatusEnum;
  loanType: KanmonLoanTypeEnum;
};

export enum KanmonLoanStatusEnum {
  CURRENT = 'CURRENT',
  DEFAULTED = 'DEFAULTED',
  FULLY_PAID = 'FULLY_PAID',
  LATE = 'LATE',
  REFINANCED = 'REFINANCED'
}

export enum KanmonLoanTypeEnum {
  LINE_OF_CREDIT = 'LINE_OF_CREDIT',
  TERM_LOAN = 'TERM_LOAN'
}

export type LinkDebitCardInput = {
  businessId: Scalars['ID']['input'];
  cardData: InitialCardDataInput;
  idempotencyKey: Scalars['String']['input'];
};

export type LinkDebitCardResponse = MutationResponse & {
  __typename?: 'LinkDebitCardResponse';
  code: Scalars['String']['output'];
  linkedDebitCardId: Scalars['ID']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type LinkTokenData = {
  __typename?: 'LinkTokenData';
  expirationTimestamp: Scalars['String']['output'];
  linkToken: Scalars['String']['output'];
};

export type LinkedDebitCard = {
  __typename?: 'LinkedDebitCard';
  expiryDate: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  last4: Scalars['String']['output'];
};

export type LinkedDebitCardsListResponse = {
  __typename?: 'LinkedDebitCardsListResponse';
  linkedDebitCards: Array<LinkedDebitCard>;
};

export type ListInvoiceAccountsPayableQueryResponse = {
  __typename?: 'ListInvoiceAccountsPayableQueryResponse';
  invoices: Array<InvoiceAccountsPayable>;
  pagination?: Maybe<InvoiceAccountsPayablePagination>;
};

export type ListOwnersQueryResponse = {
  __typename?: 'ListOwnersQueryResponse';
  owners: Array<TeamOwner>;
};

export type Location = {
  __typename?: 'Location';
  city?: Maybe<Scalars['String']['output']>;
  coordinates?: Maybe<Coordinates>;
  country?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  provinceState?: Maybe<Scalars['String']['output']>;
  streetAddressLine1?: Maybe<Scalars['String']['output']>;
  streetAddressLine2?: Maybe<Scalars['String']['output']>;
};

/** We need to use this input when creating or updating locations. */
export type LocationInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  provinceState?: InputMaybe<Scalars['String']['input']>;
  streetAddressLine1?: InputMaybe<Scalars['String']['input']>;
  streetAddressLine2?: InputMaybe<Scalars['String']['input']>;
};

export type LocationStrictInput = {
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  provinceState: Scalars['String']['input'];
  streetAddressLine1: Scalars['String']['input'];
  streetAddressLine2?: InputMaybe<Scalars['String']['input']>;
};

export type MailedCheckCapability = {
  __typename?: 'MailedCheckCapability';
  fee: Fee;
  isEligible: Scalars['Boolean']['output'];
  limits: MailedCheckLimits;
};

export type MailedCheckCreateInput = {
  amount: Scalars['Float']['input'];
  contactId: Scalars['ID']['input'];
  contactNotificationOptions?: InputMaybe<TransactionContactNotificationOptionsInput>;
  idempotencyKey: Scalars['String']['input'];
  memo?: InputMaybe<Scalars['String']['input']>;
  subAccountId: Scalars['ID']['input'];
};

export type MailedCheckCreateMutationResponse = MutationResponse & {
  __typename?: 'MailedCheckCreateMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type MailedCheckLimits = {
  __typename?: 'MailedCheckLimits';
  MAX_PER_TRANSACTION: Scalars['Float']['output'];
};

export enum MailingAddressLocationEnum {
  BUSINESS = 'BUSINESS',
  PERSONAL = 'PERSONAL'
}

export type MonthlyAccountStatements = {
  __typename?: 'MonthlyAccountStatements';
  closingBalance?: Maybe<Scalars['Float']['output']>;
  month?: Maybe<Scalars['String']['output']>;
  openingBalance?: Maybe<Scalars['Float']['output']>;
  totalDeposits?: Maybe<Scalars['Float']['output']>;
  totalFees?: Maybe<Scalars['Float']['output']>;
  totalWithdrawals?: Maybe<Scalars['Float']['output']>;
  year?: Maybe<Scalars['String']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  accountUsageSubmit: AccountUsageSubmitResponse;
  addVirtualCard?: Maybe<AddVirtualCardResponse>;
  announcementInteract?: Maybe<AnnouncementInteractResponse>;
  applicationDataUpdate: ApplicationDataUpdateResponse;
  businessAccessTokenGenerate?: Maybe<BusinessAccessTokenMutationResponse>;
  cancelScheduledPayment?: Maybe<CancelScheduledPaymentResponse>;
  captureInitialFundingAccountType?: Maybe<CaptureInitialFundingAccountTypeMutationResponse>;
  captureInitialFundingAmount?: Maybe<CaptureInitialFundingAmountMutationResponse>;
  cardActivate?: Maybe<CardActivateResponse>;
  cardFreeze?: Maybe<CardFreezeResponse>;
  cardFundingSubmit?: Maybe<CardFundingSubmitResponse>;
  cardUnfreeze?: Maybe<CardUnfreezeResponse>;
  checkDepositCreate?: Maybe<CheckDepositCreateMutationResponse>;
  checkOnboardingApplicationEligibility: OnboardingApplicationEligibilityCheckResponse;
  confirmAdditionalOwnersOnboardingStatus?: Maybe<ConfirmAdditionalOwnersOnboardingStatusMutationResponse>;
  contactBillPayPaymentDetailsCreate?: Maybe<ContactBillPayPaymentDetailsCreateMutationResponse>;
  contactBillPayPaymentDetailsDelete?: Maybe<ContactBillPayPaymentDetailsDeleteMutationResponse>;
  contactBillPayPaymentDetailsUpdate?: Maybe<ContactBillPayPaymentDetailsUpdateMutationResponse>;
  contactCreate?: Maybe<ContactCreateMutationResponse>;
  contactDelete?: Maybe<ContactDeleteMutationResponse>;
  contactDomesticAchCreate?: Maybe<ContactCreateMutationResponse>;
  contactDomesticAchUpdate?: Maybe<ContactUpdateMutationResponse>;
  contactDomesticWireCreate?: Maybe<ContactCreateMutationResponse>;
  contactDomesticWireUpdate?: Maybe<ContactUpdateMutationResponse>;
  contactMailedCheckCreate?: Maybe<ContactCreateMutationResponse>;
  contactMailedCheckUpdate?: Maybe<ContactUpdateMutationResponse>;
  contactUpdate?: Maybe<ContactUpdateMutationResponse>;
  createInvoiceAccountsPayablePayment?: Maybe<CreateInvoiceAccountsPayablePaymentMutationResponse>;
  createInvoiceAccountsPayableScheduledPayment?: Maybe<CreateInvoiceAccountsPayableScheduledPaymentMutationResponse>;
  createInvoiceAttachment?: Maybe<CreateInvoiceAttachmentMutationResponse>;
  createKanmonConnectToken?: Maybe<KanmonConnectTokenMutationResponse>;
  createLinkToken?: Maybe<CreateLinkTokenMutationResponse>;
  debitCardDepositSubmit?: Maybe<ConcreteMutationResponse>;
  deleteLinkedDebitCard?: Maybe<ConcreteMutationResponse>;
  disableTopUps?: Maybe<DisableTopUpsResponse>;
  documentRequestResendEmail: DocumentRequestEmailResendResponse;
  domesticWireCreate?: Maybe<DomesticWireCreateMutationResponse>;
  exchangePublicToken?: Maybe<ExchangePublicTokenMutationResponse>;
  homeAnnouncementInteract?: Maybe<AnnouncementInteractResponse>;
  inAppReviewDismiss?: Maybe<InAppReviewDismissResponse>;
  initialCardFundingSubmit?: Maybe<InitialCardFundingSubmitResponse>;
  inviteAdditionalOwner?: Maybe<InviteAdditionalOwnerMutationResponse>;
  linkDebitCard?: Maybe<LinkDebitCardResponse>;
  mailedCheckCreate?: Maybe<MailedCheckCreateMutationResponse>;
  markInvoiceAccountsPayableAsArchived?: Maybe<UpdateInvoiceAccountsPayableMutationResponse>;
  markInvoiceAccountsPayableAsPaid?: Maybe<UpdateInvoiceAccountsPayableMutationResponse>;
  markInvoiceAccountsPayableAsUnarchived?: Maybe<UpdateInvoiceAccountsPayableMutationResponse>;
  markInvoiceAccountsPayableAsUnpaid?: Maybe<UpdateInvoiceAccountsPayableMutationResponse>;
  onboardingAgreementsTermsAndConditionsAccept: OnboardingAgreementsTermsAndConditionsAcceptMutationResponse;
  onboardingApplicationSubmit: OnboardingApplicationSubmitResponse;
  onboardingMailingAddressUpdate: OnboardingMailingAddressUpdateSubmitResponse;
  preconfigureTopUpsSettings?: Maybe<PreconfigureTopUpsSettingsResponse>;
  redeemReferralOTP?: Maybe<PartnershipReferral>;
  registerPerson?: Maybe<RegisterPersonResponse>;
  registerUser?: Maybe<RegisterUserResponse>;
  registerUserAndTermsAccept?: Maybe<RegisterUserResponse>;
  removePlaidItem?: Maybe<RemovePlaidItemResponse>;
  requestPasswordReset?: Maybe<ConcreteMutationResponse>;
  /** userEmail must be same email that the user signed up with */
  resendVerificationEmail?: Maybe<ConcreteMutationResponse>;
  /** ** deprecated ** use resetPassword instead */
  resetMyPassword?: Maybe<ConcreteMutationResponse>;
  resetPassword?: Maybe<ConcreteMutationResponse>;
  retryFundingTransaction?: Maybe<RetryFundingTransactionMutationResponse>;
  revenueHubConnectionDelete?: Maybe<RevenueHubConnectionDeleteMutationResponse>;
  revenueHubConnectionSave?: Maybe<RevenueHubConnectionSaveMutationResponse>;
  saveInvoiceAccountsPayable?: Maybe<SaveInvoiceAccountsPayableMutationResponse>;
  shareAndEarnRedeemedOfferMarkAsSeen?: Maybe<ShareAndEarnRedeemedOfferMarkAsSeenMutationResponse>;
  shareAndEarnResendBlockedCustomerEmail?: Maybe<ShareAndEarnResendBlockedCustomerEmailMutationResponse>;
  shareAndEarnUserRegisterAndOfferAccept?: Maybe<RegisterUserResponse>;
  subAccountBatchCreate?: Maybe<SubAccountBatchCreateResponse>;
  subAccountBatchUpdate?: Maybe<SubAccountBatchUpdateResponse>;
  subAccountCreate?: Maybe<SubAccountCreateResponse>;
  subAccountDelete?: Maybe<SubAccountDeleteResponse>;
  subAccountRulePercentCreate?: Maybe<SubAccountRulePercentCreateMutationResponse>;
  subAccountRulePercentDelete?: Maybe<SubAccountRulePercentDeleteMutationResponse>;
  subAccountRulePercentUpdate?: Maybe<SubAccountRulePercentUpdateMutationResponse>;
  subAccountTransferCreate?: Maybe<SubAccountTransferCreateMutationResponse>;
  subAccountUpdate?: Maybe<SubAccountUpdateResponse>;
  subAccountsReconcile?: Maybe<SubAccountsReconcileResponse>;
  subscriptionCancel?: Maybe<SubscriptionCancelMutationResponse>;
  subscriptionReactivate?: Maybe<SubscriptionReactivateMutationResponse>;
  subscriptionUpgrade?: Maybe<SubscriptionUpgradeMutationResponse>;
  supportPhoneCallBookingCreate?: Maybe<SupportPhoneCallBookingMutationResponse>;
  transactionNoteUpdate?: Maybe<TransactionNoteUpdateResponse>;
  transactionsACHCreate?: Maybe<TransactionsAchCreateMutationResponse>;
  updateScheduledPayment?: Maybe<UpdateScheduledPaymentResponse>;
  updateTopUpsSettings?: Maybe<UpdateTopUpsSettingsResponse>;
  updateUser?: Maybe<UpdateUserMutationResponse>;
};


export type MutationAccountUsageSubmitArgs = {
  data: AccountUsageData;
};


export type MutationAddVirtualCardArgs = {
  accountId: Scalars['ID']['input'];
  businessId: Scalars['ID']['input'];
};


export type MutationAnnouncementInteractArgs = {
  actionEvent: AnnouncementActionEventEnum;
  type: AnnouncementTypeEnum;
};


export type MutationApplicationDataUpdateArgs = {
  application: ApplicationInput;
  businessId: Scalars['String']['input'];
  metadata?: InputMaybe<Scalars['JSONObject']['input']>;
};


export type MutationBusinessAccessTokenGenerateArgs = {
  businessId: Scalars['ID']['input'];
  clientId: Scalars['String']['input'];
  token: Scalars['String']['input'];
  tokenType: AuthTokenTypeEnum;
};


export type MutationCancelScheduledPaymentArgs = {
  businessId: Scalars['ID']['input'];
  scheduledPaymentId: Scalars['ID']['input'];
};


export type MutationCaptureInitialFundingAccountTypeArgs = {
  accountType: Scalars['String']['input'];
  businessId: Scalars['ID']['input'];
  idempotencyKey: Scalars['String']['input'];
};


export type MutationCaptureInitialFundingAmountArgs = {
  amountInCents: Scalars['Int']['input'];
  businessId: Scalars['ID']['input'];
  businessType?: InputMaybe<BusinessType>;
};


export type MutationCardActivateArgs = {
  accountId: Scalars['ID']['input'];
  businessId: Scalars['ID']['input'];
  cardId: Scalars['ID']['input'];
  expirationDate: Scalars['String']['input'];
  securityCode: Scalars['String']['input'];
};


export type MutationCardFreezeArgs = {
  accountId: Scalars['ID']['input'];
  businessId: Scalars['ID']['input'];
  cardId: Scalars['ID']['input'];
  endDate?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCardFundingSubmitArgs = {
  cardFundingInput: CardFundingInput;
};


export type MutationCardUnfreezeArgs = {
  accountId: Scalars['ID']['input'];
  businessId: Scalars['ID']['input'];
  cardId: Scalars['ID']['input'];
};


export type MutationCheckDepositCreateArgs = {
  businessId: Scalars['ID']['input'];
  data: CheckDepositCreateInput;
};


export type MutationCheckOnboardingApplicationEligibilityArgs = {
  data: PartialOnboardingApplicationDataWithoutSsn;
};


export type MutationConfirmAdditionalOwnersOnboardingStatusArgs = {
  businessId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationContactBillPayPaymentDetailsCreateArgs = {
  data: ContactBillPayPaymentDetailsCreateInput;
};


export type MutationContactBillPayPaymentDetailsDeleteArgs = {
  data: ContactBillPayPaymentDetailsDeleteInput;
};


export type MutationContactBillPayPaymentDetailsUpdateArgs = {
  data: ContactBillPayPaymentDetailsUpdateInput;
};


export type MutationContactCreateArgs = {
  input: ContactCreateDataInput;
};


export type MutationContactDeleteArgs = {
  input: ContactDeleteInput;
};


export type MutationContactDomesticAchCreateArgs = {
  data: ContactDomesticAchCreateDataInput;
};


export type MutationContactDomesticAchUpdateArgs = {
  data: ContactDomesticAchUpdateDataInput;
};


export type MutationContactDomesticWireCreateArgs = {
  data: ContactDomesticWireCreateDataInput;
};


export type MutationContactDomesticWireUpdateArgs = {
  data: ContactDomesticWireUpdateDataInput;
};


export type MutationContactMailedCheckCreateArgs = {
  data: ContactMailedCheckCreateDataInput;
};


export type MutationContactMailedCheckUpdateArgs = {
  data: ContactMailedCheckUpdateDataInput;
};


export type MutationContactUpdateArgs = {
  input: ContactUpdateDataInput;
};


export type MutationCreateInvoiceAccountsPayablePaymentArgs = {
  businessId: Scalars['ID']['input'];
  data: CreateInvoiceAccountsPayablePaymentDataInput;
  invoiceId: Scalars['ID']['input'];
};


export type MutationCreateInvoiceAccountsPayableScheduledPaymentArgs = {
  businessId: Scalars['ID']['input'];
  data: CreateInvoiceAccountsPayableScheduledPaymentDataInput;
  invoiceId: Scalars['ID']['input'];
};


export type MutationCreateInvoiceAttachmentArgs = {
  attachmentData: CreateInvoiceAttachmentDataInput;
  businessId: Scalars['ID']['input'];
};


export type MutationCreateKanmonConnectTokenArgs = {
  businessId: Scalars['ID']['input'];
};


export type MutationCreateLinkTokenArgs = {
  androidPackageName?: InputMaybe<Scalars['String']['input']>;
  businessId: Scalars['ID']['input'];
  itemId?: InputMaybe<Scalars['String']['input']>;
  redirectUri?: InputMaybe<Scalars['String']['input']>;
};


export type MutationDebitCardDepositSubmitArgs = {
  input: DebitCardDepositSubmitInput;
};


export type MutationDeleteLinkedDebitCardArgs = {
  input: DeleteLinkedDebitCardInput;
};


export type MutationDisableTopUpsArgs = {
  accountId: Scalars['ID']['input'];
  businessId: Scalars['ID']['input'];
  data: DisableTopUpsDataInput;
};


export type MutationDocumentRequestResendEmailArgs = {
  data: DocumentRequestEmailResendData;
};


export type MutationDomesticWireCreateArgs = {
  businessId: Scalars['ID']['input'];
  data: DomesticWireCreateInput;
};


export type MutationExchangePublicTokenArgs = {
  accountType?: InputMaybe<PlaidAccountType>;
  businessId: Scalars['ID']['input'];
  identityCheckInputs?: InputMaybe<IdentityCheckInputs>;
  institutionName?: InputMaybe<Scalars['String']['input']>;
  plaidAccountId: Scalars['String']['input'];
  publicToken: Scalars['String']['input'];
};


export type MutationHomeAnnouncementInteractArgs = {
  actionEvent: AnnouncementActionEventEnum;
  announcementId: Scalars['ID']['input'];
};


export type MutationInAppReviewDismissArgs = {
  inAppReviewType: InAppReviewType;
};


export type MutationInitialCardFundingSubmitArgs = {
  initialCardFundingInput: InitialCardFundingInput;
};


export type MutationInviteAdditionalOwnerArgs = {
  businessId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationLinkDebitCardArgs = {
  input: LinkDebitCardInput;
};


export type MutationMailedCheckCreateArgs = {
  businessId: Scalars['ID']['input'];
  data: MailedCheckCreateInput;
};


export type MutationMarkInvoiceAccountsPayableAsArchivedArgs = {
  businessId: Scalars['ID']['input'];
  invoiceId: Scalars['ID']['input'];
};


export type MutationMarkInvoiceAccountsPayableAsPaidArgs = {
  businessId: Scalars['ID']['input'];
  invoiceId: Scalars['ID']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
};


export type MutationMarkInvoiceAccountsPayableAsUnarchivedArgs = {
  businessId: Scalars['ID']['input'];
  invoiceId: Scalars['ID']['input'];
};


export type MutationMarkInvoiceAccountsPayableAsUnpaidArgs = {
  businessId: Scalars['ID']['input'];
  invoiceId: Scalars['ID']['input'];
};


export type MutationOnboardingAgreementsTermsAndConditionsAcceptArgs = {
  businessId: Scalars['String']['input'];
  documentReference: Scalars['String']['input'];
};


export type MutationOnboardingApplicationSubmitArgs = {
  data: OnboardingApplicationSubmitData;
};


export type MutationOnboardingMailingAddressUpdateArgs = {
  data: OnboardingMailingAddressUpdateData;
};


export type MutationPreconfigureTopUpsSettingsArgs = {
  businessId: Scalars['ID']['input'];
  data: UpdateTopUpsSettingsDataInput;
};


export type MutationRedeemReferralOtpArgs = {
  otp?: InputMaybe<Scalars['String']['input']>;
};


export type MutationRegisterPersonArgs = {
  businessId: Scalars['String']['input'];
  personId: Scalars['String']['input'];
};


export type MutationRegisterUserArgs = {
  phone?: InputMaybe<Scalars['String']['input']>;
};


export type MutationRegisterUserAndTermsAcceptArgs = {
  clientId?: InputMaybe<Scalars['String']['input']>;
  documentReference: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  refreshToken: Scalars['String']['input'];
};


export type MutationRemovePlaidItemArgs = {
  businessId: Scalars['ID']['input'];
  itemId: Scalars['String']['input'];
};


export type MutationRequestPasswordResetArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
};


export type MutationResendVerificationEmailArgs = {
  reCAPTCHAToken: Scalars['String']['input'];
  userEmail: Scalars['String']['input'];
};


export type MutationResetMyPasswordArgs = {
  newPassword: Scalars['String']['input'];
};


export type MutationResetPasswordArgs = {
  newPassword: Scalars['String']['input'];
  oldPassword: Scalars['String']['input'];
};


export type MutationRetryFundingTransactionArgs = {
  amountInCents: Scalars['Int']['input'];
  businessId: Scalars['ID']['input'];
  itemId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationRevenueHubConnectionDeleteArgs = {
  businessId: Scalars['ID']['input'];
  connectionId: Scalars['ID']['input'];
};


export type MutationRevenueHubConnectionSaveArgs = {
  input: RevenueHubConnectionSaveInput;
};


export type MutationSaveInvoiceAccountsPayableArgs = {
  businessId: Scalars['ID']['input'];
  invoiceData: InvoiceAccountsPayableDataInput;
};


export type MutationShareAndEarnRedeemedOfferMarkAsSeenArgs = {
  businessId: Scalars['ID']['input'];
  offerId: Scalars['ID']['input'];
  participantType: ShareAndEarnParticipantTypeEnum;
};


export type MutationShareAndEarnResendBlockedCustomerEmailArgs = {
  advocateId: Scalars['ID']['input'];
  businessId: Scalars['ID']['input'];
};


export type MutationShareAndEarnUserRegisterAndOfferAcceptArgs = {
  advocateId: Scalars['ID']['input'];
  advocateSource?: InputMaybe<Scalars['String']['input']>;
  phone: Scalars['String']['input'];
};


export type MutationSubAccountBatchCreateArgs = {
  bankAccountId: Scalars['ID']['input'];
  businessId: Scalars['ID']['input'];
  data: Array<SubAccountBatchCreateInput>;
  replaceExistingSubAccounts?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationSubAccountBatchUpdateArgs = {
  bankAccountId: Scalars['ID']['input'];
  businessId: Scalars['ID']['input'];
  data: Array<SubAccountBatchUpdateInput>;
};


export type MutationSubAccountCreateArgs = {
  bankAccountId: Scalars['ID']['input'];
  businessId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};


export type MutationSubAccountDeleteArgs = {
  businessId: Scalars['ID']['input'];
  subAccountId: Scalars['ID']['input'];
};


export type MutationSubAccountRulePercentCreateArgs = {
  businessId: Scalars['ID']['input'];
  data: SubAccountRulePercentCreateData;
  subAccountId: Scalars['ID']['input'];
};


export type MutationSubAccountRulePercentDeleteArgs = {
  businessId: Scalars['ID']['input'];
  subAccountId: Scalars['ID']['input'];
};


export type MutationSubAccountRulePercentUpdateArgs = {
  businessId: Scalars['ID']['input'];
  data: SubAccountRulePercentUpdateData;
  subAccountId: Scalars['ID']['input'];
};


export type MutationSubAccountTransferCreateArgs = {
  businessId: Scalars['ID']['input'];
  data: SubAccountTransferCreateInput;
};


export type MutationSubAccountUpdateArgs = {
  businessId: Scalars['ID']['input'];
  data: SubAccountUpdateDataInput;
  subAccountId: Scalars['ID']['input'];
};


export type MutationSubAccountsReconcileArgs = {
  businessId: Scalars['ID']['input'];
  data: Array<SubAccountReconcileDataInput>;
};


export type MutationSubscriptionCancelArgs = {
  input: SubscriptionCancelInput;
};


export type MutationSubscriptionReactivateArgs = {
  input: SubscriptionReactivateInput;
};


export type MutationSubscriptionUpgradeArgs = {
  input: SubscriptionUpgradeInput;
};


export type MutationSupportPhoneCallBookingCreateArgs = {
  data: SupportPhoneCallCreateInput;
};


export type MutationTransactionNoteUpdateArgs = {
  businessId: Scalars['ID']['input'];
  note: Scalars['String']['input'];
  transactionId: Scalars['ID']['input'];
};


export type MutationTransactionsAchCreateArgs = {
  businessId: Scalars['ID']['input'];
  data: TransactionsAchCreateInput;
};


export type MutationUpdateScheduledPaymentArgs = {
  businessId: Scalars['ID']['input'];
  scheduledPaymentId: Scalars['ID']['input'];
  updateScheduledPaymentInput: UpdateScheduledPaymentInput;
};


export type MutationUpdateTopUpsSettingsArgs = {
  accountId: Scalars['ID']['input'];
  businessId: Scalars['ID']['input'];
  data: UpdateTopUpsSettingsDataInput;
};


export type MutationUpdateUserArgs = {
  businessId: Scalars['ID']['input'];
  userData?: InputMaybe<UserDataInput>;
  userId: Scalars['ID']['input'];
};

export type MutationPayload = {
  userErrors: Array<UserError>;
};

export type MutationResponse = {
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type NaicsIndustries = {
  __typename?: 'NaicsIndustries';
  defaultIndustry: KybIndustry;
  groupedIndustries: Array<GroupedIndustries>;
};

export enum OnboardingAccountStatus {
  ACCOUNT_USAGE_SURVEY_SUBMITTED = 'ACCOUNT_USAGE_SURVEY_SUBMITTED',
  NEEDS_ACCOUNT_USAGE_SURVEY = 'NEEDS_ACCOUNT_USAGE_SURVEY',
  OPENED = 'OPENED'
}

export type OnboardingAgreements = {
  __typename?: 'OnboardingAgreements';
  termsAndConditions: Agreement;
};

export type OnboardingAgreementsTermsAndConditionsAcceptMutationResponse = MutationResponse & {
  __typename?: 'OnboardingAgreementsTermsAndConditionsAcceptMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type OnboardingApplication = {
  __typename?: 'OnboardingApplication';
  businessAddress?: Maybe<Location>;
  businessId: Scalars['ID']['output'];
  homeAddress?: Maybe<Location>;
  id: Scalars['ID']['output'];
  requiredDocumentUpload?: Maybe<OnboardingRequiredDocumentUpload>;
  status?: Maybe<OnboardingApplicationStatus>;
};

export type OnboardingApplicationBusinessInput = {
  address: LocationStrictInput;
  annualRevenue: Scalars['Float']['input'];
  businessType: BusinessType;
  dba?: InputMaybe<Scalars['String']['input']>;
  description: Scalars['String']['input'];
  ein?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  legalName: Scalars['String']['input'];
  naicsCode: Scalars['String']['input'];
  numberOfEmployees: Scalars['Int']['input'];
  phone: Scalars['String']['input'];
  typeOfProductsOrServices: Scalars['String']['input'];
  website?: InputMaybe<Scalars['String']['input']>;
  yearsAtLocation: Scalars['Int']['input'];
};

export type OnboardingApplicationEligibility = {
  __typename?: 'OnboardingApplicationEligibility';
  isEligible: Scalars['Boolean']['output'];
};

export type OnboardingApplicationEligibilityCheckResponse = MutationResponse & {
  __typename?: 'OnboardingApplicationEligibilityCheckResponse';
  code: Scalars['String']['output'];
  data: OnboardingApplicationEligibility;
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type OnboardingApplicationOwnerInput = {
  address: LocationStrictInput;
  dateOfBirth: Scalars['String']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  isControlPerson: Scalars['Boolean']['input'];
  isPrimaryOwner: Scalars['Boolean']['input'];
  jobTitle: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  ownershipPercentage: Scalars['Int']['input'];
  phone: Scalars['String']['input'];
  ssn: Scalars['String']['input'];
};

export enum OnboardingApplicationStatus {
  APPROVED = 'APPROVED',
  REFUSED = 'REFUSED',
  REJECTED = 'REJECTED',
  REVIEWING = 'REVIEWING',
  SUBMITTED = 'SUBMITTED'
}

export type OnboardingApplicationSubmitData = {
  business: OnboardingApplicationBusinessInput;
  owners: Array<OnboardingApplicationOwnerInput>;
  socureDeviceSessionId?: InputMaybe<Scalars['String']['input']>;
};

export type OnboardingApplicationSubmitResponse = MutationResponse & {
  __typename?: 'OnboardingApplicationSubmitResponse';
  application: OnboardingApplication;
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type OnboardingMailingAddressUpdateData = {
  address: LocationStrictInput;
  businessId: Scalars['ID']['input'];
};

export type OnboardingMailingAddressUpdateSubmitResponse = MutationResponse & {
  __typename?: 'OnboardingMailingAddressUpdateSubmitResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type OnboardingPersonaInquiry = {
  __typename?: 'OnboardingPersonaInquiry';
  activeSessionToken?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  status: OnboardingPersonaInquiryStatusEnum;
};

export enum OnboardingPersonaInquiryStatusEnum {
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  PENDING = 'PENDING'
}

export type OnboardingRequiredDocumentUpload = {
  __typename?: 'OnboardingRequiredDocumentUpload';
  personaInquiry?: Maybe<OnboardingPersonaInquiry>;
  requiredDocuments: Array<RequiredDocumentEnum>;
};

export enum OperatingSystemEnum {
  ANDROID = 'ANDROID',
  IOS = 'IOS'
}

export type OutgoingWireCapability = {
  __typename?: 'OutgoingWireCapability';
  fee: Fee;
  isEligible: Scalars['Boolean']['output'];
  limits: TransferLimits;
};

export type OwnerCandidate = {
  __typename?: 'OwnerCandidate';
  address?: Maybe<PersonAddress>;
  businessId: Scalars['String']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  ownershipPercentage: Scalars['Float']['output'];
  shippingAddress?: Maybe<PersonAddress>;
  title: Scalars['String']['output'];
  type: Scalars['String']['output'];
  userId?: Maybe<Scalars['String']['output']>;
};

export type PartialOnboardingApplicationBusinessInput = {
  address?: InputMaybe<LocationStrictInput>;
  annualRevenue?: InputMaybe<Scalars['Float']['input']>;
  businessType?: InputMaybe<BusinessType>;
  dba?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  ein?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  legalName?: InputMaybe<Scalars['String']['input']>;
  naicsCode?: InputMaybe<Scalars['String']['input']>;
  numberOfEmployees?: InputMaybe<Scalars['Int']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  typeOfProductsOrServices?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
  yearsAtLocation?: InputMaybe<Scalars['Int']['input']>;
};

export type PartialOnboardingApplicationDataWithoutSsn = {
  business: PartialOnboardingApplicationBusinessInput;
  persons?: InputMaybe<Array<PartialOnboardingApplicationOwnerInputWithoutSsn>>;
  socureDeviceSessionId?: InputMaybe<Scalars['String']['input']>;
};

export type PartialOnboardingApplicationOwnerInputWithoutSsn = {
  address?: InputMaybe<LocationStrictInput>;
  dateOfBirth?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  isControlPerson?: InputMaybe<Scalars['Boolean']['input']>;
  isPrimaryOwner?: InputMaybe<Scalars['Boolean']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  ownershipPercentage?: InputMaybe<Scalars['Int']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type PartnershipApplication = {
  __typename?: 'PartnershipApplication';
  business?: Maybe<ApplicantBusiness>;
  id?: Maybe<Scalars['String']['output']>;
  persons?: Maybe<Array<Maybe<Person>>>;
};

export type PartnershipReferral = {
  __typename?: 'PartnershipReferral';
  application?: Maybe<PartnershipApplication>;
};

export type PercentFee = {
  __typename?: 'PercentFee';
  /** @deprecated Field is not resolved. Use transactionFees query instead. */
  calculatedFee?: Maybe<Scalars['Float']['output']>;
  maximum: Scalars['Float']['output'];
  minimum: Scalars['Float']['output'];
  percentage: Scalars['Float']['output'];
  type: FeeType;
};

export type PercentFeeDetails = {
  __typename?: 'PercentFeeDetails';
  feeType: FeeType;
  maximumAmount: Scalars['Float']['output'];
  minimumAmount: Scalars['Float']['output'];
  percent: Scalars['Float']['output'];
};

export type Person = {
  __typename?: 'Person';
  address?: Maybe<Address>;
  dateOfBirth?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  ownershipPercentage?: Maybe<Scalars['Int']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type PersonAddress = {
  __typename?: 'PersonAddress';
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  line1: Scalars['String']['output'];
  line2?: Maybe<Scalars['String']['output']>;
  postalCode: Scalars['String']['output'];
  state: Scalars['String']['output'];
};

export type PersonInput = {
  address?: InputMaybe<AddressInput>;
  dateOfBirth?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  ownershipPercentage?: InputMaybe<Scalars['Int']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export enum PlaidAccountType {
  BUSINESS = 'BUSINESS',
  PERSONAL = 'PERSONAL'
}

export type PlaidAccounts = {
  __typename?: 'PlaidAccounts';
  accounts?: Maybe<Array<PlaidAccountsData>>;
};

export type PlaidAccountsData = {
  __typename?: 'PlaidAccountsData';
  account_id?: Maybe<Scalars['String']['output']>;
  balances?: Maybe<PlaidBalances>;
  mask?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  official_name?: Maybe<Scalars['String']['output']>;
  owners?: Maybe<Array<Maybe<PlaidOwners>>>;
  subtype?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  verification_status?: Maybe<Scalars['String']['output']>;
};

export type PlaidAuthData = {
  __typename?: 'PlaidAuthData';
  accounts?: Maybe<Array<PlaidAccountsData>>;
  item?: Maybe<PlaidItemData>;
};

export type PlaidBalances = {
  __typename?: 'PlaidBalances';
  available?: Maybe<Scalars['Float']['output']>;
  current?: Maybe<Scalars['Float']['output']>;
  iso_currency_code?: Maybe<Scalars['String']['output']>;
};

export type PlaidFundingCapabilitiesQueryResponse = {
  __typename?: 'PlaidFundingCapabilitiesQueryResponse';
  initialFunding: InitialFundingCapability;
  items: PlaidItemsCapability;
  topUps: TopUpsCapability;
};

export enum PlaidFundingStatusEnum {
  FUNDING_ATTEMPT_INITIATED = 'FUNDING_ATTEMPT_INITIATED',
  FUNDING_BLOCKED = 'FUNDING_BLOCKED',
  MICRODEPOSITS_VERIFICATION_FAILED = 'MICRODEPOSITS_VERIFICATION_FAILED',
  MICRODEPOSITS_VERIFICATION_INITIATED = 'MICRODEPOSITS_VERIFICATION_INITIATED',
  MICRODEPOSITS_VERIFIED = 'MICRODEPOSITS_VERIFIED',
  PLAID_ITEM_DEACTIVATED = 'PLAID_ITEM_DEACTIVATED',
  PLAID_NAME_MISMATCH = 'PLAID_NAME_MISMATCH',
  TRANSACTION_FAILED = 'TRANSACTION_FAILED',
  TRANSACTION_INITIATED = 'TRANSACTION_INITIATED',
  TRANSACTION_NON_SUFFICIENT_BALANCE = 'TRANSACTION_NON_SUFFICIENT_BALANCE',
  TRANSACTION_POSTED = 'TRANSACTION_POSTED',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR'
}

export type PlaidInstitution = {
  __typename?: 'PlaidInstitution';
  id: Scalars['ID']['output'];
  logo?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type PlaidItem = {
  __typename?: 'PlaidItem';
  accountId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  institution?: Maybe<PlaidInstitution>;
  maskedAccountNumber?: Maybe<Scalars['String']['output']>;
  status: PlaidItemStatus;
};

export type PlaidItemData = {
  __typename?: 'PlaidItemData';
  item_id?: Maybe<Scalars['String']['output']>;
};

export type PlaidItemIdAndStatus = {
  __typename?: 'PlaidItemIdAndStatus';
  id: Scalars['ID']['output'];
  status: PlaidItemStatus;
};

export enum PlaidItemStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}

export type PlaidItemsCapability = {
  __typename?: 'PlaidItemsCapability';
  maximumItemCount: Scalars['Int']['output'];
};

export type PlaidItemsQueryResponse = {
  __typename?: 'PlaidItemsQueryResponse';
  data: Array<PlaidItem>;
};

export type PlaidOwners = {
  __typename?: 'PlaidOwners';
  addresses?: Maybe<Array<Maybe<PlaidOwnersAddresses>>>;
  emails?: Maybe<Array<Maybe<PlaidOwnersEmails>>>;
  names?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  phone_numbers?: Maybe<Array<Maybe<PlaidOwnersPhoneNumbers>>>;
};

export type PlaidOwnersAddress = {
  __typename?: 'PlaidOwnersAddress';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  postal_code?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
};

export type PlaidOwnersAddresses = {
  __typename?: 'PlaidOwnersAddresses';
  data?: Maybe<PlaidOwnersAddress>;
  primary: Scalars['Boolean']['output'];
};

export type PlaidOwnersEmails = {
  __typename?: 'PlaidOwnersEmails';
  data: Scalars['String']['output'];
  primary: Scalars['Boolean']['output'];
  type: Scalars['String']['output'];
};

export type PlaidOwnersPhoneNumbers = {
  __typename?: 'PlaidOwnersPhoneNumbers';
  data: Scalars['String']['output'];
  primary: Scalars['Boolean']['output'];
  type: Scalars['String']['output'];
};

export enum PlaidTopUpsErrorCodeEnum {
  INSUFFICIENT_BALANCE = 'INSUFFICIENT_BALANCE',
  PLAID_ITEM_ERROR = 'PLAID_ITEM_ERROR',
  PLAID_ITEM_EXPIRED = 'PLAID_ITEM_EXPIRED',
  UNKNOWN = 'UNKNOWN',
  VELOCITY_LIMIT_30D = 'VELOCITY_LIMIT_30D'
}

export type PreconfigureTopUpsSettingsResponse = MutationResponse & {
  __typename?: 'PreconfigureTopUpsSettingsResponse';
  code: Scalars['String']['output'];
  data: PreconfiguredTopUpSettings;
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type PreconfiguredTopUpSettings = {
  __typename?: 'PreconfiguredTopUpSettings';
  lowBalanceThreshold: Scalars['Float']['output'];
  plaidItem: PlaidItemIdAndStatus;
  topUpAmount: Scalars['Float']['output'];
};

export type PrimaryContact = {
  __typename?: 'PrimaryContact';
  dob?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type PrimaryContactInput = {
  dob?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type Query = {
  __typename?: 'Query';
  acceptedAgreements?: Maybe<Array<Maybe<AcceptedAgreement>>>;
  achCutoffTimes?: Maybe<AchCutoffTimesResponse>;
  agreementsPDF: AgreementsPdf;
  announcement?: Maybe<Announcement>;
  billingInvoicePDF?: Maybe<BillingInvoicePdf>;
  business?: Maybe<Business>;
  cardFundingRSAEncryptionKey?: Maybe<RsaEncryptionKey>;
  cardPinChangeForm?: Maybe<CardPinChangeForm>;
  checkDepositTransactionsList?: Maybe<TransactionsListResponse>;
  debitCardDepositAmountIsValid?: Maybe<DebitCardDepositAmountIsValidResponse>;
  digitalCardAssetUrl?: Maybe<DigitalCardAssetUrl>;
  emailExists?: Maybe<Scalars['Boolean']['output']>;
  getFundingAmounts?: Maybe<GetFundingAmountsQueryResponse>;
  getFundingStatus?: Maybe<GetFundingStatusQueryResponse>;
  getInvoiceAccountsPayable?: Maybe<GetInvoiceAccountsPayableQueryResponse>;
  getInvoiceAttachment?: Maybe<GetInvoiceAttachmentResponse>;
  getPlaidAuthData?: Maybe<GetPlaidAuthDataQueryResponse>;
  getPlaidBalance?: Maybe<GetBalanceQueryResponse>;
  getPlaidIdentity?: Maybe<GetIdentityQueryResponse>;
  getTopUpsSettings?: Maybe<GetTopUpsSettingsQueryResponse>;
  homeAnnouncements: Array<HomeAnnouncement>;
  ingoCustomer?: Maybe<IngoCustomerResponse>;
  kanmonLending: KanmonLending;
  linkedDebitCardsList?: Maybe<LinkedDebitCardsListResponse>;
  listInvoiceAccountsPayable?: Maybe<ListInvoiceAccountsPayableQueryResponse>;
  listOwners?: Maybe<ListOwnersQueryResponse>;
  me?: Maybe<User>;
  naics?: Maybe<NaicsIndustries>;
  /**
   * ** deprecated ** use termsAndConditionsPDF instead
   * @deprecated use termsAndConditionsPDF instead
   */
  onboardingAgreements?: Maybe<OnboardingAgreements>;
  plaidFundingCapabilities?: Maybe<PlaidFundingCapabilitiesQueryResponse>;
  plaidItems?: Maybe<PlaidItemsQueryResponse>;
  releaseVersion?: Maybe<Scalars['String']['output']>;
  requiredDocuments: RequiredDocumentsResponse;
  revenueHub: RevenueHub;
  routingNumberACHIsValid?: Maybe<Scalars['Boolean']['output']>;
  routingNumberWireIsValid?: Maybe<Scalars['Boolean']['output']>;
  searchBillerDirectory?: Maybe<SearchBillerDirectoryResponse>;
  shareAndEarnMicrosite?: Maybe<ShareAndEarnMicrosite>;
  subAccountRuleTotalPercentAvailable?: Maybe<Scalars['Int']['output']>;
  subscription?: Maybe<Subscription>;
  subscriptionPlans?: Maybe<SubscriptionPlans>;
  supportHelpCenter: SupportHelpCenterService;
  supportPhoneCall: SupportPhoneCallService;
  termsAndConditionsPDF?: Maybe<TermsAndConditionsPdf>;
  timeSaved?: Maybe<TimeSavedResponse>;
  topUpsAmounts?: Maybe<TopUpsAmountsQueryResponse>;
  transaction?: Maybe<TransactionListItem>;
  transactionFees?: Maybe<TransactionFeesResponse>;
  transactionSearchCSV?: Maybe<TransactionSearchCsvResponse>;
  transactionUpsellContent?: Maybe<TransactionUpsellContent>;
  transactionUpsellContentPerType?: Maybe<TransactionUpsellContentPerType>;
  transactionsList?: Maybe<TransactionsListResponse>;
  verifyBusinessNameIsValid?: Maybe<Scalars['Boolean']['output']>;
};


export type QueryAcceptedAgreementsArgs = {
  businessId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type QueryAchCutoffTimesArgs = {
  businessId: Scalars['ID']['input'];
};


export type QueryAgreementsPdfArgs = {
  type: AgreementType;
};


export type QueryAnnouncementArgs = {
  type: AnnouncementTypeEnum;
};


export type QueryBillingInvoicePdfArgs = {
  billingInvoiceId: Scalars['ID']['input'];
};


export type QueryBusinessArgs = {
  businessId: Scalars['ID']['input'];
};


export type QueryCardPinChangeFormArgs = {
  accountId: Scalars['ID']['input'];
  businessId: Scalars['ID']['input'];
  cardId: Scalars['ID']['input'];
};


export type QueryCheckDepositTransactionsListArgs = {
  businessId: Scalars['ID']['input'];
  filters?: InputMaybe<TransactionsListFiltersInput>;
  pagination?: InputMaybe<TransactionsPaginationOptionsInput>;
};


export type QueryDebitCardDepositAmountIsValidArgs = {
  amount: Scalars['Float']['input'];
  businessId: Scalars['ID']['input'];
};


export type QueryDigitalCardAssetUrlArgs = {
  accountId: Scalars['ID']['input'];
  businessId: Scalars['ID']['input'];
  cardId: Scalars['ID']['input'];
};


export type QueryEmailExistsArgs = {
  email: Scalars['String']['input'];
};


export type QueryGetFundingAmountsArgs = {
  businessId: Scalars['ID']['input'];
  businessType?: InputMaybe<BusinessType>;
};


export type QueryGetFundingStatusArgs = {
  businessId: Scalars['ID']['input'];
};


export type QueryGetInvoiceAccountsPayableArgs = {
  businessId: Scalars['ID']['input'];
  invoiceId: Scalars['ID']['input'];
};


export type QueryGetInvoiceAttachmentArgs = {
  attachmentId: Scalars['ID']['input'];
  businessId: Scalars['ID']['input'];
};


export type QueryGetPlaidAuthDataArgs = {
  businessId: Scalars['ID']['input'];
};


export type QueryGetPlaidBalanceArgs = {
  businessId: Scalars['ID']['input'];
};


export type QueryGetPlaidIdentityArgs = {
  businessId: Scalars['ID']['input'];
};


export type QueryGetTopUpsSettingsArgs = {
  accountId: Scalars['ID']['input'];
  businessId: Scalars['ID']['input'];
};


export type QueryIngoCustomerArgs = {
  mobileDeviceId: Scalars['ID']['input'];
};


export type QueryKanmonLendingArgs = {
  businessId: Scalars['ID']['input'];
};


export type QueryLinkedDebitCardsListArgs = {
  businessId: Scalars['ID']['input'];
};


export type QueryListInvoiceAccountsPayableArgs = {
  businessId: Scalars['ID']['input'];
  filters?: InputMaybe<InvoiceAccountsPayableListFiltersInput>;
  pagination?: InputMaybe<InvoiceAccountsPayableListPaginationOptionsInput>;
  sorting: InvoiceAccountsPayableListSortingInput;
};


export type QueryListOwnersArgs = {
  businessId: Scalars['ID']['input'];
};


export type QueryPlaidFundingCapabilitiesArgs = {
  businessId: Scalars['ID']['input'];
};


export type QueryPlaidItemsArgs = {
  businessId: Scalars['ID']['input'];
};


export type QueryRequiredDocumentsArgs = {
  applicationId: Scalars['String']['input'];
  businessId: Scalars['String']['input'];
};


export type QueryRevenueHubArgs = {
  businessId: Scalars['ID']['input'];
};


export type QueryRoutingNumberAchIsValidArgs = {
  routingNumber: Scalars['String']['input'];
};


export type QueryRoutingNumberWireIsValidArgs = {
  routingNumber: Scalars['String']['input'];
};


export type QuerySearchBillerDirectoryArgs = {
  billerName: Scalars['String']['input'];
};


export type QuerySubAccountRuleTotalPercentAvailableArgs = {
  businessId: Scalars['ID']['input'];
};


export type QuerySubscriptionArgs = {
  businessId: Scalars['ID']['input'];
};


export type QuerySubscriptionPlansArgs = {
  businessId: Scalars['ID']['input'];
};


export type QueryTimeSavedArgs = {
  businessId: Scalars['ID']['input'];
};


export type QueryTopUpsAmountsArgs = {
  businessId: Scalars['ID']['input'];
};


export type QueryTransactionArgs = {
  businessId: Scalars['ID']['input'];
  transactionId: Scalars['ID']['input'];
};


export type QueryTransactionFeesArgs = {
  amount: Scalars['Float']['input'];
  businessId: Scalars['ID']['input'];
};


export type QueryTransactionSearchCsvArgs = {
  businessId: Scalars['ID']['input'];
  csvFormat?: InputMaybe<CsvFormatEnum>;
  filters?: InputMaybe<TransactionsListFiltersInput>;
};


export type QueryTransactionUpsellContentArgs = {
  businessId: Scalars['ID']['input'];
};


export type QueryTransactionUpsellContentPerTypeArgs = {
  businessId: Scalars['ID']['input'];
  transactionType: SubscriptionUpsellTransactionType;
};


export type QueryTransactionsListArgs = {
  businessId: Scalars['ID']['input'];
  filters?: InputMaybe<TransactionsListFiltersInput>;
  pagination?: InputMaybe<TransactionsPaginationOptionsInput>;
};


export type QueryVerifyBusinessNameIsValidArgs = {
  businessType?: InputMaybe<BusinessType>;
  name: Scalars['String']['input'];
};

export type RsaEncryptionKey = {
  __typename?: 'RSAEncryptionKey';
  id: Scalars['String']['output'];
  key: Scalars['String']['output'];
};

export enum RecipientTypeEnum {
  BILL_PAYMENT = 'BILL_PAYMENT',
  DOMESTIC_ACH = 'DOMESTIC_ACH',
  DOMESTIC_WIRE = 'DOMESTIC_WIRE',
  INTERNATIONAL_WIRE = 'INTERNATIONAL_WIRE'
}

export type RegisterPersonData = {
  __typename?: 'RegisterPersonData';
  businessId: Scalars['String']['output'];
  personId: Scalars['String']['output'];
};

export type RegisterPersonResponse = MutationResponse & {
  __typename?: 'RegisterPersonResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
  user?: Maybe<RegisteredPerson>;
};

export type RegisterUserData = {
  __typename?: 'RegisterUserData';
  businessId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
};

export type RegisterUserResponse = MutationResponse & {
  __typename?: 'RegisterUserResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
  user?: Maybe<RegisterUserData>;
};

export type RegisteredPerson = {
  __typename?: 'RegisteredPerson';
  auth0Id?: Maybe<Scalars['String']['output']>;
  businessId?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  userId: Scalars['ID']['output'];
};

export type RemovePlaidItemResponse = MutationResponse & {
  __typename?: 'RemovePlaidItemResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export enum RequiredDocumentEnum {
  DBA_DOC = 'DBA_DOC',
  GOVERNMENT_ID = 'GOVERNMENT_ID',
  INCORPORATION_DOC = 'INCORPORATION_DOC',
  SELFIE = 'SELFIE'
}

export type RequiredDocumentsCollection = {
  __typename?: 'RequiredDocumentsCollection';
  customerId: Scalars['Boolean']['output'];
  dba: Scalars['Boolean']['output'];
  incorporation: Scalars['Boolean']['output'];
};

export type RequiredDocumentsResponse = {
  __typename?: 'RequiredDocumentsResponse';
  applicationId: Scalars['String']['output'];
  businessId: Scalars['String']['output'];
  documents: RequiredDocumentsCollection;
};

export type RetryFundingTransactionMutationResponse = MutationResponse & {
  __typename?: 'RetryFundingTransactionMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type RevenueHub = {
  __typename?: 'RevenueHub';
  business: Business;
  connection: RevenueHubConnection;
  connections: Array<RevenueHubConnection>;
};


export type RevenueHubConnectionArgs = {
  id: Scalars['ID']['input'];
};

export type RevenueHubConnection = {
  __typename?: 'RevenueHubConnection';
  business: Business;
  /** Rutter connectionId */
  id: Scalars['ID']['output'];
  /**
   * URL that can be used to re-authenticate a connection. This URL launches the Rutter Link web app .
   * For more info, see: https://docs.rutterapi.com/connections/connection-url
   */
  linkUpdateUrl: Scalars['String']['output'];
  platform: RevenueHubConnectionPlatformEnum;
  revenueDetails?: Maybe<RevenueHubConnectionDetails>;
  status: RevenueHubConnectionStatusEnum;
};


export type RevenueHubConnectionRevenueDetailsArgs = {
  filters: RevenueHubConnectionFiltersInput;
};

export type RevenueHubConnectionDateTimeFilterInput = {
  /** dateTime in ISO 8601 format */
  endDateTime: Scalars['String']['input'];
  /** dateTime in ISO 8601 format */
  startDateTime: Scalars['String']['input'];
};

export type RevenueHubConnectionDeleteMutationResponse = MutationResponse & {
  __typename?: 'RevenueHubConnectionDeleteMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type RevenueHubConnectionDetails = {
  __typename?: 'RevenueHubConnectionDetails';
  /** Current balance pulled for the platform. Filters have no impact on this value. */
  currentBalance?: Maybe<Scalars['Float']['output']>;
  /** Funds earned per day in the provided time period */
  grossRevenuePerDayTimeSeries: Array<RevenueHubConnectionDetailsRevenuePerDay>;
  /** Total funds earned for the provided time period */
  totalGrossRevenue: Scalars['Float']['output'];
  /** Total funds that have been transferred out for the provided time period */
  totalPaidOutAmount?: Maybe<Scalars['Float']['output']>;
  /** Total amount of returns made by customers for the provided time period */
  totalReturnsAmount: Scalars['Float']['output'];
  /** Amount of sales made for the provided time period */
  transactionsCount: Scalars['Int']['output'];
};

export type RevenueHubConnectionDetailsRevenuePerDay = {
  __typename?: 'RevenueHubConnectionDetailsRevenuePerDay';
  /** Date in ISO 8601 format. eg. 2023-05-01 */
  date: Scalars['String']['output'];
  totalRevenueAmount: Scalars['Float']['output'];
};

export type RevenueHubConnectionFiltersInput = {
  /** Represents the time period that the fetched data will reflect (excluding currentBalance) */
  dateTimeFilter: RevenueHubConnectionDateTimeFilterInput;
};

export enum RevenueHubConnectionPlatformEnum {
  AMAZON = 'AMAZON',
  ETSY = 'ETSY',
  PAYPAL = 'PAYPAL',
  SHOPIFY = 'SHOPIFY',
  SQUARE = 'SQUARE',
  STRIPE = 'STRIPE'
}

export type RevenueHubConnectionSaveInput = {
  businessId: Scalars['ID']['input'];
  platform: RevenueHubConnectionPlatformEnum;
  /**
   * Once the Rutter Link flow is complete, the publicToken, which represents a
   * succesful authentication, will be passed to the Rutter Link onSuccess callback
   */
  publicToken: Scalars['String']['input'];
};

export type RevenueHubConnectionSaveMutationResponse = MutationResponse & {
  __typename?: 'RevenueHubConnectionSaveMutationResponse';
  code: Scalars['String']['output'];
  connection: RevenueHubConnection;
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export enum RevenueHubConnectionStatusEnum {
  /** Connection is active and data can be fetched from Rutter */
  ACTIVE = 'ACTIVE',
  /**
   * Returned when a request has been made for a connection that has been temporarily disabled.
   * See disabled_reason in API docs for possible causes of this status.
   * Docs: https://docs.rutterapi.com/rest/2023-03-14/connections#fetch-a-connection-status
   */
  DISABLED = 'DISABLED',
  /**
   * Returned when a request has been made for a connection that
   * needs re-authentication. The connection link url can be used to
   * re-authenticate the connection.
   */
  NEEDS_REAUTH = 'NEEDS_REAUTH',
  /** Returned when a request has been made for data that is not yet ready. */
  NOT_READY = 'NOT_READY'
}

export type RppsBillerDetails = {
  __typename?: 'RppsBillerDetails';
  billerName: Scalars['String']['output'];
  rppsBillerId: Scalars['String']['output'];
};

export type SameDayAchCapability = {
  __typename?: 'SameDayAchCapability';
  fee: Fee;
  isEligible: Scalars['Boolean']['output'];
  limits: TransferLimits;
};

export type SaveInvoiceAccountsPayableMutationResponse = MutationResponse & {
  __typename?: 'SaveInvoiceAccountsPayableMutationResponse';
  code: Scalars['String']['output'];
  invoiceId: Scalars['ID']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
  userErrors?: Maybe<Array<UserError>>;
};

export enum ScheduleFrequencyEnum {
  EVERY_DAY = 'EVERY_DAY',
  EVERY_MONTH = 'EVERY_MONTH',
  EVERY_TWO_WEEKS = 'EVERY_TWO_WEEKS',
  EVERY_WEEK = 'EVERY_WEEK',
  ONCE = 'ONCE'
}

export type SearchBillerDirectoryResponse = {
  __typename?: 'SearchBillerDirectoryResponse';
  billers: Array<Biller>;
};

export type ShareAndEarn = {
  __typename?: 'ShareAndEarn';
  /** A user acting as an advocate can have multiple offers */
  advocate?: Maybe<ShareAndEarnAdvocate>;
  businessId: Scalars['ID']['output'];
  copyJson?: Maybe<Scalars['String']['output']>;
  /** as a user can you can only be a referral once */
  referral?: Maybe<ShareAndEarnReferral>;
};


export type ShareAndEarnCopyJsonArgs = {
  section?: InputMaybe<ShareAndEarnCopySectionEnum>;
};

export type ShareAndEarnAdvocate = {
  __typename?: 'ShareAndEarnAdvocate';
  hasUnseenRedeemedOffer: Scalars['Boolean']['output'];
  isBlocked: Scalars['Boolean']['output'];
  isEligible: Scalars['Boolean']['output'];
  offerUrl: Scalars['String']['output'];
  totalRedeemedAmount: Scalars['Float']['output'];
  totalRedeemedOffers: Scalars['Int']['output'];
};

export enum ShareAndEarnCopySectionEnum {
  ADVOCATE_OFFER_REDEEMED_SPLASH_SCREEN = 'ADVOCATE_OFFER_REDEEMED_SPLASH_SCREEN',
  HOME = 'HOME',
  REFERRAL_OFFER_ACCEPTED_SPLASH_SCREEN = 'REFERRAL_OFFER_ACCEPTED_SPLASH_SCREEN',
  REFERRAL_OFFER_REDEEMED_SPLASH_SCREEN = 'REFERRAL_OFFER_REDEEMED_SPLASH_SCREEN',
  SHARE_AND_EARN_HUB = 'SHARE_AND_EARN_HUB'
}

export type ShareAndEarnMicrosite = {
  __typename?: 'ShareAndEarnMicrosite';
  advocate?: Maybe<ShareAndEarnMicrositeAdvocate>;
  copyJson?: Maybe<Scalars['String']['output']>;
};


export type ShareAndEarnMicrositeAdvocateArgs = {
  advocateId: Scalars['ID']['input'];
};

export type ShareAndEarnMicrositeAdvocate = {
  __typename?: 'ShareAndEarnMicrositeAdvocate';
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isValid: Scalars['Boolean']['output'];
};

export enum ShareAndEarnParticipantTypeEnum {
  ADVOCATE = 'ADVOCATE',
  REFERRAL = 'REFERRAL'
}

export type ShareAndEarnRedeemedOfferMarkAsSeenMutationResponse = MutationResponse & {
  __typename?: 'ShareAndEarnRedeemedOfferMarkAsSeenMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type ShareAndEarnReferral = {
  __typename?: 'ShareAndEarnReferral';
  offerId: Scalars['String']['output'];
  offerStatus: ShareAndEarnReferralOfferStatusEnum;
};

export enum ShareAndEarnReferralOfferStatusEnum {
  ACCEPTED = 'ACCEPTED',
  REDEEMED = 'REDEEMED',
  SEEN_REDEEMED_SPLASH_SCREEN = 'SEEN_REDEEMED_SPLASH_SCREEN'
}

export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc'
}

export enum SortKey {
  CREATEDDATE = 'createdDate',
  DUEDATE = 'dueDate',
  INVOICEDATE = 'invoiceDate',
  UPDATEDDATE = 'updatedDate'
}

export type SubAccount = {
  __typename?: 'SubAccount';
  balance: Scalars['Float']['output'];
  businessId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  isDefault?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  percentRule?: Maybe<SubAccountRulePercent>;
};

export type SubAccountBatchCreateInput = {
  initialAmount?: InputMaybe<Scalars['Float']['input']>;
  name: Scalars['String']['input'];
  percentToTransfer?: InputMaybe<Scalars['Int']['input']>;
};

export type SubAccountBatchCreateResponse = MutationResponse & {
  __typename?: 'SubAccountBatchCreateResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type SubAccountBatchUpdateInput = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['String']['input'];
  newPercentRule?: InputMaybe<Scalars['Int']['input']>;
};

export type SubAccountBatchUpdateResponse = MutationResponse & {
  __typename?: 'SubAccountBatchUpdateResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type SubAccountCreateResponse = MutationResponse & {
  __typename?: 'SubAccountCreateResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  subAccount?: Maybe<SubAccount>;
  success: Scalars['Boolean']['output'];
};

export type SubAccountDeleteResponse = MutationResponse & {
  __typename?: 'SubAccountDeleteResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type SubAccountReconcileDataInput = {
  amount: Scalars['Float']['input'];
  subAccountId: Scalars['ID']['input'];
};

export type SubAccountRulePercent = {
  __typename?: 'SubAccountRulePercent';
  availableUpdateLimit?: Maybe<Scalars['Int']['output']>;
  businessId: Scalars['ID']['output'];
  percent?: Maybe<Scalars['Int']['output']>;
};

export type SubAccountRulePercentCreateData = {
  percent: Scalars['Int']['input'];
};

export type SubAccountRulePercentCreateMutationResponse = {
  __typename?: 'SubAccountRulePercentCreateMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  subAccountRulePercent?: Maybe<SubAccountRulePercent>;
  success: Scalars['Boolean']['output'];
};

export type SubAccountRulePercentDeleteMutationResponse = {
  __typename?: 'SubAccountRulePercentDeleteMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type SubAccountRulePercentUpdateData = {
  percent?: InputMaybe<Scalars['Int']['input']>;
};

export type SubAccountRulePercentUpdateMutationResponse = {
  __typename?: 'SubAccountRulePercentUpdateMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  subAccountRulePercent?: Maybe<SubAccountRulePercent>;
  success: Scalars['Boolean']['output'];
};

export type SubAccountTransferCreateInput = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  destinationSubAccountId: Scalars['ID']['input'];
  sourceSubAccountId: Scalars['ID']['input'];
};

export type SubAccountTransferCreateMutationResponse = MutationResponse & {
  __typename?: 'SubAccountTransferCreateMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type SubAccountUpdateDataInput = {
  name: Scalars['String']['input'];
};

export type SubAccountUpdateResponse = MutationResponse & {
  __typename?: 'SubAccountUpdateResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type SubAccountsReconcileResponse = MutationResponse & {
  __typename?: 'SubAccountsReconcileResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type Subscription = {
  __typename?: 'Subscription';
  accountId?: Maybe<Scalars['ID']['output']>;
  /**
   * ISO Date string
   * @example 2023-10-06
   */
  nextBillingDate?: Maybe<Scalars['String']['output']>;
  paymentPeriod?: Maybe<SubscriptionPlanPricePeriod>;
  plan: Scalars['String']['output'];
  price?: Maybe<Scalars['Float']['output']>;
  /**
   * Full DateTime string
   * @example 2023-10-06T13:00:00.000Z
   */
  startDate?: Maybe<Scalars['String']['output']>;
  status: SubscriptionStatus;
};

export type SubscriptionCancelInput = {
  businessId: Scalars['ID']['input'];
};

export type SubscriptionCancelMutationResponse = MutationResponse & {
  __typename?: 'SubscriptionCancelMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  subscription: Subscription;
  success: Scalars['Boolean']['output'];
};

export type SubscriptionPlan = {
  __typename?: 'SubscriptionPlan';
  content: SubscriptionPlanContent;
  isCurrentPlan?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
};

export type SubscriptionPlanContent = {
  __typename?: 'SubscriptionPlanContent';
  description?: Maybe<Array<Scalars['String']['output']>>;
  featured?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Array<SubscriptionPlanContentPrice>>;
  retentionFeatures?: Maybe<Array<SubscriptionPlanContentSectionBullet>>;
  sections?: Maybe<Array<SubscriptionPlanContentSection>>;
  shortTitle?: Maybe<Scalars['String']['output']>;
  subtitle?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  upgrade?: Maybe<SubscriptionPlanContentUpgrade>;
  upgradeButtonText?: Maybe<Scalars['String']['output']>;
  upsell?: Maybe<SubscriptionPlanContentUpsell>;
};

export type SubscriptionPlanContentPrice = {
  __typename?: 'SubscriptionPlanContentPrice';
  period?: Maybe<SubscriptionPlanPricePeriod>;
  price?: Maybe<Scalars['Float']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

export type SubscriptionPlanContentSection = {
  __typename?: 'SubscriptionPlanContentSection';
  bullets?: Maybe<Array<SubscriptionPlanContentSectionBullet>>;
  heading?: Maybe<Scalars['String']['output']>;
};

export type SubscriptionPlanContentSectionBullet = {
  __typename?: 'SubscriptionPlanContentSectionBullet';
  icon?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

export type SubscriptionPlanContentUpgrade = {
  __typename?: 'SubscriptionPlanContentUpgrade';
  successSubtitle: Scalars['String']['output'];
  successTitle: Scalars['String']['output'];
};

export type SubscriptionPlanContentUpsell = {
  __typename?: 'SubscriptionPlanContentUpsell';
  subtitle: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export enum SubscriptionPlanPricePeriod {
  MONTHLY = 'MONTHLY'
}

export type SubscriptionPlans = {
  __typename?: 'SubscriptionPlans';
  footer?: Maybe<Array<Scalars['String']['output']>>;
  plans?: Maybe<Array<SubscriptionPlan>>;
};

export type SubscriptionReactivateInput = {
  businessId: Scalars['ID']['input'];
};

export type SubscriptionReactivateMutationResponse = MutationResponse & {
  __typename?: 'SubscriptionReactivateMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  subscription: Subscription;
  success: Scalars['Boolean']['output'];
};

export enum SubscriptionStatus {
  ACTIVE = 'ACTIVE',
  CANCEL_REQUESTED = 'CANCEL_REQUESTED'
}

export type SubscriptionUpgradeInput = {
  accountId: Scalars['ID']['input'];
  businessId: Scalars['ID']['input'];
  idempotencyKey: Scalars['String']['input'];
  paymentPeriod: SubscriptionPlanPricePeriod;
  plan: Scalars['String']['input'];
};

export type SubscriptionUpgradeMutationResponse = MutationResponse & {
  __typename?: 'SubscriptionUpgradeMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  subscription: Subscription;
  success: Scalars['Boolean']['output'];
};

export enum SubscriptionUpsellTransactionType {
  ACH = 'ACH',
  ACH_SAMEDAY = 'ACH_SAMEDAY',
  BILL_PAYMENT = 'BILL_PAYMENT',
  OUTGOING_WIRE = 'OUTGOING_WIRE',
  PHYSICAL_CHECK = 'PHYSICAL_CHECK'
}

export type SupportArticle = {
  __typename?: 'SupportArticle';
  body?: Maybe<Scalars['String']['output']>;
  htmlBody?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export enum SupportArticleCategoryEnum {
  FAQS = 'FAQS'
}

export enum SupportArticleLocaleEnum {
  EN_US = 'EN_US'
}

export type SupportArticleSearch = {
  __typename?: 'SupportArticleSearch';
  faqs: Array<SupportArticleSearchResult>;
};


export type SupportArticleSearchFaqsArgs = {
  searchTerm: Scalars['String']['input'];
};

export type SupportArticleSearchResult = {
  __typename?: 'SupportArticleSearchResult';
  body?: Maybe<Scalars['String']['output']>;
  htmlBody?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export type SupportArticleSection = {
  __typename?: 'SupportArticleSection';
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type SupportHelpCenterService = {
  __typename?: 'SupportHelpCenterService';
  /** View a single article */
  article?: Maybe<SupportArticle>;
  /** List of support FAQ articles */
  faqs: Array<SupportArticle>;
  /** Search for articles */
  search?: Maybe<SupportArticleSearch>;
  /** List of support article sections */
  sections: Array<SupportArticleSection>;
};


export type SupportHelpCenterServiceArticleArgs = {
  articleId: Scalars['ID']['input'];
};


export type SupportHelpCenterServiceFaqsArgs = {
  sectionId?: InputMaybe<Scalars['ID']['input']>;
};

export type SupportPhoneCallBooking = {
  __typename?: 'SupportPhoneCallBooking';
  confirmationEmail?: Maybe<Scalars['String']['output']>;
  /** dateTime formatted as a unix timestamp */
  dateTime?: Maybe<Scalars['String']['output']>;
};

export type SupportPhoneCallBookingMutationResponse = MutationPayload & {
  __typename?: 'SupportPhoneCallBookingMutationResponse';
  supportPhoneCallBooking?: Maybe<SupportPhoneCallBooking>;
  userErrors: Array<UserError>;
};

export type SupportPhoneCallCreateInput = {
  /** dateTime formatted as a unix timestamp */
  dateTime: Scalars['String']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  notes: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  reCAPTCHAToken?: InputMaybe<Scalars['String']['input']>;
  /** Format example: America/New_York */
  timezone?: InputMaybe<Scalars['String']['input']>;
};

export type SupportPhoneCallService = {
  __typename?: 'SupportPhoneCallService';
  /** list all available slots for a specific day */
  availableSlotsForDay: Array<Scalars['String']['output']>;
  /** list total number of slots available per day for a week from startDate */
  availableSlotsForWeek: Array<SupportPhoneCallSlots>;
};


export type SupportPhoneCallServiceAvailableSlotsForDayArgs = {
  date: Scalars['String']['input'];
  timezone: Scalars['String']['input'];
};


export type SupportPhoneCallServiceAvailableSlotsForWeekArgs = {
  startDate?: InputMaybe<Scalars['String']['input']>;
  timezone: Scalars['String']['input'];
};

export type SupportPhoneCallSlots = {
  __typename?: 'SupportPhoneCallSlots';
  date?: Maybe<Scalars['String']['output']>;
  slots?: Maybe<Scalars['Int']['output']>;
};

export enum SupportTicketStatusEnum {
  OPEN = 'OPEN',
  RESOLVED = 'RESOLVED'
}

export type TeamOwner = {
  __typename?: 'TeamOwner';
  address?: Maybe<PersonAddress>;
  businessId: Scalars['String']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isPrimaryOwner?: Maybe<Scalars['Boolean']['output']>;
  lastName: Scalars['String']['output'];
  onboardingCompleted?: Maybe<Scalars['Boolean']['output']>;
  ownershipPercentage: Scalars['Float']['output'];
  shippingAddress?: Maybe<PersonAddress>;
  title: Scalars['String']['output'];
  type: Scalars['String']['output'];
  userId?: Maybe<Scalars['String']['output']>;
};

export type TermsAndConditionsPdf = {
  __typename?: 'TermsAndConditionsPDF';
  documentReference?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type TimeSavedResponse = {
  __typename?: 'TimeSavedResponse';
  pdfGuidelineUrl?: Maybe<Scalars['String']['output']>;
  perMailedCheckTimeSavedSeconds: Scalars['Float']['output'];
  perSameDayAchTimeSavedSeconds: Scalars['Float']['output'];
  perStandardAchTimeSavedSeconds: Scalars['Float']['output'];
  perWireTimeSavedSeconds: Scalars['Float']['output'];
  timeSavedTotalSeconds: Scalars['Float']['output'];
};

export type TopUpAmounts = {
  __typename?: 'TopUpAmounts';
  defaultAmount: Scalars['Float']['output'];
  defaultThresholdAmount: Scalars['Float']['output'];
  maximumAmount: Scalars['Float']['output'];
  minimumAmount: Scalars['Float']['output'];
  minimumThresholdAmount: Scalars['Float']['output'];
};

export type TopUpSettings = {
  __typename?: 'TopUpSettings';
  isTopUpsEnabled: Scalars['Boolean']['output'];
  lowBalanceThreshold: Scalars['Float']['output'];
  plaidItem: PlaidItemIdAndStatus;
  topUpAmount: Scalars['Float']['output'];
};

export type TopUpVelocityLimits = {
  __typename?: 'TopUpVelocityLimits';
  maxTransactionCountPer30DayRolling: Scalars['Int']['output'];
};

export type TopUpsAmountsQueryResponse = {
  __typename?: 'TopUpsAmountsQueryResponse';
  defaultAmount: Scalars['Int']['output'];
  defaultThresholdAmount: Scalars['Int']['output'];
  maximumAmount: Scalars['Int']['output'];
  /** Maximum number of active Plaid items per business */
  maximumItemCount: Scalars['Int']['output'];
  minimumAmount: Scalars['Int']['output'];
};

export type TopUpsCapability = {
  __typename?: 'TopUpsCapability';
  amounts: TopUpAmounts;
  isEligible: Scalars['Boolean']['output'];
  velocityLimits: TopUpVelocityLimits;
};

export type TopUpsError = {
  __typename?: 'TopUpsError';
  code: PlaidTopUpsErrorCodeEnum;
  message: Scalars['String']['output'];
};

export type TransactionContactNotificationOptionsInput = {
  sendEmail?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum TransactionDirectionEnum {
  CREDIT = 'CREDIT',
  DEBIT = 'DEBIT'
}

export type TransactionFeesResponse = {
  __typename?: 'TransactionFeesResponse';
  billPay: Scalars['Float']['output'];
  domesticWire: Scalars['Float']['output'];
  mailedCheck: Scalars['Float']['output'];
  sameDayAch: Scalars['Float']['output'];
};

export type TransactionListItem = {
  __typename?: 'TransactionListItem';
  amount: Scalars['Float']['output'];
  businessId: Scalars['ID']['output'];
  cardLast4?: Maybe<Scalars['String']['output']>;
  /** datetime in ISO 8601 format */
  createdAt?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  errorMessage?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isBancorpUpgradeDeposit?: Maybe<Scalars['Boolean']['output']>;
  memo?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  /** datetime in ISO 8601 format */
  postedAt?: Maybe<Scalars['String']['output']>;
  returnedBy?: Maybe<Scalars['String']['output']>;
  status?: Maybe<TransactionStatusEnum>;
  transactionType: TransactionTypeEnum;
  user?: Maybe<BasicUser>;
};

export type TransactionNoteUpdateResponse = MutationResponse & {
  __typename?: 'TransactionNoteUpdateResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type TransactionSearchCsvResponse = {
  __typename?: 'TransactionSearchCSVResponse';
  csvURL: Scalars['String']['output'];
};

export enum TransactionSortByDateEnum {
  CREATED_AT = 'CREATED_AT',
  ESTIMATED_COMPLETION = 'ESTIMATED_COMPLETION'
}

export enum TransactionStatusEnum {
  CANCELED = 'CANCELED',
  CANCELLATION_INITIATED = 'CANCELLATION_INITIATED',
  CREATED = 'CREATED',
  EXPIRED = 'EXPIRED',
  FAILED = 'FAILED',
  INITIATED = 'INITIATED',
  IN_REVIEW = 'IN_REVIEW',
  POSTED = 'POSTED'
}

export enum TransactionTypeEnum {
  ACH = 'ACH',
  ACH_RETURN = 'ACH_RETURN',
  BILL_PAYMENT = 'BILL_PAYMENT',
  BILL_PAYMENT_REFUND = 'BILL_PAYMENT_REFUND',
  CARD_PURCHASE = 'CARD_PURCHASE',
  CHECK_DEPOSIT = 'CHECK_DEPOSIT',
  DOMESTIC_WIRE = 'DOMESTIC_WIRE',
  FEE = 'FEE',
  FEE_REVERSAL = 'FEE_REVERSAL',
  GENERIC = 'GENERIC',
  LENDING = 'LENDING',
  MAILED_CHECK = 'MAILED_CHECK',
  MAILED_CHECK_REFUND = 'MAILED_CHECK_REFUND',
  RTP_RECEIVE = 'RTP_RECEIVE',
  SUB_ACCOUNT_TRANSFER = 'SUB_ACCOUNT_TRANSFER',
  WIRE_RETURN = 'WIRE_RETURN',
  WIRE_REVERSAL = 'WIRE_REVERSAL'
}

export type TransactionUpsellContent = {
  __typename?: 'TransactionUpsellContent';
  ach: TransactionUpsellContentPerType;
  billPayment: TransactionUpsellContentPerType;
  outgoingWire: TransactionUpsellContentPerType;
  physicalCheck: TransactionUpsellContentPerType;
  sameDayAch: TransactionUpsellContentPerType;
};

export type TransactionUpsellContentPerType = {
  __typename?: 'TransactionUpsellContentPerType';
  currentPlan: CurrentPlan;
  upsellPlan?: Maybe<UpsellPlan>;
};

export type TransactionsAchCreateInput = {
  amount: Scalars['Float']['input'];
  contactId: Scalars['ID']['input'];
  contactNotificationOptions?: InputMaybe<TransactionContactNotificationOptionsInput>;
  idempotencyKey?: InputMaybe<Scalars['String']['input']>;
  memo?: InputMaybe<Scalars['String']['input']>;
  sameDay?: InputMaybe<Scalars['Boolean']['input']>;
  subAccountId: Scalars['ID']['input'];
};

export type TransactionsAchCreateMutationResponse = MutationResponse & {
  __typename?: 'TransactionsACHCreateMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type TransactionsAmountFilterOptionsInput = {
  exact?: InputMaybe<Scalars['Float']['input']>;
  range?: InputMaybe<FloatRangeInput>;
};

export type TransactionsCapabilitiesResponse = {
  __typename?: 'TransactionsCapabilitiesResponse';
  ach: AchCapability;
  achPull: AchPullCapability;
  billPay: BillPayCapability;
  mailedCheck: MailedCheckCapability;
  outgoingWire: OutgoingWireCapability;
  sameDayAch: SameDayAchCapability;
};

export type TransactionsDatetimeFilterOptionsInput = {
  /** datetime in ISO 8601 format */
  exactDatetime?: InputMaybe<Scalars['String']['input']>;
  range?: InputMaybe<DatetimeRangeInput>;
};

export type TransactionsListFiltersInput = {
  /** Limits results to one or more transaction statuses. */
  amount?: InputMaybe<TransactionsAmountFilterOptionsInput>;
  /** Filter by the card Ids */
  cardIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  datetime?: InputMaybe<TransactionsDatetimeFilterOptionsInput>;
  direction?: InputMaybe<TransactionDirectionEnum>;
  /** Limits results to one or more transaction statuses. */
  statuses?: InputMaybe<Array<TransactionStatusEnum>>;
  /** Limits results to a particular sub account */
  subAccountId?: InputMaybe<Scalars['ID']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  transactionTypes?: InputMaybe<Array<TransactionTypeEnum>>;
};

export type TransactionsListResponse = {
  __typename?: 'TransactionsListResponse';
  pagination?: Maybe<TransactionsPagination>;
  transactionListItems: Array<TransactionListItem>;
};

export type TransactionsPagination = {
  __typename?: 'TransactionsPagination';
  cursor?: Maybe<Scalars['String']['output']>;
};

export type TransactionsPaginationOptionsInput = {
  cursor?: InputMaybe<Scalars['String']['input']>;
};

export type TransferLimits = {
  __typename?: 'TransferLimits';
  MAX_PER_TRANSACTION: Scalars['Float']['output'];
  MIN_PER_TRANSACTION: Scalars['Float']['output'];
  PER_30_DAYS_ROLLING: Scalars['Float']['output'];
  PER_DAY: Scalars['Float']['output'];
};

export type UpdateInvoiceAccountsPayableMutationResponse = MutationResponse & {
  __typename?: 'UpdateInvoiceAccountsPayableMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
  userErrors?: Maybe<Array<UserError>>;
};

export type UpdateScheduledPaymentInput = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  idempotencyKey: Scalars['String']['input'];
  startDate?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateScheduledPaymentResponse = MutationResponse & {
  __typename?: 'UpdateScheduledPaymentResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type UpdateTopUpsSettingsDataInput = {
  idempotencyKey: Scalars['String']['input'];
  itemId: Scalars['String']['input'];
  lowBalanceThreshold?: InputMaybe<Scalars['Float']['input']>;
  topUpAmount?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateTopUpsSettingsResponse = MutationResponse & {
  __typename?: 'UpdateTopUpsSettingsResponse';
  code: Scalars['String']['output'];
  data: TopUpSettings;
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type UpdateUserMutationResponse = MutationResponse & {
  __typename?: 'UpdateUserMutationResponse';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
  user?: Maybe<UserData>;
  userErrors?: Maybe<Array<UserError>>;
};

export type UpsellPlan = {
  __typename?: 'UpsellPlan';
  buttonContent?: Maybe<Scalars['String']['output']>;
  discountFeeDetails?: Maybe<DiscountFeeDetails>;
  planName: Scalars['String']['output'];
};

export type User = {
  __typename?: 'User';
  address?: Maybe<Address>;
  email?: Maybe<Scalars['String']['output']>;
  emailVerified?: Maybe<Scalars['Boolean']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  hasUnreadSupportMessage?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  isPrimaryOwner: Scalars['Boolean']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  onboardingCompleted?: Maybe<Scalars['Boolean']['output']>;
  ownerBusinesses: Array<Business>;
  phone?: Maybe<Scalars['String']['output']>;
  shippingAddress?: Maybe<Address>;
  signUpDate: Scalars['String']['output'];
  wasExistingRadiusUser?: Maybe<Scalars['Boolean']['output']>;
};

export type UserAddressData = {
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  line1?: InputMaybe<Scalars['String']['input']>;
  line2?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};

export type UserData = {
  __typename?: 'UserData';
  address?: Maybe<Address>;
  dob?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  ownershipPercentage?: Maybe<Scalars['Int']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  shippingAddress?: Maybe<Address>;
  title?: Maybe<Scalars['String']['output']>;
};

export type UserDataInput = {
  address?: InputMaybe<UserAddressData>;
  dob?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  ownershipPercentage?: InputMaybe<Scalars['Int']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  shippingAddress?: InputMaybe<UserAddressData>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UserError = {
  __typename?: 'UserError';
  code: ErrorCodeEnum;
  /** Key path to the relevant argument, e.g. that failed validation */
  field: Array<Scalars['String']['output']>;
  message: Scalars['String']['output'];
};

export type YearlyAccountStatements = {
  __typename?: 'YearlyAccountStatements';
  monthlyStatements: Array<MonthlyAccountStatements>;
  year?: Maybe<Scalars['String']['output']>;
};

export type ApplicationDataUpdateResponse = MutationResponse & {
  __typename?: 'applicationDataUpdateResponse';
  application: ApplicationData;
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  metadata?: Maybe<Scalars['JSONObject']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetPlaidAuthDataQueryResponse = {
  __typename?: 'getPlaidAuthDataQueryResponse';
  data: PlaidAuthData;
};

export type ShareAndEarnResendBlockedCustomerEmailMutationResponse = {
  __typename?: 'shareAndEarnResendBlockedCustomerEmailMutationResponse';
  success: Scalars['Boolean']['output'];
};

export type TransactionsListQueryVariables = Exact<{
  businessId: Scalars['ID']['input'];
  pagination?: InputMaybe<TransactionsPaginationOptionsInput>;
  filters?: InputMaybe<TransactionsListFiltersInput>;
}>;


export type TransactionsListQuery = { __typename?: 'Query', transactionsList?: { __typename?: 'TransactionsListResponse', pagination?: { __typename?: 'TransactionsPagination', cursor?: string | null } | null, transactionListItems: Array<{ __typename?: 'TransactionListItem', id: string, description?: string | null, amount: number, status?: TransactionStatusEnum | null, createdAt?: string | null, transactionType: TransactionTypeEnum, errorMessage?: string | null, isBancorpUpgradeDeposit?: boolean | null, returnedBy?: string | null }> } | null, me?: { __typename?: 'User', id: string, wasExistingRadiusUser?: boolean | null, ownerBusinesses: Array<{ __typename?: 'Business', id: string, onboarding?: { __typename?: 'BusinessOnboarding', initialFundingStatus?: CardFundingTransactionStatusEnum | null, id: string } | null }> } | null, business?: { __typename?: 'Business', id: string, bankAccount?: { __typename?: 'BankAccount', id: string, availableBalance?: number | null } | null } | null };

export type TransactionNoteUpdateMutationVariables = Exact<{
  businessId: Scalars['ID']['input'];
  transactionId: Scalars['ID']['input'];
  note: Scalars['String']['input'];
}>;


export type TransactionNoteUpdateMutation = { __typename?: 'Mutation', transactionNoteUpdate?: { __typename?: 'TransactionNoteUpdateResponse', code: string, message: string, success: boolean } | null };

export type AnnouncementQueryVariables = Exact<{
  type: AnnouncementTypeEnum;
}>;


export type AnnouncementQuery = { __typename?: 'Query', announcement?: { __typename?: 'Announcement', type?: AnnouncementTypeEnum | null, isInteractionComplete?: boolean | null, requiredAudience?: AnnouncementAudienceRequirementEnum | null } | null };

export type AnnouncementInteractMutationVariables = Exact<{
  type: AnnouncementTypeEnum;
  actionEvent: AnnouncementActionEventEnum;
}>;


export type AnnouncementInteractMutation = { __typename?: 'Mutation', announcementInteract?: { __typename?: 'AnnouncementInteractResponse', code: string, success: boolean, message: string } | null };

export type BusinessCreatedAtQueryVariables = Exact<{
  businessId: Scalars['ID']['input'];
}>;


export type BusinessCreatedAtQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, email?: string | null } | null, business?: { __typename?: 'Business', id: string, createdAt?: string | null } | null };

export type SubAccountBalancesQueryVariables = Exact<{
  businessId: Scalars['ID']['input'];
}>;


export type SubAccountBalancesQuery = { __typename?: 'Query', business?: { __typename?: 'Business', id: string, bankAccount?: { __typename?: 'BankAccount', id: string, subAccounts: Array<{ __typename?: 'SubAccount', id: string, balance: number, isDefault?: boolean | null, name: string }> } | null } | null };

export type SubAccountNamesQueryVariables = Exact<{
  businessId: Scalars['ID']['input'];
}>;


export type SubAccountNamesQuery = { __typename?: 'Query', business?: { __typename?: 'Business', id: string, bankAccount?: { __typename?: 'BankAccount', id: string, subAccounts: Array<{ __typename?: 'SubAccount', id: string, name: string }> } | null } | null };

export type SubAccountCreateMutationVariables = Exact<{
  name: Scalars['String']['input'];
  businessId: Scalars['ID']['input'];
  bankAccountId: Scalars['ID']['input'];
}>;


export type SubAccountCreateMutation = { __typename?: 'Mutation', subAccountCreate?: { __typename?: 'SubAccountCreateResponse', code: string, message: string, success: boolean, subAccount?: { __typename?: 'SubAccount', id: string } | null } | null };

export type SubAccountRulePercentCreateMutationVariables = Exact<{
  businessId: Scalars['ID']['input'];
  subAccountId: Scalars['ID']['input'];
  percent: Scalars['Int']['input'];
}>;


export type SubAccountRulePercentCreateMutation = { __typename?: 'Mutation', subAccountRulePercentCreate?: { __typename?: 'SubAccountRulePercentCreateMutationResponse', code: string, success: boolean, message: string } | null };

export type SubAccountTotalPercentAvailableQueryVariables = Exact<{
  businessId: Scalars['ID']['input'];
}>;


export type SubAccountTotalPercentAvailableQuery = { __typename?: 'Query', subAccountRuleTotalPercentAvailable?: number | null };

export type RenameSubAccountMutationVariables = Exact<{
  businessId: Scalars['ID']['input'];
  subAccountId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
}>;


export type RenameSubAccountMutation = { __typename?: 'Mutation', subAccountUpdate?: { __typename?: 'SubAccountUpdateResponse', code: string, message: string, success: boolean } | null };

export type SubAccountRulePercentUpdateMutationVariables = Exact<{
  businessId: Scalars['ID']['input'];
  subAccountId: Scalars['ID']['input'];
  percent?: InputMaybe<Scalars['Int']['input']>;
}>;


export type SubAccountRulePercentUpdateMutation = { __typename?: 'Mutation', subAccountRulePercentUpdate?: { __typename?: 'SubAccountRulePercentUpdateMutationResponse', code: string, success: boolean, message: string } | null };

export type SubAccountRulePercentDeleteMutationVariables = Exact<{
  businessId: Scalars['ID']['input'];
  subAccountId: Scalars['ID']['input'];
}>;


export type SubAccountRulePercentDeleteMutation = { __typename?: 'Mutation', subAccountRulePercentDelete?: { __typename?: 'SubAccountRulePercentDeleteMutationResponse', code: string, message: string, success: boolean } | null };

export type SubAccountDeleteMutationVariables = Exact<{
  businessId: Scalars['ID']['input'];
  subAccountId: Scalars['ID']['input'];
}>;


export type SubAccountDeleteMutation = { __typename?: 'Mutation', subAccountDelete?: { __typename?: 'SubAccountDeleteResponse', code: string, message: string, success: boolean } | null };

export type SubAccountDetailsQueryVariables = Exact<{
  businessId: Scalars['ID']['input'];
  subAccountId: Scalars['ID']['input'];
}>;


export type SubAccountDetailsQuery = { __typename?: 'Query', business?: { __typename?: 'Business', id: string, bankAccount?: { __typename?: 'BankAccount', id: string, subAccount?: { __typename?: 'SubAccount', id: string, name: string, balance: number, isDefault?: boolean | null, percentRule?: { __typename?: 'SubAccountRulePercent', businessId: string, percent?: number | null, availableUpdateLimit?: number | null } | null } | null } | null } | null };

export type SubAccountsQueryVariables = Exact<{
  businessId: Scalars['ID']['input'];
}>;


export type SubAccountsQuery = { __typename?: 'Query', business?: { __typename?: 'Business', id: string, bankAccount?: { __typename?: 'BankAccount', id: string, availableBalance?: number | null, subAccounts: Array<{ __typename?: 'SubAccount', id: string, name: string, balance: number, isDefault?: boolean | null, percentRule?: { __typename?: 'SubAccountRulePercent', percent?: number | null } | null }> } | null } | null };

export type SubAccountTransferCreateMutationVariables = Exact<{
  businessId: Scalars['ID']['input'];
  data: SubAccountTransferCreateInput;
}>;


export type SubAccountTransferCreateMutation = { __typename?: 'Mutation', subAccountTransferCreate?: { __typename?: 'SubAccountTransferCreateMutationResponse', code: string, message: string, success: boolean } | null };

export type PhoneNumberQueryVariables = Exact<{ [key: string]: never; }>;


export type PhoneNumberQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, phone?: string | null } | null };

export type OnboardingMeQueryVariables = Exact<{ [key: string]: never; }>;


export type OnboardingMeQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, email?: string | null, phone?: string | null, firstName?: string | null, lastName?: string | null, wasExistingRadiusUser?: boolean | null, ownerBusinesses: Array<{ __typename?: 'Business', id: string, bankAccount?: { __typename?: 'BankAccount', id: string } | null, onboarding?: { __typename?: 'BusinessOnboarding', accountStatus?: OnboardingAccountStatus | null, id: string } | null }> } | null };

export type EmailVerifiedQueryVariables = Exact<{ [key: string]: never; }>;


export type EmailVerifiedQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, emailVerified?: boolean | null } | null };

export type ResendVerificationEmailMutationVariables = Exact<{
  email: Scalars['String']['input'];
  reCAPTCHAToken: Scalars['String']['input'];
}>;


export type ResendVerificationEmailMutation = { __typename?: 'Mutation', resendVerificationEmail?: { __typename?: 'ConcreteMutationResponse', code: string, success: boolean, message: string } | null };

export type ConfirmAdditionalOwnersOnboardingStatusMutationVariables = Exact<{
  businessId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
}>;


export type ConfirmAdditionalOwnersOnboardingStatusMutation = { __typename?: 'Mutation', confirmAdditionalOwnersOnboardingStatus?: { __typename?: 'ConfirmAdditionalOwnersOnboardingStatusMutationResponse', status?: { __typename?: 'AdditionalOwnerOnboardingStatus', onboardingCompleted: boolean } | null } | null };

export type CardsQueryVariables = Exact<{
  businessId: Scalars['ID']['input'];
}>;


export type CardsQuery = { __typename?: 'Query', business?: { __typename?: 'Business', id: string, bankAccounts: Array<{ __typename?: 'BankAccount', id: string, cards: Array<{ __typename?: 'Card', id: string, productType?: CardProductType | null, embossLine1?: string | null, embossLine2?: string | null, shippedDate?: string | null, businessId: string, cardNumber?: { __typename?: 'CardNumber', last4?: string | null } | null, activation?: { __typename?: 'CardActivation', cardId: string, activated?: boolean | null, pinSet?: { __typename?: 'CardPinSet', cardId: string, status?: CardPinSetStatusEnum | null } | null } | null, user?: { __typename?: 'BasicUser', id: string, firstName?: string | null, lastName?: string | null, isPrimaryOwner: boolean, signUpDate: string } | null }> }> } | null };

export type CardQueryVariables = Exact<{
  businessId: Scalars['ID']['input'];
  cardId: Scalars['ID']['input'];
}>;


export type CardQuery = { __typename?: 'Query', business?: { __typename?: 'Business', id: string, bankAccount?: { __typename?: 'BankAccount', id: string, card?: { __typename?: 'Card', id: string, embossLine1?: string | null, shippedDate?: string | null, productType?: CardProductType | null, disabled?: boolean | null, cardNumber?: { __typename?: 'CardNumber', last4?: string | null } | null, activation?: { __typename?: 'CardActivation', cardId: string, activated?: boolean | null, pinSet?: { __typename?: 'CardPinSet', cardId: string, status?: CardPinSetStatusEnum | null, updatedAt?: string | null } | null } | null, freezeInfo?: { __typename?: 'CardFreezeInfo', frozen: boolean } | null } | null } | null } | null };

export type CardActivateMutationVariables = Exact<{
  businessId: Scalars['ID']['input'];
  accountId: Scalars['ID']['input'];
  cardId: Scalars['ID']['input'];
  expirationDate: Scalars['String']['input'];
  securityCode: Scalars['String']['input'];
}>;


export type CardActivateMutation = { __typename?: 'Mutation', cardActivate?: { __typename?: 'CardActivateResponse', code: string, success: boolean, message: string } | null };

export type CardPinChangeFormQueryVariables = Exact<{
  businessId: Scalars['ID']['input'];
  accountId: Scalars['ID']['input'];
  cardId: Scalars['ID']['input'];
}>;


export type CardPinChangeFormQuery = { __typename?: 'Query', cardPinChangeForm?: { __typename?: 'CardPinChangeForm', cardId: string, url: string, expiresAt: string } | null };

export type CardFreezeMutationVariables = Exact<{
  businessId: Scalars['ID']['input'];
  accountId: Scalars['ID']['input'];
  cardId: Scalars['ID']['input'];
}>;


export type CardFreezeMutation = { __typename?: 'Mutation', cardFreeze?: { __typename?: 'CardFreezeResponse', code: string, success: boolean, message: string } | null };

export type CardUnfreezeMutationVariables = Exact<{
  businessId: Scalars['ID']['input'];
  accountId: Scalars['ID']['input'];
  cardId: Scalars['ID']['input'];
}>;


export type CardUnfreezeMutation = { __typename?: 'Mutation', cardUnfreeze?: { __typename?: 'CardUnfreezeResponse', code: string, success: boolean, message: string } | null };

export type DigitalCardAssetUrlQueryVariables = Exact<{
  businessId: Scalars['ID']['input'];
  accountId: Scalars['ID']['input'];
  cardId: Scalars['ID']['input'];
}>;


export type DigitalCardAssetUrlQuery = { __typename?: 'Query', digitalCardAssetUrl?: { __typename?: 'DigitalCardAssetUrl', cardId: string, url: string, expiresAt: string } | null };

export type AddVirtualCardMutationVariables = Exact<{
  businessId: Scalars['ID']['input'];
  accountId: Scalars['ID']['input'];
}>;


export type AddVirtualCardMutation = { __typename?: 'Mutation', addVirtualCard?: { __typename?: 'AddVirtualCardResponse', code: string, success: boolean, message: string } | null };

export type BusinessNameQueryVariables = Exact<{
  businessId: Scalars['ID']['input'];
}>;


export type BusinessNameQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, firstName?: string | null } | null, business?: { __typename?: 'Business', id: string, name?: string | null } | null };

export type TransactionSearchCsvQueryVariables = Exact<{
  businessId: Scalars['ID']['input'];
  filters?: InputMaybe<TransactionsListFiltersInput>;
  csvFormat?: InputMaybe<CsvFormatEnum>;
}>;


export type TransactionSearchCsvQuery = { __typename?: 'Query', transactionSearchCSV?: { __typename?: 'TransactionSearchCSVResponse', csvURL: string } | null };

export type TransactionDetailsQueryVariables = Exact<{
  businessId: Scalars['ID']['input'];
  transactionId: Scalars['ID']['input'];
}>;


export type TransactionDetailsQuery = { __typename?: 'Query', transaction?: { __typename?: 'TransactionListItem', id: string, amount: number, createdAt?: string | null, postedAt?: string | null, note?: string | null, transactionType: TransactionTypeEnum, status?: TransactionStatusEnum | null, errorMessage?: string | null, description?: string | null, returnedBy?: string | null, cardLast4?: string | null, user?: { __typename?: 'BasicUser', id: string, email?: string | null, firstName?: string | null, lastName?: string | null, isPrimaryOwner: boolean } | null } | null };

export type ListInvoiceAccountsPayableQueryVariables = Exact<{
  businessId: Scalars['ID']['input'];
  sorting: InvoiceAccountsPayableListSortingInput;
  filters?: InputMaybe<InvoiceAccountsPayableListFiltersInput>;
  pagination?: InputMaybe<InvoiceAccountsPayableListPaginationOptionsInput>;
}>;


export type ListInvoiceAccountsPayableQuery = { __typename?: 'Query', listInvoiceAccountsPayable?: { __typename?: 'ListInvoiceAccountsPayableQueryResponse', invoices: Array<{ __typename?: 'InvoiceAccountsPayable', id: string, vendorName?: string | null, contactId?: string | null, invoiceNumber?: string | null, invoiceDate?: string | null, dueDate?: string | null, status: InvoiceStatus, paymentStatus?: InvoicePaymentStatus | null, amount?: number | null, paidAmount: number, scheduledAmount: number, pendingAmount: number, remainingAmount: number, businessId: string, createdVia: InvoiceAccountPayableCreatedVia, createdDate: string, updatedDate?: string | null, paidDate?: string | null, description?: string | null, markedAsPaidReason?: string | null, attachmentId?: string | null }>, pagination?: { __typename?: 'InvoiceAccountsPayablePagination', cursor?: string | null } | null } | null };

export type GetInvoiceAccountsPayableQueryVariables = Exact<{
  businessId: Scalars['ID']['input'];
  invoiceId: Scalars['ID']['input'];
}>;


export type GetInvoiceAccountsPayableQuery = { __typename?: 'Query', getInvoiceAccountsPayable?: { __typename?: 'GetInvoiceAccountsPayableQueryResponse', invoice: { __typename?: 'InvoiceAccountsPayable', amount?: number | null, businessId: string, id: string, invoiceDate?: string | null, createdDate: string, invoiceNumber?: string | null, createdVia: InvoiceAccountPayableCreatedVia, isArchived?: boolean | null, isMarkedAsPaid?: boolean | null, description?: string | null, attachmentId?: string | null, contactId?: string | null, dueDate?: string | null, markedAsPaidReason?: string | null, paidAmount: number, scheduledAmount: number, pendingAmount: number, remainingAmount: number, paidDate?: string | null, paymentStatus?: InvoicePaymentStatus | null, status: InvoiceStatus, updatedDate?: string | null, vendorName?: string | null, transactions?: Array<{ __typename?: 'InvoiceAccountsPayableTransaction', invoiceId: string, transactionId?: string | null, status: InvoiceTransactionStatus, accountId?: string | null, paymentMethod?: InvoiceAccountPayablePaymentMethod | null, amount: number, scheduledDate?: string | null, scheduledPaymentId?: string | null, lastUpdated: string, subAccount: { __typename?: 'SubAccount', id: string } } | null> | null } } | null };

export type GetInvoiceAccountsPayableWithoutTransactionsQueryVariables = Exact<{
  businessId: Scalars['ID']['input'];
  invoiceId: Scalars['ID']['input'];
}>;


export type GetInvoiceAccountsPayableWithoutTransactionsQuery = { __typename?: 'Query', getInvoiceAccountsPayable?: { __typename?: 'GetInvoiceAccountsPayableQueryResponse', invoice: { __typename?: 'InvoiceAccountsPayable', amount?: number | null, businessId: string, id: string, invoiceDate?: string | null, createdDate: string, invoiceNumber?: string | null, createdVia: InvoiceAccountPayableCreatedVia, isArchived?: boolean | null, isMarkedAsPaid?: boolean | null, description?: string | null, attachmentId?: string | null, contactId?: string | null, dueDate?: string | null, markedAsPaidReason?: string | null, paidAmount: number, scheduledAmount: number, pendingAmount: number, remainingAmount: number, paidDate?: string | null, paymentStatus?: InvoicePaymentStatus | null, status: InvoiceStatus, updatedDate?: string | null, vendorName?: string | null } } | null };

export type InvoicePaymentsContactQueryVariables = Exact<{
  businessId: Scalars['ID']['input'];
  contactId: Scalars['ID']['input'];
}>;


export type InvoicePaymentsContactQuery = { __typename?: 'Query', business?: { __typename?: 'Business', id: string, contact?: { __typename?: 'Contact', id: string, businessId: string, name: string, relationship?: string | null, address?: { __typename?: 'Location', streetAddressLine1?: string | null, streetAddressLine2?: string | null, city?: string | null, provinceState?: string | null, country?: string | null, postalCode?: string | null } | null, achPaymentDetails?: { __typename?: 'ContactAchPaymentDetails', accountNumber: string, routingNumber: string } | null, wirePaymentDetails?: { __typename?: 'ContactWirePaymentDetails', accountNumber: string, routingNumber: string, bankName: string } | null, mailedCheckPaymentDetails?: { __typename?: 'ContactMailedCheckPaymentDetails', enabled: boolean } | null, billPayPaymentDetails?: { __typename?: 'ContactBillPayPaymentDetails', accountNumber: string } | null, rppsBillerDetails?: { __typename?: 'RppsBillerDetails', rppsBillerId: string, billerName: string } | null } | null } | null };

export type InvoicePaymentsContactListQueryVariables = Exact<{
  businessId: Scalars['ID']['input'];
}>;


export type InvoicePaymentsContactListQuery = { __typename?: 'Query', business?: { __typename?: 'Business', contacts: Array<{ __typename?: 'Contact', id: string, businessId: string, name: string, email?: string | null, achPaymentDetails?: { __typename?: 'ContactAchPaymentDetails', routingNumber: string, accountNumber: string } | null, wirePaymentDetails?: { __typename?: 'ContactWirePaymentDetails', routingNumber: string, accountNumber: string } | null, mailedCheckPaymentDetails?: { __typename?: 'ContactMailedCheckPaymentDetails', enabled: boolean } | null, billPayPaymentDetails?: { __typename?: 'ContactBillPayPaymentDetails', accountNumber: string } | null, rppsBillerDetails?: { __typename?: 'RppsBillerDetails', rppsBillerId: string, billerName: string } | null }> } | null };

export type SaveInvoiceAccountsPayableMutationVariables = Exact<{
  businessId: Scalars['ID']['input'];
  invoiceData: InvoiceAccountsPayableDataInput;
}>;


export type SaveInvoiceAccountsPayableMutation = { __typename?: 'Mutation', saveInvoiceAccountsPayable?: { __typename?: 'SaveInvoiceAccountsPayableMutationResponse', success: boolean, code: string, message: string, invoiceId: string } | null };

export type MarkInvoiceAccountsPayableAsPaidMutationVariables = Exact<{
  businessId: Scalars['ID']['input'];
  invoiceId: Scalars['ID']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
}>;


export type MarkInvoiceAccountsPayableAsPaidMutation = { __typename?: 'Mutation', markInvoiceAccountsPayableAsPaid?: { __typename?: 'UpdateInvoiceAccountsPayableMutationResponse', success: boolean, code: string, message: string } | null };

export type MarkInvoiceAccountsPayableAsUnpaidMutationVariables = Exact<{
  businessId: Scalars['ID']['input'];
  invoiceId: Scalars['ID']['input'];
}>;


export type MarkInvoiceAccountsPayableAsUnpaidMutation = { __typename?: 'Mutation', markInvoiceAccountsPayableAsUnpaid?: { __typename?: 'UpdateInvoiceAccountsPayableMutationResponse', success: boolean, code: string, message: string } | null };

export type MarkInvoiceAccountsPayableAsArchivedMutationVariables = Exact<{
  businessId: Scalars['ID']['input'];
  invoiceId: Scalars['ID']['input'];
}>;


export type MarkInvoiceAccountsPayableAsArchivedMutation = { __typename?: 'Mutation', markInvoiceAccountsPayableAsArchived?: { __typename?: 'UpdateInvoiceAccountsPayableMutationResponse', success: boolean, code: string, message: string } | null };

export type MarkInvoiceAccountsPayableAsUnarchivedMutationVariables = Exact<{
  businessId: Scalars['ID']['input'];
  invoiceId: Scalars['ID']['input'];
}>;


export type MarkInvoiceAccountsPayableAsUnarchivedMutation = { __typename?: 'Mutation', markInvoiceAccountsPayableAsUnarchived?: { __typename?: 'UpdateInvoiceAccountsPayableMutationResponse', success: boolean, code: string, message: string } | null };

export type UpdateInvoiceContactAchPaymentDetailsMutationVariables = Exact<{
  data: ContactDomesticAchUpdateDataInput;
}>;


export type UpdateInvoiceContactAchPaymentDetailsMutation = { __typename?: 'Mutation', contactDomesticAchUpdate?: { __typename?: 'ContactUpdateMutationResponse', code: string, success: boolean } | null };

export type UpdateInvoiceContactMailedCheckPaymentDetailsMutationVariables = Exact<{
  data: ContactMailedCheckUpdateDataInput;
}>;


export type UpdateInvoiceContactMailedCheckPaymentDetailsMutation = { __typename?: 'Mutation', contactMailedCheckUpdate?: { __typename?: 'ContactUpdateMutationResponse', code: string, success: boolean, message: string } | null };

export type UpdateInvoiceContactWirePaymentDetailsMutationVariables = Exact<{
  data: ContactDomesticWireUpdateDataInput;
}>;


export type UpdateInvoiceContactWirePaymentDetailsMutation = { __typename?: 'Mutation', contactDomesticWireUpdate?: { __typename?: 'ContactUpdateMutationResponse', code: string, success: boolean } | null };

export type ContactBillPayPaymentDetailsCreateMutationVariables = Exact<{
  data: ContactBillPayPaymentDetailsCreateInput;
}>;


export type ContactBillPayPaymentDetailsCreateMutation = { __typename?: 'Mutation', contactBillPayPaymentDetailsCreate?: { __typename?: 'ContactBillPayPaymentDetailsCreateMutationResponse', code: string, success: boolean } | null };

export type UpdateInvoiceContactBillPayPaymentDetailsMutationVariables = Exact<{
  data: ContactBillPayPaymentDetailsUpdateInput;
}>;


export type UpdateInvoiceContactBillPayPaymentDetailsMutation = { __typename?: 'Mutation', contactBillPayPaymentDetailsUpdate?: { __typename?: 'ContactBillPayPaymentDetailsUpdateMutationResponse', code: string, success: boolean } | null };

export type UpdateInvoiceContactMutationVariables = Exact<{
  input: ContactUpdateDataInput;
}>;


export type UpdateInvoiceContactMutation = { __typename?: 'Mutation', contactUpdate?: { __typename?: 'ContactUpdateMutationResponse', code: string, success: boolean } | null };

export type CreateInvoiceAccountsPayablePaymentMutationVariables = Exact<{
  businessId: Scalars['ID']['input'];
  invoiceId: Scalars['ID']['input'];
  data: CreateInvoiceAccountsPayablePaymentDataInput;
}>;


export type CreateInvoiceAccountsPayablePaymentMutation = { __typename?: 'Mutation', createInvoiceAccountsPayablePayment?: { __typename?: 'CreateInvoiceAccountsPayablePaymentMutationResponse', code: string, success: boolean, message: string, transactionId: string } | null };

export type CreateInvoiceAccountsPayableScheduledPaymentMutationVariables = Exact<{
  businessId: Scalars['ID']['input'];
  invoiceId: Scalars['ID']['input'];
  data: CreateInvoiceAccountsPayableScheduledPaymentDataInput;
}>;


export type CreateInvoiceAccountsPayableScheduledPaymentMutation = { __typename?: 'Mutation', createInvoiceAccountsPayableScheduledPayment?: { __typename?: 'CreateInvoiceAccountsPayableScheduledPaymentMutationResponse', code: string, success: boolean, message: string, scheduledPaymentId: string } | null };

export type CreateInvoiceAccountsPayableContactMutationVariables = Exact<{
  input: ContactCreateDataInput;
}>;


export type CreateInvoiceAccountsPayableContactMutation = { __typename?: 'Mutation', contactCreate?: { __typename?: 'ContactCreateMutationResponse', code: string, success: boolean, message: string, contact: { __typename?: 'Contact', id: string } } | null };

export type CreateInvoiceAttachmentMutationVariables = Exact<{
  businessId: Scalars['ID']['input'];
  attachmentData: CreateInvoiceAttachmentDataInput;
}>;


export type CreateInvoiceAttachmentMutation = { __typename?: 'Mutation', createInvoiceAttachment?: { __typename?: 'CreateInvoiceAttachmentMutationResponse', attachmentId: string, upload: { __typename?: 'InvoiceAttachmentUpload', url: string, expires: string, fields: Array<{ __typename?: 'InvoiceAttachmentUploadFields', headerKey: string, headerValue: string }> } } | null };

export type GetInvoiceAttachmentUrlQueryVariables = Exact<{
  businessId: Scalars['ID']['input'];
  attachmentId: Scalars['ID']['input'];
}>;


export type GetInvoiceAttachmentUrlQuery = { __typename?: 'Query', getInvoiceAttachment?: { __typename?: 'GetInvoiceAttachmentResponse', attachmentId: string, status: InvoiceAttachmentStatus, file?: { __typename?: 'InvoiceAttachmentFile', url: string, expires: string, contentType: string } | null } | null };

export type GetInvoiceAttachmentDataQueryVariables = Exact<{
  businessId: Scalars['ID']['input'];
  attachmentId: Scalars['ID']['input'];
}>;


export type GetInvoiceAttachmentDataQuery = { __typename?: 'Query', getInvoiceAttachment?: { __typename?: 'GetInvoiceAttachmentResponse', attachmentId: string, status: InvoiceAttachmentStatus, attachmentData?: { __typename?: 'InvoiceAttachmentData', invoiceNumber?: string | null, vendorName?: string | null, invoiceDate?: string | null, invoiceTotal?: string | null, invoiceDueDate?: string | null, paymentDetails?: { __typename?: 'InvoiceAttachmentDataPaymentDetails', routingNumber?: string | null, zipCode?: string | null, streetAddress?: string | null, city?: string | null, state?: string | null, accountNumber?: string | null, personalOrBusinessName?: string | null, paymentTerms?: string | null } | null } | null } | null };

export type GetInvoiceAccountInfoQueryVariables = Exact<{
  businessId: Scalars['ID']['input'];
}>;


export type GetInvoiceAccountInfoQuery = { __typename?: 'Query', business?: { __typename?: 'Business', id: string, bankAccount?: { __typename?: 'BankAccount', id: string, availableBalance?: number | null, accountNumber?: string | null, subAccounts: Array<{ __typename?: 'SubAccount', id: string, name: string, balance: number, isDefault?: boolean | null }>, capabilities?: { __typename?: 'BankAccountCapabilities', ach: { __typename?: 'BankAccountCapabilitiesACH', enabled?: boolean | null, limit: number }, sameDayAch: { __typename?: 'BankAccountCapabilitiesSameDayACH', enabled?: boolean | null, fee: { __typename?: 'FlatFee', type: FeeType, amount: number } | { __typename?: 'PercentFee', maximum: number, minimum: number, percentage: number, type: FeeType } }, mailedCheck: { __typename?: 'BankAccountCapabilitiesMailedCheck', limit: number, fee: { __typename?: 'FlatFee', type: FeeType, amount: number } | { __typename?: 'PercentFee', maximum: number, minimum: number, percentage: number, type: FeeType } }, checkDeposit: { __typename?: 'BankAccountCapabilitiesCheckDeposit', enabled?: boolean | null, limit: number }, billPay: { __typename?: 'BankAccountCapabilitiesBillPay', limit: number, fee: { __typename?: 'FlatFee', type: FeeType, amount: number } | { __typename?: 'PercentFee', maximum: number, minimum: number, percentage: number, type: FeeType } }, domesticWire: { __typename?: 'BankAccountCapabilitiesDomesticWire', enabled?: boolean | null, limit: number, fee: { __typename?: 'FlatFee', type: FeeType, amount: number } | { __typename?: 'PercentFee', maximum: number, minimum: number, percentage: number, type: FeeType } } } | null } | null } | null };

export type UpdateScheduledPaymentMutationVariables = Exact<{
  businessId: Scalars['ID']['input'];
  scheduledPaymentId: Scalars['ID']['input'];
  updateScheduledPaymentInput: UpdateScheduledPaymentInput;
}>;


export type UpdateScheduledPaymentMutation = { __typename?: 'Mutation', updateScheduledPayment?: { __typename?: 'UpdateScheduledPaymentResponse', code: string, message: string, success: boolean } | null };

export type CancelScheduledPaymentMutationVariables = Exact<{
  businessId: Scalars['ID']['input'];
  scheduledPaymentId: Scalars['ID']['input'];
}>;


export type CancelScheduledPaymentMutation = { __typename?: 'Mutation', cancelScheduledPayment?: { __typename?: 'CancelScheduledPaymentResponse', code: string, message: string, success: boolean } | null };

export type InvoiceForwardingEmailQueryVariables = Exact<{
  businessId: Scalars['ID']['input'];
}>;


export type InvoiceForwardingEmailQuery = { __typename?: 'Query', business?: { __typename?: 'Business', id: string, invoiceForwardingEmail?: string | null } | null };

export type AchCutoffTimesQueryVariables = Exact<{
  businessId: Scalars['ID']['input'];
}>;


export type AchCutoffTimesQuery = { __typename?: 'Query', achCutoffTimes?: { __typename?: 'AchCutoffTimesResponse', isSameDayAvailable: boolean, nextCutoff: string, secondsToNextCutoff: number } | null };

export type SearchBillerDirectoryQueryVariables = Exact<{
  billerName: Scalars['String']['input'];
}>;


export type SearchBillerDirectoryQuery = { __typename?: 'Query', searchBillerDirectory?: { __typename?: 'SearchBillerDirectoryResponse', billers: Array<{ __typename?: 'Biller', rppsBillerId: string, billerName: string, billerAccountNumberPatterns?: Array<string> | null }> } | null };

export type KanmonLandingPageQueryVariables = Exact<{
  businessId: Scalars['ID']['input'];
}>;


export type KanmonLandingPageQuery = { __typename?: 'Query', kanmonLending: { __typename?: 'KanmonLending', business: { __typename?: 'Business', id: string, users: Array<{ __typename?: 'BasicUser', id: string, firstName?: string | null, lastName?: string | null, isPrimaryOwner: boolean }> }, products: Array<{ __typename?: 'KanmonLendingProduct', id: string, loanStatus: KanmonLoanStatusEnum, loanType: KanmonLoanTypeEnum }> } };

export type CreateKanmonConnectTokenMutationVariables = Exact<{
  businessId: Scalars['ID']['input'];
}>;


export type CreateKanmonConnectTokenMutation = { __typename?: 'Mutation', createKanmonConnectToken?: { __typename?: 'KanmonConnectTokenMutationResponse', connectToken: string } | null };

export type CardsActivatedQueryVariables = Exact<{
  businessId: Scalars['ID']['input'];
}>;


export type CardsActivatedQuery = { __typename?: 'Query', business?: { __typename?: 'Business', id: string, bankAccount?: { __typename?: 'BankAccount', cards: Array<{ __typename?: 'Card', id: string, productType?: CardProductType | null, activation?: { __typename?: 'CardActivation', activated?: boolean | null } | null }> } | null } | null };

export type AchCreateMutationVariables = Exact<{
  businessId: Scalars['ID']['input'];
  data: TransactionsAchCreateInput;
}>;


export type AchCreateMutation = { __typename?: 'Mutation', transactionsACHCreate?: { __typename?: 'TransactionsACHCreateMutationResponse', code: string, success: boolean } | null };

export type RecipientsAchQueryVariables = Exact<{
  businessId: Scalars['ID']['input'];
  contactPaymentType: ContactPaymentType;
}>;


export type RecipientsAchQuery = { __typename?: 'Query', business?: { __typename?: 'Business', id: string, contacts: Array<{ __typename?: 'Contact', id: string, businessId: string, name: string, achPaymentDetails?: { __typename?: 'ContactAchPaymentDetails', routingNumber: string, accountNumber: string } | null }> } | null };

export type RecipientDomesticAchCreateMutationVariables = Exact<{
  data: ContactDomesticAchCreateDataInput;
}>;


export type RecipientDomesticAchCreateMutation = { __typename?: 'Mutation', contactDomesticAchCreate?: { __typename?: 'ContactCreateMutationResponse', code: string, success: boolean, message: string, contact: { __typename?: 'Contact', id: string, businessId: string, name: string, achPaymentDetails?: { __typename?: 'ContactAchPaymentDetails', accountNumber: string, routingNumber: string } | null } } | null };

export type RecipientDomesticAchUpdateMutationVariables = Exact<{
  data: ContactDomesticAchUpdateDataInput;
}>;


export type RecipientDomesticAchUpdateMutation = { __typename?: 'Mutation', contactDomesticAchUpdate?: { __typename?: 'ContactUpdateMutationResponse', code: string, success: boolean, message: string } | null };

export type RoutingNumberAchIsValidQueryVariables = Exact<{
  routingNumber: Scalars['String']['input'];
}>;


export type RoutingNumberAchIsValidQuery = { __typename?: 'Query', routingNumberACHIsValid?: boolean | null };

export type DomesticWireCreateMutationVariables = Exact<{
  businessId: Scalars['ID']['input'];
  data: DomesticWireCreateInput;
}>;


export type DomesticWireCreateMutation = { __typename?: 'Mutation', domesticWireCreate?: { __typename?: 'DomesticWireCreateMutationResponse', code: string, message: string, success: boolean } | null };

export type RecipientsForDomesticWireQueryVariables = Exact<{
  businessId: Scalars['ID']['input'];
  contactPaymentType: ContactPaymentType;
}>;


export type RecipientsForDomesticWireQuery = { __typename?: 'Query', business?: { __typename?: 'Business', id: string, contacts: Array<{ __typename?: 'Contact', id: string, businessId: string, name: string, relationship?: string | null, address?: { __typename?: 'Location', streetAddressLine1?: string | null, streetAddressLine2?: string | null, provinceState?: string | null, city?: string | null, postalCode?: string | null } | null, wirePaymentDetails?: { __typename?: 'ContactWirePaymentDetails', routingNumber: string, accountNumber: string, bankName: string } | null }> } | null };

export type RecipientDomesticWireCreateMutationVariables = Exact<{
  data: ContactDomesticWireCreateDataInput;
}>;


export type RecipientDomesticWireCreateMutation = { __typename?: 'Mutation', contactDomesticWireCreate?: { __typename?: 'ContactCreateMutationResponse', code: string, success: boolean, message: string, contact: { __typename?: 'Contact', id: string, businessId: string, name: string, relationship?: string | null, address?: { __typename?: 'Location', streetAddressLine1?: string | null, streetAddressLine2?: string | null, provinceState?: string | null, city?: string | null, postalCode?: string | null } | null, wirePaymentDetails?: { __typename?: 'ContactWirePaymentDetails', accountNumber: string, routingNumber: string, bankName: string } | null } } | null };

export type RecipientDomesticWireUpdateMutationVariables = Exact<{
  data: ContactDomesticWireUpdateDataInput;
}>;


export type RecipientDomesticWireUpdateMutation = { __typename?: 'Mutation', contactDomesticWireUpdate?: { __typename?: 'ContactUpdateMutationResponse', code: string, success: boolean, message: string } | null };

export type RoutingNumberWireIsValidQueryVariables = Exact<{
  routingNumber: Scalars['String']['input'];
}>;


export type RoutingNumberWireIsValidQuery = { __typename?: 'Query', routingNumberWireIsValid?: boolean | null };

export type TimeSavedQueryVariables = Exact<{
  businessId: Scalars['ID']['input'];
}>;


export type TimeSavedQuery = { __typename?: 'Query', timeSaved?: { __typename?: 'TimeSavedResponse', perMailedCheckTimeSavedSeconds: number, perSameDayAchTimeSavedSeconds: number, perStandardAchTimeSavedSeconds: number, perWireTimeSavedSeconds: number, timeSavedTotalSeconds: number, pdfGuidelineUrl?: string | null } | null };

export type TransactionFeesQueryVariables = Exact<{
  businessId: Scalars['ID']['input'];
  amount: Scalars['Float']['input'];
}>;


export type TransactionFeesQuery = { __typename?: 'Query', transactionFees?: { __typename?: 'TransactionFeesResponse', domesticWire: number, mailedCheck: number, sameDayAch: number, billPay: number } | null };

export type CapabilitiesWithLimitAndFeesFragment = { __typename?: 'BankAccountCapabilities', ach: { __typename?: 'BankAccountCapabilitiesACH', enabled?: boolean | null, limit: number }, sameDayAch: { __typename?: 'BankAccountCapabilitiesSameDayACH', enabled?: boolean | null, fee: { __typename?: 'FlatFee', type: FeeType, amount: number } | { __typename?: 'PercentFee', maximum: number, minimum: number, percentage: number, type: FeeType } }, mailedCheck: { __typename?: 'BankAccountCapabilitiesMailedCheck', limit: number, fee: { __typename?: 'FlatFee', type: FeeType, amount: number } | { __typename?: 'PercentFee', maximum: number, minimum: number, percentage: number, type: FeeType } }, checkDeposit: { __typename?: 'BankAccountCapabilitiesCheckDeposit', enabled?: boolean | null, limit: number }, billPay: { __typename?: 'BankAccountCapabilitiesBillPay', limit: number, fee: { __typename?: 'FlatFee', type: FeeType, amount: number } | { __typename?: 'PercentFee', maximum: number, minimum: number, percentage: number, type: FeeType } }, domesticWire: { __typename?: 'BankAccountCapabilitiesDomesticWire', enabled?: boolean | null, limit: number, fee: { __typename?: 'FlatFee', type: FeeType, amount: number } | { __typename?: 'PercentFee', maximum: number, minimum: number, percentage: number, type: FeeType } } };

export type CapabilitiesAndAvailableBalanceQueryVariables = Exact<{
  businessId: Scalars['ID']['input'];
}>;


export type CapabilitiesAndAvailableBalanceQuery = { __typename?: 'Query', business?: { __typename?: 'Business', id: string, bankAccount?: { __typename?: 'BankAccount', id: string, availableBalance?: number | null } | null, bankAccounts: Array<{ __typename?: 'BankAccount', id: string, capabilities?: { __typename?: 'BankAccountCapabilities', ach: { __typename?: 'BankAccountCapabilitiesACH', enabled?: boolean | null, limit: number }, sameDayAch: { __typename?: 'BankAccountCapabilitiesSameDayACH', enabled?: boolean | null, fee: { __typename?: 'FlatFee', type: FeeType, amount: number } | { __typename?: 'PercentFee', maximum: number, minimum: number, percentage: number, type: FeeType } }, mailedCheck: { __typename?: 'BankAccountCapabilitiesMailedCheck', limit: number, fee: { __typename?: 'FlatFee', type: FeeType, amount: number } | { __typename?: 'PercentFee', maximum: number, minimum: number, percentage: number, type: FeeType } }, checkDeposit: { __typename?: 'BankAccountCapabilitiesCheckDeposit', enabled?: boolean | null, limit: number }, billPay: { __typename?: 'BankAccountCapabilitiesBillPay', limit: number, fee: { __typename?: 'FlatFee', type: FeeType, amount: number } | { __typename?: 'PercentFee', maximum: number, minimum: number, percentage: number, type: FeeType } }, domesticWire: { __typename?: 'BankAccountCapabilitiesDomesticWire', enabled?: boolean | null, limit: number, fee: { __typename?: 'FlatFee', type: FeeType, amount: number } | { __typename?: 'PercentFee', maximum: number, minimum: number, percentage: number, type: FeeType } } } | null }> } | null };

export type PaymentMethodFeesQueryVariables = Exact<{
  businessId: Scalars['ID']['input'];
}>;


export type PaymentMethodFeesQuery = { __typename?: 'Query', business?: { __typename?: 'Business', bankAccounts: Array<{ __typename?: 'BankAccount', id: string, capabilities?: { __typename?: 'BankAccountCapabilities', ach: { __typename?: 'BankAccountCapabilitiesACH', enabled?: boolean | null, limit: number }, sameDayAch: { __typename?: 'BankAccountCapabilitiesSameDayACH', enabled?: boolean | null, fee: { __typename?: 'FlatFee', type: FeeType, amount: number } | { __typename?: 'PercentFee', maximum: number, minimum: number, percentage: number, type: FeeType } }, mailedCheck: { __typename?: 'BankAccountCapabilitiesMailedCheck', limit: number, fee: { __typename?: 'FlatFee', type: FeeType, amount: number } | { __typename?: 'PercentFee', maximum: number, minimum: number, percentage: number, type: FeeType } }, checkDeposit: { __typename?: 'BankAccountCapabilitiesCheckDeposit', enabled?: boolean | null, limit: number }, billPay: { __typename?: 'BankAccountCapabilitiesBillPay', limit: number, fee: { __typename?: 'FlatFee', type: FeeType, amount: number } | { __typename?: 'PercentFee', maximum: number, minimum: number, percentage: number, type: FeeType } }, domesticWire: { __typename?: 'BankAccountCapabilitiesDomesticWire', enabled?: boolean | null, limit: number, fee: { __typename?: 'FlatFee', type: FeeType, amount: number } | { __typename?: 'PercentFee', maximum: number, minimum: number, percentage: number, type: FeeType } } } | null }> } | null };

export type GetFundingStatusQueryVariables = Exact<{
  businessId: Scalars['ID']['input'];
}>;


export type GetFundingStatusQuery = { __typename?: 'Query', getFundingStatus?: { __typename?: 'GetFundingStatusQueryResponse', status: PlaidFundingStatusEnum } | null };

export type SubAccountsReconcileMutationVariables = Exact<{
  businessId: Scalars['ID']['input'];
  data: Array<SubAccountReconcileDataInput> | SubAccountReconcileDataInput;
}>;


export type SubAccountsReconcileMutation = { __typename?: 'Mutation', subAccountsReconcile?: { __typename?: 'SubAccountsReconcileResponse', success: boolean } | null };

export type ListRevenueHubConnectionsQueryVariables = Exact<{
  businessId: Scalars['ID']['input'];
}>;


export type ListRevenueHubConnectionsQuery = { __typename?: 'Query', revenueHub: { __typename?: 'RevenueHub', connections: Array<{ __typename?: 'RevenueHubConnection', id: string, platform: RevenueHubConnectionPlatformEnum, status: RevenueHubConnectionStatusEnum }> } };

export type SaveRevenueHubConnectionMutationVariables = Exact<{
  input: RevenueHubConnectionSaveInput;
}>;


export type SaveRevenueHubConnectionMutation = { __typename?: 'Mutation', revenueHubConnectionSave?: { __typename?: 'RevenueHubConnectionSaveMutationResponse', code: string, success: boolean, message: string, connection: { __typename?: 'RevenueHubConnection', id: string, platform: RevenueHubConnectionPlatformEnum, status: RevenueHubConnectionStatusEnum } } | null };

export type GetRevenueHubConnectionQueryVariables = Exact<{
  businessId: Scalars['ID']['input'];
  connectionId: Scalars['ID']['input'];
  filters: RevenueHubConnectionFiltersInput;
}>;


export type GetRevenueHubConnectionQuery = { __typename?: 'Query', revenueHub: { __typename?: 'RevenueHub', connection: { __typename?: 'RevenueHubConnection', id: string, status: RevenueHubConnectionStatusEnum, linkUpdateUrl: string, revenueDetails?: { __typename?: 'RevenueHubConnectionDetails', currentBalance?: number | null, totalGrossRevenue: number, totalPaidOutAmount?: number | null, totalReturnsAmount: number, transactionsCount: number, grossRevenuePerDayTimeSeries: Array<{ __typename?: 'RevenueHubConnectionDetailsRevenuePerDay', date: string, totalRevenueAmount: number }> } | null } } };

export type GetRevenueHubConnectionLinkUpdateUrlQueryVariables = Exact<{
  businessId: Scalars['ID']['input'];
  connectionId: Scalars['ID']['input'];
}>;


export type GetRevenueHubConnectionLinkUpdateUrlQuery = { __typename?: 'Query', revenueHub: { __typename?: 'RevenueHub', connection: { __typename?: 'RevenueHubConnection', id: string, linkUpdateUrl: string } } };

export type RevenueHubConnectionDeleteMutationVariables = Exact<{
  businessId: Scalars['ID']['input'];
  connectionId: Scalars['ID']['input'];
}>;


export type RevenueHubConnectionDeleteMutation = { __typename?: 'Mutation', revenueHubConnectionDelete?: { __typename?: 'RevenueHubConnectionDeleteMutationResponse', code: string, success: boolean, message: string } | null };

export type TopUpsQueryVariables = Exact<{
  businessId: Scalars['ID']['input'];
  accountId: Scalars['ID']['input'];
}>;


export type TopUpsQuery = { __typename?: 'Query', capabilities?: { __typename?: 'PlaidFundingCapabilitiesQueryResponse', topUps: { __typename?: 'TopUpsCapability', isEligible: boolean, velocityLimits: { __typename?: 'TopUpVelocityLimits', maxTransactionCountPer30DayRolling: number }, amounts: { __typename?: 'TopUpAmounts', defaultAmount: number, defaultThresholdAmount: number, maximumAmount: number, minimumAmount: number, minimumThresholdAmount: number } } } | null, topUpSettings?: { __typename?: 'GetTopUpsSettingsQueryResponse', isTopUpsEnabled: boolean, lowBalanceThreshold: number, topUpAmount: number, plaidItem?: { __typename?: 'PlaidItem', id: string, status: PlaidItemStatus } | null, error?: { __typename?: 'TopUpsError', code: PlaidTopUpsErrorCodeEnum, message: string } | null } | null, plaidItems?: { __typename?: 'PlaidItemsQueryResponse', data: Array<{ __typename?: 'PlaidItem', id: string, status: PlaidItemStatus, maskedAccountNumber?: string | null, institution?: { __typename?: 'PlaidInstitution', id: string, name: string, url?: string | null, logo?: string | null } | null }> } | null };

export type GetTopUpsSettingsQueryVariables = Exact<{
  businessId: Scalars['ID']['input'];
  accountId: Scalars['ID']['input'];
}>;


export type GetTopUpsSettingsQuery = { __typename?: 'Query', getTopUpsSettings?: { __typename?: 'GetTopUpsSettingsQueryResponse', isTopUpsEnabled: boolean, lowBalanceThreshold: number, topUpAmount: number, plaidItem?: { __typename?: 'PlaidItem', id: string, status: PlaidItemStatus, maskedAccountNumber?: string | null, institution?: { __typename?: 'PlaidInstitution', id: string, name: string, url?: string | null, logo?: string | null } | null } | null, error?: { __typename?: 'TopUpsError', code: PlaidTopUpsErrorCodeEnum, message: string } | null } | null };

export type UpdateTopUpsSettingsMutationVariables = Exact<{
  businessId: Scalars['ID']['input'];
  accountId: Scalars['ID']['input'];
  data: UpdateTopUpsSettingsDataInput;
}>;


export type UpdateTopUpsSettingsMutation = { __typename?: 'Mutation', updateTopUpsSettings?: { __typename?: 'UpdateTopUpsSettingsResponse', code: string, success: boolean, message: string, data: { __typename?: 'TopUpSettings', isTopUpsEnabled: boolean, lowBalanceThreshold: number, topUpAmount: number, plaidItem: { __typename?: 'PlaidItemIdAndStatus', id: string, status: PlaidItemStatus } } } | null };

export type DisableTopUpsMutationVariables = Exact<{
  businessId: Scalars['ID']['input'];
  accountId: Scalars['ID']['input'];
  data: DisableTopUpsDataInput;
}>;


export type DisableTopUpsMutation = { __typename?: 'Mutation', disableTopUps?: { __typename?: 'DisableTopUpsResponse', code: string, success: boolean, message: string } | null };

export type BillingDetailsQueryVariables = Exact<{
  businessId: Scalars['ID']['input'];
}>;


export type BillingDetailsQuery = { __typename?: 'Query', business?: { __typename?: 'Business', id: string, billing: { __typename?: 'Billing', plan?: { __typename?: 'BillingPlan', price?: number | null, nextPaymentDate?: string | null } | null, invoices: Array<{ __typename?: 'BillingInvoice', id: string, amountDue?: number | null, date?: string | null }> } } | null };

export type BillingInvoicePdfQueryVariables = Exact<{
  billingInvoiceId: Scalars['ID']['input'];
}>;


export type BillingInvoicePdfQuery = { __typename?: 'Query', billingInvoicePDF?: { __typename?: 'BillingInvoicePDF', invoice: string } | null };

export type AgreementsPdfQueryVariables = Exact<{
  type: AgreementType;
}>;


export type AgreementsPdfQuery = { __typename?: 'Query', agreementsPDF: { __typename?: 'AgreementsPDF', pdfURL: string } };

export type CreateLinkTokenMutationVariables = Exact<{
  businessId: Scalars['ID']['input'];
  itemId?: InputMaybe<Scalars['String']['input']>;
  redirectUri?: InputMaybe<Scalars['String']['input']>;
}>;


export type CreateLinkTokenMutation = { __typename?: 'Mutation', createLinkToken?: { __typename?: 'CreateLinkTokenMutationResponse', success: boolean, code: string, message: string, data: { __typename?: 'LinkTokenData', linkToken: string, expirationTimestamp: string } } | null };

export type ExchangePublicTokenMutationVariables = Exact<{
  accountType?: InputMaybe<PlaidAccountType>;
  businessId: Scalars['ID']['input'];
  plaidAccountId: Scalars['String']['input'];
  publicToken: Scalars['String']['input'];
  institutionName?: InputMaybe<Scalars['String']['input']>;
}>;


export type ExchangePublicTokenMutation = { __typename?: 'Mutation', exchangePublicToken?: { __typename?: 'ExchangePublicTokenMutationResponse', success: boolean, code: string, message: string, data: { __typename?: 'ExchangePublicTokenData', itemId: string } } | null };

export type RemovePlaidItemMutationVariables = Exact<{
  businessId: Scalars['ID']['input'];
  itemId: Scalars['String']['input'];
}>;


export type RemovePlaidItemMutation = { __typename?: 'Mutation', removePlaidItem?: { __typename?: 'RemovePlaidItemResponse', code: string, message: string, success: boolean } | null };

export type PlaidItemsQueryVariables = Exact<{
  businessId: Scalars['ID']['input'];
}>;


export type PlaidItemsQuery = { __typename?: 'Query', plaidItems?: { __typename?: 'PlaidItemsQueryResponse', data: Array<{ __typename?: 'PlaidItem', id: string, status: PlaidItemStatus, maskedAccountNumber?: string | null, institution?: { __typename?: 'PlaidInstitution', id: string, name: string, url?: string | null, logo?: string | null } | null }> } | null, capabilities?: { __typename?: 'PlaidFundingCapabilitiesQueryResponse', items: { __typename?: 'PlaidItemsCapability', maximumItemCount: number } } | null };

export type OwnerCandidatesQueryVariables = Exact<{
  businessId: Scalars['ID']['input'];
}>;


export type OwnerCandidatesQuery = { __typename?: 'Query', business?: { __typename?: 'Business', id: string, ownerCandidates: Array<{ __typename?: 'OwnerCandidate', id: string, businessId: string, userId?: string | null, firstName: string, lastName: string, type: string, ownershipPercentage: number, email: string, title: string }> } | null };

export type ResetPasswordMutationVariables = Exact<{
  oldPassword: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
}>;


export type ResetPasswordMutation = { __typename?: 'Mutation', resetPassword?: { __typename?: 'ConcreteMutationResponse', code: string, success: boolean, message: string } | null };

export type DeleteRecipientMutationVariables = Exact<{
  input: ContactDeleteInput;
}>;


export type DeleteRecipientMutation = { __typename?: 'Mutation', contactDelete?: { __typename?: 'ContactDeleteMutationResponse', code: string, success: boolean, message: string } | null };

export type AccountStatementsByYearQueryVariables = Exact<{
  businessId: Scalars['ID']['input'];
}>;


export type AccountStatementsByYearQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, wasExistingRadiusUser?: boolean | null } | null, business?: { __typename?: 'Business', id: string, bankAccount?: { __typename?: 'BankAccount', id: string, accountStatementsByYear: Array<{ __typename?: 'YearlyAccountStatements', year?: string | null, monthlyStatements: Array<{ __typename?: 'MonthlyAccountStatements', month?: string | null, year?: string | null, closingBalance?: number | null, totalDeposits?: number | null, totalWithdrawals?: number | null, totalFees?: number | null }> }> } | null } | null };

export type AccountStatementPdfQueryVariables = Exact<{
  businessId: Scalars['ID']['input'];
  year: Scalars['String']['input'];
  month: Scalars['String']['input'];
}>;


export type AccountStatementPdfQuery = { __typename?: 'Query', business?: { __typename?: 'Business', id: string, bankAccount?: { __typename?: 'BankAccount', id: string, accountStatementPdf?: { __typename?: 'AccountStatementPdf', month?: string | null, year?: string | null, pdf?: string | null } | null } | null } | null };

export type RegisterPersonMutationVariables = Exact<{
  businessId: Scalars['String']['input'];
  personId: Scalars['String']['input'];
}>;


export type RegisterPersonMutation = { __typename?: 'Mutation', registerPerson?: { __typename?: 'RegisterPersonResponse', success: boolean, code: string, message: string, user?: { __typename?: 'RegisteredPerson', businessId?: string | null, email?: string | null, userId: string, auth0Id?: string | null } | null } | null };

export type ListOwnersQueryVariables = Exact<{
  businessId: Scalars['ID']['input'];
}>;


export type ListOwnersQuery = { __typename?: 'Query', listOwners?: { __typename?: 'ListOwnersQueryResponse', owners: Array<{ __typename?: 'TeamOwner', id: string, userId?: string | null, businessId: string, email: string, firstName: string, lastName: string, title: string, type: string, ownershipPercentage: number, onboardingCompleted?: boolean | null, isPrimaryOwner?: boolean | null }> } | null };

export type SendInvitationMutationVariables = Exact<{
  businessId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
}>;


export type SendInvitationMutation = { __typename?: 'Mutation', inviteAdditionalOwner?: { __typename?: 'InviteAdditionalOwnerMutationResponse', success: boolean } | null };

export type ShareAndEarnHubQueryVariables = Exact<{
  businessId: Scalars['ID']['input'];
}>;


export type ShareAndEarnHubQuery = { __typename?: 'Query', business?: { __typename?: 'Business', shareAndEarn?: { __typename?: 'ShareAndEarn', advocate?: { __typename?: 'ShareAndEarnAdvocate', offerUrl: string, isBlocked: boolean, isEligible: boolean, totalRedeemedOffers: number, totalRedeemedAmount: number } | null } | null } | null };

export type ShareAndEarnResendBlockedCustomerEmailMutationVariables = Exact<{
  businessId: Scalars['ID']['input'];
  advocateId: Scalars['ID']['input'];
}>;


export type ShareAndEarnResendBlockedCustomerEmailMutation = { __typename?: 'Mutation', shareAndEarnResendBlockedCustomerEmail?: { __typename?: 'shareAndEarnResendBlockedCustomerEmailMutationResponse', success: boolean } | null };

export type BusinessDetailsQueryVariables = Exact<{
  businessId: Scalars['ID']['input'];
}>;


export type BusinessDetailsQuery = { __typename?: 'Query', business?: { __typename?: 'Business', id: string, name?: string | null } | null };

export type BankAccountInfoQueryVariables = Exact<{
  businessId: Scalars['ID']['input'];
}>;


export type BankAccountInfoQuery = { __typename?: 'Query', business?: { __typename?: 'Business', id: string, bankAccount?: { __typename?: 'BankAccount', id: string, availableBalance?: number | null, routingNumber?: string | null, accountNumber?: string | null, bankName?: string | null, bankAddress?: string | null } | null } | null };

export type BusinessInfoQueryVariables = Exact<{
  businessId: Scalars['ID']['input'];
}>;


export type BusinessInfoQuery = { __typename?: 'Query', business?: { __typename?: 'Business', id: string, name?: string | null, bankAccount?: { __typename?: 'BankAccount', id: string, availableBalance?: number | null, subAccounts: Array<{ __typename?: 'SubAccount', id: string, name: string, balance: number, isDefault?: boolean | null }> } | null } | null };

export type SubscriptionPlansDataQueryVariables = Exact<{
  businessId: Scalars['ID']['input'];
}>;


export type SubscriptionPlansDataQuery = { __typename?: 'Query', subscriptionPlans?: { __typename?: 'SubscriptionPlans', footer?: Array<string> | null, plans?: Array<{ __typename?: 'SubscriptionPlan', name: string, isCurrentPlan?: boolean | null, content: { __typename?: 'SubscriptionPlanContent', title?: string | null, shortTitle?: string | null, subtitle?: string | null, upgradeButtonText?: string | null, description?: Array<string> | null, featured?: boolean | null, name?: string | null, price?: Array<{ __typename?: 'SubscriptionPlanContentPrice', period?: SubscriptionPlanPricePeriod | null, price?: number | null, text?: string | null }> | null, upgrade?: { __typename?: 'SubscriptionPlanContentUpgrade', successTitle: string, successSubtitle: string } | null, upsell?: { __typename?: 'SubscriptionPlanContentUpsell', title: string, subtitle: string } | null, retentionFeatures?: Array<{ __typename?: 'SubscriptionPlanContentSectionBullet', icon?: string | null, text?: string | null }> | null, sections?: Array<{ __typename?: 'SubscriptionPlanContentSection', heading?: string | null, bullets?: Array<{ __typename?: 'SubscriptionPlanContentSectionBullet', icon?: string | null, text?: string | null }> | null }> | null } }> | null } | null, subscription?: { __typename?: 'Subscription', status: SubscriptionStatus, accountId?: string | null, plan: string, price?: number | null, paymentPeriod?: SubscriptionPlanPricePeriod | null, startDate?: string | null, nextBillingDate?: string | null } | null };

export type SubscriptionQueryVariables = Exact<{
  businessId: Scalars['ID']['input'];
}>;


export type SubscriptionQuery = { __typename?: 'Query', subscription?: { __typename?: 'Subscription', status: SubscriptionStatus, accountId?: string | null, plan: string, price?: number | null, paymentPeriod?: SubscriptionPlanPricePeriod | null, startDate?: string | null, nextBillingDate?: string | null } | null };

export type SubscriptionUpgradeMutationVariables = Exact<{
  input: SubscriptionUpgradeInput;
}>;


export type SubscriptionUpgradeMutation = { __typename?: 'Mutation', subscriptionUpgrade?: { __typename?: 'SubscriptionUpgradeMutationResponse', code: string, message: string, subscription: { __typename?: 'Subscription', status: SubscriptionStatus, accountId?: string | null, plan: string, price?: number | null, paymentPeriod?: SubscriptionPlanPricePeriod | null, startDate?: string | null, nextBillingDate?: string | null } } | null };

export type SubscriptionCancelMutationVariables = Exact<{
  input: SubscriptionCancelInput;
}>;


export type SubscriptionCancelMutation = { __typename?: 'Mutation', subscriptionCancel?: { __typename?: 'SubscriptionCancelMutationResponse', code: string, success: boolean, message: string, subscription: { __typename?: 'Subscription', accountId?: string | null, plan: string, price?: number | null, paymentPeriod?: SubscriptionPlanPricePeriod | null, startDate?: string | null, nextBillingDate?: string | null, status: SubscriptionStatus } } | null };

export type SubscriptionReactivateMutationVariables = Exact<{
  input: SubscriptionReactivateInput;
}>;


export type SubscriptionReactivateMutation = { __typename?: 'Mutation', subscriptionReactivate?: { __typename?: 'SubscriptionReactivateMutationResponse', code: string, message: string, success: boolean, subscription: { __typename?: 'Subscription', accountId?: string | null, plan: string, price?: number | null, paymentPeriod?: SubscriptionPlanPricePeriod | null, startDate?: string | null, nextBillingDate?: string | null, status: SubscriptionStatus } } | null };

export type TransactionUpsellContentQueryVariables = Exact<{
  businessId: Scalars['ID']['input'];
  transactionType: SubscriptionUpsellTransactionType;
}>;


export type TransactionUpsellContentQuery = { __typename?: 'Query', transactionUpsellContentPerType?: { __typename?: 'TransactionUpsellContentPerType', upsellPlan?: { __typename?: 'UpsellPlan', planName: string, buttonContent?: string | null, discountFeeDetails?: { __typename?: 'FlatFeeDetails', feeType: FeeType, amount: number } | { __typename?: 'PercentFeeDetails', feeType: FeeType, minimumAmount: number, maximumAmount: number, percent: number } | null } | null } | null };

export type FaqLandingQueryVariables = Exact<{ [key: string]: never; }>;


export type FaqLandingQuery = { __typename?: 'Query', supportHelpCenter: { __typename?: 'SupportHelpCenterService', sections: Array<{ __typename?: 'SupportArticleSection', id: string, name?: string | null }> } };

export type FaqCategoryQueryVariables = Exact<{
  sectionId?: InputMaybe<Scalars['ID']['input']>;
}>;


export type FaqCategoryQuery = { __typename?: 'Query', supportHelpCenter: { __typename?: 'SupportHelpCenterService', faqs: Array<{ __typename?: 'SupportArticle', id: string, title?: string | null }> } };

export type FaqArticleQueryVariables = Exact<{
  articleId: Scalars['ID']['input'];
}>;


export type FaqArticleQuery = { __typename?: 'Query', supportHelpCenter: { __typename?: 'SupportHelpCenterService', article?: { __typename?: 'SupportArticle', id: string, title?: string | null, body?: string | null } | null } };

export type FaqSearchQueryVariables = Exact<{
  searchTerm: Scalars['String']['input'];
}>;


export type FaqSearchQuery = { __typename?: 'Query', supportHelpCenter: { __typename?: 'SupportHelpCenterService', search?: { __typename?: 'SupportArticleSearch', faqs: Array<{ __typename?: 'SupportArticleSearchResult', id: string, title?: string | null }> } | null } };

export type AvailableSlotsForWeekQueryVariables = Exact<{
  timezone: Scalars['String']['input'];
}>;


export type AvailableSlotsForWeekQuery = { __typename?: 'Query', supportPhoneCall: { __typename?: 'SupportPhoneCallService', availableSlotsForWeek: Array<{ __typename?: 'SupportPhoneCallSlots', date?: string | null, slots?: number | null }> } };

export type AvailableSlotsForDayQueryVariables = Exact<{
  date: Scalars['String']['input'];
  timezone: Scalars['String']['input'];
}>;


export type AvailableSlotsForDayQuery = { __typename?: 'Query', supportPhoneCall: { __typename?: 'SupportPhoneCallService', availableSlotsForDay: Array<string> } };

export type BookPhoneCallMutationVariables = Exact<{
  data: SupportPhoneCallCreateInput;
}>;


export type BookPhoneCallMutation = { __typename?: 'Mutation', supportPhoneCallBookingCreate?: { __typename?: 'SupportPhoneCallBookingMutationResponse', supportPhoneCallBooking?: { __typename?: 'SupportPhoneCallBooking', dateTime?: string | null, confirmationEmail?: string | null } | null, userErrors: Array<{ __typename?: 'UserError', code: ErrorCodeEnum, message: string, field: Array<string> }> } | null };

export const CapabilitiesWithLimitAndFeesFragmentDoc = gql`
    fragment capabilitiesWithLimitAndFees on BankAccountCapabilities {
  ach {
    enabled
    limit
  }
  sameDayAch {
    enabled
    fee {
      ... on PercentFee {
        maximum
        minimum
        percentage
        type
      }
      ... on FlatFee {
        type
        amount
      }
    }
  }
  mailedCheck {
    limit
    fee {
      ... on FlatFee {
        type
        amount
      }
      ... on PercentFee {
        maximum
        minimum
        percentage
        type
      }
    }
  }
  checkDeposit {
    enabled
    limit
  }
  billPay {
    limit
    fee {
      ... on PercentFee {
        maximum
        minimum
        percentage
        type
      }
      ... on FlatFee {
        type
        amount
      }
    }
  }
  domesticWire {
    enabled
    limit
    fee {
      ... on FlatFee {
        type
        amount
      }
      ... on PercentFee {
        maximum
        minimum
        percentage
        type
      }
    }
  }
}
    `;
export const TransactionsListDocument = gql`
    query transactionsList($businessId: ID!, $pagination: TransactionsPaginationOptionsInput, $filters: TransactionsListFiltersInput) {
  transactionsList(
    pagination: $pagination
    filters: $filters
    businessId: $businessId
  ) {
    pagination {
      cursor
    }
    transactionListItems {
      id
      description
      amount
      status
      createdAt
      transactionType
      errorMessage
      isBancorpUpgradeDeposit
      returnedBy
    }
  }
  me {
    id
    wasExistingRadiusUser
    ownerBusinesses {
      id
      onboarding {
        id: businessId
        initialFundingStatus
      }
    }
  }
  business(businessId: $businessId) {
    id
    bankAccount {
      id
      availableBalance
    }
  }
}
    `;
export type TransactionsListComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<TransactionsListQuery, TransactionsListQueryVariables>, 'query'> & ({ variables: TransactionsListQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const TransactionsListComponent = (props: TransactionsListComponentProps) => (
      <ApolloReactComponents.Query<TransactionsListQuery, TransactionsListQueryVariables> query={TransactionsListDocument} {...props} />
    );
    

/**
 * __useTransactionsListQuery__
 *
 * To run a query within a React component, call `useTransactionsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransactionsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransactionsListQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useTransactionsListQuery(baseOptions: Apollo.QueryHookOptions<TransactionsListQuery, TransactionsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TransactionsListQuery, TransactionsListQueryVariables>(TransactionsListDocument, options);
      }
export function useTransactionsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TransactionsListQuery, TransactionsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TransactionsListQuery, TransactionsListQueryVariables>(TransactionsListDocument, options);
        }
export type TransactionsListQueryHookResult = ReturnType<typeof useTransactionsListQuery>;
export type TransactionsListLazyQueryHookResult = ReturnType<typeof useTransactionsListLazyQuery>;
export type TransactionsListQueryResult = Apollo.QueryResult<TransactionsListQuery, TransactionsListQueryVariables>;
export const TransactionNoteUpdateDocument = gql`
    mutation transactionNoteUpdate($businessId: ID!, $transactionId: ID!, $note: String!) {
  transactionNoteUpdate(
    businessId: $businessId
    transactionId: $transactionId
    note: $note
  ) {
    code
    message
    success
  }
}
    `;
export type TransactionNoteUpdateMutationFn = Apollo.MutationFunction<TransactionNoteUpdateMutation, TransactionNoteUpdateMutationVariables>;
export type TransactionNoteUpdateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<TransactionNoteUpdateMutation, TransactionNoteUpdateMutationVariables>, 'mutation'>;

    export const TransactionNoteUpdateComponent = (props: TransactionNoteUpdateComponentProps) => (
      <ApolloReactComponents.Mutation<TransactionNoteUpdateMutation, TransactionNoteUpdateMutationVariables> mutation={TransactionNoteUpdateDocument} {...props} />
    );
    

/**
 * __useTransactionNoteUpdateMutation__
 *
 * To run a mutation, you first call `useTransactionNoteUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransactionNoteUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transactionNoteUpdateMutation, { data, loading, error }] = useTransactionNoteUpdateMutation({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      transactionId: // value for 'transactionId'
 *      note: // value for 'note'
 *   },
 * });
 */
export function useTransactionNoteUpdateMutation(baseOptions?: Apollo.MutationHookOptions<TransactionNoteUpdateMutation, TransactionNoteUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TransactionNoteUpdateMutation, TransactionNoteUpdateMutationVariables>(TransactionNoteUpdateDocument, options);
      }
export type TransactionNoteUpdateMutationHookResult = ReturnType<typeof useTransactionNoteUpdateMutation>;
export type TransactionNoteUpdateMutationResult = Apollo.MutationResult<TransactionNoteUpdateMutation>;
export type TransactionNoteUpdateMutationOptions = Apollo.BaseMutationOptions<TransactionNoteUpdateMutation, TransactionNoteUpdateMutationVariables>;
export const AnnouncementDocument = gql`
    query announcement($type: AnnouncementTypeEnum!) {
  announcement(type: $type) {
    type
    isInteractionComplete
    requiredAudience
  }
}
    `;
export type AnnouncementComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AnnouncementQuery, AnnouncementQueryVariables>, 'query'> & ({ variables: AnnouncementQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const AnnouncementComponent = (props: AnnouncementComponentProps) => (
      <ApolloReactComponents.Query<AnnouncementQuery, AnnouncementQueryVariables> query={AnnouncementDocument} {...props} />
    );
    

/**
 * __useAnnouncementQuery__
 *
 * To run a query within a React component, call `useAnnouncementQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnnouncementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnnouncementQuery({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
export function useAnnouncementQuery(baseOptions: Apollo.QueryHookOptions<AnnouncementQuery, AnnouncementQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AnnouncementQuery, AnnouncementQueryVariables>(AnnouncementDocument, options);
      }
export function useAnnouncementLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AnnouncementQuery, AnnouncementQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AnnouncementQuery, AnnouncementQueryVariables>(AnnouncementDocument, options);
        }
export type AnnouncementQueryHookResult = ReturnType<typeof useAnnouncementQuery>;
export type AnnouncementLazyQueryHookResult = ReturnType<typeof useAnnouncementLazyQuery>;
export type AnnouncementQueryResult = Apollo.QueryResult<AnnouncementQuery, AnnouncementQueryVariables>;
export const AnnouncementInteractDocument = gql`
    mutation announcementInteract($type: AnnouncementTypeEnum!, $actionEvent: AnnouncementActionEventEnum!) {
  announcementInteract(type: $type, actionEvent: $actionEvent) {
    code
    success
    message
  }
}
    `;
export type AnnouncementInteractMutationFn = Apollo.MutationFunction<AnnouncementInteractMutation, AnnouncementInteractMutationVariables>;
export type AnnouncementInteractComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AnnouncementInteractMutation, AnnouncementInteractMutationVariables>, 'mutation'>;

    export const AnnouncementInteractComponent = (props: AnnouncementInteractComponentProps) => (
      <ApolloReactComponents.Mutation<AnnouncementInteractMutation, AnnouncementInteractMutationVariables> mutation={AnnouncementInteractDocument} {...props} />
    );
    

/**
 * __useAnnouncementInteractMutation__
 *
 * To run a mutation, you first call `useAnnouncementInteractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAnnouncementInteractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [announcementInteractMutation, { data, loading, error }] = useAnnouncementInteractMutation({
 *   variables: {
 *      type: // value for 'type'
 *      actionEvent: // value for 'actionEvent'
 *   },
 * });
 */
export function useAnnouncementInteractMutation(baseOptions?: Apollo.MutationHookOptions<AnnouncementInteractMutation, AnnouncementInteractMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AnnouncementInteractMutation, AnnouncementInteractMutationVariables>(AnnouncementInteractDocument, options);
      }
export type AnnouncementInteractMutationHookResult = ReturnType<typeof useAnnouncementInteractMutation>;
export type AnnouncementInteractMutationResult = Apollo.MutationResult<AnnouncementInteractMutation>;
export type AnnouncementInteractMutationOptions = Apollo.BaseMutationOptions<AnnouncementInteractMutation, AnnouncementInteractMutationVariables>;
export const BusinessCreatedAtDocument = gql`
    query businessCreatedAt($businessId: ID!) {
  me {
    id
    email
  }
  business(businessId: $businessId) {
    id
    createdAt
  }
}
    `;
export type BusinessCreatedAtComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<BusinessCreatedAtQuery, BusinessCreatedAtQueryVariables>, 'query'> & ({ variables: BusinessCreatedAtQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const BusinessCreatedAtComponent = (props: BusinessCreatedAtComponentProps) => (
      <ApolloReactComponents.Query<BusinessCreatedAtQuery, BusinessCreatedAtQueryVariables> query={BusinessCreatedAtDocument} {...props} />
    );
    

/**
 * __useBusinessCreatedAtQuery__
 *
 * To run a query within a React component, call `useBusinessCreatedAtQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessCreatedAtQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessCreatedAtQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function useBusinessCreatedAtQuery(baseOptions: Apollo.QueryHookOptions<BusinessCreatedAtQuery, BusinessCreatedAtQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BusinessCreatedAtQuery, BusinessCreatedAtQueryVariables>(BusinessCreatedAtDocument, options);
      }
export function useBusinessCreatedAtLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BusinessCreatedAtQuery, BusinessCreatedAtQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BusinessCreatedAtQuery, BusinessCreatedAtQueryVariables>(BusinessCreatedAtDocument, options);
        }
export type BusinessCreatedAtQueryHookResult = ReturnType<typeof useBusinessCreatedAtQuery>;
export type BusinessCreatedAtLazyQueryHookResult = ReturnType<typeof useBusinessCreatedAtLazyQuery>;
export type BusinessCreatedAtQueryResult = Apollo.QueryResult<BusinessCreatedAtQuery, BusinessCreatedAtQueryVariables>;
export const SubAccountBalancesDocument = gql`
    query subAccountBalances($businessId: ID!) {
  business(businessId: $businessId) {
    id
    bankAccount {
      id
      subAccounts {
        id
        balance
        isDefault
        name
      }
    }
  }
}
    `;
export type SubAccountBalancesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SubAccountBalancesQuery, SubAccountBalancesQueryVariables>, 'query'> & ({ variables: SubAccountBalancesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const SubAccountBalancesComponent = (props: SubAccountBalancesComponentProps) => (
      <ApolloReactComponents.Query<SubAccountBalancesQuery, SubAccountBalancesQueryVariables> query={SubAccountBalancesDocument} {...props} />
    );
    

/**
 * __useSubAccountBalancesQuery__
 *
 * To run a query within a React component, call `useSubAccountBalancesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubAccountBalancesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubAccountBalancesQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function useSubAccountBalancesQuery(baseOptions: Apollo.QueryHookOptions<SubAccountBalancesQuery, SubAccountBalancesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubAccountBalancesQuery, SubAccountBalancesQueryVariables>(SubAccountBalancesDocument, options);
      }
export function useSubAccountBalancesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubAccountBalancesQuery, SubAccountBalancesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubAccountBalancesQuery, SubAccountBalancesQueryVariables>(SubAccountBalancesDocument, options);
        }
export type SubAccountBalancesQueryHookResult = ReturnType<typeof useSubAccountBalancesQuery>;
export type SubAccountBalancesLazyQueryHookResult = ReturnType<typeof useSubAccountBalancesLazyQuery>;
export type SubAccountBalancesQueryResult = Apollo.QueryResult<SubAccountBalancesQuery, SubAccountBalancesQueryVariables>;
export const SubAccountNamesDocument = gql`
    query subAccountNames($businessId: ID!) {
  business(businessId: $businessId) {
    id
    bankAccount {
      id
      subAccounts {
        id
        name
      }
    }
  }
}
    `;
export type SubAccountNamesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SubAccountNamesQuery, SubAccountNamesQueryVariables>, 'query'> & ({ variables: SubAccountNamesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const SubAccountNamesComponent = (props: SubAccountNamesComponentProps) => (
      <ApolloReactComponents.Query<SubAccountNamesQuery, SubAccountNamesQueryVariables> query={SubAccountNamesDocument} {...props} />
    );
    

/**
 * __useSubAccountNamesQuery__
 *
 * To run a query within a React component, call `useSubAccountNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubAccountNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubAccountNamesQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function useSubAccountNamesQuery(baseOptions: Apollo.QueryHookOptions<SubAccountNamesQuery, SubAccountNamesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubAccountNamesQuery, SubAccountNamesQueryVariables>(SubAccountNamesDocument, options);
      }
export function useSubAccountNamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubAccountNamesQuery, SubAccountNamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubAccountNamesQuery, SubAccountNamesQueryVariables>(SubAccountNamesDocument, options);
        }
export type SubAccountNamesQueryHookResult = ReturnType<typeof useSubAccountNamesQuery>;
export type SubAccountNamesLazyQueryHookResult = ReturnType<typeof useSubAccountNamesLazyQuery>;
export type SubAccountNamesQueryResult = Apollo.QueryResult<SubAccountNamesQuery, SubAccountNamesQueryVariables>;
export const SubAccountCreateDocument = gql`
    mutation subAccountCreate($name: String!, $businessId: ID!, $bankAccountId: ID!) {
  subAccountCreate(
    name: $name
    businessId: $businessId
    bankAccountId: $bankAccountId
  ) {
    code
    message
    success
    subAccount {
      id
    }
  }
}
    `;
export type SubAccountCreateMutationFn = Apollo.MutationFunction<SubAccountCreateMutation, SubAccountCreateMutationVariables>;
export type SubAccountCreateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SubAccountCreateMutation, SubAccountCreateMutationVariables>, 'mutation'>;

    export const SubAccountCreateComponent = (props: SubAccountCreateComponentProps) => (
      <ApolloReactComponents.Mutation<SubAccountCreateMutation, SubAccountCreateMutationVariables> mutation={SubAccountCreateDocument} {...props} />
    );
    

/**
 * __useSubAccountCreateMutation__
 *
 * To run a mutation, you first call `useSubAccountCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubAccountCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subAccountCreateMutation, { data, loading, error }] = useSubAccountCreateMutation({
 *   variables: {
 *      name: // value for 'name'
 *      businessId: // value for 'businessId'
 *      bankAccountId: // value for 'bankAccountId'
 *   },
 * });
 */
export function useSubAccountCreateMutation(baseOptions?: Apollo.MutationHookOptions<SubAccountCreateMutation, SubAccountCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubAccountCreateMutation, SubAccountCreateMutationVariables>(SubAccountCreateDocument, options);
      }
export type SubAccountCreateMutationHookResult = ReturnType<typeof useSubAccountCreateMutation>;
export type SubAccountCreateMutationResult = Apollo.MutationResult<SubAccountCreateMutation>;
export type SubAccountCreateMutationOptions = Apollo.BaseMutationOptions<SubAccountCreateMutation, SubAccountCreateMutationVariables>;
export const SubAccountRulePercentCreateDocument = gql`
    mutation subAccountRulePercentCreate($businessId: ID!, $subAccountId: ID!, $percent: Int!) {
  subAccountRulePercentCreate(
    businessId: $businessId
    subAccountId: $subAccountId
    data: {percent: $percent}
  ) {
    code
    success
    message
  }
}
    `;
export type SubAccountRulePercentCreateMutationFn = Apollo.MutationFunction<SubAccountRulePercentCreateMutation, SubAccountRulePercentCreateMutationVariables>;
export type SubAccountRulePercentCreateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SubAccountRulePercentCreateMutation, SubAccountRulePercentCreateMutationVariables>, 'mutation'>;

    export const SubAccountRulePercentCreateComponent = (props: SubAccountRulePercentCreateComponentProps) => (
      <ApolloReactComponents.Mutation<SubAccountRulePercentCreateMutation, SubAccountRulePercentCreateMutationVariables> mutation={SubAccountRulePercentCreateDocument} {...props} />
    );
    

/**
 * __useSubAccountRulePercentCreateMutation__
 *
 * To run a mutation, you first call `useSubAccountRulePercentCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubAccountRulePercentCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subAccountRulePercentCreateMutation, { data, loading, error }] = useSubAccountRulePercentCreateMutation({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      subAccountId: // value for 'subAccountId'
 *      percent: // value for 'percent'
 *   },
 * });
 */
export function useSubAccountRulePercentCreateMutation(baseOptions?: Apollo.MutationHookOptions<SubAccountRulePercentCreateMutation, SubAccountRulePercentCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubAccountRulePercentCreateMutation, SubAccountRulePercentCreateMutationVariables>(SubAccountRulePercentCreateDocument, options);
      }
export type SubAccountRulePercentCreateMutationHookResult = ReturnType<typeof useSubAccountRulePercentCreateMutation>;
export type SubAccountRulePercentCreateMutationResult = Apollo.MutationResult<SubAccountRulePercentCreateMutation>;
export type SubAccountRulePercentCreateMutationOptions = Apollo.BaseMutationOptions<SubAccountRulePercentCreateMutation, SubAccountRulePercentCreateMutationVariables>;
export const SubAccountTotalPercentAvailableDocument = gql`
    query subAccountTotalPercentAvailable($businessId: ID!) {
  subAccountRuleTotalPercentAvailable(businessId: $businessId)
}
    `;
export type SubAccountTotalPercentAvailableComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SubAccountTotalPercentAvailableQuery, SubAccountTotalPercentAvailableQueryVariables>, 'query'> & ({ variables: SubAccountTotalPercentAvailableQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const SubAccountTotalPercentAvailableComponent = (props: SubAccountTotalPercentAvailableComponentProps) => (
      <ApolloReactComponents.Query<SubAccountTotalPercentAvailableQuery, SubAccountTotalPercentAvailableQueryVariables> query={SubAccountTotalPercentAvailableDocument} {...props} />
    );
    

/**
 * __useSubAccountTotalPercentAvailableQuery__
 *
 * To run a query within a React component, call `useSubAccountTotalPercentAvailableQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubAccountTotalPercentAvailableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubAccountTotalPercentAvailableQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function useSubAccountTotalPercentAvailableQuery(baseOptions: Apollo.QueryHookOptions<SubAccountTotalPercentAvailableQuery, SubAccountTotalPercentAvailableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubAccountTotalPercentAvailableQuery, SubAccountTotalPercentAvailableQueryVariables>(SubAccountTotalPercentAvailableDocument, options);
      }
export function useSubAccountTotalPercentAvailableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubAccountTotalPercentAvailableQuery, SubAccountTotalPercentAvailableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubAccountTotalPercentAvailableQuery, SubAccountTotalPercentAvailableQueryVariables>(SubAccountTotalPercentAvailableDocument, options);
        }
export type SubAccountTotalPercentAvailableQueryHookResult = ReturnType<typeof useSubAccountTotalPercentAvailableQuery>;
export type SubAccountTotalPercentAvailableLazyQueryHookResult = ReturnType<typeof useSubAccountTotalPercentAvailableLazyQuery>;
export type SubAccountTotalPercentAvailableQueryResult = Apollo.QueryResult<SubAccountTotalPercentAvailableQuery, SubAccountTotalPercentAvailableQueryVariables>;
export const RenameSubAccountDocument = gql`
    mutation renameSubAccount($businessId: ID!, $subAccountId: ID!, $name: String!) {
  subAccountUpdate(
    businessId: $businessId
    subAccountId: $subAccountId
    data: {name: $name}
  ) {
    code
    message
    success
  }
}
    `;
export type RenameSubAccountMutationFn = Apollo.MutationFunction<RenameSubAccountMutation, RenameSubAccountMutationVariables>;
export type RenameSubAccountComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RenameSubAccountMutation, RenameSubAccountMutationVariables>, 'mutation'>;

    export const RenameSubAccountComponent = (props: RenameSubAccountComponentProps) => (
      <ApolloReactComponents.Mutation<RenameSubAccountMutation, RenameSubAccountMutationVariables> mutation={RenameSubAccountDocument} {...props} />
    );
    

/**
 * __useRenameSubAccountMutation__
 *
 * To run a mutation, you first call `useRenameSubAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenameSubAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renameSubAccountMutation, { data, loading, error }] = useRenameSubAccountMutation({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      subAccountId: // value for 'subAccountId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useRenameSubAccountMutation(baseOptions?: Apollo.MutationHookOptions<RenameSubAccountMutation, RenameSubAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RenameSubAccountMutation, RenameSubAccountMutationVariables>(RenameSubAccountDocument, options);
      }
export type RenameSubAccountMutationHookResult = ReturnType<typeof useRenameSubAccountMutation>;
export type RenameSubAccountMutationResult = Apollo.MutationResult<RenameSubAccountMutation>;
export type RenameSubAccountMutationOptions = Apollo.BaseMutationOptions<RenameSubAccountMutation, RenameSubAccountMutationVariables>;
export const SubAccountRulePercentUpdateDocument = gql`
    mutation subAccountRulePercentUpdate($businessId: ID!, $subAccountId: ID!, $percent: Int) {
  subAccountRulePercentUpdate(
    businessId: $businessId
    subAccountId: $subAccountId
    data: {percent: $percent}
  ) {
    code
    success
    message
  }
}
    `;
export type SubAccountRulePercentUpdateMutationFn = Apollo.MutationFunction<SubAccountRulePercentUpdateMutation, SubAccountRulePercentUpdateMutationVariables>;
export type SubAccountRulePercentUpdateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SubAccountRulePercentUpdateMutation, SubAccountRulePercentUpdateMutationVariables>, 'mutation'>;

    export const SubAccountRulePercentUpdateComponent = (props: SubAccountRulePercentUpdateComponentProps) => (
      <ApolloReactComponents.Mutation<SubAccountRulePercentUpdateMutation, SubAccountRulePercentUpdateMutationVariables> mutation={SubAccountRulePercentUpdateDocument} {...props} />
    );
    

/**
 * __useSubAccountRulePercentUpdateMutation__
 *
 * To run a mutation, you first call `useSubAccountRulePercentUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubAccountRulePercentUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subAccountRulePercentUpdateMutation, { data, loading, error }] = useSubAccountRulePercentUpdateMutation({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      subAccountId: // value for 'subAccountId'
 *      percent: // value for 'percent'
 *   },
 * });
 */
export function useSubAccountRulePercentUpdateMutation(baseOptions?: Apollo.MutationHookOptions<SubAccountRulePercentUpdateMutation, SubAccountRulePercentUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubAccountRulePercentUpdateMutation, SubAccountRulePercentUpdateMutationVariables>(SubAccountRulePercentUpdateDocument, options);
      }
export type SubAccountRulePercentUpdateMutationHookResult = ReturnType<typeof useSubAccountRulePercentUpdateMutation>;
export type SubAccountRulePercentUpdateMutationResult = Apollo.MutationResult<SubAccountRulePercentUpdateMutation>;
export type SubAccountRulePercentUpdateMutationOptions = Apollo.BaseMutationOptions<SubAccountRulePercentUpdateMutation, SubAccountRulePercentUpdateMutationVariables>;
export const SubAccountRulePercentDeleteDocument = gql`
    mutation subAccountRulePercentDelete($businessId: ID!, $subAccountId: ID!) {
  subAccountRulePercentDelete(
    businessId: $businessId
    subAccountId: $subAccountId
  ) {
    code
    message
    success
  }
}
    `;
export type SubAccountRulePercentDeleteMutationFn = Apollo.MutationFunction<SubAccountRulePercentDeleteMutation, SubAccountRulePercentDeleteMutationVariables>;
export type SubAccountRulePercentDeleteComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SubAccountRulePercentDeleteMutation, SubAccountRulePercentDeleteMutationVariables>, 'mutation'>;

    export const SubAccountRulePercentDeleteComponent = (props: SubAccountRulePercentDeleteComponentProps) => (
      <ApolloReactComponents.Mutation<SubAccountRulePercentDeleteMutation, SubAccountRulePercentDeleteMutationVariables> mutation={SubAccountRulePercentDeleteDocument} {...props} />
    );
    

/**
 * __useSubAccountRulePercentDeleteMutation__
 *
 * To run a mutation, you first call `useSubAccountRulePercentDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubAccountRulePercentDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subAccountRulePercentDeleteMutation, { data, loading, error }] = useSubAccountRulePercentDeleteMutation({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      subAccountId: // value for 'subAccountId'
 *   },
 * });
 */
export function useSubAccountRulePercentDeleteMutation(baseOptions?: Apollo.MutationHookOptions<SubAccountRulePercentDeleteMutation, SubAccountRulePercentDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubAccountRulePercentDeleteMutation, SubAccountRulePercentDeleteMutationVariables>(SubAccountRulePercentDeleteDocument, options);
      }
export type SubAccountRulePercentDeleteMutationHookResult = ReturnType<typeof useSubAccountRulePercentDeleteMutation>;
export type SubAccountRulePercentDeleteMutationResult = Apollo.MutationResult<SubAccountRulePercentDeleteMutation>;
export type SubAccountRulePercentDeleteMutationOptions = Apollo.BaseMutationOptions<SubAccountRulePercentDeleteMutation, SubAccountRulePercentDeleteMutationVariables>;
export const SubAccountDeleteDocument = gql`
    mutation subAccountDelete($businessId: ID!, $subAccountId: ID!) {
  subAccountDelete(businessId: $businessId, subAccountId: $subAccountId) {
    code
    message
    success
  }
}
    `;
export type SubAccountDeleteMutationFn = Apollo.MutationFunction<SubAccountDeleteMutation, SubAccountDeleteMutationVariables>;
export type SubAccountDeleteComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SubAccountDeleteMutation, SubAccountDeleteMutationVariables>, 'mutation'>;

    export const SubAccountDeleteComponent = (props: SubAccountDeleteComponentProps) => (
      <ApolloReactComponents.Mutation<SubAccountDeleteMutation, SubAccountDeleteMutationVariables> mutation={SubAccountDeleteDocument} {...props} />
    );
    

/**
 * __useSubAccountDeleteMutation__
 *
 * To run a mutation, you first call `useSubAccountDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubAccountDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subAccountDeleteMutation, { data, loading, error }] = useSubAccountDeleteMutation({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      subAccountId: // value for 'subAccountId'
 *   },
 * });
 */
export function useSubAccountDeleteMutation(baseOptions?: Apollo.MutationHookOptions<SubAccountDeleteMutation, SubAccountDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubAccountDeleteMutation, SubAccountDeleteMutationVariables>(SubAccountDeleteDocument, options);
      }
export type SubAccountDeleteMutationHookResult = ReturnType<typeof useSubAccountDeleteMutation>;
export type SubAccountDeleteMutationResult = Apollo.MutationResult<SubAccountDeleteMutation>;
export type SubAccountDeleteMutationOptions = Apollo.BaseMutationOptions<SubAccountDeleteMutation, SubAccountDeleteMutationVariables>;
export const SubAccountDetailsDocument = gql`
    query subAccountDetails($businessId: ID!, $subAccountId: ID!) {
  business(businessId: $businessId) {
    id
    bankAccount {
      id
      subAccount(subAccountId: $subAccountId) {
        id
        name
        balance
        isDefault
        percentRule {
          businessId
          percent
          availableUpdateLimit
        }
      }
    }
  }
}
    `;
export type SubAccountDetailsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SubAccountDetailsQuery, SubAccountDetailsQueryVariables>, 'query'> & ({ variables: SubAccountDetailsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const SubAccountDetailsComponent = (props: SubAccountDetailsComponentProps) => (
      <ApolloReactComponents.Query<SubAccountDetailsQuery, SubAccountDetailsQueryVariables> query={SubAccountDetailsDocument} {...props} />
    );
    

/**
 * __useSubAccountDetailsQuery__
 *
 * To run a query within a React component, call `useSubAccountDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubAccountDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubAccountDetailsQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      subAccountId: // value for 'subAccountId'
 *   },
 * });
 */
export function useSubAccountDetailsQuery(baseOptions: Apollo.QueryHookOptions<SubAccountDetailsQuery, SubAccountDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubAccountDetailsQuery, SubAccountDetailsQueryVariables>(SubAccountDetailsDocument, options);
      }
export function useSubAccountDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubAccountDetailsQuery, SubAccountDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubAccountDetailsQuery, SubAccountDetailsQueryVariables>(SubAccountDetailsDocument, options);
        }
export type SubAccountDetailsQueryHookResult = ReturnType<typeof useSubAccountDetailsQuery>;
export type SubAccountDetailsLazyQueryHookResult = ReturnType<typeof useSubAccountDetailsLazyQuery>;
export type SubAccountDetailsQueryResult = Apollo.QueryResult<SubAccountDetailsQuery, SubAccountDetailsQueryVariables>;
export const SubAccountsDocument = gql`
    query subAccounts($businessId: ID!) {
  business(businessId: $businessId) {
    id
    bankAccount {
      id
      availableBalance
      subAccounts {
        id
        name
        balance
        isDefault
        percentRule {
          percent
        }
      }
    }
  }
}
    `;
export type SubAccountsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SubAccountsQuery, SubAccountsQueryVariables>, 'query'> & ({ variables: SubAccountsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const SubAccountsComponent = (props: SubAccountsComponentProps) => (
      <ApolloReactComponents.Query<SubAccountsQuery, SubAccountsQueryVariables> query={SubAccountsDocument} {...props} />
    );
    

/**
 * __useSubAccountsQuery__
 *
 * To run a query within a React component, call `useSubAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubAccountsQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function useSubAccountsQuery(baseOptions: Apollo.QueryHookOptions<SubAccountsQuery, SubAccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubAccountsQuery, SubAccountsQueryVariables>(SubAccountsDocument, options);
      }
export function useSubAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubAccountsQuery, SubAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubAccountsQuery, SubAccountsQueryVariables>(SubAccountsDocument, options);
        }
export type SubAccountsQueryHookResult = ReturnType<typeof useSubAccountsQuery>;
export type SubAccountsLazyQueryHookResult = ReturnType<typeof useSubAccountsLazyQuery>;
export type SubAccountsQueryResult = Apollo.QueryResult<SubAccountsQuery, SubAccountsQueryVariables>;
export const SubAccountTransferCreateDocument = gql`
    mutation subAccountTransferCreate($businessId: ID!, $data: SubAccountTransferCreateInput!) {
  subAccountTransferCreate(businessId: $businessId, data: $data) {
    code
    message
    success
  }
}
    `;
export type SubAccountTransferCreateMutationFn = Apollo.MutationFunction<SubAccountTransferCreateMutation, SubAccountTransferCreateMutationVariables>;
export type SubAccountTransferCreateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SubAccountTransferCreateMutation, SubAccountTransferCreateMutationVariables>, 'mutation'>;

    export const SubAccountTransferCreateComponent = (props: SubAccountTransferCreateComponentProps) => (
      <ApolloReactComponents.Mutation<SubAccountTransferCreateMutation, SubAccountTransferCreateMutationVariables> mutation={SubAccountTransferCreateDocument} {...props} />
    );
    

/**
 * __useSubAccountTransferCreateMutation__
 *
 * To run a mutation, you first call `useSubAccountTransferCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubAccountTransferCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subAccountTransferCreateMutation, { data, loading, error }] = useSubAccountTransferCreateMutation({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSubAccountTransferCreateMutation(baseOptions?: Apollo.MutationHookOptions<SubAccountTransferCreateMutation, SubAccountTransferCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubAccountTransferCreateMutation, SubAccountTransferCreateMutationVariables>(SubAccountTransferCreateDocument, options);
      }
export type SubAccountTransferCreateMutationHookResult = ReturnType<typeof useSubAccountTransferCreateMutation>;
export type SubAccountTransferCreateMutationResult = Apollo.MutationResult<SubAccountTransferCreateMutation>;
export type SubAccountTransferCreateMutationOptions = Apollo.BaseMutationOptions<SubAccountTransferCreateMutation, SubAccountTransferCreateMutationVariables>;
export const PhoneNumberDocument = gql`
    query phoneNumber {
  me {
    id
    phone
  }
}
    `;
export type PhoneNumberComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<PhoneNumberQuery, PhoneNumberQueryVariables>, 'query'>;

    export const PhoneNumberComponent = (props: PhoneNumberComponentProps) => (
      <ApolloReactComponents.Query<PhoneNumberQuery, PhoneNumberQueryVariables> query={PhoneNumberDocument} {...props} />
    );
    

/**
 * __usePhoneNumberQuery__
 *
 * To run a query within a React component, call `usePhoneNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `usePhoneNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePhoneNumberQuery({
 *   variables: {
 *   },
 * });
 */
export function usePhoneNumberQuery(baseOptions?: Apollo.QueryHookOptions<PhoneNumberQuery, PhoneNumberQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PhoneNumberQuery, PhoneNumberQueryVariables>(PhoneNumberDocument, options);
      }
export function usePhoneNumberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PhoneNumberQuery, PhoneNumberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PhoneNumberQuery, PhoneNumberQueryVariables>(PhoneNumberDocument, options);
        }
export type PhoneNumberQueryHookResult = ReturnType<typeof usePhoneNumberQuery>;
export type PhoneNumberLazyQueryHookResult = ReturnType<typeof usePhoneNumberLazyQuery>;
export type PhoneNumberQueryResult = Apollo.QueryResult<PhoneNumberQuery, PhoneNumberQueryVariables>;
export const OnboardingMeDocument = gql`
    query onboardingMe {
  me {
    id
    email
    phone
    firstName
    lastName
    wasExistingRadiusUser
    ownerBusinesses {
      id
      bankAccount {
        id
      }
      onboarding {
        id: businessId
        accountStatus
      }
    }
  }
}
    `;
export type OnboardingMeComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<OnboardingMeQuery, OnboardingMeQueryVariables>, 'query'>;

    export const OnboardingMeComponent = (props: OnboardingMeComponentProps) => (
      <ApolloReactComponents.Query<OnboardingMeQuery, OnboardingMeQueryVariables> query={OnboardingMeDocument} {...props} />
    );
    

/**
 * __useOnboardingMeQuery__
 *
 * To run a query within a React component, call `useOnboardingMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnboardingMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnboardingMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useOnboardingMeQuery(baseOptions?: Apollo.QueryHookOptions<OnboardingMeQuery, OnboardingMeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OnboardingMeQuery, OnboardingMeQueryVariables>(OnboardingMeDocument, options);
      }
export function useOnboardingMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OnboardingMeQuery, OnboardingMeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OnboardingMeQuery, OnboardingMeQueryVariables>(OnboardingMeDocument, options);
        }
export type OnboardingMeQueryHookResult = ReturnType<typeof useOnboardingMeQuery>;
export type OnboardingMeLazyQueryHookResult = ReturnType<typeof useOnboardingMeLazyQuery>;
export type OnboardingMeQueryResult = Apollo.QueryResult<OnboardingMeQuery, OnboardingMeQueryVariables>;
export const EmailVerifiedDocument = gql`
    query emailVerified {
  me {
    id
    emailVerified
  }
}
    `;
export type EmailVerifiedComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<EmailVerifiedQuery, EmailVerifiedQueryVariables>, 'query'>;

    export const EmailVerifiedComponent = (props: EmailVerifiedComponentProps) => (
      <ApolloReactComponents.Query<EmailVerifiedQuery, EmailVerifiedQueryVariables> query={EmailVerifiedDocument} {...props} />
    );
    

/**
 * __useEmailVerifiedQuery__
 *
 * To run a query within a React component, call `useEmailVerifiedQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailVerifiedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailVerifiedQuery({
 *   variables: {
 *   },
 * });
 */
export function useEmailVerifiedQuery(baseOptions?: Apollo.QueryHookOptions<EmailVerifiedQuery, EmailVerifiedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmailVerifiedQuery, EmailVerifiedQueryVariables>(EmailVerifiedDocument, options);
      }
export function useEmailVerifiedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmailVerifiedQuery, EmailVerifiedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmailVerifiedQuery, EmailVerifiedQueryVariables>(EmailVerifiedDocument, options);
        }
export type EmailVerifiedQueryHookResult = ReturnType<typeof useEmailVerifiedQuery>;
export type EmailVerifiedLazyQueryHookResult = ReturnType<typeof useEmailVerifiedLazyQuery>;
export type EmailVerifiedQueryResult = Apollo.QueryResult<EmailVerifiedQuery, EmailVerifiedQueryVariables>;
export const ResendVerificationEmailDocument = gql`
    mutation resendVerificationEmail($email: String!, $reCAPTCHAToken: String!) {
  resendVerificationEmail(userEmail: $email, reCAPTCHAToken: $reCAPTCHAToken) {
    code
    success
    message
  }
}
    `;
export type ResendVerificationEmailMutationFn = Apollo.MutationFunction<ResendVerificationEmailMutation, ResendVerificationEmailMutationVariables>;
export type ResendVerificationEmailComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ResendVerificationEmailMutation, ResendVerificationEmailMutationVariables>, 'mutation'>;

    export const ResendVerificationEmailComponent = (props: ResendVerificationEmailComponentProps) => (
      <ApolloReactComponents.Mutation<ResendVerificationEmailMutation, ResendVerificationEmailMutationVariables> mutation={ResendVerificationEmailDocument} {...props} />
    );
    

/**
 * __useResendVerificationEmailMutation__
 *
 * To run a mutation, you first call `useResendVerificationEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendVerificationEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendVerificationEmailMutation, { data, loading, error }] = useResendVerificationEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *      reCAPTCHAToken: // value for 'reCAPTCHAToken'
 *   },
 * });
 */
export function useResendVerificationEmailMutation(baseOptions?: Apollo.MutationHookOptions<ResendVerificationEmailMutation, ResendVerificationEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendVerificationEmailMutation, ResendVerificationEmailMutationVariables>(ResendVerificationEmailDocument, options);
      }
export type ResendVerificationEmailMutationHookResult = ReturnType<typeof useResendVerificationEmailMutation>;
export type ResendVerificationEmailMutationResult = Apollo.MutationResult<ResendVerificationEmailMutation>;
export type ResendVerificationEmailMutationOptions = Apollo.BaseMutationOptions<ResendVerificationEmailMutation, ResendVerificationEmailMutationVariables>;
export const ConfirmAdditionalOwnersOnboardingStatusDocument = gql`
    mutation confirmAdditionalOwnersOnboardingStatus($businessId: ID!, $userId: ID!) {
  confirmAdditionalOwnersOnboardingStatus(
    businessId: $businessId
    userId: $userId
  ) {
    status {
      onboardingCompleted
    }
  }
}
    `;
export type ConfirmAdditionalOwnersOnboardingStatusMutationFn = Apollo.MutationFunction<ConfirmAdditionalOwnersOnboardingStatusMutation, ConfirmAdditionalOwnersOnboardingStatusMutationVariables>;
export type ConfirmAdditionalOwnersOnboardingStatusComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ConfirmAdditionalOwnersOnboardingStatusMutation, ConfirmAdditionalOwnersOnboardingStatusMutationVariables>, 'mutation'>;

    export const ConfirmAdditionalOwnersOnboardingStatusComponent = (props: ConfirmAdditionalOwnersOnboardingStatusComponentProps) => (
      <ApolloReactComponents.Mutation<ConfirmAdditionalOwnersOnboardingStatusMutation, ConfirmAdditionalOwnersOnboardingStatusMutationVariables> mutation={ConfirmAdditionalOwnersOnboardingStatusDocument} {...props} />
    );
    

/**
 * __useConfirmAdditionalOwnersOnboardingStatusMutation__
 *
 * To run a mutation, you first call `useConfirmAdditionalOwnersOnboardingStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmAdditionalOwnersOnboardingStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmAdditionalOwnersOnboardingStatusMutation, { data, loading, error }] = useConfirmAdditionalOwnersOnboardingStatusMutation({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useConfirmAdditionalOwnersOnboardingStatusMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmAdditionalOwnersOnboardingStatusMutation, ConfirmAdditionalOwnersOnboardingStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConfirmAdditionalOwnersOnboardingStatusMutation, ConfirmAdditionalOwnersOnboardingStatusMutationVariables>(ConfirmAdditionalOwnersOnboardingStatusDocument, options);
      }
export type ConfirmAdditionalOwnersOnboardingStatusMutationHookResult = ReturnType<typeof useConfirmAdditionalOwnersOnboardingStatusMutation>;
export type ConfirmAdditionalOwnersOnboardingStatusMutationResult = Apollo.MutationResult<ConfirmAdditionalOwnersOnboardingStatusMutation>;
export type ConfirmAdditionalOwnersOnboardingStatusMutationOptions = Apollo.BaseMutationOptions<ConfirmAdditionalOwnersOnboardingStatusMutation, ConfirmAdditionalOwnersOnboardingStatusMutationVariables>;
export const CardsDocument = gql`
    query cards($businessId: ID!) {
  business(businessId: $businessId) {
    id
    bankAccounts {
      id
      cards {
        id
        productType
        embossLine1
        embossLine2
        cardNumber {
          last4
        }
        shippedDate
        activation {
          cardId
          activated
          pinSet {
            cardId
            status
          }
        }
        user {
          id
          firstName
          lastName
          isPrimaryOwner
          signUpDate
        }
        businessId
      }
    }
  }
}
    `;
export type CardsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CardsQuery, CardsQueryVariables>, 'query'> & ({ variables: CardsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const CardsComponent = (props: CardsComponentProps) => (
      <ApolloReactComponents.Query<CardsQuery, CardsQueryVariables> query={CardsDocument} {...props} />
    );
    

/**
 * __useCardsQuery__
 *
 * To run a query within a React component, call `useCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCardsQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function useCardsQuery(baseOptions: Apollo.QueryHookOptions<CardsQuery, CardsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CardsQuery, CardsQueryVariables>(CardsDocument, options);
      }
export function useCardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CardsQuery, CardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CardsQuery, CardsQueryVariables>(CardsDocument, options);
        }
export type CardsQueryHookResult = ReturnType<typeof useCardsQuery>;
export type CardsLazyQueryHookResult = ReturnType<typeof useCardsLazyQuery>;
export type CardsQueryResult = Apollo.QueryResult<CardsQuery, CardsQueryVariables>;
export const CardDocument = gql`
    query card($businessId: ID!, $cardId: ID!) {
  business(businessId: $businessId) {
    id
    bankAccount {
      id
      card(cardId: $cardId) {
        id
        embossLine1
        cardNumber {
          last4
        }
        shippedDate
        productType
        activation {
          cardId
          activated
          pinSet {
            cardId
            status
            updatedAt
          }
        }
        freezeInfo {
          frozen
        }
        disabled
      }
    }
  }
}
    `;
export type CardComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CardQuery, CardQueryVariables>, 'query'> & ({ variables: CardQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const CardComponent = (props: CardComponentProps) => (
      <ApolloReactComponents.Query<CardQuery, CardQueryVariables> query={CardDocument} {...props} />
    );
    

/**
 * __useCardQuery__
 *
 * To run a query within a React component, call `useCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCardQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      cardId: // value for 'cardId'
 *   },
 * });
 */
export function useCardQuery(baseOptions: Apollo.QueryHookOptions<CardQuery, CardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CardQuery, CardQueryVariables>(CardDocument, options);
      }
export function useCardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CardQuery, CardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CardQuery, CardQueryVariables>(CardDocument, options);
        }
export type CardQueryHookResult = ReturnType<typeof useCardQuery>;
export type CardLazyQueryHookResult = ReturnType<typeof useCardLazyQuery>;
export type CardQueryResult = Apollo.QueryResult<CardQuery, CardQueryVariables>;
export const CardActivateDocument = gql`
    mutation cardActivate($businessId: ID!, $accountId: ID!, $cardId: ID!, $expirationDate: String!, $securityCode: String!) {
  cardActivate(
    businessId: $businessId
    accountId: $accountId
    cardId: $cardId
    expirationDate: $expirationDate
    securityCode: $securityCode
  ) {
    code
    success
    message
  }
}
    `;
export type CardActivateMutationFn = Apollo.MutationFunction<CardActivateMutation, CardActivateMutationVariables>;
export type CardActivateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CardActivateMutation, CardActivateMutationVariables>, 'mutation'>;

    export const CardActivateComponent = (props: CardActivateComponentProps) => (
      <ApolloReactComponents.Mutation<CardActivateMutation, CardActivateMutationVariables> mutation={CardActivateDocument} {...props} />
    );
    

/**
 * __useCardActivateMutation__
 *
 * To run a mutation, you first call `useCardActivateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCardActivateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cardActivateMutation, { data, loading, error }] = useCardActivateMutation({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      accountId: // value for 'accountId'
 *      cardId: // value for 'cardId'
 *      expirationDate: // value for 'expirationDate'
 *      securityCode: // value for 'securityCode'
 *   },
 * });
 */
export function useCardActivateMutation(baseOptions?: Apollo.MutationHookOptions<CardActivateMutation, CardActivateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CardActivateMutation, CardActivateMutationVariables>(CardActivateDocument, options);
      }
export type CardActivateMutationHookResult = ReturnType<typeof useCardActivateMutation>;
export type CardActivateMutationResult = Apollo.MutationResult<CardActivateMutation>;
export type CardActivateMutationOptions = Apollo.BaseMutationOptions<CardActivateMutation, CardActivateMutationVariables>;
export const CardPinChangeFormDocument = gql`
    query cardPinChangeForm($businessId: ID!, $accountId: ID!, $cardId: ID!) {
  cardPinChangeForm(
    businessId: $businessId
    accountId: $accountId
    cardId: $cardId
  ) {
    cardId
    url
    expiresAt
  }
}
    `;
export type CardPinChangeFormComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CardPinChangeFormQuery, CardPinChangeFormQueryVariables>, 'query'> & ({ variables: CardPinChangeFormQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const CardPinChangeFormComponent = (props: CardPinChangeFormComponentProps) => (
      <ApolloReactComponents.Query<CardPinChangeFormQuery, CardPinChangeFormQueryVariables> query={CardPinChangeFormDocument} {...props} />
    );
    

/**
 * __useCardPinChangeFormQuery__
 *
 * To run a query within a React component, call `useCardPinChangeFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useCardPinChangeFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCardPinChangeFormQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      accountId: // value for 'accountId'
 *      cardId: // value for 'cardId'
 *   },
 * });
 */
export function useCardPinChangeFormQuery(baseOptions: Apollo.QueryHookOptions<CardPinChangeFormQuery, CardPinChangeFormQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CardPinChangeFormQuery, CardPinChangeFormQueryVariables>(CardPinChangeFormDocument, options);
      }
export function useCardPinChangeFormLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CardPinChangeFormQuery, CardPinChangeFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CardPinChangeFormQuery, CardPinChangeFormQueryVariables>(CardPinChangeFormDocument, options);
        }
export type CardPinChangeFormQueryHookResult = ReturnType<typeof useCardPinChangeFormQuery>;
export type CardPinChangeFormLazyQueryHookResult = ReturnType<typeof useCardPinChangeFormLazyQuery>;
export type CardPinChangeFormQueryResult = Apollo.QueryResult<CardPinChangeFormQuery, CardPinChangeFormQueryVariables>;
export const CardFreezeDocument = gql`
    mutation cardFreeze($businessId: ID!, $accountId: ID!, $cardId: ID!) {
  cardFreeze(businessId: $businessId, accountId: $accountId, cardId: $cardId) {
    code
    success
    message
  }
}
    `;
export type CardFreezeMutationFn = Apollo.MutationFunction<CardFreezeMutation, CardFreezeMutationVariables>;
export type CardFreezeComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CardFreezeMutation, CardFreezeMutationVariables>, 'mutation'>;

    export const CardFreezeComponent = (props: CardFreezeComponentProps) => (
      <ApolloReactComponents.Mutation<CardFreezeMutation, CardFreezeMutationVariables> mutation={CardFreezeDocument} {...props} />
    );
    

/**
 * __useCardFreezeMutation__
 *
 * To run a mutation, you first call `useCardFreezeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCardFreezeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cardFreezeMutation, { data, loading, error }] = useCardFreezeMutation({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      accountId: // value for 'accountId'
 *      cardId: // value for 'cardId'
 *   },
 * });
 */
export function useCardFreezeMutation(baseOptions?: Apollo.MutationHookOptions<CardFreezeMutation, CardFreezeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CardFreezeMutation, CardFreezeMutationVariables>(CardFreezeDocument, options);
      }
export type CardFreezeMutationHookResult = ReturnType<typeof useCardFreezeMutation>;
export type CardFreezeMutationResult = Apollo.MutationResult<CardFreezeMutation>;
export type CardFreezeMutationOptions = Apollo.BaseMutationOptions<CardFreezeMutation, CardFreezeMutationVariables>;
export const CardUnfreezeDocument = gql`
    mutation cardUnfreeze($businessId: ID!, $accountId: ID!, $cardId: ID!) {
  cardUnfreeze(businessId: $businessId, accountId: $accountId, cardId: $cardId) {
    code
    success
    message
  }
}
    `;
export type CardUnfreezeMutationFn = Apollo.MutationFunction<CardUnfreezeMutation, CardUnfreezeMutationVariables>;
export type CardUnfreezeComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CardUnfreezeMutation, CardUnfreezeMutationVariables>, 'mutation'>;

    export const CardUnfreezeComponent = (props: CardUnfreezeComponentProps) => (
      <ApolloReactComponents.Mutation<CardUnfreezeMutation, CardUnfreezeMutationVariables> mutation={CardUnfreezeDocument} {...props} />
    );
    

/**
 * __useCardUnfreezeMutation__
 *
 * To run a mutation, you first call `useCardUnfreezeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCardUnfreezeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cardUnfreezeMutation, { data, loading, error }] = useCardUnfreezeMutation({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      accountId: // value for 'accountId'
 *      cardId: // value for 'cardId'
 *   },
 * });
 */
export function useCardUnfreezeMutation(baseOptions?: Apollo.MutationHookOptions<CardUnfreezeMutation, CardUnfreezeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CardUnfreezeMutation, CardUnfreezeMutationVariables>(CardUnfreezeDocument, options);
      }
export type CardUnfreezeMutationHookResult = ReturnType<typeof useCardUnfreezeMutation>;
export type CardUnfreezeMutationResult = Apollo.MutationResult<CardUnfreezeMutation>;
export type CardUnfreezeMutationOptions = Apollo.BaseMutationOptions<CardUnfreezeMutation, CardUnfreezeMutationVariables>;
export const DigitalCardAssetUrlDocument = gql`
    query digitalCardAssetUrl($businessId: ID!, $accountId: ID!, $cardId: ID!) {
  digitalCardAssetUrl(
    businessId: $businessId
    accountId: $accountId
    cardId: $cardId
  ) {
    cardId
    url
    expiresAt
  }
}
    `;
export type DigitalCardAssetUrlComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<DigitalCardAssetUrlQuery, DigitalCardAssetUrlQueryVariables>, 'query'> & ({ variables: DigitalCardAssetUrlQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const DigitalCardAssetUrlComponent = (props: DigitalCardAssetUrlComponentProps) => (
      <ApolloReactComponents.Query<DigitalCardAssetUrlQuery, DigitalCardAssetUrlQueryVariables> query={DigitalCardAssetUrlDocument} {...props} />
    );
    

/**
 * __useDigitalCardAssetUrlQuery__
 *
 * To run a query within a React component, call `useDigitalCardAssetUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useDigitalCardAssetUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDigitalCardAssetUrlQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      accountId: // value for 'accountId'
 *      cardId: // value for 'cardId'
 *   },
 * });
 */
export function useDigitalCardAssetUrlQuery(baseOptions: Apollo.QueryHookOptions<DigitalCardAssetUrlQuery, DigitalCardAssetUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DigitalCardAssetUrlQuery, DigitalCardAssetUrlQueryVariables>(DigitalCardAssetUrlDocument, options);
      }
export function useDigitalCardAssetUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DigitalCardAssetUrlQuery, DigitalCardAssetUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DigitalCardAssetUrlQuery, DigitalCardAssetUrlQueryVariables>(DigitalCardAssetUrlDocument, options);
        }
export type DigitalCardAssetUrlQueryHookResult = ReturnType<typeof useDigitalCardAssetUrlQuery>;
export type DigitalCardAssetUrlLazyQueryHookResult = ReturnType<typeof useDigitalCardAssetUrlLazyQuery>;
export type DigitalCardAssetUrlQueryResult = Apollo.QueryResult<DigitalCardAssetUrlQuery, DigitalCardAssetUrlQueryVariables>;
export const AddVirtualCardDocument = gql`
    mutation addVirtualCard($businessId: ID!, $accountId: ID!) {
  addVirtualCard(businessId: $businessId, accountId: $accountId) {
    code
    success
    message
  }
}
    `;
export type AddVirtualCardMutationFn = Apollo.MutationFunction<AddVirtualCardMutation, AddVirtualCardMutationVariables>;
export type AddVirtualCardComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddVirtualCardMutation, AddVirtualCardMutationVariables>, 'mutation'>;

    export const AddVirtualCardComponent = (props: AddVirtualCardComponentProps) => (
      <ApolloReactComponents.Mutation<AddVirtualCardMutation, AddVirtualCardMutationVariables> mutation={AddVirtualCardDocument} {...props} />
    );
    

/**
 * __useAddVirtualCardMutation__
 *
 * To run a mutation, you first call `useAddVirtualCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddVirtualCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addVirtualCardMutation, { data, loading, error }] = useAddVirtualCardMutation({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useAddVirtualCardMutation(baseOptions?: Apollo.MutationHookOptions<AddVirtualCardMutation, AddVirtualCardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddVirtualCardMutation, AddVirtualCardMutationVariables>(AddVirtualCardDocument, options);
      }
export type AddVirtualCardMutationHookResult = ReturnType<typeof useAddVirtualCardMutation>;
export type AddVirtualCardMutationResult = Apollo.MutationResult<AddVirtualCardMutation>;
export type AddVirtualCardMutationOptions = Apollo.BaseMutationOptions<AddVirtualCardMutation, AddVirtualCardMutationVariables>;
export const BusinessNameDocument = gql`
    query businessName($businessId: ID!) {
  me {
    id
    firstName
  }
  business(businessId: $businessId) {
    id
    name
  }
}
    `;
export type BusinessNameComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<BusinessNameQuery, BusinessNameQueryVariables>, 'query'> & ({ variables: BusinessNameQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const BusinessNameComponent = (props: BusinessNameComponentProps) => (
      <ApolloReactComponents.Query<BusinessNameQuery, BusinessNameQueryVariables> query={BusinessNameDocument} {...props} />
    );
    

/**
 * __useBusinessNameQuery__
 *
 * To run a query within a React component, call `useBusinessNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessNameQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function useBusinessNameQuery(baseOptions: Apollo.QueryHookOptions<BusinessNameQuery, BusinessNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BusinessNameQuery, BusinessNameQueryVariables>(BusinessNameDocument, options);
      }
export function useBusinessNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BusinessNameQuery, BusinessNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BusinessNameQuery, BusinessNameQueryVariables>(BusinessNameDocument, options);
        }
export type BusinessNameQueryHookResult = ReturnType<typeof useBusinessNameQuery>;
export type BusinessNameLazyQueryHookResult = ReturnType<typeof useBusinessNameLazyQuery>;
export type BusinessNameQueryResult = Apollo.QueryResult<BusinessNameQuery, BusinessNameQueryVariables>;
export const TransactionSearchCsvDocument = gql`
    query transactionSearchCSV($businessId: ID!, $filters: TransactionsListFiltersInput, $csvFormat: CsvFormatEnum) {
  transactionSearchCSV(
    businessId: $businessId
    filters: $filters
    csvFormat: $csvFormat
  ) {
    csvURL
  }
}
    `;
export type TransactionSearchCsvComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<TransactionSearchCsvQuery, TransactionSearchCsvQueryVariables>, 'query'> & ({ variables: TransactionSearchCsvQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const TransactionSearchCsvComponent = (props: TransactionSearchCsvComponentProps) => (
      <ApolloReactComponents.Query<TransactionSearchCsvQuery, TransactionSearchCsvQueryVariables> query={TransactionSearchCsvDocument} {...props} />
    );
    

/**
 * __useTransactionSearchCsvQuery__
 *
 * To run a query within a React component, call `useTransactionSearchCsvQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransactionSearchCsvQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransactionSearchCsvQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      filters: // value for 'filters'
 *      csvFormat: // value for 'csvFormat'
 *   },
 * });
 */
export function useTransactionSearchCsvQuery(baseOptions: Apollo.QueryHookOptions<TransactionSearchCsvQuery, TransactionSearchCsvQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TransactionSearchCsvQuery, TransactionSearchCsvQueryVariables>(TransactionSearchCsvDocument, options);
      }
export function useTransactionSearchCsvLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TransactionSearchCsvQuery, TransactionSearchCsvQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TransactionSearchCsvQuery, TransactionSearchCsvQueryVariables>(TransactionSearchCsvDocument, options);
        }
export type TransactionSearchCsvQueryHookResult = ReturnType<typeof useTransactionSearchCsvQuery>;
export type TransactionSearchCsvLazyQueryHookResult = ReturnType<typeof useTransactionSearchCsvLazyQuery>;
export type TransactionSearchCsvQueryResult = Apollo.QueryResult<TransactionSearchCsvQuery, TransactionSearchCsvQueryVariables>;
export const TransactionDetailsDocument = gql`
    query transactionDetails($businessId: ID!, $transactionId: ID!) {
  transaction(businessId: $businessId, transactionId: $transactionId) {
    id
    amount
    createdAt
    postedAt
    note
    transactionType
    status
    errorMessage
    description
    returnedBy
    cardLast4
    user {
      id
      email
      firstName
      lastName
      isPrimaryOwner
    }
  }
}
    `;
export type TransactionDetailsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<TransactionDetailsQuery, TransactionDetailsQueryVariables>, 'query'> & ({ variables: TransactionDetailsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const TransactionDetailsComponent = (props: TransactionDetailsComponentProps) => (
      <ApolloReactComponents.Query<TransactionDetailsQuery, TransactionDetailsQueryVariables> query={TransactionDetailsDocument} {...props} />
    );
    

/**
 * __useTransactionDetailsQuery__
 *
 * To run a query within a React component, call `useTransactionDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransactionDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransactionDetailsQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      transactionId: // value for 'transactionId'
 *   },
 * });
 */
export function useTransactionDetailsQuery(baseOptions: Apollo.QueryHookOptions<TransactionDetailsQuery, TransactionDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TransactionDetailsQuery, TransactionDetailsQueryVariables>(TransactionDetailsDocument, options);
      }
export function useTransactionDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TransactionDetailsQuery, TransactionDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TransactionDetailsQuery, TransactionDetailsQueryVariables>(TransactionDetailsDocument, options);
        }
export type TransactionDetailsQueryHookResult = ReturnType<typeof useTransactionDetailsQuery>;
export type TransactionDetailsLazyQueryHookResult = ReturnType<typeof useTransactionDetailsLazyQuery>;
export type TransactionDetailsQueryResult = Apollo.QueryResult<TransactionDetailsQuery, TransactionDetailsQueryVariables>;
export const ListInvoiceAccountsPayableDocument = gql`
    query listInvoiceAccountsPayable($businessId: ID!, $sorting: InvoiceAccountsPayableListSortingInput!, $filters: InvoiceAccountsPayableListFiltersInput, $pagination: InvoiceAccountsPayableListPaginationOptionsInput) {
  listInvoiceAccountsPayable(
    businessId: $businessId
    sorting: $sorting
    filters: $filters
    pagination: $pagination
  ) {
    invoices {
      id
      vendorName
      contactId
      invoiceNumber
      invoiceDate
      dueDate
      status
      paymentStatus
      amount
      paidAmount
      scheduledAmount
      pendingAmount
      remainingAmount
      businessId
      createdVia
      createdDate
      updatedDate
      paidDate
      description
      markedAsPaidReason
      attachmentId
    }
    pagination {
      cursor
    }
  }
}
    `;
export type ListInvoiceAccountsPayableComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ListInvoiceAccountsPayableQuery, ListInvoiceAccountsPayableQueryVariables>, 'query'> & ({ variables: ListInvoiceAccountsPayableQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ListInvoiceAccountsPayableComponent = (props: ListInvoiceAccountsPayableComponentProps) => (
      <ApolloReactComponents.Query<ListInvoiceAccountsPayableQuery, ListInvoiceAccountsPayableQueryVariables> query={ListInvoiceAccountsPayableDocument} {...props} />
    );
    

/**
 * __useListInvoiceAccountsPayableQuery__
 *
 * To run a query within a React component, call `useListInvoiceAccountsPayableQuery` and pass it any options that fit your needs.
 * When your component renders, `useListInvoiceAccountsPayableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListInvoiceAccountsPayableQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      sorting: // value for 'sorting'
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useListInvoiceAccountsPayableQuery(baseOptions: Apollo.QueryHookOptions<ListInvoiceAccountsPayableQuery, ListInvoiceAccountsPayableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListInvoiceAccountsPayableQuery, ListInvoiceAccountsPayableQueryVariables>(ListInvoiceAccountsPayableDocument, options);
      }
export function useListInvoiceAccountsPayableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListInvoiceAccountsPayableQuery, ListInvoiceAccountsPayableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListInvoiceAccountsPayableQuery, ListInvoiceAccountsPayableQueryVariables>(ListInvoiceAccountsPayableDocument, options);
        }
export type ListInvoiceAccountsPayableQueryHookResult = ReturnType<typeof useListInvoiceAccountsPayableQuery>;
export type ListInvoiceAccountsPayableLazyQueryHookResult = ReturnType<typeof useListInvoiceAccountsPayableLazyQuery>;
export type ListInvoiceAccountsPayableQueryResult = Apollo.QueryResult<ListInvoiceAccountsPayableQuery, ListInvoiceAccountsPayableQueryVariables>;
export const GetInvoiceAccountsPayableDocument = gql`
    query getInvoiceAccountsPayable($businessId: ID!, $invoiceId: ID!) {
  getInvoiceAccountsPayable(businessId: $businessId, invoiceId: $invoiceId) {
    invoice {
      amount
      businessId
      id
      invoiceDate
      createdDate
      invoiceNumber
      createdVia
      createdDate
      isArchived
      isMarkedAsPaid
      description
      attachmentId
      contactId
      dueDate
      markedAsPaidReason
      paidAmount
      scheduledAmount
      pendingAmount
      remainingAmount
      paidDate
      paymentStatus
      status
      updatedDate
      vendorName
      transactions {
        invoiceId
        transactionId
        status
        accountId
        paymentMethod
        amount
        scheduledDate
        scheduledPaymentId
        lastUpdated
        status
        subAccount {
          id
        }
      }
    }
  }
}
    `;
export type GetInvoiceAccountsPayableComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetInvoiceAccountsPayableQuery, GetInvoiceAccountsPayableQueryVariables>, 'query'> & ({ variables: GetInvoiceAccountsPayableQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetInvoiceAccountsPayableComponent = (props: GetInvoiceAccountsPayableComponentProps) => (
      <ApolloReactComponents.Query<GetInvoiceAccountsPayableQuery, GetInvoiceAccountsPayableQueryVariables> query={GetInvoiceAccountsPayableDocument} {...props} />
    );
    

/**
 * __useGetInvoiceAccountsPayableQuery__
 *
 * To run a query within a React component, call `useGetInvoiceAccountsPayableQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoiceAccountsPayableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoiceAccountsPayableQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      invoiceId: // value for 'invoiceId'
 *   },
 * });
 */
export function useGetInvoiceAccountsPayableQuery(baseOptions: Apollo.QueryHookOptions<GetInvoiceAccountsPayableQuery, GetInvoiceAccountsPayableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInvoiceAccountsPayableQuery, GetInvoiceAccountsPayableQueryVariables>(GetInvoiceAccountsPayableDocument, options);
      }
export function useGetInvoiceAccountsPayableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvoiceAccountsPayableQuery, GetInvoiceAccountsPayableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInvoiceAccountsPayableQuery, GetInvoiceAccountsPayableQueryVariables>(GetInvoiceAccountsPayableDocument, options);
        }
export type GetInvoiceAccountsPayableQueryHookResult = ReturnType<typeof useGetInvoiceAccountsPayableQuery>;
export type GetInvoiceAccountsPayableLazyQueryHookResult = ReturnType<typeof useGetInvoiceAccountsPayableLazyQuery>;
export type GetInvoiceAccountsPayableQueryResult = Apollo.QueryResult<GetInvoiceAccountsPayableQuery, GetInvoiceAccountsPayableQueryVariables>;
export const GetInvoiceAccountsPayableWithoutTransactionsDocument = gql`
    query getInvoiceAccountsPayableWithoutTransactions($businessId: ID!, $invoiceId: ID!) {
  getInvoiceAccountsPayable(businessId: $businessId, invoiceId: $invoiceId) {
    invoice {
      amount
      businessId
      id
      invoiceDate
      createdDate
      invoiceNumber
      createdVia
      createdDate
      isArchived
      isMarkedAsPaid
      description
      attachmentId
      contactId
      dueDate
      markedAsPaidReason
      paidAmount
      scheduledAmount
      pendingAmount
      remainingAmount
      paidDate
      paymentStatus
      status
      updatedDate
      vendorName
    }
  }
}
    `;
export type GetInvoiceAccountsPayableWithoutTransactionsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetInvoiceAccountsPayableWithoutTransactionsQuery, GetInvoiceAccountsPayableWithoutTransactionsQueryVariables>, 'query'> & ({ variables: GetInvoiceAccountsPayableWithoutTransactionsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetInvoiceAccountsPayableWithoutTransactionsComponent = (props: GetInvoiceAccountsPayableWithoutTransactionsComponentProps) => (
      <ApolloReactComponents.Query<GetInvoiceAccountsPayableWithoutTransactionsQuery, GetInvoiceAccountsPayableWithoutTransactionsQueryVariables> query={GetInvoiceAccountsPayableWithoutTransactionsDocument} {...props} />
    );
    

/**
 * __useGetInvoiceAccountsPayableWithoutTransactionsQuery__
 *
 * To run a query within a React component, call `useGetInvoiceAccountsPayableWithoutTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoiceAccountsPayableWithoutTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoiceAccountsPayableWithoutTransactionsQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      invoiceId: // value for 'invoiceId'
 *   },
 * });
 */
export function useGetInvoiceAccountsPayableWithoutTransactionsQuery(baseOptions: Apollo.QueryHookOptions<GetInvoiceAccountsPayableWithoutTransactionsQuery, GetInvoiceAccountsPayableWithoutTransactionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInvoiceAccountsPayableWithoutTransactionsQuery, GetInvoiceAccountsPayableWithoutTransactionsQueryVariables>(GetInvoiceAccountsPayableWithoutTransactionsDocument, options);
      }
export function useGetInvoiceAccountsPayableWithoutTransactionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvoiceAccountsPayableWithoutTransactionsQuery, GetInvoiceAccountsPayableWithoutTransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInvoiceAccountsPayableWithoutTransactionsQuery, GetInvoiceAccountsPayableWithoutTransactionsQueryVariables>(GetInvoiceAccountsPayableWithoutTransactionsDocument, options);
        }
export type GetInvoiceAccountsPayableWithoutTransactionsQueryHookResult = ReturnType<typeof useGetInvoiceAccountsPayableWithoutTransactionsQuery>;
export type GetInvoiceAccountsPayableWithoutTransactionsLazyQueryHookResult = ReturnType<typeof useGetInvoiceAccountsPayableWithoutTransactionsLazyQuery>;
export type GetInvoiceAccountsPayableWithoutTransactionsQueryResult = Apollo.QueryResult<GetInvoiceAccountsPayableWithoutTransactionsQuery, GetInvoiceAccountsPayableWithoutTransactionsQueryVariables>;
export const InvoicePaymentsContactDocument = gql`
    query invoicePaymentsContact($businessId: ID!, $contactId: ID!) {
  business(businessId: $businessId) {
    id
    contact(contactId: $contactId) {
      address {
        streetAddressLine1
        streetAddressLine2
        city
        provinceState
        country
        postalCode
      }
      id
      businessId
      name
      relationship
      achPaymentDetails {
        accountNumber
        routingNumber
      }
      wirePaymentDetails {
        accountNumber
        routingNumber
        bankName
      }
      mailedCheckPaymentDetails {
        enabled
      }
      billPayPaymentDetails {
        accountNumber
      }
      rppsBillerDetails {
        rppsBillerId
        billerName
      }
    }
  }
}
    `;
export type InvoicePaymentsContactComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<InvoicePaymentsContactQuery, InvoicePaymentsContactQueryVariables>, 'query'> & ({ variables: InvoicePaymentsContactQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const InvoicePaymentsContactComponent = (props: InvoicePaymentsContactComponentProps) => (
      <ApolloReactComponents.Query<InvoicePaymentsContactQuery, InvoicePaymentsContactQueryVariables> query={InvoicePaymentsContactDocument} {...props} />
    );
    

/**
 * __useInvoicePaymentsContactQuery__
 *
 * To run a query within a React component, call `useInvoicePaymentsContactQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoicePaymentsContactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoicePaymentsContactQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      contactId: // value for 'contactId'
 *   },
 * });
 */
export function useInvoicePaymentsContactQuery(baseOptions: Apollo.QueryHookOptions<InvoicePaymentsContactQuery, InvoicePaymentsContactQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InvoicePaymentsContactQuery, InvoicePaymentsContactQueryVariables>(InvoicePaymentsContactDocument, options);
      }
export function useInvoicePaymentsContactLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvoicePaymentsContactQuery, InvoicePaymentsContactQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InvoicePaymentsContactQuery, InvoicePaymentsContactQueryVariables>(InvoicePaymentsContactDocument, options);
        }
export type InvoicePaymentsContactQueryHookResult = ReturnType<typeof useInvoicePaymentsContactQuery>;
export type InvoicePaymentsContactLazyQueryHookResult = ReturnType<typeof useInvoicePaymentsContactLazyQuery>;
export type InvoicePaymentsContactQueryResult = Apollo.QueryResult<InvoicePaymentsContactQuery, InvoicePaymentsContactQueryVariables>;
export const InvoicePaymentsContactListDocument = gql`
    query invoicePaymentsContactList($businessId: ID!) {
  business(businessId: $businessId) {
    contacts {
      id
      businessId
      name
      email
      achPaymentDetails {
        routingNumber
        accountNumber
      }
      wirePaymentDetails {
        routingNumber
        accountNumber
      }
      mailedCheckPaymentDetails {
        enabled
      }
      billPayPaymentDetails {
        accountNumber
      }
      rppsBillerDetails {
        rppsBillerId
        billerName
      }
    }
  }
}
    `;
export type InvoicePaymentsContactListComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<InvoicePaymentsContactListQuery, InvoicePaymentsContactListQueryVariables>, 'query'> & ({ variables: InvoicePaymentsContactListQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const InvoicePaymentsContactListComponent = (props: InvoicePaymentsContactListComponentProps) => (
      <ApolloReactComponents.Query<InvoicePaymentsContactListQuery, InvoicePaymentsContactListQueryVariables> query={InvoicePaymentsContactListDocument} {...props} />
    );
    

/**
 * __useInvoicePaymentsContactListQuery__
 *
 * To run a query within a React component, call `useInvoicePaymentsContactListQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoicePaymentsContactListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoicePaymentsContactListQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function useInvoicePaymentsContactListQuery(baseOptions: Apollo.QueryHookOptions<InvoicePaymentsContactListQuery, InvoicePaymentsContactListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InvoicePaymentsContactListQuery, InvoicePaymentsContactListQueryVariables>(InvoicePaymentsContactListDocument, options);
      }
export function useInvoicePaymentsContactListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvoicePaymentsContactListQuery, InvoicePaymentsContactListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InvoicePaymentsContactListQuery, InvoicePaymentsContactListQueryVariables>(InvoicePaymentsContactListDocument, options);
        }
export type InvoicePaymentsContactListQueryHookResult = ReturnType<typeof useInvoicePaymentsContactListQuery>;
export type InvoicePaymentsContactListLazyQueryHookResult = ReturnType<typeof useInvoicePaymentsContactListLazyQuery>;
export type InvoicePaymentsContactListQueryResult = Apollo.QueryResult<InvoicePaymentsContactListQuery, InvoicePaymentsContactListQueryVariables>;
export const SaveInvoiceAccountsPayableDocument = gql`
    mutation saveInvoiceAccountsPayable($businessId: ID!, $invoiceData: InvoiceAccountsPayableDataInput!) {
  saveInvoiceAccountsPayable(businessId: $businessId, invoiceData: $invoiceData) {
    success
    code
    message
    invoiceId
  }
}
    `;
export type SaveInvoiceAccountsPayableMutationFn = Apollo.MutationFunction<SaveInvoiceAccountsPayableMutation, SaveInvoiceAccountsPayableMutationVariables>;
export type SaveInvoiceAccountsPayableComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SaveInvoiceAccountsPayableMutation, SaveInvoiceAccountsPayableMutationVariables>, 'mutation'>;

    export const SaveInvoiceAccountsPayableComponent = (props: SaveInvoiceAccountsPayableComponentProps) => (
      <ApolloReactComponents.Mutation<SaveInvoiceAccountsPayableMutation, SaveInvoiceAccountsPayableMutationVariables> mutation={SaveInvoiceAccountsPayableDocument} {...props} />
    );
    

/**
 * __useSaveInvoiceAccountsPayableMutation__
 *
 * To run a mutation, you first call `useSaveInvoiceAccountsPayableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveInvoiceAccountsPayableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveInvoiceAccountsPayableMutation, { data, loading, error }] = useSaveInvoiceAccountsPayableMutation({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      invoiceData: // value for 'invoiceData'
 *   },
 * });
 */
export function useSaveInvoiceAccountsPayableMutation(baseOptions?: Apollo.MutationHookOptions<SaveInvoiceAccountsPayableMutation, SaveInvoiceAccountsPayableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveInvoiceAccountsPayableMutation, SaveInvoiceAccountsPayableMutationVariables>(SaveInvoiceAccountsPayableDocument, options);
      }
export type SaveInvoiceAccountsPayableMutationHookResult = ReturnType<typeof useSaveInvoiceAccountsPayableMutation>;
export type SaveInvoiceAccountsPayableMutationResult = Apollo.MutationResult<SaveInvoiceAccountsPayableMutation>;
export type SaveInvoiceAccountsPayableMutationOptions = Apollo.BaseMutationOptions<SaveInvoiceAccountsPayableMutation, SaveInvoiceAccountsPayableMutationVariables>;
export const MarkInvoiceAccountsPayableAsPaidDocument = gql`
    mutation markInvoiceAccountsPayableAsPaid($businessId: ID!, $invoiceId: ID!, $reason: String) {
  markInvoiceAccountsPayableAsPaid(
    businessId: $businessId
    invoiceId: $invoiceId
    reason: $reason
  ) {
    success
    code
    message
  }
}
    `;
export type MarkInvoiceAccountsPayableAsPaidMutationFn = Apollo.MutationFunction<MarkInvoiceAccountsPayableAsPaidMutation, MarkInvoiceAccountsPayableAsPaidMutationVariables>;
export type MarkInvoiceAccountsPayableAsPaidComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<MarkInvoiceAccountsPayableAsPaidMutation, MarkInvoiceAccountsPayableAsPaidMutationVariables>, 'mutation'>;

    export const MarkInvoiceAccountsPayableAsPaidComponent = (props: MarkInvoiceAccountsPayableAsPaidComponentProps) => (
      <ApolloReactComponents.Mutation<MarkInvoiceAccountsPayableAsPaidMutation, MarkInvoiceAccountsPayableAsPaidMutationVariables> mutation={MarkInvoiceAccountsPayableAsPaidDocument} {...props} />
    );
    

/**
 * __useMarkInvoiceAccountsPayableAsPaidMutation__
 *
 * To run a mutation, you first call `useMarkInvoiceAccountsPayableAsPaidMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkInvoiceAccountsPayableAsPaidMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markInvoiceAccountsPayableAsPaidMutation, { data, loading, error }] = useMarkInvoiceAccountsPayableAsPaidMutation({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      invoiceId: // value for 'invoiceId'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useMarkInvoiceAccountsPayableAsPaidMutation(baseOptions?: Apollo.MutationHookOptions<MarkInvoiceAccountsPayableAsPaidMutation, MarkInvoiceAccountsPayableAsPaidMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkInvoiceAccountsPayableAsPaidMutation, MarkInvoiceAccountsPayableAsPaidMutationVariables>(MarkInvoiceAccountsPayableAsPaidDocument, options);
      }
export type MarkInvoiceAccountsPayableAsPaidMutationHookResult = ReturnType<typeof useMarkInvoiceAccountsPayableAsPaidMutation>;
export type MarkInvoiceAccountsPayableAsPaidMutationResult = Apollo.MutationResult<MarkInvoiceAccountsPayableAsPaidMutation>;
export type MarkInvoiceAccountsPayableAsPaidMutationOptions = Apollo.BaseMutationOptions<MarkInvoiceAccountsPayableAsPaidMutation, MarkInvoiceAccountsPayableAsPaidMutationVariables>;
export const MarkInvoiceAccountsPayableAsUnpaidDocument = gql`
    mutation markInvoiceAccountsPayableAsUnpaid($businessId: ID!, $invoiceId: ID!) {
  markInvoiceAccountsPayableAsUnpaid(
    businessId: $businessId
    invoiceId: $invoiceId
  ) {
    success
    code
    message
  }
}
    `;
export type MarkInvoiceAccountsPayableAsUnpaidMutationFn = Apollo.MutationFunction<MarkInvoiceAccountsPayableAsUnpaidMutation, MarkInvoiceAccountsPayableAsUnpaidMutationVariables>;
export type MarkInvoiceAccountsPayableAsUnpaidComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<MarkInvoiceAccountsPayableAsUnpaidMutation, MarkInvoiceAccountsPayableAsUnpaidMutationVariables>, 'mutation'>;

    export const MarkInvoiceAccountsPayableAsUnpaidComponent = (props: MarkInvoiceAccountsPayableAsUnpaidComponentProps) => (
      <ApolloReactComponents.Mutation<MarkInvoiceAccountsPayableAsUnpaidMutation, MarkInvoiceAccountsPayableAsUnpaidMutationVariables> mutation={MarkInvoiceAccountsPayableAsUnpaidDocument} {...props} />
    );
    

/**
 * __useMarkInvoiceAccountsPayableAsUnpaidMutation__
 *
 * To run a mutation, you first call `useMarkInvoiceAccountsPayableAsUnpaidMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkInvoiceAccountsPayableAsUnpaidMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markInvoiceAccountsPayableAsUnpaidMutation, { data, loading, error }] = useMarkInvoiceAccountsPayableAsUnpaidMutation({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      invoiceId: // value for 'invoiceId'
 *   },
 * });
 */
export function useMarkInvoiceAccountsPayableAsUnpaidMutation(baseOptions?: Apollo.MutationHookOptions<MarkInvoiceAccountsPayableAsUnpaidMutation, MarkInvoiceAccountsPayableAsUnpaidMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkInvoiceAccountsPayableAsUnpaidMutation, MarkInvoiceAccountsPayableAsUnpaidMutationVariables>(MarkInvoiceAccountsPayableAsUnpaidDocument, options);
      }
export type MarkInvoiceAccountsPayableAsUnpaidMutationHookResult = ReturnType<typeof useMarkInvoiceAccountsPayableAsUnpaidMutation>;
export type MarkInvoiceAccountsPayableAsUnpaidMutationResult = Apollo.MutationResult<MarkInvoiceAccountsPayableAsUnpaidMutation>;
export type MarkInvoiceAccountsPayableAsUnpaidMutationOptions = Apollo.BaseMutationOptions<MarkInvoiceAccountsPayableAsUnpaidMutation, MarkInvoiceAccountsPayableAsUnpaidMutationVariables>;
export const MarkInvoiceAccountsPayableAsArchivedDocument = gql`
    mutation markInvoiceAccountsPayableAsArchived($businessId: ID!, $invoiceId: ID!) {
  markInvoiceAccountsPayableAsArchived(
    businessId: $businessId
    invoiceId: $invoiceId
  ) {
    success
    code
    message
  }
}
    `;
export type MarkInvoiceAccountsPayableAsArchivedMutationFn = Apollo.MutationFunction<MarkInvoiceAccountsPayableAsArchivedMutation, MarkInvoiceAccountsPayableAsArchivedMutationVariables>;
export type MarkInvoiceAccountsPayableAsArchivedComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<MarkInvoiceAccountsPayableAsArchivedMutation, MarkInvoiceAccountsPayableAsArchivedMutationVariables>, 'mutation'>;

    export const MarkInvoiceAccountsPayableAsArchivedComponent = (props: MarkInvoiceAccountsPayableAsArchivedComponentProps) => (
      <ApolloReactComponents.Mutation<MarkInvoiceAccountsPayableAsArchivedMutation, MarkInvoiceAccountsPayableAsArchivedMutationVariables> mutation={MarkInvoiceAccountsPayableAsArchivedDocument} {...props} />
    );
    

/**
 * __useMarkInvoiceAccountsPayableAsArchivedMutation__
 *
 * To run a mutation, you first call `useMarkInvoiceAccountsPayableAsArchivedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkInvoiceAccountsPayableAsArchivedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markInvoiceAccountsPayableAsArchivedMutation, { data, loading, error }] = useMarkInvoiceAccountsPayableAsArchivedMutation({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      invoiceId: // value for 'invoiceId'
 *   },
 * });
 */
export function useMarkInvoiceAccountsPayableAsArchivedMutation(baseOptions?: Apollo.MutationHookOptions<MarkInvoiceAccountsPayableAsArchivedMutation, MarkInvoiceAccountsPayableAsArchivedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkInvoiceAccountsPayableAsArchivedMutation, MarkInvoiceAccountsPayableAsArchivedMutationVariables>(MarkInvoiceAccountsPayableAsArchivedDocument, options);
      }
export type MarkInvoiceAccountsPayableAsArchivedMutationHookResult = ReturnType<typeof useMarkInvoiceAccountsPayableAsArchivedMutation>;
export type MarkInvoiceAccountsPayableAsArchivedMutationResult = Apollo.MutationResult<MarkInvoiceAccountsPayableAsArchivedMutation>;
export type MarkInvoiceAccountsPayableAsArchivedMutationOptions = Apollo.BaseMutationOptions<MarkInvoiceAccountsPayableAsArchivedMutation, MarkInvoiceAccountsPayableAsArchivedMutationVariables>;
export const MarkInvoiceAccountsPayableAsUnarchivedDocument = gql`
    mutation markInvoiceAccountsPayableAsUnarchived($businessId: ID!, $invoiceId: ID!) {
  markInvoiceAccountsPayableAsUnarchived(
    businessId: $businessId
    invoiceId: $invoiceId
  ) {
    success
    code
    message
  }
}
    `;
export type MarkInvoiceAccountsPayableAsUnarchivedMutationFn = Apollo.MutationFunction<MarkInvoiceAccountsPayableAsUnarchivedMutation, MarkInvoiceAccountsPayableAsUnarchivedMutationVariables>;
export type MarkInvoiceAccountsPayableAsUnarchivedComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<MarkInvoiceAccountsPayableAsUnarchivedMutation, MarkInvoiceAccountsPayableAsUnarchivedMutationVariables>, 'mutation'>;

    export const MarkInvoiceAccountsPayableAsUnarchivedComponent = (props: MarkInvoiceAccountsPayableAsUnarchivedComponentProps) => (
      <ApolloReactComponents.Mutation<MarkInvoiceAccountsPayableAsUnarchivedMutation, MarkInvoiceAccountsPayableAsUnarchivedMutationVariables> mutation={MarkInvoiceAccountsPayableAsUnarchivedDocument} {...props} />
    );
    

/**
 * __useMarkInvoiceAccountsPayableAsUnarchivedMutation__
 *
 * To run a mutation, you first call `useMarkInvoiceAccountsPayableAsUnarchivedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkInvoiceAccountsPayableAsUnarchivedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markInvoiceAccountsPayableAsUnarchivedMutation, { data, loading, error }] = useMarkInvoiceAccountsPayableAsUnarchivedMutation({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      invoiceId: // value for 'invoiceId'
 *   },
 * });
 */
export function useMarkInvoiceAccountsPayableAsUnarchivedMutation(baseOptions?: Apollo.MutationHookOptions<MarkInvoiceAccountsPayableAsUnarchivedMutation, MarkInvoiceAccountsPayableAsUnarchivedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkInvoiceAccountsPayableAsUnarchivedMutation, MarkInvoiceAccountsPayableAsUnarchivedMutationVariables>(MarkInvoiceAccountsPayableAsUnarchivedDocument, options);
      }
export type MarkInvoiceAccountsPayableAsUnarchivedMutationHookResult = ReturnType<typeof useMarkInvoiceAccountsPayableAsUnarchivedMutation>;
export type MarkInvoiceAccountsPayableAsUnarchivedMutationResult = Apollo.MutationResult<MarkInvoiceAccountsPayableAsUnarchivedMutation>;
export type MarkInvoiceAccountsPayableAsUnarchivedMutationOptions = Apollo.BaseMutationOptions<MarkInvoiceAccountsPayableAsUnarchivedMutation, MarkInvoiceAccountsPayableAsUnarchivedMutationVariables>;
export const UpdateInvoiceContactAchPaymentDetailsDocument = gql`
    mutation updateInvoiceContactAchPaymentDetails($data: ContactDomesticAchUpdateDataInput!) {
  contactDomesticAchUpdate(data: $data) {
    code
    success
  }
}
    `;
export type UpdateInvoiceContactAchPaymentDetailsMutationFn = Apollo.MutationFunction<UpdateInvoiceContactAchPaymentDetailsMutation, UpdateInvoiceContactAchPaymentDetailsMutationVariables>;
export type UpdateInvoiceContactAchPaymentDetailsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateInvoiceContactAchPaymentDetailsMutation, UpdateInvoiceContactAchPaymentDetailsMutationVariables>, 'mutation'>;

    export const UpdateInvoiceContactAchPaymentDetailsComponent = (props: UpdateInvoiceContactAchPaymentDetailsComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateInvoiceContactAchPaymentDetailsMutation, UpdateInvoiceContactAchPaymentDetailsMutationVariables> mutation={UpdateInvoiceContactAchPaymentDetailsDocument} {...props} />
    );
    

/**
 * __useUpdateInvoiceContactAchPaymentDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateInvoiceContactAchPaymentDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvoiceContactAchPaymentDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInvoiceContactAchPaymentDetailsMutation, { data, loading, error }] = useUpdateInvoiceContactAchPaymentDetailsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateInvoiceContactAchPaymentDetailsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInvoiceContactAchPaymentDetailsMutation, UpdateInvoiceContactAchPaymentDetailsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInvoiceContactAchPaymentDetailsMutation, UpdateInvoiceContactAchPaymentDetailsMutationVariables>(UpdateInvoiceContactAchPaymentDetailsDocument, options);
      }
export type UpdateInvoiceContactAchPaymentDetailsMutationHookResult = ReturnType<typeof useUpdateInvoiceContactAchPaymentDetailsMutation>;
export type UpdateInvoiceContactAchPaymentDetailsMutationResult = Apollo.MutationResult<UpdateInvoiceContactAchPaymentDetailsMutation>;
export type UpdateInvoiceContactAchPaymentDetailsMutationOptions = Apollo.BaseMutationOptions<UpdateInvoiceContactAchPaymentDetailsMutation, UpdateInvoiceContactAchPaymentDetailsMutationVariables>;
export const UpdateInvoiceContactMailedCheckPaymentDetailsDocument = gql`
    mutation updateInvoiceContactMailedCheckPaymentDetails($data: ContactMailedCheckUpdateDataInput!) {
  contactMailedCheckUpdate(data: $data) {
    code
    success
    message
  }
}
    `;
export type UpdateInvoiceContactMailedCheckPaymentDetailsMutationFn = Apollo.MutationFunction<UpdateInvoiceContactMailedCheckPaymentDetailsMutation, UpdateInvoiceContactMailedCheckPaymentDetailsMutationVariables>;
export type UpdateInvoiceContactMailedCheckPaymentDetailsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateInvoiceContactMailedCheckPaymentDetailsMutation, UpdateInvoiceContactMailedCheckPaymentDetailsMutationVariables>, 'mutation'>;

    export const UpdateInvoiceContactMailedCheckPaymentDetailsComponent = (props: UpdateInvoiceContactMailedCheckPaymentDetailsComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateInvoiceContactMailedCheckPaymentDetailsMutation, UpdateInvoiceContactMailedCheckPaymentDetailsMutationVariables> mutation={UpdateInvoiceContactMailedCheckPaymentDetailsDocument} {...props} />
    );
    

/**
 * __useUpdateInvoiceContactMailedCheckPaymentDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateInvoiceContactMailedCheckPaymentDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvoiceContactMailedCheckPaymentDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInvoiceContactMailedCheckPaymentDetailsMutation, { data, loading, error }] = useUpdateInvoiceContactMailedCheckPaymentDetailsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateInvoiceContactMailedCheckPaymentDetailsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInvoiceContactMailedCheckPaymentDetailsMutation, UpdateInvoiceContactMailedCheckPaymentDetailsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInvoiceContactMailedCheckPaymentDetailsMutation, UpdateInvoiceContactMailedCheckPaymentDetailsMutationVariables>(UpdateInvoiceContactMailedCheckPaymentDetailsDocument, options);
      }
export type UpdateInvoiceContactMailedCheckPaymentDetailsMutationHookResult = ReturnType<typeof useUpdateInvoiceContactMailedCheckPaymentDetailsMutation>;
export type UpdateInvoiceContactMailedCheckPaymentDetailsMutationResult = Apollo.MutationResult<UpdateInvoiceContactMailedCheckPaymentDetailsMutation>;
export type UpdateInvoiceContactMailedCheckPaymentDetailsMutationOptions = Apollo.BaseMutationOptions<UpdateInvoiceContactMailedCheckPaymentDetailsMutation, UpdateInvoiceContactMailedCheckPaymentDetailsMutationVariables>;
export const UpdateInvoiceContactWirePaymentDetailsDocument = gql`
    mutation updateInvoiceContactWirePaymentDetails($data: ContactDomesticWireUpdateDataInput!) {
  contactDomesticWireUpdate(data: $data) {
    code
    success
  }
}
    `;
export type UpdateInvoiceContactWirePaymentDetailsMutationFn = Apollo.MutationFunction<UpdateInvoiceContactWirePaymentDetailsMutation, UpdateInvoiceContactWirePaymentDetailsMutationVariables>;
export type UpdateInvoiceContactWirePaymentDetailsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateInvoiceContactWirePaymentDetailsMutation, UpdateInvoiceContactWirePaymentDetailsMutationVariables>, 'mutation'>;

    export const UpdateInvoiceContactWirePaymentDetailsComponent = (props: UpdateInvoiceContactWirePaymentDetailsComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateInvoiceContactWirePaymentDetailsMutation, UpdateInvoiceContactWirePaymentDetailsMutationVariables> mutation={UpdateInvoiceContactWirePaymentDetailsDocument} {...props} />
    );
    

/**
 * __useUpdateInvoiceContactWirePaymentDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateInvoiceContactWirePaymentDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvoiceContactWirePaymentDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInvoiceContactWirePaymentDetailsMutation, { data, loading, error }] = useUpdateInvoiceContactWirePaymentDetailsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateInvoiceContactWirePaymentDetailsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInvoiceContactWirePaymentDetailsMutation, UpdateInvoiceContactWirePaymentDetailsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInvoiceContactWirePaymentDetailsMutation, UpdateInvoiceContactWirePaymentDetailsMutationVariables>(UpdateInvoiceContactWirePaymentDetailsDocument, options);
      }
export type UpdateInvoiceContactWirePaymentDetailsMutationHookResult = ReturnType<typeof useUpdateInvoiceContactWirePaymentDetailsMutation>;
export type UpdateInvoiceContactWirePaymentDetailsMutationResult = Apollo.MutationResult<UpdateInvoiceContactWirePaymentDetailsMutation>;
export type UpdateInvoiceContactWirePaymentDetailsMutationOptions = Apollo.BaseMutationOptions<UpdateInvoiceContactWirePaymentDetailsMutation, UpdateInvoiceContactWirePaymentDetailsMutationVariables>;
export const ContactBillPayPaymentDetailsCreateDocument = gql`
    mutation ContactBillPayPaymentDetailsCreate($data: ContactBillPayPaymentDetailsCreateInput!) {
  contactBillPayPaymentDetailsCreate(data: $data) {
    code
    success
  }
}
    `;
export type ContactBillPayPaymentDetailsCreateMutationFn = Apollo.MutationFunction<ContactBillPayPaymentDetailsCreateMutation, ContactBillPayPaymentDetailsCreateMutationVariables>;
export type ContactBillPayPaymentDetailsCreateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ContactBillPayPaymentDetailsCreateMutation, ContactBillPayPaymentDetailsCreateMutationVariables>, 'mutation'>;

    export const ContactBillPayPaymentDetailsCreateComponent = (props: ContactBillPayPaymentDetailsCreateComponentProps) => (
      <ApolloReactComponents.Mutation<ContactBillPayPaymentDetailsCreateMutation, ContactBillPayPaymentDetailsCreateMutationVariables> mutation={ContactBillPayPaymentDetailsCreateDocument} {...props} />
    );
    

/**
 * __useContactBillPayPaymentDetailsCreateMutation__
 *
 * To run a mutation, you first call `useContactBillPayPaymentDetailsCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContactBillPayPaymentDetailsCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contactBillPayPaymentDetailsCreateMutation, { data, loading, error }] = useContactBillPayPaymentDetailsCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useContactBillPayPaymentDetailsCreateMutation(baseOptions?: Apollo.MutationHookOptions<ContactBillPayPaymentDetailsCreateMutation, ContactBillPayPaymentDetailsCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ContactBillPayPaymentDetailsCreateMutation, ContactBillPayPaymentDetailsCreateMutationVariables>(ContactBillPayPaymentDetailsCreateDocument, options);
      }
export type ContactBillPayPaymentDetailsCreateMutationHookResult = ReturnType<typeof useContactBillPayPaymentDetailsCreateMutation>;
export type ContactBillPayPaymentDetailsCreateMutationResult = Apollo.MutationResult<ContactBillPayPaymentDetailsCreateMutation>;
export type ContactBillPayPaymentDetailsCreateMutationOptions = Apollo.BaseMutationOptions<ContactBillPayPaymentDetailsCreateMutation, ContactBillPayPaymentDetailsCreateMutationVariables>;
export const UpdateInvoiceContactBillPayPaymentDetailsDocument = gql`
    mutation updateInvoiceContactBillPayPaymentDetails($data: ContactBillPayPaymentDetailsUpdateInput!) {
  contactBillPayPaymentDetailsUpdate(data: $data) {
    code
    success
  }
}
    `;
export type UpdateInvoiceContactBillPayPaymentDetailsMutationFn = Apollo.MutationFunction<UpdateInvoiceContactBillPayPaymentDetailsMutation, UpdateInvoiceContactBillPayPaymentDetailsMutationVariables>;
export type UpdateInvoiceContactBillPayPaymentDetailsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateInvoiceContactBillPayPaymentDetailsMutation, UpdateInvoiceContactBillPayPaymentDetailsMutationVariables>, 'mutation'>;

    export const UpdateInvoiceContactBillPayPaymentDetailsComponent = (props: UpdateInvoiceContactBillPayPaymentDetailsComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateInvoiceContactBillPayPaymentDetailsMutation, UpdateInvoiceContactBillPayPaymentDetailsMutationVariables> mutation={UpdateInvoiceContactBillPayPaymentDetailsDocument} {...props} />
    );
    

/**
 * __useUpdateInvoiceContactBillPayPaymentDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateInvoiceContactBillPayPaymentDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvoiceContactBillPayPaymentDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInvoiceContactBillPayPaymentDetailsMutation, { data, loading, error }] = useUpdateInvoiceContactBillPayPaymentDetailsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateInvoiceContactBillPayPaymentDetailsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInvoiceContactBillPayPaymentDetailsMutation, UpdateInvoiceContactBillPayPaymentDetailsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInvoiceContactBillPayPaymentDetailsMutation, UpdateInvoiceContactBillPayPaymentDetailsMutationVariables>(UpdateInvoiceContactBillPayPaymentDetailsDocument, options);
      }
export type UpdateInvoiceContactBillPayPaymentDetailsMutationHookResult = ReturnType<typeof useUpdateInvoiceContactBillPayPaymentDetailsMutation>;
export type UpdateInvoiceContactBillPayPaymentDetailsMutationResult = Apollo.MutationResult<UpdateInvoiceContactBillPayPaymentDetailsMutation>;
export type UpdateInvoiceContactBillPayPaymentDetailsMutationOptions = Apollo.BaseMutationOptions<UpdateInvoiceContactBillPayPaymentDetailsMutation, UpdateInvoiceContactBillPayPaymentDetailsMutationVariables>;
export const UpdateInvoiceContactDocument = gql`
    mutation updateInvoiceContact($input: ContactUpdateDataInput!) {
  contactUpdate(input: $input) {
    code
    success
  }
}
    `;
export type UpdateInvoiceContactMutationFn = Apollo.MutationFunction<UpdateInvoiceContactMutation, UpdateInvoiceContactMutationVariables>;
export type UpdateInvoiceContactComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateInvoiceContactMutation, UpdateInvoiceContactMutationVariables>, 'mutation'>;

    export const UpdateInvoiceContactComponent = (props: UpdateInvoiceContactComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateInvoiceContactMutation, UpdateInvoiceContactMutationVariables> mutation={UpdateInvoiceContactDocument} {...props} />
    );
    

/**
 * __useUpdateInvoiceContactMutation__
 *
 * To run a mutation, you first call `useUpdateInvoiceContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvoiceContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInvoiceContactMutation, { data, loading, error }] = useUpdateInvoiceContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInvoiceContactMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInvoiceContactMutation, UpdateInvoiceContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInvoiceContactMutation, UpdateInvoiceContactMutationVariables>(UpdateInvoiceContactDocument, options);
      }
export type UpdateInvoiceContactMutationHookResult = ReturnType<typeof useUpdateInvoiceContactMutation>;
export type UpdateInvoiceContactMutationResult = Apollo.MutationResult<UpdateInvoiceContactMutation>;
export type UpdateInvoiceContactMutationOptions = Apollo.BaseMutationOptions<UpdateInvoiceContactMutation, UpdateInvoiceContactMutationVariables>;
export const CreateInvoiceAccountsPayablePaymentDocument = gql`
    mutation createInvoiceAccountsPayablePayment($businessId: ID!, $invoiceId: ID!, $data: CreateInvoiceAccountsPayablePaymentDataInput!) {
  createInvoiceAccountsPayablePayment(
    businessId: $businessId
    invoiceId: $invoiceId
    data: $data
  ) {
    code
    success
    message
    transactionId
  }
}
    `;
export type CreateInvoiceAccountsPayablePaymentMutationFn = Apollo.MutationFunction<CreateInvoiceAccountsPayablePaymentMutation, CreateInvoiceAccountsPayablePaymentMutationVariables>;
export type CreateInvoiceAccountsPayablePaymentComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateInvoiceAccountsPayablePaymentMutation, CreateInvoiceAccountsPayablePaymentMutationVariables>, 'mutation'>;

    export const CreateInvoiceAccountsPayablePaymentComponent = (props: CreateInvoiceAccountsPayablePaymentComponentProps) => (
      <ApolloReactComponents.Mutation<CreateInvoiceAccountsPayablePaymentMutation, CreateInvoiceAccountsPayablePaymentMutationVariables> mutation={CreateInvoiceAccountsPayablePaymentDocument} {...props} />
    );
    

/**
 * __useCreateInvoiceAccountsPayablePaymentMutation__
 *
 * To run a mutation, you first call `useCreateInvoiceAccountsPayablePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInvoiceAccountsPayablePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInvoiceAccountsPayablePaymentMutation, { data, loading, error }] = useCreateInvoiceAccountsPayablePaymentMutation({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      invoiceId: // value for 'invoiceId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateInvoiceAccountsPayablePaymentMutation(baseOptions?: Apollo.MutationHookOptions<CreateInvoiceAccountsPayablePaymentMutation, CreateInvoiceAccountsPayablePaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateInvoiceAccountsPayablePaymentMutation, CreateInvoiceAccountsPayablePaymentMutationVariables>(CreateInvoiceAccountsPayablePaymentDocument, options);
      }
export type CreateInvoiceAccountsPayablePaymentMutationHookResult = ReturnType<typeof useCreateInvoiceAccountsPayablePaymentMutation>;
export type CreateInvoiceAccountsPayablePaymentMutationResult = Apollo.MutationResult<CreateInvoiceAccountsPayablePaymentMutation>;
export type CreateInvoiceAccountsPayablePaymentMutationOptions = Apollo.BaseMutationOptions<CreateInvoiceAccountsPayablePaymentMutation, CreateInvoiceAccountsPayablePaymentMutationVariables>;
export const CreateInvoiceAccountsPayableScheduledPaymentDocument = gql`
    mutation createInvoiceAccountsPayableScheduledPayment($businessId: ID!, $invoiceId: ID!, $data: CreateInvoiceAccountsPayableScheduledPaymentDataInput!) {
  createInvoiceAccountsPayableScheduledPayment(
    businessId: $businessId
    invoiceId: $invoiceId
    data: $data
  ) {
    code
    success
    message
    scheduledPaymentId
  }
}
    `;
export type CreateInvoiceAccountsPayableScheduledPaymentMutationFn = Apollo.MutationFunction<CreateInvoiceAccountsPayableScheduledPaymentMutation, CreateInvoiceAccountsPayableScheduledPaymentMutationVariables>;
export type CreateInvoiceAccountsPayableScheduledPaymentComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateInvoiceAccountsPayableScheduledPaymentMutation, CreateInvoiceAccountsPayableScheduledPaymentMutationVariables>, 'mutation'>;

    export const CreateInvoiceAccountsPayableScheduledPaymentComponent = (props: CreateInvoiceAccountsPayableScheduledPaymentComponentProps) => (
      <ApolloReactComponents.Mutation<CreateInvoiceAccountsPayableScheduledPaymentMutation, CreateInvoiceAccountsPayableScheduledPaymentMutationVariables> mutation={CreateInvoiceAccountsPayableScheduledPaymentDocument} {...props} />
    );
    

/**
 * __useCreateInvoiceAccountsPayableScheduledPaymentMutation__
 *
 * To run a mutation, you first call `useCreateInvoiceAccountsPayableScheduledPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInvoiceAccountsPayableScheduledPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInvoiceAccountsPayableScheduledPaymentMutation, { data, loading, error }] = useCreateInvoiceAccountsPayableScheduledPaymentMutation({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      invoiceId: // value for 'invoiceId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateInvoiceAccountsPayableScheduledPaymentMutation(baseOptions?: Apollo.MutationHookOptions<CreateInvoiceAccountsPayableScheduledPaymentMutation, CreateInvoiceAccountsPayableScheduledPaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateInvoiceAccountsPayableScheduledPaymentMutation, CreateInvoiceAccountsPayableScheduledPaymentMutationVariables>(CreateInvoiceAccountsPayableScheduledPaymentDocument, options);
      }
export type CreateInvoiceAccountsPayableScheduledPaymentMutationHookResult = ReturnType<typeof useCreateInvoiceAccountsPayableScheduledPaymentMutation>;
export type CreateInvoiceAccountsPayableScheduledPaymentMutationResult = Apollo.MutationResult<CreateInvoiceAccountsPayableScheduledPaymentMutation>;
export type CreateInvoiceAccountsPayableScheduledPaymentMutationOptions = Apollo.BaseMutationOptions<CreateInvoiceAccountsPayableScheduledPaymentMutation, CreateInvoiceAccountsPayableScheduledPaymentMutationVariables>;
export const CreateInvoiceAccountsPayableContactDocument = gql`
    mutation createInvoiceAccountsPayableContact($input: ContactCreateDataInput!) {
  contactCreate(input: $input) {
    code
    success
    message
    contact {
      id
    }
  }
}
    `;
export type CreateInvoiceAccountsPayableContactMutationFn = Apollo.MutationFunction<CreateInvoiceAccountsPayableContactMutation, CreateInvoiceAccountsPayableContactMutationVariables>;
export type CreateInvoiceAccountsPayableContactComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateInvoiceAccountsPayableContactMutation, CreateInvoiceAccountsPayableContactMutationVariables>, 'mutation'>;

    export const CreateInvoiceAccountsPayableContactComponent = (props: CreateInvoiceAccountsPayableContactComponentProps) => (
      <ApolloReactComponents.Mutation<CreateInvoiceAccountsPayableContactMutation, CreateInvoiceAccountsPayableContactMutationVariables> mutation={CreateInvoiceAccountsPayableContactDocument} {...props} />
    );
    

/**
 * __useCreateInvoiceAccountsPayableContactMutation__
 *
 * To run a mutation, you first call `useCreateInvoiceAccountsPayableContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInvoiceAccountsPayableContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInvoiceAccountsPayableContactMutation, { data, loading, error }] = useCreateInvoiceAccountsPayableContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInvoiceAccountsPayableContactMutation(baseOptions?: Apollo.MutationHookOptions<CreateInvoiceAccountsPayableContactMutation, CreateInvoiceAccountsPayableContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateInvoiceAccountsPayableContactMutation, CreateInvoiceAccountsPayableContactMutationVariables>(CreateInvoiceAccountsPayableContactDocument, options);
      }
export type CreateInvoiceAccountsPayableContactMutationHookResult = ReturnType<typeof useCreateInvoiceAccountsPayableContactMutation>;
export type CreateInvoiceAccountsPayableContactMutationResult = Apollo.MutationResult<CreateInvoiceAccountsPayableContactMutation>;
export type CreateInvoiceAccountsPayableContactMutationOptions = Apollo.BaseMutationOptions<CreateInvoiceAccountsPayableContactMutation, CreateInvoiceAccountsPayableContactMutationVariables>;
export const CreateInvoiceAttachmentDocument = gql`
    mutation createInvoiceAttachment($businessId: ID!, $attachmentData: CreateInvoiceAttachmentDataInput!) {
  createInvoiceAttachment(
    businessId: $businessId
    attachmentData: $attachmentData
  ) {
    upload {
      url
      expires
      fields {
        headerKey
        headerValue
      }
    }
    attachmentId
  }
}
    `;
export type CreateInvoiceAttachmentMutationFn = Apollo.MutationFunction<CreateInvoiceAttachmentMutation, CreateInvoiceAttachmentMutationVariables>;
export type CreateInvoiceAttachmentComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateInvoiceAttachmentMutation, CreateInvoiceAttachmentMutationVariables>, 'mutation'>;

    export const CreateInvoiceAttachmentComponent = (props: CreateInvoiceAttachmentComponentProps) => (
      <ApolloReactComponents.Mutation<CreateInvoiceAttachmentMutation, CreateInvoiceAttachmentMutationVariables> mutation={CreateInvoiceAttachmentDocument} {...props} />
    );
    

/**
 * __useCreateInvoiceAttachmentMutation__
 *
 * To run a mutation, you first call `useCreateInvoiceAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInvoiceAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInvoiceAttachmentMutation, { data, loading, error }] = useCreateInvoiceAttachmentMutation({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      attachmentData: // value for 'attachmentData'
 *   },
 * });
 */
export function useCreateInvoiceAttachmentMutation(baseOptions?: Apollo.MutationHookOptions<CreateInvoiceAttachmentMutation, CreateInvoiceAttachmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateInvoiceAttachmentMutation, CreateInvoiceAttachmentMutationVariables>(CreateInvoiceAttachmentDocument, options);
      }
export type CreateInvoiceAttachmentMutationHookResult = ReturnType<typeof useCreateInvoiceAttachmentMutation>;
export type CreateInvoiceAttachmentMutationResult = Apollo.MutationResult<CreateInvoiceAttachmentMutation>;
export type CreateInvoiceAttachmentMutationOptions = Apollo.BaseMutationOptions<CreateInvoiceAttachmentMutation, CreateInvoiceAttachmentMutationVariables>;
export const GetInvoiceAttachmentUrlDocument = gql`
    query getInvoiceAttachmentUrl($businessId: ID!, $attachmentId: ID!) {
  getInvoiceAttachment(businessId: $businessId, attachmentId: $attachmentId) {
    attachmentId
    status
    file {
      url
      expires
      contentType
    }
  }
}
    `;
export type GetInvoiceAttachmentUrlComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetInvoiceAttachmentUrlQuery, GetInvoiceAttachmentUrlQueryVariables>, 'query'> & ({ variables: GetInvoiceAttachmentUrlQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetInvoiceAttachmentUrlComponent = (props: GetInvoiceAttachmentUrlComponentProps) => (
      <ApolloReactComponents.Query<GetInvoiceAttachmentUrlQuery, GetInvoiceAttachmentUrlQueryVariables> query={GetInvoiceAttachmentUrlDocument} {...props} />
    );
    

/**
 * __useGetInvoiceAttachmentUrlQuery__
 *
 * To run a query within a React component, call `useGetInvoiceAttachmentUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoiceAttachmentUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoiceAttachmentUrlQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      attachmentId: // value for 'attachmentId'
 *   },
 * });
 */
export function useGetInvoiceAttachmentUrlQuery(baseOptions: Apollo.QueryHookOptions<GetInvoiceAttachmentUrlQuery, GetInvoiceAttachmentUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInvoiceAttachmentUrlQuery, GetInvoiceAttachmentUrlQueryVariables>(GetInvoiceAttachmentUrlDocument, options);
      }
export function useGetInvoiceAttachmentUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvoiceAttachmentUrlQuery, GetInvoiceAttachmentUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInvoiceAttachmentUrlQuery, GetInvoiceAttachmentUrlQueryVariables>(GetInvoiceAttachmentUrlDocument, options);
        }
export type GetInvoiceAttachmentUrlQueryHookResult = ReturnType<typeof useGetInvoiceAttachmentUrlQuery>;
export type GetInvoiceAttachmentUrlLazyQueryHookResult = ReturnType<typeof useGetInvoiceAttachmentUrlLazyQuery>;
export type GetInvoiceAttachmentUrlQueryResult = Apollo.QueryResult<GetInvoiceAttachmentUrlQuery, GetInvoiceAttachmentUrlQueryVariables>;
export const GetInvoiceAttachmentDataDocument = gql`
    query getInvoiceAttachmentData($businessId: ID!, $attachmentId: ID!) {
  getInvoiceAttachment(businessId: $businessId, attachmentId: $attachmentId) {
    attachmentId
    status
    attachmentData {
      invoiceNumber
      vendorName
      invoiceDate
      paymentDetails {
        routingNumber
        zipCode
        streetAddress
        city
        state
        accountNumber
        personalOrBusinessName
        paymentTerms
      }
      invoiceTotal
      invoiceDueDate
    }
  }
}
    `;
export type GetInvoiceAttachmentDataComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetInvoiceAttachmentDataQuery, GetInvoiceAttachmentDataQueryVariables>, 'query'> & ({ variables: GetInvoiceAttachmentDataQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetInvoiceAttachmentDataComponent = (props: GetInvoiceAttachmentDataComponentProps) => (
      <ApolloReactComponents.Query<GetInvoiceAttachmentDataQuery, GetInvoiceAttachmentDataQueryVariables> query={GetInvoiceAttachmentDataDocument} {...props} />
    );
    

/**
 * __useGetInvoiceAttachmentDataQuery__
 *
 * To run a query within a React component, call `useGetInvoiceAttachmentDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoiceAttachmentDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoiceAttachmentDataQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      attachmentId: // value for 'attachmentId'
 *   },
 * });
 */
export function useGetInvoiceAttachmentDataQuery(baseOptions: Apollo.QueryHookOptions<GetInvoiceAttachmentDataQuery, GetInvoiceAttachmentDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInvoiceAttachmentDataQuery, GetInvoiceAttachmentDataQueryVariables>(GetInvoiceAttachmentDataDocument, options);
      }
export function useGetInvoiceAttachmentDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvoiceAttachmentDataQuery, GetInvoiceAttachmentDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInvoiceAttachmentDataQuery, GetInvoiceAttachmentDataQueryVariables>(GetInvoiceAttachmentDataDocument, options);
        }
export type GetInvoiceAttachmentDataQueryHookResult = ReturnType<typeof useGetInvoiceAttachmentDataQuery>;
export type GetInvoiceAttachmentDataLazyQueryHookResult = ReturnType<typeof useGetInvoiceAttachmentDataLazyQuery>;
export type GetInvoiceAttachmentDataQueryResult = Apollo.QueryResult<GetInvoiceAttachmentDataQuery, GetInvoiceAttachmentDataQueryVariables>;
export const GetInvoiceAccountInfoDocument = gql`
    query getInvoiceAccountInfo($businessId: ID!) {
  business(businessId: $businessId) {
    id
    bankAccount {
      id
      availableBalance
      accountNumber
      subAccounts {
        id
        name
        balance
        isDefault
      }
      capabilities {
        ...capabilitiesWithLimitAndFees
      }
    }
  }
}
    ${CapabilitiesWithLimitAndFeesFragmentDoc}`;
export type GetInvoiceAccountInfoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetInvoiceAccountInfoQuery, GetInvoiceAccountInfoQueryVariables>, 'query'> & ({ variables: GetInvoiceAccountInfoQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetInvoiceAccountInfoComponent = (props: GetInvoiceAccountInfoComponentProps) => (
      <ApolloReactComponents.Query<GetInvoiceAccountInfoQuery, GetInvoiceAccountInfoQueryVariables> query={GetInvoiceAccountInfoDocument} {...props} />
    );
    

/**
 * __useGetInvoiceAccountInfoQuery__
 *
 * To run a query within a React component, call `useGetInvoiceAccountInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoiceAccountInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoiceAccountInfoQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function useGetInvoiceAccountInfoQuery(baseOptions: Apollo.QueryHookOptions<GetInvoiceAccountInfoQuery, GetInvoiceAccountInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInvoiceAccountInfoQuery, GetInvoiceAccountInfoQueryVariables>(GetInvoiceAccountInfoDocument, options);
      }
export function useGetInvoiceAccountInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvoiceAccountInfoQuery, GetInvoiceAccountInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInvoiceAccountInfoQuery, GetInvoiceAccountInfoQueryVariables>(GetInvoiceAccountInfoDocument, options);
        }
export type GetInvoiceAccountInfoQueryHookResult = ReturnType<typeof useGetInvoiceAccountInfoQuery>;
export type GetInvoiceAccountInfoLazyQueryHookResult = ReturnType<typeof useGetInvoiceAccountInfoLazyQuery>;
export type GetInvoiceAccountInfoQueryResult = Apollo.QueryResult<GetInvoiceAccountInfoQuery, GetInvoiceAccountInfoQueryVariables>;
export const UpdateScheduledPaymentDocument = gql`
    mutation updateScheduledPayment($businessId: ID!, $scheduledPaymentId: ID!, $updateScheduledPaymentInput: UpdateScheduledPaymentInput!) {
  updateScheduledPayment(
    businessId: $businessId
    scheduledPaymentId: $scheduledPaymentId
    updateScheduledPaymentInput: $updateScheduledPaymentInput
  ) {
    code
    message
    success
  }
}
    `;
export type UpdateScheduledPaymentMutationFn = Apollo.MutationFunction<UpdateScheduledPaymentMutation, UpdateScheduledPaymentMutationVariables>;
export type UpdateScheduledPaymentComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateScheduledPaymentMutation, UpdateScheduledPaymentMutationVariables>, 'mutation'>;

    export const UpdateScheduledPaymentComponent = (props: UpdateScheduledPaymentComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateScheduledPaymentMutation, UpdateScheduledPaymentMutationVariables> mutation={UpdateScheduledPaymentDocument} {...props} />
    );
    

/**
 * __useUpdateScheduledPaymentMutation__
 *
 * To run a mutation, you first call `useUpdateScheduledPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScheduledPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScheduledPaymentMutation, { data, loading, error }] = useUpdateScheduledPaymentMutation({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      scheduledPaymentId: // value for 'scheduledPaymentId'
 *      updateScheduledPaymentInput: // value for 'updateScheduledPaymentInput'
 *   },
 * });
 */
export function useUpdateScheduledPaymentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateScheduledPaymentMutation, UpdateScheduledPaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateScheduledPaymentMutation, UpdateScheduledPaymentMutationVariables>(UpdateScheduledPaymentDocument, options);
      }
export type UpdateScheduledPaymentMutationHookResult = ReturnType<typeof useUpdateScheduledPaymentMutation>;
export type UpdateScheduledPaymentMutationResult = Apollo.MutationResult<UpdateScheduledPaymentMutation>;
export type UpdateScheduledPaymentMutationOptions = Apollo.BaseMutationOptions<UpdateScheduledPaymentMutation, UpdateScheduledPaymentMutationVariables>;
export const CancelScheduledPaymentDocument = gql`
    mutation cancelScheduledPayment($businessId: ID!, $scheduledPaymentId: ID!) {
  cancelScheduledPayment(
    businessId: $businessId
    scheduledPaymentId: $scheduledPaymentId
  ) {
    code
    message
    success
  }
}
    `;
export type CancelScheduledPaymentMutationFn = Apollo.MutationFunction<CancelScheduledPaymentMutation, CancelScheduledPaymentMutationVariables>;
export type CancelScheduledPaymentComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CancelScheduledPaymentMutation, CancelScheduledPaymentMutationVariables>, 'mutation'>;

    export const CancelScheduledPaymentComponent = (props: CancelScheduledPaymentComponentProps) => (
      <ApolloReactComponents.Mutation<CancelScheduledPaymentMutation, CancelScheduledPaymentMutationVariables> mutation={CancelScheduledPaymentDocument} {...props} />
    );
    

/**
 * __useCancelScheduledPaymentMutation__
 *
 * To run a mutation, you first call `useCancelScheduledPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelScheduledPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelScheduledPaymentMutation, { data, loading, error }] = useCancelScheduledPaymentMutation({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      scheduledPaymentId: // value for 'scheduledPaymentId'
 *   },
 * });
 */
export function useCancelScheduledPaymentMutation(baseOptions?: Apollo.MutationHookOptions<CancelScheduledPaymentMutation, CancelScheduledPaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelScheduledPaymentMutation, CancelScheduledPaymentMutationVariables>(CancelScheduledPaymentDocument, options);
      }
export type CancelScheduledPaymentMutationHookResult = ReturnType<typeof useCancelScheduledPaymentMutation>;
export type CancelScheduledPaymentMutationResult = Apollo.MutationResult<CancelScheduledPaymentMutation>;
export type CancelScheduledPaymentMutationOptions = Apollo.BaseMutationOptions<CancelScheduledPaymentMutation, CancelScheduledPaymentMutationVariables>;
export const InvoiceForwardingEmailDocument = gql`
    query invoiceForwardingEmail($businessId: ID!) {
  business(businessId: $businessId) {
    id
    invoiceForwardingEmail
  }
}
    `;
export type InvoiceForwardingEmailComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<InvoiceForwardingEmailQuery, InvoiceForwardingEmailQueryVariables>, 'query'> & ({ variables: InvoiceForwardingEmailQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const InvoiceForwardingEmailComponent = (props: InvoiceForwardingEmailComponentProps) => (
      <ApolloReactComponents.Query<InvoiceForwardingEmailQuery, InvoiceForwardingEmailQueryVariables> query={InvoiceForwardingEmailDocument} {...props} />
    );
    

/**
 * __useInvoiceForwardingEmailQuery__
 *
 * To run a query within a React component, call `useInvoiceForwardingEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceForwardingEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceForwardingEmailQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function useInvoiceForwardingEmailQuery(baseOptions: Apollo.QueryHookOptions<InvoiceForwardingEmailQuery, InvoiceForwardingEmailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InvoiceForwardingEmailQuery, InvoiceForwardingEmailQueryVariables>(InvoiceForwardingEmailDocument, options);
      }
export function useInvoiceForwardingEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvoiceForwardingEmailQuery, InvoiceForwardingEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InvoiceForwardingEmailQuery, InvoiceForwardingEmailQueryVariables>(InvoiceForwardingEmailDocument, options);
        }
export type InvoiceForwardingEmailQueryHookResult = ReturnType<typeof useInvoiceForwardingEmailQuery>;
export type InvoiceForwardingEmailLazyQueryHookResult = ReturnType<typeof useInvoiceForwardingEmailLazyQuery>;
export type InvoiceForwardingEmailQueryResult = Apollo.QueryResult<InvoiceForwardingEmailQuery, InvoiceForwardingEmailQueryVariables>;
export const AchCutoffTimesDocument = gql`
    query achCutoffTimes($businessId: ID!) {
  achCutoffTimes(businessId: $businessId) {
    isSameDayAvailable
    nextCutoff
    secondsToNextCutoff
  }
}
    `;
export type AchCutoffTimesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AchCutoffTimesQuery, AchCutoffTimesQueryVariables>, 'query'> & ({ variables: AchCutoffTimesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const AchCutoffTimesComponent = (props: AchCutoffTimesComponentProps) => (
      <ApolloReactComponents.Query<AchCutoffTimesQuery, AchCutoffTimesQueryVariables> query={AchCutoffTimesDocument} {...props} />
    );
    

/**
 * __useAchCutoffTimesQuery__
 *
 * To run a query within a React component, call `useAchCutoffTimesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAchCutoffTimesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAchCutoffTimesQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function useAchCutoffTimesQuery(baseOptions: Apollo.QueryHookOptions<AchCutoffTimesQuery, AchCutoffTimesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AchCutoffTimesQuery, AchCutoffTimesQueryVariables>(AchCutoffTimesDocument, options);
      }
export function useAchCutoffTimesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AchCutoffTimesQuery, AchCutoffTimesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AchCutoffTimesQuery, AchCutoffTimesQueryVariables>(AchCutoffTimesDocument, options);
        }
export type AchCutoffTimesQueryHookResult = ReturnType<typeof useAchCutoffTimesQuery>;
export type AchCutoffTimesLazyQueryHookResult = ReturnType<typeof useAchCutoffTimesLazyQuery>;
export type AchCutoffTimesQueryResult = Apollo.QueryResult<AchCutoffTimesQuery, AchCutoffTimesQueryVariables>;
export const SearchBillerDirectoryDocument = gql`
    query searchBillerDirectory($billerName: String!) {
  searchBillerDirectory(billerName: $billerName) {
    billers {
      rppsBillerId
      billerName
      billerAccountNumberPatterns
    }
  }
}
    `;
export type SearchBillerDirectoryComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SearchBillerDirectoryQuery, SearchBillerDirectoryQueryVariables>, 'query'> & ({ variables: SearchBillerDirectoryQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const SearchBillerDirectoryComponent = (props: SearchBillerDirectoryComponentProps) => (
      <ApolloReactComponents.Query<SearchBillerDirectoryQuery, SearchBillerDirectoryQueryVariables> query={SearchBillerDirectoryDocument} {...props} />
    );
    

/**
 * __useSearchBillerDirectoryQuery__
 *
 * To run a query within a React component, call `useSearchBillerDirectoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchBillerDirectoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchBillerDirectoryQuery({
 *   variables: {
 *      billerName: // value for 'billerName'
 *   },
 * });
 */
export function useSearchBillerDirectoryQuery(baseOptions: Apollo.QueryHookOptions<SearchBillerDirectoryQuery, SearchBillerDirectoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchBillerDirectoryQuery, SearchBillerDirectoryQueryVariables>(SearchBillerDirectoryDocument, options);
      }
export function useSearchBillerDirectoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchBillerDirectoryQuery, SearchBillerDirectoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchBillerDirectoryQuery, SearchBillerDirectoryQueryVariables>(SearchBillerDirectoryDocument, options);
        }
export type SearchBillerDirectoryQueryHookResult = ReturnType<typeof useSearchBillerDirectoryQuery>;
export type SearchBillerDirectoryLazyQueryHookResult = ReturnType<typeof useSearchBillerDirectoryLazyQuery>;
export type SearchBillerDirectoryQueryResult = Apollo.QueryResult<SearchBillerDirectoryQuery, SearchBillerDirectoryQueryVariables>;
export const KanmonLandingPageDocument = gql`
    query kanmonLandingPage($businessId: ID!) {
  kanmonLending(businessId: $businessId) {
    business {
      id
      users {
        id
        firstName
        lastName
        isPrimaryOwner
      }
    }
    products {
      id
      loanStatus
      loanType
    }
  }
}
    `;
export type KanmonLandingPageComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<KanmonLandingPageQuery, KanmonLandingPageQueryVariables>, 'query'> & ({ variables: KanmonLandingPageQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const KanmonLandingPageComponent = (props: KanmonLandingPageComponentProps) => (
      <ApolloReactComponents.Query<KanmonLandingPageQuery, KanmonLandingPageQueryVariables> query={KanmonLandingPageDocument} {...props} />
    );
    

/**
 * __useKanmonLandingPageQuery__
 *
 * To run a query within a React component, call `useKanmonLandingPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useKanmonLandingPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKanmonLandingPageQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function useKanmonLandingPageQuery(baseOptions: Apollo.QueryHookOptions<KanmonLandingPageQuery, KanmonLandingPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KanmonLandingPageQuery, KanmonLandingPageQueryVariables>(KanmonLandingPageDocument, options);
      }
export function useKanmonLandingPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KanmonLandingPageQuery, KanmonLandingPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KanmonLandingPageQuery, KanmonLandingPageQueryVariables>(KanmonLandingPageDocument, options);
        }
export type KanmonLandingPageQueryHookResult = ReturnType<typeof useKanmonLandingPageQuery>;
export type KanmonLandingPageLazyQueryHookResult = ReturnType<typeof useKanmonLandingPageLazyQuery>;
export type KanmonLandingPageQueryResult = Apollo.QueryResult<KanmonLandingPageQuery, KanmonLandingPageQueryVariables>;
export const CreateKanmonConnectTokenDocument = gql`
    mutation createKanmonConnectToken($businessId: ID!) {
  createKanmonConnectToken(businessId: $businessId) {
    connectToken
  }
}
    `;
export type CreateKanmonConnectTokenMutationFn = Apollo.MutationFunction<CreateKanmonConnectTokenMutation, CreateKanmonConnectTokenMutationVariables>;
export type CreateKanmonConnectTokenComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateKanmonConnectTokenMutation, CreateKanmonConnectTokenMutationVariables>, 'mutation'>;

    export const CreateKanmonConnectTokenComponent = (props: CreateKanmonConnectTokenComponentProps) => (
      <ApolloReactComponents.Mutation<CreateKanmonConnectTokenMutation, CreateKanmonConnectTokenMutationVariables> mutation={CreateKanmonConnectTokenDocument} {...props} />
    );
    

/**
 * __useCreateKanmonConnectTokenMutation__
 *
 * To run a mutation, you first call `useCreateKanmonConnectTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateKanmonConnectTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createKanmonConnectTokenMutation, { data, loading, error }] = useCreateKanmonConnectTokenMutation({
 *   variables: {
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function useCreateKanmonConnectTokenMutation(baseOptions?: Apollo.MutationHookOptions<CreateKanmonConnectTokenMutation, CreateKanmonConnectTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateKanmonConnectTokenMutation, CreateKanmonConnectTokenMutationVariables>(CreateKanmonConnectTokenDocument, options);
      }
export type CreateKanmonConnectTokenMutationHookResult = ReturnType<typeof useCreateKanmonConnectTokenMutation>;
export type CreateKanmonConnectTokenMutationResult = Apollo.MutationResult<CreateKanmonConnectTokenMutation>;
export type CreateKanmonConnectTokenMutationOptions = Apollo.BaseMutationOptions<CreateKanmonConnectTokenMutation, CreateKanmonConnectTokenMutationVariables>;
export const CardsActivatedDocument = gql`
    query cardsActivated($businessId: ID!) {
  business(businessId: $businessId) {
    id
    bankAccount {
      cards {
        id
        productType
        activation {
          activated
        }
      }
    }
  }
}
    `;
export type CardsActivatedComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CardsActivatedQuery, CardsActivatedQueryVariables>, 'query'> & ({ variables: CardsActivatedQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const CardsActivatedComponent = (props: CardsActivatedComponentProps) => (
      <ApolloReactComponents.Query<CardsActivatedQuery, CardsActivatedQueryVariables> query={CardsActivatedDocument} {...props} />
    );
    

/**
 * __useCardsActivatedQuery__
 *
 * To run a query within a React component, call `useCardsActivatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useCardsActivatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCardsActivatedQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function useCardsActivatedQuery(baseOptions: Apollo.QueryHookOptions<CardsActivatedQuery, CardsActivatedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CardsActivatedQuery, CardsActivatedQueryVariables>(CardsActivatedDocument, options);
      }
export function useCardsActivatedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CardsActivatedQuery, CardsActivatedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CardsActivatedQuery, CardsActivatedQueryVariables>(CardsActivatedDocument, options);
        }
export type CardsActivatedQueryHookResult = ReturnType<typeof useCardsActivatedQuery>;
export type CardsActivatedLazyQueryHookResult = ReturnType<typeof useCardsActivatedLazyQuery>;
export type CardsActivatedQueryResult = Apollo.QueryResult<CardsActivatedQuery, CardsActivatedQueryVariables>;
export const AchCreateDocument = gql`
    mutation ACHCreate($businessId: ID!, $data: TransactionsACHCreateInput!) {
  transactionsACHCreate(businessId: $businessId, data: $data) {
    code
    success
  }
}
    `;
export type AchCreateMutationFn = Apollo.MutationFunction<AchCreateMutation, AchCreateMutationVariables>;
export type AchCreateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AchCreateMutation, AchCreateMutationVariables>, 'mutation'>;

    export const AchCreateComponent = (props: AchCreateComponentProps) => (
      <ApolloReactComponents.Mutation<AchCreateMutation, AchCreateMutationVariables> mutation={AchCreateDocument} {...props} />
    );
    

/**
 * __useAchCreateMutation__
 *
 * To run a mutation, you first call `useAchCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAchCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [achCreateMutation, { data, loading, error }] = useAchCreateMutation({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAchCreateMutation(baseOptions?: Apollo.MutationHookOptions<AchCreateMutation, AchCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AchCreateMutation, AchCreateMutationVariables>(AchCreateDocument, options);
      }
export type AchCreateMutationHookResult = ReturnType<typeof useAchCreateMutation>;
export type AchCreateMutationResult = Apollo.MutationResult<AchCreateMutation>;
export type AchCreateMutationOptions = Apollo.BaseMutationOptions<AchCreateMutation, AchCreateMutationVariables>;
export const RecipientsAchDocument = gql`
    query recipientsACH($businessId: ID!, $contactPaymentType: ContactPaymentType!) {
  business(businessId: $businessId) {
    id
    contacts(contactPaymentType: $contactPaymentType) {
      id
      businessId
      name
      achPaymentDetails {
        routingNumber
        accountNumber
      }
    }
  }
}
    `;
export type RecipientsAchComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RecipientsAchQuery, RecipientsAchQueryVariables>, 'query'> & ({ variables: RecipientsAchQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RecipientsAchComponent = (props: RecipientsAchComponentProps) => (
      <ApolloReactComponents.Query<RecipientsAchQuery, RecipientsAchQueryVariables> query={RecipientsAchDocument} {...props} />
    );
    

/**
 * __useRecipientsAchQuery__
 *
 * To run a query within a React component, call `useRecipientsAchQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecipientsAchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecipientsAchQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      contactPaymentType: // value for 'contactPaymentType'
 *   },
 * });
 */
export function useRecipientsAchQuery(baseOptions: Apollo.QueryHookOptions<RecipientsAchQuery, RecipientsAchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RecipientsAchQuery, RecipientsAchQueryVariables>(RecipientsAchDocument, options);
      }
export function useRecipientsAchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RecipientsAchQuery, RecipientsAchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RecipientsAchQuery, RecipientsAchQueryVariables>(RecipientsAchDocument, options);
        }
export type RecipientsAchQueryHookResult = ReturnType<typeof useRecipientsAchQuery>;
export type RecipientsAchLazyQueryHookResult = ReturnType<typeof useRecipientsAchLazyQuery>;
export type RecipientsAchQueryResult = Apollo.QueryResult<RecipientsAchQuery, RecipientsAchQueryVariables>;
export const RecipientDomesticAchCreateDocument = gql`
    mutation recipientDomesticACHCreate($data: ContactDomesticAchCreateDataInput!) {
  contactDomesticAchCreate(data: $data) {
    code
    success
    message
    contact {
      id
      businessId
      name
      achPaymentDetails {
        accountNumber
        routingNumber
      }
    }
  }
}
    `;
export type RecipientDomesticAchCreateMutationFn = Apollo.MutationFunction<RecipientDomesticAchCreateMutation, RecipientDomesticAchCreateMutationVariables>;
export type RecipientDomesticAchCreateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RecipientDomesticAchCreateMutation, RecipientDomesticAchCreateMutationVariables>, 'mutation'>;

    export const RecipientDomesticAchCreateComponent = (props: RecipientDomesticAchCreateComponentProps) => (
      <ApolloReactComponents.Mutation<RecipientDomesticAchCreateMutation, RecipientDomesticAchCreateMutationVariables> mutation={RecipientDomesticAchCreateDocument} {...props} />
    );
    

/**
 * __useRecipientDomesticAchCreateMutation__
 *
 * To run a mutation, you first call `useRecipientDomesticAchCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecipientDomesticAchCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recipientDomesticAchCreateMutation, { data, loading, error }] = useRecipientDomesticAchCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRecipientDomesticAchCreateMutation(baseOptions?: Apollo.MutationHookOptions<RecipientDomesticAchCreateMutation, RecipientDomesticAchCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RecipientDomesticAchCreateMutation, RecipientDomesticAchCreateMutationVariables>(RecipientDomesticAchCreateDocument, options);
      }
export type RecipientDomesticAchCreateMutationHookResult = ReturnType<typeof useRecipientDomesticAchCreateMutation>;
export type RecipientDomesticAchCreateMutationResult = Apollo.MutationResult<RecipientDomesticAchCreateMutation>;
export type RecipientDomesticAchCreateMutationOptions = Apollo.BaseMutationOptions<RecipientDomesticAchCreateMutation, RecipientDomesticAchCreateMutationVariables>;
export const RecipientDomesticAchUpdateDocument = gql`
    mutation recipientDomesticACHUpdate($data: ContactDomesticAchUpdateDataInput!) {
  contactDomesticAchUpdate(data: $data) {
    code
    success
    message
  }
}
    `;
export type RecipientDomesticAchUpdateMutationFn = Apollo.MutationFunction<RecipientDomesticAchUpdateMutation, RecipientDomesticAchUpdateMutationVariables>;
export type RecipientDomesticAchUpdateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RecipientDomesticAchUpdateMutation, RecipientDomesticAchUpdateMutationVariables>, 'mutation'>;

    export const RecipientDomesticAchUpdateComponent = (props: RecipientDomesticAchUpdateComponentProps) => (
      <ApolloReactComponents.Mutation<RecipientDomesticAchUpdateMutation, RecipientDomesticAchUpdateMutationVariables> mutation={RecipientDomesticAchUpdateDocument} {...props} />
    );
    

/**
 * __useRecipientDomesticAchUpdateMutation__
 *
 * To run a mutation, you first call `useRecipientDomesticAchUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecipientDomesticAchUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recipientDomesticAchUpdateMutation, { data, loading, error }] = useRecipientDomesticAchUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRecipientDomesticAchUpdateMutation(baseOptions?: Apollo.MutationHookOptions<RecipientDomesticAchUpdateMutation, RecipientDomesticAchUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RecipientDomesticAchUpdateMutation, RecipientDomesticAchUpdateMutationVariables>(RecipientDomesticAchUpdateDocument, options);
      }
export type RecipientDomesticAchUpdateMutationHookResult = ReturnType<typeof useRecipientDomesticAchUpdateMutation>;
export type RecipientDomesticAchUpdateMutationResult = Apollo.MutationResult<RecipientDomesticAchUpdateMutation>;
export type RecipientDomesticAchUpdateMutationOptions = Apollo.BaseMutationOptions<RecipientDomesticAchUpdateMutation, RecipientDomesticAchUpdateMutationVariables>;
export const RoutingNumberAchIsValidDocument = gql`
    query routingNumberACHIsValid($routingNumber: String!) {
  routingNumberACHIsValid(routingNumber: $routingNumber)
}
    `;
export type RoutingNumberAchIsValidComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RoutingNumberAchIsValidQuery, RoutingNumberAchIsValidQueryVariables>, 'query'> & ({ variables: RoutingNumberAchIsValidQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RoutingNumberAchIsValidComponent = (props: RoutingNumberAchIsValidComponentProps) => (
      <ApolloReactComponents.Query<RoutingNumberAchIsValidQuery, RoutingNumberAchIsValidQueryVariables> query={RoutingNumberAchIsValidDocument} {...props} />
    );
    

/**
 * __useRoutingNumberAchIsValidQuery__
 *
 * To run a query within a React component, call `useRoutingNumberAchIsValidQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoutingNumberAchIsValidQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoutingNumberAchIsValidQuery({
 *   variables: {
 *      routingNumber: // value for 'routingNumber'
 *   },
 * });
 */
export function useRoutingNumberAchIsValidQuery(baseOptions: Apollo.QueryHookOptions<RoutingNumberAchIsValidQuery, RoutingNumberAchIsValidQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RoutingNumberAchIsValidQuery, RoutingNumberAchIsValidQueryVariables>(RoutingNumberAchIsValidDocument, options);
      }
export function useRoutingNumberAchIsValidLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RoutingNumberAchIsValidQuery, RoutingNumberAchIsValidQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RoutingNumberAchIsValidQuery, RoutingNumberAchIsValidQueryVariables>(RoutingNumberAchIsValidDocument, options);
        }
export type RoutingNumberAchIsValidQueryHookResult = ReturnType<typeof useRoutingNumberAchIsValidQuery>;
export type RoutingNumberAchIsValidLazyQueryHookResult = ReturnType<typeof useRoutingNumberAchIsValidLazyQuery>;
export type RoutingNumberAchIsValidQueryResult = Apollo.QueryResult<RoutingNumberAchIsValidQuery, RoutingNumberAchIsValidQueryVariables>;
export const DomesticWireCreateDocument = gql`
    mutation domesticWireCreate($businessId: ID!, $data: DomesticWireCreateInput!) {
  domesticWireCreate(businessId: $businessId, data: $data) {
    code
    message
    success
  }
}
    `;
export type DomesticWireCreateMutationFn = Apollo.MutationFunction<DomesticWireCreateMutation, DomesticWireCreateMutationVariables>;
export type DomesticWireCreateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DomesticWireCreateMutation, DomesticWireCreateMutationVariables>, 'mutation'>;

    export const DomesticWireCreateComponent = (props: DomesticWireCreateComponentProps) => (
      <ApolloReactComponents.Mutation<DomesticWireCreateMutation, DomesticWireCreateMutationVariables> mutation={DomesticWireCreateDocument} {...props} />
    );
    

/**
 * __useDomesticWireCreateMutation__
 *
 * To run a mutation, you first call `useDomesticWireCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDomesticWireCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [domesticWireCreateMutation, { data, loading, error }] = useDomesticWireCreateMutation({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDomesticWireCreateMutation(baseOptions?: Apollo.MutationHookOptions<DomesticWireCreateMutation, DomesticWireCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DomesticWireCreateMutation, DomesticWireCreateMutationVariables>(DomesticWireCreateDocument, options);
      }
export type DomesticWireCreateMutationHookResult = ReturnType<typeof useDomesticWireCreateMutation>;
export type DomesticWireCreateMutationResult = Apollo.MutationResult<DomesticWireCreateMutation>;
export type DomesticWireCreateMutationOptions = Apollo.BaseMutationOptions<DomesticWireCreateMutation, DomesticWireCreateMutationVariables>;
export const RecipientsForDomesticWireDocument = gql`
    query recipientsForDomesticWire($businessId: ID!, $contactPaymentType: ContactPaymentType!) {
  business(businessId: $businessId) {
    id
    contacts(contactPaymentType: $contactPaymentType) {
      id
      businessId
      name
      relationship
      address {
        streetAddressLine1
        streetAddressLine2
        provinceState
        city
        postalCode
      }
      wirePaymentDetails {
        routingNumber
        accountNumber
        bankName
      }
    }
  }
}
    `;
export type RecipientsForDomesticWireComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RecipientsForDomesticWireQuery, RecipientsForDomesticWireQueryVariables>, 'query'> & ({ variables: RecipientsForDomesticWireQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RecipientsForDomesticWireComponent = (props: RecipientsForDomesticWireComponentProps) => (
      <ApolloReactComponents.Query<RecipientsForDomesticWireQuery, RecipientsForDomesticWireQueryVariables> query={RecipientsForDomesticWireDocument} {...props} />
    );
    

/**
 * __useRecipientsForDomesticWireQuery__
 *
 * To run a query within a React component, call `useRecipientsForDomesticWireQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecipientsForDomesticWireQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecipientsForDomesticWireQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      contactPaymentType: // value for 'contactPaymentType'
 *   },
 * });
 */
export function useRecipientsForDomesticWireQuery(baseOptions: Apollo.QueryHookOptions<RecipientsForDomesticWireQuery, RecipientsForDomesticWireQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RecipientsForDomesticWireQuery, RecipientsForDomesticWireQueryVariables>(RecipientsForDomesticWireDocument, options);
      }
export function useRecipientsForDomesticWireLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RecipientsForDomesticWireQuery, RecipientsForDomesticWireQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RecipientsForDomesticWireQuery, RecipientsForDomesticWireQueryVariables>(RecipientsForDomesticWireDocument, options);
        }
export type RecipientsForDomesticWireQueryHookResult = ReturnType<typeof useRecipientsForDomesticWireQuery>;
export type RecipientsForDomesticWireLazyQueryHookResult = ReturnType<typeof useRecipientsForDomesticWireLazyQuery>;
export type RecipientsForDomesticWireQueryResult = Apollo.QueryResult<RecipientsForDomesticWireQuery, RecipientsForDomesticWireQueryVariables>;
export const RecipientDomesticWireCreateDocument = gql`
    mutation recipientDomesticWireCreate($data: ContactDomesticWireCreateDataInput!) {
  contactDomesticWireCreate(data: $data) {
    code
    success
    message
    contact {
      id
      businessId
      name
      relationship
      address {
        streetAddressLine1
        streetAddressLine2
        provinceState
        city
        postalCode
      }
      wirePaymentDetails {
        accountNumber
        routingNumber
        bankName
      }
    }
  }
}
    `;
export type RecipientDomesticWireCreateMutationFn = Apollo.MutationFunction<RecipientDomesticWireCreateMutation, RecipientDomesticWireCreateMutationVariables>;
export type RecipientDomesticWireCreateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RecipientDomesticWireCreateMutation, RecipientDomesticWireCreateMutationVariables>, 'mutation'>;

    export const RecipientDomesticWireCreateComponent = (props: RecipientDomesticWireCreateComponentProps) => (
      <ApolloReactComponents.Mutation<RecipientDomesticWireCreateMutation, RecipientDomesticWireCreateMutationVariables> mutation={RecipientDomesticWireCreateDocument} {...props} />
    );
    

/**
 * __useRecipientDomesticWireCreateMutation__
 *
 * To run a mutation, you first call `useRecipientDomesticWireCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecipientDomesticWireCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recipientDomesticWireCreateMutation, { data, loading, error }] = useRecipientDomesticWireCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRecipientDomesticWireCreateMutation(baseOptions?: Apollo.MutationHookOptions<RecipientDomesticWireCreateMutation, RecipientDomesticWireCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RecipientDomesticWireCreateMutation, RecipientDomesticWireCreateMutationVariables>(RecipientDomesticWireCreateDocument, options);
      }
export type RecipientDomesticWireCreateMutationHookResult = ReturnType<typeof useRecipientDomesticWireCreateMutation>;
export type RecipientDomesticWireCreateMutationResult = Apollo.MutationResult<RecipientDomesticWireCreateMutation>;
export type RecipientDomesticWireCreateMutationOptions = Apollo.BaseMutationOptions<RecipientDomesticWireCreateMutation, RecipientDomesticWireCreateMutationVariables>;
export const RecipientDomesticWireUpdateDocument = gql`
    mutation recipientDomesticWireUpdate($data: ContactDomesticWireUpdateDataInput!) {
  contactDomesticWireUpdate(data: $data) {
    code
    success
    message
  }
}
    `;
export type RecipientDomesticWireUpdateMutationFn = Apollo.MutationFunction<RecipientDomesticWireUpdateMutation, RecipientDomesticWireUpdateMutationVariables>;
export type RecipientDomesticWireUpdateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RecipientDomesticWireUpdateMutation, RecipientDomesticWireUpdateMutationVariables>, 'mutation'>;

    export const RecipientDomesticWireUpdateComponent = (props: RecipientDomesticWireUpdateComponentProps) => (
      <ApolloReactComponents.Mutation<RecipientDomesticWireUpdateMutation, RecipientDomesticWireUpdateMutationVariables> mutation={RecipientDomesticWireUpdateDocument} {...props} />
    );
    

/**
 * __useRecipientDomesticWireUpdateMutation__
 *
 * To run a mutation, you first call `useRecipientDomesticWireUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecipientDomesticWireUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recipientDomesticWireUpdateMutation, { data, loading, error }] = useRecipientDomesticWireUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRecipientDomesticWireUpdateMutation(baseOptions?: Apollo.MutationHookOptions<RecipientDomesticWireUpdateMutation, RecipientDomesticWireUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RecipientDomesticWireUpdateMutation, RecipientDomesticWireUpdateMutationVariables>(RecipientDomesticWireUpdateDocument, options);
      }
export type RecipientDomesticWireUpdateMutationHookResult = ReturnType<typeof useRecipientDomesticWireUpdateMutation>;
export type RecipientDomesticWireUpdateMutationResult = Apollo.MutationResult<RecipientDomesticWireUpdateMutation>;
export type RecipientDomesticWireUpdateMutationOptions = Apollo.BaseMutationOptions<RecipientDomesticWireUpdateMutation, RecipientDomesticWireUpdateMutationVariables>;
export const RoutingNumberWireIsValidDocument = gql`
    query routingNumberWireIsValid($routingNumber: String!) {
  routingNumberWireIsValid(routingNumber: $routingNumber)
}
    `;
export type RoutingNumberWireIsValidComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RoutingNumberWireIsValidQuery, RoutingNumberWireIsValidQueryVariables>, 'query'> & ({ variables: RoutingNumberWireIsValidQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RoutingNumberWireIsValidComponent = (props: RoutingNumberWireIsValidComponentProps) => (
      <ApolloReactComponents.Query<RoutingNumberWireIsValidQuery, RoutingNumberWireIsValidQueryVariables> query={RoutingNumberWireIsValidDocument} {...props} />
    );
    

/**
 * __useRoutingNumberWireIsValidQuery__
 *
 * To run a query within a React component, call `useRoutingNumberWireIsValidQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoutingNumberWireIsValidQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoutingNumberWireIsValidQuery({
 *   variables: {
 *      routingNumber: // value for 'routingNumber'
 *   },
 * });
 */
export function useRoutingNumberWireIsValidQuery(baseOptions: Apollo.QueryHookOptions<RoutingNumberWireIsValidQuery, RoutingNumberWireIsValidQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RoutingNumberWireIsValidQuery, RoutingNumberWireIsValidQueryVariables>(RoutingNumberWireIsValidDocument, options);
      }
export function useRoutingNumberWireIsValidLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RoutingNumberWireIsValidQuery, RoutingNumberWireIsValidQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RoutingNumberWireIsValidQuery, RoutingNumberWireIsValidQueryVariables>(RoutingNumberWireIsValidDocument, options);
        }
export type RoutingNumberWireIsValidQueryHookResult = ReturnType<typeof useRoutingNumberWireIsValidQuery>;
export type RoutingNumberWireIsValidLazyQueryHookResult = ReturnType<typeof useRoutingNumberWireIsValidLazyQuery>;
export type RoutingNumberWireIsValidQueryResult = Apollo.QueryResult<RoutingNumberWireIsValidQuery, RoutingNumberWireIsValidQueryVariables>;
export const TimeSavedDocument = gql`
    query timeSaved($businessId: ID!) {
  timeSaved(businessId: $businessId) {
    perMailedCheckTimeSavedSeconds
    perSameDayAchTimeSavedSeconds
    perStandardAchTimeSavedSeconds
    perWireTimeSavedSeconds
    timeSavedTotalSeconds
    pdfGuidelineUrl
  }
}
    `;
export type TimeSavedComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<TimeSavedQuery, TimeSavedQueryVariables>, 'query'> & ({ variables: TimeSavedQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const TimeSavedComponent = (props: TimeSavedComponentProps) => (
      <ApolloReactComponents.Query<TimeSavedQuery, TimeSavedQueryVariables> query={TimeSavedDocument} {...props} />
    );
    

/**
 * __useTimeSavedQuery__
 *
 * To run a query within a React component, call `useTimeSavedQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimeSavedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimeSavedQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function useTimeSavedQuery(baseOptions: Apollo.QueryHookOptions<TimeSavedQuery, TimeSavedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TimeSavedQuery, TimeSavedQueryVariables>(TimeSavedDocument, options);
      }
export function useTimeSavedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TimeSavedQuery, TimeSavedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TimeSavedQuery, TimeSavedQueryVariables>(TimeSavedDocument, options);
        }
export type TimeSavedQueryHookResult = ReturnType<typeof useTimeSavedQuery>;
export type TimeSavedLazyQueryHookResult = ReturnType<typeof useTimeSavedLazyQuery>;
export type TimeSavedQueryResult = Apollo.QueryResult<TimeSavedQuery, TimeSavedQueryVariables>;
export const TransactionFeesDocument = gql`
    query transactionFees($businessId: ID!, $amount: Float!) {
  transactionFees(businessId: $businessId, amount: $amount) {
    domesticWire
    mailedCheck
    sameDayAch
    billPay
  }
}
    `;
export type TransactionFeesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<TransactionFeesQuery, TransactionFeesQueryVariables>, 'query'> & ({ variables: TransactionFeesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const TransactionFeesComponent = (props: TransactionFeesComponentProps) => (
      <ApolloReactComponents.Query<TransactionFeesQuery, TransactionFeesQueryVariables> query={TransactionFeesDocument} {...props} />
    );
    

/**
 * __useTransactionFeesQuery__
 *
 * To run a query within a React component, call `useTransactionFeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransactionFeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransactionFeesQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      amount: // value for 'amount'
 *   },
 * });
 */
export function useTransactionFeesQuery(baseOptions: Apollo.QueryHookOptions<TransactionFeesQuery, TransactionFeesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TransactionFeesQuery, TransactionFeesQueryVariables>(TransactionFeesDocument, options);
      }
export function useTransactionFeesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TransactionFeesQuery, TransactionFeesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TransactionFeesQuery, TransactionFeesQueryVariables>(TransactionFeesDocument, options);
        }
export type TransactionFeesQueryHookResult = ReturnType<typeof useTransactionFeesQuery>;
export type TransactionFeesLazyQueryHookResult = ReturnType<typeof useTransactionFeesLazyQuery>;
export type TransactionFeesQueryResult = Apollo.QueryResult<TransactionFeesQuery, TransactionFeesQueryVariables>;
export const CapabilitiesAndAvailableBalanceDocument = gql`
    query capabilitiesAndAvailableBalance($businessId: ID!) {
  business(businessId: $businessId) {
    id
    bankAccount {
      id
      availableBalance
    }
    bankAccounts {
      id
      capabilities {
        ...capabilitiesWithLimitAndFees
      }
    }
  }
}
    ${CapabilitiesWithLimitAndFeesFragmentDoc}`;
export type CapabilitiesAndAvailableBalanceComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CapabilitiesAndAvailableBalanceQuery, CapabilitiesAndAvailableBalanceQueryVariables>, 'query'> & ({ variables: CapabilitiesAndAvailableBalanceQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const CapabilitiesAndAvailableBalanceComponent = (props: CapabilitiesAndAvailableBalanceComponentProps) => (
      <ApolloReactComponents.Query<CapabilitiesAndAvailableBalanceQuery, CapabilitiesAndAvailableBalanceQueryVariables> query={CapabilitiesAndAvailableBalanceDocument} {...props} />
    );
    

/**
 * __useCapabilitiesAndAvailableBalanceQuery__
 *
 * To run a query within a React component, call `useCapabilitiesAndAvailableBalanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useCapabilitiesAndAvailableBalanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCapabilitiesAndAvailableBalanceQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function useCapabilitiesAndAvailableBalanceQuery(baseOptions: Apollo.QueryHookOptions<CapabilitiesAndAvailableBalanceQuery, CapabilitiesAndAvailableBalanceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CapabilitiesAndAvailableBalanceQuery, CapabilitiesAndAvailableBalanceQueryVariables>(CapabilitiesAndAvailableBalanceDocument, options);
      }
export function useCapabilitiesAndAvailableBalanceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CapabilitiesAndAvailableBalanceQuery, CapabilitiesAndAvailableBalanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CapabilitiesAndAvailableBalanceQuery, CapabilitiesAndAvailableBalanceQueryVariables>(CapabilitiesAndAvailableBalanceDocument, options);
        }
export type CapabilitiesAndAvailableBalanceQueryHookResult = ReturnType<typeof useCapabilitiesAndAvailableBalanceQuery>;
export type CapabilitiesAndAvailableBalanceLazyQueryHookResult = ReturnType<typeof useCapabilitiesAndAvailableBalanceLazyQuery>;
export type CapabilitiesAndAvailableBalanceQueryResult = Apollo.QueryResult<CapabilitiesAndAvailableBalanceQuery, CapabilitiesAndAvailableBalanceQueryVariables>;
export const PaymentMethodFeesDocument = gql`
    query paymentMethodFees($businessId: ID!) {
  business(businessId: $businessId) {
    bankAccounts {
      id
      capabilities {
        ...capabilitiesWithLimitAndFees
      }
    }
  }
}
    ${CapabilitiesWithLimitAndFeesFragmentDoc}`;
export type PaymentMethodFeesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<PaymentMethodFeesQuery, PaymentMethodFeesQueryVariables>, 'query'> & ({ variables: PaymentMethodFeesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const PaymentMethodFeesComponent = (props: PaymentMethodFeesComponentProps) => (
      <ApolloReactComponents.Query<PaymentMethodFeesQuery, PaymentMethodFeesQueryVariables> query={PaymentMethodFeesDocument} {...props} />
    );
    

/**
 * __usePaymentMethodFeesQuery__
 *
 * To run a query within a React component, call `usePaymentMethodFeesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentMethodFeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentMethodFeesQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function usePaymentMethodFeesQuery(baseOptions: Apollo.QueryHookOptions<PaymentMethodFeesQuery, PaymentMethodFeesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PaymentMethodFeesQuery, PaymentMethodFeesQueryVariables>(PaymentMethodFeesDocument, options);
      }
export function usePaymentMethodFeesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaymentMethodFeesQuery, PaymentMethodFeesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PaymentMethodFeesQuery, PaymentMethodFeesQueryVariables>(PaymentMethodFeesDocument, options);
        }
export type PaymentMethodFeesQueryHookResult = ReturnType<typeof usePaymentMethodFeesQuery>;
export type PaymentMethodFeesLazyQueryHookResult = ReturnType<typeof usePaymentMethodFeesLazyQuery>;
export type PaymentMethodFeesQueryResult = Apollo.QueryResult<PaymentMethodFeesQuery, PaymentMethodFeesQueryVariables>;
export const GetFundingStatusDocument = gql`
    query getFundingStatus($businessId: ID!) {
  getFundingStatus(businessId: $businessId) {
    status
  }
}
    `;
export type GetFundingStatusComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetFundingStatusQuery, GetFundingStatusQueryVariables>, 'query'> & ({ variables: GetFundingStatusQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetFundingStatusComponent = (props: GetFundingStatusComponentProps) => (
      <ApolloReactComponents.Query<GetFundingStatusQuery, GetFundingStatusQueryVariables> query={GetFundingStatusDocument} {...props} />
    );
    

/**
 * __useGetFundingStatusQuery__
 *
 * To run a query within a React component, call `useGetFundingStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFundingStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFundingStatusQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function useGetFundingStatusQuery(baseOptions: Apollo.QueryHookOptions<GetFundingStatusQuery, GetFundingStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFundingStatusQuery, GetFundingStatusQueryVariables>(GetFundingStatusDocument, options);
      }
export function useGetFundingStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFundingStatusQuery, GetFundingStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFundingStatusQuery, GetFundingStatusQueryVariables>(GetFundingStatusDocument, options);
        }
export type GetFundingStatusQueryHookResult = ReturnType<typeof useGetFundingStatusQuery>;
export type GetFundingStatusLazyQueryHookResult = ReturnType<typeof useGetFundingStatusLazyQuery>;
export type GetFundingStatusQueryResult = Apollo.QueryResult<GetFundingStatusQuery, GetFundingStatusQueryVariables>;
export const SubAccountsReconcileDocument = gql`
    mutation subAccountsReconcile($businessId: ID!, $data: [SubAccountReconcileDataInput!]!) {
  subAccountsReconcile(businessId: $businessId, data: $data) {
    success
  }
}
    `;
export type SubAccountsReconcileMutationFn = Apollo.MutationFunction<SubAccountsReconcileMutation, SubAccountsReconcileMutationVariables>;
export type SubAccountsReconcileComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SubAccountsReconcileMutation, SubAccountsReconcileMutationVariables>, 'mutation'>;

    export const SubAccountsReconcileComponent = (props: SubAccountsReconcileComponentProps) => (
      <ApolloReactComponents.Mutation<SubAccountsReconcileMutation, SubAccountsReconcileMutationVariables> mutation={SubAccountsReconcileDocument} {...props} />
    );
    

/**
 * __useSubAccountsReconcileMutation__
 *
 * To run a mutation, you first call `useSubAccountsReconcileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubAccountsReconcileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subAccountsReconcileMutation, { data, loading, error }] = useSubAccountsReconcileMutation({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSubAccountsReconcileMutation(baseOptions?: Apollo.MutationHookOptions<SubAccountsReconcileMutation, SubAccountsReconcileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubAccountsReconcileMutation, SubAccountsReconcileMutationVariables>(SubAccountsReconcileDocument, options);
      }
export type SubAccountsReconcileMutationHookResult = ReturnType<typeof useSubAccountsReconcileMutation>;
export type SubAccountsReconcileMutationResult = Apollo.MutationResult<SubAccountsReconcileMutation>;
export type SubAccountsReconcileMutationOptions = Apollo.BaseMutationOptions<SubAccountsReconcileMutation, SubAccountsReconcileMutationVariables>;
export const ListRevenueHubConnectionsDocument = gql`
    query listRevenueHubConnections($businessId: ID!) {
  revenueHub(businessId: $businessId) {
    connections {
      id
      platform
      status
    }
  }
}
    `;
export type ListRevenueHubConnectionsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ListRevenueHubConnectionsQuery, ListRevenueHubConnectionsQueryVariables>, 'query'> & ({ variables: ListRevenueHubConnectionsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ListRevenueHubConnectionsComponent = (props: ListRevenueHubConnectionsComponentProps) => (
      <ApolloReactComponents.Query<ListRevenueHubConnectionsQuery, ListRevenueHubConnectionsQueryVariables> query={ListRevenueHubConnectionsDocument} {...props} />
    );
    

/**
 * __useListRevenueHubConnectionsQuery__
 *
 * To run a query within a React component, call `useListRevenueHubConnectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListRevenueHubConnectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListRevenueHubConnectionsQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function useListRevenueHubConnectionsQuery(baseOptions: Apollo.QueryHookOptions<ListRevenueHubConnectionsQuery, ListRevenueHubConnectionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListRevenueHubConnectionsQuery, ListRevenueHubConnectionsQueryVariables>(ListRevenueHubConnectionsDocument, options);
      }
export function useListRevenueHubConnectionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListRevenueHubConnectionsQuery, ListRevenueHubConnectionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListRevenueHubConnectionsQuery, ListRevenueHubConnectionsQueryVariables>(ListRevenueHubConnectionsDocument, options);
        }
export type ListRevenueHubConnectionsQueryHookResult = ReturnType<typeof useListRevenueHubConnectionsQuery>;
export type ListRevenueHubConnectionsLazyQueryHookResult = ReturnType<typeof useListRevenueHubConnectionsLazyQuery>;
export type ListRevenueHubConnectionsQueryResult = Apollo.QueryResult<ListRevenueHubConnectionsQuery, ListRevenueHubConnectionsQueryVariables>;
export const SaveRevenueHubConnectionDocument = gql`
    mutation saveRevenueHubConnection($input: RevenueHubConnectionSaveInput!) {
  revenueHubConnectionSave(input: $input) {
    code
    success
    message
    connection {
      id
      platform
      status
    }
  }
}
    `;
export type SaveRevenueHubConnectionMutationFn = Apollo.MutationFunction<SaveRevenueHubConnectionMutation, SaveRevenueHubConnectionMutationVariables>;
export type SaveRevenueHubConnectionComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SaveRevenueHubConnectionMutation, SaveRevenueHubConnectionMutationVariables>, 'mutation'>;

    export const SaveRevenueHubConnectionComponent = (props: SaveRevenueHubConnectionComponentProps) => (
      <ApolloReactComponents.Mutation<SaveRevenueHubConnectionMutation, SaveRevenueHubConnectionMutationVariables> mutation={SaveRevenueHubConnectionDocument} {...props} />
    );
    

/**
 * __useSaveRevenueHubConnectionMutation__
 *
 * To run a mutation, you first call `useSaveRevenueHubConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveRevenueHubConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveRevenueHubConnectionMutation, { data, loading, error }] = useSaveRevenueHubConnectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveRevenueHubConnectionMutation(baseOptions?: Apollo.MutationHookOptions<SaveRevenueHubConnectionMutation, SaveRevenueHubConnectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveRevenueHubConnectionMutation, SaveRevenueHubConnectionMutationVariables>(SaveRevenueHubConnectionDocument, options);
      }
export type SaveRevenueHubConnectionMutationHookResult = ReturnType<typeof useSaveRevenueHubConnectionMutation>;
export type SaveRevenueHubConnectionMutationResult = Apollo.MutationResult<SaveRevenueHubConnectionMutation>;
export type SaveRevenueHubConnectionMutationOptions = Apollo.BaseMutationOptions<SaveRevenueHubConnectionMutation, SaveRevenueHubConnectionMutationVariables>;
export const GetRevenueHubConnectionDocument = gql`
    query getRevenueHubConnection($businessId: ID!, $connectionId: ID!, $filters: RevenueHubConnectionFiltersInput!) {
  revenueHub(businessId: $businessId) {
    connection(id: $connectionId) {
      id
      status
      linkUpdateUrl
      revenueDetails(filters: $filters) {
        currentBalance
        grossRevenuePerDayTimeSeries {
          date
          totalRevenueAmount
        }
        totalGrossRevenue
        totalPaidOutAmount
        totalReturnsAmount
        transactionsCount
      }
    }
  }
}
    `;
export type GetRevenueHubConnectionComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetRevenueHubConnectionQuery, GetRevenueHubConnectionQueryVariables>, 'query'> & ({ variables: GetRevenueHubConnectionQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetRevenueHubConnectionComponent = (props: GetRevenueHubConnectionComponentProps) => (
      <ApolloReactComponents.Query<GetRevenueHubConnectionQuery, GetRevenueHubConnectionQueryVariables> query={GetRevenueHubConnectionDocument} {...props} />
    );
    

/**
 * __useGetRevenueHubConnectionQuery__
 *
 * To run a query within a React component, call `useGetRevenueHubConnectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRevenueHubConnectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRevenueHubConnectionQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      connectionId: // value for 'connectionId'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetRevenueHubConnectionQuery(baseOptions: Apollo.QueryHookOptions<GetRevenueHubConnectionQuery, GetRevenueHubConnectionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRevenueHubConnectionQuery, GetRevenueHubConnectionQueryVariables>(GetRevenueHubConnectionDocument, options);
      }
export function useGetRevenueHubConnectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRevenueHubConnectionQuery, GetRevenueHubConnectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRevenueHubConnectionQuery, GetRevenueHubConnectionQueryVariables>(GetRevenueHubConnectionDocument, options);
        }
export type GetRevenueHubConnectionQueryHookResult = ReturnType<typeof useGetRevenueHubConnectionQuery>;
export type GetRevenueHubConnectionLazyQueryHookResult = ReturnType<typeof useGetRevenueHubConnectionLazyQuery>;
export type GetRevenueHubConnectionQueryResult = Apollo.QueryResult<GetRevenueHubConnectionQuery, GetRevenueHubConnectionQueryVariables>;
export const GetRevenueHubConnectionLinkUpdateUrlDocument = gql`
    query getRevenueHubConnectionLinkUpdateUrl($businessId: ID!, $connectionId: ID!) {
  revenueHub(businessId: $businessId) {
    connection(id: $connectionId) {
      id
      linkUpdateUrl
    }
  }
}
    `;
export type GetRevenueHubConnectionLinkUpdateUrlComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetRevenueHubConnectionLinkUpdateUrlQuery, GetRevenueHubConnectionLinkUpdateUrlQueryVariables>, 'query'> & ({ variables: GetRevenueHubConnectionLinkUpdateUrlQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetRevenueHubConnectionLinkUpdateUrlComponent = (props: GetRevenueHubConnectionLinkUpdateUrlComponentProps) => (
      <ApolloReactComponents.Query<GetRevenueHubConnectionLinkUpdateUrlQuery, GetRevenueHubConnectionLinkUpdateUrlQueryVariables> query={GetRevenueHubConnectionLinkUpdateUrlDocument} {...props} />
    );
    

/**
 * __useGetRevenueHubConnectionLinkUpdateUrlQuery__
 *
 * To run a query within a React component, call `useGetRevenueHubConnectionLinkUpdateUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRevenueHubConnectionLinkUpdateUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRevenueHubConnectionLinkUpdateUrlQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      connectionId: // value for 'connectionId'
 *   },
 * });
 */
export function useGetRevenueHubConnectionLinkUpdateUrlQuery(baseOptions: Apollo.QueryHookOptions<GetRevenueHubConnectionLinkUpdateUrlQuery, GetRevenueHubConnectionLinkUpdateUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRevenueHubConnectionLinkUpdateUrlQuery, GetRevenueHubConnectionLinkUpdateUrlQueryVariables>(GetRevenueHubConnectionLinkUpdateUrlDocument, options);
      }
export function useGetRevenueHubConnectionLinkUpdateUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRevenueHubConnectionLinkUpdateUrlQuery, GetRevenueHubConnectionLinkUpdateUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRevenueHubConnectionLinkUpdateUrlQuery, GetRevenueHubConnectionLinkUpdateUrlQueryVariables>(GetRevenueHubConnectionLinkUpdateUrlDocument, options);
        }
export type GetRevenueHubConnectionLinkUpdateUrlQueryHookResult = ReturnType<typeof useGetRevenueHubConnectionLinkUpdateUrlQuery>;
export type GetRevenueHubConnectionLinkUpdateUrlLazyQueryHookResult = ReturnType<typeof useGetRevenueHubConnectionLinkUpdateUrlLazyQuery>;
export type GetRevenueHubConnectionLinkUpdateUrlQueryResult = Apollo.QueryResult<GetRevenueHubConnectionLinkUpdateUrlQuery, GetRevenueHubConnectionLinkUpdateUrlQueryVariables>;
export const RevenueHubConnectionDeleteDocument = gql`
    mutation revenueHubConnectionDelete($businessId: ID!, $connectionId: ID!) {
  revenueHubConnectionDelete(businessId: $businessId, connectionId: $connectionId) {
    code
    success
    message
  }
}
    `;
export type RevenueHubConnectionDeleteMutationFn = Apollo.MutationFunction<RevenueHubConnectionDeleteMutation, RevenueHubConnectionDeleteMutationVariables>;
export type RevenueHubConnectionDeleteComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RevenueHubConnectionDeleteMutation, RevenueHubConnectionDeleteMutationVariables>, 'mutation'>;

    export const RevenueHubConnectionDeleteComponent = (props: RevenueHubConnectionDeleteComponentProps) => (
      <ApolloReactComponents.Mutation<RevenueHubConnectionDeleteMutation, RevenueHubConnectionDeleteMutationVariables> mutation={RevenueHubConnectionDeleteDocument} {...props} />
    );
    

/**
 * __useRevenueHubConnectionDeleteMutation__
 *
 * To run a mutation, you first call `useRevenueHubConnectionDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevenueHubConnectionDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revenueHubConnectionDeleteMutation, { data, loading, error }] = useRevenueHubConnectionDeleteMutation({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      connectionId: // value for 'connectionId'
 *   },
 * });
 */
export function useRevenueHubConnectionDeleteMutation(baseOptions?: Apollo.MutationHookOptions<RevenueHubConnectionDeleteMutation, RevenueHubConnectionDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RevenueHubConnectionDeleteMutation, RevenueHubConnectionDeleteMutationVariables>(RevenueHubConnectionDeleteDocument, options);
      }
export type RevenueHubConnectionDeleteMutationHookResult = ReturnType<typeof useRevenueHubConnectionDeleteMutation>;
export type RevenueHubConnectionDeleteMutationResult = Apollo.MutationResult<RevenueHubConnectionDeleteMutation>;
export type RevenueHubConnectionDeleteMutationOptions = Apollo.BaseMutationOptions<RevenueHubConnectionDeleteMutation, RevenueHubConnectionDeleteMutationVariables>;
export const TopUpsDocument = gql`
    query TopUps($businessId: ID!, $accountId: ID!) {
  capabilities: plaidFundingCapabilities(businessId: $businessId) {
    topUps {
      velocityLimits {
        maxTransactionCountPer30DayRolling
      }
      amounts {
        defaultAmount
        defaultThresholdAmount
        maximumAmount
        minimumAmount
        minimumThresholdAmount
      }
      isEligible
    }
  }
  topUpSettings: getTopUpsSettings(businessId: $businessId, accountId: $accountId) {
    isTopUpsEnabled
    lowBalanceThreshold
    topUpAmount
    plaidItem {
      id
      status
    }
    error {
      code
      message
    }
  }
  plaidItems(businessId: $businessId) {
    data {
      id
      status
      maskedAccountNumber
      institution {
        id
        name
        url
        logo
      }
    }
  }
}
    `;
export type TopUpsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<TopUpsQuery, TopUpsQueryVariables>, 'query'> & ({ variables: TopUpsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const TopUpsComponent = (props: TopUpsComponentProps) => (
      <ApolloReactComponents.Query<TopUpsQuery, TopUpsQueryVariables> query={TopUpsDocument} {...props} />
    );
    

/**
 * __useTopUpsQuery__
 *
 * To run a query within a React component, call `useTopUpsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopUpsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopUpsQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useTopUpsQuery(baseOptions: Apollo.QueryHookOptions<TopUpsQuery, TopUpsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TopUpsQuery, TopUpsQueryVariables>(TopUpsDocument, options);
      }
export function useTopUpsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TopUpsQuery, TopUpsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TopUpsQuery, TopUpsQueryVariables>(TopUpsDocument, options);
        }
export type TopUpsQueryHookResult = ReturnType<typeof useTopUpsQuery>;
export type TopUpsLazyQueryHookResult = ReturnType<typeof useTopUpsLazyQuery>;
export type TopUpsQueryResult = Apollo.QueryResult<TopUpsQuery, TopUpsQueryVariables>;
export const GetTopUpsSettingsDocument = gql`
    query GetTopUpsSettings($businessId: ID!, $accountId: ID!) {
  getTopUpsSettings(businessId: $businessId, accountId: $accountId) {
    isTopUpsEnabled
    lowBalanceThreshold
    topUpAmount
    plaidItem {
      id
      status
      maskedAccountNumber
      institution {
        id
        name
        url
        logo
      }
    }
    error {
      code
      message
    }
  }
}
    `;
export type GetTopUpsSettingsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetTopUpsSettingsQuery, GetTopUpsSettingsQueryVariables>, 'query'> & ({ variables: GetTopUpsSettingsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetTopUpsSettingsComponent = (props: GetTopUpsSettingsComponentProps) => (
      <ApolloReactComponents.Query<GetTopUpsSettingsQuery, GetTopUpsSettingsQueryVariables> query={GetTopUpsSettingsDocument} {...props} />
    );
    

/**
 * __useGetTopUpsSettingsQuery__
 *
 * To run a query within a React component, call `useGetTopUpsSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTopUpsSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTopUpsSettingsQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useGetTopUpsSettingsQuery(baseOptions: Apollo.QueryHookOptions<GetTopUpsSettingsQuery, GetTopUpsSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTopUpsSettingsQuery, GetTopUpsSettingsQueryVariables>(GetTopUpsSettingsDocument, options);
      }
export function useGetTopUpsSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTopUpsSettingsQuery, GetTopUpsSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTopUpsSettingsQuery, GetTopUpsSettingsQueryVariables>(GetTopUpsSettingsDocument, options);
        }
export type GetTopUpsSettingsQueryHookResult = ReturnType<typeof useGetTopUpsSettingsQuery>;
export type GetTopUpsSettingsLazyQueryHookResult = ReturnType<typeof useGetTopUpsSettingsLazyQuery>;
export type GetTopUpsSettingsQueryResult = Apollo.QueryResult<GetTopUpsSettingsQuery, GetTopUpsSettingsQueryVariables>;
export const UpdateTopUpsSettingsDocument = gql`
    mutation UpdateTopUpsSettings($businessId: ID!, $accountId: ID!, $data: UpdateTopUpsSettingsDataInput!) {
  updateTopUpsSettings(
    businessId: $businessId
    accountId: $accountId
    data: $data
  ) {
    code
    success
    message
    data {
      isTopUpsEnabled
      lowBalanceThreshold
      topUpAmount
      plaidItem {
        id
        status
      }
    }
  }
}
    `;
export type UpdateTopUpsSettingsMutationFn = Apollo.MutationFunction<UpdateTopUpsSettingsMutation, UpdateTopUpsSettingsMutationVariables>;
export type UpdateTopUpsSettingsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateTopUpsSettingsMutation, UpdateTopUpsSettingsMutationVariables>, 'mutation'>;

    export const UpdateTopUpsSettingsComponent = (props: UpdateTopUpsSettingsComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateTopUpsSettingsMutation, UpdateTopUpsSettingsMutationVariables> mutation={UpdateTopUpsSettingsDocument} {...props} />
    );
    

/**
 * __useUpdateTopUpsSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateTopUpsSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTopUpsSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTopUpsSettingsMutation, { data, loading, error }] = useUpdateTopUpsSettingsMutation({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      accountId: // value for 'accountId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateTopUpsSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTopUpsSettingsMutation, UpdateTopUpsSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTopUpsSettingsMutation, UpdateTopUpsSettingsMutationVariables>(UpdateTopUpsSettingsDocument, options);
      }
export type UpdateTopUpsSettingsMutationHookResult = ReturnType<typeof useUpdateTopUpsSettingsMutation>;
export type UpdateTopUpsSettingsMutationResult = Apollo.MutationResult<UpdateTopUpsSettingsMutation>;
export type UpdateTopUpsSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateTopUpsSettingsMutation, UpdateTopUpsSettingsMutationVariables>;
export const DisableTopUpsDocument = gql`
    mutation DisableTopUps($businessId: ID!, $accountId: ID!, $data: DisableTopUpsDataInput!) {
  disableTopUps(businessId: $businessId, accountId: $accountId, data: $data) {
    code
    success
    message
  }
}
    `;
export type DisableTopUpsMutationFn = Apollo.MutationFunction<DisableTopUpsMutation, DisableTopUpsMutationVariables>;
export type DisableTopUpsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DisableTopUpsMutation, DisableTopUpsMutationVariables>, 'mutation'>;

    export const DisableTopUpsComponent = (props: DisableTopUpsComponentProps) => (
      <ApolloReactComponents.Mutation<DisableTopUpsMutation, DisableTopUpsMutationVariables> mutation={DisableTopUpsDocument} {...props} />
    );
    

/**
 * __useDisableTopUpsMutation__
 *
 * To run a mutation, you first call `useDisableTopUpsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableTopUpsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableTopUpsMutation, { data, loading, error }] = useDisableTopUpsMutation({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      accountId: // value for 'accountId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDisableTopUpsMutation(baseOptions?: Apollo.MutationHookOptions<DisableTopUpsMutation, DisableTopUpsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DisableTopUpsMutation, DisableTopUpsMutationVariables>(DisableTopUpsDocument, options);
      }
export type DisableTopUpsMutationHookResult = ReturnType<typeof useDisableTopUpsMutation>;
export type DisableTopUpsMutationResult = Apollo.MutationResult<DisableTopUpsMutation>;
export type DisableTopUpsMutationOptions = Apollo.BaseMutationOptions<DisableTopUpsMutation, DisableTopUpsMutationVariables>;
export const BillingDetailsDocument = gql`
    query billingDetails($businessId: ID!) {
  business(businessId: $businessId) {
    id
    billing {
      plan {
        price
        nextPaymentDate
      }
      invoices {
        id
        amountDue
        date
      }
    }
  }
}
    `;
export type BillingDetailsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<BillingDetailsQuery, BillingDetailsQueryVariables>, 'query'> & ({ variables: BillingDetailsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const BillingDetailsComponent = (props: BillingDetailsComponentProps) => (
      <ApolloReactComponents.Query<BillingDetailsQuery, BillingDetailsQueryVariables> query={BillingDetailsDocument} {...props} />
    );
    

/**
 * __useBillingDetailsQuery__
 *
 * To run a query within a React component, call `useBillingDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBillingDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillingDetailsQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function useBillingDetailsQuery(baseOptions: Apollo.QueryHookOptions<BillingDetailsQuery, BillingDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BillingDetailsQuery, BillingDetailsQueryVariables>(BillingDetailsDocument, options);
      }
export function useBillingDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BillingDetailsQuery, BillingDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BillingDetailsQuery, BillingDetailsQueryVariables>(BillingDetailsDocument, options);
        }
export type BillingDetailsQueryHookResult = ReturnType<typeof useBillingDetailsQuery>;
export type BillingDetailsLazyQueryHookResult = ReturnType<typeof useBillingDetailsLazyQuery>;
export type BillingDetailsQueryResult = Apollo.QueryResult<BillingDetailsQuery, BillingDetailsQueryVariables>;
export const BillingInvoicePdfDocument = gql`
    query billingInvoicePDF($billingInvoiceId: ID!) {
  billingInvoicePDF(billingInvoiceId: $billingInvoiceId) {
    invoice
  }
}
    `;
export type BillingInvoicePdfComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<BillingInvoicePdfQuery, BillingInvoicePdfQueryVariables>, 'query'> & ({ variables: BillingInvoicePdfQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const BillingInvoicePdfComponent = (props: BillingInvoicePdfComponentProps) => (
      <ApolloReactComponents.Query<BillingInvoicePdfQuery, BillingInvoicePdfQueryVariables> query={BillingInvoicePdfDocument} {...props} />
    );
    

/**
 * __useBillingInvoicePdfQuery__
 *
 * To run a query within a React component, call `useBillingInvoicePdfQuery` and pass it any options that fit your needs.
 * When your component renders, `useBillingInvoicePdfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillingInvoicePdfQuery({
 *   variables: {
 *      billingInvoiceId: // value for 'billingInvoiceId'
 *   },
 * });
 */
export function useBillingInvoicePdfQuery(baseOptions: Apollo.QueryHookOptions<BillingInvoicePdfQuery, BillingInvoicePdfQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BillingInvoicePdfQuery, BillingInvoicePdfQueryVariables>(BillingInvoicePdfDocument, options);
      }
export function useBillingInvoicePdfLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BillingInvoicePdfQuery, BillingInvoicePdfQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BillingInvoicePdfQuery, BillingInvoicePdfQueryVariables>(BillingInvoicePdfDocument, options);
        }
export type BillingInvoicePdfQueryHookResult = ReturnType<typeof useBillingInvoicePdfQuery>;
export type BillingInvoicePdfLazyQueryHookResult = ReturnType<typeof useBillingInvoicePdfLazyQuery>;
export type BillingInvoicePdfQueryResult = Apollo.QueryResult<BillingInvoicePdfQuery, BillingInvoicePdfQueryVariables>;
export const AgreementsPdfDocument = gql`
    query agreementsPDF($type: AgreementType!) {
  agreementsPDF(type: $type) {
    pdfURL
  }
}
    `;
export type AgreementsPdfComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AgreementsPdfQuery, AgreementsPdfQueryVariables>, 'query'> & ({ variables: AgreementsPdfQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const AgreementsPdfComponent = (props: AgreementsPdfComponentProps) => (
      <ApolloReactComponents.Query<AgreementsPdfQuery, AgreementsPdfQueryVariables> query={AgreementsPdfDocument} {...props} />
    );
    

/**
 * __useAgreementsPdfQuery__
 *
 * To run a query within a React component, call `useAgreementsPdfQuery` and pass it any options that fit your needs.
 * When your component renders, `useAgreementsPdfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAgreementsPdfQuery({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
export function useAgreementsPdfQuery(baseOptions: Apollo.QueryHookOptions<AgreementsPdfQuery, AgreementsPdfQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AgreementsPdfQuery, AgreementsPdfQueryVariables>(AgreementsPdfDocument, options);
      }
export function useAgreementsPdfLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AgreementsPdfQuery, AgreementsPdfQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AgreementsPdfQuery, AgreementsPdfQueryVariables>(AgreementsPdfDocument, options);
        }
export type AgreementsPdfQueryHookResult = ReturnType<typeof useAgreementsPdfQuery>;
export type AgreementsPdfLazyQueryHookResult = ReturnType<typeof useAgreementsPdfLazyQuery>;
export type AgreementsPdfQueryResult = Apollo.QueryResult<AgreementsPdfQuery, AgreementsPdfQueryVariables>;
export const CreateLinkTokenDocument = gql`
    mutation createLinkToken($businessId: ID!, $itemId: String, $redirectUri: String) {
  createLinkToken(
    businessId: $businessId
    itemId: $itemId
    redirectUri: $redirectUri
  ) {
    success
    code
    message
    data {
      linkToken
      expirationTimestamp
    }
  }
}
    `;
export type CreateLinkTokenMutationFn = Apollo.MutationFunction<CreateLinkTokenMutation, CreateLinkTokenMutationVariables>;
export type CreateLinkTokenComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateLinkTokenMutation, CreateLinkTokenMutationVariables>, 'mutation'>;

    export const CreateLinkTokenComponent = (props: CreateLinkTokenComponentProps) => (
      <ApolloReactComponents.Mutation<CreateLinkTokenMutation, CreateLinkTokenMutationVariables> mutation={CreateLinkTokenDocument} {...props} />
    );
    

/**
 * __useCreateLinkTokenMutation__
 *
 * To run a mutation, you first call `useCreateLinkTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLinkTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLinkTokenMutation, { data, loading, error }] = useCreateLinkTokenMutation({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      itemId: // value for 'itemId'
 *      redirectUri: // value for 'redirectUri'
 *   },
 * });
 */
export function useCreateLinkTokenMutation(baseOptions?: Apollo.MutationHookOptions<CreateLinkTokenMutation, CreateLinkTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateLinkTokenMutation, CreateLinkTokenMutationVariables>(CreateLinkTokenDocument, options);
      }
export type CreateLinkTokenMutationHookResult = ReturnType<typeof useCreateLinkTokenMutation>;
export type CreateLinkTokenMutationResult = Apollo.MutationResult<CreateLinkTokenMutation>;
export type CreateLinkTokenMutationOptions = Apollo.BaseMutationOptions<CreateLinkTokenMutation, CreateLinkTokenMutationVariables>;
export const ExchangePublicTokenDocument = gql`
    mutation exchangePublicToken($accountType: PlaidAccountType, $businessId: ID!, $plaidAccountId: String!, $publicToken: String!, $institutionName: String) {
  exchangePublicToken(
    accountType: $accountType
    businessId: $businessId
    plaidAccountId: $plaidAccountId
    publicToken: $publicToken
    institutionName: $institutionName
  ) {
    data {
      itemId
    }
    success
    code
    message
  }
}
    `;
export type ExchangePublicTokenMutationFn = Apollo.MutationFunction<ExchangePublicTokenMutation, ExchangePublicTokenMutationVariables>;
export type ExchangePublicTokenComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ExchangePublicTokenMutation, ExchangePublicTokenMutationVariables>, 'mutation'>;

    export const ExchangePublicTokenComponent = (props: ExchangePublicTokenComponentProps) => (
      <ApolloReactComponents.Mutation<ExchangePublicTokenMutation, ExchangePublicTokenMutationVariables> mutation={ExchangePublicTokenDocument} {...props} />
    );
    

/**
 * __useExchangePublicTokenMutation__
 *
 * To run a mutation, you first call `useExchangePublicTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExchangePublicTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exchangePublicTokenMutation, { data, loading, error }] = useExchangePublicTokenMutation({
 *   variables: {
 *      accountType: // value for 'accountType'
 *      businessId: // value for 'businessId'
 *      plaidAccountId: // value for 'plaidAccountId'
 *      publicToken: // value for 'publicToken'
 *      institutionName: // value for 'institutionName'
 *   },
 * });
 */
export function useExchangePublicTokenMutation(baseOptions?: Apollo.MutationHookOptions<ExchangePublicTokenMutation, ExchangePublicTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExchangePublicTokenMutation, ExchangePublicTokenMutationVariables>(ExchangePublicTokenDocument, options);
      }
export type ExchangePublicTokenMutationHookResult = ReturnType<typeof useExchangePublicTokenMutation>;
export type ExchangePublicTokenMutationResult = Apollo.MutationResult<ExchangePublicTokenMutation>;
export type ExchangePublicTokenMutationOptions = Apollo.BaseMutationOptions<ExchangePublicTokenMutation, ExchangePublicTokenMutationVariables>;
export const RemovePlaidItemDocument = gql`
    mutation RemovePlaidItem($businessId: ID!, $itemId: String!) {
  removePlaidItem(businessId: $businessId, itemId: $itemId) {
    code
    message
    success
  }
}
    `;
export type RemovePlaidItemMutationFn = Apollo.MutationFunction<RemovePlaidItemMutation, RemovePlaidItemMutationVariables>;
export type RemovePlaidItemComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemovePlaidItemMutation, RemovePlaidItemMutationVariables>, 'mutation'>;

    export const RemovePlaidItemComponent = (props: RemovePlaidItemComponentProps) => (
      <ApolloReactComponents.Mutation<RemovePlaidItemMutation, RemovePlaidItemMutationVariables> mutation={RemovePlaidItemDocument} {...props} />
    );
    

/**
 * __useRemovePlaidItemMutation__
 *
 * To run a mutation, you first call `useRemovePlaidItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePlaidItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePlaidItemMutation, { data, loading, error }] = useRemovePlaidItemMutation({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      itemId: // value for 'itemId'
 *   },
 * });
 */
export function useRemovePlaidItemMutation(baseOptions?: Apollo.MutationHookOptions<RemovePlaidItemMutation, RemovePlaidItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemovePlaidItemMutation, RemovePlaidItemMutationVariables>(RemovePlaidItemDocument, options);
      }
export type RemovePlaidItemMutationHookResult = ReturnType<typeof useRemovePlaidItemMutation>;
export type RemovePlaidItemMutationResult = Apollo.MutationResult<RemovePlaidItemMutation>;
export type RemovePlaidItemMutationOptions = Apollo.BaseMutationOptions<RemovePlaidItemMutation, RemovePlaidItemMutationVariables>;
export const PlaidItemsDocument = gql`
    query PlaidItems($businessId: ID!) {
  plaidItems(businessId: $businessId) {
    data {
      id
      status
      maskedAccountNumber
      institution {
        id
        name
        url
        logo
      }
    }
  }
  capabilities: plaidFundingCapabilities(businessId: $businessId) {
    items {
      maximumItemCount
    }
  }
}
    `;
export type PlaidItemsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<PlaidItemsQuery, PlaidItemsQueryVariables>, 'query'> & ({ variables: PlaidItemsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const PlaidItemsComponent = (props: PlaidItemsComponentProps) => (
      <ApolloReactComponents.Query<PlaidItemsQuery, PlaidItemsQueryVariables> query={PlaidItemsDocument} {...props} />
    );
    

/**
 * __usePlaidItemsQuery__
 *
 * To run a query within a React component, call `usePlaidItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlaidItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlaidItemsQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function usePlaidItemsQuery(baseOptions: Apollo.QueryHookOptions<PlaidItemsQuery, PlaidItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlaidItemsQuery, PlaidItemsQueryVariables>(PlaidItemsDocument, options);
      }
export function usePlaidItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlaidItemsQuery, PlaidItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlaidItemsQuery, PlaidItemsQueryVariables>(PlaidItemsDocument, options);
        }
export type PlaidItemsQueryHookResult = ReturnType<typeof usePlaidItemsQuery>;
export type PlaidItemsLazyQueryHookResult = ReturnType<typeof usePlaidItemsLazyQuery>;
export type PlaidItemsQueryResult = Apollo.QueryResult<PlaidItemsQuery, PlaidItemsQueryVariables>;
export const OwnerCandidatesDocument = gql`
    query ownerCandidates($businessId: ID!) {
  business(businessId: $businessId) {
    id
    ownerCandidates {
      id
      businessId
      userId
      firstName
      lastName
      type
      ownershipPercentage
      email
      title
    }
  }
}
    `;
export type OwnerCandidatesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<OwnerCandidatesQuery, OwnerCandidatesQueryVariables>, 'query'> & ({ variables: OwnerCandidatesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const OwnerCandidatesComponent = (props: OwnerCandidatesComponentProps) => (
      <ApolloReactComponents.Query<OwnerCandidatesQuery, OwnerCandidatesQueryVariables> query={OwnerCandidatesDocument} {...props} />
    );
    

/**
 * __useOwnerCandidatesQuery__
 *
 * To run a query within a React component, call `useOwnerCandidatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOwnerCandidatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOwnerCandidatesQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function useOwnerCandidatesQuery(baseOptions: Apollo.QueryHookOptions<OwnerCandidatesQuery, OwnerCandidatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OwnerCandidatesQuery, OwnerCandidatesQueryVariables>(OwnerCandidatesDocument, options);
      }
export function useOwnerCandidatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OwnerCandidatesQuery, OwnerCandidatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OwnerCandidatesQuery, OwnerCandidatesQueryVariables>(OwnerCandidatesDocument, options);
        }
export type OwnerCandidatesQueryHookResult = ReturnType<typeof useOwnerCandidatesQuery>;
export type OwnerCandidatesLazyQueryHookResult = ReturnType<typeof useOwnerCandidatesLazyQuery>;
export type OwnerCandidatesQueryResult = Apollo.QueryResult<OwnerCandidatesQuery, OwnerCandidatesQueryVariables>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($oldPassword: String!, $newPassword: String!) {
  resetPassword(newPassword: $newPassword, oldPassword: $oldPassword) {
    code
    success
    message
  }
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;
export type ResetPasswordComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ResetPasswordMutation, ResetPasswordMutationVariables>, 'mutation'>;

    export const ResetPasswordComponent = (props: ResetPasswordComponentProps) => (
      <ApolloReactComponents.Mutation<ResetPasswordMutation, ResetPasswordMutationVariables> mutation={ResetPasswordDocument} {...props} />
    );
    

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      oldPassword: // value for 'oldPassword'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const DeleteRecipientDocument = gql`
    mutation deleteRecipient($input: ContactDeleteInput!) {
  contactDelete(input: $input) {
    code
    success
    message
  }
}
    `;
export type DeleteRecipientMutationFn = Apollo.MutationFunction<DeleteRecipientMutation, DeleteRecipientMutationVariables>;
export type DeleteRecipientComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteRecipientMutation, DeleteRecipientMutationVariables>, 'mutation'>;

    export const DeleteRecipientComponent = (props: DeleteRecipientComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteRecipientMutation, DeleteRecipientMutationVariables> mutation={DeleteRecipientDocument} {...props} />
    );
    

/**
 * __useDeleteRecipientMutation__
 *
 * To run a mutation, you first call `useDeleteRecipientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRecipientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRecipientMutation, { data, loading, error }] = useDeleteRecipientMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteRecipientMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRecipientMutation, DeleteRecipientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRecipientMutation, DeleteRecipientMutationVariables>(DeleteRecipientDocument, options);
      }
export type DeleteRecipientMutationHookResult = ReturnType<typeof useDeleteRecipientMutation>;
export type DeleteRecipientMutationResult = Apollo.MutationResult<DeleteRecipientMutation>;
export type DeleteRecipientMutationOptions = Apollo.BaseMutationOptions<DeleteRecipientMutation, DeleteRecipientMutationVariables>;
export const AccountStatementsByYearDocument = gql`
    query accountStatementsByYear($businessId: ID!) {
  me {
    id
    wasExistingRadiusUser
  }
  business(businessId: $businessId) {
    id
    bankAccount {
      id
      accountStatementsByYear {
        year
        monthlyStatements {
          month
          year
          closingBalance
          totalDeposits
          totalWithdrawals
          totalFees
        }
      }
    }
  }
}
    `;
export type AccountStatementsByYearComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AccountStatementsByYearQuery, AccountStatementsByYearQueryVariables>, 'query'> & ({ variables: AccountStatementsByYearQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const AccountStatementsByYearComponent = (props: AccountStatementsByYearComponentProps) => (
      <ApolloReactComponents.Query<AccountStatementsByYearQuery, AccountStatementsByYearQueryVariables> query={AccountStatementsByYearDocument} {...props} />
    );
    

/**
 * __useAccountStatementsByYearQuery__
 *
 * To run a query within a React component, call `useAccountStatementsByYearQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountStatementsByYearQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountStatementsByYearQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function useAccountStatementsByYearQuery(baseOptions: Apollo.QueryHookOptions<AccountStatementsByYearQuery, AccountStatementsByYearQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountStatementsByYearQuery, AccountStatementsByYearQueryVariables>(AccountStatementsByYearDocument, options);
      }
export function useAccountStatementsByYearLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountStatementsByYearQuery, AccountStatementsByYearQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountStatementsByYearQuery, AccountStatementsByYearQueryVariables>(AccountStatementsByYearDocument, options);
        }
export type AccountStatementsByYearQueryHookResult = ReturnType<typeof useAccountStatementsByYearQuery>;
export type AccountStatementsByYearLazyQueryHookResult = ReturnType<typeof useAccountStatementsByYearLazyQuery>;
export type AccountStatementsByYearQueryResult = Apollo.QueryResult<AccountStatementsByYearQuery, AccountStatementsByYearQueryVariables>;
export const AccountStatementPdfDocument = gql`
    query accountStatementPdf($businessId: ID!, $year: String!, $month: String!) {
  business(businessId: $businessId) {
    id
    bankAccount {
      id
      accountStatementPdf(year: $year, month: $month) {
        month
        year
        pdf
      }
    }
  }
}
    `;
export type AccountStatementPdfComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AccountStatementPdfQuery, AccountStatementPdfQueryVariables>, 'query'> & ({ variables: AccountStatementPdfQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const AccountStatementPdfComponent = (props: AccountStatementPdfComponentProps) => (
      <ApolloReactComponents.Query<AccountStatementPdfQuery, AccountStatementPdfQueryVariables> query={AccountStatementPdfDocument} {...props} />
    );
    

/**
 * __useAccountStatementPdfQuery__
 *
 * To run a query within a React component, call `useAccountStatementPdfQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountStatementPdfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountStatementPdfQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      year: // value for 'year'
 *      month: // value for 'month'
 *   },
 * });
 */
export function useAccountStatementPdfQuery(baseOptions: Apollo.QueryHookOptions<AccountStatementPdfQuery, AccountStatementPdfQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountStatementPdfQuery, AccountStatementPdfQueryVariables>(AccountStatementPdfDocument, options);
      }
export function useAccountStatementPdfLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountStatementPdfQuery, AccountStatementPdfQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountStatementPdfQuery, AccountStatementPdfQueryVariables>(AccountStatementPdfDocument, options);
        }
export type AccountStatementPdfQueryHookResult = ReturnType<typeof useAccountStatementPdfQuery>;
export type AccountStatementPdfLazyQueryHookResult = ReturnType<typeof useAccountStatementPdfLazyQuery>;
export type AccountStatementPdfQueryResult = Apollo.QueryResult<AccountStatementPdfQuery, AccountStatementPdfQueryVariables>;
export const RegisterPersonDocument = gql`
    mutation registerPerson($businessId: String!, $personId: String!) {
  registerPerson(businessId: $businessId, personId: $personId) {
    success
    code
    message
    user {
      businessId
      email
      userId
      auth0Id
    }
  }
}
    `;
export type RegisterPersonMutationFn = Apollo.MutationFunction<RegisterPersonMutation, RegisterPersonMutationVariables>;
export type RegisterPersonComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RegisterPersonMutation, RegisterPersonMutationVariables>, 'mutation'>;

    export const RegisterPersonComponent = (props: RegisterPersonComponentProps) => (
      <ApolloReactComponents.Mutation<RegisterPersonMutation, RegisterPersonMutationVariables> mutation={RegisterPersonDocument} {...props} />
    );
    

/**
 * __useRegisterPersonMutation__
 *
 * To run a mutation, you first call `useRegisterPersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterPersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerPersonMutation, { data, loading, error }] = useRegisterPersonMutation({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useRegisterPersonMutation(baseOptions?: Apollo.MutationHookOptions<RegisterPersonMutation, RegisterPersonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterPersonMutation, RegisterPersonMutationVariables>(RegisterPersonDocument, options);
      }
export type RegisterPersonMutationHookResult = ReturnType<typeof useRegisterPersonMutation>;
export type RegisterPersonMutationResult = Apollo.MutationResult<RegisterPersonMutation>;
export type RegisterPersonMutationOptions = Apollo.BaseMutationOptions<RegisterPersonMutation, RegisterPersonMutationVariables>;
export const ListOwnersDocument = gql`
    query listOwners($businessId: ID!) {
  listOwners(businessId: $businessId) {
    owners {
      id
      userId
      businessId
      email
      firstName
      lastName
      title
      type
      ownershipPercentage
      onboardingCompleted
      isPrimaryOwner
    }
  }
}
    `;
export type ListOwnersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ListOwnersQuery, ListOwnersQueryVariables>, 'query'> & ({ variables: ListOwnersQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ListOwnersComponent = (props: ListOwnersComponentProps) => (
      <ApolloReactComponents.Query<ListOwnersQuery, ListOwnersQueryVariables> query={ListOwnersDocument} {...props} />
    );
    

/**
 * __useListOwnersQuery__
 *
 * To run a query within a React component, call `useListOwnersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListOwnersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListOwnersQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function useListOwnersQuery(baseOptions: Apollo.QueryHookOptions<ListOwnersQuery, ListOwnersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListOwnersQuery, ListOwnersQueryVariables>(ListOwnersDocument, options);
      }
export function useListOwnersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListOwnersQuery, ListOwnersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListOwnersQuery, ListOwnersQueryVariables>(ListOwnersDocument, options);
        }
export type ListOwnersQueryHookResult = ReturnType<typeof useListOwnersQuery>;
export type ListOwnersLazyQueryHookResult = ReturnType<typeof useListOwnersLazyQuery>;
export type ListOwnersQueryResult = Apollo.QueryResult<ListOwnersQuery, ListOwnersQueryVariables>;
export const SendInvitationDocument = gql`
    mutation sendInvitation($businessId: ID!, $userId: ID!) {
  inviteAdditionalOwner(businessId: $businessId, userId: $userId) {
    success
  }
}
    `;
export type SendInvitationMutationFn = Apollo.MutationFunction<SendInvitationMutation, SendInvitationMutationVariables>;
export type SendInvitationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SendInvitationMutation, SendInvitationMutationVariables>, 'mutation'>;

    export const SendInvitationComponent = (props: SendInvitationComponentProps) => (
      <ApolloReactComponents.Mutation<SendInvitationMutation, SendInvitationMutationVariables> mutation={SendInvitationDocument} {...props} />
    );
    

/**
 * __useSendInvitationMutation__
 *
 * To run a mutation, you first call `useSendInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendInvitationMutation, { data, loading, error }] = useSendInvitationMutation({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useSendInvitationMutation(baseOptions?: Apollo.MutationHookOptions<SendInvitationMutation, SendInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendInvitationMutation, SendInvitationMutationVariables>(SendInvitationDocument, options);
      }
export type SendInvitationMutationHookResult = ReturnType<typeof useSendInvitationMutation>;
export type SendInvitationMutationResult = Apollo.MutationResult<SendInvitationMutation>;
export type SendInvitationMutationOptions = Apollo.BaseMutationOptions<SendInvitationMutation, SendInvitationMutationVariables>;
export const ShareAndEarnHubDocument = gql`
    query shareAndEarnHub($businessId: ID!) {
  business(businessId: $businessId) {
    shareAndEarn {
      advocate {
        offerUrl
        isBlocked
        isEligible
        totalRedeemedOffers
        totalRedeemedAmount
      }
    }
  }
}
    `;
export type ShareAndEarnHubComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ShareAndEarnHubQuery, ShareAndEarnHubQueryVariables>, 'query'> & ({ variables: ShareAndEarnHubQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ShareAndEarnHubComponent = (props: ShareAndEarnHubComponentProps) => (
      <ApolloReactComponents.Query<ShareAndEarnHubQuery, ShareAndEarnHubQueryVariables> query={ShareAndEarnHubDocument} {...props} />
    );
    

/**
 * __useShareAndEarnHubQuery__
 *
 * To run a query within a React component, call `useShareAndEarnHubQuery` and pass it any options that fit your needs.
 * When your component renders, `useShareAndEarnHubQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShareAndEarnHubQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function useShareAndEarnHubQuery(baseOptions: Apollo.QueryHookOptions<ShareAndEarnHubQuery, ShareAndEarnHubQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShareAndEarnHubQuery, ShareAndEarnHubQueryVariables>(ShareAndEarnHubDocument, options);
      }
export function useShareAndEarnHubLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShareAndEarnHubQuery, ShareAndEarnHubQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShareAndEarnHubQuery, ShareAndEarnHubQueryVariables>(ShareAndEarnHubDocument, options);
        }
export type ShareAndEarnHubQueryHookResult = ReturnType<typeof useShareAndEarnHubQuery>;
export type ShareAndEarnHubLazyQueryHookResult = ReturnType<typeof useShareAndEarnHubLazyQuery>;
export type ShareAndEarnHubQueryResult = Apollo.QueryResult<ShareAndEarnHubQuery, ShareAndEarnHubQueryVariables>;
export const ShareAndEarnResendBlockedCustomerEmailDocument = gql`
    mutation shareAndEarnResendBlockedCustomerEmail($businessId: ID!, $advocateId: ID!) {
  shareAndEarnResendBlockedCustomerEmail(
    businessId: $businessId
    advocateId: $advocateId
  ) {
    success
  }
}
    `;
export type ShareAndEarnResendBlockedCustomerEmailMutationFn = Apollo.MutationFunction<ShareAndEarnResendBlockedCustomerEmailMutation, ShareAndEarnResendBlockedCustomerEmailMutationVariables>;
export type ShareAndEarnResendBlockedCustomerEmailComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ShareAndEarnResendBlockedCustomerEmailMutation, ShareAndEarnResendBlockedCustomerEmailMutationVariables>, 'mutation'>;

    export const ShareAndEarnResendBlockedCustomerEmailComponent = (props: ShareAndEarnResendBlockedCustomerEmailComponentProps) => (
      <ApolloReactComponents.Mutation<ShareAndEarnResendBlockedCustomerEmailMutation, ShareAndEarnResendBlockedCustomerEmailMutationVariables> mutation={ShareAndEarnResendBlockedCustomerEmailDocument} {...props} />
    );
    

/**
 * __useShareAndEarnResendBlockedCustomerEmailMutation__
 *
 * To run a mutation, you first call `useShareAndEarnResendBlockedCustomerEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShareAndEarnResendBlockedCustomerEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shareAndEarnResendBlockedCustomerEmailMutation, { data, loading, error }] = useShareAndEarnResendBlockedCustomerEmailMutation({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      advocateId: // value for 'advocateId'
 *   },
 * });
 */
export function useShareAndEarnResendBlockedCustomerEmailMutation(baseOptions?: Apollo.MutationHookOptions<ShareAndEarnResendBlockedCustomerEmailMutation, ShareAndEarnResendBlockedCustomerEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ShareAndEarnResendBlockedCustomerEmailMutation, ShareAndEarnResendBlockedCustomerEmailMutationVariables>(ShareAndEarnResendBlockedCustomerEmailDocument, options);
      }
export type ShareAndEarnResendBlockedCustomerEmailMutationHookResult = ReturnType<typeof useShareAndEarnResendBlockedCustomerEmailMutation>;
export type ShareAndEarnResendBlockedCustomerEmailMutationResult = Apollo.MutationResult<ShareAndEarnResendBlockedCustomerEmailMutation>;
export type ShareAndEarnResendBlockedCustomerEmailMutationOptions = Apollo.BaseMutationOptions<ShareAndEarnResendBlockedCustomerEmailMutation, ShareAndEarnResendBlockedCustomerEmailMutationVariables>;
export const BusinessDetailsDocument = gql`
    query businessDetails($businessId: ID!) {
  business(businessId: $businessId) {
    id
    name
  }
}
    `;
export type BusinessDetailsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<BusinessDetailsQuery, BusinessDetailsQueryVariables>, 'query'> & ({ variables: BusinessDetailsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const BusinessDetailsComponent = (props: BusinessDetailsComponentProps) => (
      <ApolloReactComponents.Query<BusinessDetailsQuery, BusinessDetailsQueryVariables> query={BusinessDetailsDocument} {...props} />
    );
    

/**
 * __useBusinessDetailsQuery__
 *
 * To run a query within a React component, call `useBusinessDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessDetailsQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function useBusinessDetailsQuery(baseOptions: Apollo.QueryHookOptions<BusinessDetailsQuery, BusinessDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BusinessDetailsQuery, BusinessDetailsQueryVariables>(BusinessDetailsDocument, options);
      }
export function useBusinessDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BusinessDetailsQuery, BusinessDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BusinessDetailsQuery, BusinessDetailsQueryVariables>(BusinessDetailsDocument, options);
        }
export type BusinessDetailsQueryHookResult = ReturnType<typeof useBusinessDetailsQuery>;
export type BusinessDetailsLazyQueryHookResult = ReturnType<typeof useBusinessDetailsLazyQuery>;
export type BusinessDetailsQueryResult = Apollo.QueryResult<BusinessDetailsQuery, BusinessDetailsQueryVariables>;
export const BankAccountInfoDocument = gql`
    query bankAccountInfo($businessId: ID!) {
  business(businessId: $businessId) {
    id
    bankAccount {
      id
      availableBalance
      routingNumber
      accountNumber
      bankName
      bankAddress
    }
  }
}
    `;
export type BankAccountInfoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<BankAccountInfoQuery, BankAccountInfoQueryVariables>, 'query'> & ({ variables: BankAccountInfoQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const BankAccountInfoComponent = (props: BankAccountInfoComponentProps) => (
      <ApolloReactComponents.Query<BankAccountInfoQuery, BankAccountInfoQueryVariables> query={BankAccountInfoDocument} {...props} />
    );
    

/**
 * __useBankAccountInfoQuery__
 *
 * To run a query within a React component, call `useBankAccountInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useBankAccountInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBankAccountInfoQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function useBankAccountInfoQuery(baseOptions: Apollo.QueryHookOptions<BankAccountInfoQuery, BankAccountInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BankAccountInfoQuery, BankAccountInfoQueryVariables>(BankAccountInfoDocument, options);
      }
export function useBankAccountInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BankAccountInfoQuery, BankAccountInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BankAccountInfoQuery, BankAccountInfoQueryVariables>(BankAccountInfoDocument, options);
        }
export type BankAccountInfoQueryHookResult = ReturnType<typeof useBankAccountInfoQuery>;
export type BankAccountInfoLazyQueryHookResult = ReturnType<typeof useBankAccountInfoLazyQuery>;
export type BankAccountInfoQueryResult = Apollo.QueryResult<BankAccountInfoQuery, BankAccountInfoQueryVariables>;
export const BusinessInfoDocument = gql`
    query businessInfo($businessId: ID!) {
  business(businessId: $businessId) {
    id
    name
    bankAccount {
      id
      availableBalance
      subAccounts {
        id
        name
        balance
        isDefault
      }
    }
  }
}
    `;
export type BusinessInfoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<BusinessInfoQuery, BusinessInfoQueryVariables>, 'query'> & ({ variables: BusinessInfoQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const BusinessInfoComponent = (props: BusinessInfoComponentProps) => (
      <ApolloReactComponents.Query<BusinessInfoQuery, BusinessInfoQueryVariables> query={BusinessInfoDocument} {...props} />
    );
    

/**
 * __useBusinessInfoQuery__
 *
 * To run a query within a React component, call `useBusinessInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessInfoQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function useBusinessInfoQuery(baseOptions: Apollo.QueryHookOptions<BusinessInfoQuery, BusinessInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BusinessInfoQuery, BusinessInfoQueryVariables>(BusinessInfoDocument, options);
      }
export function useBusinessInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BusinessInfoQuery, BusinessInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BusinessInfoQuery, BusinessInfoQueryVariables>(BusinessInfoDocument, options);
        }
export type BusinessInfoQueryHookResult = ReturnType<typeof useBusinessInfoQuery>;
export type BusinessInfoLazyQueryHookResult = ReturnType<typeof useBusinessInfoLazyQuery>;
export type BusinessInfoQueryResult = Apollo.QueryResult<BusinessInfoQuery, BusinessInfoQueryVariables>;
export const SubscriptionPlansDataDocument = gql`
    query subscriptionPlansData($businessId: ID!) {
  subscriptionPlans(businessId: $businessId) {
    footer
    plans {
      name
      isCurrentPlan
      content {
        title
        shortTitle
        subtitle
        upgradeButtonText
        description
        featured
        name
        price {
          period
          price
          text
        }
        upgrade {
          successTitle
          successSubtitle
        }
        upsell {
          title
          subtitle
        }
        retentionFeatures {
          icon
          text
        }
        sections {
          heading
          bullets {
            icon
            text
          }
        }
      }
    }
  }
  subscription(businessId: $businessId) {
    status
    accountId
    plan
    price
    paymentPeriod
    startDate
    nextBillingDate
  }
}
    `;
export type SubscriptionPlansDataComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SubscriptionPlansDataQuery, SubscriptionPlansDataQueryVariables>, 'query'> & ({ variables: SubscriptionPlansDataQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const SubscriptionPlansDataComponent = (props: SubscriptionPlansDataComponentProps) => (
      <ApolloReactComponents.Query<SubscriptionPlansDataQuery, SubscriptionPlansDataQueryVariables> query={SubscriptionPlansDataDocument} {...props} />
    );
    

/**
 * __useSubscriptionPlansDataQuery__
 *
 * To run a query within a React component, call `useSubscriptionPlansDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionPlansDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscriptionPlansDataQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function useSubscriptionPlansDataQuery(baseOptions: Apollo.QueryHookOptions<SubscriptionPlansDataQuery, SubscriptionPlansDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubscriptionPlansDataQuery, SubscriptionPlansDataQueryVariables>(SubscriptionPlansDataDocument, options);
      }
export function useSubscriptionPlansDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubscriptionPlansDataQuery, SubscriptionPlansDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubscriptionPlansDataQuery, SubscriptionPlansDataQueryVariables>(SubscriptionPlansDataDocument, options);
        }
export type SubscriptionPlansDataQueryHookResult = ReturnType<typeof useSubscriptionPlansDataQuery>;
export type SubscriptionPlansDataLazyQueryHookResult = ReturnType<typeof useSubscriptionPlansDataLazyQuery>;
export type SubscriptionPlansDataQueryResult = Apollo.QueryResult<SubscriptionPlansDataQuery, SubscriptionPlansDataQueryVariables>;
export const SubscriptionDocument = gql`
    query subscription($businessId: ID!) {
  subscription(businessId: $businessId) {
    status
    accountId
    plan
    price
    paymentPeriod
    startDate
    nextBillingDate
  }
}
    `;
export type SubscriptionComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SubscriptionQuery, SubscriptionQueryVariables>, 'query'> & ({ variables: SubscriptionQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const SubscriptionComponent = (props: SubscriptionComponentProps) => (
      <ApolloReactComponents.Query<SubscriptionQuery, SubscriptionQueryVariables> query={SubscriptionDocument} {...props} />
    );
    

/**
 * __useSubscriptionQuery__
 *
 * To run a query within a React component, call `useSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscriptionQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function useSubscriptionQuery(baseOptions: Apollo.QueryHookOptions<SubscriptionQuery, SubscriptionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubscriptionQuery, SubscriptionQueryVariables>(SubscriptionDocument, options);
      }
export function useSubscriptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubscriptionQuery, SubscriptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubscriptionQuery, SubscriptionQueryVariables>(SubscriptionDocument, options);
        }
export type SubscriptionQueryHookResult = ReturnType<typeof useSubscriptionQuery>;
export type SubscriptionLazyQueryHookResult = ReturnType<typeof useSubscriptionLazyQuery>;
export type SubscriptionQueryResult = Apollo.QueryResult<SubscriptionQuery, SubscriptionQueryVariables>;
export const SubscriptionUpgradeDocument = gql`
    mutation subscriptionUpgrade($input: SubscriptionUpgradeInput!) {
  subscriptionUpgrade(input: $input) {
    code
    message
    subscription {
      status
      accountId
      plan
      price
      paymentPeriod
      startDate
      nextBillingDate
    }
  }
}
    `;
export type SubscriptionUpgradeMutationFn = Apollo.MutationFunction<SubscriptionUpgradeMutation, SubscriptionUpgradeMutationVariables>;
export type SubscriptionUpgradeComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SubscriptionUpgradeMutation, SubscriptionUpgradeMutationVariables>, 'mutation'>;

    export const SubscriptionUpgradeComponent = (props: SubscriptionUpgradeComponentProps) => (
      <ApolloReactComponents.Mutation<SubscriptionUpgradeMutation, SubscriptionUpgradeMutationVariables> mutation={SubscriptionUpgradeDocument} {...props} />
    );
    

/**
 * __useSubscriptionUpgradeMutation__
 *
 * To run a mutation, you first call `useSubscriptionUpgradeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionUpgradeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subscriptionUpgradeMutation, { data, loading, error }] = useSubscriptionUpgradeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubscriptionUpgradeMutation(baseOptions?: Apollo.MutationHookOptions<SubscriptionUpgradeMutation, SubscriptionUpgradeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubscriptionUpgradeMutation, SubscriptionUpgradeMutationVariables>(SubscriptionUpgradeDocument, options);
      }
export type SubscriptionUpgradeMutationHookResult = ReturnType<typeof useSubscriptionUpgradeMutation>;
export type SubscriptionUpgradeMutationResult = Apollo.MutationResult<SubscriptionUpgradeMutation>;
export type SubscriptionUpgradeMutationOptions = Apollo.BaseMutationOptions<SubscriptionUpgradeMutation, SubscriptionUpgradeMutationVariables>;
export const SubscriptionCancelDocument = gql`
    mutation subscriptionCancel($input: SubscriptionCancelInput!) {
  subscriptionCancel(input: $input) {
    code
    success
    message
    subscription {
      accountId
      plan
      price
      paymentPeriod
      startDate
      nextBillingDate
      status
    }
  }
}
    `;
export type SubscriptionCancelMutationFn = Apollo.MutationFunction<SubscriptionCancelMutation, SubscriptionCancelMutationVariables>;
export type SubscriptionCancelComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SubscriptionCancelMutation, SubscriptionCancelMutationVariables>, 'mutation'>;

    export const SubscriptionCancelComponent = (props: SubscriptionCancelComponentProps) => (
      <ApolloReactComponents.Mutation<SubscriptionCancelMutation, SubscriptionCancelMutationVariables> mutation={SubscriptionCancelDocument} {...props} />
    );
    

/**
 * __useSubscriptionCancelMutation__
 *
 * To run a mutation, you first call `useSubscriptionCancelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionCancelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subscriptionCancelMutation, { data, loading, error }] = useSubscriptionCancelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubscriptionCancelMutation(baseOptions?: Apollo.MutationHookOptions<SubscriptionCancelMutation, SubscriptionCancelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubscriptionCancelMutation, SubscriptionCancelMutationVariables>(SubscriptionCancelDocument, options);
      }
export type SubscriptionCancelMutationHookResult = ReturnType<typeof useSubscriptionCancelMutation>;
export type SubscriptionCancelMutationResult = Apollo.MutationResult<SubscriptionCancelMutation>;
export type SubscriptionCancelMutationOptions = Apollo.BaseMutationOptions<SubscriptionCancelMutation, SubscriptionCancelMutationVariables>;
export const SubscriptionReactivateDocument = gql`
    mutation subscriptionReactivate($input: SubscriptionReactivateInput!) {
  subscriptionReactivate(input: $input) {
    code
    message
    success
    subscription {
      accountId
      plan
      price
      paymentPeriod
      startDate
      nextBillingDate
      status
    }
  }
}
    `;
export type SubscriptionReactivateMutationFn = Apollo.MutationFunction<SubscriptionReactivateMutation, SubscriptionReactivateMutationVariables>;
export type SubscriptionReactivateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SubscriptionReactivateMutation, SubscriptionReactivateMutationVariables>, 'mutation'>;

    export const SubscriptionReactivateComponent = (props: SubscriptionReactivateComponentProps) => (
      <ApolloReactComponents.Mutation<SubscriptionReactivateMutation, SubscriptionReactivateMutationVariables> mutation={SubscriptionReactivateDocument} {...props} />
    );
    

/**
 * __useSubscriptionReactivateMutation__
 *
 * To run a mutation, you first call `useSubscriptionReactivateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionReactivateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subscriptionReactivateMutation, { data, loading, error }] = useSubscriptionReactivateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubscriptionReactivateMutation(baseOptions?: Apollo.MutationHookOptions<SubscriptionReactivateMutation, SubscriptionReactivateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubscriptionReactivateMutation, SubscriptionReactivateMutationVariables>(SubscriptionReactivateDocument, options);
      }
export type SubscriptionReactivateMutationHookResult = ReturnType<typeof useSubscriptionReactivateMutation>;
export type SubscriptionReactivateMutationResult = Apollo.MutationResult<SubscriptionReactivateMutation>;
export type SubscriptionReactivateMutationOptions = Apollo.BaseMutationOptions<SubscriptionReactivateMutation, SubscriptionReactivateMutationVariables>;
export const TransactionUpsellContentDocument = gql`
    query transactionUpsellContent($businessId: ID!, $transactionType: SubscriptionUpsellTransactionType!) {
  transactionUpsellContentPerType(
    businessId: $businessId
    transactionType: $transactionType
  ) {
    upsellPlan {
      planName
      buttonContent
      discountFeeDetails {
        ... on FlatFeeDetails {
          feeType
          amount
        }
        ... on PercentFeeDetails {
          feeType
          minimumAmount
          maximumAmount
          percent
        }
      }
    }
  }
}
    `;
export type TransactionUpsellContentComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<TransactionUpsellContentQuery, TransactionUpsellContentQueryVariables>, 'query'> & ({ variables: TransactionUpsellContentQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const TransactionUpsellContentComponent = (props: TransactionUpsellContentComponentProps) => (
      <ApolloReactComponents.Query<TransactionUpsellContentQuery, TransactionUpsellContentQueryVariables> query={TransactionUpsellContentDocument} {...props} />
    );
    

/**
 * __useTransactionUpsellContentQuery__
 *
 * To run a query within a React component, call `useTransactionUpsellContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransactionUpsellContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransactionUpsellContentQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      transactionType: // value for 'transactionType'
 *   },
 * });
 */
export function useTransactionUpsellContentQuery(baseOptions: Apollo.QueryHookOptions<TransactionUpsellContentQuery, TransactionUpsellContentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TransactionUpsellContentQuery, TransactionUpsellContentQueryVariables>(TransactionUpsellContentDocument, options);
      }
export function useTransactionUpsellContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TransactionUpsellContentQuery, TransactionUpsellContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TransactionUpsellContentQuery, TransactionUpsellContentQueryVariables>(TransactionUpsellContentDocument, options);
        }
export type TransactionUpsellContentQueryHookResult = ReturnType<typeof useTransactionUpsellContentQuery>;
export type TransactionUpsellContentLazyQueryHookResult = ReturnType<typeof useTransactionUpsellContentLazyQuery>;
export type TransactionUpsellContentQueryResult = Apollo.QueryResult<TransactionUpsellContentQuery, TransactionUpsellContentQueryVariables>;
export const FaqLandingDocument = gql`
    query faqLanding {
  supportHelpCenter {
    sections {
      id
      name
    }
  }
}
    `;
export type FaqLandingComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<FaqLandingQuery, FaqLandingQueryVariables>, 'query'>;

    export const FaqLandingComponent = (props: FaqLandingComponentProps) => (
      <ApolloReactComponents.Query<FaqLandingQuery, FaqLandingQueryVariables> query={FaqLandingDocument} {...props} />
    );
    

/**
 * __useFaqLandingQuery__
 *
 * To run a query within a React component, call `useFaqLandingQuery` and pass it any options that fit your needs.
 * When your component renders, `useFaqLandingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFaqLandingQuery({
 *   variables: {
 *   },
 * });
 */
export function useFaqLandingQuery(baseOptions?: Apollo.QueryHookOptions<FaqLandingQuery, FaqLandingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FaqLandingQuery, FaqLandingQueryVariables>(FaqLandingDocument, options);
      }
export function useFaqLandingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FaqLandingQuery, FaqLandingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FaqLandingQuery, FaqLandingQueryVariables>(FaqLandingDocument, options);
        }
export type FaqLandingQueryHookResult = ReturnType<typeof useFaqLandingQuery>;
export type FaqLandingLazyQueryHookResult = ReturnType<typeof useFaqLandingLazyQuery>;
export type FaqLandingQueryResult = Apollo.QueryResult<FaqLandingQuery, FaqLandingQueryVariables>;
export const FaqCategoryDocument = gql`
    query faqCategory($sectionId: ID) {
  supportHelpCenter {
    faqs(sectionId: $sectionId) {
      id
      title
    }
  }
}
    `;
export type FaqCategoryComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<FaqCategoryQuery, FaqCategoryQueryVariables>, 'query'>;

    export const FaqCategoryComponent = (props: FaqCategoryComponentProps) => (
      <ApolloReactComponents.Query<FaqCategoryQuery, FaqCategoryQueryVariables> query={FaqCategoryDocument} {...props} />
    );
    

/**
 * __useFaqCategoryQuery__
 *
 * To run a query within a React component, call `useFaqCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useFaqCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFaqCategoryQuery({
 *   variables: {
 *      sectionId: // value for 'sectionId'
 *   },
 * });
 */
export function useFaqCategoryQuery(baseOptions?: Apollo.QueryHookOptions<FaqCategoryQuery, FaqCategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FaqCategoryQuery, FaqCategoryQueryVariables>(FaqCategoryDocument, options);
      }
export function useFaqCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FaqCategoryQuery, FaqCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FaqCategoryQuery, FaqCategoryQueryVariables>(FaqCategoryDocument, options);
        }
export type FaqCategoryQueryHookResult = ReturnType<typeof useFaqCategoryQuery>;
export type FaqCategoryLazyQueryHookResult = ReturnType<typeof useFaqCategoryLazyQuery>;
export type FaqCategoryQueryResult = Apollo.QueryResult<FaqCategoryQuery, FaqCategoryQueryVariables>;
export const FaqArticleDocument = gql`
    query faqArticle($articleId: ID!) {
  supportHelpCenter {
    article(articleId: $articleId) {
      id
      title
      body
    }
  }
}
    `;
export type FaqArticleComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<FaqArticleQuery, FaqArticleQueryVariables>, 'query'> & ({ variables: FaqArticleQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const FaqArticleComponent = (props: FaqArticleComponentProps) => (
      <ApolloReactComponents.Query<FaqArticleQuery, FaqArticleQueryVariables> query={FaqArticleDocument} {...props} />
    );
    

/**
 * __useFaqArticleQuery__
 *
 * To run a query within a React component, call `useFaqArticleQuery` and pass it any options that fit your needs.
 * When your component renders, `useFaqArticleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFaqArticleQuery({
 *   variables: {
 *      articleId: // value for 'articleId'
 *   },
 * });
 */
export function useFaqArticleQuery(baseOptions: Apollo.QueryHookOptions<FaqArticleQuery, FaqArticleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FaqArticleQuery, FaqArticleQueryVariables>(FaqArticleDocument, options);
      }
export function useFaqArticleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FaqArticleQuery, FaqArticleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FaqArticleQuery, FaqArticleQueryVariables>(FaqArticleDocument, options);
        }
export type FaqArticleQueryHookResult = ReturnType<typeof useFaqArticleQuery>;
export type FaqArticleLazyQueryHookResult = ReturnType<typeof useFaqArticleLazyQuery>;
export type FaqArticleQueryResult = Apollo.QueryResult<FaqArticleQuery, FaqArticleQueryVariables>;
export const FaqSearchDocument = gql`
    query faqSearch($searchTerm: String!) {
  supportHelpCenter {
    search {
      faqs(searchTerm: $searchTerm) {
        id
        title
      }
    }
  }
}
    `;
export type FaqSearchComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<FaqSearchQuery, FaqSearchQueryVariables>, 'query'> & ({ variables: FaqSearchQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const FaqSearchComponent = (props: FaqSearchComponentProps) => (
      <ApolloReactComponents.Query<FaqSearchQuery, FaqSearchQueryVariables> query={FaqSearchDocument} {...props} />
    );
    

/**
 * __useFaqSearchQuery__
 *
 * To run a query within a React component, call `useFaqSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useFaqSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFaqSearchQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *   },
 * });
 */
export function useFaqSearchQuery(baseOptions: Apollo.QueryHookOptions<FaqSearchQuery, FaqSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FaqSearchQuery, FaqSearchQueryVariables>(FaqSearchDocument, options);
      }
export function useFaqSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FaqSearchQuery, FaqSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FaqSearchQuery, FaqSearchQueryVariables>(FaqSearchDocument, options);
        }
export type FaqSearchQueryHookResult = ReturnType<typeof useFaqSearchQuery>;
export type FaqSearchLazyQueryHookResult = ReturnType<typeof useFaqSearchLazyQuery>;
export type FaqSearchQueryResult = Apollo.QueryResult<FaqSearchQuery, FaqSearchQueryVariables>;
export const AvailableSlotsForWeekDocument = gql`
    query availableSlotsForWeek($timezone: String!) {
  supportPhoneCall {
    availableSlotsForWeek(timezone: $timezone) {
      date
      slots
    }
  }
}
    `;
export type AvailableSlotsForWeekComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AvailableSlotsForWeekQuery, AvailableSlotsForWeekQueryVariables>, 'query'> & ({ variables: AvailableSlotsForWeekQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const AvailableSlotsForWeekComponent = (props: AvailableSlotsForWeekComponentProps) => (
      <ApolloReactComponents.Query<AvailableSlotsForWeekQuery, AvailableSlotsForWeekQueryVariables> query={AvailableSlotsForWeekDocument} {...props} />
    );
    

/**
 * __useAvailableSlotsForWeekQuery__
 *
 * To run a query within a React component, call `useAvailableSlotsForWeekQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableSlotsForWeekQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableSlotsForWeekQuery({
 *   variables: {
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useAvailableSlotsForWeekQuery(baseOptions: Apollo.QueryHookOptions<AvailableSlotsForWeekQuery, AvailableSlotsForWeekQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AvailableSlotsForWeekQuery, AvailableSlotsForWeekQueryVariables>(AvailableSlotsForWeekDocument, options);
      }
export function useAvailableSlotsForWeekLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AvailableSlotsForWeekQuery, AvailableSlotsForWeekQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AvailableSlotsForWeekQuery, AvailableSlotsForWeekQueryVariables>(AvailableSlotsForWeekDocument, options);
        }
export type AvailableSlotsForWeekQueryHookResult = ReturnType<typeof useAvailableSlotsForWeekQuery>;
export type AvailableSlotsForWeekLazyQueryHookResult = ReturnType<typeof useAvailableSlotsForWeekLazyQuery>;
export type AvailableSlotsForWeekQueryResult = Apollo.QueryResult<AvailableSlotsForWeekQuery, AvailableSlotsForWeekQueryVariables>;
export const AvailableSlotsForDayDocument = gql`
    query availableSlotsForDay($date: String!, $timezone: String!) {
  supportPhoneCall {
    availableSlotsForDay(date: $date, timezone: $timezone)
  }
}
    `;
export type AvailableSlotsForDayComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AvailableSlotsForDayQuery, AvailableSlotsForDayQueryVariables>, 'query'> & ({ variables: AvailableSlotsForDayQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const AvailableSlotsForDayComponent = (props: AvailableSlotsForDayComponentProps) => (
      <ApolloReactComponents.Query<AvailableSlotsForDayQuery, AvailableSlotsForDayQueryVariables> query={AvailableSlotsForDayDocument} {...props} />
    );
    

/**
 * __useAvailableSlotsForDayQuery__
 *
 * To run a query within a React component, call `useAvailableSlotsForDayQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableSlotsForDayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableSlotsForDayQuery({
 *   variables: {
 *      date: // value for 'date'
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useAvailableSlotsForDayQuery(baseOptions: Apollo.QueryHookOptions<AvailableSlotsForDayQuery, AvailableSlotsForDayQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AvailableSlotsForDayQuery, AvailableSlotsForDayQueryVariables>(AvailableSlotsForDayDocument, options);
      }
export function useAvailableSlotsForDayLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AvailableSlotsForDayQuery, AvailableSlotsForDayQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AvailableSlotsForDayQuery, AvailableSlotsForDayQueryVariables>(AvailableSlotsForDayDocument, options);
        }
export type AvailableSlotsForDayQueryHookResult = ReturnType<typeof useAvailableSlotsForDayQuery>;
export type AvailableSlotsForDayLazyQueryHookResult = ReturnType<typeof useAvailableSlotsForDayLazyQuery>;
export type AvailableSlotsForDayQueryResult = Apollo.QueryResult<AvailableSlotsForDayQuery, AvailableSlotsForDayQueryVariables>;
export const BookPhoneCallDocument = gql`
    mutation bookPhoneCall($data: SupportPhoneCallCreateInput!) {
  supportPhoneCallBookingCreate(data: $data) {
    supportPhoneCallBooking {
      dateTime
      confirmationEmail
    }
    userErrors {
      code
      message
      field
    }
  }
}
    `;
export type BookPhoneCallMutationFn = Apollo.MutationFunction<BookPhoneCallMutation, BookPhoneCallMutationVariables>;
export type BookPhoneCallComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<BookPhoneCallMutation, BookPhoneCallMutationVariables>, 'mutation'>;

    export const BookPhoneCallComponent = (props: BookPhoneCallComponentProps) => (
      <ApolloReactComponents.Mutation<BookPhoneCallMutation, BookPhoneCallMutationVariables> mutation={BookPhoneCallDocument} {...props} />
    );
    

/**
 * __useBookPhoneCallMutation__
 *
 * To run a mutation, you first call `useBookPhoneCallMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookPhoneCallMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookPhoneCallMutation, { data, loading, error }] = useBookPhoneCallMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useBookPhoneCallMutation(baseOptions?: Apollo.MutationHookOptions<BookPhoneCallMutation, BookPhoneCallMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BookPhoneCallMutation, BookPhoneCallMutationVariables>(BookPhoneCallDocument, options);
      }
export type BookPhoneCallMutationHookResult = ReturnType<typeof useBookPhoneCallMutation>;
export type BookPhoneCallMutationResult = Apollo.MutationResult<BookPhoneCallMutation>;
export type BookPhoneCallMutationOptions = Apollo.BaseMutationOptions<BookPhoneCallMutation, BookPhoneCallMutationVariables>;