import { GetRevenueHubConnectionQuery, Maybe } from "@generated/graphql"
import { formatMoney as formatMoneyAccounting } from "accounting"

export type RevenueDetails =
  GetRevenueHubConnectionQuery["revenueHub"]["connection"]["revenueDetails"]

export const formatMoney = (amount: Maybe<number> | undefined) => {
  if (!amount) return "$0"
  return `${formatMoneyAccounting(Math.abs(amount))}`
}

export const isBalanceSupportedByPlatform = (name: string): boolean => {
  return name !== "Etsy" && name !== "Square"
}
