import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import { useAppSelector } from "@core/redux/utils"
import { selectActiveBusinessID } from "@core/active-business/redux/selectors"
import { Analytics } from "@core/analytics/actions"
import { events } from "@core/analytics/events"
import { RoutesEnum } from "@routers/types"
import { FormBody } from "@components/composite/form-body/form-body"
import { ButtonTypeEnum } from "@components/primitive/button/button"
import { addFundsActions } from "@features/add-funds/redux/actions"
import { DisplayOnlyField } from "@components/extended/fields/display-only-field"
import { QueryResults } from "@components/composite/query-results/query-results"
import { Text } from "@components/primitive/text/text"
import { useBankAccountInfoQuery } from "@generated/graphql"

interface IAddFundsACHWireThirdPartyProps {
  title: string
  subtitle: string
  imageSrc?: string
}

export const AddFundsACHWireThirdParty: React.FC<IAddFundsACHWireThirdPartyProps> = ({
  title,
  subtitle,
  imageSrc,
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const businessId = useAppSelector(selectActiveBusinessID)

  const {
    data,
    loading: bankAccountInfoLoading,
    error: bankAccountInfoError,
    refetch,
  } = useBankAccountInfoQuery({ variables: { businessId } })

  return (
    <FormBody
      imageSrc={imageSrc}
      title={title}
      subtitle={subtitle}
      buttons={[
        {
          children: t("topUp.addFunds.back"),
          type: ButtonTypeEnum.TERTIARY,
          onClick: () => {
            dispatch(addFundsActions.navigateBack())
          },
        },
        {
          children: t("buttons.done"),
          type: ButtonTypeEnum.SECONDARY,
          onClick: () => {
            dispatch(addFundsActions.clearState())
            history.push(RoutesEnum.DASHBOARD)
          },
        },
      ]}
    >
      <QueryResults
        size="medium"
        retry={() => refetch()}
        error={bankAccountInfoError}
        loading={bankAccountInfoLoading}
        listShimmerCount={4}
      >
        <Text sx={{ marginBottom: 12 }} tag="body-bold">
          {t("topUp.addFunds.accountDetails.heading")}
        </Text>
        <DisplayOnlyField
          onCopy={() => Analytics.track(events.addFunds.funded.copyFields.accountNumber)}
          label={t("topUp.addFunds.accountDetails.accountNumber")}
          value={data?.business?.bankAccount?.accountNumber || ""}
        />
        <DisplayOnlyField
          onCopy={() => Analytics.track(events.addFunds.funded.copyFields.routingNumber)}
          label={t("topUp.addFunds.accountDetails.routingNumber")}
          value={data?.business?.bankAccount?.routingNumber || ""}
        />
        <DisplayOnlyField
          label={t("topUp.addFunds.accountDetails.bankName")}
          value={data?.business?.bankAccount?.bankName || ""}
          infoText={t("dashboard.sidebar.bankNameHelp")}
          onCopy={() => Analytics.track(events.addFunds.funded.copyFields.bankName)}
        />
        <DisplayOnlyField
          label={t("topUp.addFunds.accountDetails.bankAddress")}
          value={data?.business?.bankAccount?.bankAddress || ""}
          onCopy={() => Analytics.track(events.addFunds.funded.copyFields.bankAddress)}
        />
      </QueryResults>
    </FormBody>
  )
}
