import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useParams, useHistory } from "react-router-dom"
import { useAppSelector } from "@core/redux/utils"
import { RoutesEnum } from "@routers/types"
import { Text } from "@components/primitive/text/text"
import { BackButton } from "@components/composite/back-button/back-button"
import { EditACHRecipient } from "@features/settings/recipients/edit/edit-ach-recipient"
import { moveMoneyActions } from "@features/move-money/redux/actions"
import { selectRecipient } from "@features/move-money/redux/selectors"
import { BaseLayout } from "@layouts/base-layout"
import { EditDomesitcWireRecipient } from "../edit/edit-wire-recipient"
import { ACHRecipients } from "./ach-recipient-list"
import { DomesticWireRecipients } from "./domestic-wire-recipient-list"

type TRecipientType = "ach" | "wire"

export const RecipientListByType: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const { recipientType } = useParams<{ recipientType: TRecipientType }>()
  const recipient = useAppSelector(selectRecipient)

  const getRecipientsList = (recipientType: TRecipientType) => {
    switch (recipientType) {
      case "ach":
        return <ACHRecipients />
      case "wire":
        return <DomesticWireRecipients />
      default:
        return null
    }
  }

  const recipientList = getRecipientsList(recipientType)

  if (!recipientList) {
    history.push(RoutesEnum.SETTINGS_RECIPIENTS)
  }

  const handleOnClose = () => {
    dispatch(moveMoneyActions.clearRecipient())
    dispatch(moveMoneyActions.clearEditingRecipient())
  }

  return (
    <>
      <EditACHRecipient
        isOpen={Boolean(recipient.id && recipientType === "ach")}
        closeOverlay={handleOnClose}
      />
      <EditDomesitcWireRecipient
        isOpen={Boolean(recipient.id && recipientType === "wire")}
        closeOverlay={handleOnClose}
      />
      <BaseLayout pageTitle={t("pageTitles.settings.recipientType", { context: recipientType })}>
        <BackButton
          text={t("settings.pages.recipients.back")}
          onClick={() => history.push(RoutesEnum.SETTINGS_RECIPIENTS)}
        />
        <Text tag="h2" sx={{ marginBottom: 36, marginTop: 16 }}>
          {t(`settings.pages.recipients.${recipientType}`)}
        </Text>
        {recipientList}
      </BaseLayout>
    </>
  )
}
