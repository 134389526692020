import React from "react"
import { Box, SxStyleProp, Image } from "rebass"
import {
  Text,
  TTextTag,
  AnimatePresence,
  Button,
  IButtonProps,
  FlexRowLayout,
  FlexColumnLayout,
  theme,
} from "@northone/ui"
import { IErrorMessage, NoticeBox } from "@components/primitive/notice-box/notice-box"
import { useTranslation } from "react-i18next"

interface ITextStyle {
  title?: React.CSSProperties
  subtitle?: React.CSSProperties
}
interface FormBodyProps {
  title: string
  subtitle?: string | React.ReactElement
  textStyle?: ITextStyle
  partialTextStyle?: ITextStyle
  buttons: Array<IButtonProps | React.ReactElement>
  titleRef?: React.RefObject<HTMLDivElement>
  errorMessage?: string | IErrorMessage
  buttonDirection?: "row" | "column"
  containerSx?: SxStyleProp
  children?: React.ReactNode
  centered?: boolean
  titleTag?: TTextTag
  buttonContainerSx?: SxStyleProp
  displayButtonsOnBottom?: boolean
  imageSrc?: string
  imageHeight?: number
  imageWidth?: number
  subTitleMarginBottom?: number
}

export const FormBody = ({
  subtitle,
  title,
  textStyle,
  partialTextStyle,
  buttons,
  children,
  titleRef,
  errorMessage,
  buttonDirection = "row",
  containerSx,
  centered = false,
  titleTag,
  buttonContainerSx,
  displayButtonsOnBottom = false,
  imageSrc,
  imageHeight,
  imageWidth,
}: FormBodyProps) => {
  const { t } = useTranslation()
  return (
    <FlexColumnLayout
      sx={{
        ...containerSx,
        ...(centered && { justifyContent: "center", alignItems: "center" }),
      }}
    >
      {errorMessage && (
        <AnimatePresence animation="fadeAndExpand">
          {typeof errorMessage === "string" ? (
            <NoticeBox level="error" text={errorMessage} />
          ) : (
            <NoticeBox
              level="error"
              content={
                <Text tag="body-small">
                  {errorMessage.text}{" "}
                  {errorMessage.customerCareLinkRequired && (
                    <a
                      style={{ color: theme.colors.ui1 }}
                      href="#"
                      onClick={() => {
                        window.Intercom("show")
                        return false
                      }}
                    >
                      {t("invoicePayments.modal.reviewPayment.error.customerCareLink")}
                    </a>
                  )}
                  {errorMessage.customerCareLinkRequired && "."}
                </Text>
              }
            />
          )}
        </AnimatePresence>
      )}
      {imageSrc && (
        <Image src={imageSrc} height={imageHeight} width={imageWidth} marginBottom="5px" />
      )}
      <Text
        textRef={titleRef}
        tag={titleTag || "h3Serif"}
        partialStyling={partialTextStyle?.title}
        sx={{ marginBottom: subtitle ? 16 : 24, ...textStyle?.title }}
      >
        {title}
      </Text>
      {subtitle && (
        <Text
          tag="body"
          partialStyling={partialTextStyle?.subtitle}
          sx={{
            marginBottom: 24,
            color: theme.colors.ui2,
            textAlign: centered ? "center" : "left",
            ...textStyle?.subtitle,
          }}
        >
          {subtitle}
        </Text>
      )}
      {children}
      <FlexRowLayout
        sx={{
          paddingY: 24,
          justifyContent: "space-between",
          marginTop: displayButtonsOnBottom ? "auto" : null,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: buttonDirection,
            ...buttonContainerSx,
          }}
        >
          {buttons.slice(0, 2).map((button, index) =>
            React.isValidElement(button) ? (
              button
            ) : (
              <Button
                key={index}
                {...button}
                sx={{
                  marginRight: index === 0 && buttonDirection === "row" ? 12 : 0,
                  marginBottom: index === 0 && buttonDirection === "column" ? 24 : 0,
                  alignSelf: "flex-start",
                  ...button.sx,
                }}
              />
            ),
          )}
        </Box>
        <FlexRowLayout>
          {buttons
            .slice(2)
            .map((button, index) =>
              React.isValidElement(button) ? button : <Button key={index} {...button} />,
            )}
        </FlexRowLayout>
      </FlexRowLayout>
    </FlexColumnLayout>
  )
}
