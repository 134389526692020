import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { Box } from "rebass"
import { Text } from "@components/primitive/text/text"
import { BaseLayout } from "@layouts/base-layout"
import { useTranslation } from "react-i18next"
import { theme } from "@layouts/theme"
import { SearchInput } from "@components/extended/fields/search-input"
import { PerksBackground } from "./perks-background"

import { perksActions } from "@features/perks/redux/actions"
import { setPerksLastVisitedInLocalStorage } from "@features/perks/utils"
import { PerksCards } from "./perks-cards"
import { QueryResults } from "@components/composite/query-results/query-results"
import { ApolloError } from "@apollo/client"
import { getPerks } from "@utils/prismic/perks/helpers"
import { useDebounce } from "use-debounce"
import { PerksNoSearchResults } from "./perks-no-search-results"
import { TPerk } from "../types"
import * as prismicH from "@prismicio/helpers"
import { ReloadPageDisplay } from "@components/composite/reload-page-display/reload-page-display"
import { Analytics } from "@core/analytics/actions"
import { events } from "@core/analytics/events"

export const PerksLanding: React.FC = () => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  useEffect(() => {
    const hubLastVisited = new Date()
    dispatch(perksActions.setPerksLastVisitedDate(hubLastVisited))
    setPerksLastVisitedInLocalStorage(hubLastVisited)
  }, [])

  return (
    <>
      <PerksBackground />
      <BaseLayout pageTitle={t("pageTitles.perks")} childrenGridColumn={"1/13"}>
        <PerksContent />
      </BaseLayout>
    </>
  )
}

const pushPerksErrorAnalytics = (hasSearchValue: boolean) => {
  Analytics.track(events.perks.pageError, {
    ErrorReason: hasSearchValue ? "Search Network" : "Network",
  })
}

const onSearchBarFocus = () => {
  Analytics.track(events.perks.searchPageLanded)
}

const PerksContent: React.FC = () => {
  const { t } = useTranslation()
  const [text, setText] = useState<undefined | string>(undefined)

  const [searchValue] = useDebounce<undefined | string>(text?.toLowerCase(), 500)

  const [perks, setPerks] = useState([])
  const [perksCache, setperksCache] = useState([])
  const [error, setError] = useState<ApolloError>()
  const [loading, setLoading] = useState(false)
  const [isHubConnected, setIsHubConnected] = useState(false)
  const [isOnline, setIsOnline] = useState(navigator.onLine)
  let hasSearchValue = false

  useEffect(() => {
    const updateIsOnline = () => setIsOnline(navigator.onLine)
    window.addEventListener("offline", updateIsOnline)
    window.addEventListener("online", updateIsOnline)

    return () => {
      window.removeEventListener("offline", updateIsOnline)
      window.removeEventListener("online", updateIsOnline)
    }
  }, [navigator.onLine])

  useEffect(() => {
    setIsHubConnected(isOnline)
  }, [isOnline])

  const fetchAllPerks = async () => {
    try {
      setLoading(true)
      const { data } = await getPerks()
      setPerks(data?.allPerks?.edges)
      setperksCache(data?.allPerks?.edges)
    } catch (error) {
      pushPerksErrorAnalytics(hasSearchValue)
      if (error instanceof ApolloError) {
        setError(error)
      }
    } finally {
      setLoading(false)
    }
  }

  const searchPerks = () => {
    return perksCache.filter((perk: TPerk) => {
      if (searchValue) {
        return (
          prismicH.asText(perk.node.partner_name).toLowerCase().includes(searchValue) ||
          prismicH.asText(perk.node.perk_header).toLowerCase().includes(searchValue) ||
          perk.node.partner_category.toLowerCase().includes(searchValue)
        )
      }
    })
  }

  useEffect(() => {
    if (searchValue) {
      const filteredPerks = searchPerks()
      setPerks(filteredPerks)
      Analytics.track(events.perks.searchStarted, { searchValue })
    } else {
      fetchAllPerks()
    }

    hasSearchValue = !!searchValue
  }, [searchValue])

  return (
    <Box sx={{ marginLeft: 70 }}>
      <PerksTitleAndHeroImage />
      <SearchInput
        placeholder={t("perks.landing.search.placeHolder")}
        value={text}
        onChange={(e) => setText(e)}
        onFocus={() => onSearchBarFocus()}
        sx={{ width: "50%", backgroundColor: "transparent" }}
      />
      <PerksHeaderDisclaimer />
      {isHubConnected ? (
        <QueryResults
          size="medium"
          loading={loading}
          retry={() => null}
          error={error}
          errorElement={
            <ReloadPageDisplay
              width="55%"
              title={t("perks.errors.general.title")}
              subtitle={t("perks.errors.general.subtitle")}
              ctaButtonText={t("perks.errors.general.ctaButtonText")}
            />
          }
        >
          {perks.length ? (
            <PerksCards perks={perks} hasSearchValue={hasSearchValue} />
          ) : (
            <PerksNoSearchResults searchValue={searchValue} />
          )}
        </QueryResults>
      ) : (
        <ReloadPageDisplay
          width="55%"
          title={t("perks.errors.general.title")}
          subtitle={t("perks.errors.general.subtitle")}
          ctaButtonText={t("perks.errors.general.ctaButtonText")}
        />
      )}
      <PerksFooter />
    </Box>
  )
}

const PerksHeaderDisclaimer: React.FC = () => {
  const { t } = useTranslation()
  return (
    <Text
      sx={{ marginY: 24, color: theme.colors.ui2, width: ["80%", "80%", "80%", "100%", "100%"] }}
      tag={"body"}
    >
      {t("perks.landing.cardsDescription")}
    </Text>
  )
}

const PerksTitleAndHeroImage: React.FC = () => {
  const { t } = useTranslation()
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        marginBottom: 100,
        marginTop: 20,
      }}
    >
      <Text width={"35%"} tag={"h2"} sx={{ marginBottom: 25 }}>
        {t("perks.landing.title")}
      </Text>
      <Text width={"45%"} tag={"body-large"}>
        {t("perks.landing.body")}
      </Text>
    </Box>
  )
}

const PerksFooter: React.FC = () => {
  const { t } = useTranslation()
  return (
    <Text tag={"body-small"} sx={{ marginBottom: 50, marginTop: 64, color: theme.colors.ui2 }}>
      {t("perks.landing.footer")}
    </Text>
  )
}
