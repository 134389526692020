import React from "react"
import { useDispatch } from "react-redux"
import { Box } from "rebass"
import { Dropdown } from "@components/composite/dropdown/dropdown"
import { useAppSelector } from "@core/redux/utils"
import { TextInput } from "@components/extended/fields/text-input"
import { Text } from "@components/primitive/text/text"
import { AddressInput } from "@components/composite/address-input/address-input"
import {
  RECIPIENT_ADDRESS_LABEL,
  RECIPIENT_DETAILS_NAME,
  RECIPIENT_BUSINESS_REASON_TITLE,
  RECIPIENT_BUSINESS_REASON_LABEL,
  RECIPIENT_BUSINESS_REASON_OTHER,
  RECIPIENT_DETAILS_BANK_DETAILS,
  RECIPIENT_DETAILS_BANK_NAME,
  RECIPIENT_DETAILS_ROUTING_NUMBER,
  RECIPIENT_DETAILS_ACCOUNT_NUMBER,
} from "@features/move-money/utils/i18n-translations"
import { theme } from "@layouts/theme"
import { FlexColumnLayout, FlexRowLayout } from "@layouts/flex"
import { validationConstants } from "@utils/validate"
import { moveMoneyActions } from "@features/move-money/redux/actions"
import { selectEditRecipient } from "@features/move-money/redux/selectors"
import { errorMessages } from "@features/move-money/domestic-wire/utils/recipientValidator"
import { businessRelationshipOptions } from "@features/move-money/domestic-wire/utils/constants"

interface IDomesticWireRecipientDetailsProps {
  routingNumberIsValid: boolean
}

export const DomesticWireRecipientDetails: React.FC<IDomesticWireRecipientDetailsProps> = ({
  routingNumberIsValid,
}) => {
  const dispatch = useDispatch()
  const {
    name,
    address,
    bankName,
    relationship,
    otherRelationship,
    bankRoutingNumber,
    bankAccountNumber,
  } = useAppSelector(selectEditRecipient)

  const { streetAddressLine1, streetAddressLine2, city, provinceState, postalCode } = address || {}

  // Error Messages
  const nameErrorMessage = name ? errorMessages.name(name) : ""
  const streetAddressLine1ErrorMessage = streetAddressLine1
    ? errorMessages.streetAddressLine1(streetAddressLine1)
    : ""
  const streetAddressLine2ErrorMessage = streetAddressLine2
    ? errorMessages.streetAddressLine2(streetAddressLine2)
    : ""
  const cityErrorMessage = city ? errorMessages.city(city) : ""
  const postalCodeErrorMessage = postalCode ? errorMessages.postalCode(postalCode) : ""
  const businessReasonErrorMessage = otherRelationship
    ? errorMessages.businessReason(otherRelationship)
    : ""
  const bankNameErrorMessage = bankName ? errorMessages.bankName(bankName) : ""
  const bankAccountNumberErroMessage = bankAccountNumber
    ? errorMessages.accountNumber(bankAccountNumber)
    : ""
  const bankRoutingNumberErroMessage = bankRoutingNumber
    ? errorMessages.routingNumber(bankRoutingNumber, routingNumberIsValid)
    : ""

  const businessRelationships: { text: string; value: string }[] = [
    { text: businessRelationshipOptions.government, value: businessRelationshipOptions.government },
    { text: businessRelationshipOptions.otherBank, value: businessRelationshipOptions.otherBank },
    { text: businessRelationshipOptions.payroll, value: businessRelationshipOptions.payroll },
    { text: businessRelationshipOptions.vendor, value: businessRelationshipOptions.vendor },
    { text: businessRelationshipOptions.other, value: businessRelationshipOptions.other },
  ]
  const isOtherRelationship = relationship === businessRelationshipOptions.other

  return (
    <FlexColumnLayout>
      <Text tag="label" textColor={theme.colors.ui3} sx={{ marginBottom: "4px" }}>
        {RECIPIENT_ADDRESS_LABEL.toUpperCase()}
      </Text>

      <Box sx={{ marginTop: "8px", marginBottom: "20px" }}>
        <TextInput
          value={name || ""}
          placeholder={RECIPIENT_DETAILS_NAME}
          label={RECIPIENT_DETAILS_NAME}
          onChange={(value) => {
            dispatch(moveMoneyActions.setEditingRecipient({ name: value }))
          }}
          errorMessage={nameErrorMessage}
        />
      </Box>

      <AddressInput
        sx={{ "& > *": { marginTop: "8px", marginBottom: "20px" } }}
        fields={{
          streetAddressLine1: {
            value: streetAddressLine1 || "",
            errorMessage: streetAddressLine1ErrorMessage,
            onChange: (value: string) =>
              dispatch(
                moveMoneyActions.setEditingRecipientAddress({
                  streetAddressLine1: value,
                }),
              ),
          },
          streetAddressLine2: {
            value: streetAddressLine2 || "",
            errorMessage: streetAddressLine2ErrorMessage,
            onChange: (value: string) =>
              dispatch(
                moveMoneyActions.setEditingRecipientAddress({
                  streetAddressLine2: value,
                }),
              ),
          },
          city: {
            value: city || "",
            errorMessage: cityErrorMessage,
            onChange: (value: string) =>
              dispatch(moveMoneyActions.setEditingRecipientAddress({ city: value })),
          },
          provinceState: {
            value: provinceState || "",
            onChange: (value: string) =>
              dispatch(moveMoneyActions.setEditingRecipientAddress({ provinceState: value })),
          },
          postalCode: {
            value: postalCode || "",
            errorMessage: postalCodeErrorMessage,
            onChange: (value: string) =>
              dispatch(moveMoneyActions.setEditingRecipientAddress({ postalCode: value })),
          },
        }}
      />

      <Text tag="label" textColor={theme.colors.ui3} sx={{ margin: "4px 0" }}>
        {RECIPIENT_BUSINESS_REASON_TITLE.toUpperCase()}
      </Text>
      <FlexRowLayout sx={{ width: "100%", marginTop: "8px", marginBottom: "20px" }}>
        <Box sx={{ width: "50%", paddingRight: "12px" }}>
          <Dropdown
            onSelect={(value) => {
              dispatch(moveMoneyActions.setEditingRecipient({ relationship: value }))
              isOtherRelationship &&
                dispatch(moveMoneyActions.setEditingRecipient({ otherRelationship: "" }))
            }}
            selectedValue={relationship || ""}
            options={businessRelationships}
            label={RECIPIENT_BUSINESS_REASON_LABEL}
            placeholder={RECIPIENT_BUSINESS_REASON_LABEL}
          />
        </Box>

        {isOtherRelationship && (
          <Box sx={{ width: "50%" }}>
            <TextInput
              value={otherRelationship || ""}
              errorMessage={businessReasonErrorMessage}
              onChange={(value) =>
                dispatch(moveMoneyActions.setEditingRecipient({ otherRelationship: value }))
              }
              label={RECIPIENT_BUSINESS_REASON_OTHER}
              placeholder={RECIPIENT_BUSINESS_REASON_OTHER}
            />
          </Box>
        )}
      </FlexRowLayout>

      <Text tag="label" textColor={theme.colors.ui3} sx={{ margin: "4px 0" }}>
        {RECIPIENT_DETAILS_BANK_DETAILS.toUpperCase()}
      </Text>

      <Box sx={{ marginTop: "8px", marginBottom: "20px" }}>
        <TextInput
          value={bankName || ""}
          errorMessage={bankNameErrorMessage}
          label={RECIPIENT_DETAILS_BANK_NAME}
          placeholder={RECIPIENT_DETAILS_BANK_NAME}
          onChange={(value) => dispatch(moveMoneyActions.setEditingRecipient({ bankName: value }))}
        />
      </Box>

      <FlexRowLayout sx={{ width: "100%", marginTop: "8px", marginBottom: "20px" }}>
        <TextInput
          value={bankRoutingNumber || ""}
          label={RECIPIENT_DETAILS_ROUTING_NUMBER}
          placeholder="#########"
          onChange={(value) =>
            dispatch(moveMoneyActions.setEditingRecipient({ bankRoutingNumber: value }))
          }
          maxLength={validationConstants.ROUTING_NUMBER_MAX_LENGTH}
          errorMessage={bankRoutingNumberErroMessage}
          containerSx={{
            width: "50%",
            marginRight: 16,
          }}
        />
        <TextInput
          value={bankAccountNumber || ""}
          label={RECIPIENT_DETAILS_ACCOUNT_NUMBER}
          placeholder="#########"
          onChange={(value) =>
            dispatch(moveMoneyActions.setEditingRecipient({ bankAccountNumber: value }))
          }
          containerSx={{
            width: "50%",
          }}
          errorMessage={bankAccountNumberErroMessage}
          maxLength={validationConstants.ACCOUNT_NUMBER_MAX_LENGTH}
        />
      </FlexRowLayout>
    </FlexColumnLayout>
  )
}
