import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { motion } from "framer-motion"

import { ButtonTypeEnum } from "@components/primitive/button/button"
import { Analytics } from "@core/analytics/actions"
import { events } from "@core/analytics/events"
import {
  useShowTeamBankingBanner,
  useUpdateDismissTeamBankingBanner,
} from "@core/announce/team-banking-banner"
import i18n from "@i18n/i18n"
import { BannerButton } from "@components/extended/buttons/banner-button"
import { RoutesEnum } from "@routers/types"

import { TeamBankingBanner } from "./team-banking-banner"

const teamsBannerOnMounted = () => {
  Analytics.track(events.settings.teamBanking.banner.viewed)
}

interface ITeamBankingBannerHomepageWrapper {
  onLoaded: () => void
}

export const TeamBankingBannerHomepageWrapper = ({
  onLoaded,
}: ITeamBankingBannerHomepageWrapper) => {
  const history = useHistory()
  const [wasDismissButtonClicked, setWasDismissButtonClicked] = useState(false)

  const updateBannerStatus = useUpdateDismissTeamBankingBanner()
  const { shouldShowBanner, loading: isShowTeamBankingBannerCheckLoading } =
    useShowTeamBankingBanner()

  const onGetStartedButtonClick = () => {
    history.push(RoutesEnum.SETTINGS_TEAM_BANKING)
    Analytics.track(events.settings.teamBanking.banner.clicked)
  }

  const onDismissButtonClick = async () => {
    Analytics.track(events.settings.teamBanking.banner.dismissed)
    setWasDismissButtonClicked(true)
    await updateBannerStatus()
  }

  useEffect(() => {
    if (isShowTeamBankingBannerCheckLoading !== false) return
    onLoaded()
  }, [isShowTeamBankingBannerCheckLoading])

  if (!shouldShowBanner || wasDismissButtonClicked || isShowTeamBankingBannerCheckLoading)
    return null

  return (
    <motion.div layout="position">
      <TeamBankingBanner
        title={i18n.t("settings.pages.teamBanking.banner.homePage.title")}
        subtitle={i18n.t("settings.pages.teamBanking.banner.homePage.subtitle")}
        bannerHeight={144}
        containerSx={{ marginBottom: "24px" }}
        onMounted={teamsBannerOnMounted}
        isPillShown={true}
        buttons={[
          <BannerButton
            key={0}
            type={ButtonTypeEnum.PRIMARY_BLACK}
            text={i18n.t("settings.pages.teamBanking.banner.buttons.primary")}
            onClick={onGetStartedButtonClick}
          />,
          <BannerButton
            key={1}
            type={ButtonTypeEnum.TERTIARY}
            text={i18n.t("settings.pages.teamBanking.banner.buttons.secondary")}
            onClick={onDismissButtonClick}
          />,
        ]}
      />
    </motion.div>
  )
}
