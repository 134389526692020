import React from "react"
import { useTranslation } from "react-i18next"
import i18n from "@i18n/i18n"
import { RoutesEnum } from "@routers/types"
import { events } from "@core/analytics/events"
import { Panel } from "@components/primitive/panel/panel"
import { INavBarLinkProps } from "@components/composite/nav-bar/nav-bar-link"
import { AnimatePresence } from "@components/animations/animate-presence/animation-presence"
import { NavSideBarSection } from "@features/sidebar/shared/side-bar-section"

const supportLinks: INavBarLinkProps[] = [
  {
    text: i18n.t("support.overview.title"),
    to: RoutesEnum.SUPPORT_OVERVIEW,
    routeMatchIsExact: true,
  },
  {
    text: i18n.t("support.scheduleCall.title"),
    to: RoutesEnum.SUPPORT_SCHEDULE_CALL,
    analyticEvent: events.support.sideBarCall,
  },
  {
    text: i18n.t("support.faq.sidebarTitle"),
    to: RoutesEnum.SUPPORT_FAQS,
    analyticEvent: events.support.sideBarFaq,
  },
]

export const SupportSidebar: React.FC = () => {
  const { t } = useTranslation()

  return (
    <AnimatePresence style={{ height: "100%" }} withoutWrapper animation="fadeIn">
      <Panel
        sx={{
          padding: 20,
        }}
      >
        <NavSideBarSection
          sectionLabel={t("support.title")}
          links={supportLinks}
          eventPrefix={"Support"}
        />
      </Panel>
    </AnimatePresence>
  )
}
