export const invoicePayments = {
  landing: {
    title: "Bill Payments",
    refreshInvoices: "Refresh",
    addInvoice: "Add Bill",
    newInvoiceBanner: {
      newInvoice: "New bill or invoice?",
      clickAddInvoiceOrEmail:
        "Click *Add bill* to upload or email them to *{{forwardingEmailAddress}}*",
    },
    disclosure: {
      title: "Have questions?",
      message: "Check out __Frequently Asked Questions__ or reach out to __Customer Care__.",
    },
  },
  status: {
    paid: "Paid",
    unpaid: "Unpaid",
    failed: "Payment Failed",
    scheduled: "Scheduled Payment",
    partiallyPaid: "Partially Paid",
    archived: "Archived",
    processing: "Processing Payment",
    draft: "Draft",
  },
  invoice: {
    vendor: "Vendor",
    amount: "Amount",
    amountPaid: "Amount Paid",
    dueDate: "Due Date",
    invoiceDate: "Bill Date",
    invoiceNumber: "Bill #",
    status: "Status",
    description: "Description",
  },
  invoicePanel: {
    archive: "Archive",
    editInvoiceDetails: "Edit bill details",
    invoiceDetails: "Bill details",
    markAsPaid: "Mark as paid",
    markAsUnpaid: "Mark as unpaid",
    moveBackToDrafts: "Move Back to Drafts",
    paid: "paid",
    paymentActivity: "Payment activity",
    payThisInvoice: "Pay this bill",
    invoiceMarkedAsPaid: "This bill was marked as fully paid.",
    loadingErrorMessage: "There was an issue loading your bill.",
    reloadThisTab: "Reload this tab",
    downloadInvoice: "download bill",
    payments: {
      scheduled: "Scheduled",
      processing: "Processing",
      paymentFailed: "Payment failed",
      payRemainingBalance: "Pay remaining {{remainingAmount}} balance",
      viewTransaction: "View transaction",
      editScheduledPayment: "Edit scheduled payment",
      noPaymentsMessage: "There have been no payments sent or scheduled for this bill",
    },
    paymentTypes: {
      ach: "ACH Payment",
      wire: "Wire Payment",
      mailedCheck: "Physical Check Payment",
      billPay: "Digital Bill Pay",
    },
    promptDialog: {
      title: "You have an unpaid bill",
      invoiceProgressNote: "Any progress on this bill will not be saved.",
      discardChanges: "Discard changes",
      continueEditing: "Continue editing",
      paymentProgressNote: "Any progress on this payment will not be saved.",
      discardProgress: "Discard progress",
      continuePayment: "Continue payment",
    },
    editPayment: {
      savePayment: "Save payment",
      deleteScheduledPayment: "Delete scheduled payment",
      editScheduledPayment: "Edit scheduled payment",
      paymentDate: {
        label: "Payment date",
        placeholder: "Select date",
      },
      paymentAmount: {
        label: "How much do you want to pay?",
        placeholder: "Enter amount to pay",
        invalidAmount: "Enter a valid number for amount to pay",
        underLimitError: "Please enter an amount greater than {{transactionMinimum}}",
        exceedsLimitError:
          "This amount exceeds the current per transaction limit for {{paymentMethod}} of {{transactionMaximum}}. Please enter a lower amount to proceed. You may break your payment into multiple parts to pay the whole bill or choose a different payment method.",
        underMinimumError:
          "The amount you entered is currently less than the {{paymentMethod}} {{transactionMinimum}} transaction limit. Please enter a greater amount.",
        exceedsInvoiceLimitError: "Enter an amount equal to or less than {{remainingAmount}}",
        exceedsBalanceError:
          "Your current account or envelope balance is insufficient to cover this payment, please adjust amount or deposit additional funds.",
        exceedsBalanceWarning:
          "Your current account or envelope balance is insufficient to cover this scheduled payment, please be sure to add additional funds prior to your scheduled payment date.",
        amountLeftToPay: "Amount left: {{amountLeftToPay}}",
      },
      amountLeftToPay: "Amount left: {{amountLeftToPay}}",
      paymentMethodDisclaimer: "This payment will be sent via {{paymentMethodLabel}}",
      paymentMethodTooltip:
        "If you need to change the payment method, cancel this payment to schedule a new one.",
      promptDialog: {
        title: "Are you sure you want to delete this scheduled payment?",
        subTitle: "This payment will be deleted and will not be processed.",
        keepPayment: "Keep payment",
        deletePayment: "Delete payment",
      },
      errorMessage:
        "There was a problem saving this payment. Please try again. If that doesn’t work, contact Customer Care.",
    },
  },
  markInvoicePaid: {
    invoiceWithNoPayments: {
      title: "You haven’t made any payments for this bill yet",
      note: "This bill will still show that $0.00 have been paid towards it.",
    },
    invoiceWithPayments: {
      title: "There is still **{{remainingAmount}}** left to pay on this bill",
      note: "This bill will still show that only {{paidAmount}} have been paid towards it.",
    },
    subtitle:
      "Marking this bill as paid will mean you cannot make future payments towards this bill. Any scheduled payments will be deleted.",
    reasonLabel: "Leave a reason why you are marking this bill as paid (optional)",
    reasonPlaceholder: "Reason for marking as paid",
    cancelButton: "Do not mark as paid",
    confirmButton: "Mark as paid",
    notificationMessage: "Your bill has been marked as paid",
    errorMessage:
      "There was a problem marking this bill as paid. Please try again. If that doesn’t work, contact Customer Care.",
  },
  markInvoiceUnpaid: {
    title: "Are you sure you want to mark this bill as unpaid?",
    subtitle:
      "Marking this bill as unpaid will mean that you can make payments towards this bill again.",
    cancelButton: "Leave as paid",
    confirmButton: "Mark as unpaid",
    notificationMessage: "Your bill has been marked as unpaid",
    errorMessage:
      "There was a problem marking this bill as unpaid. Please try again. If that doesn’t work, contact Customer Care.",
  },
  markInvoiceArchived: {
    title: "Are you sure you want to archive this bill?",
    subtitle:
      "The bill will be archived and cannot be paid. You can move this bill back to your drafts at any time. Any scheduled payments will be deleted.",
    cancelButton: "Keep bill active",
    confirmButton: "Archive bill",
    notificationMessage: "Your bill has been archived",
    errorMessage:
      "There was a problem archiving this bill. Please try again. If that doesn’t work, contact Customer Care.",
  },
  markInvoiceUnarchived: {
    notificationMessage: "Your bill has been moved back to drafts",
    errorMessage:
      "There was a problem moving this bill back to drafts. Please try again. If that doesn’t work,",
    contactLink: "contact Customer Care",
  },
  paymentMethods: {
    ach: "ACH",
    wire: "Wire",
    physicalCheck: "Physical Check",
    billPay: "Digital Bill Pay",
  },
  modal: {
    progressBar: {
      invoiceDetails: "Bill Details",
      invoicePayments: "Bill Payment",
      invoicePaymentsReview: "Review Payment",
    },
    invoiceDetails: {
      title: "Bill details",
      addNewContact: {
        modalTitle: "Add new vendor",
        corporation: {
          title: "Corporation",
          placeholder: "Enter corporation",
          emptyResult: {
            title: "No corporations found.",
            subtitle: "Add other vendor or try again",
          },
        },
        otherVendor: {
          title: "Other vendor",
          buttonTooltip: "Add a new vendor",
          nameLabel: "Name of person or business",
          namePlaceholder: "Enter vendor name",
          emailLabel: "Email address",
          emailPlaceholder: "Enter email",
          invalidEmail: "Invalid email",
          disclosure: "By adding a vendor, you are agreeing to our NorthOne __Terms of Service__.",
        },
        confirmButton: "Save",
      },
      inputs: {
        vendorName: {
          label: "Vendor Name (required)",
          placeholder: "Select a Vendor",
          new: "NEW",
          newContactDisclaimer1:
            "We could not match this vendor so we’ve created a new one for you, save your bill to add it. Use the dropdown if you would like to choose a different one.",
          newContactDisclaimer2:
            "By saving this vendor, you are agreeing to our NorthOne __Terms of Service__.",
        },
        vendorEmail: {
          label: "Vendor Email (optional)",
          placeholder: "Email address",
          invalidEmail: "Invalid email",
        },
        invoiceTotal: {
          label: "Bill Total (required)",
          placeholder: "Total amount",
          invalidInvoiceTotal: "Enter only numbers for bill total",
        },
        invoiceNumber: {
          label: "Bill Number (required)",
          placeholder: "Reference #",
        },
        invoiceDueDate: {
          label: "Bill Due Date (optional)",
          placeholder: "Select date",
        },
        invoiceDate: {
          label: "Bill Date (required)",
          placeholder: "Select date",
        },
        description: {
          label: "Description (optional)",
          placeholder: "Leave a note about the bill",
        },
      },
      buttons: {
        saveInvoice: "Save bill",
        continueToPaymentDetails: "Continue",
      },
      uploadedInvoiceNotice: {
        heading: "Do you want to scan this bill?",
        body: "We'll automagically read the most important information on the bill and prepare the payment for your approval.",
        scanInvoice: "Scan bill",
        manuallyFillOutInvoice: "Manually fill out bill",
      },
      autoExtractedInvoiceNotice: {
        heading: "This bill was automatically filled out by NorthOne.",
        body: "Please review the details below to ensure accuracy, NorthOne is not responsible for payments based on bill errors. If this bill was not meant to be uploaded, archive it below.",
        archiveInvoice: "Archive bill",
      },
      confirmationToastMessage: {
        invoiceSaved: "Your bill was saved",
      },
    },
    paymentDetails: {
      title: "Bill payment",
      paymentDetails: "Payment details",
      paymentDate: "When do you want to pay?",
      inputs: {
        paymentMethod: {
          label: "Payment Method",
          placeholder: "Select method",
          standardAch: "Standard ACH",
          standardAchDescription: "1-3 business days",
          sameDayAch: "Same Day ACH",
          achSameDayDescription: "Same-day, before {{achCutoffTime}}",
          achScheduledSameDayDescription: "Schedule only, after {{achCutoffTime}}",
          mailedCheck: "Physical Check",
          mailedCheckDescription: "3-9 business days",
          wire: "Domestic Wire",
          wireDescription: "Next business day",
          billPay: "Digital Bill Pay",
          billPayDescription: "Same-day, before 11pm ET",
        },
        routingNumber: {
          label: "Routing Number",
          invalidLength: "Enter a 9-digit routing number",
          invalidCharacters: "Enter only numbers for routing number",
          invalidNumber: "Enter a valid routing number",
          validationError: "Error ... please refresh",
        },
        accountNumber: {
          label: "Account Number",
          invalidLength: "Enter a 6 to 17-digit account number",
          invalidCharacters: "Enter only numbers for account number",
          billPayError: "Account number format is incorrect",
        },
        subAccount: {
          label: "Where do you want to send the money from?",
          balance: "Balance",
        },
        mailedCheck: {
          addressLine1: {
            label: "Street address",
            placeholder: "Street address",
          },
          addressLine2: {
            label: "Apartment, suite, etc. (optional)",
            placeholder: "Apartment, suite, etc. ",
          },
          addressCity: {
            label: "City",
            placeholder: "City",
          },
          addressState: {
            label: "State",
            placeholder: "Select",
          },
          zipCode: {
            label: "Zip code",
            placeholder: "Zip code",
          },
          memo: {
            label: "Memo",
            placeholder: "Leave a note about the payment.",
          },
        },
        wire: {
          personalOrBusinessName: {
            label: "Personal or business name",
            placeholder: "Personal or business name",
          },
          addressLine1: {
            label: "Street address",
            placeholder: "Street address",
          },
          addressLine2: {
            label: "Apartment, suite, etc. (optional)",
            placeholder: "Apartment, suite, etc. ",
          },
          addressCity: {
            label: "City",
            placeholder: "City",
          },
          addressState: {
            label: "State",
            placeholder: "Select",
          },
          zipCode: {
            label: "Zip code",
            placeholder: "Zip code",
          },
          businessRelationship: {
            title: "Business relationship",
            label: "Business relationship type",
            placeholder: "Select",
            options: {
              vendorOrSupplier: "Vendor or supplier",
              government: "Government (taxes, licences, fees...",
              myOtherBank: "My other bank",
              payrollProvider: "Payroll Provider",
              other: "Other",
            },
            otherRelationshipDetails: {
              label: "if other, describe the relationship",
              placeholder: "if other, describe the relationship",
            },
          },
          reason: {
            title: "Reason",
            label: "Give specific details as to who you’re sending the payment to and why",
            placeholder: "For example, “Sending money to ADP for September payroll.”",
          },
          bankName: {
            title: "Bank details",
            label: "Bank name",
            placeholder: "Bank name",
          },
          disclaimerWireSent:
            "Wires sent after 3:30 PM EST (Monday to Friday) will be received the next business day.",
          disclaimerWirePaymentSchedule:
            "If you would like to schedule this payment for a future date, please select a different payment method.",
        },
        paymentDate: {
          label: "Payment date",
          placeholder: "Select date",
          estimatedArrival: "Estimated arrival: {{date}}",
          estimatedArrivalToday: "Estimated arrival: Today, {{date}}",
        },
        payRemainingAmount: {
          label: "Pay remaining amount",
        },
        payFullAmount: {
          label: "Pay full amount",
        },
        paymentAmount: {
          label: "How much do you want to pay?",
          placeholder: "Enter amount to pay",
          exceedsLimitError:
            "This amount exceeds the current per transaction limit for {{paymentMethod}} of {{transactionMaximum}}. Please enter a lower amount to proceed. You may break your payment into multiple parts to pay the whole bill or choose a different payment method.",
          underMinimumError:
            "The amount you entered is currently less than the {{paymentMethod}} {{transactionMinimum}} transaction limit. Please enter a greater amount.",
          exceedsInvoiceLimitError: "Enter an amount equal to or less than {{remainingAmount}}",
          exceedsBalanceError:
            "Your current account or envelope balance is insufficient to cover this payment, please adjust amount or deposit additional funds.",
          exceedsBalanceWarning:
            "Your current account or envelope balance is insufficient to cover this scheduled payment, please be sure to add additional funds prior to your scheduled payment date.",
          amountLeftToPay: "Amount left: {{amountLeftToPay}}",
        },
      },
      confirmationToastMessage: {
        immediatePayment: "Your payment is being processed",
        scheduledPayment: "Your payment was scheduled",
      },
      buttons: {
        editInvoice: "Edit bill details",
        reviewPayment: "Review payment",
      },
      disclosure:
        "Transaction limits apply, scheduling and processing times are subject to cut-off times, consult our __Deposit Account Agreement__.",
      pastCutOffTimeError:
        "The Same-Day ACH cut-off time has passed, please choose standard ACH or schedule for a different day.",
    },
    reviewPayment: {
      title: "Review payment",
      inputs: {
        pay: "Pay",
        to: "to",
        paymentMethod: {
          label: "Payment Method",
          fee: "{{amount}} Fee",
          noFee: "No fee",
          standardAch: "Standard ACH",
          sameDayAch: "Same Day ACH",
          mailedCheck: "Physical Check",
          wire: "Domestic Wire",
          billPay: "Digital Bill Pay",
        },
        paymentDate: {
          label: "Payment Date",
          estimatedArrival: "Estimated arrival: {{date}}",
          estimatedArrivalToday: "Estimated arrival: Today, {{date}}",
          noDate: "No date selected",
        },
        sendTo: {
          label: "Send to",
        },
        withdrawFrom: {
          label: "Withdraw from",
          depositAccount: "Deposit Account",
          envelope: "Envelope",
        },
      },
      disclosure:
        "By sending a wire, you confirm you've read and agree to the __Wire Transfer Agreement__. ",
      buttons: {
        editPayment: "Edit payment details",
        sendPayment: "Send payment",
        schedulePayment: "Schedule payment",
      },
      error: {
        invoicePaymentError:
          "There was a problem sending this payment. Please try again. If that doesn’t work,",
        invoiceScheduledPaymentError:
          "There was a problem scheduling this payment. Please try again. If that doesn’t work,",
        customerCareLink: "contact Customer Care",
        insufficientBalanceError: "You have insufficient funds for this payment.",
        exceedsLimitError:
          "This amount exceeds your payment limits. Please adjust amount or try again later.",
        invalidAchDetailsError:
          "Your ACH payment could not be processed. Please adjust the payment details to try again.",
        missingDetailsError:
          "Some details are missing for this recipient. Please edit the payment details or try again later.",
        missingAddressError:
          "The address is missing for this recipient. Please add an address or try again later.",
        cardNotActivatedError:
          "Your debit card must be activated before you can send this payment. Activate your card to try again.",
        invalidWireDetailsError:
          "Your wire payment could not be processed. Please adjust the payment details to try again.",
        invalidScheduledStartDateError:
          "The date you selected is in the past. Please schedule on a date in the future.",
      },
    },
    invoiceViewer: {
      title: "Drop your bill or click to upload",
      subTitle: "We accept .pdf, .jpg, and .png",
      error: {
        defaultTitle: "There was an issue uploading your bill.",
        uploadTitle: "We could not upload your bill.",
        fileSize: "Your file was too large.",
        fileFormat: "We only accept .pdf, .jpg, and .png.",
        uploadAgain: "Drop your bill or click to upload to try again.",
      },
      deleteButton: "Delete bill",
    },
  },
  table: {
    emptyState: {
      unpaid: {
        title: "You have no bills",
        click: "Click",
        addInvoice: "Add bill",
        toUploadOrEmailThemTo: "to upload or email them to",
      },
      paid: {
        title: "You have no paid bills",
      },
      archived: {
        title: "You have no archived bills",
      },
    },
  },
}
