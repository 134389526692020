import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { formatMoney } from "accounting"
import { Box } from "rebass"
import { LoadingIcon } from "@northone/ui"

import { images } from "@assets/images/images"
import { Text } from "@components/primitive/text/text"
import {
  InvoiceStatus,
  SortKey,
  SortDirection,
  useInvoicePaymentsContactListQuery,
  useInvoiceForwardingEmailQuery,
} from "@generated/graphql"
import { ErrorScreen } from "@components/composite/error-screen/error-screen"
import { Table } from "@components/composite/table/table"
import { Tabs } from "@components/composite/tabs/tabs"
import { appActions } from "@core/redux/app/actions"
import { BaseLayout } from "@layouts/base-layout"
import { FlexColumnLayout, FlexRowLayout } from "@layouts/flex"
import { Button, ButtonTypeEnum } from "@components/primitive/button/button"
import { RoutesEnum } from "@routers/types"
import { getDateWithSpecificFormat } from "@utils/dates"

import { InvoiceQuickViewPanel, MISSING_VALUE_PLACEHOLDER } from "./invoice-quick-view-panel"
import { InvoiceTableEmptyState } from "./invoice-table-empty-state"
import { InvoiceStatusPills } from "./invoice-status-pills"
import { invoicePaymentsActions, TInvoicesQueryVariables } from "./redux/actions"
import { TInvoiceAccountsPayable } from "./redux/reducer"
import { getInvoicePayments } from "./redux/selector"
import { useListInvoiceAccountsPayable } from "./hooks"
import { useAppSelector } from "@core/redux/utils"
import { selectActiveBusinessID } from "@core/active-business/redux/selectors"
import { NoticeBox } from "@components/primitive/notice-box/notice-box"
import { theme } from "@layouts/theme"
import { Analytics } from "@core/analytics/actions"
import { events } from "@core/analytics/events"
import { INVOICE_PAYMENTS_FAQ } from "@utils/constants"

const formatDateOrDefault = (date: string | undefined): string => {
  return date ? getDateWithSpecificFormat(date, "LLL d, yyyy") : MISSING_VALUE_PLACEHOLDER
}

const formatDollars = (amountInDollars: unknown) => formatMoney(Number(amountInDollars))

const VENDOR_NAME_MAX_CHARACTERS = 32

export const InvoicePaymentsLanding = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  const businessId = useAppSelector(selectActiveBusinessID)
  const { activeInvoice, invoicesQueryVariables } = useAppSelector(getInvoicePayments)

  const [isRefreshInProgress, setIsRefreshInProgress] = useState<boolean>(false)

  const setActiveInvoice = (activeInvoice: TInvoiceAccountsPayable | undefined) => {
    dispatch(invoicePaymentsActions.setActiveInvoice(activeInvoice))
  }

  const {
    cursor,
    invoices,
    invoicesLoading,
    invoicesError,
    hasMoreInvoices,
    fetchMoreInvoices: fetchMoreInvoicesQuery,
    moreInvoicesLoading,
    moreInvoicesError,
    poll: pollInvoices,
    refetch: refetchInvoices,
  } = useListInvoiceAccountsPayable({
    queryVariables: invoicesQueryVariables,
  })

  const fetchMoreInvoices = () =>
    fetchMoreInvoicesQuery({
      variables: {
        businessId,
        ...invoicesQueryVariables,
        pagination: {
          cursor,
        },
      },
    })

  const { data: invoiceForwardingEmailData, loading: isForwardingEmailQueryLoading } =
    useInvoiceForwardingEmailQuery({ variables: { businessId } })

  const forwardingEmailAddress = invoiceForwardingEmailData?.business?.invoiceForwardingEmail

  // preload contact list
  useInvoicePaymentsContactListQuery({ variables: { businessId } })

  const handleSortChange = (sortColumn: string, sortDirection: SortDirection) => {
    updateInvoicesQueryVariables({
      sorting: {
        sortKey: sortColumn as SortKey,
        sortDirection,
      },
    })
  }

  const updateInvoicesQueryVariables = (invoicesQueryVariables: TInvoicesQueryVariables) => {
    dispatch(invoicePaymentsActions.updateInvoicesQueryVariables(invoicesQueryVariables))
  }

  const handleRowClick = (invoice: TInvoiceAccountsPayable) => {
    setActiveInvoice(invoice)
  }

  const onInvoiceUpdateCompleted = async ({ message }: { message?: string } | void = {}) => {
    // Refresh view
    await pollInvoices()
    await refetchInvoices()

    // Close invoice quick view panel
    setActiveInvoice(undefined)

    // Display toast message
    if (message) {
      dispatch(appActions.setNotificationMessage(message))
    }
  }

  const {
    filters,
    sorting: { sortKey, sortDirection },
  } = invoicesQueryVariables

  const statusFilter = filters?.status ?? InvoiceStatus.UNPAID

  useEffect(() => {
    // Close invoice quick view panel on initial page load, and on "status" filter change.
    setActiveInvoice(undefined)
  }, [statusFilter])

  const hasEmptyData = invoices.length === 0 && !invoicesLoading && statusFilter
  if (isForwardingEmailQueryLoading || !forwardingEmailAddress) {
    return (
      <FlexRowLayout sx={{ gridColumn: "1/12" }}>
        <FlexColumnLayout
          alignItems="center"
          justifyContent="center"
          sx={{ margin: "auto", height: "50vh" }}
        >
          <LoadingIcon dimension={50} />
        </FlexColumnLayout>
      </FlexRowLayout>
    )
  }

  const quickViewPanel = activeInvoice ? (
    <InvoiceQuickViewPanel
      invoiceId={activeInvoice.id}
      onCloseClick={() => setActiveInvoice(undefined)}
      onInvoiceUpdateCompleted={onInvoiceUpdateCompleted}
      cachedInvoiceData={activeInvoice}
    />
  ) : null

  return (
    <BaseLayout
      withLogoFooter
      pageTitle={t("pageTitles.invoicePayments")}
      rightAside={quickViewPanel}
      childrenGridColumn={activeInvoice ? "1/8" : "1/11"}
      rightAsideGridColumn="8/13"
    >
      <FlexColumnLayout>
        <FlexRowLayout justifyContent="space-between">
          <Text tag="h2">{t("invoicePayments.landing.title")}</Text>
          <Box sx={{ display: "flex" }}>
            <Button
              type={ButtonTypeEnum.TERTIARY_ALT}
              iconSrc={images.icons.refresh}
              iconSx={invoicesLoading || isRefreshInProgress ? { opacity: 0.5 } : {}}
              onClick={async () => {
                Analytics.track(events.invoicePayments.refreshInvoicesClick)

                // Close invoice quick view panel
                setActiveInvoice(undefined)

                try {
                  setIsRefreshInProgress(true)
                  await refetchInvoices()
                } finally {
                  setIsRefreshInProgress(false)
                }
              }}
              disabled={invoicesLoading || isRefreshInProgress}
              isLoading={isRefreshInProgress && invoicesLoading}
            >
              {t("invoicePayments.landing.refreshInvoices")}
            </Button>

            <Button
              sx={{ marginLeft: "12px" }}
              type={ButtonTypeEnum.PRIMARY_BLUE}
              iconSrc={images.icons.add}
              onClick={() => {
                Analytics.track(events.invoicePayments.addInvoiceClick)
                dispatch(invoicePaymentsActions.clearState())
                history.push(RoutesEnum.INVOICE_PAYMENTS)
              }}
            >
              {t("invoicePayments.landing.addInvoice")}
            </Button>
          </Box>
        </FlexRowLayout>

        {Boolean(forwardingEmailAddress) && (
          <NoticeBox
            level="info"
            containerSx={{
              bg: theme.colors.ui6,
              border: 0,
              mt: "1rem",
              backgroundImage: `url('${images.icons.noticeGreen}')`,
              backgroundPosition: "14px center",
            }}
            content={
              <Text tag="body">
                <strong style={{ fontWeight: 500 }}>
                  {t("invoicePayments.landing.newInvoiceBanner.newInvoice")}
                </strong>{" "}
                <Text tag="body" hasPartialBolding textColor={theme.colors.ui2} as="span">
                  {t("invoicePayments.landing.newInvoiceBanner.clickAddInvoiceOrEmail", {
                    forwardingEmailAddress,
                  })}
                </Text>
              </Text>
            }
          />
        )}

        <Tabs
          tabs={[
            {
              title: t("invoicePayments.status.unpaid"),
              onTabSelected: () => {
                updateInvoicesQueryVariables({
                  filters: { status: InvoiceStatus.UNPAID },
                })
              },
            },
            {
              title: t("invoicePayments.status.paid"),
              onTabSelected: () => {
                updateInvoicesQueryVariables({
                  filters: { status: InvoiceStatus.PAID },
                })
              },
            },
            {
              title: t("invoicePayments.status.archived"),
              onTabSelected: () => {
                updateInvoicesQueryVariables({
                  filters: { status: InvoiceStatus.ARCHIVED },
                })
              },
            },
          ]}
          sx={{ marginBottom: "-2px" }}
        />

        <Table
          columns={[
            {
              title: t("invoicePayments.invoice.vendor"),
              accessor: "vendorName",
              prepareData: (vendorName: string | null | undefined) =>
                vendorName && vendorName.length > VENDOR_NAME_MAX_CHARACTERS
                  ? vendorName.slice(0, VENDOR_NAME_MAX_CHARACTERS) + "..."
                  : vendorName || MISSING_VALUE_PLACEHOLDER,
            },
            {
              title: t("invoicePayments.invoice.amount"),
              accessor: "amount",
              align: "right",
              prepareData: (amount: number | null | undefined) =>
                typeof amount !== "number" ? MISSING_VALUE_PLACEHOLDER : formatDollars(amount),
              width: "80px",
            },
            {
              title: t("invoicePayments.invoice.amountPaid"),
              accessor: "paidAmount",
              align: "right",
              prepareData: (paidAmount: number | null | undefined, { pendingAmount }) =>
                typeof paidAmount !== "number"
                  ? MISSING_VALUE_PLACEHOLDER
                  : formatDollars(paidAmount + pendingAmount),
              width: "90px",
            },
            {
              title: t("invoicePayments.invoice.dueDate"),
              accessor: "dueDate",
              isSortable: true,
              prepareData: formatDateOrDefault,
              width: "85px",
            },
            {
              title: t("invoicePayments.invoice.invoiceDate"),
              accessor: "invoiceDate",
              isSortable: true,
              prepareData: formatDateOrDefault,
              width: "105px",
              isHidden: Boolean(activeInvoice),
            },
            {
              title: t("invoicePayments.invoice.invoiceNumber"),
              accessor: "invoiceNumber",
              prepareData: (invoiceNumber: string | null | undefined) =>
                invoiceNumber || MISSING_VALUE_PLACEHOLDER,
              width: "65px",
              isHidden: Boolean(activeInvoice),
            },
            {
              title: t("invoicePayments.invoice.status"),
              accessor: "status",
              align: "left",
              width: "150px",
              dataRender: (_status, invoice) => (
                <InvoiceStatusPills invoice={invoice} showMinimalView />
              ),
            },
            {
              title: "InvoiceId",
              accessor: "id",
              isHidden: true,
            },
          ]}
          data={invoicesLoading ? [] : invoices}
          onClick={handleRowClick}
          onSortChange={({ column, sortDirection }) =>
            handleSortChange(column.accessor, sortDirection)
          }
          sortByColumn={sortKey}
          sortDirection={sortDirection}
          activeRow={activeInvoice}
          pagination={{
            hasMoreItems: hasMoreInvoices,
            moreItemsLoading: moreInvoicesLoading,
            fetchMoreItems: fetchMoreInvoices,
          }}
        />
        {hasEmptyData && !invoicesError && forwardingEmailAddress && (
          <InvoiceTableEmptyState
            statusFilter={statusFilter}
            forwardingEmailAddress={forwardingEmailAddress}
          />
        )}
        {invoicesLoading ? (
          <FlexColumnLayout
            alignItems="center"
            justifyContent="center"
            sx={{ margin: "auto", height: "30vh", width: "100%", background: theme.colors.ui6 }}
          >
            <LoadingIcon dimension={36} />
          </FlexColumnLayout>
        ) : null}
        <FlexRowLayout
          sx={{
            paddingTop: 24,
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Text tag="body-small-bold" textColor={theme.colors.ui2}>
            {t("invoicePayments.landing.disclosure.title")}
          </Text>
          <Text
            color={theme.colors.ui2}
            sx={{ margin: 1 }}
            tag="body-small"
            textWithEmbeddedLinksProps={{
              text: t("invoicePayments.landing.disclosure.message"),
              linksInOrder: [INVOICE_PAYMENTS_FAQ, 'javascript:window.Intercom("show")'],
              linkStyle: { color: theme.colors.ui2 },
            }}
          />
        </FlexRowLayout>

        {invoicesError || moreInvoicesError ? <ErrorScreen size="large" /> : null}
      </FlexColumnLayout>
    </BaseLayout>
  )
}
