import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { v4 as uuid } from "uuid"
import { ApolloError } from "@apollo/client/core"
import { Analytics } from "@core/analytics/actions"
import { events } from "@core/analytics/events"
import { useAppSelector } from "@core/redux/utils"
import { selectActiveBusinessID } from "@core/active-business/redux/selectors"
import { ACHScreensEnum } from "@features/move-money/router/ach"
import { FormBody } from "@components/composite/form-body/form-body"
import { ButtonTypeEnum } from "@components/primitive/button/button"
import { ACH_RECIPIENTS } from "@features/move-money/operations.gql"
import { moveMoneyActions } from "@features/move-money/redux/actions"
import {
  selectEditRecipient,
  selectACHRecipientFieldsAreValid,
} from "@features/move-money/redux/selectors"
import {
  RECIPIENT_CONTINUE_BTN,
  RECIPIENT_EDIT_TITLE,
  RECIPIENT_ADD_TITLE,
  RECIPIENT_EDIT_SUBTITLE,
  BUTTONS_GO_BACK,
  ERRORS_GENERIC,
  ERROR_DUPLICATE_ACH_CONTACT,
} from "@features/move-money/utils/i18n-translations"
import { TermsDisclaimer } from "@features/move-money/shared/terms-disclaimer"
import {
  selectACHUpdateRecipientVariables,
  selectACHCreateRecipientVariables,
} from "@features/settings/recipients/edit/redux/selectors"
import { pollWithMaxRetries } from "@utils/poll-with-max-retries"
import { MAX_RECIPIENT_NAME_LENGTH } from "@utils/constants"
import { FlexColumnLayout } from "@layouts/flex"
import {
  useRoutingNumberAchIsValidLazyQuery,
  useRecipientDomesticAchCreateMutation,
  useRecipientDomesticAchUpdateMutation,
  ContactPaymentType,
  ErrorCodeEnum,
} from "@generated/graphql"
import { ACHRecipientDetails } from "./ach-details"
import { hasGQLErrorType } from "@utils/errors"

export const ACHRecipientDetailsForm: React.FC = () => {
  const dispatch = useDispatch()
  const [idempotencyKey] = useState<string>(uuid())
  const [errorMessage, setErrorMessage] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [confirmButtonPressed, setConfirmButtonPressed] = useState(false)
  const titleRef: React.RefObject<HTMLDivElement> = React.useRef(null)
  const editingRecipient = useAppSelector(selectEditRecipient)
  const businessId = useAppSelector(selectActiveBusinessID)
  const recipientFieldsAreValid = useAppSelector(selectACHRecipientFieldsAreValid)
  const domesticAchRecipientUpdateVariables = useAppSelector(
    selectACHUpdateRecipientVariables(idempotencyKey),
  )
  const domesticAchRecipientCreateVariables = useAppSelector(
    selectACHCreateRecipientVariables(idempotencyKey),
  )
  const { id, bankRoutingNumber, bankAccountNumber, name } = editingRecipient
  const nameLengthExceeded = (name && name?.length > MAX_RECIPIENT_NAME_LENGTH) || false

  const buttons = [
    {
      type: ButtonTypeEnum.TERTIARY,
      children: BUTTONS_GO_BACK,
      onClick: () => dispatch(moveMoneyActions.navigateBack()),
    },
    {
      canContinueWithKey: true,
      type: ButtonTypeEnum.PRIMARY_GOLD,
      children: RECIPIENT_CONTINUE_BTN,
      disabled: !recipientFieldsAreValid || confirmButtonPressed,
      onClick: () => {
        setConfirmButtonPressed(true)
        setIsLoading(true)
        setErrorMessage("")
        getRoutingNumberIsValid({ variables: { routingNumber: bankRoutingNumber || "" } })
      },
      isLoading,
    },
  ]

  const navigateToNextScreen = () => {
    dispatch(moveMoneyActions.navigateNext(ACHScreensEnum.ACH_AMOUNT))
    Analytics.track(events.moveMoney.ach.recipientDetails)
  }

  const [getRoutingNumberIsValid, { data: routingNumberData }] =
    useRoutingNumberAchIsValidLazyQuery({
      onCompleted: (data) => {
        if (data) {
          if (!data.routingNumberACHIsValid && titleRef && titleRef.current) {
            setIsLoading(false)
            setConfirmButtonPressed(false)
            titleRef.current.scrollIntoView({ behavior: "smooth", block: "center" })
          }
          if (data.routingNumberACHIsValid && name && bankAccountNumber && bankRoutingNumber) {
            if (!id) {
              createACHRecipient({ variables: domesticAchRecipientCreateVariables })
            } else {
              updateACHRecipient({ variables: domesticAchRecipientUpdateVariables })
            }
          }
        }
      },
      onError: () => {
        setIsLoading(false)
        setErrorMessage(ERRORS_GENERIC)
        setConfirmButtonPressed(false)
      },
      fetchPolicy: "network-only",
    })

  const getMessageFromError = (error: ApolloError): string => {
    const isDuplicateContact = hasGQLErrorType(
      error,
      ErrorCodeEnum.DUPLICATE_ACH_CONTACT_NOT_ALLOWED,
    )
    return isDuplicateContact ? ERROR_DUPLICATE_ACH_CONTACT : ERRORS_GENERIC
  }

  const [createACHRecipient] = useRecipientDomesticAchCreateMutation({
    onCompleted: (data) => {
      pollWithMaxRetries([
        {
          query: ACH_RECIPIENTS,
          variables: { businessId, contactPaymentType: ContactPaymentType.ACH },
        },
      ])
      const recipient = data.contactDomesticAchCreate?.contact
      if (recipient) {
        dispatch(moveMoneyActions.setRecipient({ ...editingRecipient, id: recipient.id }))
        dispatch(moveMoneyActions.setEditingRecipient({ ...editingRecipient, id: recipient.id }))
        setIsLoading(false)
        navigateToNextScreen()
      }
    },
    onError: (err) => {
      setIsLoading(false)
      setErrorMessage(getMessageFromError(err))
      setConfirmButtonPressed(false)
    },
  })

  const [updateACHRecipient] = useRecipientDomesticAchUpdateMutation({
    onCompleted: () => {
      pollWithMaxRetries([
        {
          query: ACH_RECIPIENTS,
          variables: { businessId, contactPaymentType: ContactPaymentType.ACH },
        },
      ])
      setIsLoading(false)
      navigateToNextScreen()
    },
    onError: (err) => {
      setConfirmButtonPressed(false)
      setIsLoading(false)
      setErrorMessage(getMessageFromError(err))
    },
  })

  return (
    <FormBody
      errorMessage={errorMessage}
      titleRef={titleRef}
      title={id ? RECIPIENT_EDIT_TITLE : RECIPIENT_ADD_TITLE}
      subtitle={id ? RECIPIENT_EDIT_SUBTITLE : undefined}
      buttons={buttons}
    >
      <FlexColumnLayout>
        <ACHRecipientDetails
          routingNumberIsValid={Boolean(
            !routingNumberData || routingNumberData.routingNumberACHIsValid,
          )}
          nameLengthExceeded={nameLengthExceeded}
        />
        <TermsDisclaimer />
      </FlexColumnLayout>
    </FormBody>
  )
}
