import React from "react"
import { Box, SxStyleProp } from "rebass"
import { FlexRowLayout } from "@layouts/flex"
import { theme } from "@layouts/theme"

export interface FlowModalBodyProps {
  children?: React.ReactNode
  sidepanel?: React.ReactElement
  layout?: "form" | "centered"
  scrollContainerRef?: React.RefObject<HTMLDivElement>
  containerStyle?: SxStyleProp
}

const VERTICAL_PADDING = 72

export const FlowModalBody = ({
  children,
  sidepanel,
  layout = "form",
  scrollContainerRef,
  containerStyle,
}: FlowModalBodyProps) =>
  layout === "form" ? (
    <FlowModalForm
      containerStyle={containerStyle}
      scrollContainerRef={scrollContainerRef}
      sidepanel={sidepanel}
    >
      {children}
    </FlowModalForm>
  ) : (
    <FlowModalCentered
      scrollContainerRef={scrollContainerRef}
      sidepanel={sidepanel}
      containerStyle={containerStyle}
    >
      {children}
    </FlowModalCentered>
  )

const FlowModalForm = ({
  children,
  sidepanel,
  scrollContainerRef,
  containerStyle,
}: Omit<FlowModalBodyProps, "layout">) => (
  <FlexRowLayout
    sx={{
      width: "100%",
      justifyContent: "space-between",
      height: "100%",
    }}
  >
    <Box
      ref={scrollContainerRef}
      sx={{
        marginX: -theme.spacing.listItemPadding,
        paddingX: theme.spacing.listItemPadding,
        paddingLeft: 168 + theme.spacing.listItemPadding,
        width: "60%",
        overflow: "auto",
        paddingY: VERTICAL_PADDING,
        ...containerStyle,
      }}
    >
      {children}
    </Box>
    {sidepanel && (
      <Box
        sx={{
          paddingLeft: "10%",
          width: "38%",
          paddingRight: 47,
          paddingY: VERTICAL_PADDING,
          alignSelf: "flex-start",
          overflow: "auto",
          maxHeight: "100%",
        }}
      >
        {sidepanel}
      </Box>
    )}
  </FlexRowLayout>
)

const FlowModalCentered = ({
  children,
  scrollContainerRef,
  containerStyle,
}: Omit<FlowModalBodyProps, "layout">) => (
  <Box ref={scrollContainerRef} sx={{ height: "100%", overflow: "auto" }}>
    <FlexRowLayout
      sx={{
        width: "100%",
        justifyContent: "center",
        paddingY: VERTICAL_PADDING,
        ...containerStyle,
      }}
    >
      {children}
    </FlexRowLayout>
  </Box>
)
