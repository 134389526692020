import moment from "moment"
import i18n from "../i18n/i18n"
import { isEmpty } from "lodash-es"
import { DateTime, Duration } from "luxon"
import { LocationInput, LocationStrictInput, ScheduleFrequencyEnum } from "@generated/graphql"

import { scheduleFrequencyToI18n } from "./mapping/schedules.mapping"

export const formatBase64Uri = (base64: string) => `data:image/png;base64,${base64}`

export const momentCalendarSpec: moment.CalendarSpec = {
  sameDay: "[today]",
  nextDay: "[tomorrow]",
  nextWeek: "on dddd",
  sameElse: "on MMMM D, YYYY",
}

export const momentCalendarSpecPreviewDate: moment.CalendarSpec = {
  sameDay: "[Today]",
  nextDay: "[Tomorrow]",
  sameElse: "MMMM D, YYYY",
}

export const momentCalendarSpecEndDate: moment.CalendarSpec = {
  sameDay: "[today]",
  nextDay: "[tomorrow]",
  nextWeek: "dddd",
  sameElse: "MMMM D, YYYY",
}

export const momentCalendarSpecDefault: moment.CalendarSpec = {
  sameDay: "[today]",
  nextDay: "MMMM D, YYYY",
  nextWeek: "MMMM D, YYYY",
  sameElse: "MMMM D, YYYY",
}

export const momentCalendarSpecPaymentSent: moment.CalendarSpec = {
  sameDay: "[today], MMMM D, YYYY",
  nextDay: "[tomorrow], MMMM D, YYYY",
  nextWeek: "on MMMM D, YYYY",
  sameElse: "on MMMM D, YYYY",
}

export const frequencyToMomentDuration: {
  [key in ScheduleFrequencyEnum]: moment.unitOfTime.DurationConstructor
} = {
  ONCE: "day",
  EVERY_DAY: "days",
  EVERY_WEEK: "weeks",
  EVERY_TWO_WEEKS: "weeks",
  EVERY_MONTH: "months",
}

export const frequencyToLuxonDuration: {
  [key in ScheduleFrequencyEnum]: keyof Duration
} = {
  ONCE: "days",
  EVERY_DAY: "days",
  EVERY_WEEK: "weeks",
  EVERY_TWO_WEEKS: "weeks",
  EVERY_MONTH: "months",
}

export const getScheduleDetail = (
  schedule: ScheduleFrequencyEnum,
  scheduleEndDate?: string,
  convertToUtc?: boolean,
) => {
  const isOneTimePayment = schedule === ScheduleFrequencyEnum.ONCE

  let scheduleDetail: string
  if (schedule && !isOneTimePayment && scheduleEndDate) {
    scheduleDetail = i18n.t("transactions.previewPanel.scheduleDetailWithEndDate", {
      scheduleFrequency: scheduleFrequencyToI18n[schedule],
      endDate: convertToUtc
        ? moment(
            scheduleEndDate.length === 10
              ? Number(scheduleEndDate) * 1000
              : Number(scheduleEndDate),
          )
            .utc()
            .calendar(null, momentCalendarSpecEndDate)
        : moment(
            scheduleEndDate.length === 10
              ? Number(scheduleEndDate) * 1000
              : Number(scheduleEndDate),
          ).calendar(null, momentCalendarSpecEndDate),
    })
  } else if (schedule && !isOneTimePayment) {
    scheduleDetail = i18n.t("transactions.previewPanel.scheduleDetailNoEndDate", {
      scheduleFrequency: scheduleFrequencyToI18n[schedule],
    })
  } else if (schedule && isOneTimePayment) {
    scheduleDetail = `${scheduleFrequencyToI18n[schedule]}`
  } else {
    scheduleDetail = i18n.t("transactions.previewPanel.unspecified")
  }

  return scheduleDetail
}

export const obscureEmail = (email?: string | null) => {
  const emailName = email?.split("@")[0]
  const domainName = email?.split("@")[1].split(".")[0]
  const domainSuffix = email?.split("@")[1].split(".")[1]
  const obscuredEmailName = emailName
    ?.split("")
    .map((char, index) => (index > 1 ? "*" : char))
    .join("")

  const obscuredDomainName = domainName
    ?.split("")
    .map(() => "*")
    .join("")

  return `${obscuredEmailName}@${obscuredDomainName}.${domainSuffix}`
}

export const sanitizePhoneNumber = (rawPhoneNumber: string) => {
  const phoneNumber = rawPhoneNumber.replace(/[^0-9]/g, "")
  return phoneNumber.length > 10 ? phoneNumber.slice(1, 11) : phoneNumber
}
export const toTitleCase = ({
  text,
  ignoreAlreadyUpperCased = false,
}: {
  text: string
  ignoreAlreadyUpperCased?: boolean
}) =>
  text.replace(/\w\S*/g, (txt) =>
    ignoreAlreadyUpperCased && /[A-Z]/.test(txt.charAt(0))
      ? txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase()
      : txt,
  )

export const convertToFullName = ({
  firstName,
  lastName,
}: {
  firstName: string
  lastName: string
}) => {
  return `${firstName} ${lastName}`
}

export const convertDateToString = (date: Date) => {
  return DateTime.fromJSDate(date).toFormat("yyyy-MM-dd")
}

export const convertStringToDate = (date: string) => {
  return DateTime.fromISO(date).toJSDate()
}

export const formatAddress = (address: LocationStrictInput | LocationInput): string => {
  if (isEmpty(address)) {
    return ""
  }

  const formattedAddress = [
    address?.streetAddressLine1,
    address?.streetAddressLine2,
    address?.city,
    address?.provinceState,
    address?.country,
    address?.postalCode,
  ]
    .filter((value) => !isEmpty(value))
    .join(", ")

  return formattedAddress
}
