import React from "react"
import { formatMoney } from "accounting"
import { events } from "@core/analytics/events"
import { useAppSelector } from "@core/redux/utils"
import { selectActiveBusinessID } from "@core/active-business/redux/selectors"
import { ACHScreensEnum } from "@features/move-money/router/ach"
import { selectAmount } from "@features/move-money/redux/selectors"
import { MoveMoneyAmountForm } from "@features/move-money/shared/amount-form"
import {
  ACH_LIMIT_EXCEEDED_MESSAGE_I18N,
  AMOUNT_INSUFFICIENT_FUNDS,
  AMOUNT_MINIMUM_AMOUNT,
} from "@features/move-money/utils/i18n-translations"
import { useCapabilitiesAndAvailableBalanceQuery } from "@generated/graphql"
import { validate } from "@utils/validate"
import { translate } from "@i18n/i18n"
import { MINIMUM_TRANSACTION_AMOUNT } from "@utils/constants"

export const ACHAmountForm: React.FC = () => {
  const businessId: string = useAppSelector(selectActiveBusinessID)
  const amount = useAppSelector(selectAmount)

  const { data, loading, error, refetch } = useCapabilitiesAndAvailableBalanceQuery({
    variables: { businessId },
    notifyOnNetworkStatusChange: true,
  })
  const availableBalance = data?.business?.bankAccount?.availableBalance || 0
  const achLimit = data?.business?.bankAccounts[0].capabilities?.ach.limit || 0

  const amountAsNumber = Number(amount)
  const exceedsAvailableBalance = amountAsNumber > availableBalance
  const exceedsTransactionLimit = amountAsNumber > achLimit

  const getErrorMessage = () => {
    if (exceedsAvailableBalance) {
      return AMOUNT_INSUFFICIENT_FUNDS
    }

    if (exceedsTransactionLimit) {
      return translate(ACH_LIMIT_EXCEEDED_MESSAGE_I18N, {
        maxAmount: formatMoney(achLimit),
      })
    }

    if (amount && amountAsNumber < MINIMUM_TRANSACTION_AMOUNT) {
      return AMOUNT_MINIMUM_AMOUNT
    }

    return undefined
  }

  const btnDisabled =
    loading ||
    !validate.isValidAmount(amountAsNumber) ||
    exceedsAvailableBalance ||
    exceedsTransactionLimit

  return (
    <MoveMoneyAmountForm
      navigateNextRoute={ACHScreensEnum.ACH_SUB_ACCOUNT}
      analyticsContinueEvent={events.moveMoney.ach.amount}
      isLoading={loading}
      hasError={error}
      retryQuery={() => refetch()}
      validationText={getErrorMessage()}
      continueBtnDisabled={btnDisabled}
    />
  )
}
