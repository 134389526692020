import { createSelector } from "reselect"
import moment from "moment"
import { IActiveBusinessState } from "@core/active-business/redux/reducer"
import { selectMoveMoney, selectActiveBusiness } from "@core/redux/app/selectors"
import { TMoveMoneyScreenNames } from "@features/move-money/router/move-money"
import { validate as recipientValidate } from "@features/move-money/domestic-wire/utils/recipientValidator"
import { businessRelationshipOptions } from "@features/move-money/domestic-wire/utils/constants"
import { validate } from "@utils/validate"
import { MAX_RECIPIENT_NAME_LENGTH } from "@utils/constants"
import {
  AchCreateMutationVariables,
  DomesticWireCreateMutationVariables,
  TransactionTypeEnum,
  Location,
} from "@generated/graphql"
import { ISubAccount } from "@typedefs/types"
import { IMoveMoneyState, IGenericRecipient } from "./state"

export const selectACHGQLVariables = (idempotencyKey: string) =>
  createSelector(
    [selectMoveMoney, selectActiveBusiness],
    (
      moveMoney: IMoveMoneyState,
      activeBusiness: IActiveBusinessState,
    ): AchCreateMutationVariables => {
      return {
        businessId: activeBusiness.businessId,
        data: {
          amount: Number(moveMoney.amount.replace(/,/g, "")),
          contactId: moveMoney.recipient.id || "",
          subAccountId: moveMoney.subAccount.id,
          idempotencyKey,
        },
      }
    },
  )

export const selectDomesticWireGQLVariables = (idempotencyKey: string) =>
  createSelector(
    [selectMoveMoney, selectActiveBusiness],
    (
      moveMoney: IMoveMoneyState,
      activeBusiness: IActiveBusinessState,
    ): DomesticWireCreateMutationVariables => {
      return {
        businessId: activeBusiness.businessId,
        data: {
          amount: Number(moveMoney.amount.replace(/,/g, "")),
          contactId: moveMoney.recipient.id || "",
          subAccountId: moveMoney.subAccount.id,
          purpose: moveMoney.purpose,
          idempotencyKey,
        },
      }
    },
  )

// Move Money
export const selectAmount = createSelector(
  selectMoveMoney,
  (moveMoney: IMoveMoneyState): string | undefined => moveMoney.amount,
)

export const selectSubAccount = createSelector(
  selectMoveMoney,
  (moveMoney: IMoveMoneyState): ISubAccount | undefined => moveMoney.subAccount,
)

export const selectPaymentMethod = createSelector(
  selectMoveMoney,
  (moveMoney: IMoveMoneyState): TransactionTypeEnum | undefined => moveMoney.paymentMethod,
)

export const selectScreenHistory = createSelector(
  selectMoveMoney,
  (moveMoney: IMoveMoneyState): TMoveMoneyScreenNames[] => moveMoney.screenHistory,
)

export const selectWireTransactionIsComplete = createSelector(
  selectMoveMoney,
  (state) => state.wireCompleted,
)

// Recipient
export const selectRecipient = createSelector(
  selectMoveMoney,
  (moveMoney: IMoveMoneyState): IGenericRecipient => moveMoney.recipient,
)

export const selectRecipientName = createSelector(
  selectRecipient,
  (recipient: IGenericRecipient): string | undefined => recipient.name,
)

export const selectRecipientRelationship = createSelector(
  selectRecipient,
  (recipient: IGenericRecipient): string | undefined => recipient.relationship,
)

export const selectRecipientBankName = createSelector(
  selectRecipient,
  (recipient: IGenericRecipient): string | undefined => recipient.bankName,
)

export const selectRecipientBankAccountNumber = createSelector(
  selectRecipient,
  (recipient: IGenericRecipient): string | undefined => recipient.bankAccountNumber,
)

export const selectRecipientBankRoutingNumber = createSelector(
  selectRecipient,
  (recipient: IGenericRecipient): string | undefined => recipient.bankRoutingNumber,
)

export const selectRecipientAddress = createSelector(
  selectRecipient,
  (recipient: IGenericRecipient): Location | undefined => recipient.address,
)

export const startDateIsToday = (startDate: Date | null) => {
  if (startDate) {
    return moment(startDate).isSame(moment(), "day")
  }
  return false
}

// Edit Recipient
export const selectEditRecipient = createSelector(
  selectMoveMoney,
  (moveMoney: IMoveMoneyState): IGenericRecipient => moveMoney.editingRecipient,
)

export const selectEditRecipientName = createSelector(
  selectEditRecipient,
  (recipient: IGenericRecipient): string | undefined => recipient.name,
)

export const selectEditRecipientBankAccountNumber = createSelector(
  selectEditRecipient,
  (recipient: IGenericRecipient): string | undefined => recipient.bankAccountNumber,
)

export const selectEditRecipientBankRoutingNumber = createSelector(
  selectEditRecipient,
  (recipient: IGenericRecipient): string | undefined => recipient.bankRoutingNumber,
)

export const selectEditRecipientAddress = createSelector(
  selectEditRecipient,
  (recipient: IGenericRecipient): Location | undefined => recipient.address,
)

export const selectACHRecipientFieldsAreValid = createSelector(
  selectEditRecipient,
  ({ name, bankRoutingNumber, bankAccountNumber }) => {
    const nameIsValid = name && name.length <= MAX_RECIPIENT_NAME_LENGTH
    const accountNumberIsValid = validate.accountNumber(bankAccountNumber)
    const routingNumberIsValid = validate.routingNumber(bankRoutingNumber)

    return Boolean(nameIsValid && accountNumberIsValid && routingNumberIsValid)
  },
)

export const selectWireRecipientFieldsAreValid = createSelector(
  selectEditRecipient,
  ({
    name,
    address,
    relationship,
    otherRelationship,
    bankName,
    bankAccountNumber,
    bankRoutingNumber,
  }: IGenericRecipient): boolean => {
    const nameIsValid = recipientValidate.name(name)
    const addressIsValid = recipientValidate.address(address)
    const relationshipIsValid =
      relationship &&
      (relationship === businessRelationshipOptions.other
        ? recipientValidate.businessReason(otherRelationship)
        : true)
    const bankNameIsValid = recipientValidate.bankName(bankName)
    const accountNumberIsValid = recipientValidate.accountNumber(bankAccountNumber)
    const routingNumberIsValid = recipientValidate.routingNumber(bankRoutingNumber)
    return Boolean(
      nameIsValid &&
        addressIsValid &&
        relationshipIsValid &&
        bankNameIsValid &&
        accountNumberIsValid &&
        routingNumberIsValid,
    )
  },
)

export const selectWireRecipientAddressIsValid = createSelector(
  selectRecipientAddress,
  (address): boolean => Boolean(recipientValidate.address(address)),
)
