import React, { useState, useRef } from "react"
import { Box, Image, SxStyleProp } from "rebass"
import { Text } from "../text/text"
import { theme } from "@layouts/theme"
import { images } from "@assets/images/images"
import { useClickOutside } from "@utils/hooks"

export interface ISelectionControlProps {
  type: "radio" | "checkbox"
  selected: boolean
  onSelect: () => void
  text?: string
  disabled?: boolean
  containerSx?: SxStyleProp
  iconSx?: SxStyleProp
  textSx?: SxStyleProp
}

export const SelectionControl: React.FC<ISelectionControlProps> = ({
  text,
  selected,
  disabled,
  onSelect,
  type,
  containerSx,
  iconSx,
  textSx,
}) => {
  const [focused, setFocused] = useState(false)
  const ref = useRef<HTMLDivElement>(null)
  useClickOutside([ref], () => setFocused(false))

  const selectionState = selected ? "selected" : "unselected"
  const focusState = focused ? "focused" : disabled ? "disabled" : "default"

  return (
    <Box
      ref={ref}
      onClick={() => {
        onSelect()
        setFocused(true)
      }}
      sx={{
        display: "flex",
        alignItems: "center",
        paddingBottom: "8px",
        cursor: "pointer",
        ...containerSx,
      }}
    >
      <Image
        tabIndex={0}
        sx={{ outline: "none", ...iconSx }}
        src={images.icons.selectionControl[type][selectionState][focusState]}
      />
      {text && (
        <Text
          sx={{ marginLeft: "8px", ...textSx }}
          tag="body"
          textColor={disabled ? theme.colors.ui3 : theme.colors.ui1}
        >
          {text}
        </Text>
      )}
    </Box>
  )
}
