import { ApolloError } from "@apollo/client"
import { selectActiveBusinessID } from "@core/active-business/redux/selectors"
import { useAppSelector } from "@core/redux/utils"
import { Subscription, SubscriptionPlan } from "@features/subscriptions/types"
import { formatGQLPlanData, formatSubscriptionData } from "@features/subscriptions/utils"

import { useSubscriptionPlansDataQuery } from "@generated/graphql"

export const useSubscriptionsData = (): {
  loading?: boolean
  error?: ApolloError
  refetch?: () => void
  plans?: (SubscriptionPlan | undefined)[]
  terms?: string[] | null
  subscription?: Subscription
} => {
  const businessId = useAppSelector(selectActiveBusinessID)

  const { data, loading, error, refetch } = useSubscriptionPlansDataQuery({
    variables: { businessId },
  })

  const plans = data?.subscriptionPlans?.plans?.map((plan) => formatGQLPlanData(plan))
  const terms = data?.subscriptionPlans?.footer
  const activePlanName = data?.subscription?.plan
  const nextBillingDate = data?.subscription?.nextBillingDate ?? undefined

  const activePlan = plans?.find((plan) => plan?.name === activePlanName)
  const status = data?.subscription?.status
  const subscription = formatSubscriptionData({ plan: activePlan, nextBillingDate, status })

  return {
    loading,
    error,
    refetch,
    plans,
    terms,
    subscription,
  }
}
