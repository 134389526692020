import React from "react"
import { useTranslation } from "react-i18next"
import { useHistory, useParams } from "react-router-dom"
import { RoutesEnum } from "@routers/types"
import { Text } from "@components/primitive/text/text"
import { QueryResults } from "@components/composite/query-results/query-results"
import { BackButton } from "@components/composite/back-button/back-button"
import { NavigationListItem } from "@components/extended/list-items/navigation/navigation-list-item"
import { BaseLayout } from "@layouts/base-layout"
import { useFaqCategoryQuery, useFaqLandingQuery } from "@generated/graphql"

export const FAQSection: React.FC = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const { sectionId } = useParams<{ sectionId: string }>()

  const { data: categoryData } = useFaqLandingQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
  })
  const { data, error, loading, refetch } = useFaqCategoryQuery({
    notifyOnNetworkStatusChange: true,
    variables: { sectionId: sectionId },
  })

  const faqArticles = data?.supportHelpCenter?.faqs
  const activeSection = categoryData?.supportHelpCenter.sections.find(
    (item) => item.id === sectionId,
  )

  return (
    <BaseLayout
      pageTitle={
        activeSection
          ? t("pageTitles.support.faqCategory", { categoryName: activeSection.name })
          : ""
      }
    >
      <BackButton
        onClick={() => history.push(RoutesEnum.SUPPORT_FAQS)}
        text={t("support.faq.backToFaqs")}
        sx={{ marginBottom: 16 }}
      />
      <Text tag="h2">{activeSection?.name}</Text>
      <QueryResults
        loading={loading}
        error={error}
        size="medium"
        retry={() => refetch}
        listShimmerCount={5}
      >
        {faqArticles?.map((article) => (
          <NavigationListItem
            key={article.id}
            text={article.title}
            onClick={() =>
              history.push(`${RoutesEnum.SUPPORT_FAQS}/${activeSection?.id}/${article.id}`)
            }
          />
        ))}
      </QueryResults>
    </BaseLayout>
  )
}
