import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { motion } from "framer-motion"

import { Text } from "@components/primitive/text/text"
import { TransactionList } from "@components/composite/transaction-list/transaction-list"
import { selectActiveBusinessID } from "@core/active-business/redux/selectors"
import { useAppSelector } from "@core/redux/utils"
import { useBusinessNameQuery, useTransactionsListQuery } from "@generated/graphql"
import { BaseLayout } from "@layouts/base-layout"

import { dashboardActions } from "./redux/actions"
import {
  selectDashboardTransactionID,
  selectDashboardIsExporting,
  selectDashboardIsFiltering,
} from "./redux/selectors"
import { DashboardSearchBar } from "./search/dashboard-search-bar"
import { ExportPanel } from "./search/export/panel"
import { FilterPanel } from "./search/filter/panel"
import { TeamBankingBannerHomepageWrapper } from "./team-banking/team-banking-banner-homepage-wrapper"
import { DashboardTransactionPanel } from "./transactions/transaction-panel"

export const Dashboard = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const activeTransactionId = useAppSelector(selectDashboardTransactionID)
  const businessId = useAppSelector(selectActiveBusinessID)
  const isExporting = useAppSelector(selectDashboardIsExporting)
  const isFiltering = useAppSelector(selectDashboardIsFiltering)
  const [isTeamBankingBannerLoading, setIsTeamBankingBannerLoading] = useState(true)

  const { data: transactionsData, loading: areTransactionsLoading } = useTransactionsListQuery({
    variables: { businessId },
    notifyOnNetworkStatusChange: true,
  })

  const getRightAside = () => {
    if (isExporting) {
      return <ExportPanel />
    }
    if (isFiltering) {
      return <FilterPanel />
    }
    if (activeTransactionId) {
      return <DashboardTransactionPanel />
    }

    return null
  }
  const { data, loading: isBusinessNameLoading } = useBusinessNameQuery({
    variables: { businessId },
  })

  const firstName = data?.me?.firstName
  const businessName = data?.business?.name
  const hasTransactions = transactionsData?.transactionsList?.transactionListItems.length

  const welcomeHeaderMarginBottom = 36

  const onTeamBankingBannerWrapperLoaded = () => {
    setIsTeamBankingBannerLoading(false)
  }

  const loading = isBusinessNameLoading || areTransactionsLoading || isTeamBankingBannerLoading

  return (
    <BaseLayout
      pageTitle={businessName ? t("pageTitles.dashboardName", { businessName }) : ""}
      rightAside={getRightAside()}
    >
      <motion.div layout="position">
        {loading ? (
          <Text tag="h2" sx={{ marginBottom: welcomeHeaderMarginBottom }}>
            {t("dashboard.welcomeLoading")}
          </Text>
        ) : (
          <Text
            tag="h2"
            sx={{
              marginBottom: welcomeHeaderMarginBottom,
              opacity: firstName ? 1 : 0,
            }}
          >
            {hasTransactions
              ? t("dashboard.welcome", { firstName })
              : t("dashboard.emptyState.title", { firstName })}
          </Text>
        )}

        <TeamBankingBannerHomepageWrapper onLoaded={onTeamBankingBannerWrapperLoaded} />

        {hasTransactions ? <DashboardSearchBar /> : null}
      </motion.div>

      <TransactionList
        activeTransactionId={activeTransactionId}
        transactionOnClick={(transactionId) =>
          dispatch(dashboardActions.setActiveTransactionId(transactionId))
        }
      />
    </BaseLayout>
  )
}
