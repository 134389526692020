import { AppActionEnum, TAppActions } from "./actions"

export interface IAppState {
  notificationMessage: string
}

export const initialAppState: IAppState = {
  notificationMessage: "",
}

export const appReducer = (state: IAppState = initialAppState, action: TAppActions): IAppState => {
  if ("type" in action) {
    switch (action.type) {
      case AppActionEnum.SET_NOTIFICATION_MESSAGE:
        return {
          ...state,
          notificationMessage: action.payload,
        }

      default:
        return state
    }
  }
  return state
}
