import {
  PROGRESS_BAR_PAYMENT_METHOD,
  PROGRESS_BAR_RECIPIENT,
  PROGRESS_BAR_RECIPIENT_DETAILS,
  PROGRESS_BAR_AMOUNT,
  PROGRESS_BAR_ACCOUNT,
  PROGRESS_BAR_CONFIRMATION,
  PROGRESS_BAR_PAYMENT_PURPOSE,
} from "@features/move-money/utils/i18n-translations"

export const moveMoneyProgressBarItems = [
  PROGRESS_BAR_PAYMENT_METHOD,
  PROGRESS_BAR_RECIPIENT,
  PROGRESS_BAR_RECIPIENT_DETAILS,
  PROGRESS_BAR_AMOUNT,
  PROGRESS_BAR_ACCOUNT,
  PROGRESS_BAR_CONFIRMATION,
]

export const moveMoneyDomesticWireProgressBarItems = [
  PROGRESS_BAR_PAYMENT_METHOD,
  PROGRESS_BAR_RECIPIENT,
  PROGRESS_BAR_RECIPIENT_DETAILS,
  PROGRESS_BAR_AMOUNT,
  PROGRESS_BAR_ACCOUNT,
  PROGRESS_BAR_PAYMENT_PURPOSE,
  PROGRESS_BAR_CONFIRMATION,
]
