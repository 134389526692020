import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"

import { TextInput } from "@components/extended/fields/text-input"
import { FlexColumnLayout, FlexRowLayout } from "@layouts/flex"
import { Dropdown } from "@components/composite/dropdown/dropdown"
import { addressStateOptions } from "./address-state"
import {
  Contact,
  InvoiceAccountPayablePaymentMethod,
  LocationStrictInput,
} from "@generated/graphql"

export type TMailedCheckPaymentDetails = {
  type: InvoiceAccountPayablePaymentMethod.MAILED_CHECK
  name: string
  address: LocationStrictInput
  mailedCheckPaymentDetails: {
    memo: string
  }
}

interface IMailedCheckPaymentDetailsFormProps {
  memo: string
  setMemo: (purpose: string) => void
  contact: Contact
  onChange: (contactPaymentDetails: TMailedCheckPaymentDetails) => void
  setIsMailedCheckContactPaymentDetailsChanged: (
    isMailedCheckContactPaymentDetailsChanged: boolean,
  ) => void
  setIsMissingFields: (isMissingFields: boolean) => void
}

export const MailedCheckPaymentDetailsForm = ({
  memo,
  setMemo,
  contact,
  onChange,
  setIsMailedCheckContactPaymentDetailsChanged,
  setIsMissingFields,
}: IMailedCheckPaymentDetailsFormProps) => {
  const { t } = useTranslation()
  const [addressLine1, setAddressLine1] = useState<string>(
    contact.address?.streetAddressLine1 || "",
  )
  const [postalCode, setPostalCode] = useState<string>(contact.address?.postalCode || "")
  const [addressLine2, setAddressLine2] = useState<string>(
    contact.address?.streetAddressLine2 || "",
  )
  const [addressState, setAddressState] = useState<string>(contact.address?.provinceState || "")
  const [city, setCity] = useState<string>(contact.address?.city || "")

  const mailedCheckPaymentDetailsContact: TMailedCheckPaymentDetails = {
    type: InvoiceAccountPayablePaymentMethod.MAILED_CHECK,
    name: contact?.name,
    mailedCheckPaymentDetails: {
      memo,
    },
    address: {
      streetAddressLine1: addressLine1,
      streetAddressLine2: addressLine2 ?? null,
      provinceState: addressState,
      postalCode: postalCode,
      country: "US",
      city,
    },
  }

  useEffect(() => {
    const isMailedCheckContactPaymentDetailsChanged =
      !contact?.address && !addressLine1 && !addressLine2 && !addressState && !city && !postalCode
        ? false
        : contact?.address?.streetAddressLine1 !== addressLine1 ||
          (contact?.address?.streetAddressLine2 ?? "") !== addressLine2 ||
          contact?.address?.provinceState !== addressState ||
          contact?.address?.city !== city ||
          contact?.address?.postalCode !== postalCode

    setIsMailedCheckContactPaymentDetailsChanged(isMailedCheckContactPaymentDetailsChanged)

    const isRequiredFieldsEmpty = !addressLine1 || !postalCode || !addressState || !city

    setIsMissingFields(isRequiredFieldsEmpty)

    onChange(mailedCheckPaymentDetailsContact)
  }, [addressLine1, addressLine2, addressState, city, postalCode, memo])

  return (
    <FlexColumnLayout sx={{ mb: 22 }}>
      <TextInput
        label={t("invoicePayments.modal.paymentDetails.inputs.mailedCheck.addressLine1.label")}
        value={addressLine1}
        onChange={setAddressLine1}
        placeholder={t(
          "invoicePayments.modal.paymentDetails.inputs.mailedCheck.addressLine1.placeholder",
        )}
        containerSx={{ marginTop: 22 }}
      />
      <FlexRowLayout sx={{ paddingTop: 22, columnGap: 16 }}>
        <TextInput
          value={postalCode}
          onChange={setPostalCode}
          label={t("invoicePayments.modal.paymentDetails.inputs.mailedCheck.zipCode.label")}
          placeholder={t("invoicePayments.modal.paymentDetails.inputs.mailedCheck.zipCode.label")}
        />
        <TextInput
          value={addressLine2}
          onChange={setAddressLine2}
          label={t("invoicePayments.modal.paymentDetails.inputs.mailedCheck.addressLine2.label")}
          placeholder={t(
            "invoicePayments.modal.paymentDetails.inputs.mailedCheck.addressLine2.placeholder",
          )}
        />
      </FlexRowLayout>
      <FlexRowLayout sx={{ paddingTop: 22, columnGap: 16 }}>
        <TextInput
          value={city}
          onChange={setCity}
          label={t("invoicePayments.modal.paymentDetails.inputs.mailedCheck.addressCity.label")}
          placeholder={t(
            "invoicePayments.modal.paymentDetails.inputs.mailedCheck.addressCity.label",
          )}
        />
        <Dropdown
          options={addressStateOptions}
          selectedValue={addressState}
          onSelect={setAddressState}
          label={t("invoicePayments.modal.paymentDetails.inputs.mailedCheck.addressState.label")}
          placeholder={t(
            "invoicePayments.modal.paymentDetails.inputs.mailedCheck.addressState.placeholder",
          )}
        />
      </FlexRowLayout>
      <TextInput
        value={memo}
        onChange={setMemo}
        label={t("invoicePayments.modal.paymentDetails.inputs.mailedCheck.memo.label")}
        placeholder={t("invoicePayments.modal.paymentDetails.inputs.mailedCheck.memo.placeholder")}
        containerSx={{ marginTop: 22 }}
        maxLength={50}
      />
    </FlexColumnLayout>
  )
}
