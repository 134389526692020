import React, { useState } from "react"
import { Input as RInput } from "@rebass/forms"
import { Field, IFieldInputProps } from "@components/primitive/field/field"
import { theme } from "@layouts/theme"
import { Text } from "@components/primitive/text/text"
import { FlexRowLayout } from "@layouts/flex"

export interface ITextInputProps extends IFieldInputProps {
  placeholder: string
  value: string
  onBlur?: React.FocusEventHandler<HTMLInputElement> | undefined
  onChange: (value: string) => void
  onKeyPress?: React.KeyboardEventHandler<HTMLInputElement> | undefined
  maxLength?: number
  prefix?: string
  suffix?: string
  autofocus?: boolean
  icon?: React.ReactNode
}

/**
 * Extends the primitive Field component
 * @param sx Should be used sparingly. One use case might be to set a custom width.
 */
export const TextInput: React.FC<ITextInputProps> = ({
  label,
  placeholder,
  disabled,
  isHighlighted,
  errorMessage,
  sx,
  value,
  onBlur,
  onChange,
  onKeyPress,
  maxLength,
  containerSx,
  prefix,
  suffix,
  autofocus,
  icon,
}) => {
  const [isFocused, setIsFocused] = useState(false)
  const textColor = value ? theme.colors.ui1 : disabled ? theme.colors.ui4 : theme.colors.ui3
  return (
    <Field
      label={label}
      isFocused={isFocused}
      isHighlighted={isHighlighted}
      errorMessage={errorMessage}
      disabled={disabled}
      sx={sx}
      containerSx={containerSx}
    >
      <FlexRowLayout
        sx={{
          paddingX: 12,
          width: "100%",
          alignItems: "center",
          height: "100%",
          overflowX: "scroll",
        }}
      >
        <Text tag="body" textColor={textColor}>
          {prefix}
        </Text>
        <FlexRowLayout
          sx={{
            height: "100%",
            width: "100%",
            position: "relative",
            alignItems: "center",
          }}
        >
          {Boolean(icon) && icon}
          <RInput
            sx={{
              outline: "none",
              border: "none",
              paddingX: 0,
              width: "100%",
              "::placeholder": {
                color: theme.colors.ui3,
              },
              ...(suffix
                ? {
                    position: "absolute",
                    color: "transparent",
                    "::placeholder": {
                      color: "transparent",
                    },
                  }
                : {}),
              caretColor: theme.colors.ui1,
              ...theme.textStyles.body,
              ...sx,
            }}
            value={value}
            placeholder={placeholder}
            disabled={disabled}
            onFocus={() => {
              setIsFocused(true)
            }}
            onBlur={(event) => {
              setIsFocused(false)
              onBlur && onBlur(event)
            }}
            onChange={(e) => onChange(e.target.value)}
            onKeyPress={onKeyPress}
            maxLength={maxLength}
            autoFocus={autofocus}
          />
          {suffix && (
            <Text
              sx={{
                whiteSpace: "nowrap",
              }}
              tag="body"
              textColor={textColor}
            >
              {value || placeholder}
              {suffix}
            </Text>
          )}
        </FlexRowLayout>
      </FlexRowLayout>
    </Field>
  )
}
