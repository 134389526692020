import { BANCORP_NAME } from "@utils/constants"

export const dashboard = {
  emptyState: {
    title: "Welcome to NorthOne, {{firstName}}",
    headline: "Your deposit is processing. \n",
    subtitle: "If it isn't available in 2-3 business days, ",
    subtitleLink: "contact Customer Care.\n",
    listHeadline: "In the meantime, explore your account:",
    bullets: {
      point1:
        "Find your bank account numbers in “Menu” to connect to Stripe, Square, and other apps",
      point2: "Add funds by ACH or wire transfer by tapping on “Move Money”",
    },
    body: "You’ll be able to manage transactions and account activity here once funds arrive in your account.\n\nIn the meantime, explore your NorthOne Account.",
    upgradedUser:
      "As part of your account upgrade, we’ve had to reset your transaction feed and move your balance to your new NorthOne Account.",
  },
  sidebar: {
    balance: "Your balance",
    availableSpend: "Available to spend",
    pendingBalance: "Pending balance",
    pendingDeposits: "Pending deposits",
    balanceInfo:
      'Your "pending balance" is the total amount of funds in your account, including deposits that have not gone through yet.',
    moveMoney: "Send money",
    addFunds: "Add funds",
    applyForLending: "Apply for lending",
    schedule: "Scheduled payments",
    sendInvoice: "Send invoice",
    accounts: "Envelopes",
    accountDetails: "Routing & Account Numbers",
    show: "Show",
    hide: "Hide",
    routingNumber: "Routing #",
    accountNumber: "Account #",
    bankName: "Bank Name",
    bankAddress: "Bank Address",
    bankNameHelp: `Banking services provided by ${BANCORP_NAME}`,
  },
  transactionPanel: {
    amount: "Amount",
    method: "Payment Method",
    transferMethod: "Payment Method",
    date: "Date and time",
    initiatedBy: "Initiated by",
    pending: {
      label: "Pending",
      title: "Cancel until 3:45 pm EST",
      subtitle:
        "You can cancel ACH payments at no charge until 3:45 pm ET, after which the payment will be processed.",
    },
    notes: "Notes",
    placeholderNote: "Leave yourself a reminder",
    saveNote: "Save note",
    saving: "Saving",
    saved: "Saved",
    saveError: "Your note couldn't be saved. Try again.",
    share: "Share",
    support: "Support",
    cardAuth: {
      pending: "Pending",
      authorized: "Authorized",
      completed: "Completed",
      fundsAvailability: "These funds are no longer available in your balance.",
      processingTime: "Processing times depend on the merchant",
    },
    wireReview: {
      initiated: "Initiated",
      inReview: "In review",
      completed: "Completed",
      fundsAvailability: "These funds are no longer available in your balance.",
      beforeWireCutoff:
        "Wires sent before {{cutOffTime}} (Monday to Friday) will be received the same business day.",
    },
    checkDeposit: {
      approved: "Approved",
      available: "Available",
      pending: "Pending",
      fundsAvailability: "Pending transactions aren’t available in your balance yet.",
      timeEstimation: "Estimated to be available in {{timeEstimation}} business days",
    },
    mailedCheck: {
      canceled: {
        subtitle:
          "This check payment has been canceled. The amount will be returned to your account",
        button: " Talk to Customer Care",
      },
      refunded: {
        subtitle: "Your canceled check has been returned to your account. ",
      },
      expired: {
        subtitle:
          "Check was not deposited by the recipient within 180 days. The amount will be returned to your account.",
      },
      created: "On its way",
      deposited: "Deposited",
      processingTime:
        "These funds are no longer available in your balance. Checks arrive within 3-9 business days. ",
    },
  },
  cancelACHModal: {
    title: "Cancel payment?",
    subtitle:
      "You can cancel ACH payments at no charge until 3:45 pm ET, after which the payment will be processed.",
    cancelPayment: "Cancel payment",
  },
  welcome: "Welcome, {{firstName}}",
  welcomeLoading: "Welcome",
  searchPlaceholder: "Search transactions",
  accountUpgradeTransfer: {
    transaction: {
      title: "Balance Transfer",
      subtext: "Tap for details",
    },
    modal: {
      title: "We’ve moved your balance to your new upgraded account",
      body: "As part of our account upgrade process, we’ve moved your current balance to your new NorthOne Account.\n\nIf you had pending funds or were waiting on a check to clear, we’ll send those funds over as soon as possible.\n\nNeed help? Read the FAQ or contact our customer care team anytime.",
      primaryButton: "View FAQs",
      secondaryButton: "Got it",
    },
  },
  export: {
    export: "Export",
    whatLabel: "By date",
    last30days: "Last 30 days",
    allTransactions: "All time (all transactions)",
    typeLabel: "File type",
    csv: "Spreadsheet (CSV)",
    qboAmount: "QBO with amount (CSV)",
    qboDebit: "QBO with debit/credit (CSV)",
    button: "Download",
  },
  transactionsEnd: {
    title: "Didn't find what you were looking for?",
    searchOrFilter: "Search or filter",
    subtitle: " to find a specific transaction.",
  },
  noResults: {
    title: "No results found",
    searchSubtitle: "Check your spelling or try filtering to find the correct transactions.",
    filterSubtitle: "Try the search bar or a different filter to find the correct transactions.",
  },
  filters: {
    filters: "Filters",
    date: {
      label: "By date",
      all: "All time",
      today: "Today",
      yesterday: "Yesterday",
      thisWeek: "This week",
      last30Days: "Last 30 days",
      exactDate: "Exact date",
      customRange: "Custom range",
      startDate: "Start Date",
      endDate: "End Date",
      selectDate: "Select Date",
    },
    amount: {
      label: "By amount",
      all: "All amounts",
      minimum: "Above",
      maximum: "Below",
      exact: "Exactly",
      labels: {
        minimum: "Minimum amount",
        maximum: "Maximum amount",
        exact: "Exact amount",
      },
      placeholders: {
        minimum: "$ Minimum",
        maximum: "$ Maximum",
        exact: "$ Exact amount",
      },
    },
    transactionMethod: {
      label: "Payment method",
      all: "All methods",
      ach: "Payment - ACH transfer",
      wire: "Payment - wire transfer",
      billPay: "Payment - Bill Pay",
      subAccount: "Internal transfer",
      deposits: "Deposits - all",
      card: "Card transactions",
    },
    button: "Apply filters",
    clear: "Clear",
  },
  legal: `NorthOne is a financial technology company, and not a bank. Banking services are provided by ${BANCORP_NAME}. The NorthOne Mastercard® Small Business Debit Card is issued by ${BANCORP_NAME}, pursuant to license by Mastercard International Incorporated. Mastercard and the circles design are registered trademarks of Mastercard International Incorporated. Card may be used everywhere Mastercard is accepted`,
}
