import React from "react"
import { Box } from "rebass"
import { Text, theme } from "@northone/ui"
import { UnorderedBulletedList } from "@components/composite/unordered-bulleted-list/unordered-bulleted-list.component"

interface IShareTitleAndBodyProps {
  title: string
  bodyText?: string
  bodyPartialStyling?: React.CSSProperties
  bodyOnClick?: () => void
  bodyListItems?: string[]
}

export const ShareAndEarnTitleAndBody: React.FC<IShareTitleAndBodyProps> = ({
  title,
  bodyText,
  bodyPartialStyling,
  bodyOnClick,
  bodyListItems,
}) => (
  <Box>
    <Text tag="h2" sx={{ marginBottom: 58 }} width="90%">
      {title}
    </Text>
    {bodyText && (
      <Text
        tag="body-large"
        sx={{ fontSize: 16, lineHeight: "21px", marginBottom: 32 }}
        textColor={theme.colors.ui2}
        partialStyling={bodyPartialStyling}
        width="100%"
        onClick={bodyOnClick}
      >
        {bodyText}
      </Text>
    )}
    {bodyListItems && (
      <Box sx={{ marginBottom: 32 }}>
        <UnorderedBulletedList
          bulletPoints={bodyListItems}
          textTag={"body-large"}
          textColor={theme.colors.ui2}
        />
      </Box>
    )}
  </Box>
)
