import { createInstance, enums, OptimizelyProvider } from "@optimizely/react-sdk"
import React, { useEffect } from "react"
import { Analytics } from "@core/analytics/actions"
import * as Sentry from "@sentry/browser"
import { config, isProduction } from "@utils/environment"
import { useAppSelector } from "@core/redux/utils"
import { selectActiveBusinessID } from "@core/active-business/redux/selectors"

interface IOptimizelyDecisionInfo {
  type: "feature" | "ab-test" | "feature-test" | "feature-variable"
  decisionInfo: { [key: string]: string }
  userId: string
  attributes: { [key: string]: string }
}

const optimizely = createInstance({
  sdkKey: config.optimizelySDK,
  errorHandler: {
    handleError: (error) => Sentry.captureException({ error, service: "optimizely" }),
  },
  datafileOptions: {
    autoUpdate: true,
  },
  logger: isProduction
    ? {
        log: () => null,
      }
    : undefined,
})

function createOnDecision() {
  const eventsTracked = new Set()
  return (decisionObject: IOptimizelyDecisionInfo) => {
    const eventKey = JSON.stringify(decisionObject)
    if (!eventsTracked.has(eventKey)) {
      eventsTracked.add(eventKey)
      const eventBody = { ...decisionObject.decisionInfo, decisionType: decisionObject.type }
      switch (decisionObject.type) {
        case "feature":
          Analytics.track("Optimizely Feature Flag Decision Result", eventBody)
          break
        case "ab-test":
          Analytics.track("Optimizely A/B Test Experiment Decision Result", eventBody)
          break
        case "feature-test":
          Analytics.track("Optimizely Feature Test Expirement Decision Result", eventBody)
          break
        case "feature-variable":
          Analytics.track("Optimizely Feature Variable Decision Result", eventBody)
          break
      }
    }
  }
}

const onDecision = createOnDecision()

interface OptimizelyWrapperProps {
  children?: React.ReactNode
}

export const OptimizelyWrapper = ({ children }: OptimizelyWrapperProps) => {
  const { userId, me } = useAppSelector((state) => state.user)
  const businessId = useAppSelector(selectActiveBusinessID)

  useEffect(() => {
    optimizely.notificationCenter.addNotificationListener(
      enums.NOTIFICATION_TYPES.DECISION,
      onDecision,
    )

    return () => optimizely.notificationCenter.clearAllNotificationListeners()
  }, [])

  return (
    <OptimizelyProvider
      optimizely={optimizely}
      user={{
        id: userId,
        attributes: {
          businessId,
          email: me?.email,
        },
      }}
    >
      {children}
    </OptimizelyProvider>
  )
}
