import React from "react"
import { Image } from "rebass"
import { ListItem } from "@components/primitive/list-item/list-item"
import { Text } from "@components/primitive/text/text"
import { AlignedColumnLayout } from "../aligned-column-layout"
import { images } from "@assets/images/images"

interface INavigationListItemProps {
  text?: string | null
  onClick?: () => void
}

/**
 * Extends the ListItem primitive
 */
export const NavigationListItem: React.FC<INavigationListItemProps> = ({ text, onClick }) => {
  return (
    <ListItem onClick={onClick}>
      <AlignedColumnLayout alignment={"flex-start"} widthRadio={0.9}>
        <Text tag={"body-small"} marginLeft={16}>
          {text}
        </Text>
      </AlignedColumnLayout>
      <AlignedColumnLayout alignment={"flex-end"} widthRadio={0.1}>
        <Image src={images.icons.chevronRight} />
      </AlignedColumnLayout>
    </ListItem>
  )
}
