import React, { useState } from "react"
import { Image, SxStyleProp, Box } from "rebass"
import { Input as RInput } from "@rebass/forms"
import { images } from "@assets/images/images"
import { Field } from "@components/primitive/field/field"
import { theme } from "@layouts/theme"

export interface ISearchInputProps {
  placeholder: string
  backgroundColorOnBlur?: string
  sx?: SxStyleProp
  value: string | undefined
  onChange: (value: string) => void
  onBlur?: () => void
  onFocus?: () => void
  onClear?: () => void
  id?: string
}

/**
 * Extends the Field primitive
 * @param sx Should be used sparingly. One use case might be to set a custom width.
 */
export const SearchInput: React.FC<ISearchInputProps> = ({
  placeholder,
  backgroundColorOnBlur,
  sx,
  value,
  onChange,
  onBlur,
  onFocus,
  onClear,
  id,
}) => {
  const [isFocused, setIsFocused] = useState(false)

  return (
    <Field
      isFocused={isFocused}
      subTextContainerStyling={{ height: 0 }}
      sx={{
        backgroundColor:
          backgroundColorOnBlur && !isFocused && !value
            ? backgroundColorOnBlur
            : theme.colors.gold10,
        display: "flex",
        flexDirection: "row",
        ...sx,
      }}
    >
      <RInput
        id={id}
        sx={{
          outline: "none",
          border: "none",
          paddingLeft: 40,
          "::placeholder": {
            color: theme.colors.ui3,
          },
          backgroundImage: `url(${images.icons.search})`,
          backgroundPosition: "12px 12px",
          backgroundRepeat: "no-repeat",
        }}
        value={value}
        placeholder={placeholder}
        onFocus={() => {
          if (onFocus) {
            onFocus()
          }
          setIsFocused(true)
        }}
        onBlur={() => {
          if (onBlur) {
            onBlur()
          }
          setIsFocused(false)
        }}
        onChange={(e) => onChange(e.target.value)}
      />
      <Box
        sx={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          paddingRight: 12,
        }}
      >
        <Image
          src={images.icons.close}
          sx={{
            display: value ? "flex" : "none",
            cursor: "pointer",
          }}
          onClick={() => {
            if (onClear) {
              onClear()
            }
            onChange("")
          }}
        />
      </Box>
    </Field>
  )
}
