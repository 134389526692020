import React from "react"
import ReactDOM from "react-dom"
import { Box } from "rebass"
import { theme } from "@layouts/theme"
import { AnimatePresence } from "@components/animations/animate-presence/animation-presence"

export interface OverlayProps {
  children?: React.ReactNode
  isOpen: boolean
  onOutsideClick?: () => void
  zIndex?: number
}

export const Overlay = ({
  onOutsideClick,
  children,
  isOpen,
  zIndex = theme.zIndex.flowModal,
}: OverlayProps) => {
  const overlayRoot = document.getElementById("overlay-root") as HTMLDivElement

  return ReactDOM.createPortal(
    <Box
      onClick={onOutsideClick}
      sx={{ zIndex, position: "fixed", top: 0, left: 0, right: 0, overflow: "auto" }}
    >
      <Box sx={{ minWidth: [undefined, theme.breakpoints[1]] }}>
        <AnimatePresence style={{ width: "100%" }} animation="fadeIn">
          {isOpen && (
            <Box
              sx={{
                backgroundColor: "rgba(18, 18, 18, 0.6)",
                backdropFilter: "blur(8px)",
                WebkitBackdropFilter: "blur(8px)",
                width: "100%",
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {children}
            </Box>
          )}
        </AnimatePresence>
      </Box>
    </Box>,
    overlayRoot,
  )
}

const overlayRoot = document.getElementById("overlay-root")
const observer = new MutationObserver((mutationList) => {
  mutationList.forEach((mutation) => {
    if (mutation.type === "childList") {
      let overlayIsOpen = false
      const overlays = overlayRoot?.childNodes
      overlays?.forEach(({ childNodes }) => {
        if (childNodes.length) {
          overlayIsOpen = true
        }
      })
      if (overlayIsOpen) {
        document.body.style.overflow = "hidden"
      } else {
        document.body.style.overflow = "unset"
      }
    }
  })
})
if (overlayRoot) {
  observer.observe(overlayRoot, { childList: true, subtree: true })
}
