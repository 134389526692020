import { useState } from "react"

import { useAppSelector } from "@core/redux/utils"
import { selectActiveBusinessID } from "@core/active-business/redux/selectors"
import { PlaidItem, useRemovePlaidItemMutation } from "@generated/graphql"
import { Analytics } from "@core/analytics/actions"
import { events } from "@core/analytics/events"

type UseRemovePlaidItemProps = {
  plaidItemToDelete?: PlaidItem
  onCompleted: () => void
}
export function useRemovePlaidItem({
  plaidItemToDelete,
  onCompleted = () => null,
}: UseRemovePlaidItemProps) {
  const [errorMessageKey, setErrorMessageKey] = useState<string>()
  const [loading, setLoading] = useState<boolean>(false)

  const businessId = useAppSelector(selectActiveBusinessID)

  const [removePlaidItem] = useRemovePlaidItemMutation({
    onCompleted: () => {
      setLoading(false)
      onCompleted()
    },
    onError: () => {
      setLoading(false)
      setErrorMessageKey("settings.pages.linkedAccount.errors.delete")
      Analytics.track(events.linkedAccounts.deleteAccountError)
      onCompleted()
    },
  })

  function handleRemovePlaidItem() {
    if (!plaidItemToDelete || loading) return
    setLoading(true)
    removePlaidItem({
      variables: {
        businessId: businessId,
        itemId: plaidItemToDelete.id,
      },
    })
  }

  return {
    errorMessageKey,
    loading,
    removePlaidItem: handleRemovePlaidItem,
  }
}
