import React from "react"
import { useTranslation } from "react-i18next"
import { DateTime } from "luxon"
import { FlexColumnLayout, FlexRowLayout } from "@northone/ui"
import { theme } from "@layouts/theme"
import { CardSummaryFooter } from "./card-summary-footer"
import { CardStatusPill } from "../shared/card-status-pill"
import { CardDetails } from "./transformers"
import { Text } from "@components/primitive/text/text"

export const CardSummaryContent: React.FC<{
  card: CardDetails
}> = ({ card }) => {
  const { t } = useTranslation()

  const { isDisabled, isFrozen, isFullyActivated, shippedDate, last4 } = card
  const formattedShippedDate = shippedDate
    ? DateTime.fromISO(shippedDate).toFormat("MMM d")
    : undefined

  const title = t(card.isVirtualCard ? `cards.landing.virtualCard` : `cards.landing.physicalCard`, {
    last4,
  })

  return (
    <FlexColumnLayout sx={{ marginLeft: 24, justifyContent: "center", width: "640px" }}>
      <FlexRowLayout
        sx={{ justifyContent: "flex-start", marginBottom: "16px", alignItems: "center" }}
      >
        <Text
          sx={{ ...theme.textStyles.body, marginRight: "20px" }}
          tag="body-large"
          hasPartialBolding
        >
          {title}
        </Text>
        <CardStatusPill
          isDisabled={isDisabled}
          isFrozen={isFrozen}
          isFullyActivated={isFullyActivated}
        />
        {formattedShippedDate && !isFullyActivated && (
          <Text tag="body-small" sx={{ color: theme.colors.ui1, marginRight: 24 }}>
            {t("cards.inactiveCard.shippedOn", { shippedDate: formattedShippedDate })}
          </Text>
        )}
      </FlexRowLayout>
      <CardSummaryFooter card={card} />
    </FlexColumnLayout>
  )
}
