import React, { useEffect, useRef } from "react"
import { formatMoney, unformat } from "accounting"
import { useTranslation } from "react-i18next"
import { Link } from "rebass"
import { useAppSelector } from "@core/redux/utils"
import { selectScreenHistory } from "@features/move-money/redux/selectors"
import { DomesticWireScreensEnum } from "@features/move-money/router/domestic-wire"
import { theme } from "@layouts/theme"
import { FlexRowLayout } from "@layouts/flex"
import { getTransactionTypeDetails } from "@utils/mapping/transactions.mapping"
import { WIRE_TRANSFER_AGREEMENT_LINK } from "@utils/constants"
import {
  TransactionTypeEnum,
  useCapabilitiesAndAvailableBalanceQuery,
  useTransactionFeesLazyQuery,
} from "@generated/graphql"
import { images } from "@assets/images/images"
import { Text } from "@components/primitive/text/text"
import { WithHighlight } from "@components/primitive/highlight/highlight"
import {
  BaseTransactionPanelProps,
  BaseTransactionPanel,
  TransactionPanelItem,
} from "./base-transaction-panel"
import { FeePill } from "@components/extended/pill/fee-pill"
import { getCounterpartyLabel } from "./transaction-panel"
import { selectActiveBusinessID } from "@core/active-business/redux/selectors"
import { getFee } from "@features/move-money/utils/fee.utils"
import { ErrorPanel } from "@components/extended/panel/error-panel"
import { LoadingPanel } from "@components/extended/panel/loading-panel"
import { TransactionPanelSubscriptionUpsell } from "./transaction-panel-subscription-upsell"
import { useSubscriptionsData } from "@features/subscriptions/shared/hooks/use-subscriptions-data"

export interface IPreviewTransactionPanelProps extends BaseTransactionPanelProps {
  amount?: string
  transactionType?: TransactionTypeEnum
  editButtonTitle?: string
  editButtonOnClick?: () => void
  subAccountName?: string
}

export const PreviewTransactionPanel: React.FC<IPreviewTransactionPanelProps> = ({
  counterpartyName,
  counterpartyLogoSrc,
  amount,
  transactionType,
  editButtonTitle,
  editButtonOnClick,
  subAccountName,
  children,
}) => {
  const { t } = useTranslation()

  const wireSendFlowScreenHistory = useAppSelector(selectScreenHistory)
  const transactionTypeDetails = getTransactionTypeDetails(transactionType)
  const businessId = useAppSelector(selectActiveBusinessID)
  const isDomesticWireTransaction = transactionType === TransactionTypeEnum.DOMESTIC_WIRE
  const { subscription } = useSubscriptionsData()

  const {
    data: capabilitiesData,
    error: capabilitiesError,
    loading: capabilitiesLoading,
    refetch: capabilitiesRefetch,
  } = useCapabilitiesAndAvailableBalanceQuery({
    variables: { businessId },
    skip: !isDomesticWireTransaction,
    notifyOnNetworkStatusChange: true,
  })
  const fee = useRef<number | undefined>(
    getFee(capabilitiesData?.business?.bankAccounts[0].capabilities?.domesticWire.fee),
  )
  const [getTransactionFees, { error: transactionFeesError }] = useTransactionFeesLazyQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    onCompleted: (data) => (fee.current = data?.transactionFees?.domesticWire),
  })

  const getTransactionFee = (amount?: string) => {
    if (!isDomesticWireTransaction) return undefined
    if (!amount) {
      fee.current = getFee(
        capabilitiesData?.business?.bankAccounts[0].capabilities?.domesticWire.fee,
      )
      return
    }
    getTransactionFees({ variables: { businessId, amount: Number(amount) } })
    return
  }
  if (!amount) {
    getTransactionFee()
  }
  useEffect(() => {
    getTransactionFee(amount)
  }, [amount, subscription?.plan.name])

  const isTransfer = transactionType === TransactionTypeEnum.SUB_ACCOUNT_TRANSFER
  const amountAsNumber = unformat(amount || "")
  const counterpartyLabel = getCounterpartyLabel(-amountAsNumber, transactionType)

  const getTransactionTypeText = () => {
    if (!transactionType) {
      return t("transactions.previewPanel.unspecified")
    }
    return transactionTypeDetails.outgoingType
  }

  const getDisclaimerText = (transactionType: TransactionTypeEnum | undefined) => {
    switch (transactionType) {
      case TransactionTypeEnum.DOMESTIC_WIRE:
        return t(`transactions.disclaimer.${TransactionTypeEnum.DOMESTIC_WIRE}`)
      default:
        return ""
    }
  }

  const getNoticeLink = (transactionType: TransactionTypeEnum | undefined) => {
    switch (transactionType) {
      case TransactionTypeEnum.DOMESTIC_WIRE:
        return wireSendFlowScreenHistory[wireSendFlowScreenHistory.length - 1] ===
          DomesticWireScreensEnum.DOMESTIC_WIRE_CONFIRM ? (
          <Text tag={"body-x-small"} color={theme.colors.ui3} sx={{ marginTop: "10px" }}>
            {t("moveMoney.wireReview.terms") + " "}
            <Link
              target="_blank"
              href={WIRE_TRANSFER_AGREEMENT_LINK}
              sx={{ color: theme.colors.ui2, cursor: "pointer" }}
            >
              {t("moveMoney.wireReview.termsOfUse")}
            </Link>
          </Text>
        ) : (
          ""
        )
      default:
        return ""
    }
  }

  if (capabilitiesLoading) {
    return <LoadingPanel iconDimension={40} />
  }

  if (capabilitiesError || transactionFeesError || (!fee.current && isDomesticWireTransaction)) {
    return <ErrorPanel retry={capabilitiesRefetch} />
  }

  return (
    <BaseTransactionPanel
      title={
        isTransfer
          ? t("accounts.transfer.previewPanel.label")
          : t("transactions.previewPanel.title")
      }
      counterpartyName={counterpartyName || t("transactions.previewPanel.recipient")}
      counterpartyLogoSrc={
        isTransfer ? counterpartyLogoSrc || images.icons.transferAvatar : counterpartyLogoSrc
      }
      buttonText={editButtonTitle}
      buttonOnClick={editButtonOnClick}
      withoutCounterpartyName={isTransfer}
      counterpartyLabel={counterpartyLabel}
    >
      <TransactionPanelItem
        label={
          isTransfer
            ? t("accounts.transfer.previewPanel.amountLabel")
            : t("dashboard.transactionPanel.amount")
        }
      >
        <WithHighlight sx={{ marginTop: "5px" }} color={theme.colors.gold10}>
          <Text
            textColor={!amount ? theme.colors.ui2 : theme.colors.gold100}
            tag="body-large"
            sx={{ fontSize: "24px" }}
          >
            {formatMoney(amountAsNumber)}
          </Text>
        </WithHighlight>
      </TransactionPanelItem>
      {!isTransfer && (
        <TransactionPanelItem label={t("dashboard.transactionPanel.method")}>
          <FlexRowLayout sx={{ alignItems: "center" }}>
            <Text tag="body" sx={{ paddingRight: 12 }}>
              {getTransactionTypeText()}
            </Text>
            {transactionType && (
              <FeePill isFilled={Boolean(fee.current && isDomesticWireTransaction)}>
                {fee.current && isDomesticWireTransaction
                  ? `+ $${fee.current} ${t("transactions.fees.fee")}*`
                  : t("transactions.fees.free")}
              </FeePill>
            )}
          </FlexRowLayout>
        </TransactionPanelItem>
      )}
      <TransactionPanelItem label={t("transactions.previewPanel.fromAccount")}>
        <FlexRowLayout sx={{ alignItems: "center" }}>
          <Text tag="body" sx={{ paddingRight: 12 }}>
            {subAccountName || t("transactions.previewPanel.unspecified")}
          </Text>
        </FlexRowLayout>
      </TransactionPanelItem>
      <TransactionPanelSubscriptionUpsell />
      {isTransfer && (
        <>
          <TransactionPanelItem label={t("transactions.previewPanel.toAccount")}>
            <FlexRowLayout sx={{ alignItems: "center" }}>
              <Text tag="body" sx={{ paddingRight: 12 }}>
                {counterpartyName || t("transactions.previewPanel.unspecified")}
              </Text>
            </FlexRowLayout>
          </TransactionPanelItem>
        </>
      )}
      {children}
      <Text tag={"body-x-small"} color={theme.colors.ui3} sx={{ marginTop: "10px" }}>
        {getDisclaimerText(transactionType)}
      </Text>
      <Text tag={"body-x-small"} color={theme.colors.ui3} sx={{ marginTop: "10px" }}>
        {getNoticeLink(transactionType)}
      </Text>
    </BaseTransactionPanel>
  )
}
