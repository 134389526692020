import { useState } from "react"

import useScript from "react-script-hook"
import { useAsync } from "react-use"

import { events } from "@core/analytics/events"
import { Analytics } from "@core/analytics/actions"
import { useCreateKanmonConnectTokenMutation } from "@generated/graphql"
import { config } from "@utils/environment"
import * as Sentry from "@sentry/browser"

/**
 * @see https://kanmon.dev/docs/types-userevent#userstate-type
 */
export type KanmonWidgetUserState =
  | "START_FLOW"
  | "USER_INPUT_REQUIRED"
  | "OTHER_USER_INPUT_REQUIRED"
  | "WAITING_FOR_OFFERS"
  | "NO_OFFERS_EXTENDED"
  | "VIEW_OFFERS"
  | "OFFER_ACCEPTED"
  | "OFFER_EXPIRED"
  | "SERVICING"
  | "IN_MANUAL_REVIEW"

/**
 * @see https://kanmon.dev/docs/types-userevent#userstatechangedevent
 */
type KanmonUserStateChangedEvent = {
  eventType: "USER_STATE_CHANGED"
  data: {
    userState: KanmonWidgetUserState
    actionMessage: string | null
    actionRequired: boolean
    section: string | null
  }
}

/**
 * @see https://kanmon.dev/docs/types-userevent#hideevent
 */
type KanmonHideEvent = {
  eventType: "HIDE"
}

type KanmonWidgetEvent = KanmonUserStateChangedEvent | KanmonHideEvent

/**
 * @see https://kanmon.dev/docs/types-errorevent
 */
type KanmonWidgetErrorEvent = {
  message: string
  errorType: "UNEXPECTED_ERROR" | "INVALID_SESSION_TOKEN_EXCEPTION"
}

/**
 * @see https://kanmon.dev/docs/types-kanmon_connect#initializeparams
 */
type KanmonConnectInitializeParams = {
  connectToken: string
  onEvent: (event: KanmonWidgetEvent) => void
  onError: (error: KanmonWidgetErrorEvent) => void
}

/**
 * @see https://kanmon.dev/docs/types-kanmon_connect#kanmon_connect
 */
type KanmonConnect = {
  start: (params: KanmonConnectInitializeParams) => void
  show: () => void
}

declare global {
  interface Window {
    KANMON_CONNECT: KanmonConnect | undefined
  }
}

export const useKanmonConnectWidget = ({
  businessId,
  currentUserIsLoanManager,
}: {
  businessId: string
  currentUserIsLoanManager: boolean
}) => {
  const shouldLoadScript = currentUserIsLoanManager ? true : false

  const [createKanmonConnectToken, { loading: connectTokenLoading, error: connectTokenError }] =
    useCreateKanmonConnectTokenMutation()

  const [kanmonScriptLoading, kanmonScriptError] = useScript({
    src: shouldLoadScript ? config.kanmonConnectScriptUrl : null,
  })

  const [widgetInitializing, setWidgetInitializing] = useState<boolean>(true)
  const [initializeWidgetError, setInitializeWidgetError] = useState<unknown | undefined>()
  const [widgetUserState, setWidgetUserState] = useState<KanmonWidgetUserState | undefined>()

  useAsync(
    async function initializeKanmon() {
      if (!currentUserIsLoanManager) {
        setWidgetInitializing(false)
        return
      }

      if (kanmonScriptError) {
        setWidgetInitializing(false)
        Sentry.captureMessage(`[SCRIPT LOAD ERROR]: ${kanmonScriptError.message}`, {
          level: "error",
        })
        return
      }

      if (kanmonScriptLoading) {
        return
      }

      const { data: connectTokenData } = await createKanmonConnectToken({
        variables: { businessId },
      })

      const connectToken = connectTokenData?.createKanmonConnectToken?.connectToken

      if (!connectToken) {
        setWidgetInitializing(false)
        return
      }

      if (!window.KANMON_CONNECT) {
        setWidgetInitializing(false)

        const errorMessage = "Kanmon Connect does not exist on the window object"
        setInitializeWidgetError(new Error(errorMessage))
        Sentry.captureMessage(errorMessage, {
          level: "error",
        })
        return
      }

      window.KANMON_CONNECT.start({
        connectToken: connectToken,
        onEvent: (event) => {
          switch (event.eventType) {
            case "USER_STATE_CHANGED":
              // Widget state is always set on load and on state change
              setWidgetUserState(event.data.userState)
              break
          }
        },
        onError: (error) => {
          setInitializeWidgetError(error)

          const errorMessage = `${error.errorType}: ${error.message}`
          Analytics.track(events.lending.kanmonWidgetError, {
            errorMessage,
          })
          Sentry.captureMessage(`[WIDGET ERROR]: ${errorMessage}`, {
            level: "error",
          })
        },
      })

      setWidgetInitializing(false)
    },
    [currentUserIsLoanManager, kanmonScriptLoading, kanmonScriptError],
  )

  const launchKanmonConnectWidget = () => window.KANMON_CONNECT?.show()

  const widgetError = initializeWidgetError || connectTokenError || kanmonScriptError

  // Widget state is always set on load and is required to render correct copy
  // This only applies to loan managers, since the widget is only initialized for loan managers
  const widgetUserStateLoading = currentUserIsLoanManager && !widgetUserState && !widgetError

  const widgetLoading =
    widgetInitializing || kanmonScriptLoading || connectTokenLoading || widgetUserStateLoading

  return { widgetLoading, widgetError, widgetUserState, launchKanmonConnectWidget }
}
