import { Contact } from "@generated/graphql"
import { businessRelationshipOptions } from "@features/move-money/domestic-wire/utils/constants"
import { IGenericRecipient } from "@features/move-money/redux/state"

const isOtherRelationship = (relationship?: string | null) => {
  if (!relationship) {
    return false
  }

  const relationshipOptions = [
    businessRelationshipOptions.government,
    businessRelationshipOptions.otherBank,
    businessRelationshipOptions.payroll,
    businessRelationshipOptions.vendor,
  ]

  return !relationshipOptions.includes(relationship)
}

export const gqlWireRecipientsToRedux = (recipient: Contact): IGenericRecipient => {
  const postalCodeNoDash =
    recipient.address?.postalCode && recipient.address.postalCode.replace("-", "")

  return {
    id: recipient.id,
    name: recipient.name,
    relationship: isOtherRelationship(recipient.relationship)
      ? businessRelationshipOptions.other
      : recipient.relationship || "",
    address: {
      streetAddressLine1: recipient.address?.streetAddressLine1,
      streetAddressLine2: recipient.address?.streetAddressLine2,
      city: recipient.address?.city,
      postalCode: postalCodeNoDash,
      provinceState: recipient.address?.provinceState,
    },
    otherRelationship: isOtherRelationship(recipient.relationship)
      ? recipient.relationship || ""
      : "",
    bankName: recipient.wirePaymentDetails?.bankName,
    bankAccountNumber: recipient.wirePaymentDetails?.accountNumber,
    bankRoutingNumber: recipient.wirePaymentDetails?.routingNumber,
  }
}

export const gqlAchRecipientsToRedux = (recipient: Contact): IGenericRecipient => ({
  id: recipient.id,
  name: recipient.name,
  bankAccountNumber: recipient.achPaymentDetails?.accountNumber,
  bankRoutingNumber: recipient.achPaymentDetails?.routingNumber,
})
