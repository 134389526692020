import React from "react"
import { useLottie } from "lottie-react"

import animationData from "@assets/lottie/loading.json"

interface ILoadingIconProps {
  dimension?: number
  style?: React.CSSProperties
}

const options = {
  animationData,
  loop: true,
  autoplay: true,
}

/**
 * @deprecated This component is deprecated and we should use
 * the same component from `@northone/ui` instead.
 **/
export const LoadingIcon = ({ dimension = 24, style }: ILoadingIconProps) => {
  const viewStyle = {
    height: dimension,
    width: dimension,
    ...style,
  }

  const { View } = useLottie(options, viewStyle)
  return View
}
