import { MoveMoneyActionEnum, TMoveMoneyActions } from "./actions"
import { initialMoveMoneyState, IMoveMoneyState, initialRecipient } from "./state"

export const moveMoneyReducer = (
  state: IMoveMoneyState = initialMoveMoneyState,
  action: TMoveMoneyActions,
): IMoveMoneyState => {
  switch (action.type) {
    case MoveMoneyActionEnum.RESET_SCREEN_HISTORY:
      return {
        ...state,
        screenHistory: initialMoveMoneyState.screenHistory,
      }
    case MoveMoneyActionEnum.SET_RECIPIENT:
      return {
        ...state,
        recipient: { ...state.recipient, ...action.payload },
      }
    case MoveMoneyActionEnum.SET_EDITING_RECIPIENT:
      return {
        ...state,
        editingRecipient: { ...state.editingRecipient, ...action.payload },
      }
    case MoveMoneyActionEnum.SET_EDITING_RECIPIENT_ADDRESS:
      return {
        ...state,
        editingRecipient: {
          ...state.editingRecipient,
          address: {
            ...state.editingRecipient.address,
            ...action.payload,
          },
        },
      }
    case MoveMoneyActionEnum.SET_AMOUNT:
      return {
        ...state,
        amount: action.payload,
      }
    case MoveMoneyActionEnum.SET_SUBACCOUNT:
      return {
        ...state,
        subAccount: action.payload,
      }
    case MoveMoneyActionEnum.DELETE_DRAFT:
      return {
        ...initialMoveMoneyState,
      }
    case MoveMoneyActionEnum.NAVIGATE_NEXT:
      return {
        ...state,
        screenHistory: [...state.screenHistory, action.payload],
      }
    case MoveMoneyActionEnum.SET_PAYMENT_TYPE:
      return {
        ...state,
        paymentMethod: action.payload,
      }
    case MoveMoneyActionEnum.NAVIGATE_BACK:
      return {
        ...state,
        screenHistory: state.screenHistory.slice(0, -1),
      }
    case MoveMoneyActionEnum.SET_WIRE_PURPOSE:
      return {
        ...state,
        purpose: action.payload,
      }
    case MoveMoneyActionEnum.SET_NON_ACTIVATED_CARD_IDS:
      return {
        ...state,
        nonActivatedCardIds: action.payload,
      }
    case MoveMoneyActionEnum.CLEAR_RECIPIENT:
      return {
        ...state,
        recipient: initialRecipient,
      }
    case MoveMoneyActionEnum.CLEAR_EDITING_RECIPIENT:
      return {
        ...state,
        editingRecipient: initialRecipient,
      }
    case MoveMoneyActionEnum.SET_WIRE_SEND_COMPLETED:
      return {
        ...state,
        wireCompleted: action.payload,
      }
    case MoveMoneyActionEnum.SET_WIRE_ENABLED:
      return {
        ...state,
        wireEnabled: action.payload,
      }
    default:
      return state
  }
}
