import React from "react"
import { useRouteMatch, useHistory, useLocation } from "react-router-dom"
import ReactPlayer from "react-player/youtube"

import { Text } from "../../components/primitive/text/text"
import { DownloadModal } from "../download-modal/download-modal"
import { useTranslation } from "react-i18next"
import { Box } from "rebass"
import { events } from "../../core/analytics/events"
import { RoutesEnum, ILocationState } from "../../routers/types"
import { navigateFromModal } from "../../utils/navigation"

const INVOICE_YOUTUBE_LINK = "https://m.youtube.com/watch?v=lpyi2cblsx8"

export const SendInvoiceModal: React.FC = () => {
  const history = useHistory()
  const location = useLocation<ILocationState>()
  const { t } = useTranslation()

  const isRouteMatch = useRouteMatch({ path: RoutesEnum.SEND_INVOICE })

  return (
    <DownloadModal
      isOpen={Boolean(isRouteMatch?.isExact)}
      closeModal={() =>
        navigateFromModal({ defaultBackRoute: RoutesEnum.DASHBOARD, history, location })
      }
      linkDataFeature={"SendInvoice"}
      downloadClickEvent={events.sendInvoice.download}
    >
      <Box
        sx={{
          marginBottom: 24,
        }}
      >
        <Text tag={"h3Serif"} sx={{ marginBottom: 24 }}>
          {t("sendInvoice.title")}
        </Text>
        <ReactPlayer url={INVOICE_YOUTUBE_LINK} width={512} height={288} controls />
      </Box>
    </DownloadModal>
  )
}
