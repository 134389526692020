import { createSelector } from "reselect"
import { selectActiveBusinessID } from "@core/active-business/redux/selectors"
import { businessRelationshipOptions } from "@features/move-money/domestic-wire/utils/constants"
import { selectEditRecipient } from "@features/move-money/redux/selectors"
import { IGenericRecipient } from "@features/move-money/redux/state"
import { addDashToPostalCode } from "@features/move-money/domestic-wire/utils/helpers"
import {
  RecipientDomesticAchUpdateMutationVariables,
  RecipientDomesticAchCreateMutationVariables,
  RecipientDomesticWireUpdateMutationVariables,
  RecipientDomesticWireCreateMutationVariables,
} from "@generated/graphql"

const getCommonACHMutationVariables = (
  recipient: IGenericRecipient,
  businessId: string,
  idempotencyKey: string,
) => {
  return {
    data: {
      businessId,
      name: recipient.name || "",
      achPaymentDetails: {
        accountNumber: recipient.bankAccountNumber || "",
        routingNumber: recipient.bankRoutingNumber || "",
      },
      idempotencyKey,
    },
  }
}

export const selectACHUpdateRecipientVariables = (idempotencyKey: string) =>
  createSelector(
    [selectEditRecipient, selectActiveBusinessID],
    (
      recipient: IGenericRecipient,
      businessId: string,
    ): RecipientDomesticAchUpdateMutationVariables => {
      return {
        data: {
          ...getCommonACHMutationVariables(recipient, businessId, idempotencyKey).data,
          ...{ contactId: recipient.id || "" },
        },
      }
    },
  )

export const selectACHCreateRecipientVariables = (idempotencyKey: string) =>
  createSelector(
    [selectEditRecipient, selectActiveBusinessID],
    (
      recipient: IGenericRecipient,
      businessId: string,
    ): RecipientDomesticAchCreateMutationVariables => {
      return getCommonACHMutationVariables(recipient, businessId, idempotencyKey)
    },
  )

const getCommonWireMutationVariables = (
  recipient: IGenericRecipient,
  businessId: string,
  idempotencyKey: string,
) => {
  return {
    data: {
      businessId,
      name: recipient.name || "",
      relationship:
        recipient.relationship === businessRelationshipOptions.other
          ? String(recipient.otherRelationship)
          : String(recipient.relationship) || "",
      address: {
        streetAddressLine1: recipient.address?.streetAddressLine1 || "",
        streetAddressLine2: recipient.address?.streetAddressLine2 || "",
        city: recipient.address?.city || "",
        postalCode:
          (recipient.address?.postalCode && addDashToPostalCode(recipient.address.postalCode)) ||
          "",
        provinceState: recipient.address?.provinceState || "",
      },
      wirePaymentDetails: {
        bankName: recipient.bankName || "",
        accountNumber: recipient.bankAccountNumber || "",
        routingNumber: recipient.bankRoutingNumber || "",
      },
      idempotencyKey,
    },
  }
}

export const selectDomesticWireUpdateRecipientVariables = (idempotencyKey: string) =>
  createSelector(
    [selectEditRecipient, selectActiveBusinessID],
    (
      recipient: IGenericRecipient,
      businessId: string,
    ): RecipientDomesticWireUpdateMutationVariables => {
      return {
        data: {
          ...getCommonWireMutationVariables(recipient, businessId, idempotencyKey).data,
          ...{ contactId: recipient.id || "" },
        },
      }
    },
  )

export const selectDomesticWireCreateRecipientVariables = (idempotencyKey: string) =>
  createSelector(
    [selectEditRecipient, selectActiveBusinessID],
    (
      recipient: IGenericRecipient,
      businessId: string,
    ): RecipientDomesticWireCreateMutationVariables => {
      return getCommonWireMutationVariables(recipient, businessId, idempotencyKey)
    },
  )
