import React from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { Box } from "rebass"
import { RoutesEnum } from "@routers/types"
import { Text } from "@components/primitive/text/text"
import { NavigationListItem } from "@components/extended/list-items/navigation/navigation-list-item"
import { BaseLayout } from "@layouts/base-layout"

interface ILegal {
  title: string
  route: string
}

const legalData: ILegal[] = [
  {
    title: "Issuer",
    route: `${RoutesEnum.SETTINGS_LEGAL}/issuer`,
  },
  {
    title: "NorthOne Small Business Deposit Account Agreement",
    route: `${RoutesEnum.SETTINGS_LEGAL}/account_agreement`,
  },
  {
    title: "Agreement to Receive Electronic Communications",
    route: `${RoutesEnum.SETTINGS_LEGAL}/electronic_comms_agreement`,
  },
  {
    title: "Wire Transfer Agreement",
    route: `${RoutesEnum.SETTINGS_LEGAL}/wire_transfer_agreement`,
  },
]

export const LegalLanding: React.FC = () => {
  const history = useHistory()
  const { t } = useTranslation()

  return (
    <BaseLayout pageTitle={t("pageTitles.settings.legal")}>
      <Text tag={"h2"} marginBottom={36}>
        {t("settings.pages.legal.title")}
      </Text>

      <Box sx={{}}>
        {legalData?.map((section, id) => (
          <NavigationListItem
            key={id}
            text={section.title}
            onClick={() => {
              history.push(section.route)
            }}
          />
        ))}
      </Box>
    </BaseLayout>
  )
}
