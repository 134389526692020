export const shareAndEarn = {
  landing: {
    title: "Invite another business. \nYou’ll both earn {{incentiveAmount}} on us.",
    body: "Tell your network about NorthOne and for every business that opens an account and makes a qualifying deposit, you'll both earn a {{incentiveAmount}} credit. **View Terms**",
  },
  share: {
    title: "Share a link",
    subtitle: "Send this link via email, social, or text message.",
  },
  noAdvocateId: {
    title: "Activate your NorthOne debit card to earn rewards.",
    content: [
      "Get access to your own personal invite link that can be shared with your network.",
      "Email your invite link to as many contacts as you like. Earn {{incentiveAmount}} credit for every person that opens an account with a qualifying deposit.",
    ],
    activateDebitCard: "Activate debit card",
  },
  blocked: {
    title: "Check your email to earn more rewards",
    body: "This feature has been locked. To continue earning rewards, reply to the email sent to **{{email}}** with the information requested.",
    contact: "Contact Customer Care",
    resend: {
      button: "Resend email",
      success: "Email was sent",
      error: "Something went wrong: Email was not sent",
    },
  },
}
