import React from "react"
import { useTranslation } from "react-i18next"
import { FlexColumnLayout, FlexRowLayout } from "@layouts/flex"
import { theme } from "@layouts/theme"
import { Text } from "@components/primitive/text/text"
import { splitTime } from "./utils/split-time.util"

export const TimeSavedCounter = ({ secondsSaved }: { secondsSaved: number }) => {
  const { t } = useTranslation()

  const { days, hours, minutes } = splitTime(secondsSaved)

  const timeUnits = [
    { label: t("moveMoney.sent.timeSavedBanner.days"), value: days },
    { label: t("moveMoney.sent.timeSavedBanner.hours"), value: hours },
    { label: t("moveMoney.sent.timeSavedBanner.minutes"), value: minutes },
  ]

  return (
    <FlexRowLayout sx={{ display: "flex", gap: 4 }}>
      {timeUnits.map(({ label, value }, idx) => (
        <FlexColumnLayout key={idx} sx={{ justifyContent: "center" }}>
          <Text tag="h3Serif" textColor={theme.colors.ui1}>
            {value}
          </Text>
          <Text tag="h5" textColor={theme.colors.copperDark}>
            {label}
          </Text>
        </FlexColumnLayout>
      ))}
    </FlexRowLayout>
  )
}
