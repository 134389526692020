import React from "react"

import LoadingComponent from "@components/extended/loading/loading"
import { Panel } from "@components/primitive/panel/panel"
import { panelStyles } from "./styles"
import { TopUpSettingsLayout } from "./top-up-settings-layout"

const layoutPanelStyles = { ...panelStyles, alignItems: "center", justifyItems: "center" }

export const TopUpsLandingLoading = () => {
  return (
    <TopUpSettingsLayout>
      <Panel sx={layoutPanelStyles}>
        <LoadingComponent />
      </Panel>
    </TopUpSettingsLayout>
  )
}
