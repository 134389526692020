import React, { useState } from "react"
import { useDatepicker, START_DATE, FocusedInput } from "@datepicker-react/hooks"
import { Month } from "./datepicker-month"
import DatepickerContext from "./datepicker-context"

interface IDatepickerProps {
  onSelect: (date: Date) => void
  selectedDate: Date | null
  minDate?: Date | null
  maxDate?: Date | null
  unavailableDates?: Date[]
  onBlur?: () => void
}

//https://medium.com/front-end-weekly/create-a-custom-react-date-picker-in-10-minutes-82fa19775f66
export const Datepicker: React.FC<IDatepickerProps> = ({
  onSelect,
  onBlur,
  selectedDate,
  minDate = new Date(new Date().setHours(0, 0, 0, 0)),
  maxDate,
  unavailableDates,
}) => {
  const today = new Date(new Date().setHours(0, 0, 0, 0))

  const [datepickerState, setDatepickerState] = useState({
    startDate: selectedDate ? selectedDate : today,
    endDate: null,
    focusedInput: START_DATE,
  })

  const handleDateChange = (data: any) => {
    setDatepickerState({ ...data, endDate: null, focusedInput: START_DATE })
    onSelect(data.startDate)
  }

  const {
    activeMonths,
    isDateSelected,
    isDateHovered,
    isFirstOrLastSelectedDate,
    isDateBlocked,
    isDateFocused,
    focusedDate,
    onDateHover,
    onDateSelect,
    onDateFocus,
    goToPreviousMonths,
    goToNextMonths,
  } = useDatepicker({
    startDate: datepickerState.startDate,
    endDate: null,
    focusedInput: datepickerState.focusedInput as FocusedInput,
    onDatesChange: (data) => handleDateChange(data),
    numberOfMonths: 1,
    // Disable picking dates before today
    minBookingDate: minDate || undefined,
    maxBookingDate: maxDate || undefined,
    unavailableDates: unavailableDates || undefined,
  })

  return (
    <DatepickerContext.Provider
      value={{
        focusedDate,
        isDateFocused,
        isDateSelected,
        isDateHovered,
        isDateBlocked,
        isFirstOrLastSelectedDate,
        onDateSelect,
        onDateFocus,
        onDateHover,
      }}
    >
      {activeMonths.map((month) => (
        <Month
          key={`${month.year}-${month.month}`}
          year={month.year}
          month={month.month}
          firstDayOfWeek={0}
          selectedDate={selectedDate}
          onBackClick={goToPreviousMonths}
          onForwardClick={goToNextMonths}
          today={today}
          onBlur={onBlur}
          minDate={minDate}
          maxDate={maxDate}
        />
      ))}
    </DatepickerContext.Provider>
  )
}
