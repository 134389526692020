import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { unformat } from "accounting"
import { useAppSelector } from "@core/redux/utils"
import { selectActiveBusinessID } from "@core/active-business/redux/selectors"
import { Analytics } from "@core/analytics/actions"
import { events } from "@core/analytics/events"
import { FormBody } from "@components/composite/form-body/form-body"
import { ButtonTypeEnum } from "@components/primitive/button/button"
import { ReconcileListItem } from "@components/extended/list-items/reconcile/reconcile-list-item"
import { getSubAccountsTotalBalance, roundToNearestHundredth } from "@features/reconcile/utils"
import { SUB_ACCOUNT_BALANCES } from "@features/accounts/create-sub-account/initial-transfer/operations.gql"
import { SUB_ACCOUNTS_QUERY } from "@features/accounts/sub-accounts/operations.gql"
import { pollWithMaxRetries, refreshTransactionLists } from "@utils/poll-with-max-retries"
import { useSubAccountBalancesQuery, useSubAccountsReconcileMutation } from "@generated/graphql"

interface IReconcileReallocateFormProps {
  onSkip: () => void
  onComplete: () => void
}

interface IReconcileSubAccount {
  id: string
  previousBalance: number
  balance: string
  name: string
  isDefault: boolean
  editingBalance: string
}

export const ReconcileReallocateForm: React.FC<IReconcileReallocateFormProps> = ({
  onSkip,
  onComplete,
}) => {
  const { t } = useTranslation()
  const businessId = useAppSelector(selectActiveBusinessID)
  const [subAccounts, setSubAccounts] = useState<IReconcileSubAccount[]>([])
  const [totalBalance, setTotalBalance] = useState(0)
  const [hasError, setHasError] = useState(false)

  useSubAccountBalancesQuery({
    variables: { businessId },
    onCompleted: (data) => {
      const subAccounts = data.business?.bankAccount?.subAccounts
      if (subAccounts) {
        setTotalBalance(getSubAccountsTotalBalance(subAccounts))
        setSubAccounts(
          subAccounts.map(({ id, isDefault, balance, name }) => ({
            id,
            name,
            balance: "",
            editingBalance: "",
            previousBalance: balance,
            isDefault: Boolean(isDefault),
            hasInsufficientFunds: false,
          })),
        )
      }
    },
  })

  const checkingAccountBalance = roundToNearestHundredth(
    totalBalance -
      getSubAccountsTotalBalance(
        subAccounts.map((subAccount) => ({ ...subAccount, balance: unformat(subAccount.balance) })),
      ),
  )

  const [reconcile, { loading }] = useSubAccountsReconcileMutation({
    variables: {
      businessId,
      data: subAccounts.map(({ balance, id, isDefault }) => ({
        amount: isDefault ? checkingAccountBalance : unformat(balance),
        subAccountId: id,
      })),
    },
    onError: () => setHasError(true),
    onCompleted: () => {
      refreshTransactionLists({ businessId })
      pollWithMaxRetries([
        { query: SUB_ACCOUNTS_QUERY, variables: { businessId } },
        { query: SUB_ACCOUNT_BALANCES, variables: { businessId } },
      ])
      onComplete()
    },
  })

  return (
    <FormBody
      errorMessage={hasError ? t("errors.generic") : ""}
      title={t("accounts.reconcile.reallocate.title")}
      subtitle={t("accounts.reconcile.reallocate.subtitle")}
      buttons={[
        {
          children: loading
            ? t("accounts.reconcile.reallocate.loadingButton")
            : t("accounts.reconcile.reallocate.button"),
          type: ButtonTypeEnum.PRIMARY_BLUE,
          disabled:
            checkingAccountBalance === totalBalance ||
            Boolean(
              subAccounts.find((subAccount) => subAccount.editingBalance !== subAccount.balance),
            ),
          isLoading: loading,
          onClick: () => {
            setHasError(false)
            reconcile()
            Analytics.track(events.accounts.reconcile.reallocate)
          },
        },
        {
          children: t("buttons.skip"),
          type: ButtonTypeEnum.SECONDARY,
          onClick: onSkip,
        },
      ]}
    >
      {subAccounts
        .sort((a, b) => (b.isDefault ? 1 : a.name > b.name ? 1 : -1))
        .map((subAccount) => {
          const { isDefault, balance, name, previousBalance, id, editingBalance } = subAccount
          const hasInsufficientFunds = editingBalance !== balance
          return (
            <ReconcileListItem
              key={id}
              setNewBalance={(newBalance) => {
                const newCheckingBalance =
                  totalBalance -
                  subAccounts.reduce(
                    (acc, curr) =>
                      curr.id === id ? acc + unformat(newBalance) : acc + unformat(curr.balance),
                    0,
                  )
                setSubAccounts([
                  ...subAccounts.filter((value) => value.id !== id),
                  {
                    ...subAccount,
                    ...(newCheckingBalance >= 0
                      ? { balance: newBalance, editingBalance: newBalance }
                      : { editingBalance: newBalance }),
                  },
                ])
              }}
              hasInsufficientFunds={hasInsufficientFunds}
              newBalance={isDefault ? checkingAccountBalance.toString() : editingBalance}
              isDefault={Boolean(isDefault)}
              previousBalance={previousBalance}
              name={name}
            />
          )
        })}
    </FormBody>
  )
}
