import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { ButtonTypeEnum, InfoBlockModal } from "@northone/ui"
import { Text } from "@components/primitive/text/text"
import { selectActiveBusinessID } from "@core/active-business/redux/selectors"
import { useAppSelector } from "@core/redux/utils"
import { useTimeSavedQuery } from "@generated/graphql"
import { theme } from "@layouts/theme"
import LoadingComponent from "@features/cards/shared/loading-spinner"

export const CalculationMethodologyModal = ({
  isModalOpen,
  closeModal,
}: {
  isModalOpen: boolean
  closeModal: () => void
}) => {
  const { t } = useTranslation()
  const { data, loading, error, refetch } = useTimeSavedQuery({
    variables: { businessId: useAppSelector(selectActiveBusinessID) },
    fetchPolicy: "cache-first",
  })

  const pdfUrl = data?.timeSaved?.pdfGuidelineUrl

  useEffect(() => {
    if (loading) return
    if (error || !pdfUrl) refetch()
  }, [loading])

  return (
    <InfoBlockModal
      modalSx={{ width: "470px" }}
      isOpen={isModalOpen}
      closeModal={closeModal}
      title={t("moveMoney.sent.timeSavedBanner.calculationMethodologyModal.title")}
      subtitle={
        loading || error || !pdfUrl ? (
          <LoadingComponent />
        ) : (
          <>
            <Text tag="body-large" sx={{ color: theme.colors.ui2 }}>
              {t("moveMoney.sent.timeSavedBanner.calculationMethodologyModal.description")}
            </Text>
            <Text
              tag="body-x-small"
              sx={{ color: theme.colors.ui2, padding: "15px 0" }}
              textWithEmbeddedLinksProps={{
                text: t("moveMoney.sent.timeSavedBanner.calculationMethodologyModal.forDetails"),
                linksInOrder: [pdfUrl],
                linkStyle: { color: theme.colors.ui2 },
              }}
            />
          </>
        )
      }
      buttons={[
        { onClick: closeModal, type: ButtonTypeEnum.PRIMARY_BLUE, children: t("buttons.done") },
      ]}
    />
  )
}
