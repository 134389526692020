import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { isEqual } from "lodash-es"
import { useAppSelector } from "@core/redux/utils"
import { Analytics } from "@core/analytics/actions"
import { events } from "@core/analytics/events"
import { Panel } from "@components/primitive/panel/panel"
import { Text } from "@components/primitive/text/text"
import { Button, ButtonTypeEnum } from "@components/primitive/button/button"
import { dashboardActions } from "@features/dashboard/redux/actions"
import {
  selectDashboardDateFilterType,
  selectDashboardFilters,
  selectDashboardTransactionTypes,
  selectDashboardSearchFilterVariables,
} from "@features/dashboard/redux/selectors"
import { theme } from "@layouts/theme"
import { FlexRowLayout } from "@layouts/flex"
import { FilterAmountFields } from "./amount-fields"
import { TransactionTypesFields } from "./transaction-types-fields"
import { FilterDateFields } from "./date-fields"

export const FilterPanel: React.FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const filters = useAppSelector(selectDashboardFilters)
  const editingFilters = useAppSelector(selectDashboardSearchFilterVariables)
  const transactionTypes = useAppSelector(selectDashboardTransactionTypes)
  const dateFilterType = useAppSelector(selectDashboardDateFilterType)

  return (
    <Panel
      sx={{
        paddingX: theme.spacing.transactionPanel.x,
        paddingY: theme.spacing.transactionPanel.y,
      }}
    >
      <Text sx={{ marginBottom: "2px" }} tag="h3Serif">
        {t("dashboard.filters.filters")}
      </Text>
      <FilterDateFields />
      <FilterAmountFields />
      <TransactionTypesFields />
      <FlexRowLayout sx={{ marginTop: 24 }}>
        <Button
          onClick={() => {
            Analytics.track(events.searchAndFilter.dashboardFilter, {
              dateType: dateFilterType,
              transactionMethod: transactionTypes,
            })
            dispatch(dashboardActions.setFilters(editingFilters))
          }}
          disabled={isEqual(filters, editingFilters)}
          type={ButtonTypeEnum.PRIMARY_BLUE}
        >
          {t("dashboard.filters.button")}
        </Button>
        <Button
          onClick={() => dispatch(dashboardActions.clearState())}
          sx={{ marginLeft: 12 }}
          type={ButtonTypeEnum.TERTIARY}
        >
          {t("dashboard.filters.clear")}
        </Button>
      </FlexRowLayout>
    </Panel>
  )
}

interface FilterDropdownLabelProps {
  children?: React.ReactNode
}

export const FilterDropdownLabel = ({ children }: FilterDropdownLabelProps) => (
  <Text textColor={theme.colors.ui2} sx={{ marginBottom: "8px", marginTop: "12px" }} tag="h5">
    {children}
  </Text>
)
