import React from "react"
import { FlexRowLayout, Text } from "@northone/ui"
import { useTranslation } from "react-i18next"
import { theme } from "../../layouts/theme"
import { Link, SxStyleProp } from "rebass"
import ReCAPTCHA from "react-google-recaptcha"

interface ReCAPTCHAComponentProps {
  reCAPTCHARef: React.RefObject<ReCAPTCHA>
  siteKey: string
  size?: "invisible" | "compact" | "normal"
  sx?: SxStyleProp
}

const ReCAPTCHADisclaimer = () => {
  const { t } = useTranslation()
  const privacyPolicyLink = "https://policies.google.com/privacy"
  const termsOfServiceLink = "https://policies.google.com/terms"
  return (
    <Text tag={"body-x-small"} textColor={theme.colors.ui2}>
      <span>{t("reCAPTCHA.disclaimer.text")} </span>
      <span>
        <LinkText link={privacyPolicyLink}>{t("reCAPTCHA.disclaimer.privacyPolicy")}</LinkText>
      </span>
      <span>{t("reCAPTCHA.disclaimer.and")} </span>
      <span>
        <LinkText link={termsOfServiceLink}>{t("reCAPTCHA.disclaimer.termsOfService")}</LinkText>
      </span>
      <span>{t("reCAPTCHA.disclaimer.apply")} </span>
    </Text>
  )
}

interface LinkTextProps {
  children?: React.ReactNode
  link: string
}

const LinkText = ({ children, link }: LinkTextProps) => (
  <Link
    href={link}
    target="_blank"
    sx={{ cursor: "pointer", textDecoration: "underline", color: "inherit" }}
  >
    {children}
  </Link>
)

export const ReCAPTCHAComponent = ({
  reCAPTCHARef,
  siteKey,
  size = "invisible",
  sx = {},
}: ReCAPTCHAComponentProps) => (
  <FlexRowLayout sx={{ ...sx }}>
    <ReCAPTCHA ref={reCAPTCHARef} size={size} sitekey={siteKey} />
    <ReCAPTCHADisclaimer />
  </FlexRowLayout>
)
