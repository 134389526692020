import React from "react"
import { Box, SxStyleProp } from "rebass"
import { theme } from "@layouts/theme"
import { TPerk } from "../types"
import { LeftSide } from "./perks-modal-left"
import { RTNode } from "@prismicio/types"
import { RightSide } from "./perks-modal-right"
import { TTextTag } from "@components/primitive/text/text"
import { Text } from "@components/primitive/text/text"
import { PrismicText } from "@prismicio/react"
import { ModalWithGrid } from "@components/extended/overlay/modal-with-grid"
import { events } from "@core/analytics/events"
import { Analytics } from "@core/analytics/actions"
import * as prismicH from "@prismicio/helpers"

export interface IPerkDetailsProp {
  perkDetails?: [] | [RTNode, ...RTNode[]]
  perkLogoUrl?: string | undefined | null
  perkSupportUrl?: string | undefined | null
  perkPartnerName?: [] | [RTNode, ...RTNode[]]
  onCloseClick?: () => void
}
export interface IPerkTextInputProp {
  tag: TTextTag
  sx?: SxStyleProp | undefined
  text?: [] | [RTNode, ...RTNode[]] | string
}
interface IPerksModalProps {
  isOpened: boolean
  onCloseClick: () => void
  perk: TPerk
}

export const PerksPartnerText = ({ text, sx, tag }: IPerkTextInputProp) => {
  const content = typeof text !== "string" ? <PrismicText field={text} /> : text
  return text ? (
    <Text tag={tag} sx={{ ...sx }}>
      {content}
    </Text>
  ) : null
}

export const pushAnalyticsOnModalClose = (perk: TPerk) => {
  const {
    node: { partner_name, partner_category },
  } = perk
  Analytics.track(events.perks.partnerModalClosed, {
    partner_name: prismicH.asText(partner_name),
    partner_category,
  })
}

const MODAL_MIN_HEIGHT = 336
export const PerksModal = ({ perk, isOpened, onCloseClick }: IPerksModalProps) => {
  return (
    <ModalWithGrid
      sx={{ width: 933, minHeight: MODAL_MIN_HEIGHT, borderRadius: 0, padding: 0, margin: 0 }}
      isOpen={isOpened}
      onOutsideClick={() => pushAnalyticsOnModalClose(perk)}
    >
      <Box
        sx={{
          minHeight: MODAL_MIN_HEIGHT,
          borderRight: 1,
          background: theme.colors.blue10,
        }}
      >
        <LeftSide perk={perk} />
      </Box>
      <Box sx={{ minHeight: MODAL_MIN_HEIGHT, position: "relative" }}>
        <RightSide perk={perk} onCloseClick={onCloseClick} />
      </Box>
    </ModalWithGrid>
  )
}
