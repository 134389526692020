export const pageTitles = {
  dashboardName: "{{businessName}} - NorthOne Banking",
  dashboard: "NorthOne Banking",
  moveMoney: {
    send: "Send Money - NorthOne Banking",
    sendFlow: "{{paymentMethod}} - Send Money - NorthOne Banking",
    add: "Add Funds - NorthOne Banking",
    addFlow: "{{fundingMethod}} - Add Funds - NorthOne Banking",
  },
  scheduled: "Scheduled Payments - NorthOne Banking",
  cards: "Cards - NorthOne Banking",
  integrations: "Integrations - NorthOne Banking",
  settings: {
    contactInfo: "Settings - NorthOne Banking",
    password: "Password - Settings - NorthOne Banking",
    recipients: "Your Recipients - Settings - NorthOne Banking",
    recipientType_ach: "ACH - Your Recipients - Settings - NorthOne Banking",
    recipientType_wire: "Wire - Your Recipients - Settings - NorthOne Banking",
    recipientType_billPayAch: "Bill Pay - Your Recipients - Settings - NorthOne Banking",
    recipientType_billPayMailedCheck: "Bill Pay - Your Recipients - Settings - NorthOne Banking",
    external: "External Accounts - Settings - NorthOne Banking",
    externalAccount: "{{accountName}} - External Accounts - Settings - NorthOne Banking",
    logo: "Logo - Settings - NorthOne Banking",
    team: "Team - Settings - NorthOne Banking",
    teamBanking: "My Team - NorthOne Banking",
    legal: "Legal - Settings - NorthOne Banking",
    legalType_issuer: "Issuer - Legal - Settings - NorthOne Banking",
    legalType_account_agreement: "Account Agreement - Legal - Settings - NorthOne Banking",
    legalType_electronic_comms_agreement:
      "Electronic Communications Agreement - Legal - Settings - NorthOne Banking",
    legalType_wire_transfer_agreement:
      "Wire Transfer Agreement - Legal - Settings - NorthOne Banking",
    statements: "Account Statements - Settings - NorthOne Banking",
    billing: "Monthly Billing - Settings - NorthOne Banking",
    autoTopUp: "Auto Top-up - Settings - NorthOne Banking",
  },
  support: {
    overview: "Support - NorthOne Banking",
    scheduleCall: "Schedule a Call - Support - NorthOne Banking",
    faqs: "FAQs - Support - NorthOne Banking",
    faqCategory: "{{categoryName}} - FAQs - Support - NorthOne Banking",
    faqQuestion: "{{question}} - FAQs - Support - NorthOne Banking",
  },
  shareAndEarn: "Earn Rewards - NorthOne Banking",
  perks: "Perks - NorthOne Banking",
  invoicePayments: "Bill Payments - Northone Banking",
  revenue: "Revenue - NorthOne Banking",
  subscriptions: "Subscriptions - NorthOne Banking",
  lending: "Lending - NorthOne Banking",
}
