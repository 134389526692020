import { Analytics } from "@core/analytics/actions"
import { events } from "@core/analytics/events"
import {
  PlaidLinkError,
  PlaidLinkOnEventMetadata,
  PlaidLinkOnExitMetadata,
  PlaidLinkStableEvent,
} from "react-plaid-link"

/**
 * Publishes analytics events from corresponding Plaid Link onEvent callback
 */
export const handlePlaidLinkAnalytics = ({
  eventName,
  metadata,
}: {
  eventName: PlaidLinkStableEvent | string
  metadata: PlaidLinkOnEventMetadata
}) => {
  const institutionName = metadata.institution_name
  console.log("PLAID EVENT", { eventName, metadata })
  switch (eventName) {
    case "OPEN":
      Analytics.track(events.linkedAccounts.plaidOpenPlaid)
      break
    case "SELECT_INSTITUTION":
      Analytics.track(events.linkedAccounts.plaidSelectInstitution, { institutionName })
      break
    case "SUBMIT_CREDENTIALS":
      Analytics.track(events.linkedAccounts.plaidSubmitCredentials, { institutionName })
      break
    case "OPEN_OAUTH":
      Analytics.track(events.linkedAccounts.plaidOpenOAuth, { institutionName })
      break
    case "TRANSITION_VIEW":
      switch (metadata.view_name) {
        case "SELECT_ACCOUNT":
          Analytics.track(events.linkedAccounts.plaidAccountSelected, { institutionName })
          break
        case "CONNECTED":
          Analytics.track(events.linkedAccounts.plaidConnected, { institutionName })
          break
      }
      break
    case "HANDOFF":
      Analytics.track(events.linkedAccounts.plaidSDKHandoff)
      break
    case "ERROR": {
      const error = {
        ...(metadata.error_type ? { type: metadata.error_type } : {}),
        ...(metadata.error_code ? { code: metadata.error_code } : {}),
        ...(metadata.error_message ? { message: metadata.error_message } : {}),
      }
      Analytics.track(events.linkedAccounts.plaidLinkError, { error })
      break
    }
  }
}

/**
 * Publishes analytics event from corresponding Plaid Link onExit callback
 */
export const handlePlaidExitAnalytics = ({
  error,
  metadata,
}: {
  error: PlaidLinkError | null
  metadata: PlaidLinkOnExitMetadata
}) => {
  // No error indicates regular exit event
  if (error) return
  Analytics.track(events.linkedAccounts.plaidLinkExit, { exitPoint: metadata.status })
}
