import React from "react"
import { useTranslation } from "react-i18next"
import { BusinessAvatar } from "@northone/ui"
import { Link } from "react-router-dom"
import { Box } from "rebass"
import { useAppSelector } from "@core/redux/utils"
import { selectActiveBusinessID } from "@core/active-business/redux/selectors"
import { Analytics } from "@core/analytics/actions"
import { events } from "@core/analytics/events"
import { RoutesEnum } from "@routers/types"
import { Panel } from "@components/primitive/panel/panel"
import { Text } from "@components/primitive/text/text"
import { AnimatePresence } from "@components/animations/animate-presence/animation-presence"
import { FlexRowLayout, FlexColumnLayout } from "@layouts/flex"
import { theme } from "@layouts/theme"
import { images } from "@assets/images/images"
import { useBusinessNameQuery, useOnboardingMeQuery } from "@generated/graphql"
import { BusinessBalances } from "../shared/business-balances"
import { SideBarActionItem } from "../shared/action-item"
import { SideBarSection } from "../shared/side-bar-section"
import { AccountDetails } from "../shared/account-details"
import { PlanPill } from "@features/subscriptions/landing/components/plan-pill"
import { isStandardPlan } from "@features/subscriptions/utils"
import { useSubscriptionsData } from "@features/subscriptions/shared/hooks/use-subscriptions-data"

const SubscriptionPill = ({
  planName,
  planShortTitle,
}: {
  planName?: string
  planShortTitle?: string
}) => {
  if (!planName || !planShortTitle || isStandardPlan(planName)) return null
  return <PlanPill text={planShortTitle} sx={{ backgroundColor: theme.colors.goldTint4 }} />
}

export const SideBar: React.FC = React.memo(
  function Sidebar() {
    const { t } = useTranslation()
    const businessId = useAppSelector(selectActiveBusinessID)
    const { data: businessData } = useBusinessNameQuery({ variables: { businessId } })
    const { data: userData } = useOnboardingMeQuery()
    const { subscription } = useSubscriptionsData()

    const businessName = businessData?.business?.name || ""
    const businessPlanName = subscription?.plan?.name
    const planShortTitle = subscription?.plan?.shortTitle

    const userName = [userData?.me?.firstName, userData?.me?.lastName]
      .filter((value) => !!value)
      .join(" ")

    return (
      <Box
        sx={{
          marginBottom: 50,
        }}
      >
        <AnimatePresence style={{ height: "100%" }} withoutWrapper animation="fadeIn">
          <Panel>
            <Link to={RoutesEnum.SETTINGS_TEAM_BANKING} style={{ textDecoration: "none" }}>
              <FlexRowLayout
                sx={{
                  alignItems: "center",
                  padding: theme.spacing.sidebar,
                }}
              >
                <BusinessAvatar businessName={businessName} />
                <FlexRowLayout sx={{ justifyContent: "space-between", width: "100%" }}>
                  <FlexColumnLayout
                    sx={{
                      marginLeft: theme.spacing.sidebar,
                    }}
                  >
                    <Text tag="h5" textColor={theme.colors.ui2}>
                      {businessName}
                    </Text>
                    <Text
                      tag="label"
                      textColor={theme.colors.ui2}
                      sx={{
                        paddingTop: "5px",
                        fontSize: "10px",
                        fontWeight: 400,
                        lineHeight: "14px",
                      }}
                    >
                      {userName}
                    </Text>
                  </FlexColumnLayout>
                  <SubscriptionPill planName={businessPlanName} planShortTitle={planShortTitle} />
                </FlexRowLayout>
              </FlexRowLayout>
            </Link>
            <BusinessBalances analyticsEvent={events.sidebar.businessBalances} withoutExpansion />
            <SideBarSection>
              <SideBarActionItem
                imageSrc={images.icons.accounts}
                text={t("dashboard.sidebar.accounts")}
                route={RoutesEnum.ACCOUNTS_OVERVIEW}
                onClick={() => {
                  Analytics.track(events.sidebar.accountsOverview)
                }}
              />
            </SideBarSection>
            <SideBarSection>
              <SideBarActionItem
                imageSrc={images.icons.pay}
                text={t("dashboard.sidebar.moveMoney")}
                route={RoutesEnum.MOVE_MONEY}
                modal={true}
                onClick={() => {
                  Analytics.track(events.sidebar.moveMoney)
                }}
                sx={{
                  marginBottom: 16,
                }}
              />
              <SideBarActionItem
                imageSrc={images.icons.addFunds}
                text={t("dashboard.sidebar.addFunds")}
                route={RoutesEnum.ADD_FUNDS}
                modal={true}
                onClick={() => {
                  Analytics.track(events.sidebar.addFunds)
                }}
                sx={{
                  marginBottom: 16,
                }}
              />
              <SideBarActionItem
                imageSrc={images.icons.applyForLending}
                text={t("dashboard.sidebar.applyForLending")}
                route={RoutesEnum.LENDING}
                modal={false}
                onClick={() => {
                  Analytics.track(events.sidebar.applyForLending)
                }}
                sx={{
                  marginBottom: 16,
                }}
              />
            </SideBarSection>
            <AccountDetails />
          </Panel>
        </AnimatePresence>
      </Box>
    )
  },
  () => true,
)
