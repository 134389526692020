import { createAction, TActionsUnion } from "@core/redux/utils"
import { CardSetupScreensEnum } from "../card-setup-modal/card-setup-router"

export enum CardsActionEnum {
  SET_CURRENT_CARD_ID = "SET_CURRENT_CARD_ID",
  SET_CARD_SETUP_MODAL_OPEN = "SET_CARD_SETUP_MODAL_OPEN",
  SET_CARD_SETUP_MODAL_SCREEN = "SET_CARD_SETUP_MODAL_SCREEN",
  SET_ACCOUNT_ID = "SET_ACCOUNT_ID",
  SET_CARD_NOT_ARRIVED_MODAL_OPEN = "SET_CARD_NOT_ARRIVED_MODAL_OPEN",
  SET_PIN_SET_STATUS_UPDATE_AT = "SET_PIN_SET_STATUS_UPDATE_AT",
  SET_IS_CARD_ACTIVATION_PIN_SET_PROCESSING = "SET_IS_CARD_ACTIVATION_PIN_SET_PROCESSING",
  SET_PIN_SET_PENDING = "SET_PIN_SET_PENDING",
  SET_ADD_VIRTUAL_CARD_MODAL_OPEN = "SET_ADD_VIRTUAL_CARD_MODAL_OPEN",
  SET_CURRENT_CARD_IS_LOADING = "SET_CURRENT_CARD_IS_LOADING",
  SET_CURRENT_CARD_ERROR_MESSAGE = "SET_CURRENT_CARD_ERROR_MESSAGE",
}

export const cardsActions = {
  setCurrentCardId: (cardId: string) => createAction(CardsActionEnum.SET_CURRENT_CARD_ID, cardId),
  setCardActivationModalOpen: (isOpen: boolean) =>
    createAction(CardsActionEnum.SET_CARD_SETUP_MODAL_OPEN, isOpen),
  setCardSetupModalScreen: (screenName: CardSetupScreensEnum) =>
    createAction(CardsActionEnum.SET_CARD_SETUP_MODAL_SCREEN, screenName),
  setCardAccountId: (id: string) => createAction(CardsActionEnum.SET_ACCOUNT_ID, id),
  setCardNotArrivedModalOpen: (isOpen: boolean) =>
    createAction(CardsActionEnum.SET_CARD_NOT_ARRIVED_MODAL_OPEN, isOpen),
  setPinSetStatusUpdateAt: (timeStamp: string) =>
    createAction(CardsActionEnum.SET_PIN_SET_STATUS_UPDATE_AT, timeStamp),
  setIsCardActivationPinSetProcessing: (isProcessing: boolean) =>
    createAction(CardsActionEnum.SET_IS_CARD_ACTIVATION_PIN_SET_PROCESSING, isProcessing),
  setPinSetPending: (submitted: boolean) =>
    createAction(CardsActionEnum.SET_PIN_SET_PENDING, submitted),
  setAddVirtualCardModalOpen: (isOpen: boolean) =>
    createAction(CardsActionEnum.SET_ADD_VIRTUAL_CARD_MODAL_OPEN, isOpen),
  setCurrentCardIsLoading: (isLoading: boolean) =>
    createAction(CardsActionEnum.SET_CURRENT_CARD_IS_LOADING, isLoading),
  setCurrentCardErrorMessage: (errorMessage: string) =>
    createAction(CardsActionEnum.SET_CURRENT_CARD_ERROR_MESSAGE, errorMessage),
}

export type TCardsActions = TActionsUnion<typeof cardsActions>
