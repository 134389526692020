import React from "react"
import { formatMoney } from "accounting"
import { useTranslation } from "react-i18next"
import { CounterpartyAvatar } from "../../avatar/counterparty-avatar"
import { Text } from "@components/primitive/text/text"
import { ListItem } from "@components/primitive/list-item/list-item"
import { theme } from "@layouts/theme"
import { FlexColumnLayout, FlexRowLayout } from "@layouts/flex"
import { MaskTextInput } from "../../fields/mask-input"
import { images } from "@assets/images/images"

export interface IReconcileListItemProps {
  name: string
  previousBalance: number
  newBalance: string
  setNewBalance: (newBalance: string) => void
  isDefault: boolean
  hasInsufficientFunds?: boolean
}

/**
 * Defines a sub-account list item
 */
export const ReconcileListItem: React.FC<IReconcileListItemProps> = ({
  name,
  isDefault,
  previousBalance,
  newBalance,
  setNewBalance,
  hasInsufficientFunds,
}) => {
  const { t } = useTranslation()
  return (
    <ListItem
      listItemHeight="medium"
      sx={{
        justifyContent: "space-between",
        cursor: "default",
      }}
      hasHoverState={false}
    >
      <FlexRowLayout>
        <CounterpartyAvatar
          counterpartyName={name}
          colorThemes={[{ backgroundColor: theme.colors.ui5, textColor: theme.colors.ui3 }]}
          imageSrc={isDefault ? images.defaultAccount : undefined}
        />
        <FlexColumnLayout sx={{ marginX: theme.spacing.listItemPadding, justifyContent: "center" }}>
          <Text tag="body-small">{name}</Text>
          {!isDefault && (
            <Text
              hasPartialBolding
              tag="body-x-small"
              textColor={hasInsufficientFunds ? theme.colors.error100 : theme.colors.ui2}
            >
              {hasInsufficientFunds
                ? t("errors.insufficientFunds")
                : t("accounts.reconcile.reallocate.previousBalance", {
                    balance: formatMoney(previousBalance),
                  })}
            </Text>
          )}
        </FlexColumnLayout>
      </FlexRowLayout>
      <FlexColumnLayout
        sx={{
          alignItems: "center",
          justifyContent: "center",
          paddingX: theme.spacing.listItemPadding,
        }}
      >
        {isDefault ? (
          <Text tag="body">{formatMoney(newBalance)}</Text>
        ) : (
          <MaskTextInput
            maskType="AMOUNT"
            value={newBalance ? newBalance.toString() : ""}
            onChange={(value) => setNewBalance(value)}
            placeholder="$0.00"
            sx={{ height: 28, width: 100 }}
            subTextContainerStyling={{ height: 0 }}
            inputStyle={{
              textAlign: "end",
              color: hasInsufficientFunds ? theme.colors.error100 : theme.colors.ui1,
            }}
          />
        )}
      </FlexColumnLayout>
    </ListItem>
  )
}
