//@ts-ignore
import { Location } from "history"

export enum RoutesEnum {
  DASHBOARD = "/",
  ACCOUNTS_OVERVIEW = "/accounts",
  SUB_ACCOUNT_DETAIL = "/accounts/:subAccountId",
  EDIT_SUB_ACCOUNT = "/accounts/:subAccountId/edit",
  SETTINGS_CONTACT_INFORMATION = "/settings",
  SETTINGS_CHANGE_PASSWORD = "/settings/change-password",
  SETTINGS_LINKED_ACCOUNT = "/settings/external-account",
  SETTINGS_LINKED_ACCOUNT_TYPE = "/settings/external-account/select-type",
  SETTINGS_LINKED_ACCOUNT_DETAIL = "/settings/external-account/:linkedAccountId",
  SETTINGS_RECIPIENTS = "/settings/recipients",
  SETTINGS_RECIPIENT_LIST_BY_TYPE = "/settings/recipients/:recipientType",
  SETTINGS_MANAGE_TEAM = "/settings/manage-team",
  SETTINGS_TEAM_BANKING = "/settings/team",
  SETTINGS_LEGAL = "/settings/legal",
  SETTINGS_LEGAL_LIST_BY_TYPE = "/settings/legal/:legalType",
  SETTINGS_ACCOUNT_STATEMENTS = "/settings/account-statements",
  SETTINGS_MONTHLY_BILLING = "/settings/monthly-billing",
  SETTINGS_AUTO_TOP_UP = "/settings/auto-top-up",
  SUPPORT_OVERVIEW = "/support",
  SUPPORT_SCHEDULE_CALL = "/support/schedule-call",
  SCHEDULES = "/schedules",
  CARDS = "/cards",
  INVOICES = "/invoices",
  SUPPORT_FAQS = "/support/faqs",
  SUPPORT_FAQS_SECTION = "/support/faqs/:sectionId",
  SUPPORT_FAQS_ARTICLE = "/support/faqs/:sectionId/:articleId",
  SUPPORT_FAQS_SEARCH_ARTICLE = "/support/faqs/search/:articleId",
  INTEGRATIONS = "/integrations",
  SHARE_AND_EARN = "/rewards",
  PERKS = "/perks",
  PLANS = "/plans",
  REVENUE_HUB = "/revenue",
  LENDING = "/lending",

  // modals
  MOVE_MONEY = "/send-money",
  ADD_FUNDS = "/add-funds",
  SEND_INVOICE = "/send-invoice",
  CREATE_SUB_ACCOUNT = "/accounts/create",
  SUB_ACCOUNT_TRANSFER = "/accounts/transfer",
  EDIT_SCHEDULE = "/schedules/edit",
  ACCOUNT_HISTORY = "/account-history",
  ACCOUNT_UPGRADE_BALANCE_TRANSFER = "/balance-transfer",
  INVOICE_PAYMENTS = "/invoices/create",
  SUBSCRIPTION_UPGRADE = "/upgrade",
  SUBSCRIPTION_CANCEL = "/cancel-subscription",
  SUBSCRIPTION_REACTIVATE = "/reactivate-subscription",
}

export interface ILocationState {
  background?: Location
}
