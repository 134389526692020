export const getHorizontalGap = (n: number, columnCount: number, gap: number) => {
  if (n === 1) {
    return {
      paddingLeft: 0,
      paddingRight: gap / 2,
    }
  } else if (n === columnCount) {
    return {
      paddingLeft: gap / 2,
      paddingRight: 0,
    }
  } else {
    return {
      paddingX: gap / 2,
    }
  }
}
