import React from "react"
import { useTranslation } from "react-i18next"
import { formatMoney } from "accounting"

import { Text } from "@northone/ui"
import { theme } from "@layouts/theme"
import { WithHighlight } from "@components/primitive/highlight/highlight"
import {
  isOutgoing,
  isTransactionActive,
  isTransactionPosted,
  TTransactionPanelStatus,
} from "./utils"
import { TransactionPanelItem } from "./base-transaction-panel"

interface IProps {
  amount: number
  status: TTransactionPanelStatus
}

const getAmountTextColor = (
  isOutgoing: boolean,
  isPosted: boolean,
  status: TTransactionPanelStatus,
) => {
  if ((isOutgoing && isPosted) || !isTransactionActive(status)) {
    return theme.colors.ui1
  }

  if (!isPosted) {
    return theme.colors.ui2
  }

  return theme.colors.gold100
}

export const TransactionPanelAmount = ({ amount, status }: IProps) => {
  const { t } = useTranslation()

  return (
    <TransactionPanelItem label={t("dashboard.transactionPanel.amount")}>
      <WithHighlight
        sx={{ marginTop: "5px" }}
        hidden={isOutgoing(amount) || !isTransactionPosted(status)}
        color={theme.colors.gold10}
      >
        <Text
          textColor={getAmountTextColor(isOutgoing(amount), isTransactionPosted(status), status)}
          tag="body-large"
          sx={{ fontSize: "24px", display: "inline-flex" }}
        >
          {isOutgoing(amount) ? "–" : "+"} {formatMoney(Math.abs(amount))}
        </Text>
      </WithHighlight>
    </TransactionPanelItem>
  )
}
