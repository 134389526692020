import React from "react"
import { events } from "@core/analytics/events"
import { MoveMoneySubAccountForm } from "@features/move-money/shared/sub-accounts-form"
import { DomesticWireScreensEnum } from "@features/move-money/router/domestic-wire"
import { useCapabilitiesAndAvailableBalanceQuery } from "@generated/graphql"
import { getFee } from "@features/move-money/utils/fee.utils"
import { useAppSelector } from "@core/redux/utils"
import { selectActiveBusinessID } from "@core/active-business/redux/selectors"
import { ErrorScreen } from "@components/composite/error-screen/error-screen"
import { LoadingPanel } from "@components/extended/panel/loading-panel"

export const DomesticWireSubAccountForm: React.FC = () => {
  const businessId = useAppSelector(selectActiveBusinessID)
  const { data, error, loading, refetch } = useCapabilitiesAndAvailableBalanceQuery({
    variables: { businessId },
    notifyOnNetworkStatusChange: true,
  })
  const fee = getFee(data?.business?.bankAccounts[0].capabilities?.domesticWire.fee)

  if (loading) {
    return <LoadingPanel />
  }
  if (!fee || error) {
    return <ErrorScreen retry={refetch} size="large" />
  }

  return (
    <MoveMoneySubAccountForm
      selectEvent={events.moveMoney.domesticWire.subAccountSelection}
      continueEvent={events.moveMoney.domesticWire.subAccount}
      navigateNextRoute={DomesticWireScreensEnum.DOMESTIC_WIRE_PURPOSE}
      paymentFee={fee}
    />
  )
}
