import { useEffect, useState } from "react"
import { PlaidAccountType } from "@generated/graphql"
import { useCreateNewPlaidItem } from "./useCreateNewPlaidItem"

export function useCreateWorkflow({ refetch }: { refetch: () => void }) {
  const [accountType, setAccountType] = useState<PlaidAccountType>()
  const [shouldShowAccountTypeModal, setShouldShowAccountTypeModal] = useState(false)
  const [shouldShowSuccessModal, setShouldShowSuccessModal] = useState(false)

  const {
    createNewItem,
    reset: resetUseCreateNewPlaidItem,
    loading,
    errorMessageKey,
    needsAccountType,
    plaidItemId,
  } = useCreateNewPlaidItem({ accountType })

  function handleReset() {
    resetUseCreateNewPlaidItem()
    setAccountType(undefined)
  }

  const handleAccountTypeModalContinue = ({ accountType }: { accountType: PlaidAccountType }) => {
    setAccountType(accountType)
    setShouldShowAccountTypeModal(false)
  }

  const handleSuccessModalContinue = () => {
    setShouldShowSuccessModal(false)
    handleReset()
  }

  useEffect(() => {
    if (shouldShowAccountTypeModal) return
    if (!needsAccountType) return
    setShouldShowAccountTypeModal(true)
  }, [shouldShowAccountTypeModal, needsAccountType])

  useEffect(() => {
    if (loading) return
    if (!plaidItemId) return
    if (errorMessageKey) return
    refetch()
    setShouldShowSuccessModal(true)
  }, [loading, plaidItemId])

  return {
    loading,
    errorMessageKey,
    createNewItem,
    newItemId: plaidItemId,
    shouldShowAccountTypeModal,
    closeAccountTypeModal: () => setShouldShowAccountTypeModal(false),
    handleAccountTypeModalContinue,
    shouldShowSuccessModal,
    closeSuccessModal: () => setShouldShowSuccessModal(false),
    handleSuccessModalContinue,
  }
}
