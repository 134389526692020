import React from "react"
import i18n from "@i18n/i18n"
import { FlowModalLayout } from "@components/extended/overlay/flow-modal"
import { CreateSubAccountName } from "./name/form"
import { CreateSubAccountInitialTransfer } from "./initial-transfer/form"
import { CreateSubAccountReview } from "./review/card"
import { CreateSubAccountPercent } from "./percent-rules/form"

export const createSubAccountProgressItems = [
  i18n.t("accounts.createSubAccount.progressBar.name"),
  i18n.t("accounts.createSubAccount.progressBar.addFunds"),
  i18n.t("accounts.createSubAccount.progressBar.rules"),
  i18n.t("accounts.createSubAccount.progressBar.summary"),
]

export type TCreateSubAccountScreenName =
  | "CREATE_SUB_ACCOUNT_NAME"
  | "CREATE_SUB_ACCOUNT_INITIAL_TRANSFER"
  | "CREATE_SUB_ACCOUNT_PERCENT_RULE"
  | "CREATE_SUB_ACCOUNT_REVIEW"

export const CREATE_SUB_ACCOUNT_ROUTES: {
  [key in TCreateSubAccountScreenName]: {
    component: React.ReactElement
    progressItem: string
    layout: FlowModalLayout
  }
} = {
  CREATE_SUB_ACCOUNT_NAME: {
    component: <CreateSubAccountName />,
    progressItem: createSubAccountProgressItems[0],
    layout: "form",
  },
  CREATE_SUB_ACCOUNT_INITIAL_TRANSFER: {
    component: <CreateSubAccountInitialTransfer />,
    progressItem: createSubAccountProgressItems[1],
    layout: "form",
  },
  CREATE_SUB_ACCOUNT_PERCENT_RULE: {
    component: <CreateSubAccountPercent />,
    progressItem: createSubAccountProgressItems[2],
    layout: "form",
  },
  CREATE_SUB_ACCOUNT_REVIEW: {
    component: <CreateSubAccountReview />,
    progressItem: createSubAccountProgressItems[3],
    layout: "centered",
  },
}
