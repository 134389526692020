import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Analytics } from "@core/analytics/actions"
import { events } from "@core/analytics/events"
import { logout } from "@core/auth/auth-service"
import { Text } from "@components/primitive/text/text"
import { Button, ButtonTypeEnum } from "@components/primitive/button/button"
import { AnimatePresence } from "@components/animations/animate-presence/animation-presence"
import { PasswordInput } from "@components/extended/fields/password-input"
import { NoticeBox } from "@components/primitive/notice-box/notice-box"
import { InfoBlockModal } from "@components/composite/info-block-modal/info-block-modal"
import { hasGQLErrorType } from "@utils/errors"
import { BaseLayout } from "@layouts/base-layout"
import { FlexColumnLayout } from "@layouts/flex"
import { useResetPasswordMutation, ErrorCodeEnum } from "@generated/graphql"
import { PasswordValidator, passwordLiveValidationRegexes } from "./password-validator"

export const PasswordSettings: React.FC = () => {
  const { t } = useTranslation()
  const [confirmModalOpen, setConfirmModalOpen] = useState(false)
  const [oldPassword, setOldPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [reEnteredPassword, setReEnteredPassword] = useState("")
  const [errorType, setErrorType] = useState<"incorrectPassword" | "other" | null>(null)

  const [resetPassword, { loading: resetPasswordLoading }] = useResetPasswordMutation({
    variables: { newPassword, oldPassword },
    onError: (e) => {
      setErrorType(
        hasGQLErrorType(e, ErrorCodeEnum.INCORRECT_PASSWORD) ? "incorrectPassword" : "other",
      )
      setConfirmModalOpen(false)
    },
    onCompleted: async () => {
      clearAllInputs()
      logout()
    },
  })

  const clearAllInputs = () => {
    setOldPassword("")
    setNewPassword("")
    setReEnteredPassword("")
  }

  const validationOptions = [
    {
      title: t("settings.pages.changePassword.validators.length"),
      valid: passwordLiveValidationRegexes.characters.test(newPassword),
    },
    {
      title: t("settings.pages.changePassword.validators.uppercase"),
      valid: passwordLiveValidationRegexes.uppercase.test(newPassword),
    },
    {
      title: t("settings.pages.changePassword.validators.lowercase"),
      valid: passwordLiveValidationRegexes.lowercase.test(newPassword),
    },
    {
      title: t("settings.pages.changePassword.validators.specialCharacter"),
      valid: passwordLiveValidationRegexes.specialCharacter.test(newPassword),
    },
    {
      title: t("settings.pages.changePassword.validators.match"),
      valid: Boolean(newPassword && newPassword === reEnteredPassword),
    },
  ]

  return (
    <BaseLayout pageTitle={t("pageTitles.settings.password")}>
      <InfoBlockModal
        title={t("settings.pages.changePassword.confirmModal.title")}
        subtitle={t("settings.pages.changePassword.confirmModal.subtitle")}
        isOpen={confirmModalOpen}
        closeModal={() => setConfirmModalOpen(false)}
        buttons={[
          {
            children: t("settings.pages.changePassword.confirmModal.button"),
            isLoading: resetPasswordLoading,
            onClick: () => {
              Analytics.track(events.settings.password.save)
              resetPassword()
            },
          },
          {
            children: t("buttons.cancel"),
            type: ButtonTypeEnum.SECONDARY,
            onClick: () => {
              clearAllInputs()
              setConfirmModalOpen(false)
            },
          },
        ]}
      />
      <AnimatePresence animation="fadeAndExpand">
        {errorType === "other" && <NoticeBox level="error" text={t("errors.generic")} />}
      </AnimatePresence>
      <Text tag="h2" sx={{ marginBottom: 36 }}>
        {t("settings.pages.changePassword.title")}
      </Text>
      <FlexColumnLayout>
        <PasswordInput
          label={t("settings.pages.changePassword.currentPassword")}
          value={oldPassword}
          onChange={(value) => {
            if (errorType === "incorrectPassword") {
              setErrorType(null)
            }
            setOldPassword(value)
          }}
          placeholder=""
          errorMessage={
            errorType === "incorrectPassword"
              ? t("settings.pages.changePassword.incorrectPassword")
              : ""
          }
        />
        <PasswordInput
          placeholder={t("settings.pages.changePassword.newPassword.placeholder")}
          onChange={setNewPassword}
          value={newPassword}
          label={t("settings.pages.changePassword.newPassword.label")}
        />
        <PasswordInput
          placeholder={t("settings.pages.changePassword.reenterPassword.placeholder")}
          onChange={setReEnteredPassword}
          value={reEnteredPassword}
          label={t("settings.pages.changePassword.reenterPassword.label")}
        />
        <PasswordValidator validationOptions={validationOptions} />
        <Button
          sx={{ alignSelf: "flex-start" }}
          disabled={Boolean(validationOptions.find(({ valid }) => !valid)) || !oldPassword}
          onClick={() => {
            setErrorType(null)
            setConfirmModalOpen(true)
          }}
        >
          {t("settings.pages.changePassword.button")}
        </Button>
      </FlexColumnLayout>
    </BaseLayout>
  )
}
