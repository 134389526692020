import React, { useState, useRef } from "react"
import { WithDropdownMenu } from "@components/primitive/dropdown-menu/dropdown-menu"
import { Text } from "@components/primitive/text/text"
import { Field } from "@components/primitive/field/field"
import { images } from "@assets/images/images"
import { Datepicker } from "@components/primitive/datepicker/datepicker"
import moment from "moment"
import { Box, SxStyleProp } from "rebass"
import { useClickOutside } from "@utils/hooks"
import { theme } from "@layouts/theme"
import { FlexColumnLayout } from "@layouts/flex"
import { AnimatePresence } from "@components/animations/animate-presence/animation-presence"
import { useTranslation } from "react-i18next"

type TCustomDateFormat = {
  sameDay: string
  nextDay: string
  nextWeek: string
  sameElse: string
  lastDay: string
  lastWeek: string
}

interface IDatepickerDropdownProps {
  label?: string
  labelComponent?: React.ReactElement
  placeholder?: string
  onSelect: (value: Date | null) => void
  selectedDate: Date | null
  minDate?: Date | null
  maxDate?: Date | null
  unavailableDates?: Date[]
  alignment?: "left" | "right"
  containerSx?: SxStyleProp
  disabled?: boolean
  isClearDateDisabled?: boolean
  customDateFormat?: TCustomDateFormat
}

export const DatepickerDropdown: React.FC<IDatepickerDropdownProps> = ({
  label,
  placeholder,
  onSelect,
  selectedDate,
  minDate,
  alignment = "left",
  maxDate,
  unavailableDates,
  labelComponent,
  containerSx,
  disabled = false,
  isClearDateDisabled = false,
  customDateFormat,
}) => {
  const [menuIsVisible, setMenuIsVisible] = useState(false)

  const ref = useRef<HTMLDivElement>(null)
  useClickOutside([ref], () => setMenuIsVisible(false))
  const { t } = useTranslation()

  return (
    <Box sx={containerSx} ref={ref}>
      <WithDropdownMenu
        content={
          <FlexColumnLayout sx={{ alignItems: "flex-end" }}>
            {!disabled && (
              <Datepicker
                onSelect={onSelect}
                selectedDate={selectedDate}
                minDate={minDate}
                maxDate={maxDate}
                unavailableDates={unavailableDates}
              />
            )}
            {!isClearDateDisabled && (
              <AnimatePresence animation="fadeAndExpand">
                {!disabled && selectedDate && (
                  <Text
                    onClick={() => onSelect(null)}
                    sx={{
                      marginTop: "5px",
                      marginBottom: 15,
                      paddingY: "5px",
                      paddingX: 10,
                      marginX: 10,
                      cursor: "pointer",
                      backgroundColor: theme.colors.ui7,
                      ":hover": {
                        backgroundColor: theme.colors.ui6,
                      },
                      ":active": {
                        backgroundColor: theme.colors.ui5,
                      },
                    }}
                    tag="body-bold"
                  >
                    {t("moveMoney.paymentDate.clearDate")}
                  </Text>
                )}
              </AnimatePresence>
            )}
          </FlexColumnLayout>
        }
        visible={menuIsVisible}
        customWidth={295}
        alignment={alignment}
      >
        <Field
          sx={{
            padding: "10px 8px 10px 12px",
            cursor: "pointer",
            ...(disabled
              ? {
                  cursor: "default",
                  outline: "none",
                  borderStyle: "none",
                }
              : {}),
          }}
          subTextContainerStyling={{ height: 0 }}
          isFocused={menuIsVisible}
          label={label}
          labelComponent={labelComponent}
        >
          <Text
            onClick={() => setMenuIsVisible(!menuIsVisible)}
            tag="body"
            sx={{
              width: "100%",
              height: "100%",
              outline: "none",
              backgroundImage: `url(${images.icons.calendar})`,
              backgroundPosition: "center right",
              backgroundRepeat: "no-repeat",
            }}
            textColor={selectedDate ? theme.colors.ui1 : theme.colors.ui2}
            disabled={disabled}
          >
            {/* Format date as "MM/DD/YYYY" */}
            {selectedDate
              ? customDateFormat
                ? moment(selectedDate).calendar(null, customDateFormat)
                : moment(selectedDate).calendar(null, {
                    sameDay: "[Today]",
                    nextDay: "[Tomorrow]",
                    nextWeek: "dddd",
                    sameElse: "MMMM D, YYYY",
                    lastDay: "MMMM D, YYYY",
                    lastWeek: "MMMM D, YYYY",
                  })
              : placeholder}
          </Text>
        </Field>
      </WithDropdownMenu>
    </Box>
  )
}
