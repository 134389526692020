import { applyMiddleware, compose, createStore, Store, Middleware } from "redux"
import thunk from "redux-thunk"
import { createRootReducer } from "./root-reducer"
import { IRootState } from "./root-state"
import { persistReducer, persistStore, Persistor } from "redux-persist"
import storage from "redux-persist/lib/storage"
import { createEpicMiddleware } from "redux-observable"
import { rootEpic } from "./epics"

const epicMiddleware = createEpicMiddleware()
const middleware: Middleware[] = [epicMiddleware, thunk]

/**
 * helper to create type-checked persist configs.
 */
const _createPersistConfig = <R>(config: {
  key: keyof IRootState | "root"

  whitelist?: Array<keyof R>
}) => ({
  key: config.key,
  storage,
  whitelist: config.whitelist || [],
})

const rootPersistConfig = _createPersistConfig<IRootState>({
  key: "root",
  // only add keys that are NECESSARY to persist and are NOT considered PERSONALLY IDENTIFIABLE VALUES
  whitelist: [],
})

export const configureStore = (
  preloadedState?: IRootState,
): { store: Store; persistor: Persistor } => {
  const rootReducer = createRootReducer()
  const persistedReducer = persistReducer(rootPersistConfig, rootReducer)

  const typedWindow: any = window
  const composeEnhancers =
    typeof typedWindow === "object" && typedWindow.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? typedWindow.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
      : compose

  const enhancers = composeEnhancers(applyMiddleware(...middleware))

  const store = createStore(persistedReducer, preloadedState, enhancers)
  const persistor = persistStore(store)

  epicMiddleware.run(rootEpic as any)

  return { store, persistor }
}
