import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import { DateTime } from "luxon"
import * as Sentry from "@sentry/browser"
import { useAppSelector } from "@core/redux/utils"
import { useCardPinChangeFormLazyQuery } from "@generated/graphql"
import { cardsActions } from "../redux/actions"
import { CardSetupScreensEnum } from "../card-setup-modal/card-setup-router"
import { Analytics } from "@core/analytics/actions"
import { events } from "@core/analytics/events"

const URL_EXPIRY_CHECK_INTERVAL_MS = 10000
const PIN_SET_LOAD_MS = 5 * 1000

export const useCardPinSet = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [isIFrameLoading, setIsIFrameLoading] = useState(true)
  const [pinSetFormUrl, setPinSetFormUrl] = useState<string>("")
  const [pinSetExpiresAt, setPinSetExpiresAt] = useState<string>("")

  const businessId = useAppSelector((state) => state.activeBusiness.businessId)
  const cardId = useAppSelector((state) => state.cards.currentCardId)
  const accountId = useAppSelector((state) => state.cards.cardAccountId)

  const [cardPinChangeQuery, { error, loading, refetch }] = useCardPinChangeFormLazyQuery({
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: { businessId, cardId: cardId || "", accountId: accountId || "" },
    onCompleted: (data) => {
      const cardPinChangeFormData = data.cardPinChangeForm
      if (cardPinChangeFormData) {
        setPinSetFormUrl(cardPinChangeFormData.url)
        setPinSetExpiresAt(cardPinChangeFormData.expiresAt)
      }
    },
  })

  const handleOnUrlExpired = () => {
    dispatch(cardsActions.setCardActivationModalOpen(false))
  }

  const checkUrlExpiry = () => {
    const currentTime = DateTime.local().toISO()
    const urlExpiryTime = DateTime.fromISO(pinSetExpiresAt)
      .minus({
        seconds: 30,
      })
      .toString()

    const isExpired = Boolean(currentTime > urlExpiryTime)
    if (isExpired) {
      handleOnUrlExpired()
    }
  }

  useEffect(() => {
    const urlExpiryCheckInterval = setInterval(() => {
      if (pinSetExpiresAt) {
        checkUrlExpiry()
      }
    }, URL_EXPIRY_CHECK_INTERVAL_MS)

    return () => {
      clearInterval(urlExpiryCheckInterval)
    }
  }, [pinSetExpiresAt])

  useEffect(() => {
    if (!pinSetFormUrl) {
      cardPinChangeQuery()
    }
  }, [pinSetFormUrl])

  /**
   * We are adding a listener to the Galileo pin set iframe.
   * When the iframe emits an event, we check to make sure the
   * event is coming from Galileo.
   * If true, we check if the request was received by Galileo.
   * We show a loading spinner for 5 second before closing the
   * modal to show a success message. Note, at this point we won't
   * know if the pin set was successful.
   * Card-summary/card-summary-content will start polling the status
   * of the pin set and handle the Error & success UI.
   */
  useEffect(() => {
    if (!pinSetFormUrl) {
      return
    }
    const listener = (event: MessageEvent) => {
      const pinsetUrl = new URL(pinSetFormUrl)
      if (event.origin !== pinsetUrl.origin) {
        return
      }

      if (event.data === 0) {
        Analytics.track(events.cards.activate.pinSet)

        dispatch(
          cardsActions.setCardSetupModalScreen(CardSetupScreensEnum.CARD_SETUP_PIN_SET_LOADING),
        )

        setTimeout(() => {
          dispatch(
            cardsActions.setCardSetupModalScreen(CardSetupScreensEnum.CARD_SETUP_PIN_SET_SUCCESS),
          )
          dispatch(cardsActions.setPinSetPending(true))
        }, PIN_SET_LOAD_MS)
      } else {
        Sentry.captureMessage(`[PIN-SET-ERROR]: ${event.data} `)
        handleOnUrlExpired()
      }
    }
    window.addEventListener("message", listener)
    return () => window.removeEventListener("message", listener)
  }, [pinSetFormUrl])

  return {
    error,
    loading,
    refetch,
    setIsIFrameLoading,
    isIFrameLoading,
    pinSetFormUrl,
    t,
  }
}
