import { createAction, TActionsUnion } from "../../redux/utils"

export enum ActiveBusinessActionEnum {
  SET_BUSINESS_ID = "ACTIVE_BUSINESS_SET_BUSINESS_ID",
}

export const activeBusinessActions = {
  setBusinessId: (id: string) => createAction(ActiveBusinessActionEnum.SET_BUSINESS_ID, id),
}

export type TActiveBusinessActions = TActionsUnion<typeof activeBusinessActions>
