import React from "react"
import {
  Text,
  Button,
  Modal,
  ButtonTypeEnum,
  AnimatePresence,
  ErrorBanner,
  LoadingIcon,
} from "@northone/ui"
import { Box, Image } from "rebass"
import { useTranslation } from "react-i18next"

import { theme } from "@layouts/theme"
import { useGetDigitalCardAssetUrl } from "./utils"

interface IViewCardNumbersModalProps {
  cardId: string
  isOpen: boolean
  closeModal: () => void
}

export const ViewCardNumbersModal: React.FC<IViewCardNumbersModalProps> = ({
  cardId,
  isOpen,
  closeModal,
}) => {
  const { t } = useTranslation()
  const {
    error,
    url,
    setUrl,
    loading,
    setImageLoading,
    closeButtonStyle,
    virtualCardImageStyle,
    loadingSpinnerContainerStyle,
  } = useGetDigitalCardAssetUrl(cardId, isOpen)

  /**
   * TODO: [DEV-506] Fix Loading Screen on Error where the height is too big and push the close button down
   * https://northone.atlassian.net/browse/DEV-506
   **/
  return (
    <Modal
      sx={{ minWidth: 407, minHeight: 520, paddingTop: 44, paddingBottom: 24 }}
      zIndex={theme.zIndex.flowModal + 1}
      onOutsideClick={closeModal}
      isOpen={isOpen}
    >
      <AnimatePresence animation="fadeAndExpand">
        {error && (
          <ErrorBanner
            textProps={{ onLinkClick: () => window.Intercom("show") }}
            text={t("cards.virtualCard.viewCardNumbersError")}
          />
        )}
      </AnimatePresence>

      <Text tag="h3Serif" sx={{ marginBottom: 16, marginX: "auto" }}>
        {t("cards.virtualCard.viewCardNumbers")}
      </Text>

      <Box
        sx={{
          marginTop: 20,
          marginBottom: 30,
        }}
      >
        {loading && (
          <Box sx={loadingSpinnerContainerStyle}>
            <LoadingIcon dimension={40} />
          </Box>
        )}

        {url && (
          <Image onLoad={() => setImageLoading(false)} sx={virtualCardImageStyle} src={url} />
        )}
      </Box>

      <Button
        type={ButtonTypeEnum.PRIMARY_BLUE}
        onClick={() => {
          closeModal()
          setUrl("")
          setImageLoading(true)
        }}
        sx={closeButtonStyle}
      >
        {t("buttons.close")}
      </Button>
    </Modal>
  )
}
