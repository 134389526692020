import { ISupportCoverage } from "@features/support/types"
import { DateTime } from "luxon"

import { isWeekend, isBankHoliday } from "@northone/bank-holidays"

export enum MonthEnum {
  January,
  February,
  March,
  April,
  May,
  June,
  July,
  August,
  September,
  October,
  November,
  December,
}

export const EASTERN_TIME_ZONE = "America/New_York"

/**
 * @description Determines whether 'now' is before the provided ISO dateTime string.
 * If only the time portion of an ISO dateTime string is provided, the current date in local
 * time will be used for the comparison.
 * @param dateTimeInISOFormat A variety of ISO 8601 formats is supported.
 * eg. 2021-11-02T19:30:00Z, 19:30:00Z etc.
 * @see https://moment.github.io/luxon/#/parsing?id=iso-8601 for a list of supported ISO 8601 formats
 */
export const isNowBefore = (dateTimeInISOFormat: string): boolean => {
  const dateTime = DateTime.fromISO(dateTimeInISOFormat)

  // a positive diff indicates that 'now' is before the dateTime
  return dateTime.diffNow().milliseconds > 0
}

/**
 * @description This function returns a Luxon DateTime object
 * @returns date and time in Eastern Timezone
 */
export const getDateTimeInEasternTimezone = () => {
  return DateTime.now().setZone(EASTERN_TIME_ZONE)
}

/**
 * @description This function returns a boolean value based on working hours
 * @param date Luxon DateTime object
 * @param schedule Object containing start and end hours for the day
 * @returns Boolean value based on weekday and weekend work hours
 */
export const isInWorkingHours = (date: DateTime, schedule: ISupportCoverage) => {
  return date.hour >= schedule.start_hour && date.hour <= schedule.end_hour
}

/**
 * @description This function converts hours to 12 hour format
 * @param  hour a 24 hour number that needs to be converted
 * @returns String value in 12 hour format
 */
export const convertTo12HrFormat = (hour: number): string => {
  const timeSuffix = hour >= 12 ? "PM" : "AM"
  const formattedHour = hour % 12 || 12
  return `${formattedHour} ${timeSuffix}`
}

/**
 * It takes a date string and a format string and returns a formatted date string
 * @param {string} date - The date string to format.
 * @param {string} format - The format you want to use to format the date.
 * @returns A string
 */
export const getDateWithSpecificFormat = (date: Date | string, format: string) => {
  if (typeof date === "string") {
    return DateTime.fromISO(date).toFormat(format)
  }

  return DateTime.fromJSDate(date).toFormat(format)
}

export const isWeekendOrBankHoliday = (dateTime: DateTime | string) => {
  const formattedDate = typeof dateTime === "string" ? dateTime : dateTime.toISO()
  return isWeekend(formattedDate) || isBankHoliday(formattedDate)
}

export const getWeekendsAndBankHolidaysBetween = (
  minDate: DateTime,
  maxDate: DateTime,
): DateTime[] => {
  if (minDate > maxDate) {
    return []
  }

  const weekendsAndBankHolidays: DateTime[] = []

  let currentDate = minDate.setZone(EASTERN_TIME_ZONE)

  do {
    if (isWeekendOrBankHoliday(currentDate)) {
      weekendsAndBankHolidays.push(currentDate)
    }

    currentDate = currentDate.plus({ days: 1 })
  } while (currentDate <= maxDate)

  return weekendsAndBankHolidays
}

export const getOneMonthFromTodayFormattedDate = () => {
  const currentDate = DateTime.local()
  const futureDate =
    currentDate.day === currentDate.daysInMonth
      ? currentDate.plus({ months: 1 }).endOf("month")
      : currentDate.plus({ months: 1 })

  return futureDate.toFormat("MMMM d, yyyy")
}

export const getFormattedFullDate = (date: string) =>
  DateTime.fromISO(date).toLocaleString(DateTime.DATE_FULL)

export const getFormattedShortDate = (date: string) => DateTime.fromISO(date).toLocaleString()
