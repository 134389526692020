import { Action } from "redux"

import { createAction, TActionsUnion } from "@core/redux/utils"
import { TAddFundsScreenName } from "@features/add-funds/add-funds.router"
import { FundingMethodEnum } from "./reducer"

type AddFundsActionTypes = AddFundsActionEnum

export interface IActionsForAddFunds<P> extends Action<AddFundsActionTypes> {
  payload?: P
}

export enum AddFundsActionEnum {
  NAVIGATE_NEXT = "ADD_FUNDS_NAVIGATE_NEXT",
  NAVIGATE_BACK = "ADD_FUNDS_NAVIGATE_BACK",
  CLEAR_STATE = "ADD_FUNDS_CLEAR_STATE",
  SET_AMOUNT = "ADD_FUNDS_SET_AMOUNT",
  SET_USER_PHONE_NUMBER = "SET_USER_PHONE_NUMBER",
  SET_FUNDING_METHOD = "ADD_FUNDS_SET_FUNDING_METHOD",
  SEND_SMS_MESSAGE = "SEND_SMS_MESSAGE",
  SEND_SMS_MESSAGE_SUCCESS = "SEND_SMS_MESSAGE_SUCCESS",
  SEND_SMS_MESSAGE_FAILURE = "SEND_SMS_MESSAGE_FAILURE",
  SET_SMS_MESSAGE_SEND_STATUS = "SET_SMS_MESSAGE_SEND_STATUS",
  SET_SMS_MESSAGE_SEND_CAPABILITY_STATUS = "SET_SMS_MESSAGE_SEND_CAPABILITY_STATUS",
  INITIALIZE_SMS_MESSAGE_SERVICE = "INITIALIZE_SMS_MESSAGE_SERVICE",
  INITIALIZE_SMS_MESSAGE_SERVICE_SUCCESS = "INITIALIZE_SMS_MESSAGE_SERVICE_SUCCESS",
  INITIALIZE_SMS_MESSAGE_SERVICE_FAILURE = "INITIALIZE_SMS_MESSAGE_SERVICE_FAILURE",
}

export const addFundsActions = {
  navigateNext: (nextScreen: TAddFundsScreenName) =>
    createAction(AddFundsActionEnum.NAVIGATE_NEXT, nextScreen),
  navigateBack: () => createAction(AddFundsActionEnum.NAVIGATE_BACK),
  clearState: () => createAction(AddFundsActionEnum.CLEAR_STATE),
  setAmount: (amount: string) => createAction(AddFundsActionEnum.SET_AMOUNT, amount),
  setUserPhoneNumber: (phone: string) =>
    createAction(AddFundsActionEnum.SET_USER_PHONE_NUMBER, phone),
  setFundingMethod: (fundingMethod: FundingMethodEnum) =>
    createAction(AddFundsActionEnum.SET_FUNDING_METHOD, fundingMethod),
  sendSMSMessageSuccess: (res: string) =>
    createAction(AddFundsActionEnum.SEND_SMS_MESSAGE_SUCCESS, res),
  sendSMSMessageFailure: (err: string) =>
    createAction(AddFundsActionEnum.SEND_SMS_MESSAGE_FAILURE, err),
  sendSMSMessage: (phone: string): IActionsForAddFunds<any> => ({
    payload: { phone: phone },
    type: AddFundsActionEnum.SEND_SMS_MESSAGE,
  }),
  setSMSMessageSendStatus: (status: boolean | null) =>
    createAction(AddFundsActionEnum.SET_SMS_MESSAGE_SEND_STATUS, status),
  setSMSMessageSendCapabilityStatus: (flag: boolean) =>
    createAction(AddFundsActionEnum.SET_SMS_MESSAGE_SEND_CAPABILITY_STATUS, flag),
  initializeSMSMessageService: () =>
    createAction(AddFundsActionEnum.INITIALIZE_SMS_MESSAGE_SERVICE),
  initializeSMSMessageServiceSuccess: () =>
    createAction(AddFundsActionEnum.INITIALIZE_SMS_MESSAGE_SERVICE_SUCCESS),
  initializeSMSMessageServiceFailure: (err: string) =>
    createAction(AddFundsActionEnum.INITIALIZE_SMS_MESSAGE_SERVICE_FAILURE, err),
}

export type TAddFundsActions = TActionsUnion<typeof addFundsActions>
