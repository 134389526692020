import React from "react"
import { Box } from "rebass"
import { LoadingIcon } from "@northone/ui"

const LoadingComponent = ({
  marginTop = 0,
  dimension = 74,
}: {
  marginTop?: number
  dimension?: number
}) => {
  return (
    <Box sx={{ marginTop }}>
      <LoadingIcon dimension={dimension} />
    </Box>
  )
}

export default LoadingComponent
