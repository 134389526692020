import React from "react"
import { ButtonTypeEnum } from "@components/primitive/button/button"
import { ChangePlanDetailsLayout } from "@features/subscriptions/shared/components/change-plan-details-layout"
import { useSubscriptionsData } from "@features/subscriptions/shared/hooks/use-subscriptions-data"
import { useTranslation } from "react-i18next"
import { Image } from "rebass"
import { images } from "@assets/images/images"
import { useDispatch } from "react-redux"
import { appActions } from "@core/redux/app/actions"
import { ErrorScreen } from "@components/composite/error-screen/error-screen"
import { getFormattedFullDate } from "@utils/dates"

export const CancellationSuccessScreen = ({ closeModal }: { closeModal: () => void }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { subscription, loading } = useSubscriptionsData()

  if (loading) return null
  if (!subscription || !subscription.nextBillingDate) return <ErrorScreen size="large" />

  const { nextBillingDate, plan } = subscription

  const copy = {
    heading: t("subscriptions.cancel.success.title", { planTitle: plan?.title }),
    subtitle: t("subscriptions.cancel.success.subtitle", {
      nextBillingDate: getFormattedFullDate(nextBillingDate),
    }),
    button: t("buttons.continue"),
    toast: t("subscriptions.cancel.success.toast"),
  }

  const buttons = [
    {
      type: ButtonTypeEnum.PRIMARY_BLACK,
      children: copy.button,
      onClick: () => {
        closeModal()
        dispatch(appActions.setNotificationMessage(copy.toast))
      },
    },
  ]

  return (
    <ChangePlanDetailsLayout
      heroImg={<Image src={images.cancellationSuccessHero} />}
      heading={copy.heading}
      subtitle={copy.subtitle}
      buttons={buttons}
    />
  )
}
