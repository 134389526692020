import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { Image } from "rebass"
import { FlexRowLayout } from "@northone/ui"
import { Text } from "@components/primitive/text/text"
import { FormBody } from "@components/composite/form-body/form-body"
import { ButtonTypeEnum } from "@components/primitive/button/button"
import { addFundsActions } from "@features/add-funds/redux/actions"
import { theme } from "@layouts/theme"
import { images } from "@assets/images/images"
import { ATM_DEPOSIT_CASH_URL } from "@utils/constants"

export const ATMsDepositCash: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const depositInstructions: { step: string }[] = [
    { step: t("topUp.addFunds.atmDepositCash.step1") },
    { step: t("topUp.addFunds.atmDepositCash.step2") },
    { step: t("topUp.addFunds.atmDepositCash.step3") },
    { step: t("topUp.addFunds.atmDepositCash.step4") },
    { step: t("topUp.addFunds.atmDepositCash.step5") },
  ]

  return (
    <FlexRowLayout>
      <FormBody
        title={t("topUp.addFunds.atmDepositCash.title")}
        subtitle={t("topUp.addFunds.atmDepositCash.subtitle")}
        buttons={[
          {
            children: t("buttons.goBack"),
            type: ButtonTypeEnum.TERTIARY,
            onClick: () => {
              dispatch(addFundsActions.navigateBack())
            },
          },
          {
            children: t("topUp.addFunds.atmDepositCash.button"),
            type: ButtonTypeEnum.PRIMARY_BLUE,
            onClick: () => {
              window.open(ATM_DEPOSIT_CASH_URL)
            },
            iconSrc: images.icons.externalLink,
            sx: { paddingRight: "13px" },
            iconSx: {
              order: 2,
              marginRight: 0,
              marginLeft: "4px",
            },
          },
        ]}
      >
        {depositInstructions.map((item: { step: string }, index) => (
          <FlexRowLayout key={index} sx={{ marginVertical: 10 }}>
            <Text hasPartialBolding textColor={theme.colors.ui2} width={25} tag="body">{`${
              index + 1
            }.`}</Text>
            <Text hasPartialBolding textColor={theme.colors.ui2} tag="body">
              {item.step}
            </Text>
          </FlexRowLayout>
        ))}
        <FlexRowLayout
          sx={{
            backgroundColor: theme.colors.ui6,
            padding: 18,
            borderRadius: "4px",
            marginTop: 4,
          }}
        >
          <Image src={images.icons.infoGrey} sx={{ minWidth: 16 }} />
          <Text sx={{ marginLeft: "12px" }} tag="body-small" textColor={theme.colors.ui1}>
            {t("topUp.addFunds.atmDepositCash.infoBanner")}
          </Text>
        </FlexRowLayout>
      </FormBody>
      <Image marginLeft={"40px"} minWidth={"120px"} height={"120px"} src={images.greenDot} />
    </FlexRowLayout>
  )
}
