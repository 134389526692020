import React from "react"
import { Text } from "@components/primitive/text/text"
import { Button, ButtonTypeEnum, FlexColumnLayout } from "@northone/ui"
import { theme } from "@layouts/theme"
import { TPerk } from "../types"
import { PrismicText } from "@prismicio/react"
import { Image } from "rebass"
import { images } from "@assets/images/images"
import { useTranslation } from "react-i18next"
import { IPerkDetailsProp, PerksPartnerText, pushAnalyticsOnModalClose } from "./perks-modal"
import { perksStyles } from "../style"
import * as prismicH from "@prismicio/helpers"
import { Analytics } from "@core/analytics/actions"
import { events } from "@core/analytics/events"

export interface IPerkProp {
  perk: TPerk
  onCloseClick?: () => void
}

const PerksSupport = ({ perkSupportUrl, perkPartnerName }: IPerkDetailsProp) => {
  const { t } = useTranslation()
  const partnerName = prismicH.asText(perkPartnerName)
  return perkSupportUrl ? (
    <Text
      sx={{ background: theme.colors.ui7, color: theme.colors.ui2, marginTop: "5px" }}
      tag={"label"}
    >
      {t("perks.modal.support.text", { partnerName: partnerName })}
      <a target="blank" href={perkSupportUrl} style={{ color: theme.colors.ui2 }}>
        {t("perks.modal.support.linkWord")}
      </a>
      .
    </Text>
  ) : null
}

const PerksClose = ({ perk, onCloseClick }: IPerkProp) => {
  const onImageClick = () => {
    pushAnalyticsOnModalClose(perk)
    if (onCloseClick) onCloseClick()
  }
  return (
    <Image
      src={images.icons.closeBlack}
      sx={{
        cursor: "pointer",
        height: 18,
        width: 18,
        position: "absolute",
        top: 20,
        right: 24,
      }}
      onClick={onImageClick}
    />
  )
}

export const RightSide = ({ perk, onCloseClick }: IPerkProp) => {
  const {
    node: { perk_header, partner_name, partner_category },
  } = perk
  const perkHeader = prismicH.asText(perk_header)
  const { t } = useTranslation()
  return (
    <>
      <FlexColumnLayout sx={{ marginLeft: 64, marginTop: 57, marginRight: 55 }}>
        <PerksPartnerText
          sx={{
            color: theme.colors.greenPerks,
            marginBottom: "8px",
            fontSize: "24px",
          }}
          tag={"body-large-bold"}
          text={perkHeader}
        />

        <PerksPartnerText
          sx={{ ...perksStyles.category, background: theme.colors.ui7 }}
          tag={"h5"}
          text={t("perks.modal.claim")}
        />

        <PerksPartnerText
          tag={"body-large"}
          sx={{
            color: theme.colors.ui1,
            marginBottom: 42,
          }}
          text={perk.node.perk_how_to_claim}
        />

        <Button
          type={ButtonTypeEnum.PRIMARY_BLUE}
          onClick={() => {
            Analytics.track(events.perks.partnerVisitSite, {
              partner_name: prismicH.asText(partner_name),
              partner_category,
            })
            window.open(perk.node.partner_link.url)
            onCloseClick && onCloseClick()
          }}
          iconSrc={images.icons.externalLink}
        >
          <PrismicText field={perk.node.perk_cta_text} />
        </Button>

        <PerksSupport
          perkPartnerName={partner_name}
          perkSupportUrl={perk.node.partner_support_link?.url}
        />
      </FlexColumnLayout>

      <PerksClose perk={perk} onCloseClick={onCloseClick} />
    </>
  )
}
