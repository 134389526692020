import React from "react"
import { ListItem } from "@components/primitive/list-item/list-item"
import { Box, Image } from "rebass"
import { FlexRowLayout, FlexColumnLayout } from "@layouts/flex"
import { Text } from "@components/primitive/text/text"
import { theme } from "@layouts/theme"
import { Button, ButtonTypeEnum, IButtonProps } from "@components/primitive/button/button"
import { AnimatePresence } from "@components/animations/animate-presence/animation-presence"
import { images } from "@assets/images/images"
import { useTranslation } from "react-i18next"

interface SubAccountRulesListItemProps {
  children?: React.ReactNode
  iconSrc: string
  iconColor: string
  title?: string
  subtitle?: string
  type: "set" | "edit"
  button: IButtonProps
  isExpanded: boolean
  setIsExpanded: (isExpanded: boolean) => void
  onDeleteClick?: () => void
}
export const SubAccountRulesListItem = ({
  iconColor,
  iconSrc,
  title,
  children,
  type,
  subtitle,
  button,
  isExpanded,
  setIsExpanded,
  onDeleteClick,
}: SubAccountRulesListItemProps) => {
  const { t } = useTranslation()
  return (
    <Box sx={{ borderBottom: `solid 1px ${theme.colors.ui5}` }}>
      <ListItem
        listItemHeight="medium"
        sx={{
          justifyContent: "space-between",
          paddingX: theme.spacing.listItemPadding,
          borderBottomColor: "transparent",
          cursor: "default",
        }}
        hasHoverState={false}
      >
        <FlexRowLayout sx={{ alignItems: "center" }}>
          <FlexRowLayout
            sx={{
              height: 28,
              width: 28,
              backgroundColor: iconColor,
              borderRadius: "4px",
              justifyContent: "center",
              alignItems: "center",
              marginRight: 20,
            }}
          >
            <Image src={iconSrc} />
          </FlexRowLayout>
          <FlexColumnLayout sx={{ paddingRight: theme.spacing.listItemPadding }}>
            {title && (
              <Text textColor={subtitle ? theme.colors.ui2 : theme.colors.ui1} tag={"body-small"}>
                {title}
              </Text>
            )}
            {subtitle && (
              <Text textColor={theme.colors.ui1} tag={"body-small"}>
                {subtitle}
              </Text>
            )}
          </FlexColumnLayout>
        </FlexRowLayout>
        {!isExpanded && (
          <Button
            onClick={() => setIsExpanded(true)}
            iconSrc={type === "set" ? images.icons.add : images.icons.editUi2}
            type={ButtonTypeEnum.TERTIARY_SHORT}
          >
            {type === "set" ? t("accounts.edit.saveRule") : t("accounts.edit.editRule")}
          </Button>
        )}
      </ListItem>
      <AnimatePresence animation="expand">
        {isExpanded && (
          <FlexColumnLayout sx={{ paddingLeft: 64 }}>
            {children}
            <FlexRowLayout
              sx={{ marginBottom: 30, marginTop: "5px", justifyContent: "space-between" }}
            >
              <FlexRowLayout>
                <Button type={ButtonTypeEnum.SECONDARY} onClick={() => setIsExpanded(false)}>
                  {t("buttons.cancel")}
                </Button>
                <Button
                  sx={{ marginLeft: theme.spacing.listItemPadding }}
                  type={ButtonTypeEnum.PRIMARY_BLUE}
                  {...button}
                />
              </FlexRowLayout>
              {type === "edit" && onDeleteClick && (
                <Button
                  type={ButtonTypeEnum.TERTIARY}
                  sx={{ marginLeft: theme.spacing.listItemPadding }}
                  onClick={onDeleteClick}
                >
                  {t("accounts.edit.clearRule")}
                </Button>
              )}
            </FlexRowLayout>
          </FlexColumnLayout>
        )}
      </AnimatePresence>
    </Box>
  )
}
