import React, { useState } from "react"
import { TSubscriptionCancelScreenNames } from "../subscriptions-cancel-modal"
import { ChangePlanDetailsLayout } from "@features/subscriptions/shared/components/change-plan-details-layout"
import { useSubscriptionsData } from "@features/subscriptions/shared/hooks/use-subscriptions-data"
import { useTranslation } from "react-i18next"
import { ButtonTypeEnum, IButtonProps } from "@components/primitive/button/button"
import { ErrorScreen } from "@components/composite/error-screen/error-screen"
import { BillingDetailsBox } from "@features/subscriptions/shared/components/billing-details-box"
import accounting from "accounting"
import { getPlanMonthlyPrice } from "@features/subscriptions/utils"
import { SubscriptionPlansDataQuery, useSubscriptionCancelMutation } from "@generated/graphql"
import { useAppSelector } from "@core/redux/utils"
import { selectActiveBusinessID } from "@core/active-business/redux/selectors"
import { NoticeBox } from "@components/primitive/notice-box/notice-box"
import { SUBSCRIPTION_DATA } from "@features/subscriptions/operations.gql"
import { Analytics } from "@core/analytics/actions"
import { events } from "@core/analytics/events"
import { getFormattedFullDate } from "@utils/dates"

export const ConfirmCancellationScreen = ({
  setCurrentModalScreen,
  closeModal,
}: {
  setCurrentModalScreen: (screen: TSubscriptionCancelScreenNames) => void
  closeModal: () => void
}) => {
  const { t } = useTranslation()
  const businessId = useAppSelector(selectActiveBusinessID)
  const [errorMessage, setErrorMessage] = useState<string | undefined>()

  const { subscription, error: queryError, loading: queryLoading } = useSubscriptionsData()

  const [cancelSubscription, { loading: mutationLoading }] = useSubscriptionCancelMutation({
    variables: {
      input: {
        businessId,
      },
    },
    update: (cache, { data }) => {
      const subscription = data?.subscriptionCancel?.subscription
      if (subscription) {
        cache.modify({
          fields: {
            subscription: () =>
              cache.writeQuery<SubscriptionPlansDataQuery>({
                query: SUBSCRIPTION_DATA,
                data: { subscription },
              }),
          },
        })
      }
    },
    onError: () => setErrorMessage(copy.mutationError),
    onCompleted: () => setCurrentModalScreen("CANCELLATION_SUCCESS"),
  })

  if (queryLoading) return null

  if (!subscription?.plan || !subscription?.nextBillingDate || queryError) {
    return <ErrorScreen size="large" />
  }

  const planName = subscription.plan.name
  const planTitle = subscription.plan.title
  const planShortTitle = subscription.plan.shortTitle
  const nextBillingDate = getFormattedFullDate(subscription.nextBillingDate)
  const monthlyPrice = getPlanMonthlyPrice(subscription.plan)

  const copy = {
    heading: t("subscriptions.cancel.confirm.title", { planTitle }),
    subtitle: t("subscriptions.cancel.confirm.subtitle", { planShortTitle, nextBillingDate }),
    confirmButtonText: t("subscriptions.cancel.confirm.confirmButton", { planShortTitle }),
    goBackButtonText: t("subscriptions.cancel.confirm.goBackButton", { planShortTitle }),
    mutationError: t("subscriptions.cancel.confirm.error", { planTitle }),
  }

  const buttons: IButtonProps[] = [
    {
      type: ButtonTypeEnum.TERTIARY,
      children: copy.goBackButtonText,
      onClick: () => {
        Analytics.track(events.subscriptions.continueWithCurrentPlan, {
          planName,
        })
        closeModal()
      },
    },
    {
      type: ButtonTypeEnum.PRIMARY_BLACK,
      children: copy.confirmButtonText,
      onClick: () => {
        if (errorMessage) setErrorMessage(undefined)
        Analytics.track(events.subscriptions.confirmSubscriptionCancellation, {
          planName,
        })
        cancelSubscription()
      },
      isLoading: mutationLoading,
    },
  ]

  const planDetails = [
    {
      label: t("subscriptions.billingDetails.labels.monthlyFee"),
      value: accounting.formatMoney(monthlyPrice?.toString() || 0),
    },
    { label: t("subscriptions.billingDetails.labels.finalBillingDate"), value: nextBillingDate },
    {
      label: t("subscriptions.billingDetails.labels.fromAccount"),
      value: t("subscriptions.billingDetails.labels.depositAccount"),
    },
  ]

  return (
    <ChangePlanDetailsLayout heading={copy.heading} subtitle={copy.subtitle} buttons={buttons}>
      {errorMessage && <NoticeBox level="error" text={errorMessage} containerSx={{ mb: 0 }} />}
      <BillingDetailsBox items={planDetails} />
    </ChangePlanDetailsLayout>
  )
}
