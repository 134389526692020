import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Text } from "@components/primitive/text/text"
import { NoticeBox } from "@components/primitive/notice-box/notice-box"
import { Button, ButtonTypeEnum } from "@components/primitive/button/button"
import { useMarkInvoiceAccountsPayableAsArchivedMutation } from "@generated/graphql"
import { useAppSelector } from "@core/redux/utils"
import { selectActiveBusinessID } from "@core/active-business/redux/selectors"
import { pollWithMaxRetries } from "@utils/poll-with-max-retries"
import { GET_INVOICE } from "@features/invoice-payments/operations.gql"

interface IAutoExtractedInvoiceNoticeProps {
  onArchive: () => void
}

export const AutoExtractedInvoiceNotice = (props: IAutoExtractedInvoiceNoticeProps) => {
  const { t } = useTranslation()
  const businessId = useAppSelector(selectActiveBusinessID)
  const invoiceId = useAppSelector((state) => state.invoicePayments.invoiceDetails?.invoiceId)
  const [archiveInvoice, { error, loading: isArchiveMutationLoading }] =
    useMarkInvoiceAccountsPayableAsArchivedMutation()

  const [isPolling, setIsPolling] = useState(false)

  const handleArchiveInvoiceClick = async () => {
    if (!invoiceId) return
    const result = await archiveInvoice({ variables: { businessId, invoiceId } })
    if (!result.errors) {
      setIsPolling(true)
      await pollWithMaxRetries([
        {
          query: GET_INVOICE,
          variables: { businessId, invoiceId },
        },
      ])
      setIsPolling(false)
      props.onArchive()
    }
  }

  return (
    <>
      <NoticeBox
        level="warning"
        showIcon={true}
        content={
          <>
            <Text tag="body-small-bold">
              {t("invoicePayments.modal.invoiceDetails.autoExtractedInvoiceNotice.heading")}
            </Text>
            <Text tag="body-small">
              {t("invoicePayments.modal.invoiceDetails.autoExtractedInvoiceNotice.body")}
            </Text>
            <Button
              type={ButtonTypeEnum.TERTIARY_ALT}
              onClick={handleArchiveInvoiceClick}
              sx={{ mt: "1em", height: "26px", px: "8px", backgroundColor: "transparent" }}
              textSx={{
                fontSize: 11,
                fontWeight: "500",
                letterSpacing: 1,
                textTransform: "uppercase",
              }}
              isLoading={isArchiveMutationLoading || isPolling}
            >
              {t("invoicePayments.modal.invoiceDetails.autoExtractedInvoiceNotice.archiveInvoice")}
            </Button>
          </>
        }
      />
      {Boolean(error) && <NoticeBox level="error" text={t("errors.generic")} />}
    </>
  )
}
