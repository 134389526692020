import { BANCORP_NAME } from "@utils/constants"

export const topUp = {
  sideBar: {
    bankAccountInfo: {
      title: "Bank Account Numbers",
      subtitle: "Use these bank account numbers to add funds to your NorthOne Account.",
    },
  },
  landing: {
    title: "Add funds to start banking, {{firstName}}",
    subtitle: "Get unlimited access to NorthOne’s ",
    bankingFeatures: "banking features ",
    subtitle2: "when you add funds to your account.",
    button: "Add funds",
  },
  awaitingFunds: {
    title: "Waiting for your funds to arrive",
    subtitle:
      "Once your funds are received, you’ll get unlimited access to all of NorthOne’s banking features. If you have questions about your transfer, please contact the bank or service you're sending funds from. ",
  },
  addFunds: {
    options: {
      title: "How do you want to add funds to your account?",
      wire: {
        title: "Get wire information³",
        subtitle: "See your routing & account numbers",
        note: "³$20 fee for receiving a domestic wire transfer",
      },
      ach: {
        title: "Get ACH information",
        subtitle: "See your routing & account numbers",
      },
      thirdParty: {
        title: "Set up payment source",
        subtitle: "Connect to third-party app",
      },
      checkDeposit: {
        title: "Deposit a check",
        subtitle: "Get a link to the mobile app",
      },
      atm: {
        title: "Add cash²",
        subtitle: "Load cash at Green Dot locations",
        note: "²Subject to terms and conditions of Green Dot Corporation.",
      },
      legal: `Banking services provided by ${BANCORP_NAME}.`,
    },
    back: "Back to options",
    check: {
      title: "Use the NorthOne mobile app to deposit a check",
      fundedSubtitle: "*Enter your U.S. phone number to send a link to the app*",
      fundedButton: "Back to options",
      toastText: "Download link sent to your phone",
      subText:
        "You’ll receive a link to the __Apple App Store__ or __Google Play Store__ to download the NorthOne app. Messaging and data rates may apply.",
      linkingServiceInitFailure:
        "Visit the __Apple App Store__ or __Google Play Store__ to download the NorthOne mobile app.",
      smsMessageSendFailure:
        "There was a technical problem. Download the app via the __App Store__ or __Google Play__.",
    },
    atm: {
      title: "Add funds at an ATM",
      subtitle:
        "Use the NorthOne mobile app to find your nearest NorthOne-network ATM.\n\nYou can use your NorthOne debit card to deposit cash or checks at more than 300,000 ATMs across America, without incurring any fees or surcharges.",
      fundedSubtitle:
        "Use your bank account numbers to set up an ACH transfer from another bank. Contact the bank you are transferring money from if you need help setting up an ACH transfer.",
    },
    atmDepositCash: {
      title: "Add cash at a Green Dot location",
      subtitle:
        "Use your NorthOne debit card to add cash at over 90,000 Green Dot service locations across America.",
      step1: "Click *Find a location*.",
      step2: "Click *Filters*.",
      step3: "Select *Reload with cash*.",
      step4: "Enter your address or allow Green Dot to access to your location.",
      step5: "View location information either in the map or by tapping on List.",
      button: "Find a location",
      infoBanner: "A fee may be charged by the Green Dot location.",
    },
    ach: {
      title: "Add funds by ACH transfer",
      subtitle:
        "Use your NorthOne Account routing and account numbers to set up an ACH transfer from another bank. Contact the bank you are transferring money from if you need help setting up an ACH transfer.",
    },
    wire: {
      title: "Add funds by wire transfer",
      subtitle:
        "Use your NorthOne Account routing and account numbers to set up a wire transfer from another bank. Contact the bank you are wiring money from if you need help setting up a wire transfer.\n\nA {{wireFee}} fee applies to receiving and sending a wire transfer.",
    },
    thirdParty: {
      title: "Set up a payment source",
      subtitle:
        "Connect to platforms like Paypal and Square to add funds to your account. Enter your routing and account numbers on the platform to set up a transfer. Contact the service you are transferring money from if you need support.",
      note: "All trademarks and brand names belong to their respective owners. Use of these trademarks and brand names do not represent endorsement by or association with this card program.",
    },
    linked: {
      title: "Connect an external bank account",
      subtitle:
        "Log in directly to another bank to securely transfer funds from an external checking or savings account.",
      button: "Connect an external bank account",
      create: {
        title: "Select an account to connect with NorthOne",
        subtitle: "You'll select the amount to deposit in the next step.",
        button: "Connect account",
      },
      amount: {
        title: "Add funds from your external account",
        accountTitle: "Your external bank account",
        amountTitle: "Amount to deposit",
        amountSubtitle: "Add $50 or more to get unlimited access to NorthOne's banking features.",
        button: "Add funds",
      },
      success: {
        heading: "Funding successful!",
        title: "{{amount}} will be deposited in your NorthOne Account",
        subtitle:
          "External bank account funds usually take one business day to arrive. You’ll receive an email once they do.",
      },
    },
    accountDetails: {
      heading: "Your banking account numbers",
      accountNumber: "Account number",
      routingNumber: "Routing number",
      bankAddress: "Bank address",
      bankName: "Bank name",
    },
    button: "I've wired $50 or more",
  },
}
