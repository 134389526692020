import React from "react"
import { SxStyleProp, Image } from "rebass"
import { OverflowListItem } from "../overflow/overflow-list-item"
import { Text } from "@components/primitive/text/text"
import { theme } from "@layouts/theme"
import { images } from "@assets/images/images"
import { ListItem } from "@components/primitive/list-item/list-item"
import { FlexRowLayout } from "@layouts/flex"

export interface INewEntityListItemProps {
  sx?: SxStyleProp
  disabled?: boolean
  text: string
  onClick: () => void
  isOverflow?: boolean
}

export const AddNewEntityListItem: React.FC<INewEntityListItemProps> = ({
  sx,
  disabled,
  text,
  onClick,
  isOverflow = true,
}) => {
  const content = (
    <FlexRowLayout sx={{ width: "100%", alignItems: "center", justifyContent: "space-between" }}>
      <FlexRowLayout sx={{ alignItems: "center" }}>
        <Image src={images.icons.new} />
        <Text
          sx={{ marginLeft: theme.spacing.listItemPadding }}
          tag={"body-small"}
          textColor={disabled ? theme.colors.ui2 : undefined}
        >
          {text}
        </Text>
      </FlexRowLayout>
      <Image src={images.icons.arrow} />
    </FlexRowLayout>
  )
  return isOverflow ? (
    <OverflowListItem onClick={onClick} sx={sx}>
      {content}
    </OverflowListItem>
  ) : (
    <ListItem onClick={onClick} sx={{ paddingX: theme.spacing.listItemPadding, height: 63, ...sx }}>
      {content}
    </ListItem>
  )
}
