import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { v4 as uuid } from "uuid"
import { events } from "@core/analytics/events"
import { useAppSelector } from "@core/redux/utils"
import { selectActiveBusinessID } from "@core/active-business/redux/selectors"
import { client } from "@core/apollo/apollo-client"
import { TRANSACTIONS_LIST } from "@components/composite/transaction-list/operations.gql"
import { mergeTransactionGroups } from "@components/composite/transaction-list/utils"
import { moveMoneyActions } from "@features/move-money/redux/actions"
import { MoveMoneyConfirmCard } from "@features/move-money/shared/confirm-card"
import { selectDomesticWireGQLVariables } from "@features/move-money/redux/selectors"
import { DomesticWireScreensEnum } from "@features/move-money/router/domestic-wire"
import { WIRE_CUTOFF } from "@features/move-money/utils/i18n-translations"
import { refreshBusinessAccountInfo } from "@utils/poll-with-max-retries"
import {
  useCapabilitiesAndAvailableBalanceQuery,
  useDomesticWireCreateMutation,
} from "@generated/graphql"
import {
  QueryTransactionsListArgs,
  TransactionsListQuery,
  TransactionTypeEnum,
} from "@generated/graphql"
import { translate } from "@i18n/i18n"
import { wireCutOffTimeInLocalTime } from "../utils/helpers"
import { getFee } from "@features/move-money/utils/fee.utils"
import { LoadingPanel } from "@components/extended/panel/loading-panel"
import { ErrorPanel } from "@components/extended/panel/error-panel"

export const DomesticWireConfirmCard: React.FC = () => {
  const [idempotencyKey] = useState(uuid())
  const dispatch = useDispatch()
  const [hasError, setHasError] = useState(false)
  const businessId: string = useAppSelector(selectActiveBusinessID)
  const gqlVariables = useAppSelector(selectDomesticWireGQLVariables(idempotencyKey))

  const {
    data: capabilitiesData,
    error: capabilitiesError,
    loading: capabilitiesLoading,
    refetch: capabilitiesRefetch,
  } = useCapabilitiesAndAvailableBalanceQuery({
    variables: { businessId },
    notifyOnNetworkStatusChange: true,
  })

  const [createDomesticWire, { loading }] = useDomesticWireCreateMutation({
    onCompleted: async () => {
      await refreshTxnListsAfterWireSend({
        businessId,
        sourceSubAccountId: gqlVariables.data.subAccountId,
      })

      if (feeAmount) {
        await refreshBusinessAccountInfo({ businessId, feeAmount })
      }

      dispatch(moveMoneyActions.navigateNext(DomesticWireScreensEnum.DOMESTIC_WIRE_CONFIRM_NOTICE))
      dispatch(moveMoneyActions.clearEditingRecipient())
    },
    onError: () => setHasError(true),
  })

  const feeAmount = getFee(
    capabilitiesData?.business?.bankAccounts[0].capabilities?.domesticWire.fee,
  )
  if (capabilitiesLoading) {
    return <LoadingPanel iconDimension={40} />
  }
  if (capabilitiesError || !feeAmount) {
    return <ErrorPanel retry={capabilitiesRefetch} />
  }

  const refreshTxnListsAfterWireSend = async ({
    businessId,
    sourceSubAccountId,
  }: {
    businessId: string
    sourceSubAccountId?: string
  }) => {
    await Promise.all([
      refreshTxnListAfterWireSend({ businessId }),
      ...(sourceSubAccountId
        ? [
            refreshTxnListAfterWireSend({
              businessId,
              filters: { subAccountId: sourceSubAccountId },
            }),
          ]
        : []),
    ])
  }

  const refreshTxnListAfterWireSend = async (
    txnVariables: QueryTransactionsListArgs,
    maxRetryCount = 100,
    foundWire = false,
    foundFee = false,
  ) => {
    if (!maxRetryCount) {
      return
    }
    const queryOptions = { query: TRANSACTIONS_LIST, variables: txnVariables }
    const cacheResult =
      client.readQuery<TransactionsListQuery, QueryTransactionsListArgs>(queryOptions)
    if (!cacheResult) {
      return
    }
    try {
      const networkResult = await client.query<TransactionsListQuery, QueryTransactionsListArgs>({
        ...queryOptions,
        fetchPolicy: "no-cache",
      })

      if (cacheResult?.transactionsList && networkResult.data.transactionsList) {
        const oldTxns = cacheResult.transactionsList.transactionListItems
        const newTxns = networkResult.data.transactionsList.transactionListItems

        for (const txn of newTxns) {
          const f = oldTxns.findIndex((a) => a.id === txn.id)
          if (f === -1) {
            if (!foundWire && txn.transactionType === TransactionTypeEnum.DOMESTIC_WIRE) {
              foundWire = true
              break
            }
            if (!foundFee && txn.transactionType === TransactionTypeEnum.FEE) {
              foundFee = true
              break
            }
          }
        }

        if (foundWire && foundFee) {
          client.writeQuery<TransactionsListQuery, QueryTransactionsListArgs>({
            ...queryOptions,
            data: {
              ...cacheResult,
              transactionsList: {
                __typename: "TransactionsListResponse",
                pagination: {
                  cursor: cacheResult.transactionsList.pagination?.cursor || null,
                  __typename: "TransactionsPagination",
                },
                transactionListItems: mergeTransactionGroups(networkResult.data, cacheResult),
              },
            },
          })
          dispatch(moveMoneyActions.setWireSendCompleted(true))
          return
        }
      }
    } catch {
      // swallow errors
    }
    setTimeout(
      () => refreshTxnListAfterWireSend(txnVariables, maxRetryCount - 1, foundWire, foundFee),
      300,
    )
  }

  return (
    <MoveMoneyConfirmCard
      gqlVariables={gqlVariables}
      createMutation={createDomesticWire}
      isLoading={loading}
      hasError={hasError}
      sendPaymentEvent={events.moveMoney.domesticWire.sendPayment}
      discardPaymentEvent={events.moveMoney.domesticWire.sendPaymentDiscard}
      discardCancelEvent={events.moveMoney.domesticWire.discardCancel}
      discardConfirmEvent={events.moveMoney.domesticWire.discardConfirm}
      note={translate(WIRE_CUTOFF, { cutOffTime: wireCutOffTimeInLocalTime() })}
    />
  )
}
