import env, { IOptionalVariable } from "env-var"
import pkg from "../../package.json"

export const RELEASE = pkg ? `${pkg.name}@${pkg.version}` : null

const stage = env
  .get("REACT_APP_ENVIRONMENT")
  .default("prod")
  .required()
  .asEnum(["dev", "prod", "staging"])

console.log("stage", stage)

export const isProduction = stage === "prod"

/**
 * Wrapper function for env-var to inject default
 * values only for development environments
 *
 * @param variableName Variable name to get from process.env
 * @param defaultValue Value to use as default during development
 * @returns The resulting env-var chain
 */
const envWithDevDefault = (variableName: string, defaultValue: string): IOptionalVariable => {
  const value = env.get(variableName)
  return isProduction ? value : value.default(defaultValue)
}

export const config = {
  stage,
  env: env
    .get("NODE_ENV")
    .default("development")
    .required()
    .asEnum(["production", "development", "test"]),
  graphQlUri: envWithDevDefault(
    "REACT_APP_GQL_URI",
    "https://dobby-graphql.dev.aws.northoneapps.com/",
  )
    .required()
    .asString(),
  segmentWriteKey: envWithDevDefault(
    "REACT_APP_SEGMENT_KEY",
    "3LkrTdUJEbwJyrmXnbf7zQmXiHjew6ky",
  ).asString(),
  sentryDsn: env.get("REACT_APP_SENTRY_DSN").asString(),
  auth0: {
    domain: envWithDevDefault("REACT_APP_AUTH0_DOMAIN", "auth.northoneapps.com")
      .required()
      .asString(),
    clientId: envWithDevDefault("REACT_APP_AUTH0_CLIENT_ID", "vuL1nZcHuSs61jCkcqrkh6fRjnOgtKNx")
      .required()
      .asString(),
  },
  plaidPublicKey: env.get("REACT_APP_PLAID_PUBLIC_KEY").default("").asString(),
  intercomAppId: env.get("REACT_APP_INTERCOM_APP_ID").asString(),
  logoutOnIdleTime: env
    .get("REACT_APP_LOGOUT_ON_IDLE_TIME")
    .default(1000 * 60 * 15)
    .required()
    .asIntPositive(),
  desktopOnboardingUrl: envWithDevDefault(
    "REACT_APP_DESKTOP_ONBOARDING_URL",
    "https://dumpling-onboarding.dev.aws.northoneapps.com/",
  )
    .required()
    .asString(),
  // Disable window resize - Allows you to not lose your place in the dev console
  disableWindowSizeRestriction: env
    .get("REACT_APP_DISABLE_WINDOW_SIZE_RESTRICTION")
    .default("false")
    .asBool(),
  optimizelySDK: envWithDevDefault(
    "REACT_APP_OPTIMIZELY_SDK_KEY",
    "DQp7L19gzfmDxWKhnZtNaT",
  ).asString(),
  satismeterWriteKey: envWithDevDefault(
    "REACT_APP_SATISMETER_WRITE_KEY",
    "vxCYgn7IkqSR7C88",
  ).asString(),
  branchSDK: env.get("REACT_APP_BRANCH_SDK_KEY").asString(),
  reCAPTCHASiteKey: env.get("REACT_APP_RECAPTCHA_SITE_KEY").required().asString(),
  perksRepositoryName: env.get("REACT_APP_PERKS_PRISMIC_REPOSITORY_NAME").required().asString(),
  perksPrismicUri: env.get("REACT_APP_PERKS_PRISMIC_URI").required().asString(),
  perksPrismicToken: env.get("REACT_APP_PERKS_PRISMIC_API_TOKEN").required().asString(),
  sharedRepoPrismicUri: env.get("REACT_APP_SHARED_REPO_PRISMIC_URI").required().asString(),
  sharedRepoPrismicToken: env.get("REACT_APP_SHARED_REPO_PRISMIC_API_TOKEN").required().asString(),
  campaignsPrismicUri: env.get("REACT_APP_CAMPAIGNS_PRISMIC_URI").required().asString(),
  campaignsPrismicToken: env.get("REACT_APP_CAMPAIGNS_PRISMIC_API_TOKEN").required().asString(),
  rutterPublicKey: env.get("REACT_APP_RUTTER_PUBLIC_KEY").required().asString(),
  kanmonConnectScriptUrl: envWithDevDefault(
    "REACT_APP_KANMON_CONNECT_SCRIPT_URL",
    "https://cdn.sandbox.kanmon.dev/scripts/v2/kanmon-connect.js",
  )
    .required()
    .asString(),
}
