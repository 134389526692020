import React from "react"
import { useTranslation } from "react-i18next"
import { SxStyleProp } from "rebass"
import { FlexColumnLayout, Modal, Text } from "@northone/ui"

import { Tabs, TTabs } from "@components/composite/tabs/tabs"
import { AddNewCorporation } from "./new-corporation/add-new-corporation"
import { AddOtherVendor } from "./other-vendor/add-other-vendor"

interface AddNewContactModalProps {
  isOpen: boolean
  closeOverlay: () => void
  onContactCreated: (contactId: string) => Promise<void>
}

export function AddNewContactModal({
  isOpen,
  closeOverlay,
  onContactCreated,
}: AddNewContactModalProps) {
  const { t } = useTranslation()

  const tabs: TTabs[] = [
    {
      title: t(i18nKeyPaths.addNewCorporationTitle),
      content: (
        <AddNewCorporation onContactCreated={onContactCreated} closeOverlay={closeOverlay} />
      ),
    },
    {
      title: t(i18nKeyPaths.addOtherVendorTitle),
      content: <AddOtherVendor closeOverlay={closeOverlay} onContactCreated={onContactCreated} />,
    },
  ]

  return (
    <Modal isOpen={isOpen} onOutsideClick={closeOverlay} zIndex={4}>
      <FlexColumnLayout sx={styles.modalWidth}>
        <Text tag="h3Serif">{t(i18nKeyPaths.addNewContactTitle)}</Text>
        <Tabs tabs={tabs} />
      </FlexColumnLayout>
    </Modal>
  )
}

const styles: Record<string, SxStyleProp> = {
  modalWidth: { width: "518px" },
}

const i18nKeyPaths = {
  addNewContactTitle: "invoicePayments.modal.invoiceDetails.addNewContact.modalTitle",
  addNewCorporationTitle: "invoicePayments.modal.invoiceDetails.addNewContact.corporation.title",
  addOtherVendorTitle: "invoicePayments.modal.invoiceDetails.addNewContact.otherVendor.title",
}
