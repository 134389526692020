import React from "react"
import { useTranslation } from "react-i18next"
import { Box } from "rebass"

import { InfoBlock } from "@northone/ui"
import { TransactionsListFiltersInput } from "@generated/graphql"

interface ITransactionListNoSearchResultProps {
  filters: TransactionsListFiltersInput
}

export const TransactionListNoSearchResult = (props: ITransactionListNoSearchResultProps) => {
  const { t } = useTranslation()

  const { filters } = props

  const searchedByText = Boolean(filters.text && Object.keys(filters).length === 1)
  const searchedByFilters = Boolean(Object.keys(filters).length && !filters.text)

  const getSubtitle = (searchedByText: boolean, searchedByFilters: boolean) => {
    if (searchedByText) return t("dashboard.noResults.searchSubtitle")
    if (searchedByFilters) return t("dashboard.noResults.filterSubtitle")
  }

  return (
    <Box sx={{ marginTop: 70 }}>
      <InfoBlock
        // TO DO - illustration pending
        title={t("dashboard.noResults.title")}
        subtitle={getSubtitle(searchedByText, searchedByFilters) || ""}
        buttons={[]}
        centered
        containerSx={{ maxWidth: "none", height: "50vh" }}
      />
    </Box>
  )
}
