import { CardSetupScreensEnum } from "../card-setup-modal/card-setup-router"
import { CardsActionEnum, TCardsActions } from "./actions"

export interface ICardsState {
  addVirtualCardModalOpen: boolean
  currentCardId: string
  cardAccountId: string
  cardSetupModalOpen: boolean
  cardSetupModalScreen: CardSetupScreensEnum
  cardNotArrivedModalOpen: boolean
  pinSetStatusUpdatedAt: string
  isCardActivationPinSetProcessing: boolean
  pinSetPending: boolean
  currentCardIsLoading: boolean
  currentCardErrorMessage: string
}

export const initialCardsState: ICardsState = {
  addVirtualCardModalOpen: false,
  currentCardId: "",
  cardAccountId: "",
  cardSetupModalOpen: false,
  cardSetupModalScreen: CardSetupScreensEnum.CARD_SETUP_ACTIVATE,
  cardNotArrivedModalOpen: false,
  pinSetStatusUpdatedAt: "",
  isCardActivationPinSetProcessing: false,
  pinSetPending: false,
  currentCardIsLoading: false,
  currentCardErrorMessage: "",
}

export const cardsReducer = (
  state: ICardsState = initialCardsState,
  action: TCardsActions,
): ICardsState => {
  switch (action.type) {
    case CardsActionEnum.SET_CURRENT_CARD_ID:
      return {
        ...state,
        currentCardId: action.payload,
      }
    case CardsActionEnum.SET_CARD_SETUP_MODAL_OPEN:
      return {
        ...state,
        cardSetupModalOpen: action.payload,
      }
    case CardsActionEnum.SET_CARD_SETUP_MODAL_SCREEN:
      return {
        ...state,
        cardSetupModalScreen: action.payload,
      }
    case CardsActionEnum.SET_ACCOUNT_ID:
      return {
        ...state,
        cardAccountId: action.payload,
      }
    case CardsActionEnum.SET_CARD_NOT_ARRIVED_MODAL_OPEN:
      return {
        ...state,
        cardNotArrivedModalOpen: action.payload,
      }
    case CardsActionEnum.SET_PIN_SET_STATUS_UPDATE_AT:
      return {
        ...state,
        pinSetStatusUpdatedAt: action.payload,
      }
    case CardsActionEnum.SET_IS_CARD_ACTIVATION_PIN_SET_PROCESSING:
      return {
        ...state,
        isCardActivationPinSetProcessing: action.payload,
      }
    case CardsActionEnum.SET_PIN_SET_PENDING:
      return {
        ...state,
        pinSetPending: action.payload,
      }
    case CardsActionEnum.SET_ADD_VIRTUAL_CARD_MODAL_OPEN:
      return {
        ...state,
        addVirtualCardModalOpen: action.payload,
      }
    case CardsActionEnum.SET_CURRENT_CARD_IS_LOADING:
      return {
        ...state,
        currentCardIsLoading: action.payload,
      }
    case CardsActionEnum.SET_CURRENT_CARD_ERROR_MESSAGE:
      return {
        ...state,
        currentCardErrorMessage: action.payload,
      }
    default:
      return state
  }
}
