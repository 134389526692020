import { teamBankingActions } from "@features/settings/team-banking/redux/actions"
import { Pill } from "@northone/ui"
import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { IRightColumnProps } from "./team-banking-list-item"
import { Text } from "@components/primitive/text/text"
import { Button, ButtonTypeEnum } from "@components/primitive/button/button"
import { images } from "@assets/images/images"
import { theme } from "@layouts/theme"
import { Analytics } from "@core/analytics/actions"
import { events } from "@core/analytics/events"

export const OwnerStatusSection = ({
  hasBeenInvited,
  onboardingCompleted,
  isPrimaryOwner,
  isHovering,
}: IRightColumnProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const onClick = () => {
    Analytics.track(events.settings.teamBanking.listItemInvite)
    dispatch(teamBankingActions.setIsModalOpen(true))
  }
  if (hasBeenInvited) {
    const { backgroundColor, i18nKeyPath, textColor } = getOwnerStatusPillProps({
      onboardingCompleted,
      isPrimaryOwner,
      isHovering,
    })
    return (
      <Pill sx={{ backgroundColor: backgroundColor, whiteSpace: "nowrap" }}>
        <Text tag="h5" textColor={textColor}>
          {t(i18nKeyPath)}
        </Text>
      </Pill>
    )
  } else
    return (
      <Button
        type={ButtonTypeEnum.PRIMARY_BLUE}
        sx={{ height: "26px", padding: "5px 8px" }}
        iconSrc={images.icons.addContact}
        textSx={{ fontSize: "10px", textTransform: "uppercase", letterSpacing: "1px" }}
        onClick={onClick}
      >
        {t("settings.pages.teamBanking.buttons.invite")}
      </Button>
    )
}
const getOwnerStatusPillProps = ({
  onboardingCompleted,
  isPrimaryOwner,
  isHovering,
}: {
  onboardingCompleted?: boolean
  isPrimaryOwner: boolean
  isHovering: boolean | null | undefined
}) => {
  return onboardingCompleted || isPrimaryOwner
    ? {
        backgroundColor: theme.colors.goldTint4,
        i18nKeyPath: "settings.pages.teamBanking.listItem.active",
        textColor: theme.colors.ui1,
      }
    : {
        backgroundColor: isHovering ? theme.colors.ui7 : theme.colors.ui5,
        i18nKeyPath: "settings.pages.teamBanking.listItem.inviteSent",
        textColor: theme.colors.ui3,
      }
}
