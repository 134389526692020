import React from "react"
import { SxStyleProp } from "rebass"
import { motion } from "framer-motion"
import { theme } from "@layouts/theme"
import { FlexRowLayout } from "@layouts/flex"

export interface ListItemProps {
  children?: React.ReactNode
  onClick?: () => void
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  sx?: SxStyleProp
  isActive?: boolean
  hasHoverState?: boolean
  disabled?: boolean
  listItemHeight?: TListItemHeight
}

export type TListItemHeight = "verySmall" | "small" | "medium" | "large"

/**
 * Defines a list item primitive - Must be extended to define a usable list-item
 */
export const ListItem = ({
  onClick,
  sx,
  children,
  isActive,
  hasHoverState = true,
  disabled,
  onMouseEnter,
  onMouseLeave,
  listItemHeight = "large",
}: ListItemProps) => {
  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <FlexRowLayout
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        sx={{
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: isActive ? theme.colors.gold10 : theme.colors.ui7,
          borderBottom: `solid 1px ${theme.colors.ui5}`,
          cursor: !disabled ? "pointer" : "default",
          height: theme.heights.listItems[listItemHeight],
          ":hover": {
            backgroundColor: hasHoverState
              ? isActive
                ? theme.colors.gold10
                : theme.colors.ui6
              : undefined,
          },
          ...sx,
        }}
        onClick={() => {
          if (onClick && !disabled) {
            onClick()
          }
        }}
        disabled={disabled}
      >
        {children}
      </FlexRowLayout>
    </motion.div>
  )
}
