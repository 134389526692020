export const events = {
  postOnboarding: {
    timerExpired: "WB Review Page Timer Expired",
    approvedCTA: "WB Approved Page Continue Tap",
    instantApproval: "WB Review Page Instant Approval",
  },
  transactions: {
    cardAuthPending: {
      transactionClick: "WB CardAuth TransactionItem Click",
      addNote: "WB CardAuth Note Click",
      supportClick: "WB CardAuth Support Click",
      share: "WB CardAuth Share Click",
    },
    cardAuthSettled: {
      transactionClick: "WB CardSettled TransactionItem Click",
      addNote: "WB CardSettled Note Click",
      supportClick: "WB CardSettled Support Click",
      share: "WB CardSettled Share Click",
    },
  },
  sidebar: {
    accountsOverview: "WB Dashboard Accounts Overview Click",
    moveMoney: "WB Dashboard Send Money Click",
    addFunds: "WB Dashboard Add Funds Click",
    applyForLending: "WB Dashboard Apply For Lending Click",
    schedule: "WB Dashboard Schedule Payments Click",
    sendInvoice: "WB Dashboard Send Invoice Click",
    businessBalances: "WB Dashboard Balance Click",
    accountsDetails: "WB Dashboard Account Details Show Click",
  },
  moveMoney: {
    paymentMethodSelection: {
      wire: "WB Send Money Domestic Wire Click",
      ach: "WB Send Money Domestic ACH Click",
      billPay: "WB Send Money Bill Pay Click",
      mailedCheck: "WB Send Money Mailed Check Wire Click",
      externalTransfer: "WB Send Money Transfer to Linked account Click",
    },
    domesticWire: {
      recipient: "WB Send Money Domestic Wire Recipient Click",
      recipientAdd: "WB Send Money Domestic Wire Add a new Recipient Click",
      recipientContinue: "WB Send Money Domestic Wire Recipient Continue Click",
      recipientDetails: "WB Send Money Domestic Wire Recipient Details Continue Click",
      amount: "WB Send Money Domestic Wire Amount Continue Click",
      subAccountSelection: "WB Send Money Domestic Wire Sub-account From Click",
      subAccount: "WB Send Money Domestic Wire Sub-account Continue Click",
      schedule: "WB Send Money Domestic Wire Schedule Continue Click",
      frequencySelect: "WB Send Money Domestic Wire Payment Schedule Click",
      sendPayment: "WB Send Money Domestic Wire Send Payment Click",
      sendPaymentDiscard: "WB Send Money Domestic Wire Discard Final Page Click",
      discardConfirm: "WB Send Money Domestic Wire Discard Pop-up Click",
      discardCancel: "WB Send Money Domestic Wire Cancel Pop-up Click",
      done: "WB Send Money Domestic Wire Done Click",
    },
    ach: {
      recipient: "WB Send Money ACH Recipient Click",
      recipientAdd: "WB Send Money ACH Add a new Recipient Click",
      recipientContinue: "WB Send Money ACH Recipient Continue Click",
      recipientDetails: "WB Send Money ACH Recipient Details Continue Click",
      amount: "WB Send Money ACH Amount Continue",
      subAccountSelection: "WB Send Money ACH Sub-account From Click",
      subAccount: "WB Send Money ACH Sub-account Continue Click",
      schedule: "WB Send Money ACH Schedule Continue Click",
      frequencySelect: "Send Money ACH Payment Schedule Click",
      sendPayment: "WB Send Money ACH Send Payment Click",
      sendPaymentDiscard: "WB Send Money ACH Discard Final Page Click",
      discardConfirm: "WB Send Money ACH Discard Pop-up Click",
      discardCancel: "WB Send Money ACH Cancel Pop-up Click",
      done: "WB Send Money ACH Done Click",
    },
    billPay: {
      recipient: "WB Send Money Bill Pay Payee Click",
      recipientAdd: "WB Send Money Bill Pay Add a new Payee Click",
      recipientContinue: "Wb Send Money Bill Pay Payee Continue Click",
      recipientSearchContinue: "WB Send Money Bill Pay Search New Payee Bar Click",
      recipientDetails: "WB Send Money Bill Pay Payee Details Continue Click",
      amount: "WB Send Money Bill Pay Amount Continue",
      subAccountSelection: "WB Send Money Bill Pay Sub-account From Click",
      subAccount: "WB Send Money Bill Pay Sub-account Continue Click",
      schedule: "WB Send Money Bill Pay schedule Continue Click",
      frequencySelect: "WB Send Money Bill Pay Payment Schedule Click",
      sendPayment: "WB Send Money Bill Pay Send Payment Click",
      sendPaymentDiscard: "WB Send Money Bill Pay Discard Final Page",
      discardConfirm: "WB Send Money Bill Pay Discard Pop-up Click",
      discardCancel: "WB Send Money Bill Pay Cancel Pop-up Click",
      done: "WB Send Money Bill Pay Done Click",
    },
    mailedCheck: {
      recipient: "WB Send Money Mail Check Recipient Click",
      recipientAdd: "WB Send Money Mail Check Add a new Recipient Click",
      recipientContinue: "WB Send Money Mail Check Recipient Continue Click",
      recipientDetails: "WB Send Money Mail Check Recipient Details Continue Click",
      amount: "WB Send Money Mail Check Amount Continue",
      subAccountSelection: "WB Send Money Mail Check Sub-account From Click",
      subAccount: "WB Send Money Mail Check Sub-account Continue Click",
      schedule: "WB Send Money Mail Check schedule Continue Click",
      frequencySelect: "WB Send Money Mail Check Payment Schedule Click",
      sendPayment: "WB Send Money Mail Check Send Payment Click",
      sendPaymentDiscard: "WB Send Money Mail Check Discard Final Page Click",
      discardConfirm: "WB Send Money Mail Check Discard Pop-up Click",
      discardCancel: "WB Send Money Mail Check Cancel Pop-up Click",
      done: "WB Send Money Mail Check Done Click",
    },
    externalTransfer: {
      fromAccount: "WB Send Money Linked Account Sub-Account From Click",
      fromAccountContinue: "WB Send Money Linked Acount Sub-Account Continue Click",
      toAccount: "WB Send Money Linked Account External Account To Click",
      addAccount: "WB Send Money Linked Account Add External Account Click",
      connectAccount: "WB Send Money External Account Link Click",
      amount: "WB Send Money Linked Account Amount Continue Click",
      schedule: "WB Send Money Linked Account Schedule Continue Click",
      frequencySelect: "WB Send Money Linked Account Payment Schedule Click",
      sendPayment: "WB Send Money Linked Account Send Payment Click",
      sendPaymentDiscard: "WB Send Money Linked Account Discard Final Page Click",
      discardConfirm: "WB Send Money Linked Account Discard Pop-up Click",
      discardCancel: "WB Send Money Linked Account Cancel Pop-up Click",
      done: "WB Send Money Linked Account Done Click",
    },
  },
  accounts: {
    overview: {
      checking: "WB Sub Accounts Checkings Click",
      subAccount: "WB Accounts Overview Sub-Account Click",
      subAccountAdd: "WB Sub Accounts Add a Sub-Account Click",
    },
    create: {
      name: "WB Sub Accounts Name Sub-Account Continue Click",
      initialTransferContinue: "WB Sub Accounts Initial Transfer Continue Click",
      initialTransferSkip: "WB Sub Accounts Skip for Now Click",
      scheduledAmountContinue: "WB Sub Accounts Rules Fixed Amount Continue Click",
      percentRuleContinue: "WB Sub Accounts Rules Percentage Continue Click",
      done: "WB Sub Accounts Done Click",
    },
    edit: {
      start: "WB Sub Accounts Edit Click",
      delete: "WB Sub Accounts Delete Click",
      deleteConfirm: "WB Sub Accounts Delete Yes Click",
      fixedAmountRuleSet: "WB Sub Accounts Set Rule Fixed Amount Click",
      fixedAmountRuleEdit: "WB Sub Accounts Edit Rule Fixed Amount Click",
      percentageRuleSet: "WB Sub Accounts Set Rule Percentage of Incoming funds Click",
      percentageRuleEdit: "WB Sub Accounts Edit Rule Percentage of Incoming Funds Click",
    },
    transfer: {
      start: "WB Accounts Overview Transfer Between Accounts Click",
      sourceAccount: "WB Transfer Money Select Account 'From' Continue Click",
      destinationAccount: "WB Transfer Money Select Deposit Account Continue Click",
      amount: "WB Transfer Money Amount Continue Click",
      schedule: "WB Transfer Money Enter Rules Continue Click",
      send: "WB Transfer Money Sub-accounts Send Transfer Click",
      discard: "WB Transfer Money Sub-accounts Discard Click",
    },
    reconcile: {
      continue: "WB Reconcile Continue Click",
      reallocate: "WB Reconcile Reallocate Click",
      skip: "WB Reconcile Skip Click",
    },
  },
  sendInvoice: {
    download: "WB Invoice Send Link Click",
  },
  topUp: {
    landing: {
      addFunds: "WB Top Up Page Primary CTA Tap",
      awaitingAddFunds: "WB Awaiting Funds Page Add Funds Tap",
      sideBarAddFunds: "WB Top Up Page Add Funds Tap",
    },
  },
  addFunds: {
    funded: {
      options: {
        wire: "WB Funding Options Wire Selection",
        ach: "WB Funding Options ACH Selection",
        thirdParty: "WB Funding Options 3rd Party Selection",
        checkDeposit: "WB Funding Options Check Selection",
        cashDeposit: "WB Funding Options Cash Selection",
      },
      check: "WB Funding via Check Primary CTA Tap",
      download: "WB Funding via Check Send SMS Tap",
      copyFields: {
        accountNumber: "WB Funding Copy Account Number",
        routingNumber: "WB Funding Copy Routing Number",
        bankName: "WB Funding Copy Bank Name",
        bankAddress: "WB Funding Copy Bank Address",
      },
    },
  },
  settings: {
    accountStatements: {
      download: "WB AccountStatement Download Click",
    },
    linkedAccount: {
      connectNew: "WB Add a Linked Account Click",
      connectAccount: "WB Linked Account Connect Account Click",
      delete: "WB Delete Linked Account Click",
      unlink: "WB Linked Accounts Disconnect Account Click",
    },
    contactInformation: {
      contactSupport: "WB Contact Information Contact Support Click",
    },
    password: {
      change: "WB Change Password Click",
      save: "WB Change Password 'Save' Click",
    },
    logo: {
      update: "WB Update Logo Click",
      remove: "WB Remove Logo Click",
    },
    teamBanking: {
      banner: {
        viewed: "WB Invite Team Banner Viewed",
        clicked: "WB Invite Team Banner Clicked",
        dismissed: "WB Invite Team Banner Dismissed",
      },
      listItemClick: "WB Role Details Clicked",
      listItemInvite: "WB Invite Team Clicked",
      sidepanelInvite: "WB Role Details Invite Team Clicked",
      sendInvite: { click: "WB Send Team Invite Clicked", error: "WB Team Invite Send Error" },
      resendInvite: {
        click: "WB Resend Team Invite Clicked",
        error: "WB Team Invite Resend Error",
      },
      confirmOnboardingStatusError: "WB Additional Owner Onboarding Status Confirmation Error",
    },
    manageTeam: {
      sendInvite: "WB Settings Manage Team Send Invite Click",
      resendInvite: "WB Settings Manage Team Re-send Invite Click",
    },
    recipient: {
      editWireRecipient: "WB EditRecipient EditClick",
      submitWireRecipient: "WB EditRecipient SaveChanges Submit",
      delete: "WB EditRecipient DeleteRecipient DeleteClick",
      deleteIcon: "WB EditRecipient DeleteRecipient IconClick",
      deleteDismiss: "WB EditRecipient DeleteRecipient DismissClick",
    },
  },
  searchAndFilter: {
    search: "WB Dashboard Search Bar Click",
    dashboardExport: "WB Dashboard Export icon Click",
    dashboardFilter: "WB Dashboard Filter icon Click",
    transactionsEnd: "WB Dashboard Search or Filter Pagination Click",
  },
  logout: "WB Dashboard Log out Click",
  cards: {
    notActivated: {
      activate: "Prompt Card Activate WB",
      notArrived: "Prompt Card NotArrived WB",
    },
    activate: {
      enterDetails: "Card Activate EnteredCardDetails WB",
      notArrived: "Card Activate NotArrived WB",
      pinSet: "Card Pin Set WB",
      success: "Card Activated Done WB",
    },
    landing: {
      viewTransactions: "Card View Transactions WB",
      lost: "Card LostOrStolen WB",
      changePin: "Card ChangePin WB",
      viewCardNumbers: "Card View Numbers",
      reportSecurityIssue: "Card Report Security Issue",
      viewCardDetails: "WB Card Details",
      viewMyCards: "WB My Cards List View",
      viewOtherCards: "WB Other Cards List View",
    },
    frozen: {
      viewTransaction: "WB Card View Transactions Frozen Screen",
      freeze: "WB Card Freeze",
      unfreeze: "WB Card Unfreeze",
      confirm: "WB Card Freeze Confirm",
      cancel: "WB Card Freeze Cancel",
    },
    locked: "WB Card Lock Contact Customer Care",
    notArrived: "Contact Customer Care WB",
    wireActivate: "Prompt Wire Card Activate WB",
    virtualCards: {
      landing: {
        viewCardNumbers: "WB Virtual Card View Numbers",
        viewTransactions: "WB Virtual Card View Transactions",
        reportSecurityIssue: "WB Virtual Card Report Security Issue",
      },
      modal: {
        viewCardDetails: "WB Virtual Card Details",
        freezeConfirm: "WB Virtual Card Freeze Confirmation",
        unfreezeConfirm: "WB Virtual Card Unfreeze",
        cancelConfirm: "WB Virtual Card Freeze Cancel Confirm",
      },
      add: "WB Add Virtual Card",
      issued: "WB Virtual Card Issued",
      cancel: "WB Virtual Card Cancel",
    },
  },
  integrations: {
    details: "WB Integrations Company details click",
    stripe: "WB Stripe integration payout redirect",
  },
  support: {
    talkToUs: "WB Support Talk to us Click",
    overviewCall: "WB Support Schedule a call Landing Page Gold CTA Click",
    sideBarCall: "WB Support Schedule a call Side menu Click",
    callTopic: "WB Support Topic selected click",
    callConfirm: "WB Support 'Schedule a call' blue confirmation",
    faqTopic: "WB Support FAQ Topic Click",
    sideBarFaq: "WB Support FAQ Side Menu Click",
  },
  schedules: {
    schedulePayment: "WB Schedule Payment Schedule Payment Click",
    filters: "WB Schedule Payment Filters Click",
    filtersYear: "WB Schedule Payment Filters Year Click",
    filtersPaymentType: "WB Schedule Payment Filters Payments Type Click",
    filtersApply: "WB Schedule Payment Filters Apply Filters Click",
    filtersClear: "WB Schedule Payment Filters Clear Click",
    listItem: "WB Schedule Payment Transaction Item Click",
  },
  idlePopUp: {
    logOutConfirm: "WB Session Logout Confirm Logout Click",
    stayLoggedIn: "WB Session Logout Remain logged in Click",
  },
  sessionStart: "WB Session Start",
  shareAndEarn: {
    hubCopyLinkTap: "WB S&E Hub - Copy Link Tap",
    hubLaunch: "WB S&E Hub - Launch Advocate Hub Tap",
    hubActivateDebitCardTap: "WB S&E Hub - Activate Debit Card Link Tap",
    incentiveError: "Error Retrieving Prismic S&E Incentive Amount",
    variables: {
      hubLaunchSourceNavigationBar: "navigation_bar",
      hubLaunchSourceACHSuccess: "ach_success",
      hubLaunchSourceWireSuccess: "wire_success",
      programStatusEnabled: "enabled",
      programStatusDisabled: "disabled",
    },
  },
  perks: {
    hubLaunch: "WB Perks Browse Perks Click",
    perksPartnerClick: "WB Perks Partner Click",
    partnerVisitSite: "WB Perks Partner Visit Site",
    partnerModalClosed: "WB Perks Partner Modal Closed",
    searchPageLanded: "WB Perks Search Page Landed",
    searchStarted: "WB Perks Search Started",
    searchNoResult: "WB Perks Search No Result",
    pageError: "Perks Page Error",
    variables: {
      hubLaunchSourceNavigationBar: "navigation_bar",
      programStatusEnabled: "enabled",
      programStatusDisabled: "disabled",
    },
  },
  invoicePayments: {
    invoiceDetails: {
      manuallyFillOutClick: "WB InvoiceDetails ManuallyFillOut Button Click",
      scanInvoiceClick: "WB InvoiceDetails Scan Button Click",
      saveInvoiceClick: "WB InvoiceDetails Save Button Click",
      exitClick: "WB InvoiceDetails Exit Button Click",
      continueEditingClick: "WB InvoiceDetails ExitConfirmation ContinueEditing Button Click",
      discardChangesClick: "WB InvoiceDetails ExitConfirmation DiscardChanges Button Click",
      attachmentUploadStarted: "WB InvoiceDetails InvoiceAttachment Uploaded",
      attachmentContactMatchAutoSelected:
        "WB InvoiceDetails AttachmentUpload Contact Match Auto Selected",
      attachmentContactAlreadyManuallySelected:
        "WB InvoiceDetails AttachmentUpload NoMatch Contact Already Manually Selected",
      attachmentNewContactPlaceholderAutoSelected:
        "WB InvoiceDetails AttachmentUpload New Contact Placeholder Auto Selected",
      extractedVendorSavedAsContact: "WB InvoiceDetails Extracted Vendor Saved as Contact",
      editInvoiceDetailsClick: "WB InvoiceDetails EditInvoiceDetails Button Click",
      reviewPaymentClick: "WB InvoiceDetails ReviewPayment Button Click",
      payInvoiceClick: "WB InvoiceDetails PayInvoice Button Click",
      sendPaymentClick: "WB InvoiceDetails SendPayment Button Click",
      schedulePaymentClick: "WB InvoiceDetails SchedulePayment Button Click",
      editPaymentDetailsClick: "WB InvoiceDetails EditPaymentDetails Button Click",
      addContact: {
        billPayDirectorySearchInitiated: "WB BillPay Directory Search Initiated",
        billPayDirectoryContactSaved: "WB BillPay Directory Contact Saved",
      },
    },
    invoicePaymentDetails: {
      discardProgressClick:
        "WB InvoicePaymentDetails ExitConfirmation DiscardProgress Button Click",
      continuePaymentClick:
        "WB InvoicePaymentDetails ExitConfirmation ContinuePayment Button Click",
      billPayPaymentMethodSelected: "WB BillPay Payment Method Selected",
    },
    invoiceQuickview: {
      payThisInvoiceClick: "WB InvoiceQuickView PayInvoice Button Click",
      markAsPaidConfirmationClick: "WB InvoiceQuickView MarkAsPaid Confirmation Button Click",
      markAsUnpaidConfirmationClick: "WB InvoiceQuickView MarkAsUnpaid Confirmation Button Click",
      archiveConfirmationClick: "WB InvoiceQuickView Archive Confirmation Button Click",
      editInvoiceDetailsClick: "WB InvoiceQuickView EditInvoiceDetails Button Click",
      moveBackToDraftsClick: "WB InvoiceQuickView MoveBackToDrafts Button Click",
      editScheduledPaymentClick: "WB InvoiceQuickView EditScheduledPayment Button Click",
    },
    invoiceAttachmentUploadSuccessful: "Accounts Payable Invoice Uploaded",
    refreshInvoicesClick: "WB InvoicePaymentsLanding Refresh Button Click",
    addInvoiceClick: "WB InvoicePaymentsLanding AddInvoice Button Click",
  },
  plaidFunding: {
    failedConnection: "WB PlaidInitialFunding Failed Connection",
    moneyOnMove: "WB PlaidInitialFunding Funds Moving Screen",
    supportClick: "WB PlaidInitialFunding Support Click",
  },
  revenueHub: {
    rutterLinkInitiated: "WB RevenueHub RutterSDK Link Initiated",
    rutterLinkCompleted: "WB RevenueHub RutterSDK Link Completed",
    rutterLinkFailed: "WB RevenueHub RutterSDK Link Failed",
    pageLoad: "WB RevenueHub Page Load",
    refreshClick: "WB RevenueHub Refresh Click",
    depositFundsClick: "WB RevenueHub DepositFund Click",
    deleteConnection: "WB RevenueHub Delete Connection Click",
    pageExit: "WB RevenueHub Page Exit",
  },
  linkedAccounts: {
    plaidOpenPlaid: "WB PlaidLink PlaidSDK Open Plaid",
    plaidSelectInstitution: "WB PlaidLink PlaidSDK Select Institution",
    plaidSubmitCredentials: "WB PlaidLink PlaidSDK Submit Credentials",
    plaidOpenOAuth: "WB PlaidLink PlaidSDK Open OAuth",
    plaidAccountSelected: "WB PlaidLink PlaidSDK Account Selected",
    plaidConnected: "WB PlaidLink PlaidSDK Connected",
    plaidSDKHandoff: "WB PlaidLink PlaidSDK Handoff",
    plaidLinkError: "WB PlaidLink PlaidSDK Error",
    plaidLinkExit: "WB PlaidLink PlaidSDK Exit",
    addAccountClick: "WB ExternalAccounts Add Account Click",
    addAccountError: "WB ExternalAccounts Add Account Error",
    accountTypeSelected: "WB ExternalAccounts Account Type Selected",
    deleteAccountIconClick: "WB ExternalAccounts Delete Account Icon Click",
    deleteAccountModalClick: "WB ExternalAccounts Delete Account Modal Click",
    deleteAccountError: "WB ExternalAccounts Delete Account Error",
  },
  topUps: {
    saveButtonClick: "WB TopUps Save Top-up Click",
    saveError: "WB TopUps Save Top-up Error",
    cancelButtonClick: "WB TopUps Cancel Top-up Click",
    cancelConfirmModalContinueClick: "WB TopUps Cancel Top-up Confirm Click",
    cancelConfirmModalBackClick: "WB TopUps Cancel Top-up Back Click",
  },
  subscriptions: {
    navBarClick: "WB View Plan Click",
    settingsSidebarClick: "WB Settings Menu Manage Plan Click",
    openSubscriptionUpgradeModal: "WB Subscriptions Upgrade Click",
    confirmSubscriptionUpgrade: "WB Confirm Purchase Upgrade Click",
    confirmSubscriptionCancellation: "WB Confirm Cancel Click",
    continueWithCurrentPlan: "WB Keep Plan Click",
    cancelUpgradeSubscription: "WB Confirm Purchase Go Back Click",
    openCancelSubscriptionModal: "WB Cancel Plan Click",
    celebrationScreenContinueClick: "WB Celebration Screen Continue Click",
    celebrationScreenExitClick: "WB Celebration Screen Exit Click",
    retentionConfirmCancellationClick: "WB Retention Confirm Cancel Click",
    retentionContinueWithPlanClick: "WB Retention Continue Click",
    reactivatePlanClick: "WB Reactivate Plan Click",
    confirmReactivatePlanClick: "WB Confirm Reactivate Plan Click",
    cancelReactivatePlanClick: "WB Cancel Reactivate Plan Click",
    upsellConfirmClick: "WB Upsell Purchase Confirm Click",
    billPayUpsell: "WB Bill Pay Upgrade Click",
    moveMoneyUpsell: "WB Move Money Upgrade Click",
  },
  lending: {
    ctaButtonClick: "WB Kanmon Lending CTA Click",
    kanmonWidgetError: "WB Kanmon Widget Error",
  },
}
