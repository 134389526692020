import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import { images } from "@assets/images/images"
import { ButtonTypeEnum } from "@components/primitive/button/button"
import { AnimatePresence, ErrorBanner, InfoBlock, InfoBlockModal, Modal } from "@northone/ui"
import { useCardFreezeMutation } from "@generated/graphql"
import { useAppSelector } from "@core/redux/utils"
import { selectActiveBusinessID } from "@core/active-business/redux/selectors"
import { pollWithMaxRetries } from "@utils/poll-with-max-retries"
import { CARD } from "../operations.gql"
import { Analytics } from "@core/analytics/actions"
import { events } from "@core/analytics/events"

interface IFreezeCardModalProps {
  isVirtualCard: boolean
  isOpen: boolean
  closeModal: () => void
  cardId: string
}

export const FreezeCardModal: React.FC<IFreezeCardModalProps> = ({
  isVirtualCard,
  isOpen,
  closeModal,
  cardId,
}) => {
  const { t } = useTranslation()
  const businessId = useAppSelector(selectActiveBusinessID)
  const accountId = useAppSelector((state) => state.cards.cardAccountId)
  const [hasError, setHasError] = useState(false)
  const [freezeCard, { loading }] = useCardFreezeMutation({
    variables: { businessId, accountId, cardId },
    onError: () => setHasError(true),
    onCompleted: () => {
      closeModal()
      pollWithMaxRetries([{ query: CARD, variables: { cardId, businessId } }])
    },
  })
  return (
    <Modal sx={{ width: 407 }} isOpen={isOpen} onOutsideClick={closeModal}>
      <AnimatePresence animation="fadeAndExpand">
        {hasError && (
          <ErrorBanner
            textProps={{ onLinkClick: () => window.Intercom("show") }}
            text={t("cards.freeze.error")}
          />
        )}
      </AnimatePresence>
      <InfoBlock
        title={t("cards.freeze.title")}
        subtitle={t("cards.freeze.subtitle")}
        imageSrc={images.freezeCard}
        buttons={[
          {
            children: t("cards.freeze.button"),
            type: ButtonTypeEnum.PRIMARY_BLUE,
            isLoading: loading,
            onClick: () => {
              setHasError(false)
              freezeCard()
              Analytics.track(
                isVirtualCard
                  ? events.cards.virtualCards.modal.freezeConfirm
                  : events.cards.frozen.confirm,
              )
            },
          },
          {
            children: t("buttons.cancel"),
            type: ButtonTypeEnum.SECONDARY,
            onClick: () => {
              closeModal()
              Analytics.track(
                isVirtualCard
                  ? events.cards.virtualCards.modal.cancelConfirm
                  : events.cards.frozen.cancel,
              )
            },
          },
        ]}
      />
    </Modal>
  )
}

export const FreezeCardModalCS: React.FC<IFreezeCardModalProps> = ({ isOpen, closeModal }) => {
  const { t } = useTranslation()
  return (
    <InfoBlockModal
      isOpen={isOpen}
      title={t("cards.freezeCS.title")}
      subtitle={t("cards.freezeCS.subtitle")}
      imageDimensions={{ height: 188, width: 227 }}
      imageSrc={images.illustrations.canTelephone}
      buttons={[
        {
          children: t("buttons.contactCustomerCare"),
          type: ButtonTypeEnum.PRIMARY_BLUE,
          onClick: () => window.Intercom("show"),
        },
      ]}
      closeModal={closeModal}
    />
  )
}
