import React from "react"
import { useDispatch } from "react-redux"
import { events } from "@core/analytics/events"
import { useAppSelector } from "@core/redux/utils"
import { selectActiveBusinessID } from "@core/active-business/redux/selectors"
import {
  ContactPaymentType,
  TransactionTypeEnum,
  useRecipientsForDomesticWireQuery,
} from "@generated/graphql"
import { IGenericRecipient } from "@features/move-money/redux/state"
import {
  selectRecipient,
  selectWireRecipientAddressIsValid,
} from "@features/move-money/redux/selectors"
import { moveMoneyActions } from "@features/move-money/redux/actions"
import { gqlWireRecipientsToRedux } from "@features/settings/recipients/recipient-list/utils"
import { RecipientSelect } from "@features/move-money/shared/recipient-select-form"
import { TMoveMoneyScreenNames } from "@features/move-money/router/move-money"
import { DomesticWireScreensEnum } from "@features/move-money/router/domestic-wire"

export const DomesticWireRecipientForm: React.FC = () => {
  const dispatch = useDispatch()
  const businessId: string = useAppSelector(selectActiveBusinessID)
  const recipient = useAppSelector(selectRecipient)
  const recipientAddressIsValid = useAppSelector(selectWireRecipientAddressIsValid)

  const { data, loading, error, refetch } = useRecipientsForDomesticWireQuery({
    variables: { businessId, contactPaymentType: ContactPaymentType.WIRE },
    notifyOnNetworkStatusChange: true,
  })

  const recipients: IGenericRecipient[] = data?.business?.contacts
    ? data?.business?.contacts.map((contact) => gqlWireRecipientsToRedux(contact))
    : []

  if (
    (recipient.name && !recipient.relationship) ||
    !recipient.address ||
    !recipientAddressIsValid
  ) {
    dispatch(
      moveMoneyActions.setEditingRecipient({
        id: recipient.id,
        name: recipient.name,
        bankAccountNumber: recipient.bankAccountNumber || undefined,
        bankRoutingNumber: recipient.bankRoutingNumber || undefined,
        relationship: recipient.relationship || undefined,
        address: recipient.address || undefined,
        bankName: recipient.bankName || undefined,
      }),
    )
  }

  const navigationHandler = (): TMoveMoneyScreenNames => {
    if (!recipient.relationship || !recipient.address || !recipientAddressIsValid) {
      return DomesticWireScreensEnum.DOMESTIC_WIRE_RECIPIENT_DETAILS
    }
    return DomesticWireScreensEnum.DOMESTIC_WIRE_AMOUNT
  }

  return (
    <RecipientSelect
      recipientType={TransactionTypeEnum.DOMESTIC_WIRE}
      error={error}
      loading={loading}
      refetch={refetch}
      recipients={recipients}
      navigateNextRoute={navigationHandler()}
      createRecipientRoute={DomesticWireScreensEnum.DOMESTIC_WIRE_RECIPIENT_DETAILS}
      analyticsContinueEvent={events.moveMoney.domesticWire.recipientContinue}
      analyticsCreateEvent={events.moveMoney.domesticWire.recipientAdd}
      analyticsSelectEvent={events.moveMoney.domesticWire.recipient}
    />
  )
}
