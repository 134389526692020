import React from "react"
import { Analytics } from "@core/analytics/actions"
import { useAppSelector } from "@core/redux/utils"
import { selectInvoicePaymentModalHistory } from "@features/invoice-payments/redux/selector"
import { useTranslation } from "react-i18next"
import { InvoicePromptModal } from "./invoice-prompt-modal"
import { ButtonTypeEnum } from "@components/primitive/button/button"
import { events } from "@core/analytics/events"

export const InvoiceExitPrompt = ({
  isOpen,
  onConfirmExit,
  onCancelExit,
}: {
  isOpen: boolean
  onConfirmExit: () => void
  onCancelExit: () => void
}) => {
  const { t } = useTranslation()
  const screenHistory = useAppSelector(selectInvoicePaymentModalHistory)
  const currentRouteName = screenHistory[screenHistory.length - 1]
  const isInvoiceDetailsRoute = currentRouteName === "INVOICE_DETAILS"
  return (
    <InvoicePromptModal
      isOpen={isOpen}
      title={t("invoicePayments.invoicePanel.promptDialog.title")}
      subTitle={
        isInvoiceDetailsRoute
          ? t("invoicePayments.invoicePanel.promptDialog.invoiceProgressNote")
          : t("invoicePayments.invoicePanel.promptDialog.paymentProgressNote")
      }
      buttons={[
        {
          type: ButtonTypeEnum.SECONDARY,
          children: isInvoiceDetailsRoute
            ? t("invoicePayments.invoicePanel.promptDialog.discardChanges")
            : t("invoicePayments.invoicePanel.promptDialog.discardProgress"),
          onClick: () => {
            if (isInvoiceDetailsRoute) {
              Analytics.track(events.invoicePayments.invoiceDetails.discardChangesClick)
            } else {
              Analytics.track(events.invoicePayments.invoicePaymentDetails.discardProgressClick)
            }
            onConfirmExit()
          },
        },
        {
          type: ButtonTypeEnum.PRIMARY_BLUE,
          children: isInvoiceDetailsRoute
            ? t("invoicePayments.invoicePanel.promptDialog.continueEditing")
            : t("invoicePayments.invoicePanel.promptDialog.continuePayment"),
          onClick: () => {
            if (isInvoiceDetailsRoute) {
              Analytics.track(events.invoicePayments.invoiceDetails.continueEditingClick)
            } else {
              Analytics.track(events.invoicePayments.invoicePaymentDetails.continuePaymentClick)
            }
            onCancelExit()
          },
        },
      ]}
    />
  )
}
