import { parseISO } from "date-fns"
import { PERKS_LAST_VISITED_KEY } from "./constants"

/**
 * Retrieves from local storage the last time the Perks hub was visited
 */
export const getPerksLastVisitedFromLocalStorage = (): Date | undefined => {
  const lastVisited = localStorage.getItem(PERKS_LAST_VISITED_KEY)
  if (!lastVisited) return undefined
  return parseISO(lastVisited)
}

/**
 * Sets the hub last visited time in local storage
 */
export const setPerksLastVisitedInLocalStorage = (lastVisited: Date) => {
  localStorage.setItem(PERKS_LAST_VISITED_KEY, lastVisited.toISOString())
}
