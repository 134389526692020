import { FeeType } from "@generated/graphql"
import { formatMoney } from "accounting"
export type FlatFee = {
  __typename?: "FlatFee"
  type: FeeType
  amount: number
}
export type PercentFee = {
  __typename?: "PercentFee"
  type: FeeType
  percentage: number
  minimum: number
  maximum: number
}
export interface ILimit {
  amount: number
  message?: string
}
export type Limits = {
  min?: ILimit
  max?: ILimit
}
export type ICalculateBalance = {
  amount: number
  fee: number
  funds?: number
  limits?: Limits
}
export const feeIsPercentFee = (feeMetadata?: FlatFee | PercentFee): feeMetadata is PercentFee => {
  if (!feeMetadata) return false
  return feeMetadata.type === FeeType.PERCENT
}
export const feeIsFlatFee = (feeMetadata?: FlatFee | PercentFee): feeMetadata is FlatFee => {
  if (!feeMetadata) return false
  return feeMetadata.type === FeeType.FLAT
}
export const getFee = (feeMetadata: FlatFee | PercentFee | undefined) => {
  const isPercentFee = feeIsPercentFee(feeMetadata)
  const isFlatFee = feeIsFlatFee(feeMetadata)
  if (isPercentFee) {
    return feeMetadata.percentage
  }
  if (isFlatFee) {
    return feeMetadata.amount
  }
  return undefined
}
export const getFormattedAmountFeeDisplay = (amount: number) => {
  const formattedAmount = formatMoney(amount, "$", 2)
  if (formattedAmount.endsWith(".00")) {
    return formattedAmount.replace(".00", "")
  }
  return formattedAmount
}

export const getFeeDisplay = (feeMetadata: FlatFee | PercentFee | undefined) => {
  const isPercentFee = feeIsPercentFee(feeMetadata)
  const isFlatFee = feeIsFlatFee(feeMetadata)
  if (isPercentFee) {
    return feeMetadata.percentage + "%"
  }
  if (isFlatFee) {
    return getFormattedAmountFeeDisplay(feeMetadata.amount)
  }
  return undefined
}
