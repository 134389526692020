import { TAddFundsActions, AddFundsActionEnum } from "./actions"
import { TAddFundsScreenName } from "@features/add-funds/add-funds.router"

export enum FundingMethodEnum {
  THIRD_PARTY = "Third-party service",
  ACH = "ACH transfer",
  CHECK_DEPOSIT = "Check deposit",
  ATM = "Cash deposit",
  WIRE = "Wire transfer",
}

export interface IAddFundsState {
  screenHistory: TAddFundsScreenName[]
  amount: string
  fundingMethod: FundingMethodEnum | null
  userPhoneNumber: string
  smsTextLinkSendIsPossibleFlag: boolean | null
  smsTextSendStatus: boolean | null
}

export interface ILinkedAccount {
  id: string
  name: string
  accountNumber: string
  balance: number
  logo: string
}

export const initialAddFundsState: IAddFundsState = {
  // TODO: Fix ReferenceError: Cannot access 'AddFundsScreensEnum' before initialization
  screenHistory: ["ADD_FUNDS_OPTIONS"],
  amount: "",
  fundingMethod: null,
  userPhoneNumber: "",
  smsTextLinkSendIsPossibleFlag: null,
  smsTextSendStatus: null,
}

export const addFundsReducer = (
  state: IAddFundsState = initialAddFundsState,
  action: TAddFundsActions,
): IAddFundsState => {
  switch (action.type) {
    case AddFundsActionEnum.NAVIGATE_NEXT:
      return {
        ...state,
        screenHistory: [...state.screenHistory, action.payload],
      }
    case AddFundsActionEnum.NAVIGATE_BACK:
      return {
        ...state,
        screenHistory: state.screenHistory.slice(0, -1),
      }
    case AddFundsActionEnum.SET_AMOUNT:
      return {
        ...state,
        amount: action.payload,
      }
    case AddFundsActionEnum.SET_USER_PHONE_NUMBER:
      return {
        ...state,
        userPhoneNumber: action.payload,
      }
    case AddFundsActionEnum.SET_SMS_MESSAGE_SEND_CAPABILITY_STATUS:
      return {
        ...state,
        smsTextLinkSendIsPossibleFlag: action.payload,
      }
    case AddFundsActionEnum.SET_SMS_MESSAGE_SEND_STATUS:
      return {
        ...state,
        smsTextSendStatus: action.payload,
      }
    case AddFundsActionEnum.SET_FUNDING_METHOD:
      return {
        ...state,
        fundingMethod: action.payload,
      }
    case AddFundsActionEnum.CLEAR_STATE:
      return initialAddFundsState
    default:
      return state
  }
}
