import gql from "graphql-tag"

export const SUBSCRIPTION_DATA = gql`
  query subscriptionPlansData($businessId: ID!) {
    subscriptionPlans(businessId: $businessId) {
      footer
      plans {
        name
        isCurrentPlan
        content {
          title
          shortTitle
          subtitle
          upgradeButtonText
          description
          featured
          name
          price {
            period
            price
            text
          }
          upgrade {
            successTitle
            successSubtitle
          }
          upsell {
            title
            subtitle
          }
          retentionFeatures {
            icon
            text
          }
          sections {
            heading
            bullets {
              icon
              text
            }
          }
        }
      }
    }
    subscription(businessId: $businessId) {
      status
      accountId
      plan
      price
      paymentPeriod
      startDate
      nextBillingDate
    }
  }
`

export const SUBSCRIPTION = gql`
  query subscription($businessId: ID!) {
    subscription(businessId: $businessId) {
      status
      accountId
      plan
      price
      paymentPeriod
      startDate
      nextBillingDate
    }
  }
`

export const UPGRADE_SUBSCRIPTION = gql`
  mutation subscriptionUpgrade($input: SubscriptionUpgradeInput!) {
    subscriptionUpgrade(input: $input) {
      code
      message
      subscription {
        status
        accountId
        plan
        price
        paymentPeriod
        startDate
        nextBillingDate
      }
    }
  }
`
export const CANCEL_SUBSCRIPTION = gql`
  mutation subscriptionCancel($input: SubscriptionCancelInput!) {
    subscriptionCancel(input: $input) {
      code
      success
      message
      subscription {
        accountId
        plan
        price
        paymentPeriod
        startDate
        nextBillingDate
        status
      }
    }
  }
`

export const REACTIVATE_SUBSCRIPTION = gql`
  mutation subscriptionReactivate($input: SubscriptionReactivateInput!) {
    subscriptionReactivate(input: $input) {
      code
      message
      success
      subscription {
        accountId
        plan
        price
        paymentPeriod
        startDate
        nextBillingDate
        status
      }
    }
  }
`

export const TRANSACTION_UPSELL = gql`
  query transactionUpsellContent(
    $businessId: ID!
    $transactionType: SubscriptionUpsellTransactionType!
  ) {
    transactionUpsellContentPerType(businessId: $businessId, transactionType: $transactionType) {
      upsellPlan {
        planName
        buttonContent
        discountFeeDetails {
          ... on FlatFeeDetails {
            feeType
            amount
          }
          ... on PercentFeeDetails {
            feeType
            minimumAmount
            maximumAmount
            percent
          }
        }
      }
    }
  }
`
