import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Text } from "@components/primitive/text/text"
import { FlexColumnLayout, FlexRowLayout } from "@layouts/flex"
import { Button, ButtonTypeEnum } from "@components/primitive/button/button"
import { theme } from "@layouts/theme"
import { images } from "@assets/images/images"

export interface IUploadedAttachmentNoticeProps {
  onScanButtonClick: () => void
  onManualButtonClick: () => void
}

export const UploadedAttachmentNotice = (props: IUploadedAttachmentNoticeProps) => {
  const { t } = useTranslation()
  const [isScanning, setIsScanning] = useState(false)

  return (
    <FlexColumnLayout
      sx={{
        backgroundColor: theme.colors.blue10,
        borderColor: theme.colors.blue60,
        borderWidth: 1,
        borderStyle: "solid",
        borderRadius: 4,
        padding: "20px 20px 20px 40px",
        mb: 22,
        backgroundImage: `url('${images.icons.noticeBlue}')`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "14px 24px",
      }}
    >
      <Text tag="body-small-bold">
        {t("invoicePayments.modal.invoiceDetails.uploadedInvoiceNotice.heading")}
      </Text>
      <Text tag="body-small">
        {t("invoicePayments.modal.invoiceDetails.uploadedInvoiceNotice.body")}
      </Text>
      <FlexRowLayout sx={{ mt: "8px" }}>
        <Button
          type={ButtonTypeEnum.PRIMARY_BLACK}
          onClick={() => {
            setIsScanning(true)
            props.onScanButtonClick()
          }}
          sx={{
            height: "26px",
            px: "8px",
            mr: "10px",
            ...(isScanning ? { width: 110, bg: theme.colors.ui2, border: 0 } : {}),
          }}
          textSx={{
            fontSize: 11,
            fontWeight: "500",
            letterSpacing: 1,
            textTransform: "uppercase",
          }}
          isLoading={isScanning}
        >
          {!isScanning &&
            t("invoicePayments.modal.invoiceDetails.uploadedInvoiceNotice.scanInvoice")}
        </Button>
        <Button
          type={ButtonTypeEnum.TERTIARY_ALT}
          onClick={props.onManualButtonClick}
          sx={{ height: "26px", px: "8px", backgroundColor: "transparent" }}
          textSx={{ fontSize: 11, fontWeight: "500", letterSpacing: 1, textTransform: "uppercase" }}
        >
          {t("invoicePayments.modal.invoiceDetails.uploadedInvoiceNotice.manuallyFillOutInvoice")}
        </Button>
      </FlexRowLayout>
    </FlexColumnLayout>
  )
}
