import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useAppSelector } from "@core/redux/utils"
import { selectActiveBusinessID } from "@core/active-business/redux/selectors"
import { Analytics } from "@core/analytics/actions"
import { events } from "@core/analytics/events"
import { FormBody } from "@components/composite/form-body/form-body"
import { QueryResults } from "@components/composite/query-results/query-results"
import { SubAccountList } from "@components/composite/sub-account-list/sub-account-list"
import { ButtonTypeEnum } from "@components/primitive/button/button"
import { accountsActions } from "@features/accounts/redux/actions"
import { selectAccountsSubAccountTransfer } from "@features/accounts/redux/selectors"
import { useSubAccountsQuery } from "@generated/graphql"

export const SourceAccountSelect: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { sourceAccount } = useAppSelector(selectAccountsSubAccountTransfer)
  const businessId = useAppSelector(selectActiveBusinessID)

  const { data, loading, error, refetch } = useSubAccountsQuery({
    variables: { businessId },
    notifyOnNetworkStatusChange: true,
  })

  return (
    <FormBody
      title={t("accounts.transfer.sourceAccount.title")}
      buttons={[
        {
          canContinueWithKey: true,
          type: ButtonTypeEnum.PRIMARY_GOLD,
          disabled: !sourceAccount.id,
          children: t("buttons.continue"),
          onClick: () => {
            dispatch(
              accountsActions.subAccountTransferNavigateNext(
                "SUB_ACCOUNT_TRANSFER_DESTINATION_ACCOUNT",
              ),
            )
            Analytics.track(events.accounts.transfer.sourceAccount)
          },
        },
      ]}
    >
      <QueryResults
        error={error}
        loading={loading}
        retry={() => refetch()}
        size="medium"
        listShimmerCount={3}
      >
        <SubAccountList
          subAccounts={data?.business?.bankAccount?.subAccounts || []}
          selectedSubAccountId={sourceAccount.id}
          onSelect={(subAccount) =>
            dispatch(accountsActions.setSubAccountTransferSourceAccount(subAccount))
          }
          paymentAmount={0}
          totalAvailableBalance={data?.business?.bankAccount?.availableBalance || 0}
          insufficientBalanceCondition={"through"}
        />
      </QueryResults>
    </FormBody>
  )
}
