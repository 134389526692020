import { TeamOwner } from "@generated/graphql"
import { TeamBankingActionEnum, TTeamBankingActions } from "./actions"
export interface ITeamBankingState {
  selectedTeamMember: TeamOwner | undefined
  isModalOpen: boolean
  isSettingsVisited: boolean
  sendInviteError: boolean
}
export const initialTeamBankingState: ITeamBankingState = {
  selectedTeamMember: undefined,
  isModalOpen: false,
  isSettingsVisited: false,
  sendInviteError: false,
}
export const teamBankingReducer = (
  state = initialTeamBankingState,
  action: TTeamBankingActions,
): ITeamBankingState => {
  switch (action.type) {
    case TeamBankingActionEnum.SET_SELECTED_TEAM_MEMBER:
      return {
        ...state,
        selectedTeamMember: action.payload,
      }
    case TeamBankingActionEnum.SET_IS_MODAL_OPEN:
      return {
        ...state,
        isModalOpen: action.payload,
      }
    case TeamBankingActionEnum.SET_IS_SETTINGS_VISITED:
      return {
        ...state,
        isSettingsVisited: action.payload,
      }
    case TeamBankingActionEnum.SET_SEND_INVITE_ERROR:
      return {
        ...state,
        sendInviteError: action.payload,
      }
    default:
      return state
  }
}
