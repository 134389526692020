import React from "react"
import { UpgradePlanSuccessLayout } from "./components/upgrade-success-layout"
import { images } from "@assets/images/images"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom"
import { useAppSelector } from "@core/redux/utils"
import { selectActiveBusinessID } from "@core/active-business/redux/selectors"
import { formatGQLPlanData, formatGQLRetentionFeatures } from "../utils"
import { useSubscriptionPlansDataQuery } from "@generated/graphql"
import { events } from "@core/analytics/events"
import { Analytics } from "@core/analytics/actions"
import { appActions } from "@core/redux/app/actions"
import { useDispatch } from "react-redux"
import { FullScreenLoading } from "@northone/ui"
import { ErrorScreen } from "@components/composite/error-screen/error-screen"
import { ButtonTypeEnum } from "@components/primitive/button/button"

export const UpgradePlanConfirmed = ({ closeModal }: { closeModal: () => void }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const businessId = useAppSelector(selectActiveBusinessID)
  const location = useLocation()
  const upgradePlanName = location.pathname.split("/").pop()

  const { data, loading, refetch, error } = useSubscriptionPlansDataQuery({
    variables: { businessId },
  })

  const planData = data?.subscriptionPlans?.plans?.find((plan) => plan.name === upgradePlanName)
  const plan = formatGQLPlanData(planData)
  const retentionFeatures = formatGQLRetentionFeatures(plan?.retentionFeatures)
  const title = plan?.upgrade?.successTitle
  const subtitle = plan?.upgrade?.successSubtitle

  if (loading) {
    return <FullScreenLoading />
  }

  if (!plan || error) {
    return <ErrorScreen size="large" retry={() => refetch()} />
  }

  return (
    <UpgradePlanSuccessLayout
      title={title || t("subscriptions.upgrade.success.titleDefault")}
      subtitle={subtitle}
      featureBulletsHeading={t("subscriptions.upgrade.success.yourFeatures")}
      imgSrc={images.upgradePlusSuccess}
      featureBullets={retentionFeatures}
      buttons={[
        {
          type: ButtonTypeEnum.PRIMARY_BLACK,
          children: t("buttons.continue"),
          onClick: () => {
            Analytics.track(events.subscriptions.celebrationScreenContinueClick)
            closeModal()
            dispatch(appActions.setNotificationMessage(t("subscriptions.upgrade.success.toast")))
          },
        },
      ]}
    />
  )
}
