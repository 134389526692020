import React from "react"
import { useDispatch } from "react-redux"
import { FormBody } from "@components/composite/form-body/form-body"
import { ButtonTypeEnum } from "@components/primitive/button/button"
import { RoundedLongTextInput } from "@components/extended/fields/rounded-long-text-input"
import { useAppSelector } from "@core/redux/utils"
import { regex } from "@utils/regex"
import { moveMoneyActions } from "@features/move-money/redux/actions"
import { wireConstants } from "@features/move-money/domestic-wire/utils/constants"
import { DomesticWireScreensEnum } from "@features/move-money/router/domestic-wire"
import {
  PURPOSE_TITLE,
  PURPOSE_SUBTITLE,
  PURPOSE_LABEL,
  PURPOSE_PLACEHOLDER,
  PURPOSE_INVALID_CHARS,
  PURPOSE_INVALID_LENGTH,
  BUTTONS_CONTINUE,
  BUTTONS_GO_BACK,
} from "@features/move-money/utils/i18n-translations"

export const DomesticWirePurpose: React.FC = () => {
  const dispatch = useDispatch()
  const purpose = useAppSelector((state) => state.moveMoney.purpose)

  const isNotLongEnough = purpose.length && purpose.length < wireConstants.WIRE_PURPOSE_MIN_LENGTH
  const hasInvalidChars = Boolean(
    purpose && !regex.isAlphanumericSpacePeriodCommaHyphen.test(purpose),
  )
  const disabled = !purpose.length || isNotLongEnough || hasInvalidChars

  const buttons = [
    {
      type: ButtonTypeEnum.TERTIARY,
      children: BUTTONS_GO_BACK,
      onClick: () => dispatch(moveMoneyActions.navigateBack()),
    },
    {
      canContinueWithKey: true,
      children: BUTTONS_CONTINUE,
      disabled,
      onClick: () => {
        dispatch(moveMoneyActions.navigateNext(DomesticWireScreensEnum.DOMESTIC_WIRE_CONFIRM))
      },
    },
  ]

  return (
    <FormBody title={PURPOSE_TITLE} buttons={buttons} subtitle={PURPOSE_SUBTITLE}>
      <RoundedLongTextInput
        maxLength={wireConstants.WIRE_PURPOSE_MAX_LENGTH}
        value={purpose}
        onChange={(v) => dispatch(moveMoneyActions.setWirePurpose(v))}
        placeholder={PURPOSE_PLACEHOLDER}
        label={PURPOSE_LABEL}
        errorMessage={hasInvalidChars ? PURPOSE_INVALID_CHARS : undefined}
        subText={isNotLongEnough ? PURPOSE_INVALID_LENGTH : undefined}
      />
    </FormBody>
  )
}
