import React, { useEffect, useRef, useState } from "react"
import { Box } from "rebass"
import { Switch, Route, useLocation, Redirect } from "react-router-dom"
import { AnimatePresence } from "framer-motion"

import { NavBar } from "../components/composite/nav-bar/nav-bar"
import { MoveMoneyModal } from "../features/move-money/move-money-modal"
import { CreateSubAccountModal } from "../features/accounts/create-sub-account/create-sub-accounts-modal"
import { SubAccountDashboard } from "../features/accounts/sub-accounts/sub-account-dashboard"
import { Accounts } from "../features/accounts/accounts"
import { Dashboard } from "../features/dashboard/dashboard"
import { SendInvoiceModal } from "../features/send-invoice/send-invoice-modal"
import { AppLayout } from "../layouts/app-layout"
import { SideBar } from "../features/sidebar/dashboard/sidebar"
import { SubAccountSidebar } from "../features/sidebar/sub-account/sidebar"
import { EditSubAccount } from "../features/accounts/edit/edit-sub-account"
import { RoutesEnum, ILocationState } from "./types"
import { AddFundsModal } from "../features/add-funds/add-funds-modal"
import { LinkedAccountsLanding } from "@features/settings/linked-accounts/landing"
import { SettingsSidebar } from "../features/sidebar/settings/settings-sidebar"
import { ContactInformationSettings } from "../features/settings/contact-info/contact-info"
import { PasswordSettings } from "../features/settings/password/password"
import { AccountStatements } from "../features/settings/statements/statements"
import { CardsLanding } from "../features/cards/cards-landing"
import { SubAccountTransferModal } from "../features/accounts/transfer/sub-account-transfer-modal"
import { ReconcileModal } from "../features/reconcile/reconcile-modal"
import { SupportFAQs } from "../features/support/faqs/landing"
import { FAQSection } from "../features/support/faqs/section"
import { FAQArticle } from "../features/support/faqs/article"
import { SupportOverview } from "../features/support/overview/overview"
import { SupportScheduleCall } from "../features/support/schedule-call/schedule-call"
import { SupportSidebar } from "../features/support/support-sidebar"
import { Integrations } from "../features/integrations/integrations-landing"
import { RecipientsLanding } from "../features/settings/recipients/landing"
import { RecipientListByType } from "../features/settings/recipients/recipient-list/recipients-list-by-type"
import { LegalLanding } from "../features/settings/legal/landing"
import { LegalListByType } from "../features/settings/legal/legal-list-by-type"
import { BusinessOwners } from "../features/settings/manage-team/landing"
import { Logo } from "@layouts/logo"
import { CardSetupModal } from "@features/cards/card-setup-modal/card-setup-modal"
import { CardNotArrivedModal } from "@features/cards/activate/card-not-arrived-modal"
import { ShareAndEarnLanding } from "@features/share-and-earn/share-and-earn-landing"
import { ShareAndEarnSidebar } from "@features/share-and-earn/referral-totals"
import { PerksLanding } from "@features/perks/components/perks-landing"
import { TeamBankingLanding } from "@features/settings/team-banking/landing"
import { InvoicePaymentsLanding } from "@features/invoice-payments/invoice-payments-landing"
import { InvoicePaymentsModal } from "@features/invoice-payments/invoice-modal/invoice-payments-modal"
import { PlaidFundingModal } from "@features/plaid-funding/components/plaid-funding-modal"
import { RevenueHubLanding } from "@features/revenue-hub/revenue-hub-landing"
import { AutoTopUpsLanding } from "@features/settings/auto-top-ups/landing"
import { SubscriptionsLanding } from "@features/subscriptions/landing/subscriptions-landing"
import { SubscriptionUpgradeModal } from "@features/subscriptions/upgrade/subscriptions-upgrade-modal"
import { SubscriptionCancelModal } from "@features/subscriptions/cancel/subscriptions-cancel-modal"
import { SubscriptionReactivateModal } from "@features/subscriptions/reactivate/reactivate-plan-modal"
import { LendingLanding } from "@features/lending/lending-landing"

export const AppSideBar: React.FC = () => {
  const location = useLocation<ILocationState>()
  const [height, setHeight] = useState(0)

  const ref = useRef<HTMLDivElement>(null)
  const hasFooterSidebarPath = location.pathname !== RoutesEnum.SHARE_AND_EARN

  useEffect(() => {
    const boundingRect = ref.current?.getBoundingClientRect()
    setHeight(boundingRect?.height || 0)
  }, [location])

  return (
    <>
      <Box ref={ref}>
        <Switch location={location.state?.background || location}>
          <Route component={SettingsSidebar} path={RoutesEnum.SETTINGS_CONTACT_INFORMATION} />
          <Route component={SupportSidebar} path={RoutesEnum.SUPPORT_OVERVIEW} />
          <Route component={SubAccountSidebar} path={RoutesEnum.SUB_ACCOUNT_DETAIL} />
          <Route component={SideBar} path={RoutesEnum.ACCOUNTS_OVERVIEW} />
          <Route component={SideBar} path={RoutesEnum.SCHEDULES} />
          <Route component={ShareAndEarnSidebar} path={RoutesEnum.SHARE_AND_EARN} />
          <Route exact component={SideBar} path={RoutesEnum.DASHBOARD} />
        </Switch>
      </Box>
      {/* Screens without a left aside should have the Logo in the bottom footer
        This is handled in the screen component by using prop `withLogoFooter` on the BaseLayout component */}
      {height && hasFooterSidebarPath ? <Logo /> : null}
    </>
  )
}

export const AppRouter: React.FC = () => {
  const location = useLocation<ILocationState>()

  return (
    <AnimatePresence>
      <NavBar key="animate-nav-bar" />
      <AppLayout leftAside={<AppSideBar />} key="animate-app-layout">
        <Switch location={location.state?.background || location}>
          <Route component={CardsLanding} path={RoutesEnum.CARDS} />
          <Route component={InvoicePaymentsLanding} path={RoutesEnum.INVOICES} />
          <Route component={PasswordSettings} path={RoutesEnum.SETTINGS_CHANGE_PASSWORD} />
          <Route component={LinkedAccountsLanding} path={RoutesEnum.SETTINGS_LINKED_ACCOUNT} />
          <Route
            component={RecipientListByType}
            path={RoutesEnum.SETTINGS_RECIPIENT_LIST_BY_TYPE}
          />
          <Route component={RecipientsLanding} path={RoutesEnum.SETTINGS_RECIPIENTS} />
          <Route component={LegalListByType} path={RoutesEnum.SETTINGS_LEGAL_LIST_BY_TYPE} />
          <Route component={LegalLanding} path={RoutesEnum.SETTINGS_LEGAL} />
          <Route component={BusinessOwners} path={RoutesEnum.SETTINGS_MANAGE_TEAM} />
          <Route component={TeamBankingLanding} path={RoutesEnum.SETTINGS_TEAM_BANKING} />
          <Route component={AccountStatements} path={RoutesEnum.SETTINGS_ACCOUNT_STATEMENTS} />
          <Route component={AutoTopUpsLanding} path={RoutesEnum.SETTINGS_AUTO_TOP_UP} />
          <Route
            component={ContactInformationSettings}
            path={RoutesEnum.SETTINGS_CONTACT_INFORMATION}
          />
          <Route component={FAQArticle} path={RoutesEnum.SUPPORT_FAQS_ARTICLE} />
          <Route component={FAQArticle} path={RoutesEnum.SUPPORT_FAQS_SEARCH_ARTICLE} />
          <Route component={FAQSection} path={RoutesEnum.SUPPORT_FAQS_SECTION} />
          <Route component={SupportFAQs} path={RoutesEnum.SUPPORT_FAQS} />
          <Route component={SupportScheduleCall} path={RoutesEnum.SUPPORT_SCHEDULE_CALL} />
          <Route component={SupportOverview} path={RoutesEnum.SUPPORT_OVERVIEW} />

          <Route component={EditSubAccount} path={RoutesEnum.EDIT_SUB_ACCOUNT} />
          <Route component={SubAccountDashboard} path={RoutesEnum.SUB_ACCOUNT_DETAIL} />
          <Route component={Accounts} path={RoutesEnum.ACCOUNTS_OVERVIEW} />
          <Route component={PerksLanding} path={RoutesEnum.PERKS} />

          <Route component={SubscriptionsLanding} path={RoutesEnum.PLANS} />

          <Route component={ShareAndEarnLanding} path={RoutesEnum.SHARE_AND_EARN} />
          <Route component={Integrations} path={RoutesEnum.INTEGRATIONS} />
          <Route component={RevenueHubLanding} path={RoutesEnum.REVENUE_HUB} />
          <Route component={LendingLanding} path={RoutesEnum.LENDING} />
          <Route exact component={Dashboard} path={RoutesEnum.DASHBOARD} />
          <Redirect to={RoutesEnum.DASHBOARD} />
        </Switch>
      </AppLayout>

      <ReconcileModal key="animate-reconcile-modal" />
      <AddFundsModal key="animate-add-funds-modal" />
      <MoveMoneyModal key="animate-move-money-modal" />
      <SendInvoiceModal key="animate-send-invoice-modal" />
      <CreateSubAccountModal key="animate-create-sub-account-modal" />
      <SubAccountTransferModal key="animate-sub-account-transfer-modal" />
      <CardSetupModal key="animated-card-setup-modal" />
      <CardNotArrivedModal key="animated-card-not-arrived-modal" />
      <InvoicePaymentsModal key="animated-invoice-payment-modal" />
      <PlaidFundingModal key="animate-plaid-funding-modal" />
      <SubscriptionUpgradeModal key="animate-subscription-upgrade-modal" />
      <SubscriptionCancelModal key="animate-subscription-cancel-modal" />
      <SubscriptionReactivateModal key="animate-subscription-reactivate-modal" />
    </AnimatePresence>
  )
}
