export const subscriptions = {
  title: "Subscriptions", // Will be updated
  landing: {
    title: "Choose the best plan for your business",
    subtitle: "Companies of all sizes and stages thrive with NorthOne.",
    error: {
      subtitle: "Reload this page to try again",
      cta: "Reload this page",
    },
  },
  new: "New",
  currentPlan: "Current plan",
  reactivatePlan: "Reactivate {{planShortTitle}}",
  planInactive: "Plan inactive",
  activeUntil: "Active until {{nextBillingDate}}",
  planUpdatedToast: "Your plan has been updated",
  upgrade: {
    pageTitle: "Upgrade Plan",
    confirmDetails: {
      heading: "Confirm {{planName}}",
      subtitle: "Your plan starts immediately and will renew every month.",
      cta: "Upgrade to Plus",
      terms: `By pressing “{{upgradeCTA}},” you hereby authorize NorthOne to charge you {{monthlyPrice}} automatically every month until you cancel your subscription. You confirm you've read and agree to the Deposit Account Agreement, including our refund policy captured in the ‘Closing the Account’ section.`,
      error: {
        nsf: "Your account did not have enough funds to activate your plan. Please ensure your account has a balance of at least {{monthlyPrice}}, add funds and try again.",
        generic:
          "We were unable to activate your subscription. Please try again, or contact Customer Care.",
      },
      upgradeError:
        "We were unable to activate your subscription. Please try again, or contact Customer Care.",
    },
    success: {
      yourFeatures: "Your features",
      toast: "Your plan has been updated",
      titleDefault: "Welcome to your new plan",
    },
  },
  upsell: {
    details: {
      cta: "Upgrade for {{formattedMonthlyPrice}}/month",
    },
  },
  cancel: {
    pageTitle: "Cancel Plan",
    cta: "Cancel plan",
    retention: {
      title: "Are you sure you want to cancel {{planTitle}}?",
      subtitle:
        "If you cancel, you’ll lose access to your {{planShortTitle}} features after {{nextBillingDate}}",
      confirmButton: "Cancel {{planShortTitle}}",
      goBackButton: "Continue with {{planShortTitle}}",
      bulletsHeading: "You'll lose access to",
    },
    confirm: {
      title: "Cancel {{planTitle}}",
      subtitle:
        "{{planShortTitle}} will remain active until {{nextBillingDate}} then you will lose your benefits and no longer be charged.",
      confirmButton: "Cancel {{planShortTitle}}",
      goBackButton: "Continue with {{planShortTitle}}",
      error: "We were unable to cancel your {{planTitle}} plan. Retry, or contact customer care.",
    },
    success: {
      title: "{{planTitle}} has been canceled",
      subtitle:
        "Your account will no longer be billed and your membership will stay active until {{nextBillingDate}}",
      toast: "Your plan has been updated",
    },
  },
  reactivate: {
    pageTitle: "Reactivate Plan",
    cta: "Reactivate plan",
    title: "Reactivate {{planTitle}}",
    subtitle: "Your plan will restart immediately and will renew every month.",
    reactivatePlan: "Reactivate {{planShortTitle}}",
    terms:
      "By pressing “Reactivate {{planShortTitle}},” you hereby authorize NorthOne to charge you {{formattedMonthlyPrice}} automatically every month until you cancel your subscription. You confirm you've read and agree to the Deposit Account Agreement, including our refund policy captured in the ‘Closing the Account’ section.",
    error:
      "We were unable to reactivate your subscription. Please try again, or contact Customer Care.",
  },
  billingDetails: {
    title: "About Your Account",
    header: "Billing Details",
    depositAccount: "Deposit Account",
    pill: {
      active: "Active",
      popular: "Popular",
    },
    labels: {
      plan: "Your plan",
      monthlyFee: "Monthly fee",
      nextBillingDate: "Next billing date",
      finalBillingDate: "Final billing date",
      fromAccount: "From account",
      depositAccount: "Deposit Account",
    },
  },
}
