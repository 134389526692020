import React from "react"
import { useDispatch } from "react-redux"
import { ApolloError } from "@apollo/client/errors"
import { Analytics } from "@core/analytics/actions"
import { useAppSelector } from "@core/redux/utils"
import { FormBody } from "@components/composite/form-body/form-body"
import { ButtonTypeEnum } from "@components/primitive/button/button"
import { AmountInput } from "@components/extended/fields/amount-input"
import { ErrorScreen } from "@components/composite/error-screen/error-screen"
import { LoadingIcon } from "@components/primitive/loading-icon/loading-icon"
import { moveMoneyActions } from "@features/move-money/redux/actions"
import { selectAmount } from "@features/move-money/redux/selectors"
import { TMoveMoneyScreenNames } from "@features/move-money/router/move-money"
import {
  BUTTONS_CONTINUE,
  BUTTONS_GO_BACK,
  ERRORS_GENERIC,
  AMOUNT_TITLE,
} from "@features/move-money/utils/i18n-translations"

interface IMoveMoneyAmountForm {
  navigateNextRoute: TMoveMoneyScreenNames
  analyticsContinueEvent: string
  isLoading: boolean
  hasError: ApolloError | undefined
  retryQuery?: () => void
  validationText?: string
  continueBtnDisabled?: boolean
}

export const MoveMoneyAmountForm: React.FC<IMoveMoneyAmountForm> = ({
  navigateNextRoute,
  analyticsContinueEvent,
  isLoading,
  hasError,
  validationText,
  continueBtnDisabled,
}) => {
  const dispatch = useDispatch()
  const amount = useAppSelector(selectAmount)
  const amountAsNumber = Number(amount)

  const buttons = [
    {
      type: ButtonTypeEnum.TERTIARY,
      children: BUTTONS_GO_BACK,
      onClick: () => dispatch(moveMoneyActions.navigateBack()),
    },
    {
      canContinueWithKey: true,
      children: BUTTONS_CONTINUE,
      disabled: continueBtnDisabled,
      onClick: () => {
        dispatch(moveMoneyActions.navigateNext(navigateNextRoute))
        Analytics.track(analyticsContinueEvent, { amount: amountAsNumber })
      },
    },
  ]

  return (
    <FormBody title={AMOUNT_TITLE} buttons={buttons} errorMessage={hasError ? ERRORS_GENERIC : ""}>
      {hasError ? (
        <ErrorScreen size="medium" />
      ) : isLoading ? (
        <LoadingIcon />
      ) : (
        <AmountInput
          value={amount}
          onChange={(amount) => dispatch(moveMoneyActions.setAmount(amount))}
          errorMessage={validationText}
        />
      )}
    </FormBody>
  )
}
