import React from "react"
import { FlexColumnLayout } from "@layouts/flex"
import { Text } from "@components/primitive/text/text"
import {
  ISelectionControlProps,
  SelectionControl,
} from "@components/primitive/selection-control/selection-control"
import { theme } from "@layouts/theme"
import { SxStyleProp } from "rebass"

interface ICheckboxListItem<T>
  extends Omit<ISelectionControlProps, "selected" | "onSelect" | "type"> {
  value: T
  selected?: boolean
  onSelect?: () => void
  disabled?: boolean
}

export interface ICheckboxListProps<T> {
  label?: string
  values: Array<T>
  items: ICheckboxListItem<T>[]
  onValuesChange: (value: Array<T>) => void
  checkboxSx?: SxStyleProp
  checkboxTextSx?: SxStyleProp
  checkboxIconSx?: SxStyleProp
}

type TCheckboxList = <T>(props: ICheckboxListProps<T>) => React.ReactElement<ICheckboxListProps<T>>

export const CheckboxList: TCheckboxList = <T,>({
  label,
  items,
  values,
  onValuesChange,
  checkboxSx,
  checkboxIconSx,
  checkboxTextSx,
}: ICheckboxListProps<T>) => {
  return (
    <FlexColumnLayout>
      {label && (
        <Text sx={{ paddingBottom: "12px" }} textColor={theme.colors.ui2} tag="h5">
          {label}
        </Text>
      )}
      {items.map((item, index) => {
        const selected = values.includes(item.value)
        return (
          <SelectionControl
            disabled={item.disabled}
            containerSx={checkboxSx}
            textSx={checkboxTextSx}
            iconSx={checkboxIconSx}
            onSelect={() => {
              if (item.onSelect) {
                item.onSelect()
              } else {
                const newValues = selected
                  ? values.filter((value) => item.value !== value)
                  : [...values, item.value]
                onValuesChange(newValues)
              }
            }}
            selected={item.selected !== undefined ? item.selected : selected}
            type="checkbox"
            key={index}
            {...item}
          />
        )
      })}
    </FlexColumnLayout>
  )
}
