import React from "react"
import { useDispatch } from "react-redux"
import { useAppSelector } from "@core/redux/utils"
import { cardsActions } from "../redux/actions"
import { Modal } from "@northone/ui"
import { CardSetupRouter, CardSetupScreensEnum } from "./card-setup-router"
import { theme } from "@layouts/theme"

export const CardSetupModal: React.FC = () => {
  const isOpen = useAppSelector((state) => state.cards.cardSetupModalOpen)
  const currentScreen = useAppSelector((state) => state.cards.cardSetupModalScreen)
  const dispatch = useDispatch()
  const closeModal = () => dispatch(cardsActions.setCardActivationModalOpen(false))

  const isSuccessScreen = currentScreen === CardSetupScreensEnum.CARD_SETUP_PIN_SET_SUCCESS

  const modalStyles = isSuccessScreen
    ? {
        margin: 0,
        padding: 0,
        borderRadius: 0,
        backgroundColor: theme.colors.goldTint1,
      }
    : {
        width: 422,
        padding: 22,
      }

  return (
    <Modal
      isOpen={isOpen}
      onOutsideClick={closeModal}
      sx={modalStyles}
      closeButton={!isSuccessScreen}
    >
      {CardSetupRouter[currentScreen]}
    </Modal>
  )
}
