import React from "react"
import { Button, primaryGoldAltStyle } from "@components/primitive/button/button"
import { images } from "@assets/images/images"
import { SxStyleProp } from "rebass"

export const SubscriptionUpsellButton = ({
  text,
  onClick,
  sx,
}: {
  text: string
  onClick: () => void
  sx?: SxStyleProp
}) => (
  <Button
    onClick={onClick}
    iconOnRight
    iconSrc={images.icons.arrowDiagonalUp}
    textTag={"body-small-bold"}
    style={primaryGoldAltStyle}
    sx={{ justifyContent: "space-between", ...sx }}
  >
    {text}
  </Button>
)
