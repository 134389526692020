import React from "react"
import { useTranslation } from "react-i18next"

import { FlexRowLayout, Text } from "@northone/ui"
import { TransactionTypeEnum } from "@generated/graphql"
import { TransactionStatusPill } from "@components/extended/pill/transaction-status-pill"
import { getTransactionTypeCopy } from "@utils/mapping/transactions.mapping"
import {
  isCardTransaction,
  isMailedCheckTransaction,
  isOutgoingWire,
  TTransactionPanelStatus,
  TTransactionPanelType,
} from "./utils"
import { TransactionPanelItem } from "./base-transaction-panel"
import { TransactionTypeDetails } from "./transaction-type-details"
import { MailedCheckContent } from "./mailed-check-panel-content"

interface IProps {
  type: TTransactionPanelType
  status: TTransactionPanelStatus
  amount: number
  returnedBy: string | undefined
  cardLast4: string | undefined
}

export const TransactionPanelPaymentMethod = ({
  type,
  status,
  returnedBy,
  amount,
  cardLast4,
}: IProps) => {
  const { t } = useTranslation()

  if (!type) return null

  const transactionTypeCopy =
    type === TransactionTypeEnum.SUB_ACCOUNT_TRANSFER
      ? t("transactions.types.envelopeAdjustment")
      : getTransactionTypeCopy(type, amount) + (cardLast4 ? ` (...${cardLast4})` : "")

  const hasTransactionStatusPill =
    !isCardTransaction(type) && !isOutgoingWire(amount, type) && !isMailedCheckTransaction(type)

  return (
    <TransactionPanelItem label={t("dashboard.transactionPanel.method")}>
      <FlexRowLayout>
        {transactionTypeCopy && <Text tag="body">{transactionTypeCopy}</Text>}
        {status && hasTransactionStatusPill && <TransactionStatusPill status={status} />}
      </FlexRowLayout>
      {status && isMailedCheckTransaction(type) && (
        <MailedCheckContent status={status} type={type} />
      )}
      {
        <TransactionTypeDetails
          returnedBy={returnedBy}
          transactionType={type}
          transactionStatus={status}
        />
      }
    </TransactionPanelItem>
  )
}
