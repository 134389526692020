import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Box, Image } from "rebass"
import { Text } from "@components/primitive/text/text"
import { Button, ButtonTypeEnum } from "@components/primitive/button/button"
import { images } from "@assets/images/images"
import { FlexColumnLayout } from "@layouts/flex"
import { theme } from "@layouts/theme"
import { config } from "@utils/environment"
import { useWindowDimensions } from "@utils/hooks"
import { regex } from "@utils/regex"

const SPLASH_SCREEN_MAX_WIDTH = 900
const MINIMUM_RESPONSIVE_WIDTH = Number(theme.breakpoints[1].replace(regex.notNumber, ""))

export const deepLinkToLogin = () => (window.location.href = "https://northone.app.link/page=logIn")

interface ResponsiveWrapperProps {
  children?: React.ReactNode
}

export const ResponsiveWrapper = ({ children }: ResponsiveWrapperProps) => {
  const { t } = useTranslation()
  const { width } = useWindowDimensions()
  const [isForcedDesktop, setIsForcedDesktop] = useState(config.disableWindowSizeRestriction)

  useEffect(() => {
    if (isForcedDesktop && width > SPLASH_SCREEN_MAX_WIDTH) {
      setIsForcedDesktop(false)
    }
  }, [width, isForcedDesktop])

  return width < SPLASH_SCREEN_MAX_WIDTH && !isForcedDesktop ? (
    <Box
      sx={{
        paddingTop: [10, "5%"],
        paddingBottom: [10, "15%"],
        bg: theme.colors.gold10,
        height: "100vh",
        overflowY: "auto",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: ["column", "row"],
          justifyContent: ["flex-start", "center"],
        }}
      >
        <FlexColumnLayout
          sx={{
            width: "100%",
            justifyContent: ["start", "center"],
            paddingLeft: [20, "10%"],
            paddingRight: [20, "10%"],
          }}
        >
          <Image sx={{ flexShrink: 0, alignSelf: "center", marginTop: 20 }} src={images.logo} />
          <Image
            src={images.phoneCardRating}
            sx={{
              imageRendering: "-webkit-optimize-contrast",
              marginTop: [30, 45],
              width: "215px",
              alignSelf: "center",
            }}
          />
          <Text
            sx={{ alignSelf: "center", textAlign: "center", marginBottom: 20, maxWidth: "343px" }}
            tag="h3Serif"
          >
            {t("responsive.title")}
          </Text>
          <Button
            type={ButtonTypeEnum.PRIMARY_BLUE}
            sx={{ width: "343px", marginBottom: 10 }}
            onClick={deepLinkToLogin}
            centerJustified
          >
            {t("responsive.primaryButton")}
          </Button>
          <Button
            onClick={() => {
              const viewport = document.getElementById("viewport")
              setIsForcedDesktop(true)
              if (viewport) {
                viewport.setAttribute("content", "width=1281, initial-scale=0")
              }
            }}
            type={ButtonTypeEnum.SECONDARY}
            sx={{ width: "343px" }}
            centerJustified
          >
            {t("responsive.secondaryButton")}
          </Button>
        </FlexColumnLayout>
      </Box>
    </Box>
  ) : (
    <Box sx={{ overflow: "auto" }}>
      <Box sx={{ width: width < MINIMUM_RESPONSIVE_WIDTH ? MINIMUM_RESPONSIVE_WIDTH : "100vw" }}>
        {children}
      </Box>
    </Box>
  )
}
