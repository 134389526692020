import React from "react"
import { motion } from "framer-motion"

export const PREVIEW_PANEL_TRANSITION = {
  duration: 0.75,
  delay: 0.5,
  ease: "easeInOut",
}

interface PreviewPanelAnimationProps {
  children?: React.ReactNode
}

export const PreviewPanelAnimation = ({ children }: PreviewPanelAnimationProps) => (
  <motion.div
    initial={{
      x: "141.5%",
    }}
    animate={{
      x: ["141.5%", "0%"],
      transition: PREVIEW_PANEL_TRANSITION,
    }}
    style={{
      width: "24%",
    }}
  >
    {children}
  </motion.div>
)
