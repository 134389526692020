import React, { useState } from "react"
import { Box, Image, SxStyleProp } from "rebass"
import copy from "copy-to-clipboard"
import { useTranslation } from "react-i18next"
import { FlexColumnLayout } from "@layouts/flex"
import { theme } from "@layouts/theme"
import { Text } from "@components/primitive/text/text"
import { images } from "@assets/images/images"
import { ToolTip } from "@components/primitive/tool-tip/tool-tip"
import { appActions } from "@core/redux/app/actions"
import { useDispatch } from "react-redux"

interface ICopyableField {
  label: string
  value: string | number
  info?: string
  sx?: SxStyleProp
  sxInput?: SxStyleProp
  sxInputText?: React.CSSProperties
  isShareAndEarn?: boolean
  showToastOnCopy?: boolean
  onCopyClicked?: () => Promise<void>
}

interface ILabelAndHelperIcon {
  label: string
  info?: string
}

const LabelAndHelperIcon: React.FC<ILabelAndHelperIcon> = ({ label, info }) => {
  return (
    <>
      <Text tag="h5" textColor={theme.colors.ui2} sx={{ cursor: "default" }}>
        {label}
      </Text>
      {info && <Image sx={{ marginLeft: "4px" }} src={images.icons.help} />}
    </>
  )
}

export const CopyableField: React.FC<ICopyableField> = ({
  label,
  value,
  info,
  sx,
  sxInput,
  sxInputText,
  isShareAndEarn,
  showToastOnCopy,
  onCopyClicked,
}) => {
  const { t } = useTranslation()
  const [hovering, setHovering] = useState(false)
  const [hoveringCopyText, setCopyTextHovering] = useState(false)
  const [toolTipVisible, setToolTipVisible] = useState(false)
  const [infoVisible, setInfoVisible] = useState(false)
  const dispatch = useDispatch()

  const copyField = async () => {
    copy(value.toString())
    if (showToastOnCopy) {
      dispatch(appActions.setNotificationMessage(t("copiedToClipboard")))
    } else {
      setToolTipVisible(true)
      setTimeout(() => setToolTipVisible(false), 1000)
    }
    onCopyClicked && (await onCopyClicked())
  }

  const copyIcon = isShareAndEarn ? (
    <Box
      sx={{ height: 50, width: 49, flexShrink: 0, position: "relative", left: 11 }}
      onMouseEnter={() => setCopyTextHovering(true)}
      onMouseLeave={() => setCopyTextHovering(false)}
      onClick={copyField}
    >
      {hoveringCopyText ? (
        <Image src={images.icons.copyTextIcon} />
      ) : (
        <Image src={images.icons.copyTextHoverIcon} />
      )}
    </Box>
  ) : (
    <Box sx={{ height: "16px", width: "16px", flexShrink: 0 }}>
      {hovering && <Image src={images.icons.copy} />}
    </Box>
  )

  return (
    <FlexColumnLayout sx={{ flex: 1, ...sx }}>
      <ToolTip
        width={180}
        text={info || ""}
        position="right"
        visible={Boolean(infoVisible && info)}
        sxProps={isShareAndEarn ? { display: "none" } : null}
      >
        <Box
          tabIndex={0}
          onClick={() => setInfoVisible(true)}
          sx={{
            cursor: "pointer",
            display: "inline-flex",
            outline: "none",
          }}
          onBlur={() => setInfoVisible(false)}
        >
          <LabelAndHelperIcon label={label} info={info} />
        </Box>
      </ToolTip>
      <ToolTip position="top" text={t("copiedToClipboard")} visible={toolTipVisible}>
        <Box
          onMouseEnter={() => setHovering(true)}
          onMouseLeave={() => setHovering(false)}
          sx={{
            display: "flex",
            flexDirection: "row",
            marginTop: "4px",
            backgroundColor: theme.colors.ui7,
            border: `1px solid ${theme.colors.ui4}`,
            paddingLeft: "12px",
            paddingY: "8px",
            paddingRight: "8px",
            borderRadius: "4px",
            justifyContent: "space-between",
            alignItems: "center",
            cursor: "pointer",
            ...sxInput,
          }}
          onClick={!isShareAndEarn ? copyField : undefined}
        >
          <Text textColor={theme.colors.ui2} style={{ ...sxInputText }} tag="body-small">
            {value}
          </Text>
          {copyIcon}
        </Box>
      </ToolTip>
    </FlexColumnLayout>
  )
}
