import React, { useRef, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useRouteMatch, useHistory, useLocation } from "react-router-dom"
import { useDispatch } from "react-redux"

import { useAppSelector } from "@core/redux/utils"
import { RoutesEnum, ILocationState } from "@routers/types"
import { FlowModal } from "@components/extended/overlay/flow-modal"
import {
  selectAddFundsScreenHistory,
  selectAddFundsFundingMethod,
} from "@features/add-funds/redux/selectors"
import { navigateFromModal } from "@utils/navigation"
import { toTitleCase } from "@utils/format"

import { ADD_FUNDS_ROUTES } from "./add-funds.router"
import { addFundsActions } from "./redux/actions"
import { AddFundsScreensEnum } from "./add-funds.router"

export const AddFundsModal: React.FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation<ILocationState>()
  const ref = useRef<HTMLDivElement>(null)
  const isRouteMatch = useRouteMatch({ path: RoutesEnum.ADD_FUNDS })
  const screenHistory = useAppSelector(selectAddFundsScreenHistory)
  const fundingMethod = useAppSelector(selectAddFundsFundingMethod)
  const currentRoute = screenHistory[screenHistory.length - 1]
  const currentScreen = ADD_FUNDS_ROUTES[currentRoute]

  useEffect(() => {
    if (ref && ref.current) {
      ref.current.scrollTo({ top: 0 })
    }
  }, [currentScreen])

  const isOpen = Boolean(isRouteMatch?.isExact)
  const pageTitle =
    fundingMethod && currentRoute !== AddFundsScreensEnum.ADD_FUNDS_OPTIONS
      ? t("pageTitles.moveMoney.addFlow", {
          fundingMethod: toTitleCase({ text: fundingMethod }),
        })
      : t("pageTitles.moveMoney.add")

  return (
    <FlowModal
      containerStyle={
        currentRoute === AddFundsScreensEnum.ADD_FUNDS_ATM
          ? { width: "70%" }
          : currentRoute === AddFundsScreensEnum.ADD_FUNDS_CHECK
          ? { width: "90%" }
          : undefined
      }
      pageTitle={pageTitle}
      scrollContainerRef={ref}
      layout={currentScreen.layout}
      isOpen={isOpen}
      onOutsideClick={() => {
        dispatch(addFundsActions.clearState())
        navigateFromModal({ defaultBackRoute: RoutesEnum.DASHBOARD, history, location })
      }}
    >
      {currentScreen.component}
    </FlowModal>
  )
}
