import React, { useEffect } from "react"
import { Button as RButton, SxStyleProp, Image, Box } from "rebass"
import { LoadingIcon } from "@northone/ui"

import { theme } from "@layouts/theme"
import { Text, TTextTag } from "../text/text"
import { FlexRowLayout } from "@layouts/flex"

export interface IButtonProps {
  onClick?: () => void
  onMouseOver?: () => void
  onMouseOut?: () => void
  type?: ButtonTypeEnum
  isActivated?: boolean
  disabled?: boolean
  iconSrc?: string
  style?: IButtonStyle
  textTag?: TTextTag
  textSx?: SxStyleProp
  children?: React.ReactNode
  isLoading?: boolean
  sx?: SxStyleProp
  iconSx?: SxStyleProp
  canContinueWithKey?: boolean
  iconOnRight?: boolean
  centerJustified?: boolean
  id?: string
}

export enum ButtonTypeEnum {
  PRIMARY_GOLD = "PRIMARY_GOLD",
  PRIMARY_BLACK = "PRIMARY_BLACK",
  PRIMARY_BLUE = "PRIMARY_BLUE",
  SECONDARY = "SECONDARY",
  TERTIARY = "TERTIARY",
  TERTIARY_ALT = "TERTIARY_ALT",
  TERTIARY_SHORT = "TERTIARY_SHORT",
}

export const getDarkerBackground = (color: string) =>
  `linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), ${color}`

interface IButtonStyle {
  enabledStyle: SxStyleProp & { textColor: string }
  disabledStyle: SxStyleProp & { textColor: string }
  activatedStyle?: SxStyleProp & { textColor: string }
  loadingStyle?: SxStyleProp & { textColor: string }
}

// TODO: Implement a button type with no styling and only text content
const buttonTypeStyleMap: {
  [key in ButtonTypeEnum]: IButtonStyle
} = {
  PRIMARY_GOLD: {
    enabledStyle: {
      backgroundColor: theme.colors.gold100,
      borderColor: "transparent",
      ":hover": {
        backgroundColor: theme.colors.gold80,
      },
      ":active": {
        background: getDarkerBackground(theme.colors.gold100),
      },
      textColor: theme.colors.ui7,
    },
    loadingStyle: {
      backgroundColor: theme.colors.gold80,
      borderColor: "transparent",
      textColor: theme.colors.ui7,
    },
    disabledStyle: {
      borderColor: "transparent",
      backgroundColor: theme.colors.gold30,
      textColor: theme.colors.ui7,
    },
  },
  PRIMARY_BLACK: {
    enabledStyle: {
      backgroundColor: theme.colors.blackPrimary,
      borderColor: theme.colors.blackPrimary,
      ":hover": {
        backgroundColor: theme.colors.blackTint1,
        borderColor: theme.colors.blackTint1,
      },
      ":active": {
        backgroundColor: theme.colors.blackPrimary,
        borderColor: theme.colors.blackTint1,
      },
      textColor: theme.colors.ui7,
    },
    disabledStyle: {
      backgroundColor: theme.colors.blackTint2,
      textColor: theme.colors.ui7,
    },
  },
  PRIMARY_BLUE: {
    enabledStyle: {
      textColor: theme.colors.ui7,
      backgroundColor: theme.colors.blue100,
      borderColor: "transparent",
      ":hover": {
        backgroundColor: theme.colors.blue80,
      },
      ":active": {
        background: getDarkerBackground(theme.colors.blue100),
      },
    },
    loadingStyle: {
      backgroundColor: theme.colors.blue80,
      borderColor: "transparent",
      textColor: theme.colors.ui7,
    },
    disabledStyle: {
      borderColor: "transparent",
      backgroundColor: theme.colors.blue30,
      textColor: theme.colors.ui7,
    },
  },
  SECONDARY: {
    enabledStyle: {
      backgroundColor: theme.colors.ui5,
      borderColor: "transparent",
      ":hover": {
        backgroundColor: theme.colors.ui6,
      },
      ":active": {
        backgroundColor: theme.colors.ui4,
      },
      textColor: theme.colors.ui1,
    },
    loadingStyle: {
      backgroundColor: theme.colors.ui5,
      borderColor: "transparent",
      textColor: theme.colors.ui1,
    },
    disabledStyle: {
      borderColor: "transparent",
      backgroundColor: theme.colors.ui6,
      textColor: theme.colors.ui3,
    },
  },
  TERTIARY: {
    enabledStyle: {
      backgroundColor: theme.colors.ui7,
      borderColor: theme.colors.ui4,
      ":hover": {
        backgroundColor: theme.colors.ui6,
      },
      ":active": {
        borderColor: "transparent",
        backgroundColor: theme.colors.ui5,
      },
      textColor: theme.colors.ui1,
    },
    disabledStyle: {
      borderColor: theme.colors.ui5,
      backgroundColor: theme.colors.ui7,
      textColor: theme.colors.ui1,
    },
  },
  TERTIARY_ALT: {
    enabledStyle: {
      backgroundColor: theme.colors.ui7,
      borderColor: theme.colors.ui4,
      ":hover": {
        backgroundColor: theme.colors.ui6,
      },
      ":active": {
        backgroundColor: theme.colors.ui5,
        borderColor: "transparent",
      },
      textColor: theme.colors.ui2,
    },
    disabledStyle: {
      borderColor: theme.colors.ui5,
      backgroundColor: theme.colors.ui7,
      textColor: theme.colors.ui4,
    },
    activatedStyle: {
      borderColor: "transparent",
      backgroundColor: theme.colors.gold30,
      textColor: theme.colors.ui1,
    },
  },
  TERTIARY_SHORT: {
    enabledStyle: {
      backgroundColor: theme.colors.ui7,
      borderColor: theme.colors.ui4,
      ":hover": {
        backgroundColor: theme.colors.ui6,
      },
      ":active": {
        backgroundColor: theme.colors.ui5,
        borderColor: "transparent",
      },
      textColor: theme.colors.ui2,
    },
    disabledStyle: {
      borderColor: theme.colors.ui5,
      backgroundColor: theme.colors.ui7,
      textColor: theme.colors.ui4,
    },
    activatedStyle: {
      borderColor: "transparent",
      backgroundColor: theme.colors.gold30,
      textColor: theme.colors.ui1,
    },
  },
}

export const primaryGoldAltStyle = {
  enabledStyle: {
    backgroundColor: theme.colors.goldTint4,
    borderColor: "transparent",
    ":hover": {
      backgroundColor: theme.colors.goldTint3,
    },
    ":active": {
      background: getDarkerBackground(theme.colors.goldTint4),
    },
    textColor: theme.colors.ui1,
  },
  loadingStyle: {
    backgroundColor: theme.colors.goldTint4,
    borderColor: "transparent",
    textColor: theme.colors.ui1,
  },
  disabledStyle: {
    borderColor: "transparent",
    backgroundColor: theme.colors.goldTint4,
    textColor: theme.colors.ui1,
  },
}

const ENTER_KEY = "Enter"

/**
 * Defines a primitive button component - Must be extended
 * @param props.children
 */
export const Button: React.FC<IButtonProps> = ({
  children,
  onClick,
  onMouseOver,
  onMouseOut,
  type = ButtonTypeEnum.PRIMARY_GOLD,
  disabled = false,
  isActivated = false,
  iconSrc,
  style,
  textTag = "body-bold",
  textSx,
  isLoading = false,
  sx,
  iconSx,
  canContinueWithKey,
  iconOnRight = false,
  centerJustified,
  id,
}) => {
  const buttonIsEnabled = !disabled && !isLoading
  const buttonStyles = style || buttonTypeStyleMap[type]
  const buttonStyle = disabled
    ? buttonStyles.disabledStyle
    : isLoading
    ? buttonStyles.loadingStyle || buttonStyles.disabledStyle
    : {
        ...buttonStyles.enabledStyle,
        ...(isActivated ? buttonStyles.activatedStyle : {}),
      }

  const isTertiaryAlt =
    type === ButtonTypeEnum.TERTIARY_ALT || type === ButtonTypeEnum.TERTIARY_SHORT

  useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      if (onClick && canContinueWithKey) {
        if (buttonIsEnabled && event.key === ENTER_KEY) {
          onClick()
        }
      }
    }
    document.addEventListener("keydown", onKeyDown)
    return () => {
      document.removeEventListener("keydown", onKeyDown)
    }
  })
  const showButtonIcon = iconSrc && !isLoading
  return (
    <FlexRowLayout
      sx={{
        alignItems: "center",
        justifyContent: centerJustified ? "center" : null,
      }}
    >
      <RButton
        id={id}
        disabled={disabled || isLoading}
        onClick={onClick}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        sx={{
          borderRadius: 4,
          paddingX: iconSrc || isLoading ? (isTertiaryAlt ? "8px" : "12px") : "20px",
          paddingRight:
            (iconSrc && children && !iconOnRight) || isLoading
              ? isTertiaryAlt
                ? "13px"
                : "20px"
              : undefined,
          borderWidth: "1px",
          borderStyle: "solid",
          cursor: disabled || isLoading ? "default" : "pointer",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: type === ButtonTypeEnum.TERTIARY_SHORT ? 26 : 40,
          paddingY: 0,
          ":focus": {
            outlineColor: theme.focusOutline,
          },
          ...buttonStyle,
          ...sx,
        }}
      >
        {isLoading && (
          <Box sx={{ marginRight: isTertiaryAlt ? "4px" : "7px" }}>
            <LoadingIcon />
          </Box>
        )}
        {showButtonIcon && (
          <Image
            src={iconSrc}
            sx={{
              ...(children && {
                order: iconOnRight ? 2 : 0,
                marginRight: iconOnRight ? 0 : isTertiaryAlt ? "4px" : "7px",
                marginLeft: iconOnRight ? "4px" : 0,
              }),
              ...iconSx,
            }}
          />
        )}
        <Text
          textColor={disabled || isLoading ? buttonStyle.textColor : buttonStyle.textColor}
          tag={isTertiaryAlt ? "h5" : textTag}
          sx={textSx}
        >
          {children}
        </Text>
      </RButton>
    </FlexRowLayout>
  )
}
