import React from "react"
import { useDispatch } from "react-redux"
import { Analytics } from "@core/analytics/actions"
import { useAppSelector } from "@core/redux/utils"
import { selectActiveBusinessID } from "@core/active-business/redux/selectors"
import { ErrorScreen } from "@components/composite/error-screen/error-screen"
import { FormBody } from "@components/composite/form-body/form-body"
import { ButtonTypeEnum } from "@components/primitive/button/button"
import { SubAccountList } from "@components/composite/sub-account-list/sub-account-list"
import { LoadingIcon } from "@components/primitive/loading-icon/loading-icon"
import { useSubAccountsQuery } from "@generated/graphql"
import { TMoveMoneyScreenNames } from "@features/move-money/router/move-money"
import { moveMoneyActions } from "@features/move-money/redux/actions"
import {
  BUTTONS_GO_BACK,
  BUTTONS_CONTINUE,
  SUBACCOUNT_TITLE,
} from "@features/move-money/utils/i18n-translations"
import { selectSubAccount, selectAmount } from "@features/move-money/redux/selectors"

interface IMoveMoneySubAccountFormProps {
  navigateNextRoute: TMoveMoneyScreenNames
  continueEvent: string
  selectEvent: string
  paymentFee?: number
}

export const MoveMoneySubAccountForm: React.FC<IMoveMoneySubAccountFormProps> = ({
  navigateNextRoute,
  continueEvent,
  selectEvent,
  paymentFee = 0,
}) => {
  const dispatch = useDispatch()
  const subAccount = useAppSelector(selectSubAccount)
  const amount = useAppSelector(selectAmount)
  const businessId: string = useAppSelector(selectActiveBusinessID)

  const { data, loading, error, refetch } = useSubAccountsQuery({
    variables: { businessId },
    notifyOnNetworkStatusChange: true,
  })

  const buttons = [
    {
      type: ButtonTypeEnum.TERTIARY,
      children: BUTTONS_GO_BACK,
      onClick: () => dispatch(moveMoneyActions.navigateBack()),
    },
    {
      canContinueWithKey: true,
      type: ButtonTypeEnum.PRIMARY_GOLD,
      disabled: !subAccount?.id,
      children: BUTTONS_CONTINUE,
      onClick: () => {
        Analytics.track(continueEvent)
        dispatch(moveMoneyActions.navigateNext(navigateNextRoute))
      },
    },
  ]

  return (
    <FormBody title={SUBACCOUNT_TITLE} buttons={buttons}>
      {error ? (
        <ErrorScreen size="medium" retry={() => refetch()} />
      ) : loading ? (
        <LoadingIcon />
      ) : (
        <SubAccountList
          subAccounts={data?.business?.bankAccount?.subAccounts || []}
          selectedSubAccountId={subAccount?.id}
          onSelect={(subAccount) => {
            Analytics.track(selectEvent, { subAccountName: subAccount.name })
            dispatch(moveMoneyActions.setSubAccount(subAccount))
          }}
          paymentAmount={Number(amount?.replace(/,/g, "")) + paymentFee}
          totalAvailableBalance={data?.business?.bankAccount?.availableBalance || 0}
          insufficientBalanceCondition={!amount ? "through" : "to"}
        />
      )}
    </FormBody>
  )
}
