import React from "react"
import { Panel } from "@components/primitive/panel/panel"
import { LoadingIcon } from "@components/primitive/loading-icon/loading-icon"

interface ILoadingPanelProps {
  height?: number
  iconDimension?: number
}
export const LoadingPanel: React.FC<ILoadingPanelProps> = ({ iconDimension, height }) => (
  <Panel
    sx={{ height: height || 500, display: "flex", justifyContent: "center", alignItems: "center" }}
  >
    <LoadingIcon dimension={iconDimension} />
  </Panel>
)
