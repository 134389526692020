import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Box } from "rebass"

import { Text } from "@components/primitive/text/text"
import { BaseLayout } from "@layouts/base-layout"
import { PlaidAccountType, PlaidItem } from "@generated/graphql"
import { QueryResults } from "@components/composite/query-results/query-results"
import { AnimatePresence, ErrorBanner } from "@northone/ui"

import { EmptyState } from "./empty-state"
import { LinkedAccountsList } from "./linked-accounts-list"
import { AccountTypeModal } from "./modals/account-type-modal"
import { CreateSuccessModal } from "./modals/create-success-modal"
import { DeleteConfirmationModal } from "./modals/delete-confirmation-modal"
import { useCreateWorkflow } from "./hooks/useCreateWorkflow"
import { usePlaidItems } from "./hooks/useGetPlaidItems"
import { useRemovePlaidItem } from "./hooks/useRemovePlaidItem"
import { useFindTopUpsItem } from "./hooks/useFindTopUpsItem"
import { useLocation } from "react-router-dom"
import { Analytics } from "@core/analytics/actions"
import { events } from "@core/analytics/events"

type LinkAccountsPanelProps = {
  plaidItems?: PlaidItem[]
  linkedTopUpPlaidItemId?: string
  onCreate: () => void
  onDelete: (plaidItem: PlaidItem) => void
  createLoading: boolean
  maximumItemCount: number
}

export function LinkedAccountsPanel({
  plaidItems,
  linkedTopUpPlaidItemId,
  onCreate,
  onDelete,
  createLoading,
  maximumItemCount,
}: LinkAccountsPanelProps) {
  if (plaidItems?.length)
    return (
      <LinkedAccountsList
        onCreate={onCreate}
        linkedTopUpPlaidItemId={linkedTopUpPlaidItemId}
        plaidItems={plaidItems}
        maximumItemCount={maximumItemCount}
        loading={createLoading}
        onDelete={onDelete}
      />
    )
  return <EmptyState onCreate={onCreate} loading={createLoading} />
}

export function LinkedAccountsLanding() {
  const { t } = useTranslation()
  const [plaidItemToDelete, setPlaidItemToDelete] = useState<PlaidItem>()

  const {
    loading: itemsLoading,
    plaidItems,
    capabilities,
    refetch,
    error: plaidItemsError,
  } = usePlaidItems()

  const {
    errorMessageKey: createErrorMessageKey,
    loading,
    createNewItem,
    newItemId,
    shouldShowAccountTypeModal,
    closeAccountTypeModal,
    handleAccountTypeModalContinue,
    shouldShowSuccessModal,
    handleSuccessModalContinue,
  } = useCreateWorkflow({ refetch })

  const {
    loading: removeItemLoading,
    removePlaidItem,
    errorMessageKey: deleteErrorMessageKey,
  } = useRemovePlaidItem({
    plaidItemToDelete,
    onCompleted: () => {
      refetch()
      handleResetDelete()
    },
  })

  const location = useLocation()
  const { linkedTopUpPlaidItemId, loading: findTopUpsItemLoading } = useFindTopUpsItem({
    cacheCheckKey: location.key,
  })

  function onDeleteClicked(plaidItem: PlaidItem) {
    Analytics.track(events.linkedAccounts.deleteAccountIconClick)
    setPlaidItemToDelete(plaidItem)
  }

  function handleResetDelete() {
    setPlaidItemToDelete(undefined)
  }

  function onCreateClicked() {
    Analytics.track(events.linkedAccounts.addAccountClick)
    createNewItem()
  }

  function onDeleteConfirmationContinueClicked() {
    Analytics.track(events.linkedAccounts.deleteAccountModalClick)
    removePlaidItem()
  }

  function onAccountTypeModalContinueClicked({ accountType }: { accountType: PlaidAccountType }) {
    Analytics.track(events.linkedAccounts.accountTypeSelected, { accountType })
    handleAccountTypeModalContinue({ accountType })
  }

  const newPlaidItem = plaidItems?.find((item) => item.id == newItemId)
  const errorMessageKey = createErrorMessageKey || deleteErrorMessageKey
  const panelLoading = itemsLoading || findTopUpsItemLoading

  return (
    <>
      <BaseLayout pageTitle={t("pageTitles.settings.external")}>
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Text tag="h2">{t("settings.pages.linkedAccount.title")}</Text>
          <AnimatePresence animation="fadeAndExpand">
            {errorMessageKey && <ErrorBanner text={t(errorMessageKey)} />}
          </AnimatePresence>
          <QueryResults
            loading={panelLoading}
            listShimmerCount={2}
            error={plaidItemsError}
            retry={refetch}
            size="medium"
          >
            {capabilities && (
              <LinkedAccountsPanel
                linkedTopUpPlaidItemId={linkedTopUpPlaidItemId}
                plaidItems={plaidItems}
                onCreate={onCreateClicked}
                onDelete={onDeleteClicked}
                createLoading={loading}
                maximumItemCount={capabilities.maximumItemCount}
              />
            )}
          </QueryResults>
        </Box>
      </BaseLayout>
      <AccountTypeModal
        isOpen={shouldShowAccountTypeModal}
        onContinue={onAccountTypeModalContinueClicked}
        onClose={closeAccountTypeModal}
      />
      <CreateSuccessModal
        isOpen={shouldShowSuccessModal}
        plaidItem={newPlaidItem}
        onContinue={handleSuccessModalContinue}
      />
      <DeleteConfirmationModal
        isOpen={plaidItemToDelete != undefined}
        plaidItem={plaidItemToDelete}
        loading={removeItemLoading}
        onContinue={onDeleteConfirmationContinueClicked}
        onCancel={handleResetDelete}
        plaidItemUsedInTopUps={plaidItemToDelete?.id == linkedTopUpPlaidItemId}
      />
    </>
  )
}
