import React, { ReactNode } from "react"
import { noop } from "lodash-es"

import { Modal } from "@components/extended/overlay/modal"
import { InfoBlock } from "@components/composite/info-block/info-block"
import { ButtonTypeEnum } from "@components/primitive/button/button"
import { theme } from "@layouts/theme"

export interface IInvoicePromptModalProps {
  isOpen: boolean
  closeOverlay?: () => void
  title: string
  subTitle: string
  buttons: Array<{
    type: ButtonTypeEnum
    children: ReactNode
    onClick: () => void
    isLoading?: boolean
  }>
}

export const InvoicePromptModal = ({
  isOpen,
  closeOverlay,
  title,
  subTitle,
  buttons,
}: IInvoicePromptModalProps) => {
  return (
    <Modal isOpen={isOpen} zIndex={theme.zIndex.dialogModal} onOutsideClick={closeOverlay || noop}>
      <InfoBlock title={title} subtitle={subTitle} buttons={buttons} />
    </Modal>
  )
}
