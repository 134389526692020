import React, { useEffect, useState } from "react"
import { auth } from "@core/auth/auth-service"
import { EmailGate } from "./email-gate"

interface EmailVerifyWrapperProps {
  children?: React.ReactNode
}

export const EmailVerifyWrapper = ({ children }: EmailVerifyWrapperProps) => {
  const [emailIsVerified, setEmailIsVerified] = useState(false)
  const [email, setEmail] = useState("")

  useEffect(() => {
    const checkEmailVerifiedClaim = async () => {
      const user = await auth.getUser()
      if (user?.email && user?.email_verified) {
        setEmail(user.email)
        setEmailIsVerified(user.email_verified)
      }
    }
    checkEmailVerifiedClaim()
  })

  return <>{!emailIsVerified && email ? <EmailGate email={email} /> : children}</>
}
