import React from "react"
import { Button } from "@components/primitive/button/button"
import { images } from "@assets/images/images"
import { theme } from "@layouts/theme"
import { SxStyleProp } from "rebass"

interface IDeleteButtonProps {
  onClick: () => void
  disabled?: boolean
  sx?: SxStyleProp
}

export const DeleteButton: React.FC<IDeleteButtonProps> = ({ onClick, disabled, sx }) => (
  <Button
    onClick={onClick}
    style={{
      enabledStyle: {
        backgroundColor: theme.colors.red100,
        textColor: "",
        ":hover": { backgroundColor: theme.colors.red80 },
        ":active": { backgroundColor: theme.colors.red100 },
      },
      disabledStyle: { backgroundColor: theme.colors.red80, textColor: "" },
    }}
    sx={{ paddingX: "6px", ...sx }}
    iconSrc={images.icons.trash}
    iconSx={{ margin: 0 }}
    disabled={disabled}
  />
)
