import React, { useRef, useContext } from "react"
import { useDay } from "@datepicker-react/hooks"
import DatepickerContext from "./datepicker-context"
import { Button } from "rebass"
import { Text } from "../text/text"
import { theme } from "@layouts/theme"

interface IDayProps {
  dayLabel: string
  date: Date
  isActive: boolean
  today: Date
  minDate?: Date | null
  maxDate?: Date | null
}

export const Day: React.FC<IDayProps> = ({ dayLabel, date, isActive, today, minDate, maxDate }) => {
  const dayRef = useRef(null)

  const {
    focusedDate,
    isDateFocused,
    isDateSelected,
    isDateHovered,
    isDateBlocked,
    isFirstOrLastSelectedDate,
    onDateSelect,
    onDateFocus,
    onDateHover,
  } = useContext(DatepickerContext)
  const { onClick, tabIndex } = useDay({
    date,
    focusedDate,
    isDateFocused,
    isDateSelected,
    isDateHovered,
    isDateBlocked,
    isFirstOrLastSelectedDate,
    onDateFocus,
    onDateSelect,
    onDateHover,
    dayRef,
  })

  const isDisabled = Boolean(
    (minDate && date < minDate) || (maxDate && date > maxDate) || isDateBlocked(date),
  )

  if (!dayLabel) {
    return <React.Fragment />
  }

  return (
    <Button
      onClick={onClick}
      tabIndex={tabIndex}
      type="button"
      ref={dayRef}
      disabled={isDisabled}
      sx={{
        outline: "none",
        padding: "8px",
        border:
          Number(date) === Number(today)
            ? `1px solid ${theme.colors.ui4}`
            : `1px solid transparent`,
        width: 40,
        height: 40,
        borderRadius: 0,
        color: theme.colors.ui7,
        backgroundColor: isDisabled
          ? theme.colors.ui7
          : isActive
          ? theme.colors.blue100
          : theme.colors.ui7,
        ":hover": {
          backgroundColor: isDisabled
            ? theme.colors.ui7
            : isActive
            ? theme.colors.blue100
            : theme.colors.ui6,
        },
        ":active": {
          backgroundColor: isDisabled ? theme.colors.ui7 : theme.colors.blue10,
        },
        cursor: isDisabled ? "default" : "pointer",
      }}
    >
      <Text
        tag={"body"}
        textColor={isDisabled ? theme.colors.ui4 : isActive ? theme.colors.ui7 : theme.colors.ui1}
      >
        {dayLabel.slice(0, 1) === "0" ? dayLabel.slice(1, 2) : dayLabel}
      </Text>
    </Button>
  )
}
