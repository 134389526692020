import { IMoveMoneyState, initialMoveMoneyState } from "@features/move-money/redux/state"
import { IDashboardState, initialDashboardState } from "@features/dashboard/redux/reducer"
import { IActiveBusinessState, initialActiveBusinessState } from "../active-business/redux/reducer"
import { IAccountsState, initialAccountsState } from "@features/accounts/redux/reducer"
import { IAddFundsState, initialAddFundsState } from "@features/add-funds/redux/reducer"
import { initialUserState, IUserState } from "@features/auth-wrapper/redux/reducer"
import { ICardsState, initialCardsState } from "@features/cards/redux/reducer"
import { initialPerksState, IPerksState } from "@features/perks/redux/reducer"
import { IAppState, initialAppState } from "./app/reducer"
import {
  initialShareAndEarnState,
  IShareAndEarnState,
} from "@features/share-and-earn/redux/reducer"
import {
  initialTeamBankingState,
  ITeamBankingState,
} from "@features/settings/team-banking/redux/reducer"
import {
  IInvoicePaymentsState,
  initialInvoicePaymentsState,
} from "@features/invoice-payments/redux/reducer"

export interface IRootState {
  moveMoney: IMoveMoneyState
  dashboard: IDashboardState
  activeBusiness: IActiveBusinessState
  accounts: IAccountsState
  cards: ICardsState
  user: IUserState
  addFunds: IAddFundsState
  shareAndEarn: IShareAndEarnState
  perks: IPerksState
  teamBanking: ITeamBankingState
  app: IAppState
  invoicePayments: IInvoicePaymentsState
}

export const initialState: IRootState = {
  moveMoney: initialMoveMoneyState,
  dashboard: initialDashboardState,
  activeBusiness: initialActiveBusinessState,
  accounts: initialAccountsState,
  cards: initialCardsState,
  user: initialUserState,
  addFunds: initialAddFundsState,
  shareAndEarn: initialShareAndEarnState,
  perks: initialPerksState,
  teamBanking: initialTeamBankingState,
  app: initialAppState,
  invoicePayments: initialInvoicePaymentsState,
}
