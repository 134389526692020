import { useAppSelector } from "@core/redux/utils"
import { useEffect } from "react"
import { addSourceToAnalytics } from "./middleware"

export const useAddBusinessIdToAnalytics = (): void => {
  const businessId = useAppSelector((state) => state.activeBusiness.businessId)
  useEffect(() => {
    addSourceToAnalytics({ key: "businessId", value: businessId }, "properties")
  }, [businessId])
}
