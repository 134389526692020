import React, { useCallback } from "react"
import { useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import { Box, Image } from "rebass"

import { Button, ButtonTypeEnum, FlexColumnLayout, FlexRowLayout, Panel, Text } from "@northone/ui"

import { cardsActions } from "../redux/actions"
import { theme } from "@layouts/theme"
import { images } from "@assets/images/images"
import { Analytics } from "@core/analytics/actions"
import { events } from "@core/analytics/events"

export { AddVirtualCardModal } from "./add-virtual-card-modal"

export const AddVirtualCard: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const openModal = useCallback(() => {
    dispatch(cardsActions.setAddVirtualCardModalOpen(true))
    Analytics.track(events.cards.virtualCards.add)
  }, [])

  return (
    <Panel
      sx={{
        paddingX: 29,
        paddingY: 16,
        width: "100%",
        bg: theme.colors.ui6,
        boxShadow: undefined,
      }}
    >
      <FlexRowLayout
        sx={{
          paddingX: 0,
          marginX: 0,
        }}
      >
        <FlexColumnLayout sx={{ width: "65px" }}>
          <Box
            sx={{
              width: 65,
              position: "relative",
              display: "flex",
              flexShrink: 0,
            }}
          >
            <Image
              src={images.addVirtualCard}
              sx={{
                width: 65,
                maxWidth: "unset",
              }}
            />
          </Box>
        </FlexColumnLayout>

        <FlexColumnLayout sx={{ alignItems: "flex-start", paddingLeft: 21 }}>
          <Text
            sx={{ ...theme.textStyles["body"], marginBottom: "8px", fontWeight: 500 }}
            tag="body-large"
            hasPartialBolding
          >
            {t("cards.virtualCards.addVirtualCard.panel.title")}
          </Text>
          <Text sx={{ marginBottom: 16, color: theme.colors.ui1 }} tag="body-small">
            {t("cards.virtualCards.addVirtualCard.panel.subtitle")}
          </Text>
          <Button
            type={ButtonTypeEnum.PRIMARY_GOLD}
            sx={{
              height: "26px",
              padding: "9px",
            }}
            iconSrc={images.icons.add}
            iconSx={{
              margin: 0,
              height: "16px",
              filter: "brightness(10)",
            }}
            onClick={openModal}
          >
            <Text
              tag="body-small"
              sx={{
                fontSize: "10px",
                fontWeight: 500,
                lineHeight: "11px",
                marginLeft: "5px",
                letterSpacing: "1px",
                textTransform: "uppercase",
              }}
            >
              {t("cards.virtualCards.addVirtualCard.panel.button")}
            </Text>
          </Button>
        </FlexColumnLayout>
      </FlexRowLayout>
    </Panel>
  )
}
