import React, { useRef } from "react"
import { AnimatePresence as FMAnimatePresence, motion, Variant, MotionProps } from "framer-motion"

interface AnimatePresenceProps {
  children?: React.ReactNode
  animation: TAnimatePresenceAnimations
  style?: MotionProps["style"]
  key?: string
  withoutWrapper?: boolean
  onAnimationComplete?: () => void
  onLayoutAnimationComplete?: () => void
}

export type TAnimatePresenceAnimations = "fadeAndExpand" | "fadeIn" | "expand"

const ANIMATE_PRESENCE_ANIMATIONS: {
  [key in TAnimatePresenceAnimations]: { open: Variant; collapsed: Variant }
} = {
  fadeAndExpand: {
    open: { height: "auto", opacity: 1 },
    collapsed: { height: 0, opacity: 0 },
  },
  fadeIn: {
    open: { opacity: 1 },
    collapsed: { opacity: 0 },
  },
  expand: {
    open: { height: "auto" },
    collapsed: { height: 0 },
  },
}

export const AnimatePresence = ({
  children,
  animation,
  style,
  key,
  withoutWrapper,
  onAnimationComplete,
  onLayoutAnimationComplete,
}: AnimatePresenceProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const contents = (
    <motion.div
      ref={ref}
      layout="position"
      initial="collapsed"
      animate="open"
      exit="collapsed"
      variants={ANIMATE_PRESENCE_ANIMATIONS[animation]}
      style={{ ...style, overflow: "hidden" }}
      onAnimationStart={() => {
        const currentNode = ref.current
        if (currentNode) {
          currentNode.style.overflow = "hidden"
        }
      }}
      onAnimationComplete={() => {
        const currentNode = ref.current
        if (onAnimationComplete) {
          onAnimationComplete()
        }
        if (currentNode) {
          currentNode.style.overflow = "visible"
        }
      }}
      onLayoutAnimationComplete={onLayoutAnimationComplete}
    >
      {children}
    </motion.div>
  )
  return withoutWrapper ? (
    contents
  ) : (
    <FMAnimatePresence key={key} initial={false}>
      {children && contents}
    </FMAnimatePresence>
  )
}
