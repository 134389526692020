import React from "react"
import { FlexColumnLayout, LoadingIcon } from "@northone/ui"

import { CorporationEmptyResult } from "./corporation-empty-result"
import type { CorporationList } from "./add-new-corporation"
import { CorporationListResult } from "./corporation-list-result"
import { SxStyleProp } from "rebass"

interface ContainerWrapperProps {
  sx?: SxStyleProp
  children: React.ReactNode
}

function ContainerWrapper({ sx, children }: ContainerWrapperProps) {
  return (
    <FlexColumnLayout alignItems="flex-start" sx={sx}>
      {children}
    </FlexColumnLayout>
  )
}

interface CorporationContainerProps {
  corporationList: CorporationList[] | undefined
  onSelect: (billerName: string, rppsBillerId: string) => void
  isDataLoading: boolean
  isSearchResultOpen: boolean
}

export function CorporationContainer({
  corporationList,
  onSelect,
  isDataLoading,
  isSearchResultOpen,
}: CorporationContainerProps) {
  if (isDataLoading) {
    return (
      <ContainerWrapper sx={styles.searchResultStyle}>
        <LoadingIcon />
      </ContainerWrapper>
    )
  }

  if (!isSearchResultOpen) {
    return null
  }

  if (!corporationList || corporationList.length === 0) {
    return (
      <ContainerWrapper>
        <CorporationEmptyResult />
      </ContainerWrapper>
    )
  }

  return (
    <ContainerWrapper sx={styles.searchResultStyle}>
      {corporationList.map(({ billerName, rppsBillerId }) => (
        <CorporationListResult
          key={rppsBillerId}
          corporationName={billerName}
          corporationId={rppsBillerId}
          onCorporationSelected={() => onSelect(billerName, rppsBillerId)}
        />
      ))}
    </ContainerWrapper>
  )
}

const styles: Record<string, SxStyleProp> = {
  searchResultStyle: {
    padding: "18px",
    boxShadow: "0px 4px 16px 0px rgba(110, 110, 110, 0.12)",
    maxHeight: "400px",
    height: "auto",
    overflowY: "scroll",
    width: "100%",
    position: "absolute",
    background: "white",
  },
}
