import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useAppSelector } from "@core/redux/utils"
import { selectActiveBusinessID } from "@core/active-business/redux/selectors"
import { Modal } from "@components/extended/overlay/modal"
import { InfoBlock } from "@components/composite/info-block/info-block"
import { ButtonTypeEnum } from "@components/primitive/button/button"
import { useMarkInvoiceAccountsPayableAsUnpaidMutation } from "@generated/graphql"
import { Analytics } from "@core/analytics/actions"
import { events } from "@core/analytics/events"
import { TInvoiceAccountsPayable } from "@features/invoice-payments/redux/reducer"

interface IUnpayInvoiceAccountPayableProps {
  isOpen: boolean
  closeOverlay: () => void
  invoice: TInvoiceAccountsPayable
  onInvoiceUpdateCompleted: ({ message }: { message: string }) => Promise<void>
}

export const MarkInvoiceUnpaidConfirmationModal = ({
  isOpen,
  closeOverlay,
  invoice,
  onInvoiceUpdateCompleted,
}: IUnpayInvoiceAccountPayableProps) => {
  const { t } = useTranslation()

  const [hasError, setHasError] = useState<boolean>(false)
  const [updateInProgress, setUpdateInProgress] = useState<boolean>(false)

  const businessId = useAppSelector(selectActiveBusinessID)
  const { id: invoiceId } = invoice

  const [markInvoiceAsUnpaid, { loading: isMarkInvoiceUnpaidLoading }] =
    useMarkInvoiceAccountsPayableAsUnpaidMutation({
      variables: { businessId, invoiceId },
      onError: () => setHasError(true),
    })

  return (
    <Modal isOpen={isOpen} onOutsideClick={closeOverlay}>
      <InfoBlock
        title={t("invoicePayments.markInvoiceUnpaid.title")}
        subtitle={t("invoicePayments.markInvoiceUnpaid.subtitle")}
        errorMessage={hasError ? t("invoicePayments.markInvoiceUnpaid.errorMessage") : ""}
        buttons={[
          {
            type: ButtonTypeEnum.SECONDARY,
            children: t("invoicePayments.markInvoiceUnpaid.cancelButton"),
            onClick: closeOverlay,
          },
          {
            type: ButtonTypeEnum.PRIMARY_BLUE,
            children: t("invoicePayments.markInvoiceUnpaid.confirmButton"),
            onClick: async () => {
              Analytics.track(
                events.invoicePayments.invoiceQuickview.markAsUnpaidConfirmationClick,
                {
                  invoiceId,
                },
              )
              setHasError(false)

              try {
                await markInvoiceAsUnpaid()
                setUpdateInProgress(true)
                await onInvoiceUpdateCompleted({
                  message: t("invoicePayments.markInvoiceUnpaid.notificationMessage"),
                })
              } finally {
                setUpdateInProgress(false)
              }
            },
            isLoading: isMarkInvoiceUnpaidLoading || updateInProgress,
          },
        ]}
      />
    </Modal>
  )
}
