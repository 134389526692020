import { combineReducers } from "redux"
import { moveMoneyReducer as moveMoney } from "@features/move-money/redux/reducer"
import { dashboardReducer as dashboard } from "@features/dashboard/redux/reducer"
import { activeBusinessReducer as activeBusiness } from "../active-business/redux/reducer"
import { accountsReducer as accounts } from "@features/accounts/redux/reducer"
import { userReducer as user } from "@features/auth-wrapper/redux/reducer"
import { cardsReducer as cards } from "@features/cards/redux/reducer"
import { addFundsReducer as addFunds } from "@features/add-funds/redux/reducer"
import { shareAndEarnReducer as shareAndEarn } from "@features/share-and-earn/redux/reducer"
import { perksReducer as perks } from "@features/perks/redux/reducer"
import { teamBankingReducer as teamBanking } from "@features/settings/team-banking/redux/reducer"
import { invoicePaymentsReducer as invoicePayments } from "@features/invoice-payments/redux/reducer"
import { IRootState, initialState } from "./root-state"
import { AppActionEnum } from "./app/actions"
import { appReducer as app } from "./app/reducer"

export const createRootReducer = () => (state: IRootState | undefined, action: any) => {
  if (action.type === AppActionEnum.RESET_STATE) {
    state = { ...initialState }
  }
  return combineReducers({
    moveMoney,
    dashboard,
    activeBusiness,
    accounts,
    user,
    addFunds,
    cards,
    app,
    shareAndEarn,
    perks,
    teamBanking,
    invoicePayments,
  })(state, action)
}
