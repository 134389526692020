import React from "react"
import { formatMoney } from "accounting"
import { useTranslation } from "react-i18next"
import { ListItem, FlexColumnLayout, Text } from "@northone/ui"
import { AlignedColumnLayout } from "../aligned-column-layout"
import { theme } from "@layouts/theme"
import { MonthlyAccountStatements } from "@generated/graphql"
import { MonthEnum } from "@utils/dates"
import { AccountStatementsViewerDownloadPDF } from "@features/settings/statements/statement-viewer"

const ACCOUNT_STATEMENT_ITEM_TITLE = "accountStatements.statementItemTitle"
const ACCOUNT_STATEMENT_ITEM_SUBTITLE = "accountStatements.statementItemSubtitle"

interface IAccountStatementListItemProps {
  statement: MonthlyAccountStatements
}

/**
 * Extends the ListItem primitive
 */
export const AccountStatementListItem: React.FC<IAccountStatementListItemProps> = ({
  statement,
}) => {
  const { t } = useTranslation()

  return (
    <ListItem sx={{ cursor: "default" }}>
      <AlignedColumnLayout alignment={"flex-start"} widthRadio={13.5}>
        <FlexColumnLayout>
          <Text
            tag={"body-small"}
            textColor={theme.colors.ui1}
            sx={{
              paddingLeft: 10,
              fontSize: "14px",
              lineHeight: "20px",
              fontWeight: "normal",
            }}
          >
            {t(ACCOUNT_STATEMENT_ITEM_TITLE, {
              month: statement.month ? MonthEnum[+statement.month - 1] : null,
            })}
          </Text>
          <Text
            tag={"body-small"}
            sx={{
              paddingLeft: 10,
              fontSize: "12px",
              lineHeight: "20px",
              fontWeight: "normal",
            }}
            textColor={theme.colors.ui2}
          >
            {t(ACCOUNT_STATEMENT_ITEM_SUBTITLE, {
              balance: statement.closingBalance ? formatMoney(statement.closingBalance) : "$0.00",
              incoming: statement.totalDeposits ? formatMoney(statement.totalDeposits) : "$0.00",
              outgoing: statement.totalWithdrawals
                ? formatMoney(statement.totalWithdrawals)
                : "$0.00",
            })}
          </Text>
        </FlexColumnLayout>
      </AlignedColumnLayout>
      <AlignedColumnLayout alignment={"flex-start"} widthRadio={1.0}>
        <AccountStatementsViewerDownloadPDF statement={statement} />
      </AlignedColumnLayout>
    </ListItem>
  )
}
