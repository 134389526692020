import React from "react"
import { useTranslation } from "react-i18next"
import { INoticeBoxProps, NoticeBox } from "@components/primitive/notice-box/notice-box"
import { DEPOSIT_ACCOUNT_AGREEMENT_URL } from "@core/constants"
import { PlaidTopUpsErrorCodeEnum } from "@generated/graphql"
import { theme } from "@layouts/theme"

const i18nKeyPaths = {
  balanceCheckFailureMessage: "autoTopUps.landing.form.errors.balanceCheckFailed",
  formSaveFailedMessage: "autoTopUps.landing.form.errors.saveFailed",
  saveButtonLabel: "autoTopUps.landing.form.buttons.save.label",
  unknownErrorMessage: "autoTopUps.landing.form.errors.unknownError",
  velocityLimitMessage: "autoTopUps.landing.form.errors.velocityLimitReached",
  itemErrorMessage: "autoTopUps.landing.form.errors.plaidItemError",
}

type FormErrorMessagesProps = {
  settingsErrorCode?: PlaidTopUpsErrorCodeEnum
  showSaveError: boolean
}

export const FormErrorMessages = ({ settingsErrorCode, showSaveError }: FormErrorMessagesProps) => {
  const { t } = useTranslation()

  const settingsSaveErrorMessage = t(i18nKeyPaths.formSaveFailedMessage, {
    saveButtonText: t(i18nKeyPaths.saveButtonLabel),
  })
  const unknownErrorMessage = t(i18nKeyPaths.unknownErrorMessage)
  const velocityLimitMessage = t(i18nKeyPaths.velocityLimitMessage)
  const insufficientBalanceMessage = t(i18nKeyPaths.balanceCheckFailureMessage, {
    minimumExternalAccountBalance: 100,
    minimumExternalAccountBalanceMultiplier: 1.5,
  })
  const itemErrorMessage = t(i18nKeyPaths.itemErrorMessage)

  const errorCodeToNoticeBoxProps = (errorCode?: PlaidTopUpsErrorCodeEnum): INoticeBoxProps => {
    switch (errorCode) {
      case PlaidTopUpsErrorCodeEnum.INSUFFICIENT_BALANCE:
        return {
          level: "warning",
          text: insufficientBalanceMessage,
          textProps: { hasPartialBolding: true, tag: "body-small" },
        }
      case PlaidTopUpsErrorCodeEnum.VELOCITY_LIMIT_30D:
        return {
          level: "warning",
          textProps: {
            tag: "body-small",
            textWithEmbeddedLinksProps: {
              text: velocityLimitMessage,
              linksInOrder: [DEPOSIT_ACCOUNT_AGREEMENT_URL],
              linkStyle: { color: theme.colors.blackPrimary },
            },
          },
        }
      case PlaidTopUpsErrorCodeEnum.PLAID_ITEM_ERROR:
      case PlaidTopUpsErrorCodeEnum.PLAID_ITEM_EXPIRED:
        return {
          level: "error",
          text: itemErrorMessage,
        }
      default:
        return {
          level: "error",
          text: unknownErrorMessage,
        }
    }
  }

  const errorCodeNoticeBoxProps = errorCodeToNoticeBoxProps(settingsErrorCode)

  return (
    <>
      {settingsErrorCode ? <NoticeBox {...errorCodeNoticeBoxProps} /> : null}
      {showSaveError ? <NoticeBox level="error" text={settingsSaveErrorMessage} /> : null}
    </>
  )
}
