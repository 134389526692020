import React from "react"
import { useTranslation } from "react-i18next"
import { DateTime } from "luxon"
import { Line } from "react-chartjs-2"
import { Chart as ChartJS, registerables } from "chart.js"
ChartJS.register(...registerables)

import { FlexColumnLayout, FlexRowLayout } from "@layouts/flex"
import { theme } from "@layouts/theme"
import { Text } from "@components/primitive/text/text"

export interface IRevenueLineGraphProps {
  grossRevenuePerDayTimeSeries: Array<{ date: string; totalRevenueAmount: number }>
}

const dateFormat = "MMM d, yyyy"
export const RevenueLineGraph = ({ grossRevenuePerDayTimeSeries }: IRevenueLineGraphProps) => {
  const { t } = useTranslation()

  if (grossRevenuePerDayTimeSeries.length === 0) return null

  const labels = grossRevenuePerDayTimeSeries.map(({ date }) =>
    DateTime.fromISO(date).toFormat(dateFormat),
  )
  const dayData = grossRevenuePerDayTimeSeries.map(({ totalRevenueAmount }) => totalRevenueAmount)

  const firstDay = DateTime.fromISO(grossRevenuePerDayTimeSeries[0].date).toFormat(dateFormat)
  const lastDay = DateTime.fromISO(
    grossRevenuePerDayTimeSeries[grossRevenuePerDayTimeSeries.length - 1].date,
  ).toFormat(dateFormat)

  return (
    <FlexColumnLayout>
      <div style={{ height: 35, width: "100%" }}>
        <Line
          data={{
            labels,
            datasets: [
              {
                label: t("revenueHub.platformActive.lineGraph.revenueDatasetLabel"),
                data: dayData,
                borderColor: theme.colors.blue60,
                borderWidth: 1.5,
                pointStyle: false,
              },
            ],
          }}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: { display: false },
              tooltip: {
                displayColors: false,
                padding: 4,
                titleSpacing: 0,
                titleMarginBottom: 0,
              },
            },
            scales: {
              x: {
                grid: { display: false },
                border: { display: false },
                ticks: { display: false },
              },
              y: {
                grid: { display: false },
                border: { display: false },
                ticks: { display: false },
              },
            },
          }}
        />
      </div>
      <FlexRowLayout
        sx={{
          borderTop: `1px solid ${theme.colors.ui4}`,
          paddingTop: "4px",
          marginBottom: 16,
          justifyContent: "space-between",
        }}
      >
        <Text tag="body-x-small" textColor={theme.colors.ui3}>
          {firstDay}
        </Text>
        <Text tag="body-x-small" textColor={theme.colors.ui3}>
          {lastDay}
        </Text>
      </FlexRowLayout>
    </FlexColumnLayout>
  )
}
