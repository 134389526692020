export const downloadModal = {
  title: "Send a link to download the NorthOne app to your phone",
  subtitle1: "You’ll receive a link to the ",
  subtitle2: " to download the NorthOne app. Messaging and data rates may apply.",
  or: " or ",
  googlePlay: "Google Play Store",
  appStore: "Apple App Store",
  sendLink: "Send link",
  label: "Phone number",
  placeholder: "Enter your phone number",
  sentButton: "Link sent!",
  sent: "A link was sent to your phone!",
  error: "Enter a valid phone number",
}
