import { MAXIMUM_REFERRAL_COUNT } from "../constants"
import { getShareAndEarnLastVisitedFromLocalStorage } from "../utils"
import { TShareAndEarnActions, ShareAndEarnActionEnum } from "./actions"

export interface IShareAndEarnState {
  totalRedeemedOffers?: number
  totalRedeemedAmount?: number
  offerUrl?: string
  isBlocked?: boolean
  isEligible?: boolean
  hubLastVisited?: Date
  isShareAndEarnLoading: boolean
  enableShareAndEarn: boolean
  featureFlagEnabled?: boolean
}

export const initialShareAndEarnState: IShareAndEarnState = {
  totalRedeemedOffers: undefined,
  totalRedeemedAmount: undefined,
  offerUrl: undefined,
  isBlocked: undefined,
  isEligible: undefined,
  hubLastVisited: getShareAndEarnLastVisitedFromLocalStorage(),
  isShareAndEarnLoading: true,
  enableShareAndEarn: true,
  featureFlagEnabled: undefined,
}

export const shareAndEarnReducer = (
  state = initialShareAndEarnState,
  action: TShareAndEarnActions,
): IShareAndEarnState => {
  switch (action.type) {
    case ShareAndEarnActionEnum.SET_SHARE_AND_EARN_DATA: {
      const { totalRedeemedOffers, featureFlagEnabled } = action.payload
      const enableShareAndEarn = totalRedeemedOffers && totalRedeemedOffers < MAXIMUM_REFERRAL_COUNT

      return {
        ...state,
        ...action.payload,
        ...(typeof enableShareAndEarn === "boolean" && { enableShareAndEarn }),
        // Prevent enabling S&E if the feature flag is off
        ...(featureFlagEnabled === undefined &&
          state.featureFlagEnabled === false && { enableShareAndEarn: false }),
        ...(featureFlagEnabled === false && { enableShareAndEarn: false }),
      }
    }
    default:
      return state
  }
}
