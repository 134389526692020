import { formatMoney, unformat } from "accounting"
import React, { ReactElement, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { Link, SxStyleProp } from "rebass"
import { Button, ButtonTypeEnum, FlexColumnLayout, FlexRowLayout, Text } from "@northone/ui"

import { PlaidItem } from "@generated/graphql"
import { theme } from "@layouts/theme"
import { RoutesEnum } from "@routers/types"

import { TopUpSettings, TopUpSettingsValues } from "./types"
import { Dropdown } from "@components/composite/dropdown/dropdown"
import { images } from "@assets/images/images"
import { MaskTextInput } from "@components/extended/fields/mask-input"

const i18nKeyPaths = {
  formHeadingActive: "autoTopUps.landing.form.headings.active",
  formHeadingInactive: "autoTopUps.landing.form.headings.inactive",
  formSubtitleAccountUnfunded: "autoTopUps.landing.form.subtitles.accountUnfunded",
  formSubtitleInactive: "autoTopUps.landing.form.subtitles.inactive",
  minimumBalanceLabel: "autoTopUps.landing.form.inputs.minimumBalance.label",
  amountLabel: "autoTopUps.landing.form.inputs.amount.label",
  accountLabel: "autoTopUps.landing.form.inputs.account.label",
  saveButtonLabel: "autoTopUps.landing.form.buttons.save.label",
  saveButtonSavedLabel: "autoTopUps.landing.form.buttons.save.savedLabel",
  cancelButtonLabel: "autoTopUps.landing.form.buttons.cancel.label",
  manageAccountsLinkText: "autoTopUps.landing.form.links.manageAccounts",
  minimumBalanceValueOutOfRangeMessage:
    "autoTopUps.landing.form.inputs.minimumBalance.errorMessages.valueOutOfRange",
  amountOutOfRangeMessage: "autoTopUps.landing.form.inputs.amount.errorMessages.valueOutOfRange",
}

const formHorizontalGap = 3
const formHeadingStyles: SxStyleProp = { marginBottom: 12 }
const subtitleStyles: SxStyleProp = { color: theme.colors.ui2 }
const buttonContainerStyles: SxStyleProp = { gap: 3, alignItems: "flex-start" }
const linkedAccountLinkStyles: SxStyleProp = {
  alignSelf: "flex-end",
  color: theme.colors.black1,
  fontSize: "14px",
  fontWeight: 500,
  paddingBottom: "10px",
}

const FormHeading = ({ isActive, isEligible }: { isActive: boolean; isEligible: boolean }) => {
  const { t } = useTranslation()
  const titleText = isActive
    ? t(i18nKeyPaths.formHeadingActive)
    : t(i18nKeyPaths.formHeadingInactive)

  const getSubtitleText = () => {
    if (!isActive) return t(i18nKeyPaths.formSubtitleInactive)
    if (!isEligible) return t(i18nKeyPaths.formSubtitleAccountUnfunded)
  }
  const subtitleText = getSubtitleText()

  const subtitleElement = subtitleText ? (
    <Text tag="body-large" sx={subtitleStyles}>
      {subtitleText}
    </Text>
  ) : null

  return (
    <>
      <Text tag="h4" sx={formHeadingStyles}>
        {titleText}
      </Text>
      {subtitleElement}
    </>
  )
}

interface TopUpsFormProps {
  isEligible: boolean
  plaidItems: PlaidItem[]
  topUpSettings?: TopUpSettings
  minimumBalanceLimits: { minimum: number; default: number }
  slotAboveForm: ReactElement | null
  topUpAmountLimits: { minimum: number; maximum: number; default: number }
  onSaveButtonClicked: (topUpSettings: TopUpSettingsValues) => Promise<void>
  onCancelButtonClicked: (topUpSettings: TopUpSettingsValues) => Promise<void>
}

const getIsFormValid = ({
  itemId,
  topUpAmount,
  minimumBalanceAmount,
  topUpAmountLimits,
  minimumBalanceLimits,
}: {
  itemId?: string
  topUpAmount?: number
  minimumBalanceAmount?: number
  topUpAmountLimits?: { minimum: number; maximum: number; default: number }
  minimumBalanceLimits?: { minimum: number; default: number }
}) => {
  if (
    !itemId ||
    topUpAmount === undefined ||
    minimumBalanceAmount === undefined ||
    !topUpAmountLimits ||
    !minimumBalanceLimits
  )
    return false

  if (topUpAmount < topUpAmountLimits.minimum || topUpAmount > topUpAmountLimits.maximum)
    return false
  if (minimumBalanceAmount < minimumBalanceLimits.minimum) return false
  return true
}

export const TopUpSettingsForm = ({
  isEligible,
  minimumBalanceLimits,
  plaidItems,
  topUpAmountLimits,
  topUpSettings,
  slotAboveForm,
  onSaveButtonClicked,
  onCancelButtonClicked,
}: TopUpsFormProps) => {
  const { t } = useTranslation()
  const [minimumBalanceAmount, setMinimumBalanceAmount] = useState<number>(
    topUpSettings?.lowBalanceThreshold ?? minimumBalanceLimits.default,
  )
  const [topUpAmount, setTopUpAmount] = useState<number>(
    topUpSettings?.topUpAmount ?? topUpAmountLimits.default,
  )
  const [itemId, setItemId] = useState<string | undefined>(topUpSettings?.plaidItem?.id)
  const [isFormSaving, setIsFormSaving] = useState(false)
  const [isFormCancelling, setIsFormCancelling] = useState(false)
  const [isFormDirty, setIsFormDirty] = useState(false)
  const [isFormValid, setIsFormValid] = useState(false)
  const [minimumBalanceErrorMessage, setMinimumBalanceErrorMessage] = useState<string>()
  const [topUpAmountErrorMessage, setTopUpAmountErrorMessage] = useState<string>()

  const isSaveButtonEnabled = isFormValid && (!topUpSettings?.isTopUpsEnabled || isFormDirty)

  const onMinimumBalanceAmountChanged = (value: string) => {
    setMinimumBalanceErrorMessage(undefined)
    const minimumBalance = unformat(value)
    setMinimumBalanceAmount(minimumBalance)
    setIsFormDirty(true)
    const { minimum } = minimumBalanceLimits
    if (minimumBalance < minimum) {
      setMinimumBalanceErrorMessage(
        t(i18nKeyPaths.minimumBalanceValueOutOfRangeMessage, {
          minimum,
          minimumFormatted: formatMoney(minimum, undefined, 0),
        }),
      )
    }
  }

  const onTopUpAmountChanged = (value: string) => {
    setTopUpAmountErrorMessage(undefined)
    const amount = unformat(value)
    setTopUpAmount(amount)
    setIsFormDirty(true)

    const { minimum, maximum } = topUpAmountLimits
    if (amount < minimum || amount > maximum) {
      setTopUpAmountErrorMessage(
        t(i18nKeyPaths.amountOutOfRangeMessage, {
          minimum,
          minimumFormatted: formatMoney(minimum, undefined, 0),
          maximumFormatted: formatMoney(maximum, undefined, 0),
        }),
      )
    }
  }

  const onAccountSelected = (value: string) => {
    setItemId(value)
    setIsFormDirty(true)
  }

  const handleSaveButtonClick = async () => {
    if (!itemId) return
    setIsFormDirty(false)

    setIsFormSaving(true)
    await onSaveButtonClicked({
      itemId,
      topUpAmount,
      lowBalanceThreshold: minimumBalanceAmount,
    })
      .catch(() => {
        setIsFormDirty(true)
      })
      .finally(() => {
        setIsFormSaving(false)
      })
  }

  const handleCancelButtonClick = async () => {
    if (!itemId) return

    setIsFormCancelling(true)
    await onCancelButtonClicked({
      itemId,
      topUpAmount,
      lowBalanceThreshold: minimumBalanceAmount,
    }).finally(() => {
      setIsFormCancelling(false)
    })
    setIsFormDirty(false)
  }

  const getSaveButtonText = (data: { isTopUpEnabled?: boolean; isFormDirty: boolean }) => {
    if (!data.isTopUpEnabled) return t(i18nKeyPaths.saveButtonLabel)
    if (data.isFormDirty) return t(i18nKeyPaths.saveButtonLabel)
    return t(i18nKeyPaths.saveButtonSavedLabel)
  }

  const minimumBalanceLabel = t(i18nKeyPaths.minimumBalanceLabel)
  const amountLabel = t(i18nKeyPaths.amountLabel)
  const saveButtonLabel = getSaveButtonText({
    isTopUpEnabled: topUpSettings?.isTopUpsEnabled,
    isFormDirty,
  })
  const cancelButtonLabel = t(i18nKeyPaths.cancelButtonLabel)
  const accountLabel = t(i18nKeyPaths.accountLabel)
  const manageAccountsLinkText = t(i18nKeyPaths.manageAccountsLinkText)

  const minimumBalancePlaceholder = `$${minimumBalanceLimits.default}`
  const topUpAmountPlaceholder = `$${topUpAmountLimits.default}`
  const topUpIsEnabled = topUpSettings?.isTopUpsEnabled ?? false

  const linkedAccounts = useMemo(() => {
    return plaidItems.map((item) => {
      const institutionLogoURI = item.institution?.logo
        ? `data:image/png;base64,${item.institution.logo}`
        : images.icons.fundingOptions.linked
      return {
        text: `${item.institution?.name} (•• ${item.maskedAccountNumber})`,
        value: item.id,
        styles: {
          alignItems: "center",
          backgroundImage: `url('${institutionLogoURI}')`,
          backgroundPosition: "2px",
          backgroundRepeat: "no-repeat",
          backgroundSize: "32px",
          display: "flex",
          height: 32,
          paddingLeft: "42px",
        },
      }
    })
  }, [plaidItems])

  useEffect(() => {
    setIsFormValid(
      getIsFormValid({
        itemId,
        topUpAmount,
        minimumBalanceAmount,
        topUpAmountLimits,
        minimumBalanceLimits,
      }),
    )
  }, [itemId, topUpAmount, minimumBalanceAmount, topUpAmountLimits, minimumBalanceLimits])

  return (
    <>
      <FormHeading isActive={topUpIsEnabled} isEligible={isEligible} />

      <hr />

      {slotAboveForm}

      <FlexRowLayout sx={{ gap: formHorizontalGap }}>
        <MaskTextInput
          maskType="AMOUNT_WITHOUT_CENTS"
          placeholder={minimumBalancePlaceholder}
          label={minimumBalanceLabel}
          value={minimumBalanceAmount.toString()}
          errorMessage={minimumBalanceErrorMessage}
          onChange={onMinimumBalanceAmountChanged}
        />
        <MaskTextInput
          maskType="AMOUNT_WITHOUT_CENTS"
          placeholder={topUpAmountPlaceholder}
          label={amountLabel}
          value={topUpAmount.toString()}
          errorMessage={topUpAmountErrorMessage}
          onChange={onTopUpAmountChanged}
        />
      </FlexRowLayout>

      <FlexColumnLayout sx={{ gap: 3 }}>
        <FlexRowLayout sx={{ gap: formHorizontalGap }}>
          <Dropdown
            selectedValue={itemId}
            options={linkedAccounts}
            label={accountLabel}
            onSelect={onAccountSelected}
          />
          <Link href={RoutesEnum.SETTINGS_LINKED_ACCOUNT} sx={linkedAccountLinkStyles}>
            {manageAccountsLinkText}
          </Link>
        </FlexRowLayout>

        <FlexRowLayout sx={buttonContainerStyles}>
          <Button
            type={ButtonTypeEnum.PRIMARY_BLUE}
            disabled={!isSaveButtonEnabled}
            onClick={handleSaveButtonClick}
            isLoading={isFormSaving}
          >
            {saveButtonLabel}
          </Button>
          {topUpSettings?.isTopUpsEnabled === true && (
            <Button
              type={ButtonTypeEnum.SECONDARY}
              onClick={handleCancelButtonClick}
              isLoading={isFormCancelling}
            >
              {cancelButtonLabel}
            </Button>
          )}
        </FlexRowLayout>
      </FlexColumnLayout>
    </>
  )
}
