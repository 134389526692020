import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import { isProduction } from "@utils/environment"
import { en } from "./locales/en/en"
import { es } from "./locales/es/es"

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  //   .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources: {
      en: {
        translation: en,
      },
      es: {
        translation: es,
      },
    },
    // the lng option is required as long as we're not using LanguageDetector
    lng: "en",
    fallbackLng: "en",
    debug: !isProduction,
    interpolation: {
      escapeValue: false,
    },
  })

export const translate = (key: string, options: any): string => i18n.t(key, options)
export const translateDynamicNoOptions = (key1: string, key2: string) => i18n.t(key1 + "." + key2)

export default i18n
