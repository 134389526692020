import React from "react"
import { useTranslation } from "react-i18next"

import { TransactionStatusEnum, TransactionTypeEnum } from "@generated/graphql"
import { FlexColumnLayout, Text, theme } from "@northone/ui"
import { Button, ButtonTypeEnum } from "@components/primitive/button/button"

interface IMailedCheckContentProps {
  status: TransactionStatusEnum
  type: TransactionTypeEnum
}

export const MailedCheckContent = (props: IMailedCheckContentProps): JSX.Element => {
  const { status, type } = props
  const { t } = useTranslation()

  const isMailedCheckCanceled =
    status === TransactionStatusEnum.CANCELED && type === TransactionTypeEnum.MAILED_CHECK
  const isMailedCheckRefunded = type === TransactionTypeEnum.MAILED_CHECK_REFUND
  const isMailedCheckExpired =
    status === TransactionStatusEnum.EXPIRED && type === TransactionTypeEnum.MAILED_CHECK
  const isCustomerSupportButtonEnable = isMailedCheckExpired || isMailedCheckCanceled

  return (
    <FlexColumnLayout sx={{ marginTop: 10 }}>
      {isMailedCheckExpired && (
        <Text tag="body" textColor={theme.colors.error100}>
          {t("dashboard.transactionPanel.mailedCheck.expired.subtitle")}
        </Text>
      )}
      {isMailedCheckRefunded && (
        <Text tag="body" textColor={theme.colors.error100}>
          {t("dashboard.transactionPanel.mailedCheck.refunded.subtitle")}
        </Text>
      )}
      {isMailedCheckCanceled && (
        <Text tag="body" textColor={theme.colors.error100}>
          {t("dashboard.transactionPanel.mailedCheck.canceled.subtitle")}
        </Text>
      )}

      {isCustomerSupportButtonEnable && (
        <Button
          type={ButtonTypeEnum.TERTIARY}
          textSx={{
            textDecoration: "underline",
            textDecorationColor: theme.colors.ui2,
          }}
          sx={{
            padding: 0,
            border: "none",
            ":hover": {
              backgroundColor: "none",
            },
            ":active": {
              backgroundColor: "none",
            },
          }}
          onClick={() => {
            window.Intercom("show")
          }}
        >
          <Text tag="body" textColor={theme.colors.ui2} sx={{ fontSize: "12px" }}>
            {t("dashboard.transactionPanel.mailedCheck.canceled.button")}
          </Text>
        </Button>
      )}
    </FlexColumnLayout>
  )
}
