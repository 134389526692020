export const support = {
  title: "Support",
  overview: {
    title: "Overview",
    getInTouch: "Get in touch with us",
    onlineChat: "Online Chat",
    talkToUs: "Talk to us",
    scheduleCallSubtitle: "No more waiting on hold. Let us know when is best to call.",
    leaveMessage: "Leave us a message",
    liveChat: {
      available: "We're here to help 'til {{time}} {{timezone}}",
      unavailable: "Leave us a message and we'll get back to you in the morning.",
    },
  },
  scheduleCall: {
    title: "Schedule a call",
    subtitle: "Choose a date that's most convenient for you. All times are in {{timezone}}.",
    labels: {
      timeslot: "Timeslot",
      phone: "Phone",
      askTopic: "What is your call about?",
      selectTopic: "Select a topic",
      selectDay: "Select a date",
      availableSlots: "Select a timeslot",
      date: "Date",
      additionalInfoLabel: "Describe what you'd like to help with.",
      additionalInfoPlaceholder: "Please provide as many details as possible.",
      fullyBooked: "{{dateString}} - Fully booked",
    },
    done: "Done",
    connect: "If you would like to connect with support about any of the following:",
    sendMessage: "Send us a message",
    pleaseMessage:
      "*Please message us via our online chat so we can help you.* For the safety and security of your account, we cannot manage these requests over the phone.",
    requestSent:
      "Your call has been scheduled for {{timeslot}} {{timezone}} on {{date}}. You will receive an email confirming this timeslot. Use the links in the email if you need to cancel or reschedule.",
    onlineChatItems: [
      "Closing your account",
      "Requesting a new debit card",
      "Updating your address or account information",
    ],
    topics: {
      atm: "ATMs",
      checks: "Checks",
      accountBalance: "Account balance",
      pendingFunds: "Pending funds",
      other: "Other",
      close: "Closing my account",
      changeAddressOrAccountInfo: "Updating address or account information",
      debitCard: "Requesting a new debit card",
    },
  },
  errors: {
    timeSlots: "There was an error retrieving available time slots",
    scheduleCall: "There was an error performing this request",
    maxLengthExceeded: "Please limit your message to {{maxLength}} characters.",
  },
  faq: {
    sidebarTitle: "Frequently asked questions",
    short: "FAQs",
    title: "Frequently Asked Questions",
    backToFaqs: "Back to all FAQs",
    backToCategory: "Back to {{category}}",
    searchPlaceholder: "Search FAQs",
  },
  disclaimer: {
    reCAPTCHA: {
      text: "This site is protected by reCAPTCHA, and the Google",
      privacyPolicy: "Privacy Policy",
      and: " and ",
      termsOfService: "Terms of Service",
      apply: " apply.",
    },
  },
}
