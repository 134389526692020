import gql from "graphql-tag"
import { CAPABILITIES_WITH_LIMIT_AND_FEES_FRAGMENT } from "../move-money/shared/operations.gql"

export const LIST_INVOICES = gql`
  query listInvoiceAccountsPayable(
    $businessId: ID!
    $sorting: InvoiceAccountsPayableListSortingInput!
    $filters: InvoiceAccountsPayableListFiltersInput
    $pagination: InvoiceAccountsPayableListPaginationOptionsInput
  ) {
    listInvoiceAccountsPayable(
      businessId: $businessId
      sorting: $sorting
      filters: $filters
      pagination: $pagination
    ) {
      invoices {
        id
        vendorName
        contactId
        invoiceNumber
        invoiceDate
        dueDate
        status
        paymentStatus
        amount
        paidAmount
        scheduledAmount
        pendingAmount
        remainingAmount
        businessId
        createdVia
        createdDate
        updatedDate
        paidDate
        description
        markedAsPaidReason
        attachmentId
      }
      pagination {
        cursor
      }
    }
  }
`

export const GET_INVOICE = gql`
  query getInvoiceAccountsPayable($businessId: ID!, $invoiceId: ID!) {
    getInvoiceAccountsPayable(businessId: $businessId, invoiceId: $invoiceId) {
      invoice {
        amount
        businessId
        id
        invoiceDate
        createdDate
        invoiceNumber
        createdVia
        createdDate
        isArchived
        isMarkedAsPaid
        description
        attachmentId
        contactId
        dueDate
        markedAsPaidReason
        paidAmount
        scheduledAmount
        pendingAmount
        remainingAmount
        paidDate
        paymentStatus
        status
        updatedDate
        vendorName
        transactions {
          invoiceId
          transactionId
          status
          accountId
          paymentMethod
          amount
          scheduledDate
          scheduledPaymentId
          lastUpdated
          status
          subAccount {
            id
          }
        }
      }
    }
  }
`

export const GET_INVOICE_WITHOUT_TRANSACTIONS = gql`
  query getInvoiceAccountsPayableWithoutTransactions($businessId: ID!, $invoiceId: ID!) {
    getInvoiceAccountsPayable(businessId: $businessId, invoiceId: $invoiceId) {
      invoice {
        amount
        businessId
        id
        invoiceDate
        createdDate
        invoiceNumber
        createdVia
        createdDate
        isArchived
        isMarkedAsPaid
        description
        attachmentId
        contactId
        dueDate
        markedAsPaidReason
        paidAmount
        scheduledAmount
        pendingAmount
        remainingAmount
        paidDate
        paymentStatus
        status
        updatedDate
        vendorName
      }
    }
  }
`

export const INVOICE_PAYMENT_CONTACT_QUERY = gql`
  query invoicePaymentsContact($businessId: ID!, $contactId: ID!) {
    business(businessId: $businessId) {
      id
      contact(contactId: $contactId) {
        address {
          streetAddressLine1
          streetAddressLine2
          city
          provinceState
          country
          postalCode
        }
        id
        businessId
        name
        relationship
        achPaymentDetails {
          accountNumber
          routingNumber
        }
        wirePaymentDetails {
          accountNumber
          routingNumber
          bankName
        }
        mailedCheckPaymentDetails {
          enabled
        }
        billPayPaymentDetails {
          accountNumber
        }
        rppsBillerDetails {
          rppsBillerId
          billerName
        }
      }
    }
  }
`

export const INVOICE_PAYMENT_CONTACT_LIST_QUERY = gql`
  query invoicePaymentsContactList($businessId: ID!) {
    business(businessId: $businessId) {
      contacts {
        id
        businessId
        name
        email
        achPaymentDetails {
          routingNumber
          accountNumber
        }
        wirePaymentDetails {
          routingNumber
          accountNumber
        }
        mailedCheckPaymentDetails {
          enabled
        }
        billPayPaymentDetails {
          accountNumber
        }
        rppsBillerDetails {
          rppsBillerId
          billerName
        }
      }
    }
  }
`

/**
 * NOTE: This mutation is saving the invoice or can updating it.
 * if we don't pass an invoiceId we are saving the invoice
 * if we pass an invoiceId we are updating the invoice
 */
export const SAVE_INVOICE = gql`
  mutation saveInvoiceAccountsPayable(
    $businessId: ID!
    $invoiceData: InvoiceAccountsPayableDataInput!
  ) {
    saveInvoiceAccountsPayable(businessId: $businessId, invoiceData: $invoiceData) {
      success
      code
      message
      invoiceId
    }
  }
`

export const MARK_INVOICE_AS_PAID = gql`
  mutation markInvoiceAccountsPayableAsPaid($businessId: ID!, $invoiceId: ID!, $reason: String) {
    markInvoiceAccountsPayableAsPaid(
      businessId: $businessId
      invoiceId: $invoiceId
      reason: $reason
    ) {
      success
      code
      message
    }
  }
`

export const MARK_INVOICE_AS_UNPAID = gql`
  mutation markInvoiceAccountsPayableAsUnpaid($businessId: ID!, $invoiceId: ID!) {
    markInvoiceAccountsPayableAsUnpaid(businessId: $businessId, invoiceId: $invoiceId) {
      success
      code
      message
    }
  }
`

export const MARK_INVOICE_AS_ARCHIVED = gql`
  mutation markInvoiceAccountsPayableAsArchived($businessId: ID!, $invoiceId: ID!) {
    markInvoiceAccountsPayableAsArchived(businessId: $businessId, invoiceId: $invoiceId) {
      success
      code
      message
    }
  }
`

export const MARK_INVOICE_AS_UNARCHIVED = gql`
  mutation markInvoiceAccountsPayableAsUnarchived($businessId: ID!, $invoiceId: ID!) {
    markInvoiceAccountsPayableAsUnarchived(businessId: $businessId, invoiceId: $invoiceId) {
      success
      code
      message
    }
  }
`

export const UPDATE_INVOICE_CONTACT_ACH_PAYMENT_DETAILS = gql`
  mutation updateInvoiceContactAchPaymentDetails($data: ContactDomesticAchUpdateDataInput!) {
    contactDomesticAchUpdate(data: $data) {
      code
      success
    }
  }
`

export const UPDATE_INVOICE_CONTACT_MAILED_CHECK_PAYMENT_DETAILS = gql`
  mutation updateInvoiceContactMailedCheckPaymentDetails(
    $data: ContactMailedCheckUpdateDataInput!
  ) {
    contactMailedCheckUpdate(data: $data) {
      code
      success
      message
    }
  }
`

export const UPDATE_INVOICE_CONTACT_WIRE_PAYMENT_DETAILS = gql`
  mutation updateInvoiceContactWirePaymentDetails($data: ContactDomesticWireUpdateDataInput!) {
    contactDomesticWireUpdate(data: $data) {
      code
      success
    }
  }
`
export const ADD_INVOICE_CONTACT_BILL_PAY_PAYMENT_DETAILS = gql`
  mutation ContactBillPayPaymentDetailsCreate($data: ContactBillPayPaymentDetailsCreateInput!) {
    contactBillPayPaymentDetailsCreate(data: $data) {
      code
      success
    }
  }
`

export const UPDATE_INVOICE_CONTACT_BILL_PAY_PAYMENT_DETAILS = gql`
  mutation updateInvoiceContactBillPayPaymentDetails(
    $data: ContactBillPayPaymentDetailsUpdateInput!
  ) {
    contactBillPayPaymentDetailsUpdate(data: $data) {
      code
      success
    }
  }
`

export const UPDATE_INVOICE_CONTACT = gql`
  mutation updateInvoiceContact($input: ContactUpdateDataInput!) {
    contactUpdate(input: $input) {
      code
      success
    }
  }
`

export const CREATE_INVOICE_ACCOUNTS_PAYABLE_PAYMENT = gql`
  mutation createInvoiceAccountsPayablePayment(
    $businessId: ID!
    $invoiceId: ID!
    $data: CreateInvoiceAccountsPayablePaymentDataInput!
  ) {
    createInvoiceAccountsPayablePayment(
      businessId: $businessId
      invoiceId: $invoiceId
      data: $data
    ) {
      code
      success
      message
      transactionId
    }
  }
`

export const CREATE_INVOICE_ACCOUNTS_PAYABLE_SCHEDULED_PAYMENT = gql`
  mutation createInvoiceAccountsPayableScheduledPayment(
    $businessId: ID!
    $invoiceId: ID!
    $data: CreateInvoiceAccountsPayableScheduledPaymentDataInput!
  ) {
    createInvoiceAccountsPayableScheduledPayment(
      businessId: $businessId
      invoiceId: $invoiceId
      data: $data
    ) {
      code
      success
      message
      scheduledPaymentId
    }
  }
`

export const CREATE_INVOICE_ACCOUNTS_PAYABLE_CONTACT = gql`
  mutation createInvoiceAccountsPayableContact($input: ContactCreateDataInput!) {
    contactCreate(input: $input) {
      code
      success
      message
      contact {
        id
      }
    }
  }
`

export const CREATE_INVOICE_ATTACHMENT = gql`
  mutation createInvoiceAttachment(
    $businessId: ID!
    $attachmentData: CreateInvoiceAttachmentDataInput!
  ) {
    createInvoiceAttachment(businessId: $businessId, attachmentData: $attachmentData) {
      upload {
        url
        expires
        fields {
          headerKey
          headerValue
        }
      }
      attachmentId
    }
  }
`

export const GET_INVOICE_ATTACHMENT_URL = gql`
  query getInvoiceAttachmentUrl($businessId: ID!, $attachmentId: ID!) {
    getInvoiceAttachment(businessId: $businessId, attachmentId: $attachmentId) {
      attachmentId
      status
      file {
        url
        expires
        contentType
      }
    }
  }
`

export const GET_INVOICE_ATTACHMENT_DATA = gql`
  query getInvoiceAttachmentData($businessId: ID!, $attachmentId: ID!) {
    getInvoiceAttachment(businessId: $businessId, attachmentId: $attachmentId) {
      attachmentId
      status
      attachmentData {
        invoiceNumber
        vendorName
        invoiceDate
        paymentDetails {
          routingNumber
          zipCode
          streetAddress
          city
          state
          accountNumber
          personalOrBusinessName
          paymentTerms
        }
        invoiceTotal
        invoiceDueDate
      }
    }
  }
`

export const GET_INVOICE_ACCOUNT_INFO = gql`
  query getInvoiceAccountInfo($businessId: ID!) {
    business(businessId: $businessId) {
      id
      bankAccount {
        id
        availableBalance
        accountNumber
        subAccounts {
          id
          name
          balance
          isDefault
        }
        capabilities {
          ...capabilitiesWithLimitAndFees
        }
      }
    }
  }
  ${CAPABILITIES_WITH_LIMIT_AND_FEES_FRAGMENT}
`

export const UPDATE_SCHEDULED_PAYMENT = gql`
  mutation updateScheduledPayment(
    $businessId: ID!
    $scheduledPaymentId: ID!
    $updateScheduledPaymentInput: UpdateScheduledPaymentInput!
  ) {
    updateScheduledPayment(
      businessId: $businessId
      scheduledPaymentId: $scheduledPaymentId
      updateScheduledPaymentInput: $updateScheduledPaymentInput
    ) {
      code
      message
      success
    }
  }
`

export const CANCEL_SCHEDULED_PAYMENT = gql`
  mutation cancelScheduledPayment($businessId: ID!, $scheduledPaymentId: ID!) {
    cancelScheduledPayment(businessId: $businessId, scheduledPaymentId: $scheduledPaymentId) {
      code
      message
      success
    }
  }
`

export const INVOICE_FORWARDING_EMAIL = gql`
  query invoiceForwardingEmail($businessId: ID!) {
    business(businessId: $businessId) {
      id
      invoiceForwardingEmail
    }
  }
`

export const ACH_CUTOFF_TIMES = gql`
  query achCutoffTimes($businessId: ID!) {
    achCutoffTimes(businessId: $businessId) {
      isSameDayAvailable
      nextCutoff
      secondsToNextCutoff
    }
  }
`

export const SEARCH_BILLER_DIRECTORY = gql`
  query searchBillerDirectory($billerName: String!) {
    searchBillerDirectory(billerName: $billerName) {
      billers {
        rppsBillerId
        billerName
        billerAccountNumberPatterns
      }
    }
  }
`
