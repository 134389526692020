import React, { useState, useRef } from "react"
import { useHistory, useLocation, useRouteMatch } from "react-router-dom"
import { ILocationState, RoutesEnum } from "@routers/types"
import { FlowModal } from "@components/extended/overlay/flow-modal"
import { navigateFromModal } from "@utils/navigation"
import { useTranslation } from "react-i18next"
import { RetentionScreen } from "./screens/cancellation-retention-screen"
import { ConfirmCancellationScreen } from "./screens/cancellation-confirm-screen"
import { CancellationSuccessScreen } from "./screens/cancellation-success-screen"

export type TSubscriptionCancelScreenNames =
  | "RETENTION"
  | "CONFIRM_CANCELLATION"
  | "CANCELLATION_SUCCESS"
export const trackExitAnalytics = () => {
  return
}

export const SubscriptionCancelModal = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation<ILocationState>()

  const [currentModalScreen, setCurrentModalScreen] =
    useState<TSubscriptionCancelScreenNames>("RETENTION")
  const closeModal = () => {
    setCurrentModalScreen("RETENTION")
    navigateFromModal({ defaultBackRoute: RoutesEnum.SUBSCRIPTION_UPGRADE, history, location })
  }

  const progressBarItems = ["Plan Details", "Review Cancellation", "Confirm Cancellation"]
  const SUBSCRIPTION_UPGRADE_SCREENS: {
    [key in TSubscriptionCancelScreenNames]: {
      component: React.ReactElement
      progressItem: string
    }
  } = {
    RETENTION: {
      component: <RetentionScreen {...{ setCurrentModalScreen, closeModal }} />,
      progressItem: progressBarItems[0],
    },
    CONFIRM_CANCELLATION: {
      component: <ConfirmCancellationScreen {...{ setCurrentModalScreen, closeModal }} />,
      progressItem: progressBarItems[1],
    },
    CANCELLATION_SUCCESS: {
      component: <CancellationSuccessScreen {...{ closeModal }} />,
      progressItem: progressBarItems[2],
    },
  }

  const isRouteMatch = useRouteMatch({ path: RoutesEnum.SUBSCRIPTION_CANCEL, exact: false })
  const scrollContainerRef = useRef<HTMLDivElement>(null)

  const currentScreen = SUBSCRIPTION_UPGRADE_SCREENS[currentModalScreen]
  const isOpen = Boolean(isRouteMatch?.isExact)

  return (
    <FlowModal
      pageTitle={t("subscriptions.cancel.pageTitle")}
      scrollContainerRef={scrollContainerRef}
      containerStyle={{ height: "90vh", paddingY: "50px" }}
      layout={"centered"}
      isOpen={isOpen}
      onExitButtonClick={() => {
        trackExitAnalytics()
        closeModal()
      }}
      progressBar={{
        currentItem: currentScreen.progressItem,
        progressItems: progressBarItems,
      }}
    >
      {currentScreen.component}
    </FlowModal>
  )
}
