import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Text } from "@components/primitive/text/text"
import { theme } from "@layouts/theme"
import { Modal } from "@components/extended/overlay/modal"
import { InfoBlock } from "@components/composite/info-block/info-block"
import { ButtonTypeEnum } from "@components/primitive/button/button"
import { FlexColumnLayout, FlexRowLayout } from "@layouts/flex"
import { RevenueHubConnectionPlatformEnum, useBankAccountInfoQuery } from "@generated/graphql"
import { selectActiveBusinessID } from "@core/active-business/redux/selectors"
import { useAppSelector } from "@core/redux/utils"
import { CopyableField } from "@components/composite/copyable-field/copyable-field"
import { RevenueHubPlatformInfo } from "../platforms"
import { Analytics } from "@core/analytics/actions"
import { events } from "@core/analytics/events"

interface PayoutInstructionsProps {
  platformInfo: RevenueHubPlatformInfo
}

export const PayoutInstructions = ({ platformInfo }: PayoutInstructionsProps) => {
  const { t } = useTranslation()
  const businessId = useAppSelector(selectActiveBusinessID)

  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <>
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault()
          setIsModalOpen(true)
          Analytics.track(events.revenueHub.depositFundsClick, {
            businessId,
            platformName: platformInfo.name,
          })
        }}
      >
        <Text
          tag="body-small"
          textColor={theme.colors.blue100}
          style={{ textDecoration: "underline" }}
        >
          {t("revenueHub.platformActive.payoutInstructions.transferFundsToNorthOne")}
        </Text>
      </a>
      <PayoutInstructionsModal
        platformInfo={platformInfo}
        isModalOpen={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
      />
    </>
  )
}

interface PayoutInstructionsModalProps {
  platformInfo: RevenueHubPlatformInfo
  isModalOpen: boolean
  closeModal: () => void
}
const PayoutInstructionsModal = ({
  platformInfo,
  isModalOpen,
  closeModal,
}: PayoutInstructionsModalProps) => {
  const businessId = useAppSelector(selectActiveBusinessID)
  const { t } = useTranslation()

  const { data } = useBankAccountInfoQuery({
    variables: { businessId },
    fetchPolicy: "cache-first",
  })
  const { routingNumber, accountNumber } = data?.business?.bankAccount || {}

  const platformSpecificInstructionsI18nKey = getPlatformSpecificInstructionsI18nKey(platformInfo)

  return (
    <Modal isOpen={isModalOpen} sx={{ minWidth: 400 }}>
      <InfoBlock
        title={t("revenueHub.platformActive.payoutInstructions.title")}
        subtitle={t("revenueHub.platformActive.payoutInstructions.subtitle", {
          platformName: platformInfo.name,
        })}
        bodyText={
          <FlexColumnLayout sx={{ mt: 3, gap: 3 }}>
            {!!platformSpecificInstructionsI18nKey && (
              <Text tag="body" textColor={theme.colors.ui2} hasPartialBolding={true}>
                {t(platformSpecificInstructionsI18nKey)}
              </Text>
            )}

            <FlexRowLayout justifyContent="flex-start" sx={{ maxWidth: 300, gap: 3 }}>
              <CopyableField
                value={routingNumber || ""}
                label={t("revenueHub.platformActive.payoutInstructions.routing")}
              />
              <CopyableField
                value={accountNumber || ""}
                label={t("revenueHub.platformActive.payoutInstructions.account")}
              />
            </FlexRowLayout>
          </FlexColumnLayout>
        }
        buttons={[
          {
            type: ButtonTypeEnum.PRIMARY_BLUE,
            onClick: () => closeModal(),
            children: t("buttons.done"),
          },
        ]}
        containerSx={{ maxWidth: "none" }}
      />
    </Modal>
  )
}

const getPlatformSpecificInstructionsI18nKey = (
  platformInfo: RevenueHubPlatformInfo,
): string | undefined => {
  switch (platformInfo.type) {
    case RevenueHubConnectionPlatformEnum.AMAZON: {
      return "revenueHub.platformActive.payoutInstructions.platforms.amazon"
    }
    case RevenueHubConnectionPlatformEnum.ETSY: {
      return "revenueHub.platformActive.payoutInstructions.platforms.etsy"
    }
    case RevenueHubConnectionPlatformEnum.PAYPAL: {
      return "revenueHub.platformActive.payoutInstructions.platforms.paypal"
    }
    case RevenueHubConnectionPlatformEnum.SHOPIFY: {
      return "revenueHub.platformActive.payoutInstructions.platforms.shopify"
    }
    case RevenueHubConnectionPlatformEnum.SQUARE: {
      return "revenueHub.platformActive.payoutInstructions.platforms.square"
    }
    case RevenueHubConnectionPlatformEnum.STRIPE: {
      return "revenueHub.platformActive.payoutInstructions.platforms.stripe"
    }
  }
}
