import React from "react"
import { FlexColumnLayout, Text, theme } from "@northone/ui"
import { SxStyleProp } from "rebass"
import { useTranslation } from "react-i18next"

const i18nKey = {
  tile: "invoicePayments.modal.invoiceDetails.addNewContact.corporation.emptyResult.title",
  subtitle: "invoicePayments.modal.invoiceDetails.addNewContact.corporation.emptyResult.subtitle",
}

export function CorporationEmptyResult() {
  const { t } = useTranslation()

  return (
    <FlexColumnLayout alignItems="center" justifyItems="center" sx={styles.containerStyle}>
      <Text tag="body-bold" sx={styles.boldTextStyle}>
        {t(i18nKey.tile)}
      </Text>
      <Text tag="body" sx={styles.greyTextStyle}>
        {t(i18nKey.subtitle)}
      </Text>
    </FlexColumnLayout>
  )
}

const styles: Record<string, SxStyleProp> = {
  containerStyle: { height: "100%", width: "100%", margin: "auto", paddingTop: 50 },
  boldTextStyle: {
    paddingBottom: 3,
  },
  greyTextStyle: {
    color: theme.colors.ui2,
  },
}
