import React from "react"
import { Box, SxStyleProp } from "rebass"
import { Modal } from "@components/extended/overlay/modal"
import { theme } from "@layouts/theme"

interface ModalProps {
  sx?: SxStyleProp | undefined
  isOpen: boolean
  gridGap?: number
  onOutsideClick?: () => void
  children?: React.ReactNode
}
export const ModalWithGrid = ({
  isOpen,
  children,
  sx,
  gridGap = 0,
  onOutsideClick,
}: ModalProps) => {
  return (
    <Modal
      sx={{ ...sx }}
      zIndex={theme.zIndex.flowModal}
      isOpen={isOpen}
      onOutsideClick={onOutsideClick}
    >
      <Box
        sx={{
          display: "grid",
          gridGap: gridGap,
          gridTemplateColumns: "repeat(auto-fit, minmax(128px, 1fr))",
        }}
      >
        {children}
      </Box>
    </Modal>
  )
}
