import React, { useState } from "react"
import { Box, SxStyleProp } from "rebass"
import { FlexColumnLayout } from "@layouts/flex"
import { TTextTag, Text } from "@components/primitive/text/text"
import { theme } from "@layouts/theme"
import { useTranslation } from "react-i18next"
import { Button, ButtonTypeEnum, IButtonProps } from "@components/primitive/button/button"
import { handleSupportButtonClick } from "@features/settings/contact-info/contact-info"

export type TErrorScreenSize = "small" | "medium" | "large"

export interface IErrorScreenProps {
  title?: string
  titleTag?: TTextTag
  subtitle?: string
  retryTitle?: string
  retry?: () => void
  sx?: SxStyleProp
  subtitleSx?: SxStyleProp
  size: TErrorScreenSize
  buttons?: IButtonProps[]
  buttonContainerSx?: SxStyleProp
  image?: React.ReactElement
  titleSx?: SxStyleProp
  buttonSx?: SxStyleProp
}

export const ErrorScreen: React.FC<IErrorScreenProps> = ({
  title,
  titleTag,
  subtitle,
  retryTitle,
  retry,
  sx,
  size,
  buttons,
  buttonContainerSx,
  subtitleSx,
  image,
  titleSx,
  buttonSx,
}) => {
  const { t } = useTranslation()
  const [isIntercomOpen, setIsIntercomOpen] = useState(false)
  const defaultTitleTag: TTextTag = size === "large" ? "h3Serif" : "h5"

  return (
    <FlexColumnLayout
      sx={{
        alignItems: ["center", "flex-start"],
        textAlign: ["center", "inherit"],
        width: "100%",
        paddingBottom: 20,
        justifyContent: "center",
        minHeight: size === "large" ? "50vh" : "auto",
        ...sx,
      }}
    >
      {image}
      <Text tag={titleTag || defaultTitleTag} sx={{ paddingY: 10, ...titleSx }}>
        {title || t("errors.genericScreen.title")}
      </Text>
      {subtitle ? (
        <Text
          tag={size === "large" ? "body" : "body-small"}
          textColor={theme.colors.ui2}
          hasPartialBolding
          sx={{ ...subtitleSx }}
        >
          {subtitle}
        </Text>
      ) : (
        <Text
          tag={size === "large" ? "body" : "body-small"}
          textColor={theme.colors.ui2}
          hasPartialBolding
          sx={{ ":hover": { cursor: "pointer" } }}
        >
          {t("errors.genericScreen.subtitle")}
          <span
            style={{ textDecoration: "underline" }}
            onClick={() => handleSupportButtonClick(isIntercomOpen, setIsIntercomOpen)}
          >
            {t("errors.genericScreen.contactSupport")}
          </span>
        </Text>
      )}
      {retry && (
        <Button type={ButtonTypeEnum.SECONDARY} onClick={retry} sx={{ marginTop: 20, ...buttonSx }}>
          {retryTitle || t("errors.genericScreen.button")}
        </Button>
      )}
      {buttons && (
        <Box
          sx={{
            display: "flex",
            marginTop: "15px",
            width: ["100%", "100%", "none"],
            flexDirection: ["column", "row", "row"],
            ...buttonContainerSx,
          }}
        >
          {buttons.map((buttonProps, index) => (
            <Button
              key={index}
              {...buttonProps}
              sx={{
                marginLeft: [0, index !== 0 ? 12 : 0],
                ...buttonProps.sx,
                marginTop: [index !== 0 ? 10 : 0, 0],
                ...buttonProps.sx,
              }}
            />
          ))}
        </Box>
      )}
    </FlexColumnLayout>
  )
}
