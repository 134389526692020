import React from "react"
import { Box, BoxProps } from "rebass"
import { theme } from "./theme"

/**
 * Defines the grid layout and gutter width
 * @param props.children
 */
export const Grid: React.FC<BoxProps> = (props) => (
  <Box
    sx={{
      display: "grid",
      gridTemplateColumns: "repeat(12, 1fr)",
      columnGap: [46, theme.gutterWidth],
      gridTemplateRows: "1fr",
      ...props.sx,
    }}
  >
    {props.children}
  </Box>
)
