import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useHistory, useLocation } from "react-router-dom"
import { FlexRowLayout, FlexColumnLayout, ToolTip } from "@northone/ui"
import { v4 as uuid } from "uuid"
import { Box, Image } from "rebass"
import { Label as RLabel } from "@rebass/forms"

import { appActions } from "@core/redux/app/actions"
import { ILocationState, RoutesEnum } from "@routers/types"
import { navigateFromModal } from "@utils/navigation"
import { convertDateToString, convertStringToDate } from "@utils/format"
import { validate } from "@utils/validate"
import {
  InvoiceAccountPayableCreatedVia,
  InvoiceAttachmentStatus,
  useCreateInvoiceAccountsPayableContactMutation,
  useGetInvoiceAttachmentDataLazyQuery,
  useInvoicePaymentsContactLazyQuery,
  useInvoicePaymentsContactListQuery,
  useSaveInvoiceAccountsPayableMutation,
  useUpdateInvoiceContactMutation,
  useGetInvoiceAccountsPayableLazyQuery,
  GetInvoiceAccountsPayableQuery,
} from "@generated/graphql"
import { DatepickerDropdown } from "@components/composite/datepicker-dropdown/datepicker-dropdown"
import { FormBody } from "@components/composite/form-body/form-body"
import { Button, ButtonTypeEnum } from "@components/primitive/button/button"
import { Dropdown } from "@components/composite/dropdown/dropdown"
import { TextInput } from "@components/extended/fields/text-input"
import { MaskTextInput } from "@components/extended/fields/mask-input"
import { Text } from "@components/primitive/text/text"
import { invoicePaymentsActions } from "@features/invoice-payments/redux/actions"
import { TInvoiceAccountsPayable } from "@features/invoice-payments/redux/reducer"
import { useAppSelector } from "@core/redux/utils"
import { images } from "@assets/images/images"
import { AddNewContactModal } from "./add-new-contact/add-new-contact-modal"
import { useBankAccountInfo, useListInvoiceAccountsPayable } from "@features/invoice-payments/hooks"
import { theme } from "@layouts/theme"
import { UploadedAttachmentNotice } from "./uploaded-attachment-notice"
import { INVOICE_DATEPICKER_FORMAT } from "../constant"
import { NORTHONE_TERMS_OF_SERVICE_URL } from "@utils/constants"
import { Analytics } from "@core/analytics/actions"
import { events } from "@core/analytics/events"
import { AutoExtractedInvoiceNotice } from "./auto-extracted-invoice-notice"
import { getInvoicePayments } from "@features/invoice-payments/redux/selector"
import { selectActiveBusinessID } from "@core/active-business/redux/selectors"

// the placeholder value for contactId that represents create new contact
const NEW_CONTACT_ID_PLACEHOLDER = null

const VENDOR_NAME_CHARACTERS_LIMIT = 32

export const InvoiceModalDetails = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const location = useLocation<ILocationState>()
  const history = useHistory()
  const [idempotencyKey] = useState(uuid())
  const businessId = useAppSelector(selectActiveBusinessID)
  const { invoiceDetails, isInvoiceDetailsEditingEnabled, uploadedAttachment } =
    useAppSelector(getInvoicePayments)
  const createdVia = useAppSelector((state) => state.invoicePayments.activeInvoice?.createdVia)
  const isAutoExtractedInvoice = createdVia && createdVia !== InvoiceAccountPayableCreatedVia.USER

  const [isSaveButtonLoading, setIsSaveButtonLoading] = useState(false)
  const [isPayInvoiceButtonLoading, setIsPayInvoiceButtonLoading] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [vendorName, setVendorName] = useState<string>(invoiceDetails?.vendorName || "")
  const initialContactId =
    isAutoExtractedInvoice && !invoiceDetails?.contactId
      ? NEW_CONTACT_ID_PLACEHOLDER
      : invoiceDetails?.contactId
  const [contactId, setContactId] =
    useState<string | undefined | typeof NEW_CONTACT_ID_PLACEHOLDER>(initialContactId)
  const [invoiceTotal, setInvoiceTotal] = useState<string>(
    invoiceDetails?.totalAmount?.toFixed(2) || "",
  )
  const [invoiceNumber, setInvoiceNumber] = useState<string>(invoiceDetails?.invoiceNumber || "")
  const [invoiceDueDate, setInvoiceDueDate] = useState<string | undefined>(invoiceDetails?.dueDate)
  const [invoiceDate, setInvoiceDate] = useState<string | undefined>(invoiceDetails?.invoiceDate)
  const [description, setDescription] = useState<string>(invoiceDetails?.description || "")
  const [vendorEmail, setVendorEmail] = useState<string>("")
  const [isAddVendorButtonHover, setIsAddVendorButtonHover] = useState(false)
  const [isAddNewContactModalOpen, setIsAddNewContactModalOpen] = useState(false)
  const [isPollingForAttachmentData, setIsPollingForAttachmentData] = useState(false)
  const initialExtractedVendorName =
    isAutoExtractedInvoice && !invoiceDetails?.contactId ? invoiceDetails?.vendorName : undefined
  const [extractedVendorName, setExtractedVendorName] = useState<string | undefined>(
    initialExtractedVendorName,
  )

  const [getInvoiceAccountsPayable] = useGetInvoiceAccountsPayableLazyQuery({
    fetchPolicy: "cache-and-network",
  })
  // invoiceId does not change
  const invoiceId = invoiceDetails?.invoiceId

  const {
    data: contactData,
    loading: isContactListLoading,
    refetch: refetchContactList,
  } = useInvoicePaymentsContactListQuery({
    variables: { businessId },
  })

  const invoiceSaveVariables = {
    businessId,
    invoiceData: {
      invoiceId: invoiceId,
      dueDate: invoiceDueDate,
      totalAmount: Number(invoiceTotal),
      idempotencyKey,
      invoiceNumber,
      invoiceDate,
      description,
      vendorName,
      contactId,
      attachmentId: invoiceDetails?.attachmentId,
    },
  }

  const [saveInvoiceMutation] = useSaveInvoiceAccountsPayableMutation({
    onError: () => {
      setIsSaveButtonLoading(false)
      setHasError(true)
    },
    onCompleted: async () => {
      await pollInvoices()

      await refetchInvoices()
    },
  })

  const [contactUpdate] = useUpdateInvoiceContactMutation()

  const invoicesQueryVariables = useAppSelector(
    (state) => state.invoicePayments.invoicesQueryVariables,
  )
  const {
    invoices,
    poll: pollInvoices,
    refetch: refetchInvoices,
  } = useListInvoiceAccountsPayable({
    queryVariables: invoicesQueryVariables,
  })

  const contacts = (contactData?.business?.contacts ? [...contactData.business.contacts] : []).sort(
    (a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1),
  )

  const contact = contacts.find((c) => c.id === contactId)

  const [getInvoiceAttachment, { stopPolling }] = useGetInvoiceAttachmentDataLazyQuery({
    pollInterval: 250,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const status = data.getInvoiceAttachment?.status
      if (status === InvoiceAttachmentStatus.FAILED) {
        stopPolling && stopPolling()
        setHasError(true) // TODO: display more helpful error message?
        setIsPollingForAttachmentData(false)
        dispatch(invoicePaymentsActions.clearUploadedAttachmentDetails())
      }

      const attachmentData = data.getInvoiceAttachment?.attachmentData
      if (!attachmentData || status !== InvoiceAttachmentStatus.COMPLETED) return

      if (!isPollingForAttachmentData) {
        // We set isPollingForAttachmentData to `false`
        // once all attachmentData fields have been set in state.
        // To prevent setting the same data again once the data has already been set,
        // we return early if isPollingForAttachmentData is `false`
        // NOTE: onCompleted runs multiple times for lazy queries when state is set inside onCompleted
        // This early return prevents re-running the same logic multiple times unnecesssarily
        // See: https://github.com/apollographql/react-apollo/issues/3505
        return
      }
      stopPolling && stopPolling()
      if (attachmentData.invoiceNumber) setInvoiceNumber(attachmentData.invoiceNumber)
      if (attachmentData.invoiceTotal) setInvoiceTotal(attachmentData.invoiceTotal)
      if (attachmentData.invoiceDate) setInvoiceDate(attachmentData.invoiceDate)
      if (attachmentData.invoiceDueDate) setInvoiceDueDate(attachmentData.invoiceDueDate)
      if (attachmentData.vendorName) {
        const contactMatch = contacts.find(
          (contact) => contact.name.toLowerCase() === attachmentData.vendorName?.toLowerCase(),
        )
        if (contactMatch) {
          setContactId(contactMatch.id)
          setVendorName(contactMatch.name)

          Analytics.track(
            events.invoicePayments.invoiceDetails.attachmentContactMatchAutoSelected,
            {
              invoiceId,
              contactId: contactMatch.id,
              contactName: contactMatch.name,
              rppsBillerId: contactMatch.rppsBillerDetails?.rppsBillerId,
              contactIdPreviouslySelected: contactId,
              contactPreviouslySelectedRppsBillerId: contact?.rppsBillerDetails?.rppsBillerId,
            },
          )
        } else {
          if (!contactId) {
            setContactId(NEW_CONTACT_ID_PLACEHOLDER)
            setVendorName(attachmentData.vendorName.slice(0, VENDOR_NAME_CHARACTERS_LIMIT))
            setExtractedVendorName(attachmentData.vendorName)

            Analytics.track(
              events.invoicePayments.invoiceDetails.attachmentNewContactPlaceholderAutoSelected,
              {
                invoiceId,
                extractedVendorName: attachmentData.vendorName,
              },
            )
          } else {
            Analytics.track(
              events.invoicePayments.invoiceDetails.attachmentContactAlreadyManuallySelected,
              {
                invoiceId,
                contactId,
                contactName: contact?.name,
                rppsBillerId: contact?.rppsBillerDetails?.rppsBillerId,
              },
            )
          }
        }
      }

      dispatch(invoicePaymentsActions.clearUploadedAttachmentDetails())
      setIsPollingForAttachmentData(false)
    },
  })

  const [createContactMutation] = useCreateInvoiceAccountsPayableContactMutation()
  const createContact = async () => {
    if (!extractedVendorName) return

    const result = await createContactMutation({
      variables: {
        input: {
          businessId,
          name: extractedVendorName,
        },
      },
    })
    const contactId = result.data?.contactCreate?.contact.id
    if (result.errors || !contactId) {
      setHasError(true)
      return
    }

    Analytics.track(events.invoicePayments.invoiceDetails.extractedVendorSavedAsContact, {
      extractedVendorName,
      contactId,
      invoiceId,
      isAutoCreatedInvoice: isAutoExtractedInvoice,
    })

    return contactId
  }

  // preload subaccount info
  useBankAccountInfo()

  // lazy query to preload selected contact's payment details
  const [fetchContact] = useInvoicePaymentsContactLazyQuery()

  const saveInvoice = async () => {
    const invoiceContactId =
      contactId === NEW_CONTACT_ID_PLACEHOLDER ? await createContact() : contactId

    const result = await saveInvoiceMutation({
      variables: {
        ...invoiceSaveVariables,
        invoiceData: { ...invoiceSaveVariables.invoiceData, contactId: invoiceContactId },
      },
    })
    return { contactId: invoiceContactId, ...result }
  }

  const updateContactEmail = async (contactId: string) => {
    await contactUpdate({
      variables: {
        input: {
          businessId,
          contactId,
          email: vendorEmail.length > 0 ? vendorEmail : null,
        },
      },
    })
  }

  const handleSaveInvoiceButtonClick = async () => {
    Analytics.track(events.invoicePayments.invoiceDetails.saveInvoiceClick, {
      invoiceId,
    })

    setIsSaveButtonLoading(true)
    setHasError(false)

    const { errors, data } = await saveInvoice()
    const newInvoiceId = data?.saveInvoiceAccountsPayable?.invoiceId

    // If we have errors, we don't want to navigate away from the modal
    if (errors || !newInvoiceId) {
      setHasError(true)
      setIsSaveButtonLoading(false)
      return
    }

    if (contactId && vendorEmail !== contact?.email) {
      await updateContactEmail(contactId)
    }

    await pollInvoices()
    await refetchInvoices()

    navigateFromModal({
      defaultBackRoute: RoutesEnum.INVOICES,
      history,
      location,
    })

    // Update action invoice in quick view
    const updatedActiveInvoice = invoices.find(
      (invoice: TInvoiceAccountsPayable) => invoice.id === newInvoiceId,
    )

    dispatch(invoicePaymentsActions.setActiveInvoice(updatedActiveInvoice))

    const confirmationToastMessage = t(
      "invoicePayments.modal.invoiceDetails.confirmationToastMessage.invoiceSaved",
    )

    dispatch(appActions.setNotificationMessage(confirmationToastMessage))
  }

  // If the invoice details fields have changed, a new invoice is created
  // and the invoiceId is updated in the invoicePayments state
  const isInvoiceDetailsChanged = invoiceDetails
    ? vendorName !== invoiceDetails?.vendorName ||
      invoiceNumber !== invoiceDetails?.invoiceNumber ||
      invoiceTotal !== invoiceDetails?.totalAmount?.toFixed(2) ||
      invoiceDate !== invoiceDetails?.invoiceDate ||
      invoiceDueDate !== invoiceDetails?.dueDate ||
      description !== invoiceDetails?.description ||
      contactId !== invoiceDetails?.contactId
    : Boolean(
        vendorName ||
          invoiceNumber ||
          invoiceTotal ||
          invoiceDate ||
          invoiceDueDate ||
          description ||
          contactId,
      )

  const handlePayInvoiceButtonClick = async () => {
    Analytics.track(events.invoicePayments.invoiceDetails.payInvoiceClick, {
      invoiceId,
    })

    setIsPayInvoiceButtonLoading(true)
    setHasError(false)

    if (contactId && vendorEmail !== (contact?.email ?? "")) {
      await updateContactEmail(contactId)
    }

    if (isInvoiceDetailsChanged) {
      const { data, errors, contactId } = await saveInvoice()
      const invoiceId = data?.saveInvoiceAccountsPayable?.invoiceId
      if (errors || !invoiceId) {
        setHasError(true)
        setIsPayInvoiceButtonLoading(false)
        return
      }

      // poll for invoice to exist
      let invoiceData: undefined | GetInvoiceAccountsPayableQuery
      while (!invoiceData) {
        const { data } = await getInvoiceAccountsPayable({
          variables: {
            businessId,
            invoiceId,
          },
        }).catch()
        invoiceData = data
      }

      const updatedInvoice = invoiceData?.getInvoiceAccountsPayable?.invoice

      dispatch(invoicePaymentsActions.setActiveInvoice(updatedInvoice))

      dispatch(
        invoicePaymentsActions.setInvoiceDetails({
          ...invoiceSaveVariables.invoiceData,
          contactId,
          invoiceId,
        }),
      )
    }

    const confirmationToastMessage = t(
      "invoicePayments.modal.invoiceDetails.confirmationToastMessage.invoiceSaved",
    )

    dispatch(appActions.setNotificationMessage(confirmationToastMessage))

    dispatch(invoicePaymentsActions.navigate("PAYMENT_DETAILS"))
  }

  const isExtractedDataLoading = Boolean(
    uploadedAttachment?.attachmentId ||
      uploadedAttachment?.isUploading ||
      isPollingForAttachmentData,
  )
  const isAttachmentUploaded = Boolean(uploadedAttachment?.attachmentId)

  const isValidEmail = vendorEmail.length > 0 ? validate.email(vendorEmail) : true // still valid if vendor email is an empty string

  const isButtonDisabled =
    !vendorName ||
    !invoiceTotal ||
    !invoiceNumber ||
    !invoiceDate ||
    !isValidEmail ||
    contactId === undefined

  async function pollForContact(contactId: string) {
    // poll for the new contact in the contact list query
    let contact
    do {
      // wait for 100ms between polling attempts
      const waitTimeInMs = 100
      await new Promise((resolve) => setTimeout(resolve, waitTimeInMs))
      const result = await refetchContactList()
      const contacts = result?.data?.business?.contacts || []
      contact = contacts.find((contact) => contact.id === contactId)
    } while (!contact)
    setContactId(contactId)
    setVendorName(contact.name)
  }

  useEffect(() => {
    if (contact && "email" in contact) {
      setVendorEmail(contact.email ? contact.email : "")
    }
  }, [contactId, contact?.email])

  useEffect(() => {
    dispatch(invoicePaymentsActions.setHasInvoiceDetailsChanged(isInvoiceDetailsChanged))
  }, [isInvoiceDetailsChanged])

  return (
    <>
      <AddNewContactModal
        isOpen={isAddNewContactModalOpen}
        closeOverlay={() => {
          setIsAddNewContactModalOpen(false)
        }}
        onContactCreated={pollForContact}
      />
      <FormBody
        errorMessage={hasError ? t("errors.generic") : undefined}
        containerSx={{ width: "50%", padding: "40px" }}
        title={t("invoicePayments.modal.invoiceDetails.title")}
        buttonContainerSx={{ width: "100%", marginTop: "auto", justifyContent: "flex-end" }}
        displayButtonsOnBottom
        buttons={[
          {
            children: t("invoicePayments.modal.invoiceDetails.buttons.saveInvoice"),
            onClick: handleSaveInvoiceButtonClick,
            type: ButtonTypeEnum.SECONDARY,
            isLoading: isSaveButtonLoading,
            disabled: isButtonDisabled,
          },
          {
            canContinueWithKey: !isAddNewContactModalOpen, // ENTER key submits the form as long as the add new contact modal is not open
            children: t("invoicePayments.modal.invoiceDetails.buttons.continueToPaymentDetails"),
            onClick: handlePayInvoiceButtonClick,
            isLoading: isPayInvoiceButtonLoading,
            disabled: isButtonDisabled,
          },
        ]}
      >
        {isAttachmentUploaded && (
          <UploadedAttachmentNotice
            onScanButtonClick={async () => {
              Analytics.track(events.invoicePayments.invoiceDetails.scanInvoiceClick, {
                invoiceId,
                attachmentId: uploadedAttachment?.attachmentId,
              })

              if (!uploadedAttachment?.attachmentId) return
              await getInvoiceAttachment({
                variables: { businessId, attachmentId: uploadedAttachment.attachmentId },
              })
              setIsPollingForAttachmentData(true)
            }}
            onManualButtonClick={() => {
              Analytics.track(events.invoicePayments.invoiceDetails.manuallyFillOutClick, {
                invoiceId,
                attachmentId: uploadedAttachment?.attachmentId,
              })

              dispatch(invoicePaymentsActions.clearUploadedAttachmentDetails())
            }}
          />
        )}
        {isAutoExtractedInvoice && (
          <AutoExtractedInvoiceNotice
            onArchive={async () => {
              // clear redux and close modal when the invoice is archived
              dispatch(invoicePaymentsActions.clearState())
              history.push(RoutesEnum.INVOICES)
              await refetchInvoices()
            }}
          />
        )}
        {isExtractedDataLoading ? (
          <LoadingForm />
        ) : (
          <FlexColumnLayout
            sx={{
              width: "100%",
              justifyContent: "flex-start",
            }}
          >
            <FlexRowLayout alignItems="flex-end">
              <Dropdown
                label={t("invoicePayments.modal.invoiceDetails.inputs.vendorName.label")}
                options={contacts
                  .map((contact) => {
                    const contactPaymentMethods = [
                      ...(contact.achPaymentDetails
                        ? [t("invoicePayments.paymentMethods.ach")]
                        : []),
                      ...(contact.wirePaymentDetails
                        ? [t("invoicePayments.paymentMethods.wire")]
                        : []),
                      ...(contact.mailedCheckPaymentDetails
                        ? [t("invoicePayments.paymentMethods.physicalCheck")]
                        : []),
                      ...(contact.rppsBillerDetails
                        ? [t("invoicePayments.paymentMethods.billPay")]
                        : []),
                    ]
                    const paymentMethodLabels = contactPaymentMethods.length
                      ? ` (${contactPaymentMethods.join(", ")})`
                      : ""
                    return {
                      text: `${contact.name}${paymentMethodLabels}`,
                      value: contact.id as string | null,
                      contactName: contact.name,
                    }
                  })
                  .concat(
                    extractedVendorName
                      ? [
                          {
                            text: `${extractedVendorName} *${t(
                              "invoicePayments.modal.invoiceDetails.inputs.vendorName.new",
                            )}*`,
                            value: NEW_CONTACT_ID_PLACEHOLDER as string | null,
                            contactName: extractedVendorName,
                          },
                        ]
                      : [],
                  )}
                placeholder={t(
                  "invoicePayments.modal.invoiceDetails.inputs.vendorName.placeholder",
                )}
                selectedValue={contactId}
                onSelect={async (contactId, { contactName }) => {
                  setVendorName(contactName)
                  setContactId(contactId)
                  if (contactId) {
                    // preload contact payment details when selected
                    await fetchContact({ variables: { businessId, contactId } })
                  }
                }}
                isLoading={isContactListLoading}
                disabled={!isInvoiceDetailsEditingEnabled}
              />
              <ToolTip
                position="top"
                visible={isAddVendorButtonHover}
                text={t(
                  "invoicePayments.modal.invoiceDetails.addNewContact.otherVendor.buttonTooltip",
                )}
                width={115}
              >
                <Button
                  sx={{
                    bg: "transparent",
                    px: 10,
                    ":hover": { bg: "transparent" },
                    ":active": { bg: "transparent" },
                  }}
                  onMouseOver={() => setIsAddVendorButtonHover(true)}
                  onMouseOut={() => setIsAddVendorButtonHover(false)}
                  onClick={() => {
                    setIsAddNewContactModalOpen(true)
                  }}
                  disabled={!isInvoiceDetailsEditingEnabled}
                >
                  <Image src={images.icons.addContactUi3} />
                </Button>
              </ToolTip>
            </FlexRowLayout>
            {contactId === NEW_CONTACT_ID_PLACEHOLDER && (
              <>
                <Text tag="body-x-small" textColor={theme.colors.ui2} sx={{ mt: 15 }}>
                  {t(
                    "invoicePayments.modal.invoiceDetails.inputs.vendorName.newContactDisclaimer1",
                  )}
                </Text>
                <Text
                  tag="body-x-small"
                  textColor={theme.colors.ui2}
                  textWithEmbeddedLinksProps={{
                    text: t(
                      "invoicePayments.modal.invoiceDetails.inputs.vendorName.newContactDisclaimer2",
                    ),
                    linksInOrder: [NORTHONE_TERMS_OF_SERVICE_URL],
                    linkStyle: { color: theme.colors.ui2 },
                  }}
                  sx={{ mt: 15 }}
                />
              </>
            )}
            {Boolean(vendorName) && (
              <FlexRowLayout sx={{ paddingTop: 22, columnGap: 16 }}>
                <TextInput
                  value={vendorEmail}
                  onChange={(value) => {
                    setVendorEmail(value)
                  }}
                  label={t("invoicePayments.modal.invoiceDetails.inputs.vendorEmail.label")}
                  placeholder={t(
                    "invoicePayments.modal.invoiceDetails.inputs.vendorEmail.placeholder",
                  )}
                  maxLength={32}
                  containerSx={{ flex: 1 }}
                  disabled={!isInvoiceDetailsEditingEnabled}
                  errorMessage={
                    vendorEmail && !isValidEmail
                      ? t("invoicePayments.modal.invoiceDetails.inputs.vendorEmail.invalidEmail")
                      : undefined
                  }
                />
              </FlexRowLayout>
            )}
            <FlexRowLayout sx={{ paddingTop: 22, columnGap: 16 }}>
              <MaskTextInput
                maskType={"AMOUNT"}
                label={t("invoicePayments.modal.invoiceDetails.inputs.invoiceTotal.label")}
                placeholder={t(
                  "invoicePayments.modal.invoiceDetails.inputs.invoiceTotal.placeholder",
                )}
                value={invoiceTotal}
                onChange={(value) => {
                  setInvoiceTotal(value.replace(/[$,]/g, ""))
                }}
                maxLength={12} // 9 characters are allowed but we must count the `$` `,` `.` signs
                containerSx={{ flex: 1 }}
                disabled={!isInvoiceDetailsEditingEnabled}
              />

              <TextInput
                value={invoiceNumber}
                onChange={(value) => {
                  setInvoiceNumber(value)
                }}
                label={t("invoicePayments.modal.invoiceDetails.inputs.invoiceNumber.label")}
                placeholder={t(
                  "invoicePayments.modal.invoiceDetails.inputs.invoiceNumber.placeholder",
                )}
                containerSx={{ flex: 1 }}
                disabled={!isInvoiceDetailsEditingEnabled}
                maxLength={25}
              />
            </FlexRowLayout>
            <FlexRowLayout sx={{ paddingTop: 22, columnGap: 16 }}>
              <DatepickerDropdown
                containerSx={{ flex: 1 }}
                label={t("invoicePayments.modal.invoiceDetails.inputs.invoiceDueDate.label")}
                placeholder={t(
                  "invoicePayments.modal.invoiceDetails.inputs.invoiceDueDate.placeholder",
                )}
                selectedDate={(invoiceDueDate && convertStringToDate(invoiceDueDate)) || null}
                onSelect={(date) => (date ? setInvoiceDueDate(convertDateToString(date)) : null)}
                minDate={null}
                disabled={!isInvoiceDetailsEditingEnabled}
                isClearDateDisabled
                customDateFormat={INVOICE_DATEPICKER_FORMAT}
              />
              <DatepickerDropdown
                containerSx={{ flex: 1 }}
                label={t("invoicePayments.modal.invoiceDetails.inputs.invoiceDate.label")}
                placeholder={t(
                  "invoicePayments.modal.invoiceDetails.inputs.invoiceDate.placeholder",
                )}
                selectedDate={(invoiceDate && convertStringToDate(invoiceDate)) || null}
                onSelect={(date) =>
                  date ? setInvoiceDate(convertDateToString(date) || undefined) : null
                }
                minDate={null}
                disabled={!isInvoiceDetailsEditingEnabled}
                isClearDateDisabled
                customDateFormat={INVOICE_DATEPICKER_FORMAT}
              />
            </FlexRowLayout>
            <TextInput
              value={description}
              onChange={(value) => {
                setDescription(value)
              }}
              label={t("invoicePayments.modal.invoiceDetails.inputs.description.label")}
              placeholder={t("invoicePayments.modal.invoiceDetails.inputs.description.placeholder")}
              containerSx={{ flex: 1, paddingTop: 22 }}
              maxLength={50}
            />
          </FlexColumnLayout>
        )}
      </FormBody>
    </>
  )
}

const LoadingBox = () => <Box sx={{ background: theme.colors.gold10, height: 40 }} />
const LoadingField = (props: { label: string }) => (
  <FlexColumnLayout sx={{ flex: 1, mb: 22 }}>
    <RLabel sx={{ ...theme.textStyles.label, color: theme.colors.ui2 }}>{props.label}</RLabel>
    <LoadingBox />
  </FlexColumnLayout>
)

const LoadingForm = () => {
  const { t } = useTranslation()
  return (
    <FlexColumnLayout sx={{ flex: 0 }}>
      <LoadingField label={t("invoicePayments.modal.invoiceDetails.inputs.vendorName.label")} />
      <FlexRowLayout sx={{ columnGap: 16 }}>
        <LoadingField label={t("invoicePayments.modal.invoiceDetails.inputs.invoiceTotal.label")} />
        <LoadingField
          label={t("invoicePayments.modal.invoiceDetails.inputs.invoiceNumber.label")}
        />
      </FlexRowLayout>
      <FlexRowLayout sx={{ columnGap: 16 }}>
        <LoadingField
          label={t("invoicePayments.modal.invoiceDetails.inputs.invoiceDueDate.label")}
        />
        <LoadingField label={t("invoicePayments.modal.invoiceDetails.inputs.invoiceDate.label")} />
      </FlexRowLayout>
      <LoadingField label={t("invoicePayments.modal.invoiceDetails.inputs.description.label")} />
    </FlexColumnLayout>
  )
}
