import React from "react"
import { Analytics } from "@core/analytics/actions"
import { events } from "@core/analytics/events"
import { FlexRowLayout, theme, Text, Button, ButtonTypeEnum } from "@northone/ui"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { ManageCardButtons } from "../manage-card/manage-card-buttons"
import { cardsActions } from "../redux/actions"
import { useCardSummaryUiElements } from "./hooks"
import { CardDetails, CardStatusEnum } from "./transformers"

interface ICardSummaryFooterProps {
  card: CardDetails
}
export const CardSummaryFooter = ({ card }: ICardSummaryFooterProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const {
    cardId,
    isVirtualCard,
    isFullyActivated,
    isFrozen,
    isDisabled,
    productType,
    status: cardStatus,
    last4,
  } = card
  const isPendingPinSet = cardStatus === CardStatusEnum.PENDING_PIN_SET

  const { buttonsProps } = useCardSummaryUiElements({
    cardId,
    productType,
    cardStatus,
    isFrozen,
    isDisabled,
    last4,
  })

  const getNotFullyActivatedFooter = () => {
    const buttonCopy = isPendingPinSet
      ? t("cards.pinNotSet.contactCS")
      : t("cards.inactiveCard.haventReceived")

    const buttonOnClick = isPendingPinSet
      ? () => window.Intercom("show")
      : () => {
          Analytics.track(events.cards.notActivated.notArrived)
          dispatch(cardsActions.setCardNotArrivedModalOpen(true))
        }

    return (
      <>
        <Button
          type={ButtonTypeEnum.TERTIARY_SHORT}
          onClick={buttonOnClick}
          sx={{ ...(!isFullyActivated && { bg: theme.colors.ui6, paddingX: "8px" }) }}
        >
          {buttonCopy}
        </Button>
      </>
    )
  }

  const getContents = () => {
    if (isDisabled) {
      return <Text tag="body-x-small">{t("cards.disabledCard.message")}</Text>
    }
    if (isFullyActivated) {
      return <ManageCardButtons isFrozen={isFrozen} cardId={cardId} isVirtualCard={isVirtualCard} />
    }
    return getNotFullyActivatedFooter()
  }

  return (
    <FlexRowLayout
      sx={{ paddingTop: 18, alignItems: "center", borderTop: `0.89px solid ${theme.colors.ui4}` }}
    >
      {buttonsProps.map(({ sx, ...buttonProps }, idx) => (
        <Button key={idx} {...buttonProps} sx={{ marginRight: "10px", paddingX: "8px", ...sx }} />
      ))}
      {getContents()}
    </FlexRowLayout>
  )
}
