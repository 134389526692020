import React from "react"
import { useDispatch } from "react-redux"
import { useHistory, useLocation } from "react-router-dom"
import { Analytics } from "@core/analytics/actions"
import { RoutesEnum, ILocationState } from "@routers/types"
import { InfoBlockModal } from "@components/composite/info-block-modal/info-block-modal"
import { ButtonTypeEnum } from "@components/primitive/button/button"
import { moveMoneyActions } from "@features/move-money/redux/actions"
import { BUTTONS_CANCEL } from "@features/move-money/utils/i18n-translations"
import {
  DISCARD_DISCARD,
  DISCARD_TITLE,
  DISCARD_SUBTITLE,
} from "@features/move-money/utils/i18n-translations"
import { navigateFromModal } from "@utils/navigation"
import { images } from "@assets/images/images"

interface IDiscardPaymentModalProps {
  isOpen: boolean
  closeModal: () => void
  discardEvent?: string
  cancelEvent?: string
}

export const DiscardPaymentModal: React.FC<IDiscardPaymentModalProps> = ({
  isOpen,
  closeModal,
  discardEvent,
  cancelEvent,
}) => {
  const history = useHistory()
  const location = useLocation<ILocationState>()
  const dispatch = useDispatch()

  const buttons = [
    {
      children: DISCARD_DISCARD,
      onClick: () => {
        if (discardEvent) {
          Analytics.track(discardEvent)
        }
        navigateFromModal({ defaultBackRoute: RoutesEnum.DASHBOARD, history, location })
        closeModal()
        dispatch(moveMoneyActions.deleteDraft())
      },
    },
    {
      children: BUTTONS_CANCEL,
      type: ButtonTypeEnum.SECONDARY,
      onClick: () => {
        if (cancelEvent) {
          Analytics.track(cancelEvent)
        }
        closeModal()
      },
    },
  ]

  return (
    <InfoBlockModal
      title={DISCARD_TITLE}
      subtitle={DISCARD_SUBTITLE}
      isOpen={isOpen}
      imageSrc={images.illustrations.trashBin}
      imageDimensions={{ height: 180, width: 250 }}
      closeModal={closeModal}
      buttons={buttons}
    />
  )
}
