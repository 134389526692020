import React from "react"
import { Text } from "@components/primitive/text/text"
import { theme } from "@layouts/theme"
import { FlexRowLayout } from "@layouts/flex"
import { Box, Image, SxStyleProp } from "rebass"
export interface IListItem {
  text: string
  imgSrc: string
}
export interface IListItemBoxProps {
  listItems: IListItem[]
  containerSx?: SxStyleProp
}

export const ListItemBox: React.FC<IListItemBoxProps> = ({ listItems, containerSx }) => {
  return (
    <Box
      sx={{
        marginHorizontal: 36,
        backgroundColor: theme.colors.gold10,
        borderRadius: "6px",
        padding: 20,
        ...containerSx,
      }}
    >
      {listItems.map((item, idx) => (
        <FlexRowLayout
          key={idx}
          sx={{
            alignItems: "center",
            marginBottom: idx < listItems.length - 1 ? "8px" : undefined,
          }}
        >
          <Image src={item.imgSrc} sx={{ height: 32, width: 32 }} />
          <Text tag="body" sx={{ marginLeft: "5px" }} style={{ color: theme.colors.ui1 }}>
            {item.text}
          </Text>
        </FlexRowLayout>
      ))}
    </Box>
  )
}
