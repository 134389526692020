import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { MaskTextInput } from "@components/extended/fields/mask-input"
import { Contact, InvoiceAccountPayablePaymentMethod } from "@generated/graphql"
import { FlexRowLayout } from "@layouts/flex"

export type TBillPayPaymentDetails = {
  type: InvoiceAccountPayablePaymentMethod.BILL_PAY
  name: string
  billPayPaymentDetails: {
    accountNumber: string
  }
}
interface BillPayPaymentDetailsProps {
  contact: Contact
  hasAccountNumberError: boolean
  onChange: (contactPaymentDetails: TBillPayPaymentDetails) => void
  setIsBillPayContactPaymentDetailsChanged: (isChanged: boolean) => void
  setIsMissingFields: (isMissingFields: boolean) => void
  setHasAccountNumberError: (hasError: boolean) => void
}

const ACCOUNT_NUMBER_LABEL_I18N_KEY =
  "invoicePayments.modal.paymentDetails.inputs.accountNumber.label"
const ACCOUNT_NUMBER_PLACEHOLDER = "#################"

export function BillPayPaymentDetailsForm({
  contact,
  hasAccountNumberError,
  setIsBillPayContactPaymentDetailsChanged,
  setIsMissingFields,
  onChange,
  setHasAccountNumberError,
}: BillPayPaymentDetailsProps) {
  const { t } = useTranslation()

  const [accountNumber, setAccountNumber] = useState<string>(
    contact?.billPayPaymentDetails?.accountNumber ?? "",
  )

  const accountNumberErrorMessage = t(
    "invoicePayments.modal.paymentDetails.inputs.accountNumber.billPayError",
  )

  function handleAccountNumberChange(newValue: string) {
    setHasAccountNumberError(false)
    setAccountNumber(newValue)
  }

  const billPayPaymentDetails: TBillPayPaymentDetails = {
    type: InvoiceAccountPayablePaymentMethod.BILL_PAY,
    name: contact?.name,
    billPayPaymentDetails: {
      accountNumber,
    },
  }

  useEffect(() => {
    const hasContactDetailsChanged =
      !contact.billPayPaymentDetails?.accountNumber ||
      contact.billPayPaymentDetails.accountNumber !== accountNumber

    setIsBillPayContactPaymentDetailsChanged(hasContactDetailsChanged)
    setIsMissingFields(!accountNumber)

    onChange(billPayPaymentDetails)
  }, [accountNumber])

  return (
    <FlexRowLayout sx={{ columnGap: 16, mb: 22 }}>
      <MaskTextInput
        maskType="ACCOUNT_NUMBER"
        label={t(ACCOUNT_NUMBER_LABEL_I18N_KEY)}
        placeholder={ACCOUNT_NUMBER_PLACEHOLDER}
        guide={false}
        value={accountNumber}
        onChange={handleAccountNumberChange}
        errorMessage={hasAccountNumberError ? accountNumberErrorMessage : undefined}
      />
    </FlexRowLayout>
  )
}
