import React from "react"
import { ActivateCardDetails } from "../activate/activate-card-details"
import { CardPinSet } from "../pin-set/card-pin-set"
import { PinSetLoading } from "../pin-set/pin-set-loading"
import { PinSetSuccess } from "../pin-set/pin-set-success"

export enum CardSetupScreensEnum {
  CARD_SETUP_ACTIVATE = "CARD_SETUP_ACTIVATE",
  CARD_SETUP_PIN_SET = "CARD_SETUP_PIN_SET",
  CARD_SETUP_PIN_SET_LOADING = "CARD_SETUP_PIN_SET_LOADING",
  CARD_SETUP_PIN_SET_SUCCESS = "CARD_SETUP_PIN_SET_SUCCESS",
}

export const CardSetupRouter: { [key in CardSetupScreensEnum]: React.ReactElement } = {
  CARD_SETUP_ACTIVATE: <ActivateCardDetails />,
  CARD_SETUP_PIN_SET: <CardPinSet />,
  CARD_SETUP_PIN_SET_LOADING: <PinSetLoading />,
  CARD_SETUP_PIN_SET_SUCCESS: <PinSetSuccess />,
}
