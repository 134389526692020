import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import i18n from "@i18n/i18n"
import { useAppSelector } from "@core/redux/utils"
import { CheckboxListDropdown } from "@components/composite/checkbox-list-dropdown/checkbox-list-dropdown"
import { dashboardActions } from "@features/dashboard/redux/actions"
import {
  selectDashboardTransactionTypes,
  selectDashboardFilterCardId,
} from "@features/dashboard/redux/selectors"
import { TransactionTypeEnum, useCardsQuery } from "@generated/graphql"

import { FilterDropdownLabel } from "./panel"
import { CheckboxList } from "@components/composite/checkbox-list/checkbox-list"

export const TransactionTypesFields: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [cardIdsToLast4Map, setCardIdsToLast4Map] = React.useState<Record<string, string>>({})
  const transactionTypes = useAppSelector(selectDashboardTransactionTypes)
  const cardIdFilterList = useAppSelector(selectDashboardFilterCardId)
  const businessId = useAppSelector((state) => state.activeBusiness.businessId)

  const { data } = useCardsQuery({
    variables: { businessId },
  })

  React.useEffect(() => {
    if (data?.business?.bankAccounts[0].cards) {
      setCardIdsToLast4Map(
        data.business.bankAccounts[0].cards.reduce(
          (acc, val) => ({ ...acc, [val.id]: val.cardNumber?.last4 }),
          {},
        ),
      )
    }
  }, [data])

  const selectedCardsText = React.useCallback(
    () =>
      cardIdFilterList.length
        ? ` (${cardIdFilterList.map((c) => `• ${cardIdsToLast4Map[c] || ""}`).join(", ")})`
        : "",
    [cardIdFilterList, cardIdsToLast4Map],
  )

  const TRANSACTION_TYPE_ITEMS = React.useCallback(
    () => [
      { text: i18n.t("dashboard.filters.transactionMethod.ach"), value: TransactionTypeEnum.ACH },
      {
        // This filter type has to be last on the list so that it shows up just above `additionalContent`
        text: `${i18n.t("dashboard.filters.transactionMethod.card")}${selectedCardsText()}`,
        value: TransactionTypeEnum.CARD_PURCHASE,
        disabled: !!cardIdFilterList.length,
      },
    ],
    [selectedCardsText, cardIdFilterList.length],
  )()

  React.useEffect(() => {
    if (!transactionTypes.includes(TransactionTypeEnum.CARD_PURCHASE)) {
      dispatch(dashboardActions.setCardIdFilter([]))
    }
  }, [transactionTypes, dispatch])

  return (
    <CheckboxListDropdown
      labelComponent={
        <FilterDropdownLabel>{t("dashboard.filters.transactionMethod.label")}</FilterDropdownLabel>
      }
      placeholder={t("dashboard.filters.transactionMethod.all")}
      checkboxListProps={{
        items: [
          {
            text: "All methods",
            value: null,
            selected:
              transactionTypes.length === TRANSACTION_TYPE_ITEMS.length || !transactionTypes.length,
            disabled: !!transactionTypes.length,
          },
          ...TRANSACTION_TYPE_ITEMS,
        ],
        onValuesChange: (values) => {
          const updatedTransactionTypes = values.includes(null)
            ? []
            : (values as TransactionTypeEnum[])
          dispatch(dashboardActions.setTransactionTypes(updatedTransactionTypes))
        },
        values: transactionTypes,
      }}
      cardIdFilterList={cardIdFilterList}
      additionalContent={
        data?.business?.bankAccounts[0].cards ? (
          <CheckboxList
            checkboxSx={{ paddingBottom: 0, height: 40, paddingX: 30 }}
            items={data.business.bankAccounts[0].cards.map((card) => ({
              text: `• • • • ${card.cardNumber?.last4}`,
              value: card.id,
            }))}
            onValuesChange={(values) => {
              const updatedTransactionTypes =
                values.length && !transactionTypes.includes(TransactionTypeEnum.CARD_PURCHASE)
                  ? [...transactionTypes, TransactionTypeEnum.CARD_PURCHASE]
                  : transactionTypes

              updatedTransactionTypes.length !== transactionTypes.length &&
                dispatch(dashboardActions.setTransactionTypes(updatedTransactionTypes))

              dispatch(dashboardActions.setCardIdFilter(values))
            }}
            values={cardIdFilterList}
          />
        ) : undefined
      }
    />
  )
}
