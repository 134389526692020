import React from "react"
import { useTranslation } from "react-i18next"
import { SxStyleProp } from "rebass"
import { useAppSelector } from "@core/redux/utils"
import { selectActiveBusinessID } from "@core/active-business/redux/selectors"
import { CopyableField } from "@components/composite/copyable-field/copyable-field"
import { Text } from "@components/primitive/text/text"
import { ErrorScreen } from "@components/composite/error-screen/error-screen"
import { theme } from "@layouts/theme"
import { FlexRowLayout, FlexColumnLayout } from "@layouts/flex"
import { useBankAccountInfoQuery } from "@generated/graphql"

interface IBankAccountInfoProps {
  sx?: SxStyleProp
  subtitle?: string
}

export const BankAccountInfo: React.FC<IBankAccountInfoProps> = ({ sx, subtitle }) => {
  const { t } = useTranslation()
  const businessId = useAppSelector(selectActiveBusinessID)

  const { data, error, refetch } = useBankAccountInfoQuery({
    variables: { businessId },
    notifyOnNetworkStatusChange: true,
  })

  const routingNumber = data?.business?.bankAccount?.routingNumber || ""
  const accountNumber = data?.business?.bankAccount?.accountNumber || ""
  const bankName = data?.business?.bankAccount?.bankName || ""
  const bankAddress = data?.business?.bankAccount?.bankAddress || ""

  return (
    <FlexColumnLayout sx={{ paddingTop: subtitle ? "16px" : "35px", ...sx }}>
      {subtitle ? (
        <Text tag="body-small" sx={{ marginBottom: "8px" }} textColor={theme.colors.ui2}>
          {subtitle}
        </Text>
      ) : null}
      {error ? (
        <ErrorScreen size="small" retry={() => refetch()} />
      ) : (
        <>
          <FlexRowLayout sx={{ marginBottom: 16 }}>
            <CopyableField
              sx={{ marginRight: 12 }}
              value={accountNumber}
              label={t("dashboard.sidebar.accountNumber")}
            />
            <CopyableField value={routingNumber} label={t("dashboard.sidebar.routingNumber")} />
          </FlexRowLayout>
          <CopyableField
            info={t("dashboard.sidebar.bankNameHelp")}
            value={bankName}
            label={t("dashboard.sidebar.bankName")}
            sx={{ marginBottom: 16 }}
          />
          <CopyableField value={bankAddress || "\n\n"} label={t("dashboard.sidebar.bankAddress")} />
        </>
      )}
    </FlexColumnLayout>
  )
}
