import React, { useState, useRef } from "react"
import ReCAPTCHA from "react-google-recaptcha"
import { useTranslation } from "react-i18next"
import { Link } from "rebass"
import { DateTime } from "luxon"
import { LoadingIcon } from "@northone/ui"

import { Analytics } from "@core/analytics/actions"
import { events } from "@core/analytics/events"
import { Text } from "@components/primitive/text/text"
import { Dropdown } from "@components/composite/dropdown/dropdown"
import { IDropdownItemProps } from "@components/primitive/dropdown-item/dropdown-item"
import { MaskTextInput } from "@components/extended/fields/mask-input"
import { Button, ButtonTypeEnum } from "@components/primitive/button/button"
import { NoticeBox } from "@components/primitive/notice-box/notice-box"
import { AnimatePresence } from "@components/animations/animate-presence/animation-presence"
import { handleSupportButtonClick } from "@features/settings/contact-info/contact-info"
import { sanitizePhoneNumber } from "@utils/format"
import { validate } from "@utils/validate"
import { MAX_INFO_CHARACTER_LENGTH } from "@utils/constants"
import { images } from "@assets/images/images"
import { BaseLayout } from "@layouts/base-layout"
import { FlexColumnLayout, FlexRowLayout } from "@layouts/flex"
import {
  useOnboardingMeQuery,
  useAvailableSlotsForWeekQuery,
  useAvailableSlotsForDayLazyQuery,
  useBookPhoneCallMutation,
  SupportPhoneCallCreateInput,
} from "@generated/graphql"
import { formatDateDropdown, formatTime, RequestSent, ScheduleCallInfo } from "./helpers"
import { theme } from "@layouts/theme"
import { config } from "@utils/environment"

export type TScheduleCallTopics =
  | "atm"
  | "checks"
  | "accountBalance"
  | "pendingfunds"
  | "other"
  | "close"
  | "changeAddressOrAcountInfo"
  | "debitCard"
  | null
export type TTopicOption = { value: TScheduleCallTopics; text: string }

export const SupportScheduleCall: React.FC = () => {
  const { t } = useTranslation()
  const { zoneName: timezone } = DateTime.local() // eg. America/New_York
  const timezoneName = DateTime.local().toFormat("ZZZZ") // eg. EST

  const [availableTimeSlots, setAvailableTimeSlots] = useState<string[]>([])
  const [callTopic, setCallTopic] = useState<TTopicOption>({ value: null, text: "" })
  const [date, setDate] = useState("")
  const [timeSlot, setTimeSlot] = useState("")
  const [isIntercomOpen, setIsIntercomOpen] = useState(false)
  const [additionalInfo, setAdditionalInfo] = useState("")
  const [phone, setPhone] = useState("")
  const [requestSent, setRequestSent] = useState(false)
  const [hasError, setHasError] = useState(false)

  useOnboardingMeQuery({
    onCompleted: (userData) => {
      const rawPhoneNumber = userData?.me?.phone || ""
      setPhone(sanitizePhoneNumber(rawPhoneNumber))
    },
  })

  const mapTopics = (topic: TScheduleCallTopics) => ({
    text: t(`support.scheduleCall.topics.${topic}`),
    value: topic,
  })

  const callTopics: TTopicOption[] = (
    ["atm", "checks", "accountBalance", "pendingFunds", "other"] as TScheduleCallTopics[]
  ).map(mapTopics)
  const onlineChatTopics: TTopicOption[] = (
    ["close", "changeAddressOrAccountInfo", "debitCard"] as TScheduleCallTopics[]
  ).map(mapTopics)

  const topicOptions: TTopicOption[] = onlineChatTopics.concat(callTopics)

  const isOnlineChatTopic =
    onlineChatTopics.filter((topic) => topic.value === callTopic.value).length > 0

  const clearState = () => {
    setDate("")
    setAvailableTimeSlots([])
    setTimeSlot("")
    setCallTopic({ value: null, text: "" })
    setAdditionalInfo("")
    setRequestSent(false)
  }

  const buttonIsDisabled = () => {
    if (isOnlineChatTopic) {
      return false
    } else if (
      !date ||
      !timeSlot ||
      !callTopic.value ||
      !validate.phoneNumber(phone.replace(/[^0-9]/g, "")) ||
      additionalInfo.length > MAX_INFO_CHARACTER_LENGTH ||
      (callTopic.value === "other" && !additionalInfo)
    ) {
      return true
    }
    return false
  }

  const {
    data,
    loading,
    error: slotsForWeekError,
  } = useAvailableSlotsForWeekQuery({
    variables: { timezone },
  })

  const dateOptions: IDropdownItemProps<string>[] = data?.supportPhoneCall.availableSlotsForWeek
    ? data?.supportPhoneCall.availableSlotsForWeek.map((date) => ({
        value: date.date ? date.date : "",
        text: date.date ? formatDateDropdown(date) : "",
        disabled: !date.slots,
        readOnly: !date.slots,
      }))
    : [{ value: "", text: "" }]

  const timeSlots: IDropdownItemProps<string>[] = availableTimeSlots.map((timeslot) => ({
    value: timeslot,
    text: formatTime(timeslot),
  }))

  const callScheduleData: SupportPhoneCallCreateInput = {
    dateTime: timeSlot,
    notes: additionalInfo ? additionalInfo : callTopic.text,
    phone: phone || "",
    timezone,
    reCAPTCHAToken: "",
  }

  const [availableSlotsForDay, { error: slotsForDayError }] = useAvailableSlotsForDayLazyQuery({
    variables: { date, timezone },
    onCompleted: (data) => {
      setTimeSlot("")
      setAvailableTimeSlots(data.supportPhoneCall.availableSlotsForDay)
    },
    fetchPolicy: "network-only",
  })

  const [scheduleCall, { loading: mutationLoading }] = useBookPhoneCallMutation({
    variables: { data: callScheduleData },
    onCompleted: () => {
      setRequestSent(true)
      setHasError(false)
    },
    onError: () => setHasError(true),
  })

  const reCAPTCHARef = useRef<ReCAPTCHA>(null)

  const onScheduleCall = async () => {
    const reCAPTCHAToken = await reCAPTCHARef.current?.executeAsync()
    if (!reCAPTCHAToken) return setHasError(true)

    scheduleCall({ variables: { data: { ...callScheduleData, reCAPTCHAToken } } })

    reCAPTCHARef.current?.reset()
  }

  const ScheduleCallBody = (
    <>
      <Text tag="body" sx={{ marginBottom: 24 }}>
        {t("support.scheduleCall.subtitle", { timezone: timezoneName })}
      </Text>
      <AnimatePresence animation="fadeAndExpand">
        {hasError ? <NoticeBox level="error" text={t("support.errors.scheduleCall")} /> : null}
      </AnimatePresence>
      <FlexRowLayout>
        <FlexColumnLayout sx={{ marginRight: 15, width: "100%" }}>
          <Dropdown
            errorMessage={slotsForWeekError ? t("support.errors.timeSlots") : undefined}
            placeholder={t("support.scheduleCall.labels.selectDay")}
            onSelect={(date) => {
              setDate(date)
              availableSlotsForDay()
            }}
            options={dateOptions}
            label={t("support.scheduleCall.labels.date")}
            selectedValue={date}
          />
        </FlexColumnLayout>
        <FlexColumnLayout sx={{ width: "100%" }}>
          <Dropdown
            disabled={!date}
            errorMessage={slotsForDayError ? t("support.errors.timeSlots") : undefined}
            placeholder={t("support.scheduleCall.labels.availableSlots")}
            onSelect={setTimeSlot}
            options={timeSlots}
            label={t("support.scheduleCall.labels.timeslot")}
            selectedValue={timeSlot}
          />
        </FlexColumnLayout>
      </FlexRowLayout>
      <FlexRowLayout sx={{ marginTop: 24 }}>
        <MaskTextInput
          maskType={"PHONE_NUMBER"}
          placeholder={t("inputs.phoneNumber")}
          value={phone || ""}
          onChange={setPhone}
          label={t("inputs.phoneNumber")}
        />
      </FlexRowLayout>
      <FlexRowLayout sx={{ marginTop: 24 }}>
        <Dropdown<TScheduleCallTopics, typeof topicOptions[number]>
          label={t("support.scheduleCall.labels.askTopic")}
          placeholder={t("support.scheduleCall.labels.selectTopic")}
          options={topicOptions.sort((a, b) =>
            a.text.toLowerCase() > b.text.toLowerCase() || a.value === "other" ? 1 : -1,
          )}
          onSelect={(value) => {
            Analytics.track(events.support.callTopic, {
              topic: t(`support.scheduleCall.topics.${value}`),
            })
            setCallTopic({ text: t(`support.scheduleCall.topics.${value}`), value })
          }}
          selectedValue={callTopic.value}
        />
      </FlexRowLayout>
      <ScheduleCallInfo
        topic={callTopic}
        additionalInfo={additionalInfo}
        setAdditionalInfo={setAdditionalInfo}
      />
      <Button
        type={
          !callTopic || !isOnlineChatTopic
            ? ButtonTypeEnum.PRIMARY_BLUE
            : ButtonTypeEnum.PRIMARY_GOLD
        }
        sx={{ marginTop: 24 }}
        disabled={buttonIsDisabled()}
        iconSrc={isOnlineChatTopic ? images.icons.export : undefined}
        onClick={() => {
          if (isOnlineChatTopic) {
            handleSupportButtonClick(isIntercomOpen, setIsIntercomOpen)
          } else {
            Analytics.track(events.support.callConfirm)
            onScheduleCall()
          }
        }}
        isLoading={mutationLoading}
      >
        {isOnlineChatTopic
          ? t("support.scheduleCall.sendMessage")
          : t("support.scheduleCall.title")}
      </Button>
      <FlexRowLayout sx={{ marginTop: 24 }}>
        <ReCAPTCHA ref={reCAPTCHARef} size="invisible" sitekey={config.reCAPTCHASiteKey} />
        <ReCAPTCHADisclaimer />
      </FlexRowLayout>
    </>
  )

  return (
    <BaseLayout pageTitle={t("pageTitles.support.scheduleCall")}>
      <Text tag="h2" sx={{ marginBottom: 24 }}>
        {t("support.scheduleCall.title")}
      </Text>
      {loading ? (
        <FlexColumnLayout
          alignItems="center"
          justifyContent="center"
          sx={{ margin: "auto", height: "30vh", width: "100%" }}
        >
          <LoadingIcon dimension={50} isCentered />
        </FlexColumnLayout>
      ) : requestSent ? (
        <RequestSent timeSlot={timeSlot} clearState={clearState} timezone={timezoneName} />
      ) : (
        ScheduleCallBody
      )}
    </BaseLayout>
  )
}

const ReCAPTCHADisclaimer = () => {
  const { t } = useTranslation()
  const privacyPolicyLink = "https://policies.google.com/privacy"
  const termsOfServiceLink = "https://policies.google.com/terms"
  return (
    <Text tag={"body-x-small"} textColor={theme.colors.ui2}>
      <span>{t("support.disclaimer.reCAPTCHA.text")} </span>
      <span>
        <LinkText link={privacyPolicyLink}>
          {t("support.disclaimer.reCAPTCHA.privacyPolicy")}
        </LinkText>
      </span>
      <span>{t("support.disclaimer.reCAPTCHA.and")} </span>
      <span>
        <LinkText link={termsOfServiceLink}>
          {t("support.disclaimer.reCAPTCHA.termsOfService")}
        </LinkText>
      </span>
      <span>{t("support.disclaimer.reCAPTCHA.apply")} </span>
    </Text>
  )
}

interface LinkTextProps {
  children?: React.ReactNode
  link: string
}

const LinkText = ({ children, link }: LinkTextProps) => (
  <Link
    href={link}
    target="_blank"
    sx={{ cursor: "pointer", textDecoration: "underline", color: "inherit" }}
  >
    {children}
  </Link>
)
