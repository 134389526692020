import React from "react"
import { UpgradePlanSuccessLayout } from "../../subscriptions/upgrade/components/upgrade-success-layout"
import { images } from "@assets/images/images"
import { useTranslation } from "react-i18next"
import { FullScreenLoading } from "@northone/ui"
import { ErrorScreen } from "@components/composite/error-screen/error-screen"
import { PlanType } from "../../subscriptions/types"
import { ButtonTypeEnum, IButtonProps } from "@components/primitive/button/button"
import { useSubscriptionsData } from "../shared/hooks/use-subscriptions-data"
import { getPlanMonthlyPrice } from "../utils"
import accounting from "accounting"

export const UpsellPlanDetails = ({
  navigateNext,
  navigateBack,
  upsellPlanName,
}: {
  navigateNext: () => void
  navigateBack: () => void
  upsellPlanName?: PlanType
}) => {
  const { t } = useTranslation()

  const { plans, loading, error, refetch } = useSubscriptionsData()
  const planData = plans?.find((plan) => plan?.name === upsellPlanName)
  const retentionFeatures = planData?.retentionFeatures
  const upsellCopy = planData?.upsell
  const monthlyPrice = getPlanMonthlyPrice(planData)

  if (loading) {
    return <FullScreenLoading />
  }
  if (!upsellPlanName || !retentionFeatures || !upsellCopy || !monthlyPrice || error) {
    return <ErrorScreen size="large" retry={() => refetch?.()} />
  }

  const formattedMonthlyPrice = accounting.formatMoney(monthlyPrice)
  const buttons: IButtonProps[] = [
    {
      type: ButtonTypeEnum.SECONDARY,
      children: t("buttons.goBack"),
      onClick: navigateBack,
    },
    {
      type: ButtonTypeEnum.PRIMARY_BLACK,
      children: t("subscriptions.upsell.details.cta", { formattedMonthlyPrice }),
      onClick: navigateNext,
    },
  ]

  return (
    <UpgradePlanSuccessLayout
      title={upsellCopy.title}
      subtitle={upsellCopy.subtitle}
      featureBulletsHeading={t("subscriptions.upgrade.success.yourFeatures")}
      imgSrc={images.upgradePlusSuccess}
      featureBullets={retentionFeatures}
      buttons={buttons}
    />
  )
}
