export const postOnboarding = {
  timer: {
    title: "Your application is being reviewed",
    subtitle:
      "Please stay on this page while we verify your application information. This can take up to two minutes.",
  },
  approved: {
    title: "You’ve been approved!",
    subtitle:
      "Your bank account has been approved and your deposit will be available within 3 hours.\n\n While you wait, come on in and start setting up your new account.",
    buttonText: "Start using NorthOne",
  },
  pending: {
    title: "Your application is still under review",
    subtitle:
      "We’re still verifying your account information. You will receive an email at *{{email}}* in 1 to 2 business days with an update to your application.\n\n Your card has not been charged and no funds have been transferred.",
  },
  checkEmail: {
    title: "Check your email for an update on your application",
    subtitle:
      "An application status update has been sent to your email address *{{email}}*.\n Check your email for more information regarding your application. \n\n Your card has not been charged and no funds have been transferred.",
  },
  emailGate: {
    title: "Confirm your email address to access your account",
    subtitle:
      "Use the link in the confirmation email sent to *{{email}}* to get started with your new NorthOne Account.",
    cta: "Resend confirmation email",
    ctaLoading: "Sending",
    ctaSent: "Confirmation email sent!",
  },
}
