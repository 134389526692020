import ach from "./ach.svg"
import mailedCheck from "./mailed-check.svg"
import domesticWire from "./wire.svg"
import transfer from "./transfer.svg"
import realTimePayment from "./real-time-payment.svg"

export const transactionIcons = {
  ach,
  mailedCheck,
  domesticWire,
  transfer,
  realTimePayment,
}
