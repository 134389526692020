import React, { useState } from "react"
import { Input as RInput } from "@rebass/forms"
import { Field, IFieldInputProps } from "@components/primitive/field/field"
import { theme } from "@layouts/theme"
import { FlexRowLayout } from "@layouts/flex"
import { Text } from "@components/primitive/text/text"
import { useTranslation } from "react-i18next"

export interface IPasswordInputProps extends IFieldInputProps {
  placeholder: string
  value: string
  onChange: (value: string) => void
  maxLength?: number
}

/**
 * Extends the primitive Field component
 * @param sx Should be used sparingly. One use case might be to set a custom width.
 */
export const PasswordInput: React.FC<IPasswordInputProps> = ({
  label,
  placeholder,
  disabled,
  isHighlighted,
  errorMessage,
  sx,
  value,
  onChange,
  maxLength,
  containerSx,
}) => {
  const { t } = useTranslation()
  const [isFocused, setIsFocused] = useState(false)
  const [isShowingPassword, setIsShowingPassword] = useState(false)
  return (
    <Field
      label={label}
      isFocused={isFocused}
      isHighlighted={isHighlighted}
      errorMessage={errorMessage}
      disabled={disabled}
      sx={sx}
      containerSx={containerSx}
    >
      <FlexRowLayout
        sx={{
          paddingX: 12,
          width: "100%",
          alignItems: "center",
          height: "100%",
          overflowX: "scroll",
        }}
      >
        <FlexRowLayout
          sx={{
            height: "100%",
            width: "100%",
            position: "relative",
            alignItems: "center",
          }}
        >
          <RInput
            sx={{
              outline: "none",
              border: "none",
              paddingX: 0,
              width: "100%",
              "::placeholder": {
                color: theme.colors.ui3,
              },
              caretColor: theme.colors.ui1,
              ...theme.textStyles.body,
              ...sx,
            }}
            value={value}
            placeholder={placeholder}
            disabled={disabled}
            onFocus={() => {
              setIsFocused(true)
            }}
            onBlur={() => {
              setIsFocused(false)
            }}
            onChange={(e) => onChange(e.target.value)}
            maxLength={maxLength}
            type={isShowingPassword ? undefined : "password"}
          />
          {value && (
            <Text
              onClick={() => setIsShowingPassword(!isShowingPassword)}
              textColor={theme.colors.ui2}
              sx={{ cursor: "pointer" }}
              tag="body-small"
            >
              {isShowingPassword ? t("buttons.hide") : t("buttons.show")}
            </Text>
          )}
        </FlexRowLayout>
      </FlexRowLayout>
    </Field>
  )
}
