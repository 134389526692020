import React, { useState, useEffect } from "react"
import { useAppSelector } from "@core/redux/utils"
import { selectActiveBusinessID } from "@core/active-business/redux/selectors"
import { TransactionPanel } from "@components/composite/transaction-panel/transaction-panel"
import { LoadingPanel } from "@components/extended/panel/loading-panel"
import { ErrorPanel } from "@components/extended/panel/error-panel"
import { selectAccountsTransactionID } from "@features/accounts/redux/selectors"
import { useTransactionDetailsQuery } from "@generated/graphql"

export const SubAccountsTransactionPanel: React.FC = () => {
  const transactionId = useAppSelector(selectAccountsTransactionID)
  const businessId = useAppSelector(selectActiveBusinessID)
  const [activeTransactionId, setActiveTransactionId] = useState(transactionId)

  useEffect(() => {
    if (transactionId !== "") {
      setActiveTransactionId(transactionId)
    }
  }, [transactionId])

  const { data, loading, error, refetch } = useTransactionDetailsQuery({
    variables: { businessId, transactionId: activeTransactionId },
    notifyOnNetworkStatusChange: true,
  })

  if (error) {
    return <ErrorPanel retry={refetch} />
  }

  if (loading) {
    return <LoadingPanel />
  }

  return (
    <TransactionPanel
      id={data?.transaction?.id || ""}
      amount={data?.transaction?.amount || 0}
      counterpartyName={data?.transaction?.description || ""}
      createdAt={data?.transaction?.createdAt || ""}
      postedAt={data?.transaction?.postedAt || ""}
      transactionType={data?.transaction?.transactionType}
      note={data?.transaction?.note || ""}
      cancelable={false}
      status={data?.transaction?.status}
      returnedBy={data?.transaction?.returnedBy || ""}
      cardLast4={data?.transaction?.cardLast4 || ""}
      user={data?.transaction?.user}
    />
  )
}
