import { FlexColumnLayout, Panel } from "@northone/ui"
import { DateTime } from "luxon"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { v4 as uuidv4 } from "uuid"

import { ErrorScreen } from "@components/composite/error-screen/error-screen"
import { appActions } from "@core/redux/app/actions"
import { useAppSelector } from "@core/redux/utils"
import {
  PlaidFundingCapabilitiesQueryResponse,
  PlaidItem,
  useDisableTopUpsMutation,
  useTopUpsLazyQuery,
  useUpdateTopUpsSettingsMutation,
} from "@generated/graphql"
import { convertToFullName } from "@utils/format"

import { TopUpsLandingLoading } from "./components/landing-loading"
import { panelStyles } from "./components/styles"
import { TopUpSettingsLayout } from "./components/top-up-settings-layout"
import { TopUpDisclaimer } from "./top-up-disclaimer"
import { TopUpSettingsForm } from "./top-up-settings-form"
import type { TopUpSettings, TopUpSettingsValues } from "./types"
import { FormErrorMessages } from "./components/form-error-messages"
import { Analytics } from "@core/analytics/actions"
import { events } from "@core/analytics/events"
import { CancelConfirmationModal } from "./components/cancel-confirmation-modal"

const i18nKeyPaths = {
  settingsSavedToastMessage: "autoTopUps.landing.toasts.settingsSaved",
}

const handleErrorRetry = () => window.location.reload()

export const AutoTopUpsLanding = () => {
  const businessId = useAppSelector((state) => state.activeBusiness.businessId)
  const firstName = useAppSelector((state) => state.user.me?.firstName)
  const lastName = useAppSelector((state) => state.user.me?.lastName)
  const accountId = useAppSelector(
    (state) => state.user.me?.ownerBusinesses.find((biz) => biz.id === businessId)?.bankAccount?.id,
  )
  const todayFormatted = DateTime.local({ locale: "en-us" }).toLocaleString()

  const [topUpSettings, setTopUpSettings] = useState<TopUpSettings>()
  const [topUpSettingsLoading, setTopUpSettingsLoading] = useState(true)
  const [shouldShowError, setShouldShowError] = useState(false)
  const [shouldShowSettingsSaveError, setShouldShowSettingsSaveError] = useState(false)
  const [idempotencyKey, setIdempotencyKey] = useState(uuidv4())
  const [plaidItems, setPlaidItems] = useState<PlaidItem[]>()
  const [topUpCapabilities, setTopUpCapabilities] =
    useState<PlaidFundingCapabilitiesQueryResponse["topUps"]>()
  const [shouldShowCancelConfirmationModal, setShouldShowCancelConfirmationModal] = useState(false)
  const [isTopUpCancelling, setIsTopUpCancelling] = useState(false)

  const [updateTopUpSettings] = useUpdateTopUpsSettingsMutation()
  const [disableTopUps] = useDisableTopUpsMutation()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [getTopUpData] = useTopUpsLazyQuery({
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      const topUpSettings = data.topUpSettings
      if (topUpSettings) setTopUpSettings(topUpSettings)
      if (data.plaidItems) setPlaidItems(data.plaidItems.data)
      if (data.capabilities) setTopUpCapabilities(data.capabilities.topUps)
      setTopUpSettingsLoading(false)
    },
    onError: () => {
      setShouldShowError(true)
    },
  })

  useEffect(() => {
    if (!accountId) return
    getTopUpData({ variables: { businessId, accountId } })
  }, [accountId, businessId])

  if (
    !firstName ||
    !lastName ||
    !accountId ||
    plaidItems === undefined ||
    topUpSettingsLoading ||
    !topUpCapabilities
  ) {
    return <TopUpsLandingLoading />
  }

  const onSettingsSaveError = () => {
    Analytics.track(events.topUps.saveError)
    setShouldShowSettingsSaveError(true)
    throw new Error("Error saving top-up settings")
  }

  const handleSettingsSaveButtonClicked = async (topUpSettingsValues: TopUpSettingsValues) => {
    Analytics.track(events.topUps.saveButtonClick)
    setShouldShowSettingsSaveError(false)
    const updateSettingsResponse = await updateTopUpSettings({
      variables: {
        businessId,
        accountId,
        data: { ...topUpSettingsValues, idempotencyKey },
      },
    })
      .catch(onSettingsSaveError)
      .finally(() => {
        setIdempotencyKey(uuidv4())
      })

    if (updateSettingsResponse.errors?.length) {
      onSettingsSaveError()
    }

    const settings = updateSettingsResponse?.data?.updateTopUpsSettings?.data
    if (!settings) return

    const plaidItem = plaidItems.find((item) => item.id === settings.plaidItem.id)
    if (!plaidItem) return

    setTopUpSettings({
      topUpAmount: settings.topUpAmount,
      isTopUpsEnabled: settings.isTopUpsEnabled,
      lowBalanceThreshold: settings.lowBalanceThreshold,
      plaidItem: { ...plaidItem, status: settings.plaidItem.status },
    })
    dispatch(appActions.setNotificationMessage(t(i18nKeyPaths.settingsSavedToastMessage)))
  }

  const handleSettingsCancelButtonClicked = async () => {
    Analytics.track(events.topUps.cancelButtonClick)
    setShouldShowCancelConfirmationModal(true)
  }

  const onCancelConfirmationCancel = async () => {
    Analytics.track(events.topUps.cancelConfirmModalBackClick)
    setShouldShowCancelConfirmationModal(false)
  }

  const onCancelConfirmationContinue = async () => {
    Analytics.track(events.topUps.cancelConfirmModalContinueClick)
    setIsTopUpCancelling(true)
    const response = await disableTopUps({
      variables: {
        businessId,
        accountId,
        data: {
          idempotencyKey: uuidv4(),
        },
      },
    }).catch(() => {
      setShouldShowError(true)
    })
    setIsTopUpCancelling(false)
    setShouldShowCancelConfirmationModal(false)
    if (response?.data?.disableTopUps?.success === true) {
      setTopUpSettings(undefined)
      dispatch(appActions.setNotificationMessage(t(i18nKeyPaths.settingsSavedToastMessage)))
      return
    }
    setShouldShowError(true)
  }

  if (shouldShowError)
    return (
      <FlexColumnLayout sx={{ gridColumn: "5/12" }}>
        <ErrorScreen size="large" retry={handleErrorRetry} />
      </FlexColumnLayout>
    )

  const fullName = convertToFullName({ firstName, lastName })
  const minimumBalanceLimits = {
    minimum: topUpCapabilities.amounts.minimumThresholdAmount,
    default: topUpCapabilities.amounts.defaultThresholdAmount,
  }
  const topUpAmountLimits = {
    minimum: topUpCapabilities.amounts.minimumAmount,
    maximum: topUpCapabilities.amounts.maximumAmount,
    default: topUpCapabilities.amounts.defaultAmount,
  }
  const maximumTopUpsPerThirtyDays =
    topUpCapabilities.velocityLimits.maxTransactionCountPer30DayRolling

  const slotAboveFormContent = (
    <FormErrorMessages
      settingsErrorCode={topUpSettings?.error?.code}
      showSaveError={shouldShowSettingsSaveError}
    />
  )

  return (
    <TopUpSettingsLayout>
      <Panel sx={panelStyles}>
        <TopUpSettingsForm
          isEligible={topUpCapabilities.isEligible}
          minimumBalanceLimits={minimumBalanceLimits}
          onSaveButtonClicked={handleSettingsSaveButtonClicked}
          onCancelButtonClicked={handleSettingsCancelButtonClicked}
          plaidItems={plaidItems}
          topUpAmountLimits={topUpAmountLimits}
          topUpSettings={topUpSettings}
          slotAboveForm={slotAboveFormContent}
        />
      </Panel>

      {topUpSettings?.isTopUpsEnabled === true ? null : (
        <TopUpDisclaimer
          customerFullName={fullName}
          date={todayFormatted}
          maximumTopUpsPerThirtyDays={maximumTopUpsPerThirtyDays}
        />
      )}

      <CancelConfirmationModal
        isOpen={shouldShowCancelConfirmationModal}
        isTopUpCancelling={isTopUpCancelling}
        onCancel={onCancelConfirmationCancel}
        onContinue={onCancelConfirmationContinue}
      />
    </TopUpSettingsLayout>
  )
}
