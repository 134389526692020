/* tslint:disable:max-line-length */
import { images } from "@assets/images/images"
import { Analytics } from "@core/analytics/actions"
import { events } from "@core/analytics/events"
import { ButtonTypeEnum, IButtonProps, TTextTag } from "@northone/ui"
import {
  SQUARE_INTEGRATION_URL,
  PAYPAL_INTEGRATION_URL,
  SHOPIFY_INTEGRATION_URL,
  AIRBNB_INTEGRATION_URL,
  STRIPE_INTEGRATION_URL,
  AMAZON_INTEGRATION_URL,
  ETSY_INTEGRATION_URL,
  LYFT_INTEGRATION_URL,
  VENMO_INTEGRATION_URL,
  GUSTO_INTEGRATION_URL,
  ALTO_INTEGRATION_URL,
  XERO_INTEGRATION_URL,
  QUICKBOOKS_INTEGRATION_URL,
} from "@utils/constants"
import { SxStyleProp } from "rebass"

export enum IntegrationTypeEnum {
  PAYMENTS = "PAYMENTS",
  ECOMMERCE = "ECOMMERCE",
  SERVICE = "SERVICE",
  PAYROLL = "PAYROLL",
  INVOICING = "INVOICING",
  ACCOUNTING = "ACCOUNTING",
  COMING_SOON = "COMING_SOON",
}

export enum IntegrationNameEnum {
  SQUARE = "Square",
  PAYPAL = "PayPal",
  SHOPIFY = "Shopify",
  AIRBNB = "Airbnb",
  STRIPE = "Stripe",
  AMAZON = "Amazon",
  ETSY = "Etsy",
  LYFT = "Lyft",
  VENMO = "Venmo",
  GUSTO = "Gusto",
  ALTO = "Alto",
  XERO = "Xero",
  QUICKBOOKS = "QuickBooks Online",
  EXPENSIFY = "Expensify",
}

interface IInstructions {
  text?: string
  bankInfo?: boolean
  partialStyling?: React.CSSProperties
  sx?: SxStyleProp
  tag?: TTextTag
}

export interface IIntegration {
  id: string
  name: string
  logo: string
  subtitle: IntegrationTypeEnum
  link?: { url: string; text?: string }
  button?: IButtonProps
  instructions?: IInstructions[]
  color: string
}

export const integrationsData: IIntegration[] = [
  {
    id: "0",
    color: "#000000",
    name: IntegrationNameEnum.SQUARE,
    logo: images.icons.integrations.square,
    subtitle: IntegrationTypeEnum.PAYMENTS,
    link: { url: SQUARE_INTEGRATION_URL },
    instructions: [
      {
        text: "Adding NorthOne to Square will allow you to receive Square deposits directly into your NorthOne Account. Here’s how you connect your accounts:",
      },
      {
        text: "*On desktop:*",
      },
      {
        text: "1. Go to *Account & Settings* and click *Bank Accounts.*",
      },
      {
        text: "2. Click *Add Bank Account.* If you are changing your bank account, select *Change Bank Account.*",
      },
      {
        text: "3. Enter your *NorthOne Account details*:",
      },
      {
        bankInfo: true,
      },
      {
        text: "4. Click *Verify.*",
      },
      {
        text: "*On Square’s mobile app:*",
      },
      {
        text: "1. Tap the *Menu.*",
      },
      {
        text: "2. Tap *Settings* and then tap *Bank Account*.",
      },
      {
        text: "3. Tap *Add Bank Account* or *Change Bank Account.*",
      },
      {
        text: "4. Enter your *NorthOne Account details*:",
      },
      {
        bankInfo: true,
      },
      {
        text: "5. Click *Done.*",
      },
    ],
  },
  {
    id: "1",
    color: "#0070BA30",
    name: IntegrationNameEnum.PAYPAL,
    logo: images.icons.integrations.paypal,
    subtitle: IntegrationTypeEnum.PAYMENTS,
    link: { url: PAYPAL_INTEGRATION_URL },
    instructions: [
      {
        text: "Adding NorthOne to your PayPal account will allow you to make PayPal deposits directly into your NorthOne Account. Here’s how you connect your accounts:",
      },
      {
        text: "**On the PayPal website**",
        partialStyling: { fontStyle: "italic" },
      },
      {
        text: "1. Click *Wallet*",
      },
      {
        text: "2. Click *Link a bank*",
      },
      {
        text: "3. Type “NorthOne” in the search bar",
      },
      {
        text: "4. Delete “NorthOne” from the search bar to find the “Enter your bank details” button",
      },
      {
        text: "5. Click *Enter your bank details*",
      },
      {
        text: "6. Enter your NorthOne Account details:",
      },
      {
        bankInfo: true,
      },
      {
        text: "7. Click *Agree and link*",
      },
      {
        text: "8. Within 3 business days, PayPal will send 2 small deposits (each between $0.01 and $0.99) to your NorthOne Account. There will be a third transaction to withdraw the deposits.",
      },
      {
        text: "9. Once you see the deposits, log into PayPal",
      },
      {
        text: "10. Click *Wallet*",
      },
      {
        text: "11. Click *NorthOne*",
      },
      {
        text: "12. Enter the deposit amounts on the “Confirm your bank” screen",
      },
      {
        text: "13. Click *Confirm.*",
      },
    ],
  },
  {
    id: "2",
    color: "#95BF4730",
    name: IntegrationNameEnum.SHOPIFY,
    logo: images.icons.integrations.shopify,
    subtitle: IntegrationTypeEnum.ECOMMERCE,
    link: { url: SHOPIFY_INTEGRATION_URL },
    instructions: [
      {
        text: "Adding NorthOne to Shopify will allow you to receive Shopify deposits directly into your NorthOne Account. Here’s how you connect your accounts:",
      },
      {
        text: "1. From your Shopify admin, go to *Settings* and click *Payment providers.*",
      },
      {
        text: "2. In the *Shopify Payments* section, click *Manage.*",
      },
      {
        text: "3. Under *Payout bank account* in the *Payout details* section, click *Change bank account.*",
      },
      {
        text: "4. Enter your *NorthOne Account details*:",
      },
      {
        bankInfo: true,
      },
      {
        text: "5. Click *Save.*",
      },
    ],
  },
  {
    id: "3",
    color: "#FF5A5F30",
    name: IntegrationNameEnum.AIRBNB,
    logo: images.icons.integrations.airbnb,
    subtitle: IntegrationTypeEnum.SERVICE,
    link: { url: AIRBNB_INTEGRATION_URL },
    instructions: [
      {
        text: "Adding NorthOne as your payout method in your Airbnb account will allow you to receive deposits directly into your NorthOne Account. Here’s how you connect your accounts:",
      },
      {
        text: "1. Go to *Account* on airbnb.com",
      },
      {
        text: "2. Select *Payout Preferences*",
      },
      {
        text: "3. Click *Add Payout Method*, enter your address, and select *ACH/Direct Deposit.*",
      },
      {
        text: "4. Enter your *NorthOne Account details*:",
      },
      {
        bankInfo: true,
      },
      {
        text: "5. Tap *Done.*",
      },
    ],
  },
  {
    id: "4",
    color: "#635BFF30",
    name: IntegrationNameEnum.STRIPE,
    logo: images.icons.integrations.stripe,
    subtitle: IntegrationTypeEnum.PAYMENTS,
    button: {
      children: "Connect Stripe",
      iconSrc: images.icons.externalLink,
      onClick: () => {
        Analytics.track(events.integrations.stripe)
        window.open(STRIPE_INTEGRATION_URL)
      },
      type: ButtonTypeEnum.PRIMARY_BLUE,
    },
    instructions: [
      {
        text: "Connect to Stripe to get paid directly into your NorthOne Account",
        tag: "body-large-bold",
      },
      {
        text: "Go to **Payout settings** in Stripe to add your account details:",
        partialStyling: { fontStyle: "italic" },
      },
      {
        bankInfo: true,
      },
      {
        text: "Already set up payouts to NorthOne?",
        sx: { marginBottom: 0 },
      },
      { text: "You’re good to go." },
    ],
  },
  {
    id: "5",
    color: "#FF990030",
    name: IntegrationNameEnum.AMAZON,
    logo: images.icons.integrations.amazon,
    subtitle: IntegrationTypeEnum.ECOMMERCE,
    link: { url: AMAZON_INTEGRATION_URL },
    instructions: [
      {
        text: "Adding NorthOne to Amazon Pay will allow you to receive disbursements directly into your NorthOne Account. Here’s how you connect your accounts:",
      },
      {
        text: "1. Sign in to *Seller Central.*",
      },
      {
        text: "2. Click *Settings* and click *Account Info.*",
      },
      {
        text: "3. Click *Deposit Methods.*",
      },
      {
        text: "4. On the Deposit Methods page, in the Amazon Payments Advanced US Live box, click *Assign.*",
      },
      {
        text: "5. Click *Use a new bank account.*",
      },
      {
        text: "6. Enter your *NorthOne Account details*:",
      },
      {
        bankInfo: true,
      },
      {
        text: "7. Click *Set Deposit Method.*",
      },
    ],
  },
  {
    id: "7",
    color: "#F1641E30",
    name: IntegrationNameEnum.ETSY,
    logo: images.icons.integrations.etsy,
    subtitle: IntegrationTypeEnum.ECOMMERCE,
    link: { url: ETSY_INTEGRATION_URL },
    instructions: [
      {
        text: "To get paid with Etsy Payments, you need to enroll in Etsy Payments. You can add NorthOne as your payout method in your Etsy account to receive payments directly into your NorthOne Account. Here is how you connect your accounts:",
      },
      {
        text: "1. On Etsy.com, click *Shop Manager.*",
      },
      {
        text: "2. Click *Finances.*",
      },
      {
        text: "3. Click *Payment settings.*",
      },
      {
        text: "4. Click *Select your bank account country.*",
      },
      {
        text: "5. Enter your bank account and residential address to receive deposits. You'll also be required to verify your personal identity. For bank account details, Enter your *NorthOne Account details*:",
      },
      {
        bankInfo: true,
      },
      {
        text: "6. Add your NorthOne debit card.",
      },
    ],
  },
  {
    id: "8",
    color: "#FF00BF30",
    name: IntegrationNameEnum.LYFT,
    logo: images.icons.integrations.lyft,
    subtitle: IntegrationTypeEnum.SERVICE,
    link: { url: LYFT_INTEGRATION_URL },
    instructions: [
      {
        text: "You can add NorthOne as your payout method in your Lyft driver dashboard. This is how you will get paid by Lyft, and deposits will go directly into your NorthOne Account.",
      },
      {
        text: "Adding NorthOne as your payout method in your Lyft driver dashboard will allow you to receive payments directly into your NorthOne Account. Here’s how you connect your accounts:",
      },
      {
        text: "1. Log in to the *Driver Dashboard.*",
      },
      {
        text: "2. Tap *Payout Information.*",
      },
      {
        text: "3. Tap *Edit bank info* under the *Bank Information* header.",
      },
      {
        text: "4. Enter your *NorthOne Account details*:",
      },
      {
        bankInfo: true,
      },
      {
        text: "5. Tap *Submit.*",
      },
      {
        text: "6. Lyft may send a confirmation text to drivers with 10 or more rides. This is only required if you receive the text.",
      },
    ],
  },
  {
    id: "10",
    color: "#3D95CE30",
    name: IntegrationNameEnum.VENMO,
    logo: images.icons.integrations.venmo,
    subtitle: IntegrationTypeEnum.PAYMENTS,
    link: { url: VENMO_INTEGRATION_URL },
    instructions: [
      {
        text: "1. Tap the *menu icon* in the upper left hand corner of the app.",
      },
      {
        text: "2. Tap *Settings* and then *Payment Methods*",
      },
      {
        text: "3. Tap *Add a bank or card...* and then select *Bank*",
      },
      {
        text: "4. Select your preferred verification method as *Microtransfers*",
      },
      {
        text: "5. Enter your *NorthOne Account details:*",
      },
      {
        bankInfo: true,
      },
      {
        text: "6. When you add your bank account to Venmo manually (with your routing and account number), Venmo will send microtransfers to your NorthOne Account to verify ownership (these will be less than $1 each). When Venmo sends these microtransfers to your account, they issue two small withdrawals and to offset those withdrawals they issue two small deposits simultaneously.",
      },
      {
        text: "7. *Make sure you have at least $2 in your NorthOne Account.*",
      },
      {
        text: "8. You'll see these deposits/debits in your NorthOne Account within 1-3 business days as separate transaction items in your Home feed. Once you do, you can visit *www.venmo.com/verifybank* to verify your bank account.",
      },
      {
        text: "9. If you’re not seeing the amounts after 3 business days, it’s possible the bank account information may have been entered incorrectly.",
      },
    ],
  },

  {
    id: "11",
    color: "#F45D4830",
    name: IntegrationNameEnum.GUSTO,
    logo: images.icons.integrations.gusto,
    subtitle: IntegrationTypeEnum.PAYROLL,
    link: { url: GUSTO_INTEGRATION_URL },
    instructions: [
      {
        text: "You can add your NorthOne Account to Gusto in order to pay for wages, taxes and your monthly Gusto fee. Here’s how to connect your accounts:",
      },
      {
        text: "1. Sign into your *Admin account.*",
      },
      {
        text: "2. In the left hand menu, go to the *Company Details.*",
      },
      {
        text: "3. Go to the *Bank Account* section and click *Edit.*",
      },
      {
        text: "4. Enter your *NorthOne Account details*:",
      },
      {
        bankInfo: true,
      },
      {
        text: "5. Click *Save & Continue.*",
      },
    ],
  },
  {
    id: "12",
    color: "#737CF5",
    name: IntegrationNameEnum.ALTO,
    logo: images.icons.integrations.alto,
    subtitle: IntegrationTypeEnum.INVOICING,
    link: { url: ALTO_INTEGRATION_URL },
    instructions: [
      {
        text: "Adding NorthOne to your Alto invoices is super easy. Just add it as a Payment option and you can add your NorthOne Account as a way for people to pay you:",
      },
      {
        text: "1. Open *Settings > Choose defaults.*",
      },
      {
        text: "2. Scroll down and choose *Payment details.*",
      },
      {
        text: "3. Enter your *NorthOne Account details*:",
      },
      {
        bankInfo: true,
      },
      {
        text: "4. Click *Save.*",
      },
    ],
  },
  {
    id: "13",
    color: "#00A3D330",
    name: IntegrationNameEnum.XERO,
    logo: images.icons.integrations.xero,
    subtitle: IntegrationTypeEnum.COMING_SOON,
    instructions: [
      {
        text: "1. Click *Connect your Xero account* at the bottom of the page. You will be redirected to the integration portal.",
      },
      {
        text: "2. Enter your NorthOne credentials.",
      },
      {
        text: "3. Follow the quick 3-step process to connect Xero with your bank account. \n\nYou can use the same portal to manage your Xero account connections.",
      },
    ],
    link: { url: XERO_INTEGRATION_URL, text: "Connect to your Xero account" },
  },
  {
    id: "14",
    color: "#2C9F1B30",
    name: IntegrationNameEnum.QUICKBOOKS,
    logo: images.icons.integrations.quickbooks,
    subtitle: IntegrationTypeEnum.ACCOUNTING,
    instructions: [
      {
        text: "1. Visit *quickbooks.intuit.com* or click the link below.",
      },
      {
        text: "2. *Log in* to QuickBooks Online.",
      },
      {
        text: "3. Click *Transactions* on the left sidebar menu, then click on *Connect account*.",
      },
      {
        text: "4. *Search for NorthOne*, then login to your NorthOne Account to complete the setup",
      },
    ],
    link: { url: QUICKBOOKS_INTEGRATION_URL, text: "Connect to QuickBooks Online" },
  },
  {
    id: "15",
    color: "#000000",
    name: IntegrationNameEnum.EXPENSIFY,
    logo: images.icons.integrations.expensify,
    subtitle: IntegrationTypeEnum.COMING_SOON,
  },
]
