import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Button, ButtonTypeEnum } from "@northone/ui"
import { v4 as uuid } from "uuid"

import { images } from "@assets/images/images"
import { QueryResults } from "@components/composite/query-results/query-results"
import { AgreementType, useAgreementsPdfQuery } from "@generated/graphql"
import { isMobile } from "react-device-detect"

interface ILegalPDFViewerProps {
  legalDocumentType: AgreementType
}
export const LegalPDFViewer: React.FC<ILegalPDFViewerProps> = ({ legalDocumentType }) => {
  const { t } = useTranslation()
  const [pdfUrl, setPdfUrl] = useState<string>("")

  // iFrames in React interfere with browser's back-navigation. It renders the previous page within the iFrame.
  // To combat this, provide a key to force component to re-create on navigation.
  // https://www.aleksandrhovhannisyan.com/blog/react-iframes-back-navigation-bug/
  const [key] = useState<string>(uuid())

  const { error, loading, refetch } = useAgreementsPdfQuery({
    notifyOnNetworkStatusChange: true,
    variables: { type: legalDocumentType },
    onCompleted: (data) => {
      setPdfUrl(data?.agreementsPDF?.pdfURL)
    },
  })

  // Utilizing Google Doc's PDF Viewer for mobile devices, where PDF embedding is diff from web
  const url =
    isMobile && pdfUrl
      ? `https://drive.google.com/viewerng/viewer?embedded=true&url=${pdfUrl}`
      : pdfUrl

  return (
    <QueryResults loading={loading} error={error} retry={() => refetch()} size={"medium"}>
      <Button
        iconSrc={images.icons.download}
        sx={{ padding: "8px", width: "150px", height: "40px" }}
        type={ButtonTypeEnum.TERTIARY_SHORT}
        onClick={() => window.open(pdfUrl, "_blank")}
      >
        {t("buttons.downloadPDF")}
      </Button>
      {pdfUrl && (
        <iframe
          key={key}
          title="agreements-pdf"
          src={url}
          style={{
            width: "130%",
            height: "500px",
            border: 0,
            marginBottom: 20,
            marginTop: 20,
          }}
        />
      )}
    </QueryResults>
  )
}
