import React from "react"
import i18n from "@i18n/i18n"

import { useAppSelector } from "@core/redux/utils"
import { Analytics } from "@core/analytics/actions"
import { events } from "@core/analytics/events"
import { selectActiveBusinessID } from "@core/active-business/redux/selectors"
import { images } from "@assets/images/images"
import { AlignedColumnLayout } from "@components/extended/list-items/aligned-column-layout"
import { MonthlyAccountStatements, useAccountStatementPdfLazyQuery } from "@generated/graphql"

export interface IStatement {
  statement: MonthlyAccountStatements | undefined | null
}

export const AccountStatementsViewerDownloadPDF: React.FC<IStatement> = (accountStatement) => {
  const businessId: string = useAppSelector(selectActiveBusinessID)
  const month = accountStatement?.statement?.month ? accountStatement?.statement?.month : ""
  const year = accountStatement?.statement?.year ? accountStatement.statement.year : ""

  const [getAccountStatementData] = useAccountStatementPdfLazyQuery({
    onCompleted: (data) => {
      if (data?.business?.bankAccount?.accountStatementPdf) {
        window.open("" + data.business.bankAccount.accountStatementPdf.pdf, "_blank")
        Analytics.track(events.settings.accountStatements.download, {
          statement: `${month}/${year}`,
        })
      }
    },
    fetchPolicy: "network-only",
  })

  return (
    <AlignedColumnLayout alignment={"flex-start"} widthRadio={1.0}>
      <input
        type="image"
        src={images.icons.download}
        alt={i18n.t("settings.pages.accountStatements.labels.download")}
        title={i18n.t("settings.pages.accountStatements.labels.download")}
        onClick={() => getAccountStatementData({ variables: { businessId, year, month } })}
      />
    </AlignedColumnLayout>
  )
}
