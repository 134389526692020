import React, { useState } from "react"
import { Field } from "@components/primitive/field/field"
import { FlexRowLayout } from "@layouts/flex"
import { Text } from "@components/primitive/text/text"
import { theme } from "@layouts/theme"
import { ToolTip } from "@components/primitive/tool-tip/tool-tip"
import { Box, Image } from "rebass"
import { Label as RLabel } from "@rebass/forms"
import { images } from "@assets/images/images"
import { useTranslation } from "react-i18next"
import copy from "copy-to-clipboard"

export interface IDisplayOnlyFieldProps {
  value: string
  infoText?: string
  label: string
  onCopy?: () => void
}
export const DisplayOnlyField: React.FC<IDisplayOnlyFieldProps> = ({
  value,
  infoText,
  label,
  onCopy,
  ...fieldProps
}) => {
  const [infoTextVisible, setInfoTextVisible] = useState(false)
  const [copiedMessageVisible, setCopiedMessageVisible] = useState(false)
  const { t } = useTranslation()
  return (
    <Field
      {...fieldProps}
      labelComponent={
        <ToolTip
          text={infoText || ""}
          position="right"
          visible={Boolean(infoText && infoTextVisible)}
        >
          <Box
            onMouseEnter={() => setInfoTextVisible(true)}
            onMouseLeave={() => setInfoTextVisible(false)}
            sx={{ display: "inline-flex" }}
          >
            <RLabel
              sx={{
                ...theme.textStyles.label,
              }}
            >
              {label}
            </RLabel>
            {infoText && <Image src={images.icons.help} sx={{ marginLeft: "4px" }} />}
          </Box>
        </ToolTip>
      }
    >
      <FlexRowLayout
        sx={{
          height: "100%",
          justifyContent: "space-between",
          alignItems: "center",
          paddingX: 12,
        }}
      >
        <Text tag="body" textColor={theme.colors.ui1}>
          {value}
        </Text>
        {copiedMessageVisible ? (
          <Text textColor={theme.colors.ui2} tag="body-small">
            {t("copiedToClipboard")}
          </Text>
        ) : (
          <Text
            onClick={() => {
              copy(value)
              if (onCopy) {
                onCopy()
              }
              setCopiedMessageVisible(true)
              setTimeout(() => setCopiedMessageVisible(false), 5000)
            }}
            sx={{
              cursor: "pointer",
              ":hover": { color: theme.colors.ui1 },
              ":active": { backgroundColor: theme.colors.gold30, color: theme.colors.ui1 },
              padding: "4px 8px",
            }}
            textColor={theme.colors.ui2}
            tag="body-small"
          >
            {t("buttons.copy")}
          </Text>
        )}
      </FlexRowLayout>
    </Field>
  )
}
