import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { Box } from "rebass"
import { Analytics } from "@core/analytics/actions"
import { events } from "@core/analytics/events"
import { RoutesEnum } from "@routers/types"
import { Text } from "@components/primitive/text/text"
import { SearchInput } from "@components/extended/fields/search-input"
import { QueryResults } from "@components/composite/query-results/query-results"
import { NavigationListItem } from "@components/extended/list-items/navigation/navigation-list-item"
import { BaseLayout } from "@layouts/base-layout"
import { theme } from "@layouts/theme"
import { useFaqLandingQuery, useFaqSearchLazyQuery } from "@generated/graphql"

interface ISupportFAQsProps {
  hasSearchbar?: boolean
}

export const SupportFAQs: React.FC<ISupportFAQsProps> = ({ hasSearchbar = true }) => {
  const history = useHistory()
  const { t } = useTranslation()
  const [searchTerm, setSearchTerm] = useState("")

  const { data, error, loading, refetch } = useFaqLandingQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
  })

  const [
    searchFAQs,
    { data: searchData, loading: searchLoading, error: searchError, refetch: searchRefetch },
  ] = useFaqSearchLazyQuery({
    fetchPolicy: "no-cache",
  })

  const faqSections = data?.supportHelpCenter.sections
  const searchResults = searchData?.supportHelpCenter.search?.faqs

  return (
    <BaseLayout pageTitle={hasSearchbar ? t("pageTitles.support.faqs") : ""}>
      <Text tag={hasSearchbar ? "h2" : "h4"} marginBottom={24}>
        {t("support.faq.title")}
      </Text>
      <>
        {hasSearchbar ? (
          <SearchInput
            placeholder={t("support.faq.searchPlaceholder")}
            value={searchTerm}
            onChange={(value) => {
              setSearchTerm(value)
              searchFAQs({ variables: { searchTerm: value } })
            }}
            sx={{ backgroundColor: theme.colors.ui7 }}
          />
        ) : null}
        <Box sx={{ marginTop: hasSearchbar ? 16 : 0 }}>
          {!searchTerm || (searchData?.supportHelpCenter.search && !searchResults?.length) ? (
            <QueryResults
              loading={loading}
              error={error}
              size="medium"
              retry={() => refetch}
              listShimmerCount={10}
            >
              {faqSections?.map((section) => (
                <NavigationListItem
                  key={section.id}
                  text={section.name}
                  onClick={() => {
                    Analytics.track(events.support.faqTopic, { topic: section.name })
                    history.push(`${RoutesEnum.SUPPORT_FAQS}/${section.id}`)
                  }}
                />
              ))}
            </QueryResults>
          ) : (
            <QueryResults
              loading={searchLoading}
              error={searchError}
              size="medium"
              retry={() => searchRefetch}
              listShimmerCount={10}
            >
              <Text tag="h5" textColor={theme.colors.ui2}>
                {"Search Results"}
              </Text>
              {searchResults?.map((result) => (
                <NavigationListItem
                  text={result.title}
                  key={result.id}
                  onClick={() => history.push(`${RoutesEnum.SUPPORT_FAQS}/search/${result.id}`)}
                />
              ))}
            </QueryResults>
          )}
        </Box>
      </>
    </BaseLayout>
  )
}
