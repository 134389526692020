import React from "react"
import * as Sentry from "@sentry/browser"
import { CLIENT_NAME } from "@core/apollo/apollo-client"
import { traceId } from "@utils/logger"

interface GlobalErrorCatchProps {
  children?: React.ReactNode
}

/**
 * TODO - [DEV-508] Update this component to use the new sentry react integration
 * https://northone.atlassian.net/browse/DEV-508
 **/
export class GlobalErrorCatch extends React.Component<GlobalErrorCatchProps> {
  public componentDidCatch(error: any, errorInfo: any) {
    Sentry.withScope((scope) => {
      scope.setTags({
        trace_id: traceId,
        client: CLIENT_NAME,
      })
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key])
      })
      Sentry.captureException(error)
    })
  }
  public render() {
    return <React.Fragment>{this.props.children}</React.Fragment>
  }
}
