import React, { useState } from "react"
import { Box } from "rebass"
import { Text } from "@components/primitive/text/text"
import { FlexColumnLayout, FlexRowLayout } from "@northone/ui"
import { theme } from "@layouts/theme"
import { Image } from "rebass"
import { PrismicText } from "@prismicio/react"
import { TPerk } from "../types"
import { PerksModal } from "./perks-modal"
import { perksStyles } from "../style"
import { Analytics } from "@core/analytics/actions"
import { events } from "@core/analytics/events"
import * as prismicH from "@prismicio/helpers"

const capturePerksClickAnalytics = (
  perkCategory: string,
  perkPartnerName: string,
  hasSearchValue: boolean,
) => {
  Analytics.track(events.perks.perksPartnerClick, {
    perkCategory,
    perkPartnerName,
    entry: hasSearchValue ? "Search" : "MainPage",
  })
}

export const PerksCard: React.FC<{ perk: TPerk; hasSearchValue: boolean }> = ({
  perk,
  hasSearchValue,
}) => {
  const [isModalOpened, setIsModalOpened] = useState<boolean>(false)
  const {
    node: { partner_name, partner_logo, partner_category, perk_header, partner_description },
  } = perk

  const openCloseModal = () => {
    setIsModalOpened(!isModalOpened)
  }

  return (
    <Box
      sx={perksStyles.container}
      onClick={() => {
        if (!isModalOpened) {
          capturePerksClickAnalytics(
            prismicH.asText(partner_name),
            partner_category,
            hasSearchValue,
          )
        }
        openCloseModal()
      }}
    >
      <FlexColumnLayout>
        <FlexRowLayout sx={perksStyles.header}>
          {partner_logo.url && (
            <Image src={partner_logo.url} sx={{ height: 36, width: 36, marginRight: 16 }} />
          )}

          <Text tag={"body-large-bold"}>
            <PrismicText field={partner_name} />
          </Text>
        </FlexRowLayout>
        <Text sx={perksStyles.category} tag={"h5"}>
          {partner_category}
        </Text>
        <Text
          sx={{
            color: theme.colors.greenPerks,
            marginBottom: "8px",
          }}
          tag={"body-large-bold"}
        >
          <PrismicText field={perk_header} />
        </Text>
        <Text tag={"body-small"}>
          <PrismicText field={partner_description} />
        </Text>
      </FlexColumnLayout>
      <PerksModal perk={perk} isOpened={isModalOpened} onCloseClick={openCloseModal} />
    </Box>
  )
}
