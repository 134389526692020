import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { FlexColumnLayout, theme } from "@northone/ui"
import { v4 as uuid } from "uuid"

import { InfoBlock } from "@components/composite/info-block/info-block"
import { ButtonTypeEnum } from "@components/primitive/button/button"
import { TextInput } from "@components/extended/fields/text-input"
import { useAppSelector } from "@core/redux/utils"
import { selectActiveBusinessID } from "@core/active-business/redux/selectors"
import { useCreateInvoiceAccountsPayableContactMutation } from "@generated/graphql"
import { validate } from "@utils/validate"
import { Text } from "@components/primitive/text/text"
import { NORTHONE_TERMS_OF_SERVICE_URL } from "@utils/constants"

const VENDOR_NAME_LABEL = "invoicePayments.modal.invoiceDetails.addNewContact.otherVendor.nameLabel"
const VENDOR_NAME_PLACEHOLDER =
  "invoicePayments.modal.invoiceDetails.addNewContact.otherVendor.namePlaceholder"
const VENDOR_EMAIL_LABEL =
  "invoicePayments.modal.invoiceDetails.addNewContact.otherVendor.emailLabel"
const VENDOR_EMAIL_PLACEHOLDER =
  "invoicePayments.modal.invoiceDetails.addNewContact.otherVendor.emailPlaceholder"
const ADD_VENDOR_DISCLOSURE =
  "invoicePayments.modal.invoiceDetails.addNewContact.otherVendor.disclosure"
const ADD_VENDOR_INVALID_EMAIL =
  "invoicePayments.modal.invoiceDetails.addNewContact.otherVendor.invalidEmail"
const ADD_VENDOR_CONFIRM_BUTTON_LABEL =
  "invoicePayments.modal.invoiceDetails.addNewContact.confirmButton"

const MARGIN_BOTTOM = "1.5rem"
const PADDING_BOTTOM = 5
const PADDING_TOP = "22px"

interface AddOtherVendorProps {
  closeOverlay: () => void
  onContactCreated: (contactId: string) => Promise<void>
}

// TODO: See how we can move some states into the parent components to make this component more reusable
export function AddOtherVendor({ closeOverlay, onContactCreated }: AddOtherVendorProps) {
  const { t } = useTranslation()
  const businessId = useAppSelector(selectActiveBusinessID)

  const [hasError, setHasError] = useState<boolean>(false)
  const [name, setName] = useState<string>("")
  const [email, setEmail] = useState<string>("")
  const [idempotencyKey, setIdempotencyKey] = useState<string>(uuid())
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [createContact] = useCreateInvoiceAccountsPayableContactMutation({
    variables: {
      input: {
        businessId,
        name,
        email: email || undefined,
        idempotencyKey,
      },
    },
    onError: () => {
      setHasError(true)
      setIsLoading(false)
    },
  })

  const isValidEmail = validate.email(email)
  const isButtonDisabled = Boolean(!name || (email && !isValidEmail))

  function resetState() {
    setHasError(false)
    setName("")
    setEmail("")
    setIdempotencyKey(uuid())
  }

  return (
    <InfoBlock
      containerSx={{ maxWidth: "100%" }}
      buttonContainerSx={{ paddingTop: "22px" }}
      errorMessage={hasError ? t("errors.generic") : ""}
      bodyText={
        <FlexColumnLayout
          sx={{
            width: "100%",
            height: "100%",
            paddingBottom: PADDING_BOTTOM,
            paddingTop: PADDING_TOP,
          }}
        >
          <TextInput
            value={name}
            onChange={setName}
            label={t(VENDOR_NAME_LABEL)}
            placeholder={t(VENDOR_NAME_PLACEHOLDER)}
            containerSx={{ mb: MARGIN_BOTTOM }}
            maxLength={32}
          />
          <TextInput
            value={email}
            onChange={setEmail}
            label={t(VENDOR_EMAIL_LABEL)}
            placeholder={t(VENDOR_EMAIL_PLACEHOLDER)}
            containerSx={{ mb: MARGIN_BOTTOM }}
            errorMessage={email && !isValidEmail ? t(ADD_VENDOR_INVALID_EMAIL) : undefined}
          />
          <Text
            tag="body-x-small"
            textColor={theme.colors.ui2}
            sx={{ mb: MARGIN_BOTTOM }}
            textWithEmbeddedLinksProps={{
              text: t(ADD_VENDOR_DISCLOSURE),
              linksInOrder: [NORTHONE_TERMS_OF_SERVICE_URL],
              linkStyle: { color: theme.colors.ui2 },
            }}
          ></Text>
        </FlexColumnLayout>
      }
      buttons={[
        {
          type: ButtonTypeEnum.PRIMARY_BLUE,
          children: t(ADD_VENDOR_CONFIRM_BUTTON_LABEL),
          canContinueWithKey: true,
          onClick: async () => {
            setHasError(false)
            setIsLoading(true)
            const result = await createContact().catch()
            const contactId = result.data?.contactCreate?.contact.id

            // handle errors from mutation
            if (result.errors?.length || !contactId) {
              setHasError(true)
              setIsLoading(false)
              return
            }

            // on success, close overlay
            setIsLoading(false)
            resetState()
            closeOverlay()
            onContactCreated(contactId)
          },
          isLoading,
          disabled: isButtonDisabled,
        },
      ]}
    />
  )
}
