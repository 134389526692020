import React from "react"
import { events } from "@core/analytics/events"
import { ACHScreensEnum } from "@features/move-money/router/ach"
import { MoveMoneySubAccountForm } from "@features/move-money/shared/sub-accounts-form"

export const ACHSubAccountForm: React.FC = () => {
  return (
    <MoveMoneySubAccountForm
      selectEvent={events.moveMoney.ach.subAccountSelection}
      continueEvent={events.moveMoney.ach.subAccount}
      navigateNextRoute={ACHScreensEnum.ACH_CONFIRM}
    />
  )
}
