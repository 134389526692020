import React from "react"
import { useTranslation } from "react-i18next"
import { Pill, Text } from "@northone/ui"
import { SxProps } from "rebass"

import { theme } from "@layouts/theme"

const DEFAULT_BACKGROUND_COLOR = theme.colors.ui5
const DEFAULT_TEXT_COLOR = theme.colors.ui2

export const PilotPill: React.FC<SxProps> = ({ sx }) => {
  const { t } = useTranslation()

  return (
    <Pill
      sx={{
        height: 25,
        padding: "5px 12px",
        backgroundColor: DEFAULT_BACKGROUND_COLOR,
        color: DEFAULT_TEXT_COLOR,
        gap: "4px",
        ...sx,
      }}
    >
      <Text
        sx={{
          fontWeight: 500,
          letterSpacing: "1px",
          textTransform: "uppercase",
          color: "inherit",
        }}
        tag="label"
      >
        {t("earlyAccess.beta")}
      </Text>
    </Pill>
  )
}
