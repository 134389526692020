import { images } from "@assets/images/images"
import { RevenueHubConnectionPlatformEnum } from "@generated/graphql"

export const defaultPlatformsList = [
  {
    type: RevenueHubConnectionPlatformEnum.STRIPE,
    imageSrc: images.revenueHub.stripe,
    name: "Stripe",
    siteUrl: "https://dashboard.stripe.com/login",
  },
  {
    type: RevenueHubConnectionPlatformEnum.SQUARE,
    imageSrc: images.revenueHub.square,
    name: "Square",
    siteUrl: "https://squareup.com/login",
  },
  {
    type: RevenueHubConnectionPlatformEnum.SHOPIFY,
    imageSrc: images.revenueHub.shopify,
    name: "Shopify",
    siteUrl: "https://www.shopify.com/login",
  },
  {
    type: RevenueHubConnectionPlatformEnum.PAYPAL,
    imageSrc: images.revenueHub.paypal,
    name: "PayPal",
    siteUrl: "https://www.paypal.com/signin",
  },
  {
    type: RevenueHubConnectionPlatformEnum.ETSY,
    imageSrc: images.revenueHub.etsy,
    name: "Etsy",
    siteUrl: "https://www.etsy.com/signin",
  },
  {
    type: RevenueHubConnectionPlatformEnum.AMAZON,
    imageSrc: images.revenueHub.amazon,
    name: "Amazon",
    siteUrl: "https://sellercentral.amazon.com/",
  },
] as const

export type RevenueHubPlatformInfo = typeof defaultPlatformsList[number]
