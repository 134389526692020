import React from "react"
import { useTranslation } from "react-i18next"

import { Text } from "@northone/ui"
import { BaseLayout } from "@layouts/base-layout"
import { theme } from "@layouts/theme"
import { selectActiveBusinessID } from "@core/active-business/redux/selectors"
import { useAppSelector } from "@core/redux/utils"
import { QueryResults } from "@components/composite/query-results/query-results"
import { AccountStatementListItemGroup } from "@components/extended/list-items/account-statement/account-statement-list-item-group"
import { useAccountStatementsByYearQuery } from "@generated/graphql"

import { AccountStatementsZeroState } from "./statements-zero-state"

// NOTE: This will require a different solution once I18n is actually used because ordinals are in English.
export const STATEMENT_AVAILABLE_DAY_OF_MONTH = "4th"

const ACCOUNT_STATEMENTS_TITLE_I18N = "settings.pages.accountStatements.title"
const ACCOUNT_STATEMENTS_HEADER_I18N = "accountStatements.header"
const PAGE_TITLES_SETTINGS_STATEMENTS_TITLE_I18N = "pageTitles.settings.statements"

export const AccountStatements: React.FC = () => {
  const { t } = useTranslation()
  const businessId: string = useAppSelector(selectActiveBusinessID)

  const { data, loading, error, refetch } = useAccountStatementsByYearQuery({
    variables: { businessId },
    notifyOnNetworkStatusChange: true,
  })

  const orderedAccountStatements = data?.business?.bankAccount?.accountStatementsByYear
  const wasExistingRadiusUser = data?.me?.wasExistingRadiusUser

  return (
    <BaseLayout pageTitle={t(PAGE_TITLES_SETTINGS_STATEMENTS_TITLE_I18N)}>
      <QueryResults
        loading={loading}
        error={error}
        retry={() => refetch()}
        size="medium"
        listShimmerCount={3}
      >
        <>
          {orderedAccountStatements &&
          orderedAccountStatements.length === 0 &&
          !wasExistingRadiusUser ? (
            <AccountStatementsZeroState />
          ) : (
            <>
              <Text tag="h2" sx={{ marginBottom: 20 }}>
                {t(ACCOUNT_STATEMENTS_TITLE_I18N)}
              </Text>
              <Text tag="body" textColor={theme.colors.ui3}>
                {t(ACCOUNT_STATEMENTS_HEADER_I18N, {
                  dayOfMonth: STATEMENT_AVAILABLE_DAY_OF_MONTH,
                })}
              </Text>
              {orderedAccountStatements && orderedAccountStatements.length > 0 ? (
                <>
                  {orderedAccountStatements.map((yearlyStatementGroup, idx) => (
                    <AccountStatementListItemGroup
                      yearlyStatementGroup={yearlyStatementGroup}
                      key={idx}
                    />
                  ))}
                </>
              ) : null}
            </>
          )}
        </>
      </QueryResults>
    </BaseLayout>
  )
}
