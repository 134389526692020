import React from "react"
import { Box, SxStyleProp } from "rebass"
import { Text } from "../text/text"
import { FlexRowLayout } from "@layouts/flex"
import { theme } from "@layouts/theme"

export type TListLabel = { title: string; widthRatio: number; alignment: "start" | "end" }

export interface IListLabelsProps {
  labels: TListLabel[]
  labelColor?: string
  sx?: SxStyleProp
}

/**
 * Defines a list labels primitive - Used in a composite to create a list of list-items
 */
export const ListLabels: React.FC<IListLabelsProps> = ({
  labels,
  sx,
  labelColor = theme.colors.ui3,
}) => {
  return (
    <FlexRowLayout sx={sx}>
      {labels.map((label, idx) => (
        <Box
          key={idx}
          sx={{
            width: `${label.widthRatio * 100}%`,
            height: 60,
            display: "flex",
            justifyContent: label.alignment === "start" ? "flex-start" : "flex-end",
            alignItems: "center",
          }}
        >
          <Text tag={"h5"} textColor={labelColor}>
            {label.title}
          </Text>
        </Box>
      ))}
    </FlexRowLayout>
  )
}
