export const buttons = {
  addMoney: "Add money",
  back: "Back",
  cancel: "Cancel",
  close: "Close",
  contactCustomerCare: "Contact Customer Care",
  continue: "Continue",
  copy: "Copy",
  delete: "Delete",
  dismiss: "Dismiss",
  done: "Done",
  downloadPDF: "DOWNLOAD (PDF)",
  edit: "Edit",
  exit: "Exit",
  goBack: "Go back",
  gotIt: "Got it",
  hide: "Hide",
  later: "I'll do this later",
  perks: "Perks",
  myPlan: "My Plan",
  reload: "Reload page",
  remove: "Remove",
  saveChanges: "Save changes",
  saveName: "Save name",
  saving: "Saving",
  setupLater: "Setup later",
  show: "Show",
  skip: "Skip",
  skipSetup: "Skip setup",
  submit: "Submit",
  shareAndEarn: "Earn {{incentiveAmount}}",
  loadMore: "Load more",
  upgrade: "Upgrade",
}
