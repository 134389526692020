import { parseISO } from "date-fns"
import { SHARE_AND_EARN_LAST_VISITED_KEY } from "./constants"

/**
 * Retrieves from local storage the last time the S&E hub was visited
 */
export const getShareAndEarnLastVisitedFromLocalStorage = (): Date | undefined => {
  const lastVisited = localStorage.getItem(SHARE_AND_EARN_LAST_VISITED_KEY)
  if (!lastVisited) return undefined
  return parseISO(lastVisited)
}

/**
 * Sets the hub last visited time in local storage
 */
export const setShareAndEarnLastVisitedInLocalStorage = (lastVisited: Date) => {
  localStorage.setItem(SHARE_AND_EARN_LAST_VISITED_KEY, lastVisited.toISOString())
}
