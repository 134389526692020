import React, { ReactElement } from "react"
import { Image } from "rebass"
import moment from "moment"
import { useTranslation } from "react-i18next"

import { FlexColumnLayout, FlexRowLayout, Text, theme } from "@northone/ui"
import { images } from "@assets/images/images"
import {
  isBeforeWireCutOffTime,
  wireCutOffTimeInLocalTime,
} from "@features/move-money/domestic-wire/utils/helpers"
import { ESTIMATED_CHECK_DEPOSIT_TIME } from "@features/add-funds/utils/constants"

export type TTimeline = "card_auth" | "wire" | "check_deposit" | "mailed_check"

interface ITransactionTimelineProps {
  isTransactionPosted: boolean
  timelineType: TTimeline
  createdAt: string
  postedAt?: string
}
export const TransactionTimeline: React.FC<ITransactionTimelineProps> = ({
  createdAt,
  isTransactionPosted,
  postedAt,
  timelineType,
}) => {
  const { t } = useTranslation()

  const getPendingTimelineImage = () => {
    if (
      timelineType === "card_auth" ||
      timelineType === "check_deposit" ||
      timelineType === "mailed_check"
    ) {
      return images.transactionPendingTimeline
    }
    if (timelineType === "wire") {
      return images.wirePendingTimeline
    }
  }

  const getFirstStepText = () => {
    if (timelineType === "card_auth") {
      return isTransactionPosted
        ? t("dashboard.transactionPanel.cardAuth.authorized")
        : t("dashboard.transactionPanel.cardAuth.pending")
    }
    if (timelineType === "check_deposit") {
      return isTransactionPosted
        ? t("dashboard.transactionPanel.checkDeposit.approved")
        : t("dashboard.transactionPanel.checkDeposit.pending")
    }
    if (timelineType === "wire") {
      return isTransactionPosted
        ? t("dashboard.transactionPanel.wireReview.initiated")
        : t("dashboard.transactionPanel.wireReview.inReview")
    }
    if (timelineType === "mailed_check") {
      return t("dashboard.transactionPanel.mailedCheck.created")
    }
  }

  const getSecondStepPostedText = () => {
    if (timelineType === "card_auth") {
      return t("dashboard.transactionPanel.cardAuth.completed")
    }
    if (timelineType === "check_deposit") {
      return t("dashboard.transactionPanel.checkDeposit.available")
    }
    if (timelineType === "wire") {
      return t("dashboard.transactionPanel.wireReview.completed")
    }

    if (timelineType === "mailed_check") {
      return t("dashboard.transactionPanel.mailedCheck.deposited")
    }
  }

  const getSecondStepPendingText = () => {
    if (timelineType === "card_auth") {
      return {
        fundsAvailability: t("dashboard.transactionPanel.cardAuth.fundsAvailability"),
        timeConsideration: t("dashboard.transactionPanel.cardAuth.processingTime"),
      }
    }
    if (timelineType === "check_deposit") {
      return {
        fundsAvailability: t("dashboard.transactionPanel.checkDeposit.fundsAvailability"),
        timeConsideration: t("dashboard.transactionPanel.checkDeposit.timeEstimation", {
          timeEstimation: ESTIMATED_CHECK_DEPOSIT_TIME,
        }),
      }
    }
    if (timelineType === "wire") {
      return {
        fundsAvailability: t("dashboard.transactionPanel.wireReview.fundsAvailability"),
        timeConsideration: t(
          isBeforeWireCutOffTime()
            ? "dashboard.transactionPanel.wireReview.beforeWireCutoff"
            : "moveMoney.wireCutOff",
          {
            cutOffTime: wireCutOffTimeInLocalTime(),
          },
        ),
      }
    }
    if (timelineType === "mailed_check") {
      return {
        fundsAvailability: null,
        timeConsideration: t("dashboard.transactionPanel.mailedCheck.processingTime"),
      }
    }
  }

  const renderFirstStep = (): ReactElement => (
    <>
      <Text tag="body-bold">{getFirstStepText()}</Text>
      <Text tag="body" style={{ marginBottom: "5%" }}>
        {moment(createdAt).format("MMMM DD, YYYY h:mm a")}
      </Text>
    </>
  )

  const renderSecondStep = (): ReactElement => {
    const pendingCopy = getSecondStepPendingText()

    return isTransactionPosted ? (
      <>
        <Text tag="body-bold">{getSecondStepPostedText()}</Text>
        <Text tag="body">{moment(postedAt).format("MMMM DD, YYYY h:mm a")}</Text>
      </>
    ) : (
      <>
        <Text tag="body-small" style={{ marginBottom: "2%" }}>
          {pendingCopy?.fundsAvailability}
        </Text>
        <Text tag="body-small" textColor={theme.colors.ui3}>
          {pendingCopy?.timeConsideration}
        </Text>
      </>
    )
  }

  return (
    <FlexRowLayout>
      <Image
        src={isTransactionPosted ? images.transactionPostedTimeline : getPendingTimelineImage()}
        sx={{ height: "100%", width: "auto", paddingTop: 1 }}
      />

      <FlexColumnLayout style={{ width: "90%", marginLeft: "2%" }}>
        {renderFirstStep()}
        {renderSecondStep()}
      </FlexColumnLayout>
    </FlexRowLayout>
  )
}
