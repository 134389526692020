import React from "react"
import { useTranslation } from "react-i18next"
import { useParams, useHistory } from "react-router-dom"
import { unescape } from "lodash-es"
import { RoutesEnum } from "@routers/types"
import { Text } from "@components/primitive/text/text"
import { QueryResults } from "@components/composite/query-results/query-results"
import { BackButton } from "@components/composite/back-button/back-button"
import { BaseLayout } from "@layouts/base-layout"
import { useFaqArticleQuery, useFaqLandingQuery } from "@generated/graphql"

export const FAQArticle: React.FC = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const { sectionId, articleId } = useParams<{ sectionId: string; articleId: string }>()

  const { data: categoryData } = useFaqLandingQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
  })

  const { data, error, loading, refetch } = useFaqArticleQuery({
    notifyOnNetworkStatusChange: true,
    variables: { articleId: articleId },
  })

  const faqArticle = data?.supportHelpCenter?.article
  const activeSection = categoryData?.supportHelpCenter.sections.find(
    (item) => item.id === sectionId,
  )

  return (
    <BaseLayout
      pageTitle={
        faqArticle ? t("pageTitles.support.faqQuestion", { question: faqArticle.title }) : ""
      }
    >
      <BackButton
        onClick={() => {
          activeSection
            ? history.push(`${RoutesEnum.SUPPORT_FAQS}/${activeSection?.id}`)
            : history.push(`${RoutesEnum.SUPPORT_FAQS}`)
        }}
        text={
          activeSection
            ? t("support.faq.backToCategory", { category: activeSection?.name })
            : t("support.faq.backToFaqs")
        }
        sx={{ marginBottom: 16 }}
      />
      <Text tag="h2">{faqArticle?.title}</Text>
      <QueryResults
        loading={loading}
        error={error}
        size="medium"
        retry={() => refetch}
        listShimmerCount={5}
      >
        <Text tag="body" marginTop={16}>
          {unescape(faqArticle?.body || "")}
        </Text>
      </QueryResults>
    </BaseLayout>
  )
}
