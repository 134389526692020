import { useDispatch } from "react-redux"

import { useShareAndEarnHubQuery } from "@generated/graphql"
import { shareAndEarnActions } from "./redux/actions"
import { SHARE_AND_EARN_INVITE_LINK_SOURCE } from "./constants"

export const useShareAndEarn = (businessId: string) => {
  const dispatch = useDispatch()
  const { data, loading, error, refetch } = useShareAndEarnHubQuery({
    variables: { businessId },
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      const advocate = data?.business?.shareAndEarn?.advocate
      if (!advocate) return

      const offerUrlWithSource = `${advocate.offerUrl}?source=${SHARE_AND_EARN_INVITE_LINK_SOURCE}`

      dispatch(
        shareAndEarnActions.setShareAndEarnData({
          ...advocate,
          offerUrl: offerUrlWithSource,
          isShareAndEarnLoading: false,
        }),
      )
    },
    onError: () => {
      dispatch(
        shareAndEarnActions.setShareAndEarnData({
          isShareAndEarnLoading: false,
        }),
      )
    },
  })
  return { data, loading, error, refetch }
}
