import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { Text } from "@northone/ui"
import { Button, ButtonTypeEnum } from "@components/primitive/button/button"
import { NoticeBox } from "@components/primitive/notice-box/notice-box"
import { FlexColumnLayout } from "@layouts/flex"
import { OnboardingLayout } from "@layouts/post-onboarding-layout"
import { useEmailVerifiedQuery, useResendVerificationEmailMutation } from "@generated/graphql"
import { ReCAPTCHAComponent } from "@core/recaptcha"
import ReCAPTCHA from "react-google-recaptcha"
import { config } from "@utils/environment"
import { useHistory } from "react-router-dom"
import { RoutesEnum } from "@routers/types"
interface IEmailGateProps {
  email: string
}

export const EmailGate: React.FC<IEmailGateProps> = ({ email }) => {
  const { t } = useTranslation()
  const history = useHistory()

  const [hasRequestedEmailResend, setHasRequestedEmailResend] = useState(false)
  const [hasError, setHasError] = useState(false)

  const [resendVerificationEmail, { loading }] = useResendVerificationEmailMutation({
    onError: () => setHasError(true),
    onCompleted: () => setHasRequestedEmailResend(true),
  })

  const { data, stopPolling, error } = useEmailVerifiedQuery({
    pollInterval: 1000,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  })

  const reCAPTCHARef = useRef<ReCAPTCHA>(null)

  const getReCAPTCHAToken = async () => {
    return await reCAPTCHARef.current?.executeAsync()
  }

  const resetReCAPTCHA = () => {
    return reCAPTCHARef.current?.reset()
  }

  const onResendEmailClick = async () => {
    const reCAPTCHAToken = await getReCAPTCHAToken()

    if (!reCAPTCHAToken) {
      resetReCAPTCHA()
      return setHasError(true)
    }

    setHasError(false)
    resendVerificationEmail({
      variables: { email, reCAPTCHAToken },
    })
    resetReCAPTCHA()
  }

  useEffect(() => {
    if (data?.me?.emailVerified) {
      stopPolling()
      history.push(RoutesEnum.DASHBOARD)
    }
    if (error) {
      stopPolling()
    }
  }, [data, error, stopPolling])

  return (
    <OnboardingLayout>
      <FlexColumnLayout sx={{ alignItems: ["center", "inherit"] }}>
        {hasError && <NoticeBox level="error" text={t("errors.generic")} />}
        <Text tag={["h2", "h1"]} sx={{ marginBottom: "8px" }}>
          {t("postOnboarding.emailGate.title")}
        </Text>
        <Text tag={"body-large"} sx={{ marginBottom: 24 }} hasPartialBolding>
          {t("postOnboarding.emailGate.subtitle", { email })}
        </Text>
        <ReCAPTCHAComponent
          reCAPTCHARef={reCAPTCHARef}
          siteKey={config.reCAPTCHASiteKey}
          sx={{
            marginBottom: 24,
          }}
        />
        <Button
          type={ButtonTypeEnum.PRIMARY_BLUE}
          sx={{ alignSelf: "flex-start" }}
          onClick={onResendEmailClick}
          isLoading={loading}
          disabled={hasRequestedEmailResend}
        >
          {loading
            ? t("postOnboarding.emailGate.ctaLoading")
            : hasRequestedEmailResend
            ? t("postOnboarding.emailGate.ctaSent")
            : t("postOnboarding.emailGate.cta")}
        </Button>
      </FlexColumnLayout>
    </OnboardingLayout>
  )
}
