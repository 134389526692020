import moment from "moment"
import { startDateIsToday } from "@features/move-money/redux/selectors"
import {
  SENT_FIRST_I18N,
  SENT_PAYMENT_TYPES_I18N,
  SENT_TRANSFER,
  SENT_PAYMENT,
  SENT_FREQUENCY_I18N,
  SENT_NO_END_DATE,
  SENT_SUBTITLE_I18N,
  TRANSACTIONS_EXPECTED_DURATION_I18N,
} from "@features/move-money/utils/i18n-translations"
import { translate } from "@i18n/i18n"
import { TransactionTypeEnum, ScheduleFrequencyEnum } from "@generated/graphql"
import { momentCalendarSpecDefault, momentCalendarSpecPaymentSent } from "@utils/format"
import { scheduleFrequencyToI18n } from "@utils/mapping/schedules.mapping"

const getRepeatingCopy = ({
  repeating,
  isTransfer,
  startDateIsToday,
  paymentType,
}: {
  repeating: boolean
  isTransfer?: boolean
  startDateIsToday: boolean
  paymentType?: string
}) => {
  if (repeating) {
    return translate(SENT_FIRST_I18N, { context: isTransfer ? "transfer" : null })
  } else if (!startDateIsToday) {
    return translate(`${SENT_PAYMENT_TYPES_I18N}.${paymentType}`, { count: 1 })
  } else if (isTransfer) {
    return SENT_TRANSFER
  } else {
    return SENT_PAYMENT
  }
}

export const getPaymentSuccessSubtitle = ({
  startDate,
  endDate,
  paymentType,
  frequency,
}: {
  startDate: Date | null
  endDate?: Date | null
  paymentType?: TransactionTypeEnum
  frequency: ScheduleFrequencyEnum
}) => {
  const repeating = frequency !== ScheduleFrequencyEnum.ONCE
  const isTransfer = paymentType === TransactionTypeEnum.SUB_ACCOUNT_TRANSFER
  const frequencyCopy = repeating
    ? translate(SENT_FREQUENCY_I18N, {
        frequency: scheduleFrequencyToI18n[frequency].toLocaleLowerCase(),
        endDate: endDate
          ? moment(endDate).calendar(null, momentCalendarSpecDefault)
          : SENT_NO_END_DATE,
      })
    : ""
  const dateCopy = moment(startDate).calendar(null, momentCalendarSpecPaymentSent)
  const context =
    isTransfer && startDateIsToday(startDate) ? "transferToday" : isTransfer ? "transfer" : null

  if (repeating && isTransfer && startDateIsToday(startDate)) {
    return translate(SENT_SUBTITLE_I18N, {
      context: "transferTodayRepeating",
      dateCopy,
      frequencyCopy,
    })
  }

  if (isTransfer) {
    return ""
  }

  return translate(SENT_SUBTITLE_I18N, {
    repeatingCopy: getRepeatingCopy({
      repeating,
      isTransfer,
      paymentType,
      startDateIsToday: startDateIsToday(startDate),
    }),
    paymentMethod: translate(`${SENT_PAYMENT_TYPES_I18N}.${paymentType}`, { count: 2 }),
    paymentDuration: translate(`${TRANSACTIONS_EXPECTED_DURATION_I18N}.${paymentType}`, {
      count: 2,
    }),
    frequencyCopy,
    dateCopy,
    context,
  })
}
