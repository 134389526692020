import React from "react"
import { FlexRowLayout } from "../../layouts/flex"
import { Box, SxStyleProp } from "rebass"
import { theme } from "@layouts/theme"

export interface InfoBannerProps {
  children?: React.ReactNode
  onClick?: () => void
  style?: SxStyleProp
  displayNone?: boolean
}

export const PersistentBottomBanner = ({
  children,
  onClick,
  style,
  displayNone,
}: InfoBannerProps) => (
  <FlexRowLayout
    displayNone={displayNone}
    onClick={onClick}
    sx={{
      flexDirection: "column",
      position: "fixed",
      bottom: "0",
      width: "100%",
      minHeight: 48,
      boxShadow: theme.boxShadow,
      borderRadius: "8px",
      alignItems: "center",
      paddingX: 16,
      marginBottom: 24,
      ":hover": {
        cursor: onClick ? "pointer" : undefined,
      },
      ...style,
    }}
  >
    <Box sx={{ paddingY: "5px", paddingLeft: 12 }}>{children}</Box>
  </FlexRowLayout>
)
