import React from "react"
import { useTranslation } from "react-i18next"

import { FlexColumnLayout, FlexRowLayout } from "@layouts/flex"
import { Box, Image } from "rebass"
import { images } from "@assets/images/images"
import { useAppSelector } from "@core/redux/utils"

import { Button, ButtonTypeEnum } from "@northone/ui"
import { RoutesEnum } from "@routers/types"
import { useHistory } from "react-router-dom"
import { Analytics } from "@core/analytics/actions"
import { events } from "@core/analytics/events"
import { SHARE_AND_EARN_HUB_TOP_SPACING } from "../share-and-earn-landing"
import { ShareAndEarnTitleAndBody } from "./title-and-body.component"

const NoAdvocateIdContent = ({ incentiveAmount }: { incentiveAmount: string }) => {
  const { t } = useTranslation()
  const content: string[] = t("shareAndEarn.noAdvocateId.content", {
    incentiveAmount,
    returnObjects: true,
  })
  return (
    <ShareAndEarnTitleAndBody
      title={t("shareAndEarn.noAdvocateId.title")}
      bodyListItems={content}
    />
  )
}

const ActivateCardButton = () => {
  const { t } = useTranslation()
  const businessId = useAppSelector((state) => state.activeBusiness.businessId)
  const history = useHistory()
  return (
    <Button
      onClick={() => {
        Analytics.track(events.shareAndEarn.hubActivateDebitCardTap, { businessId })
        history.push(RoutesEnum.CARDS)
      }}
      sx={{ alignSelf: "flex-start" }}
      type={ButtonTypeEnum.PRIMARY_GOLD}
    >
      {t("shareAndEarn.noAdvocateId.activateDebitCard")}
    </Button>
  )
}

export const NoAdvocateId = ({ incentiveAmount }: { incentiveAmount: string }) => {
  return (
    <FlexRowLayout>
      <FlexColumnLayout sx={{ paddingTop: SHARE_AND_EARN_HUB_TOP_SPACING }} width="100%">
        <NoAdvocateIdContent incentiveAmount={incentiveAmount} />
        <ActivateCardButton />
      </FlexColumnLayout>
      <Box paddingTop={20}>
        <Image
          width={350}
          src={images.shareAndEarnNoAdvocateId}
          sx={{ position: "relative", top: 45, left: 80 }}
        />
      </Box>
    </FlexRowLayout>
  )
}
