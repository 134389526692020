import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next"

import i18n from "@i18n/i18n"
import { Box } from "rebass"

import { selectActiveBusinessID } from "@core/active-business/redux/selectors"
import { useAppSelector } from "@core/redux/utils"
import { QueryResults } from "@components/composite/query-results/query-results"
import { BannerButton } from "@components/extended/buttons/banner-button"
import { ButtonTypeEnum } from "@components/primitive/button/button"
import { NoticeBox } from "@components/primitive/notice-box/notice-box"
import { Text } from "@components/primitive/text/text"
import { TeamBankingBanner } from "@features/dashboard/team-banking/team-banking-banner"
import { TeamOwner, useListOwnersQuery } from "@generated/graphql"
import { InviteModal } from "./invite-modal"
import { BaseLayout } from "@layouts/base-layout"
import { FlexColumnLayout } from "@layouts/flex"
import { teamBankingActions } from "./redux/actions"
import { TeamBankingError } from "./team-banking-error"
import { TeamMemberList } from "./team-member-list"
import { TeamMemberSidePanel } from "./team-member-side-panel"
import { theme } from "@layouts/theme"
import { MULTI_OWNER_PILOT_CUSTOMER_FAQ } from "@utils/constants"

const useTeamMembersQuery = () => {
  const businessId = useAppSelector(selectActiveBusinessID)
  const [allTeamMembers, setAllTeamMembers] = useState<TeamOwner[] | undefined>()
  const [registeredTeamMembers, setRegisteredTeamMembers] = useState<TeamOwner[]>([])
  const [unregisteredTeamMembers, setUnregisteredTeamMembers] = useState<TeamOwner[]>([])
  const { loading, error, refetch } = useListOwnersQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
    variables: { businessId },
    onCompleted: (data) => {
      const owners = data?.listOwners?.owners ?? []
      setAllTeamMembers(owners)
      setRegisteredTeamMembers(owners.filter((teamMember) => teamMember.userId))
      setUnregisteredTeamMembers(owners.filter((teamMember) => !teamMember.userId))
    },
  })
  return {
    loading,
    error,
    refetch,
    allTeamMembers,
    registeredTeamMembers,
    unregisteredTeamMembers,
    setRegisteredTeamMembers,
    setUnregisteredTeamMembers,
  }
}
export const TeamBankingLanding = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const selectedTeamMember = useAppSelector((state) => state.teamBanking.selectedTeamMember)
  const isModalOpen = useAppSelector((state) => state.teamBanking.isModalOpen)
  const sendInviteError = useAppSelector((state) => state.teamBanking.sendInviteError)
  const location = useLocation()

  useEffect(() => {
    dispatch(teamBankingActions.setSelectedTeamMember(undefined))
    dispatch(teamBankingActions.setIsModalOpen(false))
  }, [location, dispatch])

  const {
    loading,
    error,
    refetch,
    allTeamMembers,
    registeredTeamMembers,
    unregisteredTeamMembers,
    setRegisteredTeamMembers,
    setUnregisteredTeamMembers,
  } = useTeamMembersQuery()

  const onInviteSent = ({ personId }: { personId: string }) => {
    const teamMember = allTeamMembers?.find((person) => person.id === personId)
    if (!teamMember || teamMember.userId) {
      return
    }
    const predictedRegisteredTeamMembers: TeamOwner[] = [...registeredTeamMembers, teamMember]
    setRegisteredTeamMembers(predictedRegisteredTeamMembers)
    const predictedUnregisteredTeamMembers: TeamOwner[] = unregisteredTeamMembers.filter(
      (unregisteredTeamMember) => unregisteredTeamMember !== teamMember,
    )
    setUnregisteredTeamMembers(predictedUnregisteredTeamMembers)
  }

  const AdditionalOwnerFAQContent = () => {
    return (
      <Text
        tag={"body-small"}
        sx={{ marginTop: 15, color: theme.colors.ui2 }}
        textWithEmbeddedLinksProps={{
          text: i18n.t("settings.pages.teamBanking.faq"),
          linksInOrder: [MULTI_OWNER_PILOT_CUSTOMER_FAQ, 'javascript:window.Intercom("show")'],
          linkStyle: { color: theme.colors.ui2 },
        }}
      ></Text>
    )
  }

  const errorBanner = sendInviteError ? (
    <NoticeBox level="error" text={t("settings.pages.teamBanking.modal.inviteSent.error")} />
  ) : null

  return (
    <BaseLayout
      pageTitle={t("pageTitles.settings.teamBanking")}
      rightAside={
        selectedTeamMember && (
          <TeamMemberSidePanel
            selectedTeamMemberHasBeenInvited={registeredTeamMembers.includes(selectedTeamMember)}
            teamMember={selectedTeamMember}
          />
        )
      }
    >
      <Box sx={{ paddingX: 20 }}>
        <Text tag="h2" sx={{ marginBottom: 36 }}>
          {t("settings.pages.teamBanking.title")}
        </Text>
        {errorBanner}
        <QueryResults
          loading={loading}
          error={error}
          errorElement={<TeamBankingError retry={refetch} />}
          retry={() => refetch()}
          size="medium"
          listShimmerCount={2}
        >
          {allTeamMembers && (
            <FlexColumnLayout
              sx={{
                width: "100%",
              }}
            >
              {Boolean(registeredTeamMembers.length) && (
                <TeamMemberList
                  teamMembers={registeredTeamMembers}
                  hasBeenInvited={true}
                  label={i18n.t("settings.pages.teamBanking.labels.member")}
                />
              )}
              {Boolean(unregisteredTeamMembers.length) && (
                <TeamMemberList
                  teamMembers={unregisteredTeamMembers}
                  hasBeenInvited={false}
                  label={i18n.t("settings.pages.teamBanking.labels.recommended")}
                  containerSx={{ marginTop: "50px" }}
                />
              )}
            </FlexColumnLayout>
          )}
        </QueryResults>
        <TeamBankingBanner
          title={i18n.t("settings.pages.teamBanking.banner.teamsPage.title")}
          subtitle={i18n.t("settings.pages.teamBanking.banner.teamsPage.subtitle")}
          buttons={[
            <BannerButton
              key={0}
              type={ButtonTypeEnum.PRIMARY_BLACK}
              text={i18n.t("settings.pages.teamBanking.banner.buttons.feedback")}
              onClick={() => window.Intercom("show")}
            />,
          ]}
          isPillShown={true}
          bannerHeight={155}
          containerSx={{ marginTop: "24px" }}
        />
        <AdditionalOwnerFAQContent />
      </Box>
      <InviteModal
        onInviteSent={onInviteSent}
        isOpen={isModalOpen}
        teamMember={selectedTeamMember}
      />
    </BaseLayout>
  )
}
