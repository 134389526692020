import { Auth0Client, GetTokenSilentlyOptions } from "@auth0/auth0-spa-js"
import { getPersistedTraitToken } from "@northone/segment-js"
import { config } from "@utils/environment"
import { Analytics } from "@core/analytics/actions"
import { store } from "../.."
import { appActions, AppActionEnum } from "../redux/app/actions"
import { ThunkDispatch } from "redux-thunk"
import { IRootState } from "../redux/root-state"
import { IAction } from "../redux/utils"

// https://auth0.com/docs/libraries/common-auth0-library-authentication-errors
export enum AuthenticationError {
  BLOCKED = "unauthorized",
  LOGIN_REQUIRED = "login_required",
  TIMEOUT = "timeout",
}

// SDK by default includes 'openid' in scope
// when useRefreshTokens is set to true, 'offline_access' is also included in the scope.

export const BASE_SCOPES = "email profile email address phone"
const OPENID_SCOPE = "openid"
const BUSINESS_TOKEN_PREFIX = "business_token"
const BUSINESS_CLAIM = "https://northone.com/businesses"

export const auth = new Auth0Client({
  domain: config.auth0.domain,
  client_id: config.auth0.clientId,
  redirect_uri: window.location.origin,
  audience: "northoneCoreApi",
  advancedOptions: {
    defaultScope: BASE_SCOPES,
  },
})

/**
 * helper function to log the user out and clear application state
 */
let loggedOut = false
export const logout = async () => {
  loggedOut = true
  await (store.dispatch as ThunkDispatch<IRootState, void, IAction<AppActionEnum>>)(
    appActions.resetState(),
  )
  Analytics.reset()
  window.Intercom("shutdown")
  auth.logout({
    returnTo: `${window.location.origin}?logout=true`,
  })
}

/**
 * helper function to redirect the user to login
 * @param mode mode paramter to pass to SSO page
 */
export const redirectToLogin = async (mode?: string): Promise<void> => {
  const urlParams = new URLSearchParams(window.location.search)
  const encodedEmail = urlParams.get("email")
  return auth.loginWithRedirect({
    mode: mode || urlParams.get("mode") || undefined,
    ptt: await getPersistedTraitToken(),
    ...(encodedEmail && { login_hint: decodeURIComponent(encodedEmail) }),
    appState: { redirectRoute: window.location.pathname },
  })
}

/**
 * attempts to get an access token
 * - will attempt twice in order to fallback to iframe session from failed refresh token exchange
 */
export const getTokenSilently = async (
  params?: GetTokenSilentlyOptions,
): Promise<string | null> => {
  // IMPORTANT: sessions can stay alive if a token is requested during logout
  // see: https://bitbucket.org/northone/northone-web-banking/pull-requests/365/np-5720-return-null-token-after-logout
  if (loggedOut) return null
  // scopes are lost on refresh token grant, so we pass it in our own param.
  // https://github.com/auth0/auth0-spa-js/pull/463
  const { scope = "", ...rest } = params || {}
  const refreshScopes = `${OPENID_SCOPE} ${BASE_SCOPES} ${scope}`.trim()
  const options = {
    ...rest,
    scope,
    refreshScopes,
  }

  return await auth.getTokenSilently(options)
}

interface Auth0UserContext {
  email: string
  email_verified: boolean
  "https://northone.com/businesses"?: Auth0BusinessClaim[]
}

interface Auth0BusinessClaim {
  id: string
  scopes: [string]
}

/**
 * returns a regular access token or logs the user out
 */
const getRegularAccessTokenOrLogout = async (
  options?: GetTokenSilentlyOptions,
): Promise<string | null> => {
  try {
    return await getTokenSilently(options)
  } catch (exception) {
    //@ts-ignore
    const { error } = exception

    if (error !== AuthenticationError.LOGIN_REQUIRED) {
      console.error(exception)
    }
    if (error !== AuthenticationError.TIMEOUT) {
      // Do not initiate logout event if request timeout
      logout()
    }
  }
  return null
}

/**
 * returns business claims if any from current user
 */
export const getBusinessClaims = async (): Promise<Auth0BusinessClaim[]> => {
  // ensure we have a valid id-token to extract user details from
  await getRegularAccessTokenOrLogout()
  // get user details from token
  const user: Auth0UserContext | undefined = await auth.getUser()
  if (user === undefined) return []
  return user[BUSINESS_CLAIM] || []
}

/**
 * returns business scopes if any from current user, including the business_token scope.
 */
const getBusinessScopes = async (): Promise<string> => {
  const businessClaims = await getBusinessClaims()
  if (businessClaims.length === 0) return ""
  const { id: businessId, scopes: businessScopes } = businessClaims[businessClaims.length - 1]
  return `${businessScopes.join(" ")} ${BUSINESS_TOKEN_PREFIX}:${businessId}`
}

/**
 * returns an access token with all included scopes or logs the user out
 */
export const getTokenOrLogout = async (
  options?: GetTokenSilentlyOptions,
): Promise<string | null> => {
  const businessScopes = await getBusinessScopes()
  return getRegularAccessTokenOrLogout({
    ...options,
    scope: businessScopes,
  })
}

/**
 * force renew authentication token
 */
export const renewTokenOrLogout = () => getTokenOrLogout({ ignoreCache: true })

export const isLoginRedirect = () => {
  const urlParams = new URLSearchParams(window.location.search)
  return Boolean(urlParams.get("code"))
}
