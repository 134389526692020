import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { Analytics } from "@core/analytics/actions"
import { events } from "@core/analytics/events"
import { useAppSelector } from "@core/redux/utils"
import { selectActiveBusinessID } from "@core/active-business/redux/selectors"
import { RoutesEnum } from "@routers/types"
import { Modal } from "@components/extended/overlay/modal"
import { InfoBlock } from "@components/composite/info-block/info-block"
import { ButtonTypeEnum } from "@components/primitive/button/button"
import { SUB_ACCOUNTS_QUERY } from "@features/accounts/sub-accounts/operations.gql"
import { images } from "@assets/images/images"
import { pollWithMaxRetries, refreshTransactionLists } from "@utils/poll-with-max-retries"
import { useSubAccountDeleteMutation } from "@generated/graphql"

interface IDeleteSubAccountProps {
  isOpen: boolean
  closeOverlay: () => void
  subAccountId: string
}

export const DeleteSubAccountModal: React.FC<IDeleteSubAccountProps> = ({
  isOpen,
  closeOverlay,
  subAccountId,
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const [hasError, setHasError] = useState(false)
  const businessId = useAppSelector(selectActiveBusinessID)

  const [deleteSubAccount, { loading }] = useSubAccountDeleteMutation({
    variables: { businessId, subAccountId },
    onCompleted: () => {
      refreshTransactionLists({ businessId, sourceSubAccountId: subAccountId })
      pollWithMaxRetries([{ query: SUB_ACCOUNTS_QUERY, variables: { businessId } }], 10)
      closeOverlay()
      setTimeout(() => history.push(RoutesEnum.ACCOUNTS_OVERVIEW), 300)
    },
    onError: () => setHasError(true),
  })

  return (
    <Modal isOpen={isOpen} onOutsideClick={closeOverlay}>
      <InfoBlock
        errorMessage={hasError ? t("errors.generic") : ""}
        title={t("accounts.delete.title")}
        subtitle={t("accounts.delete.subtitle")}
        imageSrc={images.illustrations.trashBin}
        imageDimensions={{ height: 180, width: 250 }}
        buttons={[
          {
            children: t("buttons.cancel"),
            onClick: closeOverlay,
          },
          {
            children: t("accounts.delete.button"),
            onClick: () => {
              setHasError(false)
              deleteSubAccount()
              Analytics.track(events.accounts.edit.deleteConfirm)
            },
            type: ButtonTypeEnum.SECONDARY,
            isLoading: loading,
          },
        ]}
      />
    </Modal>
  )
}
