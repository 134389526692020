import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams, useHistory } from "react-router-dom"
import { Box } from "rebass"
import { useAppSelector } from "@core/redux/utils"
import { selectActiveBusinessID } from "@core/active-business/redux/selectors"
import { Analytics } from "@core/analytics/actions"
import { events } from "@core/analytics/events"
import { FormBody } from "@components/composite/form-body/form-body"
import { Text } from "@components/primitive/text/text"
import { TextInput } from "@components/extended/fields/text-input"
import { ButtonTypeEnum, Button } from "@components/primitive/button/button"
import { QueryResults } from "@components/composite/query-results/query-results"
import { DeleteButton } from "@components/extended/buttons/delete-button"
import {
  SUB_ACCOUNTS_QUERY,
  SUB_ACCOUNT_DETAILS,
} from "@features/accounts/sub-accounts/operations.gql"
import { theme } from "@layouts/theme"
import { BaseLayout } from "@layouts/base-layout"
import { pollWithMaxRetries } from "@utils/poll-with-max-retries"
import {
  useSubAccountDetailsQuery,
  useRenameSubAccountMutation,
  useSubAccountNamesQuery,
} from "@generated/graphql"
import { SubAccountPercentRuleItem } from "./percent-rule-item"
import { DeleteSubAccountModal } from "./delete-sub-account-modal"

export const EditSubAccount: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const [hasError, setHasError] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [newName, setNewName] = useState("")
  const businessId = useAppSelector(selectActiveBusinessID)
  const { subAccountId } = useParams<{ subAccountId: string }>()

  const {
    data: detailsData,
    loading: detailsLoading,
    error: detailsError,
    refetch: refetchDetails,
  } = useSubAccountDetailsQuery({
    variables: { businessId, subAccountId },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => setNewName(data.business?.bankAccount?.subAccount?.name || ""),
  })

  const {
    data: subAccountNamesData,
    loading: subAccountNamesLoading,
    error: subAccountNamesError,
    refetch: refetchSubAccountNames,
  } = useSubAccountNamesQuery({
    variables: { businessId },
    notifyOnNetworkStatusChange: true,
  })
  const subAccount = detailsData?.business?.bankAccount?.subAccount

  const nameExists =
    newName !== subAccount?.name &&
    subAccountNamesData?.business?.bankAccount?.subAccounts.find(
      (subAccount) => subAccount.name.toLowerCase() === newName.trim().toLowerCase(),
    )

  const [renameSubAccount, { loading: renameSubAccountLoading }] = useRenameSubAccountMutation({
    onError: () => setHasError(true),
    variables: { businessId, subAccountId, name: newName },
    onCompleted: () => {
      pollWithMaxRetries([
        { query: SUB_ACCOUNTS_QUERY, variables: { businessId } },
        { query: SUB_ACCOUNT_DETAILS, variables: { businessId, subAccountId } },
      ])
    },
  })

  return (
    <>
      <DeleteSubAccountModal
        isOpen={deleteModalOpen}
        closeOverlay={() => setDeleteModalOpen(false)}
        subAccountId={subAccountId}
      />
      <BaseLayout>
        <FormBody
          errorMessage={hasError ? t("errors.generic") : ""}
          title={t("accounts.edit.title")}
          buttonContainerSx={{ justifyContent: "space-between", width: "100%" }}
          buttons={[
            {
              children: t("buttons.done"),
              type: ButtonTypeEnum.PRIMARY_BLUE,
              onClick: () => history.goBack(),
            },
            ...(subAccountId
              ? [
                  <DeleteButton
                    key="delete"
                    onClick={() => {
                      setDeleteModalOpen(true)
                      Analytics.track(events.accounts.edit.delete)
                    }}
                  />,
                ]
              : []),
          ]}
        >
          <QueryResults
            retry={() => {
              refetchSubAccountNames()
              refetchDetails()
            }}
            loading={detailsLoading || subAccountNamesLoading}
            error={detailsError || subAccountNamesError}
            size="medium"
            listShimmerCount={3}
          >
            <Box sx={{ borderBottom: `1px solid ${theme.colors.ui5}`, paddingBottom: 30 }}>
              <TextInput
                label={t("accounts.edit.nameLabel")}
                placeholder={""}
                onChange={setNewName}
                value={newName}
                errorMessage={nameExists ? t("accounts.createSubAccount.name.exists") : ""}
              />
              <Button
                sx={{ marginTop: 20 }}
                disabled={subAccount?.name === newName || !newName}
                isLoading={renameSubAccountLoading}
                onClick={() => {
                  setHasError(false)
                  renameSubAccount()
                }}
              >
                {t("accounts.edit.button")}
              </Button>
            </Box>
            <Text sx={{ marginTop: 12 }} tag="h4">
              {t("accounts.edit.rulesTitleFR")}
            </Text>
            <Text sx={{ marginY: 12 }} tag="body" textColor={theme.colors.ui2}>
              {t("accounts.edit.rulesSubtitleFR")}
            </Text>
            <SubAccountPercentRuleItem
              subAccountId={subAccount?.id || ""}
              percentRule={subAccount?.percentRule || null}
            />
          </QueryResults>
        </FormBody>
      </BaseLayout>
    </>
  )
}
