import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { formatMoney } from "accounting"
import { Box, Image } from "rebass"

import { FlexColumnLayout, FlexRowLayout } from "@northone/ui"

import { images } from "@assets/images/images"
import { useAppSelector } from "@core/redux/utils"
import { selectActiveBusinessID } from "@core/active-business/redux/selectors"
import { Button, ButtonTypeEnum } from "@components/primitive/button/button"
import { Pill } from "@components/primitive/pill/pill"
import { Text } from "@components/primitive/text/text"
import {
  Maybe,
  InvoiceAccountPayablePaymentMethod,
  InvoiceStatus,
  InvoiceTransactionStatus,
} from "@generated/graphql"
import { theme } from "@layouts/theme"
import { getDateWithSpecificFormat } from "@utils/dates"
import { pollWithMaxRetries } from "@utils/poll-with-max-retries"
import { EditInvoiceScheduledTransaction } from "./edit-invoice-scheduled-transaction"
import { GET_INVOICE } from "./operations.gql"
import { Link } from "react-router-dom"
import { RoutesEnum } from "@routers/types"
import { dashboardActions } from "@features/dashboard/redux/actions"
import { useDispatch } from "react-redux"
import { Analytics } from "@core/analytics/actions"
import { events } from "@core/analytics/events"
import {
  TInvoiceAccountsPayable,
  TInvoiceAccountsPayableTransaction,
} from "@features/invoice-payments/redux/reducer"

interface IInvoiceTransactionsProps {
  invoice: TInvoiceAccountsPayable
  refetchInvoice: () => Promise<void>
  handlePayRemainingBalanceClick: () => void
}

const EmptyView = () => {
  const { t } = useTranslation()

  return (
    <Box
      sx={{
        width: "432px",
        height: "218px",
        padding: "40px 100px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: theme.colors.ui6,
      }}
    >
      <Text
        tag="body-small"
        sx={{
          textAlign: "center",
          color: theme.colors.ui2,
        }}
      >
        {t("invoicePayments.invoicePanel.payments.noPaymentsMessage")}
      </Text>
    </Box>
  )
}

const InvoiceTransactionStatusPill = ({ status }: { status: InvoiceTransactionStatus }) => {
  const { t } = useTranslation()

  let label = ""
  let backgroundColor = ""

  switch (status) {
    case InvoiceTransactionStatus.SCHEDULED:
      label = t("invoicePayments.invoicePanel.payments.scheduled")
      backgroundColor = theme.colors.greenTint3
      break
    case InvoiceTransactionStatus.INITIATED:
      label = t("invoicePayments.invoicePanel.payments.processing")
      backgroundColor = theme.colors.blackTint3
      break
    case InvoiceTransactionStatus.FAILED:
      label = t("invoicePayments.invoicePanel.payments.paymentFailed")
      backgroundColor = theme.colors.errorTint
      break
    default:
      break
  }

  if (
    ![
      InvoiceTransactionStatus.SCHEDULED,
      InvoiceTransactionStatus.INITIATED,
      InvoiceTransactionStatus.FAILED,
    ].includes(status)
  )
    return null

  return (
    <FlexColumnLayout>
      <Pill
        sx={{
          height: "20px",
          marginRight: "8px",
          borderRadius: "24px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          padding: "2px 8px",
          gap: "2px",
          backgroundColor,
        }}
      >
        <Text
          tag="h5"
          textColor={theme.colors.ui1}
          sx={{
            fontSize: "8px",
          }}
        >
          {label.toUpperCase()}
        </Text>
      </Pill>
    </FlexColumnLayout>
  )
}

const InvoiceTransaction = ({
  transaction,
  setActiveScheduledTransaction,
}: {
  transaction: TInvoiceAccountsPayableTransaction
  setActiveScheduledTransaction: (transaction: TInvoiceAccountsPayableTransaction | null) => void
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { transactionId, scheduledDate, amount, status, lastUpdated } = transaction

  const showViewTransactionLink = !!transactionId
  const showEditScheduledPaymentLink = status === InvoiceTransactionStatus.SCHEDULED

  return (
    <FlexRowLayout
      sx={{
        minHeight: "74px",
        paddingTop: "16px",
        paddingBottom: "16px",
        borderBottom: `1px solid ${theme.colors.ui5}`,
      }}
    >
      <FlexColumnLayout
        sx={{
          padding: 0,
          width: "40%",
          textAlign: "left",
        }}
      >
        <Text tag="body-large-bold">
          {getDateWithSpecificFormat(scheduledDate ?? lastUpdated, "LLL d, yyyy")}
        </Text>

        {showViewTransactionLink && (
          <Link
            to={RoutesEnum.DASHBOARD}
            onClick={() => {
              dispatch(dashboardActions.setActiveTransactionId(transactionId))
            }}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              textDecoration: "none",
            }}
          >
            <Text tag="body-small" textColor={theme.colors.ui1}>
              {t("invoicePayments.invoicePanel.payments.viewTransaction")}
            </Text>
            <Image
              src={images.icons.arrowRightBlack}
              height={16}
              width={16}
              sx={{
                marginLeft: "5px",
              }}
            />
          </Link>
        )}

        {showEditScheduledPaymentLink && (
          <Button
            textTag="body-large"
            type={ButtonTypeEnum.TERTIARY_SHORT}
            onClick={() => {
              Analytics.track(events.invoicePayments.invoiceQuickview.editScheduledPaymentClick)
              setActiveScheduledTransaction(transaction)
            }}
            sx={{
              padding: 0,
              border: "none",
              background: "none",
              ":hover": {
                background: "none",
              },
              ":active": {
                background: "none",
              },
            }}
            textSx={{
              fontSize: "12px",
              fontWeight: "normal",
              textTransform: "none",
              lineHeight: "17px",
              letterSpacing: "0.1px",
              color: theme.colors.ui1,
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            {t("invoicePayments.invoicePanel.payments.editScheduledPayment")}

            <Image
              src={images.icons.editBlack}
              height={16}
              width={16}
              sx={{
                marginLeft: "5px",
              }}
            />
          </Button>
        )}
      </FlexColumnLayout>

      <FlexColumnLayout
        sx={{
          padding: 0,
          width: "60%",
          textAlign: "right",
        }}
      >
        <Text
          tag="body-large-bold"
          sx={{
            color:
              status === InvoiceTransactionStatus.SCHEDULED ? theme.colors.ui2 : theme.colors.ui1,
          }}
        >
          {formatMoney(amount)}
        </Text>

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <FlexRowLayout>
            <InvoiceTransactionStatusPill status={status} />
            <FlexColumnLayout>
              <Text
                tag="body-small"
                sx={{
                  color: theme.colors.ui2,
                }}
              >
                {transaction.paymentMethod === InvoiceAccountPayablePaymentMethod.ACH &&
                  t("invoicePayments.invoicePanel.paymentTypes.ach")}
                {transaction.paymentMethod === InvoiceAccountPayablePaymentMethod.WIRE &&
                  t("invoicePayments.invoicePanel.paymentTypes.wire")}
                {transaction.paymentMethod === InvoiceAccountPayablePaymentMethod.MAILED_CHECK &&
                  t("invoicePayments.invoicePanel.paymentTypes.mailedCheck")}
                {transaction.paymentMethod === InvoiceAccountPayablePaymentMethod.BILL_PAY &&
                  t("invoicePayments.invoicePanel.paymentTypes.billPay")}
              </Text>
            </FlexColumnLayout>
          </FlexRowLayout>
        </Box>
      </FlexColumnLayout>
    </FlexRowLayout>
  )
}

export const InvoiceTransactions = ({
  invoice,
  refetchInvoice,
  handlePayRemainingBalanceClick,
}: IInvoiceTransactionsProps) => {
  const { t } = useTranslation()

  const businessId = useAppSelector(selectActiveBusinessID)

  const [activeScheduledTransaction, setActiveScheduledTransaction] =
    useState<TInvoiceAccountsPayableTransaction | null>()

  const { remainingAmount, status, transactions } = invoice

  if (activeScheduledTransaction) {
    const { invoiceId } = activeScheduledTransaction

    const onCompleted = async () => {
      await pollWithMaxRetries([
        {
          query: GET_INVOICE,
          variables: {
            businessId,
            invoiceId,
          },
        },
      ])

      await refetchInvoice()

      setActiveScheduledTransaction(null)
    }

    return (
      <EditInvoiceScheduledTransaction
        invoice={invoice}
        transaction={activeScheduledTransaction}
        setActiveScheduledTransaction={setActiveScheduledTransaction}
        onCompleted={onCompleted}
      />
    )
  }

  const activeTransactions = (transactions ?? [])
    .filter((transaction: Maybe<TInvoiceAccountsPayableTransaction>) => {
      return transaction?.status !== InvoiceTransactionStatus.CANCELED
    })
    .map((transaction: Maybe<TInvoiceAccountsPayableTransaction>, index: number) => {
      return transaction ? (
        <InvoiceTransaction
          key={index}
          transaction={transaction}
          setActiveScheduledTransaction={setActiveScheduledTransaction}
        />
      ) : null
    })

  if (!activeTransactions?.length) {
    return <EmptyView />
  }

  return (
    <>
      {activeTransactions}

      {status === InvoiceStatus.UNPAID && remainingAmount > 0 && (
        <Button
          type={ButtonTypeEnum.TERTIARY}
          iconSrc={images.icons.arrowRightBlack}
          iconOnRight
          sx={{
            width: "100%",
            height: "50px",
            display: "flex",
            justifyContent: "flex-start",
          }}
          textTag="body-small"
          iconSx={{
            width: "16px",
            height: "16px",
          }}
          onClick={handlePayRemainingBalanceClick}
        >
          {t("invoicePayments.invoicePanel.payments.payRemainingBalance", {
            remainingAmount: formatMoney(remainingAmount),
          })}
        </Button>
      )}
    </>
  )
}
