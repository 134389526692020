import React from "react"
import { useDispatch } from "react-redux"
import { selectActiveBusinessID } from "@core/active-business/redux/selectors"
import { Analytics } from "@core/analytics/actions"
import { events } from "@core/analytics/events"
import { useAppSelector } from "@core/redux/utils"
import { QueryResults } from "@components/composite/query-results/query-results"
import { GenericRecipientListItem } from "@components/extended/list-items/generic-recipient/generic-recipient-list-item"
import { moveMoneyActions } from "@features/move-money/redux/actions"
import { selectRecipient } from "@features/move-money/redux/selectors"
import { gqlWireRecipientsToRedux } from "./utils"
import { Contact, ContactPaymentType, useRecipientsForDomesticWireQuery } from "@generated/graphql"

export const DomesticWireRecipients: React.FC = () => {
  const dispatch = useDispatch()
  const businessId = useAppSelector(selectActiveBusinessID)
  const activeRecipient = useAppSelector(selectRecipient)

  const { data, error, loading, refetch } = useRecipientsForDomesticWireQuery({
    variables: { businessId, contactPaymentType: ContactPaymentType.WIRE },
  })

  const clickHandler = (recipient: Contact) => {
    dispatch(moveMoneyActions.setRecipient(gqlWireRecipientsToRedux(recipient)))
    dispatch(moveMoneyActions.setEditingRecipient(gqlWireRecipientsToRedux(recipient)))
    Analytics.track(events.settings.recipient.editWireRecipient)
  }

  return (
    <QueryResults
      listShimmerCount={5}
      size="medium"
      error={error}
      loading={loading}
      retry={() => refetch()}
    >
      {data?.business?.contacts.map((recipient) => (
        <GenericRecipientListItem
          name={recipient.name}
          key={recipient.id}
          onClick={() => clickHandler(recipient)}
          isActive={activeRecipient?.id === recipient.id}
        />
      ))}
    </QueryResults>
  )
}
