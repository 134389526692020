import React from "react"
import { useTranslation } from "react-i18next"

import { theme } from "@layouts/theme"
import { Text } from "@northone/ui"
import { TransactionPanelItem } from "./base-transaction-panel"

export const TransactionPanelCancelPayment = () => {
  const { t } = useTranslation()

  return (
    <TransactionPanelItem label={t("dashboard.transactionPanel.pending.label")}>
      <Text tag="body-bold" textColor={theme.colors.ui2}>
        {t("dashboard.transactionPanel.pending.title")}
      </Text>
      <Text tag="body-small" textColor={theme.colors.ui3}>
        {t("dashboard.transactionPanel.pending.subtitle")}
      </Text>
    </TransactionPanelItem>
  )
}
