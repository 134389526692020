import React from "react"
import { SubscriptionsLayout } from "./components/subscriptions-layout"
import { FlexColumnLayout, FlexRowLayout } from "@northone/ui"
import { PlanDetailsBox } from "./components/plan-details-box/plan-details-box"
import { isStandardPlan } from "../utils"
import { SubscriptionsLandingFooter } from "./components/landing-footer"
import { SubscriptionsLandingError } from "./components/subscriptions-landing-error"
import { SubscriptionsAccountDetails } from "./subscriptions-account-details"
import { useSubscriptionsData } from "../shared/hooks/use-subscriptions-data"

export const SubscriptionsLanding = () => (
  <SubscriptionsLayout>
    <SubscriptionsContent />
  </SubscriptionsLayout>
)

const SubscriptionsContent = () => {
  const { loading, error, refetch, plans, terms, subscription } = useSubscriptionsData()
  const isMissingData = !plans?.length || !subscription || !terms || error
  if (loading) {
    //TO-DO: Loading state
    return null
  }
  if (isMissingData) {
    return <SubscriptionsLandingError refetch={() => refetch?.()} />
  }
  const hasPaidSubscription = Boolean(subscription.plan && !isStandardPlan(subscription.plan.name))

  return (
    <FlexColumnLayout sx={{ margin: "auto" }}>
      <FlexRowLayout sx={{ justifyContent: "center", columnGap: "24px" }}>
        {plans.map((plan) => {
          return (
            plan && (
              <PlanDetailsBox key={plan.name} planContent={plan} subscription={subscription} />
            )
          )
        })}
      </FlexRowLayout>
      {hasPaidSubscription && <SubscriptionsAccountDetails subscription={subscription} />}
      <SubscriptionsLandingFooter terms={terms} />
    </FlexColumnLayout>
  )
}
