import React from "react"
import { FlexColumnLayout, ButtonTypeEnum, theme, Button } from "@northone/ui"
import { PropsWithChildren } from "react"
import { useTranslation } from "react-i18next"
import { SxStyleProp, Box } from "rebass"
import { Text } from "@components/primitive/text/text"
import { RevenueHubPlatformInfo } from "../../platforms"
import { useGetRevenueHubConnectionLinkUpdateUrlQuery } from "@generated/graphql"
import { useAppSelector } from "@core/redux/utils"
import { selectActiveBusinessID } from "@core/active-business/redux/selectors"
import { ConnectionLoading } from "./loading"

interface IErrorView {
  headingText: string
  button?: {
    onClick: () => void
    text: string
  }
}

const ConnectionErrorLayout = ({
  headingText,
  button,
  children,
}: PropsWithChildren<IErrorView>) => {
  return (
    <FlexColumnLayout sx={errorViewStyles.container}>
      <Text tag="body-large-bold" sx={errorViewStyles.header}>
        {headingText}
      </Text>
      <Box sx={errorViewStyles.children}>{children}</Box>
      <Box sx={errorViewStyles.ctaContainer}>
        {button && (
          <Button
            onClick={button.onClick}
            type={ButtonTypeEnum.PRIMARY_BLUE}
            sx={errorViewStyles.cta}
          >
            {button.text}
          </Button>
        )}
      </Box>
    </FlexColumnLayout>
  )
}

export const ConnectionGenericError = ({
  onButtonClick,
  platformInfo,
}: {
  onButtonClick: () => void
  platformInfo: RevenueHubPlatformInfo
}) => {
  const { t } = useTranslation()
  return (
    <ConnectionErrorLayout
      headingText={t("revenueHub.platformActive.error.generic.header")}
      button={{
        text: t("revenueHub.platformActive.error.generic.cta"),
        onClick: onButtonClick,
      }}
    >
      <Text tag="body-small" sx={errorViewStyles.header}>
        {t("revenueHub.platformActive.error.generic.subtitle", {
          platformName: platformInfo.name,
        })}
      </Text>
    </ConnectionErrorLayout>
  )
}

export const ConnectionReAuth = ({
  platformInfo,
  connectionId,
}: {
  platformInfo: RevenueHubPlatformInfo
  connectionId: string
}) => {
  const { t } = useTranslation()
  const businessId = useAppSelector(selectActiveBusinessID)
  const { data, loading, refetch, error } = useGetRevenueHubConnectionLinkUpdateUrlQuery({
    variables: { businessId, connectionId },
  })
  const linkUpdateUrl = data?.revenueHub.connection.linkUpdateUrl
  const onClick = () => window.open(linkUpdateUrl)

  if (error)
    return <ConnectionGenericError onButtonClick={() => refetch()} platformInfo={platformInfo} />

  if (loading) return <ConnectionLoading />

  return (
    <ConnectionErrorLayout
      headingText={t("revenueHub.platformActive.error.reAuth.header")}
      button={{
        text: t("revenueHub.platformActive.error.reAuth.cta"),
        onClick,
      }}
    >
      <Text tag="body-small" sx={errorViewStyles.header}>
        {t("revenueHub.platformActive.error.reAuth.subtitle", {
          platformName: platformInfo.name,
        })}
      </Text>
    </ConnectionErrorLayout>
  )
}

export const ConnectionDisabled = ({
  platformInfo,
  connectionId,
}: {
  platformInfo: RevenueHubPlatformInfo
  connectionId: string
}) => {
  const { t } = useTranslation()
  const businessId = useAppSelector(selectActiveBusinessID)
  const { data, loading, error, refetch } = useGetRevenueHubConnectionLinkUpdateUrlQuery({
    variables: { businessId, connectionId },
  })
  const linkUpdateUrl = data?.revenueHub.connection.linkUpdateUrl
  const onClick = () => window.open(linkUpdateUrl)

  if (error)
    return <ConnectionGenericError onButtonClick={() => refetch()} platformInfo={platformInfo} />

  if (loading) return <ConnectionLoading />

  return (
    <ConnectionErrorLayout
      headingText={t("revenueHub.platformActive.error.disabled.header")}
      button={{
        text: t("revenueHub.platformActive.error.disabled.cta"),
        onClick,
      }}
    >
      <Text
        tag={"body"}
        sx={{ textAlign: "center" }}
        textWithEmbeddedLinksProps={{
          text: t("revenueHub.platformActive.error.disabled.subtitle", {
            platformName: platformInfo.name,
          }),
          linksInOrder: [platformInfo.siteUrl],
        }}
      />
    </ConnectionErrorLayout>
  )
}

export const ConnectionNotReady = () => {
  const { t } = useTranslation()
  return (
    <ConnectionErrorLayout headingText={t("revenueHub.platformActive.error.notReady.header")}>
      <Text tag="body-small" sx={errorViewStyles.header}>
        {t("revenueHub.platformActive.error.notReady.subtitle")}
      </Text>
    </ConnectionErrorLayout>
  )
}

const errorViewStyles: Record<string, SxStyleProp> = {
  container: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    bg: theme.colors.ui6,
    marginTop: "auto",
    padding: 24,
  },
  header: {
    textAlign: "center",
    marginBottom: 16,
  },
  children: {
    marginBottom: 16,
  },
}
