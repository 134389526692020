import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import { selectActiveBusinessID } from "@core/active-business/redux/selectors"
import { useAppSelector } from "@core/redux/utils"
import { ButtonTypeEnum } from "@components/primitive/button/button"
import { images } from "@assets/images/images"
import { useRevenueHubConnectionDeleteMutation } from "@generated/graphql"
import { LIST_REVENUE_HUB_CONNECTIONS } from "../operations.gql"
import { theme } from "@layouts/theme"
import { Modal } from "@components/extended/overlay/modal"
import { InfoBlock } from "@components/composite/info-block/info-block"
import { NoticeBox } from "@components/primitive/notice-box/notice-box"
import { Text } from "@components/primitive/text/text"
import { Analytics } from "@core/analytics/actions"
import { events } from "@core/analytics/events"
import { ImageButton } from "@components/extended/image/image-button"

interface RevenueHubDisconnectPlatformProps {
  connectionId: string
  platformName: string
}

export const RevenueHubDisconnectPlatform = ({
  connectionId,
  platformName,
}: RevenueHubDisconnectPlatformProps) => {
  const [isDisconnectModalOpen, setIsDisconnectModalOpen] = useState(false)

  return (
    <>
      <ImageButton onClick={() => setIsDisconnectModalOpen(true)} imgSrc={images.icons.greyTrash} />
      <RevenueHubDisconnectPlatformModal
        connectionId={connectionId}
        platformName={platformName}
        isOpen={isDisconnectModalOpen}
        closeModal={() => setIsDisconnectModalOpen(false)}
      />
    </>
  )
}

interface RevenueHubDisconnectPlatformModalProps {
  isOpen: boolean
  platformName: string
  closeModal: () => void
  connectionId: string
}

export const RevenueHubDisconnectPlatformModal = ({
  connectionId,
  platformName,
  isOpen,
  closeModal,
}: RevenueHubDisconnectPlatformModalProps) => {
  const { t } = useTranslation()
  const businessId = useAppSelector(selectActiveBusinessID)

  const [revenueHubConnectionDelete, { loading: isMutationLoading, error: connectionDeleteError }] =
    useRevenueHubConnectionDeleteMutation({
      refetchQueries: [LIST_REVENUE_HUB_CONNECTIONS],
    })

  const handleDisconnect = async () => {
    Analytics.track(events.revenueHub.deleteConnection, { businessId, platformName })
    await revenueHubConnectionDelete({
      variables: {
        businessId,
        connectionId,
      },
    })
    closeModal()
  }

  const disconnectButton = {
    type: ButtonTypeEnum.SECONDARY,
    children: t("revenueHub.disconnectPlatformModal.secondaryCta"),
    onClick: () => {
      handleDisconnect()
    },
    isLoading: isMutationLoading,
  }

  const keepConnectionButton = {
    type: ButtonTypeEnum.PRIMARY_BLUE,
    children: t("revenueHub.disconnectPlatformModal.primaryCta"),
    onClick: () => {
      closeModal()
    },
  }

  return (
    <Modal
      isOpen={isOpen}
      zIndex={theme.zIndex.dialogModal}
      onOutsideClick={closeModal}
      sx={{ maxWidth: "500px" }}
    >
      {Boolean(connectionDeleteError) && (
        <NoticeBox
          level="error"
          containerSx={{
            border: 0,
            width: "95%",
          }}
          content={
            <Text
              tag={"body-small"}
              textWithEmbeddedLinksProps={{
                text: t("revenueHub.disconnectPlatformModal.errorMessage"),
                linksInOrder: ['javascript:window.Intercom("show")'],
                linkStyle: { color: theme.colors.ui1 },
              }}
            />
          }
        />
      )}
      <InfoBlock
        title={t("revenueHub.disconnectPlatformModal.title", {
          platformName,
        })}
        subtitle={t("revenueHub.disconnectPlatformModal.subtitle")}
        buttons={[disconnectButton, keepConnectionButton]}
      />
    </Modal>
  )
}
