export const transactions = {
  types: {
    ACH: "ACH payment",
    ACH_RETURN: "Returned ACH",
    BILL_PAYMENT: "Digital Bill Payment",
    BILL_PAYMENT_REFUND: "Digital Bill Refund",
    CARD_PURCHASE: "Card purchase",
    CHECK_DEPOSIT: "Check deposit",
    DOMESTIC_WIRE: "Wire payment",
    RETURNED_WIRE: "Returned wire",
    envelopeAdjustment: "Envelope adjustment",
    LINKED_ACCOUNT: "External transfer",
    FEE: "Fee",
    WIRE_REVERSAL: "Refunded wire",
    FEE_REVERSAL: "Refunded fee",
    MAILED_CHECK: "Check payment",
    REAL_TIME_PAYMENT: "Real Time Payment",
    incoming: "Incoming",
    incomingWire: "Incoming wire",
    outgoing: "Outgoing",
    envelope: "Envelope",
    paymentDeclined: "Payment declined",
    MAILED_CHECK_REFUNDED: "Check refund",
    LENDING: "Lending",
  },
  status: {
    pending: "Pending",
    inReview: "In review",
    mailedCheckCreated: "On its way",
    refunded: "Refunded",
    canceled: "Canceled",
    failed: "Failed",
    expired: "Expired",
    mailedCheckPosted: "Deposited",
  },
  transferCounterpartyDirection: {
    to: "To",
    from: "From",
  },
  linkedAccountTitle: "Transfer to external account",
  expectedDuration: {
    ACH: "2 to 3 business days",
    BILL_PAYMENT: "Varies",
    CARD_PURCHASE: "1 business day",
    CHECK_DEPOSIT: "10 business days",
    EXTERNAL_TRANSFER: "5 t0 8 business days",
    DOMESTIC_WIRE: "1 business day",
    GENERIC: "1 business day",
    INTERNATIONAL_WIRE: "2 to 3 business days",
    SUB_ACCOUNT_TRANSFER: "Immediately",
    ACH_PROCESSING:
      "ACH transfers are processed on the next business day by 10:00 am PT/1:00 pm ET and arrive one or two business days later.",
  },
  fees: {
    fee: "fee",
    free: "No cost",
  },
  disclaimer: {
    DOMESTIC_WIRE: "*Fee for sending a domestic wire",
  },
  previewPanel: {
    title: "Transaction Preview",
    recipient: "Recipient",
    payee: "Payee",
    description: "Description",
    account: "Account",
    unspecified: "Unspecified",
    schedule: "Schedule",
    frequency: "Frequency",
    scheduleDetailNoSchedule: "Once",
    scheduleDetailNoEndDate: "{{scheduleFrequency}} — forever",
    scheduleDetailWithEndDate: "{{scheduleFrequency}} — until {{endDate}}",
    edit: "Edit",
    sendPayment: "Send payment",
    sendWirePayment: "Agree and send payment",
    discard: "Discard",
    fromAccount: "From",
    toAccount: "To",
    confirm: "Confirm",
    amount: "Amount",
    yourPlan: "Your plan",
  },
  share: {
    amount: "Amount: ",
    date: "Date: ",
    type: "Type: ",
    notes: "Notes: ",
    merchant: "Description: ",
    id: "TransactionID: ",
    toolTipText: "Copied to clipboard",
  },
  history: {
    banner: "See **{{type}}** from before March 2021",
    modalTitle: "Export your data from before March, 2021",
    modalSubtitle: "We’ve saved your old account information. You can download it anytime.",
    listItem1: "Historical Transactions (CSV & QBO)",
    listItem2: "Account Statements (PDFs)",
    listItem3: "Billing Statements (PDFs)",
    requestData: "Request your data",
    done: "Done",
    checkEmail: "Check your email!",
    emailSent: "Your historical data has been sent to {{email}}",
  },
  featureRemoval: {
    billPayTitle: "We’re improving how we do bill payments",
    mailedCheckTitle: "We’re improving how we do check payments",
    externalTransferTitle: "We’re improving how we send external transfers",
    wireTitle: "We’re improving how we do wire payments",
    checkDeposits: "We're improving how we do check deposits",
  },
  listItem: {
    incomingWire: "Incoming Wire",
    holdTime: "Subject to {{holdTime}}-Day Hold",
    returned: "Returned",
    returnedBy: "Returned by {{returnedBy}}",
    returnedByReceivingBank: "Returned by receiving bank",
  },
  returnedACHNote:
    "Your ACH payment was returned. Reach out for more information. \n\n**Talk to Customer Care**",
  declinedWireNote:
    "Your wire payment was declined and refunded. Reach out for more information.\n\n**Talk to Customer Care**",
  returnedWireNote:
    // TODO: Re-implement {{returnedBy}} in copy [NOR-1282]
    "Receiving bank returned your wire payment and may have charged a return fee. \n\n**Talk to Customer Care**",
}
