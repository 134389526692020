import React from "react"
import { Box, Image } from "rebass"

import { images } from "../assets/images/images"
import { WithMaxWidth } from "./max-width"
import { Grid } from "./grid"
import { FlexColumnLayout } from "./flex"
import { useWindowDimensions } from "../utils/hooks"
import UserActionDropdown from "../features/auth-wrapper/user-action-dropdown"
import { theme } from "./theme"

interface OnboardingLayoutProps {
  children?: React.ReactNode
}

export const OnboardingLayout = ({ children }: OnboardingLayoutProps) => {
  const { width, height } = useWindowDimensions()

  return (
    <FlexColumnLayout
      sx={{
        height,
        justifyContent: [undefined, "center"],
        position: "relative",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          backgroundColor: theme.colors.gold10,
          backgroundPosition: `calc(100%) calc(100% - ${height / 3}px)`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          height,
          width,
          justifyContent: "center",
          zIndex: -1,
          transform: "rotate(15deg) scale(1.5)",
        }}
      />
      <WithMaxWidth
        sx={{
          display: "flex",
          flexDirection: "column",
          width: ["auto", "100%"],
          marginTop: "20px",
        }}
      >
        <Grid>
          <Box
            sx={{
              gridColumn: ["6/8", "3/8"],
              alignItems: "center",
              display: "flex",
            }}
          >
            <Image src={images.logo} sx={{ display: ["none", "inherit"] }} />
            <Image src={images.northoneIcon} sx={{ display: ["inherit", "none"] }} />
          </Box>
          <UserActionDropdown />
          <FlexColumnLayout
            sx={{
              paddingTop: ["25px", "20px"],
              gridColumn: "3/11",
              height: "70vh",
              overflowY: ["auto", undefined],
              justifyContent: "center",
            }}
          >
            {children}
          </FlexColumnLayout>
        </Grid>
      </WithMaxWidth>
    </FlexColumnLayout>
  )
}
