import React from "react"
import { Button } from "@components/primitive/button/button"
import { useTranslation } from "react-i18next"
import { images } from "@assets/images/images"
import { theme } from "@layouts/theme"
import { logout } from "@core/auth/auth-service"
import { SxStyleProp } from "rebass"
import { NAV_BAR_IDS } from "./nav-bar"
import { Analytics } from "@core/analytics/actions"
import { events } from "@core/analytics/events"
/**
 * Extends the primitive Button component. Only used in the nav bar.
 */
interface ILogOutButtonProps {
  onClick?: () => void
  buttonSx?: SxStyleProp
}

export const LogoutButton: React.FC<ILogOutButtonProps> = ({ onClick, buttonSx }) => {
  const { t } = useTranslation()
  return (
    <Button
      id={NAV_BAR_IDS.logOutButton}
      textTag="body"
      style={{
        enabledStyle: {
          backgroundColor: "transparent",
          borderColor: "transparent",
          textColor: theme.colors.ui2,
          ":hover": { backgroundColor: theme.colors.ui6 },
          ":active": { backgroundColor: theme.colors.ui5 },
          ...buttonSx,
        },
        disabledStyle: {
          opacity: 0,
          textColor: "transparent",
        },
      }}
      iconSrc={images.icons.logout}
      onClick={
        onClick
          ? onClick
          : () => {
              Analytics.track(events.logout)
              logout()
            }
      }
    >
      {t("logout.button")}
    </Button>
  )
}
