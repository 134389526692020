import gql from "graphql-tag"

export const GET_SHARE_AND_EARN_CAMPAIGN_DETAILS = gql`
  query getShareAndEarnCampaign {
    allShare_earns {
      edges {
        node {
          offer_details {
            incentive_amount
          }
        }
      }
    }
  }
`
