import React from "react"
import { Text } from "@northone/ui"
import { theme } from "@layouts/theme"

interface IDisclaimerNoteProps {
  text: string
}

export const DisclaimerNote: React.FC<IDisclaimerNoteProps> = ({ text }) => (
  <Text sx={{ color: theme.colors.ui2 }} tag="body-x-small">
    {text}
  </Text>
)
