import { Button, IButtonProps } from "@components/primitive/button/button"
import React from "react"

type TBannerButtonProps = IButtonProps & {
  type: Pick<IButtonProps, "type">
  text: string
  onClick: Pick<IButtonProps, "onClick">
}

export const BannerButton = ({ type, text, onClick }: TBannerButtonProps) => (
  <Button
    type={type}
    onClick={onClick}
    sx={{ padding: "8px", height: "26px" }}
    textSx={{
      fontSize: "10px",
      lineHeight: "11px",
      textTransform: "uppercase",
      letterSpacing: "1px",
    }}
  >
    {text}
  </Button>
)
