import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Analytics } from "@core/analytics/actions"
import { events } from "@core/analytics/events"
import { Text } from "@components/primitive/text/text"
import { Button } from "@components/primitive/button/button"
import { QueryResults } from "@components/composite/query-results/query-results"
import { obscureEmail } from "@utils/format"
import { BaseLayout } from "@layouts/base-layout"
import { FlexColumnLayout } from "@layouts/flex"
import { theme } from "@layouts/theme"
import { useOnboardingMeQuery } from "@generated/graphql"

export const handleSupportButtonClick = (
  isIntercomOpen: boolean,
  setIsIntercomOpen: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  if (isIntercomOpen) {
    setIsIntercomOpen(!isIntercomOpen)
    window.Intercom("hide")
  } else {
    setIsIntercomOpen(!isIntercomOpen)
    window.Intercom("show")
  }
}

export const ContactInformationSettings: React.FC = () => {
  const { t } = useTranslation()
  const [isIntercomOpen, setIsIntercomOpen] = useState(false)

  const { data, loading, error, refetch } = useOnboardingMeQuery({
    notifyOnNetworkStatusChange: true,
  })

  const obscuredEmail = obscureEmail(data?.me?.email)
  const obscuredPhone = `+1-***-***-${data?.me?.phone?.substring(data?.me?.phone?.length - 4)}`

  return (
    <BaseLayout pageTitle={t("pageTitles.settings.contactInfo")}>
      <Text tag="h2" sx={{ marginBottom: 36 }}>
        {t("settings.pages.contactInformation.title")}
      </Text>
      <QueryResults
        size="medium"
        retry={() => refetch()}
        loading={loading}
        error={error}
        listShimmerCount={3}
      >
        <FlexColumnLayout>
          <Text tag="h5" sx={{ paddingBottom: 12 }} textColor={theme.colors.ui2}>
            {t("settings.pages.contactInformation.emailAddressLabel")}
          </Text>
          <Text tag="body" textColor={theme.colors.ui1}>
            {obscuredEmail}
          </Text>
          <Text tag="h5" sx={{ paddingTop: 40, paddingBottom: 12 }} textColor={theme.colors.ui2}>
            {t("settings.pages.contactInformation.phoneNumberLabel")}
          </Text>
          <Text tag="body" textColor={theme.colors.ui1}>
            {obscuredPhone}
          </Text>
          <Text tag="body" sx={{ paddingTop: 40, paddingBottom: 22 }} textColor={theme.colors.ui2}>
            {t("settings.pages.contactInformation.contactSupportDetail")}
          </Text>
          <Button
            sx={{ alignSelf: "flex-start" }}
            onClick={() => {
              Analytics.track(events.settings.contactInformation.contactSupport)
              handleSupportButtonClick(isIntercomOpen, setIsIntercomOpen)
            }}
          >
            {t("settings.pages.contactInformation.contactSupportButton")}
          </Button>
        </FlexColumnLayout>
      </QueryResults>
    </BaseLayout>
  )
}
