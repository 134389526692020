import React, { useState } from "react"
import { Button, ButtonTypeEnum } from "@northone/ui"

import i18n from "@i18n/i18n"
import { images } from "@assets/images/images"
import { events } from "@core/analytics/events"
import { Analytics } from "@core/analytics/actions"

import { ChangePinModal } from "../manage-card/change-pin-modal"
import { FreezeCardModal } from "../manage-card/freeze-card-modal"
import { ReportCardLostModal } from "../manage-card/report-lost-modal"
import { ReportSecurityIssueModal } from "./report-security-issue-modal"
import { ViewCardNumbersModal } from "./view-card-numbers-modal"

enum ManageCardActionEnum {
  "FREEZE_UNFREEZE" = "FREEZE_UNFREEZE",
  "REPORT_LOST" = "REPORT_LOST",
  "CHANGE_PIN" = "CHANGE_PIN",
  "VIEW_CARD_NUMBERS" = "VIEW_CARD_NUMBERS",
  "REPORT_SECURITY_ISSUE" = "REPORT_SECURITY_ISSUE",
}

enum ManageCardDisplayEnum {
  "VIRTUAL" = "VIRTUAL",
  "PHYSICAL" = "PHYSICAL",
  "ALL" = "ALL",
  "NOT_FROZEN" = "NOT_FROZEN",
}

const manageCardButtons: {
  [key in ManageCardActionEnum]: {
    text: string
    icon?: string
    displayFor: ManageCardDisplayEnum
  }
} = {
  VIEW_CARD_NUMBERS: {
    text: i18n.t("cards.activeCard.viewCardNumbers"),
    displayFor: ManageCardDisplayEnum.VIRTUAL,
  },
  FREEZE_UNFREEZE: {
    text: i18n.t("cards.activeCard.freeze"),
    icon: images.icons.lock,
    displayFor: ManageCardDisplayEnum.NOT_FROZEN,
  },
  REPORT_LOST: {
    text: i18n.t("cards.activeCard.reportLost"),
    displayFor: ManageCardDisplayEnum.PHYSICAL,
  },
  CHANGE_PIN: {
    text: i18n.t("cards.activeCard.changePin"),
    displayFor: ManageCardDisplayEnum.PHYSICAL,
  },
  REPORT_SECURITY_ISSUE: {
    text: i18n.t("cards.activeCard.reportSecurityIssue"),
    displayFor: ManageCardDisplayEnum.VIRTUAL,
  },
}

const handleAnalyticsEvent = (isVirtualCard: boolean, actionType: string) => {
  switch (actionType) {
    case ManageCardActionEnum.VIEW_CARD_NUMBERS:
      Analytics.track(
        isVirtualCard
          ? events.cards.virtualCards.landing.viewCardNumbers
          : events.cards.landing.viewCardNumbers,
      )
      break

    case ManageCardActionEnum.FREEZE_UNFREEZE:
      !isVirtualCard && Analytics.track(events.cards.frozen.freeze)
      break

    case ManageCardActionEnum.REPORT_SECURITY_ISSUE:
      Analytics.track(
        isVirtualCard
          ? events.cards.virtualCards.landing.reportSecurityIssue
          : events.cards.landing.reportSecurityIssue,
      )
      break

    case ManageCardActionEnum.CHANGE_PIN:
      Analytics.track(events.cards.landing.changePin)
      break

    case ManageCardActionEnum.REPORT_LOST:
      Analytics.track(events.cards.landing.lost)
      break
  }
}

const shouldDisplayButtons = (isVirtualCard: boolean, isFrozen: boolean, displayFor: string) => {
  return (
    (isVirtualCard && displayFor === ManageCardDisplayEnum.VIRTUAL) ||
    (!isVirtualCard && displayFor === ManageCardDisplayEnum.PHYSICAL) ||
    (!isFrozen && displayFor === ManageCardDisplayEnum.NOT_FROZEN) ||
    displayFor === ManageCardDisplayEnum.ALL
  )
}

export const ManageCardButtons: React.FC<{
  isVirtualCard: boolean
  cardId: string
  isFrozen: boolean
}> = ({ isVirtualCard, cardId, isFrozen }) => {
  const [activeActionType, setActiveActionType] = useState<ManageCardActionEnum | null>(null)
  const closeModalHandler = () => setActiveActionType(null)

  return (
    <>
      <ChangePinModal
        isOpen={activeActionType === ManageCardActionEnum.CHANGE_PIN}
        closeModal={closeModalHandler}
      />
      <FreezeCardModal
        isVirtualCard={isVirtualCard}
        cardId={cardId}
        isOpen={activeActionType === ManageCardActionEnum.FREEZE_UNFREEZE}
        closeModal={closeModalHandler}
      />
      <ReportCardLostModal
        isOpen={activeActionType === ManageCardActionEnum.REPORT_LOST}
        closeModal={closeModalHandler}
      />
      <ReportSecurityIssueModal
        isOpen={activeActionType === ManageCardActionEnum.REPORT_SECURITY_ISSUE}
        closeModal={closeModalHandler}
      />
      <ViewCardNumbersModal
        cardId={cardId}
        isOpen={activeActionType === ManageCardActionEnum.VIEW_CARD_NUMBERS}
        closeModal={closeModalHandler}
      />

      {(Object.keys(manageCardButtons) as ManageCardActionEnum[]).map((actionType, idx) => {
        const { displayFor, text } = manageCardButtons[actionType]
        if (shouldDisplayButtons(isVirtualCard, isFrozen, displayFor)) {
          return (
            <Button
              key={idx}
              onClick={() => {
                handleAnalyticsEvent(isVirtualCard, actionType)
                setActiveActionType(actionType)
              }}
              sx={{ paddingX: "8px", marginRight: 10 }}
              type={ButtonTypeEnum.TERTIARY_SHORT}
              iconSrc={manageCardButtons[actionType].icon}
            >
              {text}
            </Button>
          )
        }
      })}
    </>
  )
}
