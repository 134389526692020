import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { motion } from "framer-motion"
import { useTranslation } from "react-i18next"
import { useAppSelector } from "@core/redux/utils"
import { selectActiveBusinessID } from "@core/active-business/redux/selectors"
import { events } from "@core/analytics/events"
import { Analytics } from "@core/analytics/actions"
import {
  PreviewPanelAnimation,
  PREVIEW_PANEL_TRANSITION,
} from "@components/animations/preview-panel/preview-panel-animation"
import { Button, ButtonTypeEnum } from "@components/primitive/button/button"
import { AnimatePresence } from "@components/animations/animate-presence/animation-presence"
import { NoticeBox } from "@components/primitive/notice-box/notice-box"
import { PreviewTransactionPanel } from "@components/composite/transaction-panel/preview-transaction-panel"
import { DeleteButton } from "@components/extended/buttons/delete-button"
import { accountsActions } from "@features/accounts/redux/actions"
import { SUB_ACCOUNTS_QUERY } from "@features/accounts/sub-accounts/operations.gql"
import {
  selectAccountsSubAccountTransfer,
  selectSubAccocuntTransferVariables,
} from "@features/accounts/redux/selectors"
import { FlexColumnLayout, FlexRowLayout } from "@layouts/flex"
import { pollWithMaxRetries, refreshTransactionLists } from "@utils/poll-with-max-retries"
import { useSubAccountTransferCreateMutation, TransactionTypeEnum } from "@generated/graphql"
import { TransferDiscardModal } from "../discard-modal/transfer-discard-modal"

export const SubAccountTransferConfirm: React.FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [hasError, setHasError] = useState(false)
  const [discardModalOpen, setDiscardModalOpen] = useState(false)
  const businessId = useAppSelector(selectActiveBusinessID)
  const { amount, destinationAccount, sourceAccount } = useAppSelector(
    selectAccountsSubAccountTransfer,
  )
  const variables = useAppSelector(selectSubAccocuntTransferVariables)
  const {
    data: { sourceSubAccountId, destinationSubAccountId },
  } = variables

  const [createTransfer, { loading }] = useSubAccountTransferCreateMutation({
    variables: variables,
    onCompleted: () => {
      refreshTransactionLists({ businessId, sourceSubAccountId, destinationSubAccountId })
      pollWithMaxRetries([{ query: SUB_ACCOUNTS_QUERY, variables: { businessId } }])
      dispatch(accountsActions.subAccountTransferNavigateNext("SUB_ACCOUNT_TRANSFER_SENT"))
    },
    onError: () => setHasError(true),
  })

  return (
    <>
      <TransferDiscardModal
        isOpen={discardModalOpen}
        closeModal={() => setDiscardModalOpen(false)}
      />
      <PreviewPanelAnimation>
        <PreviewTransactionPanel
          amount={amount}
          counterpartyName={destinationAccount.name}
          transactionType={TransactionTypeEnum.SUB_ACCOUNT_TRANSFER}
          subAccountName={sourceAccount.name}
          buttonText={t("transactions.previewPanel.edit")}
          buttonOnClick={() => dispatch(accountsActions.subAccountTransferNavigateBack())}
        />
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: PREVIEW_PANEL_TRANSITION }}
        >
          <FlexRowLayout
            sx={{
              width: "100%",
              paddingTop: 20,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FlexColumnLayout sx={{ paddingRight: 15, flexGrow: 1 }}>
              <Button
                isLoading={loading}
                type={ButtonTypeEnum.PRIMARY_BLUE}
                onClick={() => {
                  createTransfer()
                  Analytics.track(events.accounts.transfer.send)
                }}
                sx={{
                  width: "100%",
                }}
              >
                {t("transactions.previewPanel.confirm")}
              </Button>
            </FlexColumnLayout>
            <DeleteButton
              onClick={() => {
                setDiscardModalOpen(true)
                Analytics.track(events.accounts.transfer.discard)
              }}
              sx={{ width: 42, height: 42, flexShrink: 0 }}
            />
          </FlexRowLayout>
          <AnimatePresence animation="fadeAndExpand">
            {hasError && <NoticeBox level="error" text={t("errors.generic")} />}
          </AnimatePresence>
        </motion.div>
      </PreviewPanelAnimation>
    </>
  )
}
