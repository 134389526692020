import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { ApolloError } from "@apollo/client/errors"
import { Analytics } from "@core/analytics/actions"
import { useAppSelector } from "@core/redux/utils"
import { AddNewEntityListItem } from "@components/extended/list-items/new-entity/add-new-entity-list-item"
import { RecipientListItem } from "@components/extended/list-items/recipient/recipient-list-item"
import { FormBody } from "@components/composite/form-body/form-body"
import { ButtonTypeEnum } from "@components/primitive/button/button"
import { QueryResults } from "@components/composite/query-results/query-results"
import { ListLabels, TListLabel } from "@components/primitive/list-label/list-labels"
import {
  BUTTONS_GO_BACK,
  BUTTONS_CONTINUE,
  RECIPIENT_LIST_ITEM_SUBTEXT_I18N,
  RECIPIENT_LIST_ITEM_TITLE_I18N,
  RECIPIENT_TITLE,
  RECIPIENT_BILLPAY_NEWPAYEE,
  RECIPIENT_NEW_TITLE,
  NETWORK_ERROR_TITLE,
  NETWORK_ERROR_SUBTITLE,
} from "@features/move-money/utils/i18n-translations"
import { TransactionTypeEnum } from "@generated/graphql"
import { selectRecipient } from "@features/move-money/redux/selectors"
import { IGenericRecipient } from "@features/move-money/redux/state"
import { moveMoneyActions } from "@features/move-money/redux/actions"
import { TMoveMoneyScreenNames } from "@features/move-money/router/move-money"
import { translate, translateDynamicNoOptions } from "@i18n/i18n"

interface IRecipientSelectProps {
  recipients: IGenericRecipient[]
  recipientType: TransactionTypeEnum
  createRecipientRoute: TMoveMoneyScreenNames
  navigateNextRoute: TMoveMoneyScreenNames
  loading: boolean
  error: ApolloError | undefined
  refetch: () => void
  analyticsContinueEvent: string
  analyticsCreateEvent?: string
  analyticsSelectEvent: string
}

export const RecipientSelect: React.FC<IRecipientSelectProps> = ({
  recipients,
  recipientType,
  createRecipientRoute,
  navigateNextRoute,
  loading,
  error,
  refetch,
  analyticsContinueEvent,
  analyticsCreateEvent,
  analyticsSelectEvent,
}) => {
  const dispatch = useDispatch()
  const recipient = useAppSelector(selectRecipient)
  const [activeRecipientID, setActiveRecipientID] = useState(false)

  const buttons = [
    {
      children: BUTTONS_GO_BACK,
      type: ButtonTypeEnum.TERTIARY,
      onClick: () => dispatch(moveMoneyActions.navigateBack()),
    },
    {
      canContinueWithKey: true,
      children: BUTTONS_CONTINUE,
      disabled: !activeRecipientID,
      onClick: () => {
        dispatch(moveMoneyActions.navigateNext(navigateNextRoute))
        Analytics.track(analyticsContinueEvent)
      },
    },
  ]

  const recipientListClickHandler = ({
    id,
    name,
    bankAccountNumber,
    bankRoutingNumber,
    relationship,
    address,
    bankName,
  }: IGenericRecipient) => {
    Analytics.track(analyticsSelectEvent, { recipientName: name })
    dispatch(
      moveMoneyActions.setRecipient({
        id,
        name,
        bankAccountNumber: bankAccountNumber || undefined,
        bankRoutingNumber: bankRoutingNumber || undefined,
        relationship: relationship || undefined,
        address: address || undefined,
        bankName: bankName || undefined,
      }),
    )
  }

  const mapRecipientsToListItems = (recipients: IGenericRecipient[]) =>
    recipients?.map(({ id, name, bankAccountNumber }, index) => (
      <RecipientListItem
        key={index}
        isActive={id === recipient.id && activeRecipientID}
        onClick={() => {
          setActiveRecipientID(true)
          recipientListClickHandler(recipients[index])
        }}
        name={name || ""}
        subText={translate(`${RECIPIENT_LIST_ITEM_SUBTEXT_I18N}.${recipientType}`, {
          accountNumber: bankAccountNumber,
        })}
      />
    ))

  const recipientListLabels: (recipientType: TransactionTypeEnum) => TListLabel[] = (
    recipientType: TransactionTypeEnum,
  ) => [
    {
      title: translateDynamicNoOptions(RECIPIENT_LIST_ITEM_TITLE_I18N, recipientType),
      widthRatio: 0.5,
      alignment: "start",
    },
  ]

  const addEntityClickHandler = () => {
    dispatch(moveMoneyActions.setEditingRecipient(null))
    dispatch(moveMoneyActions.setRecipient(null))
    analyticsCreateEvent && Analytics.track(analyticsCreateEvent)
    dispatch(moveMoneyActions.navigateNext(createRecipientRoute))
  }

  return (
    <FormBody title={RECIPIENT_TITLE} buttons={buttons}>
      <QueryResults
        loading={loading}
        error={error}
        retry={() => refetch()}
        errorText={{
          title: NETWORK_ERROR_TITLE,
          subtitle: NETWORK_ERROR_SUBTITLE,
        }}
        size="medium"
        listShimmerCount={5}
      >
        <AddNewEntityListItem
          onClick={() => addEntityClickHandler()}
          text={
            recipientType === TransactionTypeEnum.BILL_PAYMENT
              ? RECIPIENT_BILLPAY_NEWPAYEE
              : RECIPIENT_NEW_TITLE
          }
        />
        {recipients?.length ? (
          <>
            <ListLabels labels={recipientListLabels(recipientType)} />
            {mapRecipientsToListItems(recipients)}
          </>
        ) : null}
      </QueryResults>
    </FormBody>
  )
}
