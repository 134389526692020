export const accounts = {
  header: "Envelopes",
  subHeading:
    "NorthOne Envelopes let you designate a portion of your balance to view on the mobile app or website to help you stay organized.",
  emptyState: {
    title: "Organize your money with Envelopes",
    subtitle:
      "NorthOne Envelopes let you designate a portion of your balance to view on the mobile app or website to help you stay organized.",
    dashboardLink: "For more information, read the ",
    dashboard: "Deposit Account Agreement",
    button: "Create an Envelope",
  },
  createSubAccount: {
    progressBar: {
      name: "Name",
      addFunds: "Assign funds",
      rules: "Set up rules",
      summary: "Summary",
    },
    preview: {
      title: "Sub-Account Preview",
      subAccount: "Envelope",
      balance: "Assigned funds",
      rules: "Automatic rules",
      noRules: "None set",
      fixedAmount: "{{amount}} transferred {{frequency}}",
      percentage: "{{percentage}}% of all incoming deposits",
    },
    name: {
      title: "Name your Envelope",
      subtitle:
        "Name your Envelope based on its purpose. ‘Taxes’, ‘Rent’, ‘Payroll’ are good examples.",
      label: "Envelope name",
      placeholder: "Example: Taxes",
      exists: "Envelope with this name already exists",
      loadingButton: "Creating Envelope",
    },
    initialTransfer: {
      title: "Enter an amount to assign to this Envelope",
      subtitle:
        "Put your sub-account to good use by transferring some initial funds from your main Checking account.",
      skip: "Skip for now",
      loadingButton: "Assigning funds",
    },
    scheduledAmount: {
      title: "Set up an automatic rule",
      subtitle: "Schedule fixed amount transfers",
      amount: {
        label: "Amount",
      },
      frequency: {
        label: "Frequency",
        label_schedule: "Schedule",
        placeholder: "Please select",
      },
      emptyNote:
        "You do not have any automatic transfers for fixed amounts scheduled for this sub-account.",
      note: "Your next automatic transfer of *{{amount}}* will be *{{date}}*",
      invalid: "Please enter a valid number",
      insufficientBalance: "Insufficient Balance in Checking",
    },
    percentRule: {
      title: "Set up another automatic rule",
      titleFR: "Set a percentage rule to automatically assign incoming funds to this Envelope",
      label: "Percentage",
      inputNote: "You can assign up to 100% of incoming funds",
      note: "*{{percentage}}%* of all incoming deposits will be assigned to this Envelope.",
      note_zeroState: "You do not have percentage transfers set up for this Envelope.",
      percentExceeded: "You can only transfer up to {{percentage}}%",
    },
  },
  newSubAccount: "Create an Envelope",
  subAccounts: {
    envelope: "Envelope",
    subtitle: " - {{percent}}% of all incoming deposits",
    envelopeAdjustment: "Envelope Adjustment",
    subHeading:
      "NorthOne Envelopes let you designate a portion of your balance to view on the mobile app or website to help you stay organized. ",
    backButton: "Back to Envelopes",
    checkingSidebarBalance: "Assigned Funds",
    subAccountSidebarBalance: "Assigned Funds",
    info: "For more information on Envelopes, read the ",
  },
  delete: {
    title: "Discard Envelope?",
    subtitle: "Any funds assigned to this Envelope will be re-assigned to your Deposit Account.",
    button: "Discard Envelope",
  },
  edit: {
    title: "Edit your Envelope",
    subtitle: "Edit your sub-account name or automatic rules.",
    nameLabel: "Envelope name",
    rulesTitle: "Envelope rules",
    rulesTitleFR: "Envelope Rule",
    button: "Save name",
    rulesSubtitle:
      "Envelope rules allow you to transfer money into your sub-account automatically.",
    rulesSubtitleFR:
      "Set a percentage rule to automatically assign incoming funds to this Envelope.",
    saveRule: "Set rule",
    editRule: "Edit rule",
    schedule: {
      title: "Schedule fixed amount transfers",
      amountLabel: "Amount",
      frequencyLabel: "Schedule",
    },
    percentage: {
      title: "No percentage rule set",
      label: "Percentage",
    },
    deleteRule: {
      title: "Remove automatic rule?",
      subtitle: "Any remaining funds will be transferred back into your main checking account.",
    },
    deleteSchedule: {
      title: "Remove automatic rule?",
    },
    clearRule: "Remove rule",
  },
  transfer: {
    startTransferCta: "Adjust Envelopes",
    previewPanel: {
      label: "Preview",
      amountLabel: "Amount",
      methodLabel: "Payment Method",
      transfer: "Transfer",
      sourceAccountLabel: "From",
      destinationAccountLabel: "To",
      dateAndTimeLabel: "Date and Time",
      scheduleLabel: "Schedule",
      unspecified: "Unspecified",
    },
    progressBar: {
      sourceAccount: "From",
      destinationAccount: "To",
      amount: "Amount",
      schedule: "Scheduling",
      confirm: "Confirmation",
    },
    sourceAccount: {
      title: "Where do you want to assign money from?",
    },
    destinationAccount: {
      title: "Where do you want to assign money to?",
    },
    amount: {
      title: "Enter the amount to assign to {{name}}",
    },
    schedule: {
      title: "Select a date to send the transfer",
      subtitle: "Internal transfers between accounts occur immediately.",
    },
    sent: {
      label: "Envelope adjustment complete",
      label_scheduled: "Your transfer is scheduled",
      label_scheduledFrequency: "Your transfers are scheduled",
      title:
        "{{amount}} has been assigned to {{destinationAccountName}} from {{sourceAccountName}}",
      title_scheduled:
        "{{amount}} will be assigned to {{destinationAccountName}} from {{sourceAccountName}}",
      title_scheduledFrequency:
        "{{amount}} transfer to {{destinationAccountName}} from {{sourceAccountName}} is scheduled",
    },
  },
  reconcile: {
    landing: {
      title: "Reallocate your Envelope funds",
      subtitle: "One or more transactions have caused your\nEnvelope funds to fall below $0.",
    },
    reallocate: {
      title: "Reallocate your Envelope funds",
      subtitle: "Enter the amount of funds you’d like to assign to each Envelope.",
      button: "Reallocate funds",
      loadingButton: "Reallocating funds",
      previousBalance: "Previously assigned *{{balance}}*",
    },
    skip: {
      title: "Skip this step?",
      subtitle:
        "Your Envelope balances will be set to $0 and all funds allocated to your Deposit Account.",
    },
  },
}
