import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { InfoBlockModal } from "@components/composite/info-block-modal/info-block-modal"
import { ButtonTypeEnum } from "@components/primitive/button/button"

interface ICancelACHModalProps {
  transactionId: string
  isOpen: boolean
  closeModal: () => void
}

export const CancelACHModal: React.FC<ICancelACHModalProps> = ({ isOpen, closeModal }) => {
  const { t } = useTranslation()

  const [hasError] = useState(false)

  return (
    <InfoBlockModal
      title={t("dashboard.cancelACHModal.title")}
      subtitle={t("dashboard.cancelACHModal.subtitle")}
      isOpen={isOpen}
      closeModal={closeModal}
      errorMessage={hasError ? t("errors.generic") : ""}
      buttons={[
        {
          children: t("dashboard.cancelACHModal.cancelPayment"),
          onClick: () => null,
        },
        { children: t("buttons.goBack"), onClick: closeModal, type: ButtonTypeEnum.SECONDARY },
      ]}
    />
  )
}
