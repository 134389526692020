import React from "react"
import { SxStyleProp } from "rebass"
import { ApolloError } from "@apollo/client"
import { LoadingIcon } from "@northone/ui"

import { TListItemHeight } from "@components/primitive/list-item/list-item"
import { FlexColumnLayout } from "@layouts/flex"
import { TErrorScreenSize, ErrorScreen } from "../error-screen/error-screen"
import { ListLoadingShimmer } from "../transaction-list/loading-shimmer"

export interface IErrorText {
  title: string
  subtitle: string
  retryTitle?: string
}
interface QueryResultsProps {
  children?: React.ReactNode
  error: ApolloError | undefined
  errorText?: IErrorText
  errorStyles?: SxStyleProp
  errorElement?: JSX.Element
  loading: boolean
  retry: () => void
  size: TErrorScreenSize
  listShimmerCount?: number
  listShimmerHeight?: TListItemHeight
  loadingContainerSx?: SxStyleProp
}

export const QueryResults = ({
  loading,
  error,
  retry,
  size,
  listShimmerCount,
  children,
  listShimmerHeight,
  errorText,
  errorStyles,
  errorElement,
  loadingContainerSx,
}: QueryResultsProps) => {
  if (loading) {
    if (listShimmerCount) {
      return <ListLoadingShimmer listItemHeight={listShimmerHeight} listCount={listShimmerCount} />
    }
    return (
      <FlexColumnLayout
        alignItems="center"
        justifyContent="center"
        sx={{ margin: "auto", width: "100%", ...loadingContainerSx }}
      >
        <LoadingIcon dimension={50} />
      </FlexColumnLayout>
    )
  }
  if (error) {
    return errorElement ? (
      errorElement
    ) : (
      <ErrorScreen
        sx={errorStyles}
        size={size}
        retry={retry}
        title={errorText?.title}
        subtitle={errorText?.subtitle}
        retryTitle={errorText?.retryTitle}
      />
    )
  }
  return <>{children}</>
}
