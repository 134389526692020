export const revenueHub = {
  landing: {
    title: "Revenue",
    errorScreen: {
      subtitle: "Reload the page to try again.",
      buttonLabel: "Reload this page",
    },
    refresh: "Refresh",
  },
  filters: {
    date: {
      last7Days: "Last 7 days",
      last30Days: "Last 30 days",
      custom: "Custom",
      to: "to",
    },
  },
  disclosure: {
    title: "Have questions?",
    message: "Check out __Frequently Asked Questions__ or reach out to __Customer Care__.",
  },

  banner: {
    title: "Get all of your revenue analytics in one place",
    subtitle: "Sync your sales channel data with NorthOne to stay on top of your business.",
  },
  platformConnect: {
    title: "Connect to {{platform}}",
    description: "Automatically import your {{platform}} data to centralize your sales.",
    buttonLabel: "Connect app",
    etsyDisclaimer:
      "The term 'Etsy' is a trademark of Etsy, Inc. This application uses the Etsy API but is not endorsed or certified by Etsy, Inc.",
    rutterUserAgreement:
      "By choosing to Connect app, you agree to __Rutter's End User Privacy Policy.__",
  },
  platformActive: {
    grossRevenue: { label: "Gross Revenue", description: "Total funds earned" },
    balance: "Balance {{balance}}",
    balanceFromPlatformDashboard: "__Balance available on {{platformName}}__",
    paidOut: { label: "Paid out", description: "Total funds that have been transferred out" },
    establishingConnection: "Establishing Connection",
    lineGraph: {
      revenueDatasetLabel: "Revenue",
    },
    etsy: {
      link: "__Available on Etsy__",
    },
    transactions: {
      label: "Transactions",
      description: "Amount of sales made",
    },
    returns: {
      label: "Returns",
      description: "Total amount of returns made by customers",
    },
    error: {
      generic: {
        header: "There was an issue with this connection",
        subtitle:
          "We’re having trouble connecting to your {{platformName}} account. Try reconnecting it below.",
        cta: "Refresh",
      },
      disabled: {
        header: "Connection is disabled",
        subtitle:
          "It looks like there is something wrong with your {{platformName}} Account. Please go to the __{{platformName}} website__ to fix the issue and try to connect again.",
        cta: "Reconnect app",
        disabled: "Disabled",
      },
      notReady: {
        header: "Connection is still processing",
        subtitle: "Sorry for the delay, this can take up to a few hours",
        dataNotReady: "Data not ready",
      },
      reAuth: {
        header: "App is disconnected",
        subtitle: "{{platformName}} is disconnected. Please connect again to see the latest data.",
        cta: "Reconnect app",
        disconnected: "Disconnected",
      },
      connectionError: "Connection Error",
    },
    payoutInstructions: {
      title: "How to transfer funds to NorthOne",
      subtitle: "Connect to {{platformName}} to get paid directly into your NorthOne Account.",
      transferFundsToNorthOne: "Transfer funds to NorthOne",
      routing: "Routing",
      account: "Account",
      platforms: {
        amazon: "Go to *Seller Central* in Amazon to add your account details:",
        etsy: "Go to *Shop Manager* in Etsy to add your account details:",
        paypal: "Go to *Wallet* and *Link a bank* in Paypal to add your account details:",
        shopify: "Go to your *Settings* in *Shopify admin* to add your account details:",
        square: "Go to *Account & Settings* in Square to add your account details:",
        stripe: "Go to *Payout settings* in Stripe to add your account details:",
      },
    },
  },
  disconnectPlatformModal: {
    title: "Are you sure you want to disconnect your {{platformName}} account?",
    subtitle:
      "You will no longer be able to see metrics from this account. You can reconnect it at any time.",
    secondaryCta: "Disconnect",
    primaryCta: "Keep connection",
    errorMessage:
      "There was a problem disconnecting this app. Please try again. If that doesn’t work, __contact Customer Care__.",
  },
}
