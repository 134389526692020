import React from "react"
import { Modal, Text } from "@northone/ui"
import { useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { Button, ButtonTypeEnum } from "@components/primitive/button/button"
import { QueryResults } from "@components/composite/query-results/query-results"
import { PlaidItem } from "@generated/graphql"
import { RoutesEnum } from "@routers/types"
import { LinkedAccountItemWithoutActions } from "../linked-account-items"
import { ButtonContainer } from "@components/primitive/button-container"

type CreateSuccessModalProps = {
  isOpen: boolean
  onContinue: () => void
  plaidItem?: PlaidItem
}

export function CreateSuccessModal({ isOpen, onContinue, plaidItem }: CreateSuccessModalProps) {
  const { t } = useTranslation()
  const history = useHistory()

  const handleManageTopUpsClick = () => history.push(RoutesEnum.SETTINGS_AUTO_TOP_UP)

  return (
    <Modal isOpen={isOpen} onOutsideClick={onContinue} sx={{ gap: 4 }}>
      <Text tag="h3Serif">{t("settings.pages.linkedAccount.successModal.title")}</Text>
      <QueryResults
        loading={!plaidItem}
        listShimmerCount={1}
        error={undefined}
        retry={() => null}
        size="medium"
      >
        {plaidItem && <LinkedAccountItemWithoutActions plaidItem={plaidItem} />}
      </QueryResults>
      <ButtonContainer>
        <Button type={ButtonTypeEnum.SECONDARY} onClick={onContinue}>
          {t("buttons.done")}
        </Button>
        <Button type={ButtonTypeEnum.PRIMARY_BLUE} onClick={handleManageTopUpsClick}>
          {t("settings.pages.linkedAccount.successModal.topUpsButton")}
        </Button>
      </ButtonContainer>
    </Modal>
  )
}
