import gql from "graphql-tag"

export const PERK_DETAILS_FRAGMENT = gql`
  fragment PerkDetailFields on Perk {
    partner_name
    partner_logo
    partner_category
    perk_header
    partner_description
    perk_details
    partner_link {
      ... on _ExternalLink {
        url
      }
    }
    perk_cta_text
    partner_support_link {
      ... on _ExternalLink {
        url
      }
    }
    perk_expiry_date
    perk_how_to_claim
  }
`

export const GET_ALL_PERKS = gql`
  ${PERK_DETAILS_FRAGMENT}
  query perksQuery($searchQuery: String) {
    allPerks(lang: "en-us", sortBy: partner_category_ASC, fulltext: $searchQuery) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          _meta {
            uid
          }
          ...PerkDetailFields
        }
      }
    }
  }
`

export const GET_PERK_BY_ID = gql`
  ${PERK_DETAILS_FRAGMENT}
  query perkById($id: String!) {
    perk(lang: "en-us", uid: $id) {
      ...PerkDetailFields
    }
  }
`
