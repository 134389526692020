import React, { useState, useEffect } from "react"
import { ThemeProvider } from "emotion-theming"
import { theme } from "@layouts/theme"
import { AppRouter } from "@routers/app-router"
import { ResponsiveWrapper } from "@features/responsive/wrapper"
import { Box, SxStyleProp } from "rebass"
import { logout } from "@core/auth/auth-service"
import { isProduction } from "@utils/environment"
import { OptimizelyWrapper } from "@core/optimizely/optimizely-wrapper"
import smoothscroll from "smoothscroll-polyfill"
import { AuthWrapper } from "@features/auth-wrapper/auth-wrapper"
import { SatismeterWrapper } from "@core/satismeter/satismeter-wrapper"
import { useLocation } from "react-router-dom"
import { AnimatePresence, FullScreenLoading } from "@northone/ui"
import { PostAuthWrapper } from "@features/auth-wrapper/post-auth-wrapper"
import { EmailVerifyWrapper } from "@features/auth-wrapper/email-verify/email-verify-wrapper"
import { Analytics } from "@core/analytics/actions"
import { MBABanner } from "@features/responsive/mba-banner"
import { NotificationToast } from "@features/notification-toast/notification-toast"
import { useAddBusinessIdToAnalytics } from "@core/analytics/hooks"

smoothscroll.polyfill()

const AUTHENTICATE_APP_CONTAINER_ID = "authenticated-app-container"

export const App: React.FC = () => {
  const location = useLocation()
  const [appIsLoading, setAppIsLoading] = useState(true)

  useAddBusinessIdToAnalytics()

  useEffect(() => {
    Analytics.page(location.pathname)
  }, [location, Analytics])

  const subscribeToChildrenChanges = () => {
    // The same loader will show until there are children nodes in the AUTHENTICATE_APP_CONTAINER_ID box
    // This mutation observer is listening to changes to the childlist
    const appContainer = document.getElementById(AUTHENTICATE_APP_CONTAINER_ID)
    const observer = new MutationObserver((mutationList) => {
      mutationList.forEach((mutation) => {
        if (mutation.type === "childList") {
          setAppIsLoading(appContainer?.childNodes?.length === 0)
        }
      })
    })
    if (appContainer) {
      observer.observe(appContainer, { childList: true, subtree: true })
    }
  }

  return (
    <>
      <ThemeProvider
        theme={{
          ...theme,
        }}
      >
        <AnimatePresence animation="fadeIn">{appIsLoading && <InitialLoader />}</AnimatePresence>
        <AuthWrapper>
          <Box ref={() => subscribeToChildrenChanges()} id={AUTHENTICATE_APP_CONTAINER_ID}>
            <PostAuthWrapper>
              <OptimizelyWrapper>
                <EmailVerifyWrapper>
                  <SatismeterWrapper>
                    <ResponsiveWrapper>
                      <NotificationToast />
                      <AppRouter />
                      <MBABanner />
                    </ResponsiveWrapper>
                  </SatismeterWrapper>
                </EmailVerifyWrapper>
              </OptimizelyWrapper>
            </PostAuthWrapper>
          </Box>
        </AuthWrapper>
      </ThemeProvider>
      {!isProduction && (
        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            right: 0,
            width: 10,
            height: 10,
            cursor: "pointer",
          }}
          onClick={() => logout()}
        />
      )}
    </>
  )
}

interface IInitialLoaderProps {
  sx?: SxStyleProp
}

// TODO: Move this to a shared component
export const InitialLoader = ({ sx }: IInitialLoaderProps) => (
  <Box>
    <FullScreenLoading titles={[]} sx={sx} />
  </Box>
)
