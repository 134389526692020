import React, { ReactNode, useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import { Box, Image } from "rebass"
import { formatMoney } from "accounting"
import { LoadingIcon } from "@northone/ui"

import { theme } from "@layouts/theme"
import { images } from "@assets/images/images"
import { Text } from "@components/primitive/text/text"
import { Panel } from "@components/primitive/panel/panel"
import { FlexColumnLayout, FlexRowLayout } from "@layouts/flex"
import { CloseButton } from "@components/primitive/close-button/close-button"
import { InvoiceStatusPills } from "./invoice-status-pills"
import { Tabs } from "@components/composite/tabs/tabs"
import { Button, ButtonTypeEnum } from "@components/primitive/button/button"
import { AnimatePresence } from "@components/animations/animate-presence/animation-presence"
import { NoticeBox } from "@components/primitive/notice-box/notice-box"
import { selectActiveBusinessID } from "@core/active-business/redux/selectors"
import { useAppSelector } from "@core/redux/utils"
import {
  InvoiceAccountPayableCreatedVia,
  InvoicePaymentStatus,
  InvoiceStatus,
  useGetInvoiceAccountsPayableQuery,
  useMarkInvoiceAccountsPayableAsUnarchivedMutation,
} from "@generated/graphql"
import { ErrorScreen } from "@components/composite/error-screen/error-screen"
import { getDateWithSpecificFormat } from "@utils/dates"
import { MarkInvoicePaidConfirmationModal } from "./invoice-modal/update-invoice-status/mark-invoice-paid-confirmation-modal"
import { MarkInvoiceUnpaidConfirmationModal } from "./invoice-modal/update-invoice-status/mark-invoice-unpaid-confirmation-modal"
import { MarkInvoiceArchivedConfirmationModal } from "./invoice-modal/update-invoice-status/mark-invoice-archived-confirmation-modal"
import { InvoiceTransactions } from "./invoice-transactions"
import { invoicePaymentsActions } from "./redux/actions"
import { IInvoiceDetailsState, TInvoiceAccountsPayable } from "./redux/reducer"
import { RoutesEnum } from "@routers/types"
import { Analytics } from "@core/analytics/actions"
import { events } from "@core/analytics/events"
import { pollWithMaxRetries } from "@utils/poll-with-max-retries"
import { GET_INVOICE_WITHOUT_TRANSACTIONS } from "./operations.gql"
import { InvoiceDownloadButton } from "./invoice-download-button"

export const MISSING_VALUE_PLACEHOLDER = "-"

const BUTTON_TEXT_STYLES = {
  fontSize: "10px",
  letterSpacing: "1px",
  whiteSpace: "nowrap",
  textTransform: "uppercase",
}

interface IInvoiceQuickViewPanelProps {
  cachedInvoiceData?: TInvoiceAccountsPayable
  invoiceId: string
  onCloseClick?: () => void
  onInvoiceUpdateCompleted: (args: { message: string } | void) => Promise<void>
}

const QuickViewPanel = ({
  vendorName,
  onCloseClick,
  children,
}: {
  vendorName?: string
  onCloseClick?: () => void
  children: ReactNode
}) => (
  <Panel
    sx={{
      paddingY: 48,
      paddingX: 40,
      marginTop: 40,
    }}
  >
    <FlexRowLayout justifyContent="space-between">
      <Text
        sx={{ paddingBottom: 16, overflowWrap: "break-word", maxWidth: "80%" }}
        textColor={"black"}
        tag="h3Serif"
      >
        {vendorName}
      </Text>
      {onCloseClick && (
        <CloseButton
          onCloseClick={onCloseClick}
          sx={{ marginLeft: 16, marginTop: -16, marginRight: -12 }}
        />
      )}
    </FlexRowLayout>

    {children}
  </Panel>
)

export const InvoiceQuickViewPanel = ({
  invoiceId,
  cachedInvoiceData,
  onCloseClick,
  onInvoiceUpdateCompleted,
}: IInvoiceQuickViewPanelProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  const businessId = useAppSelector(selectActiveBusinessID)

  const [isMarkAsPaidModalOpen, setIsMarkAsPaidModalOpen] = useState<boolean>(false)
  const [isMarkAsUnpaidModalOpen, setIsMarkAsUnpaidModalOpen] = useState<boolean>(false)
  const [isArchiveInvoiceModalOpen, setArchiveInvoiceModalOpen] = useState<boolean>(false)

  // Mark invoice as paid
  const openMarkAsPaidModal = useCallback(() => {
    setIsMarkAsPaidModalOpen(true)
  }, [])

  const closeMarkAsPaidModal = useCallback(() => {
    setIsMarkAsPaidModalOpen(false)
  }, [])

  // Mark invoice as unpaid
  const openMarkAsUnpaidModal = useCallback(() => {
    setIsMarkAsUnpaidModalOpen(true)
  }, [])

  const closeMarkAsUnpaidModal = useCallback(() => {
    setIsMarkAsUnpaidModalOpen(false)
  }, [])

  // Mark invoice as archived
  const openArchiveInvoiceModal = useCallback(() => {
    setArchiveInvoiceModalOpen(true)
  }, [])

  const closeArchiveInvoiceModal = useCallback(() => {
    setArchiveInvoiceModalOpen(false)
  }, [])

  // Fetch invoice
  const {
    data,
    loading: isInvoiceLoading,
    error: invoiceLoadingError,
    refetch,
  } = useGetInvoiceAccountsPayableQuery({
    variables: { businessId, invoiceId },
    fetchPolicy: "network-only",
  })

  const invoice = data?.getInvoiceAccountsPayable?.invoice || cachedInvoiceData
  const isInvoiceHasAttachmentId = Boolean(invoice?.attachmentId)
  // Mark invoice as unarchived
  const [unarchiveInvoice, { loading: isUnarchiveInvoiceLoading, error: unarchiveInvoiceError }] =
    useMarkInvoiceAccountsPayableAsUnarchivedMutation({
      variables: { businessId, invoiceId },
      onCompleted: () => {
        onInvoiceUpdateCompleted({
          message: t("invoicePayments.markInvoiceUnarchived.notificationMessage"),
        })
      },
    })

  if (isInvoiceLoading && !invoice) {
    return (
      <QuickViewPanel onCloseClick={onCloseClick}>
        <Box
          sx={{
            margin: "auto",
            width: "100%",
            height: "438px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoadingIcon
            css={{
              color: theme.colors.blackPrimary,
            }}
          />
        </Box>
      </QuickViewPanel>
    )
  }

  if (invoiceLoadingError || !invoice) {
    return (
      <QuickViewPanel onCloseClick={onCloseClick}>
        <Box
          sx={{
            width: "100%",
            height: "438px",
            padding: "25px 100px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Image src={images.icons.caution} height={27} width={24} />

          <ErrorScreen
            size="small"
            retry={() => refetch()}
            subtitle={t("invoicePayments.invoicePanel.loadingErrorMessage")}
            retryTitle={t("invoicePayments.invoicePanel.reloadThisTab")}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            subtitleSx={{
              textAlign: "center",
            }}
          />
        </Box>
      </QuickViewPanel>
    )
  }

  const {
    createdVia,
    vendorName,
    contactId,
    dueDate,
    invoiceDate,
    description,
    invoiceNumber,
    attachmentId,
    amount,
    paidAmount,
    pendingAmount,
    scheduledAmount,
    remainingAmount,
    status,
    paymentStatus,
    isMarkedAsPaid,
    markedAsPaidReason,
  } = invoice

  const dispatchInvoiceDetails = () => {
    const invoiceDetails: IInvoiceDetailsState = {
      invoiceId: invoice.id,
      vendorName: vendorName ?? undefined,
      contactId: contactId ?? undefined,
      dueDate: dueDate ?? undefined,
      description: description ?? undefined,
      invoiceDate: invoiceDate ?? undefined,
      invoiceNumber: invoiceNumber ?? undefined,
      totalAmount: amount ?? undefined,
      attachmentId: attachmentId ?? undefined,
    }

    // Disable invoice form editing if invoice has any payments
    if (paidAmount || pendingAmount || scheduledAmount) {
      dispatch(invoicePaymentsActions.setInvoiceDetailsEditingEnabled(false))
    }

    dispatch(invoicePaymentsActions.setInvoiceDetails(invoiceDetails))
    dispatch(invoicePaymentsActions.setActiveInvoice(invoice))
  }

  const navigateToInvoiceModal = () => history.push(RoutesEnum.INVOICE_PAYMENTS)

  const handleEditInvoiceDetailsClick = () => {
    Analytics.track(events.invoicePayments.invoiceQuickview.editInvoiceDetailsClick, {
      invoiceId: invoice.id,
    })
    dispatchInvoiceDetails()
    navigateToInvoiceModal()
  }

  const handlePayInvoiceClick = () => {
    Analytics.track(events.invoicePayments.invoiceQuickview.payThisInvoiceClick, {
      invoiceId: invoice.id,
    })
    dispatchInvoiceDetails()
    navigateToInvoiceModal()
    if (createdVia === InvoiceAccountPayableCreatedVia.USER) {
      // invoices created by the user can be paid by going straight to payment details
      dispatch(invoicePaymentsActions.navigate("PAYMENT_DETAILS"))
    }
  }

  const isInvoiceEditable =
    status === InvoiceStatus.UNPAID && paymentStatus !== InvoicePaymentStatus.PROCESSING

  const handleUnarchiveInvoice = async () => {
    Analytics.track(events.invoicePayments.invoiceQuickview.moveBackToDraftsClick, {
      invoiceId: invoice.id,
    })
    await unarchiveInvoice()
    await onInvoiceUpdateCompleted()
  }

  const refetchInvoice = async () => {
    await pollWithMaxRetries([
      { query: GET_INVOICE_WITHOUT_TRANSACTIONS, variables: { businessId, invoiceId } },
    ])
    await refetch()
  }

  const isPartiallyPaid = paidAmount > 0

  const isFullyPaid = Boolean(amount) && paidAmount === amount

  const showPayInvoiceButton = remainingAmount > 0 // Show `Pay Invoice` if user is allowed to make/schedule further payments.

  const isInvoiceDownloadButtonLabeled =
    invoice.status === InvoiceStatus.PAID || invoice.status === InvoiceStatus.ARCHIVED

  return (
    <>
      <MarkInvoicePaidConfirmationModal
        isOpen={isMarkAsPaidModalOpen}
        invoice={invoice}
        closeOverlay={closeMarkAsPaidModal}
        onInvoiceUpdateCompleted={onInvoiceUpdateCompleted}
      />

      <MarkInvoiceUnpaidConfirmationModal
        isOpen={isMarkAsUnpaidModalOpen}
        invoice={invoice}
        closeOverlay={closeMarkAsUnpaidModal}
        onInvoiceUpdateCompleted={onInvoiceUpdateCompleted}
      />

      <MarkInvoiceArchivedConfirmationModal
        isOpen={isArchiveInvoiceModalOpen}
        invoice={invoice}
        closeOverlay={closeArchiveInvoiceModal}
        onInvoiceUpdateCompleted={onInvoiceUpdateCompleted}
      />

      <QuickViewPanel
        onCloseClick={onCloseClick}
        vendorName={vendorName || MISSING_VALUE_PLACEHOLDER}
      >
        <FlexRowLayout>
          <InvoicePanelItem label={t("invoicePayments.invoice.amount")}>
            <Text tag="body-large" color={theme.colors.ui1} fontSize="24px">
              {typeof amount === "number" ? formatMoney(amount) : `$ ${MISSING_VALUE_PLACEHOLDER}`}
            </Text>
          </InvoicePanelItem>
          <InvoiceStatusPills invoice={invoice} sx={{ marginTop: 18, marginLeft: 12 }} />
        </FlexRowLayout>

        <Text tag="body" color={theme.colors.ui2} sx={{ marginBottom: 20 }}>
          {formatMoney(paidAmount + pendingAmount || 0)} {t("invoicePayments.invoicePanel.paid")}
        </Text>

        {status === InvoiceStatus.PAID && isMarkedAsPaid && (
          <>
            <Text tag="body" color={theme.colors.ui2} sx={{ marginBottom: 20 }}>
              {t("invoicePayments.invoicePanel.invoiceMarkedAsPaid")}
              {markedAsPaidReason && (
                <>
                  <br />
                  {markedAsPaidReason}
                </>
              )}
            </Text>
          </>
        )}

        <AnimatePresence animation="fadeAndExpand">
          {unarchiveInvoiceError && (
            <FlexRowLayout>
              <NoticeBox
                level="error"
                content={
                  <Text tag="body">
                    {t("invoicePayments.markInvoiceUnarchived.errorMessage")}{" "}
                    <a
                      href="#"
                      onClick={() => {
                        window.Intercom("show")
                        return false
                      }}
                    >
                      {t("invoicePayments.markInvoiceUnarchived.contactLink")}
                    </a>
                    {"."}
                  </Text>
                }
              />
            </FlexRowLayout>
          )}
        </AnimatePresence>

        <FlexRowLayout sx={{ marginBottom: 10 }}>
          {invoice.status === InvoiceStatus.UNPAID && (
            <FlexRowLayout sx={{ gap: 3 }}>
              {showPayInvoiceButton && (
                <Button
                  type={ButtonTypeEnum.PRIMARY_BLACK}
                  iconSrc={images.icons.paperPlaneWhite}
                  textSx={{
                    ...BUTTON_TEXT_STYLES,
                    fontWeight: "500",
                  }}
                  onClick={handlePayInvoiceClick}
                >
                  {t("invoicePayments.invoicePanel.payThisInvoice")}
                </Button>
              )}

              <Button
                type={ButtonTypeEnum.TERTIARY}
                iconSrc={images.icons.checkMark}
                textSx={{
                  ...BUTTON_TEXT_STYLES,
                  color: theme.colors.ui2,
                }}
                onClick={openMarkAsPaidModal}
              >
                {t("invoicePayments.invoicePanel.markAsPaid")}
              </Button>

              {!isPartiallyPaid && (
                <Button
                  type={ButtonTypeEnum.TERTIARY}
                  iconSrc={images.icons.archive}
                  textSx={{
                    ...BUTTON_TEXT_STYLES,
                    color: theme.colors.ui2,
                  }}
                  iconSx={{
                    color: theme.colors.ui2,
                  }}
                  onClick={openArchiveInvoiceModal}
                >
                  {t("invoicePayments.invoicePanel.archive")}
                </Button>
              )}
            </FlexRowLayout>
          )}

          {status === InvoiceStatus.PAID && (
            <FlexRowLayout sx={{ gap: 3 }}>
              {!isFullyPaid && (
                <Button
                  type={ButtonTypeEnum.PRIMARY_BLACK}
                  iconSrc={images.icons.arrowLeft}
                  textSx={{
                    ...BUTTON_TEXT_STYLES,
                    fontWeight: "500",
                  }}
                  onClick={openMarkAsUnpaidModal}
                >
                  {t("invoicePayments.invoicePanel.markAsUnpaid")}
                </Button>
              )}
              {isInvoiceHasAttachmentId && <InvoiceDownloadButton invoice={invoice} withLabel />}
            </FlexRowLayout>
          )}

          {status === InvoiceStatus.ARCHIVED && (
            <FlexRowLayout sx={{ gap: 3 }}>
              <Button
                type={ButtonTypeEnum.PRIMARY_BLACK}
                iconSrc={images.icons.arrowLeft}
                textSx={{
                  ...BUTTON_TEXT_STYLES,
                  fontWeight: "500",
                }}
                iconSx={{
                  width: "14px",
                }}
                onClick={handleUnarchiveInvoice}
                isLoading={isUnarchiveInvoiceLoading}
              >
                {t("invoicePayments.invoicePanel.moveBackToDrafts")}
              </Button>
              {isInvoiceHasAttachmentId && <InvoiceDownloadButton invoice={invoice} withLabel />}
            </FlexRowLayout>
          )}
        </FlexRowLayout>

        <Tabs
          tabs={[
            {
              title: t("invoicePayments.invoicePanel.invoiceDetails"),
              content: (
                <>
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr 2fr",
                      gap: "2rem 1.5rem",
                      marginTop: "1.5rem",
                      marginBottom: "2.5rem",
                    }}
                  >
                    <InvoicePanelItem label={t("invoicePayments.invoice.dueDate")} marginBottom="0">
                      <Text tag="body">
                        {dueDate
                          ? getDateWithSpecificFormat(dueDate, "LLL d, yyyy")
                          : MISSING_VALUE_PLACEHOLDER}
                      </Text>
                    </InvoicePanelItem>
                    <InvoicePanelItem
                      label={t("invoicePayments.invoice.invoiceDate")}
                      marginBottom="0"
                    >
                      <Text tag="body">
                        {invoiceDate
                          ? getDateWithSpecificFormat(invoiceDate, "LLL d, yyyy")
                          : MISSING_VALUE_PLACEHOLDER}
                      </Text>
                    </InvoicePanelItem>
                    <InvoicePanelItem
                      label={t("invoicePayments.invoice.invoiceNumber")}
                      marginBottom="0"
                    >
                      <Text tag="body">
                        {invoiceNumber ? invoiceNumber : MISSING_VALUE_PLACEHOLDER}
                      </Text>
                    </InvoicePanelItem>
                    <InvoicePanelItem
                      label={t("invoicePayments.invoice.description")}
                      marginBottom="0"
                    >
                      <Text tag="body">
                        {description ? description : MISSING_VALUE_PLACEHOLDER}
                      </Text>
                    </InvoicePanelItem>
                  </div>

                  <FlexRowLayout sx={{ gap: 3 }}>
                    {isInvoiceEditable && (
                      <Button
                        type={ButtonTypeEnum.TERTIARY}
                        iconSrc={images.icons.edit}
                        textSx={{
                          ...BUTTON_TEXT_STYLES,
                          color: theme.colors.ui2,
                        }}
                        onClick={handleEditInvoiceDetailsClick}
                      >
                        {t("invoicePayments.invoicePanel.editInvoiceDetails")}
                      </Button>
                    )}
                    {isInvoiceHasAttachmentId && invoice.status === InvoiceStatus.UNPAID && (
                      <InvoiceDownloadButton
                        invoice={invoice}
                        withLabel={isInvoiceDownloadButtonLabeled}
                      />
                    )}
                  </FlexRowLayout>
                </>
              ),
            },
            {
              title: t("invoicePayments.invoicePanel.paymentActivity"),
              content: isInvoiceLoading ? (
                <FlexColumnLayout
                  alignItems="center"
                  justifyContent="center"
                  sx={{ margin: "auto", height: "10vh" }}
                >
                  <LoadingIcon isCentered />
                </FlexColumnLayout>
              ) : (
                <InvoiceTransactions
                  invoice={invoice}
                  handlePayRemainingBalanceClick={handlePayInvoiceClick}
                  refetchInvoice={refetchInvoice}
                />
              ),
            },
          ]}
        />
      </QuickViewPanel>
    </>
  )
}

export const InvoicePanelItem = ({
  label,
  children,
  marginBottom = "16px",
}: {
  label: string
  marginBottom?: string
  children: React.ReactElement
}) => (
  <FlexColumnLayout sx={{ marginBottom }}>
    <Text tag="h5" sx={{ paddingBottom: "2px" }} textColor={theme.colors.ui2}>
      {label}
    </Text>
    {children}
  </FlexColumnLayout>
)
