import { createSelector } from "reselect"
import { unformat } from "accounting"
import { DateTime } from "luxon"
import { IRootState } from "@core/redux/root-state"
import {
  TransactionsListFiltersInput,
  TransactionsAmountFilterOptionsInput,
  TransactionTypeEnum,
  TransactionDirectionEnum,
  TransactionsDatetimeFilterOptionsInput,
} from "@generated/graphql"
import { TAmountFilterType, TDateFilterType, IDashboardState } from "./reducer"
import { isEqual } from "lodash-es"

export const getDashboard = (state: IRootState) => state.dashboard

// memoized/cached version of dashboard state
export const selectDashboardState = createSelector(
  getDashboard,
  (dashboard: IDashboardState) => dashboard,
)

export const selectDashboardAmountFilterType = createSelector(
  getDashboard,
  (dashboard: IDashboardState) => dashboard.amountFilterType,
)

export const selectDashboardAmount = createSelector(
  getDashboard,
  (dashboard: IDashboardState) => dashboard.amount,
)

export const selectDashboardDateFilterType = createSelector(
  getDashboard,
  (dashboard: IDashboardState) => dashboard.dateFilterType,
)

export const selectDashboardExactDate = createSelector(
  getDashboard,
  (dashboard: IDashboardState) => dashboard.exactDate,
)

export const selectDashboardStartDate = createSelector(
  getDashboard,
  (dashboard: IDashboardState) => dashboard.startDate,
)

export const selectDashboardEndDate = createSelector(
  getDashboard,
  (dashboard: IDashboardState) => dashboard.endDate,
)

export const selectDashboardFilters = createSelector(
  getDashboard,
  (dashboard: IDashboardState) => dashboard.filters,
)

export const selectDashboardTransactionID = createSelector(
  getDashboard,
  (dashboard: IDashboardState) => dashboard.transactionId,
)

export const selectDashboardTransactionTypes = createSelector(
  getDashboard,
  (dashboard: IDashboardState) => dashboard.transactionTypes,
)

export const selectDashboardFilterCardId = createSelector(
  getDashboard,
  (dashboard: IDashboardState) => dashboard.cardIds,
)

export const selectDashboardSearchTerm = createSelector(
  getDashboard,
  (dashboard: IDashboardState) => dashboard.searchTerm,
)

export const selectDashboardIsFiltering = createSelector(
  getDashboard,
  (dashboard: IDashboardState) => dashboard.isFiltering,
)

export const selectDashboardIsExporting = createSelector(
  getDashboard,
  (dashboard: IDashboardState) => dashboard.isExporting,
)

const getAmountFilter = (
  amountFilterType: TAmountFilterType,
  amount: string,
): TransactionsAmountFilterOptionsInput | null => {
  const amountValue = unformat(amount)
  if (!amountFilterType || !amountValue) {
    return null
  }
  if (amountFilterType === "exact") {
    return { exact: amountValue }
  }
  return { range: { [amountFilterType]: amountValue } }
}

interface IGetDateFiltersOptions {
  dateFilterType: TDateFilterType
  startDate: Date | null
  endDate: Date | null
  exactDate: Date | null
}

const getDateFilters = ({
  dateFilterType,
  startDate,
  endDate,
  exactDate,
}: IGetDateFiltersOptions): TransactionsDatetimeFilterOptionsInput | null => {
  switch (dateFilterType) {
    case "today":
      return { exactDatetime: DateTime.local().toISODate() }
    case "yesterday":
      return {
        exactDatetime: DateTime.local().minus({ days: 1 }).toISODate(),
      }
    case "thisWeek":
      return {
        range: {
          startDatetime: DateTime.local().startOf("week").toISODate(),
        },
      }
    case "last30Days":
      return {
        range: {
          startDatetime: DateTime.local().minus({ days: 30 }).toISODate(),
        },
      }
    case "exactDate":
      return !exactDate
        ? null
        : {
            exactDatetime: DateTime.fromJSDate(exactDate).toISODate(),
          }
    case "customRange":
      return !startDate || !endDate
        ? null
        : {
            range: {
              startDatetime: DateTime.fromJSDate(startDate).toISODate(),
              endDatetime: DateTime.fromJSDate(endDate).toISODate(),
            },
          }
    default:
      return null
  }
}

export const selectDashboardSearchFilterVariables = createSelector(
  [getDashboard],
  ({
    amountFilterType,
    amount,
    transactionTypes,
    dateFilterType,
    endDate,
    startDate,
    exactDate,
    cardIds,
  }): TransactionsListFiltersInput => {
    const amountFilter = getAmountFilter(amountFilterType, amount)
    const dateFilter = getDateFilters({ startDate, endDate, dateFilterType, exactDate })
    return {
      ...(amountFilter && { amount: amountFilter }),
      ...(transactionTypes.length && {
        transactionTypes: transactionTypes.includes(TransactionTypeEnum.GENERIC)
          ? [...transactionTypes, TransactionTypeEnum.CHECK_DEPOSIT]
          : transactionTypes,
      }),
      ...(isEqual(transactionTypes, [TransactionTypeEnum.GENERIC]) && {
        direction: TransactionDirectionEnum.CREDIT,
      }),
      ...(dateFilter && { datetime: dateFilter }),
      ...(cardIds.length && { cardIds }),
    }
  },
)
