import { createAction, TActionsUnion } from "@core/redux/utils"

export enum PerksActionEnum {
  SET_PERKS_HUB_LAST_VISITED = "PERKS_HUB_LAST_VISITED",
}

export const perksActions = {
  setPerksLastVisitedDate: (date: Date) =>
    createAction(PerksActionEnum.SET_PERKS_HUB_LAST_VISITED, date),
}

export type TPerksActions = TActionsUnion<typeof perksActions>
