import React, { useState } from "react"
import { Text } from "@components/primitive/text/text"
import { ListItem, ListItemProps } from "@components/primitive/list-item/list-item"
import { theme } from "@layouts/theme"
import { FlexColumnLayout, FlexRowLayout } from "@layouts/flex"
import { TeamOwner } from "@generated/graphql"
import { TeamMemberAvatar } from "@components/extended/avatar/team-member-avatar"
import { Image, Box } from "rebass"
import { images } from "@assets/images/images"
import { useTranslation } from "react-i18next"
import { convertToFullName } from "@utils/format"
import { OwnerStatusSection } from "./owner-status-section"

export interface ITeamBankingListItemProps extends ListItemProps {
  teamMember: TeamOwner
  hasBeenInvited?: boolean
}

const bodySmall = "body-small"
const oneThird = "33.33%"
const columnMarginRight = "20px"
const truncatedStyling = {
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
}

type ILeftColumnProps = {
  fullName: string
  title?: string | null
}

const LeftColumn = ({ fullName, title }: ILeftColumnProps) => (
  <FlexRowLayout width={oneThird} sx={{ marginRight: columnMarginRight }}>
    <TeamMemberAvatar name={fullName} />
    <FlexColumnLayout
      sx={{
        justifyContent: "center",
        paddingLeft: "20px",
      }}
    >
      <Text
        sx={{
          ...truncatedStyling,
        }}
        tag={bodySmall}
      >
        {fullName}
      </Text>
      <Text sx={{ ...truncatedStyling }} tag={bodySmall} textColor={theme.colors.ui2}>
        {title}
      </Text>
    </FlexColumnLayout>
  </FlexRowLayout>
)

type IMiddleColumnProps = {
  email?: string | null
}

const MiddleColumn = ({ email }: IMiddleColumnProps) => (
  <Box width={oneThird} sx={{ marginRight: columnMarginRight }}>
    <Text sx={{ ...truncatedStyling, width: "100%" }} tag={bodySmall} textColor={theme.colors.ui2}>
      {email}
    </Text>
  </Box>
)

export type IRightColumnProps = {
  hasBeenInvited?: boolean
  onboardingCompleted?: boolean
  isHovering: boolean | null | undefined
  isPrimaryOwner: boolean
}

const RightColumn = ({
  hasBeenInvited,
  onboardingCompleted,
  isHovering,
  isPrimaryOwner,
}: IRightColumnProps) => {
  const { t } = useTranslation()

  return (
    <FlexRowLayout width={oneThird} sx={{ justifyContent: "space-between", alignItems: "center" }}>
      <FlexRowLayout>
        <Text
          tag={bodySmall}
          textColor={theme.colors.ui2}
          sx={{ marginRight: "5px", ...truncatedStyling }}
        >
          {isPrimaryOwner
            ? t("settings.pages.teamBanking.listItem.primaryAdmin")
            : t("settings.pages.teamBanking.listItem.admin")}
        </Text>
        <Image src={images.icons.infoBold} style={{ marginRight: 10 }} />
      </FlexRowLayout>
      <OwnerStatusSection
        isHovering={isHovering}
        hasBeenInvited={hasBeenInvited}
        onboardingCompleted={onboardingCompleted}
        isPrimaryOwner={isPrimaryOwner}
      />
    </FlexRowLayout>
  )
}

export const TeamBankingListItem = ({
  teamMember,
  hasBeenInvited,
  onClick,
}: ITeamBankingListItemProps) => {
  const { firstName, lastName, title, email, onboardingCompleted, isPrimaryOwner } = teamMember

  const fullName = convertToFullName({ firstName, lastName })
  const [isHovering, setIsHovering] = useState(false)

  return (
    <ListItem
      listItemHeight="medium"
      sx={{
        justifyContent: "space-between",
      }}
      onClick={onClick}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <LeftColumn fullName={fullName} title={title} />
      <MiddleColumn email={email} />
      <RightColumn
        isHovering={isHovering}
        hasBeenInvited={hasBeenInvited}
        onboardingCompleted={onboardingCompleted ?? false}
        isPrimaryOwner={isPrimaryOwner ?? false}
      />
    </ListItem>
  )
}
