import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { DateTime } from "luxon"
import { useAppSelector } from "@core/redux/utils"
import { selectActiveBusinessID } from "@core/active-business/redux/selectors"
import { Analytics } from "@core/analytics/actions"
import { events } from "@core/analytics/events"
import { Panel } from "@components/primitive/panel/panel"
import { Text } from "@components/primitive/text/text"
import { NoticeBox } from "@components/primitive/notice-box/notice-box"
import { RadioList } from "@components/composite/radio-list/radio-list"
import { Button, ButtonTypeEnum } from "@components/primitive/button/button"
import { dashboardActions } from "@features/dashboard/redux/actions"
import { theme } from "@layouts/theme"
import { FlexRowLayout } from "@layouts/flex"
import {
  CsvFormatEnum,
  useTransactionSearchCsvLazyQuery,
  QueryTransactionSearchCsvArgs,
} from "@generated/graphql"

export const ExportPanel: React.FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [hasError, setHasError] = useState(false)
  const [exportContents, setExportContents] = useState<"ALL" | "LAST_30_DAYS" | undefined>()
  const [csvFormat, setCsvFormat] = useState<CsvFormatEnum | null>()
  const businessId = useAppSelector(selectActiveBusinessID)

  const [getCSV, { loading, data }] = useTransactionSearchCsvLazyQuery({
    fetchPolicy: "network-only",
    onError: () => setHasError(true),
  })

  useEffect(() => {
    if (data && data.transactionSearchCSV?.csvURL) {
      window.open(data.transactionSearchCSV.csvURL)
    }
  }, [data])

  return (
    <Panel
      sx={{
        paddingX: theme.spacing.transactionPanel.x,
        paddingY: theme.spacing.transactionPanel.y,
      }}
    >
      {hasError && <NoticeBox level="error" text={t("errors.generic")} />}
      <Text sx={{ marginBottom: 14 }} tag="h3Serif">
        {t("dashboard.export.export")}
      </Text>

      <RadioList
        onValueChange={setExportContents}
        label={t("dashboard.export.whatLabel")}
        value={exportContents}
        items={[
          { value: "LAST_30_DAYS", text: t("dashboard.export.last30days") },
          { value: "ALL", text: t("dashboard.export.allTransactions") },
        ]}
      />
      <RadioList
        onValueChange={setCsvFormat}
        label={t("dashboard.export.typeLabel")}
        value={csvFormat}
        items={[
          { value: CsvFormatEnum.STANDARD, text: t("dashboard.export.csv") },
          { value: CsvFormatEnum.QBO, text: t("dashboard.export.qboAmount") },
          { value: CsvFormatEnum.QBO_DEBIT_CREDIT, text: t("dashboard.export.qboDebit") },
        ]}
      />
      <FlexRowLayout sx={{ marginTop: 14 }}>
        <Button
          isLoading={loading}
          disabled={!csvFormat || !exportContents}
          type={ButtonTypeEnum.PRIMARY_BLUE}
          onClick={() => {
            Analytics.track(events.searchAndFilter.dashboardExport, { fileType: csvFormat })
            setHasError(false)
            getCSV({
              variables: {
                businessId,
                csvFormat,
                filters: exportContents === "ALL" ? {} : getLast30DaysFilters(),
              },
            })
          }}
        >
          {t("dashboard.export.button")}
        </Button>
        <Button
          onClick={() => dispatch(dashboardActions.setIsExporting(false))}
          sx={{ marginLeft: 12 }}
          type={ButtonTypeEnum.TERTIARY}
        >
          {t("buttons.cancel")}
        </Button>
      </FlexRowLayout>
    </Panel>
  )
}

const getLast30DaysFilters = (): QueryTransactionSearchCsvArgs["filters"] => ({
  datetime: {
    range: {
      startDatetime: DateTime.local().minus({ days: 30 }).toISODate(),
    },
  },
})
