import { createSelector } from "reselect"
import { IRootState } from "@core/redux/root-state"
import { IAddFundsState } from "./reducer"
import { validate } from "@utils/validate"

export const getAddFunds = (state: IRootState) => state.addFunds

export const selectAddFundsScreenHistory = createSelector(
  getAddFunds,
  (funds: IAddFundsState) => funds.screenHistory,
)

export const selectAddFundsFundingMethod = createSelector(
  getAddFunds,
  (funds: IAddFundsState) => funds.fundingMethod,
)

export const selectUserPhoneNumber = createSelector(
  getAddFunds,
  (funds: IAddFundsState) => funds.userPhoneNumber,
)

export const selectSMSTextLinkSendCapability = createSelector(
  getAddFunds,
  (funds: IAddFundsState) => funds.smsTextLinkSendIsPossibleFlag,
)

export const selectSMSTextSendStatus = createSelector(
  getAddFunds,
  (funds: IAddFundsState) => funds.smsTextSendStatus,
)

export const selectUserPhoneNumberIsValid = createSelector(selectUserPhoneNumber, (phone: string) =>
  validate.phoneNumber(phone),
)
