import React from "react"
import { useTranslation } from "react-i18next"

import { FlexColumnLayout, FlexRowLayout } from "@layouts/flex"
import { theme } from "@layouts/theme"
import { Box, Image } from "rebass"
import { images } from "@assets/images/images"
import { NORTHONE_SHARE_AND_EARN_TERMS_URL } from "@utils/constants"
import { ReferralsShare } from "../referrals-share"
import { SHARE_AND_EARN_HUB_TOP_SPACING } from "../share-and-earn-landing"
import { ShareAndEarnTitleAndBody } from "./title-and-body.component"

const HappyPathContent = ({ incentiveAmount }: { incentiveAmount: string }) => {
  const { t } = useTranslation()
  return (
    <ShareAndEarnTitleAndBody
      title={t("shareAndEarn.landing.title", { incentiveAmount })}
      bodyText={t("shareAndEarn.landing.body", { incentiveAmount })}
      bodyPartialStyling={{
        color: theme.colors.ui2,
        textDecoration: "underline",
        cursor: "pointer",
      }}
      bodyOnClick={() => window.open(NORTHONE_SHARE_AND_EARN_TERMS_URL, "_blank")}
    />
  )
}

export const HappyPath = ({ incentiveAmount }: { incentiveAmount: string }) => {
  return (
    <FlexRowLayout>
      <FlexColumnLayout sx={{ paddingTop: SHARE_AND_EARN_HUB_TOP_SPACING }} width="100%">
        <HappyPathContent incentiveAmount={incentiveAmount} />
        <ReferralsShare />
      </FlexColumnLayout>
      <Box paddingTop={30}>
        <Image width={365} src={images.shareAndEarnLanding} />
      </Box>
    </FlexRowLayout>
  )
}
