import i18n from "../../i18n/i18n"
import { TransactionStatusEnum, TransactionTypeEnum } from "../../generated/graphql"
import { images } from "../../assets/images/images"

interface ITransactionTypeDetails {
  outgoingType: string
  expectedDuration: string
  counterpartyLabel: string
  transactionFeedIcon?: string
  incomingType: string
}

export const getTransactionTypeDetails = (
  transactionType?: TransactionTypeEnum,
): ITransactionTypeDetails => {
  switch (transactionType) {
    case TransactionTypeEnum.ACH:
      return {
        outgoingType: i18n.t("transactions.types.ACH"),
        expectedDuration: i18n.t("transactions.expectedDuration.ACH"),
        counterpartyLabel: i18n.t("transactions.previewPanel.recipient"),
        incomingType: i18n.t("transactions.types.incoming"),
      }
    case TransactionTypeEnum.ACH_RETURN:
      return {
        outgoingType: i18n.t("transactions.types.ACH_RETURN"),
        expectedDuration: "",
        counterpartyLabel: i18n.t("transactions.previewPanel.description"),
        incomingType: i18n.t("transactions.types.ACH_RETURN"),
      }
    case TransactionTypeEnum.CARD_PURCHASE:
      return {
        outgoingType: i18n.t("transactions.types.CARD_PURCHASE"),
        expectedDuration: i18n.t("transactions.expectedDuration.CARD_PURCHASE"),
        counterpartyLabel: i18n.t("transactions.previewPanel.description"),
        incomingType: i18n.t("transactions.types.incoming"),
      }
    case TransactionTypeEnum.CHECK_DEPOSIT:
      return {
        outgoingType: i18n.t("transactions.types.CHECK_DEPOSIT"),
        expectedDuration: i18n.t("transactions.expectedDuration.CHECK_DEPOSIT"),
        counterpartyLabel: i18n.t("transactions.previewPanel.recipient"),
        incomingType: i18n.t("transactions.types.CHECK_DEPOSIT"),
      }
    case TransactionTypeEnum.DOMESTIC_WIRE:
      return {
        outgoingType: i18n.t("transactions.types.DOMESTIC_WIRE"),
        expectedDuration: i18n.t("transactions.expectedDuration.DOMESTIC_WIRE"),
        counterpartyLabel: i18n.t("transactions.previewPanel.recipient"),
        incomingType: i18n.t("transactions.types.incomingWire"),
      }
    case TransactionTypeEnum.GENERIC:
      return {
        outgoingType: i18n.t("transactions.types.outgoing"),
        expectedDuration: i18n.t("transactions.expectedDuration.GENERIC"),
        counterpartyLabel: i18n.t("transactions.previewPanel.description"),
        incomingType: i18n.t("transactions.types.incoming"),
      }
    case TransactionTypeEnum.SUB_ACCOUNT_TRANSFER:
      return {
        outgoingType: i18n.t("transactions.types.envelope"),
        expectedDuration: i18n.t("transactions.expectedDuration.SUB_ACCOUNT_TRANSFER"),
        counterpartyLabel: i18n.t("transactions.previewPanel.account"),
        transactionFeedIcon: images.icons.transferAvatar,
        incomingType: i18n.t("transactions.types.envelope"),
      }
    case TransactionTypeEnum.FEE:
      return {
        outgoingType: i18n.t("transactions.types.FEE"),
        expectedDuration: "",
        counterpartyLabel: i18n.t("transactions.previewPanel.description"),
        transactionFeedIcon: images.icons.fee,
        incomingType: i18n.t("transactions.types.FEE"),
      }
    case TransactionTypeEnum.WIRE_REVERSAL:
      return {
        outgoingType: i18n.t("transactions.types.WIRE_REVERSAL"),
        expectedDuration: "",
        counterpartyLabel: i18n.t("transactions.previewPanel.description"),
        incomingType: i18n.t("transactions.types.WIRE_REVERSAL"),
      }
    case TransactionTypeEnum.FEE_REVERSAL:
      return {
        outgoingType: i18n.t("transactions.types.FEE_REVERSAL"),
        expectedDuration: "",
        counterpartyLabel: i18n.t("transactions.previewPanel.description"),
        transactionFeedIcon: images.icons.fee,
        incomingType: i18n.t("transactions.types.FEE_REVERSAL"),
      }
    case TransactionTypeEnum.WIRE_RETURN:
      return {
        outgoingType: i18n.t("transactions.types.RETURNED_WIRE"),
        expectedDuration: "",
        counterpartyLabel: i18n.t("transactions.previewPanel.description"),
        incomingType: i18n.t("transactions.types.RETURNED_WIRE"),
      }
    case TransactionTypeEnum.RTP_RECEIVE:
      return {
        outgoingType: i18n.t("transactions.types.REAL_TIME_PAYMENT"),
        expectedDuration: "",
        counterpartyLabel: "",
        transactionFeedIcon: images.icons.realTimePayment,
        incomingType: i18n.t("transactions.types.REAL_TIME_PAYMENT"),
      }
    case TransactionTypeEnum.MAILED_CHECK:
      return {
        outgoingType: i18n.t("transactions.types.MAILED_CHECK"),
        expectedDuration: "",
        counterpartyLabel: "",
        incomingType: i18n.t("transactions.types.MAILED_CHECK"),
      }

    case TransactionTypeEnum.MAILED_CHECK_REFUND:
      return {
        outgoingType: i18n.t("transactions.types.MAILED_CHECK_REFUNDED"),
        expectedDuration: "",
        counterpartyLabel: "",
        incomingType: i18n.t("transactions.types.MAILED_CHECK_REFUNDED"),
      }
    case TransactionTypeEnum.BILL_PAYMENT:
      return {
        outgoingType: i18n.t("transactions.types.BILL_PAYMENT"),
        expectedDuration: i18n.t("transactions.exexpectedDurationpectedDuration.BILL_PAYMENT"),
        counterpartyLabel: i18n.t("transactions.previewPanel.recipient"),
        incomingType: i18n.t("transactions.types.BILL_PAYMENT"),
      }
    case TransactionTypeEnum.BILL_PAYMENT_REFUND:
      return {
        outgoingType: i18n.t("transactions.types.BILL_PAYMENT_REFUND"),
        expectedDuration: "",
        counterpartyLabel: i18n.t("transactions.previewPanel.description"),
        incomingType: i18n.t("transactions.types.BILL_PAYMENT_REFUND"),
      }
    case TransactionTypeEnum.LENDING:
      return {
        outgoingType: i18n.t("transactions.types.LENDING"),
        expectedDuration: "",
        counterpartyLabel: i18n.t("transactions.previewPanel.description"),
        transactionFeedIcon: images.icons.lendingTransaction,
        incomingType: i18n.t("transactions.types.LENDING"),
      }
    default:
      return {
        outgoingType: i18n.t("transactions.types.outgoing"),
        expectedDuration: "",
        counterpartyLabel: "",
        incomingType: i18n.t("transactions.types.incoming"),
      }
  }
}

export const getIsPendingTransaction = (status: TransactionStatusEnum) =>
  [TransactionStatusEnum.INITIATED, TransactionStatusEnum.CREATED].includes(status)

export const getTransactionTypeCopy = (transactionType: TransactionTypeEnum, amount: number) => {
  const transactionTypeDetails = getTransactionTypeDetails(transactionType)
  return amount > 0 ? transactionTypeDetails.incomingType : transactionTypeDetails.outgoingType
}

export const getTransactionIcons = (transactionType: TransactionTypeEnum) => {
  switch (transactionType) {
    case TransactionTypeEnum.ACH:
      return images.icons.transactions.ach
    case TransactionTypeEnum.CHECK_DEPOSIT:
      return images.icons.transactions.mailedCheck
    case TransactionTypeEnum.DOMESTIC_WIRE:
      return images.icons.transactions.domesticWire
    case TransactionTypeEnum.SUB_ACCOUNT_TRANSFER:
      return images.icons.transactions.transfer
    case TransactionTypeEnum.RTP_RECEIVE:
      return images.icons.transactions.realTimePayment
    case TransactionTypeEnum.CARD_PURCHASE:
    case TransactionTypeEnum.GENERIC:
    default:
      return images.icons.transactions.ach
  }
}
