import { useEffect, useState } from "react"
import { useTopUpsLazyQuery, TopUpSettings } from "@generated/graphql"
import { useAppSelector } from "@core/redux/utils"
import { ApolloError } from "@apollo/client"

export function useFindTopUpsItem({ cacheCheckKey }: { cacheCheckKey?: string }) {
  const [linkedTopUpPlaidItemId, setLinkedTopUpPlaidItemId] = useState<string>()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<ApolloError>()

  const businessId = useAppSelector((state) => state.activeBusiness.businessId)
  const accountId = useAppSelector(
    (state) => state.user.me?.ownerBusinesses.find((biz) => biz.id === businessId)?.bankAccount?.id,
  )

  const [getTopUpData] = useTopUpsLazyQuery({
    onCompleted: (data) => {
      if (loading) setLoading(false)

      const topUpSettings = data.topUpSettings as TopUpSettings
      if (!topUpSettings.isTopUpsEnabled) return

      const plaidItemId = topUpSettings?.plaidItem?.id
      if (plaidItemId && plaidItemId != linkedTopUpPlaidItemId)
        setLinkedTopUpPlaidItemId(plaidItemId)
    },
    onError: (error) => {
      if (loading) setLoading(false)
      setError(error)
    },
  })

  useEffect(() => {
    if (!accountId || !businessId) return
    getTopUpData({ variables: { businessId, accountId }, fetchPolicy: "network-only" })
  }, [cacheCheckKey, accountId, businessId])

  return {
    linkedTopUpPlaidItemId,
    loading,
    error,
  }
}
