import React from "react"
import { Image } from "rebass"
import { SelectorBox, ISelectorBoxProps } from "./selector-box"
import { FlexColumnLayout, FlexRowLayout, Text, theme } from "@northone/ui"

export interface IBusinessTypeSelectorBoxProps extends ISelectorBoxProps {
  title: string
  subtitle: string
  iconSrc: string
}

export const AccountTypeSelectorBox: React.FC<IBusinessTypeSelectorBoxProps> = ({
  title,
  subtitle,
  iconSrc,
  isSelected,
  onSelect,
  tabIndex,
}) => (
  <SelectorBox
    tabIndex={tabIndex}
    onSelect={onSelect}
    isSelected={isSelected}
    sx={{
      marginBottom: "16px",
      height: 80,
      display: "flex",
      alignItems: "center",
      outline: "none",
      width: "100%",
      backgroundColor: isSelected ? theme.colors.blue10 : theme.colors.ui6,
    }}
  >
    <FlexRowLayout
      dataTestId={`business-type-selectbox-${tabIndex}`}
      sx={{ alignItems: "flex-start", paddingX: "18px", flexShrink: 0 }}
    >
      {iconSrc && <Image src={iconSrc} sx={{ width: [32, 32, 25, 32] }} />}
      <FlexColumnLayout sx={{ marginLeft: "15px" }}>
        <Text tag="body-bold" sx={{ marginBottom: "5px" }}>
          {title}
        </Text>
        <Text tag="body-small" color={theme.colors.ui2} sx={{ width: "100%" }}>
          {subtitle}
        </Text>
      </FlexColumnLayout>
    </FlexRowLayout>
  </SelectorBox>
)
