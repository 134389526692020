import React from "react"
import { AlignedColumnLayout } from "../aligned-column-layout"
import { Text } from "@components/primitive/text/text"
import { theme } from "@layouts/theme"
import { OverflowListItem } from "../overflow/overflow-list-item"
import { FlexColumnLayout } from "@layouts/flex"
import { BusinessOwnerAvatar } from "@components/extended/avatar/business-owner-avatar"

export interface IBusinessOwnerListItemProps {
  name: string
  cardholderSubtitle?: string
  alternateCardText?: string
  isActive?: boolean
  cardNumber?: string | null
  disabled?: boolean | null
  actionItem?: React.ReactElement
}

export const BusinessOwnerListItemFR: React.FC<IBusinessOwnerListItemProps> = ({
  name,
  cardholderSubtitle,
  isActive,
  disabled,
  actionItem,
}) => {
  return (
    <OverflowListItem hasHoverState={actionItem ? true : false} sx={{ cursor: "default" }}>
      <AlignedColumnLayout widthRadio={0.1} alignment={"flex-start"}>
        <BusinessOwnerAvatar name={name} disabled={disabled} />
      </AlignedColumnLayout>
      <AlignedColumnLayout widthRadio={0.3} sidePadding={12} alignment={"flex-start"}>
        <FlexColumnLayout sx={{ marginLeft: 12 }}>
          <Text
            tag={isActive ? "body-small-bold" : "body-small"}
            textColor={disabled ? theme.colors.ui2 : undefined}
          >
            {name}
          </Text>
          {cardholderSubtitle && (
            <Text tag={"h5"} textColor={theme.colors.ui2}>
              {cardholderSubtitle}
            </Text>
          )}
        </FlexColumnLayout>
      </AlignedColumnLayout>
      <AlignedColumnLayout
        widthRadio={0.3}
        sidePadding={theme.spacing.listItemPadding}
        alignment={"flex-start"}
      ></AlignedColumnLayout>
      <AlignedColumnLayout widthRadio={0.3} alignment={"flex-end"}>
        {actionItem}
      </AlignedColumnLayout>
    </OverflowListItem>
  )
}
