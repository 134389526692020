import { ISupportCoverageNode } from "../../../features/support/types"
import { getChatSupportSchedule } from "./helpers"

export const getSupportSchedule = async () => {
  const supportSchedule = await getChatSupportSchedule()
  const nodes: ISupportCoverageNode[] = supportSchedule.data?.allSupport_coverages?.edges ?? []
  const scheduleNodes = nodes.map((hoursNode) => hoursNode.node)
  const schedule = scheduleNodes.map((scheduleNode) => scheduleNode.schedule)
  return schedule.length ? schedule[0] : undefined
}
