import React, { ReactNode, useState } from "react"
import Select, {
  CSSObjectWithLabel,
  DropdownIndicatorProps,
  FormatOptionLabelMeta,
} from "react-select"
import { SxStyleProp } from "rebass"
import { Field } from "@components/primitive/field/field"
import { images } from "@assets/images/images"
import { theme } from "@layouts/theme"
import { Image } from "rebass"
import { FlexRowLayout } from "@layouts/flex"

type TOptionValue = string | null

interface IDropdownOptionProps<T extends TOptionValue> {
  text: string
  value: T
  styles?: CSSObjectWithLabel
}

interface IDropdownProps<T extends TOptionValue, O extends IDropdownOptionProps<T>> {
  options: O[]
  customContainerStyle?: SxStyleProp
  selectedValue?: T
  onSelect: (value: T, item: O) => void
  label?: string
  disabled?: boolean
  placeholder?: string
  errorMessage?: string
  labelComponent?: React.ReactElement
  isLoading?: boolean
  formatOptionLabel?: (data: O, formatOptionLabelMeta: FormatOptionLabelMeta<O>) => ReactNode
}

type TDropdown = <T extends TOptionValue, O extends IDropdownOptionProps<T>>(
  props: IDropdownProps<T, O>,
) => React.ReactElement<IDropdownProps<T, O>>

export const Dropdown: TDropdown = <T extends TOptionValue, O extends IDropdownOptionProps<T>>({
  options,
  onSelect,
  selectedValue,
  label,
  placeholder,
  labelComponent,
  errorMessage,
  customContainerStyle,
  disabled,
  isLoading,
  formatOptionLabel,
}: IDropdownProps<T, O>) => {
  const [isFocused, setIsFocused] = useState(false)
  return (
    <Field
      errorMessage={errorMessage}
      isFocused={isFocused}
      label={label}
      containerSx={customContainerStyle}
      labelComponent={labelComponent}
      disabled={disabled}
    >
      <Select<O>
        options={options}
        value={options.find((option) => option.value === selectedValue)}
        placeholder={placeholder}
        isDisabled={disabled}
        isLoading={isLoading}
        getOptionLabel={(option) => option.text}
        getOptionValue={(option) => option.value ?? ""}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        formatOptionLabel={formatOptionLabel}
        onChange={(option) => {
          if (option?.value !== undefined) {
            onSelect(option.value, option)
          }
        }}
        components={{
          DropdownIndicator,
        }}
        styles={{
          control: (base) => ({
            ...base,
            backgroundColor: "transparent",
            borderRadius: 0,
            borderWidth: 0,
            boxShadow: "none",
            cursor: "pointer",
          }),
          placeholder: (base, state) => ({
            ...base,
            color: state.hasValue ? theme.colors.ui1 : theme.colors.ui3,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "calc(100% - 15px)",
          }),
          option: (base, state) => ({
            ...base,
            backgroundColor: state.isFocused
              ? theme.colors.ui6
              : state.isSelected
              ? theme.colors.ui5
              : theme.colors.ui7,
            color: theme.colors.ui1,
            cursor: "pointer",
            ":active": {
              backgroundColor: theme.colors.gold60,
            },
            ...state.data.styles,
          }),
          menu: (base) => ({
            ...base,
            borderRadius: 0,
            marginTop: 1,
            borderWidth: 0,
            boxShadow: "rgb(110 110 110 / 12%) 0px 4px 16px",
          }),
          singleValue: (base, state) => ({
            ...base,
            ...state.data.styles,
          }),
        }}
      />
    </Field>
  )
}

const DropdownIndicator = <T extends TOptionValue, O extends IDropdownOptionProps<T>>(
  props: DropdownIndicatorProps<O, false>,
) => {
  const image = props.selectProps.menuIsOpen ? images.icons.chevronUp : images.icons.chevronDown
  return (
    <FlexRowLayout
      {...props}
      sx={{
        width: 36,
        height: 36,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Image src={image} style={{ width: 20, height: 20 }} />
    </FlexRowLayout>
  )
}
