import gql from "graphql-tag"

export const CARDS_ACTIVATED_QUERY = gql`
  query cardsActivated($businessId: ID!) {
    business(businessId: $businessId) {
      id
      bankAccount {
        cards {
          id
          productType
          activation {
            activated
          }
        }
      }
    }
  }
`

export const ACH_CREATE = gql`
  mutation ACHCreate($businessId: ID!, $data: TransactionsACHCreateInput!) {
    transactionsACHCreate(businessId: $businessId, data: $data) {
      code
      success
    }
  }
`

export const ACH_RECIPIENTS = gql`
  query recipientsACH($businessId: ID!, $contactPaymentType: ContactPaymentType!) {
    business(businessId: $businessId) {
      id
      contacts(contactPaymentType: $contactPaymentType) {
        id
        businessId
        name
        achPaymentDetails {
          routingNumber
          accountNumber
        }
      }
    }
  }
`

export const ACH_RECIPIENT_CREATE = gql`
  mutation recipientDomesticACHCreate($data: ContactDomesticAchCreateDataInput!) {
    contactDomesticAchCreate(data: $data) {
      code
      success
      message
      contact {
        id
        businessId
        name
        achPaymentDetails {
          accountNumber
          routingNumber
        }
      }
    }
  }
`

export const ACH_RECIPIENT_UPDATE = gql`
  mutation recipientDomesticACHUpdate($data: ContactDomesticAchUpdateDataInput!) {
    contactDomesticAchUpdate(data: $data) {
      code
      success
      message
    }
  }
`

export const ACH_ROUTING_NUMBER_VALID = gql`
  query routingNumberACHIsValid($routingNumber: String!) {
    routingNumberACHIsValid(routingNumber: $routingNumber)
  }
`

export const DOMESTIC_WIRE_CREATE = gql`
  mutation domesticWireCreate($businessId: ID!, $data: DomesticWireCreateInput!) {
    domesticWireCreate(businessId: $businessId, data: $data) {
      code
      message
      success
    }
  }
`

export const DOMESTIC_WIRE_RECIPIENTS = gql`
  query recipientsForDomesticWire($businessId: ID!, $contactPaymentType: ContactPaymentType!) {
    business(businessId: $businessId) {
      id
      contacts(contactPaymentType: $contactPaymentType) {
        id
        businessId
        name
        relationship
        address {
          streetAddressLine1
          streetAddressLine2
          provinceState
          city
          postalCode
        }
        wirePaymentDetails {
          routingNumber
          accountNumber
          bankName
        }
      }
    }
  }
`

export const DOMESTIC_WIRE_RECIPIENT_CREATE = gql`
  mutation recipientDomesticWireCreate($data: ContactDomesticWireCreateDataInput!) {
    contactDomesticWireCreate(data: $data) {
      code
      success
      message
      contact {
        id
        businessId
        name
        relationship
        address {
          streetAddressLine1
          streetAddressLine2
          provinceState
          city
          postalCode
        }
        wirePaymentDetails {
          accountNumber
          routingNumber
          bankName
        }
      }
    }
  }
`

export const DOMESTIC_WIRE_RECIPIENT_UPDATE = gql`
  mutation recipientDomesticWireUpdate($data: ContactDomesticWireUpdateDataInput!) {
    contactDomesticWireUpdate(data: $data) {
      code
      success
      message
    }
  }
`

export const WIRE_ROUTING_NUMBER_VALID = gql`
  query routingNumberWireIsValid($routingNumber: String!) {
    routingNumberWireIsValid(routingNumber: $routingNumber)
  }
`

export const TIME_SAVED_QUERY = gql`
  query timeSaved($businessId: ID!) {
    timeSaved(businessId: $businessId) {
      perMailedCheckTimeSavedSeconds
      perSameDayAchTimeSavedSeconds
      perStandardAchTimeSavedSeconds
      perWireTimeSavedSeconds
      timeSavedTotalSeconds
      pdfGuidelineUrl
    }
  }
`

export const TRANSACTION_FEES = gql`
  query transactionFees($businessId: ID!, $amount: Float!) {
    transactionFees(businessId: $businessId, amount: $amount) {
      domesticWire
      mailedCheck
      sameDayAch
      billPay
    }
  }
`
