import { DateTime } from "luxon"
import React from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { RoutesEnum } from "@routers/types"
import i18n from "@i18n/i18n"
import { LongTextInput } from "@components/extended/fields/long-text-input"
import { Text } from "@components/primitive/text/text"
import { Button } from "@components/primitive/button/button"
import { ButtonTypeEnum } from "@components/primitive/button/button"
import { FlexColumnLayout, FlexRowLayout } from "@layouts/flex"
import { MAX_INFO_CHARACTER_LENGTH } from "@utils/constants"
import { SupportPhoneCallSlots } from "@generated/graphql"
import { TScheduleCallTopics, TTopicOption } from "./schedule-call"

export const formatDateDropdown = (data: SupportPhoneCallSlots) => {
  const dateString = DateTime.fromSeconds(Number(data.date), { zone: "UTC+4" }).toFormat(
    "EEE MMM. d",
  )
  return data.slots && data.slots > 0
    ? dateString
    : i18n.t("support.scheduleCall.labels.fullyBooked", { dateString })
}

export const formatTime = (time: string) => {
  return DateTime.fromSeconds(Number(time)).toFormat("h:mm a").toLowerCase()
}

export const OnlineChatCopy: React.FC = () => {
  const { t } = useTranslation()
  const onlineChatTopics: string[] = t("support.scheduleCall.onlineChatItems", {
    returnObjects: true,
  })
  return (
    <FlexColumnLayout sx={{ marginTop: 24 }}>
      <Text tag={"body"}>{t("support.scheduleCall.connect")}</Text>
      <ul>
        {onlineChatTopics.map((topic, idx) => (
          <li key={idx}>
            <Text tag="body">{topic}</Text>
          </li>
        ))}
      </ul>
      <Text tag="body" hasPartialBolding>
        {t("support.scheduleCall.pleaseMessage")}
      </Text>
    </FlexColumnLayout>
  )
}

export const ScheduleCallInfo: React.FC<{
  topic: TTopicOption
  additionalInfo: string
  setAdditionalInfo: React.Dispatch<React.SetStateAction<string>>
}> = ({ topic, additionalInfo, setAdditionalInfo }) => {
  const { t } = useTranslation()
  const onlineChatTopics: TTopicOption[] = (
    ["close", "changeAddressOrAccountInfo", "debitCard"] as TScheduleCallTopics[]
  ).map((topic) => ({
    text: t(`support.scheduleCall.topics.${topic}`),
    value: topic,
  }))
  if (onlineChatTopics.filter((t) => t.value === topic.value).length) {
    return (
      <FlexRowLayout sx={{ marginTop: 24 }}>
        <OnlineChatCopy />
      </FlexRowLayout>
    )
  } else if (topic.value === "other") {
    return (
      <FlexRowLayout sx={{ marginTop: 24 }}>
        <LongTextInput
          errorMessage={
            additionalInfo.length > MAX_INFO_CHARACTER_LENGTH
              ? t("support.errors.maxLengthExceeded", { maxLength: MAX_INFO_CHARACTER_LENGTH })
              : undefined
          }
          label={t("support.scheduleCall.labels.additionalInfoLabel")}
          placeholder={t("support.scheduleCall.labels.additionalInfoPlaceholder")}
          value={additionalInfo}
          onChange={setAdditionalInfo}
        />
      </FlexRowLayout>
    )
  } else {
    return null
  }
}

export const RequestSent: React.FC<{
  clearState: () => void
  timeSlot: string
  timezone: string
}> = ({ clearState, timeSlot, timezone }) => {
  const history = useHistory()
  const { t } = useTranslation()
  return (
    <>
      <Text tag="body" sx={{ maxWidth: 560 }}>
        {t("support.scheduleCall.requestSent", {
          timeslot: formatTime(timeSlot),
          timezone,
          date: DateTime.fromSeconds(Number(timeSlot)).toLocaleString({
            weekday: "long",
            month: "long",
            day: "numeric",
          }),
        })}
      </Text>
      <Button
        type={ButtonTypeEnum.SECONDARY}
        onClick={() => {
          history.push(RoutesEnum.SUPPORT_OVERVIEW)
          clearState()
        }}
        sx={{ marginTop: 24 }}
      >
        {t("support.scheduleCall.done")}
      </Button>
    </>
  )
}
