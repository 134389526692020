import React from "react"
import { FlexRowLayout } from "@layouts/flex"

interface AlignedColumnLayoutProps {
  children?: React.ReactNode
  alignment: "flex-start" | "flex-end"
  sidePadding?: number
  widthRadio: number
}

export const AlignedColumnLayout = ({
  alignment,
  sidePadding,
  widthRadio,
  children,
}: AlignedColumnLayoutProps) => (
  <FlexRowLayout
    sx={{
      width: `${widthRadio * 100}%`,
      justifyContent: alignment,
      alignItems: "center",
      paddingLeft: alignment === "flex-start" ? 0 : sidePadding,
      paddingRight: alignment === "flex-end" ? 0 : sidePadding,
    }}
  >
    {children}
  </FlexRowLayout>
)
