import gql from "graphql-tag"

export const LIST_REVENUE_HUB_CONNECTIONS = gql`
  query listRevenueHubConnections($businessId: ID!) {
    revenueHub(businessId: $businessId) {
      connections {
        id
        platform
        status
      }
    }
  }
`

export const SAVE_REVENUE_HUB_CONNECTION = gql`
  mutation saveRevenueHubConnection($input: RevenueHubConnectionSaveInput!) {
    revenueHubConnectionSave(input: $input) {
      code
      success
      message
      connection {
        id
        platform
        status
      }
    }
  }
`

export const GET_REVENUE_HUB_CONNECTION = gql`
  query getRevenueHubConnection(
    $businessId: ID!
    $connectionId: ID!
    $filters: RevenueHubConnectionFiltersInput!
  ) {
    revenueHub(businessId: $businessId) {
      connection(id: $connectionId) {
        id
        status
        linkUpdateUrl
        revenueDetails(filters: $filters) {
          currentBalance
          grossRevenuePerDayTimeSeries {
            date
            totalRevenueAmount
          }
          totalGrossRevenue
          totalPaidOutAmount
          totalReturnsAmount
          transactionsCount
        }
      }
    }
  }
`

export const GET_REVENUE_HUB_CONNECTION_LINK_UPDATE_URL = gql`
  query getRevenueHubConnectionLinkUpdateUrl($businessId: ID!, $connectionId: ID!) {
    revenueHub(businessId: $businessId) {
      connection(id: $connectionId) {
        id
        linkUpdateUrl
      }
    }
  }
`

export const DELETE_REVENUE_HUB_CONNECTION = gql`
  mutation revenueHubConnectionDelete($businessId: ID!, $connectionId: ID!) {
    revenueHubConnectionDelete(businessId: $businessId, connectionId: $connectionId) {
      code
      success
      message
    }
  }
`
