import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { motion } from "framer-motion"
import { Box } from "rebass"

import { AnimatePresence } from "@components/animations/animate-presence/animation-presence"
import { useAppSelector } from "@core/redux/utils"
import { selectActiveBusinessID } from "@core/active-business/redux/selectors"
import { useShareAndEarn } from "@features/share-and-earn/hooks"
import { APP_CONTAINER_ID, LEFT_ASIDE_CONTAINER_ID } from "@layouts/app-layout"
import { RIGHT_ASIDE_CONTAINER_ID } from "@layouts/base-layout"
import { FlexRowLayout } from "@layouts/flex"
import { WithMaxWidth } from "@layouts/max-width"
import { theme } from "@layouts/theme"
import { RoutesEnum } from "@routers/types"
import { useScrollTop } from "@utils/hooks"
import { getHorizontalGap } from "@utils/styling"
import { InitialLoader } from "../../../App"

import { NavBarLink } from "./nav-bar-link"
import { PerksButton } from "./perks-button"
import { HIGHEST_ZINDEX_POSSIBLE } from "@utils/constants"
import { useDispatch } from "react-redux"
import { shareAndEarnActions } from "@features/share-and-earn/redux/actions"
import { LogoutModalButton } from "./logout-prompt-modal"
import { MyPlanButton } from "./subscriptions-button"

const useGetNavBarLinks = () => {
  const { t } = useTranslation()

  const links = [
    {
      text: t("navBar.dashboard"),
      to: RoutesEnum.DASHBOARD,
    },
    {
      text: t("navBar.cards"),
      to: RoutesEnum.CARDS,
    },
    {
      text: t("navBar.invoicePayments"),
      to: RoutesEnum.INVOICES,
    },
    {
      text: t("navBar.revenue"),
      to: RoutesEnum.REVENUE_HUB,
    },
    {
      text: t("navBar.integrations"),
      to: RoutesEnum.INTEGRATIONS,
    },
    {
      text: t("navBar.lending"),
      to: RoutesEnum.LENDING,
    },
    {
      id: "main-settings-page",
      text: t("navBar.settings"),
      to: RoutesEnum.SETTINGS_TEAM_BANKING,
    },
    {
      text: t("navBar.support"),
      to: RoutesEnum.SUPPORT_OVERVIEW,
    },
  ]
  return links
}

export const NAV_BAR_IDS = {
  container: "nav-bar-id",
  perksButton: "perks-button",
  shareAndEarnButton: "share-and-earn-button",
  logOutButton: "log-out-button",
  plansButton: "manage-plans-button",
}

export const NavBar: React.FC = React.memo(
  function NavBar() {
    const dispatch = useDispatch()

    const [contentScrollTop, setContentScrollTop] = useState(0)
    const [rightAsideScrollTop, setRightAsideScrollTop] = useState(0)
    const [leftAsideScrollTop, setLeftAsideScrollTop] = useState(0)

    const links = useGetNavBarLinks()

    useScrollTop(APP_CONTAINER_ID, setContentScrollTop)
    useScrollTop(RIGHT_ASIDE_CONTAINER_ID, setRightAsideScrollTop)
    useScrollTop(LEFT_ASIDE_CONTAINER_ID, setLeftAsideScrollTop)

    const isScrolling =
      contentScrollTop > 0 ||
      rightAsideScrollTop > theme.spacing.sidebar ||
      leftAsideScrollTop > theme.spacing.sidebar

    const businessId = useAppSelector(selectActiveBusinessID)
    const { loading } = useShareAndEarn(businessId)

    // TODO: Delete share and earn code
    const shareAndEarnFeatureEnabled = false
    useEffect(() => {
      dispatch(
        shareAndEarnActions.setShareAndEarnData({
          featureFlagEnabled: shareAndEarnFeatureEnabled,
        }),
      )
    }, [dispatch, shareAndEarnFeatureEnabled])

    if (loading) {
      return <InitialLoader sx={{ zIndex: HIGHEST_ZINDEX_POSSIBLE, background: "white" }} />
    }

    return (
      <AnimatePresence style={{ height: "100%" }} withoutWrapper animation="fadeIn">
        <FlexRowLayout
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            height: theme.heights.navBar,
            backgroundColor: isScrolling ? theme.colors.ui7 : "transparent",
            zIndex: theme.zIndex.navBar,
          }}
          id={NAV_BAR_IDS.container}
        >
          <motion.div
            animate={{
              boxShadow: isScrolling
                ? "0px 2px 6px rgba(0, 0, 0, 0.08)"
                : "0px 2px 5px rgba(0, 0, 0, 0)",
            }}
            style={{ width: "100%" }}
          >
            <WithMaxWidth
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <FlexRowLayout>
                {links.map((linksProps, idx) => (
                  <Box key={idx}>
                    <NavBarLink
                      {...linksProps}
                      sx={{ ...getHorizontalGap(idx + 1, links.length, 36) }}
                      routeMatchIsExact={linksProps.to === RoutesEnum.DASHBOARD}
                    />
                  </Box>
                ))}
              </FlexRowLayout>
              <FlexRowLayout alignItems="center">
                <MyPlanButton id={NAV_BAR_IDS.plansButton} />
                <PerksButton id={NAV_BAR_IDS.perksButton} />
                <LogoutModalButton />
              </FlexRowLayout>
            </WithMaxWidth>
          </motion.div>
        </FlexRowLayout>
      </AnimatePresence>
    )
  },
  () => true,
)
