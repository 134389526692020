import React from "react"
import { Text as RText, Box, SxStyleProp, TextProps as RTextProps, Link } from "rebass"
import "@assets/fonts/fonts.css"
import { theme } from "@layouts/theme"

export type TTextTag =
  | "h1"
  | "h2"
  | "h3"
  | "h3Serif"
  | "h4"
  | "h5"
  | "body-large"
  | "body"
  | "body-small"
  | "body-large-bold"
  | "body-bold"
  | "body-small-bold"
  | "body-x-small"
  | "label"
  | "label-bold"
  | "amount-input"

export interface ITextWithEmbeddedLinksProps {
  text: string
  linksInOrder: string[]
  linkStyle?: SxStyleProp
}

export interface ITextProps extends RTextProps {
  tag: TTextTag
  textColor?: string
  textRef?: React.RefObject<HTMLDivElement>
  hasPartialBolding?: boolean
  textWithEmbeddedLinksProps?: ITextWithEmbeddedLinksProps
}

const designSystemFontToTag: { [key in TTextTag]: React.ElementType } = {
  h1: "h1",
  h2: "h2",
  h3: "h3",
  h3Serif: "h3",
  h4: "h4",
  h5: "h5",
  "body-large": "p",
  body: "p",
  "body-small": "p",
  "body-large-bold": "p",
  "body-bold": "p",
  "body-small-bold": "p",
  label: "p",
  "label-bold": "p",
  "amount-input": "p",
  "body-x-small": "p",
}

export const getTextWithLineBreaks = (text: string, hasPartialBolding?: boolean) =>
  text.split(/\n/).map((t, index, arr) => (
    <React.Fragment key={index}>
      <span>{hasPartialBolding ? getBoldedAndNonBoldedText(t) : t}</span>
      {index !== arr.length - 1 && (
        <Box as="br" sx={{ height: 0, display: ["inline", "inline"] }} />
      )}
    </React.Fragment>
  ))

// This makes text wrapped in inside *'s *BOLD*
export const getBoldedAndNonBoldedText = (text: string) =>
  text.split("*").map((word, index) =>
    index % 2 ? (
      <strong key={index} style={{ fontWeight: 500 }}>
        {word}
      </strong>
    ) : (
      word
    ),
  )

/**
 * This function returns a formatted string given text containing embedded
 * links wrapped inside __'s. For example, "This is \___link 1\___ and this is \___link 2\___".
 *
 * @param {string} text - The text string
 * @param {string[]} linksInOrder - The links to be used in order
 * @param {SxStyleProp} linkStyle - The style to apply to the link component
 */
export const getTextWithEmbeddedLinks = (
  textWithEmbeddedLinksProps: ITextWithEmbeddedLinksProps,
) => {
  const { text, linksInOrder, linkStyle } = textWithEmbeddedLinksProps
  // Check for malformed input text
  if (text.length === 0 || (text.split("__").length - 1) % 2 !== 0) return <></>
  let count = 0

  const sentence = text.split("__").map((item, index) => {
    if (index % 2 === 0) return item
    const href = linksInOrder[count]
    const target = href && href.includes("javascript") ? "_self" : "_target"
    count++
    return (
      <Link key={index} sx={{ ...linkStyle }} href={href} target={target}>
        {item}
      </Link>
    )
  })

  return <>{sentence}</>
}

/**
 * Defines a primitive text component - Must be extended
 * Primitive Text defines typography and font styles per design system
 * @param props.children
 */
export const Text: React.FC<Omit<ITextProps, "css">> = ({
  children,
  tag,
  textColor,
  sx,
  textRef,
  hasPartialBolding,
  textWithEmbeddedLinksProps,
  as,
  ...textProps
}) => {
  const getContents = () => {
    if (typeof children === "string") {
      return getTextWithLineBreaks(children, hasPartialBolding)
    }
    if (textWithEmbeddedLinksProps) {
      return getTextWithEmbeddedLinks(textWithEmbeddedLinksProps)
    }
    return children
  }
  return (
    <RText
      ref={textRef}
      {...textProps}
      as={as || designSystemFontToTag[tag]}
      sx={{
        color: textColor || theme.colors.ui1,
        width: "auto",
        display: "inline-block",
        ...theme.textStyles[tag],
        ...sx,
      }}
    >
      {getContents()}
    </RText>
  )
}
