import React from "react"
import { Box } from "rebass"
import { Shimmer } from "@components/primitive/shimmer/shimmer"
import { theme } from "@layouts/theme"
import { FlexColumnLayout } from "@layouts/flex"
import { TListItemHeight } from "@components/primitive/list-item/list-item"

interface IListLoadingShimmerProps {
  listCount: number
  listItemHeight?: TListItemHeight
}

export const ListLoadingShimmer: React.FC<IListLoadingShimmerProps> = ({
  listCount,
  listItemHeight = "large",
}) => {
  return (
    <Box>
      {[...Array(listCount)].map((_, idx) => {
        return (
          <FlexColumnLayout
            key={idx}
            sx={{ height: theme.heights.listItems[listItemHeight] + 1, justifyContent: "center" }}
          >
            <Shimmer customHeight={17} sx={{ borderRadius: "4px" }} />
          </FlexColumnLayout>
        )
      })}
    </Box>
  )
}
