import gql from "graphql-tag"

/**
 * TODO: [DEV-507] Review the query to address warnings in the console regarding missing fields.
 * https://northone.atlassian.net/browse/DEV-507
 **/
export const TRANSACTIONS_LIST = gql`
  query transactionsList(
    $businessId: ID!
    $pagination: TransactionsPaginationOptionsInput
    $filters: TransactionsListFiltersInput
  ) {
    transactionsList(pagination: $pagination, filters: $filters, businessId: $businessId) {
      pagination {
        cursor
      }
      transactionListItems {
        id
        description
        amount
        status
        createdAt
        transactionType
        errorMessage
        isBancorpUpgradeDeposit
        returnedBy
      }
    }
    me {
      id
      wasExistingRadiusUser
      ownerBusinesses {
        id
        onboarding {
          id: businessId
          initialFundingStatus
        }
      }
    }
    business(businessId: $businessId) {
      id
      bankAccount {
        id
        availableBalance
      }
    }
  }
`
