// import i18n from "@i18n/i18n"
import { regex } from "@utils/regex"
import { americanStates } from "@utils/constants"
import { IAddress } from "@features/move-money/redux/state"
import {
  RECIPIENT_NAME_MAX_LENGTH_ERROR,
  RECIPIENT_ADDRESS_LINE_ONE_MAX_LENGTH_ERROR,
  RECIPIENT_ADDRESS_LINE_TWO_MAX_LENGTH_ERROR,
  RECIPIENT_CITY_MAX_LENGTH_ERROR,
  RECIPIENT_POSTAL_CODE_LENGTH_ERROR,
  RECIPIENT_POSTAL_CODE_INVALID_CHARACTER,
  RECIPIENT_BUSINESS_REASON_MAX_LENGTH_ERROR,
  RECIPIENT_BUSINESS_REASON_MIN_LENGTH_ERROR,
  RECIPIENT_BANK_NAME_MAX_LENGTH_ERROR,
  RECIPIENT_ROUTING_NUMBER_INVALID_LENGTH,
  RECIPIENT_ROUTING_NUMBER_INVALID_CHARACTER,
  RECIPIENT_DETAILS_ROUTING_NUMBER_INVALID,
  RECIPIENT_ACCOUNT_NUMBER_INVALID_LENGTH,
  RECIPIENT_ACCOUNT_NUMBER_INVALID_CHARACTER,
  RECIPIENT_INVALID_CHARACTER,
} from "@features/move-money/utils/i18n-translations"
import { wireConstants } from "./constants"

/**
 * Validate address values inputs from an input form.
 * Each function will return true if the value passed in is valid according to it's own rules.
 */
export const validate = {
  minLength: (value: string, minLength: number) => Boolean(value.length > minLength),
  maxLength: (value: string, maxLength: number) => Boolean(value.length <= maxLength),
  noSpecialCharacter: (value: string) =>
    Boolean(regex.isAlphanumericSpacePeriodCommaHyphen.test(value)),
  onlyNumbers: (value: string) => Boolean(regex.onlyNumbers.test(value)),
  name: (name?: string | null) =>
    Boolean(
      name &&
        validate.maxLength(name, wireConstants.RECIPIENT_NAME_MAX_LENGTH) &&
        validate.noSpecialCharacter(name),
    ),
  streetAddressLine1: (streetAddressLine1?: string | null) =>
    Boolean(
      streetAddressLine1 &&
        validate.maxLength(
          streetAddressLine1,
          wireConstants.RECIPIENT_ADDRESS_LINE_ONE_MAX_LENGTH,
        ) &&
        validate.noSpecialCharacter(streetAddressLine1),
    ),
  streetAddressLine2: (streetAddressLine2?: string | null) =>
    Boolean(
      !streetAddressLine2 ||
        (streetAddressLine2 &&
          validate.maxLength(
            streetAddressLine2,
            wireConstants.RECIPIENT_ADDRESS_LINE_TWO_MAX_LENGTH,
          ) &&
          validate.noSpecialCharacter(streetAddressLine2)),
    ),
  city: (city?: string | null) =>
    Boolean(
      city &&
        validate.maxLength(city, wireConstants.RECIPIENT_CITY_MAX_LENGTH) &&
        validate.noSpecialCharacter(city),
    ),
  postalCode: (postalCode?: string | null) =>
    Boolean(
      postalCode &&
        validate.onlyNumbers(postalCode) &&
        (postalCode.length === wireConstants.RECIPIENT_POSTAL_CODE_MIN_LENGTH ||
          postalCode.length === wireConstants.RECIPIENT_POSTAL_CODE_MAX_LENGTH),
    ),
  provinceState: (provinceState?: string | null) =>
    Boolean(provinceState && americanStates.find(({ value }) => value === provinceState)),
  businessReason: (businessReason?: string | null) =>
    Boolean(
      businessReason &&
        validate.maxLength(businessReason, wireConstants.RECIPIENT_BUSINESS_REASON_MAX_LENGTH) &&
        validate.minLength(businessReason, wireConstants.RECIPIENT_BUSINESS_REASON_MIN_LENGTH) &&
        validate.noSpecialCharacter(businessReason),
    ),
  bankName: (bankName?: string | null) =>
    Boolean(
      bankName &&
        validate.maxLength(bankName, wireConstants.RECIPIENT_BANK_NAME_MAX_LENGTH) &&
        validate.noSpecialCharacter(bankName),
    ),
  routingNumber: (routingNumber?: string | null) =>
    Boolean(
      routingNumber &&
        validate.onlyNumbers(routingNumber) &&
        routingNumber.length === wireConstants.RECIPIENT_ROUTING_NUMBER_LENGTH,
    ),
  accountNumber: (accountNumber?: string | null) =>
    Boolean(
      accountNumber &&
        validate.onlyNumbers(accountNumber) &&
        validate.minLength(accountNumber, wireConstants.RECIPIENT_ACCOUNT_NUMBER_MIN_LENGTH) &&
        validate.maxLength(accountNumber, wireConstants.RECIPIENT_ACCOUNT_NUMBER_MAX_LENGTH),
    ),
  address: (address?: IAddress) =>
    Boolean(
      address &&
        validate.streetAddressLine1(address.streetAddressLine1) &&
        validate.streetAddressLine2(address.streetAddressLine2) &&
        validate.city(address.city) &&
        validate.provinceState(address.provinceState) &&
        validate.postalCode(address.postalCode),
    ),
}

export const errorMessages = {
  name: (name: string) => {
    if (!validate.noSpecialCharacter(name)) return RECIPIENT_INVALID_CHARACTER
    if (!validate.maxLength(name, wireConstants.RECIPIENT_NAME_MAX_LENGTH))
      return RECIPIENT_NAME_MAX_LENGTH_ERROR
  },
  streetAddressLine1: (streetAddressLine1: string) => {
    if (!validate.noSpecialCharacter(streetAddressLine1)) return RECIPIENT_INVALID_CHARACTER
    if (
      !validate.maxLength(streetAddressLine1, wireConstants.RECIPIENT_ADDRESS_LINE_ONE_MAX_LENGTH)
    )
      return RECIPIENT_ADDRESS_LINE_ONE_MAX_LENGTH_ERROR
  },
  streetAddressLine2: (streetAddressLine2: string) => {
    if (!validate.noSpecialCharacter(streetAddressLine2)) return RECIPIENT_INVALID_CHARACTER
    if (!validate.streetAddressLine2(streetAddressLine2))
      return RECIPIENT_ADDRESS_LINE_TWO_MAX_LENGTH_ERROR
  },
  city: (city: string) => {
    if (!validate.noSpecialCharacter(city)) return RECIPIENT_INVALID_CHARACTER
    if (!validate.maxLength(city, wireConstants.RECIPIENT_CITY_MAX_LENGTH))
      return RECIPIENT_CITY_MAX_LENGTH_ERROR
  },
  postalCode: (postalCode: string) => {
    if (!validate.onlyNumbers(postalCode)) return RECIPIENT_POSTAL_CODE_INVALID_CHARACTER
    if (
      postalCode.length !== wireConstants.RECIPIENT_POSTAL_CODE_MIN_LENGTH &&
      postalCode.length !== wireConstants.RECIPIENT_POSTAL_CODE_MAX_LENGTH
    )
      return RECIPIENT_POSTAL_CODE_LENGTH_ERROR
  },
  businessReason: (businessReason: string) => {
    if (!validate.noSpecialCharacter(businessReason)) return RECIPIENT_INVALID_CHARACTER
    if (!validate.minLength(businessReason, wireConstants.RECIPIENT_BUSINESS_REASON_MIN_LENGTH))
      return RECIPIENT_BUSINESS_REASON_MIN_LENGTH_ERROR
    if (!validate.maxLength(businessReason, wireConstants.RECIPIENT_BUSINESS_REASON_MAX_LENGTH))
      return RECIPIENT_BUSINESS_REASON_MAX_LENGTH_ERROR
  },
  bankName: (bankName: string) => {
    if (!validate.noSpecialCharacter(bankName)) return RECIPIENT_INVALID_CHARACTER
    if (!validate.maxLength(bankName, wireConstants.RECIPIENT_BANK_NAME_MAX_LENGTH))
      return RECIPIENT_BANK_NAME_MAX_LENGTH_ERROR
  },
  routingNumber: (routingNumber: string, routingNumberIsValid: boolean) => {
    if (!validate.onlyNumbers(routingNumber)) return RECIPIENT_ROUTING_NUMBER_INVALID_CHARACTER
    if (routingNumber.length !== wireConstants.RECIPIENT_ROUTING_NUMBER_LENGTH)
      return RECIPIENT_ROUTING_NUMBER_INVALID_LENGTH
    if (!routingNumberIsValid) return RECIPIENT_DETAILS_ROUTING_NUMBER_INVALID
  },
  accountNumber: (accountNumber: string) => {
    if (!validate.onlyNumbers(accountNumber)) return RECIPIENT_ACCOUNT_NUMBER_INVALID_CHARACTER
    if (
      !validate.minLength(accountNumber, wireConstants.RECIPIENT_ACCOUNT_NUMBER_MIN_LENGTH) ||
      !validate.maxLength(accountNumber, wireConstants.RECIPIENT_ACCOUNT_NUMBER_MAX_LENGTH)
    )
      return RECIPIENT_ACCOUNT_NUMBER_INVALID_LENGTH
  },
}
