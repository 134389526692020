import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { formatMoney } from "accounting"
import { Text } from "@northone/ui"
import { useAppSelector } from "@core/redux/utils"
import { selectActiveBusinessID } from "@core/active-business/redux/selectors"
import { Analytics } from "@core/analytics/actions"
import { events } from "@core/analytics/events"
import { Modal } from "@components/extended/overlay/modal"
import { InfoBlock } from "@components/composite/info-block/info-block"
import { LongTextInput } from "@components/extended/fields/long-text-input"
import { ButtonTypeEnum } from "@components/primitive/button/button"
import { useMarkInvoiceAccountsPayableAsPaidMutation } from "@generated/graphql"
import { theme } from "@layouts/theme"
import { TInvoiceAccountsPayable } from "@features/invoice-payments/redux/reducer"

interface IPayInvoiceAccountPayableProps {
  isOpen: boolean
  closeOverlay: () => void
  invoice: TInvoiceAccountsPayable
  onInvoiceUpdateCompleted: ({ message }: { message: string }) => void
}

export const MarkInvoicePaidConfirmationModal = ({
  isOpen,
  closeOverlay,
  invoice,
  onInvoiceUpdateCompleted,
}: IPayInvoiceAccountPayableProps) => {
  const { t } = useTranslation()

  const [reason, setReason] = useState<string>("")
  const [hasError, setHasError] = useState<boolean>(false)
  const [updateInProgress, setUpdateInProgress] = useState<boolean>(false)

  const businessId = useAppSelector(selectActiveBusinessID)
  const { id: invoiceId, amount, paidAmount } = invoice

  const [markInvoiceAsPaid, { loading: isMarkInvoicePaidLoading }] =
    useMarkInvoiceAccountsPayableAsPaidMutation({
      variables: { businessId, invoiceId, reason },
      onError: () => setHasError(true),
    })

  const invoiceHasNoPayments = !amount || !paidAmount

  const titleElement = (
    <Text
      tag="h3Serif"
      partialStyling={{
        color: theme.colors.gold100,
      }}
      sx={{
        marginBottom: "20px",
      }}
    >
      {invoiceHasNoPayments
        ? t("invoicePayments.markInvoicePaid.invoiceWithNoPayments.title")
        : t("invoicePayments.markInvoicePaid.invoiceWithPayments.title", {
            remainingAmount: formatMoney(amount - paidAmount),
          })}
    </Text>
  )

  const subtitleElement = (
    <>
      <Text tag="body-large" sx={{ marginBottom: "20px" }}>
        {t("invoicePayments.markInvoicePaid.subtitle")}
      </Text>

      <Text tag="body-large" sx={{ marginBottom: "20px" }}>
        {invoiceHasNoPayments
          ? t("invoicePayments.markInvoicePaid.invoiceWithNoPayments.note")
          : t("invoicePayments.markInvoicePaid.invoiceWithPayments.note", {
              paidAmount: formatMoney(paidAmount || 0),
            })}
      </Text>

      <LongTextInput
        value={reason}
        onChange={setReason}
        label={t("invoicePayments.markInvoicePaid.reasonLabel")}
        placeholder={t("invoicePayments.markInvoicePaid.reasonPlaceholder")}
      />
    </>
  )
  return (
    <Modal isOpen={isOpen} onOutsideClick={closeOverlay}>
      <InfoBlock
        title={titleElement}
        subtitle={subtitleElement}
        errorMessage={hasError ? t("invoicePayments.markInvoicePaid.errorMessage") : ""}
        buttons={[
          {
            type: ButtonTypeEnum.SECONDARY,
            children: t("invoicePayments.markInvoicePaid.cancelButton"),
            onClick: closeOverlay,
          },
          {
            type: ButtonTypeEnum.PRIMARY_BLUE,
            children: t("invoicePayments.markInvoicePaid.confirmButton"),
            onClick: async () => {
              Analytics.track(events.invoicePayments.invoiceQuickview.markAsPaidConfirmationClick)
              setHasError(false)

              try {
                await markInvoiceAsPaid()
                setUpdateInProgress(true)
                await onInvoiceUpdateCompleted({
                  message: t("invoicePayments.markInvoicePaid.notificationMessage"),
                })
              } finally {
                setUpdateInProgress(false)
              }
            },
            isLoading: isMarkInvoicePaidLoading || updateInProgress,
          },
        ]}
      />
    </Modal>
  )
}
