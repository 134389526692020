import React from "react"
import { Box, SxStyleProp } from "rebass"
import { LoadingIcon } from "@northone/ui"

//TODO: Update loading icons in Cards to use this shared loading component
const LoadingComponent = ({
  marginTop = 0,
  dimension = 74,
  containerSx,
}: {
  marginTop?: number
  dimension?: number
  containerSx?: SxStyleProp
}) => {
  return (
    <Box sx={{ marginTop, ...containerSx }}>
      <LoadingIcon dimension={dimension} />
    </Box>
  )
}

export default LoadingComponent
