import React, { useState } from "react"
import { DateTime } from "luxon"
import copy from "copy-to-clipboard"
import { formatMoney } from "accounting"
import { useTranslation } from "react-i18next"

import { images } from "@assets/images/images"
import { Button, ButtonTypeEnum } from "@components/primitive/button/button"
import { TransactionStatusEnum, TransactionTypeEnum } from "@generated/graphql"
import { ToolTip } from "@components/primitive/tool-tip/tool-tip"
import { getTransactionTypeDetails } from "@utils/mapping/transactions.mapping"
import { Analytics } from "@core/analytics/actions"
import { events } from "@core/analytics/events"

interface ITransactionShareButtonProps {
  id: string
  counterpartyName: string
  amount: number
  transactionType?: TransactionTypeEnum
  status?: TransactionStatusEnum | null
  date: string
  note?: string
}

export const TransactionShareButton: React.FC<ITransactionShareButtonProps> = ({
  id,
  counterpartyName,
  amount,
  date,
  note,
  transactionType,
  status,
}) => {
  const { t } = useTranslation()
  const [toolTipVisible, setToolTipVisible] = useState(false)
  const isCardTransaction = transactionType === TransactionTypeEnum.CARD_PURCHASE && amount < 0

  const onShareClick = () => {
    copy(sharemsg)
    setToolTipVisible(true)
    if (isCardTransaction) {
      const isPosted = status === TransactionStatusEnum.POSTED
      Analytics.track(
        isPosted
          ? events.transactions.cardAuthSettled.share
          : events.transactions.cardAuthPending.share,
      )
    }
    setTimeout(() => setToolTipVisible(false), 1000)
  }

  const sharemsg = `${t("transactions.share.amount")} ${formatMoney(amount)}
    ${t("transactions.share.date")} ${DateTime.fromSeconds(Number(date)).toISODate()}
    ${t("transactions.share.type")} ${
    transactionType && getTransactionTypeDetails(transactionType).outgoingType
  }
    ${note ? t("transactions.share.notes") + note : ""}
    ${t("transactions.share.merchant") + counterpartyName}
    ${t("transactions.share.id") + id}`

  return (
    <ToolTip text={t("transactions.share.toolTipText")} position="top" visible={toolTipVisible}>
      <Button
        iconSrc={images.icons.share}
        type={ButtonTypeEnum.TERTIARY_ALT}
        sx={{ marginRight: 12 }}
        onClick={() => onShareClick()}
      >
        {t("dashboard.transactionPanel.share")}
      </Button>
    </ToolTip>
  )
}
