import React from "react"
import { Box } from "rebass"
import { useTranslation } from "react-i18next"
import { formatMoney } from "accounting"
import { SubAccountListItem } from "@components/extended/list-items/sub-account/sub-account-list-item"
import { AlignedColumnLayout } from "@components/extended/list-items/aligned-column-layout"
import { ListItem } from "@components/primitive/list-item/list-item"
import { theme } from "@layouts/theme"
import { Text } from "@components/primitive/text/text"
import { ISubAccount } from "@typedefs/types"

interface ISubAccountList {
  subAccounts: ISubAccount[] | []
  selectedSubAccountId?: string
  onSelect: (subAccount: ISubAccount) => void
  paymentAmount?: number
  totalAvailableBalance: number
  insufficientBalanceCondition?: "to" | "through"
  withArrow?: boolean
  accountIdsToExclude?: string[]
  withTotalAvailableBalance?: boolean
}

/**
 * Defines a sub-account list comprised of a sub-account list item for each sub-account
 * at the end of the list is a display of the total available balance
 */
export const SubAccountList: React.FC<ISubAccountList> = ({
  subAccounts,
  selectedSubAccountId,
  onSelect,
  paymentAmount,
  totalAvailableBalance,
  insufficientBalanceCondition = "to",
  withArrow,
  accountIdsToExclude,
  withTotalAvailableBalance = true,
}) => {
  const { t } = useTranslation()
  const disabledStyling = {
    cursor: "default",
    backgroundColor: theme.colors.ui7,
    ":hover": {
      backgroundColor: theme.colors.ui7,
    },
  }

  const sortedSubAccounts = [
    ...subAccounts.filter(({ isDefault, id }) =>
      accountIdsToExclude ? !accountIdsToExclude.includes(id) && isDefault : isDefault,
    ),
    ...subAccounts.filter(({ isDefault, id }) =>
      accountIdsToExclude ? !accountIdsToExclude.includes(id) && !isDefault : !isDefault,
    ),
  ]

  const sortedSubAccountsWithSufficientBalance =
    insufficientBalanceCondition === "through"
      ? sortedSubAccounts.filter((subAccount) =>
          paymentAmount === 0 || paymentAmount ? subAccount.balance > paymentAmount : subAccount,
        )
      : sortedSubAccounts.filter((subAccount) =>
          paymentAmount === 0 || paymentAmount ? subAccount.balance >= paymentAmount : subAccount,
        )

  const sortedSubAccountsWithInsufficientBalance =
    insufficientBalanceCondition === "through"
      ? (paymentAmount === 0 || paymentAmount) &&
        sortedSubAccounts.filter((subAccount) => subAccount.balance <= paymentAmount)
      : (paymentAmount === 0 || paymentAmount) &&
        sortedSubAccounts.filter((subAccount) => subAccount.balance < paymentAmount)

  return (
    <Box>
      {sortedSubAccountsWithSufficientBalance.map((subAccount) => (
        <SubAccountListItem
          key={subAccount.id}
          name={subAccount.name}
          nameSubText={
            subAccount.isDefault
              ? undefined
              : t("accounts.subAccounts.envelope") +
                (subAccount.percentRule?.percent
                  ? t("accounts.subAccounts.subtitle", { percent: subAccount.percentRule.percent })
                  : "")
          }
          balance={subAccount.balance}
          onClick={() => onSelect(subAccount)}
          isActive={subAccount.id === selectedSubAccountId}
          withArrow={withArrow}
          isDefault={subAccount.isDefault || false}
        />
      ))}
      {sortedSubAccountsWithInsufficientBalance &&
      sortedSubAccountsWithInsufficientBalance.length > 0 ? (
        <>
          <Box
            sx={{
              paddingTop: 10,
            }}
          >
            <Text tag={"h5"} textColor={theme.colors.ui2}>
              {t("moveMoney.subAccount.insufficientFundsLabel")}
            </Text>
          </Box>
          {sortedSubAccountsWithInsufficientBalance.map((subAccount) => (
            <SubAccountListItem
              key={subAccount.id}
              name={subAccount.name}
              nameSubText={subAccount.isDefault ? undefined : t("accounts.subAccounts.envelope")}
              balance={subAccount.balance}
              disabled={true}
              sx={disabledStyling}
              withArrow={withArrow}
            />
          ))}
        </>
      ) : null}
      {withTotalAvailableBalance && (
        <ListItem
          sx={{
            height: 68,
            paddingTop: 20,
            ...disabledStyling,
          }}
        >
          <AlignedColumnLayout widthRadio={0.7} alignment={"flex-start"}>
            <Text tag={"body-bold"}>{t("moveMoney.subAccount.totalBalance")}</Text>
          </AlignedColumnLayout>
          <AlignedColumnLayout widthRadio={0.3} alignment={"flex-end"}>
            <Text tag={"body"}>{formatMoney(totalAvailableBalance)}</Text>
          </AlignedColumnLayout>
        </ListItem>
      )}
    </Box>
  )
}
