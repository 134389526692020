import React from "react"
import { useTranslation } from "react-i18next"

import { images } from "@assets/images/images"
import { RoutesEnum } from "@routers/types"
import { events } from "@core/analytics/events"
import { Analytics } from "@core/analytics/actions"
import { NavBarLink } from "./nav-bar-link"
import { theme } from "@layouts/theme"
import { LoadingIcon } from "@northone/ui"
import { isStandardPlan } from "@features/subscriptions/utils"
import { useSubscriptionsData } from "@features/subscriptions/shared/hooks/use-subscriptions-data"

type ButtonText = "upgrade" | "myPlan"

const BUTTON_I18N_KEY_PATH: Record<ButtonText, string> = {
  upgrade: "buttons.upgrade",
  myPlan: "buttons.myPlan",
}

const getButtonContent = (currentPlan: string | undefined) => {
  switch (currentPlan) {
    case "standard":
      return {
        i18nKeyPath: BUTTON_I18N_KEY_PATH.upgrade,
        icon: images.icons.plansUpgrade,
        activeIcon: images.icons.plansUpgradeActive,
      }
    default:
      return {
        i18nKeyPath: BUTTON_I18N_KEY_PATH.myPlan,
        icon: images.icons.plansDefault,
        activeIcon: images.icons.plansActive,
      }
  }
}

export const MyPlanButton = ({ id }: { id: string }) => {
  const { t } = useTranslation()
  const { loading, subscription } = useSubscriptionsData()
  const currentPlan = subscription?.plan.name

  if (!currentPlan) return null

  if (loading) {
    return <LoadingIcon />
  }

  const trackAnalytics = () => Analytics.track(events.subscriptions.navBarClick)
  const { i18nKeyPath, icon, activeIcon } = getButtonContent(currentPlan)

  return (
    <NavBarLink
      id={id}
      text={t(i18nKeyPath)}
      to={RoutesEnum.PLANS}
      onClick={trackAnalytics}
      iconSrc={icon}
      activeIconSrc={activeIcon}
      sx={isStandardPlan(currentPlan) ? myPlanButtonStyle : undefined}
    />
  )
}

const myPlanButtonStyle = {
  backgroundColor: theme.colors.goldTint4,
  borderRadius: "4px",
}
