import React from "react"
import { PaymentMethodForm } from "@features/move-money/payment-method/form"
import { FlowModalLayout } from "@components/extended/overlay/flow-modal"
import { ACH_ROUTER, TACHScreenNames } from "./ach"
import { moveMoneyProgressBarItems } from "./progress-bar-items"
import { DOMESTIC_WIRE_ROUTER, TDomesticWireScreenNames } from "./domestic-wire"
import { MoveMoneyUpsellPlanDetails } from "../subscription-upsell/upsell-details"
import { MoveMoneyUpsellConfirm } from "../subscription-upsell/upsell-confirm"

/**
 * NOTE:  This type is manually defined with all of its string literal values,
 *        and is then used to force the MOVE_MONEY_ROUTES object to have the same keys.
 *        This type could simply be inferred from the MOVE_MONEY_ROUTES object by using
 *        `keyof typeof MOVE_MONEY_ROUTES`!
 */
export type TMoveMoneyScreenNames =
  | "PAYMENT_METHOD"
  | "SUBSCRIPTION_UPSELL_DETAILS"
  | "SUBSCRIPTION_UPSELL_CONFIRM"
  | TACHScreenNames
  | TDomesticWireScreenNames

const lastProgressItem = moveMoneyProgressBarItems[moveMoneyProgressBarItems.length - 1]

export const MOVE_MONEY_ROUTES: {
  [key in TMoveMoneyScreenNames]: {
    component: React.ReactElement
    progressItem: string
    layout: FlowModalLayout
  }
} = {
  PAYMENT_METHOD: {
    component: <PaymentMethodForm />,
    progressItem: moveMoneyProgressBarItems[0],
    layout: "form",
  },
  SUBSCRIPTION_UPSELL_DETAILS: {
    component: <MoveMoneyUpsellPlanDetails />,
    progressItem: lastProgressItem,
    layout: "centered",
  },
  SUBSCRIPTION_UPSELL_CONFIRM: {
    component: <MoveMoneyUpsellConfirm />,
    progressItem: lastProgressItem,
    layout: "centered",
  },
  ...ACH_ROUTER,
  ...DOMESTIC_WIRE_ROUTER,
}
