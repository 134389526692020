import React from "react"
import { useTranslation } from "react-i18next"
import { isEmpty } from "lodash-es"

import { FlexRowLayout, Text } from "@northone/ui"
import { theme } from "@layouts/theme"
import { Maybe } from "@generated/graphql"
import { CounterpartyAvatar } from "@components/extended/avatar/counterparty-avatar"
import { TransactionPanelItem } from "./base-transaction-panel"

interface IProps {
  user:
    | Maybe<{
        id: string
        email?: Maybe<string>
        firstName?: Maybe<string>
        lastName?: Maybe<string>
        isPrimaryOwner: boolean
      }>
    | undefined
}

export const TransactionPanelUser = ({ user }: IProps) => {
  const { t } = useTranslation()

  const mappedUser = isEmpty(user)
    ? null
    : [user?.firstName, user?.lastName].filter((value) => !!value).join(" ")

  const hasMappedUser = mappedUser !== null

  if (!hasMappedUser) return null

  return (
    <TransactionPanelItem label={t("dashboard.transactionPanel.initiatedBy")}>
      <FlexRowLayout>
        <CounterpartyAvatar
          counterpartyName={mappedUser}
          textTag="body-small-bold"
          borderRadius={3}
          colorThemes={[
            { textColor: theme.colors.black1, backgroundColor: theme.colors.goldTint1 },
          ]}
        />
        <Text tag="body" sx={{ paddingLeft: "8px", paddingTop: "4px" }}>
          {mappedUser}
        </Text>
      </FlexRowLayout>
    </TransactionPanelItem>
  )
}
