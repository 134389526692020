import React from "react"
import { ButtonTypeEnum } from "@components/primitive/button/button"
import { useTranslation } from "react-i18next"
import { Subscription, SubscriptionPlan } from "../../../types"
import { useHistory, useLocation } from "react-router-dom"
import { ILocationState, RoutesEnum } from "@routers/types"
import { navigateToModal } from "@utils/navigation"
import { Analytics } from "@core/analytics/actions"
import { events } from "@core/analytics/events"
import { isStandardPlan } from "@features/subscriptions/utils"
import { SubscriptionStatus } from "@generated/graphql"
import { getFormattedShortDate } from "@utils/dates"
import { PlanDetailsBoxLayout } from "./plan-details-box-layout"

export const PlanDetailsBox = ({
  planContent,
  subscription,
}: {
  planContent?: SubscriptionPlan
  subscription: Subscription
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const currentLocation = useLocation<ILocationState>()

  if (!planContent) return null

  const isCurrentPlan = planContent.name === subscription.plan.name
  const standardPlanActive = isStandardPlan(subscription.plan.name)
  const cancelRequested = subscription.status === SubscriptionStatus.CANCEL_REQUESTED
  const sections = planContent.sections
  const isFeatured = planContent.featured
  const planName = planContent.name

  const getPillText = () => {
    if (standardPlanActive && isFeatured) return t("subscriptions.new")
    if (!standardPlanActive && isCurrentPlan && cancelRequested)
      return t("subscriptions.activeUntil", {
        nextBillingDate: getFormattedShortDate(subscription.nextBillingDate),
      })
    if (!standardPlanActive && isCurrentPlan) return t("subscriptions.billingDetails.pill.active")
    return
  }

  const getCTAText = () => {
    if (isStandardPlan(planContent.name)) {
      return isCurrentPlan ? t("subscriptions.currentPlan") : t("subscriptions.planInactive")
    }
    if (isCurrentPlan && cancelRequested)
      return t("subscriptions.reactivatePlan", { planShortTitle: planContent.shortTitle })
    return planContent.upgradeButtonText
  }

  const onUpgradeClick = () => {
    Analytics.track(events.subscriptions.openSubscriptionUpgradeModal, { planName })
    navigateToModal({
      currentLocation,
      history,
      routeName: `${RoutesEnum.SUBSCRIPTION_UPGRADE}/${planName}`,
    })
  }

  const onReactivateClick = () => {
    Analytics.track(events.subscriptions.reactivatePlanClick, { planName })
    navigateToModal({
      currentLocation,
      history,
      routeName: RoutesEnum.SUBSCRIPTION_REACTIVATE,
    })
  }

  const containerSx = {
    ...(isFeatured && !isCurrentPlan
      ? {
          background: "linear-gradient(168deg, #FFF7E7 -31.15%, #FFF 91.23%)",
        }
      : {}),
  }

  return (
    <PlanDetailsBoxLayout
      title={planContent.title}
      subtitle={planContent.subtitle}
      description={planContent.description}
      price={planContent.price.map((p) => p.text)}
      displayButton={standardPlanActive || cancelRequested}
      buttonProps={{
        disabled: isStandardPlan(planContent.name),
        type: isFeatured ? ButtonTypeEnum.PRIMARY_BLACK : ButtonTypeEnum.SECONDARY,
        onClick: cancelRequested ? onReactivateClick : onUpgradeClick,
        children: getCTAText(),
      }}
      containerSx={containerSx}
      sections={sections}
      pillText={getPillText()}
    />
  )
}
