import React from "react"
import { useTranslation } from "react-i18next"
import { images } from "@assets/images/images"
import { DisclaimerNote } from "@components/composite/disclaimer-note/disclaimer-note"
import { AddFundsACHWireThirdParty } from "@features/add-funds/shared/ach-wire-third-party/form"

export const AddFundsThirdPartyForm: React.FC = () => {
  const { t } = useTranslation()

  return (
    <>
      <AddFundsACHWireThirdParty
        imageSrc={images.paymentSourceIcons}
        title={t("topUp.addFunds.thirdParty.title")}
        subtitle={t("topUp.addFunds.thirdParty.subtitle")}
      />
      <DisclaimerNote text={t("topUp.addFunds.thirdParty.note")} />
    </>
  )
}
