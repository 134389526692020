import gql from "graphql-tag"

export const SUB_ACCOUNT_BALANCES = gql`
  query subAccountBalances($businessId: ID!) {
    business(businessId: $businessId) {
      id
      bankAccount {
        id
        subAccounts {
          id
          balance
          isDefault
          name
        }
      }
    }
  }
`
