import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useAppSelector } from "@core/redux/utils"
import { selectActiveBusinessID } from "@core/active-business/redux/selectors"
import { Analytics } from "@core/analytics/actions"
import { events } from "@core/analytics/events"
import { ButtonTypeEnum } from "@components/primitive/button/button"
import { InfoBlockModal } from "@components/composite/info-block-modal/info-block-modal"
import { SUB_ACCOUNT_BALANCES } from "@features/accounts/create-sub-account/initial-transfer/operations.gql"
import { SUB_ACCOUNTS_QUERY } from "@features/accounts/sub-accounts/operations.gql"
import { pollWithMaxRetries, refreshTransactionLists } from "@utils/poll-with-max-retries"
import { useSubAccountsReconcileMutation, useSubAccountBalancesQuery } from "@generated/graphql"

interface IReconcileSkipModalProps {
  isOpen: boolean
  closeModal: () => void
  onCompleted: () => void
}

export const ReconcileSkipModal: React.FC<IReconcileSkipModalProps> = ({
  isOpen,
  closeModal,
  onCompleted,
}) => {
  const { t } = useTranslation()
  const [hasError, setHasError] = useState(false)
  const businessId = useAppSelector(selectActiveBusinessID)

  const { data: subAccountsData } = useSubAccountBalancesQuery({
    variables: { businessId },
  })

  const subAccounts = subAccountsData?.business?.bankAccount?.subAccounts || []
  const subAccountTotalBalance = subAccounts.reduce((acc, curr) => acc + curr.balance, 0) || 0

  const [reconcile, { loading: reconcileLoading }] = useSubAccountsReconcileMutation({
    variables: {
      businessId,
      data: subAccounts.map((subAccount) => ({
        subAccountId: subAccount.id,
        amount: subAccount.isDefault ? subAccountTotalBalance : 0,
      })),
    },
    onError: () => setHasError(true),
    onCompleted: () => {
      refreshTransactionLists({ businessId })
      pollWithMaxRetries([
        { query: SUB_ACCOUNTS_QUERY, variables: { businessId } },
        { query: SUB_ACCOUNT_BALANCES, variables: { businessId } },
      ])
      onCompleted()
    },
  })

  return (
    <InfoBlockModal
      errorMessage={hasError ? t("errors.generic") : ""}
      title={t("accounts.reconcile.skip.title")}
      subtitle={t("accounts.reconcile.skip.subtitle")}
      buttons={[
        {
          children: t("buttons.skip"),
          onClick: () => {
            setHasError(false)
            reconcile()
            Analytics.track(events.accounts.reconcile.skip)
          },
          isLoading: reconcileLoading,
        },
        { children: t("buttons.cancel"), type: ButtonTypeEnum.SECONDARY, onClick: closeModal },
      ]}
      isOpen={isOpen}
      closeModal={closeModal}
    />
  )
}
