import React from "react"
import { useTranslation } from "react-i18next"

import { images } from "@assets/images/images"
import { InfoBlockModal } from "@components/composite/info-block-modal/info-block-modal"
import { ButtonTypeEnum } from "@components/primitive/button/button"

interface IReportSecurityIssueModalProps {
  isOpen: boolean
  closeModal: () => void
}

export const ReportSecurityIssueModal: React.FC<IReportSecurityIssueModalProps> = ({
  isOpen,
  closeModal,
}) => {
  const { t } = useTranslation()
  return (
    <InfoBlockModal
      isOpen={isOpen}
      title={t("cards.virtualCards.securityModal.title")}
      titleSx={{ paddingTop: 0, paddingBottom: 10 }}
      subtitle={t("cards.virtualCards.securityModal.subtitle")}
      subtitleTag={"body-large"}
      imageSx={{ marginTop: 0 }}
      imageDimensions={{
        width: 227,
        height: 188,
      }}
      isImageContain
      imageSrc={images.illustrations.canTelephone}
      buttons={[
        {
          children: t("cards.virtualCards.securityModal.contactButton"),
          onClick: () => {
            closeModal()
            window.Intercom("show")
          },
          type: ButtonTypeEnum.PRIMARY_BLUE,
        },
      ]}
      closeModal={closeModal}
    />
  )
}
