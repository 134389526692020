import React from "react"
import { Overlay, OverlayProps } from "@components/primitive/overlay/overlay"
import { ProgressBar, IProgressBarProps } from "@components/composite/progress-bar/progress-bar"
import { theme } from "@layouts/theme"
import { Grid } from "@layouts/grid"
import { Panel } from "@components/primitive/panel/panel"
import { FlexColumnLayout } from "@layouts/flex"
import { useTranslation } from "react-i18next"
import { FlowModalBody, FlowModalBodyProps } from "./flow-modal-body"
import { Button } from "@components/primitive/button/button"
import { images } from "@assets/images/images"
import { Helmet } from "react-helmet"
import { SxStyleProp } from "rebass"

export type FlowModalLayout = "form" | "centered"
export interface FlowModalProps extends OverlayProps, FlowModalBodyProps {
  progressBar?: IProgressBarProps
  layout: FlowModalLayout
  scrollContainerRef?: React.RefObject<HTMLDivElement>
  pageTitle?: string
  containerStyle?: SxStyleProp
  onExitButtonClick?: () => void
  bottomBanner?: JSX.Element
}

export const FlowModal = ({
  progressBar,
  children,
  sidepanel,
  layout,
  scrollContainerRef,
  pageTitle,
  containerStyle,
  onExitButtonClick,
  bottomBanner,
  ...overlayProps
}: FlowModalProps) => {
  return (
    <>
      {overlayProps.isOpen && pageTitle && (
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
      )}
      <Overlay {...overlayProps}>
        <Grid
          sx={{
            height: "90vh",
            maxWidth: [undefined, undefined, undefined, undefined, theme.maxWidth],
            width: "100%",
            paddingLeft: [36, 36, 36, 36, 0],
          }}
        >
          <OverlaySidebar progressBar={progressBar} onExitButtonClick={onExitButtonClick} />
          <Panel
            onClick={(e) => {
              e.stopPropagation()
            }}
            sx={{
              gridRow: "1/2",
              gridColumn: ["3/13", "3/13", "3/13", "3/13", "2/13"],
              height: "90vh",
              borderRadius: 4,
              borderTopRightRadius: [0, 0, 0, 0, 4],
              borderBottomRightRadius: [0, 0, 0, 0, 4],
            }}
          >
            <FlowModalBody
              containerStyle={containerStyle}
              scrollContainerRef={scrollContainerRef}
              sidepanel={sidepanel}
              layout={layout}
            >
              {children}
            </FlowModalBody>
            {bottomBanner}
          </Panel>
        </Grid>
      </Overlay>
    </>
  )
}

const OverlaySidebar: React.FC<{
  progressBar?: IProgressBarProps
  onExitButtonClick?: () => void
}> = ({ progressBar, onExitButtonClick }) => {
  const { t } = useTranslation()
  return (
    <FlexColumnLayout
      sx={{
        height: "100%",
        gridRow: "1/2",
        gridColumn: ["1/3", "1/3", "1/3", "1/3", "1/2"],
        marginRight: -(theme.gutterWidth - 24),
        alignItems: "flex-end",
      }}
    >
      <Button
        iconSx={{ filter: "brightness(0) invert(1)" }}
        iconSrc={images.icons.chevronLeft}
        sx={{
          marginTop: 20,
          backgroundColor: "rgba(240, 243, 246, 0.2)",
          ":hover": { backgroundColor: "rgba(240, 243, 246, 0.4)" },
          ":active": { backgroundColor: "rgba(240, 243, 246, 0.15)" },
        }}
        onClick={onExitButtonClick}
      >
        {t("buttons.exit")}
      </Button>

      {progressBar && (
        <ProgressBar {...progressBar} sx={{ marginTop: 30, alignSelf: "flex-end" }} />
      )}
    </FlexColumnLayout>
  )
}
