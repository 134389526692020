import React from "react"

import { theme } from "@layouts/theme"

interface IBadgeProps {
  color?: string
  size?: number
  xOffset?: number
  yOffset?: number
}

const DEFAULT_BADGE_SIZE = 6
const DEFAULT_X_OFFSET = 29
const DEFAULT_Y_OFFSET = 7

export const Badge: React.FC<IBadgeProps> = ({
  color = theme.colors.error100,
  size = DEFAULT_BADGE_SIZE,
  xOffset = DEFAULT_X_OFFSET,
  yOffset = DEFAULT_Y_OFFSET,
}) => {
  return (
    <span
      style={{
        backgroundColor: color,
        height: size,
        width: size,
        left: xOffset,
        top: yOffset,

        position: "absolute",
        display: "inline-block",
        borderRadius: "50%",
      }}
    />
  )
}
