import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import { BaseLayout } from "@layouts/base-layout"
import { shareAndEarnActions } from "./redux/actions"
import { useAppSelector } from "@core/redux/utils"
import { NoAdvocateId } from "./content/no-advocate"
import { CustomerBlocked } from "./content/blocked"
import { HappyPath } from "./content/happy-path"
import { LoadingPanel } from "@components/extended/panel/loading-panel"
import { useHistory } from "react-router-dom"
import { RoutesEnum } from "@routers/types"
import { setShareAndEarnLastVisitedInLocalStorage } from "./utils"
import { Box } from "rebass"
import { theme } from "@layouts/theme"
import { useShareAndEarnIncentiveAmount } from "@utils/prismic/campaigns/helpers"

const RewardsContent = () => {
  const history = useHistory()
  const {
    offerUrl: isActiveAdvocate,
    isBlocked,
    enableShareAndEarn,
    isShareAndEarnLoading,
  } = useAppSelector((state) => state.shareAndEarn)

  const { formattedIncentiveAmount, incentiveAmountLoading } = useShareAndEarnIncentiveAmount()

  if (isShareAndEarnLoading || incentiveAmountLoading) return <LoadingPanel />

  if (!formattedIncentiveAmount) return null

  if (!enableShareAndEarn) {
    history.push(RoutesEnum.DASHBOARD)
  }

  if (isBlocked) {
    return <CustomerBlocked />
  }

  if (!isActiveAdvocate) {
    return <NoAdvocateId incentiveAmount={formattedIncentiveAmount} />
  }

  if (isActiveAdvocate) {
    return <HappyPath incentiveAmount={formattedIncentiveAmount} />
  }
  return null
}

export const SHARE_AND_EARN_HUB_TOP_SPACING = 75
export const ShareAndEarnLanding = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  useEffect(() => {
    const hubLastVisited = new Date()
    dispatch(shareAndEarnActions.setShareAndEarnData({ hubLastVisited }))
    setShareAndEarnLastVisitedInLocalStorage(hubLastVisited)
  }, [])

  return (
    <>
      <Box
        sx={{
          backgroundColor: theme.colors.ui6,
          height: "362px",
          top: -theme.heights.navBar,
          width: "100vw",
          position: "absolute",
          zIndex: "-1",
          left: 0,
        }}
      ></Box>
      <BaseLayout
        withLogoFooter
        pageTitle={t("pageTitles.shareAndEarn")}
        childrenGridColumn={"4/11"}
      >
        <RewardsContent />
      </BaseLayout>
    </>
  )
}
