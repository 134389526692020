import React, { useState } from "react"
import { Box, Image } from "rebass"
import { motion } from "framer-motion"
import { useTranslation } from "react-i18next"
import { formatMoney } from "accounting"
import { useAppSelector } from "@core/redux/utils"
import { selectActiveBusinessID } from "@core/active-business/redux/selectors"
import { Analytics } from "@core/analytics/actions"
import { WithHighlight } from "@components/primitive/highlight/highlight"
import { Text } from "@components/primitive/text/text"
import { ErrorScreen } from "@components/composite/error-screen/error-screen"
import { AnimatePresence } from "@components/animations/animate-presence/animation-presence"
import { FlexRowLayout, FlexColumnLayout } from "@layouts/flex"
import { theme } from "@layouts/theme"
import { images } from "@assets/images/images"
import { useBankAccountInfoQuery } from "@generated/graphql"

interface IBusinessBalancesProps {
  withoutExpansion?: boolean
  analyticsEvent?: string
}

export const BusinessBalances: React.FC<IBusinessBalancesProps> = ({
  withoutExpansion = false,
  analyticsEvent,
}) => {
  const { t } = useTranslation()
  const [isExpanded, setIsExpanded] = useState(false)
  const businessId = useAppSelector(selectActiveBusinessID)

  const { data, refetch, error } = useBankAccountInfoQuery({
    variables: { businessId },
    notifyOnNetworkStatusChange: true,
  })

  const balance = data?.business?.bankAccount?.availableBalance
  const pendingBalance = 0

  return (
    <motion.div
      initial={false}
      animate={{ backgroundColor: isExpanded ? theme.colors.ui6 : theme.colors.ui7 }}
    >
      <FlexColumnLayout
        sx={{
          margin: theme.spacing.sidebar,
          borderBottom: withoutExpansion ? undefined : `1px solid ${theme.colors.ui5}`,
          marginBottom: 0,
        }}
      >
        <FlexColumnLayout
          sx={{
            height: 70,
          }}
        >
          {error ? (
            <ErrorScreen size="small" retry={() => refetch()} />
          ) : (
            <BalanceDisplay
              withHighLight
              label={
                isExpanded ? t("dashboard.sidebar.availableSpend") : t("dashboard.sidebar.balance")
              }
              balance={balance}
              imageSrc={
                withoutExpansion
                  ? undefined
                  : isExpanded
                  ? images.icons.chevronUp
                  : images.icons.chevronDown
              }
              expandingPanelOnClick={
                withoutExpansion
                  ? undefined
                  : analyticsEvent
                  ? () => {
                      setIsExpanded(!isExpanded)
                      Analytics.track(analyticsEvent)
                    }
                  : () => setIsExpanded(!isExpanded)
              }
            />
          )}
        </FlexColumnLayout>
        <AnimatePresence animation="expand">
          {isExpanded && !withoutExpansion ? (
            <>
              <BalanceDisplay
                label={t("dashboard.sidebar.pendingBalance")}
                balance={pendingBalance}
              />
              <BalanceDisplay
                label={t("dashboard.sidebar.pendingDeposits")}
                balance={pendingBalance && balance ? pendingBalance - balance : undefined}
              />
              <FlexRowLayout sx={{ paddingBottom: theme.spacing.sidebar }}>
                <Image src={images.icons.info} sx={{ minWidth: 16 }} />
                <Text sx={{ marginLeft: "12px" }} tag="body-small" textColor={theme.colors.ui2}>
                  {t("dashboard.sidebar.balanceInfo")}
                </Text>
              </FlexRowLayout>
            </>
          ) : null}
        </AnimatePresence>
      </FlexColumnLayout>
    </motion.div>
  )
}

export const BalanceDisplay: React.FC<{
  balance?: number | null
  label: string
  withHighLight?: boolean
  imageSrc?: string
  expandingPanelOnClick?: () => void
}> = ({ balance, label, imageSrc, withHighLight, expandingPanelOnClick }) => (
  <FlexRowLayout
    sx={{
      justifyContent: "space-between",
      marginBottom: "16px",
      cursor: expandingPanelOnClick ? "pointer" : "default",
    }}
    onClick={expandingPanelOnClick}
  >
    <FlexColumnLayout>
      <FlexColumnLayout
        sx={{
          height: 20,
        }}
      >
        <Text textColor={theme.colors.ui2} sx={{ marginBottom: "4px" }} tag="h5">
          {label}
        </Text>
      </FlexColumnLayout>
      <WithHighlight hidden={!withHighLight} color={theme.colors.gold10}>
        {balance !== undefined && balance !== null && (
          // Not using FlexRowLayout as we need container to shrink to wrap content
          <Box
            sx={{
              alignItems: "flex-end",
              display: "inline-flex",
              flexDirection: "row",
            }}
          >
            <Text
              sx={{
                fontFeatureSettings: `'pnum' on, 'onum' on`,
                fontSize: "20px",
                lineHeight: "30px",
                fontWeight: "normal",
              }}
              tag="h3"
            >
              $
            </Text>
            <Text
              sx={{
                fontFeatureSettings: `'pnum' on, 'onum' on`,
                fontSize: "32px",
                lineHeight: "30px",
                paddingBottom: "4px",
                paddingX: "2px",
                fontWeight: "normal",
              }}
              tag="h3"
            >
              {formatMoney(Math.floor(balance), "", 0)}
            </Text>
            <Text
              sx={{
                fontFeatureSettings: `'pnum' on, 'onum' on`,
                fontSize: "20px",
                lineHeight: "30px",
                fontWeight: "normal",
              }}
              tag="h3"
            >
              .{formatMoney(balance).split(".")[1]}
            </Text>
          </Box>
        )}
      </WithHighlight>
    </FlexColumnLayout>
    {imageSrc && <Image sx={{ cursor: "pointer", marginTop: "30px" }} src={imageSrc} />}
  </FlexRowLayout>
)
