import { WatchQueryFetchPolicy } from "@apollo/client/core/watchQueryOptions"
import { selectActiveBusinessID } from "@core/active-business/redux/selectors"
import { useAppSelector } from "@core/redux/utils"
import {
  SubscriptionUpsellTransactionType,
  useTransactionUpsellContentLazyQuery,
} from "@generated/graphql"
import { useEffect } from "react"

export const useTransactionSubscriptionUpsell = ({
  transactionType,
  fetchPolicy,
}: {
  transactionType?: SubscriptionUpsellTransactionType
  fetchPolicy?: WatchQueryFetchPolicy
}) => {
  const businessId = useAppSelector(selectActiveBusinessID)
  const [getTransactionUpsellContent, { data, loading, error, refetch }] =
    useTransactionUpsellContentLazyQuery({ fetchPolicy })

  useEffect(() => {
    if (!transactionType) return
    getTransactionUpsellContent({ variables: { businessId, transactionType } })
  }, [transactionType])

  const upsellPlan = data?.transactionUpsellContentPerType?.upsellPlan
  return {
    upsellPlan,
    loading,
    error,
    refetch,
  }
}
