import React, { useState } from "react"
import { Textarea } from "@rebass/forms"
import { Field, IFieldInputProps } from "@components/primitive/field/field"
import { theme } from "@layouts/theme"

export interface ILongTextInputProps extends IFieldInputProps {
  placeholder: string
  value: string
  onChange: (value: string) => void
}

/**
 * Extends the primitive Field component
 * @param sx Should be used sparingly. One use case might be to set a custom width.
 */
export const LongTextInput: React.FC<ILongTextInputProps> = ({
  label,
  placeholder,
  disabled,
  isHighlighted,
  errorMessage,
  sx,
  value,
  onChange,
}) => {
  const [isFocused, setIsFocused] = useState(false)
  return (
    <Field
      label={label}
      isFocused={isFocused}
      isHighlighted={isHighlighted}
      errorMessage={errorMessage}
      disabled={disabled}
      sx={{ height: 99, paddingBottom: "5px", ...sx }}
    >
      <Textarea
        sx={{
          resize: "none",
          outline: "none",
          border: "none",
          paddingX: 12,
          "::placeholder": {
            color: disabled ? theme.colors.ui4 : theme.colors.ui3,
            ...theme.textStyles.body,
          },
          ...theme.textStyles.body,
          height: "100%",
          ...sx,
        }}
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        onFocus={() => {
          setIsFocused(true)
        }}
        onBlur={() => {
          setIsFocused(false)
        }}
        onChange={(e) => onChange(e.target.value)}
      />
    </Field>
  )
}
