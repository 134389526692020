import React from "react"
import { useTranslation } from "react-i18next"
import { useHistory, useParams } from "react-router-dom"
import { useAppSelector } from "@core/redux/utils"
import { selectActiveBusinessID } from "@core/active-business/redux/selectors"
import { RoutesEnum } from "@routers/types"
import { Panel } from "@components/primitive/panel/panel"
import { AnimatePresence } from "@components/animations/animate-presence/animation-presence"
import { ErrorScreen } from "@components/composite/error-screen/error-screen"
import { BackButton } from "@components/composite/back-button/back-button"
import { FlexColumnLayout } from "@layouts/flex"
import { theme } from "@layouts/theme"
import { useSubAccountDetailsQuery } from "@generated/graphql"
import { BalanceDisplay } from "../shared/business-balances"
import { AccountDetails } from "../shared/account-details"

export const SubAccountSidebar: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const businessId = useAppSelector(selectActiveBusinessID)
  const { subAccountId } = useParams<{ subAccountId: string }>()

  const { data, error, refetch } = useSubAccountDetailsQuery({
    variables: { businessId, subAccountId },
  })

  const subAccount = data?.business?.bankAccount?.subAccount
  const subAccountBalance = subAccount?.balance
  const isDefault = subAccount?.isDefault || false

  return (
    <AnimatePresence style={{ height: "100%" }} withoutWrapper animation="fadeIn">
      <BackButton
        onClick={() => history.push(RoutesEnum.ACCOUNTS_OVERVIEW)}
        text={t("accounts.subAccounts.backButton")}
        sx={{ marginBottom: 25 }}
      />
      <Panel>
        <FlexColumnLayout
          sx={{
            margin: theme.spacing.sidebar,
            borderBottom: isDefault ? `1px solid ${theme.colors.ui5}` : undefined,
            marginBottom: 0,
          }}
        >
          {error ? (
            <ErrorScreen size="small" retry={() => refetch()} />
          ) : (
            <BalanceDisplay
              label={
                isDefault
                  ? t("accounts.subAccounts.checkingSidebarBalance")
                  : t("accounts.subAccounts.subAccountSidebarBalance")
              }
              balance={subAccountBalance}
              withHighLight
            />
          )}
        </FlexColumnLayout>
        {isDefault ? <AccountDetails /> : null}
      </Panel>
    </AnimatePresence>
  )
}
