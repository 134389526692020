import React from "react"
import { useTranslation } from "react-i18next"
import { Analytics } from "@core/analytics/actions"
import { events } from "@core/analytics/events"
import { ButtonTypeEnum } from "@components/primitive/button/button"
import { InfoBlock } from "@northone/ui"
import { images } from "@assets/images/images"

interface IReconcileLanding {
  onContinue: () => void
  onSkip: () => void
}

export const ReconcileLanding: React.FC<IReconcileLanding> = ({ onContinue, onSkip }) => {
  const { t } = useTranslation()
  return (
    <InfoBlock
      centered
      containerSx={{
        justifyContent: "center",
        height: "60%",
        position: "absolute",
      }}
      imageSrc={images.illustrations.envelopes}
      imageDimensions={{ width: 160, height: 220 }}
      title={t("accounts.reconcile.landing.title")}
      subtitle={t("accounts.reconcile.landing.subtitle")}
      buttons={[
        {
          children: t("buttons.continue"),
          onClick: () => {
            Analytics.track(events.accounts.reconcile.continue)
            onContinue()
          },
          type: ButtonTypeEnum.PRIMARY_BLUE,
        },
        {
          children: t("buttons.skip"),
          type: ButtonTypeEnum.SECONDARY,
          onClick: onSkip,
        },
      ]}
    />
  )
}
