import { useAppSelector } from "@core/redux/utils"
import { teamBankingActions } from "@features/settings/team-banking/redux/actions"
import {
  AnnouncementActionEventEnum,
  AnnouncementTypeEnum,
  useAnnouncementInteractMutation,
  useAnnouncementQuery,
} from "@generated/graphql"
import { useDispatch } from "react-redux"

export const useUpdateDismissAdditionalOwnerSetting = () => {
  const dispatch = useDispatch()
  const [updateDismissAdditionalOwnerSettingFlag] = useAnnouncementInteractMutation({
    variables: {
      type: AnnouncementTypeEnum.ADDITIONAL_OWNER_SETTINGS,
      actionEvent: AnnouncementActionEventEnum.DISMISS,
    },
    onError: () => {
      dispatch(teamBankingActions.setIsSettingsVisited(false))
    },
  })
  return updateDismissAdditionalOwnerSettingFlag
}
export const useShowAdditionalOwnerSettingBadge = (): boolean => {
  const dispatch = useDispatch()
  const hasAdditionalOwnerVisitedSettings = useAppSelector(
    (state) => state.teamBanking.isSettingsVisited,
  )
  const { error, loading } = useAnnouncementQuery({
    fetchPolicy: "network-only",
    skip: hasAdditionalOwnerVisitedSettings,
    variables: { type: AnnouncementTypeEnum.ADDITIONAL_OWNER_SETTINGS },
    onCompleted: (data) => {
      const hasRecord = data?.announcement ? true : false
      dispatch(teamBankingActions.setIsSettingsVisited(hasRecord))
    },
  })
  const wasSettingsPageVisited = !loading && !error && hasAdditionalOwnerVisitedSettings
  const canShowAdditionalOwnerSettingBadge = !wasSettingsPageVisited
  return canShowAdditionalOwnerSettingBadge
}
