import { Pill, Text } from "@northone/ui"
import React from "react"
import { TransactionStatusEnum } from "@generated/graphql"
import i18n from "@i18n/i18n"
import { theme } from "@layouts/theme"

interface ITransactionStatusPillProps {
  status: TransactionStatusEnum
}

const getPillAttributes = (status: TransactionStatusEnum) => {
  switch (status) {
    case TransactionStatusEnum.INITIATED:
    case TransactionStatusEnum.CREATED:
      return { color: theme.colors.ui3, text: i18n.t("transactions.status.pending") }
    case TransactionStatusEnum.IN_REVIEW:
      return { color: theme.colors.ui3, text: i18n.t("transactions.status.inReview") }
    default:
      return null
  }
}

export const TransactionStatusPill: React.FC<ITransactionStatusPillProps> = ({ status }) => {
  const pillAttributes = getPillAttributes(status)
  if (!pillAttributes) {
    return null
  }
  return (
    <Pill
      sx={{
        border: `1px solid ${pillAttributes.color}`,
        marginLeft: 13,
      }}
    >
      <Text tag={"h5"} sx={{ lineHeight: "14px" }} textColor={pillAttributes.color}>
        {pillAttributes.text}
      </Text>
    </Pill>
  )
}
