import React, { useEffect, useState } from "react"
import { Box, SxStyleProp } from "rebass"
import { motion, useAnimation } from "framer-motion"
import * as Sentry from "@sentry/browser"
import FontFaceObserver from "fontfaceobserver"

const fontA = new FontFaceObserver("Graphik")
const fontB = new FontFaceObserver("Plantin MT Pro")

interface WithHighlightProps {
  children?: React.ReactNode
  horizontalPadding?: number
  color: string
  hidden?: boolean
  sx?: SxStyleProp
}

export const WithHighlight = ({
  children,
  color,
  horizontalPadding = 4,
  hidden = false,
  sx,
}: WithHighlightProps) => {
  const childrenRef: React.RefObject<HTMLDivElement> = React.useRef(null)
  const [childrenHeight, setChildrenHeight] = useState(0)
  const [childrenWidth, setChildrenWidth] = useState(0)
  const setDimensions = () => {
    const boundingRect = childrenRef.current?.getBoundingClientRect()
    setChildrenWidth(boundingRect?.width || 0)
    setChildrenHeight(boundingRect?.height || 0)
  }
  useEffect(() => {
    setDimensions()
    Promise.all([fontA.load(), fontB.load()])
      .then(setDimensions)
      .catch((error) => Sentry.captureException(error))
  }, [])
  useEffect(setDimensions, [children])

  const controls = useAnimation()

  useEffect(() => {
    if (hidden) {
      controls.start({ opacity: 0 }).then(() => controls.set({ scaleX: 0 }))
    } else {
      controls.set({ opacity: 1 })
      controls.start({ scaleX: 1 })
    }
  }, [hidden, controls])

  return (
    <Box sx={{ position: "relative", display: "inline-block", zIndex: 1, ...sx }}>
      <Box sx={{ display: "inline" }} ref={childrenRef}>
        {children}
      </Box>
      <motion.div
        initial={{ scaleX: hidden ? 0 : 1, opacity: hidden ? 0 : 1 }}
        style={{
          position: "absolute",
          backgroundColor: color,
          bottom: 0,
          zIndex: -1,
          height: childrenHeight * 0.5,
          width: `${horizontalPadding * 2 + childrenWidth}px`,
          left: `-${horizontalPadding}px`,
        }}
        transition={{ type: "spring", stiffness: 550, damping: 40, mass: 1.4 }}
        animate={controls}
      ></motion.div>
    </Box>
  )
}
