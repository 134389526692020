import jsValidator from "validator"
import { americanStates } from "./constants"
import { Location } from "../generated/graphql"
import phone from "phone"
import { regex } from "./regex"

export const validationConstants = {
  ROUTING_NUMBER_MAX_LENGTH: 9,
  ACCOUNT_NUMBER_MIN_LENGTH: 6,
  ACCOUNT_NUMBER_MAX_LENGTH: 17,
}

export const validate = {
  routingNumber: (routingNumber?: string) =>
    routingNumber &&
    jsValidator.isNumeric(routingNumber) &&
    routingNumber.length === validationConstants.ROUTING_NUMBER_MAX_LENGTH,
  accountNumber: (accountNumber?: string) =>
    accountNumber &&
    jsValidator.isNumeric(accountNumber) &&
    accountNumber.length <= validationConstants.ACCOUNT_NUMBER_MAX_LENGTH,
  streetAddressLine1: (streetAddressLine1?: string | null) =>
    streetAddressLine1 && streetAddressLine1.length <= 35,
  streetAddressLine2: (streetAddressLine2?: string | null) =>
    streetAddressLine2 !== null &&
    streetAddressLine2 !== undefined &&
    streetAddressLine2.length <= 35,
  city: (city?: string | null) => city && city.length <= 21,
  postalCode: (postalCode?: string | null) =>
    postalCode && jsValidator.isPostalCode(postalCode, "US"),
  provinceState: (provinceState?: string | null) =>
    provinceState && americanStates.find(({ value }) => value === provinceState),
  address: (address?: Location) =>
    address &&
    validate.streetAddressLine1(address.streetAddressLine1) &&
    validate.streetAddressLine2(address.streetAddressLine2) &&
    validate.city(address.city) &&
    validate.provinceState(address.provinceState) &&
    validate.postalCode(address.postalCode),
  phoneNumber: (phoneNumber: string) =>
    jsValidator.isNumeric(phoneNumber) && phoneNumber.length === 10,
  validPhone: (phoneNumber: string) => {
    const cleanPhoneNumber = phoneNumber.replace(regex.notNumber, "")
    return Boolean(cleanPhoneNumber.length === 10 && phone(cleanPhoneNumber).length)
  },
  phoneNumberLength: (phoneNumber: string) =>
    phoneNumber.replace(regex.notNumber, "").length === 10,
  twitter: (handle: string) => /^@?(\w){1,15}$/.test(handle),
  isValidAmount: (amount: number) => !isNaN(amount) && amount > 0,
  // email regex from zod: https://github.com/colinhacks/zod/blob/master/src/types.ts
  email: (email: string) =>
    // eslint-disable-next-line
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[(((25[0-5])|(2[0-4][0-9])|(1[0-9]{2})|([0-9]{1,2}))\.){3}((25[0-5])|(2[0-4][0-9])|(1[0-9]{2})|([0-9]{1,2}))\])|(\[IPv6:(([a-f0-9]{1,4}:){7}|::([a-f0-9]{1,4}:){0,6}|([a-f0-9]{1,4}:){1}:([a-f0-9]{1,4}:){0,5}|([a-f0-9]{1,4}:){2}:([a-f0-9]{1,4}:){0,4}|([a-f0-9]{1,4}:){3}:([a-f0-9]{1,4}:){0,3}|([a-f0-9]{1,4}:){4}:([a-f0-9]{1,4}:){0,2}|([a-f0-9]{1,4}:){5}:([a-f0-9]{1,4}:){0,1})([a-f0-9]{1,4}|(((25[0-5])|(2[0-4][0-9])|(1[0-9]{2})|([0-9]{1,2}))\.){3}((25[0-5])|(2[0-4][0-9])|(1[0-9]{2})|([0-9]{1,2})))\])|([A-Za-z0-9]([A-Za-z0-9-]*[A-Za-z0-9])*(\.[A-Za-z]{2,})+))$/.test(
      email,
    ),
}
