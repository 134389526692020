import React from "react"
import { useDispatch } from "react-redux"
import { useAppSelector } from "@core/redux/utils"
import { selectActiveBusinessID } from "@core/active-business/redux/selectors"
import { QueryResults } from "@components/composite/query-results/query-results"
import { GenericRecipientListItem } from "@components/extended/list-items/generic-recipient/generic-recipient-list-item"
import { selectRecipient } from "@features/move-money/redux/selectors"
import { moveMoneyActions } from "@features/move-money/redux/actions"
import { ContactPaymentType, useRecipientsAchQuery } from "@generated/graphql"
import { gqlAchRecipientsToRedux } from "./utils"

export const ACHRecipients: React.FC = () => {
  const dispatch = useDispatch()
  const businessId = useAppSelector(selectActiveBusinessID)
  const activeRecipient = useAppSelector(selectRecipient)

  const { data, error, loading, refetch } = useRecipientsAchQuery({
    variables: { businessId, contactPaymentType: ContactPaymentType.ACH },
  })

  return (
    <QueryResults
      listShimmerCount={5}
      size="medium"
      error={error}
      loading={loading}
      retry={() => refetch()}
    >
      {data?.business?.contacts.map((recipient) => (
        <GenericRecipientListItem
          name={recipient.name}
          key={recipient.id}
          onClick={() => {
            dispatch(moveMoneyActions.setRecipient(gqlAchRecipientsToRedux(recipient)))
            dispatch(moveMoneyActions.setEditingRecipient(gqlAchRecipientsToRedux(recipient)))
          }}
          isActive={activeRecipient?.id === recipient.id}
        />
      ))}
    </QueryResults>
  )
}
