import React, { useState } from "react"
import { useAppSelector } from "@core/redux/utils"
import { selectActiveBusinessID } from "@core/active-business/redux/selectors"
import { FlowModal } from "@components/extended/overlay/flow-modal"
import { useSubAccountBalancesQuery } from "@generated/graphql"
import { ReconcileSkipModal } from "./skip/reconcile-skip-modal"
import { ReconcileLanding } from "./landing/reconcile-landing"
import { ReconcileReallocateForm } from "./reallocate/form"
import { getSubAccountsTotalBalance } from "./utils"

export const ReconcileModal: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [currentScreen, setCurrentScreen] = useState<"LANDING" | "REALLOCATE">("LANDING")
  const [skipModalOpen, setSkipModalOpen] = useState(false)
  const businessId = useAppSelector(selectActiveBusinessID)

  useSubAccountBalancesQuery({
    variables: { businessId },
    onCompleted: (data) => {
      const subAccounts = data.business?.bankAccount?.subAccounts
      const subAccountTotalBalance = getSubAccountsTotalBalance(subAccounts || [])
      const hasNegativeSubAccountBalance = Boolean(
        subAccounts?.find((subAccount) => subAccount.balance < 0),
      )
      if (subAccountTotalBalance >= 0 && hasNegativeSubAccountBalance) {
        setIsOpen(true)
      }
    },
  })

  const closeReconcileModal = () => {
    setSkipModalOpen(false)
    setIsOpen(false)
    setCurrentScreen("LANDING")
  }

  return (
    <>
      <ReconcileSkipModal
        onCompleted={closeReconcileModal}
        isOpen={skipModalOpen}
        closeModal={() => setSkipModalOpen(false)}
      />
      <FlowModal
        layout={currentScreen === "LANDING" ? "centered" : "form"}
        isOpen={isOpen}
        onOutsideClick={() => setSkipModalOpen(true)}
      >
        {currentScreen === "LANDING" ? (
          <ReconcileLanding
            onContinue={() => setCurrentScreen("REALLOCATE")}
            onSkip={() => setSkipModalOpen(true)}
          />
        ) : (
          <ReconcileReallocateForm
            onSkip={() => setSkipModalOpen(true)}
            onComplete={closeReconcileModal}
          />
        )}
      </FlowModal>
    </>
  )
}
