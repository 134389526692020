import { analytics } from "@northone/segment-js"
import { SegmentMiddleware } from "@northone/segment-js/dist/src/types"
import { logger } from "../../utils/logger"
export interface ISegmentSource {
  key: string
  value: string
}

type SegmentEventContext = "properties" | "traits" | "context"

export const addSourceToAnalytics = (
  field: ISegmentSource,
  eventContext: SegmentEventContext,
): void => {
  analytics().addSourceMiddleware(addFieldToContext(field, eventContext))
}

export const addFieldToContext = (
  field: ISegmentSource,
  eventContext: SegmentEventContext,
): SegmentMiddleware =>
  function addField({ payload, next }): void {
    try {
      const requestObj = payload.obj[eventContext]
      if (requestObj && field.value) {
        requestObj[field.key] = field.value
      }
    } catch (error) {
      logger.error(error)
    }
    next(payload)
  }
