import React from "react"
import { Box, SxStyleProp } from "rebass"
import { theme } from "@northone/ui"

export interface ISelectorBoxProps {
  isSelected: boolean
  children?: React.ReactNode
  tabIndex?: number
  sx?: SxStyleProp
  onSelect: () => void
  onFocus?: () => void
  onBlur?: () => void
}

export const SelectorBox: React.FC<ISelectorBoxProps> = ({
  isSelected,
  children,
  onSelect,
  sx,
  tabIndex,
  onFocus,
  onBlur,
}) => (
  <Box
    data-test-id="selector-box"
    onFocus={onFocus}
    onBlur={onBlur}
    tabIndex={tabIndex}
    onClick={onSelect}
    sx={{
      width: ["100%", "100%", "180px", "185px", "195px", "195px"],
      height: ["80px", "80px", "100px"],
      backgroundColor: isSelected ? theme.colors.gold10 : theme.colors.ui6,
      borderRadius: "4px",
      padding: isSelected ? 0 : "1px",
      border: isSelected ? `3px solid ${theme.colors.blue100}` : `2px solid ${theme.colors.ui5}`,
      ":hover": {
        cursor: "pointer",
      },
      ...sx,
    }}
  >
    {children}
  </Box>
)
