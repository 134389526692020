import React from "react"
import { UpgradePlanDetails } from "@features/subscriptions/upgrade/upgrade-plan-details"
import { useDispatch, useSelector } from "react-redux"
import { moveMoneyActions } from "../redux/actions"
import { getUpsellTransactionTypeFromTransactionEnum } from "@components/composite/transaction-panel/transaction-panel-subscription-upsell"
import { useTransactionSubscriptionUpsell } from "@features/subscriptions/shared/hooks/use-transaction-subscription-upsell"
import { selectPaymentMethod } from "../redux/selectors"
import { ErrorScreen } from "@components/composite/error-screen/error-screen"
import { appActions } from "@core/redux/app/actions"
import { useTranslation } from "react-i18next"
import { formatPlanNameString } from "@features/subscriptions/utils"

export const MoveMoneyUpsellConfirm = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigateBackToConfirmationScreen = () => {
    dispatch(moveMoneyActions.navigateBack())
    dispatch(moveMoneyActions.navigateBack())
  }
  const paymentMethod = useSelector(selectPaymentMethod)
  const transactionType = getUpsellTransactionTypeFromTransactionEnum(paymentMethod)
  const { upsellPlan } = useTransactionSubscriptionUpsell({ transactionType })
  const upsellPlanName = formatPlanNameString(upsellPlan?.planName)

  if (!upsellPlanName) return <ErrorScreen size="large" />

  return (
    <UpgradePlanDetails
      upsellPlanName={upsellPlanName}
      navigateNext={() => {
        navigateBackToConfirmationScreen()
        dispatch(appActions.setNotificationMessage(t("subscriptions.planUpdatedToast")))
      }}
      navigateBack={() => dispatch(moveMoneyActions.navigateBack())}
    />
  )
}
