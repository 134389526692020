import React, { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import { useAppSelector } from "@core/redux/utils"
import { selectActiveBusinessID } from "@core/active-business/redux/selectors"
import { Analytics } from "@core/analytics/actions"
import { events } from "@core/analytics/events"
import { FormBody } from "@components/composite/form-body/form-body"
import { AmountInput } from "@components/extended/fields/amount-input"
import { ButtonTypeEnum } from "@components/primitive/button/button"
import { accountsActions } from "@features/accounts/redux/actions"
import {
  selectSubAccountInitialBalance,
  selectSubAccountID,
} from "@features/accounts/redux/selectors"
import { SUB_ACCOUNTS_QUERY } from "@features/accounts/sub-accounts/operations.gql"
import { useSubAccountTransferCreateMutation, useSubAccountBalancesQuery } from "@generated/graphql"
import { validate } from "@utils/validate"
import { pollWithMaxRetries, refreshTransactionLists } from "@utils/poll-with-max-retries"

export const CreateSubAccountInitialTransfer: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [hasError, setHasError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [exceedsBalance, setExceedsBalance] = useState(false)
  const businessId = useAppSelector(selectActiveBusinessID)
  const initialBalance = useAppSelector(selectSubAccountInitialBalance)
  const subAccountId = useAppSelector(selectSubAccountID)
  const initialBalanceAsNumber = Number(initialBalance.replace(/,/g, ""))

  const {
    data,
    loading: balanceQueryLoading,
    error: balanceQueryError,
  } = useSubAccountBalancesQuery({ variables: { businessId } })

  const checkingAccount = data?.business?.bankAccount?.subAccounts.find(
    (subAccount) => subAccount.isDefault,
  )

  useEffect(() => {
    if (checkingAccount && initialBalanceAsNumber > checkingAccount?.balance) {
      return setExceedsBalance(true)
    }
    return setExceedsBalance(false)
  }, [initialBalanceAsNumber])
  const destinationSubAccountId = subAccountId
  const sourceSubAccountId = checkingAccount?.id || ""
  const [createTransfer] = useSubAccountTransferCreateMutation({
    variables: {
      businessId,
      data: {
        amount: initialBalanceAsNumber,
        destinationSubAccountId,
        sourceSubAccountId,
      },
    },
    onError: () => {
      setIsLoading(false)
      setHasError(true)
    },
    onCompleted: () => {
      refreshTransactionLists({ businessId, sourceSubAccountId, destinationSubAccountId })
      pollWithMaxRetries([{ query: SUB_ACCOUNTS_QUERY, variables: { businessId } }])
      dispatch(accountsActions.createSubAccountNavigateNext("CREATE_SUB_ACCOUNT_PERCENT_RULE"))
    },
  })

  return (
    <FormBody
      errorMessage={hasError || balanceQueryError ? t("errors.generic") : ""}
      title={t("accounts.createSubAccount.initialTransfer.title")}
      buttons={[
        {
          canContinueWithKey: true,
          children: isLoading
            ? t("accounts.createSubAccount.initialTransfer.loadingButton")
            : t("buttons.continue"),
          disabled:
            !validate.isValidAmount(initialBalanceAsNumber) ||
            !initialBalance ||
            balanceQueryLoading ||
            exceedsBalance,
          isLoading: isLoading,
          onClick: () => {
            setIsLoading(true)
            setHasError(false)
            createTransfer()
            Analytics.track(events.accounts.create.initialTransferContinue, {
              amount: initialBalance,
            })
          },
          type: ButtonTypeEnum.PRIMARY_GOLD,
        },
        {
          children: t("accounts.createSubAccount.initialTransfer.skip"),
          onClick: () => {
            dispatch(accountsActions.setCreateSubAccountInitialBalance(""))
            dispatch(
              accountsActions.createSubAccountNavigateNext("CREATE_SUB_ACCOUNT_PERCENT_RULE"),
            )
            Analytics.track(events.accounts.create.initialTransferSkip)
          },
          type: ButtonTypeEnum.SECONDARY,
        },
      ]}
    >
      <AmountInput
        errorMessage={exceedsBalance ? t("errors.insufficientFunds") : ""}
        value={initialBalance}
        onChange={(value) => dispatch(accountsActions.setCreateSubAccountInitialBalance(value))}
      />
    </FormBody>
  )
}
