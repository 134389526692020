import React from "react"
import { OverlayProps, Overlay } from "@components/primitive/overlay/overlay"
import { Panel } from "@components/primitive/panel/panel"
import { SxStyleProp } from "rebass"

interface ModalProps extends OverlayProps {
  sx?: SxStyleProp
  children?: React.ReactNode
}

export const Modal = ({ sx, children, ...overlayProps }: ModalProps) => (
  <Overlay {...overlayProps}>
    <Panel
      onClick={(e) => e.stopPropagation()}
      sx={{
        minWidth: 300,
        padding: 36,
        ...sx,
      }}
    >
      {children}
    </Panel>
  </Overlay>
)
