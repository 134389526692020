import { osName, osVersion, mobileModel, browserName, browserVersion } from "react-device-detect"
import { ApolloClient, InMemoryCache, from, HttpLink, ApolloLink } from "@apollo/client"
import { onError } from "@apollo/client/link/error"
import { setContext } from "@apollo/client/link/context"
import util from "util"
import { config, isProduction } from "@utils/environment"
import { logger, traceId } from "@utils/logger"
import * as Sentry from "@sentry/browser"
import { getTokenOrLogout } from "../auth/auth-service"
import pkg from "../../../package.json"

export const CLIENT_NAME = "northone-web-banking"

const withTokenLink = setContext(async () => ({ accessToken: await getTokenOrLogout() }))

const authLink = new ApolloLink((operation, forward) => {
  const accessToken = operation.getContext().accessToken
  if (!accessToken) {
    return null
  }
  operation.setContext({
    headers: {
      ...(accessToken ? { authorization: `Bearer ${accessToken}` } : {}),
      client: CLIENT_NAME,
      os_name: osName,
      os_version: `${osName} ${osVersion}`,
      ...(mobileModel !== "none" && { device_model: mobileModel }),
      browser: `${browserName} ${browserVersion}`,
      trace_id: traceId,
    },
  })
  return forward ? forward(operation) : null
})
const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  const operationString = operation?.query.loc?.source.body && operation?.query.loc?.source.body
  const operationVariables = isProduction ? {} : operation.variables
  Sentry.configureScope((scope) => {
    scope.setTags({
      operation: operation.operationName,
      client: CLIENT_NAME,
      trace_id: traceId,
    })
    scope.setExtras({
      ...(operationString && { operationString }),
      ...(operationVariables && { operationVariables }),
    })
  })
  if (networkError) {
    Sentry.withScope((scope) => {
      scope.setExtras({
        error: networkError,
      })
      Sentry.captureMessage(`[GQL-ERROR]: ${operation.operationName} - Network Error`)
    })
    if (!isProduction) {
      logger.error(`[Network error]: ${networkError}`)
    }
  }
  if (graphQLErrors) {
    graphQLErrors.forEach((error) => {
      if (!isProduction) {
        logger.error(error)
      }
      Sentry.withScope((scope) => {
        scope.setExtras({
          error: util.inspect(error, { depth: 10 }),
          trace_id: traceId,
        })
        Sentry.captureMessage(`[GQL-ERROR]: ${operation.operationName} - ${error.message}`)
      })
    })
  }
})
const httpLink = new HttpLink({
  credentials: "same-origin",
  fetch,
  uri: config.graphQlUri,
})
const link = from([withTokenLink, authLink, errorLink, httpLink])
export const client = new ApolloClient({
  cache: new InMemoryCache(),
  connectToDevTools: !isProduction,
  link,
  name: `${CLIENT_NAME} - ${config.stage}`,
  version: `${pkg.version}`,
})
