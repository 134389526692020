import { RoutesEnum, ILocationState } from "../routers/types"
//@ts-ignore
import { History, Location } from "history"

type RouteWithDynamicPath = `${RoutesEnum}/${string}`

interface INavigateToModalParams {
  routeName: RoutesEnum | RouteWithDynamicPath
  currentLocation: Location
  history: History
}

export const navigateToModal = ({ history, currentLocation, routeName }: INavigateToModalParams) =>
  history.push(routeName, { background: currentLocation })

interface INavigateFromModalParams {
  location: Location<ILocationState>
  history: History
  defaultBackRoute: RoutesEnum
}

export const navigateFromModal = ({
  history,
  location,
  defaultBackRoute,
}: INavigateFromModalParams) =>
  history.push(location.state?.background?.pathname || defaultBackRoute)
