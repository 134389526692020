import React, { useRef, useEffect } from "react"
import { Box, SxStyleProp } from "rebass"
import { motion } from "framer-motion"
import { FlexRowLayout, FlexColumnLayout } from "@layouts/flex"
import { Text } from "@components/primitive/text/text"
import { theme } from "@layouts/theme"

export interface IProgressBarProps {
  progressItems: string[]
  currentItem: string
  sx?: SxStyleProp
}

const progressLineStyling: React.CSSProperties = {
  borderRadius: "5px",
  width: "4px",
  position: "absolute",
}

/**
 * Defines a progress bar composite
 */
export const ProgressBar: React.FC<IProgressBarProps> = ({ progressItems, currentItem, sx }) => {
  const usePreviousItem = (item: string) => {
    const ref: React.MutableRefObject<string | undefined> = useRef()
    useEffect(() => {
      ref.current = item
    })
    return ref.current
  }

  const previousItem = usePreviousItem(currentItem)

  const singleStepHeight = 39
  const totalBarHeight = progressItems.length * singleStepHeight
  const currentItemStep = progressItems.indexOf(currentItem) + 1
  const previousItemStep = previousItem ? progressItems.indexOf(previousItem) + 1 : 0

  const startPosition =
    (previousItemStep / progressItems.length - 1) * totalBarHeight -
    (singleStepHeight / 2) * ((progressItems.length - currentItemStep) / progressItems.length)
  const endPosition =
    ((previousItemStep + (currentItemStep - previousItemStep)) / progressItems.length - 1) *
      totalBarHeight -
    (singleStepHeight / 2) * ((progressItems.length - currentItemStep) / progressItems.length)

  const transition = {
    duration: 0.4,
    delay: 0.1,
    ease: "easeInOut",
  }

  return (
    <FlexRowLayout
      sx={{
        justifyContent: "flex-end",
        position: "relative",
        ...sx,
      }}
    >
      <FlexColumnLayout
        sx={{
          alignItems: "flex-end",
          marginRight: 16,
        }}
      >
        {progressItems.map((item, idx) => (
          <Text
            key={idx}
            tag={"body-bold"}
            textColor={theme.colors.ui7}
            opacity={item !== currentItem ? 0.5 : undefined}
            sx={{ paddingTop: idx === 0 ? 0 : 23, whiteSpace: "nowrap" }}
          >
            {item}
          </Text>
        ))}
      </FlexColumnLayout>
      <Box
        sx={{
          overflow: "hidden",
          position: "relative",
          height: totalBarHeight,
          backgroundColor: theme.colors.ui7,
          ...progressLineStyling,
          zIndex: theme.zIndex.progressBarContainer,
        }}
      >
        <Box
          style={{
            ...progressLineStyling,
            top: 0,
            right: 0,
            height: `10%`,
            backgroundColor: theme.colors.gold80,
            zIndex: theme.zIndex.progressBar,
          }}
        />
        <motion.div
          initial={{
            y: startPosition,
          }}
          animate={{
            y: [startPosition, endPosition],
            transition,
          }}
          style={{
            ...progressLineStyling,
            top: 0,
            right: 0,
            height: "100%",
            backgroundColor: theme.colors.gold80,
            zIndex: theme.zIndex.progressBar,
          }}
        />
      </Box>
    </FlexRowLayout>
  )
}
