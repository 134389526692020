import winston from "winston"
import Transport from "winston-transport"
import * as Sentry from "@sentry/browser"

import { isProduction } from "@utils/environment"

class SentryTransport extends Transport {
  public log(info: { level: string; message: string; meta?: any }, callback: () => void) {
    // Sentry levels can only be set to fatal, error, warning, info, or debug
    const sentryLevel = info.level === "verbose" ? "debug" : info.level
    Sentry.addBreadcrumb({
      level: sentryLevel as Sentry.Severity,
      category: "console",
      message: info.message,
      ...(info.meta && { data: info.meta }),
    })

    if (callback) {
      callback()
    }
  }
}

const logger = winston.createLogger({
  level: "info",
  format: winston.format.prettyPrint(),
})

if (isProduction) {
  logger.add(new SentryTransport({ level: "info" }))
} else {
  logger.add(new winston.transports.Console({ level: "verbose" }))
}

const traceId = Math.random().toString(36).substring(2, 9)

export { logger, traceId }
