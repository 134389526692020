import { PerksActionEnum, TPerksActions } from "./actions"
import { getPerksLastVisitedFromLocalStorage } from "../utils"

export interface IPerksState {
  hubLastVisited?: Date
}

export const initialPerksState: IPerksState = {
  hubLastVisited: getPerksLastVisitedFromLocalStorage(),
}

export const perksReducer = (state = initialPerksState, action: TPerksActions): IPerksState => {
  switch (action.type) {
    case PerksActionEnum.SET_PERKS_HUB_LAST_VISITED: {
      return {
        ...state,
        hubLastVisited: action.payload,
      }
    }
    default:
      return state
  }
}
