import React, { PropsWithChildren, ReactElement } from "react"
import { Text } from "@components/primitive/text/text"
import { FlexColumnLayout } from "@layouts/flex"
import { Button, IButtonProps } from "@components/primitive/button/button"
import { theme } from "@layouts/theme"
import { FlexRowLayout } from "@northone/ui"

interface ChangePlanDetailsProps {
  heroImg?: ReactElement
  heading: string
  subtitle: string
  terms?: string
  buttons: IButtonProps[]
}

export const ChangePlanDetailsLayout = ({
  heroImg,
  heading,
  subtitle,
  children,
  buttons,
  terms,
}: PropsWithChildren<ChangePlanDetailsProps>) => {
  return (
    <FlexColumnLayout sx={{ marginTop: "40px", rowGap: "24px", width: "405px" }}>
      {heroImg}
      <Text tag="h2" sx={{ fontSize: "36px" }}>
        {heading}
      </Text>
      <FlexColumnLayout
        sx={{
          justifyContent: "space-between",
          rowGap: "24px",
        }}
      >
        <Text tag="body-large" color={theme.colors.ui2}>
          {subtitle}
        </Text>
        {children}
        <FlexRowLayout sx={{ columnGap: "16px" }}>
          {buttons.map((props, idx) => (
            <Button key={idx} {...props} />
          ))}
        </FlexRowLayout>
        {terms && (
          <Text tag="body-x-small" color={theme.colors.ui2}>
            {terms}
          </Text>
        )}
      </FlexColumnLayout>
    </FlexColumnLayout>
  )
}
