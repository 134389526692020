import React from "react"
import { useDispatch } from "react-redux"
import { ButtonTypeEnum } from "@components/primitive/button/button"
import { InfoBlock } from "@components/composite/info-block/info-block"
import { images } from "@assets/images/images"
import { moveMoneyActions } from "@features/move-money/redux/actions"
import { DomesticWireScreensEnum } from "@features/move-money/router/domestic-wire"
import {
  CONFIRMATION_NOTICE_TITLE,
  CONFIRMATION_NOTICE_SUBTITLE,
  CONFIRMATION_NOTICE_BUTTON,
} from "@features/move-money/utils/i18n-translations"

export const DomesticWireConfirmNotice: React.FC = () => {
  const dispatch = useDispatch()

  const buttons = [
    {
      canContinueWithKey: true,
      type: ButtonTypeEnum.PRIMARY_BLUE,
      children: CONFIRMATION_NOTICE_BUTTON,
      onClick: () =>
        dispatch(moveMoneyActions.navigateNext(DomesticWireScreensEnum.DOMESTIC_WIRE_SENT)),
      disabled: false,
    },
  ]

  return (
    <InfoBlock
      subTitleHasPartialBolding
      imageSrc={images.illustrations.openMailbox}
      imageDimensions={{ height: 180, width: 250 }}
      title={CONFIRMATION_NOTICE_TITLE}
      subtitle={CONFIRMATION_NOTICE_SUBTITLE}
      buttons={buttons}
    />
  )
}
