import { useState, useEffect } from "react"
import { DigitalCardAssetUrlQuery, DigitalCardAssetUrlQueryVariables } from "@generated/graphql"
import { client } from "@core/apollo/apollo-client"

import { DIGITAL_CARD_ASSET_URL_QUERY } from "../operations.gql"
import { useAppSelector } from "@core/redux/utils"
import { selectActiveBusinessID } from "@core/active-business/redux/selectors"
import { selectCardAccountId } from "../redux/selectors"
import { Analytics } from "@core/analytics/actions"
import { events } from "@core/analytics/events"
import { SxStyleProp } from "rebass"

export const useGetDigitalCardAssetUrl = (cardId: string, isModalOpen: boolean) => {
  const [url, setUrl] = useState("")
  const [error, setError] = useState(false)
  const [imageLoading, setImageLoading] = useState(true)
  const businessId = useAppSelector(selectActiveBusinessID)
  const accountId = useAppSelector(selectCardAccountId)
  const loading = !url || imageLoading

  const loadingSpinnerContainerStyle: SxStyleProp = {
    width: 340,
    height: 214,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }

  const virtualCardImageStyle: SxStyleProp = {
    display: "block",
    width: "auto",
    height: 280,
    marginX: "auto",
    boxShadow: "0px 3px 12px rgba(0, 0, 0, 0.2)",
    borderRadius: "8px",
    ...(loading ? { display: "none" } : {}),
  }

  const closeButtonStyle: SxStyleProp = {
    marginTop: "24px",
    justifySelf: "end",
    alignSelf: "center",
    width: "78px",
    height: "40px",
  }

  const getAndStoreDigitalCardAssetUrl = async (
    businessId: string,
    accountId: string,
    cardId: string,
  ) => {
    try {
      const variables = { businessId, accountId, cardId }
      const queryOptions = { query: DIGITAL_CARD_ASSET_URL_QUERY, variables }
      const response = await client.query<
        DigitalCardAssetUrlQuery,
        DigitalCardAssetUrlQueryVariables
      >({
        ...queryOptions,
        fetchPolicy: "network-only",
      })
      if (response.data.digitalCardAssetUrl?.url) {
        setUrl(response.data.digitalCardAssetUrl.url)
      }
    } catch {
      setError(true)
    }
  }

  useEffect(() => {
    if (!url && isModalOpen && accountId && cardId) {
      getAndStoreDigitalCardAssetUrl(businessId, accountId, cardId)
      Analytics.track(events.cards.virtualCards.modal.viewCardDetails)
    }
  })

  return {
    error,
    url,
    setUrl,
    setImageLoading,
    loading,
    closeButtonStyle,
    virtualCardImageStyle,
    loadingSpinnerContainerStyle,
  }
}
