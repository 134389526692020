import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import i18n from "@i18n/i18n"
import { RoutesEnum } from "@routers/types"
import { Panel } from "@components/primitive/panel/panel"
import { INavBarLinkProps } from "@components/composite/nav-bar/nav-bar-link"
import { AnimatePresence } from "@components/animations/animate-presence/animation-presence"
import { BackButton } from "@components/composite/back-button/back-button"
import { NavSideBarSection } from "../shared/side-bar-section"
import { useUpdateDismissAdditionalOwnerSetting } from "@core/announce/additionalOwnerSetting"
import { useAppSelector } from "@core/redux/utils"
import { teamBankingActions } from "@features/settings/team-banking/redux/actions"
import { useDispatch } from "react-redux"
import { SettingsPlansSection } from "./sections/plans-section"
import { AccountSection } from "./sections/account-section"

const teamSettingsLinks: INavBarLinkProps[] = [
  {
    text: i18n.t("settings.pages.teamBanking.title"),
    to: RoutesEnum.SETTINGS_TEAM_BANKING,
  },
]

const personalSettingsLinks: INavBarLinkProps[] = [
  {
    text: i18n.t("settings.pages.contactInformation.title"),
    to: RoutesEnum.SETTINGS_CONTACT_INFORMATION,
    routeMatchIsExact: true,
  },
  {
    text: i18n.t("settings.pages.changePassword.title"),
    to: RoutesEnum.SETTINGS_CHANGE_PASSWORD,
  },
]

const businessSettingsLinks: INavBarLinkProps[] = [
  {
    text: i18n.t("settings.pages.recipients.title"),
    to: RoutesEnum.SETTINGS_RECIPIENTS,
  },
  {
    text: i18n.t("settings.pages.linkedAccount.title"),
    to: RoutesEnum.SETTINGS_LINKED_ACCOUNT,
  },
  {
    text: i18n.t("settings.pages.businessOwners.title"),
    to: RoutesEnum.SETTINGS_MANAGE_TEAM,
  },
  {
    text: i18n.t("settings.pages.legal.title"),
    to: RoutesEnum.SETTINGS_LEGAL,
  },
]

export const SettingsSidebar = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const settingsNavProps = {
    eventPrefix: "Settings",
  }

  const updateDismissAdditionalOwnerSettingFlag = useUpdateDismissAdditionalOwnerSetting()
  const hasAdditionalOwnerVisitedSettings = useAppSelector(
    (state) => state.teamBanking.isSettingsVisited,
  )
  useEffect(() => {
    if (hasAdditionalOwnerVisitedSettings) return
    dispatch(teamBankingActions.setIsSettingsVisited(true))
    updateDismissAdditionalOwnerSettingFlag()
  }, [hasAdditionalOwnerVisitedSettings])

  return (
    <AnimatePresence style={{ height: "100%" }} withoutWrapper animation="fadeIn">
      <BackButton
        onClick={() => history.push(RoutesEnum.DASHBOARD)}
        text={t("settings.backToDashboard")}
        sx={{ marginBottom: 25 }}
      />
      <Panel
        sx={{
          padding: 20,
        }}
      >
        <NavSideBarSection
          sectionLabel={t("settings.sidebarLabels.teamSettings")}
          links={teamSettingsLinks}
          {...settingsNavProps}
        />

        <NavSideBarSection
          sectionLabel={t("settings.sidebarLabels.personalSettings")}
          links={personalSettingsLinks}
          {...settingsNavProps}
        />
        <NavSideBarSection
          sectionLabel={t("settings.sidebarLabels.businessSettings")}
          links={businessSettingsLinks}
          {...settingsNavProps}
        />
        <AccountSection />
        <SettingsPlansSection />
      </Panel>
    </AnimatePresence>
  )
}
