import React from "react"
import { selectMoveMoney } from "@core/redux/app/selectors"
import { useAppSelector } from "@core/redux/utils"
import { PreviewTransactionPanel } from "@components/composite/transaction-panel/preview-transaction-panel"
import { LoadingPanel } from "@components/extended/panel/loading-panel"
import { TransactionTypeEnum } from "@generated/graphql"
import { ActivateCardPanel } from "./domestic-wire/activate-card-panel/activate-card-panel"
import { RemovedFeaturePanel } from "./removed-feature-panel"

export const MoveMoneyPanel: React.FC = () => {
  const { paymentMethod, amount, recipient, subAccount, wireEnabled } =
    useAppSelector(selectMoveMoney)
  const isExternalTransfer = paymentMethod === TransactionTypeEnum.SUB_ACCOUNT_TRANSFER

  if (paymentMethod === TransactionTypeEnum.DOMESTIC_WIRE && !wireEnabled) {
    return wireEnabled === undefined ? <LoadingPanel iconDimension={40} /> : <ActivateCardPanel />
  }

  if (isExternalTransfer) {
    return <RemovedFeaturePanel transactionType={paymentMethod} />
  }

  return paymentMethod ? (
    <PreviewTransactionPanel
      {...{
        amount,
        subAccountName: subAccount.name,
        counterpartyName: recipient?.name || "",
        transactionType: paymentMethod,
        billPayMethod: recipient?.billPaymentMethod,
        isExternalTransfer,
        counterpartyLogoSrc: recipient.logo,
      }}
    />
  ) : null
}
