import React from "react"
import { SxStyleProp } from "rebass"
import { images } from "@assets/images/images"
import { theme } from "@layouts/theme"
import { Button, ButtonTypeEnum } from "@components/primitive/button/button"

interface IBackButtonProps {
  onClick: () => void
  text: string
  sx?: SxStyleProp
}

export const BackButton: React.FC<IBackButtonProps> = ({ onClick, text, sx }) => {
  return (
    <Button
      type={ButtonTypeEnum.SECONDARY}
      sx={{
        background: "none",
        marginBottom: 1,
        height: 35,
        color: theme.colors.ui2,
        ":hover": {
          background: "none",
          color: theme.colors.ui1,
        },
        ...sx,
      }}
      onClick={onClick}
      iconSrc={images.icons.chevronLeft}
      textSx={{ color: "inherit" }}
      textTag="body"
    >
      {text}
    </Button>
  )
}
