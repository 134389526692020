import React, { PropsWithChildren } from "react"
import { useTranslation } from "react-i18next"
import { FlexColumnLayout, Text } from "@northone/ui"
import { BillingDetailsBox } from "../shared/components/billing-details-box"
import { theme } from "@layouts/theme"
import { formatMonthlyPrice, getPlanMonthlyPrice } from "../utils"
import { Subscription } from "../types"
import { PlanPill } from "./components/plan-pill"
import { navigateToModal } from "@utils/navigation"
import { useHistory, useLocation } from "react-router-dom"
import { RoutesEnum } from "@routers/types"
import { SxStyleProp } from "rebass"
import { ButtonTypeEnum, Button } from "@components/primitive/button/button"
import { Analytics } from "@core/analytics/actions"
import { events } from "@core/analytics/events"
import { getFormattedFullDate, getFormattedShortDate } from "@utils/dates"
import { SubscriptionStatus } from "@generated/graphql"

const I18N_LABELS = {
  billingDetailsTitleKeyPath: "subscriptions.billingDetails.title",
  planKeyPath: "subscriptions.billingDetails.labels.plan",
  monthlyFeeKeyPath: "subscriptions.billingDetails.labels.monthlyFee",
  nextBillingDateKeyPath: "subscriptions.billingDetails.labels.nextBillingDate",
  fromAccountKeyPath: "subscriptions.billingDetails.labels.fromAccount",
  depositAccountKeyPath: "subscriptions.billingDetails.labels.depositAccount",
  cancelPlanKeyPath: "subscriptions.cancel.cta",
  reactivatePlanKeyPath: "subscriptions.reactivate.cta",
} as const

export const SubscriptionsAccountDetails = ({ subscription }: { subscription: Subscription }) => {
  const { t } = useTranslation()
  const currentLocation = useLocation()
  const history = useHistory()

  if (!subscription) return null
  const { plan, nextBillingDate, status } = subscription
  const cancelRequested = status === SubscriptionStatus.CANCEL_REQUESTED

  const planTitle = plan.title
  const monthlyPrice = getPlanMonthlyPrice(plan)

  if (typeof monthlyPrice !== "number" || !nextBillingDate) return null

  const pillText = cancelRequested
    ? t("subscriptions.activeUntil", {
        nextBillingDate: getFormattedShortDate(nextBillingDate),
      })
    : t("subscriptions.billingDetails.pill.active")
  const buttonText = cancelRequested
    ? t(I18N_LABELS.reactivatePlanKeyPath)
    : t(I18N_LABELS.cancelPlanKeyPath)

  const billingDetailsItems = [
    {
      label: t(I18N_LABELS.planKeyPath),
      value: planTitle,
    },
    {
      label: t(I18N_LABELS.monthlyFeeKeyPath),
      value: formatMonthlyPrice(monthlyPrice),
    },
    {
      label: t(I18N_LABELS.nextBillingDateKeyPath),
      value: getFormattedFullDate(nextBillingDate),
    },
    {
      label: t(I18N_LABELS.fromAccountKeyPath),
      value: t(I18N_LABELS.depositAccountKeyPath),
    },
  ]

  const onCancelClick = () => {
    Analytics.track(events.subscriptions.openCancelSubscriptionModal, {
      planName: plan?.name,
    })
    navigateToModal({
      currentLocation,
      history,
      routeName: RoutesEnum.SUBSCRIPTION_CANCEL,
    })
  }

  const onReactivateClick = () => {
    Analytics.track(events.subscriptions.reactivatePlanClick, { planName: plan?.name })
    navigateToModal({
      currentLocation,
      history,
      routeName: RoutesEnum.SUBSCRIPTION_REACTIVATE,
    })
  }

  return (
    <SubscriptionsAccountDetailsLayout>
      <BillingDetailsBox items={billingDetailsItems} pillComponent={<PlanPill text={pillText} />}>
        <Button
          type={ButtonTypeEnum.SECONDARY}
          onClick={cancelRequested ? onReactivateClick : onCancelClick}
          sx={buttonStyle.container}
        >
          <Text tag="body" sx={buttonStyle.text}>
            {buttonText}
          </Text>
        </Button>
      </BillingDetailsBox>
    </SubscriptionsAccountDetailsLayout>
  )
}

const SubscriptionsAccountDetailsLayout = ({ children }: PropsWithChildren) => {
  const { t } = useTranslation()

  const { containerStyle, textStyle, childContainerStyle } = layoutStyle

  return (
    <FlexColumnLayout sx={containerStyle}>
      <Text tag="h3" sx={textStyle}>
        {t(I18N_LABELS.billingDetailsTitleKeyPath)}
      </Text>
      <FlexColumnLayout sx={childContainerStyle}>{children}</FlexColumnLayout>
    </FlexColumnLayout>
  )
}

const layoutStyle = {
  containerStyle: {
    marginTop: "40px",
    width: "100%",
    rowGap: "24px",
  },
  textStyle: {
    fontSize: "28px",
  },
  childContainerStyle: {
    width: "100%",
    justifyContent: "space-between",
    rowGap: "24px",
  },
}

const buttonStyle: Record<string, SxStyleProp> = {
  container: {
    border: "none",
    textAlign: "left",
    textDecoration: "underline",
    background: "none",
    width: "fit-content",
    padding: 0,
    ":hover": { backgroundColor: "none" },
    ":active": { backgroundColor: "none" },
  },
  text: {
    color: theme.colors.ui2,
    textDecoration: "underline",
    ...theme.textStyles.body,
  },
}
