import React from "react"
import { FlowModalLayout } from "@components/extended/overlay/flow-modal"
import { DomesticWireRecipientForm } from "@features/move-money/domestic-wire/recipient/form"
import { DomesticWireRecipientDetailsForm } from "@features/move-money/domestic-wire/recipient-details/form"
import { DomesticWireAmountForm } from "@features/move-money/domestic-wire/amount/form"
import { DomesticWireSubAccountForm } from "@features/move-money/domestic-wire/sub-account/form"
import { DomesticWireConfirmCard } from "@features/move-money/domestic-wire/confirm/card"
import { DomesticWireSentCard } from "@features/move-money/domestic-wire/sent/card"
import { DomesticWirePurpose } from "@features/move-money/domestic-wire/purpose/form"
import { DomesticWireConfirmNotice } from "@features/move-money/domestic-wire/confirm-notice/confirm-notice"
import { moveMoneyDomesticWireProgressBarItems } from "./progress-bar-items"

export type TDomesticWireScreenNames =
  | "DOMESTIC_WIRE_RECIPIENT"
  | "DOMESTIC_WIRE_RECIPIENT_DETAILS"
  | "DOMESTIC_WIRE_AMOUNT"
  | "DOMESTIC_WIRE_SUB_ACCOUNT"
  | "DOMESTIC_WIRE_PURPOSE"
  | "DOMESTIC_WIRE_CONFIRM"
  | "DOMESTIC_WIRE_CONFIRM_NOTICE"
  | "DOMESTIC_WIRE_SENT"

export enum DomesticWireScreensEnum {
  DOMESTIC_WIRE_RECIPIENT = "DOMESTIC_WIRE_RECIPIENT",
  DOMESTIC_WIRE_RECIPIENT_DETAILS = "DOMESTIC_WIRE_RECIPIENT_DETAILS",
  DOMESTIC_WIRE_AMOUNT = "DOMESTIC_WIRE_AMOUNT",
  DOMESTIC_WIRE_SUB_ACCOUNT = "DOMESTIC_WIRE_SUB_ACCOUNT",
  DOMESTIC_WIRE_PURPOSE = "DOMESTIC_WIRE_PURPOSE",
  DOMESTIC_WIRE_CONFIRM = "DOMESTIC_WIRE_CONFIRM",
  DOMESTIC_WIRE_CONFIRM_NOTICE = "DOMESTIC_WIRE_CONFIRM_NOTICE",
  DOMESTIC_WIRE_SENT = "DOMESTIC_WIRE_SENT",
}

export const DOMESTIC_WIRE_ROUTER: {
  [key in TDomesticWireScreenNames]: {
    component: React.ReactElement
    progressItem: string
    layout: FlowModalLayout
  }
} = {
  DOMESTIC_WIRE_RECIPIENT: {
    component: <DomesticWireRecipientForm />,
    progressItem: moveMoneyDomesticWireProgressBarItems[1],
    layout: "form",
  },
  DOMESTIC_WIRE_RECIPIENT_DETAILS: {
    component: <DomesticWireRecipientDetailsForm />,
    progressItem: moveMoneyDomesticWireProgressBarItems[2],
    layout: "form",
  },
  DOMESTIC_WIRE_AMOUNT: {
    component: <DomesticWireAmountForm />,
    progressItem: moveMoneyDomesticWireProgressBarItems[3],
    layout: "form",
  },
  DOMESTIC_WIRE_SUB_ACCOUNT: {
    component: <DomesticWireSubAccountForm />,
    progressItem: moveMoneyDomesticWireProgressBarItems[4],
    layout: "form",
  },
  DOMESTIC_WIRE_PURPOSE: {
    component: <DomesticWirePurpose />,
    progressItem: moveMoneyDomesticWireProgressBarItems[5],
    layout: "form",
  },
  DOMESTIC_WIRE_CONFIRM: {
    component: <DomesticWireConfirmCard />,
    progressItem: moveMoneyDomesticWireProgressBarItems[6],
    layout: "centered",
  },
  DOMESTIC_WIRE_CONFIRM_NOTICE: {
    component: <DomesticWireConfirmNotice />,
    progressItem: moveMoneyDomesticWireProgressBarItems[7],
    layout: "centered",
  },
  DOMESTIC_WIRE_SENT: {
    component: <DomesticWireSentCard />,
    progressItem: moveMoneyDomesticWireProgressBarItems[8],
    layout: "centered",
  },
}
