import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import { formatMoney } from "accounting"
import { DateTime } from "luxon"

import { appActions } from "@core/redux/app/actions"
import { RoutesEnum } from "@routers/types"
import { theme } from "@layouts/theme"
import { WIRE_TRANSFER_AGREEMENT_LINK } from "@utils/constants"
import { FlexColumnLayout, FlexRowLayout } from "@northone/ui"
import { IErrorMessage, NoticeBox } from "@components/primitive/notice-box/notice-box"
import { useAppSelector } from "@core/redux/utils"
import { selectActiveBusinessID } from "@core/active-business/redux/selectors"
import { LoadingIcon } from "@components/primitive/loading-icon/loading-icon"
import { ButtonTypeEnum } from "@components/primitive/button/button"
import { FormBody } from "@components/composite/form-body/form-body"
import { Text } from "@components/primitive/text/text"
import { ErrorScreen } from "@components/composite/error-screen/error-screen"
import { invoicePaymentsActions } from "@features/invoice-payments/redux/actions"
import { formatAddress } from "@utils/format"
import {
  InvoiceAccountPayablePaymentMethod,
  useAchCutoffTimesLazyQuery,
  useTransactionFeesLazyQuery,
} from "@generated/graphql"
import { getInvoicePayments } from "@features/invoice-payments/redux/selector"
import {
  useInvoicePaymentsContact,
  useBankAccountInfo,
  useCreateInvoicePayment,
  useCreateInvoiceScheduledPayment,
  useListInvoiceAccountsPayable,
} from "@features/invoice-payments/hooks"
import { getDateWithSpecificFormat } from "@utils/dates"
import { Analytics } from "@core/analytics/actions"
import { events } from "@core/analytics/events"
import { getReviewPaymentCustomErrorMessage } from "./utils"
import { InvoiceSubscriptionUpsellButton } from "../subscription-upsell/upsell-button"
import { useSubscriptionsData } from "@features/subscriptions/shared/hooks/use-subscriptions-data"
const SAME_DAY_ACH_PAYMENT_TYPE = "ach_sameday"

export const InvoiceModalPaymentsReview = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  const [isInvoicesPollingLoading, setInvoicesPollingLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<IErrorMessage>({
    text: "",
    customerCareLinkRequired: false,
  })

  const businessId = useAppSelector(selectActiveBusinessID)
  const invoice = useAppSelector(getInvoicePayments)
  const invoicePaymentDetails = invoice.invoicePaymentDetails
  const transactionFeeData =
    useRef<{
      __typename?: "TransactionFeesResponse"
      domesticWire: number
      mailedCheck: number
      sameDayAch: number
      billPay: number
    }>()

  const onError = () => {
    setErrorMessage({ text: t("errors.generic"), customerCareLinkRequired: false })
  }

  const {
    accountNumber,
    accountId,
    isLoading: isBankAccountInfoLoading,
    subAccounts,
  } = useBankAccountInfo()
  const { contact } = useInvoicePaymentsContact()

  const [getAchCutoffTimes] = useAchCutoffTimesLazyQuery({
    fetchPolicy: "network-only",
    variables: {
      businessId,
    },
    onError,
  })

  const { createInvoicePaymentMutation, isCreateInvoicePaymentLoading } = useCreateInvoicePayment()

  const { createInvoiceScheduledPaymentMutation, isCreateInvoiceScheduledPaymentLoading } =
    useCreateInvoiceScheduledPayment()

  const subAccount = subAccounts?.find(
    (subAccount) => subAccount.id === invoicePaymentDetails?.subAccountId,
  )

  const invoicesQueryVariables = useAppSelector(
    (state) => state.invoicePayments.invoicesQueryVariables,
  )

  const { poll: pollInvoices, refetch: refetchInvoices } = useListInvoiceAccountsPayable({
    queryVariables: invoicesQueryVariables,
  })

  const onTransactionFeeError = () => {
    setErrorMessage({ text: t("errors.genericNetworkError.title"), customerCareLinkRequired: true })
  }

  const [getTransactionFees, { loading: transactionFeeLoading }] = useTransactionFeesLazyQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
    onError: onTransactionFeeError,
    onCompleted: (data) => {
      if (data && data.transactionFees) {
        transactionFeeData.current = data.transactionFees
      }
    },
  })

  const isSameDayACHPayment =
    invoicePaymentDetails?.paymentMethodDetails.type === InvoiceAccountPayablePaymentMethod.ACH &&
    invoicePaymentDetails?.paymentMethod === SAME_DAY_ACH_PAYMENT_TYPE

  const isMailedCheckPayment = invoicePaymentDetails?.paymentMethod === "mailed_check"

  const isWirePayment = invoicePaymentDetails?.paymentMethod === "wire"

  const isBillPayPayment = invoicePaymentDetails?.paymentMethod === "bill_pay"

  const isPaymentDateSetForToday =
    invoicePaymentDetails?.paymentDate === DateTime.local().toISODate()

  const paymentMethod = invoicePaymentDetails?.paymentMethod

  const isLoading =
    isCreateInvoicePaymentLoading ||
    isCreateInvoiceScheduledPaymentLoading ||
    isInvoicesPollingLoading ||
    transactionFeeLoading

  const getPaymentMethodTitle = () => {
    switch (paymentMethod) {
      case InvoiceAccountPayablePaymentMethod.ACH:
        return t("invoicePayments.modal.reviewPayment.inputs.paymentMethod.standardAch")
      case InvoiceAccountPayablePaymentMethod.MAILED_CHECK:
        return t("invoicePayments.modal.reviewPayment.inputs.paymentMethod.mailedCheck")
      case InvoiceAccountPayablePaymentMethod.WIRE:
        return t("invoicePayments.modal.reviewPayment.inputs.paymentMethod.wire")
      case SAME_DAY_ACH_PAYMENT_TYPE:
        return t("invoicePayments.modal.reviewPayment.inputs.paymentMethod.sameDayAch")
      case "bill_pay": // TODO Replace with InvoiceAccountPayablePaymentMethod.BILL_PAY when Bill Pay is added to the enum
        return t("invoicePayments.modal.reviewPayment.inputs.paymentMethod.billPay")
      default:
        return ""
    }
  }

  const getPaymentMethodFee = () => {
    if (!transactionFeeData.current) return
    if (isSameDayACHPayment) {
      return t("invoicePayments.modal.reviewPayment.inputs.paymentMethod.fee", {
        amount: formatMoney(transactionFeeData.current.sameDayAch, {
          precision: 2,
        }),
      })
    }

    if (isMailedCheckPayment) {
      return t("invoicePayments.modal.reviewPayment.inputs.paymentMethod.fee", {
        amount: formatMoney(transactionFeeData.current.mailedCheck, { precision: 2 }),
      })
    }

    if (isWirePayment) {
      return t("invoicePayments.modal.reviewPayment.inputs.paymentMethod.fee", {
        amount: formatMoney(transactionFeeData.current.domesticWire, { precision: 2 }),
      })
    }

    if (isBillPayPayment) {
      return t("invoicePayments.modal.reviewPayment.inputs.paymentMethod.fee", {
        amount: formatMoney(transactionFeeData.current.billPay, { precision: 2 }),
      })
    }

    return t("invoicePayments.modal.reviewPayment.inputs.paymentMethod.noFee")
  }

  const getContactPaymentDetails = () => {
    switch (paymentMethod) {
      case "ach":
      case "ach_sameday":
        return contact?.achPaymentDetails?.accountNumber ?? ""
      case "mailed_check":
        return contact?.address ? formatAddress(contact.address) : ""
      case "wire":
        return contact?.wirePaymentDetails?.accountNumber ?? ""
      case "bill_pay":
        return contact?.billPayPaymentDetails?.accountNumber ?? ""
    }
  }

  const SubTitle = () => (
    <FlexRowLayout sx={{ columnGap: 2, paddingBottom: 24 }}>
      <Text tag="body-large-bold">{t("invoicePayments.modal.reviewPayment.inputs.pay")}</Text>
      <Text textColor={theme.colors.gold100} tag="body-large-bold">
        {formatMoney(Number(invoicePaymentDetails?.paymentAmount))}
      </Text>
      <Text tag="body-large-bold">{t("invoicePayments.modal.reviewPayment.inputs.to")}</Text>
      <Text textColor={theme.colors.gold100} tag="body-large-bold">
        {contact?.name}
      </Text>
    </FlexRowLayout>
  )

  const PaymentMethod = () => (
    <FlexColumnLayout
      sx={{
        padding: "20px 0",
        borderBottom: `2px solid ${theme.colors.ui5}`,
      }}
    >
      <Text color={theme.colors.ui2} tag="label">
        {t("invoicePayments.modal.reviewPayment.inputs.paymentMethod.label").toUpperCase()}
      </Text>
      <Text tag="body-large-bold">{getPaymentMethodTitle()}</Text>
      <Text color={theme.colors.ui2} tag="body-small">
        {getPaymentMethodFee()}
      </Text>
      <InvoiceSubscriptionUpsellButton />
    </FlexColumnLayout>
  )

  const PaymentDate = () => {
    if (!invoicePaymentDetails) return null

    return (
      <FlexColumnLayout
        sx={{
          padding: "16px 0",
          borderBottom: `2px solid ${theme.colors.ui5}`,
          overflowY: "auto",
        }}
      >
        <Text color={theme.colors.ui2} tag="label">
          {t("invoicePayments.modal.reviewPayment.inputs.paymentDate.label").toUpperCase()}
        </Text>
        <Text tag="body-large-bold">
          {getDateWithSpecificFormat(invoicePaymentDetails?.paymentDate, "MMMM dd, yyyy")}
        </Text>
      </FlexColumnLayout>
    )
  }

  const InvoiceSendTo = () => {
    const contactPaymentDetails = getContactPaymentDetails()

    return (
      <FlexColumnLayout
        sx={{
          padding: "16px 0",
          borderBottom: `2px solid ${theme.colors.ui5}`,
        }}
      >
        <Text color={theme.colors.ui2} tag="label">
          {t("invoicePayments.modal.reviewPayment.inputs.sendTo.label").toUpperCase()}
        </Text>
        <Text tag="body-large-bold">{contact?.name}</Text>
        {contactPaymentDetails ? (
          <Text color={theme.colors.ui2} sx={{ alignItems: "flex-start" }} tag="body-small">
            {paymentMethod === "mailed_check"
              ? contactPaymentDetails
              : `(...${contactPaymentDetails.slice(-4)})`}
          </Text>
        ) : (
          <LoadingIcon style={{ margin: 0 }} />
        )}
      </FlexColumnLayout>
    )
  }

  const AmountWithdrawFrom = () => {
    if (!invoicePaymentDetails) return null
    return (
      <FlexColumnLayout
        sx={{
          padding: "16px 0",
          borderBottom: `2px solid ${theme.colors.ui5}`,
        }}
      >
        <Text color={theme.colors.ui2} tag="label">
          {t("invoicePayments.modal.reviewPayment.inputs.withdrawFrom.label").toUpperCase()}
        </Text>
        <FlexRowLayout sx={{ columnGap: 2 }}>
          <Text tag="body-large-bold">{subAccount?.name}</Text>

          {isBankAccountInfoLoading || !subAccount ? (
            <LoadingIcon style={{ margin: 0 }} />
          ) : (
            <Text color={theme.colors.ui2} sx={{ alignItems: "flex-start" }} tag="body-large-bold">
              ({formatMoney(subAccount.balance)})
            </Text>
          )}
        </FlexRowLayout>
        {isBankAccountInfoLoading ? (
          <LoadingIcon style={{ margin: 0 }} />
        ) : (
          <Text color={theme.colors.ui2} tag="body-small">
            {accountNumber && subAccount?.isDefault
              ? `(...${accountNumber.slice(-4)})`
              : t("invoicePayments.modal.reviewPayment.inputs.withdrawFrom.envelope")}
          </Text>
        )}
      </FlexColumnLayout>
    )
  }

  const Disclosure = () => {
    if (!isWirePayment) return null
    return (
      <FlexRowLayout>
        <Text
          color={theme.colors.ui2}
          sx={{ margin: "auto", paddingTop: 16 }}
          tag="body-x-small"
          textWithEmbeddedLinksProps={{
            text: t("invoicePayments.modal.reviewPayment.disclosure"),
            linksInOrder: [WIRE_TRANSFER_AGREEMENT_LINK],
            linkStyle: { color: theme.colors.ui2 },
          }}
        />
      </FlexRowLayout>
    )
  }

  const handleSendPaymentClick = async () => {
    try {
      if (isPaymentDateSetForToday) {
        Analytics.track(events.invoicePayments.invoiceDetails.sendPaymentClick, {
          invoiceId: invoice.activeInvoice?.id,
        })
      } else {
        Analytics.track(events.invoicePayments.invoiceDetails.schedulePaymentClick, {
          invoiceId: invoice.activeInvoice?.id,
        })
      }

      setInvoicesPollingLoading(true)

      if (paymentMethod === "ach_sameday" && isPaymentDateSetForToday) {
        const achCutoffTimes = await getAchCutoffTimes()

        if (!achCutoffTimes.data?.achCutoffTimes?.isSameDayAvailable) {
          setErrorMessage({
            text: t("invoicePayments.modal.paymentDetails.pastCutOffTimeError"),
            customerCareLinkRequired: false,
          })
          setInvoicesPollingLoading(false)
          return
        }
      }

      isPaymentDateSetForToday
        ? await createInvoicePaymentMutation()
        : await createInvoiceScheduledPaymentMutation()

      await pollInvoices()
      await refetchInvoices()

      dispatch(invoicePaymentsActions.clearState())
      history.push(RoutesEnum.INVOICES)

      const confirmationToastMessage = isPaymentDateSetForToday
        ? t("invoicePayments.modal.paymentDetails.confirmationToastMessage.immediatePayment")
        : t("invoicePayments.modal.paymentDetails.confirmationToastMessage.scheduledPayment")

      dispatch(appActions.setNotificationMessage(confirmationToastMessage))
    } catch (e) {
      const createPaymentErrorText = getReviewPaymentCustomErrorMessage({
        error: e,
        isScheduledPayment: !isPaymentDateSetForToday,
      })

      setErrorMessage({
        text: createPaymentErrorText.errorText,
        customerCareLinkRequired: createPaymentErrorText.isDefault,
      })
    } finally {
      setInvoicesPollingLoading(false)
    }
  }

  const { subscription } = useSubscriptionsData()
  useEffect(() => {
    if (invoicePaymentDetails) {
      getTransactionFees({
        variables: { businessId, amount: Number(invoicePaymentDetails.paymentAmount) },
      })
    }
  }, [invoicePaymentDetails, subscription?.plan.name])

  if ((!accountId || !subAccount) && !isLoading) {
    return <ErrorScreen size="medium" sx={{ p: 40 }} />
  }

  return (
    <FormBody
      containerSx={{ width: "50%", padding: "40px", overflow: "auto" }}
      title={t("invoicePayments.modal.reviewPayment.title")}
      buttonContainerSx={{ width: "100%", justifyContent: "flex-end" }}
      displayButtonsOnBottom
      buttons={[
        {
          canContinueWithKey: true,
          children: t("invoicePayments.modal.reviewPayment.buttons.editPayment"),
          onClick: () => {
            Analytics.track(events.invoicePayments.invoiceDetails.editPaymentDetailsClick, {
              invoiceId: invoice.activeInvoice?.id,
            })
            dispatch(invoicePaymentsActions.navigate("PAYMENT_DETAILS"))
          },
          type: ButtonTypeEnum.SECONDARY,
          disabled: isLoading,
        },
        {
          canContinueWithKey: true,
          // TODO: Need to be updated with the Scheduled Payment title ENG-1034
          children: t(
            isPaymentDateSetForToday
              ? "invoicePayments.modal.reviewPayment.buttons.sendPayment"
              : "invoicePayments.modal.reviewPayment.buttons.schedulePayment",
          ),
          onClick: handleSendPaymentClick,
          type: ButtonTypeEnum.PRIMARY_BLUE,
          isLoading,
        },
      ]}
    >
      <FlexColumnLayout sx={{ width: "100%" }}>
        {errorMessage.text && (
          <NoticeBox
            level="error"
            content={
              <Text tag="body-small">
                {errorMessage.text}{" "}
                {errorMessage.customerCareLinkRequired && (
                  <a
                    style={{ color: theme.colors.ui1 }}
                    href="#"
                    onClick={() => {
                      window.Intercom("show")
                      return false
                    }}
                  >
                    {t("invoicePayments.modal.reviewPayment.error.customerCareLink")}
                  </a>
                )}
                {errorMessage.customerCareLinkRequired && "."}
              </Text>
            }
          />
        )}
        <SubTitle />
        <PaymentMethod />
        <PaymentDate />
        <InvoiceSendTo />
        <AmountWithdrawFrom />
        <Disclosure />
      </FlexColumnLayout>
    </FormBody>
  )
}
