import focusedSelectedCheckbox from "./focused-selected-checkbox.svg"
import defaultSelectedCheckbox from "./selected-checkbox.svg"
import disabledSelectedCheckbox from "./selected-disabled-checkbox.svg"

import focusedUnselectedCheckbox from "./focused-unselected-checkbox.svg"
import defaultUnselectedCheckbox from "./unselected-checkbox.svg"
import disabledUnselectedCheckbox from "./unselected-disabled-checkbox.svg"

import focusedSelectedRadio from "./focused-selected-radio.svg"
import defaultSelectedRadio from "./selected-radio.svg"
import disabledSelectedRadio from "./selected-disabled-radio.svg"

import focusedUnselectedRadio from "./focused-unselected-radio.svg"
import defaultUnselectedRadio from "./unselected-radio.svg"
import disabledUnselectedRadio from "./unselected-disabled-radio.svg"

export const selectionControlIcons = {
  checkbox: {
    selected: {
      focused: focusedSelectedCheckbox,
      default: defaultSelectedCheckbox,
      disabled: disabledSelectedCheckbox,
    },
    unselected: {
      focused: focusedUnselectedCheckbox,
      default: defaultUnselectedCheckbox,
      disabled: disabledUnselectedCheckbox,
    },
  },
  radio: {
    selected: {
      focused: focusedSelectedRadio,
      default: defaultSelectedRadio,
      disabled: disabledSelectedRadio,
    },
    unselected: {
      focused: focusedUnselectedRadio,
      default: defaultUnselectedRadio,
      disabled: disabledUnselectedRadio,
    },
  },
}
