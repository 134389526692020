import React, { useState, useEffect } from "react"
import clsx from "clsx"
import "./shimmer.css"
import { Box, SxStyleProp } from "rebass"

const DEFAULT_DURATION_MS = 1500

const calcShimmerStyle = (width: number, height: number, duration = DEFAULT_DURATION_MS) => ({
  backgroundSize: `${width * 10}px ${height}px`,
  animationDuration: `${(duration / 1000).toFixed(1)}s`,
})

interface IShimmerProps {
  customHeight?: number
  customWidth?: number
  duration?: number
  sx?: SxStyleProp
}

export const Shimmer: React.FC<IShimmerProps> = ({ duration, customHeight, customWidth, sx }) => {
  const shimmerRef: React.RefObject<HTMLDivElement> = React.useRef(null)
  const [containerWidth, setContainerWidth] = useState(0)
  const [containerHeight, setContainerHeight] = useState(0)

  const setDimensions = () => {
    const containerWidth = shimmerRef.current?.offsetWidth
    const parentHeight = shimmerRef.current?.offsetHeight

    setContainerWidth(containerWidth || 0)
    setContainerHeight(parentHeight || 0)
  }

  useEffect(() => {
    setDimensions()
  }, [])

  const shimmerStyle = calcShimmerStyle(containerWidth, containerHeight, duration)
  const style = {
    ...shimmerStyle,
    ...{
      height: customHeight ? customHeight : 100,
      width: customWidth ? customWidth : "100%",
    },
  }

  return <Box ref={shimmerRef} className={clsx("shimmer")} sx={{ ...sx, ...style }} />
}
