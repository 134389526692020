import { OnboardingMeQuery } from "@generated/graphql"
import { TUserActions, UserActionEnum } from "./actions"

export interface IUserState {
  userId: string
  me?: OnboardingMeQuery["me"]
}

export const initialUserState = {
  userId: "",
  me: undefined,
}

export const userReducer = (
  state: IUserState = initialUserState,
  action: TUserActions,
): IUserState => {
  switch (action.type) {
    case UserActionEnum.SET_USER_ID:
      return {
        ...state,
        userId: action.payload,
      }
    case UserActionEnum.SET_ME:
      return {
        ...state,
        me: action.payload,
      }
    default:
      return state
  }
}
