import React from "react"
import { useDispatch } from "react-redux"
import { SubscriptionUpsellButton } from "@features/subscriptions/shared/components/upsell-button"
import { invoicePaymentsActions } from "@features/invoice-payments/redux/actions"
import { getInvoicePayments } from "@features/invoice-payments/redux/selector"
import { useAppSelector } from "@core/redux/utils"
import { IPaymentDetailsState } from "@features/invoice-payments/redux/reducer"
import { Analytics } from "@core/analytics/actions"
import { events } from "@core/analytics/events"
import { SubscriptionUpsellTransactionType } from "@generated/graphql"
import { useTransactionSubscriptionUpsell } from "@features/subscriptions/shared/hooks/use-transaction-subscription-upsell"

export const InvoiceSubscriptionUpsellButton = () => {
  const dispatch = useDispatch()
  const paymentMethod = useAppSelector(getInvoicePayments).invoicePaymentDetails?.paymentMethod
  const transactionType = getUpsellTransactionTypeFromInvoicePaymentMethod(paymentMethod)
  const { upsellPlan } = useTransactionSubscriptionUpsell({
    transactionType,
    fetchPolicy: "cache-and-network",
  })
  const buttonContent = upsellPlan?.buttonContent

  if (!buttonContent) return null

  return (
    <SubscriptionUpsellButton
      text={buttonContent}
      onClick={() => {
        Analytics.track(events.subscriptions.billPayUpsell, { paymentMethod })
        dispatch(invoicePaymentsActions.navigate("SUBSCRIPTION_UPSELL_DETAILS"))
      }}
      sx={{ marginTop: "16px" }}
    />
  )
}

export const getUpsellTransactionTypeFromInvoicePaymentMethod = (
  paymentMethod?: IPaymentDetailsState["paymentMethod"],
): SubscriptionUpsellTransactionType | undefined => {
  switch (paymentMethod) {
    case "ach":
      return SubscriptionUpsellTransactionType.ACH
    case "ach_sameday":
      return SubscriptionUpsellTransactionType.ACH_SAMEDAY
    case "bill_pay":
      return SubscriptionUpsellTransactionType.BILL_PAYMENT
    case "mailed_check":
      return SubscriptionUpsellTransactionType.PHYSICAL_CHECK
    case "wire":
      return SubscriptionUpsellTransactionType.OUTGOING_WIRE
    default: {
      return
    }
  }
}
