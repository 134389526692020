import React from "react"
import {
  Button,
  ListItem,
  Text,
  Avatar,
  ButtonTypeEnum,
  LoadingIcon,
  FlexColumnLayout,
  Pill,
} from "@northone/ui"
import { useTranslation } from "react-i18next"

import { theme } from "@layouts/theme"
import { PlaidItem } from "@generated/graphql"
import { AlignedColumnLayout } from "@components/extended/list-items/aligned-column-layout"
import { images } from "@assets/images/images"

type ConnectNewItemProps = {
  onClick: () => void
  loading: boolean
}

export function ConnectNewItem({ onClick, loading }: ConnectNewItemProps) {
  const { t } = useTranslation()

  return (
    <ListItem hasHoverState={true} onClick={onClick} disabled={loading}>
      <AlignedColumnLayout alignment="flex-start" widthRadio={0.4} sidePadding={20}>
        {loading ? (
          <FlexColumnLayout
            sx={{
              justifyContent: "center",
              alignItems: "center",
              padding: "10px",
              marginRight: "5px",
            }}
          >
            <LoadingIcon />
          </FlexColumnLayout>
        ) : (
          <Avatar
            imageSrc={images.icons.add}
            name={"Add"}
            colorThemes={[
              {
                backgroundColor: theme.colors.ui3,
                textColor: theme.colors.ui2,
                borderColor: theme.colors.ui2,
              },
            ]}
            dimensions={{
              width: 40,
              borderRadius: 2,
            }}
          />
        )}
        <Text tag="body-small-bold">{t("settings.pages.linkedAccount.list.connect")}</Text>
      </AlignedColumnLayout>
      <AlignedColumnLayout widthRadio={0.6} alignment="flex-start" />
    </ListItem>
  )
}

function PlaidInstitution({ plaidItem }: { plaidItem: PlaidItem }) {
  const institutionLogo = plaidItem.institution?.logo ?? images.icons.fundingOptions.linked
  const institutionName = plaidItem.institution?.name || ""

  return (
    <>
      <Avatar
        imageSrc={institutionLogo}
        name={institutionName}
        colorThemes={[
          {
            backgroundColor: theme.colors.blue10,
            textColor: theme.colors.blue100,
          },
        ]}
        dimensions={{
          width: 40,
          borderRadius: 2,
        }}
      />
      <Text tag="body-small-bold" sx={{ marginLeft: 10 }}>
        {plaidItem.institution?.name}
      </Text>
    </>
  )
}

function PlaidMaskedAccount({ plaidItem }: { plaidItem: PlaidItem }) {
  const { t } = useTranslation()
  const maskedAccountNumber = `${t("settings.pages.linkedAccount.list.account")} •• ${
    plaidItem.maskedAccountNumber
  }`
  return (
    <Text tag="body-small" sx={{ color: theme.colors.ui2 }}>
      {maskedAccountNumber}
    </Text>
  )
}

type LinkedAccountItemProps = {
  plaidItem: PlaidItem
  showDelete?: boolean
  hasActiveTopUp?: boolean
  onDelete: () => void
}

export function LinkedAccountItem({
  plaidItem,
  showDelete = true,
  hasActiveTopUp = false,
  onDelete,
}: LinkedAccountItemProps) {
  const { t } = useTranslation()
  return (
    <ListItem hasHoverState={false}>
      <AlignedColumnLayout alignment="flex-start" widthRadio={0.3} sidePadding={20}>
        <PlaidInstitution plaidItem={plaidItem} />
      </AlignedColumnLayout>
      <AlignedColumnLayout alignment="flex-start" widthRadio={0.3} sidePadding={20}>
        <PlaidMaskedAccount plaidItem={plaidItem} />
      </AlignedColumnLayout>
      <AlignedColumnLayout alignment="flex-start" widthRadio={0.3}>
        {hasActiveTopUp && (
          <Pill sx={{ backgroundColor: theme.colors.greenTint3 }}>
            <Text tag="label" sx={{ textTransform: "uppercase" }}>
              {t("settings.pages.linkedAccount.list.topUpsActive")}
            </Text>
          </Pill>
        )}
      </AlignedColumnLayout>
      <AlignedColumnLayout alignment="flex-end" widthRadio={0.1} sidePadding={20}>
        {showDelete && (
          <Button
            type={ButtonTypeEnum.SECONDARY}
            iconSrc={images.icons.greyTrash}
            onClick={onDelete}
          />
        )}
      </AlignedColumnLayout>
    </ListItem>
  )
}

export function LinkedAccountItemWithoutActions({ plaidItem }: { plaidItem: PlaidItem }) {
  return (
    <ListItem hasHoverState={false}>
      <AlignedColumnLayout alignment="flex-start" widthRadio={0.5} sidePadding={20}>
        <PlaidInstitution plaidItem={plaidItem} />
      </AlignedColumnLayout>
      <AlignedColumnLayout alignment="flex-end" widthRadio={0.4} sidePadding={20}>
        <PlaidMaskedAccount plaidItem={plaidItem} />
      </AlignedColumnLayout>
      <AlignedColumnLayout alignment="flex-end" widthRadio={0.1} sidePadding={50} />
    </ListItem>
  )
}
