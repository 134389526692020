import React from "react"
import { Image, SxStyleProp } from "rebass"

export const ImageLink = ({
  imgSrc,
  imgSx,
  url,
}: {
  imgSrc: string
  imgSx?: SxStyleProp
  url: string
}) => (
  <a href={url} target="_blank" rel="noreferrer">
    <Image src={imgSrc} sx={imgSx} />
  </a>
)
