import React, { useRef, useEffect, useState } from "react"
import { useRouteMatch, useHistory, useLocation } from "react-router-dom"
import { useDispatch } from "react-redux"

import { navigateFromModal } from "@utils/navigation"
import { RoutesEnum, ILocationState } from "@routers/types"
import { useAppSelector } from "@core/redux/utils"
import { FlowModal } from "@components/extended/overlay/flow-modal"
import { invoicePaymentsActions } from "../redux/actions"
import { getInvoicePayments, selectInvoicePaymentModalHistory } from "../redux/selector"
import {
  invoicePaymentsModalProgressItems,
  INVOICE_PAYMENTS_MODAL_ROUTES,
} from "./invoice-payments-modal.router"
import { InvoiceAttachment } from "./invoice-attachment"
import { Analytics } from "@core/analytics/actions"
import { events } from "@core/analytics/events"
import { FlexRowLayout } from "@layouts/flex"
import { InvoiceExitPrompt } from "./invoice-prompt-modal/invoice-exit-prompt"

export const InvoicePaymentsModal: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const ref = useRef<HTMLDivElement>(null)
  const location = useLocation<ILocationState>()
  const isRouteMatch = useRouteMatch({ path: RoutesEnum.INVOICE_PAYMENTS })
  const screenHistory = useAppSelector(selectInvoicePaymentModalHistory)
  const [isExitPromptOpen, setIsExitPromptOpen] = useState<boolean>(false)

  const currentRouteName = screenHistory[screenHistory.length - 1]
  const currentRoute = INVOICE_PAYMENTS_MODAL_ROUTES[currentRouteName]
  const isInvoiceDetailsRoute = currentRouteName === "INVOICE_DETAILS"

  const isUpsellRoute = ["SUBSCRIPTION_UPSELL_DETAILS", "SUBSCRIPTION_UPSELL_CONFIRM"].includes(
    currentRouteName,
  )

  const { hasInvoiceDetailsChanged, hasInvoicePaymentDetailsChanged } =
    useAppSelector(getInvoicePayments)

  const getShouldShowExitModalPrompt = () => {
    if (isUpsellRoute) return true
    return isInvoiceDetailsRoute ? hasInvoiceDetailsChanged : hasInvoicePaymentDetailsChanged
  }

  useEffect(() => {
    if (ref?.current) {
      ref.current.scrollTo({ top: 0 })
    }
  }, [currentRoute])

  const navigateToInvoicesLandingPage = () => {
    dispatch(invoicePaymentsActions.clearState()) // We want to be sure that the state is cleared before navigating away
    navigateFromModal({ defaultBackRoute: RoutesEnum.INVOICES, history, location })
  }

  const handleExitButtonClick = () => {
    Analytics.track(events.invoicePayments.invoiceDetails.exitClick)

    if (getShouldShowExitModalPrompt()) {
      setIsExitPromptOpen(true)
    } else {
      navigateToInvoicesLandingPage()
    }
  }

  const containerStyle = isUpsellRoute
    ? { height: "90vh", paddingY: "50px" }
    : { width: "100%", padding: 0, margin: 0 }

  return (
    <FlowModal
      containerStyle={containerStyle}
      scrollContainerRef={ref}
      layout={currentRoute.layout}
      isOpen={Boolean(isRouteMatch?.isExact)}
      onExitButtonClick={handleExitButtonClick}
      progressBar={{
        currentItem: currentRoute.progressItem,
        progressItems: invoicePaymentsModalProgressItems,
      }}
    >
      {isUpsellRoute ? (
        currentRoute.component
      ) : (
        <FlexRowLayout sx={{ padding: 0, margin: 0, height: "100%" }}>
          <InvoiceAttachment />
          {currentRoute.component}
        </FlexRowLayout>
      )}
      <InvoiceExitPrompt
        isOpen={isExitPromptOpen}
        onCancelExit={() => setIsExitPromptOpen(false)}
        onConfirmExit={() => {
          setIsExitPromptOpen(false)
          navigateToInvoicesLandingPage()
        }}
      />
    </FlowModal>
  )
}
