import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Box } from "rebass"

import { Button, ButtonTypeEnum } from "@components/primitive/button/button"
import { Chevron } from "@components/primitive/chevron"
import { LoadingIcon } from "@components/primitive/loading-icon/loading-icon"
import { ITextProps, Text } from "@components/primitive/text/text"
import { FlexColumnLayout, FlexRowLayout } from "@layouts/flex"
import { theme } from "@layouts/theme"

export enum SortDirection {
  ASC = "asc",
  DESC = "desc",
}

interface ITableProps<T extends Record<string, unknown>> {
  columns: TTableColumn<T>[]
  data: T[]
  onClick?: (item: T) => void
  activeRow?: T
  sortByColumn?: string
  sortDirection?: SortDirection
  onSortChange?: ({
    column,
    sortDirection,
  }: {
    column: TTableColumn<T>
    sortDirection: SortDirection
  }) => void
  pagination?: {
    hasMoreItems: boolean
    moreItemsLoading: boolean
    fetchMoreItems: () => void
    fetchMoreItemsButtonText?: string
  }
}

type TTableColumn<T extends Record<string, unknown>> = {
  title: string
  accessor: keyof T
  width?: string | number
  align?: "left" | "right"
  dataRender?: (data: unknown, parent: T) => React.ReactElement | null
  isSortable?: boolean
  isHidden?: boolean
  prepareData?: (value: any, parent: T) => unknown
}

const defaultDataRender = (data: unknown, props?: Pick<ITextProps, "textColor">) => (
  <Text tag="body" {...props}>
    {`${data}`}
  </Text>
)

export const Table = <T extends Record<string, unknown>>({
  columns,
  data,
  onClick,
  onSortChange,
  sortByColumn,
  sortDirection,
  activeRow,
  pagination,
}: ITableProps<T>) => {
  const { t } = useTranslation()

  const { hasMoreItems, moreItemsLoading, fetchMoreItems, fetchMoreItemsButtonText } =
    pagination || {}

  const [hoverRow, setHoverRow] = useState<T | undefined>()

  const handleColumnClick = (column: TTableColumn<T>) => {
    if (!column.isSortable) return
    const isSameColumn = sortByColumn === column.accessor
    const newSortDirection = isSameColumn
      ? sortDirection === SortDirection.ASC
        ? SortDirection.DESC
        : SortDirection.ASC
      : SortDirection.ASC
    if (onSortChange) onSortChange({ column, sortDirection: newSortDirection })
  }

  return (
    <table
      cellSpacing={0}
      cellPadding="16px"
      style={{
        borderColor: theme.colors.ui5,
        borderTopWidth: 2,
        borderTopStyle: "solid",
      }}
    >
      <thead>
        <tr>
          {columns.map((column, index) => {
            const isFirstColumn = index === 0
            const isSorted = column.accessor === sortByColumn
            return (
              <th
                key={index}
                style={{
                  borderColor: theme.colors.ui5,
                  borderTop: 0,
                  borderRight: 0,
                  borderLeftWidth: isFirstColumn ? 0 : 1,
                  borderBottomWidth: 1,
                  borderStyle: "solid",
                  textAlign: column.align || "left",
                  padding: "8px 16px",
                  cursor: column.isSortable ? "pointer" : undefined,
                  display: column.isHidden ? "none" : undefined,
                  width: column.width,
                  outlineColor: theme.focusOutline,
                }}
                tabIndex={column.isSortable ? 0 : undefined}
                onClick={() => handleColumnClick(column)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") handleColumnClick(column)
                }}
              >
                <FlexRowLayout
                  justifyContent={column.align === "right" ? "flex-end" : "space-between"}
                  alignItems="center"
                >
                  <Text
                    tag="body"
                    textColor={theme.colors.ui3}
                    fontSize="11px"
                    letterSpacing={1}
                    fontWeight="500"
                  >
                    {column.title.toUpperCase()}
                  </Text>
                  {column.isSortable ? (
                    <SortIndicators
                      sortDirection={isSorted ? sortDirection : undefined}
                      containerStyle={{ marginLeft: "0.5rem" }}
                    />
                  ) : null}
                </FlexRowLayout>
              </th>
            )
          })}
        </tr>
      </thead>
      <tbody>
        {data.map((item, rowIndex) => (
          <tr
            key={rowIndex}
            tabIndex={onClick ? 0 : undefined}
            onClick={() => onClick && onClick(item)}
            onKeyDown={(e) => {
              if (e.key === "Enter" && onClick) onClick(item)
            }}
            onMouseEnter={() => setHoverRow(item)}
            onMouseLeave={() => setHoverRow(undefined)}
            style={{ cursor: onClick ? "pointer" : undefined, outlineColor: theme.focusOutline }}
          >
            {columns.map((column, columnIndex) => {
              const isFirstColumn = columnIndex === 0
              const backgroundColor =
                item === activeRow
                  ? theme.colors.gold10
                  : item === hoverRow
                  ? theme.colors.ui6
                  : undefined
              const rawValue = item[column.accessor]
              const preparedValue = column.prepareData
                ? column.prepareData(rawValue, item)
                : rawValue
              const content = column.dataRender
                ? column.dataRender(preparedValue, item)
                : defaultDataRender(preparedValue || "", {
                    textColor: isFirstColumn ? theme.colors.ui1 : theme.colors.ui2,
                  })
              return (
                <td
                  key={columnIndex}
                  style={{
                    borderColor: theme.colors.ui5,
                    borderStyle: "solid",
                    borderLeftWidth: isFirstColumn ? 0 : 1,
                    borderBottomWidth: 1,
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    textAlign: column.align || "left",
                    display: column.isHidden ? "none" : undefined,
                    backgroundColor,
                    width: column.width,
                  }}
                >
                  {content}
                </td>
              )
            })}
          </tr>
        ))}
      </tbody>
      {data.length > 0 && hasMoreItems && (
        <tfoot>
          <tr>
            <td
              colSpan={columns.length}
              style={{
                padding: "22px",
              }}
            >
              <Box
                sx={{
                  height: "40px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {moreItemsLoading ? (
                  <LoadingIcon />
                ) : (
                  <Button type={ButtonTypeEnum.TERTIARY_ALT} onClick={fetchMoreItems}>
                    {fetchMoreItemsButtonText || t("buttons.loadMore")}
                  </Button>
                )}
              </Box>
            </td>
          </tr>
        </tfoot>
      )}
    </table>
  )
}

type SortIndicatorsProps = {
  sortDirection?: SortDirection
  containerStyle?: React.CSSProperties
}

const SortIndicators = ({ sortDirection, containerStyle }: SortIndicatorsProps) => {
  const activeColor = theme.colors.ui2
  const inactiveColor = theme.colors.ui4
  return (
    <FlexColumnLayout style={containerStyle}>
      <Chevron direction="up" color={sortDirection === "asc" ? activeColor : inactiveColor} />
      <Chevron direction="down" color={sortDirection === "desc" ? activeColor : inactiveColor} />
    </FlexColumnLayout>
  )
}
