import React, { useState } from "react"
import { WithHighlight } from "@components/primitive/highlight/highlight"
import { theme } from "@layouts/theme"
import { Text } from "@components/primitive/text/text"
import { Link as RouterLink, useRouteMatch } from "react-router-dom"
import { Link, Image, SxStyleProp } from "rebass"
import { Analytics } from "@core/analytics/actions"
import { AdditionalOwnerSettingBadge } from "./settings-badge"
import { FlexRowLayout } from "@northone/ui"
import { Badge } from "@components/primitive/badge/badge"

export interface INavBarLinkProps {
  id?: string
  text: string
  href?: string
  to?: string
  routeMatchIsExact?: boolean
  eventPrefix?: string
  analyticEvent?: string
  notification?: boolean
  iconSrc?: string
  activeIconSrc?: string
  onClick?: () => void
  sx?: SxStyleProp
}

export const NavBarLink = ({
  id,
  text,
  to,
  href,
  routeMatchIsExact = false,
  eventPrefix = "Dashboard",
  analyticEvent,
  notification,
  iconSrc,
  activeIconSrc,
  onClick,
  sx,
}: INavBarLinkProps) => {
  const [isHovering, setIsHovering] = useState<boolean>(false)
  const isActiveRoute = useRouteMatch({
    path: to,
    exact: routeMatchIsExact,
  })

  const isActive = isActiveRoute || isHovering
  const textColor = isActive ? theme.colors.ui1 : theme.colors.ui2

  const clickHandler = () => {
    if (onClick) {
      return onClick()
    }
    Analytics.track(analyticEvent || `WB ${eventPrefix} ${text} Click`)
  }

  const content = (
    <FlexRowLayout
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      sx={{
        paddingX: "18px",
        height: "40px",
        alignItems: "center",
        position: "relative",
        ...sx,
      }}
    >
      {iconSrc ? (
        <Image
          src={isActive ? activeIconSrc : iconSrc}
          sx={{ maxWidth: "24px", marginRight: "7px" }}
        />
      ) : null}
      <WithHighlight hidden={!isActiveRoute} color={theme.colors.blue10}>
        <Text tag="body" textColor={textColor}>
          {text}
        </Text>
        <AdditionalOwnerSettingBadge navBarLinkId={id} />
      </WithHighlight>
      {notification ? <Badge xOffset={35} /> : null}
    </FlexRowLayout>
  )
  return to ? (
    <RouterLink to={to} style={{ textDecoration: "none", padding: 0 }} onClick={clickHandler}>
      {content}
    </RouterLink>
  ) : (
    <Link target="_blank" href={href}>
      {content}
    </Link>
  )
}
