import React from "react"
import { useTranslation } from "react-i18next"

import i18n from "@i18n/i18n"
import { images } from "@assets/images/images"
import { InfoBlockModal } from "@components/composite/info-block-modal/info-block-modal"
import { ButtonTypeEnum } from "@components/primitive/button/button"

interface IChangePinModalProps {
  isOpen: boolean
  closeModal: () => void
}

export const ChangePinModal: React.FC<IChangePinModalProps> = ({ isOpen, closeModal }) => {
  const { t } = useTranslation()
  return (
    <InfoBlockModal
      isOpen={isOpen}
      title={t("cards.changePIN.title")}
      subtitle={t("cards.changePIN.subtitle")}
      subtitleTag={"body-large"}
      imageDimensions={{ height: 133, width: 228 }}
      imageSx={{ marginTop: 0 }}
      imageSrc={images.illustrations.phoneIllustration}
      buttons={[
        {
          children: t("buttons.done"),
          type: ButtonTypeEnum.PRIMARY_BLUE,
          onClick: closeModal,
        },
        {
          children: t("cards.changePIN.bodyTextLink"),
          type: ButtonTypeEnum.SECONDARY,
          onClick: () => window.Intercom("show"),
        },
      ]}
      listItemData={pinInstructions}
      closeModal={closeModal}
    />
  )
}

const pinInstructions = [
  {
    text: i18n.t("cards.changePIN.listCards.debitCard"),
    imgSrc: images.icons.diagonalCards,
  },
  {
    text: i18n.t("cards.changePIN.listCards.currentPin"),
    imgSrc: images.icons.pinShield,
  },
  {
    text: i18n.t("cards.activateCard.listCards.ssn"),
    imgSrc: images.icons.ssnCard,
  },
]
