import React from "react"
import { useMonth } from "@datepicker-react/hooks"
import { Day } from "./datepicker-day"
import { Box, Image } from "rebass"
import { Text } from "../text/text"
import { FlexRowLayout } from "@layouts/flex"
import { images } from "@assets/images/images"

interface IMonthProps {
  year: number
  month: number
  firstDayOfWeek: 0 | 1 | 2 | 3 | 4 | 5 | 6 | undefined
  selectedDate: Date | null
  onBackClick: () => void
  onForwardClick: () => void
  today: Date
  onBlur?: () => void
  minDate?: Date | null
  maxDate?: Date | null
}

export const Month: React.FC<IMonthProps> = ({
  year,
  month,
  firstDayOfWeek,
  selectedDate,
  onBackClick,
  onForwardClick,
  today,
  onBlur,
  minDate,
  maxDate,
}) => {
  const { days, weekdayLabels, monthLabel } = useMonth({
    year,
    month,
    firstDayOfWeek,
  })

  return (
    <Box
      sx={{
        margin: "8px",
      }}
      onBlur={onBlur}
    >
      <FlexRowLayout
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <NavButton onClick={onBackClick} direction="left" />
        <Text tag={"body-bold"}>{monthLabel}</Text>
        <NavButton onClick={onForwardClick} direction="right" />
      </FlexRowLayout>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(7, 1fr)",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {weekdayLabels.map((dayLabel) => (
          <FlexRowLayout
            key={dayLabel}
            sx={{
              width: 40,
              height: 40,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text tag={"body"} sx={{ textAlign: "center" }} key={dayLabel}>
              {dayLabel.slice(0, 1)}
            </Text>
          </FlexRowLayout>
        ))}
      </Box>
      <Box
        sx={{
          display: "inline-grid",
          gridTemplateColumns: "repeat(7, 1fr)",
          justifyContent: "center",
        }}
      >
        {days.map((day, index) => {
          if (typeof day === "object") {
            return (
              <Day
                date={day.date}
                key={day.date.toString()}
                dayLabel={day.dayLabel}
                isActive={Number(day.date) === Number(selectedDate)}
                today={today}
                minDate={minDate}
                maxDate={maxDate}
              />
            )
          }
          return <Box key={index} />
        })}
      </Box>
    </Box>
  )
}

interface INavButtonProps {
  onClick: () => void
  direction: "left" | "right"
}

const NavButton: React.FC<INavButtonProps> = ({ onClick, direction }) => {
  return (
    <Box
      sx={{
        width: 40,
        height: 40,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <Image src={direction === "left" ? images.icons.chevronLeft : images.icons.chevronRight} />
    </Box>
  )
}
