import React from "react"
import { useTranslation } from "react-i18next"

import { images } from "@assets/images/images"
import { InfoBlockModal } from "@components/composite/info-block-modal/info-block-modal"
import { ButtonTypeEnum } from "@components/primitive/button/button"
interface IReportCardLostModalProps {
  isOpen: boolean
  closeModal: () => void
}

export const ReportCardLostModal: React.FC<IReportCardLostModalProps> = ({
  isOpen,
  closeModal,
}) => {
  const { t } = useTranslation()
  return (
    <InfoBlockModal
      isOpen={isOpen}
      title={t("cards.stolenCard.title")}
      titleSx={{ paddingTop: 0, paddingBottom: 10 }}
      subtitle={t("cards.stolenCard.subtitle")}
      subtitleTag={"body-large"}
      imageDimensions={{ height: 188, width: 227 }}
      imageSx={{ marginTop: 0 }}
      imageSrc={images.illustrations.canTelephone}
      buttons={[
        {
          children: t("buttons.contactCustomerCare"),
          type: ButtonTypeEnum.PRIMARY_BLUE,
          onClick: () => window.Intercom("show"),
        },
      ]}
      closeModal={closeModal}
    />
  )
}
