import React from "react"
import { useTranslation } from "react-i18next"

import { ErrorScreen } from "@components/composite/error-screen/error-screen"
import { FlexColumnLayout } from "@layouts/flex"
import { theme } from "@layouts/theme"

export const LendingLandingError: React.FC = () => {
  const { t } = useTranslation()
  const reloadPage = () => window.location.reload()

  return (
    <FlexColumnLayout
      sx={{
        rowGap: "inherit",
        marginRight: "50px",
        margin: "auto",
        alignItems: "center",
        justifyContent: "center",
        borderColor: theme.colors.ui5,
        borderStyle: "solid",
        borderWidth: "1px",
        width: "100%",
        height: 211,
      }}
    >
      <ErrorScreen
        sx={{
          margin: "auto",
          alignItems: "center",
          paddingBottom: 0,
        }}
        size="large"
        titleTag="body-large-bold"
        titleSx={{
          lineHeight: "23px",
          fontStyle: "normal",
          paddingY: 0,
          marginBottom: "8px",
        }}
        retry={() => reloadPage()}
        subtitle={t("lending.landing.errorScreen.subtitle")}
        subtitleSx={{
          fontWeight: 400,
        }}
        retryTitle={t("lending.landing.errorScreen.buttonLabel")}
        buttonSx={{
          marginTop: "24px",
        }}
      />
    </FlexColumnLayout>
  )
}
