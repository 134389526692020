import React, { useState, useEffect } from "react"
import { Box, SxStyleProp } from "rebass"

interface Props {
  children: React.ReactNode
  show?: boolean
}

function getStyle(visible: boolean): SxStyleProp {
  return {
    transition: "opacity 1s ease",
    opacity: visible ? 1 : 0,
  }
}

/**
 * A transition wrapper that fades in the component on the initial render
 */
export function FadeInWrapper({ children, show = true }: Props) {
  const [visible, setLoading] = useState(false)

  useEffect(() => {
    setLoading(show)
  }, [show])

  return <Box sx={getStyle(visible)}>{children}</Box>
}
