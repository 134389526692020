import React from "react"
import { Box } from "rebass"
import { FlexColumnLayout, FlexRowLayout } from "@northone/ui"
import { theme } from "@layouts/theme"
import { TPerk } from "../types"
import { PrismicRichText } from "@prismicio/react"
import { Image } from "rebass"
import { IPerkDetailsProp, PerksPartnerText } from "./perks-modal"
import { perksStyles } from "../style"

interface IPerkProp {
  perk: TPerk
  onCloseClick?: () => void
}

const PerksDetails = ({ perkDetails }: IPerkDetailsProp) => {
  return perkDetails ? (
    <Box sx={{ ...theme.textStyles["body-large"] }}>
      <PrismicRichText field={perkDetails} />
    </Box>
  ) : null
}

const PerksLogo = ({ perkLogoUrl }: IPerkDetailsProp) => {
  return perkLogoUrl ? (
    <Image src={perkLogoUrl} sx={{ marginRight: 12, alignSelf: "flex-start" }} />
  ) : null
}

export const LeftSide = ({ perk }: IPerkProp) => {
  const {
    node: { partner_name, partner_logo, partner_category, perk_details },
  } = perk
  return (
    <>
      <FlexColumnLayout sx={{ marginLeft: 64, marginTop: 57, marginRight: 75 }}>
        <FlexRowLayout sx={{ ...perksStyles.header, border: 0 }}>
          <PerksLogo perkLogoUrl={partner_logo.url} />
          <FlexColumnLayout>
            <PerksPartnerText text={partner_name} tag="h2" />
            <PerksPartnerText
              text={partner_category}
              sx={{ ...perksStyles.category, background: theme.colors.blue10, margin: 0 }}
              tag={"h5"}
            />
          </FlexColumnLayout>
        </FlexRowLayout>
        <PerksDetails perkDetails={perk_details} />
      </FlexColumnLayout>
    </>
  )
}
