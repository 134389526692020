import { formatMoney } from "accounting"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import { useAppSelector } from "@core/redux/utils"
import { RoutesEnum } from "@routers/types"
import { InfoBlock } from "@components/composite/info-block/info-block"
import { getPaymentSuccessSubtitle } from "@features/move-money/utils/helpers"
import { accountsActions } from "@features/accounts/redux/actions"
import { selectAccountsSubAccountTransfer } from "@features/accounts/redux/selectors"
import { images } from "@assets/images/images"
import { ScheduleFrequencyEnum, TransactionTypeEnum } from "@generated/graphql"

export const SubAccountTransferSent: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const subAccountTransferState = useAppSelector(selectAccountsSubAccountTransfer)
  const [{ amount, destinationAccount, sourceAccount }] = useState(subAccountTransferState)

  return (
    <InfoBlock
      imageSrc={images.illustrations.rocketship}
      imageDimensions={{ height: 180, width: 250 }}
      heading={t("accounts.transfer.sent.label")}
      title={t("accounts.transfer.sent.title", {
        amount: formatMoney(amount),
        destinationAccountName: destinationAccount.name,
        sourceAccountName: sourceAccount.name,
      })}
      subtitle={getPaymentSuccessSubtitle({
        paymentType: TransactionTypeEnum.SUB_ACCOUNT_TRANSFER,
        frequency: ScheduleFrequencyEnum.ONCE,
        startDate: new Date(),
        endDate: null,
      })}
      buttons={[
        {
          onClick: () => {
            dispatch(accountsActions.subAccountTransferDiscard())
            history.push(RoutesEnum.ACCOUNTS_OVERVIEW)
          },
          children: t("buttons.done"),
        },
      ]}
    />
  )
}
