import React from "react"
import { Field } from "@components/primitive/field/field"
import MaskedInput from "react-text-mask"
import createNumberMask from "text-mask-addons/dist/createNumberMask"
import { theme } from "@layouts/theme"
import { images } from "@assets/images/images"
interface IAmountInputProps {
  value: string | undefined
  onChange: (value: string) => void
  errorMessage?: string
}
export const AmountInput: React.FC<IAmountInputProps> = ({ value, onChange, errorMessage }) => {
  return (
    <Field
      errorMessage={errorMessage}
      backgroundColor={theme.colors.ui7}
      sx={{ height: 63, width: 445 }}
    >
      <MaskedInput
        onChange={(e) => onChange(e.target.value.replace(/,/g, "").slice(0, 10))}
        mask={createNumberMask({ prefix: "", allowDecimal: true })}
        value={value}
        placeholder={"0.00"}
        autoFocus
        style={{
          backgroundColor: "transparent",
          outline: "none",
          width: "100%",
          border: "none",
          ...theme.textStyles["amount-input"],
          paddingBottom: 0,
          paddingLeft: 35,
          backgroundImage: `url(${images.icons.dollar})`,
          backgroundPosition: "11px 16px",
          backgroundRepeat: "no-repeat",
          height: "90%",
          alignItems: "center",
          overflow: "hidden",
        }}
      />
    </Field>
  )
}
