import React, { PropsWithChildren } from "react"
import { useTranslation } from "react-i18next"
import { Image, Box } from "rebass"
import { useAsync } from "react-use"
import { NetworkStatus } from "@apollo/client"

import { selectActiveBusinessID } from "@core/active-business/redux/selectors"
import { events } from "@core/analytics/events"
import { Analytics } from "@core/analytics/actions"
import { useAppSelector } from "@core/redux/utils"
import { FlexColumnLayout, FlexRowLayout } from "@layouts/flex"
import { images } from "@assets/images/images"
import { Text } from "@components/primitive/text/text"
import { theme } from "@layouts/theme"
import { Logo } from "@layouts/logo"
import { BaseLayout } from "@layouts/base-layout"
import { LaunchWidgetButton } from "./shared/launch-widget-button"
import "./lending-landing.css"
import { LandingTermLoanSection } from "./term-loan/landing-term-loan-section"
import { LandingLocSection } from "./line-of-credit/landing-loc-section"
import { KanmonLoanTypeEnum, useKanmonLandingPageQuery } from "@generated/graphql"
import {
  KanmonWidgetUserState,
  useKanmonConnectWidget,
} from "./shared/hooks/use-kanmon-connect-widget"
import { mapWidgetUserStateToTopBannerCtaText } from "./utils"
import { LendingLandingLoading } from "./shared/lending-landing-loading"
import { LendingLandingError } from "./shared/lending-landing-error"

export const LendingLanding = () => {
  const businessId = useAppSelector(selectActiveBusinessID)
  const userId = useAppSelector((state) => state.user.userId)

  const {
    data,
    loading: kanmonLandingDataLoading,
    error: kanmonLandingDataError,
    refetch: kanmonLandingRefetch,
    networkStatus: kanmonLandingNetworkStatus,
  } = useKanmonLandingPageQuery({
    variables: { businessId },
    notifyOnNetworkStatusChange: true,
  })

  const primaryOwner = data?.kanmonLending.business?.users?.find((user) => user.isPrimaryOwner)

  // Currently, loan manager can only be the primary owner, but in the future
  // it may be a controller that is not the primary owner, etc.
  const loanManager = primaryOwner
  const currentUserIsLoanManager = Boolean(loanManager?.id === userId)

  const { launchKanmonConnectWidget, widgetLoading, widgetError, widgetUserState } =
    useKanmonConnectWidget({
      businessId,
      currentUserIsLoanManager,
    })

  useAsync(async () => {
    if (widgetUserState === "SERVICING") {
      await kanmonLandingRefetch()
    }
  }, [widgetUserState])

  const handleCtaButtonClick = () => {
    launchKanmonConnectWidget()
    Analytics.track(events.lending.ctaButtonClick)
  }

  const kanmonAppAlreadyStarted = widgetUserState !== "START_FLOW"

  const enableHeaderCtaButton = currentUserIsLoanManager
  const enableLoanProductSectionCtaButton = currentUserIsLoanManager && !kanmonAppAlreadyStarted

  const headerCtaButtonOnClick = enableHeaderCtaButton ? handleCtaButtonClick : undefined
  const loanProductSectionCtaButtonOnClick = enableLoanProductSectionCtaButton
    ? handleCtaButtonClick
    : undefined

  const isLocActive = Boolean(
    data?.kanmonLending.products.some(
      (product) => product.loanType === KanmonLoanTypeEnum.LINE_OF_CREDIT,
    ),
  )
  const isTermLoanActive = Boolean(
    data?.kanmonLending.products.some(
      (product) => product.loanType === KanmonLoanTypeEnum.TERM_LOAN,
    ),
  )

  const loading =
    (kanmonLandingDataLoading && kanmonLandingNetworkStatus !== NetworkStatus.refetch) ||
    widgetLoading
  if (loading) {
    return (
      <LandingLayout>
        <LendingLandingLoading />
      </LandingLayout>
    )
  }

  const loanManagerName =
    loanManager?.firstName && loanManager.lastName
      ? `${loanManager.firstName} ${loanManager.lastName}`
      : undefined

  const error = kanmonLandingDataError || widgetError || !loanManagerName
  if (error) {
    return (
      <LandingLayout>
        <LendingLandingError />
      </LandingLayout>
    )
  }

  return (
    <LandingLayout ctaButtonOnClick={headerCtaButtonOnClick} widgetUserState={widgetUserState}>
      <LandingLocSection
        currentUserIsLoanManager={currentUserIsLoanManager}
        loanManagerName={loanManagerName}
        ctaButtonOnClick={loanProductSectionCtaButtonOnClick}
        loanProductIsActive={isLocActive}
      />
      <LandingTermLoanSection
        currentUserIsLoanManager={currentUserIsLoanManager}
        loanManagerName={loanManagerName}
        ctaButtonOnClick={loanProductSectionCtaButtonOnClick}
        loanProductIsActive={isTermLoanActive}
      />
    </LandingLayout>
  )
}

export const LandingLayout: React.FC<
  PropsWithChildren<{
    ctaButtonOnClick?: () => void
    widgetUserState?: KanmonWidgetUserState
  }>
> = ({ children, ctaButtonOnClick, widgetUserState }) => {
  const { t } = useTranslation()
  return (
    <>
      <LandingHeadingBackground />
      <BaseLayout pageTitle={t("pageTitles.lending")} childrenGridColumn={"2/12"}>
        <FlexColumnLayout>
          <LandingHeading ctaButtonOnClick={ctaButtonOnClick} widgetUserState={widgetUserState} />
          <FlexColumnLayout sx={{ rowGap: 24, marginTop: 36 }}>{children}</FlexColumnLayout>
          <LandingFooter />
        </FlexColumnLayout>
      </BaseLayout>
    </>
  )
}

const LandingHeading: React.FC<{
  ctaButtonOnClick?: () => void
  widgetUserState: KanmonWidgetUserState | undefined
}> = ({ ctaButtonOnClick, widgetUserState }) => {
  const { t } = useTranslation()

  const titleText = t("lending.landing.topBanner.title")
  const descriptionText = t("lending.landing.topBanner.description")
  const ctaButtonText = mapWidgetUserStateToTopBannerCtaText(widgetUserState)
  const applyCTASubtextText = t("lending.landing.topBanner.applyCTASubtext")

  const ctaButtonComponent = ctaButtonOnClick ? (
    <LaunchWidgetButton label={ctaButtonText} onClick={ctaButtonOnClick} />
  ) : undefined

  const ctaSubtextTextComponent = ctaButtonOnClick ? (
    <Text
      tag="body-x-small"
      sx={{
        fontWeight: 400,
        letterSpacing: "0.25px",
        fontStyle: "normal",
        color: theme.colors.blackTint1,
      }}
    >
      {applyCTASubtextText}
    </Text>
  ) : undefined

  return (
    <FlexRowLayout
      className="lending-landing-heading"
      sx={{
        alignItems: "center",
        width: "100%",
        justifyContent: "space-between",
      }}
    >
      <FlexColumnLayout
        sx={{
          rowGap: 24,
          height: 354,
          justifyContent: "center",
          marginTop: "-20px",
        }}
      >
        <Text
          tag={"h1"}
          sx={{
            fontWeight: 600,
            lineHeight: "54px",
            fontStyle: "normal",
            color: theme.colors.black1,
          }}
        >
          {titleText}
        </Text>
        <Text
          tag="body-large"
          sx={{
            fontWeight: 400,
            lineHeight: "23px",
            fontStyle: "normal",
            maxWidth: 384,
            color: theme.colors.blackTint1,
          }}
        >
          {descriptionText}
        </Text>
        {ctaButtonComponent}
        {ctaSubtextTextComponent}
      </FlexColumnLayout>
      <Image
        src={images.lending.hero}
        sx={{
          marginTop: "-20px",
          objectFit: "contain",
          height: 290,
          marginRight: "50px",
          flexShrink: 0,
        }}
      />
    </FlexRowLayout>
  )
}

const LandingHeadingBackground: React.FC = () => (
  <Box
    sx={{
      background: "linear-gradient(248deg, rgba(248, 240, 230, 0.00) 1.13%, #F8F0E6 97.23%)",
      justifyContent: "flex-end",
      width: "100vw",
      height: 354,
      left: 0,
      position: "absolute",
      zIndex: -1,
    }}
  />
)

const LandingFooter: React.FC = () => {
  const { t } = useTranslation()
  return (
    <FlexColumnLayout>
      <Logo legalDisclaimerText={t("lending.legal.disclaimer")} sx={{ marginTop: 40, width: 590 }}>
        <Text
          tag={"body-x-small"}
          textColor={theme.colors.ui2}
          sx={{ marginBottom: 15, marginTop: 2 }}
        >
          {t("lending.legal.citation1")}
        </Text>
      </Logo>
    </FlexColumnLayout>
  )
}
