import { useEffect, useState } from "react"
import { useAppSelector } from "@core/redux/utils"
import { selectActiveBusinessID } from "@core/active-business/redux/selectors"
import { usePlaidLink } from "react-plaid-link"
import { useCreateLinkTokenMutation } from "@generated/graphql"
import { handlePlaidExitAnalytics, handlePlaidLinkAnalytics } from "../analytics"

export function useLinkNewPlaidItem({ handleCancel }: { handleCancel: () => void }) {
  const [hasLinkTokenError, setHasLinkTokenError] = useState(false)
  const [token, setToken] = useState<string>()
  const [publicToken, setPublicToken] = useState<string>()
  const [plaidAccountId, setPlaidAccountId] = useState<string>()
  const [institutionName, setInstitutionName] = useState<string>()
  const [loading, setLoading] = useState<boolean>(false)

  const businessId = useAppSelector(selectActiveBusinessID)

  const [createLinkTokenMutation] = useCreateLinkTokenMutation({
    variables: {
      businessId,
    },
    onCompleted: (data) => {
      const linkToken = data?.createLinkToken?.data.linkToken
      if (!linkToken) return
      setToken(linkToken)
    },
    onError: () => {
      setLoading(false)
      setHasLinkTokenError(true)
    },
  })

  const {
    open,
    ready,
    error: plaidLinkError,
  } = usePlaidLink({
    token: token || null,
    onSuccess: (public_token, metadata) => {
      setPublicToken(public_token)
      setPlaidAccountId(metadata?.accounts?.[0].id)
      setLoading(false)
    },
    onEvent: (eventName, metadata) => {
      const institutionName = metadata?.institution_name
      if (!institutionName) return
      setInstitutionName(institutionName)
      handlePlaidLinkAnalytics({ eventName, metadata })
    },
    onExit: (error, metadata) => {
      handleCancel()
      handleReset()
      handlePlaidExitAnalytics({ error, metadata })
    },
  })

  function handleOpen() {
    if (loading) return
    setLoading(true)
    createLinkTokenMutation()
  }

  function handleReset() {
    setHasLinkTokenError(false)
    setLoading(false)
    setToken(undefined)
    setPublicToken(undefined)
    setPlaidAccountId(undefined)
    setInstitutionName(undefined)
  }

  useEffect(() => {
    if (!ready) return
    open()
  }, [open, ready])

  function getErrorMessage() {
    if (!hasLinkTokenError && !plaidLinkError) return
    return "settings.pages.linkedAccount.errors.plaidCreate"
  }

  return {
    errorMessageKey: getErrorMessage(),
    loading,
    businessId,
    institutionName,
    open: handleOpen,
    reset: handleReset,
    plaidAccountId,
    publicToken,
  }
}
