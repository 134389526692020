import { selectActiveBusinessID } from "@core/active-business/redux/selectors"
import { useAppSelector } from "@core/redux/utils"
import {
  AnnouncementActionEventEnum,
  AnnouncementTypeEnum,
  useAnnouncementInteractMutation,
  useAnnouncementQuery,
  useListOwnersQuery,
} from "@generated/graphql"

export const useUpdateDismissTeamBankingBanner = () => {
  const [updateDismissTeamBankingBannerFlag] = useAnnouncementInteractMutation({
    variables: {
      type: AnnouncementTypeEnum.ADDITIONAL_OWNER_BANNER,
      actionEvent: AnnouncementActionEventEnum.DISMISS,
    },
  })
  return updateDismissTeamBankingBannerFlag
}

export const useShowTeamBankingBanner = () => {
  const businessId = useAppSelector(selectActiveBusinessID)

  const { data: ownerListData, loading: isOwnerListDataLoading } = useListOwnersQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
    variables: { businessId },
    onError: () => {
      return null
    },
  })

  const { data: bannerAnnouncementQueryResult, loading: isAnnouncementLoading } =
    useAnnouncementQuery({
      fetchPolicy: "network-only",
      variables: { type: AnnouncementTypeEnum.ADDITIONAL_OWNER_BANNER },
    })

  const wasBannerDismissed = Boolean(bannerAnnouncementQueryResult?.announcement)
  const hasInvitedOwners = ownerListData?.listOwners?.owners.some(
    (owner) => owner.userId && !owner.isPrimaryOwner,
  )

  return {
    loading: isAnnouncementLoading || isOwnerListDataLoading,
    shouldShowBanner: !hasInvitedOwners && !wasBannerDismissed,
  }
}
