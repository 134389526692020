import React from "react"
import { useTranslation } from "react-i18next"
import { images } from "@assets/images/images"
import { theme } from "@layouts/theme"
import { Button, ButtonTypeEnum } from "@components/primitive/button/button"

interface RefreshButtonProps {
  onClick: () => void
}

export const RefreshButton = ({ onClick }: RefreshButtonProps) => {
  const { t } = useTranslation()

  return (
    <Button
      onClick={() => onClick()}
      type={ButtonTypeEnum.TERTIARY}
      iconSrc={images.icons.refresh}
      textSx={{
        textTransform: "uppercase",
        letterSpacing: "1px",
        color: theme.colors.ui2,
        fontSize: "10px",
      }}
      sx={{ paddingRight: 13 }}
    >
      {t("revenueHub.landing.refresh")}
    </Button>
  )
}
