import { createSelector } from "reselect"
import { unformat } from "accounting"
import { IRootState } from "@core/redux/root-state"
import { SubAccountTransferCreateMutationVariables } from "@generated/graphql"
import { IAccountsState, ICreateSubAccountState } from "./reducer"

export const getAccounts = (state: IRootState) => state.accounts

export const selectAccountsSubAccountTransfer = createSelector(
  getAccounts,
  (accounts: IAccountsState) => accounts.subAccountTransfer,
)

export const selectSubAccocuntTransferVariables = createSelector(
  [selectAccountsSubAccountTransfer, (state: IRootState) => state.activeBusiness],
  (transfer, activeBusiness): SubAccountTransferCreateMutationVariables => {
    return {
      businessId: activeBusiness.businessId,
      data: {
        destinationSubAccountId: transfer.destinationAccount.id,
        sourceSubAccountId: transfer.sourceAccount.id,
        amount: unformat(transfer.amount),
      },
    }
  },
)

export const selectCreateSubAccountState = createSelector(
  getAccounts,
  (accounts: IAccountsState) => accounts.createSubAccount,
)

export const selectSubAccountInitialBalance = createSelector(
  selectCreateSubAccountState,
  (subAccount: ICreateSubAccountState) => subAccount.initialBalance,
)

export const selectSubAccountID = createSelector(
  selectCreateSubAccountState,
  (subAccount: ICreateSubAccountState) => subAccount.id,
)

export const selectSubAccounName = createSelector(
  selectCreateSubAccountState,
  (subAccount: ICreateSubAccountState) => subAccount.name,
)

export const selectAccountsTransactionID = createSelector(
  getAccounts,
  (accounts: IAccountsState) => accounts.transactionId,
)

export const selectSubAccountTransfer = createSelector(
  getAccounts,
  (accounts: IAccountsState) => accounts.transactionId,
)
