import React from "react"
import { Text } from "@components/primitive/text/text"
import { ListItem, ListItemProps } from "@components/primitive/list-item/list-item"
import { theme } from "@layouts/theme"
import { FlexColumnLayout } from "@layouts/flex"
import { Image } from "rebass"

export interface IntegrationListItemProps extends ListItemProps {
  name: string
  subtitle: string
  iconSrc: string
}

/**
 * Defines a integration list item
 */
export const IntegrationListItem = ({
  name,
  subtitle,
  iconSrc,
  ...listItemProps
}: IntegrationListItemProps) => {
  return (
    <ListItem
      listItemHeight="medium"
      sx={{
        paddingX: theme.spacing.listItemPadding,
        justifyContent: "flex-start",
        opacity: listItemProps.disabled ? 0.5 : 1,
        ...listItemProps.sx,
      }}
      {...listItemProps}
    >
      <Image src={iconSrc} sx={{ height: 28, width: 28 }} />
      <FlexColumnLayout sx={{ marginX: theme.spacing.listItemPadding, justifyContent: "center" }}>
        <Text tag="body-small">{name}</Text>
        <Text tag="body-x-small" textColor={theme.colors.ui2}>
          {subtitle}
        </Text>
      </FlexColumnLayout>
    </ListItem>
  )
}
