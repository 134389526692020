import React, { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import i18n from "@i18n/i18n"

import { images } from "@assets/images/images"
import { theme } from "@layouts/theme"
import { FlexRowLayout } from "@layouts/flex"
import { Maybe, TransactionStatusEnum, TransactionTypeEnum } from "@generated/graphql"
import { events } from "@core/analytics/events"
import { Analytics } from "@core/analytics/actions"
import { accountsActions } from "@features/accounts/redux/actions"
import { dashboardActions } from "@features/dashboard/redux/actions"
import { Button, ButtonTypeEnum } from "@components/primitive/button/button"
import { DeleteButton } from "@components/extended/buttons/delete-button"
import { getTransactionTypeDetails } from "@utils/mapping/transactions.mapping"
import { isCardTransaction, isOutgoing, isTransactionPosted } from "./utils"
import { BaseTransactionPanelProps, BaseTransactionPanel } from "./base-transaction-panel"
import { TransactionPanelCancelPayment } from "./transaction-panel-cancel-payment"
import { TransactionShareButton } from "./transaction-share-button"
import { TransactionPanelDate } from "./transaction-panel-date"
import { TransactionPanelAmount } from "./transaction-panel-amount"
import { TransactionPanelPaymentMethod } from "./transaction-panel-payment-method"
import { TransactionPanelNotes } from "./transaction-panel-notes"
import { TransactionPanelUser } from "./transaction-panel-user"
import { CancelACHModal } from "./cancel-ach-modal"

interface IHomeTransactionPanelProps extends BaseTransactionPanelProps {
  id: string
  amount: number
  transactionType?: TransactionTypeEnum
  createdAt: string
  postedAt?: string
  note?: string
  status?: TransactionStatusEnum | null
  returnedBy?: string
  cancelable: boolean
  cardLast4?: string
  user?: Maybe<{
    id: string
    email?: Maybe<string>
    firstName?: Maybe<string>
    lastName?: Maybe<string>
    isPrimaryOwner: boolean
  }>
}

export const getCounterpartyLabel = (amount: number, transactionType?: TransactionTypeEnum) => {
  if (!transactionType) {
    return ""
  }
  const transactionTypeDetails = getTransactionTypeDetails(transactionType)
  return amount > 0 && transactionType !== TransactionTypeEnum.SUB_ACCOUNT_TRANSFER
    ? i18n.t("transactions.previewPanel.description")
    : transactionTypeDetails.counterpartyLabel
}

export const TransactionPanel: React.FC<IHomeTransactionPanelProps> = ({
  counterpartyName,
  counterpartyLogoSrc,
  id,
  amount,
  createdAt,
  postedAt,
  note,
  transactionType,
  cancelable,
  status,
  returnedBy,
  cardLast4,
  user,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const counterpartyLabel = getCounterpartyLabel(amount, transactionType)
  const [cancelModalOpen, setCancelModalOpen] = useState(false)

  const handleCardAuthAnalytics = (postedEvent: string, authEvent: string) => {
    if (isCardTransaction(transactionType) && isOutgoing(amount)) {
      Analytics.track(isTransactionPosted(status) ? postedEvent : authEvent)
    }
  }

  const supportClick = () => {
    handleCardAuthAnalytics(
      events.transactions.cardAuthSettled.supportClick,
      events.transactions.cardAuthPending.supportClick,
    )

    window.Intercom("show")
  }

  useEffect(() => {
    if (isCardTransaction(transactionType) && isOutgoing(amount)) {
      Analytics.track(
        isTransactionPosted(status)
          ? events.transactions.cardAuthSettled.transactionClick
          : events.transactions.cardAuthPending.transactionClick,
      )
    }
  }, [])

  return (
    <>
      <CancelACHModal
        isOpen={cancelModalOpen}
        closeModal={() => setCancelModalOpen(false)}
        transactionId={id}
      />
      <BaseTransactionPanel
        counterpartyLabel={counterpartyLabel}
        counterpartyName={counterpartyName}
        counterpartyLogoSrc={
          counterpartyLogoSrc || getTransactionTypeDetails(transactionType).transactionFeedIcon
        }
        onCloseClick={() => {
          dispatch(dashboardActions.setActiveTransactionId(""))
          dispatch(accountsActions.setActiveTransactionId(""))
        }}
      >
        <TransactionPanelAmount amount={amount} status={status} />
        <TransactionPanelPaymentMethod
          type={transactionType}
          status={status}
          returnedBy={returnedBy}
          amount={amount}
          cardLast4={cardLast4}
        />
        {cancelable && <TransactionPanelCancelPayment />}
        <TransactionPanelDate
          type={transactionType}
          amount={amount}
          status={status}
          createdAt={createdAt}
          postedAt={postedAt}
        />
        <TransactionPanelUser user={user} />
        <TransactionPanelNotes
          transactionId={id}
          note={note}
          amount={amount}
          type={transactionType}
          status={status}
        />
        <FlexRowLayout
          sx={{
            borderTop: `1px solid ${theme.colors.ui4}`,
            marginX: -theme.spacing.transactionPanel.x,
            marginBottom: -theme.spacing.transactionPanel.y,
            paddingX: theme.spacing.transactionPanel.x,
            paddingY: 16,
            boxShadow: "0px -4px 14px rgba(0, 0, 0, 0.05)",
          }}
        >
          <TransactionShareButton
            counterpartyName={counterpartyName}
            amount={amount}
            transactionType={transactionType}
            date={createdAt}
            note={note}
            id={id}
            status={status}
          />
          <Button
            iconSrc={images.icons.support}
            type={ButtonTypeEnum.TERTIARY_ALT}
            onClick={() => supportClick()}
          >
            {t("dashboard.transactionPanel.support")}
          </Button>
          {cancelable && (
            <DeleteButton
              key="delete"
              onClick={() => setCancelModalOpen(true)}
              sx={{
                marginLeft: 10,
              }}
            />
          )}
        </FlexRowLayout>
      </BaseTransactionPanel>
    </>
  )
}
