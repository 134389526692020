import { Pill, theme, Text } from "@northone/ui"
import React from "react"
import { Image } from "rebass"
import { images } from "@assets/images/images"
import { useTranslation } from "react-i18next"
import { theme as webBankingTheme } from "@layouts/theme"

interface ICardStatusPillUiElements {
  text: string
  backgroundColor: string
  textColor: string
  icon?: string
}

const useCardStatusPill = ({
  isFullyActivated,
  isFrozen,
  isDisabled,
}: ICardStatusPillProps): ICardStatusPillUiElements => {
  const { t } = useTranslation()
  if (isDisabled) {
    return {
      text: t("cards.disabledCard.locked"),
      backgroundColor: theme.colors.ui1,
      textColor: theme.colors.ui7,
      icon: images.icons.frozen,
    }
  }
  if (isFrozen) {
    return {
      text: t("cards.activeCard.frozen"),
      backgroundColor: theme.colors.ui1,
      textColor: theme.colors.ui7,
      icon: images.icons.frozen,
    }
  }

  if (isFullyActivated) {
    return {
      text: t("cards.activeCard.active"),
      backgroundColor: webBankingTheme.colors.gold20,
      textColor: theme.colors.ui1,
    }
  }

  return {
    text: t("cards.inactiveCard.onItsWay"),
    backgroundColor: theme.colors.ui4,
    textColor: theme.colors.ui1,
    icon: images.icons.onItsWay,
  }
}

interface ICardStatusPillProps {
  isFullyActivated: boolean
  isFrozen: boolean
  isDisabled: boolean
}

export const CardStatusPill = ({
  isFullyActivated,
  isFrozen,
  isDisabled,
}: ICardStatusPillProps) => {
  const { text, backgroundColor, textColor, icon } = useCardStatusPill({
    isFullyActivated,
    isFrozen,
    isDisabled,
  })
  return (
    <Pill sx={{ bg: backgroundColor, height: 25, marginRight: "16px", paddingX: "8px" }}>
      {icon && <Image src={icon} sx={{ marginRight: "4px", height: 15, width: "auto" }} />}
      <Text sx={{ color: textColor }} tag="h5">
        {text}
      </Text>
    </Pill>
  )
}
