import React from "react"
import { FlexRowLayout } from "@layouts/flex"
import { CounterpartyAvatar } from "../../avatar/counterparty-avatar"
import { Text } from "@components/primitive/text/text"
import { theme } from "@layouts/theme"
import { OverflowListItem } from "../overflow/overflow-list-item"
import { Image } from "rebass"
import { images } from "@assets/images/images"
import { ListItemProps } from "@components/primitive/list-item/list-item"

interface IGenericRecipientListItemProps extends ListItemProps {
  name: string
}
export const GenericRecipientListItem: React.FC<IGenericRecipientListItemProps> = ({
  name,
  ...listItemProps
}) => (
  <OverflowListItem {...listItemProps}>
    <FlexRowLayout sx={{ justifyContent: "space-between", width: "100%", alignItems: "center" }}>
      <CounterpartyAvatar
        counterpartyName={name}
        colorThemes={[{ textColor: theme.colors.blue100, backgroundColor: theme.colors.blue10 }]}
      />
      <Text sx={{ width: "100%", marginLeft: 16 }} tag="body-small">
        {name}
      </Text>
      <Image sx={{ marginLeft: 16 }} src={images.icons.chevronRight} />
    </FlexRowLayout>
  </OverflowListItem>
)
