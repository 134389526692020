import React, { useState, useRef } from "react"
import { useTranslation } from "react-i18next"
import { Box } from "rebass"
import { Analytics } from "@core/analytics/actions"
import { events } from "@core/analytics/events"
import { Text } from "@components/primitive/text/text"
import { IntegrationListItem } from "@components/extended/list-items/integration/integration-list-item"
import { useClickOutside } from "@utils/hooks"
import { theme } from "@layouts/theme"
import { FlexColumnLayout } from "@layouts/flex"
import { BaseLayout } from "@layouts/base-layout"
import { integrationsData, IIntegration, IntegrationTypeEnum } from "./integrations-data"
import { IntegrationPanel } from "./integrations-panel"

export const Integrations: React.FC = () => {
  const { t } = useTranslation()
  const [activeIntegration, setActiveIntegration] = useState<IIntegration | null>(null)
  const listRef = useRef<HTMLDivElement>(null)
  const panelRef = useRef<HTMLDivElement>(null)

  useClickOutside([listRef, panelRef], () => setActiveIntegration(null))

  return (
    <BaseLayout
      pageTitle={t("pageTitles.integrations")}
      childrenGridColumn="4/9"
      rightAsideGridColumn="9/13"
      paddingBottom={theme.spacing.baseLayoutPaddingBottom}
      withLogoFooter
      rightAside={
        activeIntegration && (
          <Box ref={panelRef}>
            <IntegrationPanel {...activeIntegration} />
          </Box>
        )
      }
    >
      <FlexColumnLayout boxRef={listRef}>
        <Text tag="h2">{t("integrations.title")}</Text>
        <Text textColor={theme.colors.ui2} sx={{ marginTop: 15, marginBottom: 15 }} tag="body">
          {t("integrations.subtitle")}
        </Text>
        <Text textColor={theme.colors.ui3} sx={{ marginBottom: 24 }} tag="body-x-small">
          {t("integrations.legal")}
        </Text>
        {integrationsData
          .sort((a, b) => (a.name > b.name ? 1 : -1))
          .map((integration) => {
            const { name, subtitle, logo, id } = integration
            return (
              <IntegrationListItem
                disabled={integration.subtitle === IntegrationTypeEnum.COMING_SOON}
                isActive={activeIntegration?.id === id}
                onClick={() => {
                  Analytics.track(events.integrations.details, { companyName: integration.name })
                  setActiveIntegration(activeIntegration?.id === id ? null : integration)
                }}
                key={name}
                name={name}
                subtitle={
                  subtitle.charAt(0).toUpperCase() +
                  subtitle.slice(1).toLowerCase().replace("_", " ")
                }
                iconSrc={logo}
              />
            )
          })}
      </FlexColumnLayout>
    </BaseLayout>
  )
}
