import { createAction, TActionsUnion } from "@core/redux/utils"
import { IShareAndEarnState } from "./reducer"

export enum ShareAndEarnActionEnum {
  SET_SHARE_AND_EARN_DATA = "SHARE_AND_EARN_DATA",
}

export const shareAndEarnActions = {
  setShareAndEarnData: (data: Partial<IShareAndEarnState>) =>
    createAction(ShareAndEarnActionEnum.SET_SHARE_AND_EARN_DATA, data),
}

export type TShareAndEarnActions = TActionsUnion<typeof shareAndEarnActions>
