import { selectionControlIcons } from "./selection-control"
import { transactionIcons } from "./transaction-icons"
import { integrationIcons } from "./integration-icons"
import addContact from "./add-contact.svg"
import addContactUi3 from "./add-contact-ui3.svg"
import addVirtualCard from "./add-virtual-card.svg"
import appStore from "./app-store.png"
import appStoreSmall from "./app-store-small.png"
import card from "./card.png"
import cardBack from "./card-back.png"
import checkDepositAddFunds from "./check-deposit-add-funds.png"
import dashboardEmptyState from "./dashboard-empty-state-image.png"
import defaultAccount from "./default-account.png"
import downloadApp from "./download-app.png"
import envelope from "./envelope.svg"
import freezeCard from "./freeze-card.png"
import googlePlay from "./google-play.png"
import googlePlaySmallWhite from "./google-play-small-white.png"
import greenDot from "./green-dot.svg"
import watch from "./watch_NB_gold.png"
import externalLink from "./external-link.svg"
import archive from "./archive.svg"
import arrow from "./arrow.svg"
import caution from "./caution.svg"
import calendar from "./calendar.svg"
import chevronDown from "./chevron-down.svg"
import chevronUp from "./chevron-up.svg"
import chevronLeft from "./chevron-left.svg"
import chevronRight from "./chevron-right.svg"
import close from "./close.svg"
import closeBlack from "./close-black.svg"
import closeLight from "./close-light.svg"
import closeWhite from "./close-white.svg"
import copy from "./copy.svg"
import copyTextIcon from "./copy-text-icon.png"
import copyTextHoverIcon from "./copy-text-hover-icon.png"
import info from "./info.svg"
import infoBold from "./info-bold.svg"
import infoGrey from "./info-icon.svg"
import search from "./search.svg"
import edit from "./edit.svg"
import editBlack from "./edit-black.svg"
import exportIcon from "./export.svg"
import logout from "./logout.svg"
import logoutWhite from "./logout-white.svg"
import pay from "./pay.svg"
import schedule from "./schedule.svg"
import accounts from "./accounts.svg"
import addFunds from "./add-funds.svg"
import filter from "./filter.svg"
import share from "./share.svg"
import download from "./download.svg"
import support from "./support.svg"
import dollar from "./dollar.svg"
import help from "./help.svg"
import payments from "./payments.svg"
import error from "./error.svg"
import newIcon from "./new.svg"
import sendInvoice from "./send-invoice.svg"
import resume from "./resume.svg"
import pause from "./pause.svg"
import pauseSmall from "./pause-small.svg"
import percent from "./percent.svg"
import arrowLeft from "./arrow-left.svg"
import arrowRight from "./arrow-right.svg"
import arrowRightBlack from "./arrow-right-black.svg"
import arrowDiagonalUp from "./arrow-diagonal-up.svg"
import add from "./add.svg"
import addDark from "./add-dark.svg"
import editUi2 from "./edit-ui2.svg"
import trash from "./trash.svg"
import statement from "./statement.svg"
import schedulePay from "./schedule-pay.svg"
import invite from "./invite.svg"
import transferAvatar from "./transfer-avatar.svg"
import arrows from "./arrows.svg"
import done from "./done.svg"
import frameBlue from "./frame-blue.svg"
import accountStatement from "./icon-account-statement.svg"
import billingStatement from "./icon-billing-statement.svg"
import downloadStatement from "./icon-download-statement.svg"
import redNotification from "./red-notification.svg"
import ach from "./fund-ach.svg"
import atm from "./fund-atm.svg"
import check from "./fund-check.svg"
import checkMark from "./check-mark.svg"
import checkMarkDark from "./checkmark-dark.svg"
import linked from "./fund-linked.svg"
import thirdParty from "./fund-third-party.svg"
import wire from "./fund-wire.svg"
import cardSecurityCode from "./security-code.png"
import diagonalCards from "./diagonal-cards.png"
import ssnCard from "./ssn-card.png"
import pinShield from "./pin-shield.png"
import fee from "./fee.svg"
import realTimePayment from "./transaction-icons/real-time-payment.svg"
import lock from "./lock.svg"
import onItsWay from "./icon-shipped.svg"
import frozen from "./frozen-icon.svg"
import shareAndEarnDefault from "./share-and-earn-default.svg"
import shareAndEarnActive from "./share-and-earn-active.svg"
import perksDefault from "./perks-default.svg"
import perksActive from "./perks-active.svg"
import illustrationCalendar from "./illustrations/calendar.png"
import canTelephone from "./illustrations/can-telephone.png"
import cashRegister from "./illustrations/cash-register.png"
import envelopes from "./illustrations/envelopes.png"
import flyingMoney from "./illustrations/flying-money.png"
import hourglass from "./illustrations/hourglass.png"
import hourglassWithMoney from "./illustrations/hourglassWithMoney.png"
import lemonadeJug from "./illustrations/lemonade-jug.png"
import lightbulb from "./illustrations/lightbulb.png"
import mailIcon from "./mail-icon.png"
import northoneCard from "./northone-debit-card.png"
import openMailbox from "./open-mailbox.png"
import paperAirplane from "./illustrations/email-sent-airplane.png"
import phoneIllustration from "./illustrations/phone-illustration.png"
import pocketWatch from "./illustrations/pocket-watch.png"
import rocketship from "./illustrations/rocketship.png"
import trashBin from "./illustrations/trash-bin.png"
import illustrationVirtualCard from "./illustrations/mobile-virtual-card.png"
import layoutLogo from "./logo-full.svg"
import linkedAccounts from "./linked-accounts.png"
import linkedAccountsNoFade from "./linked-accounts-no-fade.png"
import lockTag from "./lock-tag.png"
import logo from "./northone-logo.svg"
import mbaBannerImage from "./iphone-12-straight-shadow-homefeed.png"
import mobileBackground from "./mobile-background.svg"
import modalGateBackground from "./modal-gate-background.png"
import northoneCounterparty from "./northone-counterparty.svg"
import northoneIcon from "./northone-icon.svg"
import paymentSourceIcons from "./payment-source-icons.png"
import phoneCardRating from "./phone-card-rating.png"
import physicalCard from "./physical-card.png"
import physicalCardFrozen from "./physical-card-locked.png"
import pinSetSuccess from "./pin-set-success.png"
import tabletBackground from "./tablet-background.svg"
import transactionPendingTimeline from "./transaction-pending-timeline.svg"
import transactionPostedTimeline from "./transaction-posted-timeline.svg"
import unfreezeIcon from "./unlock.svg"
import virtualCard from "./virtual-card.png"
import virtualCardFrozen from "./virtual-card-locked.png"
import wirePendingTimeline from "./wire-pending-timeline.svg"
import shareAndEarnLanding from "./share-and-earn-black-&-white.png"
import shareAndEarnNoAdvocateId from "./share-and-earn-cards.png"
import shareAndEarnBlocked from "./share-and-earn-phone-letter.png"
import shareAndEarnLock from "./share-and-earn-lock.png"
import perksHero from "./perks-hero.png"
import perksBackgroundSphere from "./perks-background-sphere.svg"
import teamBankingCards from "./team-banking-cards.png"
import wallet from "./wallet.png"
import cards from "./cards.svg"
import cardSimple from "./card-simple.svg"
import wireSimple from "./wire-simple.svg"
import gift from "./gift.svg"
import cross from "./cross.svg"
import greyCross from "./grey-cross.svg"
import attachedCans from "./attached-cans.png"
import invoiceUploadIcon from "./invoice-upload.svg"
import paperPlaneWhite from "./paper-plane-white.svg"
import invoiceEmptyState from "./invoice-empty-state-icon.svg"
import checkmarkBlue from "./checkmark-blue.svg"
import noticeBlue from "./notice-blue.svg"
import noticeGold from "./notice-gold.svg"
import noticeGreen from "./notice-green.svg"
import greyTrash from "./grey-trash.svg"
import refresh from "./refresh.svg"
import hourglassBlackWhite from "./hourglass-black-white.png"
import stripe from "./revenue-hub/stripe.png"
import square from "./revenue-hub/square.png"
import amazon from "./revenue-hub/amazon.png"
import etsy from "./revenue-hub/etsy.png"
import shopify from "./revenue-hub/shopify.png"
import paypal from "./revenue-hub/paypal.png"
import squareWidget from "./revenue-hub/square-widget.png"
import paypalWidget from "./revenue-hub/paypal-widget.png"
import shopifyWidget from "./revenue-hub/shopify-widget.png"
import fundThirdParty from "./fund-third-party.svg"
import personalAccount from "./personal-account.svg"
import corpInfo from "./corp-info.svg"
import plansDefault from "./plans-default.svg"
import plansActive from "./plans-active.svg"
import plansUpgrade from "./plans-upgrade.svg"
import plansUpgradeActive from "./plans-upgrade-active.svg"
import cancellationSuccessHero from "./cancellation-success-hero.jpg"
import upgradePlusSuccess from "./plus-upgrade-success.jpg"
import warningTriangle from "./warning-triangle.svg"
import lendingHero from "./lending-hero.png"
import applyForLending from "./apply-for-lending.svg"
import lendingTransaction from "./lending-transaction.svg"

export const images = {
  addVirtualCard,
  appStore,
  appStoreSmall,
  card,
  cardBack,
  checkDepositAddFunds,
  dashboardEmptyState,
  defaultAccount,
  downloadApp,
  envelope,
  freezeCard,
  googlePlay,
  googlePlaySmallWhite,
  greenDot,
  hourglassBlackWhite,
  icons: {
    addContact,
    addContactUi3,
    archive,
    watch,
    externalLink,
    arrow,
    caution,
    calendar,
    checkMark,
    checkmarkBlue,
    checkMarkDark,
    chevronDown,
    chevronUp,
    chevronLeft,
    chevronRight,
    close,
    closeBlack,
    closeLight,
    closeWhite,
    copy,
    copyTextIcon,
    copyTextHoverIcon,
    info,
    infoBold,
    infoGrey,
    transactions: transactionIcons,
    search,
    integrations: integrationIcons,
    edit,
    editBlack,
    export: exportIcon,
    logout,
    logoutWhite,
    pay,
    schedule,
    accounts,
    addFunds,
    filter,
    share,
    download,
    support,
    dollar,
    help,
    payments,
    error,
    selectionControl: selectionControlIcons,
    new: newIcon,
    sendInvoice,
    resume,
    mailIcon,
    pause,
    pauseSmall,
    percent,
    arrowDiagonalUp,
    arrowLeft,
    arrowRight,
    arrowRightBlack,
    add,
    addDark,
    editUi2,
    trash,
    statement,
    schedulePay,
    invite,
    transferAvatar,
    arrows,
    done,
    frameBlue,
    accountStatement,
    billingStatement,
    downloadStatement,
    redNotification,
    fundingOptions: {
      ach,
      atm,
      check,
      linked,
      thirdParty,
      wire,
    },
    cardSecurityCode,
    diagonalCards,
    ssnCard,
    pinShield,
    fee,
    realTimePayment,
    lock,
    onItsWay,
    frozen,
    shareAndEarnDefault,
    shareAndEarnActive,
    plansDefault,
    plansActive,
    plansUpgrade,
    plansUpgradeActive,
    perksDefault,
    perksActive,
    invoiceUploadIcon,
    paperPlaneWhite,
    invoiceEmptyState,
    noticeBlue,
    noticeGold,
    noticeGreen,
    greyTrash,
    refresh,
    fundThirdParty,
    personalAccount,
    corpInfo,
    warningTriangle,
    applyForLending,
    lendingTransaction,
  },
  illustrations: {
    calendar: illustrationCalendar,
    canTelephone,
    cashRegister,
    envelopes,
    flyingMoney,
    hourglass,
    hourglassWithMoney,
    lemonadeJug,
    lightbulb,
    northoneCard,
    openMailbox,
    paperAirplane,
    phoneIllustration,
    pocketWatch,
    rocketship,
    trashBin,
    virtualCard: illustrationVirtualCard,
  },
  revenueHub: {
    stripe,
    paypal,
    shopify,
    square,
    etsy,
    amazon,
    squareWidget,
    paypalWidget,
    shopifyWidget,
  },
  layoutLogo,
  linkedAccounts,
  linkedAccountsNoFade,
  lockTag,
  logo,
  mbaBannerImage,
  mobileBackground,
  modalGateBackground,
  northoneCounterparty,
  northoneIcon,
  paymentSourceIcons,
  phoneCardRating,
  physicalCard,
  physicalCardFrozen,
  pinSetSuccess,
  tabletBackground,
  transactionPendingTimeline,
  transactionPostedTimeline,
  unfreezeIcon,
  virtualCard,
  virtualCardFrozen,
  wirePendingTimeline,
  shareAndEarnLanding,
  shareAndEarnNoAdvocateId,
  shareAndEarnBlocked,
  shareAndEarnLock,
  perksHero,
  perksBackgroundSphere,
  teamBankingCards,
  wallet,
  cards,
  cardSimple,
  wireSimple,
  gift,
  cross,
  greyCross,
  attachedCans,
  cancellationSuccessHero,
  upgradePlusSuccess,
  lending: {
    hero: lendingHero,
  },
}
