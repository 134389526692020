import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { v4 as uuid } from "uuid"
import { useAppSelector } from "@core/redux/utils"
import { events } from "@core/analytics/events"
import { selectActiveBusinessID } from "@core/active-business/redux/selectors"
import { ACHScreensEnum } from "@features/move-money/router/ach"
import { moveMoneyActions } from "@features/move-money/redux/actions"
import { selectACHGQLVariables } from "@features/move-money/redux/selectors"
import { MoveMoneyConfirmCard } from "@features/move-money/shared/confirm-card"
import { AchCreateMutationVariables, useAchCreateMutation } from "@generated/graphql"
import { refreshTransactionLists } from "@utils/poll-with-max-retries"

export const ACHPaymentConfirmCard: React.FC = () => {
  const [idempotencyKey] = useState(uuid())
  const dispatch = useDispatch()
  const [hasError, setHasError] = useState(false)
  const businessId: string = useAppSelector(selectActiveBusinessID)
  const gqlVariables: AchCreateMutationVariables = useAppSelector(
    selectACHGQLVariables(idempotencyKey),
  )

  const [createAch, { loading }] = useAchCreateMutation({
    onCompleted: () => {
      refreshTransactionLists({ businessId, sourceSubAccountId: gqlVariables.data.subAccountId })
      dispatch(moveMoneyActions.navigateNext(ACHScreensEnum.ACH_SENT))
    },
    onError: () => setHasError(true),
  })

  return (
    <MoveMoneyConfirmCard
      gqlVariables={gqlVariables}
      createMutation={createAch}
      isLoading={loading}
      hasError={hasError}
      sendPaymentEvent={events.moveMoney.ach.sendPayment}
      discardPaymentEvent={events.moveMoney.ach.sendPaymentDiscard}
      discardCancelEvent={events.moveMoney.ach.discardCancel}
      discardConfirmEvent={events.moveMoney.ach.discardConfirm}
    />
  )
}
