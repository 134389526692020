import LoadingComponent from "@components/extended/loading/loading"
import React from "react"

export const ConnectionLoading = () => (
  <LoadingComponent
    dimension={25}
    containerSx={{
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  />
)
