import { Text, theme } from "@northone/ui"
import React from "react"
import { TransactionStatusEnum, TransactionTypeEnum } from "@generated/graphql"
import i18n from "@i18n/i18n"
import { SxStyleProp } from "rebass"
import { TTransactionPanelStatus } from "./utils"

interface ITransactionTypeDetailsProps {
  transactionType: TransactionTypeEnum
  returnedBy?: string
  transactionStatus?: TTransactionPanelStatus
}

const getTransactionTypeDetailsText = ({
  transactionType,
  returnedBy,
  transactionStatus,
}: {
  transactionType: TransactionTypeEnum
  returnedBy?: string
  transactionStatus?: TTransactionPanelStatus
}): { text: string | null; style?: SxStyleProp } => {
  switch (transactionType) {
    case TransactionTypeEnum.ACH_RETURN:
      return {
        text: i18n.t("transactions.returnedACHNote"),
        style: { color: theme.colors.error100 },
      }
    case TransactionTypeEnum.FEE_REVERSAL:
    case TransactionTypeEnum.WIRE_REVERSAL:
      return {
        text: i18n.t("transactions.declinedWireNote"),
        style: { color: theme.colors.error100 },
      }
    case TransactionTypeEnum.WIRE_RETURN:
      return {
        text: i18n.t("transactions.returnedWireNote", { returnedBy: returnedBy }),
        style: { color: theme.colors.error100 },
      }
    case TransactionTypeEnum.BILL_PAYMENT: {
      if (transactionStatus === TransactionStatusEnum.FAILED) {
        return {
          text: "Your digital bill payment has failed, please contact customer support.",
          style: { color: theme.colors.error100 },
        }
      }
      return { text: null }
    }
    case TransactionTypeEnum.BILL_PAYMENT_REFUND:
      return {
        text: "Your digital bill payment has been refunded to your account.",
        style: { color: theme.colors.ui2 },
      }
    default:
      return { text: null }
  }
}

export const TransactionTypeDetails: React.FC<ITransactionTypeDetailsProps> = ({
  transactionType,
  transactionStatus,
  returnedBy,
}) => {
  const { text, style } = getTransactionTypeDetailsText({
    transactionType,
    transactionStatus,
    returnedBy,
  })
  if (!text) {
    return null
  }
  return (
    <Text
      partialStyling={{
        color: theme.colors.ui2,
        textDecoration: "underline",
        cursor: "pointer",
      }}
      sx={{ ...style, marginTop: "6px" }}
      tag="body-small"
      onClick={() => window.Intercom("show")}
    >
      {text}
    </Text>
  )
}
