import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useAppSelector } from "@core/redux/utils"
import { selectActiveBusinessID } from "@core/active-business/redux/selectors"
import { Analytics } from "@core/analytics/actions"
import { events } from "@core/analytics/events"
import { ButtonTypeEnum } from "@components/primitive/button/button"
import { FormBody } from "@components/composite/form-body/form-body"
import { Text } from "@components/primitive/text/text"
import { TextInput } from "@components/extended/fields/text-input"
import { accountsActions } from "@features/accounts/redux/actions"
import { selectCreateSubAccountState } from "@features/accounts/redux/selectors"
import { theme } from "@layouts/theme"
import { FlexColumnLayout } from "@layouts/flex"
import {
  useSubAccountRulePercentCreateMutation,
  useSubAccountTotalPercentAvailableLazyQuery,
} from "@generated/graphql"
import { SUB_ACCOUNTS_QUERY } from "@features/accounts/sub-accounts/operations.gql"
import { pollWithMaxRetries } from "@utils/poll-with-max-retries"

export const CreateSubAccountPercent: React.FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const businessId = useAppSelector(selectActiveBusinessID)
  const { percentage, id } = useAppSelector(selectCreateSubAccountState)
  const [hasError, setHasError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [maxPercentage, setMaxPercentage] = useState(100)
  const hasValidPercentRule = percentage && Number(percentage) < 100

  const onError = () => {
    setHasError(true)
    setIsLoading(false)
  }

  const [availablePercentageQuery] = useSubAccountTotalPercentAvailableLazyQuery({
    variables: { businessId },
    onError,
    onCompleted: (data) => {
      if (data && typeof data.subAccountRuleTotalPercentAvailable === "number") {
        if (data.subAccountRuleTotalPercentAvailable < Number(percentage)) {
          setMaxPercentage(data.subAccountRuleTotalPercentAvailable)
          setIsLoading(false)
        } else {
          createPercentRule()
        }
      }
    },
    fetchPolicy: "network-only",
  })

  const [createPercentRule] = useSubAccountRulePercentCreateMutation({
    variables: {
      businessId,
      subAccountId: id,
      percent: Number(percentage),
    },
    onError,
    onCompleted: () => {
      pollWithMaxRetries([{ query: SUB_ACCOUNTS_QUERY, variables: { businessId } }])
      dispatch(accountsActions.createSubAccountNavigateNext("CREATE_SUB_ACCOUNT_REVIEW"))
    },
  })

  return (
    <FormBody
      title={t("accounts.createSubAccount.percentRule.titleFR")}
      buttons={[
        {
          canContinueWithKey: true,
          children: t("buttons.continue"),
          disabled: !hasValidPercentRule,
          isLoading,
          onClick: () => {
            setIsLoading(true)
            availablePercentageQuery()
            Analytics.track(events.accounts.create.percentRuleContinue, {
              amount: "",
              percentage,
            })
          },
        },
        {
          children: t("accounts.createSubAccount.initialTransfer.skip"),
          onClick: () => {
            dispatch(accountsActions.setCreateSubAccountPercentage(""))
            dispatch(accountsActions.createSubAccountNavigateNext("CREATE_SUB_ACCOUNT_REVIEW"))
          },
          type: ButtonTypeEnum.SECONDARY,
        },
      ]}
      errorMessage={hasError ? t("errors.generic") : ""}
    >
      <FlexColumnLayout
        sx={{
          paddingBottom: "24px",
          borderBottom: `1px solid ${theme.colors.ui4}`,
        }}
      >
        <TextInput
          sx={{ width: 250 }}
          label={t("accounts.createSubAccount.percentRule.label")}
          placeholder={"0"}
          suffix="%"
          value={percentage}
          onChange={(value) => {
            if (maxPercentage !== 100) {
              setMaxPercentage(100)
            }
            if (!isNaN(Number(value))) {
              dispatch(accountsActions.setCreateSubAccountPercentage(value))
            }
          }}
          maxLength={2}
          errorMessage={
            Number(percentage) > maxPercentage
              ? t("accounts.createSubAccount.percentRule.percentExceeded", {
                  percentage: maxPercentage,
                })
              : ""
          }
        />
        <Text tag="body-small" color={theme.colors.ui3}>
          {t("accounts.createSubAccount.percentRule.inputNote")}
        </Text>
      </FlexColumnLayout>
      <FlexColumnLayout sx={{ paddingTop: 24 }}>
        <Text hasPartialBolding textColor={theme.colors.ui2} tag="body">
          {t("accounts.createSubAccount.percentRule.note", {
            percentage,
            context: percentage ? null : "zeroState",
          })}
        </Text>
      </FlexColumnLayout>
    </FormBody>
  )
}
