import React from "react"
import { images } from "@assets/images/images"
import { InfoBlockModal } from "@northone/ui"
import { useCardNotArrived } from "./utils"

export const CardNotArrivedModal: React.FC = () => {
  const { title, subtitle, buttons, isOpen, closeModal } = useCardNotArrived()

  return (
    <InfoBlockModal
      title={title}
      subtitle={subtitle}
      buttons={buttons}
      isOpen={isOpen}
      closeModal={closeModal}
      midBlockImageSrc={images.envelope}
      imageDimensions={{ height: 100, width: 152 }}
      modalSx={{ width: "408px" }}
      closeButton
    />
  )
}
