import React, { useState } from "react"
import { Text } from "@northone/ui"
import { Modal } from "@components/extended/overlay/modal"
import { theme } from "@layouts/theme"
import { TeamOwner, useRegisterPersonMutation } from "@generated/graphql"
import { convertToFullName } from "@utils/format"
import { useTranslation } from "react-i18next"
import { FlexColumnLayout, FlexRowLayout } from "@northone/ui"
import { Box, Image, Link } from "rebass"
import { images } from "@assets/images/images"
import { teamBankingActions } from "./redux/actions"
import { useDispatch } from "react-redux"
import { AccessDetailsProps, CheckMarkList } from "./team-member-side-panel"
import { settings } from "@i18n/locales/en/features/settings"
import { NORTHONE_PRIVACY_POLICY_URL, NORTHONE_TERMS_OF_SERVICE_URL } from "@utils/constants"
import { Button, ButtonTypeEnum } from "@components/primitive/button/button"
import { useAppSelector } from "@core/redux/utils"
import { selectActiveBusinessID } from "@core/active-business/redux/selectors"
import { appActions } from "@core/redux/app/actions"
import { NoticeBox } from "@components/primitive/notice-box/notice-box"
import { Analytics } from "@core/analytics/actions"
import { events } from "@core/analytics/events"

const CloseX = () => {
  const dispatch = useDispatch()

  const onClick = () => dispatch(teamBankingActions.setIsModalOpen(false))

  return (
    <FlexRowLayout sx={{ justifyContent: "flex-end", paddingTop: "26px", paddingRight: "26px" }}>
      <Image
        src={images.icons.closeLight}
        sx={{
          cursor: "pointer",
          width: "16px",
          height: "16px",
        }}
        onClick={onClick}
      />
    </FlexRowLayout>
  )
}

const TopSection = ({
  fullName,
  email,
  hasError,
}: {
  fullName: string
  email: string
  hasError: boolean
}) => {
  const { t } = useTranslation()

  return (
    <>
      <FlexColumnLayout sx={{ padding: "10px 48px 13px 48px" }}>
        <Text tag={"h3Serif"} sx={{ fontWeight: 600, marginBottom: "24px" }}>
          {t("settings.pages.teamBanking.modal.title", { fullName })}
        </Text>
        <FlexColumnLayout>
          {hasError && (
            <NoticeBox
              level="error"
              text={t("settings.pages.teamBanking.modal.inviteSent.error")}
            />
          )}
          <Text tag={"label"} sx={{ color: theme.colors.ui2 }}>
            {t("settings.pages.teamBanking.modal.emailLabel")}
          </Text>
          <Text sx={{ marginTop: "5px" }} tag={"body"}>
            {email}
          </Text>
        </FlexColumnLayout>
      </FlexColumnLayout>
    </>
  )
}
const MiddleSection = ({ accessDetails }: { accessDetails: AccessDetailsProps[] }) => {
  const { t } = useTranslation()

  return (
    <Box sx={{ backgroundColor: theme.colors.ui6 }}>
      <FlexColumnLayout sx={{ marginX: "48px", marginTop: "16px" }}>
        <Text tag={"body-bold"}>{t("settings.pages.teamBanking.modal.permissions")}</Text>
        {accessDetails.map((feature, idx) => (
          <FlexRowLayout
            key={idx}
            sx={{
              marginTop: "24px",
              paddingBottom: "16px",
              justifyContent: "space-between",
              borderBottom:
                idx === accessDetails.length - 1 ? null : `1px solid ${theme.colors.ui4}`,
            }}
          >
            <FlexColumnLayout>
              <Text
                tag={"label"}
                sx={{
                  textTransform: "uppercase",
                  color: theme.colors.ui2,
                  letterSpacing: "1px",
                  fontWeight: 500,
                  lineHeight: "11px",
                  marginBottom: "8px",
                }}
              >
                {feature.featureLabel}
              </Text>
              <Text tag={"body-small-bold"}>{feature.accessLabel}</Text>
            </FlexColumnLayout>
            <FlexColumnLayout sx={{ marginRight: "63px", width: "205px" }}>
              <CheckMarkList details={feature.details} />
            </FlexColumnLayout>
          </FlexRowLayout>
        ))}
      </FlexColumnLayout>
    </Box>
  )
}

const BottomSection = ({
  personId,
  onError,
  onInviteSent,
}: {
  personId: string
  onError: () => void
  onInviteSent: ({ personId }: { personId: string }) => void
}) => {
  const { t } = useTranslation()
  const businessId = useAppSelector(selectActiveBusinessID)
  const dispatch = useDispatch()

  const [registerPerson, { loading }] = useRegisterPersonMutation({
    variables: {
      businessId,
      personId,
    },
    onError: () => {
      onError()
    },
    onCompleted: () => {
      dispatch(teamBankingActions.setIsModalOpen(false))
      dispatch(teamBankingActions.setSelectedTeamMember(undefined))
      dispatch(
        appActions.setNotificationMessage(t("settings.pages.teamBanking.modal.inviteSent.success")),
      )
      onInviteSent({ personId })
    },
  })

  const onClick = async () => {
    Analytics.track(events.settings.teamBanking.sendInvite.click)
    await registerPerson()
  }

  return (
    <FlexColumnLayout sx={{ paddingX: "48px" }}>
      <Text sx={{ color: theme.colors.ui2, marginY: "20px" }} tag={"body-small"}>
        {t("settings.pages.teamBanking.modal.termsBeginning")}
        <Link
          href={NORTHONE_TERMS_OF_SERVICE_URL}
          target="_blank"
          sx={{ textDecoration: "none", color: theme.colors.ui2, fontWeight: 500 }}
        >
          {t("settings.pages.teamBanking.modal.termsLink")}
        </Link>
        {t("settings.pages.teamBanking.modal.and")}
        <Link
          href={NORTHONE_PRIVACY_POLICY_URL}
          target="_blank"
          sx={{ textDecoration: "none", color: theme.colors.ui2, fontWeight: 500 }}
        >
          {t("settings.pages.teamBanking.modal.privacyLink")}
        </Link>
      </Text>
      <Button
        type={ButtonTypeEnum.PRIMARY_GOLD}
        sx={{ marginBottom: "20px" }}
        onClick={onClick}
        isLoading={loading}
      >
        {loading
          ? t("settings.pages.teamBanking.buttons.sendingInvite")
          : t("settings.pages.teamBanking.buttons.sendInvite")}
      </Button>
      <Text tag={"body-x-small"} sx={{ color: theme.colors.ui2 }}>
        {t("settings.pages.teamBanking.modal.disclaimer")}
      </Text>
    </FlexColumnLayout>
  )
}

interface IModalProps {
  isOpen: boolean
  teamMember: TeamOwner | undefined
  onInviteSent: ({ personId }: { personId: string }) => void
}

export const InviteModal = ({ isOpen, teamMember, onInviteSent }: IModalProps) => {
  const [hasError, setHasError] = useState(false)
  const dispatch = useDispatch()

  if (!teamMember) return null
  const { firstName, lastName, email, id } = teamMember

  const fullName = convertToFullName({ firstName, lastName })
  const accessDetails = settings.pages.teamBanking.accessDetails
  const onOutsideClick = () => dispatch(teamBankingActions.setIsModalOpen(false))

  const onError = () => {
    setHasError(true)
    Analytics.track(events.settings.teamBanking.sendInvite.error)
  }

  return (
    <Modal
      sx={{ padding: "0px", paddingBottom: "32px", width: "100%", maxWidth: "518px" }}
      zIndex={theme.zIndex.flowModal}
      isOpen={isOpen}
      onOutsideClick={onOutsideClick}
    >
      <CloseX />
      <FlexColumnLayout>
        <TopSection fullName={fullName} email={email} hasError={hasError} />
        <MiddleSection accessDetails={accessDetails} />
        <BottomSection personId={id} onError={onError} onInviteSent={onInviteSent} />
      </FlexColumnLayout>
    </Modal>
  )
}
