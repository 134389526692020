import React from "react"
import { useDispatch } from "react-redux"
import { useAppSelector } from "@core/redux/utils"
import { TextInput } from "@components/extended/fields/text-input"
import { moveMoneyActions } from "@features/move-money/redux/actions"
import { selectEditRecipient } from "@features/move-money/redux/selectors"
import { validationConstants } from "@utils/validate"
import { FlexColumnLayout, FlexRowLayout } from "@layouts/flex"
import {
  RECIPIENT_DETAILS_NAME,
  RECIPIENT_DETAILS_CHAR_LIMIT_EXCEEDED,
  RECIPIENT_DETAILS_ROUTING_NUMBER,
  RECIPIENT_DETAILS_ROUTING_NUMBER_INVALID,
  RECIPIENT_DETAILS_ACCOUNT_NUMBER,
} from "@features/move-money/utils/i18n-translations"

interface IACHRecipientDetailsProps {
  routingNumberIsValid: boolean
  nameLengthExceeded: boolean
}

export const ACHRecipientDetails: React.FC<IACHRecipientDetailsProps> = ({
  routingNumberIsValid,
  nameLengthExceeded,
}) => {
  const dispatch = useDispatch()
  const { name, bankRoutingNumber, bankAccountNumber } = useAppSelector(selectEditRecipient)

  return (
    <FlexColumnLayout>
      <TextInput
        value={name || ""}
        placeholder={RECIPIENT_DETAILS_NAME}
        label={RECIPIENT_DETAILS_NAME}
        onChange={(value) => dispatch(moveMoneyActions.setEditingRecipient({ name: value }))}
        errorMessage={nameLengthExceeded ? RECIPIENT_DETAILS_CHAR_LIMIT_EXCEEDED : undefined}
      />
      <FlexRowLayout sx={{ width: "100%" }}>
        <TextInput
          value={bankRoutingNumber || ""}
          label={RECIPIENT_DETAILS_ROUTING_NUMBER}
          placeholder="#########"
          onChange={(value) =>
            dispatch(moveMoneyActions.setEditingRecipient({ bankRoutingNumber: value }))
          }
          maxLength={validationConstants.ROUTING_NUMBER_MAX_LENGTH}
          errorMessage={!routingNumberIsValid ? RECIPIENT_DETAILS_ROUTING_NUMBER_INVALID : ""}
          containerSx={{ width: "50%", marginRight: 16 }}
        />
        <TextInput
          value={bankAccountNumber || ""}
          label={RECIPIENT_DETAILS_ACCOUNT_NUMBER}
          placeholder="#########"
          onChange={(value) =>
            dispatch(moveMoneyActions.setEditingRecipient({ bankAccountNumber: value }))
          }
          containerSx={{ width: "50%" }}
          maxLength={validationConstants.ACCOUNT_NUMBER_MAX_LENGTH}
        />
      </FlexRowLayout>
    </FlexColumnLayout>
  )
}
