import { createSelector } from "reselect"
import { selectActiveBusiness, selectUser } from "../../redux/app/selectors"

export const selectActiveBusinessID = createSelector(
  selectActiveBusiness,
  (state) => state.businessId,
)

export const selectDefaultAccountID = createSelector(
  selectUser,
  (state) => state.me?.ownerBusinesses[0].bankAccount?.id,
)
