import React from "react"
import { useTranslation } from "react-i18next"
import { Image } from "rebass"
import { FlexColumnLayout, Text } from "@northone/ui"
import { images } from "@assets/images/images"
import { theme } from "@layouts/theme"
import { STATEMENT_AVAILABLE_DAY_OF_MONTH } from "./statements"

const STATEMENT_NOT_READY_TITLE_I18N = "accountStatements.zeroState.statementNotReadyTitle"
const STATEMENT_NOT_READY_SUBTITLE_I18N = "accountStatements.zeroState.statementNotReadySubtitle"

export const AccountStatementsZeroState: React.FC = () => {
  const { t } = useTranslation()

  return (
    <FlexColumnLayout sx={{ alignItems: "center", marginTop: 90 }}>
      <Image
        sx={{
          height: 200,
          width: 200,
          marginBottom: 15,
          alignItems: "center",
        }}
        src={images.icons.watch}
      />
      <Text tag="h2" sx={{ fontSize: "28px" }}>
        {t(STATEMENT_NOT_READY_TITLE_I18N)}
      </Text>
      <Text tag="body" textColor={theme.colors.ui2} sx={{ marginY: 15, fontSize: "16px" }}>
        {t(STATEMENT_NOT_READY_SUBTITLE_I18N, {
          dayOfMonth: STATEMENT_AVAILABLE_DAY_OF_MONTH,
        })}
      </Text>
    </FlexColumnLayout>
  )
}
