import { images } from "@assets/images/images"
import { theme } from "@layouts/theme"
import React from "react"
import { Box, Image } from "rebass"

export const PerksBackground: React.FC = () => (
  <Box
    sx={{
      top: -20,
      right: 0,
      height: 352,
      width: "100%",
      position: "absolute",
      zIndex: -1,
      borderBottomColor: theme.colors.gold60,
      borderBottomWidth: "4px",
      borderBottomStyle: "solid",
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "stretch",
    }}
  >
    <Box
      sx={{
        backgroundImage: `url(${images.perksBackgroundSphere})`,
        width: "45%",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top left",
        display: "flex",
        justifyContent: "left",
        alignItems: "flex-end",
        backgroundColor: theme.colors.goldSphere,
      }}
    >
      <Image marginLeft={75} width={300} src={images.perksHero} />
    </Box>
  </Box>
)
