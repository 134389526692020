import { validationConstants } from "@utils/validate"

export enum RoutingNumberErrorTranslationKeyEnum {
  INVALID_LENGTH = "invoicePayments.modal.paymentDetails.inputs.routingNumber.invalidLength",
  INVALID_NUMBER = "invoicePayments.modal.paymentDetails.inputs.routingNumber.invalidNumber",
  VALIDATION_ERROR = "invoicePayments.modal.paymentDetails.inputs.routingNumber.validationError",
}

export const isValidRoutingNumberLength = (routingNumber: string) =>
  routingNumber.length === validationConstants.ROUTING_NUMBER_MAX_LENGTH
