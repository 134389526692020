import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { v4 as uuid } from "uuid"
import { useAppSelector } from "@core/redux/utils"
import { selectActiveBusinessID } from "@core/active-business/redux/selectors"
import { Modal } from "@components/extended/overlay/modal"
import { InfoBlock } from "@components/composite/info-block/info-block"
import { ButtonTypeEnum } from "@components/primitive/button/button"
import { ACH_RECIPIENTS, DOMESTIC_WIRE_RECIPIENTS } from "@features/move-money/operations.gql"
import { pollWithMaxRetries } from "@utils/poll-with-max-retries"
import { theme } from "@layouts/theme"
import { useDeleteRecipientMutation, ContactPaymentType } from "@generated/graphql"

interface IDeleteRecipientModalProps {
  isOpen: boolean
  closeOverlay: () => void
  recipientId: string
  onCompleted: () => void
  paymentType: ContactPaymentType
}

export const DeleteRecipientModal: React.FC<IDeleteRecipientModalProps> = ({
  isOpen,
  closeOverlay,
  recipientId,
  onCompleted,
  paymentType,
}) => {
  const { t } = useTranslation()
  const [idempotencyKey] = useState<string>(uuid())
  const [hasError, setHasError] = useState(false)
  const businessId = useAppSelector(selectActiveBusinessID)

  const getRecipientsQuery = (paymentType: ContactPaymentType) => {
    switch (paymentType) {
      case ContactPaymentType.ACH:
        return ACH_RECIPIENTS
      case ContactPaymentType.WIRE:
        return DOMESTIC_WIRE_RECIPIENTS
    }
  }

  const query = getRecipientsQuery(paymentType)

  const [deleteRecipient, { loading }] = useDeleteRecipientMutation({
    variables: { input: { businessId, contactId: recipientId, idempotencyKey } },
    onError: () => setHasError(true),
    onCompleted: () => {
      pollWithMaxRetries([
        {
          query,
          variables: { businessId, contactPaymentType: paymentType },
        },
      ])
      onCompleted()
    },
  })
  return (
    <Modal zIndex={theme.zIndex.flowModal + 1} isOpen={isOpen} onOutsideClick={closeOverlay}>
      <InfoBlock
        errorMessage={hasError ? t("errors.generic") : ""}
        title={t("settings.pages.recipients.delete.title")}
        buttons={[
          {
            children: t("settings.pages.recipients.delete.button"),
            onClick: () => {
              setHasError(false)
              deleteRecipient()
            },
            isLoading: loading,
          },
          { children: t("buttons.cancel"), onClick: closeOverlay, type: ButtonTypeEnum.SECONDARY },
        ]}
      />
    </Modal>
  )
}
