import React, { useState } from "react"
import accounting from "accounting"
import { useTranslation } from "react-i18next"

import { getFormattedFullDate } from "@utils/dates"
import { ChangePlanDetailsLayout } from "../shared/components/change-plan-details-layout"
import { BillingDetailsBox } from "../shared/components/billing-details-box"
import { useSubscriptionsData } from "../shared/hooks/use-subscriptions-data"
import { getPlanMonthlyPrice } from "../utils"
import { ErrorScreen } from "@components/composite/error-screen/error-screen"
import { ButtonTypeEnum } from "@components/primitive/button/button"
import { SubscriptionPlansDataQuery, useSubscriptionReactivateMutation } from "@generated/graphql"
import { useAppSelector } from "@core/redux/utils"
import { selectActiveBusinessID } from "@core/active-business/redux/selectors"
import { NoticeBox } from "@components/primitive/notice-box/notice-box"
import { Analytics } from "@core/analytics/actions"
import { events } from "@core/analytics/events"
import { appActions } from "@core/redux/app/actions"
import { useDispatch } from "react-redux"
import { SUBSCRIPTION_DATA } from "../operations.gql"

export const ReactivatePlanScreen = ({ closeModal }: { closeModal: () => void }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const businessId = useAppSelector(selectActiveBusinessID)
  const [errorMessage, setErrorMessage] = useState<string | undefined>()

  const { subscription } = useSubscriptionsData()
  const monthlyPrice = getPlanMonthlyPrice(subscription?.plan)
  const plan = subscription?.plan
  const nextBillingDate = subscription?.nextBillingDate

  const [reactivatePlan, { loading }] = useSubscriptionReactivateMutation({
    variables: { input: { businessId } },
    onError: () => setErrorMessage(copy.error),
    onCompleted: () => {
      closeModal()
      dispatch(appActions.setNotificationMessage(copy.toast))
    },
    update: (cache, { data }) => {
      const subscription = data?.subscriptionReactivate?.subscription
      if (subscription) {
        cache.modify({
          fields: {
            subscription: () =>
              cache.writeQuery<SubscriptionPlansDataQuery>({
                query: SUBSCRIPTION_DATA,
                data: { subscription },
              }),
          },
        })
      }
    },
  })

  if (!monthlyPrice || !plan || !nextBillingDate) return <ErrorScreen size="large" />

  const formattedMonthlyPrice = accounting.formatMoney(monthlyPrice)
  const planTitle = plan.title
  const planShortTitle = plan.shortTitle

  const copy = {
    heading: t("subscriptions.reactivate.title", { planTitle }),
    subtitle: t("subscriptions.reactivate.subtitle"),
    terms: t("subscriptions.reactivate.terms", {
      planShortTitle,
      formattedMonthlyPrice,
    }),
    confirmButton: t("subscriptions.reactivate.reactivatePlan", { planShortTitle }),
    goBackButton: t("buttons.goBack"),
    error: t("subscriptions.reactivate.error"),
    labels: {
      monthlyFee: t("subscriptions.billingDetails.labels.monthlyFee"),
      nextBillingDate: t("subscriptions.billingDetails.labels.nextBillingDate"),
      fromAccount: t("subscriptions.billingDetails.labels.fromAccount"),
      depositAccount: t("subscriptions.billingDetails.labels.depositAccount"),
    },
    toast: t("subscriptions.planUpdatedToast"),
  }

  const billingDetails = [
    {
      label: copy.labels.monthlyFee,
      value: formattedMonthlyPrice,
    },
    {
      label: copy.labels.nextBillingDate,
      value: getFormattedFullDate(nextBillingDate),
    },
    {
      label: copy.labels.fromAccount,
      value: copy.labels.depositAccount,
    },
  ]

  const buttons = [
    {
      type: ButtonTypeEnum.TERTIARY,
      children: copy.goBackButton,
      onClick: () => {
        Analytics.track(events.subscriptions.cancelReactivatePlanClick)
        closeModal()
      },
    },
    {
      type: ButtonTypeEnum.PRIMARY_BLACK,
      children: copy.confirmButton,
      isLoading: loading,
      onClick: () => {
        Analytics.track(events.subscriptions.confirmReactivatePlanClick)
        setErrorMessage(undefined)
        reactivatePlan()
      },
    },
  ]

  return (
    <ChangePlanDetailsLayout
      heading={copy.heading}
      subtitle={copy.subtitle}
      buttons={buttons}
      terms={copy.terms}
    >
      {errorMessage && <NoticeBox level="error" text={errorMessage} containerSx={{ mb: 0 }} />}
      <BillingDetailsBox items={billingDetails} />
    </ChangePlanDetailsLayout>
  )
}
