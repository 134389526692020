import React from "react"
import { Avatar, IColorTheme } from "@northone/ui"
import { theme } from "@layouts/theme"
import { TTextTag } from "@components/primitive/text/text"

interface ICounterpartyAvatarProps {
  counterpartyName: string
  imageSrc?: string
  size?: number
  colorThemes?: IColorTheme[]
  textTag?: TTextTag
  borderRadius?: number
  opacity?: number
}

/**
 * Extends the Avatar primitive
 */
export const CounterpartyAvatar: React.FC<ICounterpartyAvatarProps> = ({
  counterpartyName,
  imageSrc,
  size = 28,
  colorThemes,
  textTag,
  borderRadius = 6,
  opacity,
}) => (
  <Avatar
    name={counterpartyName}
    imageSrc={imageSrc}
    dimensions={{
      width: size,
      borderRadius: borderRadius,
    }}
    opacity={opacity}
    textTag={textTag}
    colorThemes={
      colorThemes
        ? colorThemes
        : [
            { backgroundColor: theme.colors.blue30, textColor: theme.colors.blue100 },
            { backgroundColor: theme.colors.green30, textColor: theme.colors.green100 },
            { backgroundColor: theme.colors.purple30, textColor: theme.colors.purple100 },
            { backgroundColor: theme.colors.brown30, textColor: theme.colors.brown100 },
            { backgroundColor: theme.colors.gold30, textColor: theme.colors.gold100 },
          ]
    }
  />
)
