import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Text, Modal } from "@northone/ui"
import { theme } from "@layouts/theme"
import { Button, ButtonTypeEnum } from "@components/primitive/button/button"
import { FlexColumnLayout } from "@layouts/flex"
import { AccountTypeSelectorBox } from "@components/selector-box/account-type-selector-box"
import { PlaidAccountType } from "@generated/graphql"
import { images } from "@assets/images/images"

type AccountTypeModalProps = {
  isOpen: boolean
  onContinue: (props: { accountType: PlaidAccountType }) => void
  onClose: () => void
}

export function AccountTypeModal({ isOpen, onContinue, onClose }: AccountTypeModalProps) {
  const { t } = useTranslation()
  const [accountType, setAccountType] = useState<PlaidAccountType>()

  function handleContinue() {
    if (!accountType) return
    onContinue({ accountType })
  }

  return (
    <Modal isOpen={isOpen} onOutsideClick={onClose}>
      <FlexColumnLayout sx={{ maxWidth: "400px", gap: "3" }}>
        <Text tag="h3Serif">{t("settings.pages.linkedAccount.accountType.title")}</Text>
        <Text tag="body" textColor={theme.colors.ui2}>
          {t("settings.pages.linkedAccount.accountType.subtitle")}
        </Text>
        <FlexColumnLayout>
          <AccountTypeSelectorBox
            title={t("settings.pages.linkedAccount.accountType.personal.label")}
            subtitle={t("settings.pages.linkedAccount.accountType.personal.description")}
            iconSrc={images.icons.personalAccount}
            isSelected={accountType === PlaidAccountType.PERSONAL}
            onSelect={() => setAccountType(PlaidAccountType.PERSONAL)}
          />
          <AccountTypeSelectorBox
            title={t("settings.pages.linkedAccount.accountType.business.label")}
            subtitle={t("settings.pages.linkedAccount.accountType.business.description")}
            iconSrc={images.icons.corpInfo}
            isSelected={accountType === PlaidAccountType.BUSINESS}
            onSelect={() => setAccountType(PlaidAccountType.BUSINESS)}
          />
        </FlexColumnLayout>
        <Button onClick={handleContinue} type={ButtonTypeEnum.PRIMARY_BLUE} disabled={!accountType}>
          {t("buttons.done")}
        </Button>
      </FlexColumnLayout>
    </Modal>
  )
}
