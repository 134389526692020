import React from "react"
import { images } from "@assets/images/images"
import { Image } from "rebass"
import { FlexRowLayout } from "@layouts/flex"
import { Text } from "@components/primitive/text/text"

export const PlanBullet = ({ icon, text }: { icon?: string; text: string }) => (
  <FlexRowLayout sx={{ marginTop: "12px" }} key={text}>
    <PlanBulletIcon icon={icon} />
    <Text tag="body" sx={{ marginLeft: "12px" }} hasPartialBolding>
      {text}
    </Text>
  </FlexRowLayout>
)

const PlanBulletIcon = ({ icon }: { icon?: string }) => {
  const imgSrc = ((icon?: string) => {
    switch (icon) {
      case "checkmark": {
        return images.icons.checkMarkDark
      }
      case "plus": {
        return images.icons.addDark
      }
      default: {
        return undefined
      }
    }
  })(icon)

  return <Image src={imgSrc} width={"15px"} />
}
