import React, { useEffect, useRef } from "react"
import { Box } from "rebass"

import { WithMaxWidth } from "./max-width"

import { Grid } from "./grid"
import { theme } from "./theme"
import { useWindowDimensions } from "../utils/hooks"
import { useLocation } from "react-router-dom"
import { FlexColumnLayout } from "./flex"
import { RoutesEnum } from "@routers/types"

interface IAppProps {
  leftAside: React.ReactElement
  children: React.ReactElement
}

export const APP_CONTAINER_ID = "app-container"
export const LEFT_ASIDE_CONTAINER_ID = "left-aside"
export const noLeftAsideRoutes: string[] = [
  RoutesEnum.PERKS,
  RoutesEnum.CARDS,
  RoutesEnum.INVOICES,
  RoutesEnum.REVENUE_HUB,
  RoutesEnum.PLANS,
  RoutesEnum.LENDING,
]

export const LeftAside: React.FC<{ leftAside: React.ReactElement }> | null = ({ leftAside }) => {
  const { height } = useWindowDimensions()
  const location = useLocation()

  const noLeftAside = noLeftAsideRoutes.includes(location.pathname)

  return noLeftAside ? null : (
    <Box
      sx={{
        gridColumn: "1/4",
        gridRow: "1/2",
        position: "relative",
      }}
    >
      <FlexColumnLayout
        id={LEFT_ASIDE_CONTAINER_ID}
        sx={{
          position: "sticky",
          top: theme.heights.navBar,
          height: height - theme.heights.navBar,
          overflow: "auto",
          paddingY: theme.spacing.sidebar,
          justifyContent: "space-between",
          marginX: -12,
          paddingX: 12,
        }}
      >
        {leftAside}
      </FlexColumnLayout>
    </Box>
  )
}

export const AppLayout: React.FC<IAppProps> = ({ leftAside, children }) => {
  const location = useLocation()

  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTo({ top: 0 })
    }
  }, [location])

  return (
    <Box
      ref={ref}
      id={APP_CONTAINER_ID}
      sx={{ height: "100vh", overflowY: "auto", position: "relative" }}
    >
      <WithMaxWidth sx={{ paddingTop: theme.heights.navBar }}>
        <Grid>
          <LeftAside leftAside={leftAside} />
          {children}
        </Grid>
      </WithMaxWidth>
    </Box>
  )
}
