import React from "react"
import { Pill } from "@northone/ui"
import { theme } from "@layouts/theme"
import { Text } from "@components/primitive/text/text"
import { SxStyleProp } from "rebass"

export const PlanPill = ({ text, sx }: { text: string; sx?: SxStyleProp }) => {
  return (
    <Pill sx={{ backgroundColor: theme.colors.goldTint3, ...sx }}>
      <Text tag="label" sx={{ textTransform: "uppercase", fontWeight: 500 }}>
        {text}
      </Text>
    </Pill>
  )
}
