import React, { useRef, useState, memo } from "react"
import { useTranslation } from "react-i18next"
import { Box } from "rebass"
import { BusinessAvatar } from "@northone/ui"
import { WithDropdownMenu } from "@components/primitive/dropdown-menu/dropdown-menu"
import { DropdownItem } from "@components/primitive/dropdown-item/dropdown-item"
import { useOnboardingMeQuery } from "@generated/graphql"
import { useClickOutside } from "@utils/hooks"
import { theme } from "@layouts/theme"
import { FlexColumnLayout } from "@layouts/flex"
import { images } from "@assets/images/images"
import { LogoutPromptModal } from "@components/composite/nav-bar/logout-prompt-modal"

const UserActionDropdown: React.FC = () => {
  const { t } = useTranslation()
  const ref = useRef<HTMLDivElement>(null)
  const [menuIsVisible, setMenuIsVisible] = useState(false)
  const [isPromptModalOpen, setIsPromptModalOpen] = useState(false)
  const { data, loading } = useOnboardingMeQuery()

  useClickOutside([ref], () => setMenuIsVisible(false))

  if (loading) {
    return null
  }

  return (
    <>
      <LogoutPromptModal
        isOpen={isPromptModalOpen}
        closeModal={() => setIsPromptModalOpen(false)}
      />
      <Box
        sx={{
          gridColumn: ["10/11", "12/13"],
          alignItems: "center",
        }}
        ref={ref}
      >
        <WithDropdownMenu
          alignment="right"
          visible={menuIsVisible}
          customWidth={180}
          content={
            <FlexColumnLayout sx={{ maxHeight: 300, overflowY: "scroll", paddingY: "12px" }}>
              <DropdownItem
                text={t("support.faq.short")}
                value="FAQs"
                onSelect={() => {
                  window.open("https://www.northone.com/faq", "_blank")
                  setMenuIsVisible(false)
                }}
              />
              <DropdownItem
                text={t("support.title")}
                value="Title"
                onSelect={() => {
                  window.Intercom("show")
                  setMenuIsVisible(false)
                }}
              />
              <DropdownItem
                text={t("logout.button")}
                value="Logout"
                iconSrc={images.icons.logout}
                sx={{ display: "flex" }}
                textSx={{ color: theme.colors.ui2 }}
                onSelect={() => setIsPromptModalOpen(true)}
              />
            </FlexColumnLayout>
          }
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              cursor: "pointer",
              marginY: "5px",
            }}
            onClick={() => setMenuIsVisible((prevMenuIsVisible) => !prevMenuIsVisible)}
          >
            <BusinessAvatar
              businessName={data?.me ? `${data.me.firstName} ${data.me.lastName}` : ""}
            />
          </Box>
        </WithDropdownMenu>
      </Box>
    </>
  )
}

export default memo(UserActionDropdown)
