import { client } from "@core/apollo/apollo-client"
import { TRANSACTIONS_LIST } from "@components/composite/transaction-list/operations.gql"
import { mergeTransactionGroups } from "@components/composite/transaction-list/utils"
import {
  BUSINESS_ACCOUNT_INFO,
  BUSINESS_ACCOUNTS_AVAILABLE_BALANCE_QUERY,
} from "@features/sidebar/shared/operations.gql"
import {
  QueryTransactionsListArgs,
  TransactionsListQuery,
  BankAccountInfoQuery,
  QueryBusinessArgs,
  BusinessInfoQuery,
  BusinessInfoQueryVariables,
} from "@generated/graphql"

interface IGQLOperation {
  query: any
  variables: any
}

export const pollSingleQuery = async (operation: IGQLOperation, maxRetryCount = 20) => {
  if (!maxRetryCount) {
    return
  }
  try {
    const cachedResponse = client.readQuery(operation)

    const response = await client.query({
      ...operation,
      fetchPolicy: "network-only",
    })
    if (cachedResponse && JSON.stringify(cachedResponse) !== JSON.stringify(response.data)) {
      return
    }
  } catch {
    // swallow errors
  }
  await pollSingleQuery(operation, maxRetryCount - 1)
}

export const pollWithMaxRetries = async (operations: IGQLOperation[], maxRetryCount?: number) => {
  await Promise.all(operations.map((operation) => pollSingleQuery(operation, maxRetryCount)))
}

export const refreshTransactionLists = async ({
  businessId,
  sourceSubAccountId,
  destinationSubAccountId,
}: {
  businessId: string
  sourceSubAccountId?: string
  destinationSubAccountId?: string
}) => {
  await Promise.all([
    refreshSingleTransactionList({ businessId }),
    ...(sourceSubAccountId
      ? [
          refreshSingleTransactionList({
            businessId,
            filters: { subAccountId: sourceSubAccountId },
          }),
        ]
      : []),
    ...(destinationSubAccountId
      ? [
          refreshSingleTransactionList({
            businessId,
            filters: { subAccountId: destinationSubAccountId },
          }),
        ]
      : []),
  ])
}

const refreshSingleTransactionList = async (
  variables: QueryTransactionsListArgs,
  maxRetryCount = 100,
) => {
  if (!maxRetryCount) {
    return
  }

  const queryOptions = { query: TRANSACTIONS_LIST, variables }
  const cacheResult =
    client.readQuery<TransactionsListQuery, QueryTransactionsListArgs>(queryOptions)

  if (!cacheResult) {
    return
  }
  try {
    const networkResult = await client.query<TransactionsListQuery, QueryTransactionsListArgs>({
      ...queryOptions,
      fetchPolicy: "no-cache",
    })

    if (
      cacheResult?.transactionsList &&
      networkResult.data?.transactionsList &&
      cacheResult.transactionsList.transactionListItems[0]?.id !==
        networkResult.data.transactionsList.transactionListItems[0]?.id
    ) {
      client.writeQuery<TransactionsListQuery, QueryTransactionsListArgs>({
        ...queryOptions,
        data: {
          ...cacheResult,
          transactionsList: {
            __typename: "TransactionsListResponse",
            pagination: {
              cursor: cacheResult.transactionsList.pagination?.cursor || null,
              __typename: "TransactionsPagination",
            },
            transactionListItems: mergeTransactionGroups(networkResult.data, cacheResult),
          },
        },
      })

      return
    }
  } catch {
    // swallow errors
  }
  setTimeout(() => refreshSingleTransactionList(variables, maxRetryCount - 1), 300)
}

export const refreshBusinessBankAccountInfo = async ({ businessId }: { businessId: string }) => {
  await refreshBusinessBankAccountData({ businessId })
}

const refreshBusinessBankAccountData = async (
  variables: QueryBusinessArgs,
  maxRetryCount = 100,
) => {
  if (!maxRetryCount) {
    return
  }
  const queryOptions = { query: BUSINESS_ACCOUNT_INFO, variables }
  const cacheResult = client.readQuery<BankAccountInfoQuery, QueryBusinessArgs>(queryOptions)

  if (!cacheResult) {
    return
  }
  try {
    const networkResult = await client.query<BankAccountInfoQuery, QueryBusinessArgs>({
      ...queryOptions,
      fetchPolicy: "network-only",
    })

    if (
      cacheResult?.business &&
      networkResult.data?.business &&
      cacheResult.business.bankAccount?.availableBalance !==
        networkResult.data.business.bankAccount?.availableBalance
    ) {
      client.writeQuery<BankAccountInfoQuery, QueryBusinessArgs>({
        ...queryOptions,
        data: {
          ...cacheResult,
          business: {
            __typename: "Business",
            id: cacheResult.business.id,
            bankAccount: {
              __typename: "BankAccount",
              id: cacheResult.business.bankAccount?.id || "",
              availableBalance: networkResult.data.business.bankAccount?.availableBalance,
              routingNumber: networkResult.data.business.bankAccount?.routingNumber,
              accountNumber: networkResult.data.business.bankAccount?.accountNumber,
              bankName: networkResult.data.business.bankAccount?.bankName,
              bankAddress: networkResult.data.business.bankAccount?.bankAddress,
            },
          },
        },
      })
      return
    }
  } catch {
    // swallow errors
  }
  setTimeout(() => refreshBusinessBankAccountData(variables, maxRetryCount - 1), 300)
}

export const refreshBusinessAccountInfo = async ({
  businessId,
  feeAmount,
}: {
  businessId: string
  feeAmount: number
}) => {
  await refreshBusinessAccountData({ variables: { businessId }, feeAmount })
}

const refreshBusinessAccountData = async ({
  variables,
  feeAmount,
  maxRetryCount = 100,
}: {
  variables: BusinessInfoQueryVariables
  feeAmount: number
  maxRetryCount?: number
}) => {
  if (!maxRetryCount) {
    return
  }

  const queryOptions = { query: BUSINESS_ACCOUNTS_AVAILABLE_BALANCE_QUERY, variables }
  const cacheResult = client.readQuery<BusinessInfoQuery, BusinessInfoQueryVariables>(queryOptions)

  if (!cacheResult) {
    return
  } else {
    try {
      const networkResult = await client.query<BusinessInfoQuery, BusinessInfoQueryVariables>({
        ...queryOptions,
        fetchPolicy: "network-only",
      })

      const oldBal = cacheResult?.business?.bankAccount?.availableBalance || 0
      const newBal = networkResult?.data?.business?.bankAccount?.availableBalance || 0
      if (cacheResult?.business && networkResult.data?.business && oldBal >= newBal + feeAmount) {
        client.writeQuery<BusinessInfoQuery, BusinessInfoQueryVariables>({
          ...queryOptions,
          data: {
            ...cacheResult,
            business: {
              __typename: "Business",
              id: cacheResult.business.id,
              name: cacheResult.business.name,
              bankAccount: {
                __typename: "BankAccount",
                id: cacheResult.business.bankAccount?.id || "",
                availableBalance: networkResult.data.business.bankAccount?.availableBalance || null,
                subAccounts: networkResult.data.business.bankAccount?.subAccounts || [],
              },
            },
          },
        })
        return
      }
    } catch {
      // swallow errors
    }
  }
  setTimeout(
    () => refreshBusinessAccountData({ variables, feeAmount, maxRetryCount: maxRetryCount - 1 }),
    300,
  )
}
