import React from "react"
import { Box, Image, SxStyleProp } from "rebass"
import { FlexColumnLayout, FlexRowLayout } from "@northone/ui"
import { theme } from "@layouts/theme"

type BannerProps = {
  children?: React.ReactNode
  sideBackgroundColor: string
  imageSrc?: string
  buttons: JSX.Element[]
  bannerHeight: number
  containerSx?: SxStyleProp
}

const borderRadius = "4px"

export const Banner = ({ imageSrc, buttons, bannerHeight, children, containerSx }: BannerProps) => (
  <FlexRowLayout
    sx={{
      borderRadius: borderRadius,
      boxShadow: theme.boxShadow,
      height: bannerHeight,
      overflow: "hidden",
      ...containerSx,
    }}
  >
    {imageSrc && (
      <Image
        src={imageSrc}
        sx={{
          maxHeight: "100%",
          maxWidth: "unset",
          minWidth: "unset",
          width: "auto",
        }}
      />
    )}
    <FlexColumnLayout
      sx={{
        backgroundColor: theme.colors.ui7,
        paddingY: "16px",
        paddingX: "30px",
        borderTopRightRadius: borderRadius,
        borderBottomRightRadius: borderRadius,
      }}
    >
      {children}
      <FlexRowLayout>
        {buttons &&
          buttons.map((button, index) => {
            const lastIndex = index === buttons.length - 1
            return (
              <Box key={index} sx={{ marginRight: !lastIndex ? "8px" : undefined }}>
                {button}
              </Box>
            )
          })}
      </FlexRowLayout>
    </FlexColumnLayout>
  </FlexRowLayout>
)
