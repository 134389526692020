import { selectActiveBusinessID } from "@core/active-business/redux/selectors"
import React from "react"
import { Analytics } from "@core/analytics/actions"
import { events } from "@core/analytics/events"
import { useAppSelector } from "@core/redux/utils"
import { NavSideBarSection } from "@features/sidebar/shared/side-bar-section"
import { isStandardPlan } from "@features/subscriptions/utils"
import { useSubscriptionQuery } from "@generated/graphql"
import { RoutesEnum } from "@routers/types"
import { useTranslation } from "react-i18next"

export const SettingsPlansSection = () => {
  const { t } = useTranslation()
  const businessId = useAppSelector(selectActiveBusinessID)
  const { data, loading } = useSubscriptionQuery({
    variables: { businessId },
  })

  if (loading) return null

  const links = [
    {
      text:
        data?.subscription && !isStandardPlan(data.subscription.plan)
          ? t("settings.pages.plans.managePlan")
          : t("settings.pages.plans.upgradePlan"),
      to: RoutesEnum.PLANS,
    },
  ]

  return (
    <NavSideBarSection
      links={links}
      eventPrefix="Settings"
      sectionLabel={t("settings.sidebarLabels.plans")}
      onClick={() => Analytics.track(events.subscriptions.settingsSidebarClick)}
    />
  )
}
