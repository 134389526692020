import React, { useRef, useEffect } from "react"
import { Box } from "rebass"
import { ListLabels } from "@components/primitive/list-label/list-labels"
import { useDispatch } from "react-redux"
import { TransactionListItem } from "@components/extended/list-items/transaction/transaction-list-item"
import { ListLoadingShimmer } from "./loading-shimmer"
import { ErrorScreen } from "../error-screen/error-screen"
import { LoadingIcon } from "@components/primitive/loading-icon/loading-icon"
import { Text } from "@components/primitive/text/text"
import { theme } from "@layouts/theme"
import { FlexColumnLayout } from "@layouts/flex"
import { useTranslation } from "react-i18next"
import { useTransactionsListQuery } from "@generated/graphql"
import {
  useFetchMoreTransactions,
  useTransactionListFilters,
  useTransactionListScrollToTop,
} from "./hooks"
import { dashboardActions } from "@features/dashboard/redux/actions"
import { Analytics } from "@core/analytics/actions"
import { useAppSelector } from "@core/redux/utils"
import { events } from "@core/analytics/events"
import { motion } from "framer-motion"
import { BancorpUpgradeDepositTransactionListItem } from "@components/extended/list-items/transaction/bancorp-upgrade-deposit-list-item"
import { getTransactionTypeDetails } from "@utils/mapping/transactions.mapping"
import { isSearching, TRANSACTION_LIST_LABELS } from "./utils"
import { TransactionListNoSearchResult } from "./transaction-list-no-search-result"
import { TransactionListEmpty } from "./transaction-list-empty"

interface ITransactionListProps {
  activeTransactionId: string
  subAccountId?: string
  transactionOnClick?: (transactionId: string) => void
}

export const TransactionList: React.FC<ITransactionListProps> = ({
  activeTransactionId,
  subAccountId,
  transactionOnClick,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { filters } = useTransactionListFilters({ subAccountId })
  const ref = useRef<HTMLDivElement>(null)
  const businessId = useAppSelector((state) => state.activeBusiness.businessId)

  const { data, loading, error, refetch, fetchMore } = useTransactionsListQuery({
    variables: Object.keys(filters).length ? { filters, businessId } : { businessId },
    notifyOnNetworkStatusChange: true,
  })
  const fetchMoreTransactionsOptions = { businessId, data, fetchMore, filters }
  const { fetchMoreTransactions } = useFetchMoreTransactions(fetchMoreTransactionsOptions)

  const { scrollToTop, scrollContainer } = useTransactionListScrollToTop()

  useEffect(() => {
    return () => {
      dispatch(dashboardActions.clearState())
    }
  }, [dispatch])

  useEffect(() => {
    if (data?.transactionsList?.pagination?.cursor) {
      fetchMoreTransactions()
    }
  }, [data])

  useEffect(() => {
    const handleScroll = () => {
      const listBottom = ref.current?.getBoundingClientRect().bottom
      const containerHeight = scrollContainer?.clientHeight
      const isContainerHeightWithin60Percent =
        listBottom && containerHeight && listBottom * 0.6 < containerHeight
      const hasMoreTransactions = data?.transactionsList?.pagination?.cursor

      const shouldFetchMoreTransactions = isContainerHeightWithin60Percent || hasMoreTransactions

      if (shouldFetchMoreTransactions) {
        fetchMoreTransactions()
      }
    }
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll)
    }
    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", handleScroll)
      }
    }
  }, [scrollContainer])

  if (error) {
    return <ErrorScreen size="large" retry={() => refetch()} />
  }

  if (loading) {
    return (
      <>
        <ListLabels labels={TRANSACTION_LIST_LABELS} />
        {isSearching(filters) ? (
          <Box sx={{ marginTop: 90 }}>
            <ListLoadingShimmer listCount={5} />
          </Box>
        ) : (
          <ListLoadingShimmer listCount={10} />
        )}
      </>
    )
  }

  const hasNoSearchResult =
    isSearching(filters) && data?.transactionsList?.transactionListItems.length === 0

  if (hasNoSearchResult) {
    return <TransactionListNoSearchResult filters={filters} />
  }

  const transactionListCount = data?.transactionsList?.transactionListItems?.length
  const isTransactionListEmpty = transactionListCount === 0 && !subAccountId

  if (isTransactionListEmpty) {
    return <TransactionListEmpty />
  }

  return (
    <Box ref={ref}>
      <motion.div layout="position">
        <ListLabels labels={TRANSACTION_LIST_LABELS} />
        {data?.transactionsList?.transactionListItems.map((transaction) => {
          const isActive = activeTransactionId === transaction.id
          return transaction.isBancorpUpgradeDeposit ? (
            <BancorpUpgradeDepositTransactionListItem
              amount={transaction.amount}
              date={transaction.createdAt || ""}
            />
          ) : (
            <TransactionListItem
              key={transaction.id}
              onClick={
                transactionOnClick
                  ? () => transactionOnClick(isActive ? "" : transaction.id)
                  : undefined
              }
              isActive={isActive}
              amount={transaction.amount}
              counterpartyName={transaction.description || ""}
              counterpartyLogoSrc={
                getTransactionTypeDetails(transaction.transactionType).transactionFeedIcon
              }
              date={transaction.createdAt || ""}
              type={transaction.transactionType}
              returnedBy={transaction.returnedBy || ""}
              status={transaction.status}
            />
          )
        })}
        {data && data?.transactionsList?.pagination?.cursor && (
          <Box sx={{ marginY: 40 }}>
            <LoadingIcon dimension={50} />
          </Box>
        )}
        {data &&
        !subAccountId &&
        !isSearching(filters) &&
        data.transactionsList?.transactionListItems.length &&
        data.transactionsList?.transactionListItems.length > 10 &&
        !data?.transactionsList?.pagination?.cursor ? (
          <FlexColumnLayout sx={{ marginY: 40, justifyContent: "center", alignItems: "center" }}>
            <Text sx={{ textAlign: "center", whiteSpace: "nowrap" }} tag="h3Serif">
              {t("dashboard.transactionsEnd.title")}
            </Text>
            <Text tag="body" sx={{ marginTop: 20 }} textColor={theme.colors.ui2}>
              <span
                onClick={() => {
                  scrollToTop()
                  Analytics.track(events.searchAndFilter.transactionsEnd)
                }}
                style={{ cursor: "pointer", textDecorationLine: "underline" }}
              >
                {t("dashboard.transactionsEnd.searchOrFilter")}
              </span>
              {t("dashboard.transactionsEnd.subtitle")}
            </Text>
          </FlexColumnLayout>
        ) : null}
      </motion.div>
    </Box>
  )
}
