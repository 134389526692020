import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { Box } from "rebass"
import { DateTime } from "luxon"
import { useAppSelector } from "@core/redux/utils"
import { Dropdown } from "@components/composite/dropdown/dropdown"
import { DatepickerDropdown } from "@components/composite/datepicker-dropdown/datepicker-dropdown"
import { dashboardActions } from "@features/dashboard/redux/actions"
import { TDateFilterType } from "@features/dashboard/redux/reducer"
import {
  selectDashboardDateFilterType,
  selectDashboardExactDate,
  selectDashboardStartDate,
  selectDashboardEndDate,
} from "@features/dashboard/redux/selectors"
import { FilterDropdownLabel } from "./panel"

export const FilterDateFields: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const dateFilterType = useAppSelector(selectDashboardDateFilterType)
  const exactDate = useAppSelector(selectDashboardExactDate)
  const startDate = useAppSelector(selectDashboardStartDate)
  const endDate = useAppSelector(selectDashboardEndDate)

  const DATE_FILTER_TYPE_OPTIONS = (
    [
      null,
      "today",
      "yesterday",
      "thisWeek",
      "last30Days",
      "exactDate",
      "customRange",
    ] as TDateFilterType[]
  ).map((type) => ({
    text: t(`dashboard.filters.date.${type || "all"}`),
    value: type,
  }))
  const today = new Date(new Date().setHours(0, 0, 0, 0))
  return (
    <>
      <Dropdown<TDateFilterType | null, typeof DATE_FILTER_TYPE_OPTIONS[number]>
        labelComponent={
          <FilterDropdownLabel>{t("dashboard.filters.date.label")}</FilterDropdownLabel>
        }
        onSelect={(type) => {
          dispatch(dashboardActions.setExactDate(null))
          dispatch(dashboardActions.setEndDate(null))
          dispatch(dashboardActions.setStartDate(null))
          dispatch(dashboardActions.setDateFilterType(type))
        }}
        options={DATE_FILTER_TYPE_OPTIONS}
        placeholder={t("dashboard.filters.date.all")}
        selectedValue={dateFilterType || undefined}
      />
      {dateFilterType === "exactDate" && (
        <DatepickerDropdown
          labelComponent={
            <FilterDropdownLabel>{t("dashboard.filters.date.exactDate")}</FilterDropdownLabel>
          }
          onSelect={(date) => dispatch(dashboardActions.setExactDate(date))}
          selectedDate={exactDate}
          alignment="right"
          maxDate={today}
          minDate={null}
          placeholder={t("dashboard.filters.date.selectDate")}
        />
      )}
      {dateFilterType === "customRange" && (
        <Box>
          <DatepickerDropdown
            labelComponent={
              <FilterDropdownLabel>{t("dashboard.filters.date.customRange")}</FilterDropdownLabel>
            }
            onSelect={(date) => dispatch(dashboardActions.setStartDate(date))}
            selectedDate={startDate}
            alignment="right"
            maxDate={endDate ? DateTime.fromJSDate(endDate).minus({ days: 1 }).toJSDate() : null}
            minDate={null}
            placeholder={t("dashboard.filters.date.startDate")}
            containerSx={{ marginBottom: 12 }}
          />
          <DatepickerDropdown
            onSelect={(date) => dispatch(dashboardActions.setEndDate(date))}
            selectedDate={endDate}
            alignment="right"
            maxDate={today}
            minDate={startDate ? DateTime.fromJSDate(startDate).plus({ days: 1 }).toJSDate() : null}
            placeholder={t("dashboard.filters.date.endDate")}
          />
        </Box>
      )}
    </>
  )
}
