import React from "react"
import { SxStyleProp } from "rebass"
import { AlignedColumnLayout } from "../aligned-column-layout"
import { CounterpartyAvatar } from "../../avatar/counterparty-avatar"
import { Text } from "@components/primitive/text/text"
import { theme } from "@layouts/theme"
import { FlexColumnLayout, FlexRowLayout } from "@layouts/flex"
import { OverflowListItem } from "../overflow/overflow-list-item"

export interface IRecipientListItemProps {
  name: string
  subText: string
  paymentHistory?: string
  isActive?: boolean
  onClick?: () => void
  sx?: SxStyleProp
  disabled?: boolean
}

/**
 * Defines a recipient list item
 */
export const RecipientListItem: React.FC<IRecipientListItemProps> = ({
  name,
  subText,
  paymentHistory,
  onClick,
  isActive,
  sx,
  disabled,
}) => {
  return (
    <OverflowListItem
      onClick={onClick}
      isActive={isActive}
      sx={{ ...sx, alignItems: "space-between" }}
    >
      <FlexRowLayout sx={{ alignItems: "center", width: "100%" }}>
        <CounterpartyAvatar
          counterpartyName={name}
          colorThemes={
            disabled
              ? [{ backgroundColor: theme.colors.ui5, textColor: theme.colors.ui3 }]
              : undefined
          }
        />
        <FlexColumnLayout sx={{ marginLeft: theme.spacing.listItemPadding }}>
          <Text
            tag={isActive ? "body-small-bold" : "body-small"}
            textColor={disabled ? theme.colors.ui2 : undefined}
          >
            {name}
          </Text>
          <Text tag={"body-x-small"} textColor={theme.colors.ui2}>
            {subText}
          </Text>
        </FlexColumnLayout>
      </FlexRowLayout>
      <AlignedColumnLayout widthRadio={0.4} alignment={"flex-end"}>
        <Text tag={"body-small"} textColor={theme.colors.ui2}>
          {paymentHistory}
        </Text>
      </AlignedColumnLayout>
    </OverflowListItem>
  )
}
