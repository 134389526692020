import i18n from "../../i18n/i18n"
import { ScheduleFrequencyEnum } from "../../generated/graphql"
import moment from "moment"

export const scheduleFrequencyToI18n: { [key in ScheduleFrequencyEnum]: string } = {
  [ScheduleFrequencyEnum.EVERY_DAY]: i18n.t("schedules.everyDay"),
  [ScheduleFrequencyEnum.EVERY_MONTH]: i18n.t("schedules.everyMonth"),
  [ScheduleFrequencyEnum.EVERY_TWO_WEEKS]: i18n.t("schedules.everyTwoWeeks"),
  [ScheduleFrequencyEnum.EVERY_WEEK]: i18n.t("schedules.everyWeek"),
  [ScheduleFrequencyEnum.ONCE]: i18n.t("schedules.once"),
}

export const scheduleFrequencyAdjectiveToI18n: { [key in ScheduleFrequencyEnum]: string } = {
  [ScheduleFrequencyEnum.EVERY_DAY]: i18n.t("schedules.daily"),
  [ScheduleFrequencyEnum.EVERY_MONTH]: i18n.t("schedules.monthly"),
  [ScheduleFrequencyEnum.EVERY_TWO_WEEKS]: i18n.t("schedules.biweekly"),
  [ScheduleFrequencyEnum.EVERY_WEEK]: i18n.t("schedules.weekly"),
  [ScheduleFrequencyEnum.ONCE]: i18n.t("schedules.once"),
}

export const getNextScheduledDate = (frequency: ScheduleFrequencyEnum) => {
  switch (frequency) {
    case ScheduleFrequencyEnum.EVERY_DAY:
      return moment().add(1, "day")
    case ScheduleFrequencyEnum.EVERY_MONTH:
      return moment().add(1, "month")
    case ScheduleFrequencyEnum.EVERY_TWO_WEEKS:
      return moment().add(2, "weeks")
    case ScheduleFrequencyEnum.EVERY_WEEK:
      return moment().add(1, "week")
    default:
      return moment()
  }
}
