import { Badge } from "@components/primitive/badge/badge"
import { useShowAdditionalOwnerSettingBadge } from "@core/announce/additionalOwnerSetting"
import React from "react"
import { INavBarLinkProps } from "./nav-bar-link"

interface IAdditionalOwnerSettingBadge {
  navBarLinkId: INavBarLinkProps["id"]
}
export const AdditionalOwnerSettingBadge = ({ navBarLinkId }: IAdditionalOwnerSettingBadge) => {
  const canSeeSettingBadge = useShowAdditionalOwnerSettingBadge()
  if (!navBarLinkId || !canSeeSettingBadge) return null
  return navBarLinkId === "main-settings-page" ? <Badge xOffset={55} yOffset={3} /> : null
}
