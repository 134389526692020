export const MAX_INFO_CHARACTER_LENGTH = 300
export const MAX_RECIPIENT_NAME_LENGTH = 32
export const HIGHEST_ZINDEX_POSSIBLE = 2147483647

export const ATM_DEPOSIT_CASH_URL = "https://secure.attheregister.com/locations"
export const NORTHONE_TERMS_OF_SERVICE_URL = "https://www.northone.com/terms-of-service"
export const NORTHONE_PRIVACY_POLICY_URL = "https://www.northone.com/privacy-policy"
export const MULTI_OWNER_PILOT_CUSTOMER_FAQ =
  "https://help.northone.com/en/articles/6968562-team-banking-customer-faq"
export const INVOICE_PAYMENTS_FAQ =
  "http://help.northone.com/en/articles/7507055-how-do-i-make-invoice-payments-with-northone"
export const REVENUE_HUB_FAQ = "http://help.northone.com/en/articles/8012154-revenue-hub-faq"
export const RUTTER_AGREEMENT_URL =
  "https://assets.website-files.com/61fa2c9e384336c001748969/628541b11ce364158a0f3e64_Rutter%20Privacy%20Notice.docx%20(1).pdf"
export const NORTHONE_ACCOUNT_UPGRADE_HELP_URL =
  "http://help.northone.com/en/articles/4745430-account-upgrade-for-current-northone-customers"
export const NORTHONE_SHARE_AND_EARN_TERMS_URL =
  "https://help.northone.com/en/articles/4228585-northone-referral-program-terms-conditions"
// Integrations
export const SQUARE_INTEGRATION_URL =
  "https://squareup.com/help/us/en/article/3896-link-and-edit-your-bank-account"
export const PAYPAL_INTEGRATION_URL =
  "https://www.paypal.com/us/smarthelp/article/how-do-i-link-a-bank-account-to-my-paypal-account-faq686"
export const SHOPIFY_INTEGRATION_URL =
  "https://help.shopify.com/en/manual/payments/shopify-payments/configuring-shopify-payments#edit-bank-account-information"
export const AIRBNB_INTEGRATION_URL =
  "https://www.airbnb.ca/help/article/54/how-do-i-edit-or-change-my-payout-method"
export const STRIPE_INTEGRATION_URL = "https://dashboard.stripe.com/settings/payouts"
export const AMAZON_INTEGRATION_URL = "https://pay.amazon.com/help/201212230"
export const ETSY_INTEGRATION_URL =
  "https://help.etsy.com/hc/en-us/articles/115013145627-How-to-Get-Paid-with-Etsy-Payments"
export const LYFT_INTEGRATION_URL =
  "https://help.lyft.com/hc/en-us/articles/115012926307-Set-up-bank-and-tax-info-to-get-paid#bankinfo"
export const VENMO_INTEGRATION_URL =
  "https://help.venmo.com/hc/en-us/articles/209690058-Adding-a-Bank-Account"
export const GUSTO_INTEGRATION_URL =
  "https://support.gusto.com/account-setup-maintenance/enter-account-info/banking/1066221611/Add-bank-account.htm"
export const ALTO_INTEGRATION_URL =
  "https://lifelikeuxhelp.zendesk.com/hc/en-us/articles/115001378945-I-would-like-to-be-paid-by-providing-my-bank-details-on-the-invoice-How-do-I-do-that-"
export const XERO_INTEGRATION_URL = "https://xero-connect.integrations.northone.com/"
export const QUICKBOOKS_INTEGRATION_URL = "https://quickbooks.intuit.com/sign-in/"

export const MULTI_OWNER_PILOT_FAQ_URL =
  "https://help.northone.com/en/articles/6716062-frequently-asked-questions-multi-owner-pilot"

export const DEPOSIT_ACCOUNT_AGREEMENT_LINK = "/settings/legal/account_agreement"
export const WIRE_TRANSFER_AGREEMENT_LINK = "/settings/legal/wire_transfer_agreement"

export const BULLET_SYMBOL = "\u2022"

export const BANCORP_NAME = "The Bancorp Bank, N.A., Member FDIC"

export const americanStates = [
  { text: "AK", value: "AK" },
  { text: "AL", value: "AL" },
  { text: "AR", value: "AR" },
  { text: "AZ", value: "AZ" },
  { text: "CA", value: "CA" },
  { text: "CO", value: "CO" },
  { text: "CT", value: "CT" },
  { text: "DC", value: "DC" },
  { text: "DE", value: "DE" },
  { text: "FL", value: "FL" },
  { text: "GA", value: "GA" },
  { text: "HI", value: "HI" },
  { text: "IA", value: "IA" },
  { text: "ID", value: "ID" },
  { text: "IL", value: "IL" },
  { text: "IN", value: "IN" },
  { text: "KS", value: "KS" },
  { text: "KY", value: "KY" },
  { text: "LA", value: "LA" },
  { text: "MA", value: "MA" },
  { text: "MD", value: "MD" },
  { text: "ME", value: "ME" },
  { text: "MI", value: "MI" },
  { text: "MN", value: "MN" },
  { text: "MO", value: "MO" },
  { text: "MS", value: "MS" },
  { text: "MT", value: "MT" },
  { text: "NC", value: "NC" },
  { text: "ND", value: "ND" },
  { text: "NE", value: "NE" },
  { text: "NH", value: "NH" },
  { text: "NJ", value: "NJ" },
  { text: "NM", value: "NM" },
  { text: "NV", value: "NV" },
  { text: "NY", value: "NY" },
  { text: "OH", value: "OH" },
  { text: "OK", value: "OK" },
  { text: "OR", value: "OR" },
  { text: "PA", value: "PA" },
  { text: "RI", value: "RI" },
  { text: "SC", value: "SC" },
  { text: "SD", value: "SD" },
  { text: "TN", value: "TN" },
  { text: "TX", value: "TX" },
  { text: "UT", value: "UT" },
  { text: "VA", value: "VA" },
  { text: "VT", value: "VT" },
  { text: "WA", value: "WA" },
  { text: "WI", value: "WI" },
  { text: "WV", value: "WV" },
  { text: "WY", value: "WY" },
]

export const STANDARD_ACH_FEE = 0
export const MINIMUM_TRANSACTION_AMOUNT = 1
