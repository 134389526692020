import { Text, theme } from "@northone/ui"
import { motion } from "framer-motion"
import React, { PropsWithChildren, useState } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { Image, SxStyleProp } from "rebass"
import { images } from "../assets/images/images"
import { RoutesEnum } from "../routers/types"
import { FlexColumnLayout } from "./flex"

interface LogoProps {
  sx?: SxStyleProp
  legalDisclaimerText?: string
}

export const Logo = ({ sx, children, legalDisclaimerText }: PropsWithChildren<LogoProps>) => {
  const [isLogoHovering, setIsLogoHovering] = useState(false)
  const history = useHistory()

  const { t } = useTranslation()

  return (
    <FlexColumnLayout
      onClick={() => history.push(`${RoutesEnum.DASHBOARD}`)}
      onMouseEnter={() => setIsLogoHovering(true)}
      onMouseLeave={() => setIsLogoHovering(false)}
      sx={{
        alignSelf: "start",
        cursor: "pointer",
        marginTop: "12px",
        ...sx,
      }}
    >
      <motion.div
        animate={{
          opacity: isLogoHovering ? 1 : 0.5,
          transition: {
            duration: 0.25,
          },
        }}
      >
        <Image src={images.layoutLogo} />
      </motion.div>
      {children}
      <Text tag={"body-x-small"} textColor={theme.colors.ui2}>
        {legalDisclaimerText ? legalDisclaimerText : t("dashboard.legal")}
      </Text>
    </FlexColumnLayout>
  )
}
