import React from "react"
import { Box, SxStyleProp } from "rebass"

interface PillProps {
  sx?: SxStyleProp
  children?: React.ReactNode
}

export const Pill = ({ sx, children }: PillProps) => {
  return (
    <Box
      sx={{
        //Not using FlexRowLayout as we need container to shrink to wrap content
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        height: 22,
        boxSizing: "border-box",
        borderRadius: 30,
        paddingX: 12,
        ...sx,
      }}
    >
      {children}
    </Box>
  )
}
