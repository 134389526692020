import React from "react"
import { Image } from "rebass"
import { useTranslation } from "react-i18next"

import { Button, ButtonTypeEnum } from "@components/primitive/button/button"
import { LoadingIcon } from "@components/primitive/loading-icon/loading-icon"
import { images } from "@assets/images/images"
import { InvoiceAccountsPayable, useGetInvoiceAttachmentUrlLazyQuery } from "@generated/graphql"
import { theme } from "@layouts/theme"
import { useAppSelector } from "@core/redux/utils"
import { selectActiveBusinessID } from "@core/active-business/redux/selectors"
import { TInvoiceAccountsPayable } from "./redux/reducer"
import { FlexRowLayout } from "@northone/ui"

interface IInvoiceDownloadButton {
  invoice: TInvoiceAccountsPayable | undefined
  withLabel?: boolean
}

const BUTTON_TEXT_STYLES = {
  fontSize: "10px",
  letterSpacing: "1px",
  whiteSpace: "nowrap",
  textTransform: "uppercase",
}

export function InvoiceDownloadButton({ invoice, withLabel = false }: IInvoiceDownloadButton) {
  const { t } = useTranslation()
  const businessId = useAppSelector(selectActiveBusinessID)

  const [getInvoiceAttachmentUrl, { loading: isGetInvoiceAttachmentUrlLoading }] =
    useGetInvoiceAttachmentUrlLazyQuery({
      fetchPolicy: "network-only",
    })

  if (!invoice) return null

  const handleInvoiceDownloadClick = async (
    attachmentId: InvoiceAccountsPayable["attachmentId"],
  ) => {
    if (!attachmentId) return

    const { data: invoiceAttachmentUrl } = await getInvoiceAttachmentUrl({
      variables: { businessId, attachmentId },
    })

    const file = invoiceAttachmentUrl?.getInvoiceAttachment?.file
    const url = file?.url
    if (!url) {
      return
    }

    const fileExtension = file.contentType.split("/")[1]
    const fileName = `invoice.${fileExtension}`
    const response = await fetch(url)
    const blob = await response.blob()
    const link = document.createElement("a")
    link.href = URL.createObjectURL(blob)
    link.download = fileName
    link.click()
    link.remove()
  }

  return (
    <Button
      type={ButtonTypeEnum.TERTIARY}
      iconSx={{ width: "16px", height: "16px" }}
      textSx={{
        color: theme.colors.ui2,
        ...BUTTON_TEXT_STYLES,
      }}
      sx={{ padding: 2, width: withLabel ? "168px" : "auto" }}
      onClick={() => handleInvoiceDownloadClick(invoice.attachmentId)}
    >
      <FlexRowLayout alignItems={"flex-end"} sx={{ gap: 1 }}>
        {isGetInvoiceAttachmentUrlLoading ? (
          <LoadingIcon />
        ) : (
          <>
            <Image tabIndex={0} src={images.icons.download} />
            {withLabel ? t("invoicePayments.invoicePanel.downloadInvoice") : null}
          </>
        )}
      </FlexRowLayout>
    </Button>
  )
}
