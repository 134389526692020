import React, { useState } from "react"
import accounting from "accounting"
import { useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next"

import {
  ErrorCodeEnum,
  SubscriptionPlanPricePeriod,
  SubscriptionPlansDataQuery,
  TransactionUpsellContentQuery,
  useSubscriptionPlansDataQuery,
  useSubscriptionUpgradeMutation,
} from "@generated/graphql"

import { LoadingIcon } from "@northone/ui"
import { ErrorScreen } from "@components/composite/error-screen/error-screen"
import { ChangePlanDetailsLayout } from "../shared/components/change-plan-details-layout"
import { getOneMonthFromTodayFormattedDate } from "@utils/dates"
import { BillingDetailsBox } from "../shared/components/billing-details-box"
import {
  selectActiveBusinessID,
  selectDefaultAccountID,
} from "@core/active-business/redux/selectors"
import { useAppSelector } from "@core/redux/utils"
import { v4 as uuid } from "uuid"
import { NoticeBox } from "@components/primitive/notice-box/notice-box"
import { formatGQLPlanData, formatMonthlyPrice, getPlanMonthlyPrice } from "../utils"
import { events } from "@core/analytics/events"
import { Analytics } from "@core/analytics/actions"
import { hasGQLErrorType } from "@utils/errors"
import { SUBSCRIPTION_DATA, TRANSACTION_UPSELL } from "../operations.gql"
import { ButtonTypeEnum } from "@components/primitive/button/button"
import { PlanType } from "../types"
import { PAYMENT_METHOD_FEES } from "@features/move-money/shared/operations.gql"

export const UpgradePlanDetails = ({
  navigateNext,
  navigateBack,
  upsellPlanName,
}: {
  navigateNext: () => void
  navigateBack: () => void
  upsellPlanName?: PlanType
}) => {
  const { t } = useTranslation()
  const businessId = useAppSelector(selectActiveBusinessID)
  const accountId = useAppSelector(selectDefaultAccountID)
  const [errorMessage, setErrorMessage] = useState<string>()

  const location = useLocation()

  const { data, error, loading } = useSubscriptionPlansDataQuery({
    variables: { businessId },
  })

  const isUpsell = Boolean(upsellPlanName)
  const upgradePlanName = upsellPlanName || location.pathname.split("/").pop()
  const planData = data?.subscriptionPlans?.plans?.find((plan) => plan.name === upgradePlanName)
  const planDetails = formatGQLPlanData(planData)
  const monthlyPrice = getPlanMonthlyPrice(planDetails)
  const upgradeCTA = planDetails?.upgradeButtonText || t("buttons.upgrade")
  const formattedMonthlyPrice = accounting.formatMoney(monthlyPrice || 0, undefined, 0)
  const planName = planDetails?.title

  const billingDetailsItems = [
    {
      label: t("subscriptions.billingDetails.labels.monthlyFee"),
      value: formatMonthlyPrice(monthlyPrice),
    },
    {
      label: t("subscriptions.billingDetails.labels.nextBillingDate"),
      value: getOneMonthFromTodayFormattedDate(),
    },
    {
      label: t("subscriptions.billingDetails.labels.fromAccount"),
      value: t("subscriptions.billingDetails.depositAccount"),
    },
  ]

  const [upgradeSubscription, { loading: mutationLoading }] = useSubscriptionUpgradeMutation({
    variables: {
      input: {
        businessId,
        accountId: accountId || "",
        plan: upgradePlanName || "",
        idempotencyKey: uuid(),
        paymentPeriod: SubscriptionPlanPricePeriod.MONTHLY,
      },
    },
    ...(isUpsell
      ? {
          refetchQueries: [
            {
              query: PAYMENT_METHOD_FEES,
              variables: { businessId },
            },
          ],
        }
      : {}),
    update: (cache, { data }) => {
      const subscription = data?.subscriptionUpgrade?.subscription
      if (subscription) {
        cache.modify({
          fields: {
            subscription: () =>
              cache.writeQuery<SubscriptionPlansDataQuery>({
                query: SUBSCRIPTION_DATA,
                data: { subscription },
              }),
            transactionUpsellContentPerType: () =>
              cache.writeQuery<TransactionUpsellContentQuery>({
                query: TRANSACTION_UPSELL,
                data: { transactionUpsellContentPerType: { upsellPlan: null } },
              }),
          },
        })
      }
    },
    onError: (error) => {
      setErrorMessage(
        hasGQLErrorType(error, ErrorCodeEnum.SUBSCRIPTION_UPGRADE_FAILED_NSF)
          ? t("subscriptions.upgrade.confirmDetails.error.nsf", {
              monthlyPrice: formattedMonthlyPrice,
            })
          : t("subscriptions.upgrade.confirmDetails.error.generic"),
      )
    },
    onCompleted: () => {
      Analytics.track(
        upsellPlanName
          ? events.subscriptions.upsellConfirmClick
          : events.subscriptions.confirmSubscriptionUpgrade,
      )
      navigateNext()
    },
  })

  if (loading) {
    return <LoadingIcon />
  }

  if (!planDetails || typeof monthlyPrice !== "number" || !accountId || !upgradePlanName || error) {
    return <ErrorScreen size={"large"} sx={{ justifyContent: "center", alignItems: "center" }} />
  }

  return (
    <ChangePlanDetailsLayout
      heading={t("subscriptions.upgrade.confirmDetails.heading", { planName })}
      subtitle={t("subscriptions.upgrade.confirmDetails.subtitle")}
      buttons={[
        {
          type: ButtonTypeEnum.TERTIARY,
          onClick: navigateBack,
          children: t("buttons.goBack"),
        },
        {
          type: ButtonTypeEnum.PRIMARY_BLACK,
          onClick: () => {
            setErrorMessage(undefined)
            upgradeSubscription()
          },
          isLoading: mutationLoading,
          children: upgradeCTA,
        },
      ]}
      terms={t("subscriptions.upgrade.confirmDetails.terms", {
        monthlyPrice: formatMonthlyPrice(monthlyPrice),
        upgradeCTA,
      })}
    >
      {errorMessage && <NoticeBox level="error" text={errorMessage} containerSx={{ mb: 0 }} />}
      <BillingDetailsBox items={billingDetailsItems} />
    </ChangePlanDetailsLayout>
  )
}
