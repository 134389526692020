import { createAction, TActionsUnion } from "@core/redux/utils"
import { ListInvoiceAccountsPayableQueryVariables } from "@generated/graphql"
import {
  IInvoiceDetailsState,
  IInvoicePaymentsState,
  IPaymentDetailsState,
  TInvoiceAccountsPayable,
} from "./reducer"
import { TInvoicePaymentsModalScreenName } from "../invoice-modal/invoice-payments-modal.router"

export enum InvoicePaymentsActionsEnum {
  UPDATE_INVOICE_QUERY_VARIABLES = "UPDATE_INVOICE_QUERY_VARIABLES",
  SET_ACTIVE_INVOICE = "SET_ACTIVE_INVOICE",
  SET_INVOICE_DETAILS = "SET_INVOICE_DETAILS",
  SET_INVOICE_DETAILS_CHANGED = "SET_INVOICE_DETAILS_CHANGED",
  SET_INVOICE_PAYMENT_DETAILS = "SET_INVOICE_PAYMENT_DETAILS",
  SET_INVOICE_PAYMENT_DETAILS_CHANGED = "SET_INVOICE_PAYMENT_DETAILS_CHANGED",
  NAVIGATE = "NAVIGATE",
  CLEAR_STATE = "CLEAR_STATE",
  SET_INVOICE_DETAILS_EDITING_ENABLED = "SET_INVOICE_DETAILS_EDITING_ENABLED",
  SET_UPLOADED_ATTACHMENT_DETAILS = "SET_UPLOADED_ATTACHMENT_DETAILS",
  CLEAR_UPLOADED_ATTACHMENT_DETAILS = "CLEAR_UPLOADED_ATTACHMENT_DETAILS",
}

export type TInvoicesQueryVariables = Partial<
  Omit<ListInvoiceAccountsPayableQueryVariables, "businessId">
>

export const invoicePaymentsActions = {
  updateInvoicesQueryVariables: (queryVariables: TInvoicesQueryVariables) =>
    createAction(InvoicePaymentsActionsEnum.UPDATE_INVOICE_QUERY_VARIABLES, queryVariables),
  setActiveInvoice: (activeInvoice: TInvoiceAccountsPayable | undefined) =>
    createAction(InvoicePaymentsActionsEnum.SET_ACTIVE_INVOICE, activeInvoice),
  setInvoiceDetails: (payload: IInvoiceDetailsState) =>
    createAction(InvoicePaymentsActionsEnum.SET_INVOICE_DETAILS, payload),
  setHasInvoiceDetailsChanged: (hasInvoiceDetailsChanged: boolean) =>
    createAction(InvoicePaymentsActionsEnum.SET_INVOICE_DETAILS_CHANGED, hasInvoiceDetailsChanged),
  setInvoicePaymentDetails: (payload: IPaymentDetailsState) =>
    createAction(InvoicePaymentsActionsEnum.SET_INVOICE_PAYMENT_DETAILS, payload),
  setHasInvoicePaymentDetailsChanged: (hasInvoicePaymentDetailsChanged: boolean) =>
    createAction(
      InvoicePaymentsActionsEnum.SET_INVOICE_PAYMENT_DETAILS_CHANGED,
      hasInvoicePaymentDetailsChanged,
    ),
  navigate: (route: TInvoicePaymentsModalScreenName) =>
    createAction(InvoicePaymentsActionsEnum.NAVIGATE, route),
  clearState: () => createAction(InvoicePaymentsActionsEnum.CLEAR_STATE),
  setInvoiceDetailsEditingEnabled: (isEditingEnabled: boolean) =>
    createAction(InvoicePaymentsActionsEnum.SET_INVOICE_DETAILS_EDITING_ENABLED, isEditingEnabled),
  setUploadedAttachmentDetails: (attachmentDetails: IInvoicePaymentsState["uploadedAttachment"]) =>
    createAction(InvoicePaymentsActionsEnum.SET_UPLOADED_ATTACHMENT_DETAILS, attachmentDetails),
  clearUploadedAttachmentDetails: () =>
    createAction(InvoicePaymentsActionsEnum.CLEAR_UPLOADED_ATTACHMENT_DETAILS),
}

export type TInvoicePaymentsActions = TActionsUnion<typeof invoicePaymentsActions>
