import React from "react"
import { Box, SxStyleProp } from "rebass"
import { Label as RLabel } from "@rebass/forms"
import { theme } from "@layouts/theme"
import { Text } from "../text/text"

export interface IFieldInputProps {
  label?: string
  disabled?: boolean
  isFocused?: boolean
  isHighlighted?: boolean
  subTextContainerStyling?: SxStyleProp
  errorMessage?: string
  subText?: string
  children?: React.ReactElement | React.ReactElement[]
  sx?: SxStyleProp
  backgroundColor?: string
  containerSx?: SxStyleProp
  labelComponent?: React.ReactElement
  subTextSx?: SxStyleProp
}

const BORDER_STYLE_AND_WIDTH = "solid 1px"

/**
 * Defines a primitive field component. Must be extended to implement a field type.
 */
export const Field: React.FC<IFieldInputProps> = ({
  label,
  disabled,
  isFocused,
  isHighlighted,
  errorMessage,
  subText,
  subTextContainerStyling,
  children,
  sx,
  containerSx,
  backgroundColor = theme.colors.gold10,
  labelComponent,
  subTextSx,
}) => {
  const hasError = Boolean(errorMessage)
  const getBorder = () => {
    if (disabled) {
      return {
        border: `${BORDER_STYLE_AND_WIDTH} transparent`,
        borderBottom: `${BORDER_STYLE_AND_WIDTH} transparent`,
      }
    }
    if (hasError) {
      return {
        border: `${BORDER_STYLE_AND_WIDTH} ${theme.colors.error100}`,
        borderBottom: `${BORDER_STYLE_AND_WIDTH} ${theme.colors.error100}`,
      }
    }
    if (isHighlighted && isFocused) {
      return {
        border: `${BORDER_STYLE_AND_WIDTH} ${theme.colors.red80}`,
        borderBottom: `${BORDER_STYLE_AND_WIDTH} ${theme.colors.red80}`,
      }
    }
    if (isHighlighted) {
      return {
        border: `${BORDER_STYLE_AND_WIDTH} transparent`,
        borderBottom: `${BORDER_STYLE_AND_WIDTH} ${theme.colors.red80}`,
      }
    }
    if (isFocused) {
      return {
        border: `${BORDER_STYLE_AND_WIDTH} ${theme.colors.ui3}`,
        borderBottom: `${BORDER_STYLE_AND_WIDTH} ${theme.colors.ui3}`,
      }
    }
    return {
      border: `${BORDER_STYLE_AND_WIDTH} transparent`,
      borderBottom: `${BORDER_STYLE_AND_WIDTH} ${theme.colors.ui3}`,
    }
  }
  return (
    <Box
      sx={{
        flexShrink: 0,
        flex: 1,
        color: disabled ? theme.colors.ui3 : theme.colors.ui2,
        ...containerSx,
      }}
    >
      {labelComponent || (
        <RLabel
          sx={{
            ...theme.textStyles.label,
          }}
        >
          {label}
        </RLabel>
      )}
      <Box
        sx={{
          height: 40,
          width: "100%",
          color: theme.colors.ui1,
          backgroundColor: isHighlighted
            ? theme.colors.red10
            : hasError
            ? theme.colors.error10
            : backgroundColor,
          ...theme.textStyles.body,
          ...getBorder(),
          ...sx,
        }}
      >
        {children}
      </Box>
      {(subText || errorMessage) && (
        <Box sx={{ minHeight: 20, ...subTextContainerStyling }}>
          {subText && (
            <Text sx={subTextSx} tag={"label"}>
              {subText}
            </Text>
          )}
          {errorMessage && (
            <Text
              tag={"label-bold"}
              textColor={theme.colors.error100}
              lineHeight={1.5}
              marginTop={"0.3rem"}
            >
              {errorMessage}
            </Text>
          )}
        </Box>
      )}
    </Box>
  )
}
