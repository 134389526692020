import React from "react"
import { SxStyleProp, Box, Link, Image } from "rebass"
import { images } from "@assets/images/images"
import { theme } from "@layouts/theme"
import { useWindowDimensions } from "@utils/hooks"
import { MOBILE_URL, APP_STORE_LINK, GOOGLE_PLAY_LINK } from "./constants"

interface IDownloadButtonsProps {
  sx?: SxStyleProp
}

export const DownloadButtons: React.FC<IDownloadButtonsProps> = ({ sx }) => {
  const { width: windowWidth } = useWindowDimensions()
  const onMobile = windowWidth < theme.mobileBreakpoint
  return (
    <Box sx={{ height: 45, display: "inline-block", ...sx }}>
      <Link sx={{ marginRight: 12 }} href={onMobile ? MOBILE_URL : APP_STORE_LINK} target="_blank">
        <Image src={images.appStore} sx={{ height: "100%" }} />
      </Link>
      <Link
        sx={{ alignItems: "flex-start" }}
        href={onMobile ? MOBILE_URL : GOOGLE_PLAY_LINK}
        target="_blank"
      >
        <Image src={images.googlePlay} sx={{ height: "100%" }} />
      </Link>
    </Box>
  )
}
