import React from "react"
import { useTranslation } from "react-i18next"
import { Image } from "rebass"

import { FlexColumnLayout, FlexRowLayout } from "@layouts/flex"
import { theme } from "@layouts/theme"
import { dashboard } from "@i18n/locales/en/features/dashboard"
import { Text } from "@components/primitive/text/text"
import { images } from "@assets/images/images"
import { TRANSACTION_LIST_LABELS } from "./utils"
import { ListLabels } from "@components/primitive/list-label/list-labels"
import { UnorderedBulletedList } from "../unordered-bulleted-list/unordered-bulleted-list.component"

export const TransactionListEmpty = () => {
  const { t } = useTranslation()

  return (
    <FlexColumnLayout>
      <ListLabels labels={TRANSACTION_LIST_LABELS} sx={{ marginBottom: 15 }} />
      <FlexRowLayout>
        <FlexColumnLayout style={{ width: "50%" }}>
          <Text
            tag="body"
            textColor={theme.colors.ui2}
            textAlign="left"
            sx={{ paddingTop: 2, fontWeight: 500 }}
          >
            {t("dashboard.emptyState.headline")}
          </Text>
          <Text tag="body" textColor={theme.colors.ui2} textAlign="left" sx={{ paddingTop: 3 }}>
            {t("dashboard.emptyState.subtitle")}
            <Text
              tag="body"
              textColor={theme.colors.ui2}
              onClick={() => window.Intercom("show")}
              sx={{ textDecorationLine: "underline" }}
            >
              {t("dashboard.emptyState.subtitleLink")}
            </Text>
          </Text>
          <Text tag="body" textColor={theme.colors.ui2} textAlign="left" sx={{ paddingTop: 3 }}>
            {t("dashboard.emptyState.listHeadline")}
          </Text>
          <UnorderedBulletedList
            bulletPoints={Object.values(dashboard.emptyState.bullets)}
            textTag={"body"}
            textColor={theme.colors.ui2}
          />
        </FlexColumnLayout>
        <Image src={images.dashboardEmptyState} sx={{ height: 230 }} />
      </FlexRowLayout>
    </FlexColumnLayout>
  )
}
