import React from "react"
import { createRoot } from "react-dom/client"
import * as Sentry from "@sentry/browser"
import { Provider } from "react-redux"
import "@i18n/i18n"
import { PersistGate } from "redux-persist/integration/react"
import { ApolloProvider } from "@apollo/client"
import Segment from "@northone/segment-js"
import { BrowserRouter as Router } from "react-router-dom"

import "./index.css"
import { App } from "./App"
import * as serviceWorker from "./serviceWorker"
import { configureStore } from "@core/redux/store"
import { config, RELEASE } from "@utils/environment"

import { client } from "@core/apollo/apollo-client"
import { GlobalErrorCatch } from "./global-error-catch"

export const { store, persistor } = configureStore()

Sentry.init({
  dsn: config.sentryDsn,
  environment: config.stage,
  ...(RELEASE && { release: RELEASE }),
})

if (config.segmentWriteKey) {
  Segment.load(config.segmentWriteKey)
}

const container = document.getElementById("root")
const root = createRoot(container as Element)

root.render(
  <GlobalErrorCatch>
    <ApolloProvider client={client}>
      <Provider store={store}>
        <PersistGate persistor={persistor} loading={null}>
          <Router>
            <App />
          </Router>
        </PersistGate>
      </Provider>
    </ApolloProvider>
  </GlobalErrorCatch>,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
