import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { InfoBlockModal } from "@components/composite/info-block-modal/info-block-modal"
import { ButtonTypeEnum } from "@components/primitive/button/button"
import { Analytics } from "@core/analytics/actions"
import { theme } from "@northone/ui"
import { LogoutButton } from "@components/composite/nav-bar/logout-button"
import { Box } from "rebass"
import { events } from "@core/analytics/events"

interface IPlaidFundingBaseModalProps {
  title: string
  subtitle: string
  analyticsEvent: string
  imageSrc?: string
}

export const PlaidFundingBaseModal = ({
  title,
  subtitle,
  analyticsEvent,
  imageSrc,
}: IPlaidFundingBaseModalProps) => {
  const { t } = useTranslation()

  useEffect(() => {
    Analytics.track(analyticsEvent)
  }, [])

  const onClick = () => {
    Analytics.track(events.plaidFunding.supportClick)
    window.Intercom("show")
  }

  return (
    <>
      <Box
        sx={{
          position: "absolute",
          cursor: "pointer",
          display: "block",
          top: 30,
          right: 60,
          zIndex: 10,
        }}
      >
        <LogoutButton buttonSx={{ textColor: theme.colors.ui3 }} />
      </Box>
      <InfoBlockModal
        centered
        modalSx={{ width: 456 }}
        isOpen
        subTitleHasPartialBolding
        title={title}
        imageSrc={imageSrc}
        imageSx={{ justifyContent: "center", alignSelf: "center", textAlign: "center" }}
        titleSx={{
          paddingBottom: 10,
        }}
        subtitleSx={{ textAlign: "center" }}
        subtitle={subtitle}
        subtitleTag={"body-large"}
        buttonContainerSx={{ justifyContent: "center", alignSelf: "center", marginTop: 10 }}
        buttons={[
          {
            children: t("buttons.contactCustomerCare"),
            type: ButtonTypeEnum.SECONDARY,
            onClick: onClick,
          },
        ]}
        closeModal={() => null}
      />
    </>
  )
}
