import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { Analytics } from "@core/analytics/actions"
import { events } from "@core/analytics/events"
import { useAppSelector } from "@core/redux/utils"
import { selectActiveBusinessID } from "@core/active-business/redux/selectors"
import { FormBody } from "@components/composite/form-body/form-body"
import { TextInput } from "@components/extended/fields/text-input"
import { accountsActions } from "@features/accounts/redux/actions"
import { selectSubAccounName } from "@features/accounts/redux/selectors"
import { SUB_ACCOUNTS_QUERY } from "@features/accounts/sub-accounts/operations.gql"
import { pollWithMaxRetries } from "@utils/poll-with-max-retries"
import { useSubAccountNamesLazyQuery, useSubAccountCreateMutation } from "@generated/graphql"

export const CreateSubAccountName: React.FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const name = useAppSelector(selectSubAccounName)
  const businessId = useAppSelector(selectActiveBusinessID)
  const [nameExists, setNameExists] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [createSubAccount] = useSubAccountCreateMutation({
    onError: () => {
      setIsLoading(false)
      setHasError(true)
    },
    onCompleted: (data) => {
      pollWithMaxRetries([{ query: SUB_ACCOUNTS_QUERY, variables: { businessId } }])
      dispatch(accountsActions.setCreateSubAccountId(data.subAccountCreate?.subAccount?.id || ""))
      dispatch(accountsActions.createSubAccountNavigateNext("CREATE_SUB_ACCOUNT_INITIAL_TRANSFER"))
    },
  })

  const [subAccountsNamesQuery] = useSubAccountNamesLazyQuery({
    variables: { businessId },
    onCompleted: (data) => {
      if (
        data.business?.bankAccount?.subAccounts.find(
          (subAccount) => subAccount.name.toLowerCase() === name.trim().toLowerCase(),
        )
      ) {
        setIsLoading(false)
        setNameExists(true)
      } else {
        createSubAccount({
          variables: { businessId, name, bankAccountId: data.business?.bankAccount?.id || "" },
        })
      }
    },
    onError: () => {
      setIsLoading(false)
      setHasError(true)
    },
    fetchPolicy: "network-only",
  })

  return (
    <FormBody
      errorMessage={hasError ? t("errors.generic") : ""}
      title={t("accounts.createSubAccount.name.title")}
      subtitle={t("accounts.createSubAccount.name.subtitle")}
      buttons={[
        {
          canContinueWithKey: true,
          children: isLoading
            ? t("accounts.createSubAccount.name.loadingButton")
            : t("buttons.continue"),
          onClick: () => {
            setIsLoading(true)
            subAccountsNamesQuery()
            Analytics.track(events.accounts.create.name)
          },
          isLoading,
          disabled: nameExists || !name,
        },
      ]}
    >
      <TextInput
        errorMessage={nameExists ? t("accounts.createSubAccount.name.exists") : ""}
        value={name}
        onChange={(value) => {
          if (nameExists) {
            setNameExists(false)
          }
          dispatch(accountsActions.setCreateSubAccountName(value))
        }}
        label={t("accounts.createSubAccount.name.label")}
        placeholder={t("accounts.createSubAccount.name.placeholder")}
      />
    </FormBody>
  )
}
