import {
  InvoiceAccountPayablePaymentMethod,
  InvoiceStatus,
  ListInvoiceAccountsPayableQueryVariables,
  SortDirection,
  SortKey,
  GetInvoiceAccountsPayableQuery,
} from "@generated/graphql"
import { TInvoicePaymentsModalScreenName } from "../invoice-modal/invoice-payments-modal.router"

import { InvoicePaymentsActionsEnum, TInvoicePaymentsActions } from "./actions"

export interface IInvoiceDetailsState {
  invoiceId?: string
  vendorName?: string
  contactId?: string
  totalAmount?: number
  invoiceNumber?: string
  dueDate?: string
  invoiceDate?: string
  description?: string
  attachmentId?: string | null
}

export type TAchInvoicePaymentMethodDetails = {
  type: InvoiceAccountPayablePaymentMethod.ACH
  sameDay?: boolean
}

export type TMailedCheckInvoicePaymentMethodDetails = {
  type: InvoiceAccountPayablePaymentMethod.MAILED_CHECK
  memo?: string
}

export type TWireInvoicePaymentMethodDetails = {
  type: InvoiceAccountPayablePaymentMethod.WIRE
  purpose?: string
}

export type TBillPayPaymentMethodDetails = {
  type: InvoiceAccountPayablePaymentMethod.BILL_PAY
}

export type TInvoicePaymentMethodDetails =
  | TAchInvoicePaymentMethodDetails
  | TMailedCheckInvoicePaymentMethodDetails
  | TWireInvoicePaymentMethodDetails
  | TBillPayPaymentMethodDetails

export interface IPaymentDetailsState {
  subAccountId: string
  isPayFullAmount: boolean
  paymentAmount: number
  paymentMethod: "ach" | "ach_sameday" | "wire" | "mailed_check" | "bill_pay"
  paymentDate: string
  paymentMethodDetails: TInvoicePaymentMethodDetails
}

export type TInvoiceAccountsPayable = NonNullable<
  Required<GetInvoiceAccountsPayableQuery["getInvoiceAccountsPayable"]>
>["invoice"]

export type TInvoiceAccountsPayableTransaction = NonNullable<
  NonNullable<Required<TInvoiceAccountsPayable["transactions"]>>[number]
>
export interface IInvoicePaymentsState {
  invoicesQueryVariables: Omit<ListInvoiceAccountsPayableQueryVariables, "businessId">
  activeInvoice: TInvoiceAccountsPayable | undefined
  invoiceDetails: IInvoiceDetailsState | undefined
  hasInvoiceDetailsChanged: boolean
  isInvoiceDetailsEditingEnabled?: boolean
  hasInvoicePaymentDetailsChanged: boolean
  invoicePaymentDetails: IPaymentDetailsState | undefined
  screenHistory: TInvoicePaymentsModalScreenName[]
  uploadedAttachment?: {
    attachmentId?: string
    isUploading?: boolean
  }
}

export const initialInvoicePaymentsState: IInvoicePaymentsState = {
  invoicesQueryVariables: {
    sorting: {
      sortKey: SortKey.CREATEDDATE,
      sortDirection: SortDirection.DESC,
    },
    filters: {
      status: InvoiceStatus.UNPAID,
    },
  },
  activeInvoice: undefined,
  invoiceDetails: undefined,
  hasInvoiceDetailsChanged: false,
  isInvoiceDetailsEditingEnabled: true,
  invoicePaymentDetails: undefined,
  hasInvoicePaymentDetailsChanged: false,
  screenHistory: ["INVOICE_DETAILS"],
  uploadedAttachment: undefined,
}

export const invoicePaymentsReducer = (
  state = initialInvoicePaymentsState,
  action: TInvoicePaymentsActions,
): IInvoicePaymentsState => {
  switch (action.type) {
    case InvoicePaymentsActionsEnum.NAVIGATE:
      return {
        ...state,
        screenHistory: [...state.screenHistory, action.payload],
      }

    case InvoicePaymentsActionsEnum.SET_INVOICE_DETAILS:
      return {
        ...state,
        invoiceDetails: action.payload,
      }

    case InvoicePaymentsActionsEnum.SET_INVOICE_DETAILS_CHANGED:
      return {
        ...state,
        hasInvoiceDetailsChanged: action.payload,
      }

    case InvoicePaymentsActionsEnum.SET_INVOICE_PAYMENT_DETAILS:
      return {
        ...state,
        invoicePaymentDetails: action.payload,
      }

    case InvoicePaymentsActionsEnum.SET_INVOICE_PAYMENT_DETAILS_CHANGED:
      return {
        ...state,
        hasInvoicePaymentDetailsChanged: action.payload,
      }

    case InvoicePaymentsActionsEnum.UPDATE_INVOICE_QUERY_VARIABLES:
      return {
        ...state,
        invoicesQueryVariables: {
          ...state.invoicesQueryVariables,
          ...action.payload,
        },
      }

    case InvoicePaymentsActionsEnum.SET_ACTIVE_INVOICE:
      return {
        ...state,
        activeInvoice: action.payload,
      }

    case InvoicePaymentsActionsEnum.SET_INVOICE_DETAILS_EDITING_ENABLED:
      return {
        ...state,
        isInvoiceDetailsEditingEnabled: action.payload,
      }

    case InvoicePaymentsActionsEnum.SET_UPLOADED_ATTACHMENT_DETAILS:
      return {
        ...state,
        uploadedAttachment: action.payload,
      }

    case InvoicePaymentsActionsEnum.CLEAR_UPLOADED_ATTACHMENT_DETAILS:
      return {
        ...state,
        uploadedAttachment: initialInvoicePaymentsState.uploadedAttachment,
      }

    case InvoicePaymentsActionsEnum.CLEAR_STATE:
      return initialInvoicePaymentsState

    default:
      return state
  }
}
