import React, { useState, useRef } from "react"
import { Text } from "../../primitive/text/text"
import { ICheckboxListProps, CheckboxList } from "../checkbox-list/checkbox-list"
import { WithDropdownMenu } from "@components/primitive/dropdown-menu/dropdown-menu"
import { Field } from "@components/primitive/field/field"
import { images } from "@assets/images/images"
import { theme } from "@layouts/theme"
import { Box, Image } from "rebass"
import { useClickOutside } from "@utils/hooks"
import { FlexRowLayout } from "@layouts/flex"

interface ICheckboxListDropdownProps<T> {
  checkboxListProps: Omit<ICheckboxListProps<T>, "label">
  labelComponent?: React.ReactElement
  label?: string
  placeholder: string
  additionalContent?: React.ReactElement
  cardIdFilterList?: string[]
}

type TCheckboxListDropdown = <T>(
  props: ICheckboxListDropdownProps<T>,
) => React.ReactElement<ICheckboxListDropdownProps<T>>

export const CheckboxListDropdown: TCheckboxListDropdown = <T,>({
  checkboxListProps,
  label,
  labelComponent,
  placeholder,
  additionalContent,
}: ICheckboxListDropdownProps<T>) => {
  const [menuIsVisible, setMenuIsVisible] = useState(false)
  const selectedFields = checkboxListProps.items.filter((item) =>
    checkboxListProps.values.includes(item.value),
  )
  const ref = useRef<HTMLDivElement>(null)
  useClickOutside([ref], () => setMenuIsVisible(false))

  return (
    <Box ref={ref}>
      <WithDropdownMenu
        content={
          <Box
            sx={{
              paddingTop: 24,
              paddingBottom: 20,
              paddingX: 12,
            }}
          >
            <CheckboxList
              checkboxSx={{
                paddingBottom: 0,
                height: 40,
                overflow: "hidden",
                whiteSpace: "nowrap",
                width: "100%",
              }}
              checkboxIconSx={{
                minWidth: "auto",
              }}
              checkboxTextSx={{
                textOverflow: "ellipsis",
                width: "100%",
                overflow: "hidden",
              }}
              {...checkboxListProps}
            />
            {additionalContent}
          </Box>
        }
        visible={menuIsVisible}
      >
        <Field
          sx={{ padding: "10px 8px 10px 12px", cursor: "pointer" }}
          isFocused={menuIsVisible}
          label={label}
          subTextContainerStyling={{ height: 0 }}
          labelComponent={labelComponent}
        >
          <FlexRowLayout onClick={() => setMenuIsVisible(!menuIsVisible)}>
            <Text
              tag="body"
              sx={{
                width: "100%",
                height: "100%",
                outline: "none",
                paddingRight: 30,
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
              textColor={selectedFields.length ? theme.colors.ui1 : theme.colors.ui2}
            >
              {selectedFields.length === 0
                ? placeholder
                : selectedFields.map((field) => field.text).join(", ")}
            </Text>
            <Box sx={{ height: 20 }}>
              <Image src={menuIsVisible ? images.icons.chevronUp : images.icons.chevronDown} />
            </Box>
          </FlexRowLayout>
        </Field>
      </WithDropdownMenu>
    </Box>
  )
}
