import { createSelector } from "reselect"
import { IRootState } from "@core/redux/root-state"

const getAppState = (state: IRootState) => state

export const selectAccounts = createSelector(getAppState, (state: IRootState) => state.accounts)

export const selectActiveBusiness = createSelector(
  getAppState,
  (state: IRootState) => state.activeBusiness,
)

export const selectAddFunds = createSelector(getAppState, (state: IRootState) => state.addFunds)

export const selectDashboard = createSelector(getAppState, (state: IRootState) => state.dashboard)

export const selectMoveMoney = createSelector(getAppState, (state: IRootState) => state.moveMoney)

export const selectUser = createSelector(getAppState, (state: IRootState) => state.user)

export const selectCards = createSelector(getAppState, (state: IRootState) => state.cards)
