import React from "react"

import { Text } from "@components/primitive/text/text"
import { FlexColumnLayout, FlexRowLayout } from "@layouts/flex"
import { Image } from "rebass"
import { theme } from "@layouts/theme"
import { Button, IButtonProps } from "@components/primitive/button/button"
import { FeatureBullets } from "@features/subscriptions/types"
import { PlanBullet } from "@features/subscriptions/shared/components/plan-bullet-icon"

export const UpgradePlanSuccessLayout = ({
  title,
  subtitle,
  imgSrc,
  featureBullets,
  featureBulletsHeading,
  buttons,
}: {
  title: string
  subtitle?: string
  imgSrc: string
  featureBullets: FeatureBullets
  featureBulletsHeading: string
  buttons: IButtonProps[]
}) => {
  return (
    <FlexRowLayout
      sx={{
        marginX: "80px",
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        maxHeight: "640px",
      }}
    >
      <FlexColumnLayout sx={{ maxWidth: "410px", maxHeight: "510px" }}>
        <FlexColumnLayout
          sx={{
            paddingBottom: "24px",
            borderBottom: `1px solid ${theme.colors.ui5}`,
          }}
        >
          <Text tag="h2" sx={{ maxWidth: "337px", marginBottom: "24px" }}>
            {title}
          </Text>
          {subtitle && (
            <Text tag="body" sx={{ color: theme.colors.ui2 }}>
              {subtitle}
            </Text>
          )}
        </FlexColumnLayout>
        <FlexColumnLayout sx={{ marginBottom: "32px", marginTop: "24px" }}>
          <Text tag="body-bold" sx={{ marginBottom: "8px" }}>
            {featureBulletsHeading}
          </Text>
          <FlexColumnLayout sx={{ rowGap: "12px" }}>
            {featureBullets.map((props) => (
              <PlanBullet key={props.text} {...props} />
            ))}
          </FlexColumnLayout>
        </FlexColumnLayout>
        <FlexRowLayout sx={{ columnGap: "16px" }}>
          {buttons.map((props, idx) => (
            <Button key={idx} {...props} />
          ))}
        </FlexRowLayout>
      </FlexColumnLayout>
      <Image src={imgSrc} sx={{ display: "block", width: "auto", objectFit: "contain" }} />
    </FlexRowLayout>
  )
}
