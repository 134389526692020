import React from "react"
import { useTranslation } from "react-i18next"
import { events } from "@core/analytics/events"
import { Analytics } from "@core/analytics/actions"
import { InfoBlockModal } from "@components/composite/info-block-modal/info-block-modal"
import { ButtonTypeEnum } from "@components/primitive/button/button"
import { useIdleLogoutTimer, LogoutTimerHookProps } from "./hooks"

export const IdleTimeoutDialog: React.FC<LogoutTimerHookProps> = (props) => {
  const { t } = useTranslation()
  const { remainingSeconds, showWarning, logout, dismiss } = useIdleLogoutTimer(props)
  const timeoutMinutes = Math.ceil(props.timeout / 1000 / 60)

  return (
    <InfoBlockModal
      title={t("authWrapper.idleTimeoutDialog.title", { remainingSeconds })}
      subtitle={t("authWrapper.idleTimeoutDialog.subtitle", { timeoutMinutes })}
      isOpen={showWarning}
      closeModal={dismiss}
      buttons={[
        {
          children: t("authWrapper.idleTimeoutDialog.buttons.stay"),
          onClick: () => {
            Analytics.track(events.idlePopUp.stayLoggedIn)
            dismiss()
          },
        },
        {
          children: t("authWrapper.idleTimeoutDialog.buttons.logout"),
          type: ButtonTypeEnum.SECONDARY,
          onClick: () => {
            Analytics.track(events.idlePopUp.logOutConfirm)
            logout()
          },
        },
      ]}
    />
  )
}
