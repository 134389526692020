import React from "react"
import { useTranslation } from "react-i18next"

import { Button, ButtonTypeEnum } from "@northone/ui"
import { FlexColumnLayout, FlexRowLayout } from "@layouts/flex"
import { theme } from "@layouts/theme"
import { Box, Image } from "rebass"
import { images } from "@assets/images/images"
import { SHARE_AND_EARN_HUB_TOP_SPACING } from "../share-and-earn-landing"
import {
  useBusinessCreatedAtQuery,
  useShareAndEarnResendBlockedCustomerEmailMutation,
} from "@generated/graphql"
import { useAppSelector } from "@core/redux/utils"
import { selectActiveBusinessID } from "@core/active-business/redux/selectors"
import { appActions } from "@core/redux/app/actions"
import { useDispatch } from "react-redux"
import { ShareAndEarnTitleAndBody } from "./title-and-body.component"

const REFEREAL_LINK_DOMAIN = "invite.northoneapps.com/"
const extractAdvocateIdFromReferralLink = (referralLink: string | undefined) => {
  if (!referralLink || !referralLink.includes(REFEREAL_LINK_DOMAIN)) return
  const splitLinkArray = referralLink.split(REFEREAL_LINK_DOMAIN)
  const advocateId = splitLinkArray[splitLinkArray.length - 1]
  return advocateId
}

const ResendButton = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const offerUrl = useAppSelector((state) => state.shareAndEarn.offerUrl)
  const advocateId = extractAdvocateIdFromReferralLink(offerUrl) ?? ""
  const businessId = useAppSelector(selectActiveBusinessID)
  const [resendEmail, { loading }] = useShareAndEarnResendBlockedCustomerEmailMutation({
    variables: {
      businessId,
      advocateId,
    },
    onError: () => {
      dispatch(appActions.setNotificationMessage(t("shareAndEarn.blocked.resend.error")))
    },
    onCompleted: () => {
      dispatch(appActions.setNotificationMessage(t("shareAndEarn.blocked.resend.success")))
    },
  })
  return (
    <Button
      isLoading={loading}
      onClick={() => {
        if (!loading) resendEmail()
      }}
      sx={{ alignSelf: "flex-start", marginRight: 12 }}
      type={ButtonTypeEnum.PRIMARY_GOLD}
    >
      {t("shareAndEarn.blocked.resend.button")}
    </Button>
  )
}

const ContactCustomerCareButton = () => {
  const { t } = useTranslation()
  return (
    <Button
      onClick={() => {
        window.Intercom("show")
      }}
      sx={{ alignSelf: "flex-start" }}
      type={ButtonTypeEnum.SECONDARY}
    >
      {t("shareAndEarn.blocked.contact")}
    </Button>
  )
}

const CustomerBlockedContentText = () => {
  const { t } = useTranslation()
  const businessId = useAppSelector(selectActiveBusinessID)

  const { data, loading: loadingEmail } = useBusinessCreatedAtQuery({ variables: { businessId } })
  const userEmail = data?.me?.email

  return (
    <ShareAndEarnTitleAndBody
      title={t("shareAndEarn.blocked.title")}
      bodyText={!loadingEmail ? t("shareAndEarn.blocked.body", { email: userEmail }) : ""}
      bodyPartialStyling={{
        color: theme.colors.gold100,
      }}
    />
  )
}

export const CustomerBlocked: React.FC = () => {
  return (
    <FlexRowLayout>
      <FlexColumnLayout sx={{ paddingTop: SHARE_AND_EARN_HUB_TOP_SPACING }} width="100%">
        <CustomerBlockedContentText />
        <FlexRowLayout>
          <ResendButton />
          <ContactCustomerCareButton />
        </FlexRowLayout>
      </FlexColumnLayout>
      <Box paddingTop={20}>
        <Image
          width={350}
          src={images.shareAndEarnBlocked}
          sx={{ position: "relative", left: 70 }}
        />
      </Box>
    </FlexRowLayout>
  )
}
