import { SubscriptionPlanPricePeriod, SubscriptionStatus } from "@generated/graphql"

export enum PlanType {
  STANDARD = "standard",
  PLUS = "plus",
}

export type Subscription =
  | {
      plan: SubscriptionPlan
      nextBillingDate?: string
      status: SubscriptionStatus.ACTIVE
    }
  | {
      plan: SubscriptionPlan
      nextBillingDate: string
      status: SubscriptionStatus.CANCEL_REQUESTED
    }

export type SubscriptionPlan = {
  name: string
  title: string
  shortTitle: string
  subtitle: string
  upgradeButtonText: string
  description: string[]
  featured: boolean
  price: SubscriptionPlanPrice[]
  sections: SubscriptionPlanSection[]
  retentionFeatures: FeatureBullets
  upgrade?: {
    successTitle: string
    successSubtitle: string
  }
  upsell?: {
    title: string
    subtitle: string
  }
}

export type SubscriptionPlanSection = {
  heading: string
  bullets: FeatureBullets
}

export type SubscriptionPlanPrice = {
  period: SubscriptionPlanPricePeriod
  price: number
  text: string
}

export type FeatureBullets = {
  icon?: string
  text: string
}[]
