import { validationConstants } from "@utils/validate"
import jsValidator from "validator"

export enum AccountNumberErrorTranslationKeyEnum {
  NO_ERROR_MESSAGE = "",
  INVALID_LENGTH = "invoicePayments.modal.paymentDetails.inputs.accountNumber.invalidLength",
  INVALID_CHARACTERS = "invoicePayments.modal.paymentDetails.inputs.accountNumber.invalidCharacters",
}

export const getAccountNumberError = (
  accountNumber: string,
): AccountNumberErrorTranslationKeyEnum => {
  if (!accountNumber) {
    // Skip validation if no value is provided
    return AccountNumberErrorTranslationKeyEnum.NO_ERROR_MESSAGE
  }

  if (!jsValidator.isNumeric(accountNumber)) {
    return AccountNumberErrorTranslationKeyEnum.INVALID_CHARACTERS
  }

  if (
    accountNumber.length < validationConstants.ACCOUNT_NUMBER_MIN_LENGTH ||
    accountNumber.length > validationConstants.ACCOUNT_NUMBER_MAX_LENGTH
  ) {
    return AccountNumberErrorTranslationKeyEnum.INVALID_LENGTH
  }

  return AccountNumberErrorTranslationKeyEnum.NO_ERROR_MESSAGE
}
