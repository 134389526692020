import React from "react"
import { useTranslation } from "react-i18next"
import { Image } from "rebass"
import { Panel } from "@northone/ui"
import { Text } from "@components/primitive/text/text"

import { images } from "@assets/images/images"
import { FlexRowLayout, FlexColumnLayout } from "@layouts/flex"
import { theme } from "@layouts/theme"

export const PilotFeedbackBanner: React.FC = () => {
  const { t } = useTranslation()

  return (
    <Panel
      sx={{
        height: "44px",
        marginBottom: 16,
      }}
    >
      <FlexRowLayout>
        <FlexColumnLayout>
          <Image
            src={images.attachedCans}
            sx={{
              height: "44px",
              borderTopLeftRadius: "4px",
              borderBottomLeftRadius: "4px",
            }}
          />
        </FlexColumnLayout>
        <FlexColumnLayout
          sx={{
            padding: "13px 11px",
          }}
        >
          <Text
            tag="label"
            sx={{
              fontSize: "12px",
              lineHeight: "17px",
              color: theme.colors.blackPrimary,
            }}
            textWithEmbeddedLinksProps={{
              text: t("earlyAccess.feedback.share"),
              linksInOrder: ["#"],
              linkStyle: {
                color: theme.colors.copperDark,
              },
            }}
            onClick={(e) => {
              e.preventDefault()
              window.Intercom("show")
            }}
          />
        </FlexColumnLayout>
      </FlexRowLayout>
    </Panel>
  )
}
