import React from "react"

import { FlowModalLayout } from "@components/extended/overlay/flow-modal"
import { AddFundsOptions } from "./options/form"
import { AddFundsCheck } from "./check/form"
import { AddFundsACHForm } from "./ach/form"
import { AddFundsWireForm } from "./wire/form"
import { AddFundsThirdPartyForm } from "./third-party/form"
import { ATMsDepositCash } from "./atm/deposit-cash"

export type TAddFundsScreenName =
  | "ADD_FUNDS_OPTIONS"
  | "ADD_FUNDS_ACH"
  | "ADD_FUNDS_WIRE"
  | "ADD_FUNDS_THIRD_PARTY"
  | "ADD_FUNDS_CHECK"
  | "ADD_FUNDS_ATM"

export enum AddFundsScreensEnum {
  ADD_FUNDS_OPTIONS = "ADD_FUNDS_OPTIONS",
  ADD_FUNDS_ACH = "ADD_FUNDS_ACH",
  ADD_FUNDS_WIRE = "ADD_FUNDS_WIRE",
  ADD_FUNDS_THIRD_PARTY = "ADD_FUNDS_THIRD_PARTY",
  ADD_FUNDS_CHECK = "ADD_FUNDS_CHECK",
  ADD_FUNDS_ATM = "ADD_FUNDS_ATM",
}

export const ADD_FUNDS_ROUTES: {
  [key in TAddFundsScreenName]: {
    component: React.ReactElement
    layout: FlowModalLayout
  }
} = {
  ADD_FUNDS_OPTIONS: {
    component: <AddFundsOptions />,
    layout: "form",
  },
  ADD_FUNDS_CHECK: {
    component: <AddFundsCheck />,
    layout: "form",
  },
  ADD_FUNDS_ACH: {
    component: <AddFundsACHForm />,
    layout: "form",
  },
  ADD_FUNDS_WIRE: {
    component: <AddFundsWireForm />,
    layout: "form",
  },
  ADD_FUNDS_THIRD_PARTY: {
    component: <AddFundsThirdPartyForm />,
    layout: "form",
  },
  ADD_FUNDS_ATM: {
    component: <ATMsDepositCash />,
    layout: "form",
  },
}
