import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { MaskTextInput } from "@components/extended/fields/mask-input"
import { getAccountNumberError } from "@features/invoice-payments/invoice-modal/payment-details/account-number-validation"
import {
  Contact,
  InvoiceAccountPayablePaymentMethod,
  useRoutingNumberAchIsValidLazyQuery,
} from "@generated/graphql"
import { FlexRowLayout } from "@layouts/flex"
import {
  RoutingNumberErrorTranslationKeyEnum,
  isValidRoutingNumberLength,
} from "../routing-number-validation"

export type TAchContactPaymentDetails = {
  type: InvoiceAccountPayablePaymentMethod.ACH
  name: string
  achPaymentDetails: {
    accountNumber: string
    routingNumber: string
  }
}

interface IAchPaymentDetailsFormProps {
  contact: Contact
  onChange: (contactPaymentDetails: TAchContactPaymentDetails) => void
  setIsAchContactPaymentDetailsChanged: (isAchContactPaymentDetailsChanged: boolean) => void
  setIsMissingFields: (isMissingFields: boolean) => void
  setHasInvalidFields: (hasInvalidFields: boolean) => void
  setIsValidationInProgress: (isValidationInProgress: boolean) => void
}

export const AchPaymentDetailsForm = ({
  contact,
  onChange,
  setIsAchContactPaymentDetailsChanged,
  setIsMissingFields,
  setHasInvalidFields,
  setIsValidationInProgress,
}: IAchPaymentDetailsFormProps) => {
  const { t } = useTranslation()

  const [accountNumber, setAccountNumber] = useState<string>(
    contact?.achPaymentDetails?.accountNumber ?? "",
  )
  const [routingNumber, setRoutingNumber] = useState<string>(
    contact?.achPaymentDetails?.routingNumber ?? "",
  )
  const [showAccountNumberError, setShowAccountNumberError] = useState<boolean>()

  const [
    checkRoutingNumberAchIsValid,
    { data: routingNumberQueryData, error: routingNumberQueryError },
  ] = useRoutingNumberAchIsValidLazyQuery()

  const validateRoutingNumber = async (routingNumber: string) => {
    setIsValidationInProgress(true)
    await checkRoutingNumberAchIsValid({ variables: { routingNumber } })
    setIsValidationInProgress(false)
  }

  const getRoutingNumberError = () => {
    if (!routingNumber) return

    if (!isValidRoutingNumberLength(routingNumber)) {
      return RoutingNumberErrorTranslationKeyEnum.INVALID_LENGTH
    }

    if (routingNumberQueryError) {
      return RoutingNumberErrorTranslationKeyEnum.VALIDATION_ERROR
    }

    if (routingNumberQueryData) {
      return routingNumberQueryData.routingNumberACHIsValid
        ? undefined
        : RoutingNumberErrorTranslationKeyEnum.INVALID_NUMBER
    }
  }
  const routingNumberError = getRoutingNumberError()

  const achPaymentDetailsContact: TAchContactPaymentDetails = {
    type: InvoiceAccountPayablePaymentMethod.ACH,
    name: contact?.name,
    achPaymentDetails: {
      accountNumber,
      routingNumber,
    },
  }

  const accountNumberError = getAccountNumberError(accountNumber)

  useEffect(() => {
    const isAchContactPaymentDetailsChanged =
      !contact?.achPaymentDetails && !accountNumber && !routingNumber
        ? false
        : contact?.achPaymentDetails?.accountNumber !== accountNumber ||
          contact?.achPaymentDetails?.routingNumber !== routingNumber

    setIsAchContactPaymentDetailsChanged(isAchContactPaymentDetailsChanged)
  }, [accountNumber, routingNumber])

  useEffect(() => {
    setIsMissingFields(!accountNumber || !routingNumber)

    setHasInvalidFields(Boolean(routingNumberError || accountNumberError))

    onChange(achPaymentDetailsContact)
  }, [accountNumber, routingNumber, routingNumberError, accountNumberError])

  return (
    <FlexRowLayout sx={{ columnGap: 16, mb: 22 }}>
      <MaskTextInput
        maskType="ROUTING_NUMBER"
        label={t("invoicePayments.modal.paymentDetails.inputs.routingNumber.label")}
        placeholder="#########"
        value={routingNumber}
        onChange={(value) => {
          const routingNumber = value.replaceAll("_", "")
          setRoutingNumber(routingNumber)
          if (isValidRoutingNumberLength(routingNumber)) {
            validateRoutingNumber(routingNumber)
          }
        }}
        errorMessage={routingNumberError ? t(routingNumberError) : undefined}
      />
      <MaskTextInput
        maskType="ACCOUNT_NUMBER"
        label={t("invoicePayments.modal.paymentDetails.inputs.accountNumber.label")}
        placeholder="#################"
        guide={false}
        value={accountNumber}
        onBlur={() => setShowAccountNumberError(true)}
        onChange={setAccountNumber}
        errorMessage={
          showAccountNumberError && accountNumberError ? t(accountNumberError) : undefined
        }
      />
    </FlexRowLayout>
  )
}
