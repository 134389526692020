import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import { events } from "@core/analytics/events"
import { Analytics } from "@core/analytics/actions"
import { FormBody } from "@components/composite/form-body/form-body"
import {
  FundingOptionListItem,
  FundingOptionListItemProps,
} from "@components/extended/list-items/funding-option/funding-option-list-item"
import { ButtonTypeEnum } from "@components/primitive/button/button"
import { DisclaimerNote } from "@components/composite/disclaimer-note/disclaimer-note"
import { TAddFundsScreenName } from "@features/add-funds/add-funds.router"
import { addFundsActions } from "@features/add-funds/redux/actions"
import { FundingMethodEnum } from "@features/add-funds/redux/reducer"
import { TransactionLimitsNote } from "@features/move-money/shared/transaction-limits-note"
import { images } from "@assets/images/images"
import { AddFundsScreensEnum } from "@features/add-funds/add-funds.router"
import { useCapabilitiesAndAvailableBalanceQuery } from "@generated/graphql"
import { selectActiveBusinessID } from "@core/active-business/redux/selectors"
import { useAppSelector } from "@core/redux/utils"
import { getFeeDisplay } from "@features/move-money/utils/fee.utils"
import { LoadingPanel } from "@components/extended/panel/loading-panel"
import { ErrorPanel } from "@components/extended/panel/error-panel"

interface FundingOption extends FundingOptionListItemProps {
  eventName: string
  nextScreen: TAddFundsScreenName
  type: FundingMethodEnum
}

export const AddFundsOptions: React.FC = () => {
  const { t } = useTranslation()
  const [selectedMethod, setFundingMethodEnum] = useState<FundingOption | null>(null)
  const dispatch = useDispatch()
  const businessId = useAppSelector(selectActiveBusinessID)

  const {
    data: capabilitiesData,
    error: capabilitiesError,
    loading: capabilitiesLoading,
    refetch: capabilitiesRefetch,
  } = useCapabilitiesAndAvailableBalanceQuery({
    variables: { businessId },
    notifyOnNetworkStatusChange: true,
  })
  const wireFee = getFeeDisplay(
    capabilitiesData?.business?.bankAccounts[0].capabilities?.domesticWire.fee,
  )

  const ADD_FUND_OPTIONS: FundingOption[] = [
    {
      title: t("topUp.addFunds.options.thirdParty.title"),
      subtitle: t("topUp.addFunds.options.thirdParty.subtitle"),
      eventName: events.addFunds.funded.options.thirdParty,
      nextScreen: AddFundsScreensEnum.ADD_FUNDS_THIRD_PARTY,
      iconSrc: images.icons.fundingOptions.thirdParty,
      type: FundingMethodEnum.THIRD_PARTY,
    },
    {
      title: t("topUp.addFunds.options.ach.title"),
      subtitle: t("topUp.addFunds.options.ach.subtitle"),
      eventName: events.addFunds.funded.options.ach,
      nextScreen: AddFundsScreensEnum.ADD_FUNDS_ACH,
      iconSrc: images.icons.fundingOptions.ach,
      type: FundingMethodEnum.ACH,
    },
    {
      title: t("topUp.addFunds.options.checkDeposit.title"),
      subtitle: t("topUp.addFunds.options.checkDeposit.subtitle"),
      eventName: events.addFunds.funded.options.checkDeposit,
      nextScreen: AddFundsScreensEnum.ADD_FUNDS_CHECK,
      iconSrc: images.icons.fundingOptions.check,
      type: FundingMethodEnum.CHECK_DEPOSIT,
    },
    {
      title: t("topUp.addFunds.options.atm.title"),
      subtitle: t("topUp.addFunds.options.atm.subtitle"),
      eventName: events.addFunds.funded.options.cashDeposit,
      nextScreen: AddFundsScreensEnum.ADD_FUNDS_ATM as TAddFundsScreenName,
      iconSrc: images.icons.fundingOptions.atm,
      type: FundingMethodEnum.ATM,
    },
    {
      title: t("topUp.addFunds.options.wire.title"),
      subtitle: t("topUp.addFunds.options.wire.subtitle"),
      eventName: events.addFunds.funded.options.wire,
      nextScreen: AddFundsScreensEnum.ADD_FUNDS_WIRE,
      iconSrc: images.icons.fundingOptions.wire,
      type: FundingMethodEnum.WIRE,
    },
  ]

  if (capabilitiesLoading) {
    return <LoadingPanel iconDimension={40} />
  }
  if (capabilitiesError || !wireFee) {
    return <ErrorPanel retry={capabilitiesRefetch} />
  }

  return (
    <>
      <FormBody
        title={t("topUp.addFunds.options.title")}
        buttons={[
          {
            canContinueWithKey: true,
            children: t("buttons.continue"),
            type: ButtonTypeEnum.PRIMARY_BLUE,
            disabled: !selectedMethod,
            onClick: () => {
              if (selectedMethod) {
                Analytics.track(selectedMethod.eventName)
                dispatch(addFundsActions.navigateNext(selectedMethod.nextScreen))
                dispatch(addFundsActions.setFundingMethod(selectedMethod.type))
              }
            },
          },
        ]}
      >
        {ADD_FUND_OPTIONS.map((fundingMethodEnum, index) => {
          const { title, subtitle, iconSrc } = fundingMethodEnum
          const isSelected = selectedMethod?.title === title
          return (
            <FundingOptionListItem
              key={index}
              title={title}
              iconSrc={iconSrc}
              subtitle={subtitle}
              onClick={() => {
                setFundingMethodEnum(fundingMethodEnum)
                dispatch(addFundsActions.setFundingMethod(fundingMethodEnum.type))
              }}
              isActive={isSelected}
            />
          )
        })}
      </FormBody>
      <TransactionLimitsNote />
      <DisclaimerNote text={t("topUp.addFunds.options.atm.note")} />
      <DisclaimerNote text={t("topUp.addFunds.options.wire.note")} />
      <DisclaimerNote text={t("topUp.addFunds.options.legal")} />
    </>
  )
}
