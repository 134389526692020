import React from "react"
import { BaseLayout } from "@layouts/base-layout"
import { useTranslation } from "react-i18next"
import i18n from "@i18n/i18n"
import { useAppSelector } from "@core/redux/utils"
import { selectActiveBusinessID } from "@core/active-business/redux/selectors"
import { Text } from "@components/primitive/text/text"
import { ListLabels, TListLabel } from "@components/primitive/list-label/list-labels"
import { QueryResults } from "@components/composite/query-results/query-results"
import { BusinessOwnerListItemFR } from "@components/extended/list-items/business-owner/business-owner-list-item-fr"
import { FlexColumnLayout } from "@layouts/flex"
import { useOwnerCandidatesQuery } from "@generated/graphql"

const TEAM_MEMBERS_LIST_LABELS: TListLabel[] = [
  {
    title: i18n.t("settings.pages.manageTeam.labels.member"),
    widthRatio: 0.4,
    alignment: "start",
  },
]

export const BusinessOwners: React.FC = () => {
  const { t } = useTranslation()
  const businessId = useAppSelector(selectActiveBusinessID)

  const { data, loading, error, refetch } = useOwnerCandidatesQuery({
    notifyOnNetworkStatusChange: true,
    variables: { businessId },
  })

  const owners = data?.business?.ownerCandidates

  return (
    <BaseLayout pageTitle={t("pageTitles.settings.team")}>
      <Text tag="h2" sx={{ marginBottom: 36 }}>
        {t("settings.pages.businessOwners.title")}
      </Text>
      <QueryResults
        loading={loading}
        error={error}
        retry={() => refetch()}
        size="medium"
        listShimmerCount={2}
      >
        {owners && owners.length > 0 ? (
          <FlexColumnLayout
            sx={{
              width: "100%",
              paddingX: 20,
            }}
          >
            <ListLabels labels={TEAM_MEMBERS_LIST_LABELS} />
            {owners.map((owner) => {
              const { firstName, lastName, id } = owner
              return <BusinessOwnerListItemFR key={id} name={`${firstName} ${lastName}`} />
            })}
          </FlexColumnLayout>
        ) : null}
      </QueryResults>
    </BaseLayout>
  )
}
