import React from "react"
import { SxStyleProp } from "rebass"
import { ApolloError } from "@apollo/client"
import { useTranslation } from "react-i18next"

import { Text } from "@components/primitive/text/text"
import { FlexColumnLayout } from "@layouts/flex"
import { Panel } from "@components/primitive/panel/panel"
import { RevenueHubPlatformInfo } from "./platforms"
import { RevenueHubConnectionStatusEnum, useGetRevenueHubConnectionQuery } from "@generated/graphql"
import { FlexRowLayout, theme } from "@northone/ui"
import {
  ConnectionDisabled,
  ConnectionNotReady,
  ConnectionReAuth,
  ConnectionGenericError,
} from "./components/connection-views/status-views"
import { ConnectionActiveView } from "./components/connection-views/connection-active"
import { ConnectionLoading } from "./components/connection-views/loading"
import { RevenueDetails, formatMoney, isBalanceSupportedByPlatform } from "./utils"
import { useAppSelector } from "@core/redux/utils"
import { selectActiveBusinessID } from "@core/active-business/redux/selectors"
import { RevenueHubDisconnectPlatform } from "./components/disconnect-platform"
import { Filters } from "./components/filters"
import { PayoutInstructions } from "./components/payout-instructions"
import { ImageLink } from "@components/extended/image/image-link"

interface RevenueHubPlatformPanelProps {
  platformInfo: RevenueHubPlatformInfo
  connectionId: string
  filters: Filters
}

const REVENUE_HUB_ETSY_DASHBOARD_URL = "https://www.etsy.com/your/shops/me/dashboard"

export const RevenueHubPlatformPanel = ({
  platformInfo,
  connectionId,
  filters,
}: RevenueHubPlatformPanelProps) => {
  const businessId = useAppSelector(selectActiveBusinessID)

  const { data, refetch, error } = useGetRevenueHubConnectionQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
    variables: {
      businessId,
      connectionId,
      filters: {
        dateTimeFilter: {
          startDateTime: filters.startDateTime,
          endDateTime: filters.endDateTime,
        },
      },
    },
  })

  const revenueDetails = data?.revenueHub.connection.revenueDetails
  const status = data?.revenueHub.connection.status

  const renderContent = ({
    status,
    revenueDetails,
    error,
    refetch,
  }: {
    status?: RevenueHubConnectionStatusEnum
    revenueDetails: RevenueDetails
    error?: ApolloError
    refetch: () => void
  }) => {
    if (error)
      return <ConnectionGenericError onButtonClick={() => refetch()} platformInfo={platformInfo} />

    switch (status) {
      case RevenueHubConnectionStatusEnum.NEEDS_REAUTH:
        return <ConnectionReAuth platformInfo={platformInfo} connectionId={connectionId} />
      case RevenueHubConnectionStatusEnum.DISABLED:
        return <ConnectionDisabled platformInfo={platformInfo} connectionId={connectionId} />
      case RevenueHubConnectionStatusEnum.NOT_READY:
        return <ConnectionNotReady />
      case RevenueHubConnectionStatusEnum.ACTIVE:
        return <ConnectionActiveView revenueDetails={revenueDetails} platformInfo={platformInfo} />
      default:
        return <ConnectionLoading />
    }
  }

  return (
    <Panel sx={panelStyles.panel}>
      <FlexColumnLayout sx={{ height: "100%" }}>
        <ConnectionPanelHeader {...{ status, error, revenueDetails, platformInfo, connectionId }} />
        {renderContent({ status, error, revenueDetails, refetch })}
      </FlexColumnLayout>
    </Panel>
  )
}

const ConnectionPanelHeader = ({
  platformInfo,
  status,
  error,
  revenueDetails,
  connectionId,
}: {
  platformInfo: RevenueHubPlatformInfo
  status?: RevenueHubConnectionStatusEnum
  error?: ApolloError
  revenueDetails: RevenueDetails
  connectionId: string
}) => {
  const { t } = useTranslation()

  const getSubtitle = (): React.ReactNode => {
    const currentBalance = revenueDetails?.currentBalance
    const balanceDashboardLinkText = t("revenueHub.platformActive.balanceFromPlatformDashboard", {
      platformName: platformInfo.name,
    })
    const balanceText = t("revenueHub.platformActive.balance", {
      balance: formatMoney(currentBalance),
    })

    if (error) {
      return <Text tag="body-small">{t("revenueHub.platformActive.error.connectionError")}</Text>
    }

    const renderActivePlatformTitle = (): React.ReactNode => {
      if (!isBalanceSupportedByPlatform(platformInfo.name)) {
        const isEtsyPlatform = platformInfo.name === "Etsy"
        const balanceDashboardURL = isEtsyPlatform
          ? REVENUE_HUB_ETSY_DASHBOARD_URL
          : platformInfo.siteUrl

        return (
          <Text
            tag="body-small"
            textColor={theme.colors.blue100}
            style={{ textDecoration: "underline" }}
            textWithEmbeddedLinksProps={{
              text: balanceDashboardLinkText,
              linksInOrder: [balanceDashboardURL],
              linkStyle: { textDecoration: "underline", color: theme.colors.blue100 },
            }}
          />
        )
      }

      return (
        <>
          {currentBalance !== undefined && <Text tag="body-small-bold">{balanceText}</Text>}
          <PayoutInstructions platformInfo={platformInfo} />
        </>
      )
    }

    switch (status) {
      case RevenueHubConnectionStatusEnum.DISABLED:
        return (
          <Text tag="body-small">{t("revenueHub.platformActive.error.disabled.disabled")}</Text>
        )
      case RevenueHubConnectionStatusEnum.NOT_READY:
        return (
          <Text tag="body-small">{t("revenueHub.platformActive.error.notReady.dataNotReady")}</Text>
        )
      case RevenueHubConnectionStatusEnum.NEEDS_REAUTH:
        return (
          <Text tag="body-small">{t("revenueHub.platformActive.error.reAuth.disconnected")}</Text>
        )
      case RevenueHubConnectionStatusEnum.ACTIVE:
        return renderActivePlatformTitle()
      default:
        return <Text tag="body-small">{t("revenueHub.platformActive.establishingConnection")}</Text>
    }
  }

  return (
    <FlexRowLayout sx={panelStyles.headingRow}>
      <ImageLink
        url={platformInfo.siteUrl}
        imgSx={panelStyles.headingImg}
        imgSrc={platformInfo.imageSrc}
      />
      <FlexRowLayout sx={{ flex: 1, justifyContent: "space-between" }}>
        <FlexColumnLayout>
          <Text tag="body-large-bold">{platformInfo.name}</Text>
          {getSubtitle()}
        </FlexColumnLayout>
        <RevenueHubDisconnectPlatform
          connectionId={connectionId}
          platformName={platformInfo.name}
        />
      </FlexRowLayout>
    </FlexRowLayout>
  )
}

const borderBottom = `1px solid ${theme.colors.ui4}`
const panelStyles: Record<string, SxStyleProp> = {
  panel: { width: "auto", minHeight: 395 },
  headingRow: {
    marginX: 20,
    paddingTop: 24,
    gap: 3,
    paddingBottom: 16,
    borderBottom,
    alignItems: "center",
  },
  headingImg: { width: 36, height: 36 },
}
