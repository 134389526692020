import React from "react"
import { Button, ButtonTypeEnum, Panel, Text } from "@northone/ui"
import { theme } from "@layouts/theme"
import {
  CARD_NOT_ACTIVATED_TITLE,
  CARD_NOT_ACTIVATED_SUPPORT,
  CARD_NOT_ACTIVATED_BUTTON,
} from "@features/move-money/utils/i18n-translations"
import { useAppSelector } from "@core/redux/utils"
import { useHistory } from "react-router"
import { RoutesEnum } from "@routers/types"
import { useDispatch } from "react-redux"
import { cardsActions } from "@features/cards/redux/actions"
import { Analytics } from "@core/analytics/actions"
import { events } from "@core/analytics/events"

export const ActivateCardPanel: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const nonActivatedCardIds = useAppSelector((state) => state.moveMoney.nonActivatedCardIds)
  return (
    <Panel
      sx={{
        paddingX: theme.spacing.transactionPanel.x,
        paddingY: theme.spacing.transactionPanel.y,
      }}
    >
      <Text marginBottom={16} sx={{ lineHeight: "26px" }} tag="h4">
        {CARD_NOT_ACTIVATED_TITLE}
      </Text>
      <Button
        onClick={() => {
          Analytics.track(events.cards.wireActivate)
          if (nonActivatedCardIds?.length === 1) {
            dispatch(cardsActions.setCurrentCardId(nonActivatedCardIds[0]))
            dispatch(cardsActions.setCardActivationModalOpen(true))
          }
          history.push(RoutesEnum.CARDS)
        }}
        sx={{ alignSelf: "flex-start" }}
        type={ButtonTypeEnum.PRIMARY_BLUE}
      >
        {CARD_NOT_ACTIVATED_BUTTON}
      </Button>
      <Text
        partialStyling={{ textDecoration: "underline", cursor: "pointer" }}
        textColor={theme.colors.ui2}
        tag="body"
        onClick={() => window.Intercom("show")}
        sx={{ marginTop: 16 }}
      >
        {CARD_NOT_ACTIVATED_SUPPORT}
      </Text>
    </Panel>
  )
}
