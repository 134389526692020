export const navBar = {
  dashboard: "Dashboard",
  cards: "Cards",
  invoicePayments: "Bill Payments",
  integrations: "Integrations",
  revenue: "Revenue",
  settings: "Settings",
  support: "Support",
  lending: "Lending",
}
