import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useHistory, useLocation } from "react-router-dom"
import { RoutesEnum, ILocationState } from "@routers/types"
import { InfoBlockModal } from "@components/composite/info-block-modal/info-block-modal"
import { ButtonTypeEnum } from "@components/primitive/button/button"
import { accountsActions } from "@features/accounts/redux/actions"
import { navigateFromModal } from "@utils/navigation"
import { images } from "@assets/images/images"

interface ITransferDiscardModalProps {
  isOpen: boolean
  closeModal: () => void
}

export const TransferDiscardModal: React.FC<ITransferDiscardModalProps> = ({
  isOpen,
  closeModal,
}) => {
  const history = useHistory()
  const location = useLocation<ILocationState>()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  return (
    <InfoBlockModal
      title={t("moveMoney.discard.title", { context: "transfer" })}
      subtitle={t("moveMoney.discard.subtitle", { context: "transfer" })}
      isOpen={isOpen}
      imageSrc={images.illustrations.trashBin}
      imageDimensions={{ height: 180, width: 250 }}
      closeModal={closeModal}
      buttons={[
        {
          children: t("moveMoney.discard.discard"),
          onClick: () => {
            navigateFromModal({
              defaultBackRoute: RoutesEnum.ACCOUNTS_OVERVIEW,
              history,
              location,
            })
            closeModal()
            dispatch(accountsActions.subAccountTransferDiscard())
          },
        },
        { children: t("buttons.cancel"), onClick: closeModal, type: ButtonTypeEnum.SECONDARY },
      ]}
    />
  )
}
