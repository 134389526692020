import React from "react"
import { SxStyleProp } from "rebass"
import { FlexRowLayout } from "@northone/ui"
import { OneOrMoreNullableElements } from "@typedefs/types"

const containerStyles: SxStyleProp = { gap: 3 }

type ButtonContainerProps = {
  children: OneOrMoreNullableElements
  sx?: SxStyleProp
}

export const ButtonContainer = ({ children, sx }: ButtonContainerProps) => {
  const styles = { ...containerStyles, ...sx }
  return <FlexRowLayout sx={styles}>{children}</FlexRowLayout>
}
