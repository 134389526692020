import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"

import { Dropdown } from "@components/composite/dropdown/dropdown"
import { MaskTextInput } from "@components/extended/fields/mask-input"
import { TextInput } from "@components/extended/fields/text-input"
import { Text } from "@components/primitive/text/text"
import {
  Contact,
  LocationStrictInput,
  InvoiceAccountPayablePaymentMethod,
  useRoutingNumberWireIsValidLazyQuery,
} from "@generated/graphql"
import { FlexColumnLayout, FlexRowLayout } from "@layouts/flex"
import { getAccountNumberError } from "@features/invoice-payments/invoice-modal/payment-details/account-number-validation"
import { businessRelationshipOptions } from "@features/move-money/domestic-wire/utils/constants"
import { theme } from "@layouts/theme"
import { addressStateOptions } from "./address-state"
import { errorMessages } from "@features/move-money/domestic-wire/utils/recipientValidator"
import {
  RoutingNumberErrorTranslationKeyEnum,
  isValidRoutingNumberLength,
} from "../routing-number-validation"

export type TWireContactPaymentDetails = {
  type: InvoiceAccountPayablePaymentMethod.WIRE
  name: string
  address: LocationStrictInput
  relationship: string
  wirePaymentDetails: {
    bankName: string
    accountNumber: string
    routingNumber: string
    purpose: string
  }
}

interface IWirePaymentDetailsFormProps {
  purpose: string
  setPurpose: (purpose: string) => void
  contact: Contact
  onChange: (contactPaymentDetails: TWireContactPaymentDetails) => void
  setIsWireContactPaymentDetailsChanged: (isWireContactPaymentDetailsChanged: boolean) => void
  setIsMissingFields: (isMissingFields: boolean) => void
  setHasInvalidFields: (hasInvalidFields: boolean) => void
  setIsValidationInProgress: (isValidationInProgress: boolean) => void
}

const isValidBusinessRelationship = (
  businessRelationshipOptions: Record<string, string>,
  value: string,
): boolean => Object.values(businessRelationshipOptions).includes(value)

const getInitialBusinessRelationship = (contact: Contact): string => {
  return contact.relationship &&
    isValidBusinessRelationship(businessRelationshipOptions, contact.relationship)
    ? contact.relationship
    : businessRelationshipOptions.other
}

const getInitialOtherRelationshipDetails = (contact: Contact): string => {
  return contact.relationship &&
    !isValidBusinessRelationship(businessRelationshipOptions, contact.relationship)
    ? contact.relationship
    : ""
}

const getBusinessRelationship = ({
  businessRelationship,
  otherRelationshipDetails,
}: {
  businessRelationship: string
  otherRelationshipDetails: string
}): string => {
  return businessRelationship === businessRelationshipOptions.other ||
    isValidBusinessRelationship(businessRelationshipOptions, otherRelationshipDetails)
    ? otherRelationshipDetails
    : businessRelationship
}

export const WirePaymentDetailsForm = ({
  purpose,
  setPurpose,
  contact,
  onChange,
  setIsWireContactPaymentDetailsChanged,
  setIsMissingFields,
  setHasInvalidFields,
  setIsValidationInProgress,
}: IWirePaymentDetailsFormProps) => {
  const { t } = useTranslation()

  const [personalOrBusinessName, setPersonalOrBusinessName] = useState(contact.name || "")
  const [addressLine1, setAddressLine1] = useState<string>(
    contact.address?.streetAddressLine1 || "",
  )
  const [zipCode, setZipCode] = useState<string>(contact.address?.postalCode || "")
  const [addressLine2, setAddressLine2] = useState<string>(
    contact.address?.streetAddressLine2 || "",
  )
  const [addressState, setAddressState] = useState<string>(contact.address?.provinceState || "")
  const [city, setCity] = useState<string>(contact.address?.city || "")
  const [bankName, setBankName] = useState<string>(contact.wirePaymentDetails?.bankName || "")

  const [accountNumber, setAccountNumber] = useState<string>(
    contact.wirePaymentDetails?.accountNumber || "",
  )
  const [routingNumber, setRoutingNumber] = useState<string>(
    contact.wirePaymentDetails?.routingNumber || "",
  )

  const [businessRelationship, setBusinessRelationship] = useState<string>(
    getInitialBusinessRelationship(contact),
  )
  const [otherRelationshipDetails, setOtherRelationshipDetails] = useState<string>(
    getInitialOtherRelationshipDetails(contact),
  )

  const [showAccountNumberError, setShowAccountNumberError] = useState<boolean>()

  const [
    checkRoutingNumberWireIsValid,
    { data: routingNumberQueryData, error: routingNumberQueryError },
  ] = useRoutingNumberWireIsValidLazyQuery()

  const validateRoutingNumber = async (routingNumber: string) => {
    setIsValidationInProgress(true)
    await checkRoutingNumberWireIsValid({ variables: { routingNumber } })
    setIsValidationInProgress(false)
  }

  const getRoutingNumberError = () => {
    if (!routingNumber) return

    if (!isValidRoutingNumberLength(routingNumber)) {
      return RoutingNumberErrorTranslationKeyEnum.INVALID_LENGTH
    }

    if (routingNumberQueryError) {
      return RoutingNumberErrorTranslationKeyEnum.VALIDATION_ERROR
    }

    if (routingNumberQueryData) {
      return routingNumberQueryData.routingNumberWireIsValid
        ? undefined
        : RoutingNumberErrorTranslationKeyEnum.INVALID_NUMBER
    }
  }
  const routingNumberError = getRoutingNumberError()

  const otherRelationshipDetailsErrorMessage = otherRelationshipDetails
    ? errorMessages.businessReason(otherRelationshipDetails)
    : ""

  const wirePaymentDetailsContact: TWireContactPaymentDetails = {
    type: InvoiceAccountPayablePaymentMethod.WIRE,
    name: personalOrBusinessName,
    address: {
      streetAddressLine1: addressLine1,
      streetAddressLine2: addressLine2 ?? null,
      provinceState: addressState,
      postalCode: zipCode,
      country: "US",
      city,
    },
    relationship: getBusinessRelationship({
      businessRelationship,
      otherRelationshipDetails,
    }),
    wirePaymentDetails: {
      bankName,
      accountNumber,
      routingNumber,
      purpose,
    },
  }

  const businessRelationshipDropdownOptions = [
    {
      text: t(
        "invoicePayments.modal.paymentDetails.inputs.wire.businessRelationship.options.vendorOrSupplier",
      ),
      value: businessRelationshipOptions.vendor,
    },
    {
      text: t(
        "invoicePayments.modal.paymentDetails.inputs.wire.businessRelationship.options.government",
      ),
      value: businessRelationshipOptions.government,
    },
    {
      text: t(
        "invoicePayments.modal.paymentDetails.inputs.wire.businessRelationship.options.myOtherBank",
      ),
      value: businessRelationshipOptions.otherBank,
    },
    {
      text: t(
        "invoicePayments.modal.paymentDetails.inputs.wire.businessRelationship.options.payrollProvider",
      ),
      value: businessRelationshipOptions.payroll,
    },
    {
      text: t(
        "invoicePayments.modal.paymentDetails.inputs.wire.businessRelationship.options.other",
      ),
      value: businessRelationshipOptions.other,
    },
  ]

  const accountNumberError = getAccountNumberError(accountNumber)

  const isRequiredFieldsEmpty =
    !personalOrBusinessName ||
    !addressLine1 ||
    !zipCode ||
    !addressState ||
    !city ||
    !bankName ||
    !accountNumber ||
    !routingNumber ||
    !businessRelationship ||
    (businessRelationship === businessRelationshipOptions.other && !otherRelationshipDetails) ||
    (businessRelationship === businessRelationshipOptions.other &&
      Boolean(otherRelationshipDetailsErrorMessage)) ||
    !purpose

  useEffect(() => {
    const isAddressChanged =
      !contact?.address && !addressLine1 && !addressLine2 && !city && !addressState && !zipCode
        ? false
        : contact?.address?.streetAddressLine1 !== addressLine1 ||
          (contact?.address?.streetAddressLine2 ?? "") !== addressLine2 ||
          contact?.address?.city !== city ||
          contact?.address?.provinceState !== addressState ||
          contact?.address?.postalCode !== zipCode

    const isRelationshipChanged =
      contact.relationship !== businessRelationship ||
      (otherRelationshipDetails && otherRelationshipDetails !== businessRelationship)

    const isWirePaymentDetailsChanged =
      !contact?.wirePaymentDetails && !bankName && !accountNumber && !routingNumber
        ? false
        : contact?.wirePaymentDetails?.bankName !== bankName ||
          contact?.wirePaymentDetails?.accountNumber !== accountNumber ||
          contact?.wirePaymentDetails?.routingNumber !== routingNumber

    const isChanged = isAddressChanged || isRelationshipChanged || isWirePaymentDetailsChanged

    setIsWireContactPaymentDetailsChanged(isChanged)

    setIsMissingFields(isRequiredFieldsEmpty)

    setHasInvalidFields(Boolean(routingNumberError || accountNumberError))

    onChange(wirePaymentDetailsContact)
  }, [
    personalOrBusinessName,
    addressLine1,
    zipCode,
    addressLine2,
    addressState,
    city,
    bankName,
    accountNumber,
    routingNumber,
    businessRelationship,
    purpose,
    routingNumberError,
    accountNumberError,
    otherRelationshipDetails,
  ])

  return (
    <FlexColumnLayout sx={{ mb: 22 }}>
      <TextInput
        label={t("invoicePayments.modal.paymentDetails.inputs.wire.personalOrBusinessName.label")}
        onChange={setPersonalOrBusinessName}
        placeholder={t(
          "invoicePayments.modal.paymentDetails.inputs.wire.personalOrBusinessName.placeholder",
        )}
        value={personalOrBusinessName}
      />
      <TextInput
        label={t("invoicePayments.modal.paymentDetails.inputs.wire.addressLine1.label")}
        value={addressLine1}
        onChange={setAddressLine1}
        placeholder={t("invoicePayments.modal.paymentDetails.inputs.wire.addressLine1.placeholder")}
        containerSx={{ marginTop: 22 }}
      />
      <FlexRowLayout sx={{ paddingTop: 22, columnGap: 16 }}>
        <TextInput
          value={zipCode}
          onChange={setZipCode}
          label={t("invoicePayments.modal.paymentDetails.inputs.wire.zipCode.label")}
          placeholder={t("invoicePayments.modal.paymentDetails.inputs.wire.zipCode.label")}
        />
        <TextInput
          value={addressLine2}
          onChange={setAddressLine2}
          label={t("invoicePayments.modal.paymentDetails.inputs.wire.addressLine2.label")}
          placeholder={t(
            "invoicePayments.modal.paymentDetails.inputs.wire.addressLine2.placeholder",
          )}
        />
      </FlexRowLayout>
      <FlexRowLayout sx={{ paddingTop: 22, columnGap: 16 }}>
        <TextInput
          value={city}
          onChange={setCity}
          label={t("invoicePayments.modal.paymentDetails.inputs.wire.addressCity.label")}
          placeholder={t("invoicePayments.modal.paymentDetails.inputs.wire.addressCity.label")}
        />
        <Dropdown
          options={addressStateOptions}
          selectedValue={addressState}
          onSelect={setAddressState}
          label={t("invoicePayments.modal.paymentDetails.inputs.wire.addressState.label")}
          placeholder={t(
            "invoicePayments.modal.paymentDetails.inputs.wire.addressState.placeholder",
          )}
        />
      </FlexRowLayout>
      <FlexColumnLayout sx={{ paddingTop: 22 }}>
        <Text tag="label" textColor={theme.colors.ui2} textAlign="left">
          {t(
            "invoicePayments.modal.paymentDetails.inputs.wire.businessRelationship.title",
          ).toUpperCase()}
        </Text>
        <FlexRowLayout sx={{ columnGap: 16 }}>
          <Dropdown
            options={businessRelationshipDropdownOptions}
            selectedValue={businessRelationship}
            onSelect={setBusinessRelationship}
            label={t("invoicePayments.modal.paymentDetails.inputs.wire.businessRelationship.label")}
            placeholder={t(
              "invoicePayments.modal.paymentDetails.inputs.wire.businessRelationship.placeholder",
            )}
          />
          {businessRelationship === businessRelationshipOptions.other && (
            <TextInput
              value={otherRelationshipDetails}
              onChange={setOtherRelationshipDetails}
              errorMessage={otherRelationshipDetailsErrorMessage}
              label={t(
                "invoicePayments.modal.paymentDetails.inputs.wire.businessRelationship.otherRelationshipDetails.label",
              )}
              placeholder={t(
                "invoicePayments.modal.paymentDetails.inputs.wire.businessRelationship.otherRelationshipDetails.placeholder",
              )}
            />
          )}
        </FlexRowLayout>
      </FlexColumnLayout>
      <FlexColumnLayout sx={{ paddingTop: 22 }}>
        <Text tag="label" textColor={theme.colors.ui2} textAlign="left">
          {t("invoicePayments.modal.paymentDetails.inputs.wire.reason.title").toUpperCase()}
        </Text>
        <TextInput
          value={purpose}
          onChange={setPurpose}
          label={t("invoicePayments.modal.paymentDetails.inputs.wire.reason.label")}
          placeholder={t("invoicePayments.modal.paymentDetails.inputs.wire.reason.placeholder")}
        />
      </FlexColumnLayout>
      <FlexColumnLayout sx={{ paddingTop: 22 }}>
        <Text tag="label" textColor={theme.colors.ui2} textAlign="left">
          {t("invoicePayments.modal.paymentDetails.inputs.wire.bankName.title").toUpperCase()}
        </Text>
        <TextInput
          value={bankName}
          onChange={setBankName}
          label={t("invoicePayments.modal.paymentDetails.inputs.wire.bankName.label")}
          placeholder={t("invoicePayments.modal.paymentDetails.inputs.wire.bankName.placeholder")}
        />
        <FlexRowLayout sx={{ paddingTop: 22, columnGap: 16 }}>
          <MaskTextInput
            maskType="ROUTING_NUMBER"
            label={t("invoicePayments.modal.paymentDetails.inputs.routingNumber.label")}
            placeholder="#########"
            value={routingNumber}
            onChange={(value) => {
              const routingNumber = value.replaceAll("_", "")
              setRoutingNumber(routingNumber)
              if (isValidRoutingNumberLength(routingNumber)) {
                validateRoutingNumber(routingNumber)
              }
            }}
            errorMessage={routingNumberError ? t(routingNumberError) : undefined}
          />
          <MaskTextInput
            maskType="ACCOUNT_NUMBER"
            label={t("invoicePayments.modal.paymentDetails.inputs.accountNumber.label")}
            placeholder="#################"
            guide={false}
            value={accountNumber}
            onBlur={() => setShowAccountNumberError(true)}
            onChange={setAccountNumber}
            errorMessage={
              showAccountNumberError && accountNumberError ? t(accountNumberError) : undefined
            }
          />
        </FlexRowLayout>
      </FlexColumnLayout>
    </FlexColumnLayout>
  )
}
