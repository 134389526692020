import React from "react"
import { SxStyleProp } from "rebass"
import { PreloadImage } from "@northone/ui"

import { FlexColumnLayout, FlexRowLayout } from "@layouts/flex"
import { Text, TTextTag } from "@components/primitive/text/text"
import { Button, IButtonProps } from "@components/primitive/button/button"
import { theme } from "@layouts/theme"
import { AnimatePresence } from "@components/animations/animate-presence/animation-presence"
import { NoticeBox } from "@components/primitive/notice-box/notice-box"
import { ListItemBox, IListItem } from "../list-item-box/list-item-box"

interface IImageDimensions {
  width: number
  height: number
}
export interface IInfoBlockProps {
  imageSrc?: string
  title?: string | React.ReactElement
  subtitle?: string | React.ReactElement
  subtitleTag?: TTextTag
  subtitleSx?: SxStyleProp
  bodyText?: string | React.ReactElement
  buttons: IButtonProps[]
  heading?: string
  subHeading?: string
  errorMessage?: string
  subTitleHasPartialBolding?: boolean
  centered?: boolean
  containerSx?: SxStyleProp
  imageDimensions?: IImageDimensions
  imageSx?: SxStyleProp
  listItemData?: IListItem[]
  titleSx?: SxStyleProp
  listItemContainerSx?: SxStyleProp
  modalSx?: SxStyleProp
  isImageContain?: boolean
  buttonContainerSx?: SxStyleProp
}

export const InfoBlock: React.FC<IInfoBlockProps> = ({
  bodyText,
  buttons,
  centered = false,
  containerSx,
  errorMessage,
  heading,
  isImageContain = false,
  imageDimensions,
  imageSrc,
  imageSx,
  listItemContainerSx,
  listItemData,
  subHeading,
  subtitle,
  subTitleHasPartialBolding = true,
  subtitleSx,
  subtitleTag,
  title,
  titleSx,
  buttonContainerSx,
}) => {
  return (
    <FlexColumnLayout
      sx={{
        alignItems: centered ? "center" : "flex-start",
        maxWidth: 400,
        ...containerSx,
      }}
    >
      <AnimatePresence animation="fadeAndExpand">
        {errorMessage && <NoticeBox level="error" text={errorMessage} />}
      </AnimatePresence>
      {imageSrc && (
        <PreloadImage
          src={imageSrc}
          dimensions={imageDimensions ? imageDimensions : { height: 180, width: 160 }}
          innerStyle={{ backgroundSize: isImageContain ? "contain" : undefined }}
          containerStyle={{
            width: 180,
            marginTop: 20,
            ...imageSx,
          }}
        />
      )}
      {heading && (
        <Text
          tag={"h5"}
          textColor={theme.colors.ui2}
          sx={{
            paddingTop: imageSrc ? 17 : 0,
          }}
        >
          {heading}
        </Text>
      )}
      {title && (
        <Text
          tag={"h3Serif"}
          sx={{
            paddingY: 10,
            textAlign: centered ? "center" : "left",
            ...titleSx,
          }}
        >
          {title}
        </Text>
      )}
      {subHeading && (
        <Text
          tag={"body"}
          textColor={theme.colors.ui2}
          sx={{
            paddingBottom: 15,
          }}
        >
          {subHeading}
        </Text>
      )}
      <Text
        sx={{ textAlign: centered ? "center" : "left", ...subtitleSx }}
        tag={subtitleTag || "body"}
        textColor={theme.colors.ui2}
        hasPartialBolding={subTitleHasPartialBolding}
      >
        {subtitle}
      </Text>

      {listItemData && (
        <ListItemBox
          listItems={listItemData}
          containerSx={{ marginTop: 24, ...listItemContainerSx }}
        />
      )}
      {bodyText && bodyText}
      <FlexRowLayout
        sx={{
          paddingTop: 20,
          ...buttonContainerSx,
        }}
      >
        {buttons.map((buttonProps, index) => (
          <Button
            key={index}
            {...buttonProps}
            sx={{ marginLeft: index !== 0 ? 12 : 0, ...buttonProps.sx }}
          />
        ))}
      </FlexRowLayout>
    </FlexColumnLayout>
  )
}
