import gql from "graphql-tag"

export const GET_SUPPORT_COVERAGE = gql`
  query getSupportCoverage {
    allSupport_coverages {
      edges {
        node {
          schedule {
            week_day
            week_day_name
            start_hour
            end_hour
            is_available
            timezone
          }
        }
      }
    }
  }
`
