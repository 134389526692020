import React from "react"
import { FlexColumnLayout, Text } from "@northone/ui"
import { theme } from "@layouts/theme"
import { YearlyAccountStatements } from "@generated/graphql"
import { AccountStatementListItem } from "./account-statement-list-item"

interface IAccountStatementListItemGroupProps {
  yearlyStatementGroup: YearlyAccountStatements
}

/**
 * Extends the ListItem primitive
 */
export const AccountStatementListItemGroup: React.FC<IAccountStatementListItemGroupProps> = ({
  yearlyStatementGroup,
}) => {
  return (
    <FlexColumnLayout>
      <Text
        tag="body-small"
        key={"" + yearlyStatementGroup.year}
        textColor={theme.colors.ui2}
        sx={{
          marginLeft: 10,
          marginTop: 20,
          fontSize: "14px",
          lineHeight: "20px",
          fontWeight: "normal",
        }}
      >
        {yearlyStatementGroup.year}
      </Text>
      {yearlyStatementGroup.monthlyStatements.map((statement, idx) => (
        <AccountStatementListItem statement={statement} key={idx} />
      ))}
    </FlexColumnLayout>
  )
}
