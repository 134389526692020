import { TransactionsListFiltersInput, TransactionsListQuery } from "@generated/graphql"
import { TListLabel } from "@components/primitive/list-label/list-labels"

type TFilterKey = keyof TransactionsListFiltersInput

const searchFilterKeys: TFilterKey[] = ["amount", "text", "transactionTypes", "datetime"]

export const isSearching = (filters: TransactionsListFiltersInput) =>
  (Object.keys(filters) as TFilterKey[]).reduce(
    (acc, curr) => acc || searchFilterKeys.includes(curr),
    false,
  )

export const TRANSACTION_LIST_LABELS: TListLabel[] = [
  { title: "Date", alignment: "start", widthRatio: 0.2 },
  { title: "Counterparty", alignment: "start", widthRatio: 0.4 },
  { title: "Method", alignment: "end", widthRatio: 0.2 },
  { title: "Amount", alignment: "end", widthRatio: 0.2 },
]

export const mergeTransactionGroups = (
  previousResult: TransactionsListQuery,
  fetchMoreResult?: TransactionsListQuery,
) => {
  const previousResultTransactions = previousResult.transactionsList?.transactionListItems || []

  const fetchMoreTransactions = fetchMoreResult?.transactionsList?.transactionListItems || []

  if (
    previousResult.transactionsList?.pagination?.cursor ===
    fetchMoreResult?.transactionsList?.pagination?.cursor
  ) {
    return previousResultTransactions
  }
  return [...previousResultTransactions, ...fetchMoreTransactions].reduce(
    (acc, curr) => (acc.find((transaction) => transaction.id === curr.id) ? acc : [...acc, curr]),
    [] as typeof fetchMoreTransactions[0][],
  )
}
