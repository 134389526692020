import React from "react"
import { Box, Flex } from "rebass"
import { PerksCard } from "./perks-card"
import { TPerk } from "../types"

interface IPerksCardsProp {
  perks: TPerk[]
  hasSearchValue: boolean
}

export const PerksCards: React.FC<IPerksCardsProp> = ({ perks, hasSearchValue }) => {
  return (
    <Box>
      <Flex
        flexWrap={"wrap"}
        alignItems={"flex-start"}
        sx={{
          gap: "32px",
        }}
      >
        {perks.map((perk: TPerk) => (
          <PerksCard perk={perk} key={perk.node._meta.uid} hasSearchValue={hasSearchValue} />
        ))}
      </Flex>
    </Box>
  )
}
