import React from "react"
import moment from "moment"
import { formatMoney } from "accounting"
import { OverflowListItem } from "../overflow/overflow-list-item"
import { AlignedColumnLayout } from "../aligned-column-layout"
import { Text } from "@components/primitive/text/text"
import { CounterpartyAvatar } from "../../avatar/counterparty-avatar"
import { WithHighlight } from "@components/primitive/highlight/highlight"
import {
  getIsPendingTransaction,
  getTransactionTypeCopy,
  getTransactionTypeDetails,
} from "@utils/mapping/transactions.mapping"
import { theme } from "@layouts/theme"
import { TransactionStatusEnum, TransactionTypeEnum } from "@generated/graphql"
import { FlexColumnLayout } from "@layouts/flex"
import i18n from "@i18n/i18n"

export interface ITransactionListItemProps {
  date: string
  counterpartyName: string
  counterpartyLogoSrc?: string
  counterpartyLogoSize?: number
  type: TransactionTypeEnum
  amount: number
  onClick?: () => void
  isActive?: boolean
  borderRadius?: number
  subtitle?: string
  status?: TransactionStatusEnum | null
  returnedBy?: string
}

const getSubtitle = ({
  type,
  status,
  amount,
  returnedBy,
}: {
  type: TransactionTypeEnum
  status?: TransactionStatusEnum | null
  amount: number
  returnedBy?: string
}): { text: string; color: string } | null => {
  if (type === TransactionTypeEnum.SUB_ACCOUNT_TRANSFER) {
    return {
      text: i18n.t("accounts.subAccounts.envelopeAdjustment"),
      color: theme.colors.ui3,
    }
  }

  if (status && getIsPendingTransaction(status) && type !== TransactionTypeEnum.MAILED_CHECK) {
    return { text: i18n.t("transactions.status.pending"), color: theme.colors.ui3 }
  }
  if (type === TransactionTypeEnum.MAILED_CHECK) {
    switch (status) {
      case TransactionStatusEnum.INITIATED:
        return { text: i18n.t("transactions.status.pending"), color: theme.colors.ui3 }
      case TransactionStatusEnum.CREATED:
        return { text: i18n.t("transactions.status.mailedCheckCreated"), color: theme.colors.ui3 }
      case TransactionStatusEnum.CANCELED:
        return { text: i18n.t("transactions.status.canceled"), color: theme.colors.error100 }
      case TransactionStatusEnum.FAILED:
        return { text: i18n.t("transactions.status.failed"), color: theme.colors.error100 }
      case TransactionStatusEnum.EXPIRED:
        return { text: i18n.t("transactions.status.expired"), color: theme.colors.error100 }
      default:
        break
    }
  }

  if (type === TransactionTypeEnum.MAILED_CHECK_REFUND) {
    return { text: i18n.t("transactions.status.refunded"), color: theme.colors.error100 }
  }

  if (type === TransactionTypeEnum.DOMESTIC_WIRE && amount > 0) {
    return {
      text: i18n.t("transactions.listItem.holdTime", { holdTime: "2" }),
      color: theme.colors.ui3,
    }
  }
  if (status === TransactionStatusEnum.IN_REVIEW) {
    return { text: i18n.t("transactions.status.inReview"), color: theme.colors.ui3 }
  }
  if ([TransactionTypeEnum.WIRE_REVERSAL, TransactionTypeEnum.FEE_REVERSAL].includes(type)) {
    return { text: i18n.t("transactions.types.paymentDeclined"), color: theme.colors.error100 }
  }
  // Note: Re-implement the `returnedBy` reference when it is consistently provided. [NOR-1282]
  if (
    returnedBy ||
    type === TransactionTypeEnum.WIRE_RETURN ||
    type === TransactionTypeEnum.ACH_RETURN
  ) {
    const temporarilyShowingGenericCopy = true
    return {
      text:
        type === TransactionTypeEnum.ACH_RETURN
          ? i18n.t("transactions.listItem.returned")
          : temporarilyShowingGenericCopy && type === TransactionTypeEnum.WIRE_RETURN
          ? i18n.t("transactions.listItem.returnedByReceivingBank")
          : i18n.t("transactions.listItem.returnedBy", { returnedBy }),
      color: theme.colors.error100,
    }
  }

  if (status === TransactionStatusEnum.FAILED) {
    return { text: i18n.t("transactions.status.failed"), color: theme.colors.error100 }
  }

  return null
}

const getTitleColor = (type: TransactionTypeEnum, status?: TransactionStatusEnum | null) => {
  if (type === TransactionTypeEnum.SUB_ACCOUNT_TRANSFER) {
    if (status === TransactionStatusEnum.POSTED) {
      return theme.colors.ui1
    }

    return theme.colors.ui2
  }
  if ((status && getIsPendingTransaction(status)) || status === TransactionStatusEnum.IN_REVIEW) {
    return theme.colors.ui2
  }
  return theme.colors.ui1
}

const getThumbnailOpacity = (status: TransactionStatusEnum | undefined | null) => {
  if (!status) return 1

  return [
    TransactionStatusEnum.IN_REVIEW,
    TransactionStatusEnum.CREATED,
    TransactionStatusEnum.INITIATED,
  ].includes(status)
    ? 0.4
    : 1
}

/**
 * Extends the ListItem primitive
 */
export const TransactionListItem: React.FC<ITransactionListItemProps> = ({
  date,
  counterpartyName,
  counterpartyLogoSrc,
  counterpartyLogoSize,
  type,
  amount,
  isActive,
  onClick,
  status,
  borderRadius,
  subtitle,
  returnedBy,
}) => {
  const transactionSubTitle = subtitle || getSubtitle({ type, amount, status, returnedBy })?.text
  const subTitleColor = getSubtitle({ type, amount, status, returnedBy })?.color || theme.colors.ui3
  const titleColor = getTitleColor(type, status)
  const thumbnailOpacity = getThumbnailOpacity(status)
  const isOutgoing = amount >= 0
  const isTransactionPosted = status === TransactionStatusEnum.POSTED

  const getAmountColor = (status: TransactionStatusEnum) => {
    switch (status) {
      case TransactionStatusEnum.POSTED:
      case TransactionStatusEnum.CANCELED:
      case TransactionStatusEnum.EXPIRED:
      case TransactionStatusEnum.FAILED:
        if (!isOutgoing) {
          return theme.colors.ui1
        }
        return theme.colors.gold100

      case TransactionStatusEnum.INITIATED:
      case TransactionStatusEnum.IN_REVIEW:
        return theme.colors.ui2

      default:
        return theme.colors.ui2
    }
  }

  if (!status) return null

  return (
    <OverflowListItem isActive={isActive} onClick={onClick}>
      <AlignedColumnLayout alignment={"flex-start"} sidePadding={20} widthRadio={0.2}>
        <Text tag={"body-small"}>
          {moment(date).calendar(null, {
            lastDay: "[Yesterday]",
            sameDay: "[Today]",
            nextDay: "[Tomorrow]",
            lastWeek: "MMM D",
            sameElse: "MMM D",
          })}
        </Text>
      </AlignedColumnLayout>
      <AlignedColumnLayout alignment={"flex-start"} sidePadding={20} widthRadio={0.4}>
        <CounterpartyAvatar
          counterpartyName={counterpartyName}
          borderRadius={borderRadius}
          imageSrc={counterpartyLogoSrc || getTransactionTypeDetails(type).transactionFeedIcon}
          size={counterpartyLogoSize}
          opacity={thumbnailOpacity}
        />
        <FlexColumnLayout>
          <Text tag={"body-small"} textColor={titleColor} sx={{ paddingLeft: 12 }}>
            {counterpartyName}
          </Text>
          {transactionSubTitle && (
            <Text
              tag={"label-bold"}
              sx={{ paddingLeft: 12, lineHeight: "12px" }}
              textColor={subTitleColor}
            >
              {transactionSubTitle}
            </Text>
          )}
        </FlexColumnLayout>
      </AlignedColumnLayout>
      <AlignedColumnLayout alignment={"flex-end"} widthRadio={0.3} sidePadding={20}>
        <Text sx={{ textAlign: "right" }} tag={"body-small"} textColor={theme.colors.ui2}>
          {getTransactionTypeCopy(type, amount)}
        </Text>
      </AlignedColumnLayout>
      <AlignedColumnLayout alignment={"flex-end"} widthRadio={0.2}>
        {isOutgoing ? (
          <WithHighlight color={theme.colors.gold10} hidden={!isTransactionPosted}>
            <Text
              textColor={getAmountColor(status)}
              tag={isTransactionPosted ? "body-small-bold" : "body-small"}
            >
              {`+ ${formatMoney(amount)}`}
            </Text>
          </WithHighlight>
        ) : (
          <Text tag="body-small" textColor={getAmountColor(status)}>
            {`– ${formatMoney(Math.abs(amount))}`}
          </Text>
        )}
      </AlignedColumnLayout>
    </OverflowListItem>
  )
}
