import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { v4 as uuid } from "uuid"
import { isEqual } from "lodash-es"
import { useAppSelector } from "@core/redux/utils"
import { selectActiveBusinessID } from "@core/active-business/redux/selectors"
import { FormBody } from "@components/composite/form-body/form-body"
import { ButtonTypeEnum } from "@components/primitive/button/button"
import { TextInput } from "@components/extended/fields/text-input"
import { FlowModal } from "@components/extended/overlay/flow-modal"
import { DeleteButton } from "@components/extended/buttons/delete-button"
import { ACH_RECIPIENTS } from "@features/move-money/operations.gql"
import {
  selectACHRecipientFieldsAreValid,
  selectRecipient,
} from "@features/move-money/redux/selectors"
import { moveMoneyActions } from "@features/move-money/redux/actions"
import { selectEditRecipient } from "@features/move-money/redux/selectors"
import { MAX_RECIPIENT_NAME_LENGTH } from "@utils/constants"
import { pollWithMaxRetries } from "@utils/poll-with-max-retries"
import { FlexColumnLayout, FlexRowLayout } from "@layouts/flex"
import {
  useRoutingNumberAchIsValidLazyQuery,
  useRecipientDomesticAchUpdateMutation,
  ContactPaymentType,
  ErrorCodeEnum,
} from "@generated/graphql"
import { selectACHUpdateRecipientVariables } from "./redux/selectors"
import { DeleteRecipientModal } from "./delete-recipient-modal"
import {
  ERRORS_GENERIC,
  ERROR_DUPLICATE_ACH_CONTACT,
} from "@features/move-money/utils/i18n-translations"
import { hasGQLErrorType } from "@utils/errors"

interface IEditACHRecipientProps {
  closeOverlay: () => void
  isOpen: boolean
}

export const EditACHRecipient: React.FC<IEditACHRecipientProps> = ({ closeOverlay, isOpen }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [errorMessage, setErrorMessage] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [hasInvalidRouting, setHasInvalidRouting] = useState(false)
  const recipient = useAppSelector(selectRecipient)
  const editingRecipient = useAppSelector(selectEditRecipient)
  const [idempotencyKey, setIdempotencyKey] = useState<string>(uuid())
  const editRecipientFieldsAreValid = useAppSelector(selectACHRecipientFieldsAreValid)
  const businessId: string = useAppSelector(selectActiveBusinessID)
  const updateACHRecipientVariables = useAppSelector(
    selectACHUpdateRecipientVariables(idempotencyKey),
  )

  const buttons = [
    { children: t("buttons.goBack"), type: ButtonTypeEnum.TERTIARY, onClick: closeOverlay },
    {
      children: t("settings.pages.recipients.edit.confirmRecipient"),
      onClick: () => {
        dispatch(moveMoneyActions.resetScreenHistory())
        setErrorMessage("")
        setIsLoading(true)
        getRoutingNumberIsValid({
          variables: { routingNumber: editingRecipient?.bankRoutingNumber || "" },
        })
      },
      disabled: Boolean(
        isEqual(editingRecipient, recipient) || (editingRecipient && !editRecipientFieldsAreValid),
      ),
      isLoading: isLoading,
    },
    <DeleteButton key="delete" onClick={() => setDeleteModalOpen(true)} />,
  ]

  useEffect(() => {
    return () => {
      setIdempotencyKey(uuid())
      setHasInvalidRouting(false)
    }
  }, [recipient])

  useEffect(() => {
    if (!isEqual(editingRecipient, recipient)) {
      setErrorMessage("")
      setIsLoading(false)
    }
  }, [recipient])

  const [updateACHRecipient] = useRecipientDomesticAchUpdateMutation({
    onCompleted: () => {
      pollWithMaxRetries([
        {
          query: ACH_RECIPIENTS,
          variables: { businessId, contactPaymentType: ContactPaymentType.ACH },
        },
      ])
      setIsLoading(false)
      closeOverlay()
    },
    onError: (error) => {
      const isDuplicateContact = hasGQLErrorType(
        error,
        ErrorCodeEnum.DUPLICATE_ACH_CONTACT_NOT_ALLOWED,
      )
      setErrorMessage(isDuplicateContact ? ERROR_DUPLICATE_ACH_CONTACT : ERRORS_GENERIC)
      setIsLoading(false)
    },
  })

  const [getRoutingNumberIsValid] = useRoutingNumberAchIsValidLazyQuery({
    onCompleted: (data) => {
      if (data && data.routingNumberACHIsValid) {
        updateACHRecipient({
          variables: updateACHRecipientVariables,
        })
      } else {
        setHasInvalidRouting(true)
        setIsLoading(false)
      }
    },
    onError: () => {
      setIsLoading(false)
      setErrorMessage(ERRORS_GENERIC)
    },
    fetchPolicy: "network-only",
  })

  return (
    <>
      <DeleteRecipientModal
        recipientId={editingRecipient?.id || ""}
        paymentType={ContactPaymentType.ACH}
        isOpen={deleteModalOpen}
        closeOverlay={() => setDeleteModalOpen(false)}
        onCompleted={() => {
          setDeleteModalOpen(false)
          closeOverlay()
        }}
      />
      <FlowModal layout="form" isOpen={isOpen} onOutsideClick={closeOverlay}>
        <FormBody
          errorMessage={errorMessage}
          title={t("settings.pages.recipients.edit.title")}
          buttons={buttons}
        >
          <FlexColumnLayout>
            <TextInput
              value={editingRecipient?.name || ""}
              placeholder={t("inputs.recipientName")}
              label={t("inputs.recipientName")}
              onChange={(value) =>
                dispatch(moveMoneyActions.setEditingRecipient({ ...editingRecipient, name: value }))
              }
              maxLength={MAX_RECIPIENT_NAME_LENGTH}
            />
            <FlexRowLayout sx={{ width: "100%" }}>
              <TextInput
                value={editingRecipient?.bankRoutingNumber || ""}
                label={t("inputs.routingNumber")}
                placeholder="#########"
                onChange={(value) =>
                  dispatch(
                    moveMoneyActions.setEditingRecipient({
                      ...editingRecipient,
                      bankRoutingNumber: value,
                    }),
                  )
                }
                maxLength={9}
                errorMessage={hasInvalidRouting ? t("errors.invalidRoutingNumber") : ""}
                containerSx={{
                  width: "50%",
                  marginRight: 16,
                }}
              />
              <TextInput
                value={editingRecipient?.bankAccountNumber || ""}
                label={t("inputs.accountNumber")}
                placeholder="#########"
                onChange={(value) =>
                  dispatch(
                    moveMoneyActions.setEditingRecipient({
                      ...editingRecipient,
                      bankAccountNumber: value,
                    }),
                  )
                }
                containerSx={{
                  width: "50%",
                }}
                maxLength={17}
              />
            </FlexRowLayout>
          </FlexColumnLayout>
        </FormBody>
      </FlowModal>
    </>
  )
}
