import { useEffect, useState } from "react"
import { useLinkNewPlaidItem } from "./useLinkNewPlaidItem"
import { useExchangePublicTokenMutation, PlaidAccountType, ErrorCodeEnum } from "@generated/graphql"
import { ApolloError } from "@apollo/client"
import { hasGQLErrorType } from "@utils/errors"
import { Analytics } from "@core/analytics/actions"
import { events } from "@core/analytics/events"

type getErrorMessageProps = {
  linkNewPlaidItemErrorMessageKey?: string
  exchangeError?: ApolloError
}

function getErrorMessage({ linkNewPlaidItemErrorMessageKey, exchangeError }: getErrorMessageProps) {
  if (!linkNewPlaidItemErrorMessageKey && !exchangeError) return
  if (linkNewPlaidItemErrorMessageKey) return linkNewPlaidItemErrorMessageKey
  if (
    exchangeError &&
    hasGQLErrorType(exchangeError, ErrorCodeEnum.PLAID_FUNDING_IDENTITY_MATCH_FAILED)
  ) {
    return "settings.pages.linkedAccount.errors.identity"
  }
  return "settings.pages.linkedAccount.errors.plaidCreate"
}

export function useCreateNewPlaidItem({ accountType }: { accountType?: PlaidAccountType }) {
  const [loading, setLoading] = useState(false)
  const [exchangeError, setExchangeError] = useState<ApolloError>()
  const [plaidItemId, setPlaidItemId] = useState<string>()

  const {
    open: linkNewPlaidItem,
    reset: resetUseNewPlaidItemLink,
    errorMessageKey: linkNewPlaidItemErrorMessageKey,
    publicToken,
    institutionName,
    plaidAccountId,
    businessId,
  } = useLinkNewPlaidItem({
    handleCancel: () => {
      reset()
    },
  })

  const [exchangePublicToken] = useExchangePublicTokenMutation({
    onCompleted: (data) => {
      setLoading(false)
      const itemId = data.exchangePublicToken?.data.itemId
      if (!itemId) return
      setPlaidItemId(itemId)
    },
    onError: (error) => {
      Analytics.track(events.linkedAccounts.addAccountError)
      setExchangeError(error)
      setLoading(false)
    },
  })

  function reset() {
    setLoading(false)
    setExchangeError(undefined)
    setPlaidItemId(undefined)
    resetUseNewPlaidItemLink()
  }

  function createNewItem() {
    setLoading(true)
    linkNewPlaidItem()
  }

  useEffect(() => {
    if (!accountType || !publicToken || !plaidAccountId) return
    exchangePublicToken({
      variables: {
        accountType,
        businessId,
        plaidAccountId,
        publicToken,
        institutionName,
      },
    })
  }, [accountType, plaidAccountId, publicToken])

  useEffect(() => {
    if (!linkNewPlaidItemErrorMessageKey) return
    setLoading(false)
  }, [linkNewPlaidItemErrorMessageKey])

  const needsAccountType = publicToken && !accountType

  const errorMessageKey = getErrorMessage({ linkNewPlaidItemErrorMessageKey, exchangeError })

  return {
    createNewItem,
    reset,
    loading,
    errorMessageKey,
    needsAccountType,
    plaidItemId,
  }
}
