import { analytics } from "@northone/segment-js"
import { SegmentMiddleware } from "@northone/segment-js/dist/src/types"
import { v4 as uuidv4 } from "uuid"
import { logger } from "@utils/logger"
import { events } from "./events"

export const Analytics = {
  reset: () => {
    analytics().reset()
  },
  track: (eventName: string, properties?: any) => {
    analytics().track(eventName, properties)
  },
  alias: (userId: string) => {
    analytics().alias(userId)
  },
  page: (pageName: string, properties?: any) => {
    analytics().page(pageName, properties)
  },
  identify: (userId?: string | Record<string, any>, traits?: Record<string, any>) => {
    analytics().identify(userId, traits)
  },
  phoneNumber: (phone: string) => {
    analytics().identify({ phone })
  },
  startSession: () => {
    const sessionId = uuidv4()
    analytics().track(events.sessionStart, { sessionId: sessionId })
    analytics().addSourceMiddleware(addSessionIdToPayload(sessionId))
  },
}

export const addSessionIdToPayload = (sessionId: string): SegmentMiddleware =>
  function addSessionId({ payload, next }) {
    try {
      if (payload.obj.properties && sessionId) {
        payload.obj.properties.sessionId = sessionId
      }
    } catch (error) {
      logger.error(error)
    }
    next(payload)
  }
