import React from "react"
import { Box, SxStyleProp } from "rebass"
import { useTranslation } from "react-i18next"

import { ListLabels, TListLabel } from "@components/primitive/list-label/list-labels"
import { PlaidItem } from "@generated/graphql"
import { Text } from "@northone/ui"

import { ConnectNewItem, LinkedAccountItem } from "./linked-account-items"
import { theme } from "@layouts/theme"

const disclaimerTextStyles: SxStyleProp = { color: theme.colors.ui2, marginTop: 3 }

export const LinkedAccountLimitReachedDisclaimer = ({
  maximumItemCount,
}: {
  maximumItemCount: number
}) => {
  const { t } = useTranslation()
  const disclaimerMessage = t("settings.pages.linkedAccount.maximumLinkedAccountsMessage", {
    linkedAccountMaximum: maximumItemCount,
  })
  return (
    <Text tag="body-small" sx={disclaimerTextStyles}>
      {disclaimerMessage}
    </Text>
  )
}

type LinkedAccountsListProps = {
  linkedTopUpPlaidItemId?: string
  maximumItemCount: number
  loading: boolean
  onCreate: () => void
  onDelete: (plaidItem: PlaidItem) => void
  plaidItems: PlaidItem[]
}

export function LinkedAccountsList({
  loading,
  linkedTopUpPlaidItemId,
  maximumItemCount,
  onCreate,
  onDelete,
  plaidItems,
}: LinkedAccountsListProps) {
  const { t } = useTranslation()

  const labels: TListLabel[] = [
    {
      title: t("settings.pages.linkedAccount.list.institution"),
      widthRatio: 0.4,
      alignment: "start",
    },
    {
      title: t("settings.pages.linkedAccount.list.account"),
      widthRatio: 0.4,
      alignment: "start",
    },
    {
      title: "",
      widthRatio: 0.2,
      alignment: "end",
    },
  ]

  const itemHasActiveTopUp = (item: PlaidItem) => {
    if (linkedTopUpPlaidItemId == undefined) return false
    return item.id === linkedTopUpPlaidItemId
  }

  return (
    <Box>
      <ListLabels labels={labels} />
      {plaidItems.map((item) => (
        <LinkedAccountItem
          key={item.id}
          plaidItem={item}
          onDelete={() => onDelete(item)}
          hasActiveTopUp={itemHasActiveTopUp(item)}
        />
      ))}
      {plaidItems.length < maximumItemCount ? (
        <ConnectNewItem onClick={onCreate} loading={loading} />
      ) : (
        <LinkedAccountLimitReachedDisclaimer maximumItemCount={maximumItemCount} />
      )}
    </Box>
  )
}
