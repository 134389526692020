import React from "react"
import { useAppSelector } from "@core/redux/utils"
import { events } from "@core/analytics/events"
import { MoveMoneySentCard } from "@features/move-money/shared/sent-card"
import { SENT_DOMESTIC_WIRE_SUBTITLE_I18N } from "@features/move-money/utils/i18n-translations"
import { translate } from "@i18n/i18n"
import { wireCutOffTimeInLocalTime } from "../utils/helpers"

export const DomesticWireSentCard: React.FC = () => {
  const bankName = useAppSelector((state) => state.moveMoney.recipient.bankName)

  return (
    <MoveMoneySentCard
      doneEvent={events.moveMoney.domesticWire.done}
      subtitle={translate(SENT_DOMESTIC_WIRE_SUBTITLE_I18N, {
        bankName: bankName,
        cutOffTime: wireCutOffTimeInLocalTime(),
      })}
    />
  )
}
