import { ActiveBusinessActionEnum, TActiveBusinessActions } from "./actions"

export interface IActiveBusinessState {
  businessId: string
}

export const initialActiveBusinessState = {
  businessId: "",
}

export const activeBusinessReducer = (
  state: IActiveBusinessState = initialActiveBusinessState,
  action: TActiveBusinessActions,
): IActiveBusinessState => {
  switch (action.type) {
    case ActiveBusinessActionEnum.SET_BUSINESS_ID:
      return {
        ...state,
        businessId: action.payload,
      }
    default:
      return state
  }
}
