/**
 * Shared Brand Styles as per Design System
 * Colors are named by opacity where 100 is fully opaque, 80 is 80% opaque and so on
 */
export const theme = {
  mobileBreakpoint: 720,
  breakpoints: ["720px", "1281px", "1367px", "1441px", "1921px"],
  colors: {
    brown100: "#41351F",
    brown80: "#675D4C",
    brown60: "#8D8679",
    brown30: "#C6C3BC",
    brown10: "#ECEBE9",
    black1: "#20191F",
    blackPrimary: "#201A19",
    blackRich: "#160D0B",
    blackTint1: "#756C6A",
    blackTint2: "#BDB6B4",
    blackTint3: "#EEEAE9",
    blue100: "#0D4E7D",
    blue80: "#3D7297",
    blue60: "#6E95B1",
    blue30: "#B7CAD9",
    blue10: "#E7EEF3",
    copperDark: "#A05F27",
    copperTint4: "#F8F0E6",
    goldTint1: "#EDCE81",
    goldTint2: "#F2DEAB",
    goldTint3: "#F6EBCD",
    goldTint4: "#FFF7E7",
    gold100: "#938666",
    gold80: "#A99E85",
    gold60: "#BEB6A3",
    gold30: "#DFDBD2",
    gold20: "#F2DEAB",
    gold10: "#F9F8F5",
    goldPrimary: "#D9B560",
    goldSphere: "#F4F1E8",
    goldDark: "#C99B40",
    greenPerks: "#3E7256",
    green100: "#69999F",
    green80: "#78A4A9",
    green60: "#A5C2C5",
    green30: "#D2E1E3",
    green10: "#F0F5F6",
    greenTint3: "#DCE4DE",
    red100: "#720909",
    red80: "#8E3A3A",
    red60: "#AA6B6B",
    red30: "#D5B6B6",
    red10: "#F1E7E7",
    purple100: "#3B3649",
    purple80: "#625E6D",
    purple60: "#898692",
    purple30: "#C5C3C9",
    purple10: "#ECEBED",
    ui1: "#272F36",
    ui2: "#858D92",
    ui3: "#AAB1BD",
    ui4: "#D9DDE4",
    ui5: "#F0F3F6",
    ui6: "#F7F7F7",
    ui7: "#FFF",
    error100: "#D83A17",
    error10: "#FDF5F3",
    errorTint: "#FAE9E8",
    errorPrimary: "#C62E0D",
  },
  textStyles: {
    h1: {
      fontSize: "48px",
      lineHeight: "64px",
      letterSpacing: "0.5px",
      fontWeight: 300,
      fontFamily: "Plantin MT Pro",
    },
    h2: {
      fontSize: "36px",
      lineHeight: "40px",
      fontWeight: 300,
      fontFamily: "Plantin MT Pro",
    },
    h3: {
      fontSize: "28px",
      lineHeight: "32px",
      fontWeight: 500,
      fontFamily: "Graphik",
    },
    h3Serif: {
      fontSize: "28px",
      lineHeight: "32px",
      fontWeight: 300,
      fontFamily: "Plantin MT Pro",
    },
    h4: {
      fontSize: "20px",
      lineHeight: "32px",
      letterSpacing: "0.25px",
      fontWeight: 500,
      fontFamily: "Graphik, Arial",
    },
    h5: {
      fontSize: "10px",
      lineHeight: "16px",
      letterSpacing: "1px",
      fontWeight: 500,
      fontFamily: "Graphik, Arial",
      textTransform: "uppercase",
    },
    "body-large": {
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: "normal",
      fontFamily: "Graphik, Arial",
    },
    body: {
      fontSize: "14px",
      lineHeight: "20px",
      fontWeight: "normal" as const,
      fontFamily: "Graphik, Arial",
    },
    "body-small": {
      fontSize: "12px",
      lineHeight: "17px",
      fontWeight: "normal",
      fontFamily: "Graphik, Arial",
    },
    "body-large-bold": {
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: 500,
      fontFamily: "Graphik, Arial",
    },
    "body-x-large": {
      fontSize: "20px",
      lineHeight: "28px",
      fontWeight: "normal",
      fontFamily: "Graphik, Arial",
    },
    "body-bold": {
      fontSize: "14px",
      lineHeight: "20px",
      fontWeight: 500,
      fontFamily: "Graphik, Arial",
    },
    "body-small-bold": {
      fontSize: "12px",
      lineHeight: "17px",
      fontWeight: 500,
      fontFamily: "Graphik, Arial",
    },
    label: {
      fontSize: "10px",
      lineHeight: "24px",
      fontWeight: "normal",
      letterSpacing: "0.2px",
      fontFamily: "Graphik, Arial",
    },
    "label-bold": {
      fontSize: "10px",
      lineHeight: "24px",
      fontWeight: 500,
      letterSpacing: "0.2px",
      fontFamily: "Graphik, Arial",
    },
    "amount-input": {
      fontSize: "44px",
      lineHeight: "48px",
      fontWeight: 300,
      letterSpacing: "0.5px",
      fontFamily: "Graphik, Arial",
    },
    "body-x-small": {
      fontSize: "10px",
      lineHeight: "14px",
      fontWeight: "normal",
      fontFamily: "Graphik, Arial",
    },
  },
  heights: {
    navBar: 80,
    listItems: {
      verySmall: 54,
      small: 60,
      medium: 63,
      large: 68,
    },
  },
  spacing: {
    sidebar: 20,
    transactionPanel: {
      x: 24,
      y: 28,
    },
    listItemPadding: 16,
    baseLayoutPaddingBottom: 120,
    appHorizontalPadding: 36,
  },
  maxWidth: 1295,
  gutterWidth: 48,
  zIndex: {
    navBar: 2,
    flowModal: 3,
    dialogModal: 4,
    progressBarContainer: 2,
    progressBar: 1,
  },
  boxShadow: "0px 3px 12px rgba(0, 0, 0, 0.12)",
  fontFamily: {
    plantin: "Plantin MT Pro",
  },
  focusOutline: "#CFD3DA",
} as const

export type TThemeColor = typeof theme.colors[keyof typeof theme.colors]
