import { ApolloClient, InMemoryCache } from "@apollo/client"
import { createPrismicLink } from "apollo-link-prismic"
import { GET_SHARE_AND_EARN_CAMPAIGN_DETAILS } from "./queries"
import { config } from "@utils/environment"
import { useEffect, useState } from "react"
import { formatMoney } from "accounting"
import { IAllShareEarnResponse } from "@features/share-and-earn/types"
import { Analytics } from "@core/analytics/actions"
import { events } from "@core/analytics/events"

export const campaignRepoClient = new ApolloClient({
  link: createPrismicLink({
    uri: config.campaignsPrismicUri,
    accessToken: config.campaignsPrismicToken,
  }),
  cache: new InMemoryCache(),
})

export const getShareAndEarnIncentiveAmount = async () => {
  const { data, error } = await campaignRepoClient.query<IAllShareEarnResponse>({
    query: GET_SHARE_AND_EARN_CAMPAIGN_DETAILS,
  })

  if (error) {
    return Analytics.track(events.shareAndEarn.incentiveError, { source: "wb" })
  }

  const amount = data.allShare_earns.edges[0].node.offer_details[0].incentive_amount
  return amount ? amount.toString() : undefined
}

export const useShareAndEarnIncentiveAmount = () => {
  const [formattedIncentiveAmount, setFormattedIncentiveAmount] =
    useState<string | undefined>(undefined)
  const [incentiveAmountLoading, setIncentiveAmountLoading] = useState(true)

  useEffect(() => {
    getShareAndEarnIncentiveAmount()
      .then((amount) => {
        if (!amount) return
        setFormattedIncentiveAmount(formatMoney(amount, "$", 0))
      })
      .catch(() => {
        Analytics.track(events.shareAndEarn.incentiveError, { source: "wb" })
      })
      .finally(() => {
        setIncentiveAmountLoading(false)
      })
  }, [])

  return { formattedIncentiveAmount, incentiveAmountLoading }
}
