import { theme } from "@layouts/theme"
import { Text } from "@northone/ui"
import React, { useTranslation } from "react-i18next"
import { SxStyleProp } from "rebass"

const i18nKeyPaths = {
  disclaimer: "autoTopUps.landing.disclaimer",
  saveButtonLabel: "autoTopUps.landing.form.buttons.save.label",
}

const textStyles: SxStyleProp = { color: theme.colors.ui2 }

type TopUpDisclaimerProps = {
  customerFullName: string
  date: string
  maximumTopUpsPerThirtyDays: number
}

export const TopUpDisclaimer = ({
  customerFullName,
  date,
  maximumTopUpsPerThirtyDays,
}: TopUpDisclaimerProps) => {
  const { t } = useTranslation()
  const disclaimerText = t(i18nKeyPaths.disclaimer, {
    customerFullName,
    date,
    maximumTopUpsPerThirtyDays,
    saveButtonText: t(i18nKeyPaths.saveButtonLabel),
  })
  return (
    <Text tag="body-small" sx={textStyles}>
      {disclaimerText}
    </Text>
  )
}
