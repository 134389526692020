import React from "react"
import { FormBody } from "@components/composite/form-body/form-body"
import { images } from "@assets/images/images"
import { useTranslation } from "react-i18next"
import { MaskTextInput, FlexRowLayout, theme } from "@northone/ui"
import { useActivateCardDetails } from "./utils"
import { Box } from "rebass"

export const ActivateCardDetails: React.FC = () => {
  const { t } = useTranslation()

  const {
    expirationDate,
    setExpirationDate,
    securityCode,
    setSecurityCode,
    last4,
    formButtons,
    securityCodeOnBlur,
    expirationDateOnBlur,
    securityCodeOnFocus,
    expirationDateOnFocus,
    GQLErrorMessage,
    expirationDateError,
    securityCodeError,
  } = useActivateCardDetails()

  return (
    <FormBody
      imageSrc={images.cardBack}
      imageWidth={118}
      title={t("cards.activate.enterCardDetails", { last4 })}
      textStyle={{ title: { marginTop: 20 } }}
      partialTextStyle={{ title: { color: theme.colors.gold100 } }}
      buttons={formButtons}
      errorMessage={GQLErrorMessage}
      containerSx={{ paddingX: 12 }}
    >
      <FlexRowLayout sx={{ marginBottom: 44 }}>
        <MaskTextInput
          sx={{ width: 163 }}
          label={t("cards.activate.label.expirationDate")}
          placeholder={"MM/YY"}
          maskType="CARD_EXPIRY"
          value={expirationDate}
          onChange={setExpirationDate}
          errorMessage={expirationDateError}
          onBlur={expirationDateOnBlur}
          onFocus={expirationDateOnFocus}
        />
        <Box sx={{ marginLeft: 17 }}>
          <MaskTextInput
            sx={{ width: 163 }}
            label={t("cards.activate.label.securityCode")}
            placeholder={"###"}
            iconSrc={images.icons.cardSecurityCode}
            iconPosition="right"
            mask={[/\d/, /\d/, /\d/]}
            onChange={setSecurityCode}
            errorMessage={securityCodeError}
            value={securityCode}
            onBlur={securityCodeOnBlur}
            onFocus={securityCodeOnFocus}
          />
        </Box>
      </FlexRowLayout>
    </FormBody>
  )
}
