import React, { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import { v4 as uuid } from "uuid"
import { isEqual } from "lodash-es"
import { Analytics } from "@core/analytics/actions"
import { events } from "@core/analytics/events"
import { useAppSelector } from "@core/redux/utils"
import { selectActiveBusinessID } from "@core/active-business/redux/selectors"
import { FormBody } from "@components/composite/form-body/form-body"
import { ButtonTypeEnum } from "@components/primitive/button/button"
import { FlowModal } from "@components/extended/overlay/flow-modal"
import { DeleteButton } from "@components/extended/buttons/delete-button"
import {
  selectRecipient,
  selectWireRecipientFieldsAreValid,
} from "@features/move-money/redux/selectors"
import { moveMoneyActions } from "@features/move-money/redux/actions"
import { DomesticWireRecipientDetails } from "@features/move-money/domestic-wire/recipient-details/domestic-wire-details"
import { selectEditRecipient } from "@features/move-money/redux/selectors"
import { pollWithMaxRetries } from "@utils/poll-with-max-retries"
import { DOMESTIC_WIRE_RECIPIENTS } from "@features/move-money/operations.gql"
import {
  ContactPaymentType,
  useRecipientDomesticWireUpdateMutation,
  useRoutingNumberWireIsValidLazyQuery,
} from "@generated/graphql"
import { selectDomesticWireUpdateRecipientVariables } from "./redux/selectors"
import { DeleteRecipientModal } from "./delete-recipient-modal"

interface IEditDomesitcWireRecipientProps {
  closeOverlay: () => void
  isOpen: boolean
}

export const EditDomesitcWireRecipient: React.FC<IEditDomesitcWireRecipientProps> = ({
  closeOverlay,
  isOpen,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [idempotencyKey, setIdempotencyKey] = useState<string>(uuid())
  const [hasError, setHasError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [hasInvalidRouting, setHasInvalidRouting] = useState(false)
  const recipient = useAppSelector(selectRecipient)
  const editingRecipient = useAppSelector(selectEditRecipient)
  const businessId = useAppSelector(selectActiveBusinessID)
  const editRecipientDetailsAreValid = useAppSelector(selectWireRecipientFieldsAreValid)
  const domesticWireRecipientUpdateVariables = useAppSelector(
    selectDomesticWireUpdateRecipientVariables(idempotencyKey),
  )

  useEffect(() => {
    return () => {
      setIdempotencyKey(uuid())
      setHasInvalidRouting(false)
    }
  }, [recipient])

  useEffect(() => {
    if (!isEqual(editingRecipient, recipient)) {
      setHasError(false)
      setIsLoading(false)
    }
  }, [recipient])

  const [updateDomesticWireRecipient] = useRecipientDomesticWireUpdateMutation({
    onCompleted: () => {
      pollWithMaxRetries([
        {
          query: DOMESTIC_WIRE_RECIPIENTS,
          variables: { businessId, contactPaymentType: ContactPaymentType.WIRE },
        },
      ])
      setIsLoading(false)
      closeOverlay()
    },
    onError: () => {
      setIsLoading(false)
      setHasError(true)
    },
  })

  const [getRoutingNumberIsValid] = useRoutingNumberWireIsValidLazyQuery({
    onCompleted: (data) => {
      if (data && data.routingNumberWireIsValid) {
        updateDomesticWireRecipient({
          variables: domesticWireRecipientUpdateVariables,
        })
        Analytics.track(events.settings.recipient.submitWireRecipient, {
          name: editingRecipient.name,
          bankName: editingRecipient.bankName,
        })
      } else {
        setHasInvalidRouting(true)
        setIsLoading(false)
      }
    },
    onError: () => {
      setIsLoading(false)
      setHasError(true)
    },
    fetchPolicy: "network-only",
  })

  const buttons = [
    { children: t("buttons.goBack"), type: ButtonTypeEnum.TERTIARY, onClick: closeOverlay },
    {
      children: t("settings.pages.recipients.edit.confirmRecipient"),
      onClick: () => {
        dispatch(moveMoneyActions.resetScreenHistory())
        setHasError(false)
        setIsLoading(true)
        getRoutingNumberIsValid({
          variables: { routingNumber: editingRecipient?.bankRoutingNumber || "" },
        })
      },
      disabled: Boolean(
        isEqual(editingRecipient, recipient) || (editingRecipient && !editRecipientDetailsAreValid),
      ),
      isLoading: isLoading,
    },
    <DeleteButton
      key="delete"
      onClick={() => {
        setDeleteModalOpen(true)
        Analytics.track(events.settings.recipient.deleteIcon)
      }}
    />,
  ]

  return (
    <>
      <DeleteRecipientModal
        paymentType={ContactPaymentType.WIRE}
        recipientId={editingRecipient?.id || ""}
        isOpen={deleteModalOpen}
        closeOverlay={() => {
          setDeleteModalOpen(false)
          Analytics.track(events.settings.recipient.deleteDismiss)
        }}
        onCompleted={() => {
          setDeleteModalOpen(false)
          closeOverlay()
          Analytics.track(events.settings.recipient.delete)
        }}
      />
      <FlowModal layout="form" isOpen={isOpen} onOutsideClick={closeOverlay}>
        <FormBody
          errorMessage={hasError ? t("errors.generic") : ""}
          title={t("settings.pages.recipients.edit.title")}
          buttons={buttons}
        >
          <DomesticWireRecipientDetails routingNumberIsValid={!hasInvalidRouting} />
        </FormBody>
      </FlowModal>
    </>
  )
}
