import React from "react"
import { useTranslation } from "react-i18next"
import { LoanProductSection } from "../shared/loan-product-section"

type LandingLocSectionProps = {
  ctaButtonOnClick?: () => void
  currentUserIsLoanManager: boolean
  loanManagerName: string
  loanProductIsActive: boolean
}

export const LandingLocSection: React.FC<LandingLocSectionProps> = ({
  ctaButtonOnClick,
  currentUserIsLoanManager,
  loanManagerName,
  loanProductIsActive,
}) => {
  const { t } = useTranslation()
  const title = t("lending.landing.locSection.title")
  const defaultSubtitle = t("lending.landing.locSection.subtitle")
  const activeStateSubtitle = t("lending.landing.locSection.activeStateSubtitle")

  const defaultDescription = currentUserIsLoanManager
    ? t("lending.landing.locSection.description")
    : t("lending.landing.locSection.viewOnly.description", { loanManagerName })

  const activeStateDescription = currentUserIsLoanManager
    ? t("lending.landing.locSection.activeStateDescription")
    : t("lending.landing.locSection.activeStateDescriptionSecondary", { loanManagerName })

  const qualicationsIntro = t("lending.landing.qualificationsIntro")
  const qualications = [
    t("lending.landing.locSection.qualifications.accountAge"),
    t("lending.landing.locSection.qualifications.creditScore"),
    t("lending.landing.locSection.qualifications.accountBalance"),
  ]

  const manageLoanBulletPointsIntro = t("lending.landing.locSection.manageLoanBulletPointsIntro")
  const manageLoanBulletPoints = [
    t("lending.landing.locSection.manageLoanBulletPoints.accessFunds"),
    t("lending.landing.locSection.manageLoanBulletPoints.repayment"),
    t("lending.landing.locSection.manageLoanBulletPoints.viewingLoanInfo"),
  ]

  const valueProps = loanProductIsActive
    ? undefined
    : [
        {
          title: t("lending.landing.maxAmountValuePropTitle"),
          value: t("lending.landing.locSection.maxAmountValuePropValue"),
        },
        {
          title: t("lending.landing.industryLeadingValuePropTitle"),
          value: t("lending.landing.locSection.industryLeadingValuePropValue"),
        },
        {
          title: t("lending.landing.decisionTimeValuePropTitle"),
          value: t("lending.landing.locSection.decisionTimeValuePropValue"),
        },
      ]

  const subtitle = loanProductIsActive ? activeStateSubtitle : defaultSubtitle
  const description = loanProductIsActive ? activeStateDescription : defaultDescription

  const bulletPointsIntro = loanProductIsActive ? manageLoanBulletPointsIntro : qualicationsIntro
  const bulletPoints = loanProductIsActive ? manageLoanBulletPoints : qualications

  const ctaButton = ctaButtonOnClick
    ? {
        onClick: ctaButtonOnClick,
        label: t("lending.landing.locSection.applyCTAButton"),
        subtext: t("lending.landing.topBanner.applyCTASubtext"),
      }
    : undefined

  return (
    <LoanProductSection
      productSectionTitle={title}
      bannerTitle={subtitle}
      description={description}
      bulletPointsTitle={bulletPointsIntro}
      bulletPoints={bulletPoints}
      valueProps={valueProps}
      ctaButton={ctaButton}
    />
  )
}
