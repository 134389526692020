import { createAction, TActionsUnion } from "@core/redux/utils"
import { OnboardingMeQuery } from "@generated/graphql"

export enum UserActionEnum {
  SET_ME = "SET_ME",
  SET_USER_ID = "SET_USER_ID",
}

export const userActions = {
  setUserId: (userId: string) => createAction(UserActionEnum.SET_USER_ID, userId),
  setMe: (me: OnboardingMeQuery["me"]) => createAction(UserActionEnum.SET_ME, me),
}

export type TUserActions = TActionsUnion<typeof userActions>
