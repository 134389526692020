import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useReducer,
  Reducer,
  ReducerAction,
  ReducerState,
  Dispatch,
} from "react"
import { BroadcastChannel, BroadcastChannelOptions } from "broadcast-channel"

export const useClickOutside = (refs: React.RefObject<HTMLDivElement>[], cb: () => void): void => {
  const handleClickOutside = (e: any) => {
    if (!refs.map(({ current }) => !current?.contains(e.target)).includes(false)) {
      cb()
    }
  }

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true)
    return () => {
      document.removeEventListener("click", handleClickOutside, true)
    }
  })
}

export const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height,
  }
}

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())
  useEffect(() => {
    function _handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener("resize", _handleResize)
    return () => window.removeEventListener("resize", _handleResize)
  }, [])
  return windowDimensions
}

/**
 * Creates shared reducer/state between all windows of the application
 * using a wrapper around the useReducer hook. All actions are
 * passed to broadcast-channel to be shared and resolved across
 * other windows of the application
 *
 * @see https://github.com/pubkey/broadcast-channel
 *
 * @param reducer Reducer function to reduce state from actions
 * @param initialState Initial state to instantiate
 * @param channelName Distinct name for shared broadcast channel
 * @param options Options to configure the broadcast channel
 */
export const useSharedReducer = <R extends Reducer<any, any>>(
  reducer: R,
  initialState: ReducerState<R>,
  channelName: string,
  options?: BroadcastChannelOptions | undefined,
): [ReducerState<R>, Dispatch<ReducerAction<R>>] => {
  const [state, internalDispatch] = useReducer(reducer, initialState)
  const channel = useRef<BroadcastChannel>()

  useEffect(() => {
    const bus = (channel.current = new BroadcastChannel(channelName, options))
    bus.onmessage = (action: ReducerAction<R>) => {
      internalDispatch(action)
    }
    return () => {
      bus.close()
    }
  }, [channelName, options])

  const dispatch = useCallback(
    (action: ReducerAction<R>) => {
      internalDispatch(action)
      channel.current?.postMessage(action)
    },
    [internalDispatch],
  )

  return [state, dispatch]
}

export const useScrollTop = (id: string, onScroll: (scrollTop: number) => void) => {
  useEffect(() => {
    const scrollContainer = document.getElementById(id)
    const handleScroll = () => {
      onScroll(scrollContainer?.scrollTop || 0)
    }
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll)
    }
    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", handleScroll)
      }
    }
  })
}

interface KeyboardEventName {
  onArrowUp?: () => void
  onArrowRight?: () => void
  onArrowDown?: () => void
  onArrowLeft?: () => void
  onTab?: () => void
  onEnter?: () => void
  enabled?: boolean
}

export const useKeyboardEvent = ({
  onArrowUp,
  onArrowDown,
  onArrowLeft,
  onArrowRight,
  onTab,
  onEnter,
  enabled = true,
}: KeyboardEventName) => {
  const onKeyDown = (event: KeyboardEvent) => {
    if (!enabled) {
      return
    }
    if (event.key === "ArrowUp" && onArrowUp) {
      event.preventDefault()
      onArrowUp()
    }
    if (event.key === "ArrowRight" && onArrowRight) {
      event.preventDefault()
      onArrowRight()
    }
    if (event.key === "ArrowDown" && onArrowDown) {
      event.preventDefault()
      onArrowDown()
    }
    if (event.key === "ArrowLeft" && onArrowLeft) {
      event.preventDefault()
      onArrowLeft()
    }
    if (event.key === "Tab" && onTab) {
      event.preventDefault()
      onTab()
    }
    if (event.key === "Enter" && onEnter) {
      onEnter()
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", onKeyDown)
    return () => document.removeEventListener("keydown", onKeyDown)
  })
}

export const useBrowserOnBackClick = (onBackClick: () => void) => {
  useEffect(() => {
    const onBackPress = (ev: { preventDefault: () => void }) => {
      ev.preventDefault()
      onBackClick()
    }

    window.addEventListener("popstate", onBackPress)
    return () => {
      window.removeEventListener("popstate", onBackPress)
    }
  })
}

export const replaceURLWith = (url: string) => {
  window.history.pushState({}, "", url)
}

/**
 * Uses touch screen feature detection and user agent to determine whether the visitor's device
 * is a mobile device.
 *
 * See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent
 *
 * @returns boolean
 */
export const useIsMobileDevice = (): boolean => {
  const [hasTouchScreen, setHasTouchScreen] = useState(false)
  const [isMobileDevice, setIsMobileDevice] = useState(false)

  useEffect(() => {
    // Ensure the device has a touch screen
    setHasTouchScreen("maxTouchPoints" in navigator && navigator.maxTouchPoints > 0)

    // Ensure the device is a mobile device
    const { userAgent } = navigator
    setIsMobileDevice(hasTouchScreen && userAgent.includes("Mobi" || "iPad"))
  }, [hasTouchScreen])

  return isMobileDevice
}
