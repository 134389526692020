import { TMoveMoneyScreenNames } from "@features/move-money/router/move-money"
import { TransactionTypeEnum, Location } from "@generated/graphql"
import { ISubAccount } from "@typedefs/types"

export type IAddress = Omit<Location, "__typename">

export interface IGenericRecipient {
  id?: string
  name?: string
  billPaymentMethod?: TransactionTypeEnum.ACH | TransactionTypeEnum.CHECK_DEPOSIT
  bankRoutingNumber?: string
  bankAccountNumber?: string
  address?: IAddress
  bankName?: string
  logo?: string
  relationship?: string
  otherRelationship?: string
}

export interface IMoveMoneyState {
  paymentMethod?: TransactionTypeEnum
  recipient: IGenericRecipient
  editingRecipient: IGenericRecipient
  amount: string
  subAccount: ISubAccount
  screenHistory: TMoveMoneyScreenNames[]
  purpose: string
  nonActivatedCardIds: string[] | null
  wireCompleted: boolean
  wireEnabled: boolean | undefined
}

export const initialAddress: Omit<Location, "__typename"> = {
  city: "",
  streetAddressLine1: "",
  streetAddressLine2: "",
  country: "",
  postalCode: "",
  provinceState: "",
}

export const initialRecipient: IGenericRecipient = {
  id: "",
  name: "",
  address: initialAddress,
  bankName: "",
  bankRoutingNumber: "",
  bankAccountNumber: "",
  relationship: "",
  otherRelationship: "",
}

export const initialMoveMoneyState: IMoveMoneyState = {
  paymentMethod: undefined,
  recipient: initialRecipient,
  editingRecipient: initialRecipient,
  amount: "",
  purpose: "",
  subAccount: {
    id: "",
    name: "",
    balance: 0,
    isDefault: false,
  },
  screenHistory: ["PAYMENT_METHOD"],
  nonActivatedCardIds: null,
  wireCompleted: false,
  wireEnabled: undefined,
}
