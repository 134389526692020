import React, { useCallback, useEffect, useState } from "react"
import { FlexColumnLayout } from "@northone/ui"
import { useTranslation } from "react-i18next"
import { v4 as uuid } from "uuid"
import { SxStyleProp } from "rebass"

import { InfoBlock } from "@components/composite/info-block/info-block"
import { ButtonTypeEnum } from "@components/primitive/button/button"
import { TextInput } from "@components/extended/fields/text-input"
import { Image } from "rebass"
import { images } from "@assets/images/images"
import {
  useCreateInvoiceAccountsPayableContactMutation,
  useSearchBillerDirectoryLazyQuery,
} from "@generated/graphql"
import { debounce } from "@utils/debounce"
import { selectActiveBusinessID } from "@core/active-business/redux/selectors"
import { useAppSelector } from "@core/redux/utils"
import { CorporationContainer } from "./corporation-container"
import { events } from "@core/analytics/events"
import { Analytics } from "@core/analytics/actions"

export type CorporationList = {
  billerName: string
  rppsBillerId: string
  billerAccountNumberPatterns?: string[] | null | undefined
}

interface AddNewCorporationProps {
  onContactCreated: (contactId: string) => Promise<void>
  closeOverlay: () => void
}

// TODO: See how we can move some states into the parent components to make this component more reusable
export function AddNewCorporation({ onContactCreated, closeOverlay }: AddNewCorporationProps) {
  const { t } = useTranslation()
  const businessId = useAppSelector(selectActiveBusinessID)

  const [corporationName, setCorporationName] = useState("")
  const [corporationId, setCorporationId] = useState("")
  const [corporationList, setCorporationList] = useState<CorporationList[]>()
  const [idempotencyKey, setIdempotencyKey] = useState<string>(uuid())
  const [isLoading, setIsLoading] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [isSearchResultOpen, setIsSearchResultOpen] = useState(false)
  const [hasTrackedSearchInitiated, setHasTrackedSearchInitiated] = useState(false)

  const [createContact] = useCreateInvoiceAccountsPayableContactMutation({
    variables: {
      input: {
        businessId,
        name: corporationName,
        idempotencyKey,
        rppsBillerDetails: {
          billerName: corporationName,
          rppsBillerId: corporationId,
        },
      },
    },
    onError: () => {
      setHasError(true)
      setIsLoading(false)
    },
  })

  const [searchBillDirectory, { loading: isSearchBillDirectoryLoading }] =
    useSearchBillerDirectoryLazyQuery({
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        if (data.searchBillerDirectory?.billers) {
          setCorporationList(data.searchBillerDirectory.billers)
        }
        setIsSearchResultOpen(true)
      },
    })

  const isButtonDisabled = Boolean(!corporationName) || Boolean(!corporationId)

  // We want to debounce the search function so that we don't make a request on every keystroke
  const debouncedSearch = useCallback(debounce(searchBillDirectory, 250), [])

  function handleChangeName(corporationName: string) {
    setCorporationName(corporationName)

    if (corporationName.length === 0) {
      setIsSearchResultOpen(false)
      return
    }

    debouncedSearch({ variables: { billerName: corporationName } })
  }

  function onCorporationSelected(corporationName: string, corporationId: string) {
    setCorporationName(corporationName)
    setCorporationId(corporationId)
    setIsSearchResultOpen(false)
  }

  function resetState() {
    setHasError(false)
    setCorporationName("")
    setCorporationId("")
    setIdempotencyKey(uuid())
  }

  async function onAddNewCorporationButtonClick() {
    setHasError(false)
    setIsLoading(true)
    const result = await createContact().catch()

    const contactId = result?.data?.contactCreate?.contact.id

    if (!result || !contactId) {
      setHasError(true)
      setIsLoading(false)
      return
    }

    Analytics.track(events.invoicePayments.invoiceDetails.addContact.billPayDirectoryContactSaved, {
      contactName: corporationName,
      contactId,
      rppsBillerId: corporationId,
    })

    // on success, close overlay
    setIsLoading(false)
    resetState()
    closeOverlay()
    onContactCreated(contactId)
  }

  useEffect(() => {
    if (corporationName !== "" && !hasTrackedSearchInitiated) {
      Analytics.track(
        events.invoicePayments.invoiceDetails.addContact.billPayDirectorySearchInitiated,
      )
      setHasTrackedSearchInitiated(true)
    }
  }, [hasTrackedSearchInitiated, corporationName])

  return (
    <InfoBlock
      containerSx={styles.containerStyle}
      buttonContainerSx={styles.buttonContainerStyle}
      errorMessage={hasError ? t("errors.generic") : ""}
      bodyText={
        <FlexColumnLayout sx={styles.bodyTextStyle}>
          <TextInput
            value={corporationName}
            onChange={handleChangeName}
            placeholder={t(i18nKeyPaths.placeholder)}
            maxLength={32}
            icon={<Image src={images.icons.search} sx={{ marginRight: 2 }} />}
          />
          <FlexColumnLayout sx={{ minHeight: "15vh" }}>
            <CorporationContainer
              corporationList={corporationList}
              isDataLoading={isSearchBillDirectoryLoading}
              onSelect={onCorporationSelected}
              isSearchResultOpen={isSearchResultOpen}
            />
          </FlexColumnLayout>
        </FlexColumnLayout>
      }
      buttons={[
        {
          type: ButtonTypeEnum.PRIMARY_BLUE,
          children: t(i18nKeyPaths.confirmButton),
          canContinueWithKey: true,
          onClick: onAddNewCorporationButtonClick,
          isLoading,
          disabled: isButtonDisabled,
        },
      ]}
    />
  )
}

const styles: Record<string, SxStyleProp> = {
  buttonContainerStyle: { paddingTop: 5 },
  containerStyle: { maxWidth: "100%", height: "100%" },
  bodyTextStyle: {
    width: "100%",
    paddingTop: "22px",
    paddingBottom: 0,
    position: "relative",
  },
  searchResultStyle: {
    padding: "18px",
    boxShadow: "0px 4px 16px 0px rgba(110, 110, 110, 0.12)",
    maxHeight: "400px",
    height: "auto",
    overflowY: "scroll",
    width: "100%",
    position: "absolute",
    background: "white",
  },
}

const i18nKeyPaths = {
  placeholder: "invoicePayments.modal.invoiceDetails.addNewContact.corporation.placeholder",
  confirmButton: "invoicePayments.modal.invoiceDetails.addNewContact.confirmButton",
}
