import React from "react"
import { useTranslation } from "react-i18next"
import { DateTime } from "luxon"
import { FlexRowLayout } from "@layouts/flex"
import { Dropdown } from "@components/composite/dropdown/dropdown"
import { DatepickerDropdown } from "@components/composite/datepicker-dropdown/datepicker-dropdown"
import { Text } from "@components/primitive/text/text"
import { theme } from "@layouts/theme"

export type Filters = {
  dateFilter: "7d" | "30d" | "custom"
  startDateTime: string
  endDateTime: string
}

export const defaultFilters: Filters = {
  dateFilter: "7d",
  startDateTime: DateTime.now().minus({ days: 7 }).startOf("day").toISO(),
  endDateTime: DateTime.now().endOf("day").toISO(),
} as const

export interface IRevenueHubFiltersProps {
  filters: Filters
  onChange: (filters: Filters) => void
}

export const RevenueHubFilters = ({
  filters: { startDateTime, endDateTime, dateFilter },
  onChange,
}: IRevenueHubFiltersProps) => {
  const { t } = useTranslation()

  return (
    <FlexRowLayout>
      {dateFilter === "custom" && (
        <FlexRowLayout
          sx={{ pr: "12px", borderRight: `1px solid ${theme.colors.ui4}`, mr: "12px" }}
        >
          <DatepickerDropdown
            onSelect={(date) => {
              if (date) {
                const newStartDateTime = DateTime.fromJSDate(date).startOf("day").toISO()
                onChange({ startDateTime: newStartDateTime, endDateTime, dateFilter })
              }
            }}
            selectedDate={new Date(startDateTime)}
            minDate={null}
            maxDate={new Date(endDateTime)}
            isClearDateDisabled={true}
            containerSx={{ width: 180 }}
          />
          <Text
            tag="body"
            textColor={theme.colors.ui2}
            sx={{ lineHeight: "40px", px: "8px", display: "block" }}
          >
            {t("revenueHub.filters.date.to")}
          </Text>
          <DatepickerDropdown
            onSelect={(date) => {
              if (date) {
                const newEndDateTime = DateTime.fromJSDate(date).endOf("day").toISO()
                onChange({ startDateTime, endDateTime: newEndDateTime, dateFilter })
              }
            }}
            selectedDate={new Date(endDateTime)}
            minDate={new Date(startDateTime)}
            maxDate={new Date()}
            isClearDateDisabled={true}
            containerSx={{ width: 180 }}
          />
        </FlexRowLayout>
      )}
      <Dropdown
        selectedValue={dateFilter}
        onSelect={(newDateFilter) => {
          if (newDateFilter === "custom") {
            onChange({
              startDateTime,
              endDateTime,
              dateFilter: newDateFilter,
            })
            return
          }

          // calculate the startDate/endDate for the 7d/30d preset date filters
          const newStartDateTime = DateTime.now()
            .minus({ days: newDateFilter === "7d" ? 7 : 30 })
            .startOf("day")
            .toISO()
          const newEndDateTime = DateTime.now().endOf("day").toISO()
          onChange({
            startDateTime: newStartDateTime,
            endDateTime: newEndDateTime,
            dateFilter: newDateFilter,
          })
        }}
        options={[
          {
            text: t("revenueHub.filters.date.last7Days"),
            value: "7d",
          },
          {
            text: t("revenueHub.filters.date.last30Days"),
            value: "30d",
          },
          {
            text: t("revenueHub.filters.date.custom"),
            value: "custom",
          },
        ]}
        customContainerStyle={{ width: 180 }}
      />
    </FlexRowLayout>
  )
}
