import React, { useState } from "react"

import { InfoBlockModal } from "@northone/ui"
import { useTranslation } from "react-i18next"
import { images } from "@assets/images/images"
import { Analytics } from "@core/analytics/actions"
import { logout } from "@core/auth/auth-service"
import { ButtonTypeEnum } from "@components/primitive/button/button"
import { events } from "@core/analytics/events"
import { LogoutButton } from "./logout-button"

export const LogoutModalButton = () => {
  const [isPromptModalOpen, setIsPromptModalOpen] = useState(false)
  return (
    <>
      <LogoutPromptModal
        isOpen={isPromptModalOpen}
        closeModal={() => setIsPromptModalOpen(false)}
      />
      <LogoutButton onClick={() => setIsPromptModalOpen(true)} />
    </>
  )
}

interface ILogoutPromptModalProps {
  isOpen: boolean
  closeModal: () => void
}

export const LogoutPromptModal: React.FC<ILogoutPromptModalProps> = ({ isOpen, closeModal }) => {
  const { t } = useTranslation()
  return (
    <InfoBlockModal
      title={t("logout.modalTitle")}
      isOpen={isOpen}
      closeModal={closeModal}
      modalSx={{ width: ["315px", "372px"] }}
      buttons={[
        {
          children: t("logout.button"),
          iconSrc: images.icons.logoutWhite,
          onClick: () => {
            Analytics.track(events.logout)
            logout()
          },
        },
        {
          children: t("buttons.cancel"),
          type: ButtonTypeEnum.SECONDARY,
          onClick: closeModal,
        },
      ]}
    />
  )
}
