import i18n from "@i18n/i18n"

const FEATURE = "moveMoney"
const CARD_NOT_ACTIVATED = "cardNotActivated"
const AMOUNT = "amount"
const WIRE_CONFIRM_NOTICE = "wireConfirmNotice"
const PURPOSE = "purpose"
const BUTTONS = "buttons"
const ERRORS = "errors"
const RECIPIENT_DETAILS = "recipientDetails"
const SENT = "sent"
const PAGE_TITLES = "pageTitles"
const TRANSACTIONS = "transactions"

const _AMOUNT = `${FEATURE}.${AMOUNT}`
const _RECIPIENT_DETAILS = `${FEATURE}.${RECIPIENT_DETAILS}`
const _ROUTING_NUMBER_FORM = `${_RECIPIENT_DETAILS}.routingNumber`
const _ACCOUNT_NUMBER_FORM = `${_RECIPIENT_DETAILS}.accountNumber`
const _PAYMENT_METHOD = `${FEATURE}.paymentMethod`
const _RECIPIENT = `${FEATURE}.recipient`

// Buttons - Navigation
export const BUTTONS_GO_BACK = i18n.t(`${BUTTONS}.goBack`)
export const BUTTONS_CONTINUE = i18n.t(`${BUTTONS}.continue`)

// Buttons
export const BUTTONS_CANCEL = i18n.t(`${BUTTONS}.cancel`)

// Errors
export const ERRORS_GENERIC = i18n.t(`${ERRORS}.generic`)
export const ERRORS_NETWORK = i18n.t(`${ERRORS}.network`)
export const NETWORK_ERROR_TITLE = i18n.t(`${ERRORS}.networkError.title`)
export const NETWORK_ERROR_SUBTITLE = i18n.t(`${ERRORS}.networkError.subtitle`)

// Move Money Modal
export const PAGE_TITLES_MOVEMONEY_SENDFLOW_I18N = `${PAGE_TITLES}.moveMoney.sendFlow`
export const PAGE_TITLES_MOVEMONEY_SEND = i18n.t(`${PAGE_TITLES}.moveMoney.send`)

// Removed Feature Panel
const _FEATURE_REMOVAL = `${TRANSACTIONS}.featureRemoval`
export const TRANS_FEATURE_REMOVAL_BILL_PAY_TITLE = i18n.t(`${_FEATURE_REMOVAL}.billPayTitle`)
export const TRANS_FEATURE_REMOVAL_MAILED_CHECK_TITLE = i18n.t(
  `${_FEATURE_REMOVAL}.mailedCheckTitle`,
)
export const TRANS_FEATURE_REMOVAL_EXTERNAL_TRANSFER_TITLE = i18n.t(
  `${_FEATURE_REMOVAL}.externalTransferTitle`,
)
export const TRANS_FEATURE_REMOVAL_WIRE_TITLE = i18n.t(`${_FEATURE_REMOVAL}.wireTitle`)
export const TRANS_FEATURE_REMOVAL_CHECK_DEPOSITS = i18n.t(`${_FEATURE_REMOVAL}.checkDeposits`)
export const SETTINGS_FEATURE_REMOVAL_SUBTITLE = i18n.t(`settings.featureRemoval.subtitle`)
export const SETTINGS_FEATURE_REMOVAL_SUBTITLE2 = i18n.t(`settings.featureRemoval.subtitle2`)
export const SETTINGS_FEATURE_REMOVAL_CTA_BTN = i18n.t(`settings.featureRemoval.ctaButton`)

// Router - Progress Bar
const _PROGRESS_BAR = `${FEATURE}.progressBar`
export const PROGRESS_BAR_PAYMENT_METHOD = i18n.t(`${_PROGRESS_BAR}.paymentMethod`)
export const PROGRESS_BAR_RECIPIENT = i18n.t(`${_PROGRESS_BAR}.recipient`)
export const PROGRESS_BAR_RECIPIENT_DETAILS = i18n.t(`${_PROGRESS_BAR}.recipientDetails`)
export const PROGRESS_BAR_AMOUNT = i18n.t(`${_PROGRESS_BAR}.amount`)
export const PROGRESS_BAR_ACCOUNT = i18n.t(`${_PROGRESS_BAR}.account`)
export const PROGRESS_BAR_CONFIRMATION = i18n.t(`${_PROGRESS_BAR}.confirmation`)
export const PROGRESS_BAR_PAYMENT_PURPOSE = i18n.t(`${_PROGRESS_BAR}.note`)

// Discard Modal
export const DISCARD_DISCARD = i18n.t(`${FEATURE}.discard.discard`)
export const DISCARD_TITLE = i18n.t(`${FEATURE}.discard.title`)
export const DISCARD_SUBTITLE = i18n.t(`${FEATURE}.discard.subtitle`)

// Payment Method
export const PAYMENT_METHOD_TITLE = i18n.t(`${_PAYMENT_METHOD}.title`)
export const PAYMENT_METHOD_WIRE_FEE = i18n.t(`${_PAYMENT_METHOD}.wireFeeSend`)
export const PAYMENT_METHOD_WIRE_FEE_I18N = `${_PAYMENT_METHOD}.wireFeeSend`

// Shared - Amount Form
export const AMOUNT_TITLE = i18n.t(`${_AMOUNT}.title`)

// Shared - Confirm Card
const _PREVIEW_PANEL = `${TRANSACTIONS}.previewPanel`
export const PREVIEW_PANEL_EDIT = i18n.t(`${_PREVIEW_PANEL}.edit`)
export const PREVIEW_PANEL_CONFIRM = i18n.t(`${_PREVIEW_PANEL}.confirm`)
export const PREVIEW_PANEL_SEND_PAYMENT = i18n.t(`${_PREVIEW_PANEL}.sendPayment`)
export const PREVIEW_PANEL_SEND_WIRE_PAYMENT = i18n.t(`${_PREVIEW_PANEL}.sendWirePayment`)

// Shared - Recipient Select
export const RECIPIENT_LIST_ITEM_SUBTEXT_I18N = "moveMoney.recipientListItem.subtext"
export const RECIPIENT_LIST_ITEM_TITLE_I18N = `${FEATURE}.recipientListItem.title`
export const RECIPIENT_TITLE = i18n.t(`${_RECIPIENT}.title`)
export const RECIPIENT_BILLPAY_NEWPAYEE = i18n.t(`${_RECIPIENT}.billPay.newPayee`)
export const RECIPIENT_NEW_TITLE = i18n.t(`${_RECIPIENT}.new.title`)

// Shared - Send Card
const _SENT = `${FEATURE}.sent`
export const SENT_CONTINUE = i18n.t(`${_SENT}.continue`)
export const SENT_RETURN = i18n.t(`${_SENT}.return`)
export const SENT_LABEL = i18n.t(`${_SENT}.label`)
export const SENT_TITLE_I18N = `${_SENT}.title`
export const SENT_PAYMENT_TYPES_I18N = `${_SENT}.paymentTypes`

// Shared - Sub Accounts Form
export const SUBACCOUNT_TITLE = i18n.t(`${FEATURE}.subAccount.title`)

// Shared - Terms Disclaimer
export const RECIPIENT_TERMS_TEXT = i18n.t(`${_RECIPIENT}.termsText`)
export const RECIPIENT_TERMS = i18n.t(`${_RECIPIENT}.terms`)

// Shared - Transaction Limit Note
export const PAYMENT_METHOD_LIMIT = i18n.t(`${_PAYMENT_METHOD}.limit`)
export const PAYMENT_METHOD_LIMIT_NOTE = i18n.t(`${_PAYMENT_METHOD}.limitNote`)
export const PAYMENT_METHOD_DEPOSIT_AGREEMENT = i18n.t(`${_PAYMENT_METHOD}.depositAgreement`)

// Utils - Helpers
export const SENT_FIRST_I18N = `${_SENT}.first`
export const SENT_TRANSFER = i18n.t(`${_SENT}.transfer`)
export const SENT_PAYMENT = i18n.t(`${_SENT}.payment`)
export const SENT_FREQUENCY_I18N = `${_SENT}.frequency`
export const SENT_NO_END_DATE = i18n.t(`${_SENT}.noEndDate`)
export const SENT_SUBTITLE_I18N = `${_SENT}.subtitle`
export const TRANSACTIONS_EXPECTED_DURATION_I18N = `${TRANSACTIONS}.expectedDuration`

// ======================================================================================
// ACH

// Amount Form
export const ACH_LIMIT_EXCEEDED_MESSAGE_I18N = `${_AMOUNT}.limitExceededMessages.ACH`

// Recipient Details Form
export const RECIPIENT_DETAILS_CHAR_LIMIT_EXCEEDED = i18n.t(`${_RECIPIENT_DETAILS}.name.maxLength`)
export const ERROR_DUPLICATE_ACH_CONTACT = i18n.t(`${_RECIPIENT_DETAILS}.errors.duplicateContact`)

// ======================================================================================
// Wire

// Activate Card Panel
const _CARD_NOT_ACTIVATED = `${FEATURE}.${CARD_NOT_ACTIVATED}`
export const CARD_NOT_ACTIVATED_TITLE = i18n.t(`${_CARD_NOT_ACTIVATED}.title`)
export const CARD_NOT_ACTIVATED_SUBTITLE = i18n.t(`${_CARD_NOT_ACTIVATED}.subtitle`)
export const CARD_NOT_ACTIVATED_BUTTON = i18n.t(`${_CARD_NOT_ACTIVATED}.button`)
export const CARD_NOT_ACTIVATED_SUPPORT = i18n.t(`${_CARD_NOT_ACTIVATED}.support`)

// Amount Form
export const AMOUNT_INSUFFICIENT_FUNDS = i18n.t(`${_AMOUNT}.insufficientFundsMessage`)
export const AMOUNT_MINIMUM_AMOUNT = i18n.t(`${_AMOUNT}.minimumAmountMessage`)
export const AMOUNT_BETWEEN_LIMIT_I18N = `${_AMOUNT}.betweenLimitMessage`

// Confirm Card
export const WIRE_CUTOFF = `${FEATURE}.wireCutOff`

// Confirm Notice
const _WIRE_CONFIRM_NOTICE = `${FEATURE}.${WIRE_CONFIRM_NOTICE}`
export const CONFIRMATION_NOTICE_TITLE = i18n.t(`${_WIRE_CONFIRM_NOTICE}.title`)
export const CONFIRMATION_NOTICE_SUBTITLE = i18n.t(`${_WIRE_CONFIRM_NOTICE}.subtitle`)
export const CONFIRMATION_NOTICE_BUTTON = i18n.t(`${BUTTONS}.gotIt`)

// Purpose Form
const _PURPOSE = `${FEATURE}.${PURPOSE}`
export const PURPOSE_TITLE = i18n.t(`${_PURPOSE}.title`)
export const PURPOSE_SUBTITLE = i18n.t(`${_PURPOSE}.subtitle`)
export const PURPOSE_LABEL = i18n.t(`${_PURPOSE}.label`)
export const PURPOSE_PLACEHOLDER = i18n.t(`${_PURPOSE}.placeholder`)
export const PURPOSE_INVALID_CHARS = i18n.t(`${_PURPOSE}.invalidChars`)
export const PURPOSE_INVALID_LENGTH = i18n.t(`${_PURPOSE}.lengthError`)

// Recipient Details Form Container
export const RECIPIENT_CONTINUE_BTN = i18n.t(`${_RECIPIENT_DETAILS}.addressForm.continueButton`)
export const RECIPIENT_EDIT_TITLE = i18n.t(`${_RECIPIENT_DETAILS}.editTitle`)
export const RECIPIENT_ADD_TITLE = i18n.t(`${_RECIPIENT_DETAILS}.addTitle`)
export const RECIPIENT_EDIT_SUBTITLE = i18n.t(`${_RECIPIENT_DETAILS}.editSubtitle`)

// Recipient Details Form
const _RELATIONSHIP_FORM = `${_RECIPIENT_DETAILS}.relationship`
export const RECIPIENT_ADDRESS_LABEL = i18n.t(`${_RECIPIENT_DETAILS}.recipientAddress`)
export const RECIPIENT_DETAILS_NAME = i18n.t(`${_RECIPIENT_DETAILS}.personalBusinessName`)
export const RECIPIENT_BUSINESS_REASON_TITLE = i18n.t(`${_RELATIONSHIP_FORM}.title`)
export const RECIPIENT_BUSINESS_REASON_LABEL = i18n.t(`${_RELATIONSHIP_FORM}.typeLabel`)
export const RECIPIENT_BUSINESS_REASON_OTHER = i18n.t(`${_RELATIONSHIP_FORM}.otherPlaceholder`)
export const RECIPIENT_DETAILS_BANK_DETAILS = i18n.t(`${_RECIPIENT_DETAILS}.bankDetails`)
export const RECIPIENT_DETAILS_BANK_NAME = i18n.t(`${_RECIPIENT_DETAILS}.bankName`)
export const RECIPIENT_DETAILS_ROUTING_NUMBER = i18n.t(`${_ROUTING_NUMBER_FORM}.title`)
export const RECIPIENT_DETAILS_ACCOUNT_NUMBER = i18n.t(`${_ACCOUNT_NUMBER_FORM}.title`)

// Sent Card
export const SENT_DOMESTIC_WIRE_SUBTITLE_I18N = `${FEATURE}.${SENT}.domesticWireSubTitle`

// Validations
const _ADDRESS_FORM = `${_RECIPIENT_DETAILS}.addressForm`
export const RECIPIENT_NAME_MAX_LENGTH_ERROR = i18n.t(`${_ADDRESS_FORM}.name.maxLength`)
export const RECIPIENT_ADDRESS_LINE_ONE_MAX_LENGTH_ERROR = i18n.t(
  `${_ADDRESS_FORM}.streetAddress.lineOneMaxLength`,
)
export const RECIPIENT_ADDRESS_LINE_TWO_MAX_LENGTH_ERROR = i18n.t(
  `${_ADDRESS_FORM}.streetAddress.lineTwoMaxLength`,
)
export const RECIPIENT_CITY_MAX_LENGTH_ERROR = i18n.t(`${_ADDRESS_FORM}.city.maxLength`)
export const RECIPIENT_POSTAL_CODE_LENGTH_ERROR = i18n.t(
  `${_ADDRESS_FORM}.postalCode.invalidLength`,
)
export const RECIPIENT_POSTAL_CODE_INVALID_CHARACTER = i18n.t(
  `${_ADDRESS_FORM}.postalCode.invalidCharacter`,
)
export const RECIPIENT_BANK_NAME_MAX_LENGTH_ERROR = i18n.t(`${_ADDRESS_FORM}.bankName.maxLength`)

export const RECIPIENT_BUSINESS_REASON_MAX_LENGTH_ERROR = i18n.t(`${_RELATIONSHIP_FORM}.maxLength`)
export const RECIPIENT_BUSINESS_REASON_MIN_LENGTH_ERROR = i18n.t(`${_RELATIONSHIP_FORM}.minLength`)

export const RECIPIENT_ROUTING_NUMBER_INVALID_LENGTH = i18n.t(
  `${_ROUTING_NUMBER_FORM}.invalidLength`,
)
export const RECIPIENT_ROUTING_NUMBER_INVALID_CHARACTER = i18n.t(
  `${_ROUTING_NUMBER_FORM}.invalidCharacter`,
)
export const RECIPIENT_DETAILS_ROUTING_NUMBER_INVALID = i18n.t(
  `${_ROUTING_NUMBER_FORM}.invalidRoutingNumber`,
)
export const RECIPIENT_ACCOUNT_NUMBER_INVALID_LENGTH = i18n.t(
  `${_ACCOUNT_NUMBER_FORM}.invalidLength`,
)
export const RECIPIENT_ACCOUNT_NUMBER_INVALID_CHARACTER = i18n.t(
  `${_ACCOUNT_NUMBER_FORM}.invalidCharacter`,
)
export const RECIPIENT_INVALID_CHARACTER = i18n.t(`${_RECIPIENT_DETAILS}.invalidCharacter`)

// Constants
const _RELATIONSHIP_OPTIONS = `${_RELATIONSHIP_FORM}.options`
export const RELATIONSHIP_TYPE_GOVERNMENT = i18n.t(`${_RELATIONSHIP_OPTIONS}.government`)
export const RELATIONSHIP_TYPE_OTHER_BANK = i18n.t(`${_RELATIONSHIP_OPTIONS}.otherBank`)
export const RELATIONSHIP_TYPE_PAYROLL = i18n.t(`${_RELATIONSHIP_OPTIONS}.payroll`)
export const RELATIONSHIP_TYPE_VENDOR = i18n.t(`${_RELATIONSHIP_OPTIONS}.vendor`)
export const RELATIONSHIP_TYPE_OTHER = i18n.t(`${_RELATIONSHIP_OPTIONS}.other`)
