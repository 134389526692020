import { theme } from "@layouts/theme"
const containerPaddingY = 32
const containerPaddingX = 20
export const perksStyles = {
  container: {
    borderRadius: 8,
    width: 277,
    height: "100%",
    paddingY: containerPaddingY,
    paddingX: containerPaddingX,
    boxShadow: theme.boxShadow,
    ":hover": {
      border: "1px solid",
      borderColor: theme.colors.gold80,
      cursor: "pointer",
      paddingY: containerPaddingY - 1,
      paddingX: containerPaddingX - 1,
    },
  },
  header: {
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    borderBottomColor: theme.colors.ui5,
    paddingBottom: 18,
    alignItems: "center",
  },
  category: {
    color: theme.colors.ui2,
    backgroundColor: theme.colors.blue10,
    width: "max-content",
    paddingX: "4px",
    paddingY: "2px",
    marginY: 20,
  },
}
