export const schedules = {
  titleFR: "We're improving the way you schedule payments.",
  subtitleFR:
    "We would love to hear from you to learn what improvements you’d like to see, or answer any questions you may have. Our Customer Care team is here to help. This feature will return very soon, new and improved!",
  everyDay: "Every day",
  everyMonth: "Every month",
  everyTwoWeeks: "Every 2 weeks",
  everyWeek: "Every week",
  once: "Once",
  daily: "daily",
  weekly: "weekly",
  biweekly: "bi-weekly",
  monthly: "monthly",
  title: "Scheduled payments",
  createScheduleButton: "Schedule a payment",
  monthSubtotalTitle_remainingPayments: "Remaining scheduled payments",
  monthSubtotalTitle_futurePayments: "Scheduled payments total",
  monthSubtotalTitle_noPayments: "No payments scheduled",
  paused: "Paused",
  edit: {
    progressBar: {
      amount: "Amount",
      schedule: "Scheduling",
      confirm: "Confirmation",
    },
    insufficientSubAccountBalanceMessage: "Insufficient Balance in {{subAccountName}}",
    exceedsTransactionLimit: "Exceeds limit for payment method",
    nextPaymentDate: "Next Payment Date",
    scheduledPaymentTitle: "When would you like the next payment to happen?",
    scheduledTransferTitle: "When would you like the next transfer to happen?",
    confirm: "Update payment",
    confirm_transfer: "Update transfer",
  },
  schedulePanel: {
    amount: "Amount",
    method: "Payment method",
    subAccountName: "From Account",
    date: "Schedule Date",
    schedule: "Frequency",
    pause: "Pause",
    restart: "Resume",
    edit: "Edit",
    pillPaused: "Paused",
    counterpartyLabels: {
      default: "Recipient",
      billPay: "Payee",
      transfer: "Account",
    },
  },
  restartModal: {
    title: "Resume this scheduled payment?",
    subtitle:
      "This will resume all future {{frequency}} payments of {{amount}} to {{counterpartyName}}.",
    primaryButton: "Resume",
    secondaryButton: "Go back",
  },
  pauseModal: {
    title: "Pause scheduled payment?",
    subtitle:
      "This will pause all future {{frequency}} payments of {{amount}} to {{counterpartyName}}.",
    primaryButton: "Pause",
    secondaryButton: "Go back",
  },
  deleteModal: {
    title: "Cancel scheduled payment?",
    subtitle:
      "This will cancel all future {{frequency}} payments of {{amount}} to {{counterpartyName}}.",
    subtitle_once: "This will cancel this payment of {{amount}} to {{counterpartyName}}.",
    primaryButton: "Cancel",
    secondaryButton: "Go back",
  },
  editDiscardModal: {
    title: "Discard payment update?",
    title_transfer: "Discard transfer update?",
    subtitle: "Your changes will not be saved.",
    discard: "Discard",
  },
  zeroState: {
    title: "Save time by scheduling payments",
    subtitle: "Set up one-time or recurring payments to send automatically.",
  },
  filter: {
    filters: "Filters",
    panel: {
      title: "Filters",
      yearLabel: "By year",
      paymentTypeLabel: "Payment type",
      allPayments: "All payments",
      outgoingPayments: "Outgoing payments",
      internalTransfers: "Internal transfers",
      applyFilters: "Apply filters",
      clearFilters: "Clear",
    },
  },
}
