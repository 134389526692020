import React from "react"
import { Textarea } from "@rebass/forms"
import { IFieldInputProps } from "@components/primitive/field/field"
import { theme } from "@layouts/theme"
import { Text } from "@components/primitive/text/text"
import { Box, SxStyleProp } from "rebass"

export interface ILongTextInputProps extends IFieldInputProps {
  placeholder: string
  value: string
  onChange: (value: string) => void
  maxLength?: number
  labelSx?: SxStyleProp
}

export const RoundedLongTextInput: React.FC<ILongTextInputProps> = ({
  label,
  placeholder,
  errorMessage,
  value,
  onChange,
  subText,
  maxLength,
  labelSx,
}) => {
  return (
    <>
      {label && (
        <Text tag="h5" sx={{ color: theme.colors.ui3, marginBottom: "5px", ...labelSx }}>
          {label}
        </Text>
      )}
      <Textarea
        sx={{
          resize: "none",
          outline: "none",
          border: `1px solid ${errorMessage ? theme.colors.error100 : theme.colors.gold10}`,
          paddingX: 13,
          paddingY: 14,
          "::placeholder": {
            color: theme.colors.ui3,
            ...theme.textStyles.body,
          },
          ...theme.textStyles.body,
          height: 97,
          backgroundColor: errorMessage ? theme.colors.error10 : theme.colors.gold10,
          borderRadius: "4px",
        }}
        maxLength={maxLength}
        value={value}
        placeholder={placeholder}
        onChange={(e) => onChange(e.target.value)}
      />
      <Box sx={{ height: 20 }}>
        {(subText || errorMessage) && (
          <Text
            tag={"label-bold"}
            textColor={errorMessage ? theme.colors.error100 : theme.colors.ui3}
          >
            {errorMessage || subText}
          </Text>
        )}
      </Box>
    </>
  )
}
