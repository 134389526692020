export const moveMoney = {
  subAccount: {
    title: "Where do you want to send money from?",
    title_transfer: "Select an account to send money from",
    insufficientFundsLabel: "Insufficient funds",
    totalAvailableToSpend: "Total available to spend",
    totalBalance: "Total Account Balance",
  },
  paymentMethod: {
    title: "How do you want to send money?",
    ach: "Send an ACH transfer",
    wire: "Send a wire transfer¹",
    external: "Transfer to external account",
    wireFeeSend: "¹ {{wireFee}} fee for sending a wire transfer",
    limitNote: "apply. Consult our",
    limit: "Transaction limits",
    depositAgreement: "Deposit Account Agreement.",
  },
  recipient: {
    title: "Who do you want to send money to?",
    placeholder: "Search for your payee",
    frequentlyPaid: "Frequently paid",
    matchingRecipients: "Matching recipients",
    new: {
      title: "Add recipient",
    },
    noMatch: "No results found",
    billPay: {
      newPayee: "Add a new payee",
      payeesACH: "BILL PAYEES - PAID BY ACH",
      payeesMailedCheck: "BILL PAYEES - PAID BY MAILED CHECK",
      lastPaid: "Last Paid",
      paymentHistory: "Paid {{amount}} on {{date}}",
      noPaymentHistory: "No payment history",
      account: "Account {{accountNumber}}",
      achRecipientDetailsTitle: "Complete Recipient Info",
    },
    termsText: "By adding a recipient, you are agreeing to our NorthOne",
    terms: "Terms of Service.",
  },
  recipientListItem: {
    title: {
      ACH: "ACH recipients",
      DOMESTIC_WIRE: "Wire recipients",
    },
    subtext: {
      ACH: "Bank account {{accountNumber}}",
      DOMESTIC_WIRE: "Account {{accountNumber}}",
    },
  },
  billPay: {
    recipientSearch: {
      title: "Search for your payee",
      subtitle:
        "Enter your payee's name to search our database of utilities, phone providers and more.",
      recipientManualAddLabel: "Manual Payee - Pay By Mailed Check",
      recipientManualAddOptionText: "Add a new payee manually",
      recipientSearchLabel: "Possible matches - Pay by ACH Transfer",
      recipientSearchInitialOption: "Enter payee name to search database",
    },
    achRecipientDetailsTitle: "Complete payee details",
    achRecipientDetailsSubTitle:
      "Complete your payee's details and confirm that they are correct. Your payee will receive this payment as an ACH transfer.",
    addCheckRecipientDetailsTitle: "Add payee details",
    reviewCheckRecipientDetailsTitle: "Review payee details",
    checkRecipientDetailsSubTitle:
      "Please add your recipient’s details and confirm that they are correct. Bills paid to this recipient will be paid by mailed check.",
    checkRecipientDetailsContinueButton: "Confirm recipient",
    payeeDetails: "Payee address",
    payeeNameLabel: "Payee name",
    payeeAddressLabel: "Street address",
    missingInformationPrompt: "We need a little more information...",
    confirmPayeeButton: "Confirm payee",
    payeeSuiteLabel: "Apartment, suite, etc. (optional)",
  },
  externalTransfer: {
    toAccount: {
      title: "Which external account do you want to transfer money to?",
      subtitle: "To manage your external accounts, visit ",
      link: "Settings > External accounts",
      zeroStateSubtitle:
        "Linked Accounts let you transfer money from NorthOne to external checking and savings accounts.",
    },
  },
  recipientDetails: {
    addTitle: "Add recipient details",
    addSubtitle: "Please add your recipient’s details and confirm that they are correct.",
    editTitle: "Edit recipient details",
    editSubtitle: "Fill out the missing information to send a wire.",
    name: {
      title: "Recipient name",
      maxLength: "Please enter no more than 32 characters",
    },
    personalBusinessName: "Personal (first and last) or business name",
    email: "Recipient email",
    yourAccountNumber: "Your account number",
    recipientAddress: "Recipient address",
    streetAddress: "Street address",
    bankName: "Bank name",
    bankStreetAddress: "Street address",
    bankDetails: "Bank details",
    invalidCharacter: "Enter only letters, numbers, periods, commas, or hyphens",
    invalidRoutingNumber: "Please enter a valid routing number",
    relationship: {
      options: {
        government: "Government (taxes, licenses, fees)",
        otherBank: "My other bank account",
        payroll: "Payroll provider",
        vendor: "Vendor or supplier",
        other: "Other",
      },
      title: "Business Relationship",
      typeLabel: "Business relationship type",
      otherPlaceholder: "If other, describe the relationship",
      minLength: "Enter at least 5 characters",
      maxLength: "Maximum character limit of 35 reached",
    },
    addressForm: {
      continueButton: "Confirm recipient",
      name: {
        maxLength:
          "Maximum character limit of 35 reached. Try abbreviating if possible. You can edit it later if you need.",
      },
      streetAddress: {
        lineOneMaxLength: "Maximum character limit of 30 reached",
        lineTwoMaxLength: "Maximum character limit of 10 reached",
      },
      city: {
        maxLength: "Maximum character limit of 24 reached",
      },
      postalCode: {
        invalidLength: "Enter a 5 or 9-digit ZIP code",
        invalidCharacter: "Enter only numbers for ZIP code",
      },
      bankName: {
        maxLength: "Maximum character limit of 35 reached",
      },
    },
    routingNumber: {
      title: "Routing number",
      invalidRoutingNumber: "Enter a valid routing number",
      invalidLength: "Enter a 9-digit routing number",
      invalidCharacter: "Enter only numbers for routing number",
    },
    accountNumber: {
      title: "Account number",
      invalidLength: "Enter a 6 to 17-digit account number",
      invalidCharacter: "Enter only numbers for account number",
    },
    errors: {
      duplicateContact:
        "A recipient with these bank account numbers already exists. Go back to select them from the recipient list.",
    },
  },
  amount: {
    title: "Enter the amount to send",
    title_transfer: "Enter the amount to send",
    insufficientFundsMessage: "Insufficient Available Balance",
    minimumAmountMessage: "Must be at least $1.00",
    betweenLimitMessage: "Enter an amount between {{minAmount}} and {{maxAmount}}",
    limitExceededMessages: {
      DOMESTIC_WIRE: "Wire payments must be below {{maxAmount}}",
      ACH: "ACH payments must be below {{maxAmount}}",
    },
    modal: {
      paymentType: "Your bill will be paid by {{paymentType}}",
      confirm: "Got it",
    },
  },
  purpose: {
    title: "Give specific details as to who you’re sending the payment to and why",
    subtitle: "The reason you enter will be reviewed.",
    label: "Reason",
    placeholder: `For example, "Sending money to ADP for September payroll."`,
    lengthError: "To continue, enter more details about the payment",
    invalidChars: "Enter only letters, numbers, periods, commas, or hyphens",
  },
  paymentDate: {
    title: "Select a date to send the payment",
    subtitles: {
      DOMESTIC_WIRE: "Wire transfers usually clear within the same business day.",
      ACH: "ACH transfers usually take 2 to 3 business days to process.",

      SUB_ACCOUNT_TRANSFER:
        "Transfers to external bank accounts take 2 to 3 business days to arrive.",
    },
    datepickerDropdownLabel: "Date",
    datepickerDropdownPlaceholder: "Select a date",
    datepickerDropdownEndDatePlaceholder: "Select a date (optional)",
    scheduleDropdownLabel: "Frequency",
    scheduleEndDateDropdownLabel: "End date",
    transactionVariantPayment: "payment",
    transactionVariantTransfer: "transfer",
    dateSummarySubtitle: "Your first payment will be sent *{{firstPaymentDate}}*.",
    scheduleSummarySubtitle_noSchedule:
      "Your {{transactionVariant}} will be sent *{{firstPaymentDate}}*. It will *not repeat*.",
    scheduleSummarySubtitle_schedule:
      "Your first {{transactionVariant}} will be sent *{{firstPaymentDate}}*. It will repeat *{{scheduleFrequency}}* until an end date is selected or the {{transactionVariant}} has been canceled.",
    scheduleSummarySubtitle_hasEndDate:
      "Your first {{transactionVariant}} will be sent *{{firstPaymentDate}}*. It will repeat *{{scheduleFrequency}}*, until *{{scheduleEndDate}}*.",
    scheduleSummarySubtitle_scheduleEdit:
      "Your next {{transactionVariant}} will be sent *{{firstPaymentDate}}*. It will repeat *{{scheduleFrequency}}*, until an end date has been selected or the payment has been canceled.",
    scheduleSummarySubtitle_hasEndDateEdit:
      "Your next {{transactionVariant}} will be sent *{{firstPaymentDate}}*. It will repeat *{{scheduleFrequency}}*, until *{{scheduleEndDate}}*.",
    scheduleNextPayment: "Your next {{transactionVariant}} will be on *{{nextPaymentDate}}*.",
    clearDate: "Clear Date",
  },
  sent: {
    label: "Your payment is on its way",
    label_scheduled: "Your payment is scheduled",
    label_scheduledFrequency: "Your payments are scheduled",
    title: "{{amount}} {{paymentMethod}} to {{recipientName}} from {{account}}",
    domesticWireSubTitle: `Your wire was initiated.\n\nWires sent after {{cutOffTime}} (Monday to Friday) will be received the next business day.\n\n{{bankName}} may charge a return fee if they decline.`,
    subtitle:
      "Your {{repeatingCopy}} will be sent *{{dateCopy}}*{{frequencyCopy}}. {{paymentMethod}} take *{{paymentDuration}}* to process.",
    subtitle_transfer: "Your {{repeatingCopy}} will be sent *{{dateCopy}}*{{frequencyCopy}}.",
    subtitle_transferTodayRepeating: "Your first transfer was completed today*{{frequencyCopy}}.",
    frequency: ", and will *repeat {{frequency}}* until {{endDate}}",
    noEndDate: "an end date is selected or the transfer is canceled",
    paymentExpectedDurationSubtitles: {
      DOMESTIC_WIRE: "Wires usually clear on the same business day.",
      ACH: "ACH payments take 2 to 3 business days to process.",
    },
    continue: "Send another payment",
    return: "Back to dashboard",
    payment: "payment",
    transfer: "transfer",
    first: "first payment",
    first_transfer: "first transfer",
    pleaseAllow: "Please allow enough time before the due date of your next bill.",
    paymentTypes: {
      ACH: "ACH payment",
      ACH_plural: "ACH payments",
      BILL_PAYMENT: "bill payment",
      BILL_PAYMENT_plural: "Bill payments",
      DOMESTIC_WIRE: "wire payment",
      DOMESTIC_WIRE_plural: "Wire payments",
      SUB_ACCOUNT_TRANSFER: "transfer",
      SUB_ACCOUNT_TRANSFER_plural: "transfers",
      EXTERNAL_TRANSFER: "External transfers",
    },
    timeSavedBanner: {
      timeSavingActionName: {
        ach: "ACH",
        wire: "wire",
      },
      title: "You've saved",
      days: "days",
      hours: "hours",
      minutes: "minutes",
      description:
        "**You saved {{minutesSaved}} minutes** sending this {{timeSavingActionName}} using NorthOne instead of a traditional bank.",
      learnMoreLink: "Learn more about how we calculate this",
      calculationMethodologyModal: {
        title: "How do we calculate this?",
        description:
          "Our team looks at the average time it takes to make ACH, Wire, and Physical Check payments and compare that to survey data about the same actions taken at traditional banks.",
        forDetails: "For more details, visit our __Guidelines for sourcing data__.",
      },
    },
  },
  discard: {
    title: "Discard payment?",
    title_transfer: "Discard?",
    subtitle: "Your payment will not be saved.",
    subtitle_transfer: "Your funds will not be reassigned.",
    discard: "Discard",
  },
  progressBar: {
    paymentMethod: "Payment method",
    recipient: "Recipient name",
    payee: "Payee name",
    recipientDetails: "Recipient details",
    payeeDetails: "Payee details",
    amount: "Amount",
    fromAccount: "From",
    toAccount: "To",
    account: "Account",
    scheduling: "Scheduling",
    confirmation: "Confirmation",
    note: "Note",
  },
  wireCutOff:
    "Wires sent after {{cutOffTime}} (Monday to Friday) will be received the next business day",
  cardNotActivated: {
    title: "Your debit card must be activated before you can send wires",
    subtitle:
      "Please call **(866) 331-3085** and activate your debit card to send a wire transfer.",
    button: "Activate card",
    support: "Questions? Contact **Customer Care**",
  },
  wireConfirmNotice: {
    title: "You'll need to confirm your wire by email or text message",
    subtitle:
      "We’ll send you a message soon to confirm your wire. Standard rates from your wireless service provider may apply.",
  },
  wireReview: {
    terms: "By sending a wire, you confirm you've read and agree to the ",
    termsOfUse: "Wire Transfer Agreement.",
  },
  agreement: {
    title: "Wire Transfer Agreement",
  },
}
