import React from "react"
import { UpsellPlanDetails } from "@features/subscriptions/upsell/upsell-details"
import { useDispatch } from "react-redux"
import { invoicePaymentsActions } from "@features/invoice-payments/redux/actions"
import { useAppSelector } from "@core/redux/utils"
import { getInvoicePayments } from "@features/invoice-payments/redux/selector"
import { useTransactionSubscriptionUpsell } from "@features/subscriptions/shared/hooks/use-transaction-subscription-upsell"
import { getUpsellTransactionTypeFromInvoicePaymentMethod } from "./upsell-button"
import { formatPlanNameString } from "@features/subscriptions/utils"

export const InvoiceUpsellPlanDetails = () => {
  const dispatch = useDispatch()
  const paymentMethod = useAppSelector(getInvoicePayments).invoicePaymentDetails?.paymentMethod
  const transactionType = getUpsellTransactionTypeFromInvoicePaymentMethod(paymentMethod)
  const { upsellPlan } = useTransactionSubscriptionUpsell({ transactionType })
  const upsellPlanName = formatPlanNameString(upsellPlan?.planName)

  return (
    <UpsellPlanDetails
      upsellPlanName={upsellPlanName}
      navigateBack={() => dispatch(invoicePaymentsActions.navigate("PAYMENT_REVIEW"))}
      navigateNext={() => dispatch(invoicePaymentsActions.navigate("SUBSCRIPTION_UPSELL_CONFIRM"))}
    />
  )
}
