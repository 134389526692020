import React from "react"
import { useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import { Box, Button, Image } from "rebass"

import { cardsActions } from "../redux/actions"
import { images } from "@assets/images/images"
import { Text } from "@components/primitive/text/text"
import { Analytics } from "@core/analytics/actions"
import { events } from "@core/analytics/events"
import { theme } from "@layouts/theme"
import { DEPOSIT_ACCOUNT_AGREEMENT_LINK } from "@utils/constants"
import { useCapabilitiesAndAvailableBalanceQuery } from "@generated/graphql"
import { useAppSelector } from "@core/redux/utils"
import { selectActiveBusinessID } from "@core/active-business/redux/selectors"
import { getFeeDisplay } from "@features/move-money/utils/fee.utils"
import { ErrorPanel } from "@components/extended/panel/error-panel"
import { LoadingPanel } from "@components/extended/panel/loading-panel"

const Benefits = () => {
  const { t } = useTranslation()

  const items = [
    {
      icon: images.cards,
      label: t("cards.setPIN.benefit1"),
    },
    {
      icon: images.wireSimple,
      label: t("cards.setPIN.benefit2"),
    },
    {
      icon: images.cardSimple,
      label: t("cards.setPIN.benefit3"),
    },
    {
      icon: images.gift,
      label: t("cards.setPIN.benefit4"),
    },
  ]

  const mappedItems = items.map((item, index) => (
    <Box
      key={index}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        marginTop: index === 0 ? "0px" : "10px",
      }}
    >
      <Image sx={{ width: "22px", height: "22px", marginRight: "15px" }} src={item.icon} />
      <Text tag="label" sx={{ fontSize: "12px" }}>
        {item.label}
      </Text>
    </Box>
  ))

  return (
    <Box
      sx={{
        width: "100%",
        padding: "15px",
        margin: "15px 0px",
        backgroundColor: theme.colors.ui7,
      }}
    >
      {mappedItems}
    </Box>
  )
}

const LegalConditions = ({ wireFee }: { wireFee: string }) => {
  const { t } = useTranslation()
  const conditions = [
    { label: t("cards.setPIN.condition1", { wireFee }), link: DEPOSIT_ACCOUNT_AGREEMENT_LINK },
    { label: t("cards.setPIN.condition2"), link: DEPOSIT_ACCOUNT_AGREEMENT_LINK },
  ]

  const mappedConditions = conditions.map((condition, index) => {
    const styles = {
      color: theme.colors.blackPrimary,
      fontSize: "10px",
      fontWeight: 400,
      lineHeight: "16px",
    }

    return (
      <Text
        key={index}
        tag={"body"}
        sx={{
          ...styles,
          marginTop: index === 0 ? "0px" : "10px",
        }}
        textWithEmbeddedLinksProps={{
          text: condition.label,
          linksInOrder: [condition.link],
          linkStyle: { color: theme.colors.blackPrimary },
        }}
      />
    )
  })

  return <Box sx={{ marginTop: "20px" }}>{mappedConditions}</Box>
}

export const PinSetSuccess = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const businessId = useAppSelector(selectActiveBusinessID)

  const {
    data: capabilitiesData,
    error: capabilitiesError,
    loading: capabilitiesLoading,
    refetch: capabilitiesRefetch,
  } = useCapabilitiesAndAvailableBalanceQuery({
    variables: { businessId },
    notifyOnNetworkStatusChange: true,
  })
  const wireFee = getFeeDisplay(
    capabilitiesData?.business?.bankAccounts[0].capabilities?.domesticWire.fee,
  )

  const closeModal = () => {
    Analytics.track(events.cards.activate.success)
    dispatch(cardsActions.setCardActivationModalOpen(false))
  }

  if (capabilitiesLoading) {
    return <LoadingPanel iconDimension={40} />
  }
  if (capabilitiesError || !wireFee) {
    return <ErrorPanel retry={capabilitiesRefetch} />
  }

  return (
    <Box
      sx={{
        margin: 0,
        padding: 0,
        maxWidth: "410px",
        backgroundColor: theme.colors.goldTint3,
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          padding: "15px 15px 5px 15px",
          backgroundColor: theme.colors.goldTint1,
        }}
      >
        <Image src={images.wallet} sx={{ maxHeight: "180px", mixBlendMode: "multiply" }} />

        <Button
          style={{
            margin: 0,
            padding: 0,
            border: "none",
            background: "none",
            display: "flex",
            cursor: "pointer",
          }}
          onClick={closeModal}
        >
          <Image
            role="button"
            src={images.cross}
            sx={{
              width: "16px",
            }}
          />
        </Button>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "24px 32px",
        }}
      >
        <Text
          tag="h2"
          sx={{
            fontSize: "28px",
            fontWeight: 600,
            borderBottom: `1.5px solid ${theme.colors.blackPrimary}`,
          }}
        >
          {t("cards.setPIN.success")}
        </Text>

        <Text
          tag="label"
          sx={{
            marginTop: "10px",
            fontSize: "14px",
          }}
        >
          {t("cards.setPIN.subtitle")}
        </Text>

        <Benefits />

        <Button
          sx={{
            ...theme.textStyles["body-small-bold"],
            height: "40px",
            borderRadius: 0,
            cursor: "pointer",
            color: theme.colors.ui7,
            backgroundColor: theme.colors.blackRich,
          }}
          onClick={closeModal}
        >
          {t("buttons.done")}
        </Button>

        <LegalConditions wireFee={wireFee} />
      </Box>
    </Box>
  )
}
