import React, { useState, useRef } from "react"
import { Box } from "rebass"
import { useTranslation } from "react-i18next"
import { motion } from "framer-motion"
import { Analytics } from "@core/analytics/actions"
import { events } from "@core/analytics/events"
import { Text } from "@components/primitive/text/text"
import { AnimatePresence } from "@components/animations/animate-presence/animation-presence"
import { FlexRowLayout } from "@layouts/flex"
import { theme } from "@layouts/theme"
import { BankAccountInfo } from "./bank-account-info"
import { Button } from "@components/primitive/button/button"

interface IAccountDetailsProps {
  subtitle?: string
}

export const AccountDetails: React.FC<IAccountDetailsProps> = ({ subtitle }) => {
  const { t } = useTranslation()
  const [isExpanded, setIsExpanded] = useState(false)
  const ref = useRef<HTMLDivElement>(null)

  return (
    <motion.div
      layout
      initial={false}
      animate={{
        backgroundColor: isExpanded ? theme.colors.ui6 : theme.colors.ui7,
      }}
      style={{ borderRadius: "0 0 8px 8px" }}
      onAnimationComplete={() => {
        if (ref && ref.current) {
          const bottom = ref.current.getBoundingClientRect().bottom
          if (bottom > window.innerHeight) {
            ref.current.scrollIntoView({ behavior: "smooth", block: "end" })
          }
        }
      }}
    >
      <Box ref={ref} sx={{ padding: theme.spacing.sidebar, scrollMarginBottom: 50 }}>
        <FlexRowLayout
          sx={{ justifyContent: "space-between", alignItems: "center", cursor: "pointer" }}
          onClick={() => {
            if (!isExpanded) {
              Analytics.track(events.sidebar.accountsDetails)
            }
            setIsExpanded(!isExpanded)
          }}
        >
          <Text tag="h5" textColor={theme.colors.ui2}>
            {t("dashboard.sidebar.accountDetails")}
          </Text>
          <Button
            onClick={() => {
              if (!isExpanded) {
                Analytics.track(events.sidebar.accountsDetails)
              }
              setIsExpanded(!isExpanded)
            }}
            textTag={"h5"}
            sx={{
              height: "auto",
              borderRadius: 4,
              paddingY: "4px",
              paddingX: "8px",
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: isExpanded ? theme.colors.blue60 : theme.colors.blue10,
              ":hover": {
                backgroundColor: isExpanded ? theme.colors.blue30 : theme.colors.ui6,
              },
              ":active": {
                backgroundColor: isExpanded ? theme.colors.blue80 : theme.colors.blue30,
              },
            }}
            textSx={{ color: isExpanded ? theme.colors.ui7 : theme.colors.ui1 }}
          >
            {isExpanded ? t("dashboard.sidebar.hide") : t("dashboard.sidebar.show")}
          </Button>
        </FlexRowLayout>
        <AnimatePresence animation="expand">
          {isExpanded ? <BankAccountInfo subtitle={subtitle} /> : null}
        </AnimatePresence>
      </Box>
    </motion.div>
  )
}
