import gql from "graphql-tag"

export const CREATE_SUB_ACCOUNT_PERCENT_RULE = gql`
  mutation subAccountRulePercentCreate($businessId: ID!, $subAccountId: ID!, $percent: Int!) {
    subAccountRulePercentCreate(
      businessId: $businessId
      subAccountId: $subAccountId
      data: { percent: $percent }
    ) {
      code
      success
      message
    }
  }
`

export const SUB_ACCOUNT_PERCENT_AVAILABLE = gql`
  query subAccountTotalPercentAvailable($businessId: ID!) {
    subAccountRuleTotalPercentAvailable(businessId: $businessId)
  }
`
