import React from "react"
import { useTranslation } from "react-i18next"
import { useHistory, useParams } from "react-router-dom"
import { RoutesEnum } from "@routers/types"
import { Text } from "@components/primitive/text/text"
import { BackButton } from "@components/composite/back-button/back-button"
import { BaseLayout } from "@layouts/base-layout"
import { AccountHolderAgreement } from "./account-holder-agreement"
import { WireTransferAgreement } from "./wire-transfer-agreement"
import { IssuerAndFDIC } from "./issuer"
import { ESignAgreement } from "./esign-agreement"

type TLegalType =
  | "issuer"
  | "account_agreement"
  | "electronic_comms_agreement"
  | "wire_transfer_agreement"

export const LegalListByType: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { legalType } = useParams<{ legalType: TLegalType }>()

  const getLegalList = () => {
    switch (legalType) {
      case "issuer":
        return <IssuerAndFDIC />
      case "account_agreement":
        return <AccountHolderAgreement />
      case "wire_transfer_agreement":
        return <WireTransferAgreement />
      case "electronic_comms_agreement":
        return <ESignAgreement />
      default:
        return null
    }
  }

  return (
    <BaseLayout pageTitle={t("pageTitles.settings.legalType", { context: legalType })}>
      <BackButton
        text={t("settings.pages.legal.back")}
        onClick={() => history.push(RoutesEnum.SETTINGS_LEGAL)}
      />
      <Text tag="h2" sx={{ marginBottom: 24, marginTop: 16 }}>
        {t(`settings.pages.legal.${legalType}`)}
      </Text>
      {getLegalList()}
    </BaseLayout>
  )
}
