import React from "react"
import { Text } from "@components/primitive/text/text"
import { Logo } from "@layouts/logo"
import { theme } from "@layouts/theme"

export const SubscriptionsLandingFooter = ({ terms }: { terms: string[] | null | undefined }) => {
  return (
    <Logo sx={{ width: "620px", marginTop: "70px" }}>
      {terms?.map((term) => (
        <Text tag={"body-x-small"} key={term} color={theme.colors.ui2} sx={{ marginY: "15px" }}>
          {terms}
        </Text>
      ))}
    </Logo>
  )
}
