import gql from "graphql-tag"

export const BUSINESS_DETAILS = gql`
  query businessDetails($businessId: ID!) {
    business(businessId: $businessId) {
      id
      name
    }
  }
`

export const BUSINESS_ACCOUNT_INFO = gql`
  query bankAccountInfo($businessId: ID!) {
    business(businessId: $businessId) {
      id
      bankAccount {
        id
        availableBalance
        routingNumber
        accountNumber
        bankName
        bankAddress
      }
    }
  }
`

export const BUSINESS_ACCOUNTS_AVAILABLE_BALANCE_QUERY = gql`
  query businessInfo($businessId: ID!) {
    business(businessId: $businessId) {
      id
      name
      bankAccount {
        id
        availableBalance
        subAccounts {
          id
          name
          balance
          isDefault
        }
      }
    }
  }
`
