import React from "react"
import { SxStyleProp } from "rebass"
import { useTranslation } from "react-i18next"
import { FlexColumnLayout, FlexRowLayout } from "@layouts/flex"
import { TextInput } from "@components/extended/fields/text-input"
import { Dropdown } from "../dropdown/dropdown"
import { americanStates } from "@utils/constants"

interface IAddressInputProps {
  fields: { [key in TAddressFields]: IAddressField }
  sx?: SxStyleProp
}

interface IAddressField {
  value: string
  onChange: (value: string) => void
  errorMessage?: string
  label?: string
}

type TAddressFields =
  | "streetAddressLine1"
  | "streetAddressLine2"
  | "city"
  | "postalCode"
  | "provinceState"

export const AddressInput: React.FC<IAddressInputProps> = ({
  fields: { streetAddressLine1, streetAddressLine2, city, postalCode, provinceState },
  sx,
}) => {
  const { t } = useTranslation()

  return (
    <FlexColumnLayout sx={sx}>
      <TextInput
        value={streetAddressLine1.value}
        placeholder={t("inputs.streetAddressLine1")}
        label={streetAddressLine1.label || t("inputs.streetAddressLine1")}
        onChange={streetAddressLine1.onChange}
        errorMessage={streetAddressLine1.errorMessage}
      />
      <TextInput
        value={streetAddressLine2.value}
        placeholder={t("inputs.streetAddressLine2")}
        label={streetAddressLine2.label || t("inputs.streetAddressLine2")}
        onChange={streetAddressLine2.onChange}
        errorMessage={streetAddressLine2.errorMessage}
      />
      <FlexRowLayout sx={{ width: "100%" }}>
        <TextInput
          value={city.value}
          label={city.label || t("inputs.city")}
          placeholder={t("inputs.city")}
          onChange={city.onChange}
          errorMessage={city.errorMessage}
          containerSx={{ paddingRight: 16, width: "50%" }}
        />
        <Dropdown
          onSelect={provinceState.onChange}
          selectedValue={provinceState.value}
          options={americanStates}
          label={postalCode.label || t("inputs.provinceState")}
          placeholder={t("inputs.selectState")}
        />
      </FlexRowLayout>
      <TextInput
        value={postalCode.value}
        label={postalCode.label || t("inputs.postalCode")}
        placeholder={t("inputs.postalCode")}
        onChange={postalCode.onChange}
        errorMessage={postalCode.errorMessage}
        maxLength={9}
        containerSx={{ width: "100%" }}
      />
    </FlexColumnLayout>
  )
}
