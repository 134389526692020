import gql from "graphql-tag"

export const CARDS = gql`
  query cards($businessId: ID!) {
    business(businessId: $businessId) {
      id
      bankAccounts {
        id
        cards {
          id
          productType
          embossLine1
          embossLine2
          cardNumber {
            last4
          }
          shippedDate
          activation {
            cardId
            activated
            pinSet {
              cardId
              status
            }
          }
          user {
            id
            firstName
            lastName
            isPrimaryOwner
            signUpDate
          }
          businessId
        }
      }
    }
  }
`

export const CARD = gql`
  query card($businessId: ID!, $cardId: ID!) {
    business(businessId: $businessId) {
      id
      bankAccount {
        id
        card(cardId: $cardId) {
          id
          embossLine1
          cardNumber {
            last4
          }
          shippedDate
          productType
          activation {
            cardId
            activated
            pinSet {
              cardId
              status
              updatedAt
            }
          }
          freezeInfo {
            frozen
          }
          disabled
        }
      }
    }
  }
`

export const ACTIVATE_CARD_MUTATION = gql`
  mutation cardActivate(
    $businessId: ID!
    $accountId: ID!
    $cardId: ID!
    $expirationDate: String!
    $securityCode: String!
  ) {
    cardActivate(
      businessId: $businessId
      accountId: $accountId
      cardId: $cardId
      expirationDate: $expirationDate
      securityCode: $securityCode
    ) {
      code
      success
      message
    }
  }
`

export const CARD_ACTIVATE_PIN_SET_QUERY = gql`
  query cardPinChangeForm($businessId: ID!, $accountId: ID!, $cardId: ID!) {
    cardPinChangeForm(businessId: $businessId, accountId: $accountId, cardId: $cardId) {
      cardId
      url
      expiresAt
    }
  }
`

export const FREEZE_CARD_MUTATION = gql`
  mutation cardFreeze($businessId: ID!, $accountId: ID!, $cardId: ID!) {
    cardFreeze(businessId: $businessId, accountId: $accountId, cardId: $cardId) {
      code
      success
      message
    }
  }
`

export const UNFREEZE_CARD_MUTATION = gql`
  mutation cardUnfreeze($businessId: ID!, $accountId: ID!, $cardId: ID!) {
    cardUnfreeze(businessId: $businessId, accountId: $accountId, cardId: $cardId) {
      code
      success
      message
    }
  }
`

export const DIGITAL_CARD_ASSET_URL_QUERY = gql`
  query digitalCardAssetUrl($businessId: ID!, $accountId: ID!, $cardId: ID!) {
    digitalCardAssetUrl(businessId: $businessId, accountId: $accountId, cardId: $cardId) {
      cardId
      url
      expiresAt
    }
  }
`
export const ADD_VIRTUAL_CARD_MUTATION = gql`
  mutation addVirtualCard($businessId: ID!, $accountId: ID!) {
    addVirtualCard(businessId: $businessId, accountId: $accountId) {
      code
      success
      message
    }
  }
`
