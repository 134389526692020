import React, { useState } from "react"
import { Image } from "rebass"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import { theme } from "@layouts/theme"
import { FlexColumnLayout, FlexRowLayout } from "@northone/ui"
import { images } from "@assets/images/images"
import { Text } from "@components/primitive/text/text"
import {
  useCreateInvoiceAttachmentMutation,
  useGetInvoiceAttachmentUrlQuery,
} from "@generated/graphql"
import { useAppSelector } from "@core/redux/utils"
import { Analytics } from "@core/analytics/actions"
import { events } from "@core/analytics/events"
import { LoadingIcon } from "@components/primitive/loading-icon/loading-icon"
import { selectActiveBusinessID } from "@core/active-business/redux/selectors"
import { invoicePaymentsActions } from "../redux/actions"
import { Button, ButtonTypeEnum } from "@components/primitive/button/button"

const ITEM_MARGIN = 28

const InvoiceAttachmentDeleteButton = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const invoiceDetails = useAppSelector((state) => state.invoicePayments.invoiceDetails)

  return (
    <FlexRowLayout sx={{ alignItems: "flex-start" }}>
      <Button
        iconSrc={images.icons.greyTrash}
        sx={{ marginRight: 12, marginTop: 10 }}
        type={ButtonTypeEnum.TERTIARY}
        textSx={{ ...theme.textStyles["body-x-small"], color: theme.colors.ui2, letterSpacing: 1 }}
        onClick={() => {
          dispatch(
            invoicePaymentsActions.setInvoiceDetails({
              ...invoiceDetails,
              attachmentId: null,
            }),
          )
        }}
      >
        {t("invoicePayments.modal.invoiceViewer.deleteButton").toUpperCase()}
      </Button>
    </FlexRowLayout>
  )
}

export const InvoiceAttachment = () => {
  const dispatch = useDispatch()
  const invoiceDetails = useAppSelector((state) => state.invoicePayments.invoiceDetails)
  const existingAttachmentId = invoiceDetails?.attachmentId

  return (
    <FlexRowLayout
      sx={{
        width: "50%",
        padding: "40px 70px",
        margin: 0,
        height: "100%",
        backgroundColor: theme.colors.ui5,
      }}
    >
      <FlexColumnLayout
        sx={{
          backgroundColor: existingAttachmentId ? theme.colors.ui5 : theme.colors.ui7,
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
        }}
      >
        {existingAttachmentId ? (
          <InvoiceAttachmentViewer attachmentId={existingAttachmentId} />
        ) : (
          <UploadInvoiceAttachment
            onSuccessfulUpload={(attachmentId, uploadTimeInSeconds) => {
              Analytics.track(events.invoicePayments.invoiceAttachmentUploadSuccessful, {
                attachmentId,
                invoiceId: invoiceDetails?.invoiceId,
                uploadTimeInSeconds,
              })
              dispatch(
                invoicePaymentsActions.setInvoiceDetails({
                  ...invoiceDetails,
                  attachmentId,
                }),
              )
              dispatch(
                invoicePaymentsActions.setUploadedAttachmentDetails({
                  isUploading: false,
                  attachmentId,
                }),
              )
            }}
          />
        )}
      </FlexColumnLayout>
    </FlexRowLayout>
  )
}

enum AttachmentErrorEnum {
  Format = "format",
  FileSize = "fileSize",
  Unknown = "unknown",
}

type UploadErrorMessages = {
  [k in AttachmentErrorEnum.Format | AttachmentErrorEnum.FileSize]: string
}

const UPLOAD_ERROR_MESSAGES: UploadErrorMessages = {
  [AttachmentErrorEnum.Format]: "invoicePayments.modal.invoiceViewer.error.fileFormat",
  [AttachmentErrorEnum.FileSize]: "invoicePayments.modal.invoiceViewer.error.fileSize",
}

const ALLOWED_FILE_TYPES = ["application/pdf", "image/png", "image/jpeg"]
const FILE_SIZE_LIMIT = 10 * 1024 * 1024 // 10MB file size limit

interface IUploadInvoiceAttachment {
  onSuccessfulUpload?: (attachmentId: string, uploadTimeInSeconds: number) => void
}
const UploadInvoiceAttachment = (props: IUploadInvoiceAttachment) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const businessId = useAppSelector(selectActiveBusinessID)
  const isLoading = useAppSelector((state) => state.invoicePayments.uploadedAttachment?.isUploading)

  const [createAttachment] = useCreateInvoiceAttachmentMutation()
  const [attachmentErrorType, setAttachmentErrorType] =
    useState<AttachmentErrorEnum | undefined>(undefined)

  const handleUploadError = () => {
    dispatch(invoicePaymentsActions.clearUploadedAttachmentDetails())
    setAttachmentErrorType(AttachmentErrorEnum.Unknown)
  }

  const uploadAttachment = async (file: File) => {
    const uploadStartTimeMs = Date.now()
    dispatch(invoicePaymentsActions.setUploadedAttachmentDetails({ isUploading: true }))

    const isFileTypeAllowed = ALLOWED_FILE_TYPES.includes(file.type)
    const isFileSizeAllowed = file.size <= FILE_SIZE_LIMIT

    if (!isFileTypeAllowed) {
      setAttachmentErrorType(AttachmentErrorEnum.Format)
    }

    if (!isFileSizeAllowed) {
      setAttachmentErrorType(AttachmentErrorEnum.FileSize)
    }

    // create the invoice attachment to get a presigned upload url
    try {
      const result = await createAttachment({
        variables: {
          businessId,
          attachmentData: {
            extract: true,
            contentType: file.type,
            contentLength: file.size,
          },
        },
      })
      const attachment = result.data?.createInvoiceAttachment

      if (!attachment) {
        handleUploadError()
        return
      }

      // use the presigned upload url to upload to S3
      const data = new FormData()
      attachment.upload.fields.forEach(({ headerKey, headerValue }) => {
        data.append(headerKey, headerValue)
      })
      data.append("file", file)
      const response = await fetch(attachment.upload.url, {
        method: "POST",
        body: data,
        mode: "cors",
      })
      const isUploadSuccessful = response.status <= 204

      if (isUploadSuccessful) {
        const uploadEndTimeMs = Date.now()

        const uploadTimeInSeconds = (uploadEndTimeMs - uploadStartTimeMs) / 1000

        props.onSuccessfulUpload &&
          props.onSuccessfulUpload(attachment.attachmentId, uploadTimeInSeconds)
      }
    } catch {
      handleUploadError()
      return
    }
  }

  const getUploadErrorMessage = (
    type: AttachmentErrorEnum.Format | AttachmentErrorEnum.FileSize,
  ): string | undefined => {
    return UPLOAD_ERROR_MESSAGES[type]
  }

  if (isLoading && !attachmentErrorType) {
    return (
      <FlexColumnLayout>
        <LoadingIcon dimension={50} style={{ marginBottom: 30 }} />
        <Text tag="body-large-bold">Your invoice is being uploaded</Text>
      </FlexColumnLayout>
    )
  }

  return (
    <>
      {attachmentErrorType ? (
        <>
          <Image sx={{ height: 46, width: 46, margin: ITEM_MARGIN }} src={images.icons.caution} />
          <Text tag="body-large-bold" sx={{ marginBottom: ITEM_MARGIN, color: theme.colors.ui2 }}>
            {t(
              attachmentErrorType === AttachmentErrorEnum.Unknown
                ? "invoicePayments.modal.invoiceViewer.error.defaultTitle"
                : "invoicePayments.modal.invoiceViewer.error.uploadTitle",
            )}
          </Text>
          {attachmentErrorType !== AttachmentErrorEnum.Unknown && (
            <Text tag="body-small" sx={{ color: theme.colors.ui2 }}>
              {t(`${getUploadErrorMessage(attachmentErrorType)}`)}
            </Text>
          )}
          <Text tag="body-small" sx={{ color: theme.colors.ui2 }}>
            {t("invoicePayments.modal.invoiceViewer.error.uploadAgain")}
          </Text>
        </>
      ) : (
        <>
          <Image
            sx={{ height: 46, width: 46, margin: ITEM_MARGIN }}
            src={images.icons.invoiceUploadIcon}
          />
          <Text tag="body-large-bold" sx={{ marginBottom: ITEM_MARGIN }}>
            {t("invoicePayments.modal.invoiceViewer.title")}
          </Text>
          <Text tag="body-small" sx={{ marginBottom: ITEM_MARGIN }}>
            {t("invoicePayments.modal.invoiceViewer.subTitle")}
          </Text>
        </>
      )}
      <input
        type="file"
        accept=".pdf,.jpg,.jpeg,.png"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          opacity: 0,
          cursor: "pointer",
        }}
        onChange={async (e) => {
          const file = e.target.files?.item(0)
          if (!file) return
          Analytics.track(events.invoicePayments.invoiceDetails.attachmentUploadStarted)
          await uploadAttachment(file)
        }}
      />
    </>
  )
}

interface IInvoiceAttachmentViewerProps {
  attachmentId: string
}
const InvoiceAttachmentViewer = (props: IInvoiceAttachmentViewerProps) => {
  const businessId = useAppSelector(selectActiveBusinessID)

  const { data, stopPolling } = useGetInvoiceAttachmentUrlQuery({
    variables: { businessId, attachmentId: props.attachmentId },
    // We get the PDF or Image from a S3 signed url, so we don't want to cache it to avoid having an expired url in the cache.
    fetchPolicy: "network-only",
    pollInterval: 250,
  })

  const { url, contentType } = data?.getInvoiceAttachment?.file || {}

  if (url) {
    stopPolling()
  }

  if (!url) {
    return <LoadingIcon dimension={50} />
  }

  if (contentType === "application/pdf") {
    return (
      <>
        <PDFViewer url={url} />
        <InvoiceAttachmentDeleteButton />
      </>
    )
  }
  if (contentType?.startsWith("image/")) {
    return (
      <>
        <ImageViewer url={url} />
        <FlexRowLayout alignItems="flex-end" sx={{ width: "100%" }}>
          <InvoiceAttachmentDeleteButton />
        </FlexRowLayout>
      </>
    )
  }

  return null
}

interface IPDFViewerProps {
  url: string
}
const PDFViewer = ({ url }: IPDFViewerProps) => {
  return <iframe src={url} style={{ border: 0, width: "100%", height: "100%" }} />
}

interface IImageViewerProps {
  url: string
}

const ImageViewer = ({ url }: IImageViewerProps) => {
  return <img src={url} style={{ display: "block", maxWidth: "100%", maxHeight: "100%" }} />
}
