import React from "react"
import { SxStyleProp } from "rebass"
import { FlexColumnLayout } from "@layouts/flex"
import { theme } from "@layouts/theme"

export interface PanelProps {
  children?: React.ReactNode
  sx?: SxStyleProp
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  panelRef?: React.RefObject<HTMLDivElement>
}

/**
 * Defines a primitive panel component - Must be extended
 * @param sx
 */
export const Panel = ({ children, sx, onClick, panelRef }: PanelProps) => {
  return (
    <FlexColumnLayout
      onClick={onClick}
      sx={{
        padding: 0,
        borderRadius: "8px",
        boxShadow: theme.boxShadow,
        backgroundColor: theme.colors.ui7,
        ...sx,
      }}
      boxRef={panelRef}
    >
      {children}
    </FlexColumnLayout>
  )
}
