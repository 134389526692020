import airbnb from "./icon-airbnb.png"
import alto from "./icon-alto.png"
import amazon from "./icon-amazon.png"
import etsy from "./icon-etsy.png"
import expensify from "./icon-expensify.png"
import gusto from "./icon-gusto.png"
import lyft from "./icon-lyft.png"
import paypal from "./icon-paypal.png"
import quickbooks from "./icon-quickbooks.png"
import shopify from "./icon-shopify.png"
import square from "./icon-square.png"
import stripe from "./icon-stripe.png"
import venmo from "./icon-venmo.png"
import xero from "./icon-xero.png"

export const integrationIcons = {
  airbnb,
  alto,
  amazon,
  etsy,
  expensify,
  gusto,
  lyft,
  paypal,
  quickbooks,
  shopify,
  square,
  stripe,
  venmo,
  xero,
}
