import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { LoadingIcon } from "@northone/ui"

import { BaseLayout } from "@layouts/base-layout"
import { FlexColumnLayout, FlexRowLayout } from "@layouts/flex"
import { Text } from "@components/primitive/text/text"
import { theme } from "@layouts/theme"
import { selectActiveBusinessID } from "@core/active-business/redux/selectors"
import { useAppSelector } from "@core/redux/utils"
import {
  RevenueHubConnectionStatusEnum,
  useListRevenueHubConnectionsQuery,
} from "@generated/graphql"
import { RevenueHubPlatformConnect } from "@features/revenue-hub/revenue-hub-platform-connect"
import { ErrorScreen } from "@components/composite/error-screen/error-screen"
import { RevenueHubPlatformPanel } from "./revenue-hub-platform-panel"
import { defaultPlatformsList } from "./platforms"
import { Filters, RevenueHubFilters, defaultFilters } from "./components/filters"
import { RefreshButton } from "./components/refresh-button"
import { RevenueHubBanner } from "./components/banner"
import { Analytics } from "@core/analytics/actions"
import { events } from "@core/analytics/events"
import { REVENUE_HUB_FAQ, RUTTER_AGREEMENT_URL } from "@utils/constants"

export const RevenueHubLanding = () => {
  const { t } = useTranslation()
  const businessId = useAppSelector(selectActiveBusinessID)
  useEffect(() => {
    Analytics.track(events.revenueHub.pageLoad, { businessId })
    return () => Analytics.track(events.revenueHub.pageExit, { businessId })
  }, [])

  const [filters, setFilters] = useState<Filters>(defaultFilters)

  const { data, error, loading, refetch } = useListRevenueHubConnectionsQuery({
    variables: { businessId },
    notifyOnNetworkStatusChange: true, // set loading to true while refetching
    fetchPolicy: "no-cache",
  })

  const connections = data?.revenueHub?.connections || []

  const enabledPlatforms = defaultPlatformsList

  /*
   * This code loops through all of the platforms in the list of enabled platforms and
   * then loops through all of the connections to a platform, looking for a connection.
   * If no active connection is found, the first connection is used instead.
   */
  const platformConnections = enabledPlatforms.map((platformInfo) => {
    const matchingConnections = connections.filter(
      (connection) => connection.platform === platformInfo.type,
    )
    const activeConnection = matchingConnections.find(
      (connection) => connection.status === RevenueHubConnectionStatusEnum.ACTIVE,
    )
    const firstConnection = matchingConnections[0]
    return {
      platformInfo,
      connection: activeConnection || firstConnection,
    }
  })

  const platformsWithConnection = platformConnections.filter(
    (platformConnection) => platformConnection.connection,
  )
  const platformsWithoutConnection = platformConnections.filter(
    (platformConnection) => !platformConnection.connection,
  )

  const hasPlatformsWithConnection = platformsWithConnection.length > 0
  const hasPlatformsWithoutConnection = platformsWithoutConnection.length > 0

  const refreshOnClick = () => {
    refetch()
    Analytics.track(events.revenueHub.refreshClick, { businessId })
  }

  const renderBody = () => {
    if (loading) {
      return (
        <FlexRowLayout alignContent="center" sx={borderStyle}>
          <FlexColumnLayout
            alignItems="center"
            justifyContent="center"
            sx={{ margin: "auto", height: "50vh" }}
          >
            <LoadingIcon dimension={74} isCentered />
          </FlexColumnLayout>
        </FlexRowLayout>
      )
    }

    if (error) {
      return (
        <FlexRowLayout alignContent="center" sx={borderStyle}>
          <ErrorScreen
            sx={{ margin: "auto", alignItems: "center" }}
            size="large"
            retry={() => refetch()}
            subtitle={t("revenueHub.landing.errorScreen.subtitle")}
            retryTitle={t("revenueHub.landing.errorScreen.buttonLabel")}
          />
        </FlexRowLayout>
      )
    }

    return (
      <>
        {hasPlatformsWithConnection ? (
          <>
            {/* date filter section start */}
            <FlexRowLayout sx={{ justifyContent: "flex-end", marginY: 32, gap: 3 }}>
              <RevenueHubFilters filters={filters} onChange={(filters) => setFilters(filters)} />
              <RefreshButton onClick={refreshOnClick} />
            </FlexRowLayout>
            {/* date filter section end */}

            <div style={{ ...gridStyle, ...borderStyle, paddingBottom: 32, marginBottom: 32 }}>
              {platformsWithConnection.map(({ platformInfo, connection }) => (
                <RevenueHubPlatformPanel
                  key={connection.id}
                  platformInfo={platformInfo}
                  connectionId={connection.id}
                  filters={filters}
                />
              ))}
            </div>
          </>
        ) : (
          <RevenueHubBanner />
        )}
        {hasPlatformsWithoutConnection && (
          <div style={gridStyle}>
            {platformsWithoutConnection.map(({ platformInfo }) => (
              <RevenueHubPlatformConnect
                key={platformInfo.name}
                platformInfo={platformInfo}
                onConnectionSaved={refetch}
              />
            ))}
          </div>
        )}
      </>
    )
  }
  // TODO: Create separate component for the disclaimers
  return (
    <BaseLayout withLogoFooter pageTitle={t("pageTitles.revenue")} childrenGridColumn="2/11">
      <FlexColumnLayout>
        <Text tag="h2">{t("revenueHub.landing.title")}</Text>
        {renderBody()}
        <Text
          color={theme.colors.ui2}
          sx={{ marginTop: 64 }}
          tag="body-small"
          textWithEmbeddedLinksProps={{
            text: t("revenueHub.platformConnect.rutterUserAgreement"),
            linksInOrder: [RUTTER_AGREEMENT_URL],
            linkStyle: { color: theme.colors.ui2 },
          }}
        />
        <Text tag="body-small" color={theme.colors.ui2}>
          {t("revenueHub.platformConnect.etsyDisclaimer")}
        </Text>
        <FlexRowLayout
          sx={{
            marginTop: 3,
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Text tag="body-small-bold" textColor={theme.colors.ui2}>
            {t("revenueHub.disclosure.title")}
          </Text>
          <Text
            color={theme.colors.ui2}
            sx={{ margin: 1 }}
            tag="body-small"
            textWithEmbeddedLinksProps={{
              text: t("revenueHub.disclosure.message"),
              linksInOrder: [REVENUE_HUB_FAQ, 'javascript:window.Intercom("show")'],
              linkStyle: { color: theme.colors.ui2 },
            }}
          />
        </FlexRowLayout>
      </FlexColumnLayout>
    </BaseLayout>
  )
}

const gridStyle = {
  display: "grid",
  gridTemplateColumns: "repeat(3, 1fr)",
  gridGap: 24,
}

const borderStyle = {
  borderBottom: `1px solid ${theme.colors.ui5}`,
}
