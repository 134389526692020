import React from "react"
import { Box } from "rebass"
import { Text } from "@components/primitive/text/text"
import { Button, ButtonTypeEnum } from "@components/primitive/button/button"
import { theme } from "@layouts/theme"

const baseStyles = {
  display: "block",
  margin: "0.75em auto",
  textAlign: "center",
}

interface IReloadPageDisplayProps {
  title: string
  subtitle: string
  ctaButtonText: string

  width?: string | number
}

export const ReloadPageDisplay: React.FC<IReloadPageDisplayProps> = ({
  title,
  subtitle,
  ctaButtonText,
  width,
}: IReloadPageDisplayProps) => {
  const reloadPage = () => window.location.reload()

  return (
    <Box sx={{ width: width ?? "auto" }}>
      <Text tag="h3Serif" sx={baseStyles}>
        {title}
      </Text>
      <Text tag="body" sx={{ ...baseStyles, color: theme.colors.ui2 }}>
        {subtitle}
      </Text>
      <Button sx={baseStyles} type={ButtonTypeEnum.SECONDARY} onClick={reloadPage}>
        {ctaButtonText}
      </Button>
    </Box>
  )
}
