import { sanitizePhoneNumber } from "@utils/format"
import * as Sentry from "@sentry/browser"
import Branch from "branch-sdk"
import { config } from "@utils/environment"

const LINKING_SERVICE_INIT_FAILURE = "Linking Service Initialization Failed"
const LINKING_SERVICE_INIT_SUCCESS = "Linking Service Initialized Successfully"
const SMS_MESSAGE_SUCCESS = "SMS Text Message App Link Request Sent Successfully"
const SMS_MESSAGE_FAILURE = "SMS Text Message App Link Request Sent Failed"

/**
 * This function initializes the Branch SDK with our branch key. If the
 * initialization fails, a state flag will be set that can be used in the
 * presentation layer for conditionally adding/removing features related
 * to SMS message sending, etc.
 */
const initialize = (): Promise<string> => {
  return new Promise((res, rej) => {
    Branch.init(config.branchSDK || "", undefined, (err) => {
      if (err) {
        Sentry.captureException(err)
        rej(LINKING_SERVICE_INIT_FAILURE as string)
      }
      res(LINKING_SERVICE_INIT_SUCCESS)
    })
  })
}

/**
 * This function uses the Branch SDK to fire off a request to send an SMS
 * message containing a deep link for downloading the mobile app.
 *
 * @param {string} phone - The phone number to send a deep link to
 */
const sendSMS = (phone: string): Promise<string> => {
  const linkData = {
    channel: "WebBanking",
    feature: "Text Me the App Link",
    data: {
      phoneNumber: phone,
    },
  }

  // Note: make_new_link param defaults to false. If set to true, sendSMS will generate a new link even if
  // one already exists.
  return new Promise((res, rej) => {
    Branch.sendSMS(sanitizePhoneNumber(phone), linkData, { make_new_link: false }, (err: any) => {
      if (err) {
        Sentry.captureException(err)
        rej(SMS_MESSAGE_FAILURE as string)
      }
      res(SMS_MESSAGE_SUCCESS)
    })
  })
}

/**
 * The linking service leverages the Branch SDK for deferred deep and contextual deep linking.
 * It assists with providing deep links that support sharing of links and invites with full
 * attribution and analytics. The Branch SDK provides a "sendSMS()" API to send an application
 * download link directly to a user's phone.
 *
 * Website: https://help.branch.io/developers-hub/docs/native-sdks-overview
 */
export const LinkingService = {
  initialize,
  sendSMS,
}
