import React, { ReactElement } from "react"
import { SxStyleProp } from "rebass"

import { theme } from "@layouts/theme"
import { ITextProps, Text } from "@components/primitive/text/text"
import { FlexColumnLayout } from "@layouts/flex"
import { images } from "@assets/images/images"

export interface INoticeBoxProps {
  level: "error" | "warning" | "info"
  text?: string
  content?: ReactElement
  containerSx?: SxStyleProp
  textSx?: SxStyleProp
  textProps?: Omit<ITextProps, "css">
  showIcon?: boolean
}

export interface IErrorMessage {
  text: string
  customerCareLinkRequired?: boolean
}

/**
 * NoticeBox component
 * @description A colored banner to display notices in a box
 */
export const NoticeBox = (props: INoticeBoxProps) => {
  const { level, text, containerSx, content, showIcon, textProps, textSx } = props

  const { backgroundColor, borderColor, icon, textStyle } =
    level === "error"
      ? {
          backgroundColor: theme.colors.error10,
          borderColor: theme.colors.error100,
          icon: images.icons.error,
          textStyle: textSx,
        }
      : level === "warning"
      ? {
          backgroundColor: theme.colors.goldTint4,
          borderColor: theme.colors.goldTint2,
          icon: images.icons.noticeGold,
          textStyle: { color: theme.colors.ui2, ...textSx },
        }
      : // "info"
        {
          backgroundColor: theme.colors.blue10,
          borderColor: theme.colors.blue60,
          icon: images.icons.noticeBlue,
          textStyle: textSx,
        }

  return (
    <FlexColumnLayout
      sx={{
        backgroundColor,
        borderColor,
        borderWidth: 1,
        borderStyle: "solid",
        borderRadius: 4,
        padding: 20,
        mb: 22,
        ...(showIcon !== false && {
          paddingLeft: 40,
          backgroundImage: `url('${icon}')`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "14px 20px",
          backgroundSize: "auto 16px",
        }),

        ...containerSx,
      }}
    >
      {content}
      {Boolean(text) && (
        <Text tag="body-small" sx={textStyle} {...textProps}>
          {text}
        </Text>
      )}
      {Boolean(!text && textProps?.textWithEmbeddedLinksProps) && (
        <Text
          tag="body-small"
          sx={textSx}
          textWithEmbeddedLinksProps={textProps?.textWithEmbeddedLinksProps}
        />
      )}
    </FlexColumnLayout>
  )
}
