export const plaidFunding = {
  fundingFailed: {
    title: "We couldn’t process your initial funding at this time",
    subtitle: "Download the mobile app to add funds or contact our Customer Care team",
  },
  moneyOnMove: {
    title: "Your funds are on the move!",
    subtitle:
      "*Your transfer is processing*. Your funds will take at least 4 business days to arrive in your new account.\n\nOnce your funds have been transfered, you will be able to access your new NorthOne account.",
  },
}
