import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { motion } from "framer-motion"
import { useHistory } from "react-router-dom"
import { useAppSelector } from "@core/redux/utils"
import { Analytics } from "@core/analytics/actions"
import { events } from "@core/analytics/events"
import { RoutesEnum } from "@routers/types"
import {
  PreviewPanelAnimation,
  PREVIEW_PANEL_TRANSITION,
} from "@components/animations/preview-panel/preview-panel-animation"
import { PreviewSubAccountPanel } from "@components/composite/preview-sub-account-panel/preview-sub-account-panel"
import { Button, ButtonTypeEnum } from "@components/primitive/button/button"
import { accountsActions } from "@features/accounts/redux/actions"
import { selectCreateSubAccountState } from "@features/accounts/redux/selectors"
import { FlexColumnLayout } from "@layouts/flex"

export const CreateSubAccountReview: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const subAccountState = useAppSelector(selectCreateSubAccountState)
  const [{ name, initialBalance, percentage, scheduledAmount, scheduledFrequency }] =
    useState(subAccountState)

  return (
    <PreviewPanelAnimation>
      <PreviewSubAccountPanel
        {...{ name, initialBalance, percentage, scheduledAmount, scheduledFrequency }}
      />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: PREVIEW_PANEL_TRANSITION }}
      >
        <FlexColumnLayout
          sx={{
            width: "100%",
            paddingTop: 20,
          }}
        >
          <Button
            type={ButtonTypeEnum.PRIMARY_BLUE}
            onClick={() => {
              history.push(RoutesEnum.ACCOUNTS_OVERVIEW)
              dispatch(accountsActions.clearCreateSubAccountState())
              Analytics.track(events.accounts.create.done)
            }}
          >
            {t("buttons.done")}
          </Button>
        </FlexColumnLayout>
      </motion.div>
    </PreviewPanelAnimation>
  )
}
