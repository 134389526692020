import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "rebass"
import { Analytics } from "@core/analytics/actions"
import { Text } from "@components/primitive/text/text"
import { MaskTextInput } from "@components/extended/fields/mask-input"
import { Button, ButtonTypeEnum } from "@components/primitive/button/button"
import { DownloadButtons } from "@components/composite/download-buttons/download-buttons"
import { GOOGLE_PLAY_LINK, APP_STORE_LINK } from "@components/composite/download-buttons/constants"
import { theme } from "@layouts/theme"
import { FlexRowLayout } from "@layouts/flex"
import { validate } from "@utils/validate"
import { sanitizePhoneNumber } from "@utils/format"
import { usePhoneNumberQuery } from "@generated/graphql"

declare global {
  interface Window {
    branch: any
  }
}

interface IDownloadBlockProps {
  linkDataFeature: string
  downloadClickEvent: string
}

export const DownloadBlock: React.FC<IDownloadBlockProps> = ({
  linkDataFeature,
  downloadClickEvent,
}) => {
  const [isSendingSMS, setIsSendingSMS] = useState(false)
  const [hasError, setHasError] = useState(false)

  const { data } = usePhoneNumberQuery({
    onCompleted: () => {
      const rawPhoneNumber = data?.me?.phone || ""
      setPhoneNumber(sanitizePhoneNumber(rawPhoneNumber))
    },
  })

  const [isSent, setIsSent] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState("")
  const { t } = useTranslation()

  const sendSMS = () => {
    setHasError(false)
    setIsSendingSMS(true)
    const linkData = {
      channel: "WebBanking",
      feature: linkDataFeature,
    }
    window.branch.sendSMS(phoneNumber.replace(/[^0-9]/g, ""), linkData, (error: any) => {
      setIsSendingSMS(false)

      if (error) {
        setHasError(true)
      } else {
        setIsSent(true)
        setTimeout(() => setIsSent(false), 5000)
      }
    })
  }
  return (
    <React.Fragment>
      <Text sx={{ marginBottom: 16 }} tag="body-large-bold">
        {t("downloadModal.title")}
      </Text>
      <FlexRowLayout sx={{ alignItems: "center" }}>
        <MaskTextInput
          label={t("downloadModal.label")}
          placeholder={t("downloadModal.placeholder")}
          initialValue={data?.me?.phone ? sanitizePhoneNumber(data?.me?.phone) : ""}
          value={phoneNumber}
          onChange={(value) => {
            if (hasError) {
              setHasError(false)
            }
            setPhoneNumber(value)
            setIsSent(false)
          }}
          maskType="PHONE_NUMBER"
          errorMessage={hasError ? t("downloadModal.error") : ""}
        />

        <Button
          isLoading={isSendingSMS}
          type={isSent ? ButtonTypeEnum.TERTIARY : ButtonTypeEnum.PRIMARY_BLUE}
          sx={{ marginLeft: 16, marginBottom: -1 }}
          onClick={() => {
            Analytics.track(downloadClickEvent)
            sendSMS()
          }}
          disabled={isSent || !validate.phoneNumber(phoneNumber.replace(/[^0-9]/g, ""))}
        >
          {isSent ? t("downloadModal.sentButton") : t("downloadModal.sendLink")}
        </Button>
      </FlexRowLayout>
      <Text tag="body-small" sx={{ width: 500 }} textColor={theme.colors.ui2}>
        {t("downloadModal.subtitle1")}
        <Link sx={{ color: theme.colors.ui2 }} href={APP_STORE_LINK} target="_blank">
          {t("downloadModal.appStore")}
        </Link>
        {t("downloadModal.or")}
        <Link sx={{ color: theme.colors.ui2 }} href={GOOGLE_PLAY_LINK} target="_blank">
          {t("downloadModal.googlePlay")}
        </Link>
        {t("downloadModal.subtitle2")}
      </Text>
      <DownloadButtons sx={{ marginTop: 20 }} />
    </React.Fragment>
  )
}
