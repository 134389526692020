import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { motion } from "framer-motion"
import { MutationFunctionOptions, FetchResult } from "@apollo/client"
import { Text } from "@northone/ui"
import { useAppSelector } from "@core/redux/utils"
import { selectMoveMoney } from "@core/redux/app/selectors"
import { Analytics } from "@core/analytics/actions"
import {
  PreviewPanelAnimation,
  PREVIEW_PANEL_TRANSITION,
} from "@components/animations/preview-panel/preview-panel-animation"
import { PreviewTransactionPanel } from "@components/composite/transaction-panel/preview-transaction-panel"
import { ButtonTypeEnum, Button } from "@components/primitive/button/button"
import { DeleteButton } from "@components/extended/buttons/delete-button"
import { AnimatePresence } from "@components/animations/animate-presence/animation-presence"
import { NoticeBox } from "@components/primitive/notice-box/notice-box"
import { ERRORS_GENERIC } from "@features/move-money/utils/i18n-translations"
import { moveMoneyActions } from "@features/move-money/redux/actions"
import { DiscardPaymentModal } from "@features/move-money/discard-modal/discard-modal"
import {
  PREVIEW_PANEL_EDIT,
  PREVIEW_PANEL_CONFIRM,
  PREVIEW_PANEL_SEND_PAYMENT,
  PREVIEW_PANEL_SEND_WIRE_PAYMENT,
} from "@features/move-money/utils/i18n-translations"
import { TransactionTypeEnum } from "@generated/graphql"
import { FlexColumnLayout, FlexRowLayout } from "@layouts/flex"
import { theme } from "@layouts/theme"

interface IMoveMoneyConfirmCardProps<TData, TVariables> {
  gqlVariables: TVariables
  createMutation: (
    options?: MutationFunctionOptions<TData, TVariables>,
  ) => Promise<FetchResult<TData>>
  isLoading: boolean
  hasError: boolean
  sendPaymentEvent: string
  discardPaymentEvent: string
  discardConfirmEvent: string
  discardCancelEvent: string
  note?: string
}

type TMoveMoneyConfirmCard = <TData, TVariables>(
  props: IMoveMoneyConfirmCardProps<TData, TVariables>,
) => React.ReactElement<IMoveMoneyConfirmCardProps<TData, TVariables>>

export const MoveMoneyConfirmCard: TMoveMoneyConfirmCard = ({
  gqlVariables,
  createMutation,
  isLoading,
  hasError,
  sendPaymentEvent,
  discardPaymentEvent,
  discardCancelEvent,
  discardConfirmEvent,
  note,
}) => {
  const dispatch = useDispatch()
  const [discardModalOpen, setDiscardModalOpen] = useState(false)
  const { amount, recipient, paymentMethod, subAccount } = useAppSelector(selectMoveMoney)

  const getSendButtonCopy = () => {
    if (paymentMethod === TransactionTypeEnum.SUB_ACCOUNT_TRANSFER) {
      return PREVIEW_PANEL_CONFIRM
    } else if (paymentMethod === TransactionTypeEnum.DOMESTIC_WIRE) {
      return PREVIEW_PANEL_SEND_WIRE_PAYMENT
    }
    return PREVIEW_PANEL_SEND_PAYMENT
  }

  return (
    <>
      <DiscardPaymentModal
        isOpen={discardModalOpen}
        closeModal={() => setDiscardModalOpen(false)}
        cancelEvent={discardCancelEvent}
        discardEvent={discardConfirmEvent}
      />
      <PreviewPanelAnimation>
        <PreviewTransactionPanel
          amount={amount}
          counterpartyName={recipient?.name || ""}
          transactionType={paymentMethod}
          editButtonTitle={PREVIEW_PANEL_EDIT}
          editButtonOnClick={() => dispatch(moveMoneyActions.navigateBack())}
          subAccountName={subAccount.name}
          counterpartyLogoSrc={recipient.logo}
        >
          <Text tag="body" sx={{ color: theme.colors.ui2 }}>
            {note}
          </Text>
        </PreviewTransactionPanel>

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: PREVIEW_PANEL_TRANSITION }}
        >
          <FlexRowLayout
            sx={{
              width: "100%",
              paddingTop: 10,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FlexColumnLayout sx={{ paddingRight: 15, flexGrow: 1 }}>
              <Button
                isLoading={isLoading}
                type={ButtonTypeEnum.PRIMARY_BLUE}
                onClick={() => {
                  createMutation({ variables: gqlVariables })
                  Analytics.track(sendPaymentEvent)
                }}
                sx={{ width: "100%" }}
              >
                {getSendButtonCopy()}
              </Button>
            </FlexColumnLayout>
            <DeleteButton
              onClick={() => {
                setDiscardModalOpen(true)
                Analytics.track(discardPaymentEvent)
              }}
              sx={{ width: 42, height: 42, flexShrink: 0 }}
            />
          </FlexRowLayout>
          <AnimatePresence animation="fadeAndExpand">
            {hasError && <NoticeBox level="error" text={ERRORS_GENERIC} />}
          </AnimatePresence>
        </motion.div>
      </PreviewPanelAnimation>
    </>
  )
}
