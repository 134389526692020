import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { LoadingIcon } from "@northone/ui"

import { RoutesEnum } from "@routers/types"
import { Analytics } from "@core/analytics/actions"
import { events } from "@core/analytics/events"
import { ActionBox, IActionBoxProps } from "@components/composite/action-box/action-box"
import { Text } from "@components/primitive/text/text"
import { handleSupportButtonClick } from "@features/settings/contact-info/contact-info"
import { SupportFAQs } from "@features/support/faqs/landing"
import { BaseLayout } from "@layouts/base-layout"
import { FlexColumnLayout, FlexRowLayout } from "@layouts/flex"
import { images } from "@assets/images/images"
import { useGetChatDetails } from "../utils"

export const SupportOverview = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const [isIntercomOpen, setIsIntercomOpen] = useState(false)
  const { chatTitle, isChatOffline, chatButtonText, isLoadingChatTitle } = useGetChatDetails()

  const chatHoursProps = {
    text: chatTitle,
    buttonText: chatButtonText,
    buttonSx: {
      ...(isChatOffline
        ? {
            width: "90%",
            marginTop: 16,
          }
        : {
            width: "67%",
            marginTop: 32,
          }),
    },
  }

  const actionItems: IActionBoxProps[] = [
    {
      title: t("support.overview.onlineChat"),
      buttonIcon: images.icons.export,
      onClick: () => {
        Analytics.track(events.support.talkToUs)
        handleSupportButtonClick(isIntercomOpen, setIsIntercomOpen)
      },
      ...chatHoursProps,
    },
    {
      title: t("support.scheduleCall.title"),
      text: t("support.overview.scheduleCallSubtitle"),
      buttonText: t("support.scheduleCall.title"),
      buttonSx: {
        width: "75%",
      },
      onClick: () => {
        Analytics.track(events.support.overviewCall)
        history.push(RoutesEnum.SUPPORT_SCHEDULE_CALL)
      },
    },
  ]

  return (
    <BaseLayout pageTitle={t("pageTitles.support.overview")}>
      <FlexColumnLayout>
        <Text tag="h2" sx={{ marginBottom: 24 }}>
          {t("support.title")}
        </Text>
        <Text tag="h4" sx={{ marginBottom: 23 }}>
          {t("support.overview.getInTouch")}
        </Text>
        <FlexRowLayout>
          {isLoadingChatTitle ? (
            <FlexColumnLayout
              alignItems="center"
              justifyContent="center"
              sx={{ margin: "auto", height: "30vh", width: "100%" }}
            >
              <LoadingIcon />
            </FlexColumnLayout>
          ) : (
            actionItems.map((props, idx) => (
              <ActionBox sx={idx === 0 ? { marginRight: 48 } : undefined} key={idx} {...props} />
            ))
          )}
        </FlexRowLayout>
        <SupportFAQs hasSearchbar={false} />
      </FlexColumnLayout>
    </BaseLayout>
  )
}
