import {
  SubscriptionPlan as GQLSubscriptionPlan,
  SubscriptionPlanContentSection,
  SubscriptionPlanContentPrice,
  SubscriptionPlanContentSectionBullet,
  SubscriptionPlanPricePeriod,
  SubscriptionStatus,
} from "@generated/graphql"
import {
  FeatureBullets,
  PlanType,
  Subscription,
  SubscriptionPlan,
  SubscriptionPlanPrice,
  SubscriptionPlanSection,
} from "./types"
import i18n from "@i18n/i18n"
import accounting from "accounting"

const formatGQLPlanSection = (
  sections?: SubscriptionPlanContentSection[] | null,
): SubscriptionPlanSection[] => {
  if (!sections) return []
  const validSections = sections.filter((section): section is SubscriptionPlanSection =>
    Boolean(section.bullets?.length),
  )
  return validSections
}

const formatGQLPlanPrice = (
  prices?: SubscriptionPlanContentPrice[] | null,
): SubscriptionPlanPrice[] => {
  if (!prices) return []
  const validPrices = prices.filter((price): price is SubscriptionPlanPrice =>
    Boolean(price.text && price.price !== undefined && price.price !== null && price.period),
  )
  return validPrices
}

export const formatGQLRetentionFeatures = (
  features?: SubscriptionPlanContentSectionBullet[] | null,
): FeatureBullets => {
  if (!features) return []
  const validBullets = features.filter((feature): feature is FeatureBullets[number] =>
    Boolean(feature.text && feature.icon),
  )
  return validBullets
}

export const formatGQLPlanData = (
  plan: GQLSubscriptionPlan | undefined,
): SubscriptionPlan | undefined => {
  const planName = plan?.name
  const planContent = plan?.content
  if (
    !planName ||
    !planContent?.title ||
    !planContent.shortTitle ||
    !formatGQLPlanPrice(planContent.price || undefined).length
  ) {
    return undefined
  }

  return {
    name: planName,
    title: planContent.title,
    shortTitle: planContent.shortTitle,
    subtitle: planContent.subtitle || "",
    upgradeButtonText: planContent.upgradeButtonText || i18n.t("buttons.upgrade"),
    description: planContent.description || [""],
    featured: Boolean(planContent.featured),
    price: formatGQLPlanPrice(planContent.price),
    sections: formatGQLPlanSection(planContent.sections),
    retentionFeatures: formatGQLRetentionFeatures(planContent.retentionFeatures),
    upgrade: planContent.upgrade || undefined,
    upsell: planContent.upsell || undefined,
  }
}

export const formatSubscriptionData = ({
  plan,
  nextBillingDate,
  status,
}: {
  plan?: SubscriptionPlan
  nextBillingDate?: string
  status?: SubscriptionStatus
}): Subscription | undefined => {
  if (plan && status === SubscriptionStatus.CANCEL_REQUESTED && nextBillingDate)
    return { plan, nextBillingDate, status }
  if (plan && status === SubscriptionStatus.ACTIVE) return { plan, nextBillingDate, status }
  return
}

export const getPlanMonthlyPrice = (plan?: SubscriptionPlan) =>
  plan?.price.find((price) => price.period === SubscriptionPlanPricePeriod.MONTHLY)?.price

export const formatMonthlyPrice = (monthlyPrice: number | undefined) =>
  accounting.formatMoney(monthlyPrice || 0)

export const isStandardPlan = (planName: string | undefined) => {
  return planName === PlanType.STANDARD
}

export const formatPlanNameString = (planName: string | undefined | null): PlanType | undefined => {
  switch (planName) {
    case "plus":
      return PlanType.PLUS
    case "standard":
      return PlanType.STANDARD
    default:
      return
  }
}
