import { useEffect, useCallback, Reducer, useState } from "react"
import { debounce } from "debounce"
import { logout as triggerLogout, renewTokenOrLogout } from "@core/auth/auth-service"
import { useSharedReducer } from "@utils/hooks"
import {
  useConfirmAdditionalOwnersOnboardingStatusMutation,
  useListOwnersQuery,
} from "@generated/graphql"
import { useAppSelector } from "@core/redux/utils"
import { Analytics } from "@core/analytics/actions"
import { events } from "@core/analytics/events"

export interface LogoutTimerHookProps {
  timeout: number
  warningTime: number
  debounce?: number
}

type LogoutTimerActiveState = {
  showWarning: false
  lastActive: Date
  countdown?: undefined
}

type LogoutTimerWarningState = {
  showWarning: true
  lastActive: Date
  countdown: number
}

type LogoutTimerAction = { type: "reset" | "warn" | "tick" | "dismiss" | "logout" }
type LogoutTimerState = LogoutTimerActiveState | LogoutTimerWarningState
type LogoutTimerReducer = Reducer<LogoutTimerState, LogoutTimerAction>

const timeSince = (date: Date) => new Date().getTime() - date.getTime()

export const useIdleLogoutTimer = ({
  timeout,
  warningTime,
  debounce: debounceTime = 300,
}: LogoutTimerHookProps) => {
  const reducer = (state: LogoutTimerState, action: LogoutTimerAction): LogoutTimerState => {
    const { lastActive, showWarning } = state
    const remainingTime = timeout + warningTime - timeSince(lastActive)

    if (action.type === "logout" || remainingTime <= 0) {
      triggerLogout()
      return state
    }

    if (showWarning) {
      switch (action.type) {
        case "dismiss":
          return {
            lastActive: new Date(),
            showWarning: false,
          }
        case "tick":
          return {
            lastActive,
            showWarning: true,
            countdown: remainingTime,
          }
        default:
          return state
      }
    } else {
      switch (action.type) {
        case "reset":
          return {
            lastActive: new Date(),
            showWarning: false,
          }
        case "warn":
          return {
            lastActive,
            showWarning: true,
            countdown: remainingTime,
          }
        default:
          return state
      }
    }
  }

  const initialState: LogoutTimerState = { showWarning: false, lastActive: new Date() }
  const [state, dispatch] = useSharedReducer<LogoutTimerReducer>(
    reducer,
    initialState,
    "idle-logout-timer",
  )

  const dismiss = useCallback(
    ({ renewSession = true } = {}) => {
      dispatch({ type: "dismiss" })
      if (renewSession) {
        renewTokenOrLogout()
      }
    },
    [dispatch],
  )
  const logout = useCallback(() => dispatch({ type: "logout" }), [dispatch])

  const { showWarning, countdown = warningTime, lastActive } = state

  // Trigger a dismiss on component load
  // Will immediately dismiss any pending warnings to avoid unexpected behavior
  useEffect(() => {
    dismiss({ renewSession: false })
  }, [dismiss])

  useEffect(() => {
    if (!showWarning) {
      // Dispatches reset events from user actions
      const reset = debounce(() => dispatch({ type: "reset" }), debounceTime)
      const events = ["load", "mousemove", "mousedown", "click", "scroll", "keypress"]
      events.forEach((event) => window.addEventListener(event, reset))
      return () => events.forEach((event) => window.removeEventListener(event, reset))
    }
  }, [dispatch, showWarning, debounceTime])

  useEffect(() => {
    if (!showWarning) {
      // Listens to see if users have been inactive for too long
      // Timer is reset on updates to lastActive
      const warningTimeout = setTimeout(() => dispatch({ type: "warn" }), timeout)
      return () => clearTimeout(warningTimeout)
    }
  }, [dispatch, showWarning, timeout, lastActive])

  useEffect(() => {
    if (showWarning) {
      // Warning dialog recursively counts down 1 second until it logs out
      // Updates triggered by countdown to allow triggering across windows
      const clockTick = setTimeout(() => dispatch({ type: "tick" }), 1000)
      return () => clearTimeout(clockTick)
    }
  }, [dispatch, showWarning, countdown])

  return {
    remainingSeconds: Math.floor(countdown / 1000),
    showWarning,
    lastActive,
    logout,
    dismiss,
  }
}

export const useOwnersList = (businessId: string, userId: string) => {
  const {
    data: ownersListData,
    loading: ownersListLoading,
    error: ownersListError,
    refetch: ownersListRefetch,
  } = useListOwnersQuery({
    skip: !userId,
    variables: {
      businessId,
    },
  })

  const currentUser = ownersListData?.listOwners?.owners.find((owner) => owner.userId === userId)

  return {
    ownersListData,
    ownersListLoading,
    ownersListError,
    ownersListRefetch,
    currentUser,
  }
}

export const useConfirmAdditionalOwnersOnboardingStatus = () => {
  const [hasAdditionalOwnerCompletedOnboarding, setHasAdditionalOwnerCompletedOnboarding] =
    useState<boolean>()
  const [additionalOwnerOnboardingStatusLoading, setAdditionalOwnerOnboardingStatusLoading] =
    useState<boolean>(true)
  const [additionalOwnerOnboardingStatusError, setAdditionalOwnerOnboardingStatusError] =
    useState<string>()
  const businessId = useAppSelector((state) => state.activeBusiness.businessId)
  const userId = useAppSelector((state) => state.user.userId)

  const [confirmOnboardingStatus] = useConfirmAdditionalOwnersOnboardingStatusMutation({
    variables: {
      businessId,
      userId,
    },
    onCompleted: async (data) => {
      const onboardingCompleted =
        data.confirmAdditionalOwnersOnboardingStatus?.status?.onboardingCompleted
      setHasAdditionalOwnerCompletedOnboarding(onboardingCompleted)
      setAdditionalOwnerOnboardingStatusLoading(false)
    },
    onError: (error) => {
      setAdditionalOwnerOnboardingStatusLoading(false)
      setAdditionalOwnerOnboardingStatusError(error.message)

      Analytics.track(events.settings.teamBanking.confirmOnboardingStatusError)
    },
  })

  useEffect(() => {
    if (!userId || !businessId) return
    confirmOnboardingStatus()
  }, [userId, businessId])

  return {
    hasAdditionalOwnerCompletedOnboarding,
    additionalOwnerOnboardingStatusLoading,
    additionalOwnerOnboardingStatusError,
  }
}
