import React from "react"
import { FlexColumnLayout, FlexRowLayout, theme } from "@northone/ui"
import { Text } from "@components/primitive/text/text"
import { TeamOwner } from "@generated/graphql"
import { Panel } from "@components/primitive/panel/panel"
import { convertToFullName } from "@utils/format"
import { TeamMemberAvatar } from "@components/extended/avatar/team-member-avatar"
import { Image } from "rebass"
import { images } from "@assets/images/images"
import { settings } from "@i18n/locales/en/features/settings"
import { useDispatch } from "react-redux"
import { teamBankingActions } from "./redux/actions"
import { CloseButton } from "@components/primitive/close-button/close-button"
import { Button, ButtonTypeEnum } from "@components/primitive/button/button"
import { useTranslation } from "react-i18next"
import { Analytics } from "@core/analytics/actions"
import { events } from "@core/analytics/events"
import { ResendInvite } from "./resend-invite"
const TopRow = ({ fullName, email }: { fullName: string; email: string | null | undefined }) => {
  const dispatch = useDispatch()
  const onCloseClick = () => dispatch(teamBankingActions.setSelectedTeamMember(undefined))
  return (
    <FlexColumnLayout
      sx={{
        borderBottom: `1px solid ${theme.colors.ui4}`,
      }}
    >
      <FlexRowLayout sx={{ justifyContent: "space-between" }}>
        <TeamMemberAvatar name={fullName} width={48} textTag={"body-large-bold"} />
        <CloseButton onCloseClick={onCloseClick} />
      </FlexRowLayout>
      <Text
        tag={"h3Serif"}
        color={theme.colors.ui1}
        sx={{ marginTop: "24px", marginBottom: "8px" }}
      >
        {fullName}
      </Text>
      <Text
        className="text-overflow-ellipsis"
        tag={"body-small"}
        color={theme.colors.ui2}
        sx={{ paddingBottom: "24px" }}
      >
        {email}
      </Text>
    </FlexColumnLayout>
  )
}
const AccessDetailsSection = ({
  feature,
  isPrimaryOwner,
}: {
  feature: AccessDetailsProps
  isPrimaryOwner: boolean
}) => {
  const { featureLabel, accessLabel, details, primaryAdminDetails } = feature
  return (
    <FlexColumnLayout>
      <Text
        tag={"label"}
        sx={{
          textTransform: "uppercase",
          color: theme.colors.ui2,
          letterSpacing: "1px",
          fontWeight: 500,
        }}
      >
        {featureLabel}
      </Text>
      <Text tag={"body-small-bold"} sx={{ marginBottom: "10px" }}>
        {accessLabel}
      </Text>
      <FlexColumnLayout sx={{ marginBottom: "26px" }}>
        <CheckMarkList
          details={isPrimaryOwner && primaryAdminDetails ? primaryAdminDetails : details}
        />
      </FlexColumnLayout>
    </FlexColumnLayout>
  )
}
export type AccessDetailsProps = {
  featureLabel: string
  accessLabel: string
  details: string[]
  primaryAdminDetails?: string[]
}
export const CheckMarkList = ({ details }: { details: string[] }) => (
  <>
    {details.map((detail, idx) => (
      <FlexRowLayout key={idx} sx={{ alignItems: "center", marginBottom: "5px" }}>
        <Image src={images.icons.checkMark} sx={{ marginRight: "18px" }} />
        <Text tag={"body-small"} sx={{ color: theme.colors.ui2 }}>
          {detail}
        </Text>
      </FlexRowLayout>
    ))}
  </>
)

interface IBottomRowButton {
  selectedTeamMemberHasBeenInvited: boolean
  userId: string | undefined
  onboardingCompleted: boolean
}
const BottomRowButton = ({
  selectedTeamMemberHasBeenInvited,
  userId,
  onboardingCompleted,
}: IBottomRowButton) => {
  const resendInviteButton = userId ? <ResendInvite userId={userId} /> : null
  const bottomRowButton = selectedTeamMemberHasBeenInvited ? (
    resendInviteButton
  ) : (
    <AddTeamMemberButton />
  )
  return onboardingCompleted ? null : bottomRowButton
}

const BottomRow = ({
  accessDetails,
  selectedTeamMemberHasBeenInvited,
  isPrimaryOwner,
  userId,
  onboardingCompleted,
}: {
  accessDetails: AccessDetailsProps[]
  selectedTeamMemberHasBeenInvited: boolean
  isPrimaryOwner: boolean
  userId: string | undefined
  onboardingCompleted: boolean
}) => {
  return (
    <FlexColumnLayout sx={{ marginTop: "24px" }}>
      {accessDetails.map((feature, idx) => (
        <AccessDetailsSection key={idx} feature={feature} isPrimaryOwner={isPrimaryOwner} />
      ))}
      {!isPrimaryOwner && (
        <BottomRowButton
          selectedTeamMemberHasBeenInvited={selectedTeamMemberHasBeenInvited}
          userId={userId}
          onboardingCompleted={onboardingCompleted}
        />
      )}
    </FlexColumnLayout>
  )
}
const AddTeamMemberButton = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const onClick = () => {
    Analytics.track(events.settings.teamBanking.sidepanelInvite)
    dispatch(teamBankingActions.setIsModalOpen(true))
  }
  return (
    <Button
      type={ButtonTypeEnum.PRIMARY_BLUE}
      sx={{ height: "40px" }}
      iconSrc={images.icons.addContact}
      iconSx={{ height: "22px" }}
      textSx={{ fontSize: "14px" }}
      onClick={onClick}
    >
      {t("settings.pages.teamBanking.buttons.addMember")}
    </Button>
  )
}
export const TeamMemberSidePanel = ({
  teamMember,
  selectedTeamMemberHasBeenInvited,
}: {
  teamMember: TeamOwner
  selectedTeamMemberHasBeenInvited: boolean
}) => {
  if (!teamMember) return null
  const { email, firstName, lastName, isPrimaryOwner, userId, onboardingCompleted } = teamMember
  const fullName = convertToFullName({ firstName, lastName })
  return (
    <Panel sx={{ padding: "24px" }}>
      <TopRow fullName={fullName} email={email} />
      <BottomRow
        accessDetails={settings.pages.teamBanking.accessDetails}
        selectedTeamMemberHasBeenInvited={selectedTeamMemberHasBeenInvited}
        isPrimaryOwner={isPrimaryOwner ?? false}
        userId={userId || undefined}
        onboardingCompleted={onboardingCompleted ?? false}
      />
    </Panel>
  )
}
