import React from "react"
import { DateTime } from "luxon"
import satismeter from "satismeter-loader"

import { useBusinessCreatedAtQuery } from "@generated/graphql"
import { config } from "@utils/environment"
import { useAppSelector } from "@core/redux/utils"
import { selectActiveBusinessID } from "@core/active-business/redux/selectors"

export const SatismeterWrapper: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const businessId = useAppSelector(selectActiveBusinessID)
  const { data } = useBusinessCreatedAtQuery({ variables: { businessId } })
  const userId = data?.me?.id
  const userEmail = data?.me?.email
  const createdAtDate = data?.business?.createdAt || ""

  const monthsSinceCreatedAt = Math.abs(
    DateTime.fromISO(createdAtDate).diffNow("months").as("months"),
  )

  if (userId && userEmail && monthsSinceCreatedAt >= 3) {
    satismeter({
      writeKey: config.satismeterWriteKey,
      userId,
      traits: {
        email: userEmail,
      },
    })
  }
  return children
}
