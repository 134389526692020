import React from "react"
import { Box } from "rebass"
import { Text } from "@components/primitive/text/text"
import { theme } from "@layouts/theme"
import { FlexRowLayout } from "@layouts/flex"

interface IValidationOption {
  title: string
  valid: boolean
}

interface IProps {
  validationOptions: IValidationOption[]
}

export const passwordLiveValidationRegexes = {
  characters: /^.{8,}$/,
  uppercase: /^.*[A-Z]/,
  lowercase: /^.*[a-z]/,
  specialCharacter: /^.*[!@#$%^&*]/,
}

export const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{8,})/

export const PasswordValidator: React.FC<IProps> = ({ validationOptions }) => (
  <FlexRowLayout
    sx={{
      alignItems: "flex-start",
      marginTop: 10,
      marginBottom: 25,
      flexWrap: "wrap",
      width: "90%",
    }}
  >
    {validationOptions.map((option, idx) => (
      <Box
        key={idx}
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginBottom: "8px",
          width: "40%",
        }}
      >
        <ValidatorCheckIcon isActive={option.valid} />
        <Text
          tag={"body"}
          style={{
            marginLeft: 12,
            color: theme.colors.ui3,
          }}
        >
          {option.title}
        </Text>
      </Box>
    ))}
  </FlexRowLayout>
)

interface ValidatorCheckIconProps {
  isActive: boolean
}

export const ValidatorCheckIcon = ({ isActive }: ValidatorCheckIconProps) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="9" cy="9" r="9" fill={isActive ? theme.colors.blue80 : "#F2F2F2"} />
    <path d="M13.5 6L7.63816 11.8618L4.5 8.84211" stroke="white" strokeWidth="2" />
  </svg>
)
