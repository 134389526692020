import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useAppSelector } from "@core/redux/utils"
import { Analytics } from "@core/analytics/actions"
import { events } from "@core/analytics/events"
import { SearchInput } from "@components/extended/fields/search-input"
import { Button, ButtonTypeEnum } from "@components/primitive/button/button"
import { dashboardActions } from "@features/dashboard/redux/actions"
import { selectDashboardState } from "@features/dashboard/redux/selectors"
import { FlexRowLayout } from "@layouts/flex"
import { theme } from "@layouts/theme"
import { images } from "@assets/images/images"

export const DASHBOARD_SEARCH_INPUT_ID = "dashboard-search-input"

export const DashboardSearchBar: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { searchTerm, isExporting, isFiltering, filters } = useAppSelector(selectDashboardState)
  const numberOfFilters = Object.keys(filters).length

  return (
    <FlexRowLayout>
      <SearchInput
        id={DASHBOARD_SEARCH_INPUT_ID}
        value={searchTerm}
        onChange={(value) => dispatch(dashboardActions.setSearchTerm(value))}
        backgroundColorOnBlur={theme.colors.ui7}
        placeholder={t("dashboard.searchPlaceholder")}
        sx={{
          borderTopColor: "transparent",
          borderLeftColor: "transparent",
          borderRightColor: "transparent",
          width: "100%",
        }}
        onFocus={() => Analytics.track(events.searchAndFilter.search)}
      />
      <Button
        sx={{ marginX: 12 }}
        isActivated={isFiltering || numberOfFilters > 0}
        onClick={() => {
          dispatch(dashboardActions.setIsFiltering(!isFiltering))
        }}
        iconSrc={images.icons.filter}
        type={ButtonTypeEnum.TERTIARY_ALT}
      >
        {t("dashboard.filters.filters")} {numberOfFilters ? `(${numberOfFilters})` : null}
      </Button>
      <Button
        isActivated={isExporting}
        onClick={() => {
          dispatch(dashboardActions.setIsExporting(!isExporting))
        }}
        iconSrc={images.icons.download}
        type={ButtonTypeEnum.TERTIARY_ALT}
      >
        {t("dashboard.export.export")}
      </Button>
    </FlexRowLayout>
  )
}
