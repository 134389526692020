import { IRootState } from "@core/redux/root-state"
import { createSelector } from "reselect"
import { IInvoicePaymentsState } from "./reducer"

export const getInvoicePayments = (state: IRootState) => state.invoicePayments

export const selectInvoicePaymentModalHistory = createSelector(
  getInvoicePayments,
  (invoicePayments: IInvoicePaymentsState) => invoicePayments.screenHistory,
)
