import React from "react"
import { FlexColumnLayout } from "@layouts/flex"
import { Text } from "@components/primitive/text/text"
import {
  ISelectionControlProps,
  SelectionControl,
} from "@components/primitive/selection-control/selection-control"
import { theme } from "@layouts/theme"

interface IRadioListItem<T> extends Omit<ISelectionControlProps, "selected" | "onSelect" | "type"> {
  value: T
}

interface IRadioListProps<T> {
  label: string
  value: T | undefined
  items: IRadioListItem<T>[]
  onValueChange: (value: T | undefined) => void
}

type TRadioList = <T>(props: IRadioListProps<T>) => React.ReactElement<IRadioListProps<T>>

export const RadioList: TRadioList = <T,>({
  label,
  items,
  value,
  onValueChange,
}: IRadioListProps<T>) => {
  return (
    <FlexColumnLayout>
      <Text sx={{ marginBottom: "12px" }} textColor={theme.colors.ui2} tag="h5">
        {label}
      </Text>
      {items.map((item, index) => {
        const selected = item.value === value
        return (
          <SelectionControl
            containerSx={{ marginBottom: "8px" }}
            onSelect={() => {
              onValueChange(selected ? undefined : item.value)
            }}
            selected={selected}
            type="radio"
            key={index}
            {...item}
          />
        )
      })}
    </FlexColumnLayout>
  )
}
