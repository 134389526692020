import React from "react"
import { theme, TThemeColor } from "@layouts/theme"

interface IChevronProps {
  color?: TThemeColor
  direction: "up" | "right" | "down" | "left"
  size?: number | string
  strokeWidth?: number | string
}
export const Chevron = ({
  color = theme.colors.ui4,
  direction,
  size = "3px",
  strokeWidth = "2px",
}: IChevronProps) => {
  const rotation = getRotation(direction)
  return (
    <div
      style={{
        borderColor: color,
        borderStyle: "solid",
        borderRightWidth: strokeWidth,
        borderBottomWidth: strokeWidth,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        padding: size,
        width: 0,
        height: 0,
        transform: `rotate(${rotation})`,
      }}
    ></div>
  )
}

const getRotation = (direction: IChevronProps["direction"]): string => {
  switch (direction) {
    case "right":
      return "-45deg"
    case "down":
      return "45deg"
    case "left":
      return "135deg"
    case "up":
    default:
      return "-135deg"
  }
}
