export const lending = {
  landing: {
    topBanner: {
      title: "NorthOne Lending",
      description: "Access funding for your business with up to $250,000 in under 24 hours.",
      applyCTAButton: "Apply now",
      applyCTASubtext: "Apply with no impact to your credit score.",
      viewOffersCTAButton: "View my offers",
      goToLendingCTAButton: "Go to Lending",
      loanManagerCTAButton: "Open loan manager",
      continueAppCTAButton: "Continue application",
    },
    errorScreen: {
      subtitle: "Reload this page to try again.",
      buttonLabel: "Reload this page",
    },
    qualificationsIntro: "You may qualify if:",
    maxAmountValuePropTitle: "Get up to",
    industryLeadingValuePropTitle: "Industry leading",
    decisionTimeValuePropTitle: "Decisions as fast as",
    termLoanSection: {
      title: "Term Loan",
      subtitle: "Get capital to grow your business with a Term Loan",
      description:
        "Get same day access to funding. Choose an amount and repay over 6 to 12 months with a Term Loan.",
      qualifications: {
        accountAge: "Your business has been open for 1 year",
        creditScore: "Your credit score is above 620",
        accountBalance: "Your NorthOne account balance is above $2,000",
      },
      activeStateSubtitle: "Your Term Loan is active. Manage now.",
      activeStateDescription: "Your Term Loan is active. Access your funds in your account.",
      activeStateDescriptionSecondary:
        "In order to manage your Term Loan, contact {{ loanManagerName }}.",
      manageLoanBulletPointsIntro: "Manage Term Loan by:",
      manageLoanBulletPoints: {
        downloadStatements: "Downloading statements",
        repayment: "Making an early repayment",
        viewingLoanInfo: "Viewing your loan information",
      },
      productActive: "Your Term Loan is active. Manage now.",
      maxAmountValuePropValue: "$250,000",
      industryLeadingValuePropValue: "Competitive rates",
      decisionTimeValuePropValue: "24 Hours",
      applyCTAButton: "Apply for a Term Loan",
      viewOnly: {
        description: "In order to apply for a Term Loan, contact {{ loanManagerName }}.",
      },
    },
    locSection: {
      title: "Line of Credit",
      subtitle: "Get fast access to working capital with a Line of Credit",
      description:
        "Borrow as you need and pay for what you use. Get a flexible line of credit designed to meet your needs.",
      qualifications: {
        accountAge: "Your business has been open for 1 year",
        creditScore: "Your credit score is above 620",
        accountBalance: "Your NorthOne account balance is above $2,000",
      },
      activeStateSubtitle: "Your Line of Credit is active. Manage now.",
      activeStateDescription: "Your Line of Credit is active. Draw funds whenever you're ready.",
      activeStateDescriptionSecondary:
        "In order to manage your Line of Credit, contact {{ loanManagerName }}.",
      manageLoanBulletPointsIntro: "Manage Line of Credit by:",
      manageLoanBulletPoints: {
        accessFunds: "Drawing funds from your existing balance",
        repayment: "Repaying your Line of Credit",
        viewingLoanInfo: "Viewing your loan information",
      },
      maxAmountValuePropValue: "$250,000",
      industryLeadingValuePropValue: "Competitive rates",
      decisionTimeValuePropValue: "24 Hours",
      applyCTAButton: "Apply for a Line of Credit",
      viewOnly: {
        description: "In order to apply for a Line of Credit, contact {{ loanManagerName }}.",
      },
    },
  },
  legal: {
    disclaimer:
      "NorthOne is a financial technology company, not a lender. NorthOne does not issue loans or make credit decisions in connection with loans. NorthOne Lending is powered by Kanmon. All loans are subject to credit approval. Your terms may vary. NorthOne Lending loans are issued by Kanmon, a licensed lender. California Loans are made pursuant to a Department of Financial Protection and Innovation California Lenders Law License.",
    citation1:
      "Funds are disbursed using Same-day ACH and can become available in 0-3 business days depending on ACH processing.",
  },
}
