import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import validator from "validator"
import { useAppSelector } from "@core/redux/utils"
import { selectActiveBusinessID } from "@core/active-business/redux/selectors"
import { Analytics } from "@core/analytics/actions"
import { events } from "@core/analytics/events"
import { SubAccountRulesListItem } from "@components/extended/list-items/sub-account-rule/sub-account-rules-list-item"
import { TextInput } from "@components/extended/fields/text-input"
import { QueryResults } from "@components/composite/query-results/query-results"
import { SUB_ACCOUNT_DETAILS } from "@features/accounts/sub-accounts/operations.gql"
import { SUB_ACCOUNT_PERCENT_AVAILABLE } from "@features/accounts/create-sub-account/percent-rules/operations.gql"
import { pollWithMaxRetries } from "@utils/poll-with-max-retries"
import { theme } from "@layouts/theme"
import { images } from "@assets/images/images"
import {
  Maybe,
  useSubAccountTotalPercentAvailableQuery,
  useSubAccountRulePercentUpdateMutation,
  useSubAccountRulePercentCreateMutation,
} from "@generated/graphql"
import { DeleteSubAccountRuleModal } from "./delete-rule-modal"

interface ISubAccountPercentRuleItemProps {
  percentRule: Maybe<{
    __typename?: "SubAccountRulePercent"
    percent?: Maybe<number>
    availableUpdateLimit?: Maybe<number>
  }>
  subAccountId: string
}
export const SubAccountPercentRuleItem: React.FC<ISubAccountPercentRuleItemProps> = ({
  percentRule,
  subAccountId,
}) => {
  const { t } = useTranslation()
  const businessId = useAppSelector(selectActiveBusinessID)

  const [newPercent, setNewPercent] = useState<string>(percentRule?.percent?.toString() || "")
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false)

  const [hasError, setHasError] = useState(false)
  const [refetchLoading, setRefetchLoading] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)

  const { data, error, loading, refetch } = useSubAccountTotalPercentAvailableQuery({
    variables: { businessId },
  })
  const maxPercent =
    percentRule?.availableUpdateLimit || data?.subAccountRuleTotalPercentAvailable || 100

  const [updatePercentRule, { loading: updatePercentLoading }] =
    useSubAccountRulePercentUpdateMutation({
      variables: {
        businessId,
        subAccountId,
        percent: Number(newPercent),
      },
      onError: () => setHasError(true),
      onCompleted: async () => {
        setRefetchLoading(true)
        await pollWithMaxRetries([
          { query: SUB_ACCOUNT_DETAILS, variables: { businessId, subAccountId } },
          { query: SUB_ACCOUNT_PERCENT_AVAILABLE, variables: { businessId } },
        ])
        setRefetchLoading(false)
        setIsExpanded(false)
      },
    })

  const [createPercentRule, { loading: createPercentLoading }] =
    useSubAccountRulePercentCreateMutation({
      variables: {
        businessId,
        subAccountId,
        percent: Number(newPercent),
      },
      onError: () => setHasError(true),
      onCompleted: async () => {
        setRefetchLoading(true)
        await pollWithMaxRetries([
          { query: SUB_ACCOUNT_DETAILS, variables: { businessId, subAccountId } },
          { query: SUB_ACCOUNT_PERCENT_AVAILABLE, variables: { businessId } },
        ])
        setRefetchLoading(false)
        setIsExpanded(false)
      },
    })

  return (
    <>
      <DeleteSubAccountRuleModal
        isOpen={deleteModalIsOpen}
        closeOverlay={() => setDeleteModalIsOpen(false)}
        subAccountId={subAccountId}
        onDeleted={() => {
          setNewPercent("")
          setIsExpanded(false)
        }}
      />
      <SubAccountRulesListItem
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
        subtitle={
          percentRule
            ? `${percentRule.percent}% of all incoming funds`
            : t("accounts.edit.percentage.title")
        }
        iconColor={theme.colors.purple30}
        iconSrc={images.icons.percent}
        type={percentRule ? "edit" : "set"}
        button={{
          children: t("accounts.edit.saveRule"),
          disabled:
            !newPercent ||
            newPercent === percentRule?.percent?.toString() ||
            !validator.isNumeric(newPercent),
          onClick: () => {
            setHasError(false)
            if (percentRule) {
              Analytics.track(events.accounts.edit.percentageRuleEdit, { percentage: newPercent })
              updatePercentRule()
            } else {
              Analytics.track(events.accounts.edit.percentageRuleSet, { percentage: newPercent })
              createPercentRule()
            }
          },
          isLoading: createPercentLoading || refetchLoading || updatePercentLoading,
        }}
        onDeleteClick={() => setDeleteModalIsOpen(true)}
      >
        <QueryResults
          size="small"
          loading={loading}
          error={error}
          retry={() => refetch()}
          listShimmerCount={1}
          listShimmerHeight="medium"
        >
          <TextInput
            sx={{ width: 250 }}
            label={t("accounts.createSubAccount.percentRule.label")}
            placeholder={"0"}
            suffix="%"
            value={newPercent}
            onChange={setNewPercent}
            maxLength={2}
            errorMessage={
              hasError
                ? t("errors.generic")
                : Number(newPercent) > maxPercent
                ? t("accounts.createSubAccount.percentRule.percentExceeded", {
                    percentage: maxPercent,
                  })
                : ""
            }
          />
        </QueryResults>
      </SubAccountRulesListItem>
    </>
  )
}
