import { TransactionStatusEnum, TransactionTypeEnum } from "@generated/graphql"

export type TTransactionPanelStatus = TransactionStatusEnum | null | undefined
export type TTransactionPanelType = TransactionTypeEnum | undefined | null

export const isOutgoing = (amount: number) => {
  return amount < 0
}

export const isOutgoingWire = (amount: number, type: TTransactionPanelType) =>
  isOutgoing(amount) && isDomesticWire(type)

export const isCardTransaction = (transactionType: TTransactionPanelType) =>
  transactionType === TransactionTypeEnum.CARD_PURCHASE

export const isCheckDeposit = (transactionType: TTransactionPanelType) =>
  transactionType === TransactionTypeEnum.CHECK_DEPOSIT

export const isTransactionPosted = (status: TTransactionPanelStatus) =>
  status === TransactionStatusEnum.POSTED

export const isTransactionInitiated = (status: TTransactionPanelStatus) => {
  return status === TransactionStatusEnum.INITIATED
}

export const isMailedCheckTransaction = (transactionType: TTransactionPanelType) =>
  transactionType === TransactionTypeEnum.MAILED_CHECK ||
  transactionType === TransactionTypeEnum.MAILED_CHECK_REFUND

export const isTransactionActive = (status: TTransactionPanelStatus) =>
  status !== TransactionStatusEnum.CANCELED &&
  status !== TransactionStatusEnum.EXPIRED &&
  status !== TransactionStatusEnum.FAILED

export const isMailedCheckRefunded = (type: TTransactionPanelType) =>
  type === TransactionTypeEnum.MAILED_CHECK_REFUND

const isDomesticWire = (transactionType: TTransactionPanelType) =>
  transactionType === TransactionTypeEnum.DOMESTIC_WIRE
