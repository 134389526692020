import React from "react"
import i18n from "@i18n/i18n"

import { FlowModalLayout } from "@components/extended/overlay/flow-modal"
import { SourceAccountSelect } from "./source-account/form"
import { DestinationAccountSelect } from "./destination-account/form"
import { SubAccountTransferAmount } from "./amount/form"
import { SubAccountTransferConfirm } from "./confirm/card"
import { SubAccountTransferSent } from "./sent/card"

export const subAccountTransferProgressItems = [
  i18n.t("accounts.transfer.progressBar.sourceAccount"),
  i18n.t("accounts.transfer.progressBar.destinationAccount"),
  i18n.t("accounts.transfer.progressBar.amount"),
  i18n.t("accounts.transfer.progressBar.confirm"),
]

export type TSubAccountTransferScreenName =
  | "SUB_ACCOUNT_TRANSFER_SOURCE_ACCOUNT"
  | "SUB_ACCOUNT_TRANSFER_DESTINATION_ACCOUNT"
  | "SUB_ACCOUNT_TRANSFER_AMOUNT"
  | "SUB_ACCOUNT_TRANSFER_CONFIRM"
  | "SUB_ACCOUNT_TRANSFER_SENT"

export const SUB_ACCOUNT_TRANSFER_ROUTES: {
  [key in TSubAccountTransferScreenName]: {
    component: React.ReactElement
    progressItem: string
    layout: FlowModalLayout
  }
} = {
  SUB_ACCOUNT_TRANSFER_SOURCE_ACCOUNT: {
    component: <SourceAccountSelect />,
    progressItem: subAccountTransferProgressItems[0],
    layout: "form",
  },
  SUB_ACCOUNT_TRANSFER_DESTINATION_ACCOUNT: {
    component: <DestinationAccountSelect />,
    progressItem: subAccountTransferProgressItems[1],
    layout: "form",
  },
  SUB_ACCOUNT_TRANSFER_AMOUNT: {
    component: <SubAccountTransferAmount />,
    progressItem: subAccountTransferProgressItems[2],
    layout: "form",
  },
  SUB_ACCOUNT_TRANSFER_CONFIRM: {
    component: <SubAccountTransferConfirm />,
    progressItem: subAccountTransferProgressItems[3],
    layout: "centered",
  },
  SUB_ACCOUNT_TRANSFER_SENT: {
    component: <SubAccountTransferSent />,
    progressItem: subAccountTransferProgressItems[4],
    layout: "centered",
  },
}
