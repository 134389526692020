import React from "react"
import { useTranslation } from "react-i18next"

import { images } from "@assets/images/images"
import { RoutesEnum } from "@routers/types"
import { useAppSelector } from "@core/redux/utils"
import { events } from "@core/analytics/events"
import { Analytics } from "@core/analytics/actions"
import { NavBarLink } from "./nav-bar-link"

interface IPerksButtonProps {
  id: string
}

export const PerksButton: React.FC<IPerksButtonProps> = ({ id }) => {
  const { t } = useTranslation()

  const perks = useAppSelector((state) => state.perks)
  const { hubLastVisited: perksLastVisited } = perks
  const showBadge = !perksLastVisited

  const trackAnalytics = () => {
    const { variables } = events.perks
    Analytics.track(events.perks.hubLaunch, {
      source: variables.hubLaunchSourceNavigationBar,
    })
  }

  return (
    <NavBarLink
      id={id}
      text={t("buttons.perks")}
      to={RoutesEnum.PERKS}
      onClick={trackAnalytics}
      iconSrc={images.icons.perksDefault}
      activeIconSrc={images.icons.perksActive}
      notification={showBadge}
    />
  )
}
