import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { useAppSelector } from "@core/redux/utils"
import { Analytics } from "@core/analytics/actions"
import { events } from "@core/analytics/events"
import { selectActiveBusinessID } from "@core/active-business/redux/selectors"
import { FlexRowLayout } from "@northone/ui"
import { pollWithMaxRetries } from "@utils/poll-with-max-retries"
import { useTransactionNoteUpdateMutation } from "@generated/graphql"
import { TRANSACTION_PANEL_QUERY } from "@features/dashboard/transactions/operations.gql"
import { AnimatePresence } from "@components/animations/animate-presence/animation-presence"
import { RoundedLongTextInput } from "@components/extended/fields/rounded-long-text-input"
import { Button, ButtonTypeEnum } from "@components/primitive/button/button"
import { NoticeBox } from "@components/primitive/notice-box/notice-box"

import {
  isCardTransaction,
  isOutgoing,
  isTransactionPosted,
  TTransactionPanelStatus,
  TTransactionPanelType,
} from "./utils"
import { TransactionPanelItem } from "./base-transaction-panel"

interface IProps {
  transactionId: string
  note: string | undefined
  amount: number
  type: TTransactionPanelType
  status: TTransactionPanelStatus
}

export const TransactionPanelNotes = ({
  transactionId,
  note = "",
  amount,
  type,
  status,
}: IProps) => {
  const { t } = useTranslation()
  const businessId = useAppSelector(selectActiveBusinessID)
  const [newNote, setNewNote] = useState<string>("")
  const [isButtonTemporarilyDisabled, setIsButtonTemporarilyDisabled] = useState<boolean>(false)
  const [hasError, setHasError] = useState<boolean>(false)

  const [updateNote, { loading }] = useTransactionNoteUpdateMutation({
    variables: { businessId, transactionId, note: newNote },
    onError: () => {
      setHasError(true)
    },
    onCompleted: () => {
      pollWithMaxRetries([
        { query: TRANSACTION_PANEL_QUERY, variables: { businessId, transactionId } },
      ])
      setTimeout(() => setIsButtonTemporarilyDisabled(false), 3000)
    },
  })

  const handleSaveNoteClick = () => {
    setIsButtonTemporarilyDisabled(true)
    updateNote()
    if (isCardTransaction(type) && isOutgoing(amount)) {
      Analytics.track(
        isTransactionPosted(status)
          ? events.transactions.cardAuthSettled.addNote
          : events.transactions.cardAuthPending.addNote,
      )
    }
  }

  useEffect(() => {
    setNewNote(note)
    setIsButtonTemporarilyDisabled(false)
  }, [note])

  return (
    <TransactionPanelItem label={t("dashboard.transactionPanel.notes")}>
      <RoundedLongTextInput
        value={newNote}
        onChange={setNewNote}
        sx={{ marginTop: "2px", width: "100%" }}
        placeholder={t("dashboard.transactionPanel.placeholderNote")}
      />
      <FlexRowLayout>
        <Button
          type={ButtonTypeEnum.TERTIARY_SHORT}
          onClick={handleSaveNoteClick}
          isActivated={isButtonTemporarilyDisabled}
          disabled={isButtonTemporarilyDisabled || note === newNote}
          sx={{ paddingX: 12 }}
        >
          {isButtonTemporarilyDisabled
            ? t("dashboard.transactionPanel.saved")
            : loading
            ? t("dashboard.transactionPanel.saving")
            : t("dashboard.transactionPanel.saveNote")}
        </Button>
      </FlexRowLayout>
      <AnimatePresence animation="fadeAndExpand">
        {hasError && <NoticeBox level="error" text={t("dashboard.transactionPanel.saveError")} />}
      </AnimatePresence>
    </TransactionPanelItem>
  )
}
