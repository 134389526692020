export const authWrapper = {
  idleTimeoutDialog: {
    title: "You'll be logged out in {{remainingSeconds}} seconds",
    subtitle:
      "You’ve been inactive for {{timeoutMinutes}} minutes. We’ll log you out soon to keep your account safe and secure.",
    buttons: {
      stay: "Stay logged in",
      logout: "Log out",
    },
  },
}
