import React from "react"

import { LoadingIcon } from "@northone/ui"
import { FlexColumnLayout } from "@layouts/flex"
import { theme } from "@layouts/theme"

export const LendingLandingLoading: React.FC = () => {
  return (
    <FlexColumnLayout
      sx={{
        rowGap: "inherit",
        marginRight: "50px",
        margin: "auto",
        alignItems: "center",
        justifyContent: "center",
        borderColor: theme.colors.ui5,
        borderStyle: "solid",
        borderWidth: "1px",
        width: "100%",
        height: 211,
      }}
    >
      <LoadingIcon dimension={74} isCentered />
    </FlexColumnLayout>
  )
}
