import { TDashboardActions, DashboardActionEnum } from "./actions"
import { TransactionsListFiltersInput, TransactionTypeEnum } from "@generated/graphql"

export type TAmountFilterType = "minimum" | "maximum" | "exact" | null
export type TDateFilterType =
  | "today"
  | "yesterday"
  | "thisWeek"
  | "last30Days"
  | "exactDate"
  | "customRange"
  | null

export interface IDashboardState {
  transactionId: string
  isExporting: boolean
  searchTerm: string
  isFiltering: boolean
  amount: string
  amountFilterType: TAmountFilterType
  transactionTypes: TransactionTypeEnum[]
  cardIds: string[]
  dateFilterType: TDateFilterType
  exactDate: Date | null
  startDate: Date | null
  endDate: Date | null
  filters: TransactionsListFiltersInput
}

export const initialDashboardState: IDashboardState = {
  transactionId: "",
  isExporting: false,
  searchTerm: "",
  isFiltering: false,
  amount: "",
  amountFilterType: null,
  transactionTypes: [],
  cardIds: [],
  dateFilterType: null,
  exactDate: null,
  startDate: null,
  endDate: null,
  filters: {},
}

export const dashboardReducer = (
  state: IDashboardState = initialDashboardState,
  action: TDashboardActions,
): IDashboardState => {
  switch (action.type) {
    case DashboardActionEnum.SET_ACTIVE_TRANSACTION_ID:
      return {
        ...state,
        transactionId: action.payload,
        ...(action.payload && { isFiltering: false, isExporting: false }),
      }
    case DashboardActionEnum.SET_IS_EXPORTING:
      return {
        ...state,
        isExporting: action.payload,
        ...(action.payload && { isFiltering: false, transactionId: "" }),
      }
    case DashboardActionEnum.SET_SEARCH_TERM:
      return {
        ...state,
        searchTerm: action.payload,
        transactionId: "",
      }
    case DashboardActionEnum.SET_IS_FILTERING:
      return {
        ...state,
        isFiltering: action.payload,
        ...(action.payload && { isExporting: false, transactionId: "" }),
      }
    case DashboardActionEnum.SET_AMOUNT:
      return {
        ...state,
        amount: action.payload,
      }
    case DashboardActionEnum.SET_AMOUNT_FILTER_TYPE:
      return {
        ...state,
        amountFilterType: action.payload,
      }
    case DashboardActionEnum.SET_FILTERS:
      return {
        ...state,
        filters: action.payload,
      }
    case DashboardActionEnum.CLEAR_STATE:
      return {
        ...initialDashboardState,
      }
    case DashboardActionEnum.SET_TRANSACTION_TYPES:
      return {
        ...state,
        transactionTypes: action.payload,
      }
    case DashboardActionEnum.SET_CARD_ID_FILTER:
      return {
        ...state,
        cardIds: action.payload,
      }
    case DashboardActionEnum.SET_DATE_FILTER_TYPE:
      return {
        ...state,
        dateFilterType: action.payload,
      }
    case DashboardActionEnum.SET_START_DATE:
      return {
        ...state,
        startDate: action.payload,
      }
    case DashboardActionEnum.SET_END_DATE:
      return {
        ...state,
        endDate: action.payload,
      }
    case DashboardActionEnum.SET_EXACT_DATE:
      return {
        ...state,
        exactDate: action.payload,
      }
    default:
      return state
  }
}
