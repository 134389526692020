import { SubscriptionPlanSection } from "@features/subscriptions/types"
import React, { ReactElement } from "react"
import { SxStyleProp } from "rebass"
import { PlanPill } from "../plan-pill"
import { theme } from "@layouts/theme"
import { FlexColumnLayout } from "@layouts/flex"
import { Button, IButtonProps } from "@components/primitive/button/button"
import { Text } from "@components/primitive/text/text"
import { PlanBullet } from "@features/subscriptions/shared/components/plan-bullet-icon"

export type PlanDetailsBoxProps = PlanDetailsHeadingProps & {
  sections: SubscriptionPlanSection[]
  pillText?: string
  containerSx?: SxStyleProp
}

export const PlanDetailsBoxLayout = ({
  title,
  subtitle,
  description,
  price,
  buttonProps,
  displayButton,
  pillText,
  sections,
  containerSx,
}: PlanDetailsBoxProps) => {
  const containerStyle: SxStyleProp = {
    position: "relative",
    width: "35vw",
    border: `1px solid ${theme.colors.ui5}`,
    paddingX: "24px",
    paddingY: "36px",
    borderRadius: "4px",
    ...containerSx,
  }

  return (
    <FlexColumnLayout sx={containerStyle}>
      {pillText && (
        <PlanPill
          sx={{ position: "absolute", top: "-13px", left: "50%", transform: "translateX(-50%)" }}
          text={pillText}
        />
      )}
      <PlanDetailsHeading
        {...{ title, subtitle, description, price, buttonProps, displayButton }}
      />
      <FlexColumnLayout sx={{ gap: "24px" }}>
        {sections.map((section) => (
          <PlanSection {...section} key={section.heading} />
        ))}
      </FlexColumnLayout>
    </FlexColumnLayout>
  )
}

interface PlanDetailsHeadingProps {
  title: string
  subtitle: string
  description: string[]
  price: string[]
  buttonProps: IButtonProps
  displayButton?: boolean
}

const PlanDetailsHeading = ({
  title,
  subtitle,
  description,
  price,
  displayButton = true,
  buttonProps,
}: PlanDetailsHeadingProps) => {
  const containerStyle = {
    borderBottom: `1px solid ${theme.colors.ui5}`,
    paddingBottom: "24px",
    marginBottom: "24px",
  }
  const titleStyle = { marginBottom: "20px", textAlign: "center" }
  const subtitleStyle = { marginBottom: "8px", textAlign: "center" }
  const descriptionStyle = { marginBottom: "20px", textAlign: "center", paddingX: "50px" }
  const priceStyle = { textAlign: "center" }
  const buttonStyle = { marginTop: "24px", width: "100%" }

  return (
    <FlexColumnLayout sx={containerStyle}>
      <Text tag={"h3"} sx={titleStyle}>
        {title}
      </Text>
      <Text tag={"h5"} sx={subtitleStyle}>
        {subtitle}
      </Text>
      <Text tag={"body"} sx={descriptionStyle}>
        {description}
      </Text>
      {price.map((priceText) => (
        <Text tag={"body-large-bold"} sx={priceStyle} key={priceText}>
          {priceText}
        </Text>
      ))}
      {displayButton && <Button sx={buttonStyle} {...buttonProps} />}
    </FlexColumnLayout>
  )
}

export const PlanSection = ({
  heading,
  bullets,
  containerSx,
}: {
  heading: string | ReactElement
  bullets: SubscriptionPlanSection["bullets"]
  containerSx?: SxStyleProp
}) => (
  <FlexColumnLayout sx={containerSx}>
    {typeof heading === "string" ? (
      <Text tag={"h3"} sx={{ fontSize: "16px" }}>
        {heading}
      </Text>
    ) : (
      heading
    )}
    {bullets.map((props) => (
      <PlanBullet key={props.text} {...props} />
    ))}
  </FlexColumnLayout>
)
