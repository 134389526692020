import React from "react"
import { Box } from "rebass"
import { INavBarLinkProps, NavBarLink } from "@components/composite/nav-bar/nav-bar-link"
import { TransactionPanelItem } from "@components/composite/transaction-panel/base-transaction-panel"
import { FlexColumnLayout } from "@layouts/flex"
import { theme } from "@layouts/theme"

interface NavSidebarSectionProps {
  sectionLabel: string
  links: INavBarLinkProps[]
  eventPrefix: string
  onClick?: () => void
}

interface SideBarSectionProps {
  borderColor?: string
  children?: React.ReactNode
}

export const SideBarSection = ({
  children,
  borderColor = theme.colors.ui5,
}: SideBarSectionProps) => (
  <FlexColumnLayout sx={{ paddingX: theme.spacing.sidebar }}>
    <FlexColumnLayout
      sx={{
        borderBottom: `1px solid ${borderColor}`,
        paddingY: theme.spacing.sidebar,
      }}
    >
      {children}
    </FlexColumnLayout>
  </FlexColumnLayout>
)

export const NavSideBarSection: React.FC<NavSidebarSectionProps> = ({
  sectionLabel,
  links,
  eventPrefix,
  onClick,
}) => {
  return (
    <TransactionPanelItem label={sectionLabel}>
      <Box sx={{ marginTop: 16 }}>
        {links.map((link, idx) => (
          <Box key={idx} sx={{ marginLeft: 16, marginBottom: 15 }}>
            <NavBarLink
              {...link}
              routeMatchIsExact={link.routeMatchIsExact}
              eventPrefix={eventPrefix}
              onClick={onClick}
            />
          </Box>
        ))}
      </Box>
    </TransactionPanelItem>
  )
}
