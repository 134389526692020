import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { images } from "@assets/images/images"
import { useSendInvitationMutation } from "@generated/graphql"
import { Button, ButtonTypeEnum } from "@components/primitive/button/button"
import { useTranslation } from "react-i18next"
import { useAppSelector } from "@core/redux/utils"
import { selectActiveBusinessID } from "@core/active-business/redux/selectors"
import { teamBankingActions } from "./redux/actions"
import { appActions } from "@core/redux/app/actions"
import { Analytics } from "@core/analytics/actions"
import { events } from "@core/analytics/events"

const getResendInviteButtonText = (inviteSent: boolean, sendInvitationMutationLoading: boolean) => {
  if (inviteSent) {
    return "settings.pages.teamBanking.buttons.inviteSent"
  }
  if (sendInvitationMutationLoading) {
    return "settings.pages.teamBanking.buttons.sendingInvite"
  }
  return "settings.pages.teamBanking.buttons.resendInvite"
}

interface IResendInvite {
  userId: string
}
export const ResendInvite = ({ userId }: IResendInvite) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [isInviteSent, setIsInviteSent] = useState<boolean>(false)
  const businessId = useAppSelector(selectActiveBusinessID)
  const [sendInvitation, { loading: sendInvitationMutationLoading }] = useSendInvitationMutation({
    variables: {
      businessId,
      userId,
    },
    onCompleted: () => {
      setIsInviteSent(true)
      dispatch(
        appActions.setNotificationMessage(t("settings.pages.teamBanking.modal.inviteSent.success")),
      )
      dispatch(teamBankingActions.setSendInviteError(false))
    },
    onError: () => {
      setIsInviteSent(false)
      dispatch(teamBankingActions.setSendInviteError(true))
      Analytics.track(events.settings.teamBanking.resendInvite.error)
    },
  })

  useEffect(() => {
    setIsInviteSent(false)
  }, [userId])

  const buttonText = t(getResendInviteButtonText(isInviteSent, sendInvitationMutationLoading))
  const buttonIcon = isInviteSent ? images.icons.checkMark : images.icons.mailIcon

  const onClick = async () => {
    Analytics.track(events.settings.teamBanking.resendInvite.click)
    await sendInvitation()
  }
  return (
    <>
      <Button
        iconSrc={buttonIcon}
        type={ButtonTypeEnum.PRIMARY_BLUE}
        iconSx={{ width: 18, height: 14 }}
        onClick={onClick}
        isLoading={sendInvitationMutationLoading}
        disabled={isInviteSent}
      >
        {buttonText}
      </Button>
    </>
  )
}
