import React from "react"
import { useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import { useAppSelector } from "@core/redux/utils"
import { Analytics } from "@core/analytics/actions"
import { events } from "@core/analytics/events"
import { FormBody } from "@components/composite/form-body/form-body"
import { AmountInput } from "@components/extended/fields/amount-input"
import { ButtonTypeEnum } from "@components/primitive/button/button"
import { accountsActions } from "@features/accounts/redux/actions"
import { selectAccountsSubAccountTransfer } from "@features/accounts/redux/selectors"
import { validate } from "@utils/validate"

export const SubAccountTransferAmount: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { amount, sourceAccount, destinationAccount } = useAppSelector(
    selectAccountsSubAccountTransfer,
  )
  const amountAsNumber = Number(amount)
  const exceedsAvailableBalance = amountAsNumber > sourceAccount.balance

  return (
    <FormBody
      title={t("accounts.transfer.amount.title", { name: destinationAccount.name })}
      buttons={[
        {
          type: ButtonTypeEnum.TERTIARY,
          children: t("buttons.goBack"),
          onClick: () => dispatch(accountsActions.subAccountTransferNavigateBack()),
        },
        {
          canContinueWithKey: true,
          children: t("buttons.continue"),
          disabled: !validate.isValidAmount(amountAsNumber) || exceedsAvailableBalance,
          onClick: () => {
            dispatch(accountsActions.subAccountTransferNavigateNext("SUB_ACCOUNT_TRANSFER_CONFIRM"))
            Analytics.track(events.accounts.transfer.amount)
          },
        },
      ]}
    >
      <AmountInput
        value={amount}
        onChange={(amount) => {
          dispatch(accountsActions.setSubAccountTransferAmount(amount))
        }}
        errorMessage={exceedsAvailableBalance ? t("moveMoney.amount.insufficientFundsMessage") : ""}
      />
    </FormBody>
  )
}
