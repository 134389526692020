import React from "react"
import { useTranslation } from "react-i18next"

import { theme } from "@layouts/theme"
import { InvoiceStatus } from "@generated/graphql"
import { Text } from "@components/primitive/text/text"
import { FlexColumnLayout } from "@layouts/flex"
import { Image } from "rebass"
import { images } from "@assets/images/images"
import { FlexRowLayout } from "@northone/ui"

interface IInvoiceTableEmptyStateProps {
  statusFilter: InvoiceStatus
  forwardingEmailAddress: string
}

export const InvoiceTableEmptyState = ({
  statusFilter,
  forwardingEmailAddress,
}: IInvoiceTableEmptyStateProps) => {
  const { t } = useTranslation()

  if (!statusFilter) return null

  const getTableTitle = (status: InvoiceStatus) => {
    const titlePerStatus = {
      [InvoiceStatus.UNPAID]: t("invoicePayments.table.emptyState.unpaid.title"),
      [InvoiceStatus.PAID]: t("invoicePayments.table.emptyState.paid.title"),
      [InvoiceStatus.ARCHIVED]: t("invoicePayments.table.emptyState.archived.title"),
      default: null,
    }

    return titlePerStatus[status] || titlePerStatus.default
  }

  const getUnpaidSubtitle = () => {
    return (
      <>
        <FlexRowLayout>
          <Text tag="body" color={theme.colors.ui2}>
            {t("invoicePayments.table.emptyState.unpaid.click")}
          </Text>
          <Text tag="body-bold" color={theme.colors.ui2} style={{ padding: "0 4px" }}>
            {t("invoicePayments.table.emptyState.unpaid.addInvoice")}
          </Text>
          <Text tag="body" color={theme.colors.ui2}>
            {t("invoicePayments.table.emptyState.unpaid.toUploadOrEmailThemTo")}
          </Text>
        </FlexRowLayout>
        <FlexRowLayout>
          <Text tag="body-bold" color={theme.colors.ui2}>
            {forwardingEmailAddress}
          </Text>
        </FlexRowLayout>
      </>
    )
  }

  return (
    <FlexColumnLayout
      sx={{
        alignItems: "center",
        backgroundColor: theme.colors.ui6,
        textAlign: "center",
        height: "30vh",
      }}
    >
      <FlexColumnLayout sx={{ alignItems: "center", justifyItems: "center", margin: "auto" }}>
        <Image src={images.icons.invoiceEmptyState} height={18} width={18} />
        <Text
          tag={"body-large-bold"}
          color={statusFilter === InvoiceStatus.UNPAID ? theme.colors.ui1 : theme.colors.ui2}
          style={{ paddingTop: 19, paddingBottom: 8 }}
        >
          {getTableTitle(statusFilter)}
        </Text>
        {statusFilter === InvoiceStatus.UNPAID && getUnpaidSubtitle()}
      </FlexColumnLayout>
    </FlexColumnLayout>
  )
}
