import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { CardProductType, useCardsQuery } from "@generated/graphql"
import { cardsActions } from "./redux/actions"
import { QueryResults } from "@components/composite/query-results/query-results"
import { useAppSelector } from "@core/redux/utils"
import { Analytics } from "@core/analytics/actions"
import { events } from "@core/analytics/events"

import { Tabs, TTabs } from "@components/composite/tabs/tabs"
import { PilotPill } from "@components/extended/pill/pilot-pill"
import { PilotFeedbackBanner } from "@components/extended/banner/pilot-feedback-banner"
import { Text } from "@components/primitive/text/text"
import { BaseLayout } from "@layouts/base-layout"
import { FlexColumnLayout } from "@layouts/flex"

import { AddVirtualCard, AddVirtualCardModal } from "./add-virtual-card"
import { CardSummary } from "./card-summary/card-summary"
import { CardList } from "./cards-list/cards-list"
import { theme } from "@layouts/theme"

export const CardsLanding: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const businessId = useAppSelector((state) => state.activeBusiness.businessId)
  const currentUserId = useAppSelector((state) => state.user.userId)

  const {
    data,
    loading: cardsLoading,
    error: cardsError,
    refetch: cardsRefetch,
  } = useCardsQuery({
    variables: { businessId },
    onCompleted: (data) => {
      const accountId = data?.business?.bankAccounts[0]?.id
      if (!accountId) return
      dispatch(cardsActions.setCardAccountId(accountId))
    },
  })

  const cards = data?.business?.bankAccounts[0].cards || []
  const hasVirtualCard = cards
    ?.filter((card) => card.user?.id === currentUserId)
    .some(({ productType }) => productType === CardProductType.VIRTUAL)
  const displayAddVirtualCardButton = cards && !hasVirtualCard

  const myCards = cards?.filter((card) => card.user?.id === currentUserId)
  const otherCards = cards?.filter((card) => card.user?.id !== currentUserId)
  const hasOtherCards = otherCards && otherCards.length >= 1

  const myCardsContent = (
    <FlexColumnLayout>
      {myCards?.map((card) => (
        <CardSummary key={card.id} cardId={card.id} />
      ))}
      {displayAddVirtualCardButton && <AddVirtualCard />}
    </FlexColumnLayout>
  )

  const tabs: TTabs[] = [
    {
      title: t("cards.landing.tabs.myCards"),
      onTabSelected: () => {
        Analytics.track(events.cards.landing.viewMyCards)
      },
      content: myCardsContent,
    },
    {
      title: t("cards.landing.tabs.otherCards"),
      additionalContent: <PilotPill sx={{ marginX: "10px" }} />,
      onTabSelected: () => {
        Analytics.track(events.cards.landing.viewOtherCards)
      },
      content: (
        <FlexColumnLayout>
          <PilotFeedbackBanner />
          <CardList cards={otherCards} />
        </FlexColumnLayout>
      ),
    },
  ]

  return (
    <BaseLayout withLogoFooter pageTitle={t("pageTitles.cards")} childrenGridColumn="3/11">
      <AddVirtualCardModal />
      <FlexColumnLayout>
        <Text tag="h2" sx={{ marginBottom: 26 }}>
          {t("cards.landing.title")}
        </Text>
      </FlexColumnLayout>
      <FlexColumnLayout>
        <QueryResults
          loading={cardsLoading}
          error={cardsError}
          errorText={{
            title: t("errors.genericScreen.title"),
            subtitle: t("cards.landing.loadingError"),
            retryTitle: t("errors.genericErrorLoading.button"),
          }}
          errorStyles={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            border: `1px solid ${theme.colors.ui5}`,
            borderRadius: "4px",
          }}
          retry={() => cardsRefetch()}
          size="large"
          listShimmerCount={1}
        >
          {hasOtherCards ? <Tabs tabs={tabs} /> : myCardsContent}
        </QueryResults>
      </FlexColumnLayout>
    </BaseLayout>
  )
}
