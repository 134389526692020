import React from "react"
import { Box, SxStyleProp } from "rebass"
import { FlexColumnLayout } from "@layouts/flex"
import { Text } from "@components/primitive/text/text"
import { Button } from "@components/primitive/button/button"
import { ListLabels } from "@components/primitive/list-label/list-labels"
import { theme } from "@layouts/theme"

export interface IActionBoxProps {
  title: string
  text?: string
  buttonText?: string
  onClick: () => void
  buttonIcon?: string
  buttonSx?: SxStyleProp
  sx?: SxStyleProp
}
export const ActionBox: React.FC<IActionBoxProps> = ({
  title,
  text,
  buttonText,
  buttonIcon,
  buttonSx,
  sx,
  onClick,
}) => (
  <Box
    sx={{
      borderRadius: 8,
      height: 170,
      width: "50%",
      paddingLeft: 24,
      paddingRight: 16,
      boxShadow: theme.boxShadow,
      ...sx,
    }}
  >
    <FlexColumnLayout>
      <ListLabels
        labels={[{ title, widthRatio: 1, alignment: "start" }]}
        sx={{ height: 53 }}
        labelColor={theme.colors.ui2}
      />
      <Text tag="body-small">{text}</Text>
      <Button iconSrc={buttonIcon} onClick={onClick} sx={{ marginTop: 16, ...buttonSx }}>
        {buttonText}
      </Button>
    </FlexColumnLayout>
  </Box>
)
