import { createPrismicLink } from "apollo-link-prismic"
import { ApolloClient, InMemoryCache } from "@apollo/client"
import { GET_ALL_PERKS, GET_PERK_BY_ID } from "./queries"
import possibleTypes from "./possibleTypes.json"
import { config } from "@utils/environment"

export const perksClient = new ApolloClient({
  link: createPrismicLink({
    uri: config.perksPrismicUri,
    accessToken: config.perksPrismicToken,
  }),
  cache: new InMemoryCache({
    possibleTypes,
  }),
})

export const getPerks = (searchQuery = "") => {
  return perksClient.query({
    query: GET_ALL_PERKS,
    variables: {
      searchQuery,
    },
  })
}

export const getPerkById = (id: string) => {
  return perksClient.query({
    query: GET_PERK_BY_ID,
    variables: { id },
  })
}
