import React from "react"
import { Modal, Text, theme } from "@northone/ui"
import { useTranslation } from "react-i18next"
import { Button, ButtonTypeEnum } from "@components/primitive/button/button"
import { PlaidItem } from "@generated/graphql"
import { LinkedAccountItemWithoutActions } from "../linked-account-items"
import { ButtonContainer } from "@components/primitive/button-container"

type DeleteConfirmationModalProps = {
  isOpen: boolean
  onContinue: () => void
  onCancel: () => void
  loading: boolean
  plaidItem?: PlaidItem
  plaidItemUsedInTopUps: boolean
}

export function DeleteConfirmationModal({
  isOpen,
  onContinue,
  onCancel,
  loading,
  plaidItem,
  plaidItemUsedInTopUps,
}: DeleteConfirmationModalProps) {
  const { t } = useTranslation()

  const descriptionKey = plaidItemUsedInTopUps
    ? "settings.pages.linkedAccount.deleteModal.description.linkedToTopUps"
    : "settings.pages.linkedAccount.deleteModal.description.default"

  return (
    <Modal isOpen={isOpen} onOutsideClick={onCancel} sx={{ gap: 4, maxWidth: 500 }}>
      <Text tag="h3Serif">{t("settings.pages.linkedAccount.deleteModal.title")}</Text>
      <Text tag="body" textColor={theme.colors.ui2}>
        {t(descriptionKey)}
      </Text>
      {plaidItem && <LinkedAccountItemWithoutActions plaidItem={plaidItem} />}
      <ButtonContainer>
        <Button type={ButtonTypeEnum.SECONDARY} onClick={onCancel}>
          {t("buttons.back")}
        </Button>
        <Button type={ButtonTypeEnum.PRIMARY_BLUE} onClick={onContinue} isLoading={loading}>
          {t("settings.pages.linkedAccount.deleteModal.confirm")}
        </Button>
      </ButtonContainer>
    </Modal>
  )
}
