import { transactions } from "./shared/transactions"
import { buttons } from "./shared/buttons"
import { inputs } from "./shared/inputs"
import { errors } from "./shared/errors"

import { earlyAccess } from "./features/early-access"
import { navBar } from "./features/nav-bar"
import { schedules } from "./shared/schedules"
import { dashboard } from "./features/dashboard"
import { moveMoney } from "./features/move-money"
import { accounts } from "./features/accounts"
import { accountStatements } from "./features/account-statements"
import { topUp } from "./features/top-up"
import { downloadModal } from "./features/download-modal"
import { postOnboarding } from "./features/post-onboarding"
import { sendInvoice } from "./features/send-invoice"
import { settings } from "./features/settings"
import { authWrapper } from "./features/auth-wrapper"
import { cards } from "./features/cards"
import { support } from "./features/support"
import { responsive } from "./features/responsive"
import { integrations } from "./features/integrations"
import { pageTitles } from "./features/page-titles"
import { shareAndEarn } from "./features/share-and-earn"
import { perks } from "./features/perks"
import { reCAPTCHA } from "./shared/recaptcha"
import { invoicePayments } from "./features/invoice-payments"
import { plaidFunding } from "./features/plaid-funding"
import { revenueHub } from "./features/revenue-hub"
import { autoTopUps } from "./features/auto-top-ups"
import { subscriptions } from "./features/subscriptions"
import { lending } from "./features/lending"

/**
 * Translations for English
 */
export const en = {
  earlyAccess,
  navBar,
  transactions,
  buttons,
  dashboard,
  schedules,
  moveMoney,
  inputs,
  accounts,
  accountStatements,
  postOnboarding,
  errors,
  downloadModal,
  topUp,
  sendInvoice,
  settings,
  shareAndEarn,
  perks,
  authWrapper,
  cards,
  support,
  logout: {
    button: "Log out",
    modalTitle: "Are you sure you want to log out of your NorthOne Account?",
  },
  copiedToClipboard: "Copied to clipboard",
  exitToDashboard: "Exit to Dashboard",
  responsive,
  integrations,
  pageTitles,
  reCAPTCHA,
  invoicePayments,
  plaidFunding,
  revenueHub,
  autoTopUps,
  subscriptions,
  lending,
}
