import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { Image, Box, Link, SxStyleProp } from "rebass"

import { useAppSelector } from "@core/redux/utils"
import { addFundsActions } from "@features/add-funds/redux/actions"
import { AnimatePresence } from "@components/animations/animate-presence/animation-presence"
import { FormBody } from "@components/composite/form-body/form-body"
import { ButtonTypeEnum, Button } from "@components/primitive/button/button"
import { MaskTextInput } from "@components/extended/fields/mask-input"
import {
  MOBILE_URL,
  GOOGLE_PLAY_LINK,
  APP_STORE_LINK,
} from "@components/composite/download-buttons/constants"
import { Text } from "@components/primitive/text/text"
import { images } from "@assets/images/images"
import { theme } from "@layouts/theme"
import { FlexRowLayout } from "@layouts/flex"
import { sanitizePhoneNumber } from "@utils/format"
import { useWindowDimensions } from "@utils/hooks"

import {
  selectUserPhoneNumber,
  selectUserPhoneNumberIsValid,
  selectSMSTextLinkSendCapability,
  selectSMSTextSendStatus,
} from "../redux/selectors"

const TOAST_TEXT_I18N = "topUp.addFunds.check.toastText"
const ADD_FUNDS_VIA_CHECK_TITLE_I18N = "topUp.addFunds.check.title"
const ADD_FUNDS_VIA_CHECK_SUBTITLE_I18N = "topUp.addFunds.check.fundedSubtitle"
const ADD_FUNDS_VIA_CHECK_BACK_BTN_I18N = "topUp.addFunds.back"
const PHONE_INPUT_PLACEHOLDER_I18N = "downloadModal.placeholder"
const LINK_SENT_BTN_I18N = "downloadModal.sentButton"
const SEND_LINK_BTN_I18N = "downloadModal.sendLink"
const SUBTEXT_I18N = "topUp.addFunds.check.subText"
const SMS_SERVICE_INIT_FAILURE_I18N = "topUp.addFunds.check.linkingServiceInitFailure"
const SMS_MESSAGE_SEND_FAILURE_I18N = "topUp.addFunds.check.smsMessageSendFailure"

const TOAST_MESSAGE_OFFSET = -50
const RIGHT_SIDE_DOWNLOAD_BOX_OFFSET = 0

const SUBTEXT_LINKS_IN_ORDER = [APP_STORE_LINK, GOOGLE_PLAY_LINK]
const downloadButtonLinkStyle = { height: "7%", width: "50%" }
const downloadButtonImageStyle = { width: "100%", height: "37px" }

interface IDownloadButtonWithLink {
  customLink: string
  linkStyle: SxStyleProp
  imgSrc: string
}

const SideColumnDownloadButton: React.FC<IDownloadButtonWithLink> = ({
  customLink,
  linkStyle,
  imgSrc,
}) => {
  return (
    <Link href={customLink} target="_blank" sx={{ ...linkStyle }}>
      <Image src={imgSrc} sx={{ ...downloadButtonImageStyle }} />
    </Link>
  )
}

/**
 * This component creates a screen in the add-funds feature area that allows
 * the user to enter a phone number to send a deep link for downloading the
 * mobile app. It utilizes the LinkingService for sending the SMS message if
 * possible but displays just a redirect message if there are browser issues
 * initializing the third-party package used for the SMS send function.
 */
export const AddFundsCheck: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [isSendingSMS, setIsSendingSMS] = useState(false)
  const [isSent, setIsSent] = useState(false)
  const [toastMessageVisible, setToastMessageVisible] = useState(false)
  const phoneNumber = useAppSelector(selectUserPhoneNumber)
  const phoneNumberIsValid = useAppSelector(selectUserPhoneNumberIsValid)
  const smsTextLinkSendCapability = useAppSelector(selectSMSTextLinkSendCapability)
  const smsTextMessageSendStatus = useAppSelector(selectSMSTextSendStatus)
  const { width: windowWidth } = useWindowDimensions()
  const onMobile = windowWidth < theme.mobileBreakpoint

  useEffect(() => {
    dispatch(addFundsActions.initializeSMSMessageService())
  }, [])

  const sendSMS = () => {
    setIsSendingSMS(true)
    dispatch(addFundsActions.sendSMSMessage(sanitizePhoneNumber(phoneNumber)))
  }

  const sendLinkButtonHandler = () => {
    sendSMS()
    setToastMessageVisible(true)
    // Design called for a 5 second toast message and thus the setTimeout below.
    setTimeout(() => {
      setIsSendingSMS(false)
      setToastMessageVisible(false)
    }, 5000)
  }

  const onTextInputChangeHandler = (value: string) => {
    dispatch(addFundsActions.setUserPhoneNumber(sanitizePhoneNumber(value)))
    if (smsTextMessageSendStatus === false) {
      dispatch(addFundsActions.setSMSMessageSendStatus(null))
    }
    setIsSent(false)
  }

  const formBodyButtons = [
    {
      children: t(ADD_FUNDS_VIA_CHECK_BACK_BTN_I18N),
      type: ButtonTypeEnum.TERTIARY,
      onClick: () => dispatch(addFundsActions.navigateBack()),
    },
  ]

  return (
    <FlexRowLayout>
      {toastMessageVisible ? (
        <AnimatePresence withoutWrapper animation="fadeIn">
          <Box
            sx={{
              zIndex: theme.zIndex.flowModal + 1,
              display: "flex",
              position: "absolute",
              backgroundColor: theme.colors.ui1,
              width: "337px",
              height: "43px",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: "15%",
              marginTop: TOAST_MESSAGE_OFFSET,
              borderRadius: "5px",
            }}
          >
            <Text tag="h5" textColor={theme.colors.ui7} sx={{ cursor: "default" }}>
              {t(TOAST_TEXT_I18N)}
            </Text>
          </Box>
        </AnimatePresence>
      ) : undefined}

      <FormBody
        title={t(ADD_FUNDS_VIA_CHECK_TITLE_I18N)}
        buttonDirection={"row"}
        buttons={formBodyButtons}
        containerSx={{ width: "70%" }}
      >
        {smsTextLinkSendCapability === false ? (
          <FlexRowLayout>
            <Box
              sx={{
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <Text
                tag={"body"}
                sx={{ width: "100%" }}
                textWithEmbeddedLinksProps={{
                  text: t(SMS_SERVICE_INIT_FAILURE_I18N),
                  linksInOrder: SUBTEXT_LINKS_IN_ORDER,
                  linkStyle: { color: theme.colors.ui2 },
                }}
              />
            </Box>
          </FlexRowLayout>
        ) : (
          <>
            <Text hasPartialBolding textColor={theme.colors.ui1} tag="body">
              {t(ADD_FUNDS_VIA_CHECK_SUBTITLE_I18N)}
            </Text>
            <FlexRowLayout sx={{ marginTop: "8px", marginBottom: "20px" }}>
              <MaskTextInput
                placeholder={t(PHONE_INPUT_PLACEHOLDER_I18N)}
                initialValue={""}
                value={phoneNumber}
                onChange={(value) => onTextInputChangeHandler(value)}
                maskType="PHONE_NUMBER"
              />
              <Button
                isLoading={isSendingSMS}
                type={isSent ? ButtonTypeEnum.TERTIARY : ButtonTypeEnum.PRIMARY_BLUE}
                sx={{ marginLeft: "15px" }}
                onClick={() => sendLinkButtonHandler()}
                disabled={isSent || !phoneNumberIsValid}
              >
                {isSent ? t(LINK_SENT_BTN_I18N) : t(SEND_LINK_BTN_I18N)}
              </Button>
            </FlexRowLayout>

            {smsTextMessageSendStatus === false ? (
              <FlexRowLayout>
                <Text
                  tag={"body-small-bold"}
                  sx={{ width: "100%", color: theme.colors.error100, marginBottom: "20px" }}
                  textWithEmbeddedLinksProps={{
                    text: t(SMS_MESSAGE_SEND_FAILURE_I18N),
                    linksInOrder: SUBTEXT_LINKS_IN_ORDER,
                    linkStyle: { color: theme.colors.error100 },
                  }}
                />
              </FlexRowLayout>
            ) : undefined}

            <FlexRowLayout>
              <Text
                tag={"body-small"}
                sx={{ width: "100%", color: theme.colors.ui2 }}
                textWithEmbeddedLinksProps={{
                  text: t(SUBTEXT_I18N),
                  linksInOrder: SUBTEXT_LINKS_IN_ORDER,
                  linkStyle: { color: theme.colors.ui2 },
                }}
              />
            </FlexRowLayout>
          </>
        )}
      </FormBody>
      <Box
        style={{
          marginLeft: "5%",
          width: "32%",
          justifyContent: "center",
          alignItems: "center",
          marginTop: RIGHT_SIDE_DOWNLOAD_BOX_OFFSET,
        }}
      >
        <Image
          width={"100%"}
          src={images.checkDepositAddFunds}
          sx={{ borderRadius: 5, boxShadow: theme.boxShadow }}
        />
        <FlexRowLayout sx={{ width: "100%", marginTop: "20px" }}>
          <SideColumnDownloadButton
            customLink={onMobile ? MOBILE_URL : APP_STORE_LINK}
            linkStyle={{ ...downloadButtonLinkStyle, marginRight: "2.5%" }}
            imgSrc={images.appStoreSmall}
          />
          <SideColumnDownloadButton
            customLink={onMobile ? MOBILE_URL : GOOGLE_PLAY_LINK}
            linkStyle={{ ...downloadButtonLinkStyle, marginLeft: "2.5%" }}
            imgSrc={images.googlePlaySmallWhite}
          />
        </FlexRowLayout>
      </Box>
    </FlexRowLayout>
  )
}
