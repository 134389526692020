import { BANCORP_NAME } from "@utils/constants"

export const settings = {
  pages: {
    autoTopUp: {
      title: "Auto top-up",
    },
    contactInformation: {
      title: "Contact information",
      emailAddressLabel: "Email Address",
      phoneNumberLabel: "Phone Number",
      mailingAddressLabel: "Mailing Address",
      mailingAddressDetail: "This is where new cards will be sent.",
      contactSupportDetail: "To update your contact information, contact Support.",
      contactSupportButton: "Contact support",
    },
    changePassword: {
      title: "Change password",
      currentPassword: "Current password",
      newPassword: {
        label: "New password",
        placeholder: "Enter a new password",
      },
      reenterPassword: {
        label: "Re-enter new password",
        placeholder: "Re-enter new password",
      },
      button: "Change password",
      validators: {
        length: "8 characters",
        uppercase: "1 uppercase",
        lowercase: "1 lowercase",
        specialCharacter: "1 special character",
        match: "Passwords match",
      },
      incorrectPassword: "Incorrect password",
      confirmModal: {
        title: "Are you sure you would like to change your password?",
        subtitle: "Click 'Confirm' to log out of your NorthOne Account and change your password.",
        button: "Confirm",
      },
    },
    linkedAccount: {
      title: "External accounts",
      subtitleFR: "We're improving the way we handle linked accounts.",
      create: {
        button: "Connect an account",
      },
      empty: {
        title: "You have no accounts connected.",
        subtitle:
          "Securely and easily connect a financial institution to add funds to your account today!",
      },
      list: {
        institution: "Institution",
        account: "Account",
        connect: "Connect an account",
        topUpsActive: "Auto Top Up Active",
      },
      maximumLinkedAccountsMessage:
        "To connect another account, delete any existing ones. You are able to connect to a maximum of {{linkedAccountMaximum}} accounts.",
      accountType: {
        title: "Did you connect a personal or business account?",
        subtitle:
          "Let us know what the account type is so that we can ensure your bank transfer is successful.",
        personal: {
          label: "Personal",
          description: "Account is under your name",
        },
        business: {
          label: "Business",
          description: "Account is under your business's name",
        },
      },
      successModal: {
        title: "Your account has been connected!",
        topUpsButton: "Manage top-ups",
      },
      errors: {
        plaidCreate:
          "There was an issue connecting your account. Try again, or contact Customer Care.",
        identity:
          "The account you are trying to connect doesn’t appear to be under your name. Try a different account, or contact Customer Care.",
        delete:
          "There was an issue disconnecting this account. Try again, or contact Customer Care.",
      },
      deleteModal: {
        title: "Are you sure you want to disconnect this account?",
        description: {
          linkedToTopUps:
            "You will have to reconfigure auto-top ups and will no longer be able to add funds from this account.",
          default: "You will no longer be able to add funds from this account.",
        },
        confirm: "Yes, Disconnect",
      },
    },
    updateLogo: {
      title: "Update logo",
      subtitle:
        "Your logo displays on your dashboard and on the invoices you create in the NorthOne mobile app.",
      update: "Update logo",
      updating: "Updating logo",
      remove: "Remove logo",
      twitter: {
        title: "Use your Twitter profile picture",
        subtitle:
          "Enter your business’s Twitter handle to update your logo to your profile picture.",
        label: "Twitter handle",
        error: "Unable to find that Twitter handle.",
      },
    },
    plans: {
      managePlan: "Manage Plan",
      upgradePlan: "Upgrade Plan",
    },
    teamBanking: {
      title: "Your team",
      labels: { member: "Team Members", recommended: "Recommended" },
      buttons: {
        invite: "Invite",
        addMember: "Add team member",
        sendInvite: "Send invite",
        resendInvite: "Resend invite",
        sendingInvite: "Sending invite",
        inviteSent: "Invite sent",
      },
      listItem: {
        admin: "Admin",
        primaryAdmin: "Primary admin",
        active: "Active",
        inviteSent: "Invite Sent",
      },
      errorSubtitle: "We had an issue loading your team. Reload the page to try again.",
      accessDetails: [
        {
          featureLabel: "Banking",
          accessLabel: "Full access",
          details: ["View transactions", "Download statements", "Make payments & deposits"],
        },
        {
          featureLabel: "Cards",
          accessLabel: "Full access",
          details: ["Get a dedicated debit card", "Manage team cards"],
        },
        {
          featureLabel: "Settings",
          accessLabel: "Full access",
          details: ["Add admins", "Contact Customer Care", "Manage account settings"],
          primaryAdminDetails: [
            "Add and remove admins",
            "Contact Customer Care",
            "Manage account settings",
            "Close NorthOne account",
            "Sign bank account agreements",
          ],
        },
      ],
      banner: {
        homePage: {
          title: "Add your team",
          subtitle:
            "Give your business partners access to your NorthOne Account with their own secure logins and debit cards.",
        },
        teamsPage: {
          title: "Managing your team's access",
          subtitle:
            "Add your team to your NorthOne Account and share your feedback along the way. We want to hear from you!",
        },
        buttons: { primary: "Get started", secondary: "Dismiss", feedback: "Share feedback" },
      },
      faq: "Have questions? Check out __Frequently Asked Questions__ or reach out to __Customer Care__.",
      modal: {
        title: "Add {{fullName}} to your team",
        emailLabel: "Email Address",
        permissions: "Permissions",
        termsBeginning: "By proceeding, you confirm you have read and agree to NorthOne’s ",
        termsLink: "Terms of Service",
        and: " and ",
        privacyLink: "Privacy and Cookie Policy",
        disclaimer: `NorthOne is a financial technology company, not a bank. Banking services provided by ${BANCORP_NAME}.`,
        inviteSent: {
          success: "Your invite was sent!",
          error: "There was an issue adding your team member. Try again or contact Customer Care.",
        },
      },
    },
    manageTeam: {
      title: "Manage team",
      labels: { member: "Team Member", cardNumber: "Card Number" },
      invitePendingCopy: "No card – invite pending",
      inviteNotSentCopy: "No card – invite not sent",
      sendInvite: "Send invite",
      resendInvite: "Resend invite",
      inviteSent: "Invite sent",
      sendModalTitle: "Invite {{name}}?",
      resendModalTitle: "Resend an invitation to {{name}}?",
      modalSubtitle: "We'll email {{email}} with their unique invite link.",
    },
    legal: {
      title: "Legal",
      back: "Back to Legal",
      issuer: "Issuer",
      account_agreement: "NorthOne Small Business Deposit Account Agreement",
      electronic_comms_agreement: "Agreement to Receive Electronic Communications",
      wire_transfer_agreement: "Wire Transfer Agreement",
    },
    businessOwners: {
      title: "Business owners",
      labels: { member: "Team Member", cardNumber: "Card Number" },
      invitePendingCopy: "No card – invite pending",
      inviteNotSentCopy: "No card – invite not sent",
      sendInvite: "Send invite",
      resendInvite: "Resend invite",
      inviteSent: "Invite sent",
      sendModalTitle: "Invite {{name}}?",
      resendModalTitle: "Resend an invitation to {{name}}?",
      modalSubtitle: "We'll email {{email}} with their unique invite link.",
    },
    accountStatements: {
      title: "Account statements",
      subtitle: "Account statements become available on the {{dayOfMonth}} of the month.",
      labels: {
        date: "Date",
        download: "Download PDF",
      },
    },
    monthlyBilling: {
      title: "Monthly billing",
      subtitle: "Your next payment will be debited on {{nextPaymentDate}}.",
      processed: "Processed",
      freeBill: "*Free* 🎉",
      labels: {
        date: "Date",
        status: "Status",
        amount: "Amount",
      },
      empty: "Your monthly bills will\nappear here",
    },
    recipients: {
      title: "Your recipients",
      ach: "ACH transfer recipients",
      wire: "Wire transfer recipients",
      billPayMailedCheck: "Bill Pay - mailed check payees",
      billPayAch: "Bill Pay - ACH transfer payees",
      back: "Back to Recipients",
      edit: {
        payeeTitle: "Edit payee details",
        title: "Edit recipient details",
        subtitle: "Please edit your recipient's bank details and confirm that they are correct.",
        confirmRecipient: "Confirm recipient",
        updatePayee: "Update payee",
      },
      delete: {
        title: "Delete recipient?",
        button: "Yes, delete",
      },
    },
  },
  sidebarLabels: {
    plans: "Your NorthOne Plan",
    teamSettings: "Team Settings",
    personalSettings: "Personal Settings",
    businessSettings: "Business Settings",
    accountSettings: "Account Settings",
  },
  backToDashboard: "Back to Dashboard",
  featureRemoval: {
    ctaButton: "Chat with support",
    subtitle:
      "We would love to hear from you to learn what improvements you’d like to see, or answer any questions you may have. Our Customer Care team is here to help.",
    subtitle2: "This feature will return very soon, new and improved!",
  },
}
