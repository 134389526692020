import { createAction, TActionsUnion } from "@core/redux/utils"
import { TransactionsListFiltersInput, TransactionTypeEnum } from "@generated/graphql"
import { TAmountFilterType, TDateFilterType } from "./reducer"

export enum DashboardActionEnum {
  SET_ACTIVE_TRANSACTION_ID = "DASHBOARD_SET_TRANSACTION_ID",
  SET_IS_EXPORTING = "DASHBOARD_SET_IS_EXPORTING",
  SET_SEARCH_TERM = "DASHBOARD_SET_SEARCH_TERM",
  SET_IS_FILTERING = "DASHBOARD_SET_IS_FILTERING",
  SET_AMOUNT = "DASHBOARD_SET_AMOUNT",
  SET_AMOUNT_FILTER_TYPE = "DASHBOARD_SET_AMOUNT_FILTER_TYPE",
  SET_FILTERS = "DASHBOARD_SET_FILTERS",
  CLEAR_STATE = "DASHBOARD_CLEAR_STATE",
  SET_TRANSACTION_TYPES = "DASHBOARD_SET_TRANSACTION_TYPES",
  SET_CARD_ID_FILTER = "DASHBOARD_SET_CARD_ID_FILTER",
  SET_DATE_FILTER_TYPE = "DASHBOARD_SET_DATE_FILTER_TYPE",
  SET_EXACT_DATE = "DASHBOARD_SET_EXACT_DATE",
  SET_START_DATE = "DASHBOARD_SET_START_DATE",
  SET_END_DATE = "DASHBOARD_SET_END_DATE",
}

export const dashboardActions = {
  setActiveTransactionId: (id: string) =>
    createAction(DashboardActionEnum.SET_ACTIVE_TRANSACTION_ID, id),
  setIsExporting: (isExporting: boolean) =>
    createAction(DashboardActionEnum.SET_IS_EXPORTING, isExporting),
  setSearchTerm: (searchTerm: string) =>
    createAction(DashboardActionEnum.SET_SEARCH_TERM, searchTerm),
  setIsFiltering: (isFiltering: boolean) =>
    createAction(DashboardActionEnum.SET_IS_FILTERING, isFiltering),
  setAmount: (amount: string) => createAction(DashboardActionEnum.SET_AMOUNT, amount),
  setAmountFilterType: (amountFilterType: TAmountFilterType) =>
    createAction(DashboardActionEnum.SET_AMOUNT_FILTER_TYPE, amountFilterType),
  setFilters: (filters: TransactionsListFiltersInput) =>
    createAction(DashboardActionEnum.SET_FILTERS, filters),
  clearState: () => createAction(DashboardActionEnum.CLEAR_STATE),
  setTransactionTypes: (transactionTypes: TransactionTypeEnum[]) =>
    createAction(DashboardActionEnum.SET_TRANSACTION_TYPES, transactionTypes),
  setCardIdFilter: (cardIds: string[]) =>
    createAction(DashboardActionEnum.SET_CARD_ID_FILTER, cardIds),
  setDateFilterType: (filterType: TDateFilterType) =>
    createAction(DashboardActionEnum.SET_DATE_FILTER_TYPE, filterType),
  setExactDate: (date: Date | null) => createAction(DashboardActionEnum.SET_EXACT_DATE, date),
  setStartDate: (date: Date | null) => createAction(DashboardActionEnum.SET_START_DATE, date),
  setEndDate: (date: Date | null) => createAction(DashboardActionEnum.SET_END_DATE, date),
}

export type TDashboardActions = TActionsUnion<typeof dashboardActions>
