import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { moveMoneyActions } from "../redux/actions"
import { UpsellPlanDetails } from "@features/subscriptions/upsell/upsell-details"
import { getUpsellTransactionTypeFromTransactionEnum } from "@components/composite/transaction-panel/transaction-panel-subscription-upsell"
import { useTransactionSubscriptionUpsell } from "@features/subscriptions/shared/hooks/use-transaction-subscription-upsell"
import { selectPaymentMethod } from "../redux/selectors"
import { formatPlanNameString } from "@features/subscriptions/utils"

export const MoveMoneyUpsellPlanDetails = () => {
  const dispatch = useDispatch()
  const paymentMethod = useSelector(selectPaymentMethod)
  const transactionType = getUpsellTransactionTypeFromTransactionEnum(paymentMethod)
  const { upsellPlan } = useTransactionSubscriptionUpsell({ transactionType })
  const upsellPlanName = formatPlanNameString(upsellPlan?.planName)

  return (
    <UpsellPlanDetails
      upsellPlanName={upsellPlanName}
      navigateBack={() => dispatch(moveMoneyActions.navigateBack())}
      navigateNext={() => dispatch(moveMoneyActions.navigateNext("SUBSCRIPTION_UPSELL_CONFIRM"))}
    />
  )
}
