import React from "react"
import { Box, Image, SxStyleProp } from "rebass"
import { FlexColumnLayout, FlexRowLayout } from "@layouts/flex"
import { CounterpartyAvatar } from "@components/extended/avatar/counterparty-avatar"
import { Panel } from "@components/primitive/panel/panel"
import { Text } from "@components/primitive/text/text"
import { theme } from "@layouts/theme"
import { ButtonTypeEnum, Button } from "@components/primitive/button/button"
import { images } from "@assets/images/images"

export interface BaseTransactionPanelProps {
  children?: React.ReactNode
  title?: string
  counterpartyLabel?: string
  counterpartyName: string
  counterpartyTextColor?: string
  counterpartyLogoSrc?: string
  buttonText?: string
  buttonOnClick?: () => void
  pill?: React.ReactElement | null
  sx?: SxStyleProp
  withoutCounterpartyName?: boolean
  onCloseClick?: () => void
}

export const BaseTransactionPanel = ({
  title,
  counterpartyName,
  counterpartyLabel,
  counterpartyTextColor,
  counterpartyLogoSrc,
  buttonText,
  buttonOnClick,
  pill,
  children,
  sx,
  withoutCounterpartyName,
  onCloseClick,
}: BaseTransactionPanelProps) => {
  return (
    <Panel
      sx={{
        paddingX: theme.spacing.transactionPanel.x,
        paddingY: theme.spacing.transactionPanel.y,
        ...sx,
      }}
    >
      {title && (
        <Text sx={{ paddingBottom: 16 }} textColor={theme.colors.ui2} tag="h5">
          {title}
        </Text>
      )}
      <FlexRowLayout
        sx={{
          justifyContent: buttonText || pill || onCloseClick ? "space-between" : "flex-start",
          marginBottom: 16,
        }}
      >
        <CounterpartyAvatar
          size={40}
          counterpartyName={counterpartyName}
          imageSrc={counterpartyLogoSrc}
        />
        {buttonText ? (
          <Button type={ButtonTypeEnum.TERTIARY} onClick={buttonOnClick}>
            {buttonText}
          </Button>
        ) : null}
        {pill}
        {onCloseClick && <CloseButton onCloseClick={onCloseClick} />}
      </FlexRowLayout>
      {!withoutCounterpartyName && (
        <>
          <Text textColor={theme.colors.ui2} tag="h5">
            {counterpartyLabel}
          </Text>
          <Text
            sx={{
              margin: counterpartyLabel ? "8px 0 8px 0" : "20px 0 8px 0",
              width: "100%",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
            textColor={
              counterpartyTextColor
                ? counterpartyTextColor
                : !counterpartyName
                ? theme.colors.ui2
                : theme.colors.ui1
            }
            tag="h3Serif"
          >
            {counterpartyName}
          </Text>
        </>
      )}
      {children}
    </Panel>
  )
}

interface TransactionPanelItemProps {
  children?: React.ReactNode
  label: string
  marginBottom?: string
}

export const TransactionPanelItem = ({
  label,
  children,
  marginBottom = "16px",
}: TransactionPanelItemProps) => (
  <FlexColumnLayout sx={{ marginBottom }}>
    <Text tag="h5" sx={{ paddingBottom: "2px" }} textColor={theme.colors.ui2}>
      {label}
    </Text>
    {children}
  </FlexColumnLayout>
)

interface CloseButtonProps {
  onCloseClick: () => void
}

const CloseButton = ({ onCloseClick }: CloseButtonProps) => {
  return (
    <Box
      sx={{
        cursor: "pointer",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        border: `1px solid ${theme.colors.ui4}`,
        borderRadius: "50%",
        width: 40,
        height: 40,
      }}
      onClick={onCloseClick}
    >
      <Image src={images.icons.close} />
    </Box>
  )
}
