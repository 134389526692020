import React, { useEffect } from "react"
import { redirectWithTraits } from "@northone/segment-js"
import { useDispatch } from "react-redux"
import { ThunkDispatch } from "redux-thunk"
import { IAction, useAppSelector } from "@core/redux/utils"
import { Analytics } from "@core/analytics/actions"
import { unauthenticatedUserIntercomSettings } from "@core/intercom/intercom-settings"
import { IRootState } from "@core/redux/root-state"
import { ErrorScreen } from "@components/composite/error-screen/error-screen"
import { OnboardingLayout } from "@layouts/post-onboarding-layout"
import { config } from "@utils/environment"
import { OnboardingAccountStatus, useOnboardingMeQuery } from "@generated/graphql"
import { userActions } from "./redux/actions"
import { selectActiveBusinessID } from "@core/active-business/redux/selectors"
import { clearSegmentLocalStorage, ISegmentTraits } from "@utils/segment"
import { useConfirmAdditionalOwnersOnboardingStatus, useOwnersList } from "./hooks"
import * as Sentry from "@sentry/browser"

interface PostAuthWrapperProps {
  children?: React.ReactNode
}

export const PostAuthWrapper = ({ children }: PostAuthWrapperProps) => {
  const dispatch = useDispatch<ThunkDispatch<IRootState, undefined, IAction<any>>>()
  const businessId = useAppSelector(selectActiveBusinessID)
  const userId = useAppSelector((state) => state.user.userId)

  const { ownersListError, ownersListRefetch, ownersListLoading, currentUser } = useOwnersList(
    businessId,
    userId,
  )

  const { hasAdditionalOwnerCompletedOnboarding, additionalOwnerOnboardingStatusLoading } =
    useConfirmAdditionalOwnersOnboardingStatus()

  const { data, loading: meQueryLoading, error, refetch } = useOnboardingMeQuery()

  // Redirect to JoinDot if the account is unopened
  useEffect(() => {
    if (meQueryLoading) return

    // data.me is undefined for brand-new users
    const accountIsOpened =
      data?.me?.ownerBusinesses[0].onboarding?.accountStatus === OnboardingAccountStatus.OPENED
    if (accountIsOpened) return

    redirectWithTraits(config.desktopOnboardingUrl)
  }, [data, meQueryLoading])

  // Redirect additional owners to JoinDot if they have not finished onboarding
  useEffect(() => {
    if (ownersListLoading) return
    if (!currentUser) return
    if (currentUser.isPrimaryOwner) return
    if (additionalOwnerOnboardingStatusLoading) return
    if (hasAdditionalOwnerCompletedOnboarding) return

    redirectWithTraits(config.desktopOnboardingUrl)
  }, [
    ownersListLoading,
    currentUser,
    additionalOwnerOnboardingStatusLoading,
    hasAdditionalOwnerCompletedOnboarding,
  ])

  // Populate component and Redux state with me query data
  useEffect(() => {
    if (!data?.me) return
    dispatch(userActions.setUserId(data.me.id))
    dispatch(userActions.setMe(data.me))
  }, [data])

  useEffect(() => {
    if (!data?.me) return
    if (!userId) return

    const accountIsOpened =
      data?.me?.ownerBusinesses[0].onboarding?.accountStatus === OnboardingAccountStatus.OPENED
    const fullName = `${data.me.firstName} ${data.me.lastName}`

    if (accountIsOpened) {
      const segmentTraits: ISegmentTraits = { email: data.me.email, businessId }
      Analytics.identify(data.me.id, segmentTraits)
      clearSegmentLocalStorage(segmentTraits)
      Analytics.startSession()
    }

    Sentry.setUser({
      id: userId,
      email: data.me.email || "",
      username: fullName,
    })

    window.Intercom("boot", {
      ...unauthenticatedUserIntercomSettings,
      user_id: userId,
      email: data.me.email || "",
      name: fullName,
    })
  }, [data, userId])

  if (error) {
    return (
      <OnboardingLayout>
        <ErrorScreen retry={refetch} size="large" />
      </OnboardingLayout>
    )
  }

  if (ownersListError) {
    return (
      <OnboardingLayout>
        <ErrorScreen retry={ownersListRefetch} size="large" />
      </OnboardingLayout>
    )
  }

  if (meQueryLoading || ownersListLoading || additionalOwnerOnboardingStatusLoading) {
    return null
  }

  return <>{children}</>
}
