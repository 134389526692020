import { ErrorCodeEnum } from "@generated/graphql"
import i18n from "@i18n/i18n"
import { hasGQLErrorType, isApolloError } from "../../../../utils/errors"

export const getReviewPaymentCustomErrorMessage = ({
  error,
  isScheduledPayment,
}: {
  error: unknown
  isScheduledPayment: boolean
}): { errorText: string; isDefault?: boolean } => {
  const defaultErrorMessage = {
    errorText: isScheduledPayment
      ? i18n.t("invoicePayments.modal.reviewPayment.error.invoiceScheduledPaymentError")
      : i18n.t("invoicePayments.modal.reviewPayment.error.invoicePaymentError"),
    isDefault: true,
  }

  if (!isApolloError(error)) {
    return defaultErrorMessage
  }

  if (hasGQLErrorType(error, ErrorCodeEnum.INVOICE_ACCOUNTS_PAYABLE_INSUFFICIENT_BALANCE)) {
    return {
      errorText: i18n.t("invoicePayments.modal.reviewPayment.error.insufficientBalanceError"),
    }
  }

  if (hasGQLErrorType(error, ErrorCodeEnum.INVOICE_ACCOUNTS_PAYABLE_EXCEEDS_LIMIT)) {
    return { errorText: i18n.t("invoicePayments.modal.reviewPayment.error.exceedsLimitError") }
  }

  if (hasGQLErrorType(error, ErrorCodeEnum.INVOICE_ACCOUNTS_PAYABLE_INVALID_ACH_DETAILS)) {
    return { errorText: i18n.t("invoicePayments.modal.reviewPayment.error.invalidAchDetailsError") }
  }

  if (hasGQLErrorType(error, ErrorCodeEnum.INVOICE_ACCOUNTS_PAYABLE_MISSING_MAILED_CHECK_DETAILS)) {
    return { errorText: i18n.t("invoicePayments.modal.reviewPayment.error.missingDetailsError") }
  }

  if (hasGQLErrorType(error, ErrorCodeEnum.INVOICE_ACCOUNTS_PAYABLE_MISSING_MAILED_CHECK_ADDRESS)) {
    return { errorText: i18n.t("invoicePayments.modal.reviewPayment.error.missingAddressError") }
  }

  if (hasGQLErrorType(error, ErrorCodeEnum.INVOICE_ACCOUNTS_PAYABLE_CARD_NOT_ACTIVATED)) {
    return { errorText: i18n.t("invoicePayments.modal.reviewPayment.error.cardNotActivatedError") }
  }

  if (hasGQLErrorType(error, ErrorCodeEnum.INVOICE_ACCOUNTS_PAYABLE_INVALID_WIRE_DETAILS)) {
    return {
      errorText: i18n.t("invoicePayments.modal.reviewPayment.error.invalidWireDetailsError"),
    }
  }

  if (hasGQLErrorType(error, ErrorCodeEnum.INVOICE_ACCOUNTS_PAYABLE_INVALID_SCHEDULE_START_DATE)) {
    return {
      errorText: i18n.t("invoicePayments.modal.reviewPayment.error.invalidScheduledStartDateError"),
    }
  }

  return defaultErrorMessage
}
