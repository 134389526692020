import React, { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import { activeBusinessActions } from "@core/active-business/redux/actions"
import {
  auth,
  AuthenticationError,
  redirectToLogin,
  getTokenSilently,
  getBusinessClaims,
  renewTokenOrLogout,
} from "@core/auth/auth-service"
import { config } from "@utils/environment"
import { IdleTimeoutDialog } from "./idle-timeout-dialog"

interface AuthWrapperProps {
  children?: React.ReactNode
}

export const AuthWrapper = ({ children }: AuthWrapperProps) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    // verifies the session is still active whenever the window is focussed on
    window.addEventListener("focus", renewTokenOrLogout)
    return () => window.removeEventListener("focus", renewTokenOrLogout)
  }, [])

  useEffect(() => {
    const checkAuthSession = async () => {
      // redirect to login on logout
      const queryParams = new URLSearchParams(window.location.search)
      if (queryParams.get("logout")) {
        return await redirectToLogin()
      }

      let redirectRoute: string
      try {
        //redirect path on appState is used to send user to deep link
        //https://community.auth0.com/t/redirect-after-login-page/63011
        const result = await auth.handleRedirectCallback()
        if (result.appState.redirectRoute) {
          redirectRoute = result.appState.redirectRoute
        }
      } catch (error) {
        // intercept blocked user callbacks
        //@ts-ignore
        if (error.error === AuthenticationError.BLOCKED) {
          return await redirectToLogin("unauthorized")
        }
      }

      const dispatchBusinessId = async () => {
        const businessClaims = await getBusinessClaims()
        if (businessClaims.length) {
          const { id } = businessClaims[businessClaims.length - 1]
          dispatch(activeBusinessActions.setBusinessId(id))
        }
        setIsAuthenticated(true)
        if (redirectRoute) window.location.href = redirectRoute
      }

      try {
        const token = await getTokenSilently()
        if (token) return await dispatchBusinessId()
        return await redirectToLogin()
      } catch (error) {
        //@ts-ignore
        if (error.error === AuthenticationError.LOGIN_REQUIRED) {
          return await redirectToLogin()
        } else {
          console.error(error)
        }
      }
    }

    checkAuthSession()
  }, [dispatch])

  return isAuthenticated ? (
    <>
      {children}
      <IdleTimeoutDialog timeout={config.logoutOnIdleTime} warningTime={1000 * 60} />
    </>
  ) : null
}
