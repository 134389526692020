import React, { isValidElement, useEffect, useState } from "react"
import { LayoutGroup, motion } from "framer-motion"
import { SxStyleProp } from "rebass"

import { theme } from "@layouts/theme"
import { FlexRowLayout, FlexColumnLayout } from "@layouts/flex"
import { Button, ButtonTypeEnum } from "@components/primitive/button/button"

export type TTabs = {
  title: string
  additionalContent?: React.ReactElement
  onTabSelected?: () => void
  content?: React.ReactElement
}

interface ITabsProps {
  tabs: TTabs[]
  sx?: SxStyleProp
  onTabChange?: (idx: number) => void
}

export const Tabs: React.FC<ITabsProps> = ({ tabs, sx, onTabChange }) => {
  const defaultIndex = 0
  const [activeTabIndex, setActiveTabIndex] = useState<number>(defaultIndex)

  const handleClick = (index: number) => {
    const tab = tabs[index]

    const isCurrentTabIndex = index === activeTabIndex

    if (onTabChange && !isCurrentTabIndex) {
      onTabChange(index)
    }

    if (tab?.onTabSelected && !isCurrentTabIndex) {
      tab.onTabSelected()
    }

    setActiveTabIndex(index)
  }

  useEffect(() => {
    const initialTab = tabs[activeTabIndex]

    if (initialTab?.onTabSelected) {
      initialTab.onTabSelected()
    }
  }, [])

  return (
    <>
      <FlexRowLayout
        sx={{
          marginBottom: 10,
          borderBottom: `2px solid ${theme.colors.ui5}`,
          width: "100%",
          zIndex: 1,
          ...sx,
        }}
      >
        {tabs.map((tab, idx) => (
          <LayoutGroup key={idx}>
            <ol style={{ padding: 0, margin: 0 }}>
              <motion.li
                layout
                style={{
                  marginRight: "49px",
                  listStyle: "none",
                  width: "100%",
                }}
              >
                <FlexRowLayout>
                  <FlexColumnLayout>
                    <Button
                      type={ButtonTypeEnum.SECONDARY}
                      sx={{
                        padding: 0,
                        cursor: "pointer",
                      }}
                      isActivated={activeTabIndex === idx}
                      style={{
                        enabledStyle: {
                          backgroundColor: "transparent",
                          borderColor: "transparent",
                          textColor: activeTabIndex === idx ? theme.colors.ui1 : theme.colors.ui2,
                        },
                        disabledStyle: {
                          opacity: 0,
                          textColor: "transparent",
                        },
                      }}
                      onClick={() => handleClick(idx)}
                    >
                      {tab.title}
                    </Button>
                    {activeTabIndex === idx ? (
                      <motion.div
                        style={{
                          position: "relative",
                          bottom: "-2px",
                          height: "2px",
                          background: theme.colors.ui1,
                        }}
                      />
                    ) : null}
                  </FlexColumnLayout>
                  {isValidElement(tab.additionalContent) && (
                    <FlexColumnLayout sx={{ justifyContent: "center" }}>
                      {tab.additionalContent}
                    </FlexColumnLayout>
                  )}
                </FlexRowLayout>
              </motion.li>
            </ol>
          </LayoutGroup>
        ))}
      </FlexRowLayout>
      {tabs[activeTabIndex].content}
    </>
  )
}
