import { MotionStyle } from "framer-motion"
import React, { useState, useEffect } from "react"
import { FlexRowLayout } from "@layouts/flex"
import { Text } from "../text/text"
import { theme } from "@layouts/theme"
import { Box, SxStyleProp } from "rebass"
import FontFaceObserver from "fontfaceobserver"
import { AnimatePresence } from "@components/animations/animate-presence/animation-presence"
import * as Sentry from "@sentry/browser"

export type TToolTipPosition = "top" | "left" | "right" | "bottom"
const fontA = new FontFaceObserver("Graphik")
const fontB = new FontFaceObserver("Plantin MT Pro")

interface ToolTipProps {
  visible: boolean
  children?: React.ReactNode
  position: TToolTipPosition
  text: string
  width?: number
  sxProps?: SxStyleProp
}

export const ToolTip = ({ visible, children, text, position, width, sxProps }: ToolTipProps) => {
  const childrenRef: React.RefObject<HTMLDivElement> = React.useRef(null)
  const [childrenHeight, setChildrenHeight] = useState(0)
  const [childrenWidth, setChildrenWidth] = useState(0)

  const setDimensions = () => {
    const boundingRect = childrenRef.current?.getBoundingClientRect()
    setChildrenHeight(boundingRect?.height || 0)
    setChildrenWidth(boundingRect?.width || 0)
  }

  useEffect(() => {
    let isMounted = true
    setDimensions()
    Promise.all([fontA.load(), fontB.load()])
      .then(() => {
        if (isMounted) {
          setDimensions()
        }
      })
      .catch((error) => Sentry.captureException(error))

    return () => {
      isMounted = false
    }
  }, [])
  useEffect(setDimensions, [children])

  const containerProps: { [key in TToolTipPosition]: MotionStyle } = {
    top: {
      flexDirection: "column",
      bottom: childrenHeight,
      left: 0,
      right: 0,
    },
    bottom: {
      flexDirection: "column-reverse",
      top: childrenHeight,
      left: 0,
      right: 0,
    },
    left: { right: childrenWidth + 8, flexDirection: "row", top: 0 },
    right: { left: childrenWidth + 8, flexDirection: "row-reverse", top: 0 },
  }
  const pointerProps: { [key in TToolTipPosition]: SxStyleProp } = {
    top: {
      borderLeft: "5px solid transparent",
      borderRight: "5px solid transparent",
      borderTop: `5px solid ${theme.colors.ui1}`,
    },
    bottom: {
      borderLeft: "5px solid transparent",
      borderRight: "5px solid transparent",
      borderBottom: `5px solid ${theme.colors.ui1}`,
    },
    left: {
      borderTop: "5px solid transparent",
      borderBottom: "5px solid transparent",
      borderLeft: `5px solid ${theme.colors.ui1}`,
      marginTop: childrenHeight / 2.5,
    },
    right: {
      borderTop: "5px solid transparent",
      borderBottom: "5px solid transparent",
      borderRight: `5px solid ${theme.colors.ui1}`,
      marginTop: childrenHeight / 2.5,
    },
  }
  return (
    <Box sx={{ position: "relative", ...sxProps }}>
      <Box sx={{ display: "inline" }} ref={childrenRef}>
        {children}
      </Box>
      <AnimatePresence
        animation="fadeIn"
        style={{
          display: "flex",
          position: "absolute",
          alignItems: position === "top" || position === "bottom" ? "center" : undefined,
          ...containerProps[position],
          zIndex: theme.zIndex.flowModal + 1,
        }}
      >
        {visible && (
          <>
            <FlexRowLayout
              sx={{
                justifyContent: "center",
                backgroundColor: theme.colors.ui1,
                padding: "8px 12px",
                borderRadius: "4px",
                maxWidth: 180,
                width,
              }}
            >
              <Text
                textColor={theme.colors.ui7}
                sx={{
                  textAlign: position === "top" || position === "bottom" ? "center" : "left",
                  lineHeight: "11px",
                }}
                tag="label"
              >
                {text}
              </Text>
            </FlexRowLayout>
            <Box
              sx={{
                height: 0,
                width: 0,
                ...pointerProps[position],
              }}
            ></Box>
          </>
        )}
      </AnimatePresence>
    </Box>
  )
}
