import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { AnimatePresence, LoadingIcon } from "@northone/ui"
import { NoticeBox } from "@components/primitive/notice-box/notice-box"
import { events } from "@core/analytics/events"
import { Analytics } from "@core/analytics/actions"
import { useAppSelector } from "@core/redux/utils"
import { selectActiveBusinessID } from "@core/active-business/redux/selectors"
import { ACHScreensEnum } from "@features/move-money/router/ach"
import { FormBody } from "@components/composite/form-body/form-body"
import { ButtonTypeEnum } from "@components/primitive/button/button"
import { MoveMoneyListItem } from "@components/extended/list-items/move-money/move-money-list-item"
import { DisclaimerNote } from "@components/composite/disclaimer-note/disclaimer-note"
import { moveMoneyActions } from "@features/move-money/redux/actions"
import { selectPaymentMethod } from "@features/move-money/redux/selectors"
import { TMoveMoneyScreenNames } from "@features/move-money/router/move-money"
import { TransactionLimitsNote } from "@features/move-money/shared/transaction-limits-note"
import {
  BUTTONS_CONTINUE,
  ERRORS_NETWORK,
  WIRE_CUTOFF,
} from "@features/move-money/utils/i18n-translations"
import { DomesticWireScreensEnum } from "@features/move-money/router/domestic-wire"
import {
  PAYMENT_METHOD_TITLE,
  PAYMENT_METHOD_WIRE_FEE_I18N,
} from "@features/move-money/utils/i18n-translations"
import {
  CardProductType,
  TransactionTypeEnum,
  useCapabilitiesAndAvailableBalanceQuery,
  useCardsActivatedQuery,
} from "@generated/graphql"
import { translate } from "@i18n/i18n"
import { wireCutOffTimeInLocalTime } from "../domestic-wire/utils/helpers"
import { getFeeDisplay } from "../utils/fee.utils"
import { ErrorPanel } from "@components/extended/panel/error-panel"
import { FlexColumnLayout } from "@layouts/flex"

interface IPaymentType {
  enum: TransactionTypeEnum
  initialScreen?: TMoveMoneyScreenNames
  eventName?: string
  subTitle?: string
}

const paymentTypes: IPaymentType[] = [
  {
    enum: TransactionTypeEnum.DOMESTIC_WIRE,
    initialScreen: DomesticWireScreensEnum.DOMESTIC_WIRE_RECIPIENT,
    eventName: events.moveMoney.paymentMethodSelection.wire,
    subTitle: translate(WIRE_CUTOFF, { cutOffTime: wireCutOffTimeInLocalTime() }),
  },
  {
    enum: TransactionTypeEnum.ACH,
    initialScreen: ACHScreensEnum.ACH_RECIPIENT,
    eventName: events.moveMoney.paymentMethodSelection.ach,
  },
  // This is actually a linked account transfer - but the GQL considers this a sub account transfer
  {
    enum: TransactionTypeEnum.SUB_ACCOUNT_TRANSFER,
  },
]

export const PaymentMethodForm: React.FC = () => {
  const dispatch = useDispatch()
  const activePaymentType = useAppSelector(selectPaymentMethod)
  const wireEnabled = useAppSelector((state) => state.moveMoney.wireEnabled)
  const businessId: string = useAppSelector(selectActiveBusinessID)
  const [hasError, setHasError] = useState(false)

  const buttons = [
    {
      canContinueWithKey: true,
      type: ButtonTypeEnum.PRIMARY_GOLD,
      children: BUTTONS_CONTINUE,
      onClick: () => {
        const paymentType = paymentTypes.find((item) => item.enum === activePaymentType)
        if (paymentType && paymentType.eventName && paymentType.initialScreen) {
          dispatch(moveMoneyActions.navigateNext(paymentType.initialScreen))
          Analytics.track(paymentType.eventName)
        }
      },
      disabled:
        !activePaymentType ||
        activePaymentType === TransactionTypeEnum.SUB_ACCOUNT_TRANSFER ||
        (activePaymentType === TransactionTypeEnum.DOMESTIC_WIRE && !wireEnabled),
    },
  ]

  const {
    data: capabilitiesData,
    error: capabilitiesError,
    loading: capabilitiesLoading,
    refetch: capabilitiesRefetch,
  } = useCapabilitiesAndAvailableBalanceQuery({
    variables: { businessId },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const wireEnabled = data.business?.bankAccounts.some(
        (bankAccount) => bankAccount.capabilities?.domesticWire.enabled,
      )
      dispatch(moveMoneyActions.setWireEnabled(Boolean(wireEnabled)))
    },
  })
  const wireFee = getFeeDisplay(
    capabilitiesData?.business?.bankAccounts[0].capabilities?.domesticWire.fee,
  )
  const { loading, error, refetch } = useCardsActivatedQuery({
    variables: { businessId },
    onError: () => dispatch(moveMoneyActions.setPaymentType(undefined)),
    onCompleted: (data) => {
      const hasActivatedCard = data.business?.bankAccount?.cards.some(
        (card) => card.activation?.activated && card.productType === CardProductType.PHYSICAL,
      )
      const nonActivatedCardIds =
        data.business?.bankAccount?.cards
          .filter((card) => !card.activation?.activated)
          .map((card) => card.id) || []

      dispatch(moveMoneyActions.setNonActivatedCardIds(hasActivatedCard ? [] : nonActivatedCardIds))
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  })

  useEffect(() => {
    dispatch(moveMoneyActions.deleteDraft())
  }, [dispatch])

  if (capabilitiesLoading) {
    return (
      <FlexColumnLayout
        alignItems="center"
        justifyContent="center"
        sx={{
          margin: "auto",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <LoadingIcon dimension={50} isCentered />
      </FlexColumnLayout>
    )
  }
  if (capabilitiesError || !wireFee) {
    return <ErrorPanel retry={capabilitiesRefetch} />
  }

  return (
    <>
      <FormBody title={PAYMENT_METHOD_TITLE} buttons={buttons}>
        <AnimatePresence animation="fadeAndExpand">
          {hasError && !loading && <NoticeBox level="error" text={ERRORS_NETWORK} />}
        </AnimatePresence>
        {paymentTypes.map((paymentType, index) => (
          <MoveMoneyListItem
            key={index}
            isActive={activePaymentType === paymentType.enum}
            type={paymentType.enum}
            subTitle={paymentType.subTitle}
            onClick={() => {
              dispatch(moveMoneyActions.setPaymentType(paymentType.enum))
              if (paymentType.enum === TransactionTypeEnum.DOMESTIC_WIRE && error) {
                setHasError(true)
                refetch()
              }
            }}
          />
        ))}
      </FormBody>
      <TransactionLimitsNote />
      <DisclaimerNote text={translate(PAYMENT_METHOD_WIRE_FEE_I18N, { wireFee })} />
    </>
  )
}
