import React, { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import { v4 as uuid } from "uuid"
import { Analytics } from "@core/analytics/actions"
import { events } from "@core/analytics/events"
import { useAppSelector } from "@core/redux/utils"
import { selectActiveBusinessID } from "@core/active-business/redux/selectors"
import { FormBody } from "@components/composite/form-body/form-body"
import { ButtonTypeEnum } from "@components/primitive/button/button"
import { moveMoneyActions } from "@features/move-money/redux/actions"
import {
  selectEditRecipient,
  selectWireRecipientFieldsAreValid,
} from "@features/move-money/redux/selectors"
import { DomesticWireScreensEnum } from "@features/move-money/router/domestic-wire"
import {
  selectDomesticWireUpdateRecipientVariables,
  selectDomesticWireCreateRecipientVariables,
} from "@features/settings/recipients/edit/redux/selectors"
import {
  RECIPIENT_CONTINUE_BTN,
  RECIPIENT_EDIT_TITLE,
  RECIPIENT_ADD_TITLE,
  RECIPIENT_EDIT_SUBTITLE,
  BUTTONS_GO_BACK,
  ERRORS_GENERIC,
} from "@features/move-money/utils/i18n-translations"
import { DOMESTIC_WIRE_RECIPIENTS } from "@features/move-money/operations.gql"
import {
  ContactPaymentType,
  useRoutingNumberWireIsValidLazyQuery,
  useRecipientDomesticWireCreateMutation,
  useRecipientDomesticWireUpdateMutation,
} from "@generated/graphql"
import { FlexColumnLayout } from "@layouts/flex"
import { pollWithMaxRetries } from "@utils/poll-with-max-retries"
import { DomesticWireRecipientDetails } from "./domestic-wire-details"

export const DomesticWireRecipientDetailsForm: React.FC = () => {
  const dispatch = useDispatch()
  const [idempotencyKey] = useState<string>(uuid())
  const [hasError, setHasError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [currentTitle, setCurrentTitle] = useState("")
  const [currentSubTitle, setCurrentSubTitle] = useState("")
  const [confirmButtonPressed, setConfirmButtonPressed] = useState(false)
  const titleRef: React.RefObject<HTMLDivElement> = React.useRef(null)
  const businessId: string = useAppSelector(selectActiveBusinessID)
  const editingRecipient = useAppSelector(selectEditRecipient)
  const recipientFieldsAreValid = useAppSelector(selectWireRecipientFieldsAreValid)
  const domesticWireRecipientUpdateVariables = useAppSelector(
    selectDomesticWireUpdateRecipientVariables(idempotencyKey),
  )
  const domesticWireRecipientCreateVariables = useAppSelector(
    selectDomesticWireCreateRecipientVariables(idempotencyKey),
  )
  const { id, name, bankRoutingNumber, bankAccountNumber } = editingRecipient

  useEffect(() => {
    setCurrentTitle(!recipientFieldsAreValid ? RECIPIENT_ADD_TITLE : RECIPIENT_EDIT_TITLE)
    setCurrentSubTitle(!id || recipientFieldsAreValid ? "" : RECIPIENT_EDIT_SUBTITLE)
  }, [])

  const buttons = [
    {
      type: ButtonTypeEnum.TERTIARY,
      children: BUTTONS_GO_BACK,
      onClick: () => dispatch(moveMoneyActions.navigateBack()),
    },
    {
      canContinueWithKey: true,
      type: ButtonTypeEnum.PRIMARY_GOLD,
      children: RECIPIENT_CONTINUE_BTN,
      disabled: !recipientFieldsAreValid || confirmButtonPressed,
      onClick: () => {
        setConfirmButtonPressed(true)
        setIsLoading(true)
        setHasError(false)
        getRoutingNumberIsValid({ variables: { routingNumber: bankRoutingNumber || "" } })
      },
      isLoading,
    },
  ]

  const navigateToNextScreen = () => {
    dispatch(moveMoneyActions.navigateNext(DomesticWireScreensEnum.DOMESTIC_WIRE_AMOUNT))
    Analytics.track(events.moveMoney.domesticWire.recipientDetails)
  }

  const fetchUpdatedWireRecipients = () => {
    pollWithMaxRetries([
      {
        query: DOMESTIC_WIRE_RECIPIENTS,
        variables: { businessId, contactPaymentType: ContactPaymentType.WIRE },
      },
    ])
  }

  const [createWireRecipient] = useRecipientDomesticWireCreateMutation({
    onCompleted: (data) => {
      fetchUpdatedWireRecipients()
      const recipient = data.contactDomesticWireCreate?.contact
      if (recipient) {
        dispatch(moveMoneyActions.setRecipient({ ...editingRecipient, id: recipient.id }))
        dispatch(moveMoneyActions.setEditingRecipient({ ...editingRecipient, id: recipient.id }))
        setIsLoading(false)
        navigateToNextScreen()
      }
    },
    onError: () => {
      setIsLoading(false)
      setHasError(true)
      setConfirmButtonPressed(false)
    },
  })

  const [updateWireRecipient] = useRecipientDomesticWireUpdateMutation({
    onCompleted: () => {
      fetchUpdatedWireRecipients()
      setIsLoading(false)
      navigateToNextScreen()
    },
    onError: () => {
      setIsLoading(false)
      setHasError(true)
      setConfirmButtonPressed(false)
    },
  })

  const [getRoutingNumberIsValid, { data: routingNumberData }] =
    useRoutingNumberWireIsValidLazyQuery({
      onCompleted: (data) => {
        if (data) {
          if (!data.routingNumberWireIsValid && titleRef && titleRef.current) {
            setIsLoading(false)
            setConfirmButtonPressed(false)
            titleRef.current.scrollIntoView({ behavior: "smooth", block: "center" })
          }
          if (data.routingNumberWireIsValid && name && bankAccountNumber && bankRoutingNumber) {
            if (!id) {
              createWireRecipient({ variables: domesticWireRecipientCreateVariables })
            } else {
              updateWireRecipient({ variables: domesticWireRecipientUpdateVariables })
              setIsLoading(false)
            }
          }
        }
      },
      onError: () => {
        setIsLoading(false)
        setHasError(true)
        setConfirmButtonPressed(false)
      },
      fetchPolicy: "network-only",
    })

  return (
    <FormBody
      errorMessage={hasError ? ERRORS_GENERIC : ""}
      titleRef={titleRef}
      title={currentTitle}
      subtitle={currentSubTitle}
      buttons={buttons}
    >
      <FlexColumnLayout>
        <DomesticWireRecipientDetails
          routingNumberIsValid={Boolean(
            !routingNumberData || routingNumberData.routingNumberWireIsValid,
          )}
        />
      </FlexColumnLayout>
    </FormBody>
  )
}
