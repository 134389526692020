import React from "react"

import { Text } from "@components/primitive/text/text"
import { theme } from "@layouts/theme"
import { FlexRowLayout, FlexColumnLayout } from "@layouts/flex"
import { Image } from "rebass"
import { images } from "@assets/images/images"
import { LaunchWidgetButton } from "./launch-widget-button"

type LoanProductSectionBannerProps = {
  title: string
  description: string
  bulletPointsTitle: string
  bulletPoints: string[]
  ctaButton?: {
    onClick: () => void
    label: string
    subtext: string
  }
}

export const LoanProductSectionBanner: React.FC<LoanProductSectionBannerProps> = ({
  title,
  description,
  bulletPointsTitle,
  bulletPoints,
  ctaButton,
}) => {
  const ctaButtonComponent = ctaButton ? (
    <LaunchWidgetButton label={ctaButton.label} onClick={ctaButton.onClick} />
  ) : undefined

  const ctaButtonSubtextComponent = ctaButton ? (
    <Text
      tag="body-x-small"
      sx={{
        fontWeight: 400,
        letterSpacing: "0.25px",
        fontStyle: "normal",
        color: theme.colors.blackTint1,
      }}
    >
      {ctaButton.subtext}
    </Text>
  ) : undefined

  return (
    <FlexRowLayout
      sx={{
        alignItems: "center",
        width: "100%",
        justifyContent: "space-between",
        padding: 70,
        border: "1px solid var(--copper-tint-4-f-8-f-0-e-6, #F8F0E6)",
        background: "linear-gradient(164deg, #F8F0E6 -171.04%, rgba(248, 240, 230, 0.00) 208.68%)",
      }}
    >
      <FlexColumnLayout
        sx={{
          rowGap: 20,
          justifyContent: "center",
          maxWidth: 424,
          height: "100%",
        }}
      >
        <Text tag={"h3"} sx={{ colors: theme.colors.ui1 }}>
          {title}
        </Text>
        <Text
          tag={"body-large"}
          sx={{
            fontWeight: 400,
            lineHeight: "23px",
            color: theme.colors.blackTint1,
          }}
        >
          {description}
        </Text>
        {ctaButtonComponent}
        {ctaButtonSubtextComponent}
      </FlexColumnLayout>
      <BulletPointsSection bulletPoints={bulletPoints} bulletPointsTitle={bulletPointsTitle} />
    </FlexRowLayout>
  )
}

const BulletPointsSection: React.FC<{ bulletPoints: string[]; bulletPointsTitle: string }> = ({
  bulletPoints,
  bulletPointsTitle,
}) => {
  const bulletPointListItems = bulletPoints.map((bulletPoint, index) => (
    <BulletPointListItem key={index} bulletPointText={bulletPoint} />
  ))

  return (
    <FlexColumnLayout
      sx={{
        rowGap: 16,
        width: 375,
        alignItems: "flex-start",
      }}
    >
      <Text
        tag={"body"}
        sx={{
          fontSize: "16px",
          color: theme.colors.copperDark,
        }}
      >
        {bulletPointsTitle}
      </Text>
      <FlexColumnLayout sx={{ rowGap: 12 }}>{bulletPointListItems}</FlexColumnLayout>
    </FlexColumnLayout>
  )
}

const BulletPointListItem: React.FC<{ bulletPointText: string }> = ({ bulletPointText }) => (
  <FlexRowLayout sx={{ columnGap: 10, alignSelf: "stretch", alignItems: "flex-start" }}>
    <Image
      src={images.icons.checkMarkDark}
      sx={{
        width: "16px",
        paddingX: "2px",
        boxSizing: "content-box",
      }}
    />
    <Text
      tag={"body"}
      sx={{
        fontWeight: 400,
      }}
    >
      {bulletPointText}
    </Text>
  </FlexRowLayout>
)
