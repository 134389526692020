import { Button, ButtonTypeEnum, Panel, Text } from "@northone/ui"
import React from "react"
import { images } from "@assets/images/images"
import { TransactionTypeEnum } from "@generated/graphql"
import { theme } from "@layouts/theme"
import {
  TRANS_FEATURE_REMOVAL_BILL_PAY_TITLE,
  TRANS_FEATURE_REMOVAL_MAILED_CHECK_TITLE,
  TRANS_FEATURE_REMOVAL_EXTERNAL_TRANSFER_TITLE,
  TRANS_FEATURE_REMOVAL_WIRE_TITLE,
  TRANS_FEATURE_REMOVAL_CHECK_DEPOSITS,
  SETTINGS_FEATURE_REMOVAL_SUBTITLE,
  SETTINGS_FEATURE_REMOVAL_SUBTITLE2,
  SETTINGS_FEATURE_REMOVAL_CTA_BTN,
} from "@features/move-money/utils/i18n-translations"

export interface RemovedFeaturePanelProps {
  transactionType?: TransactionTypeEnum
}

export const RemovedFeaturePanel: React.FC<RemovedFeaturePanelProps> = ({ transactionType }) => {
  const titles: { [key: string]: string } = {
    [TransactionTypeEnum.BILL_PAYMENT]: TRANS_FEATURE_REMOVAL_BILL_PAY_TITLE,
    [TransactionTypeEnum.CHECK_DEPOSIT]: TRANS_FEATURE_REMOVAL_MAILED_CHECK_TITLE,
    [TransactionTypeEnum.SUB_ACCOUNT_TRANSFER]: TRANS_FEATURE_REMOVAL_EXTERNAL_TRANSFER_TITLE,
    [TransactionTypeEnum.DOMESTIC_WIRE]: TRANS_FEATURE_REMOVAL_WIRE_TITLE,
    [TransactionTypeEnum.CHECK_DEPOSIT]: TRANS_FEATURE_REMOVAL_CHECK_DEPOSITS,
  }

  return (
    <Panel
      sx={{
        paddingX: theme.spacing.transactionPanel.x,
        paddingY: theme.spacing.transactionPanel.y,
      }}
    >
      <Text tag="h4" sx={{ lineHeight: "26px", marginBottom: 15 }}>
        {transactionType ? titles[transactionType] : null}
      </Text>
      <Text tag="body-large" sx={{ color: theme.colors.ui2 }}>
        {SETTINGS_FEATURE_REMOVAL_SUBTITLE}
        <br></br>
        <br></br>
        {SETTINGS_FEATURE_REMOVAL_SUBTITLE2}
      </Text>
      <Button
        iconSrc={images.icons.export}
        iconSx={{ marginRight: "20px" }}
        sx={{ marginTop: 20, width: "100%", justifyContent: "flex-start" }}
        type={ButtonTypeEnum.PRIMARY_BLUE}
        onClick={() => window.Intercom("show")}
      >
        {SETTINGS_FEATURE_REMOVAL_CTA_BTN}
      </Button>
    </Panel>
  )
}
