import { appActions } from "@core/redux/app/actions"
import { useAppSelector } from "@core/redux/utils"
import { AnimatePresence, FlexRowLayout, Text, theme } from "@northone/ui"
import React, { useEffect } from "react"
import ReactDOM from "react-dom"
import { useDispatch } from "react-redux"

const TOAST_VISIBLE_TIME_MS = 2000

export const NotificationToast: React.FC = () => {
  const message = useAppSelector((state) => state.app.notificationMessage)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!message) return
    const notificationMessageTimeout = setTimeout(
      () => dispatch(appActions.setNotificationMessage("")),
      TOAST_VISIBLE_TIME_MS,
    )
    return () => clearTimeout(notificationMessageTimeout)
  }, [message])

  const root = document.getElementById("root") as HTMLDivElement

  return ReactDOM.createPortal(
    <AnimatePresence animation="fadeIn">
      {message && (
        <FlexRowLayout
          sx={{
            backgroundColor: theme.colors.ui1,
            paddingY: 12,
            paddingX: 22,
            position: "fixed",
            top: 80,
            left: "50%",
            transform: "translate(-50%, 0)",
            zIndex: 1000000,
            borderRadius: 4,
          }}
        >
          <Text tag="body" sx={{ color: theme.colors.ui7 }}>
            {message}
          </Text>
        </FlexRowLayout>
      )}
    </AnimatePresence>,
    root,
  )
}
