import React from "react"
import { useTranslation } from "react-i18next"
import { Image, Link, Box } from "rebass"
import { useAppSelector } from "@core/redux/utils"
import { selectActiveBusinessID } from "@core/active-business/redux/selectors"
import { Panel } from "@components/primitive/panel/panel"
import { Button, Text } from "@northone/ui"

import { CopyableField } from "@components/composite/copyable-field/copyable-field"
import { QueryResults } from "@components/composite/query-results/query-results"
import { theme } from "@layouts/theme"
import { FlexColumnLayout, FlexRowLayout } from "@layouts/flex"
import { useBankAccountInfoQuery } from "@generated/graphql"
import { IIntegration } from "./integrations-data"

export const IntegrationPanel: React.FC<IIntegration> = ({
  logo,
  name,
  instructions,
  subtitle,
  link,
  color,
  button,
}) => {
  const { t } = useTranslation()

  return (
    <Panel sx={{ paddingY: 22, paddingX: 24, overflow: "hidden", position: "relative" }}>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          height: 40,
          bg: color,
          zIndex: 1,
        }}
      />
      <FlexColumnLayout>
        <Image src={logo} sx={{ height: 40, width: 40, zIndex: 2 }} />
        <Text textColor={theme.colors.ui2} sx={{ marginY: "4px" }} tag="h5">
          {subtitle}
        </Text>
        <Text sx={{ marginBottom: 15 }} tag="h4">
          {name}
        </Text>
        {instructions?.map((instruction, idx) => {
          return instruction.text ? (
            <Text
              key={idx}
              hasPartialBolding={!instruction.partialStyling}
              partialStyling={instruction.partialStyling}
              sx={{ marginBottom: 13, ...instruction.sx }}
              tag={instruction.tag || "body"}
            >
              {instruction.text}
            </Text>
          ) : (
            <IntegrationsBankDetails key={idx} />
          )
        })}
        {link && (
          <Link sx={{ color: theme.colors.ui2, marginTop: "5px" }} href={link.url} target="_blank">
            <Text sx={{ textDecorationLine: "underline" }} tag="body">
              {link?.text || t("integrations.moreInfo")}
            </Text>
          </Link>
        )}

        {button && (
          <Box sx={{ marginTop: 23 }}>
            <Button {...button} />
          </Box>
        )}
      </FlexColumnLayout>
    </Panel>
  )
}

export const IntegrationsBankDetails: React.FC = () => {
  const { t } = useTranslation()
  const businessId = useAppSelector(selectActiveBusinessID)

  const { data, error, refetch, loading } = useBankAccountInfoQuery({
    variables: { businessId },
    notifyOnNetworkStatusChange: true,
  })
  const routingNumber = data?.business?.bankAccount?.routingNumber || ""
  const accountNumber = data?.business?.bankAccount?.accountNumber || ""

  return (
    <QueryResults loading={loading} error={error} retry={() => refetch()} size="small">
      <FlexRowLayout sx={{ marginBottom: 24 }}>
        <CopyableField
          sx={{ marginRight: 12 }}
          value={routingNumber}
          label={t("dashboard.sidebar.routingNumber")}
        />
        <CopyableField value={accountNumber} label={t("dashboard.sidebar.accountNumber")} />
      </FlexRowLayout>
    </QueryResults>
  )
}
