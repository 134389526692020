export const errors = {
  generic: "Something went wrong!",
  insufficientFunds: "Insufficient funds",
  accountNumberInput: "Invalid account number",
  genericScreen: {
    title: "Something went wrong",
    subtitle: "Please try again or ",
    contactSupport: "contact support",
    button: "Try again",
  },
  genericErrorLoading: {
    title: "There was a problem loading this page",
    subtitle: "Try reloading the page. If that doesn't work, contact Customer Care.",
    button: "Reload this page",
  },
  genericNetworkError: {
    title: "Something went wrong. Please try reloading the page. If that doesn't work,",
  },
  invalidRoutingNumber: "Please enter a valid routing number",
  generateBillError: "Something went wrong downloading your bill. Please try again.",
  network: "There was a network problem. Please try again.",
  networkError: {
    title: "There's a problem loading this page",
    subtitle: "There was a technical problem. Try again.",
  },
}
