import React from "react"
import { useTranslation } from "react-i18next"
import { SxStyleProp } from "rebass"

import { FlexColumnLayout, FlexRowLayout, theme } from "@northone/ui"
import { Text } from "@components/primitive/text/text"
import { RevenueDetails, formatMoney } from "@features/revenue-hub/utils"
import { RevenueLineGraph } from "../revenue-line-graph"
import { RevenueHubPlatformInfo } from "@features/revenue-hub/platforms"
import { RevenueHubConnectionPlatformEnum } from "@generated/graphql"

interface ConnectionActiveViewProps {
  revenueDetails: RevenueDetails
  platformInfo: RevenueHubPlatformInfo
}

export const ConnectionActiveView = ({
  revenueDetails,
  platformInfo,
}: ConnectionActiveViewProps) => {
  const { t } = useTranslation()

  const isPlatformEtsy = platformInfo.type === RevenueHubConnectionPlatformEnum.ETSY

  return (
    <FlexColumnLayout sx={panelStyles.dataContainer}>
      <PlatformDataSection
        label={t("revenueHub.platformActive.grossRevenue.label")}
        value={formatMoney(revenueDetails?.totalGrossRevenue)}
        description={t("revenueHub.platformActive.grossRevenue.description")}
      />

      {/* Graph Section Start */}
      {!!revenueDetails?.grossRevenuePerDayTimeSeries && (
        <RevenueLineGraph
          grossRevenuePerDayTimeSeries={revenueDetails.grossRevenuePerDayTimeSeries}
        />
      )}
      {/* Graph Section End */}
      {isPlatformEtsy ? (
        <EtsyDashboardLink etsySiteUrl={platformInfo.siteUrl} />
      ) : (
        <PlatformDataSection
          containerSx={panelStyles.paidOutContainer}
          label={t("revenueHub.platformActive.paidOut.label")}
          value={formatMoney(revenueDetails?.totalPaidOutAmount)}
          description={t("revenueHub.platformActive.paidOut.description")}
        />
      )}
      <PlatformDataSection
        containerSx={panelStyles.transactionsContainer}
        label={t("revenueHub.platformActive.transactions.label")}
        value={revenueDetails?.transactionsCount.toString() || "0"}
        description={t("revenueHub.platformActive.transactions.description")}
      />
      <PlatformDataSection
        label={t("revenueHub.platformActive.returns.label")}
        value={formatMoney(revenueDetails?.totalReturnsAmount)}
        description={t("revenueHub.platformActive.returns.description")}
      />
    </FlexColumnLayout>
  )
}

const PlatformDataSection = ({
  label,
  value,
  description,
  valueSx,
  containerSx,
}: {
  label: string
  value: string
  valueSx?: SxStyleProp
  containerSx?: SxStyleProp
  description: string
}) => (
  <FlexColumnLayout sx={{ ...containerSx }}>
    <FlexRowLayout sx={platformDataSectionStyles.headingContainer}>
      <Text tag="body-small" sx={platformDataSectionStyles.label}>
        {label}
      </Text>
      <Text tag="body-small" sx={{ ...platformDataSectionStyles.value, ...valueSx }}>
        {value}
      </Text>
    </FlexRowLayout>

    <Text tag="body-x-small" sx={{ color: theme.colors.ui2 }}>
      {description}
    </Text>
  </FlexColumnLayout>
)
const PAID_OUT_ETSY_LABEL = "revenueHub.platformActive.paidOut.label"
const PAID_OUT_ETSY_LINK = "revenueHub.platformActive.etsy.link"
const PAID_OUT_ETSY_DESCRIPTION = "revenueHub.platformActive.paidOut.description"

interface EtsyDashboardLinkProps {
  etsySiteUrl: string
}

const EtsyDashboardLink = ({ etsySiteUrl }: EtsyDashboardLinkProps) => {
  const { t } = useTranslation()

  return (
    <FlexColumnLayout sx={panelStyles.paidOutContainer}>
      <FlexRowLayout justifyContent="space-between" sx={platformDataSectionStyles.headingContainer}>
        <Text tag="body-small">{t(PAID_OUT_ETSY_LABEL)}</Text>
        <Text
          tag="body-small"
          textWithEmbeddedLinksProps={{
            text: t(PAID_OUT_ETSY_LINK),
            linksInOrder: [etsySiteUrl],
            linkStyle: { color: theme.colors.blue100 },
          }}
        />
      </FlexRowLayout>
      <Text tag="body-x-small" sx={{ color: theme.colors.ui2 }}>
        {t(PAID_OUT_ETSY_DESCRIPTION)}
      </Text>
    </FlexColumnLayout>
  )
}

const borderBottom = `1px solid ${theme.colors.ui4}`
const panelStyles: Record<string, SxStyleProp> = {
  dataContainer: {
    justifyContent: "center",
    paddingX: 20,
    paddingTop: 16,
    paddingBottom: 24,
    height: "100%",
  },
  graphContainer: { height: "50px", borderBottom },
  graphAxisContainer: { justifyContent: "space-between", marginTop: "4px", marginBottom: 16 },
  graphAxisText: { color: theme.colors.ui3 },
  paidOutContainer: { borderBottom, paddingBottom: 16, marginBottom: 16 },
  transactionsContainer: { paddingBottom: 16 },
}

const platformDataSectionStyles: Record<string, SxStyleProp> = {
  headingContainer: { justifyContent: "space-between", marginBottom: "4px" },
  label: { color: theme.colors.ui1 },
  value: { color: theme.colors.ui1 },
  description: { color: theme.colors.ui2 },
}
