import React from "react"
import i18n from "@i18n/i18n"

import { FlowModalLayout } from "@components/extended/overlay/flow-modal"
import { InvoiceModalDetails } from "./invoice-details/form"
import { InvoiceModalPaymentDetails } from "./payment-details/form"
import { InvoiceModalPaymentsReview } from "./review-payment/form"
import { InvoiceUpsellPlanDetails } from "./subscription-upsell/upsell-details"
import { InvoiceUpsellPlanConfirm } from "./subscription-upsell/upsell-confirm"

export const invoicePaymentsModalProgressItems = [
  i18n.t("invoicePayments.modal.progressBar.invoiceDetails"),
  i18n.t("invoicePayments.modal.progressBar.invoicePayments"),
  i18n.t("invoicePayments.modal.progressBar.invoicePaymentsReview"),
]

export type TInvoicePaymentsModalScreenName =
  | "INVOICE_DETAILS"
  | "PAYMENT_DETAILS"
  | "PAYMENT_REVIEW"
  | "SUBSCRIPTION_UPSELL_DETAILS"
  | "SUBSCRIPTION_UPSELL_CONFIRM"

export const INVOICE_PAYMENTS_MODAL_ROUTES: {
  [key in TInvoicePaymentsModalScreenName]: {
    component: React.ReactElement
    progressItem: string
    layout: FlowModalLayout
  }
} = {
  INVOICE_DETAILS: {
    component: <InvoiceModalDetails />,
    progressItem: invoicePaymentsModalProgressItems[0],
    layout: "form",
  },
  PAYMENT_DETAILS: {
    component: <InvoiceModalPaymentDetails />,
    progressItem: invoicePaymentsModalProgressItems[1],
    layout: "form",
  },
  PAYMENT_REVIEW: {
    component: <InvoiceModalPaymentsReview />,
    progressItem: invoicePaymentsModalProgressItems[2],
    layout: "form",
  },
  SUBSCRIPTION_UPSELL_DETAILS: {
    component: <InvoiceUpsellPlanDetails />,
    progressItem: invoicePaymentsModalProgressItems[2],
    layout: "centered",
  },
  SUBSCRIPTION_UPSELL_CONFIRM: {
    component: <InvoiceUpsellPlanConfirm />,
    progressItem: invoicePaymentsModalProgressItems[2],
    layout: "centered",
  },
}
