import { TAccountsActions, AccountsActionEnum } from "./actions"
import { TCreateSubAccountScreenName } from "../create-sub-account/create-sub-accounts.router"
import { ScheduleFrequencyEnum } from "@generated/graphql"
import { TSubAccountTransferScreenName } from "../transfer/sub-account-transfer.router"
import { ISubAccount } from "@typedefs/types"

export interface ICreateSubAccountState {
  id: string
  name: string
  initialBalance: string
  percentage: string
  scheduledAmount: string
  scheduledFrequency: ScheduleFrequencyEnum | null
  screenHistory: TCreateSubAccountScreenName[]
}

export interface ISubAccountTransferState {
  sourceAccount: ISubAccount
  destinationAccount: ISubAccount
  amount: string
  screenHistory: TSubAccountTransferScreenName[]
}

export interface IAccountsState {
  transactionId: string
  createSubAccount: ICreateSubAccountState
  subAccountTransfer: ISubAccountTransferState
}

const initialCreateSubAccount: ICreateSubAccountState = {
  id: "",
  name: "",
  initialBalance: "",
  percentage: "",
  scheduledAmount: "",
  scheduledFrequency: null,
  screenHistory: ["CREATE_SUB_ACCOUNT_NAME"],
}

const initialSubAccountTransferState: ISubAccountTransferState = {
  sourceAccount: {
    id: "",
    name: "",
    balance: 0,
    isDefault: false,
  },
  destinationAccount: {
    id: "",
    name: "",
    balance: 0,
    isDefault: false,
  },
  amount: "",
  screenHistory: ["SUB_ACCOUNT_TRANSFER_SOURCE_ACCOUNT"],
}

export const initialAccountsState = {
  transactionId: "",
  createSubAccount: initialCreateSubAccount,
  subAccountTransfer: initialSubAccountTransferState,
}

export const accountsReducer = (
  state: IAccountsState = initialAccountsState,
  action: TAccountsActions,
): IAccountsState => {
  switch (action.type) {
    case AccountsActionEnum.SET_ACTIVE_TRANSACTION_ID:
      return {
        ...state,
        transactionId: action.payload,
      }
    case AccountsActionEnum.CREATE_SUB_ACCOUNT_SET_ID:
      return {
        ...state,
        createSubAccount: {
          ...state.createSubAccount,
          id: action.payload,
        },
      }
    case AccountsActionEnum.CREATE_SUB_ACCOUNT_SET_NAME:
      return {
        ...state,
        createSubAccount: {
          ...state.createSubAccount,
          name: action.payload,
        },
      }
    case AccountsActionEnum.CREATE_SUB_ACCOUNT_SET_INITIAL_BALANCE:
      return {
        ...state,
        createSubAccount: {
          ...state.createSubAccount,
          initialBalance: action.payload,
        },
      }
    case AccountsActionEnum.CREATE_SUB_ACCOUNT_SET_SCHEDULED_AMOUNT:
      return {
        ...state,
        createSubAccount: {
          ...state.createSubAccount,
          scheduledAmount: action.payload,
        },
      }
    case AccountsActionEnum.CREATE_SUB_ACCOUNT_SET_SCHEDULED_FREQUENCY:
      return {
        ...state,
        createSubAccount: {
          ...state.createSubAccount,
          scheduledFrequency: action.payload,
        },
      }
    case AccountsActionEnum.CREATE_SUB_ACCOUNT_SET_PERCENTAGE:
      return {
        ...state,
        createSubAccount: {
          ...state.createSubAccount,
          percentage: action.payload,
        },
      }
    case AccountsActionEnum.CREATE_SUB_ACCOUNT_CLEAR_STATE:
      return {
        ...state,
        createSubAccount: initialCreateSubAccount,
      }
    case AccountsActionEnum.CREATE_SUB_ACCOUNT_NAVIGATE_NEXT:
      return {
        ...state,
        createSubAccount: {
          ...state.createSubAccount,
          screenHistory: [...state.createSubAccount.screenHistory, action.payload],
        },
      }
    case AccountsActionEnum.CREATE_SUB_ACCOUNT_NAVIGATE_BACK:
      return {
        ...state,
        createSubAccount: {
          ...state.createSubAccount,
          screenHistory: state.createSubAccount.screenHistory.slice(0, -1),
        },
      }
    case AccountsActionEnum.SUB_ACCOUNT_TRANSFER_NAVIGATE_NEXT:
      return {
        ...state,
        subAccountTransfer: {
          ...state.subAccountTransfer,
          screenHistory: [...state.subAccountTransfer.screenHistory, action.payload],
        },
      }
    case AccountsActionEnum.SUB_ACCOUNT_TRANSFER_NAVIGATE_BACK:
      return {
        ...state,
        subAccountTransfer: {
          ...state.subAccountTransfer,
          screenHistory: state.subAccountTransfer.screenHistory.slice(0, -1),
        },
      }
    case AccountsActionEnum.SUB_ACCOUNT_TRANSFER_DISCARD:
      return {
        ...state,
        subAccountTransfer: {
          ...initialSubAccountTransferState,
        },
      }
    case AccountsActionEnum.SUB_ACCOUNT_TRANSFER_SET_SOURCE_ACCOUNT:
      return {
        ...state,
        subAccountTransfer: {
          ...state.subAccountTransfer,
          sourceAccount: action.payload,
        },
      }
    case AccountsActionEnum.SUB_ACCOUNT_TRANSFER_SET_DESTINATION_ACCOUNT:
      return {
        ...state,
        subAccountTransfer: {
          ...state.subAccountTransfer,
          destinationAccount: action.payload,
        },
      }
    case AccountsActionEnum.SUB_ACCOUNT_TRANSFER_SET_AMOUNT:
      return {
        ...state,
        subAccountTransfer: {
          ...state.subAccountTransfer,
          amount: action.payload,
        },
      }
    default:
      return state
  }
}
