export const autoTopUps = {
  landing: {
    title: "Auto top-up",
    subtitle: "Automatically add funds to your NorthOne account when your balance is running low.",
    toasts: {
      settingsSaved: "Your changes to auto top-up have been saved",
    },
    form: {
      headings: {
        active: "You are all set! Auto top-up is on.",
        inactive: "You don’t have an auto top-up active",
      },
      subtitles: {
        inactive: "Set up an auto top-up to keep your account funded when your balance is low",
        accountUnfunded: "Auto top-up will start once your account is funded.",
      },
      errors: {
        balanceCheckFailed:
          "Your external bank account did not have enough funds for a top-up. *Please ensure your existing bank account has sufficient funds of at least ${{minimumExternalAccountBalance}}, and {{minimumExternalAccountBalanceMultiplier}}x the transfer amount*.",
        saveFailed:
          "There was an issue saving your changes. Try tapping “{{saveButtonText}}” again, or contact Customer Care.",
        unknownError:
          "We were unable to process your auto top-up transaction. Try again, or contact Customer Care.",
        velocityLimitReached:
          "Top-ups is temporarily disabled because you’ve hit the maximum amount of top-ups in the last 30 days. Please refer to the __Deposit Account Agreement__ for more info.",
        plaidItemError:
          "Top up is inactive due to an error with your external account. Select a different account, or contact Customer Care.",
      },
      inputs: {
        minimumBalance: {
          label: "When balance is below",
          errorMessages: {
            valueOutOfRange: "Please enter an amount greater than {{minimumFormatted}}.",
          },
        },
        amount: {
          label: "Auto top-up with",
          errorMessages: {
            valueOutOfRange:
              "Please enter an amount between {{minimumFormatted}} and {{maximumFormatted}}.",
          },
        },
        account: {
          label: "From",
        },
      },
      links: {
        manageAccounts: "Manage Accounts",
      },
      buttons: {
        save: {
          label: "Save auto top-up",
          savedLabel: "Saved",
        },
        cancel: {
          label: "Cancel auto top-up",
        },
      },
    },
    disclaimer: `By selecting “{{saveButtonText}}”, I, {{customerFullName}}, on {{date}}, authorize NorthOne to automatically debit my designated linked external account for recurring top-ups when my NorthOne Account balance falls below a certain threshold, up to {{maximumTopUpsPerThirtyDays}} within a 30 day rolling window. This authorization remains in effect until revoked by turning off the feature through the NorthOne platform. I am responsible for any fees charged by my bank for ACH debits.`,
    cancelConfirmationModal: {
      title: "Are you sure you want to cancel auto top up?",
      body: "If you cancel, you will have to reconfigure auto-top up.",
      buttons: {
        confirm: "Yes, Cancel",
        cancel: "Back",
      },
    },
  },
}
