import i18n from "@i18n/i18n"

import { KanmonWidgetUserState } from "./shared/hooks/use-kanmon-connect-widget"

export const mapWidgetUserStateToTopBannerCtaText = (
  widgetUserState: KanmonWidgetUserState | undefined,
): string => {
  switch (widgetUserState) {
    case "START_FLOW": {
      return i18n.t("lending.landing.topBanner.applyCTAButton")
    }
    case "USER_INPUT_REQUIRED":
    case "OTHER_USER_INPUT_REQUIRED":
    case "WAITING_FOR_OFFERS": {
      return i18n.t("lending.landing.topBanner.continueAppCTAButton")
    }
    case "VIEW_OFFERS": {
      return i18n.t("lending.landing.topBanner.viewOffersCTAButton")
    }
    case "NO_OFFERS_EXTENDED": {
      return i18n.t("lending.landing.topBanner.goToLendingCTAButton")
    }
    case "OFFER_ACCEPTED":
    case "OFFER_EXPIRED":
    case "SERVICING": {
      return i18n.t("lending.landing.topBanner.loanManagerCTAButton")
    }
    default: {
      return i18n.t("lending.landing.topBanner.loanManagerCTAButton")
    }
  }
}
