import React from "react"
import { useTranslation } from "react-i18next"
import { Image } from "rebass"

import { FlexColumnLayout, FlexRowLayout } from "@northone/ui"
import { theme } from "@layouts/theme"
import { Text } from "@components/primitive/text/text"
import { images } from "@assets/images/images"

interface RevenueHubBannerWrapperProps {
  children?: React.ReactNode
}

const RevenueHubBannerWrapper = ({ children }: RevenueHubBannerWrapperProps) => (
  <FlexRowLayout sx={wrapperStyle}>{children}</FlexRowLayout>
)

export const RevenueHubBanner = () => {
  const { t } = useTranslation()

  const { text, image } = bannerStyle

  return (
    <RevenueHubBannerWrapper>
      <FlexColumnLayout sx={text.containerStyle}>
        <Text tag="h3Serif" sx={text.titleStyle}>
          {t("revenueHub.banner.title")}
        </Text>
        <Text tag="body" sx={text.subtitleStyle}>
          {t("revenueHub.banner.subtitle")}
        </Text>
      </FlexColumnLayout>
      <FlexRowLayout justifyContent="space-between" sx={image.containerStyle}>
        <Image sx={image.imageStyle} src={images.revenueHub.squareWidget} />
        <Image sx={{ ...image.imageStyle, opacity: 0.5 }} src={images.revenueHub.paypalWidget} />
        <Image sx={{ ...image.imageStyle, opacity: 0.2 }} src={images.revenueHub.shopifyWidget} />
      </FlexRowLayout>
    </RevenueHubBannerWrapper>
  )
}

const wrapperStyle = {
  background: "linear-gradient(180deg, #F7F7F7 0%, rgba(247, 247, 247, 0) 100%)",
  marginBottom: 70,
  marginTop: 22,
  gap: 2,
  padding: "35px 0 0 35px",
  minHeight: "30vh",
  borderBottom: `1px solid ${theme.colors.ui5}`,
}

const bannerStyle = {
  text: {
    containerStyle: { width: "30%", gap: 2, marginRight: "5px" },
    titleStyle: { fontWeight: 600 },
    subtitleStyle: { color: theme.colors.ui2 },
  },
  image: {
    containerStyle: { width: "70%", gap: 3 },
    imageStyle: { width: "auto" },
  },
}
