import { CardPinSetStatusEnum, CardProductType, CardQuery } from "@generated/graphql"

export enum CardStatusEnum {
  "FULLY_ACTIVATED" = "FULLY_ACTIVATED",
  "NOT_ACTIVATED" = "NOT_ACTIVATED",
  "PENDING_PIN_SET" = "PENDING_PIN_SET",
}

const getCardStatus = (cardData: CardQuery | undefined): CardStatusEnum => {
  const card = cardData?.business?.bankAccount?.card
  const activated = card?.activation?.activated || false
  const pinSetStatus = card?.activation?.pinSet?.status
  const productType = card?.productType
  const isVirtualCard = productType === CardProductType.VIRTUAL
  const fullyActivated = isVirtualCard || (activated && pinSetStatus === CardPinSetStatusEnum.SET)
  const pendingPinSet =
    !isVirtualCard &&
    activated &&
    pinSetStatus &&
    [CardPinSetStatusEnum.READY, CardPinSetStatusEnum.ERROR].includes(pinSetStatus)

  if (fullyActivated) return CardStatusEnum.FULLY_ACTIVATED
  if (pendingPinSet) return CardStatusEnum.PENDING_PIN_SET
  return CardStatusEnum.NOT_ACTIVATED
}

export type CardDetails = {
  cardId: string
  isDisabled: boolean
  isFrozen: boolean
  isFullyActivated: boolean
  productType: CardProductType | undefined
  isVirtualCard: boolean
  status: CardStatusEnum
  shippedDate: string | undefined
  pinSetStatus: CardPinSetStatusEnum
  embossLine1: string | undefined
  last4: string
}
export const cardDataTransformer = (data: CardQuery | undefined): CardDetails | undefined => {
  const card = data?.business?.bankAccount?.card
  if (!card) return undefined
  const productType = card.productType || undefined
  const status = getCardStatus(data)
  return {
    cardId: card.id,
    isDisabled: card.disabled || false,
    isFrozen: card.freezeInfo?.frozen || false,
    productType,
    isVirtualCard: productType === CardProductType.VIRTUAL,
    isFullyActivated: status === CardStatusEnum.FULLY_ACTIVATED,
    status,
    shippedDate: card.shippedDate || undefined,
    pinSetStatus: card.activation?.pinSet?.status || CardPinSetStatusEnum.READY,
    embossLine1: card.embossLine1 || undefined,
    last4: card.cardNumber?.last4 || "",
  }
}
