import React from "react"
import { SxStyleProp, Image } from "rebass"
import { formatMoney } from "accounting"
import { OverflowListItem } from "../overflow/overflow-list-item"
import { CounterpartyAvatar } from "../../avatar/counterparty-avatar"
import { Text } from "@components/primitive/text/text"
import { theme } from "@layouts/theme"
import { images } from "@assets/images/images"
import { FlexColumnLayout, FlexRowLayout } from "@layouts/flex"
import { IColorTheme } from "@northone/ui"

export interface ISubAccountListItemProps {
  name: string
  balance: number
  balanceSubText?: string
  nameSubText?: string
  isActive?: boolean
  onClick?: () => void
  sx?: SxStyleProp
  disabled?: boolean
  withArrow?: boolean
  colorThemes?: IColorTheme[]
  isDefault?: boolean
}

/**
 * Defines a sub-account list item
 */
export const SubAccountListItem: React.FC<ISubAccountListItemProps> = ({
  name,
  nameSubText,
  balance,
  balanceSubText,
  onClick,
  isActive,
  sx,
  disabled,
  withArrow,
  colorThemes,
  isDefault,
}) => {
  return (
    <OverflowListItem onClick={onClick} isActive={isActive} sx={sx}>
      <FlexRowLayout sx={{ width: "100%", alignItems: "center" }}>
        <CounterpartyAvatar
          imageSrc={isDefault ? images.defaultAccount : undefined}
          counterpartyName={name}
          colorThemes={
            disabled
              ? [{ backgroundColor: theme.colors.ui5, textColor: theme.colors.ui3 }]
              : colorThemes
          }
        />
        <FlexColumnLayout>
          <Text
            sx={{ marginX: theme.spacing.listItemPadding }}
            tag={isActive ? "body-small-bold" : "body-small"}
            textColor={disabled ? theme.colors.ui2 : undefined}
          >
            {name}
          </Text>
          {nameSubText && (
            <Text
              tag="body-x-small"
              textColor={theme.colors.ui2}
              sx={{ marginX: theme.spacing.listItemPadding }}
            >
              {nameSubText}
            </Text>
          )}
        </FlexColumnLayout>
      </FlexRowLayout>
      <FlexRowLayout sx={{ alignItems: "center", width: "50%", justifyContent: "flex-end" }}>
        <FlexColumnLayout sx={{ alignItems: "flex-end" }}>
          <Text tag={"body-small"} textColor={disabled ? theme.colors.ui3 : theme.colors.ui1}>
            {formatMoney(balance)}
          </Text>
          {balanceSubText && (
            <Text hasPartialBolding tag="body-x-small" textColor={theme.colors.ui2}>
              {balanceSubText}
            </Text>
          )}
        </FlexColumnLayout>
        {withArrow ? (
          <Image
            sx={{ marginLeft: theme.spacing.listItemPadding }}
            src={images.icons.chevronRight}
          />
        ) : null}
      </FlexRowLayout>
    </OverflowListItem>
  )
}
