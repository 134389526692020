import { createAction, TActionsUnion } from "@core/redux/utils"
import { TeamOwner } from "@generated/graphql"
export enum TeamBankingActionEnum {
  SET_SELECTED_TEAM_MEMBER = "SET_SELECTED_TEAM_MEMBER",
  SET_IS_MODAL_OPEN = "SET_IS_MODAL_OPEN",
  SET_IS_SETTINGS_VISITED = "SET_IS_SETTINGS_VISITED",
  SET_SEND_INVITE_ERROR = "SET_SEND_INVITE_ERROR",
}
export const teamBankingActions = {
  setSelectedTeamMember: (data: TeamOwner | undefined) =>
    createAction(TeamBankingActionEnum.SET_SELECTED_TEAM_MEMBER, data),
  setIsModalOpen: (isModalOpen: boolean) =>
    createAction(TeamBankingActionEnum.SET_IS_MODAL_OPEN, isModalOpen),
  setIsSettingsVisited: (isSettingsVisited: boolean) =>
    createAction(TeamBankingActionEnum.SET_IS_SETTINGS_VISITED, isSettingsVisited),
  setSendInviteError: (sendInviteError: boolean) =>
    createAction(TeamBankingActionEnum.SET_SEND_INVITE_ERROR, sendInviteError),
}
export type TTeamBankingActions = TActionsUnion<typeof teamBankingActions>
