import React from "react"
import { useAppSelector } from "@core/redux/utils"
import { selectActiveBusinessID } from "@core/active-business/redux/selectors"
import { events } from "@core/analytics/events"
import { ACHScreensEnum } from "@features/move-money/router/ach"
import { RecipientSelect } from "@features/move-money/shared/recipient-select-form"
import { IGenericRecipient } from "@features/move-money/redux/state"
import { ContactPaymentType, TransactionTypeEnum, useRecipientsAchQuery } from "@generated/graphql"

export const ACHRecipientForm: React.FC = () => {
  const businessId: string = useAppSelector(selectActiveBusinessID)

  const { data, loading, error, refetch } = useRecipientsAchQuery({
    variables: { businessId, contactPaymentType: ContactPaymentType.ACH },
    notifyOnNetworkStatusChange: true,
  })

  const recipients: IGenericRecipient[] = data?.business?.contacts
    ? data?.business?.contacts.map((contact) => ({
        id: contact.id,
        name: contact.name,
        bankAccountNumber: contact.achPaymentDetails?.accountNumber,
      }))
    : []

  return (
    <RecipientSelect
      recipientType={TransactionTypeEnum.ACH}
      error={error}
      loading={loading}
      refetch={refetch}
      recipients={recipients}
      navigateNextRoute={ACHScreensEnum.ACH_AMOUNT}
      createRecipientRoute={ACHScreensEnum.ACH_RECIPIENT_DETAILS}
      analyticsContinueEvent={events.moveMoney.ach.recipientContinue}
      analyticsCreateEvent={events.moveMoney.ach.recipientAdd}
      analyticsSelectEvent={events.moveMoney.ach.recipient}
    />
  )
}
