import { ApolloClient, InMemoryCache } from "@apollo/client"
import { createPrismicLink } from "apollo-link-prismic"
import possibleTypes from "./possibleTypes.json"
import { GET_SUPPORT_COVERAGE } from "./queries"
import { config } from "@utils/environment"

// FIXME: [ONB-250] Re-implement to go through GQL API
export const sharedRepoClient = new ApolloClient({
  link: createPrismicLink({
    uri: config.sharedRepoPrismicUri,
    accessToken: config.sharedRepoPrismicToken,
  }),
  cache: new InMemoryCache({
    possibleTypes,
  }),
})

export const getChatSupportSchedule = () => {
  return sharedRepoClient.query({
    query: GET_SUPPORT_COVERAGE,
  })
}
