import React from "react"
import { PreloadImage } from "@northone/ui"

import { AlignedColumnLayout } from "../aligned-column-layout"
import { ListItem, ListItemProps } from "@components/primitive/list-item/list-item"
import { FlexRowLayout } from "@layouts/flex"
import { Text } from "@components/primitive/text/text"
import { theme } from "@layouts/theme"

export interface FundingOptionListItemProps extends ListItemProps {
  title: string
  subtitle: string
  iconSrc: string
}

export const FundingOptionListItem = ({
  title,
  iconSrc,
  subtitle,
  ...listCardProps
}: FundingOptionListItemProps) => {
  return (
    <ListItem
      sx={{
        paddingX: theme.spacing.listItemPadding,
        justifyContent: "space-between",
      }}
      {...listCardProps}
    >
      <PreloadImage
        src={iconSrc}
        dimensions={{
          height: 34,
          width: 32,
        }}
        containerStyle={{ marginRight: 24 }}
      />
      <FlexRowLayout sx={{ width: "100%" }}>
        <AlignedColumnLayout alignment={"flex-start"} widthRadio={0.4} sidePadding={20}>
          <Text tag={listCardProps.isActive ? "body-small-bold" : "body-small"}>{title}</Text>
        </AlignedColumnLayout>
        <AlignedColumnLayout alignment={"flex-start"} widthRadio={0.6} sidePadding={20}>
          <Text tag={"body-small"} textColor={theme.colors.ui3}>
            {subtitle}
          </Text>
        </AlignedColumnLayout>
      </FlexRowLayout>
    </ListItem>
  )
}
