import React from "react"
import { Button, ButtonTypeEnum, FlexRowLayout, Text, theme } from "@northone/ui"
import { SxStyleProp } from "rebass"

interface CorporationListResult {
  corporationName: string
  corporationId: string
  onCorporationSelected: (corporationName: string) => void
}

export function CorporationListResult({
  corporationName,
  onCorporationSelected,
}: CorporationListResult) {
  if (!corporationName) {
    return null
  }

  return (
    <FlexRowLayout sx={styles.containerStyle}>
      <Button
        type={ButtonTypeEnum.TERTIARY_ALT}
        sx={styles.buttonStyle}
        onClick={() => onCorporationSelected(corporationName)}
      >
        <Text tag="body" sx={styles.textContainerStyle}>
          {corporationName}
        </Text>
      </Button>
    </FlexRowLayout>
  )
}

const styles: Record<string, SxStyleProp> = {
  containerStyle: { padding: "6px" },
  buttonStyle: {
    padding: 0,
    border: "none",
    background: "none",
    ":hover": {
      background: "none",
    },
    ":active": {
      background: "none",
    },
  },
  textContainerStyle: {
    flexShrink: 0,
    width: "100%",
    color: theme.colors.ui1,
    textAlign: "left",
  },
}
