import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { formatMoney } from "accounting"
import { v4 as uuid } from "uuid"
import { DateTime } from "luxon"
import { Box, Image } from "rebass"

import { FlexRowLayout } from "@northone/ui"

import { images } from "@assets/images/images"
import { useAppSelector } from "@core/redux/utils"
import { selectActiveBusinessID } from "@core/active-business/redux/selectors"
import { Button, ButtonTypeEnum } from "@components/primitive/button/button"
import { DatepickerDropdown } from "@components/composite/datepicker-dropdown/datepicker-dropdown"
import { Text } from "@components/primitive/text/text"
import { FormBody } from "@components/composite/form-body/form-body"
import {
  InvoiceAccountPayablePaymentMethod,
  useCancelScheduledPaymentMutation,
  useTransactionFeesLazyQuery,
  useUpdateScheduledPaymentMutation,
} from "@generated/graphql"
import { theme } from "@layouts/theme"
import { convertDateToString, convertStringToDate } from "@utils/format"
import { INVOICE_DATEPICKER_FORMAT } from "./invoice-modal/constant"
import { InvoicePromptModal } from "./invoice-modal/invoice-prompt-modal/invoice-prompt-modal"
import { ToolTip } from "@components/primitive/tool-tip/tool-tip"
import { MaskTextInput } from "@components/extended/fields/mask-input"
import { IErrorMessage, NoticeBox } from "@components/primitive/notice-box/notice-box"
import { useBankAccountInfo } from "./hooks"
import { MINIMUM_TRANSACTION_AMOUNT } from "@utils/constants"
import {
  TInvoiceAccountsPayable,
  TInvoiceAccountsPayableTransaction,
} from "@features/invoice-payments/redux/reducer"
import { getTransactionFeeForPaymentMethod } from "./utils"
import { TransactionFees } from "@typedefs/types"

export interface IEditInvoiceScheduledTransactionProps {
  invoice: TInvoiceAccountsPayable
  transaction: TInvoiceAccountsPayableTransaction
  setActiveScheduledTransaction: (transaction: TInvoiceAccountsPayableTransaction | null) => void
  onCompleted: () => Promise<void>
}

export const EditInvoiceScheduledTransaction = ({
  invoice,
  transaction,
  setActiveScheduledTransaction,
  onCompleted,
}: IEditInvoiceScheduledTransactionProps) => {
  const { t } = useTranslation()
  const businessId = useAppSelector(selectActiveBusinessID)

  const [idempotencyKey] = useState(uuid())
  const [paymentAmount, setPaymentAmount] = useState<string>(transaction?.amount?.toString() ?? "")
  const [isPaymentAmountTouched, setIsPaymentAmountTouched] = useState<boolean>(false)
  const [paymentDate, setPaymentDate] = useState<string>(transaction?.scheduledDate ?? "")
  const [isPaymentMethodTooltipVisible, setIsPaymentMethodTooltipVisible] = useState<boolean>(false)
  const [isPaymentUpdatePending, setIsPaymentUpdatePending] = useState<boolean>(false)
  const [isPaymentCancellationPending, setIsPaymentCancellationPending] = useState<boolean>(false)
  const [isCancelScheduledPaymentModalOpen, setIsCancelScheduledPaymentModalOpen] =
    useState<boolean>(false)

  const [errorMessage, setErrorMessage] = useState<IErrorMessage>()
  const onTransactionFeeError = () => {
    setErrorMessage({ text: t("errors.genericNetworkError.title"), customerCareLinkRequired: true })
  }
  const transactionFeeData = useRef<TransactionFees>()

  const [getTransactionFees, { error: transactionFeeError }] = useTransactionFeesLazyQuery({
    notifyOnNetworkStatusChange: true,
    onError: onTransactionFeeError,
    onCompleted: (data) => {
      if (data && data.transactionFees) {
        transactionFeeData.current = data.transactionFees
      }
    },
  })

  const {
    paymentMethod,
    scheduledPaymentId,
    scheduledDate,
    subAccount: { id: subAccountId },
  } = transaction

  const invoiceError = () => {
    setErrorMessage({
      text: t("invoicePayments.invoicePanel.editPayment.errorMessage"),
      customerCareLinkRequired: false,
    })
  }

  const { limits, accountAvailableBalance, subAccounts } = useBankAccountInfo()
  const subAccount = subAccounts?.find((subAccount) => subAccount.id === subAccountId)

  if (subAccounts && !subAccount) {
    invoiceError()
  }

  const [updateScheduledPayment, { loading: isUpdateScheduledPaymentLoading }] =
    useUpdateScheduledPaymentMutation({ onError: invoiceError })

  const [cancelScheduledPayment, { loading: isCancelScheduledPaymentLoading }] =
    useCancelScheduledPaymentMutation({ onError: invoiceError })

  const { remainingAmount } = invoice

  let paymentMethodLabel: string | undefined = undefined
  let transactionMaximum: number | undefined = undefined

  switch (paymentMethod) {
    case InvoiceAccountPayablePaymentMethod.ACH:
      paymentMethodLabel = t("invoicePayments.paymentMethods.ach")
      transactionMaximum = limits?.ach.limit
      break
    case InvoiceAccountPayablePaymentMethod.WIRE:
      paymentMethodLabel = t("invoicePayments.wire.wire")
      transactionMaximum = limits?.domesticWire.limit
      break
    case InvoiceAccountPayablePaymentMethod.MAILED_CHECK:
      paymentMethodLabel = t("invoicePayments.paymentMethods.physicalCheck")
      transactionMaximum = limits?.mailedCheck.limit
      break
    case InvoiceAccountPayablePaymentMethod.BILL_PAY:
      paymentMethodLabel = t("invoicePayments.paymentMethods.billPay")
      transactionMaximum = limits?.billPay.limit
      break
  }

  const tomorrowDate = DateTime.local().startOf("day").plus({ days: 1 }).toJSDate()
  const isPaymentDateSetForToday = paymentDate === DateTime.local().toISODate()

  /**
   * This is the hard limit for the amount that the scheduled payment can be updated to
   * eg. if total invoice amount is $150, total scheduled is $30, but the scheduled payment being edited
   * is currently saved as $20, the remainingAmountAvailableToSchedule is $140
   **/
  const remainingAmountAvailableToSchedule = remainingAmount + transaction.amount

  const amountLeftToPay = remainingAmountAvailableToSchedule - Number(paymentAmount)

  const isPaymentAmountEmpty = paymentAmount?.length === 0

  const isPaymentAmountLessThanMinimumPaymentAmount =
    Number(paymentAmount) < MINIMUM_TRANSACTION_AMOUNT

  const isPaymentAmountMoreThanRemainingAmount =
    Number(paymentAmount) > remainingAmountAvailableToSchedule

  const isPaymentAmountOverMaximum =
    transactionMaximum && Number(paymentAmount) > transactionMaximum

  const getIsPaymentAmountMoreThanAccountAvailableBalance = () => {
    if (!transactionFeeData.current || !paymentMethod) return

    const fee = getTransactionFeeForPaymentMethod(transactionFeeData.current, paymentMethod)
    return accountAvailableBalance && Number(paymentAmount) + Number(fee) > accountAvailableBalance
  }

  const getIsPaymentAmountMoreThanSubAccountBalance = () => {
    if (!transactionFeeData.current || !paymentMethod) return

    const fee = getTransactionFeeForPaymentMethod(transactionFeeData.current, paymentMethod)
    return paymentAmount && typeof subAccount?.balance === "number"
      ? Number(paymentAmount) + Number(fee) > subAccount.balance
      : false
  }

  const isPaymentAmountGreaterThanAccountBalances =
    getIsPaymentAmountMoreThanAccountAvailableBalance() ||
    getIsPaymentAmountMoreThanSubAccountBalance()

  const isValidPaymentAmount =
    !isPaymentAmountEmpty &&
    !isPaymentAmountLessThanMinimumPaymentAmount &&
    !isPaymentAmountMoreThanRemainingAmount &&
    !isPaymentAmountOverMaximum &&
    !(isPaymentDateSetForToday && getIsPaymentAmountMoreThanAccountAvailableBalance()) &&
    !(isPaymentDateSetForToday && getIsPaymentAmountMoreThanSubAccountBalance())

  const handleUpdatePayment = async () => {
    const isTransactionEdited =
      (paymentDate && scheduledDate && paymentDate !== scheduledDate) ||
      (isValidPaymentAmount && Number(paymentAmount) !== transaction.amount)

    if (!scheduledPaymentId || !isTransactionEdited) {
      setActiveScheduledTransaction(null)
      return
    }

    setIsPaymentUpdatePending(true)

    try {
      await updateScheduledPayment({
        variables: {
          businessId,
          scheduledPaymentId,
          updateScheduledPaymentInput: {
            amount: Number(paymentAmount),
            startDate: paymentDate,
            idempotencyKey,
          },
        },
      })

      await onCompleted()
    } finally {
      setIsPaymentUpdatePending(false)
    }
  }

  const handleCancelPayment = async () => {
    if (scheduledPaymentId) {
      setIsPaymentCancellationPending(true)

      try {
        await cancelScheduledPayment({
          variables: {
            businessId,
            scheduledPaymentId,
          },
        })

        await onCompleted()
      } finally {
        setIsPaymentCancellationPending(false)
        setIsCancelScheduledPaymentModalOpen(false)
      }
    }
  }

  const formatPaymentAmountErrorMessageText = (): string | undefined => {
    if (!isPaymentAmountTouched) {
      return undefined
    }

    if (isPaymentAmountEmpty) {
      return t("invoicePayments.invoicePanel.editPayment.paymentAmount.invalidAmount")
    }

    if (isPaymentAmountLessThanMinimumPaymentAmount) {
      return t("invoicePayments.invoicePanel.editPayment.paymentAmount.underLimitError", {
        transactionMinimum: formatMoney(MINIMUM_TRANSACTION_AMOUNT),
      })
    }

    if (isPaymentAmountMoreThanRemainingAmount) {
      return t("invoicePayments.invoicePanel.editPayment.paymentAmount.exceedsInvoiceLimitError", {
        remainingAmount: formatMoney(remainingAmountAvailableToSchedule),
      })
    }

    return undefined
  }

  useEffect(() => {
    if (paymentAmount) {
      getTransactionFees({
        variables: { businessId, amount: Number(paymentAmount) },
      })
    }
  }, [paymentAmount])

  if (transactionFeeError) {
    setErrorMessage({
      text: t("errors.genericNetworkError.title"),
      customerCareLinkRequired: true,
    })
  }

  return (
    <>
      <FormBody
        errorMessage={errorMessage}
        titleTag="body-bold"
        title={t("invoicePayments.invoicePanel.editPayment.editScheduledPayment")}
        buttons={[]}
      >
        <DatepickerDropdown
          containerSx={{ marginBottom: "22px" }}
          customDateFormat={INVOICE_DATEPICKER_FORMAT}
          label={t("invoicePayments.invoicePanel.editPayment.paymentDate.label")}
          placeholder={t("invoicePayments.invoicePanel.editPayment.paymentDate.placeholder")}
          minDate={tomorrowDate}
          selectedDate={convertStringToDate(paymentDate)}
          onSelect={(date) => (date ? setPaymentDate(convertDateToString(date)) : null)}
          isClearDateDisabled
        />

        {isPaymentAmountGreaterThanAccountBalances && !isPaymentAmountOverMaximum && (
          <Box sx={{ marginTop: "1rem" }}>
            <NoticeBox
              level={isPaymentDateSetForToday ? "error" : "warning"}
              text={t(
                isPaymentDateSetForToday
                  ? "invoicePayments.invoicePanel.editPayment.paymentAmount.exceedsBalanceError"
                  : "invoicePayments.invoicePanel.editPayment.paymentAmount.exceedsBalanceWarning",
              )}
              showIcon={false}
            />
          </Box>
        )}

        {transactionMaximum && isPaymentAmountOverMaximum && (
          <Box sx={{ marginTop: "1rem" }}>
            <NoticeBox
              level="warning"
              text={t("invoicePayments.invoicePanel.editPayment.paymentAmount.exceedsLimitError", {
                paymentMethod: paymentMethodLabel,
                transactionMaximum: formatMoney(transactionMaximum),
              })}
              showIcon={false}
            />
          </Box>
        )}

        <MaskTextInput
          maskType="AMOUNT"
          label={t("invoicePayments.invoicePanel.editPayment.paymentAmount.label")}
          placeholder={t("invoicePayments.invoicePanel.editPayment.paymentAmount.placeholder")}
          value={paymentAmount}
          onBlur={() => setIsPaymentAmountTouched(true)}
          onChange={(value) => setPaymentAmount(value.replace(/[$,]/g, ""))}
          maxLength={11}
          inputStyle={{
            color:
              isPaymentAmountTouched && !isValidPaymentAmount
                ? theme.colors.error100
                : theme.colors.ui1,
          }}
          errorMessage={formatPaymentAmountErrorMessageText()}
        />

        <Text
          tag="label"
          sx={{
            color: theme.colors.ui2,
          }}
        >
          {t("invoicePayments.invoicePanel.editPayment.amountLeftToPay", {
            amountLeftToPay: formatMoney(amountLeftToPay),
          })}
        </Text>

        {Boolean(paymentMethodLabel) && (
          <Box
            sx={{
              marginTop: "28px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Text
              tag="body-small"
              sx={{
                color: theme.colors.ui2,
              }}
            >
              {t("invoicePayments.invoicePanel.editPayment.paymentMethodDisclaimer", {
                paymentMethodLabel,
              })}
            </Text>

            <ToolTip
              position="right"
              visible={isPaymentMethodTooltipVisible}
              text={t("invoicePayments.invoicePanel.editPayment.paymentMethodTooltip")}
              width={180}
            >
              <Image
                src={images.icons.info}
                sx={{
                  width: "14px",
                  height: "14px",
                  marginLeft: "5px",
                }}
                onMouseEnter={() => setIsPaymentMethodTooltipVisible(true)}
                onMouseLeave={() => setIsPaymentMethodTooltipVisible(false)}
              />
            </ToolTip>
          </Box>
        )}

        <FlexRowLayout sx={{ marginTop: "27px" }}>
          <Button
            type={ButtonTypeEnum.PRIMARY_BLACK}
            sx={{ marginRight: "1rem" }}
            textTag="h5"
            onClick={handleUpdatePayment}
            isLoading={isUpdateScheduledPaymentLoading || isPaymentUpdatePending}
            disabled={
              !isValidPaymentAmount ||
              isUpdateScheduledPaymentLoading ||
              isPaymentUpdatePending ||
              isCancelScheduledPaymentLoading ||
              isPaymentCancellationPending ||
              isPaymentAmountMoreThanRemainingAmount
            }
          >
            {t("invoicePayments.invoicePanel.editPayment.savePayment")}
          </Button>
          <Button
            type={ButtonTypeEnum.TERTIARY}
            textTag="h5"
            textSx={{
              color: theme.colors.ui2,
            }}
            onClick={() => {
              setIsCancelScheduledPaymentModalOpen(true)
            }}
            isLoading={isCancelScheduledPaymentLoading || isPaymentCancellationPending}
            disabled={
              isUpdateScheduledPaymentLoading ||
              isPaymentUpdatePending ||
              isCancelScheduledPaymentLoading ||
              isPaymentCancellationPending
            }
          >
            {t("invoicePayments.invoicePanel.editPayment.deleteScheduledPayment")}
          </Button>
        </FlexRowLayout>
      </FormBody>

      <InvoicePromptModal
        isOpen={isCancelScheduledPaymentModalOpen}
        title={t("invoicePayments.invoicePanel.editPayment.promptDialog.title")}
        subTitle={t("invoicePayments.invoicePanel.editPayment.promptDialog.subTitle")}
        buttons={[
          {
            type: ButtonTypeEnum.SECONDARY,
            children: t("invoicePayments.invoicePanel.editPayment.promptDialog.keepPayment"),
            onClick: () => {
              setIsCancelScheduledPaymentModalOpen(false)
            },
          },
          {
            type: ButtonTypeEnum.PRIMARY_BLUE,
            children: t("invoicePayments.invoicePanel.editPayment.promptDialog.deletePayment"),
            onClick: handleCancelPayment,
            isLoading: isCancelScheduledPaymentLoading || isPaymentCancellationPending,
          },
        ]}
      />
    </>
  )
}
