import { BANCORP_NAME } from "@utils/constants"

export const perks = {
  landing: {
    title: "Save on tools that grow your business",
    body: "Access thousands of dollars in deals and discounts to build, manage, and grow your business.",
    search: {
      placeHolder: "Find perks for the tools you love",
      noResults: {
        title: "No results found",
        body: "Try searching by category to discover savings on similar tools",
      },
    },
    cardsDescription:
      "By selecting a perk and clicking “Visit site”, you will leave the NorthOne website and be directed to an external website not managed or maintained by NorthOne.",
    footer: `All trademarks and brand names belong to their respective owners. 
      Use of these trademarks and brand names do not represent endorsement by or association with this program. 

      We have the right to add and remove offers at any time, without notice. Individual product terms and conditions apply - visit participating partner websites for details.

      The privacy policies of the external website may differ from our privacy policies. Please review the privacy policies and security indicators displayed on the external website before providing any personal information. NorthOne and the Issuer of your card, ${BANCORP_NAME}, neither endorse nor guarantee any of the information, recommendations, optional programs, products, or services advertised, offered by, or made available through the external website (“Products and Services”) and disclaim any liability for any failure of the Products and Services.`,
  },
  modal: {
    claim: "How to claim it",
    support: {
      text: "For more details, contact {{partnerName}} ",
      linkWord: "support",
    },
  },
  errors: {
    general: {
      title: "Something went wrong",
      subtitle: "We ran into a technical problem. Reload the page to try again.",
      ctaButtonText: "Reload this page",
    },
  },
}
