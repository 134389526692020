import React from "react"
import { useTranslation } from "react-i18next"
import { Box } from "rebass"

import { CopyableField } from "@components/composite/copyable-field/copyable-field"
import { Text } from "@components/primitive/text/text"
import { Analytics } from "@core/analytics/actions"
import { events } from "@core/analytics/events"
import { useAppSelector } from "@core/redux/utils"
import { theme } from "@layouts/theme"

export interface IReferralsShareProps {
  setShowCopied: (copied: boolean) => void
  shareSubject: string
  shareMessage: string
  inviteUrl: string
  disabled?: boolean
}

const stripHttpsFromLink = (link: string): string => link.replace("https://", "")

export const ReferralsShare: React.FC = () => {
  const { t } = useTranslation()
  const offerUrl = useAppSelector((state) => state.shareAndEarn.offerUrl)
  const businessId = useAppSelector((state) => state.activeBusiness.businessId)

  const onCopyClick = async () => {
    Analytics.track(events.shareAndEarn.hubCopyLinkTap, { businessId })
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Text tag="h4" sx={{ marginBottom: 10 }}>
        {t("shareAndEarn.share.title")}
      </Text>
      <Text tag="body-large" sx={{ marginBottom: 10, color: theme.colors.ui2 }}>
        {t("shareAndEarn.share.subtitle")}
      </Text>
      <CopyableField
        value={stripHttpsFromLink(offerUrl ?? "")}
        label={""}
        sx={{ marginBottom: 16 }}
        sxInput={{ height: 51 }}
        isShareAndEarn={true}
        showToastOnCopy={true}
        sxInputText={{ color: theme.colors.ui1, fontSize: 16 }}
        onCopyClicked={onCopyClick}
      />
    </Box>
  )
}
