import React, { useState, useRef } from "react"
import { useHistory, useLocation, useRouteMatch } from "react-router-dom"
import { ILocationState, RoutesEnum } from "@routers/types"
import { FlowModal } from "@components/extended/overlay/flow-modal"
import { navigateFromModal } from "@utils/navigation"
import { UpgradePlanDetails } from "./upgrade-plan-details"
import { UpgradePlanConfirmed } from "./upgrade-plan-confirmed"
import { events } from "@core/analytics/events"
import { Analytics } from "@core/analytics/actions"
import { useTranslation } from "react-i18next"

export type TSubscriptionUpgradeScreenNames = "PLAN_DETAILS" | "PLAN_CONFIRMED"
export const trackExitAnalytics = (currentRoute: TSubscriptionUpgradeScreenNames) =>
  Analytics.track(
    currentRoute === "PLAN_DETAILS"
      ? events.subscriptions.cancelUpgradeSubscription
      : events.subscriptions.celebrationScreenExitClick,
  )

export const SubscriptionUpgradeModal = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation<ILocationState>()

  const [currentRoute, setCurrentRoute] = useState<TSubscriptionUpgradeScreenNames>("PLAN_DETAILS")
  const navigateNext = () => setCurrentRoute("PLAN_CONFIRMED")
  const closeModal = () => {
    setCurrentRoute("PLAN_DETAILS")
    navigateFromModal({ defaultBackRoute: RoutesEnum.SUBSCRIPTION_UPGRADE, history, location })
  }

  const progressBarItems = ["Plan Details", "Plan Confirmed"]
  const SUBSCRIPTION_UPGRADE_ROUTES: {
    [key in TSubscriptionUpgradeScreenNames]: {
      component: React.ReactElement
      progressItem: string
    }
  } = {
    PLAN_DETAILS: {
      component: (
        <UpgradePlanDetails
          navigateNext={navigateNext}
          navigateBack={() => {
            trackExitAnalytics("PLAN_DETAILS")
            closeModal()
          }}
        />
      ),
      progressItem: progressBarItems[0],
    },
    PLAN_CONFIRMED: {
      component: <UpgradePlanConfirmed closeModal={closeModal} />,
      progressItem: progressBarItems[1],
    },
  }

  const isRouteMatch = useRouteMatch({ path: `${RoutesEnum.SUBSCRIPTION_UPGRADE}/*`, exact: false })
  const scrollContainerRef = useRef<HTMLDivElement>(null)

  const currentScreen = SUBSCRIPTION_UPGRADE_ROUTES[currentRoute]
  const isOpen = Boolean(isRouteMatch?.isExact)

  return (
    <FlowModal
      pageTitle={t("subscriptions.upgrade.pageTitle")}
      scrollContainerRef={scrollContainerRef}
      containerStyle={{ height: "90vh", paddingY: "50px" }}
      layout={"centered"}
      isOpen={isOpen}
      onExitButtonClick={() => {
        trackExitAnalytics(currentRoute)
        closeModal()
      }}
      progressBar={{
        currentItem: currentScreen.progressItem,
        progressItems: progressBarItems,
      }}
    >
      {currentScreen.component}
    </FlowModal>
  )
}
