import { FlexColumnLayout, FlexRowLayout } from "@layouts/flex"
import React from "react"

import { Text } from "@components/primitive/text/text"
import { theme } from "@layouts/theme"

type LoanProductSectionValueProps = {
  valueProps: { title: string; value: string }[]
}

export const LoanProductSectionValueProps: React.FC<LoanProductSectionValueProps> = ({
  valueProps,
}) => {
  const valuePropBoxComponents = valueProps.map((valueProp, index) => (
    <LoanValuePropBox key={index} title={valueProp.title} value={valueProp.value} />
  ))

  return <FlexRowLayout sx={{ columnGap: 16 }}>{valuePropBoxComponents}</FlexRowLayout>
}

const LoanValuePropBox: React.FC<{
  title: string
  value: string
}> = ({ title, value }) => {
  return (
    <FlexColumnLayout
      sx={{
        height: 141,
        rowGap: 10,
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "4px",
        border: "1px solid var(--copper-tint-4-f-8-f-0-e-6, #F8F0E6)",
        background: "linear-gradient(156deg, #F8EEE6 -121.38%, rgba(248, 240, 230, 0.00) 84.72%)",
      }}
    >
      <Text
        tag={"body-x-small"}
        sx={{
          fontWeight: 500,
          letterSpacing: "1px",
          color: theme.colors.copperDark,
          textTransform: "uppercase",
        }}
      >
        {title}
      </Text>
      <Text
        tag={"h4"}
        sx={{
          lineHeight: "26px",
        }}
      >
        {value}
      </Text>
    </FlexColumnLayout>
  )
}
