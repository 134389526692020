import React from "react"
import { useTranslation } from "react-i18next"
import { AddFundsACHWireThirdParty } from "../shared/ach-wire-third-party/form"

export const AddFundsACHForm: React.FC = () => {
  const { t } = useTranslation()
  return (
    <AddFundsACHWireThirdParty
      title={t("topUp.addFunds.ach.title")}
      subtitle={t("topUp.addFunds.ach.subtitle")}
    />
  )
}
