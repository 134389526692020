import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Image } from "rebass"
import { Text } from "@northone/ui"

import { images } from "@assets/images/images"
import { selectActiveBusinessID } from "@core/active-business/redux/selectors"
import { useAppSelector } from "@core/redux/utils"
import { useTimeSavedQuery } from "@generated/graphql"
import { FlexColumnLayout, FlexRowLayout } from "@layouts/flex"
import { theme } from "@layouts/theme"

import { TimeSavedCounter } from "./time-saved-counter"
import { CalculationMethodologyModal } from "./calculation-methodology.modal"
import LoadingComponent from "@features/cards/shared/loading-spinner"

interface ITimeSavedBannerProps {
  timeSavingAction: TimeSavingActionsEnum
}

export enum TimeSavingActionsEnum {
  "ACH" = "ACH",
  "DOMESTIC_WIRE" = "DOMESTIC_WIRE",
}

const timeSavingActionNameI18nKeyPaths: {
  [key in TimeSavingActionsEnum]: string
} = {
  [TimeSavingActionsEnum.ACH]: "moveMoney.sent.timeSavedBanner.timeSavingActionName.ach",
  [TimeSavingActionsEnum.DOMESTIC_WIRE]: "moveMoney.sent.timeSavedBanner.timeSavingActionName.wire",
}

export const TimeSavedBanner = ({ timeSavingAction }: ITimeSavedBannerProps) => {
  const { t } = useTranslation()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const { data, loading, error, refetch } = useTimeSavedQuery({
    variables: { businessId: useAppSelector(selectActiveBusinessID) },
  })

  const secondsSaved: { [key in TimeSavingActionsEnum]: number } = {
    [TimeSavingActionsEnum.ACH]: data?.timeSaved?.perSameDayAchTimeSavedSeconds || 0,
    [TimeSavingActionsEnum.DOMESTIC_WIRE]: data?.timeSaved?.perWireTimeSavedSeconds || 0,
  }
  const minutesSavedInLastAction = Math.ceil(secondsSaved[timeSavingAction] / 60)

  const basePadding = "30px"

  const renderBody = () => {
    if (!loading && (error || !data?.timeSaved)) {
      refetch()
      return null
    }

    return (
      <FlexRowLayout sx={{ gap: 100 }}>
        {loading ? (
          <LoadingComponent />
        ) : (
          <>
            <FlexColumnLayout sx={{ gap: 2, justifyContent: "center" }}>
              <Text tag="h3Serif" textColor={theme.colors.ui1}>
                {t("moveMoney.sent.timeSavedBanner.title")}
              </Text>
              <TimeSavedCounter secondsSaved={data?.timeSaved?.timeSavedTotalSeconds || 0} />
            </FlexColumnLayout>
            <FlexColumnLayout sx={{ gap: 4, justifyContent: "center" }}>
              <Text hasPartialBolding textColor={theme.colors.ui1} tag="body">
                {t("moveMoney.sent.timeSavedBanner.description", {
                  minutesSaved: minutesSavedInLastAction,
                  timeSavingActionName: t(timeSavingActionNameI18nKeyPaths[timeSavingAction]),
                })}
              </Text>
              <Text
                tag={"body"}
                onClick={() => setIsModalOpen(true)}
                sx={{
                  textDecoration: "underline",
                  color: theme.colors.ui2,
                  cursor: "pointer",
                }}
              >
                {t("moveMoney.sent.timeSavedBanner.learnMoreLink")}
              </Text>
            </FlexColumnLayout>
          </>
        )}
      </FlexRowLayout>
    )
  }
  return (
    <>
      <FlexRowLayout
        sx={{
          backgroundColor: theme.colors.ui6,
          width: "100%",
          borderRadius: 4,
          borderTop: `1px solid ${theme.colors.ui4}`,
          padding: basePadding,
        }}
      >
        <Image src={images.hourglassBlackWhite} height="120px" sx={{ padding: `0 40px` }} />
        {renderBody()}
      </FlexRowLayout>
      <CalculationMethodologyModal
        isModalOpen={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
      />
    </>
  )
}
