import React from "react"
import { Box, Image, SxStyleProp } from "rebass"
import { theme } from "@layouts/theme"
import { Text } from "../text/text"
import { FlexRowLayout } from "@layouts/flex"

export interface IDropdownItemProps<T> {
  text: string
  secondaryText?: string
  value: T
  isActive?: boolean
  onSelect?: (item: { text: string; value: T }) => void
  readOnly?: boolean
  disabled?: boolean
  iconSrc?: string
  sx?: SxStyleProp
  textSx?: SxStyleProp
}

type TDropdownItem = <T>(props: IDropdownItemProps<T>) => React.ReactElement<IDropdownItemProps<T>>

export const DropdownItem: TDropdownItem = <T,>({
  isActive = false,
  readOnly = false,
  text,
  secondaryText,
  onSelect,
  disabled = false,
  iconSrc,
  sx,
  textSx,
  value,
}: IDropdownItemProps<T>) => (
  <Box
    onMouseDown={() => {
      if (!disabled && onSelect) {
        onSelect({ value, text })
      }
    }}
    sx={{
      backgroundColor: isActive ? theme.colors.ui5 : theme.colors.ui7,
      ":hover": { backgroundColor: readOnly ? undefined : theme.colors.ui6 },
      ":active": { backgroundColor: readOnly ? undefined : theme.colors.ui5 },
      height: 40,
      paddingY: "10px",
      paddingX: "12px",
      cursor: readOnly ? "default" : "pointer",
      width: "100%",
      ...sx,
    }}
  >
    {iconSrc && <Image src={iconSrc} sx={{ marginRight: "12px" }} />}
    <FlexRowLayout
      sx={{
        justifyContent: secondaryText ? "space-between " : "flex-start",
      }}
    >
      <Text
        sx={{
          height: "100%",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          display: "block",
          ...textSx,
        }}
        textColor={disabled ? theme.colors.ui4 : theme.colors.ui1}
        tag="body"
      >
        {text}
      </Text>
      {secondaryText ? (
        <Text
          tag="body"
          textColor={theme.colors.ui2}
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            paddingRight: 3,
            height: "100%",
          }}
        >
          {secondaryText}
        </Text>
      ) : null}
    </FlexRowLayout>
  </Box>
)
