import { FlexColumnLayout, FlexRowLayout, theme } from "@northone/ui"
import React, { useState } from "react"
import { Image } from "rebass"
import { Button, ButtonTypeEnum } from "@components/primitive/button/button"
import { Text } from "@components/primitive/text/text"
import { useTranslation } from "react-i18next"
import { images } from "@assets/images/images"
import { PersistentBottomBanner } from "@components/primitive/persistent-bottom-banner"
import { useIsMobileDevice } from "@utils/hooks"
import { deepLinkToLogin } from "./wrapper"

export const MBABanner: React.FC = () => {
  const { t } = useTranslation()
  const isPhone = useIsMobileDevice()
  const [bannerDisplay, setBannerDisplay] = useState(false)

  const hideBanner = () => {
    setBannerDisplay(true)
  }

  if (!isPhone) return null

  return (
    <PersistentBottomBanner
      displayNone={bannerDisplay}
      style={{
        backgroundColor: theme.colors.gold100,
        height: "500px",
        paddingBottom: "150px",
      }}
    >
      <FlexRowLayout>
        <FlexColumnLayout sx={{ width: "90%", marginLeft: 20 }}>
          <Text
            sx={{
              marginTop: 200,
              color: theme.colors.ui7,
              fontFamily: "Plantin MT Pro",
              lineHeight: "70px",
              fontSize: "60px",
              width: "90%",
            }}
            tag="body-large"
          >
            {t("responsive.banner.text")}
          </Text>
          <Button
            type={ButtonTypeEnum.SECONDARY}
            sx={{
              alignSelf: "left",
              textAlign: "middle",
              width: 300,
              height: 125,
              marginTop: 30,
              marginLeft: 20,
            }}
            textSx={{ fontSize: "40px" }}
            onClick={deepLinkToLogin}
          >
            {t("responsive.banner.buttonText")}
          </Button>
        </FlexColumnLayout>
        <Image
          src={images.mbaBannerImage}
          width={300}
          sx={{
            imageRendering: "-webkit-optimize-contrast",
            position: "relative",
            top: "-2rem",
            marginRight: "6rem",
            alignSelf: ["flex-end", "flex-start"],
          }}
        />
        <Image
          sx={{
            flexShrink: 0,
            marginTop: "150px",
            marginRight: "1rem",
            cursor: "pointer",
          }}
          size={50}
          src={images.icons.closeWhite}
          color="#fff"
          onClick={hideBanner}
        />
      </FlexRowLayout>
    </PersistentBottomBanner>
  )
}
