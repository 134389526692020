import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useAppSelector } from "@core/redux/utils"
import { Dropdown } from "@components/composite/dropdown/dropdown"
import { MaskTextInput } from "@components/extended/fields/mask-input"
import { dashboardActions } from "@features/dashboard/redux/actions"
import { TAmountFilterType } from "@features/dashboard/redux/reducer"
import {
  selectDashboardAmountFilterType,
  selectDashboardAmount,
} from "@features/dashboard/redux/selectors"
import { FilterDropdownLabel } from "./panel"

export const FilterAmountFields: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const amountFilterType = useAppSelector(selectDashboardAmountFilterType)
  const amount = useAppSelector(selectDashboardAmount)

  const AMOUNT_FILTER_TYPE_OPTIONS = (
    [null, "minimum", "maximum", "exact"] as TAmountFilterType[]
  ).map((type) => ({
    text: t(`dashboard.filters.amount.${type || "all"}`),
    value: type,
  }))
  return (
    <>
      <Dropdown<TAmountFilterType | null, typeof AMOUNT_FILTER_TYPE_OPTIONS[number]>
        labelComponent={
          <FilterDropdownLabel>{t("dashboard.filters.amount.label")}</FilterDropdownLabel>
        }
        onSelect={(type) => {
          dispatch(dashboardActions.setAmount(""))
          dispatch(dashboardActions.setAmountFilterType(type))
        }}
        options={AMOUNT_FILTER_TYPE_OPTIONS}
        placeholder={t("dashboard.filters.amount.all")}
        selectedValue={amountFilterType || undefined}
      />
      {amountFilterType && (
        <MaskTextInput
          subTextContainerStyling={{ height: 0 }}
          maskType="AMOUNT"
          value={amount}
          onChange={(value) => dispatch(dashboardActions.setAmount(value))}
          labelComponent={
            <FilterDropdownLabel>
              {t(`dashboard.filters.amount.labels.${amountFilterType}`)}
            </FilterDropdownLabel>
          }
          placeholder={t(`dashboard.filters.amount.placeholders.${amountFilterType}`)}
        />
      )}
    </>
  )
}
