import React, { useState, useEffect } from "react"
import MaskedInput, { maskArray } from "react-text-mask"
import { SxStyleProp } from "rebass"
import createNumberMask from "text-mask-addons/dist/createNumberMask"
import { theme } from "@layouts/theme"
import { Field } from "@components/primitive/field/field"

interface IMaskTextInputProps {
  value: string | undefined
  onBlur?: React.FocusEventHandler<HTMLInputElement> | undefined
  onChange: (value: string) => void
  onFocus?: React.FocusEventHandler<HTMLInputElement> | undefined
  errorMessage?: string
  maskType: TMaskType
  placeholder?: string
  sx?: SxStyleProp
  label?: string
  labelComponent?: React.ReactElement
  subText?: string
  containerSx?: SxStyleProp
  subTextContainerStyling?: SxStyleProp
  inputStyle?: React.CSSProperties
  subTextSx?: SxStyleProp
  maxLength?: number
  initialValue?: string
  disabled?: boolean
  guide?: boolean
}

export type TMaskType =
  | "ACCOUNT_NUMBER"
  | "AMOUNT"
  | "AMOUNT_WITHOUT_CENTS"
  | "PERCENTAGE"
  | "PHONE_NUMBER"
  | "ROUTING_NUMBER"

const MASKS: { [key in TMaskType]: (string | RegExp)[] | ((value: string) => maskArray) } = {
  ACCOUNT_NUMBER: Array(17).fill(/\d/),
  AMOUNT: createNumberMask({ prefix: "$", allowDecimal: true }),
  AMOUNT_WITHOUT_CENTS: createNumberMask({ prefix: "$", allowDecimal: false }),
  PERCENTAGE: createNumberMask({ suffix: "%", prefix: "" }),
  PHONE_NUMBER: ["(", /[1-9]/, /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/],
  ROUTING_NUMBER: Array(9).fill(/\d/),
}

export const MaskTextInput: React.FC<IMaskTextInputProps> = ({
  value,
  onBlur,
  onChange,
  onFocus,
  errorMessage,
  maskType,
  placeholder,
  sx,
  label,
  subText,
  containerSx,
  subTextContainerStyling,
  inputStyle,
  labelComponent,
  subTextSx,
  maxLength,
  initialValue,
  disabled,
  guide,
}) => {
  const [key, setKey] = useState(1)

  useEffect(() => {
    // Update the key to remount the masked input when the initialValue changes.
    // Otherwise the mask formatting will not apply.
    setKey(key + 1)
  }, [initialValue])

  return (
    <Field
      containerSx={containerSx}
      subText={subText}
      label={label}
      errorMessage={errorMessage}
      subTextContainerStyling={subTextContainerStyling}
      labelComponent={labelComponent}
      sx={{ paddingX: 12, ...sx }}
      subTextSx={subTextSx}
      disabled={disabled}
    >
      <MaskedInput
        key={key}
        onBlur={onBlur}
        onChange={(e) => onChange(e.target.value)}
        onFocus={onFocus}
        mask={MASKS[maskType]}
        value={value}
        maxLength={maxLength}
        placeholder={placeholder}
        guide={guide}
        disabled={disabled}
        style={{
          backgroundColor: "transparent",
          outline: "none",
          width: "100%",
          border: "none",
          ...theme.textStyles.body,
          paddingBottom: 0,
          height: "100%",
          alignItems: "center",
          overflow: "hidden",
          ...inputStyle,
        }}
      />
    </Field>
  )
}
