import { usePlaidItemsQuery } from "@generated/graphql"
import { useAppSelector } from "@core/redux/utils"
import { selectActiveBusinessID } from "@core/active-business/redux/selectors"

export function usePlaidItems() {
  const businessId = useAppSelector(selectActiveBusinessID)

  const { data, error, refetch, loading } = usePlaidItemsQuery({ variables: { businessId } })

  return {
    error,
    loading,
    plaidItems: data?.plaidItems?.data,
    capabilities: data?.capabilities?.items,
    refetch,
  }
}
