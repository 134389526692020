import gql from "graphql-tag"

export const TRANSACTION_PANEL_QUERY = gql`
  query transactionDetails($businessId: ID!, $transactionId: ID!) {
    transaction(businessId: $businessId, transactionId: $transactionId) {
      id
      amount
      createdAt
      postedAt
      note
      transactionType
      status
      errorMessage
      description
      returnedBy
      cardLast4
      user {
        id
        email
        firstName
        lastName
        isPrimaryOwner
      }
    }
  }
`
