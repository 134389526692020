import { ObservableQuery } from "@apollo/client"

import { DASHBOARD_SEARCH_INPUT_ID } from "@features/dashboard/search/dashboard-search-bar"
import { TransactionsListFiltersInput, TransactionsListQuery } from "@generated/graphql"
import { APP_CONTAINER_ID } from "@layouts/app-layout"
import { TRANSACTIONS_LIST } from "./operations.gql"
import { mergeTransactionGroups } from "./utils"
import { useAppSelector } from "@core/redux/utils"

type TTransactionListFiltersProps = {
  subAccountId: string | undefined
}

type TFetchMoreOptions = {
  businessId: string
  data: TransactionsListQuery | undefined
  fetchMore: ObservableQuery["fetchMore"]
  filters: TransactionsListFiltersInput
}

/**
 * It returns an object that contains the filters that
 * should be applied to the transactions list
 * @param {TTransactionListFiltersProps} options - TTransactionListFiltersProps
 * @returns filters
 */
export const useTransactionListFilters = (options: TTransactionListFiltersProps) => {
  const { subAccountId } = options

  const searchTerm = useAppSelector((state) => state.dashboard.searchTerm)

  const { amount, transactionTypes, direction, datetime, cardIds } = useAppSelector(
    (state) => state.dashboard.filters,
  )

  const filters: TransactionsListFiltersInput = subAccountId
    ? { subAccountId }
    : {
        ...(searchTerm && { text: searchTerm }),
        ...(amount && { amount }),
        ...(transactionTypes && { transactionTypes }),
        ...(direction && { direction }),
        ...(datetime && { datetime }),
        ...(cardIds && { cardIds }),
      }
  return { filters }
}

/**
 * It returns the `fetchMoreTransactions` function that calls the ObservableQuery
 * `fetchMore` from `apollo/client` with the correct arguments
 * @param {TFetchMoreOptions} options - TFetchMoreOptions
 * @returns fetchMoreTransactions
 */
export const useFetchMoreTransactions = (options: TFetchMoreOptions) => {
  const { businessId, data, fetchMore, filters } = options

  const fetchMoreTransactions = () => {
    if (data?.transactionsList?.pagination?.cursor) {
      fetchMore({
        query: TRANSACTIONS_LIST,
        variables: {
          businessId,
          pagination: { cursor: data?.transactionsList?.pagination.cursor },
          filters,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return previousResult
          }
          const newCursor = fetchMoreResult.transactionsList?.pagination?.cursor
          return {
            __typename: "Query",
            transactionsList: {
              __typename: "TransactionsListResponse",
              pagination: { cursor: newCursor || null },
              transactionListItems: mergeTransactionGroups(previousResult, fetchMoreResult),
            },
          }
        },
      })
    }
  }

  return { fetchMoreTransactions }
}

/**
 * It returns an object with a `scrollToTop` function that scrolls the
 * `scrollContainer` to the top of the page
 * @returns scrollToTop and scrollContainer
 */
export const useTransactionListScrollToTop = () => {
  const scrollContainer = document.getElementById(APP_CONTAINER_ID)

  const scrollToTop = () => {
    scrollContainer?.scrollTo({ top: 0, behavior: "smooth" })
    setTimeout(() => document.getElementById(DASHBOARD_SEARCH_INPUT_ID)?.focus(), 200)
  }

  return { scrollToTop, scrollContainer }
}
