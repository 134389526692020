import React, { useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { DateTime } from "luxon"
import { v4 as uuid } from "uuid"
import { FormatOptionLabelMeta } from "react-select"
import { FlexRowLayout, LoadingIcon } from "@northone/ui"
import { Box } from "rebass"

import { invoicePaymentsActions } from "@features/invoice-payments/redux/actions"
import { FormBody } from "@components/composite/form-body/form-body"
import { MaskTextInput } from "@components/extended/fields/mask-input"
import { ButtonTypeEnum } from "@components/primitive/button/button"
import { Text } from "@components/primitive/text/text"
import { Dropdown } from "@components/composite/dropdown/dropdown"
import { ToggleSwitch } from "@components/primitive/toggle-switch/toggle-switch"
import { FlexColumnLayout } from "@layouts/flex"
import {
  ContactPaymentType,
  ErrorCodeEnum,
  InvoiceAccountPayablePaymentMethod,
  useContactBillPayPaymentDetailsCreateMutation,
  useTransactionFeesLazyQuery,
  useUpdateInvoiceContactAchPaymentDetailsMutation,
  useUpdateInvoiceContactBillPayPaymentDetailsMutation,
  useUpdateInvoiceContactMailedCheckPaymentDetailsMutation,
  InvoicePaymentsContactQueryHookResult,
} from "@generated/graphql"
import { useAppSelector } from "@core/redux/utils"
import { convertDateToString, convertStringToDate } from "@utils/format"
import { selectActiveBusinessID } from "@core/active-business/redux/selectors"
import { IErrorMessage, NoticeBox } from "@components/primitive/notice-box/notice-box"
import { ErrorScreen } from "@components/composite/error-screen/error-screen"
import { theme } from "@layouts/theme"
import { DatepickerDropdown } from "@components/composite/datepicker-dropdown/datepicker-dropdown"
import { MINIMUM_TRANSACTION_AMOUNT, DEPOSIT_ACCOUNT_AGREEMENT_LINK } from "@utils/constants"
import { getWeekendsAndBankHolidaysBetween } from "@utils/dates"
import { getInvoicePayments } from "../../redux/selector"
import {
  useAchCutoffTimes,
  useInvoicePaymentsContact,
  useBankAccountInfo,
  useUpdateContactWithWireDetails,
} from "@features/invoice-payments/hooks"
import {
  IPaymentDetailsState,
  TInvoicePaymentMethodDetails,
} from "@features/invoice-payments/redux/reducer"
import {
  TAchContactPaymentDetails,
  AchPaymentDetailsForm,
} from "@features/invoice-payments/invoice-modal/payment-details/payment-method/ach-payment-details"

import {
  TMailedCheckPaymentDetails,
  MailedCheckPaymentDetailsForm,
} from "@features/invoice-payments/invoice-modal/payment-details/payment-method/mailed-check-payment-details"
import {
  TWireContactPaymentDetails,
  WirePaymentDetailsForm,
} from "@features/invoice-payments/invoice-modal/payment-details/payment-method/wire-payment-details"
import { INVOICE_DATEPICKER_FORMAT } from "../constant"
import { formatMoney } from "accounting"
import { pollWithMaxRetries } from "@utils/poll-with-max-retries"
import { ACH_RECIPIENTS, DOMESTIC_WIRE_RECIPIENTS } from "@features/move-money/operations.gql"
import { Analytics } from "@core/analytics/actions"
import { events } from "@core/analytics/events"
import { FlatFee, PercentFee, getFeeDisplay } from "@features/move-money/utils/fee.utils"
import { getTransactionFeeForPaymentMethod } from "@features/invoice-payments/utils"
import { TransactionFees } from "@typedefs/types"
import {
  BillPayPaymentDetailsForm,
  TBillPayPaymentDetails,
} from "./payment-method/bill-pay-payment-details"
import { ApolloError } from "@apollo/client"
import { GraphQLError } from "graphql"
import { INVOICE_PAYMENT_CONTACT_LIST_QUERY } from "@features/invoice-payments/operations.gql"

export type TPaymentMethod = IPaymentDetailsState["paymentMethod"]

type TInvoicePaymentsContact = NonNullable<
  NonNullable<InvoicePaymentsContactQueryHookResult["data"]>["business"]
>["contact"]

export type TContactPaymentDetails =
  | TAchContactPaymentDetails
  | TWireContactPaymentDetails
  | TMailedCheckPaymentDetails
  | TBillPayPaymentDetails

/**
 * Type guard function that checks if the given contact object is an instance of TAchContactPaymentDetails.
 * Returns true if the contact object is an Ach contact.
 * @param contact The contact object to check.
 * @returns True if the contact object is an instance of TAchContactPaymentDetails, false otherwise.
 */
const isAchContactPaymentDetails = (
  contact: TContactPaymentDetails | undefined,
): contact is TAchContactPaymentDetails => {
  return Boolean(contact?.type === InvoiceAccountPayablePaymentMethod.ACH)
}

/**
 * Type guard function that checks if the given contact object is an instance of TMailedCheckPaymentDetails.
 * Returns true if the contact object is a Mailed Check contact.
 * @param contact The contact object to check.
 * @returns True if the contact object is an instance of TMailedCheckPaymentDetails, false otherwise.
 */
const isMailedCheckContactPaymentDetails = (
  contact: TContactPaymentDetails | undefined,
): contact is TMailedCheckPaymentDetails => {
  return Boolean(contact?.type === InvoiceAccountPayablePaymentMethod.MAILED_CHECK)
}

/**
 * Type guard function that checks if the given contact object is an instance of TWireContactPaymentDetails.
 * Returns true if the contact object is a Wire contact.
 * @param contact The contact object to check.
 * @returns True if the contact object is an instance of TWireContactPaymentDetails, false otherwise.
 */
const isWireContactPaymentDetails = (
  contact: TContactPaymentDetails | undefined,
): contact is TWireContactPaymentDetails => {
  return Boolean(contact?.type === InvoiceAccountPayablePaymentMethod.WIRE)
}

const isBillPayContactPaymentDetails = (
  contact: TContactPaymentDetails | undefined,
): contact is TBillPayPaymentDetails => {
  // TO-DO: update to use type from GQL once it's been added: https://northone.atlassian.net/jira/software/c/projects/DEV/boards/154?modal=detail&selectedIssue=DEV-483
  return Boolean(contact?.type === InvoiceAccountPayablePaymentMethod.BILL_PAY)
}

const mapToPaymentMethodDetails = (
  contactPaymentDetails: TContactPaymentDetails | undefined,
  paymentMethod: TPaymentMethod,
): TInvoicePaymentMethodDetails | undefined => {
  if (!paymentMethod) {
    return undefined
  }

  switch (contactPaymentDetails?.type) {
    case InvoiceAccountPayablePaymentMethod.ACH:
      // Set sameDay for ACH payment method
      return {
        type: InvoiceAccountPayablePaymentMethod.ACH,
        sameDay: paymentMethod === "ach_sameday",
      }

    case InvoiceAccountPayablePaymentMethod.MAILED_CHECK:
      // Set memo for MAILED CHECK payment method
      return {
        type: InvoiceAccountPayablePaymentMethod.MAILED_CHECK,
        memo: contactPaymentDetails?.mailedCheckPaymentDetails?.memo,
      }

    case InvoiceAccountPayablePaymentMethod.WIRE:
      // Set purpose for WIRE payment method
      return {
        type: InvoiceAccountPayablePaymentMethod.WIRE,
        purpose: contactPaymentDetails.wirePaymentDetails?.purpose,
      }

    case InvoiceAccountPayablePaymentMethod.BILL_PAY:
      return {
        type: InvoiceAccountPayablePaymentMethod.BILL_PAY,
      }

    default:
      // Set sameDay for ACH payment method
      return {
        type: InvoiceAccountPayablePaymentMethod.ACH,
        sameDay: false,
      }
  }
}

const getDefaultPaymentMethodForContact = ({
  contact,
  isSameDayAvailable,
}: {
  contact: TInvoicePaymentsContact
  isSameDayAvailable?: boolean
}): TPaymentMethod => {
  if (contact?.rppsBillerDetails) {
    return "bill_pay"
  }

  if (contact?.achPaymentDetails && isSameDayAvailable) {
    return "ach_sameday"
  }

  if (contact?.achPaymentDetails) {
    return "ach"
  }

  if (contact?.wirePaymentDetails) {
    return "wire"
  }
  if (contact?.mailedCheckPaymentDetails) {
    return "mailed_check"
  }

  return "ach"
}

interface IPaymentMethodOption {
  text: string
  value: TPaymentMethod
  fee?: FlatFee | PercentFee | number
  description: string
}

export const InvoiceModalPaymentDetails = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [idempotencyKey] = useState(uuid())

  const businessId = useAppSelector(selectActiveBusinessID)
  const invoice = useAppSelector(getInvoicePayments)

  const remainingAmount =
    invoice.activeInvoice?.remainingAmount ?? invoice.invoiceDetails?.totalAmount ?? 0

  const isPartialPaymentInitiated = Boolean(invoice.activeInvoice?.paidAmount)

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<TPaymentMethod | undefined>(
    invoice.invoicePaymentDetails?.paymentMethod,
  )

  const [isPayFullAmount, setIsPayFullAmount] = useState(
    invoice.invoicePaymentDetails?.isPayFullAmount ?? true,
  )

  const [paymentAmount, setPaymentAmount] = useState<string>(
    invoice.invoicePaymentDetails?.paymentAmount.toFixed(2) || remainingAmount?.toFixed(2) || "",
  )

  const isPaymentAmountMoreThanRemaining = Number(paymentAmount) > remainingAmount

  const paymentDetails = invoice.invoicePaymentDetails?.paymentMethodDetails

  const [purpose, setPurpose] = useState<string>(
    (paymentDetails && "purpose" in paymentDetails && paymentDetails.purpose) || "",
  )

  const [memo, setMemo] = useState<string>(
    (paymentDetails && "memo" in paymentDetails && paymentDetails.memo) || "",
  )

  const [subAccountId, setSubAccountId] = useState<string | undefined>(
    invoice.invoicePaymentDetails?.subAccountId,
  )

  const [isValidationInProgress, setIsValidationInProgress] = useState<boolean>(false)

  const [isMissingFields, setIsMissingFields] = useState(false)
  const [hasInvalidFields, setHasInvalidFields] = useState(false)

  const [contactPaymentDetails, setContactPaymentDetails] = useState<TContactPaymentDetails>()

  const [isLoadingContinueButton, setIsLoadingContinueButton] = useState(false)

  const [paymentDate, setPaymentDate] = useState<string>(
    invoice.invoicePaymentDetails?.paymentDate ?? DateTime.local().toISODate(),
  )

  const [isAchContactPaymentDetailsChanged, setIsAchContactPaymentDetailsChanged] = useState(false)

  const [isWireContactPaymentDetailsChanged, setIsWireContactPaymentDetailsChanged] =
    useState(false)

  const [isMailedCheckContactPaymentDetailsChanged, setIsMailedCheckContactPaymentDetailsChanged] =
    useState(false)

  const [isBillPayContactPaymentDetailsChanged, setIsBillPayContactPaymentDetailsChanged] =
    useState(false)

  const [errorMessage, setErrorMessage] = useState<IErrorMessage>()
  const [hasContactAccountNumberError, setHasContactAccountNumberError] = useState(false)

  const transactionFeeData = useRef<TransactionFees>()

  const onTransactionFeeError = () => {
    setErrorMessage({ text: t("errors.genericNetworkError.title"), customerCareLinkRequired: true })
  }

  const [
    isPaymentAmountGreaterThanAvailableBalances,
    setIsPaymentAmountGreaterThanAvailableBalances,
  ] = useState<boolean>()

  const onError = () => {
    setErrorMessage({ text: t("errors.generic") })
    setIsLoadingContinueButton(false)
  }

  const { isAchCutoffTimesLoading, achCutoffTimesError, achCutoffTime, isSameDayAvailable } =
    useAchCutoffTimes({
      onError,
    })

  const { contact, isContactDataLoading } = useInvoicePaymentsContact()

  const defaultPaymentMethodForContact = getDefaultPaymentMethodForContact({
    contact,
    isSameDayAvailable,
  })

  const paymentMethod = selectedPaymentMethod || defaultPaymentMethodForContact

  const paymentMethodDetails = mapToPaymentMethodDetails(contactPaymentDetails, paymentMethod)

  const [getTransactionFees, { error: transactionFeeError }] = useTransactionFeesLazyQuery({
    notifyOnNetworkStatusChange: true,
    onError: onTransactionFeeError,
    onCompleted: (data) => {
      if (!data.transactionFees) {
        onTransactionFeeError()
        return
      }
      transactionFeeData.current = data.transactionFees
      const fee = getTransactionFeeForPaymentMethod(transactionFeeData.current, paymentMethod)
      if (fee === null) {
        return
      }
      setIsPaymentAmountGreaterThanAvailableBalances(
        getIsPaymentAmountGreaterThanAvailableBalances(fee),
      )
    },
  })

  const {
    subAccounts = [],
    isLoading: isAccountInfoQueryLoading,
    limits,
    accountAvailableBalance,
    isWireEnabled,
    error: bankAccountInfoError,
  } = useBankAccountInfo()

  if (!subAccountId && subAccounts?.[0]) {
    setSubAccountId(subAccounts[0].id)
  }

  const selectedSubAccount = subAccounts.find((subAccount) => subAccount.id === subAccountId)

  const { updateContactWithWireDetailsMutation } = useUpdateContactWithWireDetails({
    onError,
  })

  if (!isAccountInfoQueryLoading && (bankAccountInfoError || !limits)) {
    setErrorMessage({
      text: t("errors.genericNetworkError.title"),
      customerCareLinkRequired: true,
    })
  }

  const [updateContactAchPaymentDetails] = useUpdateInvoiceContactAchPaymentDetailsMutation({
    onError,
    onCompleted: () => {
      pollWithMaxRetries([
        {
          query: ACH_RECIPIENTS,
          variables: { businessId, contactPaymentType: ContactPaymentType.ACH },
        },
      ])
    },
  })

  const [updateContactMailedCheckPaymentDetails] =
    useUpdateInvoiceContactMailedCheckPaymentDetailsMutation({
      onError,
    })

  const pollBillPayContacts = () =>
    pollWithMaxRetries([
      {
        query: INVOICE_PAYMENT_CONTACT_LIST_QUERY,
        variables: { businessId, contactPaymentType: ContactPaymentType.BILLPAY },
      },
    ])
  const [addContactBillPayPaymentDetailsMutation] = useContactBillPayPaymentDetailsCreateMutation({
    //Placeholder callbacks swallow errors: https://github.com/apollographql/apollo-client/issues/7167#issuecomment-1306918044
    //To be improved in https://northone.atlassian.net/browse/DEV-707
    onError: () => undefined,
    onCompleted: pollBillPayContacts,
  })
  const [updateContactBillPayPaymentDetailsMutation] =
    useUpdateInvoiceContactBillPayPaymentDetailsMutation({
      onError: () => undefined,
      onCompleted: pollBillPayContacts,
    })

  const todayDate = DateTime.local().startOf("day").toJSDate()
  const tomorrowDate = DateTime.local().startOf("day").plus({ days: 1 }).toJSDate()
  const nextYearDate = DateTime.local().startOf("day").plus({ years: 1 }).toJSDate()

  const weekendsAndBankHolidays = useMemo<Date[]>(
    () =>
      getWeekendsAndBankHolidaysBetween(
        DateTime.fromJSDate(todayDate),
        DateTime.fromJSDate(nextYearDate),
      ).map((date: DateTime) => date.toJSDate()),
    [],
  )

  const hasInvoicePaymentDataChanged =
    isAchContactPaymentDetailsChanged ||
    isMailedCheckContactPaymentDetailsChanged ||
    isWireContactPaymentDetailsChanged ||
    isBillPayContactPaymentDetailsChanged

  const isBillPayEnabled = Boolean(contact?.rppsBillerDetails?.rppsBillerId)

  useEffect(() => {
    dispatch(
      invoicePaymentsActions.setHasInvoicePaymentDetailsChanged(hasInvoicePaymentDataChanged),
    )
  }, [hasInvoicePaymentDataChanged])

  const paymentMethodOptions: IPaymentMethodOption[] =
    isAchCutoffTimesLoading || achCutoffTimesError || isAccountInfoQueryLoading
      ? []
      : [
          {
            value: "ach",
            text: t("invoicePayments.modal.paymentDetails.inputs.paymentMethod.standardAch"),
            description: t(
              "invoicePayments.modal.paymentDetails.inputs.paymentMethod.standardAchDescription",
            ),
          },
          {
            text: t("invoicePayments.modal.paymentDetails.inputs.paymentMethod.sameDayAch"),
            value: "ach_sameday",
            fee: limits?.sameDayAch.fee,
            description: t(
              isSameDayAvailable
                ? "invoicePayments.modal.paymentDetails.inputs.paymentMethod.achSameDayDescription"
                : "invoicePayments.modal.paymentDetails.inputs.paymentMethod.achScheduledSameDayDescription",
              { achCutoffTime: achCutoffTime.toFormat("h a").concat(" ET") },
            ),
          },
          {
            text: t("invoicePayments.modal.paymentDetails.inputs.paymentMethod.mailedCheck"),
            value: "mailed_check",
            fee: limits?.mailedCheck.fee,
            description: t(
              "invoicePayments.modal.paymentDetails.inputs.paymentMethod.mailedCheckDescription",
            ),
          },
          ...(isBillPayEnabled
            ? [
                {
                  text: t("invoicePayments.modal.paymentDetails.inputs.paymentMethod.billPay"),
                  value: "bill_pay" as const,
                  fee: limits?.billPay.fee,
                  description: t(
                    "invoicePayments.modal.paymentDetails.inputs.paymentMethod.billPayDescription",
                  ),
                },
              ]
            : []),
          ...(isWireEnabled
            ? [
                {
                  text: t("invoicePayments.modal.paymentDetails.inputs.paymentMethod.wire"),
                  value: "wire" as const,
                  fee: limits?.domesticWire.fee,
                  description: t(
                    "invoicePayments.modal.paymentDetails.inputs.paymentMethod.wireDescription",
                  ),
                },
              ]
            : []),
        ]

  const contactId = invoice.invoiceDetails?.contactId
  const isAchPaymentMethod = paymentMethod === "ach" || paymentMethod === "ach_sameday"
  const isWirePaymentMethod = paymentMethod === "wire"
  const isMailedCheckPaymentMethod = paymentMethod === "mailed_check"
  const isBillPayPaymentMethod = paymentMethod === "bill_pay"

  const isPaymentMethodValid = Boolean(paymentMethod && paymentMethodDetails)
  const isContactIdValid = Boolean(contactId && subAccountId)
  const isWirePaymentValid = isWirePaymentMethod && purpose && !isMissingFields
  const isACHPaymentValid = isAchPaymentMethod && !isMissingFields
  const isMailedCheckPaymentValid = isMailedCheckPaymentMethod && !isMissingFields
  const isBillPayPaymentValid = isBillPayPaymentMethod && !isMissingFields

  const isPayFullAmountEnabled = Number(invoice.activeInvoice?.remainingAmount) > 0

  const transactionMaximum = isAchPaymentMethod
    ? limits?.ach.limit
    : isWirePaymentMethod
    ? limits?.domesticWire.limit
    : isMailedCheckPaymentMethod
    ? limits?.mailedCheck.limit
    : isBillPayPaymentMethod
    ? limits?.billPay.limit
    : undefined

  const isPaymentAmountOverMaximum =
    transactionMaximum && paymentAmount && Number(paymentAmount) > transactionMaximum

  const isPaymentAmountUnderMinimum =
    paymentAmount && Number(paymentAmount) < MINIMUM_TRANSACTION_AMOUNT

  const isPaymentDateSetForToday = paymentDate === DateTime.local().toISODate()

  const isMissingSubAccountsData = !isAccountInfoQueryLoading && !subAccounts.length
  const missingContactId = !contactId

  const isPayFullAmountToggleChecked = isPayFullAmount && isPayFullAmountEnabled

  const amountLeftToPay = remainingAmount - Number(paymentAmount)

  const getIsPaymentAmountGreaterThanAvailableBalances = (fee: number) => {
    if (!isAccountInfoQueryLoading && !accountAvailableBalance) {
      onError()
      return
    }
    if (accountAvailableBalance && Number(paymentAmount) + fee > accountAvailableBalance) {
      return true
    }
    if (!selectedSubAccount) return false
    if (Number(paymentAmount) + fee > selectedSubAccount.balance) {
      return true
    }
    return false
  }

  const isPaymentAmountMoreThanBalanceForScheduledPayment =
    isPaymentAmountGreaterThanAvailableBalances && !isPaymentDateSetForToday

  const isPaymentAmountMoreThanBalanceForImmediatePayment =
    isPaymentAmountGreaterThanAvailableBalances && isPaymentDateSetForToday

  const getIsPaymentAmountValid = () => {
    const isPaymentAmountValid =
      paymentAmount &&
      !isPaymentAmountUnderMinimum &&
      !isPaymentAmountOverMaximum &&
      !isPaymentAmountMoreThanRemaining &&
      !isPaymentAmountMoreThanBalanceForImmediatePayment

    return isPaymentAmountValid
  }

  const isContinueButtonEnabled = () => {
    return (
      !isValidationInProgress &&
      !hasInvalidFields &&
      isPaymentMethodValid &&
      !hasContactAccountNumberError &&
      getIsPaymentAmountValid() &&
      isContactIdValid &&
      (isACHPaymentValid ||
        isMailedCheckPaymentValid ||
        isWirePaymentValid ||
        isBillPayPaymentValid)
    )
  }

  const getShouldDisplayValidationError = () => {
    return Boolean(
      isPaymentAmountMoreThanRemaining || isPaymentAmountMoreThanBalanceForImmediatePayment,
    )
  }

  const handleContinueButtonClick = async () => {
    Analytics.track(events.invoicePayments.invoiceDetails.reviewPaymentClick, {
      invoiceId: invoice.activeInvoice?.id,
    })

    if (!contactId) {
      return
    }

    const mappedPaymentMethodDetails = mapToPaymentMethodDetails(
      contactPaymentDetails,
      paymentMethod,
    )

    if (!mappedPaymentMethodDetails) {
      return
    }

    setIsLoadingContinueButton(true)

    // Update Contact payment details if-and-only-if there are changes
    let mutationErrors: GraphQLError[] = []
    if (
      isAchPaymentMethod &&
      isAchContactPaymentDetailsChanged &&
      isAchContactPaymentDetails(contactPaymentDetails)
    ) {
      const result = await updateContactAchPaymentDetails({
        variables: {
          data: {
            businessId,
            contactId,
            name: contactPaymentDetails.name,
            achPaymentDetails: {
              accountNumber: contactPaymentDetails.achPaymentDetails.accountNumber,
              routingNumber: contactPaymentDetails.achPaymentDetails.routingNumber,
            },
            idempotencyKey,
          },
        },
      })
      if (result.errors?.length) {
        mutationErrors = [...result.errors]
      }
    }

    if (
      isMailedCheckPaymentMethod &&
      (!contact?.mailedCheckPaymentDetails?.enabled || isMailedCheckContactPaymentDetailsChanged) &&
      isMailedCheckContactPaymentDetails(contactPaymentDetails)
    ) {
      const result = await updateContactMailedCheckPaymentDetails({
        variables: {
          data: {
            businessId,
            contactId,
            name: contactPaymentDetails.name,
            address: contactPaymentDetails.address,
            idempotencyKey,
          },
        },
      })
      if (result.errors?.length) {
        mutationErrors = [...result.errors]
      }
    }

    if (
      isWirePaymentMethod &&
      isWireContactPaymentDetailsChanged &&
      isWireContactPaymentDetails(contactPaymentDetails)
    ) {
      const result = await updateContactWithWireDetailsMutation(contactPaymentDetails)

      // Note: WIRE contact update is asynchronous
      await pollWithMaxRetries([
        {
          query: DOMESTIC_WIRE_RECIPIENTS,
          variables: { businessId, contactPaymentType: ContactPaymentType.WIRE },
        },
      ])
      if (result?.errors?.length) {
        mutationErrors = [...result.errors]
      }
    }

    if (isBillPayPaymentMethod && isBillPayContactPaymentDetails(contactPaymentDetails)) {
      const variables = {
        data: {
          businessId,
          contactId,
          idempotencyKey,
          paymentDetails: {
            accountNumber: contactPaymentDetails.billPayPaymentDetails.accountNumber,
          },
        },
      }
      if (!contact?.billPayPaymentDetails) {
        const result = await addContactBillPayPaymentDetailsMutation({
          variables,
        })
        const errors = result.errors as any as ApolloError
        if (errors?.graphQLErrors?.length) {
          mutationErrors = [...errors.graphQLErrors]
        }
      } else if (contact?.billPayPaymentDetails && isBillPayContactPaymentDetailsChanged) {
        const result = await updateContactBillPayPaymentDetailsMutation({
          variables,
        })
        const errors = result.errors as any as ApolloError
        if (errors?.graphQLErrors?.length) {
          mutationErrors = [...errors.graphQLErrors]
        }
      }
    }

    dispatch(
      invoicePaymentsActions.setInvoicePaymentDetails({
        subAccountId: subAccountId as string, // Note: button is disabled when subAccountId is undefined
        paymentAmount:
          isPayFullAmount && isPayFullAmountEnabled && invoice.invoiceDetails?.totalAmount
            ? invoice.invoiceDetails.totalAmount
            : Number(paymentAmount),
        paymentMethodDetails: mappedPaymentMethodDetails,
        paymentMethod,
        isPayFullAmount,
        paymentDate,
      }),
    )

    setIsLoadingContinueButton(false)
    if (mutationErrors?.length) {
      const code = mutationErrors[0].extensions.code
      if (code === ErrorCodeEnum.CONTACT_INVALID_BILLER_ACCOUNT_NUMBER) {
        setHasContactAccountNumberError(true)
      } else {
        setErrorMessage({ text: t("errors.generic") })
      }
      return
    }

    dispatch(invoicePaymentsActions.navigate("PAYMENT_REVIEW"))
  }

  const getPaymentMethodLabel = () => {
    switch (paymentMethod) {
      case "ach":
      case "ach_sameday":
        return t("invoicePayments.paymentMethods.ach")
      case "wire":
        return t("invoicePayments.paymentMethods.wire")
      case "mailed_check":
        return t("invoicePayments.paymentMethods.physicalCheck")
      case "bill_pay":
        return t("invoicePayments.paymentMethods.billPay")
    }
  }

  const formatPaymentAmountErrorMessageText = (): string | undefined => {
    if (isPaymentAmountMoreThanRemaining) {
      return t(
        "invoicePayments.modal.paymentDetails.inputs.paymentAmount.exceedsInvoiceLimitError",
        { remainingAmount: formatMoney(remainingAmount) },
      )
    }

    if (isPaymentAmountMoreThanBalanceForImmediatePayment) {
      return t("invoicePayments.modal.paymentDetails.inputs.paymentAmount.exceedsBalanceError")
    }

    return undefined
  }

  const PaymentMethodOptionRenderer = (
    data: IPaymentMethodOption,
    _formatOptionLabelMeta: FormatOptionLabelMeta<IPaymentMethodOption>,
  ) => {
    const { text, fee, description } = data

    const getPaymentMethodFeeLabel = () => {
      if (!fee) {
        return t("invoicePayments.modal.reviewPayment.inputs.paymentMethod.noFee")
      } else if (typeof fee === "number") {
        return fee
      }
      return t("invoicePayments.modal.reviewPayment.inputs.paymentMethod.fee", {
        amount: getFeeDisplay(fee),
      })
    }

    return (
      <FlexRowLayout>
        <Text tag="body-small" sx={{ flex: 1, textAlign: "start" }}>
          {text}
          <span style={{ color: theme.colors.ui2, paddingLeft: "5px" }}>
            ({getPaymentMethodFeeLabel()})
          </span>
        </Text>
        <Text tag="body-small" sx={{ flex: 1, textAlign: "end", color: theme.colors.ui2 }}>
          {description}
        </Text>
      </FlexRowLayout>
    )
  }

  useEffect(() => {
    if (paymentAmount) {
      getTransactionFees({
        variables: { businessId, amount: Number(paymentAmount) },
      })
    }
  }, [paymentAmount, paymentMethod, selectedSubAccount])

  if (
    !invoice.invoiceDetails ||
    isMissingSubAccountsData ||
    missingContactId ||
    transactionFeeError ||
    (!isAccountInfoQueryLoading && !subAccounts)
  ) {
    return <ErrorScreen size="medium" sx={{ p: 40 }} />
  }

  return (
    <FormBody
      containerSx={{ width: "50%", padding: "40px", overflowY: "auto" }}
      title={t("invoicePayments.modal.paymentDetails.title")}
      buttonContainerSx={{ width: "100%", justifyContent: "flex-end" }}
      displayButtonsOnBottom
      buttons={[
        {
          children: t("invoicePayments.modal.paymentDetails.buttons.editInvoice"),
          onClick: () => {
            Analytics.track(events.invoicePayments.invoiceDetails.editInvoiceDetailsClick, {
              invoiceId: invoice.activeInvoice?.id,
            })
            // Go back to Invoice Details
            dispatch(invoicePaymentsActions.navigate("INVOICE_DETAILS"))
          },
          type: ButtonTypeEnum.SECONDARY,
        },
        {
          canContinueWithKey: true,
          children: t("invoicePayments.modal.paymentDetails.buttons.reviewPayment"),
          onClick: handleContinueButtonClick,
          isLoading: isLoadingContinueButton,
          disabled: !isContinueButtonEnabled(),
        },
      ]}
      errorMessage={errorMessage}
    >
      <FlexColumnLayout>
        <Text tag="body-large-bold" sx={{ marginBottom: "1rem" }}>
          {t("invoicePayments.modal.paymentDetails.paymentDetails")}
        </Text>
        <Dropdown
          label={t("invoicePayments.modal.paymentDetails.inputs.paymentMethod.label")}
          placeholder={t("invoicePayments.modal.paymentDetails.inputs.paymentMethod.placeholder")}
          customContainerStyle={{ mb: "1rem" }}
          formatOptionLabel={PaymentMethodOptionRenderer}
          isLoading={isAchCutoffTimesLoading || isContactDataLoading || isAccountInfoQueryLoading}
          options={paymentMethodOptions}
          selectedValue={paymentMethod}
          onSelect={(value) => {
            setSelectedPaymentMethod(value)
            switch (value) {
              case "ach": {
                setPaymentDate(convertDateToString(todayDate))
                break
              }
              case "ach_sameday": {
                setPaymentDate(convertDateToString(isSameDayAvailable ? todayDate : tomorrowDate))
                break
              }
              case "mailed_check": {
                break
              }
              case "wire": {
                setPaymentDate(convertDateToString(todayDate))
                break
              }
              case "bill_pay": {
                Analytics.track(
                  events.invoicePayments.invoicePaymentDetails.billPayPaymentMethodSelected,
                  {
                    contactName: contact?.name,
                    contactId: contact?.id,
                  },
                )
                break
              }
            }
          }}
        />

        {paymentMethod && isContactDataLoading && <LoadingIcon isCentered />}

        {!isContactDataLoading && contact && (
          <>
            {isAchPaymentMethod && (
              <AchPaymentDetailsForm
                contact={contact}
                onChange={setContactPaymentDetails}
                setIsAchContactPaymentDetailsChanged={setIsAchContactPaymentDetailsChanged}
                setIsMissingFields={setIsMissingFields}
                setHasInvalidFields={setHasInvalidFields}
                setIsValidationInProgress={setIsValidationInProgress}
              />
            )}

            {isWirePaymentMethod && (
              <WirePaymentDetailsForm
                purpose={purpose}
                setPurpose={setPurpose}
                contact={contact}
                onChange={setContactPaymentDetails}
                setIsWireContactPaymentDetailsChanged={setIsWireContactPaymentDetailsChanged}
                setIsMissingFields={setIsMissingFields}
                setHasInvalidFields={setHasInvalidFields}
                setIsValidationInProgress={setIsValidationInProgress}
              />
            )}

            {isMailedCheckPaymentMethod && (
              <MailedCheckPaymentDetailsForm
                memo={memo}
                setMemo={setMemo}
                contact={contact}
                onChange={setContactPaymentDetails}
                setIsMailedCheckContactPaymentDetailsChanged={
                  setIsMailedCheckContactPaymentDetailsChanged
                }
                setIsMissingFields={setIsMissingFields}
              />
            )}
            {isBillPayPaymentMethod && (
              <BillPayPaymentDetailsForm
                contact={contact}
                onChange={setContactPaymentDetails}
                setIsBillPayContactPaymentDetailsChanged={setIsBillPayContactPaymentDetailsChanged}
                setIsMissingFields={setIsMissingFields}
                hasAccountNumberError={hasContactAccountNumberError}
                setHasAccountNumberError={setHasContactAccountNumberError}
              />
            )}
          </>
        )}

        <Dropdown
          options={subAccounts.map((subAccount) => ({
            text: `${subAccount.name} (${t(
              "invoicePayments.modal.paymentDetails.inputs.subAccount.balance",
            )} $${subAccount.balance})`,
            value: subAccount.id,
          }))}
          onSelect={setSubAccountId}
          selectedValue={subAccountId}
          label={t("invoicePayments.modal.paymentDetails.inputs.subAccount.label")}
          isLoading={isAccountInfoQueryLoading}
        />

        <Text tag="body-large-bold" sx={{ marginTop: "2rem", marginBottom: "1rem" }}>
          {t("invoicePayments.modal.paymentDetails.paymentDate")}
        </Text>
        <FlexColumnLayout sx={{ mb: "1.5rem" }}>
          <DatepickerDropdown
            label={t("invoicePayments.modal.paymentDetails.inputs.paymentDate.label")}
            placeholder={t("invoicePayments.modal.paymentDetails.inputs.paymentDate.placeholder")}
            selectedDate={convertStringToDate(paymentDate)}
            minDate={
              paymentMethod === "ach_sameday" && !isSameDayAvailable ? tomorrowDate : todayDate
            }
            maxDate={isWirePaymentMethod ? todayDate : nextYearDate}
            unavailableDates={weekendsAndBankHolidays}
            onSelect={(date) => (date ? setPaymentDate(convertDateToString(date)) : null)}
            isClearDateDisabled
            customDateFormat={INVOICE_DATEPICKER_FORMAT}
            disabled={isWirePaymentMethod}
          />

          {isWirePaymentMethod && (
            <FlexColumnLayout sx={{ mt: "1rem", alignItems: "flex-start" }}>
              <Text color={theme.colors.ui2} tag="label">
                {t("invoicePayments.modal.paymentDetails.inputs.wire.disclaimerWireSent")}
              </Text>
              <Text color={theme.colors.ui2} tag="label">
                {t(
                  "invoicePayments.modal.paymentDetails.inputs.wire.disclaimerWirePaymentSchedule",
                )}
              </Text>
            </FlexColumnLayout>
          )}
        </FlexColumnLayout>
        <Box sx={{ marginBottom: "1rem" }}>
          <ToggleSwitch
            label={
              <Text tag="body">
                {t(
                  isPartialPaymentInitiated
                    ? "invoicePayments.modal.paymentDetails.inputs.payRemainingAmount.label"
                    : "invoicePayments.modal.paymentDetails.inputs.payFullAmount.label",
                )}{" "}
                <span style={{ color: theme.colors.ui2 }}>({formatMoney(remainingAmount)})</span>
              </Text>
            }
            checked={isPayFullAmountToggleChecked}
            onClick={() => {
              setIsPayFullAmount(!isPayFullAmount)
              if (!isPayFullAmount && remainingAmount) {
                setPaymentAmount(remainingAmount.toFixed(2))
              }
            }}
            disabled={!isPayFullAmountEnabled}
          />
          {getShouldDisplayValidationError() && isPayFullAmountToggleChecked && (
            <Text
              tag={"label-bold"}
              textColor={theme.colors.error100}
              lineHeight={1.5}
              marginTop={"0.3rem"}
            >
              {formatPaymentAmountErrorMessageText()}
            </Text>
          )}
        </Box>
        {!isPayFullAmountToggleChecked && (
          <Box>
            <MaskTextInput
              maskType={"AMOUNT"}
              label={t("invoicePayments.modal.paymentDetails.inputs.paymentAmount.label")}
              placeholder={t(
                "invoicePayments.modal.paymentDetails.inputs.paymentAmount.placeholder",
              )}
              value={paymentAmount}
              onChange={(value) => {
                setPaymentAmount(value.replace(/[$,]/g, ""))
              }}
              subTextContainerStyling={{ mb: "1rem" }}
              errorMessage={formatPaymentAmountErrorMessageText()}
            />
            <Text
              tag="label"
              sx={{
                color: theme.colors.ui2,
              }}
            >
              {t("invoicePayments.modal.paymentDetails.inputs.paymentAmount.amountLeftToPay", {
                amountLeftToPay: formatMoney(amountLeftToPay),
              })}
            </Text>
          </Box>
        )}
        {isPaymentAmountOverMaximum && transactionMaximum && (
          <NoticeBox
            level="warning"
            text={t("invoicePayments.modal.paymentDetails.inputs.paymentAmount.exceedsLimitError", {
              paymentMethod: getPaymentMethodLabel(),
              transactionMaximum: formatMoney(transactionMaximum),
            })}
            showIcon={false}
            containerSx={{
              mb: 2,
            }}
          />
        )}
        {isPaymentAmountUnderMinimum && (
          <NoticeBox
            level="warning"
            text={t("invoicePayments.modal.paymentDetails.inputs.paymentAmount.underMinimumError", {
              paymentMethod: getPaymentMethodLabel(),
              transactionMinimum: formatMoney(MINIMUM_TRANSACTION_AMOUNT),
            })}
            showIcon={false}
          />
        )}
        {isPaymentAmountMoreThanBalanceForScheduledPayment && (
          <NoticeBox
            level="warning"
            text={t(
              "invoicePayments.modal.paymentDetails.inputs.paymentAmount.exceedsBalanceWarning",
              {
                paymentMethod: getPaymentMethodLabel(),
                transactionMinimum: formatMoney(MINIMUM_TRANSACTION_AMOUNT),
              },
            )}
            showIcon={false}
          />
        )}

        <Text
          tag="body-small"
          textWithEmbeddedLinksProps={{
            text: t("invoicePayments.modal.paymentDetails.disclosure"),
            linksInOrder: [DEPOSIT_ACCOUNT_AGREEMENT_LINK],
            linkStyle: { color: theme.colors.ui2 },
          }}
        ></Text>
      </FlexColumnLayout>
    </FormBody>
  )
}
